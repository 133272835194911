/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react'
import styles from 'components/pages/support/help/helpStyles'

const ConfigureMxRecords = () => {
  const classes = styles()

  return useMemo(
    () => (
      <div className={classes.content} data-testid="content" style={{ height: '100%' }}>
        <p>
          <b>
            Before using Email Gateway Defense, you must modify your domain’s MX records to point to Barracuda Networks'
            mail servers. MX records direct your email traffic and are stored by your domain’s DNS provider.
          </b>
        </p>
        <p>
          Note: You must modify all of your MX records to point to Barracuda Networks' mail servers. If any of your MX
          records point to servers other than Barracuda Networks', that can interfere with Barracuda Networks' ability
          to protect your domain(s) from threats and spam.
        </p>
        <ol style={{ listStyleType: 'decimal' }}>
          <li>
            Click a domain name to expand the table row and expose its MX record information.
            <br />
            <small>You will need this information in Step 4 below.</small>
          </li>
          <li>
            Sign in to your domain’s DNS provider account.
            <br />
            <small>DNS providers include companies such as GoDaddy and Enom.</small>
          </li>
          <li>
            In your DNS provider account, locate the page for updating your domain’s DNS records.
            <br />
            <small>
              It might be called something like DNS Zone File, Name Server Management, or Advanced Settings.
            </small>
          </li>
          <li>
            Replace the existing MX records in the DNS provider account with the ones you exposed in Step 1 above.
            <br />
            <small>You will need this information in Step 4 below.</small>
          </li>
          <li>
            Save your changes and wait for the new settings to take effect.
            <br />
            <small>Updating MX records can take up to 48 hours.</small>
          </li>
          <li>
            Repeat this step for all of your Domains
            <br />
            <small>
              It is essential for threat and spam prevention that you follow this procedure for all of your domains.
            </small>
          </li>
        </ol>
      </div>
    ),
    [classes]
  )
}

export default ConfigureMxRecords
