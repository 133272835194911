import { useMemo } from 'react'

const applyMask = (value: string) => {
  const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  const blocks: string[] = []
  let currentBlock = 0
  let currentChar = ''

  for (let i = 0; i < value.length; i++) {
    currentChar = value[i]

    if (currentChar === '.' && blocks[currentBlock] !== '') {
      currentBlock++
      // eslint-disable-next-line no-continue
      continue
    }
    if (blocks[currentBlock]?.length === 3) {
      currentBlock++
    }
    if (currentBlock > 3) {
      break
    }
    if (typeof blocks[currentBlock] !== 'string') {
      blocks[currentBlock] = ''
    }
    if (validChars.includes(currentChar)) {
      blocks[currentBlock] = `${blocks[currentBlock]}${currentChar}`
    }
    if (blocks.reduce((totalLen, block) => totalLen + block.length, 0) >= 12) {
      break
    }
  }

  // Normalize numeric text
  for (let i = 0; i < blocks.length; i++) {
    const num = Math.max(Math.min(parseInt(blocks[i], 10), 255), 0)
    blocks[i] = `${Number.isNaN(num) ? '' : num}`
  }

  let transformed = blocks.join('.')
  if (currentChar === '.') {
    transformed = `${transformed}.`
  }

  // Remove trailing dot
  if (blocks.length === 4 && transformed[transformed.length - 1] === '.') {
    transformed = transformed.substr(0, transformed.length - 1)
  }

  return transformed
}

export const useIpTextFieldLogic = () => {
  const onInput = (e: any) => {
    if (e.target && typeof e.target === 'object') {
      e.target.value = applyMask(e.target.value || '')
    }
  }

  return useMemo(() => ({ onInput }), [])
}
