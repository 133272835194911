import { makeStyles } from '@barracuda-internal/bds-core'
import { SIDE_MENU_WIDTH } from 'components/libs/layout/layoutStyles'

export default makeStyles(theme => ({
  outerWrapper: {
    margin: theme.spacing(2, 3, 2, 3),
    flexWrap: 'nowrap',
    width: 'auto'
  },
  searchTextFieldWrapper: {
    minWidth: 260,
    margin: theme.spacing(0, 1.5, 0, 0)
  },
  searchTextField: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    flex: '1 1 auto'
  },
  advancedSearch: {
    width: `calc(100% - ${SIDE_MENU_WIDTH}px)`,
    maxWidth: 630,
    zIndex: 100
  },
  selectMenus: {
    backgroundColor: theme.palette.common.white,
    fontSize: '14px',
    padding: theme.spacing(0, 1),
    width: 136,
    lineHeight: '19px',
    '& > div:focus': {
      background: 'inherit'
    },
    '&:before': {
      // normal
      borderBottom: 'none !important'
    },
    '&:after': {
      // focused
      borderBottom: 'none !important'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: 'none !important'
    },
    '@media screen and (min-width:1536px)': {
      width: 160
    }
  },
  disableBtn: {
    opacity: 0.6,
    pointerEvents: 'none'
  },
  menuItem: {
    '&.custom': {
      display: 'none'
    }
  }
}))
