import { string } from 'prop-types'
import { AvailableSettings, SettingValue } from 'types/Settings'

export interface AntiPhishingSettings {
  [AvailableSettings.BAFI]: BafiActions
  [AvailableSettings.EXT_SENDER_WARNING]: SettingValue
  [AvailableSettings.INTENT]: SettingValue
  [AvailableSettings.REDIRECTOR_CONTENT_ACTION]: ContentIntentActions
  [AvailableSettings.INTENT_REALTIME]?: SettingValue
  [AvailableSettings.LINKPROTECT]: SettingValue
  [AvailableSettings.TYPOSQUAT]: SettingValue
  [AvailableSettings.EMAIL_BANNERS]: EmailBannersActions
  [AvailableSettings.EMAIL_BANNERS_TESTING_USERS]?: string
}

export interface LinkedDomainPolicy {
  id: string
  domain: string
  policy: string
  comment: string
}

export enum BafiActions {
  QUARANTINE = '2',
  BLOCK = '1',
  OFF = '0'
}

export enum ContentIntentActions {
  QUARANTINE = 'quarantine',
  BLOCK = 'block',
  DEFER = 'defer',
  OFF = 'off'
}

export enum IntentDomainPolicies {
  IGNORE = 'ignore',
  BLOCK = 'block',
  QUARANTINE = 'quarantine'
}

export enum EmailBannersActions {
  ON = '1',
  TESTING_MODE = '2',
  OFF = '0'
}

export const AVAILABLE_BAFI_ACTIONS = ['block', 'quarantine', 'off']
export const AVAILABLE_CONTENT_INTENT_ACTIONS = ['block', 'quarantine', 'defer', 'off']
export const AVAILABLE_CPL_CONTENT_INTENT_ACTIONS = ['block', 'defer', 'off']
export const AVAILABLE_INTENT_DOMAIN_POLICIES = ['ignore', 'block', 'quarantine']
export const AVAILABLE_CPL_INTENT_DOMAIN_POLICIES = ['ignore', 'block']
