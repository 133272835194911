/* eslint-disable no-underscore-dangle */
import appConfig from 'config/appConfig'
import restClient from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { MixpanelProperties, getMixpanelProperties } from 'lib/monitoring/mixpanel/mixpanelProperties'

export { TRACKING_EVENTS } from 'config/trackingEvents/trackingEvents'

export interface Flags {
  [key: string]: string
}

const mixpanelConfig = appConfig.MIXPANEL
const isMixpanelEnabled = mixpanelConfig.IS_ENABLED

export async function trackMixpanelEvent(eventName: string, metadata?: Record<string, unknown>) {
  if (isMixpanelEnabled) {
    try {
      const mixpanelProperties: Partial<MixpanelProperties> = getMixpanelProperties()
      const resp = await restClient(apiRoutes.MIXPANEL, {
        data: {
          eventName,
          metadata,
          mixpanelProperties
        }
      })
    } catch {
      // eslint-disable-next-line
      console.log('Mixpanel reporting is failed for event:', eventName)
    }
  } else if (appConfig.ENVIRONMENT.IS_DEV) {
    // eslint-disable-next-line
    console.log('Mixpanel is not defined, blocked event:', eventName)
  }
}

export function trackPageLeaveMixpanelEvent(eventName: string) {
  window.addEventListener('beforeunload', () => {
    trackMixpanelEvent(eventName)
  })
}
