import { createSlice } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import {
  cnameValidation,
  confirmValidation,
  postValidation,
  removeLogo,
  removeValidation
} from 'redux/features/encryption/encryptionApiThunks'

export interface EncryptionState {
  cnameValidationApiStatus: ApiStatus
  postValidationApiStatus: ApiStatus
  confirmValidationApiStatus: ApiStatus
  removeValidationApiStatus: ApiStatus
  removeLogoApiStatus: ApiStatus
}

// initialState
export const INITIAL_STATE: EncryptionState = {
  cnameValidationApiStatus: inIdle,
  postValidationApiStatus: inIdle,
  confirmValidationApiStatus: inIdle,
  removeValidationApiStatus: inIdle,
  removeLogoApiStatus: inIdle
}

/* eslint-disable no-param-reassign */
export const encryptionSlice = createSlice({
  name: 'ENCRYPTION',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // cnameValidation
      .addCase(cnameValidation.pending, state => {
        state.cnameValidationApiStatus = inProgress
      })
      .addCase(cnameValidation.fulfilled, (state, action) => {
        state.cnameValidationApiStatus = successResponse
      })
      .addCase(cnameValidation.rejected, (state, action) => {
        state.cnameValidationApiStatus = failedResponse(action.payload)
      })

      // postValidation
      .addCase(postValidation.pending, state => {
        state.postValidationApiStatus = inProgress
      })
      .addCase(postValidation.fulfilled, (state, action) => {
        state.postValidationApiStatus = successResponse
      })
      .addCase(postValidation.rejected, (state, action) => {
        state.postValidationApiStatus = failedResponse(action.payload)
      })

      // confirmValidation
      .addCase(confirmValidation.pending, state => {
        state.confirmValidationApiStatus = inProgress
      })
      .addCase(confirmValidation.fulfilled, (state, action) => {
        state.confirmValidationApiStatus = successResponse
      })
      .addCase(confirmValidation.rejected, (state, action) => {
        state.confirmValidationApiStatus = failedResponse(action.payload)
      })

      // removeValidation
      .addCase(removeValidation.pending, state => {
        state.removeValidationApiStatus = inProgress
      })
      .addCase(removeValidation.fulfilled, (state, action) => {
        state.removeValidationApiStatus = successResponse
      })
      .addCase(removeValidation.rejected, (state, action) => {
        state.removeValidationApiStatus = failedResponse(action.payload)
      })

      // removeLogo
      .addCase(removeLogo.pending, state => {
        state.removeLogoApiStatus = inProgress
      })
      .addCase(removeLogo.fulfilled, (state, action) => {
        state.removeLogoApiStatus = successResponse
      })
      .addCase(removeLogo.rejected, (state, action) => {
        state.removeLogoApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = encryptionSlice.actions

export { cnameValidation, confirmValidation, postValidation, removeLogo, removeValidation }

export default encryptionSlice.reducer
