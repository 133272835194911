import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getMessage, MstoreState, reset } from 'redux/features/mstore/mstoreSlice'
import { getAccountPermissions, SettingsState } from 'redux/features/settings/settingsSlice'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'

export interface State {
  accountPermissions: SettingsState['accountPermissions']
  message: MstoreState['message']
}

export type MessageDetailLogic = [State]

export const useMessageDetailLogic = (): MessageDetailLogic => {
  const { messageId, domainId } = useParams<{ messageId: string; domainId: string }>()
  const dispatch = useAppDispatch()
  const { accountPermissions, message, userId } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions,
    message: _store.mstore.message,
    userId: _store.auth.accessTokenObject?.userId
  }))
  const { hasRightToSkipSendUserAction } = useMessageLogRights()

  useEffect(() => {
    dispatch(reset())
    dispatch(getAccountPermissions())
  }, [dispatch])

  useEffect(() => {
    if (messageId && domainId && accountPermissions) {
      dispatch(
        getMessage({
          messageId,
          showImages: 0,
          headersOnly: 0,
          domainId,
          userId: hasRightToSkipSendUserAction ? undefined : userId
        })
      )
    }
  }, [dispatch, messageId, domainId, accountPermissions, hasRightToSkipSendUserAction, userId])

  return useMemo(
    () => [
      {
        accountPermissions,
        message
      }
    ],
    [accountPermissions, message]
  )
}
