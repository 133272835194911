import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataTableState, UpdateDataTable } from 'types/redux/dataTables/dataTables'
import reducerHandlers from '../reduceHandlers'

export const GRID_COLUMNS = {
  STATUS: 'status',
  TIME: 'time',
  FROM: 'from',
  TO: 'to',
  SUBJECT: 'subject',
  FILE_NAME: 'file_name',
  FILE_TYPE: 'file_type',
  REPORT: 'report'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.STATUS]: {
    sortable: false,
    orderIndex: 0,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.TIME]: {
    sortable: false,
    orderIndex: 1,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.FROM]: {
    sortable: false,
    orderIndex: 2,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.TO]: {
    sortable: false,
    orderIndex: 3,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.SUBJECT]: {
    sortable: false,
    orderIndex: 4,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.FILE_NAME]: {
    sortable: false,
    orderIndex: 5,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.FILE_TYPE]: {
    sortable: false,
    orderIndex: 6,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.REPORT]: {
    sortable: false,
    orderIndex: 7,
    show: true,
    resizable: true
  }
}

export const ITEMS_PER_PAGE = 50

// initialState
export const INITIAL_STATE: DataTableState = {
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: ''
}

/* eslint-disable no-param-reassign */
export const atpSlice = createSlice({
  name: 'DATATABLE/ATP',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) =>
        reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload }),
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  }
})
/* eslint-enable no-param-reassign */

export const { update, reset } = atpSlice.actions

export default atpSlice.reducer
