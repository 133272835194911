import { useSearchParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isSuccess } from 'redux/toolkit/api'
import { oauth2CreateSession } from 'redux/features/auth/authApiThunks'
import { resetTokenExchange, tokenExchange } from 'redux/features/oauth2/oauth2Slice'
import routesConfig from 'lib/routesConfig'
import { OAuth2TokenExchangeResponse } from 'redux/features/oauth2/oauth2ApiThunks'
import appConfig from 'config/appConfig'
import { usePostAuthCommands } from 'lib/postAuthCommands/usePostAuthCommands'

export const useOAuth2CallbackLogic = () => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const code = params.get('code')
  const error = params.get('error')
  const { commandFactory, commandHandler } = usePostAuthCommands()

  const {
    isTokenExchangeSuccess,
    isTokenExchangeFailed,
    tokenExchangeError,
    tokenExchangeResponse,
    isOAuth2CreateSessionSuccess,
    isOAuth2CreateSessionFailed
  } = useAppSelector(_store => ({
    isTokenExchangeSuccess: isSuccess(_store.oauth2.api.tokenExchangeApiStatus),
    isTokenExchangeFailed: isFailed(_store.oauth2.api.tokenExchangeApiStatus),
    tokenExchangeError: getErrorMessage(_store.oauth2.api.tokenExchangeApiStatus),
    tokenExchangeResponse: _store.oauth2.tokenExchangeResponse,
    isOAuth2CreateSessionSuccess: isSuccess(_store.auth.api.oauth2CreateSessionApiStatus),
    isOAuth2CreateSessionFailed: isFailed(_store.auth.api.oauth2CreateSessionApiStatus)
  }))

  const getFormActionUrl = useCallback((res: OAuth2TokenExchangeResponse) => {
    if (res.identity.isReactEnabled) {
      return `${res.idToken.azp}/internal/oauth2/session`
    }
    return `${res.idToken.azp}/oauth2/session`
  }, [])

  useEffect(
    () => () => {
      dispatch(resetTokenExchange())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (code) {
      dispatch(tokenExchange({ code }))
      return
    }
    if (error) {
      routesConfig.LOGIN.goto(undefined, false, `?error=${error}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isTokenExchangeSuccess && tokenExchangeResponse) {
      if (tokenExchangeResponse.idToken.azp === appConfig.GATEWAY_URL) {
        dispatch(oauth2CreateSession({ idTokenJwt: tokenExchangeResponse.idTokenJwt }))
        return
      }
      const wrap = document.createElement('div')
      wrap.style.display = 'none'
      const form = document.createElement('form')
      form.method = 'post'
      form.action = getFormActionUrl(tokenExchangeResponse)
      const inp = document.createElement('input')
      inp.name = 'id_token_jwt'
      inp.value = tokenExchangeResponse.idTokenJwt
      form.appendChild(inp)
      wrap.appendChild(form)
      document.body.appendChild(wrap)
      form.submit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenExchangeSuccess])

  useEffect(() => {
    if (isTokenExchangeFailed) {
      const errorCode = tokenExchangeError || 'oauth2.auth_code_flow.token_exchange_failed'
      routesConfig.LOGIN.goto(undefined, false, `?error=${errorCode}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenExchangeFailed])

  useEffect(() => {
    if (isOAuth2CreateSessionSuccess) {
      const command = commandFactory.create(tokenExchangeResponse?.idToken.metadata?.queryString || '')
      commandHandler.handle(command)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOAuth2CreateSessionSuccess])

  useEffect(() => {
    if (isOAuth2CreateSessionFailed) {
      routesConfig.LOGIN.goto(undefined, false, '?error=EGDIdentityNotFound')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOAuth2CreateSessionFailed])
}
