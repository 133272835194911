import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function MailServerTest(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        To test your mail server/Smart Host configuration, enter an email address for this domain and click <b>Send</b>.
        The system sends a message to the email address you specify. The From address in this email will be{' '}
        <b>Barracuda Networks Email Test</b>.
      </p>
      <br />
      <b>NOTE:</b> If your mail server requires TLS, this test will fail. Because this is only a simple SMTP test, the
      failure indicates the connection is working correctly.
    </div>
  )
}

export default MailServerTest
