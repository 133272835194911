import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, IpAddressEntry } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  setHasPageChanges,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import routesConfig from 'lib/routesConfig'

export interface State {
  // isSuccess: boolean
  hasPageChanges: boolean
  // isSaveInProgress: boolean
  ipExemption: IpAddressEntry[]
  trustedForwarders: IpAddressEntry[]
}

export interface EventHandlers {
  onAddIpPolicy: (item: IpAddressEntry) => void
  onRemoveIpPolicy: (id: string) => void
  onBulkEditIpPolicy: () => void
  onAddTrustedForwarder: (item: IpAddressEntry) => void
  onRemoveTrustedForwarder: (id: string) => void
  onBulkEditTrustedForwarder: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsIpAddressTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseIpPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.IP_POLICIES, AvailableSettings.TRUSTED_FORWARDERS]

export const useIpPoliciesLogic = (): UseIpPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const [isIpAddressTableDirty, setIsIpAddressTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      dispatch(setHasPageChanges(false))
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const ipExemption = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.ip_policies ? (JSON.parse(settings.ip_policies as string) as IpAddressEntry[]) : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const trustedForwarders = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.trusted_forwarders ? (JSON.parse(settings.trusted_forwarders as string) as IpAddressEntry[]) : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateData = useCallback(
    (exemptions: IpAddressEntry[], setting: AvailableSettings.IP_POLICIES | AvailableSettings.TRUSTED_FORWARDERS) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [setting]: exemptions }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [setting]: exemptions } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAddIpPolicy = useCallback(
    (item: IpAddressEntry) => {
      const exemptions = [...ipExemption, item]
      updateData(exemptions, AvailableSettings.IP_POLICIES)
    },
    [ipExemption, updateData]
  )

  const onRemoveIpPolicy = useCallback(
    (id: string) => {
      const exemptions = ipExemption.filter(entry => entry.id !== id)
      updateData(exemptions, AvailableSettings.IP_POLICIES)
    },
    [ipExemption, updateData]
  )

  const onBulkEditIpPolicy = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_IP_POLICIES_BULK_EDIT.goto()
  }, [])

  const onAddTrustedForwarder = useCallback(
    (item: IpAddressEntry) => {
      const exemptions = [...trustedForwarders, item]
      updateData(exemptions, AvailableSettings.TRUSTED_FORWARDERS)
    },
    [trustedForwarders, updateData]
  )

  const onRemoveTrustedForwarder = useCallback(
    (id: string) => {
      const exemptions = trustedForwarders.filter(entry => entry.id !== id)
      updateData(exemptions, AvailableSettings.TRUSTED_FORWARDERS)
    },
    [trustedForwarders, updateData]
  )

  const onBulkEditTrustedForwarder = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_TRUSTED_FORWARDERS_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        ipExemption,
        trustedForwarders,
        hasPageChanges: isIpAddressTableDirty
      },
      {
        onAddIpPolicy,
        onRemoveIpPolicy,
        onBulkEditIpPolicy,
        onAddTrustedForwarder,
        onRemoveTrustedForwarder,
        onBulkEditTrustedForwarder,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsIpAddressTableDirty
      }
    ],
    [
      ipExemption,
      trustedForwarders,
      isIpAddressTableDirty,
      onAddIpPolicy,
      onRemoveIpPolicy,
      onBulkEditIpPolicy,
      onAddTrustedForwarder,
      onRemoveTrustedForwarder,
      onBulkEditTrustedForwarder,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
