import { FocusEventHandler, useCallback, useEffect, useMemo, MouseEventHandler } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'

import { populateEmailData } from 'lib/emergencyInbox'
import { getLinkedAccounts } from 'redux/features/settings/settingsSlice'
import { postNewEmail, resetPostNewEmail } from 'redux/features/mstore/mstoreSlice'
import { ComposerAttachment } from 'redux/features/mstore/mstoreApiThunks'
import { useEmailContent } from 'components/libs/dialog/emailComposer/useEmailContent'
import { Field, Methods as FormMethods, useForm } from 'components/libs/dialog/emailComposer/useForm'
import { onFocusContentEditable, onMouseDownContentEditable } from 'lib/caret'

export interface EmailComposerLogicConfig {
  emailType: string
  onClose: () => void
}

export interface State {
  from: Field<string>
  to: Field<string>
  cc: Field<string>
  subject: Field<string>
  body: Field<string>
  attachments: Field<ComposerAttachment[]>
  isGetMessagePending: boolean
  isGetMessageSuccess: boolean
  availableFromEmails: string[]
  isPostNewEmailPending: boolean
  isNewEmail: boolean
  isFormValid: boolean
}

export interface EventHandlers {
  onAddAttachment: FormMethods['onAddAttachment']
  onCancel: () => void
  onChangeField: FormMethods['onChangeField']
  onDeleteAttachment: FormMethods['onDeleteAttachment']
  onFocusContentEditable: FocusEventHandler<HTMLDivElement>
  onMouseDownContentEditable: MouseEventHandler
  onSend: () => void
}

export type EmailComposerLogic = [State, EventHandlers]

export const useEmailComposerLogic = ({ emailType, onClose }: EmailComposerLogicConfig): EmailComposerLogic => {
  const dispatch = useAppDispatch()
  const {
    isGetMessagePending,
    isGetMessageSuccess,
    isPostNewEmailPending,
    isPostNewEmailSuccess,
    isPostNewEmailFailed,
    userId,
    message,
    linkedAccountsEmails
  } = useAppSelector(_store => ({
    isGetMessagePending: isPending(_store.mstore.getMessageApiStatus),
    isGetMessageSuccess: isSuccess(_store.mstore.getMessageApiStatus),
    isPostNewEmailPending: isPending(_store.mstore.postNewEmailApiStatus),
    isPostNewEmailSuccess: isSuccess(_store.mstore.postNewEmailApiStatus),
    isPostNewEmailFailed: isFailed(_store.mstore.postNewEmailApiStatus),
    postNewEmailError: getErrorMessage(_store.mstore.postNewEmailApiStatus),
    linkedAccountsEmails: _store.settings.linkedAccounts?.map(account => account.email) || [],
    userId: _store.auth.accessTokenObject?.userId || '',
    message: _store.mstore.message
  }))
  const availableFromEmails = useMemo(() => [userId, ...linkedAccountsEmails], [linkedAccountsEmails, userId])
  const emailContent = useEmailContent({ emailType, message })
  const composerData = useMemo(
    () => populateEmailData(emailType, emailContent, userId),
    [emailContent, emailType, userId]
  )
  const [
    { from, to, cc, subject, body, attachments },
    { getValues, isFormValid, onSetAttachments, onChangeField, onDeleteAttachment, onAddAttachment }
  ] = useForm({
    ...composerData,
    attachments: []
  })

  const onSend = useCallback(() => {
    if (!isFormValid()) {
      return
    }
    dispatch(
      postNewEmail({
        originalMessage: message,
        ...getValues()
      })
    )
  }, [dispatch, getValues, isFormValid, message])

  const onCancel = useCallback(() => onClose(), [onClose])

  useEffect(() => {
    if (!message?.attachments || emailContent.isNewEmail) {
      return
    }
    onSetAttachments(
      message.attachments.map(attachment => ({
        id: attachment.id,
        fileName: attachment.filename,
        binaryContent: null
      }))
    )
  }, [emailContent.isNewEmail, message, onSetAttachments])

  // Handle post new email success
  useEffect(() => {
    if (!isPostNewEmailSuccess) {
      return
    }
    dispatch(resetPostNewEmail())
    onClose()
  }, [dispatch, isPostNewEmailSuccess, onClose])

  // Handle post new email failure
  useEffect(() => {
    if (!isPostNewEmailFailed) {
      return
    }
    dispatch(resetPostNewEmail())
  }, [dispatch, isPostNewEmailFailed, onClose])

  // Load linked accounts
  useEffect(() => {
    dispatch(getLinkedAccounts())
  }, [dispatch])

  return useMemo(
    () => [
      {
        from,
        to,
        cc,
        subject,
        body,
        attachments,
        availableFromEmails,
        isPostNewEmailPending,
        isGetMessagePending,
        isGetMessageSuccess,
        isNewEmail: emailContent.isNewEmail,
        isFormValid: isFormValid()
      },
      {
        onFocusContentEditable,
        onMouseDownContentEditable,
        onAddAttachment,
        onCancel,
        onChangeField,
        onDeleteAttachment,
        onSend
      }
    ],
    [
      attachments,
      body,
      cc,
      emailContent.isNewEmail,
      from,
      isFormValid,
      isGetMessagePending,
      isGetMessageSuccess,
      isPostNewEmailPending,
      availableFromEmails,
      onAddAttachment,
      onCancel,
      onChangeField,
      onDeleteAttachment,
      onSend,
      subject,
      to
    ]
  )
}
