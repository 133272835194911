/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'

import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'

import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import microsoftLogo from 'assets/images/microsoft.png'

import styles from 'components/pages/login/loginStyles'
import { EventHandlers, State, UiState } from 'components/pages/login/useLoginLogic'
import { LoginViews } from 'components/pages/login/loginPageTypes'

const BASE_I18N_KEY = 'ess.login'

export type PasswordCardProps = {
  state: State
  eventHandlers: EventHandlers
}

export type PasswordViews = LoginViews.Password | LoginViews.AzureTemporaryPasscode

function PassowrdCard(props: PasswordCardProps) {
  const { state, eventHandlers } = props
  const classes = styles(bdsTheme)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const cardType: PasswordViews = state.cardType as any
  const titles: { [key in PasswordViews]: { title: string; subtitle: string } } = useMemo(
    () => ({
      [LoginViews.Password]: { title: 'title', subtitle: 'password_for' },
      [LoginViews.AzureTemporaryPasscode]: { title: 'azure_temporary_passcode_title', subtitle: 'signin_option_for' }
    }),
    []
  )
  const MsIcon = useCallback(() => <img className={classes.msLogo} alt="" src={microsoftLogo} />, [classes.msLogo])

  return useMemo(
    () => (
      <Card elevation={3} className={classes.card} data-testid="password-card">
        {(state.isLoading || state.uiState === UiState.INITIALIZING) && (
          <LinearProgress data-testid="linear-progress" />
        )}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage(titles[cardType].title)}</Typography>
            <Typography variant="body1">
              {formatMessage(titles[cardType].subtitle, { email: <b>{state.formValues.user_id}</b> })}
            </Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
            {state.topErrorDescription && (
              <Grid item xs={12}>
                <Alert severity="error" className={classes.topError}>
                  {state.topErrorDescription}
                </Alert>
              </Grid>
            )}
          </Box>
          <Box>
            <Grid container>
              {cardType === LoginViews.Password && (
                <>
                  <Grid item xs={12} className={classes.cardFormPasswordRow}>
                    <TextField
                      error={state.validatedLoginError}
                      fullWidth
                      name="password"
                      value={state.formValues.password}
                      onChange={eventHandlers.onInputChange}
                      size="medium"
                      label="Password"
                      type="password"
                      helperText={state.validatedLoginError ? formatMessage('invalid_email') : ''}
                      data-testid="password-input"
                      inputRef={ref => eventHandlers.setAutofocusRef(ref)}
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.cardFormLinkRow}>
                    <Link
                      className={`${classes.flexLink} ${state.isLoading ? 'disabled' : ''}`}
                      onClick={eventHandlers.onForgotPassword}
                      data-testid="forgot-password-link"
                    >
                      <Typography variant="body1">{formatMessage('forgot_password')}</Typography>
                    </Link>
                  </Grid>
                  <Grid item xs={12} className={classes.cardFormActionRow}>
                    <Button
                      className={classes.cardFormButton}
                      color="primary"
                      fullWidth
                      variant="contained"
                      disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                      onClick={eventHandlers.onLogin}
                      innerRef={state.loginButton}
                      data-testid="login-button"
                    >
                      {formatMessage('continue')}
                    </Button>
                  </Grid>
                </>
              )}

              {cardType === LoginViews.AzureTemporaryPasscode && (
                <Grid item xs={12} className={classes.cardFormActionRow}>
                  <Button
                    className={classes.cardMsLoginButton}
                    fullWidth
                    variant="outlined"
                    disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                    onClick={eventHandlers.auth0Login}
                    innerRef={state.loginButton}
                    data-testid="login-button"
                    startIcon={<MsIcon />}
                  >
                    {formatMessage('sign_in_with_microsoft')}
                  </Button>
                </Grid>
              )}

              {state.isTemporaryPasscodeEnabled && (
                <>
                  <Grid item xs={12} className={classes.cardFormLinkRow}>
                    <Grid container direction="row" className={classes.optionDivider}>
                      <Grid item className={classes.optionDividerLine} />
                      <Grid item className={classes.optionDividerText}>
                        {formatMessage('option_divider_text')}
                      </Grid>
                      <Grid item className={classes.optionDividerLine} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.cardFormActionRow}>
                    <Button
                      className={classes.cardFormButton}
                      fullWidth
                      variant="outlined"
                      disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                      onClick={eventHandlers.onTemporaryPasscode}
                    >
                      {formatMessage('email_a_temporary_passcode')}
                    </Button>
                  </Grid>
                </>
              )}

              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link onClick={() => eventHandlers.resetLoginState(true)} data-testid="different-user-link">
                  <Typography variant="body1">{formatMessage('different_account_message')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      MsIcon,
      cardType,
      classes.card,
      classes.cardFormActionRow,
      classes.cardFormButton,
      classes.cardFormLinkRow,
      classes.cardFormPasswordRow,
      classes.cardHeader,
      classes.cardLogo,
      classes.cardMsLoginButton,
      classes.cardWrapper,
      classes.flexLink,
      classes.optionDivider,
      classes.optionDividerLine,
      classes.optionDividerText,
      classes.topError,
      eventHandlers,
      formatMessage,
      state.formValues.password,
      state.formValues.user_id,
      state.isLoading,
      state.isTemporaryPasscodeEnabled,
      state.loginButton,
      state.topErrorDescription,
      state.topErrorMessage,
      state.uiState,
      state.validatedLoginError,
      titles
    ]
  )
}

export default PassowrdCard
/* eslint-enable jsx-a11y/anchor-is-valid */
