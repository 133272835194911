import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  changePassword,
  deleteLinkedAccount,
  deletePolicy,
  getAccountPermissions,
  getAccountSettings,
  getDomainSettings,
  getLinkedAccounts,
  getQuarantineNotification,
  getSenderPolicies,
  postLinkedAccount,
  postPolicy,
  putBulkEditPolicies,
  putQuarantineNotification,
  updateAccountSettings,
  updateDomainSettings,
  verifyLinkedAccount
} from 'redux/features/settings/settingsApiThunks'
import {
  AccountPermission,
  DEFAULT_SETTINGS_OBJECT,
  InboundSchedule,
  InboundScheduleUI,
  LinkedAccount,
  QuarantineNotificationUI,
  SenderPoliciesReport,
  SettingsObject
} from 'types/Settings'
import { convertScheduleToObj } from 'lib/convertSchedule'

export interface SettingsState {
  getSenderPoliciesApiStatus: ApiStatus
  postPolicyApiStatus: ApiStatus
  deletePolicyApiStatus: ApiStatus
  putBulkEditPoliciesApiStatus: ApiStatus
  getQuarantineNotificationApiStatus: ApiStatus
  putQuarantineNotificationApiStatus: ApiStatus
  changePasswordApiStatus: ApiStatus
  getLinkedAccountsApiStatus: ApiStatus
  postLinkedAccountApiStatus: ApiStatus
  verifyLinkedAccountApiStatus: ApiStatus
  deleteLinkedAccountApiStatus: ApiStatus
  getAccountPermissionsApiStatus: ApiStatus
  getAccountSettingsApiStatus: ApiStatus
  updateAccountSettingsApiStatus: ApiStatus
  getDomainSettingsApiStatus: ApiStatus
  updateDomainSettingsApiStatus: ApiStatus

  senderPolicies: SenderPoliciesReport | undefined
  quarantineNotification: QuarantineNotificationUI | undefined
  updatedSchedule: InboundScheduleUI | undefined
  changePasswordMessage: string | undefined
  linkedAccountAdded: LinkedAccount | undefined
  linkedAccounts: LinkedAccount[] | undefined
  accountPermissions: AccountPermission | undefined
  accountSettings: SettingsObject
  domainSettings: SettingsObject
  numberOfDomainsInConflict: number | undefined
  conflictSettings: SettingsObject
  customOutboundSchedule: InboundScheduleUI | undefined
  hasPageChanges: boolean
}

// initialState
export const INITIAL_STATE: SettingsState = {
  getSenderPoliciesApiStatus: inIdle,
  postPolicyApiStatus: inIdle,
  deletePolicyApiStatus: inIdle,
  putBulkEditPoliciesApiStatus: inIdle,
  getQuarantineNotificationApiStatus: inIdle,
  putQuarantineNotificationApiStatus: inIdle,
  changePasswordApiStatus: inIdle,
  getLinkedAccountsApiStatus: inIdle,
  postLinkedAccountApiStatus: inIdle,
  verifyLinkedAccountApiStatus: inIdle,
  deleteLinkedAccountApiStatus: inIdle,
  getAccountPermissionsApiStatus: inIdle,
  getAccountSettingsApiStatus: inIdle,
  updateAccountSettingsApiStatus: inIdle,
  getDomainSettingsApiStatus: inIdle,
  updateDomainSettingsApiStatus: inIdle,

  senderPolicies: undefined,
  quarantineNotification: undefined,
  updatedSchedule: undefined,
  changePasswordMessage: undefined,
  linkedAccountAdded: undefined,
  linkedAccounts: undefined,
  accountPermissions: undefined,
  accountSettings: DEFAULT_SETTINGS_OBJECT,
  domainSettings: DEFAULT_SETTINGS_OBJECT,
  numberOfDomainsInConflict: undefined,
  conflictSettings: {},
  hasPageChanges: false,
  customOutboundSchedule: undefined
}

/* eslint-disable no-param-reassign */
export const settingsSlice = createSlice({
  name: 'SETTINGS',
  initialState: INITIAL_STATE,
  reducers: {
    updateSchedule: (state: SettingsState, action: PayloadAction<InboundScheduleUI>) => {
      state.updatedSchedule = action.payload
    },
    setHasPageChanges: (state: SettingsState, action: PayloadAction<boolean>) => {
      state.hasPageChanges = action.payload
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetAccountPermissions: (state: SettingsState) => {
      state.getAccountPermissionsApiStatus = INITIAL_STATE.getAccountPermissionsApiStatus
      state.accountPermissions = INITIAL_STATE.accountPermissions
    },
    resetGetSettingsApiStatus: state => {
      state.getAccountSettingsApiStatus = INITIAL_STATE.getAccountSettingsApiStatus
      state.getDomainSettingsApiStatus = INITIAL_STATE.getDomainSettingsApiStatus
    },
    resetUpdateSettingsApiStatus: state => {
      state.updateAccountSettingsApiStatus = INITIAL_STATE.updateAccountSettingsApiStatus
      state.updateDomainSettingsApiStatus = INITIAL_STATE.updateDomainSettingsApiStatus
    },
    resetAccountAndDomainSettings: (state: SettingsState) => {
      state.accountSettings = DEFAULT_SETTINGS_OBJECT
      state.domainSettings = DEFAULT_SETTINGS_OBJECT
      state.getAccountSettingsApiStatus = INITIAL_STATE.getAccountSettingsApiStatus
      state.getDomainSettingsApiStatus = INITIAL_STATE.getDomainSettingsApiStatus
      state.updateAccountSettingsApiStatus = INITIAL_STATE.updateAccountSettingsApiStatus
      state.updateDomainSettingsApiStatus = INITIAL_STATE.updateDomainSettingsApiStatus
      state.hasPageChanges = INITIAL_STATE.hasPageChanges
    }
  },
  extraReducers: builder => {
    builder
      // getSenderPolicies
      .addCase(getSenderPolicies.pending, state => {
        state.getSenderPoliciesApiStatus = inProgress
      })
      .addCase(getSenderPolicies.fulfilled, (state, action) => {
        state.getSenderPoliciesApiStatus = successResponse
        state.senderPolicies = action.payload.data
      })
      .addCase(getSenderPolicies.rejected, (state, action) => {
        state.getSenderPoliciesApiStatus = failedResponse(action.payload)
      })

      // postPolicy
      .addCase(postPolicy.pending, state => {
        state.postPolicyApiStatus = inProgress
      })
      .addCase(postPolicy.fulfilled, (state, action) => {
        state.postPolicyApiStatus = successResponse
      })
      .addCase(postPolicy.rejected, (state, action) => {
        state.postPolicyApiStatus = failedResponse(action.payload)
      })

      // deletePolicy
      .addCase(deletePolicy.pending, state => {
        state.deletePolicyApiStatus = inProgress
      })
      .addCase(deletePolicy.fulfilled, state => {
        state.deletePolicyApiStatus = successResponse
      })
      .addCase(deletePolicy.rejected, (state, action) => {
        state.deletePolicyApiStatus = failedResponse(action.payload)
      })

      // putBulkEditPolicies
      .addCase(putBulkEditPolicies.pending, state => {
        state.putBulkEditPoliciesApiStatus = inProgress
      })
      .addCase(putBulkEditPolicies.fulfilled, state => {
        state.putBulkEditPoliciesApiStatus = successResponse
      })
      .addCase(putBulkEditPolicies.rejected, (state, action) => {
        state.putBulkEditPoliciesApiStatus = failedResponse(action.payload)
      })

      // getQuarantineNotification
      .addCase(getQuarantineNotification.pending, state => {
        state.getQuarantineNotificationApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(getQuarantineNotification.fulfilled, (state, action) => {
        state.getQuarantineNotificationApiStatus = successResponse

        const quarantineNotificationResult = action.payload.results?.[0]
        const customInboundSchedule = convertScheduleToObj(quarantineNotificationResult.customInboundSchedule)
        state.quarantineNotification = {
          inboundSchedule: quarantineNotificationResult.inboundSchedule,
          customInboundSchedule
        }

        state.updatedSchedule = customInboundSchedule
      })
      .addCase(getQuarantineNotification.rejected, (state, action) => {
        state.getQuarantineNotificationApiStatus = failedResponse(action.payload)
      })

      // putQuarantineNotification
      .addCase(putQuarantineNotification.pending, state => {
        state.putQuarantineNotificationApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(putQuarantineNotification.fulfilled, (state, action) => {
        state.putQuarantineNotificationApiStatus = successResponse

        const quarantineNotificationResult = action.payload.results?.[0]
        const customInboundSchedule = convertScheduleToObj(quarantineNotificationResult.customInboundSchedule)
        state.quarantineNotification = {
          inboundSchedule: quarantineNotificationResult.inboundSchedule,
          customInboundSchedule
        }

        state.updatedSchedule = customInboundSchedule
      })
      .addCase(putQuarantineNotification.rejected, (state, action) => {
        state.putQuarantineNotificationApiStatus = failedResponse(action.payload)
      })

      // changePassword
      .addCase(changePassword.pending, state => {
        state.changePasswordApiStatus = inProgress
      })
      .addCase(changePassword.fulfilled, state => {
        state.changePasswordApiStatus = successResponse
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePasswordApiStatus = failedResponse(action.payload)
      })

      // getLinkedAccounts
      .addCase(getLinkedAccounts.pending, state => {
        state.getLinkedAccountsApiStatus = inProgress
      })
      .addCase(getLinkedAccounts.fulfilled, (state, action) => {
        state.getLinkedAccountsApiStatus = successResponse
        state.linkedAccounts = action.payload.results
      })
      .addCase(getLinkedAccounts.rejected, (state, action) => {
        state.getLinkedAccountsApiStatus = failedResponse(action.payload)
      })

      // postLinkedAccount
      .addCase(postLinkedAccount.pending, state => {
        state.postLinkedAccountApiStatus = inProgress
      })
      .addCase(postLinkedAccount.fulfilled, (state, action) => {
        state.postLinkedAccountApiStatus = successResponse

        const account = action.payload.results[0]
        if (account.verificationPending) {
          state.linkedAccountAdded = account
        } else if (state.linkedAccounts) {
          state.linkedAccounts = action.payload.results
        }
      })
      .addCase(postLinkedAccount.rejected, (state, action) => {
        state.postLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // verifyLinkedAccount
      .addCase(verifyLinkedAccount.pending, state => {
        state.verifyLinkedAccountApiStatus = inProgress
      })
      .addCase(verifyLinkedAccount.fulfilled, state => {
        state.verifyLinkedAccountApiStatus = successResponse
      })
      .addCase(verifyLinkedAccount.rejected, (state, action) => {
        state.verifyLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // deleteLinkedAccount
      .addCase(deleteLinkedAccount.pending, state => {
        state.deleteLinkedAccountApiStatus = inProgress
      })
      .addCase(deleteLinkedAccount.fulfilled, (state, action) => {
        state.deleteLinkedAccountApiStatus = successResponse

        if (state.linkedAccounts) {
          state.linkedAccounts = state.linkedAccounts.filter(value => value.id !== action.payload[0].id)
        }
      })
      .addCase(deleteLinkedAccount.rejected, (state, action) => {
        state.deleteLinkedAccountApiStatus = failedResponse(action.payload)
      })

      // getAccountPermissions
      .addCase(getAccountPermissions.pending, state => {
        state.getAccountPermissionsApiStatus = inProgress
      })
      .addCase(getAccountPermissions.fulfilled, (state, action) => {
        state.getAccountPermissionsApiStatus = successResponse
        // eslint-disable-next-line prefer-destructuring
        state.accountPermissions = action.payload.results[0]
      })
      .addCase(getAccountPermissions.rejected, (state, action) => {
        state.getAccountPermissionsApiStatus = failedResponse(action.payload)
      })

      // getAccountSettings
      .addCase(getAccountSettings.pending, state => {
        state.getAccountSettingsApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(getAccountSettings.fulfilled, (state, action) => {
        state.getAccountSettingsApiStatus = successResponse
        state.accountSettings = action.payload
        if (action.payload.custom_outbound_quarantine_interval) {
          state.customOutboundSchedule = convertScheduleToObj(
            action.payload.custom_outbound_quarantine_interval as InboundSchedule
          )
        }
      })
      .addCase(getAccountSettings.rejected, (state, action) => {
        state.getAccountSettingsApiStatus = failedResponse(action.payload)
      })

      // updateAccountSettings
      .addCase(updateAccountSettings.pending, state => {
        state.updateAccountSettingsApiStatus = inProgress
        state.numberOfDomainsInConflict = undefined
        state.conflictSettings = {}
      })
      .addCase(updateAccountSettings.fulfilled, state => {
        state.updateAccountSettingsApiStatus = successResponse
      })
      .addCase(updateAccountSettings.rejected, (state, action) => {
        if (typeof action.payload === 'string') {
          state.updateAccountSettingsApiStatus = failedResponse(action.payload)
        } else if (action.payload?.number_of_domains_in_conflict) {
          state.numberOfDomainsInConflict = action.payload.number_of_domains_in_conflict
          state.conflictSettings = action.meta.arg.settings
          state.updateAccountSettingsApiStatus = failedResponse(action.payload.message)
        }
      })

      // getDomainSettings
      .addCase(getDomainSettings.pending, state => {
        state.getDomainSettingsApiStatus = inProgress
        state.updatedSchedule = INITIAL_STATE.updatedSchedule
      })
      .addCase(getDomainSettings.fulfilled, (state, action) => {
        state.getDomainSettingsApiStatus = successResponse
        state.domainSettings = action.payload
        if (action.payload.custom_outbound_quarantine_interval) {
          state.customOutboundSchedule = convertScheduleToObj(
            action.payload.custom_outbound_quarantine_interval as InboundSchedule
          )
        }
      })
      .addCase(getDomainSettings.rejected, (state, action) => {
        state.getDomainSettingsApiStatus = failedResponse(action.payload)
      })

      // updateDomainSettings
      .addCase(updateDomainSettings.pending, state => {
        state.updateDomainSettingsApiStatus = inProgress
      })
      .addCase(updateDomainSettings.fulfilled, state => {
        state.updateDomainSettingsApiStatus = successResponse
      })
      .addCase(updateDomainSettings.rejected, (state, action) => {
        state.updateDomainSettingsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  updateSchedule,
  setHasPageChanges,
  reset,
  resetAccountPermissions,
  resetGetSettingsApiStatus,
  resetUpdateSettingsApiStatus,
  resetAccountAndDomainSettings
} = settingsSlice.actions

export {
  getSenderPolicies,
  postPolicy,
  deletePolicy,
  putBulkEditPolicies,
  getQuarantineNotification,
  putQuarantineNotification,
  changePassword,
  getLinkedAccounts,
  postLinkedAccount,
  verifyLinkedAccount,
  deleteLinkedAccount,
  getAccountPermissions,
  getAccountSettings,
  updateAccountSettings,
  getDomainSettings,
  updateDomainSettings
}

export default settingsSlice.reducer
