import { useMemo, useEffect, useState, useCallback, Dispatch, SetStateAction, ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, RecipientPolicy, RecipientPolicyStatus } from 'types/Settings'
import routesConfig from 'lib/routesConfig'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'

import { isSuccess } from 'redux/toolkit/api'

export interface State {
  defaultPolicy: RecipientPolicyStatus
  data: RecipientPolicy[]
  isDisabledNavigation: boolean
  isDisabledSubmit: boolean
}

export interface EventHandlers {
  onSave: () => void
  onAdd: (item: RecipientPolicy) => void
  onRemove: (id: string) => void
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onBulkEdit: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsPoliciesTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseRecipientPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.USER_POLICIES, AvailableSettings.DEFAULT_SCAN_POLICY]

export const enum RECIPIENT_SELECT_NAMES {
  POLICY = 'policy',
  DEFAULT_POLICY = 'defaultPolicy'
}
export const enum RECIPIENT_INPUT_NAMES {
  NAME = 'name',
  COMMENT = 'comment'
}

export const useRecipientPoliciesLogic = (): UseRecipientPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [defaultPolicy, setDefaultPolicy] = useState<RecipientPolicyStatus>(RecipientPolicyStatus.scan)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus)
  }))

  const [{ isDirty }, resetInitialForm] = useDirtyFormObjectCheck({ defaultPolicy })
  const [isPoliciesTableDirty, setIsPoliciesTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const resetForm = useCallback(() => {
    let nextValue: RecipientPolicyStatus | undefined
    if (accessTokenObject?.pdDomainId && domainSettings.default_scan_policy) {
      nextValue = domainSettings.default_scan_policy as RecipientPolicyStatus
    } else if (accountSettings.default_scan_policy) {
      nextValue = accountSettings.default_scan_policy as RecipientPolicyStatus
    }
    if (nextValue) {
      resetInitialForm({ defaultPolicy: nextValue })
      setDefaultPolicy(nextValue)
    }
  }, [
    accessTokenObject?.pdDomainId,
    accountSettings.default_scan_policy,
    domainSettings.default_scan_policy,
    resetInitialForm
  ])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  useEffect(() => {
    if (isGetAccountSettingsSuccess || isGetDomainSettingsSuccess) {
      resetForm()
    }
    // eslint-disable-next-line
  }, [isGetAccountSettingsSuccess, isGetDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const data = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.user_policies ? (JSON.parse(settings.user_policies as string) as RecipientPolicy[]) : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateData = useCallback(
    (policies: RecipientPolicy[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.USER_POLICIES]: policies }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.USER_POLICIES]: policies } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(
    (item: RecipientPolicy) => {
      const policies = [...data, item]
      updateData(policies)
    },
    [data, updateData]
  )

  const onRemove = useCallback(
    (id: string) => {
      const policies = data.filter(entry => entry.id !== id)
      updateData(policies)
    },
    [data, updateData]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES_BULK_EDIT.goto()
  }, [])

  const onSave = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: { [AvailableSettings.DEFAULT_SCAN_POLICY]: defaultPolicy }
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: { [AvailableSettings.DEFAULT_SCAN_POLICY]: defaultPolicy } }))
    }
  }, [dispatch, accessTokenObject, defaultPolicy])

  const handleOnSelectChange = useCallback((e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { value } = e.target
    setDefaultPolicy(value as RecipientPolicyStatus)
  }, [])

  return useMemo(
    () => [
      {
        data,
        defaultPolicy,
        isDisabledSubmit: !isDirty,
        isDisabledNavigation: isDirty || isPoliciesTableDirty
      },
      {
        onSave,
        onAdd,
        onRemove,
        handleOnSelectChange,
        onBulkEdit,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsPoliciesTableDirty
      }
    ],
    [
      data,
      defaultPolicy,
      isDirty,
      isPoliciesTableDirty,
      setIsPoliciesTableDirty,
      onSave,
      onAdd,
      onRemove,
      handleOnSelectChange,
      onBulkEdit,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
