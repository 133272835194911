import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(2),
    minWidth: 110,
    marginRight: theme.spacing(2)
  },
  colorBox: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    marginRight: theme.spacing(0.5)
  },
  count: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '130%',
    color: '#191919'
  },
  text: {
    fontSize: 12,
    lineHeight: '125%',
    color: '#191919'
  }
}))
