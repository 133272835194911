import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import { DomainInfo, LoginResponse, User, UserStates } from 'types/auth'

import {
  auth0Login,
  autoLogin,
  azureSSOLogin,
  bccLogin,
  ENFORCE_MANUAL_LOGIN,
  getDomainInfo,
  getLoginSettings,
  getSSOSetings,
  login,
  logout,
  requestLoginInfo,
  RequestLoginInfoPayload,
  resetAll,
  resetPassword,
  switchAccount,
  SwitchAccount,
  switchToOldUi,
  temporaryLogin,
  temporaryPasscode,
  updateUserStatesAction,
  validateAccessToken,
  validateSessionId,
  oauth2CreateSession,
  Login
} from 'redux/features/auth/authApiThunks'
import { manageDomain } from 'redux/features/domains/domainsApiThunks'
import { LoginSettings, SSOSettingsResponse } from 'types/login'

export interface AuthState {
  api: {
    getLoginSettingsApiStatus: ApiStatus
    switchAccountApiStatus: ApiStatus
    oauth2CreateSessionApiStatus: ApiStatus
  }
  validateAccessTokenApiStatus: ApiStatus
  loginApiStatus: ApiStatus
  validateSessionIdApiStatus: ApiStatus
  logoutApiStatus: ApiStatus
  resetPasswordApiStatus: ApiStatus
  temporaryPasscodeApiStatus: ApiStatus
  requestLoginInfoApiStatus: ApiStatus
  domainInfoApiStatus: ApiStatus
  requestLoginSettingsApiStatus: ApiStatus
  switchToOldUiApiStatus: ApiStatus

  accessToken?: LoginResponse['accessToken']
  session?: LoginResponse
  accessTokenObject?: User
  accessTokenExpires?: number
  enforceManualLogin: boolean
  requestLoginInfoOrigin?: RequestLoginInfoPayload['origin']
  loginSettings?: LoginSettings
  domainInfo?: DomainInfo
  isValidatedSession: boolean
  ssoSettings?: SSOSettingsResponse
  userStates?: UserStates
  switchAccount?: SwitchAccount
  oauth2CreateSessionResponse: Login | undefined
}

// initialState
export const INITIAL_STATE: AuthState = {
  api: {
    getLoginSettingsApiStatus: inIdle,
    switchAccountApiStatus: inIdle,
    oauth2CreateSessionApiStatus: inIdle
  },
  validateAccessTokenApiStatus: inIdle,
  validateSessionIdApiStatus: inIdle,
  loginApiStatus: inIdle,
  logoutApiStatus: inIdle,
  resetPasswordApiStatus: inIdle,
  temporaryPasscodeApiStatus: inIdle,
  requestLoginInfoApiStatus: inIdle,
  domainInfoApiStatus: inIdle,
  requestLoginSettingsApiStatus: inIdle,
  switchToOldUiApiStatus: inIdle,

  accessToken: undefined,
  session: undefined,
  accessTokenObject: undefined,
  accessTokenExpires: undefined,
  enforceManualLogin: false,
  requestLoginInfoOrigin: undefined,
  domainInfo: undefined,
  loginSettings: undefined,
  isValidatedSession: false,
  ssoSettings: undefined,
  userStates: undefined,
  switchAccount: undefined,
  oauth2CreateSessionResponse: undefined
}

function isLoginPendingAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, auth0Login, temporaryLogin, oauth2CreateSession].some(apiThunk =>
    apiThunk.pending.match(action)
  )
}

function isLoginFulfilledAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, auth0Login, temporaryLogin, oauth2CreateSession].some(apiThunk =>
    apiThunk.fulfilled.match(action)
  )
}

function isLoginRejectedAction(action: AnyAction) {
  return [login, autoLogin, azureSSOLogin, bccLogin, auth0Login, temporaryLogin, oauth2CreateSession].some(apiThunk =>
    apiThunk.rejected.match(action)
  )
}

/* eslint-disable no-param-reassign */
export const authSlice = createSlice({
  name: 'AUTH',
  initialState: INITIAL_STATE,
  reducers: {
    setAccessToken: (state, action: PayloadAction<AuthState['accessToken']>) => {
      state.accessToken = action.payload
    },
    resetLogin: (state: AuthState) => {
      state.loginApiStatus = INITIAL_STATE.loginApiStatus
      state.temporaryPasscodeApiStatus = INITIAL_STATE.temporaryPasscodeApiStatus
    },
    resetLoginSettings: (state: AuthState) => {
      state.userStates = INITIAL_STATE.userStates
      state.loginSettings = INITIAL_STATE.loginSettings
      state.requestLoginSettingsApiStatus = INITIAL_STATE.requestLoginSettingsApiStatus
    },
    resetSSOSettings: (state: AuthState) => {
      state.ssoSettings = INITIAL_STATE.ssoSettings
      state.api.getLoginSettingsApiStatus = INITIAL_STATE.api.getLoginSettingsApiStatus
    },
    resetDomainInfo: (state: AuthState) => {
      state.domainInfoApiStatus = INITIAL_STATE.domainInfoApiStatus
      state.domainInfo = INITIAL_STATE.domainInfo
    },
    resetLogout: (state: AuthState) => {
      state.logoutApiStatus = INITIAL_STATE.logoutApiStatus
    },
    resetSwitchAccount: (state: AuthState) => {
      state.api.switchAccountApiStatus = INITIAL_STATE.api.switchAccountApiStatus
      state.switchAccount = INITIAL_STATE.switchAccount
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetOauth2CreateSession: state => {
      state.api.oauth2CreateSessionApiStatus = INITIAL_STATE.api.oauth2CreateSessionApiStatus
      state.oauth2CreateSessionResponse = INITIAL_STATE.oauth2CreateSessionResponse
    }
  },
  extraReducers: builder => {
    builder
      // updateUserStatesAction
      .addCase(updateUserStatesAction, (state, action) => {
        state.userStates = action.payload
        state.loginApiStatus = inIdle
      })

      // domains.manageDomain
      .addCase(manageDomain.fulfilled, (state, action) => {
        if (state.accessTokenObject) {
          state.accessTokenObject.pdDomainId = action.payload.pdDomainId
          state.accessTokenObject.pdDomainName = action.payload.pdDomainName
        }
      })

      // domain info
      .addCase(getDomainInfo.pending, state => {
        state.domainInfoApiStatus = inProgress
      })
      .addCase(getDomainInfo.fulfilled, (state, action) => {
        state.domainInfoApiStatus = successResponse
        state.domainInfo = action.payload
      })
      .addCase(getDomainInfo.rejected, (state, action) => {
        state.domainInfoApiStatus = failedResponse(action.payload)
      })

      // validateSessionId
      .addCase(validateSessionId.pending, state => {
        state.validateSessionIdApiStatus = inProgress
      })
      .addCase(validateSessionId.fulfilled, (state, action) => {
        state.validateSessionIdApiStatus = successResponse
        if (action.payload) {
          state.accessToken = action.payload.accessToken
          state.session = action.payload.session
          state.accessTokenObject = action.payload.accessTokenObject
          state.accessTokenExpires = action.payload.accessTokenExpires
          state.isValidatedSession = true
        }
      })
      .addCase(validateSessionId.rejected, (state, action) => {
        state.validateSessionIdApiStatus = failedResponse(action.payload)
      })

      // logout
      .addCase(logout.pending, state => {
        state.logoutApiStatus = inProgress
      })
      .addCase(logout.fulfilled, state => {
        state.logoutApiStatus = successResponse
      })
      .addCase(logout.rejected, (state, action) => {
        state.logoutApiStatus = failedResponse(action.payload)
      })

      // reset password
      .addCase(resetPassword.pending, state => {
        state.resetPasswordApiStatus = inProgress
      })
      .addCase(resetPassword.fulfilled, state => {
        state.resetPasswordApiStatus = successResponse
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordApiStatus = failedResponse(action.payload)
      })

      // request login info
      .addCase(requestLoginInfo.pending, state => {
        state.requestLoginInfoApiStatus = inProgress
        state.requestLoginInfoOrigin = undefined
      })
      .addCase(requestLoginInfo.fulfilled, (state, action) => {
        state.requestLoginInfoApiStatus = successResponse
        state.requestLoginInfoOrigin = action.meta.arg.origin
      })
      .addCase(requestLoginInfo.rejected, (state, action) => {
        state.requestLoginInfoApiStatus = failedResponse(action.payload)
        state.requestLoginInfoOrigin = action.meta.arg.origin
      })

      // temporary password
      .addCase(temporaryPasscode.pending, state => {
        state.temporaryPasscodeApiStatus = inProgress
      })
      .addCase(temporaryPasscode.fulfilled, state => {
        state.temporaryPasscodeApiStatus = successResponse
      })
      .addCase(temporaryPasscode.rejected, (state, action) => {
        state.temporaryPasscodeApiStatus = failedResponse(action.payload)
      })

      // login settings
      .addCase(getLoginSettings.pending, state => {
        state.requestLoginSettingsApiStatus = inProgress
      })
      .addCase(getLoginSettings.fulfilled, (state, action) => {
        state.requestLoginSettingsApiStatus = successResponse
        state.loginSettings = action.payload
      })
      .addCase(getLoginSettings.rejected, (state, action) => {
        state.requestLoginSettingsApiStatus = failedResponse(action.payload)
      })

      // SSO settings
      .addCase(getSSOSetings.pending, state => {
        state.api.getLoginSettingsApiStatus = inProgress
      })
      .addCase(getSSOSetings.fulfilled, (state, action) => {
        state.api.getLoginSettingsApiStatus = successResponse
        state.ssoSettings = action.payload
      })
      .addCase(getSSOSetings.rejected, (state, action) => {
        state.api.getLoginSettingsApiStatus = failedResponse(action.payload)
      })

      // validateAccessToken
      .addCase(validateAccessToken.pending, state => {
        state.validateAccessTokenApiStatus = inProgress
      })
      .addCase(validateAccessToken.fulfilled, (state, action) => {
        state.validateAccessTokenApiStatus = successResponse
        state.accessTokenObject = action.payload
      })
      .addCase(validateAccessToken.rejected, (state, action) => {
        state.validateAccessTokenApiStatus = failedResponse(action.payload)
      })

      // switch-account
      .addCase(switchAccount.pending, state => {
        state.api.switchAccountApiStatus = inProgress
        state.switchAccount = INITIAL_STATE.switchAccount
      })
      .addCase(switchAccount.fulfilled, (state, action) => {
        state.api.switchAccountApiStatus = successResponse
        state.switchAccount = action.payload || undefined
      })
      .addCase(switchAccount.rejected, (state, action) => {
        state.api.switchAccountApiStatus = failedResponse(action.payload)
      })

      // switch to old UI
      .addCase(switchToOldUi.pending, state => {
        state.switchToOldUiApiStatus = inProgress
      })
      .addCase(switchToOldUi.fulfilled, state => {
        state.switchToOldUiApiStatus = successResponse
      })
      .addCase(switchToOldUi.rejected, (state, action) => {
        state.switchToOldUiApiStatus = failedResponse(action.payload)
      })

      // oauth2CreateSession
      .addCase(oauth2CreateSession.pending, state => {
        state.api.oauth2CreateSessionApiStatus = inProgress
      })
      .addCase(oauth2CreateSession.fulfilled, (state, action) => {
        state.api.oauth2CreateSessionApiStatus = successResponse
        state.oauth2CreateSessionResponse = action.payload
      })
      .addCase(oauth2CreateSession.rejected, (state, action) => {
        state.api.oauth2CreateSessionApiStatus = failedResponse(action.payload)
      })

      // login
      .addMatcher(isLoginPendingAction, state => {
        state.loginApiStatus = inProgress
      })
      .addMatcher(isLoginFulfilledAction, (state, action) => {
        state.loginApiStatus = successResponse
        state.accessToken = action.payload.accessToken
        state.session = action.payload.session
        state.accessTokenObject = action.payload.accessTokenObject
        state.accessTokenExpires = action.payload.accessTokenExpires
        state.isValidatedSession = true
      })
      .addMatcher(isLoginRejectedAction, (state, action) => {
        if (action.payload === ENFORCE_MANUAL_LOGIN) {
          state.enforceManualLogin = true
        }
        try {
          if (
            temporaryLogin.rejected.match(action) &&
            action.payload &&
            JSON.parse(action.payload).statusCode === 403
          ) {
            state.loginApiStatus = failedResponse(
              JSON.stringify({ ...JSON.parse(action.payload), temporaryFeatureDisabled: true })
            )
          } else {
            state.loginApiStatus = failedResponse(action.payload)
          }
        } catch {
          state.loginApiStatus = failedResponse(action.payload)
        }
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setAccessToken,
  resetLogin,
  resetLoginSettings,
  resetSSOSettings,
  resetDomainInfo,
  resetSwitchAccount,
  resetLogout,
  reset,
  resetOauth2CreateSession
} = authSlice.actions

export {
  getDomainInfo,
  login,
  temporaryLogin,
  autoLogin,
  azureSSOLogin,
  bccLogin,
  auth0Login,
  validateSessionId,
  logout,
  resetPassword,
  requestLoginInfo,
  temporaryPasscode,
  getLoginSettings,
  getSSOSetings,
  validateAccessToken,
  switchAccount,
  resetAll,
  switchToOldUi,
  oauth2CreateSession
}

export default authSlice.reducer
