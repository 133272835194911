import { useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { EmailLogApiClient } from 'components/pages/emailLog/emailLogApiClient'

export const useEmailLogApiClient = () => {
  const { accessToken } = useAppSelector(_stores => ({
    accessToken: _stores.auth.accessToken || ''
  }))

  return useMemo(() => EmailLogApiClient({ accessToken }), [accessToken])
}
