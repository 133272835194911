import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import useHandleQNDigestLinks from 'lib/useHandleQNDigestLinks'
import Loading from 'components/libs/loading/Loading'
import useLogout from 'lib/useLogout'

export default function QNDigestLinksHandler() {
  const [logout] = useLogout()
  const [handleQnDigestLink] = useHandleQNDigestLinks()
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get('return_url') || undefined
  const url = searchParams.get('url') || undefined

  // init
  useEffect(() => {
    if (!handleQnDigestLink({ redirectUrl, url })) {
      logout(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Loading />
}
