import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  login: {
    backgroundColor: theme.palette.info.main,
    height: '100%'
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: colors.white,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  snackBar: {
    marginBottom: 32,
    borderRadius: 32,
    boxShadow: `5px 10px 18px ${colors.scaleGray200}`
  }
}))
