import React, { useCallback, useMemo } from 'react'
import { Grid } from '@barracuda-internal/bds-core'
import { CategoryTypes } from 'types/categoryTypes'

import { EventHandlers, State } from 'components/pages/overview/dashboard/emailStatisticsCharts/EmailStatisticsChart'
import { useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending } from 'redux/toolkit/api'
import { useFormatMessage } from 'lib/localization'
import { Action, Direction } from 'types/Messages'
import { StatComponentProps } from 'types/stats'
import {
  ChartAxisBottomValues,
  ChartColorCodes,
  ChartColors,
  ChartTotalValueKeys,
  ChartTotalValues,
  useEmailStatisticsChartLogic
} from 'components/pages/overview/dashboard/emailStatisticsCharts/useEmailStatisticsChartLogic'
import outboundChartDemoData from 'components/pages/overview/dashboard/emailStatisticsCharts/outboundChartDemoData'
import PieChartWithTable, {
  PieChartWithTableProps
} from 'components/pages/overview/dashboard/pieChartWithTable/PieChartWithTable'
import { TOOLTIP_CHART_HEIGHT } from 'components/pages/overview/dashboard/dashboardStyles'

export type UseOutboundEmailStatisticsLogic = [State, EventHandlers]
const BASE_I18N_KEY = 'ess.overview.dashboard.charts'

const CHART_AXIS_BOTTOM_VALUES: ChartAxisBottomValues = {
  [Action.none]: [CategoryTypes.allowed, CategoryTypes.blocked, CategoryTypes.quarantined],
  [Action.blocked]: [CategoryTypes.blockedSpam, CategoryTypes.blockedAv],
  [Action.allowed]: [CategoryTypes.allowed, CategoryTypes.encrypted],
  [Action.quarantined]: [CategoryTypes.quarantined]
}

const CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES = {
  [CategoryTypes.allowed]: ChartTotalValueKeys.sent
}

const CHART_TOTAL_VALUES: ChartTotalValues = {
  [Action.none]: [
    [ChartTotalValueKeys.sent, [CategoryTypes.allowed]],
    [ChartTotalValueKeys.blocked, [CategoryTypes.blocked]],
    [ChartTotalValueKeys.quarantined, [CategoryTypes.quarantined]]
  ],
  [Action.blocked]: [
    [ChartTotalValueKeys.total, [CategoryTypes.blockedSpam, CategoryTypes.blockedAtd, CategoryTypes.blockedAv]],
    [ChartTotalValueKeys.spam, [CategoryTypes.blockedSpam]],
    [ChartTotalValueKeys.virus, [CategoryTypes.blockedAv, CategoryTypes.blockedAtd]]
  ],
  [Action.allowed]: [
    [ChartTotalValueKeys.total_sent, [CategoryTypes.allowed, CategoryTypes.encrypted]],
    [ChartTotalValueKeys.allowed, [CategoryTypes.allowed]],
    [ChartTotalValueKeys.encrypted, [CategoryTypes.encrypted]]
  ],
  [Action.quarantined]: [[ChartTotalValueKeys.quarantined, [CategoryTypes.quarantined]]]
}

const CHART_COLORS: ChartColors = {
  [Action.none]: [ChartColorCodes.allowed, ChartColorCodes.blocked, ChartColorCodes.quarantined],
  [Action.blocked]: [ChartColorCodes.spam, ChartColorCodes.virus],
  [Action.allowed]: [ChartColorCodes.allowed, ChartColorCodes.encrypted],
  [Action.quarantined]: [ChartColorCodes.quarantined]
}

export const useOutboundEmailStatisticsLogic = (props: StatComponentProps): UseOutboundEmailStatisticsLogic => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const { outboundEmailStatistics, outboundEmailStatisticsInProgress, outboundEmailStatisticsFailed } = useAppSelector(
    _store => ({
      outboundEmailStatistics: _store.stats.outboundEmailStatistics,
      outboundEmailStatisticsInProgress: isPending(_store.stats.api.getOutboundEmailStatisticsApiStatus),
      outboundEmailStatisticsFailed: isFailed(_store.stats.api.getOutboundEmailStatisticsApiStatus)
    })
  )
  const [chartConfig, selectedFilter, onSelectFilter] = useEmailStatisticsChartLogic({
    direction: Direction.OUTBOUND,
    emailStatisticsData: outboundEmailStatistics,
    chartAxisBottomValues: CHART_AXIS_BOTTOM_VALUES,
    chartAxisAlternativeCategoryNames: CHART_AXIS_ALTERNATIVE_CATEGORY_NAMES,
    chartTotalValues: CHART_TOTAL_VALUES,
    chartColors: CHART_COLORS,
    demoData: outboundChartDemoData,
    domainId: props.domainId,
    range: props.range
  })

  const chartTooltip: State['chartTooltip'] = useCallback(
    (xAxis: string) => {
      const statData = outboundEmailStatistics?.results?.find(stat => stat.date === xAxis)
      if (!statData) {
        return null
      }

      const total = statData.actionCategories.allowed
      const totalEncrypted = statData.extra
      const sentPercentage = Math.round((100 * total - totalEncrypted) / total) || 0

      const pieChartWithTableConfig: PieChartWithTableProps = {
        chartConfig: [
          { id: formatMessage('sent'), value: total },
          { id: formatMessage('encrypted'), value: totalEncrypted }
        ],
        tableConfig: [
          {
            text: formatMessage('sent'),
            color: ChartColorCodes.allowed,
            value: total,
            percentage: sentPercentage
          },
          {
            text: formatMessage('encrypted'),
            color: ChartColorCodes.encrypted,
            value: totalEncrypted,
            percentage: sentPercentage ? 100 - sentPercentage : 0
          },
          {
            text: formatMessage('blocked'),
            value: statData.actionCategories.blocked
          },
          {
            text: formatMessage('quarantined'),
            value: statData.actionCategories.quarantined
          }
        ],
        pieColors: [ChartColorCodes.allowed, ChartColorCodes.encrypted],
        customTotalTitle: formatMessage('sent'),
        total
      }

      return (
        <Grid data-testid="tooltip-container" container style={{ height: TOOLTIP_CHART_HEIGHT }}>
          <PieChartWithTable {...pieChartWithTableConfig} isTooltip />
        </Grid>
      )
    },
    [outboundEmailStatistics, formatMessage]
  )

  return useMemo(
    () => [
      {
        chartConfig,
        inProgress: outboundEmailStatisticsInProgress,
        isFailed: outboundEmailStatisticsFailed,
        selectedFilter,
        chartTooltip
      },
      {
        onSelectFilter
      }
    ],
    [
      chartConfig,
      outboundEmailStatisticsInProgress,
      outboundEmailStatisticsFailed,
      selectedFilter,
      onSelectFilter,
      chartTooltip
    ]
  )
}
