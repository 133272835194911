import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'

import { postFpfnReport } from 'redux/features/fpfn/fpfnApiThunks'

export interface FpFnState {
  redirectionUrlApiStatus: ApiStatus
  redirectionURL: string | undefined
}

// initialState
export const INITIAL_STATE: FpFnState = {
  redirectionUrlApiStatus: inIdle,
  redirectionURL: undefined
}

/* eslint-disable no-param-reassign */
export const fpfnSlice = createSlice({
  name: 'FPFN',
  initialState: INITIAL_STATE,
  reducers: {
    resetFpfnRedirectionUrl: state => {
      state.redirectionUrlApiStatus = INITIAL_STATE.redirectionUrlApiStatus
      state.redirectionURL = INITIAL_STATE.redirectionURL
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // postFpFnReport
      .addCase(postFpfnReport.pending, state => {
        state.redirectionUrlApiStatus = inProgress
      })
      .addCase(postFpfnReport.fulfilled, (state, action) => {
        state.redirectionUrlApiStatus = successResponse
        state.redirectionURL = action.payload[0].redirectUrl
      })
      .addCase(postFpfnReport.rejected, (state, action) => {
        state.redirectionUrlApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetFpfnRedirectionUrl, reset } = fpfnSlice.actions

export { postFpfnReport }

export default fpfnSlice.reducer
