import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { EmailServer, GetDomainExtraField } from 'types/domains'
import {
  addMailServer,
  removeMailServer,
  resetAddEmailServer,
  resetRemoveEmailServer,
  resetTestEmailServer,
  resetUpdateEmailServer,
  testMailServer,
  updateMailServer
} from 'redux/features/emailServer/emailServerSlice'
import { EmailServerType } from 'types/emailServer'
import { isSuccess, isPending, isFailed } from 'redux/toolkit/api'
import { getDomain } from 'redux/features/domains/domainsSlice'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { DomainsTabRights, useDomainsTabRights } from 'components/pages/domains/useDomainsTabRights'

export const DEFAULT_PREFERENCE_VALUE = '10'

export enum SaveMode {
  ADD,
  UPDATE
}

export enum EmailServerUiState {
  DISPLAY,
  EDIT
}

export interface ModifiedEmailServer extends EmailServer {
  uiState: EmailServerUiState
}

export interface State {
  tableData: ModifiedEmailServer[]
  mxServers: {
    primary: string
    backup: string
  }
  outboundSmarthost: string
  refs: {
    hostRefs: Map<string, HTMLInputElement | null>
    preferenceRefs: Map<string, HTMLInputElement | null>
  }
  errors: {
    hostError?: string
    preferenceError?: string
    recipientError?: string
  }
  inProgress: boolean
  inProgressServerId: string | undefined
  inProgressServerName: string | undefined
  isAddMailServerPending: boolean
  isRemoveMailServerPending: boolean
  isUpdateMailServerPending: boolean
  isTestMailServerPending: boolean
  isTestMailServerDialogOpen: boolean
  domainName: string
  recipient: string
  smtpErrorDetails: string
  isConfirmDialogOpen: boolean
  permissions: DomainsTabRights
}

export interface EventHandlers {
  onChangeHost: (id: string, value: string) => void
  onChangePreference: (id: string, value: string) => void
  toggleEmailServerUiState: (id: string) => void
  collectAddressRef: (id: string, ref: HTMLInputElement | null) => void
  collectPriorityRef: (id: string, ref: HTMLInputElement | null) => void
  onAddMailServer: (id: string) => void
  onRemoveMailServer: (id: string) => void
  onUpdateMailServer: (id: string) => void
  onOpenTestMailServerDialog: (id: string) => void
  onCloseTestMailServerDialog: () => void
  onTestMailServer: () => void
  onChangeRecipient: (e: ChangeEvent<HTMLInputElement>) => void
  onOpenConfirmDialog: (id: string) => void
  onCloseConfirmDialog: () => void
  onConfirmRemoval: () => void
}

export type EditDomainMailServersLogic = [State, EventHandlers]

export const useEditDomainMailServersLogic = (form: EditDomainForm): EditDomainMailServersLogic => {
  const permissions = useDomainsTabRights()
  const dispatch = useAppDispatch()
  const [errors, setErrors] = useState<State['errors']>({})
  const [editIds, setEditIds] = useState<string[]>([])
  const [inProgressServerId, setInProgressServerId] = useState<string>()
  const [isTestMailServerDialogOpen, setIsTestMailServerDialogOpen] = useState(false)
  const [recipient, setRecipient] = useState('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const hostRefs = useRef<Map<string, HTMLInputElement | null>>(new Map())
  const preferenceRefs = useRef<Map<string, HTMLInputElement | null>>(new Map())
  const [, { setShouldConfirmNavigation }] = form

  const {
    domainId,
    mailServers,
    primaryMxHost,
    backupMxHost,
    outboundSmarthost,
    isAddMailServerPending,
    isAddMailServerSuccess,
    isAddMailServerFailed,
    isRemoveMailServerPending,
    isRemoveMailServerSuccess,
    isUpdateMailServerPending,
    isUpdateMailServerSuccess,
    isUpdateMailServerFailed,
    isTestMailServerPending,
    isTestMailServerSuccess,
    isTestMailServerFailed,
    domainName,
    smtpErrorDetails
  } = useAppSelector(_store => ({
    domainId: _store.domains.domain?.domainId,
    mailServers: _store.domains.domain?.mailServers || [],
    primaryMxHost: _store.domains.domain?.primaryMxRecord?.domain || '',
    backupMxHost: _store.domains.domain?.backupMxRecord?.domain || '',
    outboundSmarthost: _store.domains.domain?.outboundSmarthost || '',
    isAddMailServerPending: isPending(_store.emailServer.addMailServerApiStatus),
    isAddMailServerSuccess: isSuccess(_store.emailServer.addMailServerApiStatus),
    isAddMailServerFailed: isFailed(_store.emailServer.addMailServerApiStatus),
    isRemoveMailServerPending: isPending(_store.emailServer.removeMailServerApiStatus),
    isRemoveMailServerSuccess: isSuccess(_store.emailServer.removeMailServerApiStatus),
    isUpdateMailServerPending: isPending(_store.emailServer.updateMailServerApiStatus),
    isUpdateMailServerSuccess: isSuccess(_store.emailServer.updateMailServerApiStatus),
    isUpdateMailServerFailed: isFailed(_store.emailServer.updateMailServerApiStatus),
    isTestMailServerPending: isPending(_store.emailServer.testMailServerApiStatus),
    isTestMailServerSuccess: isSuccess(_store.emailServer.testMailServerApiStatus),
    isTestMailServerFailed: isFailed(_store.emailServer.testMailServerApiStatus),
    domainName: _store.domains.domain?.domainName || '',
    smtpErrorDetails: _store.emailServer.smtpErrorDetails || ''
  }))

  const inProgressServerName = useMemo(() => {
    if (!inProgressServerId) {
      return ''
    }
    const inProgressServer = mailServers.find(item => item.id === inProgressServerId)
    return inProgressServer ? inProgressServer.host : ''
  }, [inProgressServerId, mailServers])

  const tableData: ModifiedEmailServer[] = useMemo(() => {
    if (!domainId) {
      return []
    }
    const servers = [...mailServers]
    servers.sort((a, b) => a.host.localeCompare(b.host) || parseInt(a.preference, 10) - parseInt(b.preference, 10))
    return [
      {
        id: '',
        host: '',
        preference: DEFAULT_PREFERENCE_VALUE,
        type: '',
        verified: false,
        uiState: EmailServerUiState.EDIT
      },
      ...servers.map(item => {
        const uiState = editIds.includes(item.id) ? EmailServerUiState.EDIT : EmailServerUiState.DISPLAY
        return { ...item, uiState }
      })
    ]
  }, [editIds, mailServers, domainId])

  const toggleEmailServerUiState = useCallback(
    (id: string) => {
      const idx = editIds.findIndex(itemId => itemId === id)
      const editItem = tableData.find(item => item.id === id)
      if (!editItem) {
        return
      }
      const nextEditIds = [...editIds]
      if (idx === -1) {
        nextEditIds.push(id)
      } else {
        nextEditIds.splice(idx, 1)
        hostRefs.current.delete(id)
        preferenceRefs.current.delete(id)
      }
      setEditIds(nextEditIds)
    },
    [editIds, tableData]
  )

  const updateShouldConfirmNavigation = useCallback(() => {
    const hasEditItem = tableData.some(item => item.id !== '' && item.uiState === EmailServerUiState.EDIT)
    const hasAddItem =
      (hostRefs.current.get('') && hostRefs.current.get('')?.value !== '') ||
      (preferenceRefs.current.get('') && preferenceRefs.current.get('')?.value !== DEFAULT_PREFERENCE_VALUE)
    if (hasEditItem || hasAddItem || isTestMailServerDialogOpen || isConfirmDialogOpen) {
      setShouldConfirmNavigation(true)
    } else {
      setShouldConfirmNavigation(false)
    }
  }, [isConfirmDialogOpen, isTestMailServerDialogOpen, setShouldConfirmNavigation, tableData])

  const onChangeHost = useCallback(
    (id: string, value: string) => {
      const ref = hostRefs.current.get(id)
      if (!ref) {
        return
      }
      ref.value = value
      updateShouldConfirmNavigation()
    },
    [updateShouldConfirmNavigation]
  )

  const onChangePreference = useCallback(
    (id: string, value: string) => {
      const ref = preferenceRefs.current.get(id)
      if (!ref) {
        return
      }
      ref.value = value
      updateShouldConfirmNavigation()
    },
    [updateShouldConfirmNavigation]
  )

  const collectAddressRef = useCallback((id: string, ref: HTMLInputElement | null) => {
    hostRefs.current.set(id, ref)
  }, [])

  const collectPriorityRef = useCallback((id: string, ref: HTMLInputElement | null) => {
    preferenceRefs.current.set(id, ref)
  }, [])

  const saveEmailServer = useCallback(
    (id: string, saveMode: SaveMode) => {
      const hostRef = hostRefs.current.get(id)
      const preferenceRef = preferenceRefs.current.get(id)
      if (domainId && hostRef && preferenceRef) {
        const host = hostRef.value
        const preference = preferenceRef.value === '' ? '10' : preferenceRef.value
        const preferenceValue = parseInt(preference, 10)
        const nextErrors: State['errors'] = {}
        if (!host) {
          nextErrors.hostError = 'empty_host'
        } else if (!/^([a-z0-9\-_.]+(:[0-9]+)?)$/i.test(host)) {
          nextErrors.hostError = 'invalid_host'
        }
        if (!/^[0-9]+$/.test(preference) || preferenceValue < 0 || preferenceValue > 999999) {
          nextErrors.preferenceError = 'invalid_preference'
        }
        setErrors(nextErrors)
        setInProgressServerId(id)
        if (Object.keys(nextErrors).length === 0) {
          if (saveMode === SaveMode.ADD) {
            dispatch(
              addMailServer({
                domainId,
                host,
                preference: preferenceValue,
                type: EmailServerType.A
              })
            )
          } else {
            dispatch(
              updateMailServer({
                serverId: id,
                domainId,
                host,
                preference: preferenceValue,
                type: EmailServerType.A
              })
            )
          }
        }
      }
    },
    [dispatch, domainId]
  )

  const onAddMailServer = useCallback((id: string) => saveEmailServer(id, SaveMode.ADD), [saveEmailServer])

  const onRemoveMailServer = useCallback(
    (id: string) => {
      setErrors({})
      setInProgressServerId(id)
      if (domainId) {
        dispatch(removeMailServer({ domainId, serverId: id }))
      }
    },
    [dispatch, domainId]
  )

  const onUpdateMailServer = useCallback((id: string) => saveEmailServer(id, SaveMode.UPDATE), [saveEmailServer])

  const onOpenTestMailServerDialog = useCallback((id: string) => {
    setInProgressServerId(id)
    setIsTestMailServerDialogOpen(true)
  }, [])

  const onCloseTestMailServerDialog = useCallback(() => {
    setInProgressServerId(undefined)
    setRecipient('')
    setErrors({})
    setIsTestMailServerDialogOpen(false)
    dispatch(resetTestEmailServer())
  }, [dispatch])

  const onTestMailServer = useCallback(() => {
    if (!recipient) {
      setErrors({
        recipientError: 'empty_recipient'
      })
      return
    }
    setErrors({})
    if (domainId && inProgressServerId) {
      dispatch(testMailServer({ domainId, serverId: inProgressServerId, recipient, domainName }))
    }
  }, [dispatch, domainId, domainName, inProgressServerId, recipient])

  const onChangeRecipient = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setRecipient(e.target.value)
  }, [])

  const onOpenConfirmDialog = useCallback((id: string) => {
    setInProgressServerId(id)
    setIsConfirmDialogOpen(true)
  }, [])

  const onCloseConfirmDialog = useCallback(() => {
    setInProgressServerId(undefined)
    setIsConfirmDialogOpen(false)
  }, [])

  const onConfirmRemoval = useCallback(() => {
    if (inProgressServerId) {
      onRemoveMailServer(inProgressServerId)
    }
  }, [inProgressServerId, onRemoveMailServer])

  useEffect(() => {
    if (isAddMailServerSuccess) {
      dispatch(resetAddEmailServer())
      const hostRef = hostRefs.current.get('')
      if (hostRef) {
        hostRef.value = ''
      }
      const preferenceRef = preferenceRefs.current.get('')
      if (preferenceRef) {
        preferenceRef.value = ''
      }
      setInProgressServerId(undefined)
      if (domainId) {
        dispatch(getDomain({ domainId, extraFields: [GetDomainExtraField.SETTINGS] }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddMailServerSuccess])

  useEffect(() => {
    if (isAddMailServerFailed) {
      dispatch(resetAddEmailServer())
      setInProgressServerId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddMailServerFailed])

  useEffect(() => {
    if (isRemoveMailServerSuccess) {
      dispatch(resetRemoveEmailServer())
      setInProgressServerId(undefined)
      setIsConfirmDialogOpen(false)
      if (domainId) {
        dispatch(getDomain({ domainId, extraFields: [GetDomainExtraField.SETTINGS] }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoveMailServerSuccess])

  useEffect(() => {
    if (isUpdateMailServerSuccess) {
      dispatch(resetUpdateEmailServer())
      if (inProgressServerId) {
        toggleEmailServerUiState(inProgressServerId)
      }
      setInProgressServerId(undefined)
      if (domainId) {
        dispatch(getDomain({ domainId, extraFields: [GetDomainExtraField.SETTINGS] }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateMailServerSuccess, inProgressServerId, toggleEmailServerUiState])

  useEffect(() => {
    if (isUpdateMailServerFailed) {
      dispatch(resetUpdateEmailServer())
      setInProgressServerId(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateMailServerFailed])

  useEffect(() => {
    if (isTestMailServerSuccess) {
      dispatch(resetTestEmailServer())
      setInProgressServerId(undefined)
      setRecipient('')
      setErrors({})
      setIsTestMailServerDialogOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestMailServerSuccess])

  useEffect(() => {
    if (isTestMailServerFailed) {
      setErrors({
        recipientError: 'email_send_failure'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTestMailServerFailed])

  useEffect(() => {
    updateShouldConfirmNavigation()
  }, [updateShouldConfirmNavigation])

  return useMemo(
    () => [
      {
        tableData,
        mxServers: {
          primary: primaryMxHost,
          backup: backupMxHost
        },
        outboundSmarthost,
        refs: {
          hostRefs: hostRefs.current,
          preferenceRefs: preferenceRefs.current
        },
        errors,
        inProgress:
          isAddMailServerPending ||
          isRemoveMailServerPending ||
          isUpdateMailServerPending ||
          isTestMailServerPending ||
          isTestMailServerDialogOpen,
        inProgressServerId,
        inProgressServerName,
        isAddMailServerPending,
        isRemoveMailServerPending,
        isUpdateMailServerPending,
        isTestMailServerPending,
        isTestMailServerDialogOpen,
        domainName,
        recipient,
        smtpErrorDetails,
        isConfirmDialogOpen,
        permissions
      },
      {
        onChangeHost,
        onChangePreference,
        toggleEmailServerUiState,
        collectAddressRef,
        collectPriorityRef,
        onAddMailServer,
        onRemoveMailServer,
        onUpdateMailServer,
        onOpenTestMailServerDialog,
        onCloseTestMailServerDialog,
        onTestMailServer,
        onChangeRecipient,
        onOpenConfirmDialog,
        onCloseConfirmDialog,
        onConfirmRemoval
      }
    ],
    [
      tableData,
      primaryMxHost,
      backupMxHost,
      outboundSmarthost,
      errors,
      isAddMailServerPending,
      isRemoveMailServerPending,
      isUpdateMailServerPending,
      inProgressServerId,
      inProgressServerName,
      isTestMailServerPending,
      isTestMailServerDialogOpen,
      domainName,
      recipient,
      smtpErrorDetails,
      isConfirmDialogOpen,
      permissions,
      onChangeHost,
      onChangePreference,
      toggleEmailServerUiState,
      collectAddressRef,
      collectPriorityRef,
      onAddMailServer,
      onRemoveMailServer,
      onUpdateMailServer,
      onOpenTestMailServerDialog,
      onCloseTestMailServerDialog,
      onTestMailServer,
      onChangeRecipient,
      onOpenConfirmDialog,
      onCloseConfirmDialog,
      onConfirmRemoval
    ]
  )
}
