import React, { PropsWithChildren, ReactElement, useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'
import { HelpOutline } from '@barracuda-internal/bds-core/dist/Icons/Core'
import styles from 'components/libs/layout/pages/pagesLayoutStyles'
import { useFormatMessage } from 'lib/localization'
import Help from 'components/pages/support/help/Help'
import { HelpComponentProps } from 'types/Help'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'

const BASE_I18N_KEY = 'ess.layout'

export type PageTitleProps = {
  title: string | ReactElement
  onOpenSupport?: () => void
  onCancel?: () => void
  cancelButtonText?: string
  onSubmit?: () => void
  submitButtonText?: string
  submitInProgress?: boolean
  isDisabledSubmit?: boolean
  help?: {
    content: (props: HelpComponentProps) => JSX.Element
    onClose: () => void
    title: TOPICS
    group: GROUP_TITLE
    isHelpOpen: boolean
  }
}

export const PageTitle = ({
  title,
  onOpenSupport,
  onCancel,
  cancelButtonText,
  onSubmit,
  submitButtonText,
  submitInProgress,
  children,
  isDisabledSubmit,
  help
}: PropsWithChildren<PageTitleProps>) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Grid className={classes.pageTitle} alignItems="center" container direction="row">
        <Grid xs item>
          <Grid container direction="row">
            <Typography variant="h1">{title}</Typography>
            {onOpenSupport && (
              <HelpOutline data-testid="on-open-support" onClick={onOpenSupport} className={classes.helpIcon} />
            )}
          </Grid>
        </Grid>
        {(onCancel || onSubmit || children) && (
          <Grid xs item>
            <Grid container direction="row" justifyContent="flex-end">
              {onCancel && (
                <Button className={classes.cancelButton} onClick={onCancel}>
                  {cancelButtonText || formatMessage('cancel')}
                </Button>
              )}
              {onSubmit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  isLoading={submitInProgress}
                  disabled={isDisabledSubmit}
                >
                  {submitButtonText || formatMessage('save_changes')}
                </Button>
              )}
              {children}
            </Grid>
          </Grid>
        )}
        <Grid>
          {help?.isHelpOpen && (
            <Help
              modalComponentInfo={{
                content: help.content,
                onClose: help.onClose,
                title: help.title,
                groupTitle: help.group
              }}
            />
          )}
        </Grid>
      </Grid>
    ),
    [
      classes,
      title,
      onOpenSupport,
      onCancel,
      cancelButtonText,
      formatMessage,
      onSubmit,
      submitInProgress,
      isDisabledSubmit,
      submitButtonText,
      children,
      help
    ]
  )
}
