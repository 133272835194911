import { useCallback, useMemo, useState } from 'react'

export interface EventTypes {
  select_all: boolean
  move: boolean
  delete: boolean
  change: boolean
  login: boolean
  logout: boolean
  log_action: boolean
  create: boolean
  delete_all: boolean
  reset: boolean
  enable: boolean
  disable: boolean
  suspend: boolean
  notify: boolean
  migrate_cpl: boolean
  restore: boolean
  login_failure: boolean
}

export const InitEventTypes: EventTypes = {
  select_all: false,
  move: false,
  delete: false,
  change: false,
  login: false,
  logout: false,
  log_action: false,
  create: false,
  delete_all: false,
  reset: false,
  enable: false,
  disable: false,
  suspend: false,
  notify: false,
  migrate_cpl: false,
  restore: false,
  login_failure: false
}

export interface State {
  openSelection: boolean
  eventTypes: object
}

export interface EventHandlers {
  onOpen: () => void
  onClose: () => void
  onClear: () => void
  onApply: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export type UseAuditLogFilterLogic = [State, EventHandlers]

export const useAuditLogFilterLogic = (
  eventTypes: EventTypes,
  onUpdateEventTypes: (eventTypes: EventTypes, performSearch: boolean) => void
): UseAuditLogFilterLogic => {
  const [openSelection, setOpenSelection] = useState(false)

  const onOpen = useCallback(() => {
    setOpenSelection(true)
  }, [setOpenSelection])

  const onClose = useCallback(() => {
    setOpenSelection(false)
  }, [setOpenSelection])

  const onClear = useCallback(() => {
    onUpdateEventTypes(eventTypes, true)
  }, [eventTypes, onUpdateEventTypes])

  const onApply = useCallback(() => {
    setOpenSelection(false)
    onUpdateEventTypes(eventTypes, true)
  }, [eventTypes, setOpenSelection, onUpdateEventTypes])

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === 'select_all') {
        let tmpEventTypes = eventTypes
        Object.keys(tmpEventTypes).forEach(key => {
          tmpEventTypes = { ...tmpEventTypes, [key]: e.target.checked }
        })
        onUpdateEventTypes(tmpEventTypes, false)
      } else {
        onUpdateEventTypes({ ...eventTypes, [e.target.name]: e.target.checked }, false)
      }
    },
    [eventTypes, onUpdateEventTypes]
  )

  return useMemo(
    () => [
      { openSelection, eventTypes },
      {
        onOpen,
        onClose,
        onClear,
        onApply,
        onChange
      }
    ],
    [openSelection, eventTypes, onOpen, onClose, onClear, onApply, onChange]
  )
}
