import axios from 'axios'
import appConfig from 'config/appConfig'

const makeLogger = (gatewayUrl: string, endpointPath: string) => {
  /* eslint-disable no-console */
  const url = `${gatewayUrl}${endpointPath}`

  const info = (message: string) => {
    if (!appConfig.DEBUG_MODE) {
      return
    }

    console.log(message)
    axios.post(url, { level: 'info', message }).catch()
  }

  const error = (message: string) => {
    if (!appConfig.DEBUG_MODE) {
      return
    }

    console.error(message)
    axios.post(url, { level: 'error', message }).catch()
  }

  return Object.freeze({
    error,
    info
  })
  /* eslint-enable no-console */
}

const logger = makeLogger(appConfig.GATEWAY_URL || '', '/internal/logger')

export default logger
