const EVENTS_PREFIX = 'ESS Wizard'

const STEP_0_PREFIX = `${EVENTS_PREFIX} Step 0`
const STEP_1_PREFIX = `${EVENTS_PREFIX} Step 1`
const STEP_2_PREFIX = `${EVENTS_PREFIX} Step 2`
const STEP_3_PREFIX = `${EVENTS_PREFIX} Step 3`

const WIZARD_EVENTS = {
  START: `${EVENTS_PREFIX} Pageview`,
  CLOSE: `${EVENTS_PREFIX} Close`,
  SAVE_AND_EXIT: `${EVENTS_PREFIX} Save Exit`,
  COMPLETE: `${EVENTS_PREFIX} Complete`,

  // Region selection
  STEP_0_START: `${STEP_0_PREFIX} Pageview`,
  STEP_0_REGION_CHANGE: `${STEP_0_PREFIX} Region change`,
  STEP_0_CONFIRM_REGION: `${STEP_0_PREFIX} Confirm region`,
  STEP_0_CLOSE_WIZARD: `${STEP_0_PREFIX} Close wizard`,
  STEP_0_COMPLETE: `${STEP_0_PREFIX} Complete`,

  // Specify email server
  STEP_1_START: `${STEP_1_PREFIX} Pageview`,
  STEP_1_DETECT_EMAIL_SERVER: `${STEP_1_PREFIX} Detect email server`,
  STEP_1_EDIT_EMAIL_SERVER: `${STEP_1_PREFIX} Edit email server`,
  STEP_1_CHANGE_EMAIL_SERVER: `${STEP_1_PREFIX} Change email server`,
  STEP_1_VERIFY_SERVER: `${STEP_1_PREFIX} Verify server`,
  STEP_1_COMPLETE: `${STEP_1_PREFIX} Complete`,

  // Add new MX records
  STEP_2_START: `${STEP_2_PREFIX} Pageview`,
  STEP_2_VISIT_PROVIDER_URL: `${STEP_2_PREFIX} Provider url`,
  STEP_2_VISIT_ADD_MX_LINK_URL: `${STEP_2_PREFIX} Add MX link url`,
  STEP_2_VERIFIY_RECORDS: `${STEP_2_PREFIX} Verify records`,
  STEP_2_COMPLETE: `${STEP_2_PREFIX} Complete`,

  // Remove old MX records
  STEP_3_START: `${STEP_3_PREFIX} Pageview`,
  STEP_3_VISIT_EDIT_MX_LINK_URL: `${STEP_3_PREFIX} Edit MX link url`,
  STEP_3_VERIFIY_UPDATE: `${STEP_3_PREFIX} Verify update`,
  STEP_3_COMPLETE: `${STEP_3_PREFIX} Complete`
} as const

export default WIZARD_EVENTS
