import appConfig from 'config/appConfig'
import { activateNewRelicClient } from 'lib/monitoring/newRelic/newRelicClient'

const newRelicConfig = appConfig.NEW_RELIC

declare global {
  interface Window {
    NREUM: any
  }
}

export function initNewRelicService() {
  window.NREUM = {}
  if (!newRelicConfig.IS_ENABLED || !appConfig.APP.IS_ENDUSER) {
    return
  }

  window.NREUM.init = {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: [newRelicConfig.TRACKING_URL] }
  }

  window.NREUM.loader_config = {
    accountID: newRelicConfig.ACCOUNT_ID,
    trustKey: newRelicConfig.TRUST_KEY,
    agentID: newRelicConfig.AGENT_ID,
    licenseKey: newRelicConfig.LICENSE_KEY,
    applicationID: newRelicConfig.APPLICATION_ID
  }
  window.NREUM.info = {
    beacon: newRelicConfig.TRACKING_URL,
    errorBeacon: newRelicConfig.TRACKING_URL,
    licenseKey: newRelicConfig.LICENSE_KEY,
    applicationID: newRelicConfig.APPLICATION_ID,
    sa: 1
  }

  activateNewRelicClient()
}
