import routesGenerator, { pushToHistory, UiRoute as GlobalUiRoute } from 'lib/routes'
import appConfig, { AppNames } from 'config/appConfig'
import appFeatures from 'config/appFeatures'
import UI_PATHS from 'config/uiPaths'
import { Message } from 'types/Messages'

export type UiRoute = GlobalUiRoute
export type UiRoutes = {
  [key in keyof typeof UI_PATHS]: UiRoute
}
export type ConvertTDFLinksToReact = (link: Message) => Message

export { pushToHistory }

// disable the general login on stacks where the BCC login is enabled
const updatedUiPaths = {
  ...UI_PATHS,
  LOGIN: appFeatures.EnabledWizardBccLogin ? undefined : UI_PATHS.LOGIN
}
export const allRoutesConfig = routesGenerator(updatedUiPaths)
const appRelatedRoutesConfig: UiRoutes = Object.keys(allRoutesConfig).reduce((all: UiRoutes, routeName: string) => {
  const routeConfig = allRoutesConfig[routeName]
  return {
    ...all,
    ...(routeConfig.isAppRelatedPath && { [routeName]: routeConfig })
  }
}, {} as UiRoutes)

const AUTH0_LOGIN_CALLBACK_PATHS = {
  [AppNames.enduser]: allRoutesConfig.AUTH0_LOGIN_CALLBACK
} as { [key: string]: UiRoute }

const AUTH0_LOGOUT_CALLBACK_PATHS = {
  [AppNames.enduser]: allRoutesConfig.LOGIN
} as { [key: string]: UiRoute }

export const appAuth0LoginCallbackPath = AUTH0_LOGIN_CALLBACK_PATHS[appConfig.APP.NAME]
export const appAuth0LogoutCallbackPath = AUTH0_LOGOUT_CALLBACK_PATHS[appConfig.APP.NAME]

export function gotoAdminEntryPathFromWizard(uri = '', forceToUseReactURL = false) {
  if (appFeatures.UseReactAppWhenFinishedWizard || appConfig.ENVIRONMENT.IS_DEV || forceToUseReactURL) {
    window.location.href = `${window.location.origin}/${appConfig.APP_NAMES.ADMIN}${uri}`
  } else {
    window.location.href = appConfig.LEGACY_UI_URL as string
  }
}

export const gotoAdminEntryPathFromCudaspt = gotoAdminEntryPathFromWizard
export const gotoAdminEntryPathFromEnduser = gotoAdminEntryPathFromWizard

export function gotoEnduserEntryPathFromWizard() {
  window.location.href = `${window.location.origin}/${appConfig.APP_NAMES.ENDUSER}/${UI_PATHS.MESSAGE_LOG.path}`
}

export const gotoEnduserEntryPathFromAdmin = gotoEnduserEntryPathFromWizard

export function gotoLegacyLogin(loginHint?: string) {
  window.location.href = `${appConfig.LEGACY_UI_URL}/${UI_PATHS.LOGIN.legacyPath}${
    loginHint ? `?login-hint=${loginHint}` : ''
  }`
}

export function gotoLegacyMessageLog() {
  window.location.href = `${appConfig.LEGACY_UI_URL}/user/log`
}

export function gotoLegacyUrl(uri: string) {
  window.location.href = `${appConfig.LEGACY_UI_URL}${uri}`
}

export function gotoBccLogout() {
  window.location.href = `${appConfig.BCC_PATH}/auth/logout`
}

export function gotoBccLogin() {
  window.location.href = `${appConfig.BCC_PATH}/auth/login`
}

export const convertQNDigestTDFLinksToReact: ConvertTDFLinksToReact = (message: Message) => {
  const basUrl = appConfig.ENVIRONMENT.IS_DEV ? 'https://ess.gt3.barracudanetworks.com' : appConfig.LEGACY_UI_URL
  let tdfQNDigestLinkUrl: string | RegExp = `${basUrl}/user/auth/login`
  if (appConfig.ENVIRONMENT.IS_GT3) {
    tdfQNDigestLinkUrl = new RegExp(
      `\\b${tdfQNDigestLinkUrl}|https://ess.gt3.barracudanetworks.com/user/auth/login\\b`,
      'gi'
    )
  }

  return {
    ...message,
    body: message.body.replaceAll(tdfQNDigestLinkUrl, allRoutesConfig.QN_DIGEST_LINKS_HANDLER.path)
  }
}

export default appRelatedRoutesConfig
