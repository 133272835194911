/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { Alert, Box, Card, CardContent, Grid, LinearProgress, Link, Typography } from '@barracuda-internal/bds-core'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { ResetPasswordLogic } from 'components/pages/actions/resetPassword/useResetPasswordLogic'

const BASE_I18N_KEY = 'ess.reset_password'

export interface CheckEmailCardProps {
  logic: ResetPasswordLogic
}

const CheckEmailCard = ({ logic }: CheckEmailCardProps) => {
  const [state, eventHandlers] = logic
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={0} className={classes.card} data-testid="check-email-card">
        {state.isLoading && <LinearProgress data-testid="linear-progress" />}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('check_email.title')}</Typography>
            <Typography variant="body1">
              {formatMessage('check_email.message', { email: <b>{state.userId}</b> })}
            </Typography>
            <Typography variant="body1">{formatMessage('check_email.link_will_expire')}</Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Typography variant="body1" className={classes.cardInlineText}>
                  {formatMessage('didnt_receive_email')}
                </Typography>
                <Link
                  onClick={eventHandlers.onResendLink}
                  data-testid="on-resend-link"
                  className={`${classes.cardInlineLink} ${state.isLoading ? 'disabled' : ''}`}
                >
                  <Typography variant="body1">{formatMessage('resend_link')}</Typography>
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link onClick={eventHandlers.onLogin} data-testid="go-to-signin">
                  <Typography variant="body1">{formatMessage('expired_link.sign_in')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardWrapper,
      classes.cardHeader,
      classes.cardLogo,
      classes.topError,
      classes.cardFormActionRow,
      classes.cardInlineText,
      classes.cardInlineLink,
      classes.cardFormLinkRow,
      state.isLoading,
      state.userId,
      state.topErrorMessage,
      formatMessage,
      eventHandlers.onResendLink,
      eventHandlers.onLogin
    ]
  )
}

export default CheckEmailCard
/* eslint-enable jsx-a11y/anchor-is-valid */
