import { makeStyles, colors } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  error: {
    color: colors.red300
  },
  success: {
    color: colors.green300
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  userLookupTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}))

export default styles
