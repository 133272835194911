import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { process, orderBy, SortDescriptor, DataResult, State } from '@progress/kendo-data-query'
import { GridDataStateChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getSenderPolicies,
  postPolicy,
  deletePolicy,
  getAccountPermissions
} from 'redux/features/settings/settingsSlice'
import { trackMixpanelEvent, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { isPolicyNameValid } from 'lib/validation'
import routesConfig from 'lib/routesConfig'
import { formatDate } from 'lib/datetime'
import appConfig from 'config/appConfig'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { Policy, PolicyStatus } from 'types/Settings'

interface GridState {
  dataState: State
  dataResult: DataResult
}

export interface ModifiedPolicy extends Policy {
  inEdit: boolean
  formattedModified?: string
}

export interface UseSenderPoliciesLogic {
  policiesAreLoading: boolean
  accountPermissionsLoading: boolean
  openInvalidDomainBox: boolean
  shouldShowBanner: boolean
  onClickBulkEdit: () => void
  sort: SortDescriptor[]
  handleSortChange: (e: GridSortChangeEvent) => void
  sortedData: ModifiedPolicy[]
  dataStateChange: (e: GridDataStateChangeEvent) => void
  gridState: GridState
  defaultPolicyFormValues: React.MutableRefObject<Policy>
  nameInput: React.MutableRefObject<HTMLInputElement | null>
  senderPolicyDropdownValues: string[]
  commentInput: React.MutableRefObject<HTMLInputElement | null>
  onSubmit: () => void
  onDelete: (policy: ModifiedPolicy) => void
  isOpenConfirmBox: boolean
  closeConfirmBox: () => void
  handleDeletePolicy: () => void
  closeInvalidDomainBox: () => void
  policyInput: React.MutableRefObject<HTMLInputElement | null>
  handleCommentInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleNameInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handlePolicyInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const initialSort = [{ field: 'name', dir: 'asc' } as SortDescriptor]

const dataState: State = {
  skip: 0,
  take: 10,
  sort: initialSort
}

export const useSenderPoliciesLogic = (): UseSenderPoliciesLogic => {
  const defaultPolicyFormValues = React.useRef<Policy>({
    id: '',
    policy: PolicyStatus.block,
    name: '',
    comment: '',
    modified: 0
  })
  const nameInput = React.useRef<HTMLInputElement | null>(null)
  const commentInput = React.useRef<HTMLInputElement | null>(null)
  const policyInput = React.useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()
  const {
    accountPermissions,
    senderPolicies,
    policiesAreLoading,
    policiesAreLoaded,
    policyIsPosting,
    policyIsPosted,
    policyIsDeleting,
    policyIsDeleted,
    accountPermissionsLoading
  } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions,
    senderPolicies: _store.settings.senderPolicies?.results || [],
    policiesAreLoading: isPending(_store.settings.getSenderPoliciesApiStatus),
    policiesAreLoaded: isSuccess(_store.settings.getSenderPoliciesApiStatus),
    policyIsPosting: isPending(_store.settings.postPolicyApiStatus),
    policyIsPosted: isSuccess(_store.settings.postPolicyApiStatus),
    policyIsDeleting: isPending(_store.settings.deletePolicyApiStatus),
    policyIsDeleted: isSuccess(_store.settings.deletePolicyApiStatus),
    accountPermissionsLoading: isPending(_store.settings.getAccountPermissionsApiStatus)
  }))
  const [isOpenConfirmBox, setIsOpenConfirmBox] = useState(false)
  const [openInvalidDomainBox, setOpenInvalidDomainBox] = useState(false)
  const [shouldShowBanner, setShouldShowBanner] = useState(false)
  const [policyToDelete, setPolicyToDelete] = useState<ModifiedPolicy>()
  const [sort, setSort] = useState(initialSort)
  const [senderPolicyDropdownValues, setSenderPolicyDropdownValues] = useState<string[]>([PolicyStatus.block])

  const setPolicyInputValue = useCallback((value: string) => {
    if (policyInput.current) {
      defaultPolicyFormValues.current.policy = value as PolicyStatus
      policyInput.current.value = value
    }
  }, [])

  const handlePolicyInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPolicyInputValue(e.target.value)
    },
    [setPolicyInputValue]
  )

  const setNameInputValue = useCallback((value: string) => {
    if (nameInput.current) {
      defaultPolicyFormValues.current.name = value
      nameInput.current.value = value
    }
  }, [])

  const handleNameInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNameInputValue(e.target.value)
    },
    [setNameInputValue]
  )

  const setCommentInputValue = useCallback((value: string) => {
    if (commentInput.current) {
      defaultPolicyFormValues.current.comment = value
      commentInput.current.value = value
    }
  }, [])

  const handleCommentInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCommentInputValue(e.target.value)
    },
    [setCommentInputValue]
  )

  const getFormData = useCallback(
    () => ({
      name: nameInput.current?.value,
      policy: policyInput.current?.value,
      comment: commentInput.current?.value
    }),
    []
  )

  const resetForm = useCallback(() => {
    setCommentInputValue('')
    setNameInputValue('')
    setPolicyInputValue(PolicyStatus.block)
  }, [setCommentInputValue, setNameInputValue, setPolicyInputValue])

  const closeInvalidDomainBox = useCallback(() => setOpenInvalidDomainBox(false), [])

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SETTINGS_SENDER_POLICIES_PAGE_VIEW)
    dispatch(getSenderPolicies())
    dispatch(getAccountPermissions())
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const options: PolicyStatus[] = []
    if (accountPermissions) {
      const { defaultUserExemptEnabled, defaultUserBlockEnabled } = accountPermissions
      if (defaultUserBlockEnabled === 1) {
        options.push(PolicyStatus.block)
      }
      if ([1, 2].includes(defaultUserExemptEnabled)) {
        options.push(PolicyStatus.exempt)
      }
      options.push(PolicyStatus.quarantine)
      setPolicyInputValue(options[0])
      setSenderPolicyDropdownValues(options)
    }
    // eslint-disable-next-line
  }, [accountPermissions])

  const gridData = useMemo(() => senderPolicies, [senderPolicies])

  const [gridState, setGridState] = useReducer(
    (_state: any, newState: any) => ({
      ..._state,
      ...newState
    }),
    {
      dataState,
      dataResult: process(gridData, dataState)
    }
  )

  const sortedData = useMemo(() => {
    const data = orderBy([...gridState.dataResult.data], sort).map((item: Policy) => {
      if (item.modified) {
        return {
          ...item,
          formattedModified: formatDate(new Date(item.modified * 1000), appConfig.DATETIME.DEFAULT_ACCEPTED_DATE_FORMAT)
        }
      }
      return item
    }) as ModifiedPolicy[]

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift({ ...defaultPolicyFormValues.current, inEdit: true })
    return data
  }, [gridState.dataResult.data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const dataStateChange = useCallback(
    (e: GridDataStateChangeEvent) => {
      setGridState({
        dataState: e.dataState,
        dataResult: process(gridData, e.dataState)
      })
    },
    [gridData]
  )

  const refreshPolicies = useCallback(() => {
    dispatch(getSenderPolicies())
    dispatch(getAccountPermissions())
  }, [dispatch])

  // Handle policy create success
  useEffect(() => {
    if (!policyIsPosted) {
      return
    }
    refreshPolicies()
  }, [policyIsPosted, refreshPolicies])

  // Handle delete policy success
  useEffect(() => {
    if (!policyIsDeleted) {
      return
    }
    refreshPolicies()
  }, [policyIsDeleted, refreshPolicies])

  const onSubmit = useCallback(() => {
    const { name, comment, policy } = getFormData()
    if (!name || !isPolicyNameValid(name)) {
      setOpenInvalidDomainBox(true)
      return
    }

    dispatch(
      postPolicy({
        id: '',
        name,
        comment: comment || '',
        policy: policy as PolicyStatus,
        modified: new Date().getTime()
      })
    )
  }, [dispatch, getFormData])

  useEffect(() => {
    if (!policyIsPosting && policiesAreLoaded) {
      setGridState({
        dataResult: process(senderPolicies, gridState.dataState)
      })
      resetForm()
    }
    // eslint-disable-next-line
  }, [policyIsPosting, senderPolicies, policiesAreLoaded])

  useEffect(() => {
    if (!policyIsDeleting && policiesAreLoaded) {
      setGridState({
        dataResult: process(senderPolicies, gridState.dataState)
      })
    }
    // eslint-disable-next-line
  }, [policyIsDeleting, senderPolicies, policiesAreLoaded])

  const onDelete = useCallback((policy: ModifiedPolicy) => {
    setPolicyToDelete(policy)
    setIsOpenConfirmBox(true)
  }, [])

  const onClickBulkEdit = useCallback(() => {
    routesConfig.SENDER_POLICIES_BULK_EDIT.goto()
  }, [])

  const handleDeletePolicy = useCallback(() => {
    if (policyToDelete) {
      dispatch(deletePolicy(policyToDelete))
    }
  }, [dispatch, policyToDelete])

  const closeConfirmBox = useCallback(() => setIsOpenConfirmBox(false), [])

  useEffect(() => {
    if (accountPermissions) {
      const { defaultUserExemptEnabled, defaultUserBlockEnabled } = accountPermissions
      setShouldShowBanner(defaultUserBlockEnabled === 0 || [0, 2].includes(defaultUserExemptEnabled))
    } else {
      setShouldShowBanner(false)
    }
  }, [accountPermissions])

  return useMemo(
    () => ({
      policiesAreLoading,
      accountPermissionsLoading,
      openInvalidDomainBox,
      shouldShowBanner,
      onClickBulkEdit,
      sort,
      handleSortChange,
      sortedData,
      dataStateChange,
      gridState,
      defaultPolicyFormValues,
      nameInput,
      senderPolicyDropdownValues,
      commentInput,
      onSubmit,
      onDelete,
      isOpenConfirmBox,
      closeConfirmBox,
      handleDeletePolicy,
      closeInvalidDomainBox,
      policyInput,
      handleCommentInputChange,
      handleNameInputChange,
      handlePolicyInputChange
    }),
    [
      policiesAreLoading,
      accountPermissionsLoading,
      openInvalidDomainBox,
      shouldShowBanner,
      onClickBulkEdit,
      sort,
      handleSortChange,
      sortedData,
      dataStateChange,
      gridState,
      senderPolicyDropdownValues,
      onSubmit,
      onDelete,
      isOpenConfirmBox,
      closeConfirmBox,
      handleDeletePolicy,
      closeInvalidDomainBox,
      handleCommentInputChange,
      handleNameInputChange,
      handlePolicyInputChange
    ]
  )
}
