import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SelfService5(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      To verify your domain, configure your MX records:
      <ol>
        <li>
          Replace your current MX records with {props.productName} MX records displayed on the page. If you want to
          first test {props.productName}, or you just want to be careful moving your mail to the Barracuda Networks
          service, then just ADD the MX records with a LOW priority (99 for example). This will allow you to complete
          the verification process, but your legitimate mail will still use your current mail server. See{' '}
          <a href="https://campus.barracuda.com/doc/96022975/" target="_new">
            How to Set Up MX Records for Domain Verification
          </a>{' '}
          in Barracuda Campus for an example.
        </li>
        <li>
          After you have changed your MX records, click the <b>Verify MX Records</b> button.
        </li>
      </ol>
      <p>
        If you do not want to route your email through the service at this time, but want to verify your domain
        ownership, check the box, and you&apos;ll see these other options for domain validation:
      </p>
      <ul>
        <li>
          <b>CNAME</b> - Follow these steps to use CNAME validation:
        </li>
        <ol>
          <li>
            Log into your DNS Server and, under this domain, create a subdomain whose name is created by concatenating
            barracuda and the CNAME token as shown on the page.
          </li>
          <li>
            Point the CNAME record of that subdomain to <code>ess.barracuda.com</code>
            <br />
            You might have to wait for a short time for the DNS propagation to take effect.
          </li>
          <li>
            Click <b>Confirm Validation</b> on the page to complete the domain verification process. This domain should
            then show as <i>validated</i> in the DOMAINS page when you log into the service web interface.
          </li>
        </ol>
        <li>
          <b>Email to Postmaster</b> - This method sends a verification email to the postmaster email address for your
          domain. The confirmation email will include a link that the recipient can click to verify the domain.
        </li>
        <li>
          <b>Email to Technical Contact</b> - This method sends a verification email to the Technical Contact email
          address, if it exists, listed on your domain&apos;s WHOIS entry. This verification option is not available if{' '}
          {props.productName} cannot find your domain&apos;s WHOIS entry. If there is not a Technical Contact, then only
          the <b>MX Records</b>, <b>CNAME</b> and <b>Email to the Postmaster</b> options are available.
        </li>
      </ul>
    </div>
  )
}

export default SelfService5
