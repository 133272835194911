import React, { useMemo } from 'react'

import { Button, CircularProgress, Grid, TextField, TextareaAutosize, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/domains/editDomain/editDomainEncryption/editDomainEncryptionStyles'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { FormRow, FormSpacer, HelperText, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import {
  BUTTON_NAMES,
  useEditDomainEncryptionLogic
} from 'components/pages/domains/editDomain/editDomainEncryption/useEditDomainEncryptionLogic'
import { EncryptionStatus } from 'types/domains'
import { SettingValue } from 'types/Settings'
import { AvailableEncryptionSettings } from 'types/Encryption'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

const BASE_I18N_KEY = 'ess.domains.edit_domain.encryption'

export interface EditDomainEncryptionProps {
  form: EditDomainForm
}

const EditDomainEncryption = ({ form }: EditDomainEncryptionProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useEditDomainEncryptionLogic(form)

  return useMemo(
    () => (
      <FormBlock title={formatMessage('title')}>
        <FormRow rowTitle={formatMessage('validation_status')}>
          <Grid container direction="row" alignItems="center">
            {!state.inProgress && (
              <Grid>
                <Typography
                  variant="body1"
                  className={state.validationStatus === EncryptionStatus.VALIDATED ? classes.success : classes.error}
                >
                  {formatMessage(state.validationStatus)}
                </Typography>
                <Typography variant="body1">
                  {state.pendingValidation.label &&
                    formatMessage(state.pendingValidation.label, { value: state.pendingValidation.value })}
                </Typography>
              </Grid>
            )}
            {state.inProgress && <CircularProgress size={20} data-testid="loading-state" />}
            {!state.inProgress && !state.confirmedValidation && (
              <Grid className={classes.floatRightContent}>
                <Button
                  className={classes.validationButton}
                  onClick={() => eventHandlers.onButtonClick(BUTTON_NAMES.CNAME)}
                  variant="contained"
                  size="small"
                  data-testid="cname-button"
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('validate_cname')}
                </Button>
                <Button
                  className={classes.validationButton}
                  onClick={() => eventHandlers.onButtonClick(BUTTON_NAMES.POST)}
                  variant="contained"
                  size="small"
                  data-testid="post-button"
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('validate_post')}
                </Button>
                <Button
                  onClick={() => eventHandlers.onButtonClick(BUTTON_NAMES.CONFIRM)}
                  variant="contained"
                  size="small"
                  data-testid="confirm-button"
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('confirm_validation')}
                </Button>
              </Grid>
            )}
            {state.confirmedValidation && (
              <Grid className={classes.floatRightContent}>
                <Button
                  onClick={() => eventHandlers.onButtonClick(BUTTON_NAMES.REMOVE_VALIDATION)}
                  variant="contained"
                  size="small"
                  data-testid="remove-validation-button"
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('remove_validation')}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormRow>
        <FormSpacer />
        {state.confirmedValidation && (
          <Grid container>
            {state.image.image?.length && (
              <Grid container>
                <FormRow rowTitle="">
                  <img className={classes.logo} src={`data:image/jpeg;base64,${state.image.image}`} alt="" />
                </FormRow>
                <FormSpacer />
              </Grid>
            )}
            <FormRow rowTitle={formatMessage('upload_image')}>
              <Grid container direction="row" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.uploadButton}
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('choose_file')}
                  <input type="file" onChange={eventHandlers.onAddFile} />
                </Button>
                <Typography variant="body2">
                  {state.image.filename ? state.image.filename : formatMessage('no_file')}
                </Typography>
                <Button
                  className={classes.removeLogoButton}
                  onClick={() => eventHandlers.onButtonClick(BUTTON_NAMES.REMOVE_LOGO)}
                  variant="contained"
                  color="primary"
                  size="small"
                  isLoading={state.actionPending || state.inProgress}
                >
                  {formatMessage('remove_file')}
                </Button>
                <Typography variant="body2">{formatMessage('upload_image_helper')}</Typography>
              </Grid>
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('allow_replies')}>
              <InlineRadioGroup
                name={AvailableEncryptionSettings.ALLOWREPLIES}
                value={state.allowReplies}
                onChange={eventHandlers.onInputChange(AvailableEncryptionSettings.ALLOWREPLIES)}
                values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                disabled={state.inProgress}
              />
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('read_receipts')}>
              <InlineRadioGroup
                name={AvailableEncryptionSettings.GET_READRECEIPTS}
                value={state.readReceipts}
                onChange={eventHandlers.onInputChange(AvailableEncryptionSettings.GET_READRECEIPTS)}
                values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                disabled={state.inProgress}
              />
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('display_name')} alignTitleToTop>
              <TextField
                size="small"
                name={AvailableEncryptionSettings.FRIENDLYNAME}
                value={state.displayName}
                onChange={(e: any) =>
                  eventHandlers.onInputChange(AvailableEncryptionSettings.FRIENDLYNAME)(e.target.value)
                }
                fullWidth
                disabled={state.inProgress}
              />
              <FormSpacer half />
              <HelperText>{formatMessage('display_name_helper')}</HelperText>
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('notification_subject')} alignTitleToTop>
              <TextField
                name={AvailableEncryptionSettings.NOTIFICATION_SUBJECT}
                size="small"
                value={state.notificationSubject}
                onChange={(e: any) =>
                  eventHandlers.onInputChange(AvailableEncryptionSettings.NOTIFICATION_SUBJECT)(e.target.value)
                }
                fullWidth
                disabled={state.inProgress}
              />
              <FormSpacer half />
              <HelperText>{formatMessage('notification_subject_helper')}</HelperText>
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('text_part')} alignTitleToTop>
              <TextareaAutosize
                name={AvailableEncryptionSettings.TXT_NOTIFICATION}
                className={classes.textArea}
                minRows={3}
                value={state.textNotification}
                onChange={(e: any) =>
                  eventHandlers.onInputChange(AvailableEncryptionSettings.TXT_NOTIFICATION)(e.target.value)
                }
                disabled={state.inProgress}
              />
              <FormSpacer half />
              <HelperText>{formatMessage('text_part_helper')}</HelperText>
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('html_part')} alignTitleToTop>
              <TextareaAutosize
                name={AvailableEncryptionSettings.HTML_NOTIFICATION}
                className={classes.textArea}
                minRows={3}
                value={state.htmlNotification}
                onChange={(e: any) =>
                  eventHandlers.onInputChange(AvailableEncryptionSettings.HTML_NOTIFICATION)(e.target.value)
                }
                disabled={state.inProgress}
              />
              <FormSpacer half />
              <HelperText>{formatMessage('html_part_helper')}</HelperText>
            </FormRow>
          </Grid>
        )}
      </FormBlock>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default EditDomainEncryption
