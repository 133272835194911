import { useCallback, useMemo } from 'react'

export interface WarningDialogLogicConfig {
  onCancel: () => void
  onConfirm?: () => void
  onClose?: () => void
  isPending?: boolean
}

export interface WarningDialogLogic {
  onCancel: () => void
  onConfirm?: () => void
  onClose: () => void
}

export const useWarningDialogLogic = ({
  isPending,
  onClose,
  onConfirm,
  onCancel
}: WarningDialogLogicConfig): WarningDialogLogic => {
  const handleClose = useCallback(() => {
    if (!isPending) {
      onClose?.()
    }
  }, [isPending, onClose])

  const handleConfirm = useCallback(() => {
    if (!isPending) {
      onConfirm?.()
    }
  }, [isPending, onConfirm])

  const handleCancel = useCallback(() => {
    if (!isPending) {
      onCancel()
    }
  }, [isPending, onCancel])

  return useMemo(
    () => ({ onClose: handleClose, onConfirm: onConfirm && handleConfirm, onCancel: handleCancel }),
    [handleCancel, handleClose, handleConfirm, onConfirm]
  )
}
