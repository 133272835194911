/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { Alert, Box, Button, Card, CardContent, Grid, LinearProgress, Typography } from '@barracuda-internal/bds-core'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { ResetPasswordLogic } from 'components/pages/actions/resetPassword/useResetPasswordLogic'

const BASE_I18N_KEY = 'ess.reset_password'

export interface SuccessfulResetCardProps {
  logic: ResetPasswordLogic
}

const SuccessfulResetCard = ({ logic }: SuccessfulResetCardProps) => {
  const [state, eventHandlers] = logic
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={0} className={classes.card} data-testid="successful-reset-card">
        {state.isLoading && <LinearProgress data-testid="linear-progress" />}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('title')}</Typography>
            <Typography variant="body1">{formatMessage('successful_reset.title')}</Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Button
                  className={classes.cardFormButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={state.isLoading}
                  onClick={eventHandlers.onClickSignin}
                  data-testid="signin-button"
                >
                  {formatMessage('successful_reset.sign_in')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardWrapper,
      classes.cardHeader,
      classes.cardLogo,
      classes.topError,
      classes.cardFormActionRow,
      classes.cardFormButton,
      state.isLoading,
      state.topErrorMessage,
      formatMessage,
      eventHandlers.onClickSignin
    ]
  )
}

export default SuccessfulResetCard
/* eslint-enable jsx-a11y/anchor-is-valid */
