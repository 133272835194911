/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { EventHandlers, State, UiState } from 'components/pages/login/useLoginLogic'

const BASE_I18N_KEY = 'ess.login'

export type LoginCardProps = {
  state: State
  eventHandlers: EventHandlers
}

function LoginCard(props: LoginCardProps) {
  const { state, eventHandlers } = props
  const classes = styles(bdsTheme)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={0} className={classes.card} data-testid="login-card">
        {(state.isLoading || state.uiState === UiState.INITIALIZING) && (
          <LinearProgress data-testid="linear-progress" />
        )}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('welcome')}</Typography>
            <Typography variant="body1">{formatMessage('welcome_message')}</Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
            {state.topErrorDescription && (
              <Grid item xs={12}>
                <Alert severity="error" className={classes.topError}>
                  {state.topErrorDescription}
                </Alert>
              </Grid>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormEmailRow}>
                <TextField
                  error={state.emailError}
                  fullWidth
                  autoFocus
                  name="user_id"
                  value={state.formValues.user_id}
                  onChange={eventHandlers.onInputChange}
                  disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                  label={formatMessage('email')}
                  helperText={state.emailError ? formatMessage('invalid_email') : ''}
                  data-testid="user-id-input"
                  inputRef={ref => eventHandlers.setAutofocusRef(ref)}
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Button
                  className={classes.cardFormButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                  onClick={eventHandlers.onEmailEnter}
                  innerRef={state.nextButton}
                  data-testid="next-button"
                >
                  {formatMessage('continue')}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link href={state.adminLoginUrl} data-testid="admin-signin-link">
                  <Typography variant="body1">{formatMessage('admin_signin')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardFormActionRow,
      classes.cardFormButton,
      classes.cardFormEmailRow,
      classes.cardFormLinkRow,
      classes.cardHeader,
      classes.cardLogo,
      classes.cardWrapper,
      classes.topError,
      eventHandlers,
      formatMessage,
      state.adminLoginUrl,
      state.emailError,
      state.formValues.user_id,
      state.isLoading,
      state.nextButton,
      state.topErrorDescription,
      state.topErrorMessage,
      state.uiState
    ]
  )
}

export default LoginCard
/* eslint-enable jsx-a11y/anchor-is-valid */
