import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { DomainEntry } from 'types/Settings'
import {
  DomainTableForm,
  DomainTableFormState,
  useDomainTableForm
} from 'components/libs/domainTable/useDomainTableForm'

export interface UseDomainTableLogicProps {
  isLoading?: boolean
  data: DomainEntry[]
  onAddItem: (ip: DomainEntry) => void
  onRemoveItem: (id: string) => void
  shouldHandleEmail?: boolean
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export interface State {
  idToRemove: string
  sort: SortDescriptor[]
  form: DomainTableForm
  formState: DomainTableFormState
  error: string
  tableData: {
    data: DomainEntry[]
    total: number
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onConfirmRemove: () => void
  onConfirmCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSortChange: (e: GridSortChangeEvent) => void
}

export type UseDomainTableLogic = [State, EventHandlers]

const initialSort = [{ field: 'domain', dir: 'asc' } as SortDescriptor]

export const enum DOMAIN_INPUT_NAMES {
  DOMAIN = 'domain',
  COMMENT = 'comment'
}

const INITIAL_FORM_STATE = { domain: '', comment: '' }

const BLANK_IP_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const useDomainTableLogic = ({
  data,
  onAddItem,
  onRemoveItem,
  shouldHandleEmail,
  delegateDirtyFormState
}: UseDomainTableLogicProps): UseDomainTableLogic => {
  const form = useDomainTableForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort)
  const [idToRemove, setIdToRemove] = useState('')

  const onAdd = useCallback(() => {
    const newEntry = {
      ...form.getState(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newEntry, { shouldHandleEmail, data })) {
      onAddItem(newEntry)
    }
  }, [form, shouldHandleEmail, data, onAddItem])

  const onRemove = useCallback(
    (id: string) => {
      setIdToRemove(id)
    },
    [setIdToRemove]
  )

  const onConfirmRemove = useCallback(() => {
    onRemoveItem(idToRemove)
    setIdToRemove('')
  }, [idToRemove, onRemoveItem, setIdToRemove])

  const onConfirmCancel = useCallback(() => {
    setIdToRemove('')
  }, [setIdToRemove])

  const tableData = useMemo(() => {
    const orderedData = orderBy(data, sort)
    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_IP_ENTRY)
    return {
      data: processedData as DomainEntry[],
      total: processedData.length || 0
    }
  }, [data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === DOMAIN_INPUT_NAMES.DOMAIN:
          form.fields.domain.setValue(value)
          break
        case name === DOMAIN_INPUT_NAMES.COMMENT:
          form.fields.comment.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  return useMemo(
    () => [
      {
        idToRemove,
        sort,
        form,
        formState: form.getState(),
        error: form.error,
        tableData
      },
      {
        onAdd,
        onRemove,
        onConfirmRemove,
        onConfirmCancel,
        handleOnInputChange,
        handleSortChange
      }
    ],
    [
      idToRemove,
      sort,
      form,
      tableData,
      onAdd,
      onRemove,
      onConfirmRemove,
      onConfirmCancel,
      handleOnInputChange,
      handleSortChange
    ]
  )
}
