/* eslint-disable quotes */
const DUPLICATE_VALUE = 'Existe una entrada duplicada.'

const inbound_settings = {
  tabs: {
    reputation: 'Anti-Spam/Antivirus',
    custom_rbls: 'RBL Personalizados',
    rate_control: 'Control de Tarifas',
    ip_policies: 'Políticas de Direcciones IP',
    regional_policies: 'Políticas Regionales',
    recipient_policies: 'Políticas de los Beneficiarios',
    sender_policies: 'Políticas del Remitente',
    sender_auth: 'Autenticación del Remitente',
    content_policies: 'Políticas de Contenidos',
    anti_phishing: 'Contra el Phishing',
    machine_learning: 'Machine Learning'
  },
  save: 'Guardar cambios',
  cancel: 'Cancelar',
  bulk_edit: 'Edición masiva',
  reputation: {
    title: 'Anti-Spam/Antivirus',
    block: 'Bloque',
    quarantine: 'Cuarentena',
    off: 'Fuera de',
    yes: 'Sí',
    no: 'No',
    use_reputation_block_list: 'Utilizar la Lista de Bloqueo de Reputación de Barracuda',
    scan_email_or_viruses: 'Buscar virus en el correo electrónico',
    use_real_time: 'Utilice Barracuda Real-Time System',
    send_to_barracuda: 'Enviar contenido de mensajes sospechosos a Barracuda Central para su análisis',
    enable_cloudscan: 'Activar Cloudscan',
    cloudscan_scoring: 'Puntuación de Cloudscan',
    cloudscan_scoring_description:
      'Establezca valores de puntuación para los mensajes escaneados por CloudScan. La puntuación puede establecerse de 1 a 10. Si se establece una puntuación en 0, se desactivará la puntuación de CloudScan.',
    action: 'Acción',
    score: 'Puntuación',
    enabled: 'Activado',
    category_name: 'Nombre de la categoría',
    description: 'Descripción',
    recommended: 'Recomendado',
    allow: 'Permitir',
    bulk_email_detection: 'Detección de correo masivo',
    email_categorization: 'Categorización del correo electrónico',
    emailcat_corporate_name: 'Correo electrónico corporativo',
    emailcat_corporate_description:
      'Correo electrónico enviado desde el servidor Exchange. Comunicaciones corporativas generales. No boletines de marketing',
    emailcat_transactional_name: 'Correo Electrónico Transaccional',
    emailcat_transactional_description:
      'Confirmación de pedidos, facturas, extractos bancarios, facturas, facturas mensuales, avisos de envío de UPS, encuestas relativas a los servicios prestados o al lugar donde se realizaron las transacciones.',
    emailcat_marketing_name: 'Material de marketing y boletines',
    emailcat_marketing_description: 'Contacto constante (ambos niveles)., etc...',
    emailcat_listserver_name: 'Listas de correo',
    emailcat_listserver_description:
      'Correos electrónicos de listas de correo, grupos de noticias y otros servicios basados en suscripciones, como Google y Yahoo! Groups.',
    emailcat_socialmedia_name: 'Redes sociales',
    emailcat_socialmedia_description:
      'Notificaciones y otros correos electrónicos de redes sociales como Facebook y LinkedIn.'
  },
  custom_rbls: {
    title: 'RBL Personalizados',
    zone: 'Zona',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    duplicate_value: 'El dominio ya existe.',
    specify_domain: 'Especifique un nombre de dominio.',
    invalid_domain: 'El nombre de dominio que ha introducido no es válido.'
  },
  rate_control: {
    title: 'Control de Tarifas',
    rate_control_exemptions: 'Exenciones del control de tasas',
    internal_rate_control: 'Control interno de tarifas',
    exempt_rate_control: 'Control de tarifas exento entre dominios de esta cuenta',
    rate_control: 'Control de Tarifas',
    yes: 'Sí',
    no: 'No',
    maximum_messages: 'Mensajes máximos por dirección IP de remitente en 30 minutos',
    default_value: 'Valor por defecto: 1000'
  },
  ip_policies: {
    title: 'Políticas de Direcciones IP',
    ip_blocking_exemption: 'Bloqueo de IP / Exención',
    trusted_forwarders: 'Transitarios de confianza'
  },
  regional_policies: {
    title: 'Políticas Regionales',
    sender_policies: 'Políticas del remitente',
    content_policies: 'Políticas de contenidos',
    geo_policies: 'Políticas de GeoIP',
    language_policies: 'Políticas lingüísticas',
    geo_policies_sender_exmpt_name: 'Exenciones GeoIP por dirección de correo electrónico / dominios',
    geo_policies_exempt_address: 'Exenciones GeoIP por dirección IP del remitente',
    country: 'País',
    language: 'Idioma',
    policy: 'Política',
    comment: 'Comentario',
    actions: 'Acciones',
    block: 'Bloque',
    quarantine: 'Cuarentena',
    add: 'Añadir',
    remove: 'Eliminar',
    duplicate_value: DUPLICATE_VALUE,
    arb: 'Árabe',
    cmn: 'Chino',
    deu: 'Alemán',
    ell: 'Griego',
    heb: 'Hebreo',
    jpn: 'Japonés',
    kor: 'Coreano',
    rus: 'Ruso',
    tha: 'Tailandés',
    tur: 'Turco',
    vie: 'Vietnamita',
    invalid_email: 'Introduzca una dirección de correo electrónico válida.'
  },
  recipient_policies: {
    title: 'Políticas de los Beneficiarios',
    default_policy_description: 'Política por defecto para todos los usuarios:',
    recipient: 'Destinatario',
    policy: 'Política',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    scan: 'Escanear',
    exempt: 'Exento',
    save: 'Guardar',
    invalid_email: 'Introduzca una dirección de correo electrónico válida.',
    duplicate_value: DUPLICATE_VALUE
  },
  sender_policies: {
    title: 'Políticas del Remitente',
    sender: 'Remitente',
    policy: 'Política',
    modified: 'Modificado',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    block: 'Bloque',
    exempt: 'Exento',
    quarantine: 'Cuarentena',
    invalid_email: 'Introduzca una dirección de correo electrónico válida.',
    duplicate_value: DUPLICATE_VALUE
  },
  sender_auth: {
    title: 'Autenticación del Remitente',
    bulk_edit: 'Edición masiva',
    dmarc_dkim_spf_header: 'DMARC, DKIM & SPF',
    yes: 'Sí',
    no: 'No',
    block: 'Bloque',
    quarantine: 'Cuarentena',
    off: 'Fuera de',
    dmarc_label: 'Use Domain Based Message Authentication (DMARC)',
    dmarc_note:
      'Note: Senders with a DMARC record and policy of "quarantine" or "reject" will override DKIM and SPF settings below.',
    dmarc_table: 'Exento de DMARC',
    dkim_label: 'Acción de correo identificado por claves de dominio (DKIM)',
    dkim_table: 'Exento de DKIM',
    spf_label: 'Activar la comprobación del Marco de directivas del remitente (SPF)',
    spf_exempt: 'Exenciones del SPF por dirección IP',
    spf_domain_exempt: 'Exenciones del SPF por dominio',
    hard_fall: 'Caída dura',
    soft_fall: 'Caída suave',
    spf_missing_label: 'Bloquear si no hay registros SPF',
    spf_missing_table: 'Exenciones SPF que faltan',
    ptr_header: 'Registros PTR',
    ptr_label: 'Bloqueo por ausencia de registros PTR',
    ptr_table: 'Exenciones PTR que faltan'
  },
  content_policies: {
    title: 'Políticas de Contenidos',
    attachment_filter: 'Filtro de fijación',
    filter_type: 'Tipo de filtro',
    pattern: 'Patrón',
    check_archives: 'Comprobar archivos',
    action: 'Acción',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    no: 'No',
    yes: 'Sí',
    scan: 'Escanear',
    do_not_scan: 'No escanear',
    block: 'Bloque',
    ignore: 'Ignore',
    allow: 'Permitir',
    quarantine: 'Cuarentena',
    filename: 'Nombre del archivo',
    mime: 'Tipo MIME',
    message_content_filter: 'Filtro de contenido de mensajes',
    subject: 'Asunto',
    headers: 'Cabeceras',
    body: 'Cuerpo',
    attachments: 'Archivos adjuntos',
    sender: 'Remitente',
    recipient: 'Destinatario',
    match: 'Partido',
    one_match_filter: 'Debe seleccionarse al menos un filtro de coincidencia.',
    duplicate_value: DUPLICATE_VALUE,
    archive_filtering: 'Filtrado de archivos protegido por contraseña:',
    msoffice_filtering: 'Documentos de Microsoft Office protegidos con contraseña:',
    pdf_filtering: 'Filtrado de PDF protegidos por contraseña:',
    save: 'Guardar',
    missing_pattern: 'Falta el patrón de filtrado'
  },
  anti_phishing: {
    title: 'Contra el Phishing',
    bafi_label: 'Inteligencia antifraude',
    sender_label: 'Aviso de remitente externo',
    block: 'Bloque',
    quarantine: 'Cuarentena',
    ignore: 'Ignore',
    defer: 'Defer',
    off: 'Fuera de',
    yes: 'Sí',
    no: 'No',
    on: 'En',
    intent: 'Intención',
    intent_label: 'Análisis de intenciones',
    content_label: 'Intención de contenido',
    intent_realtime_label: 'Utilice el análisis de intenciones en tiempo real',
    link_label: 'Protección de enlaces',
    typosquat_label: 'Protección contra la "typosquatting',
    intent_domain_table: 'Políticas de dominio de intención',
    domain: 'Nombre de dominio',
    policy: 'Política',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    duplicate_value: 'El dominio {dominio} ya existe.',
    specify_domain: 'Especifique un nombre de dominio.',
    invalid_domain: 'El nombre de dominio que ha introducido no es válido.',
    email_banners: 'Banners de advertencia por correo electrónico',
    insert_email_banners: 'Insertar pancartas de advertencia por correo electrónico',
    testing_mode: 'Modo de prueba',
    email_banners_testing_mode_text:
      '¿No está seguro de cómo funcionan los banners de aviso por correo electrónico? Utilice el modo de prueba para probar esta función en un número selecto de usuarios.',
    testing_mode_enable: 'Habilitar para los siguientes correos electrónicos de usuario',
    testing_mode_placeholder: 'Ex: hello@demo.com,abc@demo.com',
    duplicate_email: 'Correo electrónico duplicado: {emails}',
    invalid_email: 'Correo electrónico inválido: {emails}',
    blank_email: 'Introduzca su dirección de correo electrónico',
    beta_version: 'Versión beta'
  },
  machine_learning: {
    title: 'Aprendizaje automático',
    machine_learning_exempt: 'Exención de aprendizaje automático por dirección de correo electrónico/dominio',
    email_domain: 'Correo electrónico/Dominio',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    invalid_email_domain: 'El dominio/correo electrónico que ha introducido no es válido.',
    duplicate_value: 'El dominio ya existe.',
    specify_domain: 'Especifique un nombre de dominio.',
    invalid_domain: 'El nombre de dominio que ha introducido no es válido.'
  }
}
/* eslint-enable quotes */

export default inbound_settings
