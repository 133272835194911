export enum Resource {
  TENANT_STATUS = 'tenantstatus',
  ADMIN_CONSENT = 'adminconsent',
  DISCONNECT = 'disconnect',
  SYNC_STATUS = 'syncstatus',
  SYNC_NOW = 'syncnow',
  LOOKUP_USER = 'lookupuser'
}

export enum TenantStatus {
  ACTIVE = 'Active',
  INACTIVE = 'InActive'
}

export enum SyncStatus {
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  EMPTY = ''
}

export enum SyncMode {
  MANUAL = 'manual',
  AUTO = 'auto'
}

export interface SyncUserStats {
  updated: number
  created: number
  deleted: number
}

export interface SyncStats {
  lastSyncTime: number
  lastUserStats: SyncUserStats
  userStats: SyncUserStats
  syncStatus: SyncStatus
}

export enum TenantAuthorizationStatus {
  AUTHORIZED,
  UNAUTHORIZED,
  UNKNOWN,
  ERROR
}

export enum SyncNowStatus {
  SYNC_ALREADY_IN_PROGRESS,
  SYNC_STARTED
}

export enum AzureUserStatus {
  PRESENT,
  NOT_PRESENT
}

export enum DisconnectResult {
  SUCCESS,
  FAILURE
}
