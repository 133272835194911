import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  EMAIL_LOG: {
    path: 'email-log',
    legacyPath: '',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [AppTypes.admin]
    },
    metadata: {
      sideMenuId: 'EMAIL_LOG'
    }
  }
}
