import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  editCell: {
    '& .k-textbox': {
      backgroundColor: `${theme.palette.common.white} !important`,
      border: `1px solid ${colors.barracudaBlack30} !important`,
      boxSizing: 'border-box',
      borderRadius: '2px'
    }
  },
  table: {
    '& .k-i-sort-desc-sm:before': {
      content: '"\\25BE" !important',
      paddingBottom: 6
    },
    '& .k-i-sort-asc-sm:before': {
      content: '"\\25BE" !important',
      transform: 'rotate(180deg) !important',
      paddingBottom: 2
    },
    '& th': {
      textTransform: 'uppercase',
      color: colors.statusInformational,
      lineHeight: '16px',
      padding: 10,
      fontSize: '12px',
      borderColor: colors.uiElement,
      borderLeft: '1 solid transparent',
      borderRight: '1 solid transparent'
    },
    '& td': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 24
    },
    '& tr': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      '&.k-state-selected': {
        backgroundColor: colors.blue050
      },
      '& button': {
        fontSize: '12px',
        boxShadow: 'none',
        height: 32,
        marginBottom: 1
      }
    }
  },
  actionButtonWrapper: {
    border: '1px solid green',

    '&.disabled': {
      border: '1px solid red'
    }
  },
  actionButton: {
    width: 80
  },
  progress: {
    height: 3,
    marginTop: 0
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  subTitle: {
    color: colors.barracudaBlack50
  }
}))
