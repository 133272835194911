import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundContentPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
        unless you change these settings for a specific domain. In that case, the domain-level settings override the
        global system settings.
      </p>
      <br />
      <br />
      <p>
        Content filtering controls message delivery based on characteristics of the message&apos;s Subject, Header,
        Body, Sender, Recipient and Attachment(s). You can specify simple words or phrases when you create filters, then
        choose where you want to apply those filters for outbound messages.{' '}
        <b>Note: Content filtering is NOT case sensitive</b>.
      </p>
      This page includes the following sections:
      <br />
      <a href="#outbound_attachment_filter">Attachment Filters</a>
      <br />
      <a href="#outbound_message_content_filter">Message Content Filters</a>
      <br />
      <a href="#outbound_predefined_filters">Predefined Filters</a>
      <br />
      <a href="#outbound_predefined_filter_exceptions">Predefined Filter Exceptions</a>
      <br />
      <br />
      <h3 id="outbound_attachment_filter">Attachment Filters</h3>
      <p>
        You can choose to take certain actions with outbound messages if they contain attachments with certain file name
        patterns or MIME types as described below.
      </p>
      <h4>File Name Filtering</h4>
      <p>
        For outbound mail, add the attachment filename patterns you want to <b>Block</b>, <b>Ignore</b>, or{' '}
        <b>Quarantine</b>. Enter filename patterns using <b>*</b> as a wildcard, then indicate which action to take for
        attachments that match your filename patterns, for example:
      </p>
      <ul>
        <li>To take an action for all .zip files, specify *.zip</li>
        <li>To take an action with all files that include the word jobs, enter *jobs* </li>
      </ul>
      <p>
        Actions you specify for outbound file name patterns apply to files found in .zip and other archive files if you
        select <b>Scan</b> in the <b>Archive Files Content</b>
        dropdown.
      </p>
      <h4>MIME Type Filtering</h4>
      <p>
        Specify MIME types, using the formats of the examples in the table below, and select either the <i>Block</i> or{' '}
        <i>Ignore</i> action to take with email messages with attachments.
      </p>
      <p>
        <b>Note:</b> Regular expressions may NOT be used.
      </p>
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Filter Type</li>
        <li>Pattern</li>
        <li>Check Archives</li>
        <li>Action</li>
        <li>Comment</li>
      </ul>
      <h3>Example MIME Types</h3>
      <b>Example MIME Types include:</b>
      <ul className={classes.noListStyle}>
        <li>application/msword</li>
        <li>application/octet-stream</li>
        <li>application/x-gzip</li>
        <li>application/java-archive</li>
        <li>application/x-javascript</li>
        <li>application/pdf</li>
        <li>application/vnd.ms-powerpoint</li>
        <li>image/gif</li>
        <li>image/jpeg</li>
        <li>image/png</li>
        <li>video/quicktime</li>
        <li>audio/mpeg</li>
      </ul>
      <h4>Password Protected Archive Filtering</h4>
      From the drop-down menu, select the action to take with attached archive files that require a password to unpack:{' '}
      <b>Block</b>, <b>Ignore</b>, or <b>Quarantine</b>. Choosing <b>Ignore</b>
      means that the service does not look for, or act on, emails with attachments that require a password to unpack.
      <br />
      <br />
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Pattern</li>
        <li>Action</li>
      </ul>
      <h4>Password Protected Microsoft Documents</h4>
      From the drop-down menu, select the action to take with messages containing password protected Microsoft
      attachments: <b>Block</b>, <b>Ignore</b>, or <b>Quarantine</b>. Choosing <b>Ignore</b> means that the service does
      not look for, or act on, emails that contain password protected Microsoft documents.
      <br />
      <br />
      <h4>Password Protected PDF Documents</h4>
      From the drop-down menu, select the action to take with messages containing password protected PDF attachments:{' '}
      <b>Block</b>, <b>Ignore</b>, or <b>Quarantine</b>. Choosing <b>Ignore</b> means that the service does not look
      for, or act on, emails that contain password protected PDF documents.
      <br />
      <br />
      <h3 id="outbound_message_content_filter">Message Content Filters</h3>
      <p>
        [[regex]] can be used. Additionally, the following <b>special characters</b> can be used by escaping (preceding
        the character) with a backslash (&quot;\&quot;):
      </p>
      <div className={classes.quote}>
        . &nbsp; [ &nbsp; ] &nbsp; \ &nbsp; * &nbsp; ? &nbsp; $ &nbsp; ( &nbsp; ) &nbsp; | &nbsp; ^ &nbsp; @
      </div>
      <p>
        Enter filter patterns, one per line, selecting either <b>Block</b>, <b>Allow</b>, <b>Quarantine</b>,{' '}
        <b>Encrypt</b>, or <b>Do not encrypt</b> for <b>Subject</b>, <b>Headers</b>,<b>Body</b>, <b>Attachments</b>,{' '}
        <b>Sender</b> address, or <b>Recipient</b> address.
      </p>
      <p>
        <b>Headers</b> filters are applied to both the header name and content of any header while the <b>Subject</b>,{' '}
        <b>Sender</b>, and <b>Recipient</b> filters only scan the contents of those header fields (if present).
      </p>
      <p>
        <b>Attachments</b> content filters are limited to text type files such as most MS Office files, HTML, pdf files,
        and other document files.
      </p>
      <p>
        <b>Note</b>: When selecting <b>Do not encrypt</b> on a <b>Message Content Filter</b> and <b>Encrypt</b> on a{' '}
        <b>Predefined Filter</b>, the Message Content Filter exemption takes precedence over the Predefined Filter and
        the message <i>will not</i> be encrypted. For more information on order precedence of content filtering, see{' '}
        <a href="https://campus.barracuda.com/doc/96022964/" target="_new">
          Content Analysis - Outbound Mail
        </a>{' '}
        in Barracuda Campus.
      </p>
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Pattern</li>
        <li>Action</li>
      </ul>
      <h3 id="outbound_predefined_filters">Predefined Filters for Data Leakage Prevention (DLP)</h3>
      <p>
        You can take actions with outbound messages that contain matches to the following pre-made patterns in the
        subject line, message body, or attachment. Select the <b>Pattern</b> type, the <b>Action</b> (<b>Block</b>,{' '}
        <b>Quarantine</b>, or <b>Encrypt</b>), and then check any or all of the <b>Match</b> categories: <b>Subject</b>,{' '}
        <b>Headers</b>, <b>Body</b>, and <b>Attachment</b>. The patterns are inserted into the keyword listings and
        include Credit Card, Social Security Numbers, Privacy, and HIPAA formats. To best secure transmission of
        messages containing this type of data, select <b>Encrypt</b> from the <b>Action</b> drop-down menu for each
        type.
        <br />
        <br />
        <b>Note:</b> The format of this data varies depending on the country, and these filters are more commonly used
        in the U.S.; they do not apply to other locales.
      </p>
      <ul>
        <li>
          <b>Credit Cards</b>
          <br />
          Messages sent through {props.productName} containing recognizable Master Card, Visa, American Express, Diners
          Club, or Discover card numbers are subject to the selected action.
        </li>
        <li>
          <b>Social Security Numbers</b>
          <br />
          Messages sent with valid social security numbers are subject to the selected action. U.S. Social Security
          Numbers (SSN) must be entered in the format nnn-nn-nnnn or nnn nn nnnn.
        </li>
        <li>
          <b>Privacy</b>
          <br />
          Messages are subject to the selected action if they contain two or more of the following data types, using
          common U.S. data patterns only: credit cards (including Japanese Credit Bureau), expiration date, date of
          birth, Social Security number, driver license number, street address, or phone number. Phone numbers must be
          entered in the format <code>nnn-nnn-nnnn</code> or <code>(nnn)nnn-nnnn</code> or <code>nnn.nnn.nnnn</code>.
        </li>
        <li>
          <b>HIPAA</b>
          <br />
          Messages are subject to the selected action if they contain TWO of the types of items as described in{' '}
          <b>Privacy</b> above and ONE medical term, or ONE <b>Privacy</b>
          item, ONE Address and ONE medical term. For example, a U.S. Social Security number, a street address, and one
          medical term is enough to trigger the HIPAA filter. For a list of medical vocabulary sources used by the HIPAA
          dictionary, see{' '}
          <a href="https://campus.barracuda.com/doc/96022963/" target="_new">
            Medical Dictionary Source for DLP HIPAA Compliance
          </a>{' '}
          in Barracuda Campus.
        </li>
      </ul>
      <p>
        Because of the millions of ways that any of the above information can be formatted, a determined person will
        likely be able to find a way to defeat the patterns used above. These filter options are no match for educating
        employees about what is and is not permissible for transmission via unencrypted email.
      </p>
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Pattern</li>
        <li>Action</li>
      </ul>
      <h3 id="outbound_predefined_filter_exceptions">Predefined Filter Exceptions</h3>
      <p>
        Create exceptions to <b>Predefined Filter</b> policies defined on this page to prevent outbound emails that
        include phone number (<b>Privacy</b>, <b>HIPAA</b>) and/or street address (<b>HIPAA</b>) items from being
        blocked, quarantined, or encrypted. Street address must be an exact match. For phone number exceptions, the
        digits must be an exact match, but the location of separators is ignored. You can use any of these separators in
        the phone number, including a blank space:
        <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;<b> . &nbsp; ( &nbsp; ) &nbsp; -</b>
        <br />
        <br />
        For example, allow outbound emails that include the main company phone number, where there is a Predefined
        Filter blocking based on <b>Privacy</b> and <b>HIPAA</b>
        information. To create an exception so that emails including this phone number are not blocked:
      </p>
      <ol>
        <li>
          Select <b>Type</b> as <b>Phone Number</b>.
        </li>
        <li>
          Enter the complete phone number in the <b>Pattern</b> field.
        </li>
        <li>
          Click <b>Add</b>.
        </li>
      </ol>
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Pattern</li>
        <li>Type</li>
      </ul>
    </div>
  )
}

export default OutboundContentPolicies
