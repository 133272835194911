import React, { useMemo } from 'react'

import { Button, Grid } from '@barracuda-internal/bds-core'
import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import IpAddressTable from 'components/libs/ipAddressTable/IpAddressTable'
import { useIpPoliciesLogic } from 'components/pages/inboundSettings/ipPolicies/useIpPoliciesLogic'
import InboundIpAddressPolicies from 'components/pages/support/help/inboundIpAddressPolicies/InboundIpAddressPolicies'
import { PageTitle, FormSpacer, PageSubtitle } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.inbound_settings.ip_policies'
const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'

const IpPolicies: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)
  const [state, eventHandlers] = useIpPoliciesLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundIpAddressPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.ipAddressPolicies,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <PageSubtitle>{formatMessage('ip_blocking_exemption')}</PageSubtitle>
        <Grid container>
          <Grid container direction="row">
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEditIpPolicy}
              variant="contained"
              size="small"
              data-testid="bulk-button-ip"
            >
              {formatInboundMessage('bulk_edit')}
            </Button>
            <IpAddressTable
              data={state.ipExemption}
              defaultNetmask
              shouldShowPolicy
              onAddItem={eventHandlers.onAddIpPolicy}
              onRemoveItem={eventHandlers.onRemoveIpPolicy}
              delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
            />
          </Grid>
        </Grid>
        <FormSpacer />
        <FormSpacer />
        <PageSubtitle>{formatMessage('trusted_forwarders')}</PageSubtitle>
        <Grid container>
          <Grid container direction="row">
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEditTrustedForwarder}
              variant="contained"
              size="small"
              data-testid="bulk-button-forwarders"
            >
              {formatInboundMessage('bulk_edit')}
            </Button>
            <IpAddressTable
              data={state.trustedForwarders}
              defaultNetmask
              onAddItem={eventHandlers.onAddTrustedForwarder}
              onRemoveItem={eventHandlers.onRemoveTrustedForwarder}
              delegateDirtyFormState={eventHandlers.setIsIpAddressTableDirty}
            />
          </Grid>
        </Grid>
      </SettingsPageContainer>
    ),
    [state, formatMessage, eventHandlers, inboundClasses.bulkEdit, formatInboundMessage]
  )
}

export default IpPolicies
