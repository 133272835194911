export enum AppTypes {
  enduser = 'enduser',
  admin = 'admin',
  wizard = 'wizard',
  cudaspt = 'cudespt',
  helpdesk = 'helpdesk',
  domain = 'domain',
  pdDomainId = 'pdDomainId',
  helpdeskWithPdDomainId = 'helpdeskWithPdDomainId',
  domainWithPdDomainId = 'domainWithPdDomainId',
  cplAccount = 'cplAccount'
}
