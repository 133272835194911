import { useCallback, useMemo, useState } from 'react'
import { SettingsFormField } from 'lib/settingsForm/useSettingsForm'

export const useStateField = (initialValue: string): SettingsFormField => {
  const [fieldValue, setFieldValue] = useState(initialValue)

  const reset = useCallback(() => {
    setFieldValue(initialValue)
  }, [initialValue])

  return useMemo(
    () => ({
      getValue: () => fieldValue,
      setValue: setFieldValue,
      reset,
      ref: undefined
    }),
    [fieldValue, reset]
  )
}
