import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ContentRect } from 'react-measure'
import { defaultSplitterValues } from '../config'
import { MeasuredDimensions, SplitProps } from '../splitterTypes'

export interface UseSplitLogic {
  renderSizes: {
    primary: string
    minPrimary: string
    minSecondary: string
  }
  renderSplitterProps: {
    pixelSize: number
    horizontal: boolean
    dragging: boolean
  }
  onMeasureContent: (contentRect: ContentRect) => void
  onMeasurePrimary: (contentRect: ContentRect) => void
  onMeasureSplitter: (contentRect: ContentRect) => void
  onSplitPointerUp: (event: React.PointerEvent<HTMLDivElement>) => void
  onSplitPointerDown: (event: React.PointerEvent<HTMLDivElement>) => void
  onSplitPointerMove: (event: React.PointerEvent<HTMLDivElement>) => void
  onSplitDoubleClick: () => void
}

export const useSplitLogic = (props: SplitProps): UseSplitLogic => {
  const {
    horizontal = false,
    initialPrimarySize = defaultSplitterValues.initialPrimarySize,
    minPrimarySize = defaultSplitterValues.minPrimarySize,
    minSecondarySize = defaultSplitterValues.minSecondarySize,
    resetOnDoubleClick = defaultSplitterValues.resetOnDoubleClick,
    showSplitter: shouldShowSecond
  } = props

  const [contentMeasuredDimensions, setContentMeasuredDimensions] = useState<MeasuredDimensions>({
    height: 0,
    width: 0
  })
  const [primaryMeasuredDimensions, setPrimaryMeasuredDimensions] = useState<MeasuredDimensions>({
    height: 0,
    width: 0
  })
  const [splitterMeasuredDimensions, setSplitterMeasuredDimensions] = useState<MeasuredDimensions>({
    height: 0,
    width: 0
  })

  const currentContentSize = useMemo(
    () => (horizontal ? contentMeasuredDimensions.height : contentMeasuredDimensions.width),
    [horizontal, contentMeasuredDimensions]
  )
  const currentPrimarySize = useMemo(
    () => (horizontal ? primaryMeasuredDimensions.height : primaryMeasuredDimensions.width),
    [horizontal, primaryMeasuredDimensions]
  )
  const currentSplitterSize = useMemo(
    () => (horizontal ? splitterMeasuredDimensions.height : splitterMeasuredDimensions.width),
    [horizontal, splitterMeasuredDimensions]
  )

  const [percent, setPercent] = useState<number | undefined>(undefined)

  const [clientStart, setClientStart] = useState(0)
  const [primaryStart, setPrimaryStart] = useState(0)
  const [dragging, setDragging] = useState(false)

  useEffect(() => {
    if (shouldShowSecond) {
      setPercent(50)
    } else {
      setPercent(100)
    }
  }, [shouldShowSecond])

  const onMeasureContent = useCallback((contentRect: ContentRect) => {
    // eslint-disable-next-line no-unused-expressions
    contentRect.bounds &&
      setContentMeasuredDimensions({
        height: contentRect.bounds.height,
        width: contentRect.bounds.width
      })
  }, [])

  const onMeasurePrimary = useCallback((contentRect: ContentRect) => {
    // eslint-disable-next-line no-unused-expressions
    contentRect.bounds &&
      setPrimaryMeasuredDimensions({
        height: contentRect.bounds.height,
        width: contentRect.bounds.width
      })
  }, [])

  const onMeasureSplitter = useCallback((contentRect: ContentRect) => {
    // eslint-disable-next-line no-unused-expressions
    contentRect.bounds &&
      setSplitterMeasuredDimensions({
        height: contentRect.bounds.height,
        width: contentRect.bounds.width
      })
  }, [])

  const onSplitPointerUp = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
    event.currentTarget.releasePointerCapture(event.pointerId)
    setDragging(false)
  }, [])

  const onSplitPointerDown = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      event.currentTarget.setPointerCapture(event.pointerId)
      setClientStart(horizontal ? event.clientY : event.clientX)
      setPrimaryStart(currentPrimarySize)
      setDragging(true)
    },
    [horizontal, currentPrimarySize]
  )

  const onSplitPointerMove = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      if (event.currentTarget.hasPointerCapture(event.pointerId)) {
        const position = horizontal ? event.clientY : event.clientX
        const primarySize = primaryStart + (position - clientStart)
        const newPrimary = Math.max(0, Math.min(primarySize, currentContentSize))
        const newPercent = (newPrimary / currentContentSize) * 100

        // Ensure that both elements are visible
        if (newPercent > 95) {
          return
        }

        setPercent(newPercent)
      }
    },
    [clientStart, horizontal, currentContentSize, primaryStart]
  )

  const renderSizes = useMemo(
    () => ({
      primary: percent !== undefined ? `${percent}%` : initialPrimarySize,
      minPrimary: minPrimarySize,
      minSecondary: minSecondarySize
    }),
    [percent, minPrimarySize, minSecondarySize, initialPrimarySize]
  )

  const renderSplitterProps = useMemo(
    () => ({
      pixelSize: currentSplitterSize,
      horizontal,
      dragging
    }),
    [currentSplitterSize, horizontal, dragging]
  )

  const onSplitDoubleClick = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    resetOnDoubleClick && setPercent(undefined)
  }, [resetOnDoubleClick])

  return useMemo(
    () => ({
      renderSizes,
      renderSplitterProps,
      onMeasureContent,
      onMeasurePrimary,
      onMeasureSplitter,
      onSplitPointerUp,
      onSplitPointerDown,
      onSplitPointerMove,
      onSplitDoubleClick
    }),
    [
      renderSizes,
      renderSplitterProps,
      onMeasureContent,
      onMeasurePrimary,
      onMeasureSplitter,
      onSplitPointerUp,
      onSplitPointerDown,
      onSplitPointerMove,
      onSplitDoubleClick
    ]
  )
}
