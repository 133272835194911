import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'

import {
  deleteAtp,
  getAtpFiletypes,
  getAtpSearch,
  getAtpOverview,
  AtpLogResult,
  getAtpReport
} from 'redux/features/atp/atpApiThunks'
import { AtpOverview, AtpSearch, ATP_FILE_TYPES } from 'types/Atp'
import { HardResetReport, resetReport } from 'redux/features/dataTables/reduceHandlers'

const ALL_FILTER = 'all'
export interface AtpState {
  getAtpLogStatus: ApiStatus
  getAtpOverviewStatus: ApiStatus
  getAtpFiletypesStatus: ApiStatus
  deleteAtpLogStatus: ApiStatus
  getAtpReportStatus: ApiStatus

  loadedSearchOffsets: number[]
  searchTerm: AtpSearch
  search: AtpLogResult | Record<string, never>

  overview: AtpOverview
  filetypes: Array<keyof typeof ATP_FILE_TYPES>
}

// initialState
export const INITIAL_STATE: AtpState = {
  getAtpLogStatus: inIdle,
  getAtpOverviewStatus: inIdle,
  getAtpFiletypesStatus: inIdle,
  deleteAtpLogStatus: inIdle,
  getAtpReportStatus: inIdle,

  loadedSearchOffsets: [],
  searchTerm: {
    searchStr: '',
    status: ALL_FILTER,
    time: 30,
    filetype: ATP_FILE_TYPES.all,
    messageId: ''
  },
  search: {},

  overview: {
    infectedCount: 0,
    suspiciousCount: 0
  },
  filetypes: []
}

/* eslint-disable no-param-reassign */
export const atpSlice = createSlice({
  name: 'ATP',
  initialState: INITIAL_STATE,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<Partial<AtpSearch>>) => {
      state.searchTerm = { ...state.searchTerm, ...action.payload }
    },
    resetSearch: (state, action: PayloadAction<HardResetReport>) => {
      state.search = resetReport(state.searchTerm, action.payload) || INITIAL_STATE.search
      state.loadedSearchOffsets = INITIAL_STATE.loadedSearchOffsets
      state.getAtpLogStatus = inIdle
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // atpLog
      .addCase(getAtpSearch.pending, state => {
        state.getAtpLogStatus = inProgress
        state.search = INITIAL_STATE.search
      })
      .addCase(getAtpSearch.fulfilled, (state, action) => {
        state.getAtpLogStatus = successResponse
        state.search = action.payload
      })
      .addCase(getAtpSearch.rejected, (state, action) => {
        state.getAtpLogStatus = failedResponse(action.payload)
      })

      // atpOverview
      .addCase(getAtpOverview.pending, state => {
        state.getAtpOverviewStatus = inProgress
      })
      .addCase(getAtpOverview.fulfilled, (state, action) => {
        state.getAtpOverviewStatus = successResponse
        state.overview = action.payload
      })
      .addCase(getAtpOverview.rejected, (state, action) => {
        state.getAtpOverviewStatus = failedResponse(action.payload)
      })

      // atpFiletypes
      .addCase(getAtpFiletypes.pending, state => {
        state.getAtpFiletypesStatus = inProgress
      })
      .addCase(getAtpFiletypes.fulfilled, (state, action) => {
        state.getAtpFiletypesStatus = successResponse
        state.filetypes = action.payload ? action.payload.sort() : []
      })
      .addCase(getAtpFiletypes.rejected, (state, action) => {
        state.getAtpFiletypesStatus = failedResponse(action.payload)
      })

      // atpFiletypes
      .addCase(deleteAtp.pending, state => {
        state.deleteAtpLogStatus = inProgress
      })
      .addCase(deleteAtp.fulfilled, state => {
        state.deleteAtpLogStatus = successResponse
      })
      .addCase(deleteAtp.rejected, (state, action) => {
        state.deleteAtpLogStatus = failedResponse(action.payload)
      })

      // atpReport
      .addCase(getAtpReport.pending, state => {
        state.getAtpReportStatus = inProgress
      })
      .addCase(getAtpReport.fulfilled, state => {
        state.getAtpReportStatus = successResponse
      })
      .addCase(getAtpReport.rejected, (state, action) => {
        state.getAtpReportStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { setSearchTerm, resetSearch, reset } = atpSlice.actions

export { getAtpSearch, getAtpOverview, getAtpFiletypes, deleteAtp, getAtpReport }

export default atpSlice.reducer
