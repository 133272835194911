/* eslint-disable quotes */
const DUPLICATE_VALUE = 'Duplicate entry exists.'

const inbound_settings = {
  tabs: {
    reputation: 'Anti-Spam/Antivirus',
    custom_rbls: 'Custom RBLs',
    rate_control: 'Rate Control',
    ip_policies: 'IP Address Policies',
    regional_policies: 'Regional Policies',
    recipient_policies: 'Recipient Policies',
    sender_policies: 'Sender Policies',
    sender_auth: 'Sender Authentication',
    content_policies: 'Content Policies',
    anti_phishing: 'Anti-Phishing',
    machine_learning: 'Machine Learning'
  },
  save: 'Save Changes',
  cancel: 'Cancel',
  bulk_edit: 'Bulk Edit',
  reputation: {
    title: 'Anti-Spam/Antivirus',
    block: 'Block',
    quarantine: 'Quarantine',
    off: 'Off',
    yes: 'Yes',
    no: 'No',
    use_reputation_block_list: 'Use Barracuda Reputation Block List',
    scan_email_or_viruses: 'Scan Email for Viruses',
    use_real_time: 'Use Barracuda Real-Time System',
    send_to_barracuda: 'Send suspicious message content to Barracuda Central for analysis',
    enable_cloudscan: 'Enable Cloudscan',
    cloudscan_scoring: 'Cloudscan Scoring',
    cloudscan_scoring_description:
      'Set score values for messages scanned by CloudScan. Scoring can be set from 1 to 10. Setting a score to 0 will disable CloudScan scoring.',
    action: 'Action',
    score: 'Score',
    enabled: 'Enabled',
    category_name: 'Category Name',
    description: 'Description',
    recommended: 'Recommended',
    allow: 'Allow',
    bulk_email_detection: 'Bulk Email Detection',
    email_categorization: 'Email Categorization',
    emailcat_corporate_name: 'Corporate Email',
    emailcat_corporate_description:
      'Email sent from exchange server. General corporate communications. No Marketing newsletters',
    emailcat_transactional_name: 'Transactional Email',
    emailcat_transactional_description:
      'Order confirmation, bills, bank statements, invoices, monthly bills, UPS shipping notices, surveys relating to services rendered and or where transactions took place.',
    emailcat_marketing_name: 'Marketing Materials and Newsletters',
    emailcat_marketing_description: 'Constant-contact (both levels)., etc...',
    emailcat_listserver_name: 'Mailing Lists',
    emailcat_listserver_description:
      'Emails from mailing lists, newsgroups, and other subscription-based services such as Google and Yahoo! Groups.',
    emailcat_socialmedia_name: 'Social Media',
    emailcat_socialmedia_description:
      'Notifications and other emails from social media sites such as Facebook and LinkedIn.'
  },
  custom_rbls: {
    title: 'Custom RBLs',
    zone: 'Zone',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    duplicate_value: 'The domain already exists.',
    specify_domain: 'Please specify a domain name.',
    invalid_domain: 'The domain name you have entered is not valid.'
  },
  rate_control: {
    title: 'Rate Control',
    rate_control_exemptions: 'Rate Control Exemptions',
    internal_rate_control: 'Internal Rate Control',
    exempt_rate_control: 'Exempt Rate Control Between Domains In This Account',
    rate_control: 'Rate Control',
    yes: 'Yes',
    no: 'No',
    maximum_messages: 'Maximum Messages per Sender IP Address per 30 minutes',
    default_value: 'Default Value: 1000'
  },
  ip_policies: {
    title: 'IP Address Policies',
    ip_blocking_exemption: 'IP Blocking / Exemption',
    trusted_forwarders: 'Trusted Forwarders'
  },
  regional_policies: {
    title: 'Regional Policies',
    sender_policies: 'Sender Policies',
    content_policies: 'Content Policies',
    geo_policies: 'GeoIP Policies',
    language_policies: 'Language Policies',
    geo_policies_sender_exmpt_name: 'GeoIP Exemptions by Email Address / Domains',
    geo_policies_exempt_address: 'GeoIP Exemptions by Sender IP Address',
    email_domain: 'Email Address / Domain',
    country: 'Country',
    language: 'Language',
    policy: 'Policy',
    comment: 'Comment',
    actions: 'Actions',
    block: 'Block',
    quarantine: 'Quarantine',
    add: 'Add',
    remove: 'Remove',
    duplicate_value: DUPLICATE_VALUE,
    arb: 'Arabic',
    cmn: 'Chinese',
    deu: 'German',
    ell: 'Greek',
    heb: 'Hebrew',
    jpn: 'Japanese',
    kor: 'Korean',
    rus: 'Russian',
    tha: 'Thai',
    tur: 'Turkish',
    vie: 'Vietnamese',
    invalid_email: 'Please enter a valid email address.'
  },
  recipient_policies: {
    title: 'Recipient Policies',
    default_policy_description: 'Default policy for all users:',
    recipient: 'Recipient',
    policy: 'Policy',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    scan: 'Scan',
    exempt: 'Exempt',
    save: 'Save',
    invalid_email: 'Please enter a valid email address.',
    duplicate_value: DUPLICATE_VALUE
  },
  sender_policies: {
    title: 'Sender Policies',
    sender: 'Sender',
    policy: 'Policy',
    modified: 'Modified',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    block: 'Block',
    exempt: 'Exempt',
    quarantine: 'Quarantine',
    invalid_email: 'Please enter a valid email address.',
    duplicate_value: DUPLICATE_VALUE
  },
  sender_auth: {
    title: 'Sender Authentication',
    bulk_edit: 'Bulk Edit',
    dmarc_dkim_spf_header: 'DMARC, DKIM & SPF',
    yes: 'Yes',
    no: 'No',
    block: 'Block',
    quarantine: 'Quarantine',
    off: 'Off',
    dmarc_label: 'Use Domain Based Message Authentication (DMARC)',
    dmarc_note:
      'Note: Senders with a DMARC record and policy of "quarantine" or "reject" will override DKIM and SPF settings below.',
    dmarc_table: 'DMARC Exempt',
    dkim_label: 'Domain Keys Identified Mail (DKIM) Action',
    dkim_table: 'DKIM Exempt',
    spf_label: 'Enable Sender Policy Framework (SPF) Checking',
    spf_exempt: 'SPF Exemptions by IP Address',
    spf_domain_exempt: 'SPF Exemptions by Domain',
    hard_fall: 'Hard Fall',
    soft_fall: 'Soft Fall',
    spf_missing_label: 'Block on No SPF Records',
    spf_missing_table: 'Missing SPF Exemptions',
    ptr_header: 'PTR Records',
    ptr_label: 'Block on No PTR Records',
    ptr_table: 'Missing PTR Exemptions'
  },
  content_policies: {
    title: 'Content Policies',
    attachment_filter: 'Attachment Filter',
    filter_type: 'Filter Type',
    pattern: 'Pattern',
    check_archives: 'Check Archives',
    action: 'Action',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    no: 'No',
    yes: 'Yes',
    scan: 'Scan',
    do_not_scan: 'Do not scan',
    block: 'Block',
    ignore: 'Ignore',
    allow: 'Allow',
    quarantine: 'Quarantine',
    filename: 'File Name',
    mime: 'MIME Type',
    message_content_filter: 'Message Content Filter',
    subject: 'Subject',
    headers: 'Headers',
    body: 'Body',
    attachments: 'Attachments',
    sender: 'Sender',
    recipient: 'Recipient',
    match: 'Match',
    one_match_filter: 'At least one match filter must be selected.',
    duplicate_value: DUPLICATE_VALUE,
    archive_filtering: 'Password Protected Archive Filtering:',
    msoffice_filtering: 'Password Protected Microsoft Office Documents:',
    pdf_filtering: 'Password Protected PDF Filtering:',
    save: 'Save',
    missing_pattern: 'Missing filtering pattern'
  },
  anti_phishing: {
    title: 'Anti-Phishing',
    bafi_label: 'Anti-Fraud Intelligence',
    sender_label: 'External Sender Warning',
    block: 'Block',
    quarantine: 'Quarantine',
    ignore: 'Ignore',
    defer: 'Defer',
    off: 'Off',
    yes: 'Yes',
    no: 'No',
    on: 'On',
    intent: 'Intent',
    intent_label: 'Intent Analysis',
    content_label: 'Content Intent',
    intent_realtime_label: 'Use Real-Time Intent Analysis',
    link_label: 'Link Protection',
    typosquat_label: 'Typosquatting Protection',
    intent_domain_table: 'Intent Domain Policies',
    domain: 'Domain Name',
    policy: 'Policy',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    duplicate_value: 'The domain {domain} already exists.',
    specify_domain: 'Please specify a domain name.',
    invalid_domain: 'The domain name you have entered is not valid.',
    email_banners: 'Email Warning Banners',
    insert_email_banners: 'Insert Email Warning Banners',
    testing_mode: 'Testing Mode',
    email_banners_testing_mode_text:
      'Not sure how email warning banners work? Use testing mode to try out this feature on a select number of users.',
    testing_mode_enable: 'Enable for the following user emails',
    testing_mode_placeholder: 'Ex: hello@demo.com,abc@demo.com',
    duplicate_email: 'Duplicate email: {emails}',
    invalid_email: 'Invalid email: {emails}',
    blank_email: 'Please enter email address',
    beta_version: 'Beta version'
  },
  machine_learning: {
    title: 'Machine Learning',
    machine_learning_exempt: 'Machine Learning Exemption by Email Address/Domain',
    email_domain: 'Email/Domain',
    comment: 'Comment',
    actions: 'Actions',
    add: 'Add',
    remove: 'Remove',
    invalid_email_domain: 'The domain/email you have entered is not valid.',
    duplicate_value: 'The domain already exists.',
    specify_domain: 'Please specify a domain name.',
    invalid_domain: 'The domain name you have entered is not valid.'
  }
}
/* eslint-enable quotes */

export default inbound_settings
