import { makeStyles } from '@barracuda-internal/bds-core'

const CELL_HEIGHT = 35

const IMAGE_DIMENSION = '15px'

const baseCellStyles = {
  lineHeight: `${CELL_HEIGHT}px`
}

const actionCellStyles = {
  ...baseCellStyles,
  '& div': {
    cursor: 'pointer'
  }
}

export default makeStyles((theme: any) => ({
  cell: {
    ...baseCellStyles
  },
  reviewCell: {
    ...actionCellStyles,
    width: '150px'
  },
  createIncidentCell: {
    ...actionCellStyles,
    width: '170px',
    textTransform: 'uppercase'
  },
  pendingIncidentCell: {
    ...actionCellStyles,
    width: '170px',
    color: theme.palette.secondary.light,
    textTransform: 'uppercase'
  },
  image: {
    width: IMAGE_DIMENSION,
    height: IMAGE_DIMENSION,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  flag: {
    height: '15px',
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
    width: '15px'
  },
  icon: {
    color: theme.palette.secondary.light,
    verticalAlign: 'middle',
    marginRight: theme.spacing(1)
  },
  text: {
    display: 'inline-block'
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))
