import { useRef, useLayoutEffect, useEffect } from 'react'

const isBrowser = typeof window !== 'undefined'

const DEFAULT_THROTTLE = 100
export const APP_HEADER_HEIGHT = 60
export const SCROLL_DElAY = 30

let throttleTimeout: any = null
let isScrollDisabled = false
let interval: any

export interface OnScroll {
  cb: ({ currPos, prevPos }: { currPos: any; prevPos: any }) => void
  deps?: any[]
  element?: Element
  useWindow?: boolean
  throttle?: number
}

function getScrollPosition({ element, useWindow }: any) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: Math.abs(window.scrollY) }
    : { x: position.left, y: Math.abs(position.top) }
}

export default function useScrollPosition({
  cb,
  deps = [],
  element,
  useWindow = false,
  throttle = DEFAULT_THROTTLE
}: OnScroll) {
  const position = useRef(getScrollPosition({ useWindow }))

  useEffect(
    () => () => {
      if (interval) clearInterval(interval)
    },
    []
  )

  useLayoutEffect(() => {
    const callBack = () => {
      const currPos = getScrollPosition({ element, useWindow })
      if (!isScrollDisabled) {
        cb({ currPos, prevPos: position.current })
      }
      position.current = currPos
      throttleTimeout = null
    }

    const handleScroll = () => {
      if (throttleTimeout === null) {
        if (throttle > 0) {
          throttleTimeout = setTimeout(callBack, throttle)
        } else {
          callBack()
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [cb, element, useWindow, deps, throttle])
}

export function scrollToElement(element: HTMLElement | undefined) {
  if (element) {
    isScrollDisabled = true
    if (interval) clearInterval(interval)
    interval = setInterval(() => {
      isScrollDisabled = false
    }, 1000)

    if ('scrollBehavior' in document.documentElement.style) {
      window.scroll({
        top: element.offsetTop - APP_HEADER_HEIGHT - SCROLL_DElAY,
        behavior: 'smooth'
      })
    } else {
      window.scroll(0, element.offsetTop)
    }
  }
}
