import { history } from 'lib/browserHistory'
import appConfig, { AppNames } from 'config/appConfig'
import { UserRightsForUiPaths } from 'components/libs/userRights/userRights'
import { AppTypes } from 'types/AppTypes'

export type RouteMetaData = Record<string, unknown>
export type BlockTransition = (callback: () => void) => void | undefined
let blockTransition: BlockTransition | undefined

export interface RouteConfig {
  path: string
  legacyPath: string
  appAccess: AppNames[]
  hasAppTypeAccess: (appType: AppTypes) => boolean
  authentication?: {
    userRightAccess?: UserRightsForUiPaths[]
  }
  metadata?: RouteMetaData
}

export interface UiRoute extends RouteConfig {
  id: string
  isAppRelatedPath: boolean
  metadata?: Record<string, unknown>

  url(params?: RouteParams): string

  uri(params?: RouteParams): string

  isActiveUrl(params?: RouteParams): boolean

  softGoto(params?: RouteParams, withUrlParams?: boolean, urlsParams?: string): void

  goto(params?: RouteParams, withUrlParams?: boolean, urlParams?: string): void

  forceGoto(params?: RouteParams, withUrlParams?: boolean, urlParams?: string): void

  refresh(params?: RouteParams): void
}

export interface UiRoutes {
  [index: string]: UiRoute
}

export interface RouteParams {
  [key: string]: string
}

interface Paths {
  [key: string]: any
}

export function createUrlGenerator(path: string) {
  return (params: RouteParams = {}) =>
    Object.keys(params).reduce((updatedPath, param) => updatedPath.replace(`:${param}`, params[param]), path)
}

export function pushToHistory(url: string) {
  history.push(url)
}

export function setBlockTransition(fn?: BlockTransition): () => void {
  blockTransition = fn
  return () => {
    blockTransition = undefined
  }
}

export const getBlockTransition = () => blockTransition

export default function routesConfig(PATHS: Paths, appName?: string, isDisableTestFilterCheck?: boolean): UiRoutes {
  function filterPaths(pathKey: string) {
    return (
      PATHS[pathKey]?.appAccess?.includes(appName || appConfig.APP.NAME) ||
      (appConfig.ENVIRONMENT.IS_TEST && !isDisableTestFilterCheck)
    )
  }

  return Object.keys(PATHS)
    .filter(route => !!PATHS[route])
    .reduce((builtRoutes, route) => {
      const modifiedBuiltRoutes: UiRoutes = { ...builtRoutes }
      const basePath = appConfig.BASE_PATHS[appConfig.APP.NAME]

      const routeConfig = PATHS[route]
      const path = `/${basePath}/${routeConfig.path}`
      const { legacyPath, appAccess, authentication, authorization = {} } = routeConfig

      const createUrl = createUrlGenerator(path)
      const createUri = createUrlGenerator(`/${routeConfig.path}`)

      modifiedBuiltRoutes[route] = {
        id: route,
        isAppRelatedPath: filterPaths(route),
        hasAppTypeAccess: (appType: AppTypes) =>
          appConfig.ENVIRONMENT.IS_TEST || (authorization.appTypeAccess || []).includes(appType),
        path,
        legacyPath,
        appAccess,
        authentication,
        url: createUrl,
        uri: createUri,
        isActiveUrl: (params: RouteParams = {}) => createUrl(params) === window.location.pathname,
        // goto to the route without alert the react-router
        softGoto: (params: RouteParams = {}, withUrlParams = false, urlParams = '') => {
          const doTransition = () => {
            const newPathName = `${createUrl(params)}${withUrlParams ? window.location.search : urlParams}`
            window.history.replaceState(null, '', newPathName)
          }

          if (blockTransition) {
            blockTransition(doTransition)
          } else {
            doTransition()
          }
        },
        goto: (params: RouteParams = {}, withUrlParams = false, urlParams = '') => {
          const doTransition = () => {
            const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : urlParams}`
            history.push(currentPath)
          }

          if (blockTransition) {
            blockTransition(doTransition)
          } else {
            doTransition()
          }
        },
        forceGoto: (params: RouteParams = {}, withUrlParams = false, urlParams = '') => {
          const currentPath = `${createUrl(params)}${withUrlParams ? window.location.search : urlParams}`
          window.location.href = `${window.location.origin}${currentPath}`
        },
        refresh: (params: RouteParams = {}) => {
          window.location.href = createUrl(params)
        },
        metadata: routeConfig.metadata
      }

      return modifiedBuiltRoutes
    }, {})
}
