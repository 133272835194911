import { useMemo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setSplitterOrientation } from 'redux/features/app/appSlice'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import { SplitterOrientation } from '../splitterTypes'

export interface UseSplitterMenuLogic {
  splitterOrientation: string
  handleChange: (value: SplitterOrientation) => void
}

export interface SplitterMenuItems {
  key: string
  label: string
  icon: JSX.Element
}

export const useSplitterMenuLogic = (): UseSplitterMenuLogic => {
  const dispatch = useAppDispatch()
  const { splitter } = useAppSelector(_store => ({
    splitter: _store.app.splitter
  }))

  const handleChange = useCallback(
    (value: SplitterOrientation) => {
      dispatch(setSplitterOrientation(value))
      localStorage.setItem(LocalStorageKeys.splitterOrientation, value)
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      splitterOrientation: splitter.orientation,
      handleChange
    }),
    [splitter, handleChange]
  )
}
