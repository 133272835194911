import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { AccountUser, AccountUserRole } from 'types/users'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import {
  getAccountUsers,
  resetAccountUsers,
  resetUpdateAccountUserRole,
  updateAccountUserRole
} from 'redux/features/users/usersSlice'
import {
  IsAllCheckboxSelected,
  IsCheckboxIdIsSelected,
  OnSelectAllCheckboxes,
  OnSelectCheckbox,
  SelectedCheckboxes,
  useDatatableCheckboxColumnLogic
} from 'lib/useDatatableCheckboxColumnLogic'
import { setSuccessSnackBar } from 'redux/features/app/appSlice'

export enum EditUserDialogErrors {
  missedDomainSelection = 'missed_domain_selection',
  userUpdateError = 'user_update_error'
}

export type State = {
  selectedUserRole: AccountUserRole
  domains?: DomainsInDB[]
  selectedDomains: SelectedCheckboxes
  isAllDomainsSelected: IsAllCheckboxSelected
  dialogError?: EditUserDialogErrors
  updateInProgress: boolean
}

export type EventHandlers = {
  onSave: () => void
  onCancel: () => void
  onSelectUserRole: (newRole: AccountUserRole) => void
  onSelectDomain: OnSelectCheckbox
  onSelectAllDomains: OnSelectAllCheckboxes
  isDomainSelected: IsCheckboxIdIsSelected
}

export type UseEditUserDialogLogicProps = {
  onClose: () => void
  user: AccountUser
}
export type UseEditUserDialogLogic = [State, EventHandlers]

export const useEditUserDialogLogic = ({ onClose, user }: UseEditUserDialogLogicProps): UseEditUserDialogLogic => {
  const { domains, isUpdateUserRoleInProgress, isUpdateUserRoleSuccess, isUpdateUserRoleFailed } = useAppSelector(
    _store => ({
      domains: _store.user.availableDomains,
      isUpdateUserRoleInProgress: isPending(_store.users.api.updateAccountUserRoleApiStatus),
      isUpdateUserRoleSuccess: isSuccess(_store.users.api.updateAccountUserRoleApiStatus),
      isUpdateUserRoleFailed: isFailed(_store.users.api.updateAccountUserRoleApiStatus)
    })
  )
  const dispatch = useAppDispatch()
  const [selectedUserRole, setSelectedUserRole] = useState<AccountUserRole>(user.role)
  const [dialogError, setDialogError] = useState<EditUserDialogErrors | undefined>()
  const [
    selectedDomains,
    onSelectDomain,
    onSelectAllDomains,
    isDomainSelected,
    isAllDomainsSelected,
    getSelectedIds,
    resetSelectedDomains
  ] = useDatatableCheckboxColumnLogic()

  // init
  useEffect(
    () => () => {
      if (isUpdateUserRoleSuccess) {
        dispatch(resetUpdateAccountUserRole())
        dispatch(resetAccountUsers())
        dispatch(getAccountUsers())
      }
    },
    [dispatch, isUpdateUserRoleSuccess]
  )

  // Update the selected domains list when the role is changed
  useEffect(() => {
    setDialogError(undefined)
    if (selectedUserRole === AccountUserRole.domain_admin) {
      resetSelectedDomains(user.managedDomains)
    } else if (selectedUserRole === AccountUserRole.help_desk) {
      resetSelectedDomains(user.helpdeskDomains)
    } else {
      resetSelectedDomains([])
    }
  }, [selectedUserRole, user, resetSelectedDomains])

  // domains list is changed
  useEffect(() => {
    setDialogError(undefined)
  }, [selectedDomains])

  // Update is finished
  useEffect(() => {
    if (isUpdateUserRoleSuccess) {
      dispatch(setSuccessSnackBar({ message: 'update_account_user_role_success', params: [user.userId] }))
      onClose()
    } else if (isUpdateUserRoleFailed) {
      setDialogError(EditUserDialogErrors.userUpdateError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateUserRoleSuccess, isUpdateUserRoleFailed, dispatch, user])

  const onSelectUserRole = useCallback((selectedRole: AccountUserRole) => {
    setSelectedUserRole(selectedRole)
  }, [])

  const onSave = useCallback(() => {
    const selectedIds = getSelectedIds(domains?.map(domain => domain.domainId) || [])
    if ([AccountUserRole.domain_admin, AccountUserRole.help_desk].includes(selectedUserRole) && !selectedIds.length) {
      setDialogError(EditUserDialogErrors.missedDomainSelection)
    } else {
      dispatch(
        updateAccountUserRole({
          userId: user.userId,
          role: selectedUserRole,
          domains: selectedIds
        })
      )
    }
  }, [selectedUserRole, dispatch, user, getSelectedIds, domains])

  const onCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return useMemo(
    () => [
      {
        selectedUserRole,
        selectedDomains,
        domains,
        isAllDomainsSelected,
        dialogError,
        updateInProgress: isUpdateUserRoleInProgress
      },
      {
        onSave,
        onCancel,
        onSelectUserRole,
        onSelectDomain,
        onSelectAllDomains,
        isDomainSelected
      }
    ],
    [
      isDomainSelected,
      onSelectAllDomains,
      selectedUserRole,
      selectedDomains,
      onSave,
      onCancel,
      onSelectUserRole,
      onSelectDomain,
      domains,
      isAllDomainsSelected,
      dialogError,
      isUpdateUserRoleInProgress
    ]
  )
}
