import { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getAzureAdSyncStats } from 'redux/features/azureAd/azureAdSlice'
import { isSuccess } from 'redux/toolkit/api'
import { SyncNowStatus, SyncStatus, TenantAuthorizationStatus } from 'types/azureAd'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import appConfig from 'config/appConfig'

export interface State {
  isMaxPollCountReached: boolean
}

export interface EventHandlers {
  resetPollCount: () => void
}

export type AzureAdPollUserStats = [State, EventHandlers]

export const useAzureAdPollUserStats = (form: EditDomainForm): AzureAdPollUserStats => {
  const [{ domainId, tenantStatus, isAzureAdPollStatsInProgress }] = form
  const dispatch = useAppDispatch()
  const timer = useRef<number | undefined>(undefined)
  const isRunning = useRef(false)
  const [pollCount, setPollCount] = useState(0)
  const { syncStatus, isGetAzureAdSyncStatsSuccess, syncResult } = useAppSelector(_store => ({
    syncStatus: _store.azureAd.azureAdSyncStats?.syncStatus,
    isGetAzureAdSyncStatsSuccess: isSuccess(_store.azureAd.api.getAzureAdSyncStats),
    syncResult: _store.azureAd.azureAdSyncNow
  }))

  const isMaxPollCountReached = useMemo(() => pollCount >= appConfig.AZURE_AD.POLL_USER_STATS_MAX_COUNT, [pollCount])

  const poll = useCallback(() => {
    if (isRunning.current) {
      dispatch(getAzureAdSyncStats({ domainId }))
    }
  }, [dispatch, domainId])

  const start = useCallback(() => {
    setPollCount(0)
    isRunning.current = true
    isAzureAdPollStatsInProgress.setValue(true)
    poll()
  }, [isAzureAdPollStatsInProgress, poll])

  const stop = useCallback(() => {
    isRunning.current = false
    isAzureAdPollStatsInProgress.setValue(false)
    window.clearTimeout(timer.current)
  }, [isAzureAdPollStatsInProgress])

  const resetPollCount = useCallback(() => {
    setPollCount(0)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stop, [])

  useEffect(() => {
    if (!isGetAzureAdSyncStatsSuccess) {
      return
    }
    setPollCount(prev => prev + 1)
    if (syncStatus === SyncStatus.COMPLETED || syncStatus === SyncStatus.EMPTY) {
      stop()
    } else {
      timer.current = window.setTimeout(poll, appConfig.AZURE_AD.POLL_USER_STATS_INTERVAL_MS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAzureAdSyncStatsSuccess])

  useEffect(() => {
    if (pollCount >= appConfig.AZURE_AD.POLL_USER_STATS_MAX_COUNT) {
      stop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollCount])

  useEffect(() => {
    if (tenantStatus.value === TenantAuthorizationStatus.AUTHORIZED) {
      start()
      return
    }
    stop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantStatus])

  useEffect(() => {
    if (syncResult === SyncNowStatus.SYNC_STARTED) {
      start()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncResult])

  return useMemo(() => [{ isMaxPollCountReached }, { resetPollCount }], [isMaxPollCountReached, resetPollCount])
}
