import React, { useMemo } from 'react'

import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'

import { Button, Divider, Grid, Typography } from '@barracuda-internal/bds-core'

import styles from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/messageDetailsContentTabMessagePreviewStyles'
import { useMessageDetailsContentTabMessagePreviewLogic } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/useMessageDetailsContentTabMessagePreviewLogic'
import { useFormatMessage } from 'lib/localization'
import { previewLoaderHtml } from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/preview-loader.html'

const BASE_I18N_KEY = 'ess.message_log.message_preview'

const MessageDetailsContentTabMessagePreview = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [
    { styleProps, isShowImagesBannerVisible, cantViewBlocked, isPreviewDisabled, previewDisabledReason },
    { onClickShowImages }
  ] = useMessageDetailsContentTabMessagePreviewLogic()
  const classes = styles(styleProps)

  const showImagesBanner = useMemo(
    () => (
      <>
        <Grid
          alignItems="center"
          className={classes.bannerContainer}
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <div className={classes.bannerMessage}>
              <ReportProblemOutlinedIcon fontSize="small" className={classes.icon} />
              <Typography variant="body2" className={classes.text}>
                {formatMessage('show_images_banner')}
              </Typography>
            </div>
          </Grid>
          {!cantViewBlocked && (
            <Grid container item justifyContent="flex-end" xs={3}>
              <Button
                className={classes.button}
                color="default"
                onClick={onClickShowImages}
                startIcon={<InsertPhotoIcon />}
                size="small"
                variant="outlined"
              >
                {formatMessage('show_images')}
              </Button>
            </Grid>
          )}
        </Grid>
        <Divider />
      </>
    ),
    [
      cantViewBlocked,
      classes.bannerContainer,
      classes.bannerMessage,
      classes.button,
      classes.icon,
      classes.text,
      formatMessage,
      onClickShowImages
    ]
  )

  return useMemo(
    () => (
      <>
        {!isPreviewDisabled && isShowImagesBannerVisible && showImagesBanner}
        <div className={classes.iframeWrapper}>
          {isPreviewDisabled && !!previewDisabledReason && formatMessage(previewDisabledReason)}
          {!isPreviewDisabled && (
            <iframe
              id="message-log-content"
              srcDoc={previewLoaderHtml}
              className={classes.iframe}
              title="Loading preview"
              width="100%"
              data-testid="preview-iframe"
            />
          )}
        </div>
      </>
    ),
    [
      classes.iframe,
      classes.iframeWrapper,
      formatMessage,
      isPreviewDisabled,
      isShowImagesBannerVisible,
      previewDisabledReason,
      showImagesBanner
    ]
  )
}

export default MessageDetailsContentTabMessagePreview
