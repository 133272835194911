import { useMemo } from 'react'
import { AppFeature, isFeatureEnabled } from 'config/appFeatures'
import { useAppSelector } from 'redux/toolkit/hooks'

export const useUserDomainIdOverride = () => {
  const { userDomainId } = useAppSelector(_stores => ({
    userDomainId: _stores.auth.accessTokenObject?.domainId
  }))
  const isMessageAuthViaMstoreSearchEnabled = isFeatureEnabled(AppFeature.Enable_message_auth_via_mstore_search)

  return useMemo(
    () => (defaultDomainId: string | number | undefined) => {
      const domainId = isMessageAuthViaMstoreSearchEnabled ? userDomainId : defaultDomainId
      return domainId ? `${domainId}` : ''
    },
    [isMessageAuthViaMstoreSearchEnabled, userDomainId]
  )
}
