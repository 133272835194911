import { colors, makeStyles } from '@barracuda-internal/bds-core'

const GEOMAP_WIDTH = 700
const GEOMAP_HEIGHT = 316
const CHART_WIDTH = 700
const CHART_HEIGHT = 290
export const TOOLTIP_CHART_HEIGHT = 150
const TABLE_WITH_DROPDOWN_WIDTH = 350

export default makeStyles(theme => {
  const CONTENT_PADDING = theme.spacing(3)
  const GEOMAP_WRAPPER_PADDING = theme.spacing(2)

  return {
    subtitle: {
      marginBottom: theme.spacing(1)
    },
    dashboardContentWrapper: {
      marginBottom: theme.spacing(2)
    },
    SelectorsWrapper: {
      zIndex: 1,
      marginTop: theme.spacing(2),
      paddingLeft: CONTENT_PADDING
    },
    domainSelector: {
      backgroundColor: colors.white,
      minWidth: 200,
      marginRight: theme.spacing(2)
    },
    contentSectionWrapper: {
      marginTop: theme.spacing(3),
      paddingLeft: CONTENT_PADDING
    },
    threatOriginsWrapper: {
      zIndex: 0,
      width: GEOMAP_WIDTH + GEOMAP_WRAPPER_PADDING * 2
    },
    chartWrapper: {
      zIndex: 0,
      width: CHART_WIDTH + GEOMAP_WRAPPER_PADDING * 2
    },
    chartPlaceholder: {
      width: '100%',
      flex: 1,
      background: colors.white
    },
    tableWrapper: {
      zIndex: 0,
      width: TABLE_WITH_DROPDOWN_WIDTH,
      marginLeft: theme.spacing(2)
    },
    geomap: {
      padding: GEOMAP_WRAPPER_PADDING,
      width: '100%',
      height: GEOMAP_HEIGHT,
      backgroundColor: colors.white
    },
    chart: {
      width: CHART_WIDTH + GEOMAP_WRAPPER_PADDING * 2,
      height: CHART_HEIGHT,
      backgroundColor: colors.white,
      position: 'relative'
    },
    chartTotalWrapper: {
      position: 'absolute',
      bottom: 0,
      height: 50,
      borderTop: `1px solid ${colors.scaleGray300}`
    },
    chartTotalCount: {
      fontSize: 20,
      marginTop: 9,
      fontWeight: 500,
      marginBottom: 2
    },
    overviewSection: {
      position: 'absolute',
      top: 0,
      height: CHART_HEIGHT
    },
    smallOverviewSection: {
      position: 'absolute',
      top: 0,
      height: TOOLTIP_CHART_HEIGHT
    },
    threatChartTotalTitle: {
      fontSize: 18,
      lineHeight: '18px'
    },
    smallThreatChartTotalTitle: {
      fontSize: 14,
      lineHeight: '18px'
    },
    threatChartTotalCount: {
      fontSize: 40,
      lineHeight: '42px',
      fontWeight: 500
    },
    smallThreatChartTotalCount: {
      fontSize: 25,
      lineHeight: '27px',
      fontWeight: 500
    },
    overviewChart: {
      position: 'relative',
      height: '100%'
    },
    overviewContent: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4)
    },
    smallOverviewContent: {
      paddingLeft: theme.spacing(1)
    },
    overviewContentTitle: {
      marginTop: theme.spacing(6)
    },
    overviewContentTableHeader: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid ${colors.scaleGray300}`
    },
    overviewContentTableHeaderTitle: {
      fontSize: 16
    },
    smallOverviewContentTableHeaderTitle: {
      fontSize: 12
    },
    overviewContentTableContent: {
      lineHeight: '35px'
    },
    smallOverviewContentTableContent: {
      lineHeight: '22px'
    },
    chartColorPrefix: {
      display: 'inline-block',
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      marginRight: theme.spacing(1)
    },
    footer: {
      backgroundColor: colors.scaleGray150,
      padding: `${theme.spacing(2)}px ${CONTENT_PADDING}px`
    },
    footerText: {
      color: colors.scaleGray600
    },
    pieChartWithTableContainer: {
      height: '100%'
    }
  }
})
