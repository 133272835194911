import { Provider } from 'types/emailServer'

export type ProviderRelatedMxLinks = {
  criteria: string
  addMxLink: string
  removeMxLink?: string
}

export type ProviderWithMxLinks = Provider & ProviderRelatedMxLinks

export const providerRelatedMxLinks: ProviderRelatedMxLinks[] = [
  {
    criteria: 'google',
    addMxLink: 'https://support.google.com/a/answer/6248174?hl=en&ref_topic=12366306'
  },
  {
    criteria: 'godaddy',
    addMxLink: 'https://www.godaddy.com/help/add-an-mx-record-19234',
    removeMxLink: 'https://www.godaddy.com/help/edit-an-mx-record-19235'
  },
  {
    criteria: 'hostgator',
    addMxLink: 'https://www.hostgator.com/help/article/mail-exchange-record-what-to-put-for-your-mx-record'
  },
  {
    criteria: 'bluehost',
    addMxLink: 'https://www.bluehost.com/help/article/dns-management-how-to-modify-mx-records#Add',
    removeMxLink: 'https://www.bluehost.com/help/article/dns-management-how-to-modify-mx-records#Change'
  },
  {
    criteria: 'namecheap',
    addMxLink:
      'https://www.namecheap.com/support/knowledgebase/article.aspx/322/2237/how-can-i-set-up-mx-records-required-for-mail-service/',
    removeMxLink:
      'https://www.namecheap.com/support/knowledgebase/article.aspx/9258/29/how-to-change-mx-records-and-email-routing-in-cpanel/#Routing_Basic'
  },
  {
    criteria: 'dreamhost',
    addMxLink: 'https://help.dreamhost.com/hc/en-us/articles/215035328-Configuring-custom-MX-records',
    removeMxLink: 'https://help.dreamhost.com/hc/en-us/articles/360056012291-Editing-or-deleting-custom-DNS-records'
  },
  {
    criteria: 'inmotion',
    addMxLink: 'https://www.inmotionhosting.com/support/edu/cpanel/set-mx-records-cpanel-zone-editor/'
  },
  {
    criteria: 'ionos',
    addMxLink:
      'https://www.ionos.com/help/domains/configuring-mail-servers-and-other-related-records/using-a-domain-with-another-providers-mail-servers-editing-mx-records/#c111489',
    removeMxLink:
      'https://www.ionos.com/help/domains/configuring-mail-servers-and-other-related-records/using-a-domain-with-another-providers-mail-servers-editing-mx-records/#c111487'
  },
  {
    criteria: 'name',
    addMxLink: 'https://www.name.com/support/articles/115004729247-adding-an-mx-record'
  },
  {
    criteria: 'hostinger',
    addMxLink: 'https://support.hostinger.com/en/articles/4443666-how-to-manage-mx-records-in-hpanel',
    removeMxLink:
      'https://support.hostinger.com/en/articles/1583249-how-to-manage-dns-records-at-hostinger#h_37eb1ce707'
  }
]
