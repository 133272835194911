import React, { useMemo } from 'react'

import { Typography, TextareaAutosize, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/adminBulkEdit/adminBulkEditStyles'
import { useAdminBulkEditLogic } from 'components/pages/adminBulkEdit/useAdminBulkEditLogic'
import SettingsPage from 'components/libs/settingsPage/SettingsPage'

const BASE_I18N_KEY = 'ess.settings.bulk_edit'

const AdminBulkEdit: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useAdminBulkEditLogic()

  return useMemo(
    () => (
      <SettingsPage
        title={state.title ? formatMessage(state.title) : ''}
        actions={{
          cancelDisabled: false,
          onSave: eventHandlers.onSave,
          onCancelConfirm: eventHandlers.onCancelConfirm
        }}
      >
        <Grid data-testid="admin-bulk-edit-content">
          <Typography variant="body2" gutterBottom data-testid="subtitle">
            {formatMessage('sub_title')}
          </Typography>
          <Typography className={classes.contentHeader} variant="h6" gutterBottom data-testid="content-header">
            {formatMessage('content_header')}
          </Typography>
          <Typography variant="body2" gutterBottom data-testid="content-subheader">
            {formatMessage('content_sub_header')}
          </Typography>
          <Typography className={classes.warning} variant="body2" gutterBottom data-testid="content-subtitle">
            {formatMessage('warning')}
          </Typography>
          <TextareaAutosize
            minRows={16}
            maxRows={16}
            className={classes.textArea}
            placeholder={formatMessage('place_holder')}
            value={state.rawData}
            onChange={eventHandlers.onChange}
            data-testid="textarea"
          />
        </Grid>
      </SettingsPage>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default AdminBulkEdit
