import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles({
  table: {
    minHeight: '33vh',
    '& th': {
      textTransform: 'uppercase',
      color: colors.statusInformational,
      lineHeight: '16px',
      verticalAlign: 'center',
      padding: 0,
      fontSize: '12px',
      borderColor: colors.uiElement,
      borderLeft: '1 solid transparent',
      borderRight: '1 solid transparent'
    },
    '& .k-grid-header .k-header:nth-child(n+2)': {
      padding: '0px 0px 12px 10px'
    },
    '& td': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      paddingLeft: 10
    },
    '& td:nth-child(1)': {
      paddingLeft: 0
    }
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  buttons: {
    marginLeft: 'auto'
  },
  progressBarContainer: {
    width: '200px',
    marginRight: '100px',
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  progressBarStatus: {
    verticalAlign: 'super',
    paddingTop: '4px',
    marginLeft: '10px'
  },
  dialogHeadingWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogTitle: {
    cursor: 'move'
  },
  closeButton: {
    marginLeft: 'auto',
    marginRight: '20px',
    marginTop: '30px'
  },
  progressBar: {
    height: '3px'
  }
})
