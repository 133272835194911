import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getSessionCookie } from 'lib/cookies'
import Loading from 'components/libs/loading/Loading'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { validateSessionId } from 'redux/features/auth/authSlice'
import { gotoAdminEntryPathFromEnduser, gotoEnduserEntryPathFromAdmin } from 'lib/routesConfig'
import useUserRights, { UserRights } from 'components/libs/userRights/useUserRights'
import appConfig from 'config/appConfig'
import { initMonitoringServices } from 'lib/monitoring/monitoringService'
import useLogout from 'lib/useLogout'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import logger from 'lib/logger'
import { history } from 'lib/browserHistory'
import { usePostAuthCommands } from 'lib/postAuthCommands/usePostAuthCommands'
import { TRACKING_EVENTS, trackIntercomEventDelayed } from '../monitoring/intercom/intercomService'

export default function validateSession<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidateSession: React.FC<any> = props => {
    const {
      accessTokenObject,
      sessionIdValidationIsDone,
      justLoggedOut,
      accessToken,
      logoutIsFailed,
      isLoggingOut,
      validateSessionIdFailed,
      isValidatedSession
    } = useAppSelector(_store => ({
      sessionIdValidationIsDone:
        isSuccess(_store.auth.validateSessionIdApiStatus) || isFailed(_store.auth.validateSessionIdApiStatus),
      accessTokenObject: _store.auth.accessTokenObject,
      justLoggedOut: isPending(_store.auth.logoutApiStatus),
      accessToken: _store.auth.accessToken,
      logoutIsFailed: isFailed(_store.auth.logoutApiStatus),
      isLoggingOut: isPending(_store.auth.logoutApiStatus),
      validateSessionIdFailed: isFailed(_store.auth.loginApiStatus),
      isValidatedSession: _store.auth.isValidatedSession
    }))
    const [continueRenderFlow, setContinueRenderFlow] = useState<boolean>(isValidatedSession || justLoggedOut)
    const { userHasRight } = useUserRights()
    const dispatch = useAppDispatch()
    const sessionId = getSessionCookie()
    const [logout] = useLogout()
    const { appTypeLoginEntryPath } = useAppTypeEntryPaths()
    const isLogoutAlreadyCalled = useRef<boolean>(false)
    const postAuthCommands = usePostAuthCommands()

    const doContinueRenderFlow = useCallback(() => {
      setContinueRenderFlow(true)

      if (accessToken && accessTokenObject) {
        initMonitoringServices(accessTokenObject, accessToken)
        const urlParams = new URLSearchParams(window.location.search)
        const source = urlParams.get('src')
        if (source === 'tdf') {
          trackIntercomEventDelayed(TRACKING_EVENTS.WEBUI.OPTED_IN_REACT)
          urlParams.delete('src')
        }
        const command = urlParams.get('command')
        if (command) {
          try {
            const cmdDecoded = JSON.parse(atob(command))
            postAuthCommands.commandHandler.handle(cmdDecoded)
          } catch {
            logger.error(`Failed parsing command in URL for ${accessTokenObject.userId}: ${command}`)
          }
          urlParams.delete('command')
          history.replace(urlParams.toString())
        }
      }
    }, [accessToken, accessTokenObject, postAuthCommands.commandHandler])

    // validate user access
    const isValidUserAccess = useCallback(() => {
      if (appConfig.APP.IS_ADMIN && !userHasRight(UserRights.ADMIN_APP_ACCESS)) {
        gotoEnduserEntryPathFromAdmin()
        return false
      }
      if (appConfig.APP.IS_ENDUSER && userHasRight(UserRights.ADMIN_APP_ACCESS)) {
        gotoAdminEntryPathFromEnduser()
        return false
      }

      return true
    }, [userHasRight])

    // Logout the user if sessionId is not set
    useEffect(() => {
      if (
        !isLogoutAlreadyCalled.current &&
        !continueRenderFlow &&
        !isLoggingOut &&
        (logoutIsFailed || !sessionId || validateSessionIdFailed)
      ) {
        isLogoutAlreadyCalled.current = true
        logout(false)
      }
    }, [logout, isLoggingOut, logoutIsFailed, sessionId, validateSessionIdFailed, continueRenderFlow])

    // init
    useEffect(() => {
      // If the session is not set yet
      if (!continueRenderFlow) {
        if (sessionId && !sessionIdValidationIsDone) {
          dispatch(validateSessionId(sessionId))
        } else {
          appTypeLoginEntryPath.goto()
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // validation is done
    useEffect(() => {
      if (!continueRenderFlow && sessionIdValidationIsDone) {
        // We have a valid accessToken, let's jump to the authorized zone
        if (accessTokenObject) {
          // user access is invalid, will use another app
          if (!isValidUserAccess()) {
            return
          }

          // continue the render flow
          doContinueRenderFlow()
        } else {
          appTypeLoginEntryPath.goto()
        }
      }
    }, [
      appTypeLoginEntryPath,
      sessionIdValidationIsDone,
      continueRenderFlow,
      accessTokenObject,
      isValidUserAccess,
      doContinueRenderFlow
    ])

    return useMemo(() => {
      if (!continueRenderFlow || isLoggingOut) {
        return <Loading />
      }

      return <WrappedComponent {...props} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [continueRenderFlow, isLoggingOut])
  }

  return ValidateSession
}
