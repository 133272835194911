import { useMemo } from 'react'
import useUserRights from 'components/libs/userRights/useUserRights'

export interface OverviewRightHelpers {
  isPdDomainIdSet: boolean
}

export interface ValidatedRights {
  helpers: OverviewRightHelpers
  canViewOutboundStatistics: boolean
}

export type UseOverviewRights = ValidatedRights

export const useOverviewRights = (): UseOverviewRights => {
  const { isPdDomainIdSet, isCplAccount } = useUserRights()

  const canViewOutboundStatistics = useMemo(() => !isCplAccount, [isCplAccount])

  return useMemo(
    () => ({
      helpers: {
        isPdDomainIdSet
      },
      canViewOutboundStatistics
    }),
    [isPdDomainIdSet, canViewOutboundStatistics]
  )
}
