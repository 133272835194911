export const previewLoaderHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <style>
        * {
            box-sizing: border-box;
        }
        html, body {
            padding: 0;
            margin: 0;
            height: 100%;
            overflow-y: hidden;
        }
        body {
            margin: 8px;
            height: 100%;
        }
        #preview-content {
            border: 0;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }
    </style>
    <script>
        function postCurrentHeight() {
            const targetNode = document.getElementById('preview-content')?.contentWindow.document.getElementsByTagName('body')[0]
            if (!targetNode) {
                return
            }
            const { bottom, top } = targetNode.getBoundingClientRect()
            window.parent.postMessage(JSON.stringify({ type: 'preview-content-changed', height: top + bottom + 20 }), "*")
        }
        window.onresize = postCurrentHeight
    </script>
</head>
<body>
<iframe id="preview-content" srcdoc="<html><body><script>window.parent.parent.postMessage(JSON.stringify({ type: 'preview-iframe-loaded' }), '*')</script></script></body></html>"></iframe>
</body>
</html>
`
