import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  CUDASPT_LOGIN: {
    path: 'login',
    legacyPath: 'cudaspt',
    appAccess: [AppNames.cudaspt],
    authorization: {
      appTypeAccess: [AppTypes.cudaspt]
    }
  },
  CUDASPT_ACCOUNTS: {
    path: 'accounts',
    legacyPath: 'cudaspt',
    appAccess: [AppNames.cudaspt],
    authorization: {
      appTypeAccess: [AppTypes.cudaspt]
    }
  }
}
