import { useCallback, useMemo } from 'react'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { SyncNowStatus, SyncStats, SyncStatus } from 'types/azureAd'
import { postAzureAdSyncNow, resetSyncNow, resetSyncStats } from 'redux/features/azureAd/azureAdSlice'
import { formatDate } from 'lib/datetime'
import { State as PollUserStatsState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdPollUserStats'
import { isPending } from 'redux/toolkit/api'

export interface Stats {
  textId: string
  usersAdded: number
  usersUpdated: number
  usersDeleted: number
  finishedAt: string
}

export interface State {
  stats: SyncStats | undefined
  status: SyncStatus
  result: SyncNowStatus | undefined
}

export interface EventHandlers {
  onSyncNow: () => void
  getSyncStats: (pollUserStatsState: PollUserStatsState) => Stats
  getSyncNowState: (pollUserStatsState: PollUserStatsState, isDirectoryOptionsDisabled: boolean) => [boolean, boolean]
}

export type AzureAdSync = [State, EventHandlers]

export const useAzureAdSyncNow = (form: EditDomainForm): AzureAdSync => {
  const dispatch = useAppDispatch()
  const [{ domainId }] = form
  const { stats, result, isGetAzureAdSyncStatsPending, isSyncNowPending } = useAppSelector(_store => ({
    stats: _store.azureAd.azureAdSyncStats,
    result: _store.azureAd.azureAdSyncNow,
    isGetAzureAdSyncStatsPending: isPending(_store.azureAd.api.getAzureAdSyncStats),
    isSyncNowPending: isPending(_store.azureAd.api.postAzureAdSyncNow)
  }))

  const status = useMemo(() => stats?.syncStatus || SyncStatus.EMPTY, [stats?.syncStatus])

  const onSyncNow = useCallback(() => {
    dispatch(resetSyncStats())
    dispatch(resetSyncNow())
    dispatch(postAzureAdSyncNow({ domainId }))
  }, [dispatch, domainId])

  const getSyncStats = useCallback(
    (pollUserStatsState: PollUserStatsState): Stats => {
      let textId = 'sync_status.hidden'
      switch (true) {
        case result === SyncNowStatus.SYNC_ALREADY_IN_PROGRESS:
          textId = 'sync_status.already_in_progress'
          break
        case stats?.syncStatus === SyncStatus.IN_PROGRESS && !pollUserStatsState.isMaxPollCountReached:
          textId = 'sync_status.currently_in_progress'
          break
        case stats?.syncStatus === SyncStatus.IN_PROGRESS && pollUserStatsState.isMaxPollCountReached:
          textId = 'sync_status.still_syncing'
          break
        case stats?.syncStatus === SyncStatus.COMPLETED:
          textId = 'sync_status.completed'
          break
        default: // Do nothing
      }
      return {
        textId,
        usersAdded: stats?.userStats?.created || 0,
        usersUpdated: stats?.userStats?.updated || 0,
        usersDeleted: stats?.userStats.deleted || 0,
        finishedAt: formatDate(new Date(stats?.lastSyncTime || 0), 'dd/MM/yyyy, HH:mm:ss')
      }
    },
    [result, stats]
  )

  const getSyncNowState = useCallback(
    (pollUserStatsState: PollUserStatsState, isDirectoryOptionsDisabled: boolean): [boolean, boolean] => {
      const isSyncNowLoading =
        !pollUserStatsState.isMaxPollCountReached &&
        (status === SyncStatus.IN_PROGRESS || isSyncNowPending || isGetAzureAdSyncStatsPending)
      const isSyncNowDisabled =
        !pollUserStatsState.isMaxPollCountReached &&
        (isDirectoryOptionsDisabled || status === SyncStatus.IN_PROGRESS || isSyncNowLoading)

      return [isSyncNowLoading, isSyncNowDisabled]
    },
    [status, isSyncNowPending, isGetAzureAdSyncStatsPending]
  )

  return useMemo(
    () => [
      { stats, status, result },
      { onSyncNow, getSyncStats, getSyncNowState }
    ],
    [status, onSyncNow, stats, result, getSyncStats, getSyncNowState]
  )
}
