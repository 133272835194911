import React, { useCallback, useMemo } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@barracuda-internal/bds-core'
import ReactCopyToClipboard from 'react-copy-to-clipboard'
import styles from 'components/pages/support/supportStyles'

import { useFormatMessage } from 'lib/localization'

import {
  useAccountConfigurationDialog,
  UseAccountConfigurationDialogLogicProps
} from 'components/pages/support/useAccountConfigurationDialogLogic'
import appConfig from 'config/appConfig'

const BASE_I18N_KEY = 'ess.support_page'

const AccountConfigurationDialog: React.FC<UseAccountConfigurationDialogLogicProps> = (
  props: UseAccountConfigurationDialogLogicProps
) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [state, eventHandlers] = useAccountConfigurationDialog(props)

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle className={classes.borderBottomStyle}>
          <Grid container direction="row" alignItems="center">
            <Typography variant="h3">
              {props.forApply
                ? formatMessage('dialog.account_configuration.apply_config_title')
                : formatMessage('dialog.account_configuration.save_config_title')}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {props.forApply ? (
            <>
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.apply_config_content_recommend')}
                <a href={appConfig.LINKS.SAVE_APPLY_ACCOUNT_CONFIG_CAMPUS_LINK} target="_blank" rel="noreferrer">
                  {formatMessage('dialog.account_configuration.apply_config_content_more')}
                </a>
              </Typography>
              <br />
              <br />
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.apply_config_content_enter')}
              </Typography>
              <br />
              <TextField fullWidth inputRef={state.tokenVal} onChange={eventHandlers.handleOnInputChange} />
            </>
          ) : (
            <>
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.save_config_content_saved')}
              </Typography>
              <br />
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.save_config_content_apply')}
              </Typography>
              <br />
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.save_config_content_valid')}
              </Typography>
              <br />
              <br />
              <Typography variant="body1">
                {formatMessage('dialog.account_configuration.save_config_content_id')}
              </Typography>
              <br />
              <TextField fullWidth value={props.token} disabled />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={eventHandlers.onCancel} color="primary">
            {formatMessage('dialog.account_configuration.cancel')}
          </Button>
          {props.forApply ? (
            <Button
              variant="contained"
              color="primary"
              disabled={state.isButtonDisabled}
              isLoading={state.applyInProgress && state.applyConfig}
              onClick={eventHandlers.onApplyClick}
            >
              {formatMessage('apply')}
            </Button>
          ) : (
            <ReactCopyToClipboard text={props.token} onCopy={eventHandlers.onCancel}>
              <Tooltip title={formatMessage('dialog.account_configuration.copy_to_clipboard')} placement="top-start">
                <Button variant="contained" color="primary">
                  {formatMessage('dialog.account_configuration.copy_to_clipboard')}
                </Button>
              </Tooltip>
            </ReactCopyToClipboard>
          )}
        </DialogActions>
      </Dialog>
    ),
    [formatMessage, eventHandlers, classes, state, props]
  )
}

export default AccountConfigurationDialog
