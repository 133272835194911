import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function UsersEdit(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        You can manually add or update local user accounts in {props.productName} if you are not using LDAP or Azure AD,
        or if you just want to create a few test accounts. To <i>automatically</i>
        create accounts for users in the domain, you can synchronize {props.productName} with your existing LDAP server
        or Azure AD. Click <b>Domains</b> and click <b>Edit</b> in the <b>Settings</b> column for the domain you want to
        configure.
      </p>
      To manually add users, enter each user email address for the domain on a separate line in the text box. For all
      users you create at one time, configure the following:
      <p>
        <ul>
          <li>
            <b>Enable User Quarantine</b>
            <br />
            When set to <b>Yes</b>, all emails for the user that meet the configured block policy go to the user&apos;s
            quarantine account. The user receives quarantine notifications based on the settings on the{' '}
            <b>Users &gt; Quarantine Notification</b> page. To allow users to configure their own notification interval,
            set <b>Allow users to specify interval</b> to <b>Yes</b> on the
            <b>Users &gt; Quarantine Notification</b> page.
          </li>
          <li>
            <b>Notify New Users</b>
            <br />
            When set to <b>Yes</b>, the user receives a welcome email once the account is created.
          </li>
        </ul>
        <h3>User Accounts Without Quarantine</h3>
        Users can allow and block senders from the <b>Settings &gt; Sender Policy</b> page. You can create user accounts
        and not enable quarantine, such that users only have allow/block capabilities so that there is less for the user
        to manage. In this case, all quarantined mail goes to the <b>Message Log</b> for the administrator to manage.
      </p>
    </div>
  )
}

export default UsersEdit
