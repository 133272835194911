import React, { useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings, ForceTlsValue, SettingValue } from 'types/Settings'

import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import DomainTable from 'components/libs/domainTable/DomainTable'
import {
  useEditDomainSmtpEncryptionLogic,
  UseEditDomainSmtpEncryptionLogicProps
} from 'components/pages/domains/editDomain/editDomainSmtpEncryption/useEditDomainSmtpEncryptionLogic'
import { FormRow, FormSpacer, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

const BASE_I18N_KEY = 'ess.domains.edit_domain.smtp_encryption'

const EditDomainSmtpEncryption = (props: UseEditDomainSmtpEncryptionLogicProps) => {
  const classes = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useEditDomainSmtpEncryptionLogic(props)

  return useMemo(
    () => (
      <FormBlock title={formatMessage('title')}>
        <FormRow rowTitle={formatMessage('force_tls_label')}>
          <InlineRadioGroup
            name={AvailableSettings.FORCE_TLS}
            value={state.forceTlsValue}
            onChange={eventHandlers.onInputChange(AvailableSettings.FORCE_TLS)}
            values={[ForceTlsValue.NEVER, ForceTlsValue.OPTIONAL, ForceTlsValue.REQUIRED]}
            labels={[formatMessage('never'), formatMessage('optional'), formatMessage('required')]}
            disabled={state.inProgress}
            helperText={formatMessage('smtp_over_tls')}
          />
        </FormRow>
        {state.permissions.canManageAdvancedSmtpEncryptionSettings && (
          <>
            <FormRow rowTitle={formatMessage('tls_label')}>
              <InlineRadioGroup
                name={AvailableSettings.INBOUND_TLS_ALL}
                value={state.inboundTlsAllValue}
                onChange={eventHandlers.onInputChange(AvailableSettings.INBOUND_TLS_ALL)}
                values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                labels={[formatMessage('all_inbound_messages'), formatMessage('selected_sender_domains')]}
                disabled={state.inProgress}
                helperText={state.tlsChanged ? formatMessage('save_changes_helper') : ''}
              />
            </FormRow>
            <FormSpacer />
            <FormRow rowTitle="">
              <Grid container direction="row" className={classes.flexWrapper}>
                <Grid>
                  <Typography variant="body1">
                    {state.inboundTlsAllValue === SettingValue.ENABLED
                      ? formatMessage('table_description_all', {
                          b: (txt: any) => <b>{txt}</b>
                        })
                      : formatMessage('table_description_select')}
                  </Typography>
                  <Typography variant="body1">{formatMessage('table_sub_description')}</Typography>
                </Grid>
                <Button
                  disabled={state.tlsChanged}
                  className={classes.bulkEdit}
                  onClick={eventHandlers.onBulkEdit}
                  variant="contained"
                  size="small"
                  data-testid="bulk-button"
                >
                  {formatMessage('bulk_edit')}
                </Button>
              </Grid>

              <DomainTable
                isLoading={state.isLoading}
                data={state.data}
                onAddItem={entry => eventHandlers.onAddItem(entry)}
                onRemoveItem={id => eventHandlers.onRemoveItem(id)}
                data-testid="domain-table"
                delegateDirtyFormState={eventHandlers.setIsSmtpEncryptionTableDirty}
              />
            </FormRow>
          </>
        )}
      </FormBlock>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default EditDomainSmtpEncryption
