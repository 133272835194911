import React, { Dispatch, SetStateAction, useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { useGeoPoliciesIPAddressExemptLogic } from 'components/pages/inboundSettings/regionalPolicies/useGeoPoliciesIPAddressExemptLogic'
import IpAddressTable from 'components/libs/ipAddressTable/IpAddressTable'

const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'

export interface GeoPoliciesIPAddressExemptProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

const GeoPoliciesIPAddressExempt = ({ delegateDirtyFormState }: GeoPoliciesIPAddressExemptProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)

  const [state, eventHandlers] = useGeoPoliciesIPAddressExemptLogic()
  return useMemo(
    () => (
      <Grid container>
        <Grid container className={inboundClasses.spaceGrid}>
          <Typography variant="h4">{formatInboundMessage('regional_policies.geo_policies_exempt_address')}</Typography>
          <Button
            className={inboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button-geoip-exempt"
          >
            {formatInboundMessage('bulk_edit')}
          </Button>
        </Grid>
        <IpAddressTable
          data={state.geoipAddressExempt}
          onAddItem={eventHandlers.onAddItem}
          onRemoveItem={eventHandlers.onRemoveItem}
          data-testid="geoip-exempt-table"
          delegateDirtyFormState={delegateDirtyFormState}
        />
      </Grid>
    ),
    [inboundClasses, formatInboundMessage, eventHandlers, state, delegateDirtyFormState]
  )
}
export default GeoPoliciesIPAddressExempt
