/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { AppBar, Grid, IconButton, Snackbar } from '@barracuda-internal/bds-core'
import { Close } from '@barracuda-internal/bds-core/dist/Icons/Core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'

import LogoURL from 'assets/images/barracuda/EP-EmailGatewayDefense_rev.svg'
import Footer from 'components/libs/layout/footer/Footer'

import LoginCard from 'components/pages/login/views/LoginCard'
import PasswordCard from 'components/pages/login/views/PasswordCard'
import MessageCard from 'components/pages/login/views/MessageCard'
import TemporaryPasswordCard from 'components/pages/login/views/TemporaryPasswordCard'

import styles from 'components/pages/login/loginStyles'
import { useLoginLogic } from 'components/pages/login/useLoginLogic'
import { LoginViews } from 'components/pages/login/loginPageTypes'

function Login() {
  const [state, eventHandlers] = useLoginLogic()
  const classes = styles(bdsTheme)

  return useMemo(
    () => (
      <AppBar position="fixed" elevation={1} className={classes.login}>
        <Snackbar
          className={classes.snackBar}
          open={state.message !== ''}
          transitionDuration={0}
          autoHideDuration={6000}
          onClose={eventHandlers.onCloseSnackBar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          TransitionProps={{
            appear: false
          }}
          data-testid="snack-bar"
        >
          <div className={classes.bottomAlert}>
            {state.message}
            <IconButton onClick={eventHandlers.onCloseSnackBar} data-testid="close-icon">
              <Close className={classes.closeIcon} />
            </IconButton>
          </div>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.container}>
              {state.cardType === LoginViews.Login && <LoginCard state={state} eventHandlers={eventHandlers} />}
              {[LoginViews.Password, LoginViews.AzureTemporaryPasscode].includes(state.cardType) && (
                <PasswordCard state={state} eventHandlers={eventHandlers} />
              )}
              {state.cardType === LoginViews.TemporaryPasscode && (
                <TemporaryPasswordCard state={state} eventHandlers={eventHandlers} />
              )}
              {state.cardType === LoginViews.Message && <MessageCard state={state} eventHandlers={eventHandlers} />}
            </div>
          </Grid>
        </Grid>
        <Footer />
      </AppBar>
    ),
    [state, eventHandlers, classes]
  )
}

export default Login
/* eslint-enable jsx-a11y/anchor-is-valid */
