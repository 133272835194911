import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { MlogViewConfig, reset, setViewConfig } from 'redux/features/mstore/mstoreSlice'

export enum UiState {
  READY,
  INITIALIZING
}

export const useViewConfig = (requiredConfig: MlogViewConfig) => {
  const dispatch = useAppDispatch()
  const { viewConfigType } = useAppSelector(_store => ({
    viewConfigType: _store.mstore.viewConfig.type
  }))

  const uiState = useMemo(() => {
    switch (true) {
      case viewConfigType === requiredConfig.type:
        return UiState.READY
      default:
        return UiState.INITIALIZING
    }
  }, [viewConfigType, requiredConfig.type])

  useEffect(() => {
    if (viewConfigType !== requiredConfig.type) {
      dispatch(setViewConfig(requiredConfig))
    }
  }, [viewConfigType, dispatch, requiredConfig])

  useEffect(
    () => () => {
      reset()
    },
    []
  )

  return useMemo(() => ({ uiState }), [uiState])
}
