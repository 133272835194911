import React, { useMemo } from 'react'

import { Box, Grid, MenuItem, Select, Typography } from '@barracuda-internal/bds-core'
import { Action } from 'types/Messages'

import { FileType, StatComponentProps } from 'types/stats'
import { useFormatMessage } from 'lib/localization'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'
import styles from 'components/pages/overview/dashboard/dashboardStyles'
import dropdownStyles from 'components/libs/tableWithDropdown/tableWithDropdownStyles'
import {
  SectionView,
  useTotalThreatsVirusesChartLogic
} from 'components/pages/overview/dashboard/totalThreatsVirusesChart/useTotalThreatsVirusesChartLogic'
import PieChartWithTable from 'components/pages/overview/dashboard/pieChartWithTable/PieChartWithTable'
import LineChart from 'components/libs/nivo/lineChart/LineChart'

const BASE_I18N_KEY = 'ess.overview.dashboard'
const DROPDOWN_ITEMS = [SectionView.overview, SectionView.fileType]
const TotalThreatsVirusesChart: React.FC<StatComponentProps> = props => {
  const classes = styles()
  const dropdownClasses = dropdownStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useTotalThreatsVirusesChartLogic(props)

  return useMemo(
    () => (
      <Grid container direction="column" data-testid="container">
        <Typography className={classes.subtitle} variant="subtitle1" data-testid="subtitle">
          {formatMessage('total_threat_viruses')}&nbsp;
          <Select
            className={dropdownClasses.dropdown}
            onChange={e => eventHandlers.onSelectView(e.target.value)}
            value={state.selectedView}
            renderValue={(selected: any): React.ReactElement => (
              <span>{formatMessage(`charts.${selected === Action.none ? 'overview' : selected}`)}</span>
            )}
            data-testid="select-menu"
          >
            {DROPDOWN_ITEMS.map(item => (
              <MenuItem key={item} value={item}>
                {formatMessage(`charts.${item}`)}
              </MenuItem>
            ))}
          </Select>
        </Typography>
        <ContentLoader isLoading={state.totalThreatsAndVirusesInProgress} isFailed={state.totalThreatsAndVirusesFailed}>
          {state.selectedView === SectionView.overview && (
            <Grid item className={classes.chart}>
              <PieChartWithTable {...state.overviewData} tableTitle={formatMessage('threats_viruses_detected')} />
            </Grid>
          )}
          {state.selectedView === SectionView.fileType && (
            <Grid item className={classes.chart}>
              <LineChart
                config={{ data: state.chartConfig.data, colors: state.chartConfig.colors }}
                colors={state.chartConfig.colors}
                disabledLegends={[]}
                formats={{
                  axisBottom: v => (state.chartConfig.axisBottomValuesToShow.includes(v) ? v : ''),
                  axisLeft: v => (Number.isInteger(v) ? v : '')
                }}
                maxValueInRange={state.chartConfig.maxValueInRange}
              />

              <Grid className={classes.chartTotalWrapper} container direction="row" data-testid="totals">
                {state.chartConfig.totals.map(totalObject => {
                  const [totalName, totalValue] = totalObject
                  let title
                  switch (totalName) {
                    case FileType.office:
                      title = formatMessage(`charts.${totalName}`)
                      break
                    case FileType.blockedAv:
                      title = formatMessage('charts.virus')
                      break
                    default:
                      title = totalName.toUpperCase()
                  }

                  return (
                    <Grid key={totalName} item xs>
                      <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid className={classes.chartTotalCount} item>
                          {totalValue.toLocaleString()}
                        </Grid>
                        <Grid item>{title}</Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          )}
        </ContentLoader>
        <Grid className={dropdownClasses.circleWrapper} item>
          {DROPDOWN_ITEMS.map(item => (
            <Box
              key={item}
              className={`${dropdownClasses.circle} ${item === state.selectedView ? 'selected' : ''}`}
              onClick={() => eventHandlers.onSelectView(item)}
              data-testid={`dropdown-item-${item}`}
            />
          ))}
        </Grid>
      </Grid>
    ),
    [state, eventHandlers, classes, dropdownClasses, formatMessage]
  )
}

export default TotalThreatsVirusesChart
