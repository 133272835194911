import { createStyles, makeStyles, colors } from '@barracuda-internal/bds-core'

const ACTION_ITEM_WIDTH = 133
const INPUT_WIDTH = 561

export default makeStyles(theme => {
  const TABLE_MARGIN = theme.spacing(2)
  const ACTION_ITEM_CONTENT_WIDTH = TABLE_MARGIN + ACTION_ITEM_WIDTH

  const DEFAULT_STYLES = createStyles({
    table: {
      marginRight: TABLE_MARGIN
    },
    tableRow: {
      color: colors.scaleGray900,
      boxShadow: `inset 0px -1px 0px ${colors.scaleGray100}`,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    tableIcons: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      marginRight: theme.spacing(0.5)
    },
    actionWrapper: {
      minWidth: ACTION_ITEM_WIDTH
    },
    tableWidth: {
      '@media screen and (min-width:681px)': {
        width: `calc(100% - ${ACTION_ITEM_WIDTH}px)`
      },
      '@media screen and (max-width:680px)': {
        width: '100%'
      }
    },
    alertWidth: {
      '@media screen and (min-width:681px)': {
        width: `calc(100% - ${ACTION_ITEM_CONTENT_WIDTH}px)`
      },
      '@media screen and (max-width:680px)': {
        width: '100%'
      }
    }
  })

  return createStyles({
    subtitle: {
      color: colors.scaleGray600
    },
    inputWrapper: {
      maxWidth: INPUT_WIDTH,
      flex: 1,
      marginRight: theme.spacing(2)
    },
    tableWrapper: {
      ...DEFAULT_STYLES.table,
      flex: 1,
      marginTop: theme.spacing(2),
      '@media screen and (max-width:680px)': {
        width: '100%',
        marginRight: 0
      }
    },
    tableHeader: {
      ...DEFAULT_STYLES.tableRow,
      height: theme.spacing(4)
    },
    alertWrapper: {
      display: 'flex',
      marginBottom: theme.spacing(3)
    },
    alertTopWrapper: {
      display: 'flex',
      marginTop: theme.spacing(3)
    },
    alertTitle: {
      fontSize: 16,
      fontWeight: 500
    },
    tableRow: {
      ...DEFAULT_STYLES.tableRow,
      minHeight: theme.spacing(8)
    },
    erroredTableRow: {
      ...DEFAULT_STYLES.tableRow,
      height: theme.spacing(9.25)
    },
    textColumn: {
      paddingRight: theme.spacing(1)
    },
    serverNameTextField: {
      paddingRight: theme.spacing(1)
    },
    linkButton: {
      fontSize: 14
    },
    saveButton: {
      fontSize: 14,
      marginRight: theme.spacing(0.25)
    },
    verifyButtonWrapper: {
      ...DEFAULT_STYLES.actionWrapper,
      flex: '0 1 auto',
      '@media screen and (max-width:680px)': {
        order: 3
      }
    },
    verifyButton: {
      marginTop: theme.spacing(7.5),
      '@media screen and (max-width:680px)': {
        marginTop: theme.spacing(2)
      }
    },
    erroredVerifyButton: {
      marginTop: theme.spacing(8)
    },
    verifiedIcon: {
      ...DEFAULT_STYLES.tableIcons,
      color: colors.forest600
    },
    unverifiedIcon: {
      ...DEFAULT_STYLES.tableIcons,
      color: colors.scaleGray600
    },
    missedToVerifyIcon: {
      ...DEFAULT_STYLES.tableIcons,
      color: colors.red600
    },
    missedToVerifyText: {
      color: colors.red600
    },
    verifiedText: {
      color: colors.forest600
    },
    unverifiedText: {
      color: colors.scaleGray600
    },
    domainColumn: {
      paddingLeft: theme.spacing(1)
    },
    domainColumContainer: {
      flexWrap: 'nowrap',
      alignItems: 'center',
      wordBreak: 'break-word'
    },
    iconWrapper: {
      display: 'flex'
    },
    launchIcon: {
      color: colors.blue600,
      width: 15,
      height: 15,
      marginLeft: theme.spacing(1),
      verticalAlign: -3
    },
    mxHelpLink: {
      marginBottom: theme.spacing(1)
    },
    verifyRecordsButton: {
      marginTop: theme.spacing(7.5),
      '@media screen and (max-width:680px)': {
        marginTop: theme.spacing(2)
      }
    },
    copyIcon: {
      marginLeft: theme.spacing(1),
      color: colors.scaleGray600,
      cursor: 'pointer',
      width: 15,
      marginTop: theme.spacing(-0.5)
    },
    copiedMessage: {
      '& > *': {
        minWidth: 'auto'
      }
    },
    missedVerifyAlert: {
      marginTop: theme.spacing(2)
    },
    providerContainer: {
      ...DEFAULT_STYLES.tableWidth,
      marginBottom: theme.spacing(2)
    },
    infoWrapper: {
      marginTop: theme.spacing(2),
      ...DEFAULT_STYLES.alertWidth
    },
    removeOldRecordssubtitle: {
      color: colors.scaleGray600,
      marginBottom: theme.spacing(3)
    }
  })
})
