/* eslint-disable quotes */
const YES = 'Yes'
const NO = 'No'

const domains = {
  header: {
    title: 'Domains Manager',
    add_domain: 'Add Domain'
  },
  table: {
    domain_name: 'Domain Name',
    status: 'Status',
    aliases: 'Aliases',
    recipients: 'Recipients (Last 30 days)',
    mail_servers: 'Mail Servers',
    settings: 'Settings',
    domain_options: 'Domain Options',
    no_records_available: 'No records available',
    no_mail_servers_defined: 'No mail servers defined'
  },
  actions: {
    edit: 'Edit',
    manage: 'Manage',
    remove: 'Remove',
    helpdesk: 'Helpdesk'
  },
  status: {
    unverified: 'Unverified',
    verified: 'Verified',
    misconfigured: 'MX Misconfigured'
  },
  mx_banner: {
    text: "MX records for one or more domains are misconfigured. This can interfere with Barracuda Networks' ability to protect your domain(s) from threats and spam.",
    learn_more: 'Learn more'
  },
  tooltip: {
    misconfigured:
      'Configure all MX records for this domain to point to Barracuda Networks. Follow the link in the banner above.',
    unique_settings: 'Domain specific policies'
  },
  enabled: 'Enabled',
  disabled: 'Disabled',
  details: {
    mx_records_configuration: 'MX Records Configuration',
    outbound_smarthost_configuration: 'Outbound Smarthost Configuration',
    aliases: 'Aliases',
    email_continuity: 'Email Continuity',
    domain_specific_policies: 'Domain Specific Policies',
    reset_to_account_policies: 'Reset to account policies',
    account_policies: 'Account Policies',
    primary: 'Primary',
    backup: 'Backup',
    hostname: 'Hostname'
  },
  add_domain: {
    title: 'Add Domain',
    domain_name: 'Domain Name',
    mail_server: 'Mail Server',
    cancel: 'Cancel',
    add: 'Add Domain',
    invalid_domain: 'Enter a valid domain name.',
    missing_mail_server: 'Please enter at least one host for your mail server.',
    invalid_mail_server: 'Invalid hostname',
    tld_warning: 'The domain name entered does not have a TLD. Are you sure you want to add this domain?',
    blacklisted_domain_name: 'This domain name cannot be added.'
  },
  add_mail_server: {
    title: 'Add Mail Server',
    mail_server: 'Mail Server',
    preference: 'Priority',
    cancel: 'Cancel',
    add: 'Add Mail Server'
  },
  delete_domain_confirm: {
    title: 'Confirmation Required',
    message:
      'Are you sure you want to delete this domain? Deleting a domain will remove all associated settings and verification status.',
    outbound_ip_conflict: 'Please delete or change the outbound logging entry for this IP.',
    only_verified_domain: 'This domain will no longer be able to send outbound email. Continue?'
  },
  edit_domain: {
    header: {
      title: 'Domain settings'
    },
    mail_servers: {
      title: 'Mail servers',
      description: 'If more than one mail server is specified each server will be attempted in order of priority.',
      address: 'Mail Server (IP address or hostname.)',
      priority: 'Priority',
      test: 'Test',
      edit: 'Edit',
      remove: 'Remove',
      add: 'Add',
      cancel: 'Cancel',
      update: 'Update',
      mx_server_configuration: 'MX server configuration',
      primary: 'Primary',
      backup: 'Backup',
      outbound_smarthost_configuration: 'Outbound smarthost configuration',
      hostname: 'Hostname',
      empty_host: 'Please enter at least one host for your mail server.',
      invalid_host: 'Invalid hostname',
      invalid_preference: 'Invalid priority.',
      send: 'Send',
      empty_recipient: "Enter the recipient's name.",
      email_send_failure: 'Sending the test email to this recipient failed',
      test_configuration: 'Test configuration',
      confirm_removal: 'Confirm removal',
      confirm_removal_body: 'Are you sure you want to remove <span>{serverName}</span>'
    },
    save: 'Save',
    cancel: 'Cancel',
    alias: {
      title: 'Domain alias'
    },
    domain_select_label: 'Select domain',
    domain_select_description: 'Make this domain an alias of',
    domain_select_loading: 'Loading available domains...',
    domain_select_empty_value: 'None',
    domain_select_helper: 'You should NOT use domain aliasing and user aliasing at the same time.',
    domain_select_error: 'Failed to load available domains',
    options: {
      title: 'Options',
      yes: YES,
      no: NO,
      spooling: {
        label: 'Spooling'
      },
      user_auto_add: {
        label: 'Automatically Add Users',
        helper: 'Note: To avoid invalid user creation, enable recipient verification on your mail server.'
      },
      enable_sender_proof_protection: {
        label: 'Enable Sender Spoof Protection'
      },
      rewrite_recipients_for_aliased_domains: {
        label: 'Rewrite Recipients for Aliased Domains'
      }
    },
    directory_services: {
      title: 'Directory services',
      description:
        'You can use either Azure AD or LDAP directory services to enable single sign on and synchronization of users lists.',
      type: {
        label: 'Type'
      },
      types: {
        none: 'None',
        azure_ad: 'Azure AD',
        ldap: 'LDAP'
      },
      azure_ad: {
        status: 'Status',
        not_authorized: 'Not authorized',
        authorize: 'Authorize',
        authorized: 'Active',
        revoke_authorization: 'Revoke authorization',
        tenant_status_error: 'Failed to query tenant status',
        authorized_account: 'Authorized account',
        authorization_date: 'Authorization date',
        directory_options: 'Directory options',
        synchronization_options: {
          label: 'Synchronization options',
          manual: 'Manual',
          automatic: 'Synchronize automatically'
        },
        sync_now: 'Sync now',
        sync_stats:
          '{sync_users_added} users added, {sync_users_updated} users updated, {sync_users_deleted} users deleted',
        sync_status: {
          hidden: ' ',
          currently_in_progress: 'Sync is currently in progress, {sync_stats}',
          completed: 'Sync finished at {sync_finished_at}, {sync_stats}',
          already_in_progress: 'Currently syncing',
          still_syncing: 'Sync still in progress, {sync_stats} so far'
        },
        sso: {
          label: 'Enable single sign on',
          yes: YES,
          no: NO
        },
        test_azure_ad_settings: {
          title: 'Test Azure AD configuration settings',
          label: 'Testing email address',
          helper: {
            note: 'For testing Azure AD settings only. Enter a valid primary email address.'
          },
          button: 'Test settings',
          empty_username: 'Please enter an email address to test',
          invalid_username: 'Please enter only the username part of the email address'
        }
      },
      ldap: {
        ldap_configuration: {
          title: 'LDAP configuration',
          host: 'LDAP host',
          port: 'Port',
          use_ssl: 'Use SSL (LDAPS)',
          use_ssl_yes: YES,
          use_ssl_no: NO,
          ldap_username: 'Bind DN/Username',
          ldap_password: 'Password',
          ldap_base_dn: 'Base DN',
          ldap_base_dn_helper:
            "Base DN for your directory. You may substitute the following value: '{default_naming_context}': If your domain is test.com, your Base DN might be DC=test,DC=com.",
          ldap_mail_attributes: 'Mail attributes',
          ldap_mail_attributes_helper:
            'Comma separated list of attributes containing the email addresses. Examples: mail,sAMAccountName,proxyaddresses',
          test_config: 'Test LDAP configuration settings',
          testing_email_address: 'Testing email address',
          testing_email_address_helper:
            'For testing LDAP settings only. Enter a valid primary email address. If blank, testing will be limited to connection.',
          test_settings: 'Test settings'
        },
        directory_options: {
          title: 'Directory options',
          ldap_sync: 'Synchronize automatically',
          ldap_sync_yes: YES,
          ldap_sync_no: NO,
          ldap_sync_helper: 'Most Recently Finished Synchronization: {last_ldap_sync}',
          sync_now: 'Synchronize now',
          ldap_auth: 'Use LDAP for authentication',
          ldap_auth_yes: YES,
          ldap_auth_no: NO,
          auth_filter: 'Authentication filter',
          auth_filter_helper: {
            info: 'Specify the LDAP filtering string for email user authentication. You may substitute the following values:',
            email_address: "'{email_address}': The entire email address entered by the user.",
            user_name:
              "'{user_name}': The user name portion of the email address or the entire input if the user did not enter an email address.",
            domain_name: "'{domain_name}': The domain portion of the email address.",
            example:
              "Example: (|(mail='{email_address}')(userPrincipalName='{user_name}')(sAMAccountName='{user_name}'))"
          }
        },
        advanced_configuration: {
          title: 'Advanced LDAP configuration',
          domain_limited: 'User filter',
          domain_limited_yes: YES,
          domain_limited_no: NO,
          user_filter: 'Custom user filter',
          user_filter_helper: {
            info: 'Specify the (optional) LDAP filtering string for email user synchronization.',
            example: 'Example: (name=*test*)'
          }
        },
        errors: {
          ldap_sync_and_user_auto_add_conflict:
            'You may not use Automatic User Creation and Automatic LDAP Synchronization at the same time. Please enable only one option.',
          ldap_password_missing: 'Please enter the new LDAP password'
        },
        test: {
          title: 'LDAP configuration test',
          in_progress: 'Testing LDAP settings is in progress...',
          no_host_specified: 'No host specified',
          no_bind_dn_specified: 'No bind DN specified',
          no_password_specified: 'No bind password specified',
          no_connection: 'No connection',
          bind_failed: 'Bind failed',
          unknown_error: 'An error occurred',
          connection_successful: 'Connection successful',
          user_lookup_not_executed: 'User lookup not executed',
          user_email_lookup_empty: 'User lookup (test email) returned empty result',
          user_filter_lookup_empty: 'User lookup (user filter) returned empty result',
          user_lookup_successful: 'User lookup successful',
          user_query_title: 'Querying user {test_email} returned the following attributes',
          user_filter_title: 'First entry found with user filter'
        }
      }
    },
    smtp_encryption: {
      title: 'SMTP Encryption',
      force_tls_label: 'Use TLS from Barracuda Networks to your mail server(s):',
      tls_label: 'Require TLS to Barracuda Networks from these domains:',
      never: 'Never',
      optional: 'Optional',
      required: 'Required',
      smtp_over_tls: 'SMTP over TLS',
      all_inbound_messages: 'All inbound messages',
      selected_sender_domains: 'Select sender domains',
      save_changes_helper: 'Save changes in order to enable bulk edit',
      table_description_all:
        'To force Email Gateway Defense to require a secure TLS connection from all domains except a certain sender domain, please enter the exempted sender domain name and click Add.',
      table_description_select:
        'To force Email Gateway Defense to require a secure TLS connection from certain sender domains, enter the sender domain name and click Add.',
      table_sub_description: 'If a TLS connection cannot be established, mail is not sent to the recipient domain.',
      bulk_edit: 'Bulk Edit'
    },
    encryption: {
      title: 'Encryption',
      validation_status: 'Validation Status',
      validate_cname: 'Validate CNAME',
      validate_post: 'Validate Postmaster',
      remove_validation: 'Remove Validation',
      confirm_validation: 'Confirm Validation',
      unable_to_connect: 'Unable to connect to encryption service.',
      validated: 'Validated',
      not_validated: 'Not Validated',
      pending_cname: 'Pending CNAME validation with {value}',
      pending_post: 'Pending post validation, please check postmaster@{value}',
      upload_image: 'Upload New Image:',
      choose_file: 'Choose File',
      no_file: 'No file chosen',
      remove_file: 'Remove Current Logo',
      upload_image_helper:
        'Branded image for this domain. Required file format: JPG, GIF, or PNG. Recommended resolution: 160x65 pixels',
      allow_replies: 'Allow Replies:',
      read_receipts: 'Enable Read Receipts:',
      display_name: 'Domain Display Name:',
      display_name_helper: 'This domain name will appear in encrypted messages.',
      notification_subject: 'Notification Subject:',
      notification_subject_helper:
        'Subject for encryption notifications sent to recipients. May use the same placeholders as the email parts below.',
      text_part: 'Text Part:',
      text_part_helper:
        'The text part of the custom notification that will be sent to recipients. May use the same placeholders as the HTML part below.',
      html_part: 'HTML Part:',
      html_part_helper: 'The HTML part of the custom notification that will be sent to recipients.'
    },
    warnings: {
      disable_spooling: {
        title: 'Email Continuity',
        description:
          'Disabling spooling feature will disable auto-enabling of Email Continuity for all of the domains associated with this account.'
      },
      disable_azure: {
        title: 'Turn Off Azure AD',
        description: {
          info: 'Turning off Azure AD will have the following consequences:',
          consequences: {
            sso: 'Single sign on with Barracuda ESS will no longer function',
            sync: 'New users will not automatically sync'
          },
          note: 'Note: Recipient verification will still function.'
        }
      },
      azure_authorization: {
        title: 'Authorize Azure AD',
        description: {
          info: 'To allow Azure AD directory service for single sign on and synchronization of user lists you must authenticate with Office 365 with an administrator account.',
          continue: 'Continuing on will take you to Office 365 to log in.',
          permissions: {
            label: 'You must also give permission to Email Gateway Defense to',
            user_profile: 'Sign in and read user profile',
            all_groups_read: 'Read all groups',
            all_groups_write: 'Read and write all groups',
            directory_data_read: 'Read directory data',
            directory_data_write: 'Read and write directory data',
            all_users_profile_read: "Read all users' full profiles",
            all_users_profile_write: "Read and write all users' full profiles"
          }
        }
      }
    },
    advanced: {
      title: 'Advanced configurations',
      srs: {
        title: 'Sender Rewriting Scheme (SRS):',
        yes: YES,
        no: NO
      },
      ldap_highest_serial: {
        title: 'Synchronize automatically:',
        value: 'Highest seen serial: {value}',
        reset: 'Reset'
      }
    }
  },
  verify_domain: {
    header: {
      title: 'Domain verification'
    },
    content: 'Domains must be verified by proof of ownership before you can use Email Gateway Defense to filter email.',
    options: {
      mx_records: {
        label:
          "<b>MX records</b> - Select this method if you have ADDED the MX record to your domain's DNS server. It is important that you add the records with a LOWER priority (eg: 99)",
        helper: 'MX records'
      },
      cname_record: {
        label: '<b>CNAME Records</b> - Validate using CNAME entry provided and point to ess.barracuda.com.',
        helper: 'CNAME Token'
      },
      postmaster_email: {
        label: '<b>Email to the postmaster</b> - Send a verification email to'
      },
      tech_email: {
        label:
          "<b>Email to the domain's technical contact</b> - Send a verification email to the technical contact email address listed on the domain's WHOIS entry.",
        no_record: 'No technical contact found'
      }
    },
    token_verification: 'Domain verification is in progress...',
    cpl_probe: 'Starting CPL domain verification...',
    already_verified: 'The domain {domainName} is already verified.',
    already_verified_by_other_account:
      'This domain has already been added and verified by a different account. If this is your domain, please contact Customer Services for further assistance.',
    ok: 'OK',
    next: 'Next',
    cancel: 'Cancel'
  },
  search_domain: {
    label: 'Search',
    hint: 'Search text in domain names'
  },
  recipients: {
    header: {
      title: 'Recipients list'
    },
    content: 'Unique recipients seen in the last 30 days',
    go_back: '« Back to domains',
    search_label: 'Search',
    search_hint: 'Search for a specific recipient or set of recipients',
    recipient: 'Recipient',
    no_records_available: 'No unique recipients in the last 30 days',
    no_recipients_found: '<b>{userIdFilter}</b> was not found in the unique recipients list'
  },
  global_reset: {
    title: 'Confirmation required',
    description: 'Confirm Reset to Account Policies'
  },
  save_setting: {
    inline_deployment: {
      description: 'Mail flow deployment method'
    },
    options: {
      microsoft: 'Inline deployment with Microsoft',
      off: 'MX Records'
    },
    save: 'Save',
    cancel: 'Cancel'
  },
  type_hybrid: 'Hybrid',
  transfer_pending: 'Transfer pending',
  transfer_completed: 'Transfer completed',
  verify_ownership_and_transfer: 'Verify ownership and transfer',
  verify_domain_transfer: {
    title: 'Verify domain transfer',
    info: 'The domain you are attempting to add already exists in our system. To enable a transfer to this account, verify you own the domain.',
    cname_record_info: "In your domain's DNS, add a CNAME record with the following content:",
    hostname: '<b>Hostname</b>',
    points_to: '<b>Points to</b>',
    long_running_info:
      'Transferring the domain might take several minutes to complete. Refresh the list on the Domains Manager page if the transfer does not appear to be complete.',
    cancel: 'Cancel',
    verify: 'Verify ownership and transfer',
    back_to_domains: 'Back to domains',
    error: {
      domain_transfer_not_found: 'Required CNAME record for this domain cannot be found',
      domain_not_found: 'This domain cannot be found',
      domain_not_verified:
        'This domain is not verified. It must be in the verified state in order to be transferred to another account.',
      get_move_domain_failed: 'An error occurred while trying to fetch transfer state',
      domain_transfer_already_complete: 'This domain already belongs to this account.',
      verify_domain_failed: 'An error occurred while trying to verify the domain',
      graphql_error: 'Failed to contact the server to complete the domain move, please try again.',
      cname_record_not_found:
        "Currently, the correct CNAME record for your domain cannot be found. Please verify that the entry has been added into your DNS records. Note that any changes could take up to 24 hours to propagate depending on your ISP's settings.",
      transfer_pending: 'The domain is already being transferred.',
      mstore_error: 'The operation returned an error: {message}',
      mstore_timeout: 'The operation timed out'
    }
  }
}

/* eslint-enable quotes */
export default domains
