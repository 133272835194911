import React from 'react'

import Loading from 'components/libs/loading/Loading'
import { useOAuth2AuthorizeLogic } from 'components/pages/oauth2/useOAuth2AuthorizeLogic'

function OAuth2Authorize() {
  useOAuth2AuthorizeLogic()

  return <Loading />
}

export default OAuth2Authorize
