import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { GraphQLRequestPayload, doRestoreGraphql } from 'redux/features/graphql/graphqlApiThunks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { QueryType } from 'types/Support'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'

export type State = {
  isCopied: boolean
  tokenVal: MutableRefObject<HTMLInputElement | null>
  isButtonDisabled: boolean
  applyInProgress: boolean
  applyConfig: boolean
}
export type EventHandlers = {
  onCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  onApplyClick: () => void
}

export type UseAccountConfigurationDialogLogicProps = {
  onCloseDialog: () => void
  token: string
  forApply: boolean
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}
export type UseAccountConfigurationDialogLogic = [State, EventHandlers]

export const useAccountConfigurationDialog = ({
  onCloseDialog,
  delegateDirtyFormState
}: UseAccountConfigurationDialogLogicProps): UseAccountConfigurationDialogLogic => {
  const dispatch = useAppDispatch()
  const { doRestoreSuccess, doRestoreFailed, doRestoreProgress } = useAppSelector(_stores => ({
    doRestoreSuccess: isSuccess(_stores.graphql.api.doRestoreGraphqlApiStatus),
    doRestoreFailed: isFailed(_stores.graphql.api.doRestoreGraphqlApiStatus),
    doRestoreProgress: isPending(_stores.graphql.api.doRestoreGraphqlApiStatus)
  }))
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const tokenInput = useRef<HTMLInputElement | null>(null)
  const [applyConfig, setApplyConfig] = useState(false)
  const [isDirtyForm] = useDirtyFormCheck([tokenInput.current?.value || ''])
  const MIN_LENGTH = 5

  useEffect(() => {
    delegateDirtyFormState(isDirtyForm)
  }, [isDirtyForm, delegateDirtyFormState])

  useEffect(() => {
    setIsCopied(false)
    if (tokenInput.current) {
      setIsButtonDisabled(tokenInput.current.value.length <= MIN_LENGTH)
    }

    if (doRestoreSuccess && applyConfig) {
      setApplyConfig(false)
      onCloseDialog()
    }

    if (doRestoreFailed && applyConfig) {
      onCloseDialog()
    }
  }, [tokenInput, doRestoreSuccess, doRestoreFailed, applyConfig, onCloseDialog])

  const onApplyClick = useCallback(() => {
    setApplyConfig(true)
    if (tokenInput.current) {
      const getTokenPayload: GraphQLRequestPayload = {
        queryType: QueryType.DORESTORE,
        token: tokenInput.current.value
      }
      dispatch(doRestoreGraphql(getTokenPayload))
    }
  }, [dispatch])

  const handleOnInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    setIsButtonDisabled(inputValue.length <= MIN_LENGTH)
  }, [])

  const onCancel = useCallback(() => {
    setApplyConfig(false)
    onCloseDialog()
  }, [onCloseDialog])

  return useMemo(
    () => [
      { isCopied, tokenVal: tokenInput, isButtonDisabled, applyInProgress: doRestoreProgress, applyConfig },
      {
        onCancel,
        handleOnInputChange,
        onApplyClick
      }
    ],
    [isCopied, onCancel, handleOnInputChange, onApplyClick, isButtonDisabled, doRestoreProgress, applyConfig]
  )
}
