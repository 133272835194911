import React, { PropsWithChildren, useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'

export const FormText = ({ children }: PropsWithChildren) =>
  useMemo(
    () => (
      <Grid container justifyContent="flex-start">
        <Typography variant="body1">{children}</Typography>
      </Grid>
    ),
    [children]
  )
