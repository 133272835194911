import { useState, useMemo, useCallback, useEffect } from 'react'

import { SearchSettings } from 'lib/services/searchSettings/searchSettingsRepository'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getAllSearchSettings, applySearchSettings, deleteSearchSettings } from 'redux/features/mstore/mstoreSlice'

export interface UseOpenSearchDialogLogic {
  savedSearchQuery: string
  savedSearches: SearchSettings[]
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleDeleteSearch: (searchId: string) => void
  handleSelectSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  selectedId: string
  onClickOpen: () => void
}

export const useOpenSearchDialogLogic = (onClose: () => void): UseOpenSearchDialogLogic => {
  const dispatch = useAppDispatch()

  const { searchSettings } = useAppSelector(_store => ({
    searchSettings: _store.mstore.searchSettings
  }))

  const [savedSearchQuery, setSavedSearchQuery] = useState('')
  const [selectedId, setSelectedId] = useState<string>('')

  useEffect(() => {
    dispatch(getAllSearchSettings())
  }, [dispatch])

  const filteredSearchSettings = useMemo(() => {
    if (!savedSearchQuery) {
      return searchSettings
    }

    return searchSettings.filter(
      searchSetting =>
        searchSetting.name.toLowerCase().includes(savedSearchQuery.toLowerCase()) ||
        (searchSetting.messageSearchFilters.search_str || '').toLowerCase().includes(savedSearchQuery.toLowerCase())
    )
  }, [searchSettings, savedSearchQuery])

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSavedSearchQuery(value)
  }, [])

  const handleDeleteSearch = useCallback(
    (searchId: string) => {
      dispatch(deleteSearchSettings(searchId))
    },
    [dispatch]
  )

  const handleSelectSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedId(e.target.value)
  }, [])

  const onClickOpen = useCallback(() => {
    dispatch(applySearchSettings(selectedId))
    onClose()
  }, [dispatch, onClose, selectedId])

  return useMemo(
    () => ({
      savedSearchQuery,
      savedSearches: filteredSearchSettings,
      handleInputChange,
      handleDeleteSearch,
      selectedId,
      handleSelectSearch,
      onClickOpen
    }),
    [
      savedSearchQuery,
      filteredSearchSettings,
      handleInputChange,
      handleDeleteSearch,
      selectedId,
      handleSelectSearch,
      onClickOpen
    ]
  )
}
