/* eslint-disable quotes */
export type TZValue = string
export type TZOffset = number
export type TZUtc = string
export type TZDescription = string
export type Timezone = [TZValue, TZOffset, TZUtc, TZDescription]

export default [
  ['ETC/GMT+12', -43200, '-12:00', 'International Date Line West'],
  ['Pacific/Apia', -39600, '-11:00', 'Midway Island, Samoa'],
  ['Pacific/Honolulu', -36000, '-10:00', 'Hawaii'],
  ['America/Anchorage', -32400, '-9:00', 'Alaska'],
  ['America/Los_Angeles', -28800, '-8:00', 'Pacific Time (US & Canada); Tijuana'],
  ['America/Phoenix', -25200, '-7:00', 'Arizona'],
  ['America/Denver', -25200, '-7:00', 'Mountain Time (US & Canada)'],
  ['America/Chihuahua', -25200, '-7:00', 'Chihuahua, La Paz, Mazatlan'],
  ['America/Managua', -21600, '-6:00', 'Central America'],
  ['America/Regina', -21600, '-6:00', 'Saskatchewan'],
  ['America/Mexico_City', -21600, '-6:00', 'Guadalajara, Mexico City, Monterrey'],
  ['America/Chicago', -21600, '-6:00', 'Central Time (US & Canada)'],
  ['America/Indianapolis', -18000, '-5:00', 'Indiana (East)'],
  ['America/Bogota', -18000, '-5:00', 'Bogota, Lima, Quito'],
  ['America/New_York', -18000, '-5:00', 'Eastern Time (US & Canada)'],
  ['America/Caracas', -14400, '-4:00', 'Caracas, La Paz'],
  ['America/Santiago', -14400, '-4:00', 'Santiago'],
  ['America/Halifax', -14400, '-4:00', 'Atlantic Time (Canada)'],
  ['America/St_Johns', -12600, '-4:-30', 'Newfoundland'],
  ['America/Buenos_Aires', -10800, '-3:00', 'Buenos Aires, Georgetown'],
  ['America/Godthab', -10800, '-3:00', 'Greenland'],
  ['America/Sao_Paulo', -10800, '-3:00', 'Brasilia'],
  ['America/Noronha', -7200, '-2:00', 'Mid-Atlantic'],
  ['Atlantic/Cape_Verde', -3600, '-1:00', 'Cape Verde Is.'],
  ['Atlantic/Azores', -3600, '-1:00', 'Azores'],
  ['Africa/Casablanca', 0, '-0:00', 'Casablanca, Monrovia'],
  ['Europe/London', 0, '-0:00', 'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London'],
  ['Africa/Lagos', 3600, '+1:00', 'West Central Africa'],
  ['Europe/Berlin', 3600, '+1:00', 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'],
  ['Europe/Paris', 3600, '+1:00', 'Brussels, Copenhagen, Madrid, Paris'],
  ['Europe/Sarajevo', 3600, '+1:00', 'Sarajevo, Skopje, Warsaw, Zagreb'],
  ['Europe/Belgrade', 3600, '+1:00', 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'],
  ['Africa/Johannesburg', 7200, '+2:00', 'Harare, Pretoria'],
  ['Asia/Jerusalem', 7200, '+2:00', 'Jerusalem'],
  ['Europe/Istanbul', 7200, '+2:00', 'Athens, Istanbul, Minsk'],
  ['Europe/Helsinki', 7200, '+2:00', 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'],
  ['Africa/Cairo', 7200, '+2:00', 'Cairo'],
  ['Europe/Bucharest', 7200, '+2:00', 'Bucharest'],
  ['Africa/Nairobi', 10800, '+3:00', 'Nairobi'],
  ['Asia/Riyadh', 10800, '+3:00', 'Kuwait, Riyadh'],
  ['Europe/Moscow', 10800, '+3:00', 'Moscow, St. Petersburg, Volgograd'],
  ['Asia/Baghdad', 10800, '+3:00', 'Baghdad'],
  ['Asia/Tehran', 12600, '+3:30', 'Tehran'],
  ['Asia/Muscat', 14400, '+4:00', 'Abu Dhabi, Muscat'],
  ['Asia/Tbilisi', 14400, '+4:00', 'Baku, Tbilisi, Yerevan'],
  ['Asia/Kabul', 16200, '+4:30', 'Kabul'],
  ['Asia/Karachi', 18000, '+5:00', 'Islamabad, Karachi, Tashkent'],
  ['Asia/Yekaterinburg', 18000, '+5:00', 'Ekaterinburg'],
  ['Asia/Calcutta', 19800, '+5:30', 'Chennai, Kolkata, Mumbai, New Delhi'],
  ['Asia/Katmandu', 20700, '+5:45', 'Kathmandu'],
  ['Asia/Colombo', 21600, '+6:00', 'Sri Jayawardenepura'],
  ['Asia/Dhaka', 21600, '+6:00', 'Astana, Dhaka'],
  ['Asia/Novosibirsk', 21600, '+6:00', 'Almaty, Novosibirsk'],
  ['Asia/Rangoon', 23400, '+6:30', 'Rangoon'],
  ['Asia/Bangkok', 25200, '+7:00', 'Bangkok, Hanoi, Jakarta'],
  ['Asia/Krasnoyarsk', 25200, '+7:00', 'Krasnoyarsk'],
  ['Australia/Perth', 28800, '+8:00', 'Perth'],
  ['Asia/Taipei', 28800, '+8:00', 'Taipei'],
  ['Asia/Singapore', 28800, '+8:00', 'Kuala Lumpur, Singapore'],
  ['Asia/Hong_Kong', 28800, '+8:00', 'Beijing, Chongqing, Hong Kong, Urumqi'],
  ['Asia/Irkutsk', 28800, '+8:00', 'Irkutsk, Ulaan Bataar'],
  ['Asia/Tokyo', 32400, '+9:00', 'Osaka, Sapporo, Tokyo'],
  ['Asia/Seoul', 32400, '+9:00', 'Seoul'],
  ['Asia/Yakutsk', 32400, '+9:00', 'Yakutsk'],
  ['Australia/Darwin', 34200, '+9:30', 'Darwin'],
  ['Australia/Adelaide', 34200, '+9:30', 'Adelaide'],
  ['Pacific/Guam', 36000, '+10:00', 'Guam, Port Moresby'],
  ['Australia/Brisbane', 36000, '+10:00', 'Brisbane'],
  ['Asia/Vladivostok', 36000, '+10:00', 'Vladivostok'],
  ['Australia/Hobart', 36000, '+10:00', 'Hobart'],
  ['Australia/Sydney', 36000, '+10:00', 'Canberra, Melbourne, Sydney'],
  ['Asia/Magadan', 39600, '+11:00', 'Magadan, Solomon Is., New Caledonia'],
  ['Pacific/Fiji', 43200, '+12:00', 'Fiji, Kamchatka, Marshall Is.'],
  ['Pacific/Auckland', 43200, '+12:00', 'Auckland, Wellington'],
  ['Pacific/Tongatapu', 46800, '+13:00', "Nuku'alofa"]
] as Timezone[]
