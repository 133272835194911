import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    padding: theme.spacing(2),
    width: 600
  },
  listHeader: {
    color: theme.palette.secondary.dark,
    fontWeight: 300,
    paddingTop: theme.spacing(3)
  },
  scrollBox: {
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))
