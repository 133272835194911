import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import { useQuarantineNotificationLogic } from 'components/pages/users/quarantineNotification/useQuarantineNotificationLogic'
import ScheduleMatrix from 'components/libs/scheduler/ScheduleMatrix'
import {
  FormRow,
  FormSpacer,
  HelperText,
  InlineRadioGroup,
  PageContainer,
  PageTitle
} from 'components/libs/layout/pages/pageLayout'

import styles from 'components/pages/users/quarantineNotification/quarantineNotificationStyles'
import routesConfig from 'lib/routesConfig'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import UsersQuarantineSupport from 'components/pages/support/help/usersQuarantine/UsersQuarantine'

const BASE_I18N_KEY = 'ess.users.quarantine_notification'

export type RadioGroupFormProps = {
  name: string
  title: string
  help?: string
  value: RadioButtonStates
  onChange: (newValue: RadioButtonStates) => void
  inProgress: boolean
  onText?: string
  offText?: string
}

const QuarantineNotification: React.FC = () => {
  const [state, eventHandlers] = useQuarantineNotificationLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: UsersQuarantineSupport,
    title: TOPICS.userQuarantineNotification,
    groupTitle: GROUP_TITLE.userTopics
  })

  return useMemo(
    () => (
      <PageContainer
        isLoading={state.isLoading}
        isFailedToLoad={state.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.isDirtyForm}
      >
        <HelpModal />
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={onOpenSupport}
          onCancel={() => routesConfig.USERS_LIST.goto()}
          onSubmit={eventHandlers.onSaveChanges}
          submitInProgress={state.inProgress}
          isDisabledSubmit={!state.isDirtyForm}
        />
        <FormRow rowTitle={formatMessage('quarantine_messages_for_intent_analysis')}>
          <InlineRadioGroup
            name="quarantine_messages_for_intent_analysis"
            value={state.isEnabledMessagesForIntentAnalysis}
            onChange={eventHandlers.setIsEnabledMessagesForIntentAnalysis}
            disabled={state.inProgress}
          />
        </FormRow>
        <FormRow rowTitle="">
          <HelperText>{formatMessage('quarantine_messages_for_intent_analysis_help')}</HelperText>
        </FormRow>

        <FormRow rowTitle={formatMessage('default_interval')}>
          <InlineRadioGroup
            name="default_interval"
            value={state.defaultInterval}
            onChange={eventHandlers.setDefaultInterval}
            disabled={state.inProgress}
            labels={[formatMessage('never'), formatMessage('scheduled')]}
          />
        </FormRow>

        <FormRow rowTitle={formatMessage('scheduled_interval')} />
        <Grid container direction="column">
          <Grid item className={classes.scheduledMatrixContainer}>
            <ScheduleMatrix {...state.scheduleMatrixConfig} />
            <Grid item className={classes.helperTextContainer}>
              <HelperText>{formatMessage('scheduled_interval_help')}</HelperText>
            </Grid>
          </Grid>
        </Grid>

        <FormRow rowTitle={formatMessage('allow_specify_interval')}>
          <InlineRadioGroup
            name="allow_specify_interval"
            value={state.isAllowSpecifyInterval}
            onChange={eventHandlers.setIsAllowSpecifyInterval}
            disabled={state.inProgress}
          />
        </FormRow>

        <FormRow rowTitle={formatMessage('allow_temp_passcode')}>
          <InlineRadioGroup
            name="allow_temp_passcode"
            value={state.isTempPasscodeEnabled}
            onChange={eventHandlers.setIsTempPasscodeEnabled}
            disabled={state.inProgress}
          />
        </FormRow>
        <FormRow rowTitle="">
          <HelperText>{formatMessage('allow_temp_passcode_help')}</HelperText>
        </FormRow>
        <FormSpacer />
        <FormSpacer />
      </PageContainer>
    ),
    [eventHandlers, classes, formatMessage, state, onOpenSupport, HelpModal]
  )
}

export default QuarantineNotification
