import { useCallback, useMemo } from 'react'
import { SenderPolicy, SenderPolicyStatus } from 'types/Settings'
import { isEmailOrDomainValid } from 'lib/validation'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface SenderPoliciesFormState {
  policy: SenderPolicyStatus
  name: string
  comment: string
}

export interface ValidatorDependencies {
  data: SenderPolicy[]
}

export interface SenderPoliciesFormConfig {
  initialState: SenderPoliciesFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type SenderPoliciesForm = SettingsForm<SenderPoliciesFormState>

export const useSenderPoliciesForm = ({
  initialState,
  delegateIsDirtyForm
}: SenderPoliciesFormConfig): SenderPoliciesForm => {
  const nameField = useRefField(initialState.name)
  const commentField = useRefField(initialState.comment)
  const policyField = useStateField(initialState.policy)

  const formConfig = useMemo(
    () => ({
      fields: {
        name: nameField,
        comment: commentField,
        policy: policyField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, nameField, policyField]
  )
  const form = useSettingsForm<SenderPoliciesFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: SenderPolicy, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.data.find(entry => entry.name === newPolicy.name)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      if (!newPolicy.name || !isEmailOrDomainValid(newPolicy.name)) {
        form.setError('invalid_email')
        return false
      }
      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
