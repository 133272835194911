import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundAntiVirus(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      <b>Use Barracuda Reputation Block List (BRBL)</b>
      <br />
      Barracuda Reputation Block List (BRBL) is a database of IP addresses that have been manually verified to be a
      noted source of spam. Incoming connection requests from IP addresses on this list are terminated, and no
      information about the incoming message (other than the originating IP address) is kept. Select to <b>
        Block
      </b>{' '}
      {!props.hasCpl && (
        <>
          or <b>Quarantine</b>
        </>
      )}{' '}
      messages, or set to <b>Off</b> to bypass this type of analysis. Checks are made against this list prior to any
      other external IP block lists, and can be overridden by:
      <ul>
        <li>All Allow Lists</li>
        <li>
          Exempting the IP address on the <b>Inbound Settings &gt; IP Address Policies</b> page
        </li>
      </ul>
      <p>
        Barracuda strongly recommends setting <b>Use Barracuda Reputation Block List</b> to <b>Block</b>{' '}
        {!props.hasCpl && (
          <>
            or <b>Quarantine</b>
          </>
        )}{' '}
        on this page.
      </p>
      <br />
      <br />
      <b>Scan Email for Viruses</b>
      <br />
      When virus scanning is enabled, all messages processed by {props.productName} are checked for viruses.
      <br />
      <br />
      Note: Detected viruses are not{!props.hasCpl && <> quarantined or </>} delivered to the intended recipient - even
      if the message is allowed by a Block/Exempt filter. Barracuda strongly recommends setting{' '}
      <b>Scan Email for Viruses</b> to <b>Yes</b> on this page.
      <br />
      <br />
      <b>Use Barracuda Real-Time System (BRTS)</b>
      <br />
      Barracuda Real-Time System (BRTS) is an advanced service to detect zero-hour spam and virus outbreaks even where
      traditional heuristics and signatures to detect such messages do not yet exist. Select to <b>Block</b>{' '}
      {!props.hasCpl && (
        <>
          or <b>Quarantine</b>
        </>
      )}{' '}
      messages, or set to <b>Off</b> to bypass this type of analysis.{' '}
      {!props.hasCpl && (
        <>
          Each quarantined message has a reason of <b>BRTS</b> in the Message Log. If the user has not set up quarantine
          for email, messages are blocked.
        </>
      )}
      {props.brtsScan && (
        <ul>
          <li>
            <b>Scan Message Content</b>
            <br />
            If checked, attachments are scanned using a real-time database that helps detect virus outbreaks. Barracuda
            Central may request that a message containing a suspected virus be forwarded for analysis, and this setting
            enables forwarding of the message to Barracuda Central.
          </li>
        </ul>
      )}
      <br />
      <b>Enable CloudScan</b>
      <br />
      When set to <b> Yes</b>, CloudScan, a cloud - based spam scanning engine, assigns a score to each message
      processed ranging from 0(unlikely spam) to 10(definitely spam).Setting a score of 1 likely blocks legitimate
      messages while setting a score of 10 allows more messages through the system.Based on this score,{' '}
      {props.productName} blocks messages that appear to be spam and they are logged in the user&apos;s Message Log with{' '}
      <b>Score</b> as the reason for the block.
      <br /> <br />
      To disable this feature, set <b> CloudScan Scoring</b> to <b> 0</b>.
      <br /> <br />
      {!props.hasCpl && (
        <>
          <b>Note:</b> The <b> Quarantine</b> score should be & gt;=0(greater than or equal to 0), and less than the{' '}
          <b> Block</b> score.If you enter a score for
          <b>Quarantine</b> greater than the <b> Block</b> score, the system automatically adjusts it to be one less
          than the <b> Block</b> score.
          <br />
          <br />
          <b>Email Categorization</b>
          <br />
          This feature gives administrators more control over what they believe to be spam, even though those messages
          may not meet the technical definition of
          <i> spam</i>.Most users do not realize that newsletters and other subscription - based emails, while they are
          considered to be bulk email, are not technically unsolicited - which means that they cannot be blocked by
          default as spam.Emails that originate from Barracuda - verified sources(including what was formerly known as
          the Barracuda Reputation Whitelist) are categorized into one of the following Categories based on the sending
          domain name and IP address:
          <ul>
            <li>
              <b>Corporate Emails</b>
              <br />
              Emails sent from an authenticated organization&apos;s Barracuda-verified mail server. Meant for general
              corporate communications only. The default action is <b>Allow</b>.
            </li>
            <li>
              <b>Transactional Emails</b>
              <br />
              Emails related to a specific transaction or order, and automated notifications. Includes order and
              delivery confirmations and notices; bills and invoices; bank statements; account update notices. This
              Category should always be set to <b>Allow</b> to ensure that critical emails are allowed through.
            </li>
            <li>
              <b>Marketing Materials</b>
              <br />
              Promotional emails and newsletters from companies such as Constant Contact. The default action is{' '}
              <b>Off</b>.
            </li>
            <li>
              <b>Mailing Lists</b>
              <br />
              Emails from mailing lists, newsgroups, and other subscription-based services such as Google and Yahoo!
              Groups. The default action is <b>Allow</b>.
            </li>
            <li>
              <b>Social Media</b>
              <br />
              Notifications and other emails from social media sites such as Facebook and LinkedIn. The default action
              is <b>Allow</b>.
            </li>
          </ul>
          The administrator can specify an <b> Action</b> to take on messages in that Category, or set the Action to{' '}
          <b> Off</b> in order to use all other spam scanning measures and processes that determine the action to take.A
          message may fall into multiple Email Categories, each of which can be associated with a different{' '}
          <b> Action</b>.In these situations, the action with the higher precedence is taken on that message, as listed
          below in descending order of precedence:
          <ul>
            <li>
              <b>Allow</b>
              <br />
              Deliver the message.
            </li>
            <li>
              <b>Block</b>
              <br />
              Do not deliver the message. The complete contents can still be viewed from the Message Log.
            </li>
            <li>
              <b>Quarantine</b>
              <br />
              Put the message in quarantine if there are no other checks for other categories that can result in actions
              of higher precedence (Allow, Block).
            </li>
            <li>
              <b>Off</b>
              <br />
              No action based on this category is taken. All other spam scanning and processing is done with the
              message.
            </li>
          </ul>
          This feature takes both the sending domain and IP address into account to determine how the message should be
          classified.Barracuda recommends enabling Email Categorization by selecting one of the actions above.To disable
          this feature, select <b> Off</b> for each category.
          <br /> <br />
          If this feature is enabled, check this page to view how emails that are selected by this filter are being
          categorized.Also check the Message Log and determine if the categorization works for your users.If a message
          is categorized but the admin or user thinks the category should be different, use
          <b> Recategorization</b> on the <b> Overview & gt; Message Log</b> page to change the category for that
          message.
          <br /> <br />
        </>
      )}
      <b> Bulk Email Detection</b>
      <br />
      Select <b> Block</b>{' '}
      {!props.hasCpl && (
        <>
          {' '}
          or <b> Quarantine</b>{' '}
        </>
      )}{' '}
      to take action with inbound messages that contain anything that looks like unsubscribe links or unsubscribe
      instructions in the message body.These messages may or may not be considered as spam by the recipient.To allow all
      such messages that are not otherwise tagged as spam, set this feature to <b> Off</b>.
      <br />
      <br />
      <b>Note</b>: If this feature is set to <b> Block</b>{' '}
      {!props.hasCpl && (
        <>
          {' '}
          or <b> Quarantine</b>{' '}
        </>
      )}
      , email messages / domains that are exempt(allowed) by users or the administrator will override this setting and
      will be allowed.Bulk email messages can be exempted by an IP policy or a sender policy.Configure an IP policy on
      the <b> Inbound Settings &gt; IP Address Policies</b> page and a sender policy on the{' '}
      <b> Inbound Settings &gt; Sender Policies</b> page.
    </div>
  )
}

export default InboundAntiVirus
