import React from 'react'

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Zoom
} from '@barracuda-internal/bds-core'
import { DropDownArrow } from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { Close as CloseIcon, MenuVertical } from '@barracuda-internal/bds-core/dist/Icons/Core'

import styles from './unifiedReportingTitleBarStyles'

export type UnifiedReportingTitleBarProps = {
  downloadConfig: DownloadReportConfig
  reportName: string
  reportOptionsConfig: ReportOptionsConfig
  saveReportDialogConfig: SaveReportDialogConfig
}

/**
 * Config for the download button in report header
 */
export interface DownloadReportConfig {
  /**
   *  Is there a download in progress
   */
  isDownloadPending: boolean
  /**
   *  Anchor element that the download menu will be binded to
   */
  downloadAnchorEl: null | HTMLElement
  /**
   *  Text for the export button
   */
  downloadButtonText: string
  /**
   *  Download options to show in the download menu
   */
  downloadMenuItems: string[]
  /**
   *  Click handler for the download button
   */
  onDownloadButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   *  Click handler for item in download menu
   */
  onDownloadMenuItemClick: React.MouseEventHandler<HTMLLIElement> | undefined
  /**
   *  Click handler for closing the download menu
   */
  onDownloadMenuClose: React.MouseEventHandler<HTMLButtonElement> | undefined
}

/**
 * Config for report options in report header
 */
export interface ReportOptionsConfig {
  /**
   * Click handler for report options button
   */
  onReportOptionsButtonClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Click handler for closing report option menu
   */
  onReportOptionsMenuClose: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Click handler for report option menu item
   */
  onReportOptionsMenuItemClick: React.MouseEventHandler<HTMLLIElement> | undefined
  /**
   * List of report options in menu
   */
  reportOptions: string[]
  /**
   * Anchor element for report option menu
   */
  reportOptionsAnchorEl: null | HTMLElement
}

/**
 * Config for save report dialog
 */
export interface SaveReportDialogConfig {
  /**
   * Change event handler for updateing report name
   */
  onReportNameChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  /**
   * Click handler for closing the save report dialog
   */
  onClose: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Click handler for opening the save report dialog
   */
  onOpen: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Click handler for saving report on save report dialog
   */
  onSave: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Boolean to determine if dialog is opened or closed
   */
  open: boolean
  /**
   * Save button text in report header
   */
  saveButtonText: string
  /**
   * Close button text in save report dialog
   */
  saveReportDialogCloseButtonText: string
  /**
   * Save button text in save report dialog
   */
  saveReportDialogSaveButtonText: string
  /**
   * Text for subtitle in save report dialog
   */
  saveReportDialogSubtitle: string
  /**
   * Label for report name text box in save report dialog
   */
  saveReportDialogTextfieldLabel: string
  /**
   * Text for title on save report dialog
   */
  saveReportDialogTitle: string
  /**
   * Selected report name for placeholder text
   */
  selectedReport: string
  /**
   * Custom content to able to add additional UI components
   */
  CustomContent?: any
  /**
   * Button's disabled state
   */
  isDisabledSaveButton?: boolean
}

export function UnifiedReportingTitleBar(props: UnifiedReportingTitleBarProps) {
  const classes = styles()

  const { downloadConfig, reportName, reportOptionsConfig, saveReportDialogConfig } = props

  const {
    isDownloadPending,
    downloadAnchorEl,
    downloadButtonText,
    downloadMenuItems,
    onDownloadButtonClick,
    onDownloadMenuClose,
    onDownloadMenuItemClick
  } = downloadConfig

  const {
    onReportOptionsButtonClick,
    onReportOptionsMenuClose,
    onReportOptionsMenuItemClick,
    reportOptions,
    reportOptionsAnchorEl
  } = reportOptionsConfig

  const {
    onClose,
    onOpen,
    onReportNameChange,
    onSave,
    open,
    saveButtonText,
    saveReportDialogCloseButtonText,
    saveReportDialogSaveButtonText,
    saveReportDialogSubtitle,
    saveReportDialogTextfieldLabel,
    saveReportDialogTitle,
    selectedReport,
    CustomContent,
    isDisabledSaveButton = false
  } = saveReportDialogConfig

  return (
    <Card className={classes.container}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={7}>
          <Tooltip
            interactive
            placement="bottom-start"
            title={
              <Typography className={classes.titleLabelTooltip} variant="subtitle1">
                {reportName}
              </Typography>
            }
          >
            <Typography className={classes.titleLabel} noWrap variant="h4">
              {reportName}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid container item xs={5} alignItems="center" justifyContent="flex-end">
          <Button
            isLoading={isDownloadPending}
            className={classes.headerButton}
            size="small"
            variant="text"
            color="primary"
            endIcon={<DropDownArrow />}
            onClick={onDownloadButtonClick}
          >
            {downloadButtonText}
          </Button>
          <Menu
            getContentAnchorEl={null}
            anchorEl={downloadAnchorEl}
            keepMounted
            open={Boolean(downloadAnchorEl)}
            onClose={onDownloadMenuClose}
            anchorOrigin={{
              vertical: 40,
              horizontal: 20
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            {downloadMenuItems.map((option: string) => (
              <MenuItem key={option} value={option} onClick={onDownloadMenuItemClick}>
                {option}
              </MenuItem>
            ))}
          </Menu>
          <Button
            className={classes.headerButton}
            size="small"
            variant="contained"
            color="primary"
            onClick={onOpen}
            disabled={isDisabledSaveButton}
          >
            {saveButtonText}
          </Button>
          {open && (
            <Dialog style={{ zIndex: '1500' }} maxWidth="sm" fullWidth open={open} TransitionComponent={Zoom}>
              <DialogTitle className={classes.saveReportDialogTitleContainer}>
                <Grid container item xs={12} alignContent="space-between">
                  <Grid container item direction="row" xs={11} alignItems="center">
                    <Typography className={classes.saveReportDialogTitle}>{saveReportDialogTitle}</Typography>
                  </Grid>
                  <Grid container item alignContent="flex-start" justifyContent="flex-end" xs={1}>
                    <IconButton size="small" onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Typography className={classes.saveReportDialogSubtitle}>{saveReportDialogSubtitle}</Typography>
                <TextField
                  id="outlined-required"
                  fullWidth
                  label={saveReportDialogTextfieldLabel}
                  defaultValue={selectedReport}
                  onChange={onReportNameChange}
                  size="small"
                />
                {CustomContent && <CustomContent />}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button size="large" color="primary" variant="text" onClick={onClose}>
                  {saveReportDialogCloseButtonText}
                </Button>
                <Button
                  className={classes.saveReportButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={onSave}
                >
                  {saveReportDialogSaveButtonText}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {!!reportOptions.length && (
            <Grid>
              <IconButton className={classes.headerButton} size="small" onClick={onReportOptionsButtonClick}>
                <MenuVertical />
              </IconButton>
              <Menu
                getContentAnchorEl={null}
                anchorEl={reportOptionsAnchorEl}
                keepMounted
                open={Boolean(reportOptionsAnchorEl)}
                onClose={onReportOptionsMenuClose}
                anchorOrigin={{
                  vertical: 40,
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                {reportOptions.map((option: any) => (
                  <MenuItem key={option} value={option} onClick={onReportOptionsMenuItemClick}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
