import { Setting } from 'types/domains'
import { SettingValue } from 'types/Settings'

export interface EncryptionLogo {
  filename?: string
  image?: string
}

export interface EncryptionSettings {
  allowreplies?: Setting<SettingValue> | null
  getReadreceipts?: Setting<SettingValue> | null
  logoDataB64?: Setting<EncryptionLogo> | null
  friendlyname?: Setting<string> | null
  notificationSubject?: Setting<string> | null
  htmlNotification?: Setting<string> | null
  txtNotification?: Setting<string> | null
}

export enum AvailableEncryptionSettings {
  ALLOWREPLIES = 'allowreplies',
  GET_READRECEIPTS = 'getReadreceipts',
  LOGO_DATA_B64 = 'logoDataB64',
  FRIENDLYNAME = 'friendlyname',
  NOTIFICATION_SUBJECT = 'notificationSubject',
  HTML_NOTIFICATION = 'htmlNotification',
  TXT_NOTIFICATION = 'txtNotification'
}
