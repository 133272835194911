import { useMemo, useEffect, useCallback, ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { SettingValue } from 'types/Settings'
import { DomainEncryptionType, EncryptionStatus } from 'types/domains'
import { isSuccess, isPending } from 'redux/toolkit/api'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import {
  cnameValidation,
  confirmValidation,
  postValidation,
  removeLogo,
  removeValidation
} from 'redux/features/encryption/encryptionSlice'
import { useFileReader } from 'lib/useFileReader'
import { AvailableEncryptionSettings, EncryptionLogo } from 'types/Encryption'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

export interface State {
  validationStatus: string
  pendingValidation: {
    label: string
    value: string
  }
  confirmedValidation: boolean
  allowReplies: string
  readReceipts: string
  image: EncryptionLogo
  displayName: string
  notificationSubject: string
  htmlNotification: string
  textNotification: string
  inProgress: boolean
  actionPending: boolean
}

export interface EventHandlers {
  onButtonClick: (name: BUTTON_NAMES) => void
  onInputChange: (name: AvailableEncryptionSettings) => (NewValue: any) => void
  onAddFile: (e: ChangeEvent<HTMLInputElement>) => void
}

export type UseEditDomainEncryptionLogic = [State, EventHandlers]

export const enum BUTTON_NAMES {
  CNAME = 'cname',
  POST = 'post',
  CONFIRM = 'confirm',
  REMOVE_VALIDATION = 'remove_validation',
  REMOVE_LOGO = 'remove_logo'
}

export const useEditDomainEncryptionLogic = (form: EditDomainForm): UseEditDomainEncryptionLogic => {
  const makeFileReader = useFileReader()
  const dispatch = useAppDispatch()
  const [
    {
      inProgress,
      domainId,
      logoDataB64,
      allowreplies,
      getReadreceipts,
      friendlyname,
      notificationSubject,
      htmlNotification,
      txtNotification,
      domain
    }
  ] = form

  const {
    isGetDomainSuccess,
    isCnameValidationInProgress,
    isPostValidationInProgress,
    isConfirmValidationInProgress,
    isRemoveValidationInProgress,
    isRemoveLogoInProgress
  } = useAppSelector(_store => ({
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
    isCnameValidationInProgress: isPending(_store.encryption.cnameValidationApiStatus),
    isPostValidationInProgress: isPending(_store.encryption.postValidationApiStatus),
    isConfirmValidationInProgress: isPending(_store.encryption.confirmValidationApiStatus),
    isRemoveValidationInProgress: isPending(_store.encryption.removeValidationApiStatus),
    isRemoveLogoInProgress: isPending(_store.encryption.removeLogoApiStatus)
  }))

  useEffect(() => {
    if (isGetDomainSuccess) {
      logoDataB64.reset(domain?.encryptionImage ? { image: domain?.encryptionImage } : {})
      allowreplies.reset(domain?.encryptionAllowReplies || SettingValue.DISABLED)
      getReadreceipts.reset(domain?.encryptionReadReceipts || SettingValue.DISABLED)
      friendlyname.reset(domain?.encryptionDisplayName || '')
      notificationSubject.reset(domain?.encryptionNotificationSubject || '')
      htmlNotification.reset(domain?.encryptionHtmlPart || '')
      txtNotification.reset(domain?.encryptionTextPart || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  const actionPending = useMemo(
    () =>
      isCnameValidationInProgress ||
      isPostValidationInProgress ||
      isConfirmValidationInProgress ||
      isRemoveValidationInProgress ||
      isRemoveLogoInProgress,
    [
      isCnameValidationInProgress,
      isPostValidationInProgress,
      isConfirmValidationInProgress,
      isRemoveValidationInProgress,
      isRemoveLogoInProgress
    ]
  )

  const validationStatus = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (true) {
      case !domain?.encryptionConnect:
        return EncryptionStatus.UNABLE_TO_CONNECT
      case !!domain?.encryptionConfirmed:
        return EncryptionStatus.VALIDATED
      default:
        return EncryptionStatus.NOT_VALIDATED
    }
  }, [domain])

  const pendingValidation = useMemo(() => {
    if (domain?.encryptionConfirmed !== '1' && domain?.encryptionType === DomainEncryptionType.CNAME) {
      return { label: EncryptionStatus.PENDING_CNAME, value: domain.encryptionVerification || '' }
    }
    if (domain?.encryptionConfirmed !== '1' && domain?.encryptionType === DomainEncryptionType.POST) {
      return { label: EncryptionStatus.PENDING_POST, value: domain.domainName }
    }
    return { label: '', value: '' }
  }, [domain])

  const onInputChange = useCallback(
    (name: AvailableEncryptionSettings) => (newValue: any) => {
      // eslint-disable-next-line default-case
      switch (name) {
        case AvailableEncryptionSettings.ALLOWREPLIES:
          allowreplies.setValue(newValue as SettingValue)
          break
        case AvailableEncryptionSettings.GET_READRECEIPTS:
          getReadreceipts.setValue(newValue as SettingValue)
          break
        case AvailableEncryptionSettings.FRIENDLYNAME:
          friendlyname.setValue(newValue as SettingValue)
          break
        case AvailableEncryptionSettings.NOTIFICATION_SUBJECT:
          notificationSubject.setValue(newValue)
          break
        case AvailableEncryptionSettings.HTML_NOTIFICATION:
          htmlNotification.setValue(newValue)
          break
        case AvailableEncryptionSettings.TXT_NOTIFICATION:
          txtNotification.setValue(newValue)
          break
      }
    },
    [allowreplies, getReadreceipts, friendlyname, notificationSubject, htmlNotification, txtNotification]
  )

  const onButtonClick = useCallback(
    (name: BUTTON_NAMES) => {
      if (domain) {
        switch (name) {
          case BUTTON_NAMES.CNAME:
            dispatch(cnameValidation({ domainId }))
            break
          case BUTTON_NAMES.POST:
            dispatch(postValidation({ domainId }))
            break
          case BUTTON_NAMES.CONFIRM: {
            const isCnameConfirmAllowed = domain.encryptionType === 'cname' && !!domain.encryptionVerification
            const isPostmasterConfirmAllowed = domain.encryptionType === 'post'
            const isConfirmAllowed = isCnameConfirmAllowed || isPostmasterConfirmAllowed
            if (!isConfirmAllowed) {
              dispatch(setErrorSnackBar({ message: 'encryption_validation_invalid' }))
              return
            }
            dispatch(confirmValidation({ domainId }))
            break
          }
          case BUTTON_NAMES.REMOVE_VALIDATION:
            dispatch(removeValidation({ domainId }))
            break
          case BUTTON_NAMES.REMOVE_LOGO:
            dispatch(removeLogo({ domainId }))
            break
          default:
        }
      }
    },
    [domain, dispatch, domainId]
  )

  const onAddFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }
      const file = e.target.files[0]
      const reader = makeFileReader()
      reader.onload = evt => {
        const result = evt.target?.result ?? null
        if (!(result instanceof ArrayBuffer)) {
          return
        }
        const base64 = window.btoa(new Uint8Array(result).reduce((data, byte) => data + String.fromCharCode(byte), ''))

        logoDataB64.setValue({
          filename: file.name,
          image: base64
        })
      }
      reader.readAsArrayBuffer(file)
    },
    [makeFileReader, logoDataB64]
  )

  return useMemo(
    () => [
      {
        validationStatus,
        pendingValidation,
        confirmedValidation: validationStatus === EncryptionStatus.VALIDATED && !!domain?.encryptionConnect,
        allowReplies: allowreplies.value || '',
        readReceipts: getReadreceipts.value || '',
        image: logoDataB64.value || {},
        displayName: friendlyname.value || '',
        notificationSubject: notificationSubject.value || '',
        htmlNotification: htmlNotification.value || '',
        textNotification: txtNotification.value || '',
        inProgress,
        actionPending
      },
      {
        onButtonClick,
        onInputChange,
        onAddFile
      }
    ],
    [
      domain,
      validationStatus,
      pendingValidation,
      allowreplies,
      getReadreceipts,
      logoDataB64,
      friendlyname,
      notificationSubject,
      htmlNotification,
      txtNotification,
      inProgress,
      actionPending,
      onButtonClick,
      onInputChange,
      onAddFile
    ]
  )
}
