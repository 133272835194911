import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction, ChangeEvent } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AVAILABLE_ATTACHMENT_FILTER_ACTIONS, AttachmentFilterAction, AvailableSettings } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'

export interface State {
  form: {
    outbound_password_archive_filtering: string
    outbound_password_msoffice_filtering: string
    outbound_password_pdf_filtering: string
  }
  actionOptions: string[]
  hasPageChanges: boolean
}

export interface EventHandlers {
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onSave: (name: SELECT_NAMES) => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsAttachmentTableDirty: Dispatch<SetStateAction<boolean>>
  setIsMessageTableDirty: Dispatch<SetStateAction<boolean>>
  setIsFilterTableDirty: Dispatch<SetStateAction<boolean>>
  setIsFilterExceptionsTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseContentPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.OUTBOUND_PASSWORD_ARCHIVE_FILTERING,
  AvailableSettings.OUTBOUND_PASSWORD_MSOFFICE_FILTERING,
  AvailableSettings.OUTBOUND_PASSWORD_PDF_FILTERING,
  AvailableSettings.OUTBOUND_ATTACHMENT_FILTER_POLICY,
  AvailableSettings.OUTBOUND_MESSAGE_CONTENT_FILTERS,
  AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS,
  AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS_EXCEPTIONS
]
export const enum SELECT_NAMES {
  ARCHIVE = 'outbound_password_archive_filtering',
  MSOFFICE = 'outbound_password_msoffice_filtering',
  PDF = 'outbound_password_pdf_filtering'
}

export const useContentPoliciesLogic = (): UseContentPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [form, setForm] = useState({
    [SELECT_NAMES.ARCHIVE]: AttachmentFilterAction.BLOCK,
    [SELECT_NAMES.MSOFFICE]: AttachmentFilterAction.IGNORE,
    [SELECT_NAMES.PDF]: AttachmentFilterAction.IGNORE
  })
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    accountSettings,
    domainSettings,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus)
  }))

  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([form])
  const [isAttachmentTableDirty, setIsAttachmentTableDirty] = useState<boolean>(false)
  const [isMessageTableDirty, setIsMessageTableDirty] = useState<boolean>(false)
  const [isFiterTableDirty, setIsFilterTableDirty] = useState<boolean>(false)
  const [isFiterExceptionsTableDirty, setIsFilterExceptionsTableDirty] = useState<boolean>(false)

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  useEffect(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    if (settings) {
      setForm({
        [SELECT_NAMES.ARCHIVE]: (settings.outbound_password_archive_filtering ||
          form.outbound_password_archive_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.MSOFFICE]: (settings.outbound_password_msoffice_filtering ||
          form.outbound_password_msoffice_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.PDF]: (settings.outbound_password_pdf_filtering ||
          form.outbound_password_pdf_filtering) as AttachmentFilterAction
      })
    }
    // eslint-disable-next-line
  }, [accessTokenObject, domainSettings, accountSettings])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const handleOnSelectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target
      setForm({ ...form, [name as SELECT_NAMES]: value })
    },
    [form]
  )

  const onSave = useCallback(
    (name: SELECT_NAMES) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [name]: form[name] }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [name]: form[name] } }))
      }
    },
    [dispatch, accessTokenObject, form]
  )

  const actionOptions = useMemo(() => AVAILABLE_ATTACHMENT_FILTER_ACTIONS, [])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    if (settings) {
      setForm({
        [SELECT_NAMES.ARCHIVE]: (settings.outbound_password_archive_filtering ||
          form.outbound_password_archive_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.MSOFFICE]: (settings.outbound_password_msoffice_filtering ||
          form.outbound_password_msoffice_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.PDF]: (settings.outbound_password_pdf_filtering ||
          form.outbound_password_pdf_filtering) as AttachmentFilterAction
      })
      setShouldUpdateInitialForm(true)
    }
    // eslint-disable-next-line
  }, [accessTokenObject, domainSettings, accountSettings])

  return useMemo(
    () => [
      {
        form,
        actionOptions,
        hasPageChanges:
          isDirtyForm ||
          isAttachmentTableDirty ||
          isMessageTableDirty ||
          isFiterTableDirty ||
          isFiterExceptionsTableDirty
      },
      {
        handleOnSelectChange,
        onSave,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsAttachmentTableDirty,
        setIsMessageTableDirty,
        setIsFilterTableDirty,
        setIsFilterExceptionsTableDirty
      }
    ],
    [
      form,
      actionOptions,
      isDirtyForm,
      isAttachmentTableDirty,
      isMessageTableDirty,
      isFiterTableDirty,
      isFiterExceptionsTableDirty,
      handleOnSelectChange,
      onSave,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
