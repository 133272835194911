import { useCallback, useMemo, useRef, useState } from 'react'

export interface FormField<T> {
  value: T
  setValue: (value: T, setChanged?: boolean) => void
  isChanged: boolean
  reset: (value: T) => void
}

export const useFormField = <T>(initialValue: T): FormField<T> => {
  const initializedWith = useRef(initialValue)
  const [fieldValue, setFieldValue] = useState(initialValue)
  const [isChanged, setIsChanged] = useState(false)

  const setValue = useCallback((value: T) => {
    setIsChanged(value !== initializedWith.current)
    setFieldValue(value)
  }, [])

  const reset = useCallback((value: T) => {
    setIsChanged(false)
    initializedWith.current = value
    setFieldValue(value)
  }, [])

  return useMemo(
    () => ({
      value: fieldValue,
      setValue,
      isChanged,
      reset
    }),
    [fieldValue, isChanged, setValue, reset]
  )
}
