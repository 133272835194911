import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { isEmailValid } from 'lib/validation'
import usePreviousValue from 'lib/usePreviousValue'
import { addUpdateAccountUsers, resetAddUpdateAccountUser } from 'redux/features/users/usersSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { AddUpdateAccountUserResult, UserUpdateResults } from 'types/users'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export enum FormValidationLevel {
  success,
  warning,
  error
}

export interface State {
  userAccounts: string
  isEnabledUserQuarantine: RadioButtonStates
  isNotifyNewUser: RadioButtonStates
  inputError?: string
  isAddUpdateInProgress: boolean
  addUpdateAccountUsersResults?: ValidatedResults
  isDirtyForm: boolean
}

export interface EventHandlers {
  setUserAccounts: Dispatch<SetStateAction<string>>
  setIsEnabledUserQuarantine: Dispatch<SetStateAction<RadioButtonStates>>
  setIsNotifyNewUsers: Dispatch<SetStateAction<RadioButtonStates>>
  onSaveChanges: () => void
}

export enum UserUpdateResultLevel {
  added = 'result_green',
  updated = 'result_blue',
  error = 'result_red'
}

export const userUpdateResultLevels: { [key: string]: UserUpdateResultLevel } = {
  [UserUpdateResults.successfullyAdded]: UserUpdateResultLevel.added,
  [UserUpdateResults.successfullyUpdated]: UserUpdateResultLevel.updated,
  [UserUpdateResults.failedToAddUser]: UserUpdateResultLevel.error,
  [UserUpdateResults.failedToUpdateUser]: UserUpdateResultLevel.error,
  [UserUpdateResults.invalidEmail]: UserUpdateResultLevel.error,
  [UserUpdateResults.invalidDomain]: UserUpdateResultLevel.error,
  [UserUpdateResults.missedDomainRight]: UserUpdateResultLevel.error,
  [UserUpdateResults.alreadyExistsAsLinkedAccount]: UserUpdateResultLevel.error
}

export type ValidatedResult = [
  UserName: string,
  Domain: string,
  Result: UserUpdateResults,
  Level: UserUpdateResultLevel
]

export interface ValidatedResults {
  results: ValidatedResult[]
  addedCount: number
  updatedCount: number
  failedCount: number
  isEnabledUserQuarantine: boolean
  isNotifiedUsers: boolean
}

export type UseAddUpdateUsersLogic = [State, EventHandlers]
export const useAddUpdateUserLogic = (): UseAddUpdateUsersLogic => {
  const dispatch = useAppDispatch()
  const { isAddUpdateUsersInProgress, isAddUpdateUsersSuccess, isAddUpdateUsersFailed, addUpdateAccountUsersResults } =
    useAppSelector(_store => ({
      isAddUpdateUsersInProgress: isPending(_store.users.api.addUpdateAccountUsersApiStatus),
      isAddUpdateUsersSuccess: isSuccess(_store.users.api.addUpdateAccountUsersApiStatus),
      isAddUpdateUsersFailed: isFailed(_store.users.api.addUpdateAccountUsersApiStatus),
      addUpdateAccountUsersResults: _store.users.addUpdateAccountUsersResults
    }))

  const [userAccounts, setUserAccounts] = useState<string>('')
  const [isEnabledUserQuarantine, setIsEnabledUserQuarantine] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [isNotifyNewUser, setIsNotifyNewUsers] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [inputError, setInputError] = useState<string | undefined>()
  const previousUserAccounts = usePreviousValue(userAccounts)
  const validatedUserIds = useMemo(() => userAccounts.split('\n').filter(email => isEmailValid(email)), [userAccounts])
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([userAccounts, isEnabledUserQuarantine, isNotifyNewUser])

  // init
  useEffect(() => {
    trackEventInAllServices(TRACKING_EVENTS.ADMIN.ADD_UPDATE_USER_PAGE_VIEW)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetAddUpdateAccountUser())
    }
  }, [dispatch])

  // Remove TextField error
  useEffect(() => {
    if (inputError && userAccounts !== previousUserAccounts) {
      setInputError(undefined)
    }
  }, [inputError, userAccounts, previousUserAccounts])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  useEffect(() => {
    if (isAddUpdateUsersSuccess) {
      setShouldUpdateInitialForm(true)
    }
  }, [isAddUpdateUsersSuccess])

  // Add/Update is Failed
  useEffect(() => {
    if (isAddUpdateUsersFailed) {
      dispatch(setErrorSnackBar({ message: 'add_update_account_users_failed' }))
    }
  }, [dispatch, isAddUpdateUsersFailed])

  const onSaveChanges = useCallback(() => {
    // 1 - validate user emails
    if (!validatedUserIds.length) {
      setInputError('user_email_error')
    } else {
      dispatch(
        addUpdateAccountUsers({
          userIds: validatedUserIds,
          enableQuarantine: isEnabledUserQuarantine === RadioButtonStates.on,
          notifyUsers: isNotifyNewUser === RadioButtonStates.on
        })
      )
    }
  }, [dispatch, isEnabledUserQuarantine, isNotifyNewUser, validatedUserIds])

  const validatedResults: ValidatedResults | undefined = useMemo(() => {
    if (!addUpdateAccountUsersResults) {
      return undefined
    }

    const results = addUpdateAccountUsersResults.reduce(
      (all: ValidatedResults, result: AddUpdateAccountUserResult) => {
        const [user_id, resultString] = result
        const domain = user_id.split('@')?.[1] || ''
        const level = userUpdateResultLevels[resultString]
        const isSuccessfullyAdded = resultString === UserUpdateResults.successfullyAdded
        const isSuccessFullyUpdated = resultString === UserUpdateResults.successfullyUpdated

        return {
          ...all,
          results: [...all.results, [user_id, domain, resultString, level]],
          ...(isSuccessfullyAdded && { addedCount: all.addedCount + 1 }),
          ...(isSuccessFullyUpdated && { updatedCount: all.updatedCount + 1 }),
          ...(!isSuccessfullyAdded && !isSuccessFullyUpdated && { failedCount: all.failedCount + 1 })
        } as ValidatedResults
      },
      {
        results: [],
        addedCount: 0,
        updatedCount: 0,
        failedCount: 0,
        isEnabledUserQuarantine: isEnabledUserQuarantine === RadioButtonStates.on,
        isNotifiedUsers: isNotifyNewUser === RadioButtonStates.on
      }
    )

    results.results = results.results.reverse()
    return results
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateAccountUsersResults])

  return useMemo(
    () => [
      {
        userAccounts,
        isEnabledUserQuarantine,
        isNotifyNewUser,
        inputError,
        isAddUpdateInProgress: isAddUpdateUsersInProgress,
        addUpdateAccountUsersResults: validatedResults,
        isDirtyForm
      },
      {
        setUserAccounts,
        setIsEnabledUserQuarantine,
        setIsNotifyNewUsers,
        onSaveChanges
      }
    ],
    [
      isEnabledUserQuarantine,
      userAccounts,
      isNotifyNewUser,
      inputError,
      isAddUpdateUsersInProgress,
      validatedResults,
      setUserAccounts,
      setIsEnabledUserQuarantine,
      setIsNotifyNewUsers,
      onSaveChanges,
      isDirtyForm
    ]
  )
}
