import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataTableState, UpdateDataTable } from 'types/redux/dataTables/dataTables'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import reducerHandlers from '../reduceHandlers'

export const GRID_COLUMNS = {
  CHECKBOX: 'checkbox',
  ACTION: 'action',
  DELIVERY: 'delivery',
  FROM: 'from',
  TO: 'to',
  SUBJECT: 'subject',
  DATE: 'date',
  SIZE: 'size',
  ENCRYPTION: 'encryption',
  ATTACHMENT: 'attachment',
  REASON: 'reason',
  SCORE: 'score'
}

export const SORT_FIELDS = {
  [GRID_COLUMNS.DATE]: 'date'
}

const initColumnWidths: { [key: string]: any } = {
  checkbox: 36,
  action: 105,
  delivery: 78,
  from: 300,
  to: 200,
  subject: 300,
  date: 150,
  size: 100,
  encryption: 50,
  attachment: 50,
  reason: 150,
  score: 80
}

const widths = localStorage.getItem(LocalStorageKeys.columnWidths)
const initWidths = widths ? JSON.parse(widths) : initColumnWidths

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.CHECKBOX]: {
    sortable: false,
    orderIndex: 0,
    show: true,
    width: initWidths.checkbox
  },
  [GRID_COLUMNS.ACTION]: {
    sortable: false,
    orderIndex: 1,
    show: true,
    resizable: true,
    width: initWidths.action
  },
  [GRID_COLUMNS.DELIVERY]: {
    sortable: false,
    orderIndex: 2,
    show: true,
    resizable: true,
    width: initWidths.delivery
  },
  [GRID_COLUMNS.FROM]: {
    sortable: false,
    orderIndex: 3,
    show: true,
    width: initWidths.from
  },
  [GRID_COLUMNS.TO]: {
    sortable: false,
    orderIndex: 4,
    show: true,
    resizable: true,
    width: initWidths.to
  },
  [GRID_COLUMNS.SUBJECT]: {
    sortable: false,
    orderIndex: 5,
    show: true,
    resizable: true,
    width: initWidths.subject,
    minResizableWidth: 300
  },
  [GRID_COLUMNS.DATE]: {
    sortable: false,
    orderIndex: 6,
    show: true,
    resizable: true,
    width: initWidths.date
  },
  [GRID_COLUMNS.SIZE]: {
    sortable: false,
    orderIndex: 7,
    show: true,
    resizable: true,
    width: initWidths.size
  },
  [GRID_COLUMNS.ENCRYPTION]: {
    sortable: false,
    orderIndex: 8,
    show: true,
    width: initWidths.encryption
  },
  [GRID_COLUMNS.ATTACHMENT]: {
    sortable: false,
    orderIndex: 9,
    show: true,
    width: initWidths.attachment
  },
  [GRID_COLUMNS.REASON]: {
    sortable: false,
    orderIndex: 10,
    show: true,
    resizable: true,
    width: initWidths.reason
  },
  [GRID_COLUMNS.SCORE]: {
    sortable: false,
    orderIndex: 11,
    show: true,
    resizable: true,
    width: initWidths.score
  }
}

export const ITEMS_PER_PAGE = 100

// initialState
export const INITIAL_STATE: DataTableState = {
  GRID_COLUMNS,
  SORT_FIELDS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.DATE, dir: 'desc' }]
}

/* eslint-disable no-param-reassign */
export const messagesSlice = createSlice({
  name: 'DATATABLE/MESSAGES',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) =>
        reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload }),
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  }
})
/* eslint-enable no-param-reassign */

export const { update, reset } = messagesSlice.actions

export default messagesSlice.reducer
