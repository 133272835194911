import React, { useCallback, useMemo, useState } from 'react'
import { Chip, Grid, Menu, MenuItem, Pagination as BDSPagination, Typography } from '@barracuda-internal/bds-core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import useStyles from './paginationStyles'

export const DEFAULT_ITEMS_PER_PAGE = [15, 25, 50, 100]

export interface PaginationProps {
  /**
   * Callback fired when the component requests to change the number of items per page.
   */
  onClickItemsPerPage?: (event: React.MouseEvent<HTMLElement>) => void
  /**
   * Callback fired when the component requests to close the menu.
   */
  onClose?: ((event: any, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined
  /**
   * Callback fired when the component requests to change the number of items per page.
   */
  onMenuItemClick?: (itemsPerPageValue: number) => void
  /**
   * Callback fired when the component requests to change the page.
   */
  onPageChange?: (event: React.ChangeEvent<unknown>, value: number) => void
  /**
   * The page number (used for server side pagination).
   */
  page?: number
  /**
   * The number of items per page.
   */
  itemsPerPage?: number
  /**
   * The number of items per page options.
   */
  itemsPerPageOptions?: number[]
  /**
   * The total number of items.
   */
  totalItems: number
}

// TODO: Move state out of component
export function Pagination({
  onClickItemsPerPage,
  onClose,
  onMenuItemClick,
  onPageChange,
  page = 1,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE[0],
  itemsPerPageOptions = DEFAULT_ITEMS_PER_PAGE,
  totalItems
}: PaginationProps): JSX.Element {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState<number>(itemsPerPage)
  const [currentPage, setCurrentPage] = useState<number>(page)

  const numberOfPages = useMemo(() => Math.ceil(totalItems / currentItemsPerPage), [totalItems, currentItemsPerPage])

  const handleClickItemsPerPage = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (typeof onClickItemsPerPage === 'function') onClickItemsPerPage(event)
      setAnchorEl(event.currentTarget)
    },
    [onClickItemsPerPage]
  )

  const handleMenuItemClick = useCallback(
    (itemsPerPageValue: number) => {
      if (typeof onMenuItemClick === 'function') onMenuItemClick(itemsPerPageValue)
      setCurrentItemsPerPage(itemsPerPageValue)
      setCurrentPage(1)
      setAnchorEl(null)
    },
    [onMenuItemClick]
  )

  const handleClose = useCallback(
    (event: any, reason: any) => {
      if (typeof onClose === 'function') onClose(event, reason)
      setAnchorEl(null)
    },
    [onClose]
  )

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      event.preventDefault()
      if (typeof onPageChange === 'function') onPageChange(event, value)
      setCurrentPage(value)
    },
    [onPageChange]
  )

  const itemsChangePagination = useMemo(() => {
    let itemsPerPageText
    if (currentPage === numberOfPages) {
      itemsPerPageText = `${currentItemsPerPage * (currentPage - 1) + 1} - ${totalItems}`
    } else {
      itemsPerPageText = `${currentItemsPerPage * (currentPage - 1) + 1} - ${currentItemsPerPage * currentPage}`
    }

    return `${itemsPerPageText} of ${totalItems} items`
  }, [currentPage, currentItemsPerPage, totalItems, numberOfPages])

  return (
    <Grid container className={classes.paginationContainer}>
      <Grid item className={classes.paginationActions}>
        <BDSPagination
          count={numberOfPages}
          showFirstButton
          showLastButton
          onChange={handlePageChange}
          page={page}
          siblingCount={2}
        />
        <Chip
          className={classes.paginationChip}
          size="small"
          label={currentItemsPerPage}
          clickable
          onClick={handleClickItemsPerPage}
          onDelete={handleClickItemsPerPage}
          deleteIcon={<ArrowDropDownIcon className={classes.dropDownArrowIcon} />}
        />
        <Menu id="page-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {itemsPerPageOptions.map(itemsPerPageOption => (
            <MenuItem
              key={itemsPerPageOption}
              selected={itemsPerPageOption === currentItemsPerPage}
              onClick={() => handleMenuItemClick(itemsPerPageOption)}
            >
              {itemsPerPageOption}
            </MenuItem>
          ))}
        </Menu>
        <Typography variant="body2" className={classes.paginationText}>
          items per page
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.paginationText}>
          {itemsChangePagination}
        </Typography>
      </Grid>
    </Grid>
  )
}
