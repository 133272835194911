//
// This file provides variables available in TDF logic in the following files:
// - src/app/views/log/read_base.php
// - src/app/controllers/log.php
//
import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import appConfig from 'config/appConfig'
import { isLargeMessage as isLargeMessageFn } from 'lib/email'

export const useMessageVariables = () => {
  const {
    message,
    bccAccountId,
    forensicsFlag,
    canExempt,
    canBlock,
    canDeliver,
    canDeliverQuarantine,
    emergencyInbox,
    hasInferenceAccess
  } = useAppSelector(_store => ({
    message: _store.mstore.message,
    bccAccountId: _store.auth.accessTokenObject?.bccAccountId || '',
    forensicsFlag: !!_store.settings.accountPermissions?.hasForensicsAccess,
    canExempt: !!_store.settings.accountPermissions?.defaultUserExemptEnabled,
    canBlock: !!_store.settings.accountPermissions?.defaultUserBlockEnabled,
    canDeliver: !!_store.settings.accountPermissions?.defaultUserDeliverEnabled,
    canDeliverQuarantine: !!_store.settings.accountPermissions?.defaultUserQuarDeliverEnabled,
    emergencyInbox: !!_store.settings.accountPermissions?.emergencyInboxEnabled,
    hasInferenceAccess: !!_store.settings.accountPermissions?.hasInferenceAccess
  }))

  const headersString = useMemo(() => {
    if (!message) {
      return ''
    }
    return Object.entries(message.headers).reduce((acc, [k, v]) => `${acc}${k}: ${v}\n`, '')
  }, [message])
  const forensicsLink = useMemo(() => {
    if (!forensicsFlag || !message || !bccAccountId) {
      return undefined
    }
    const searchParams = new URLSearchParams({
      email: message.fields.headerFrom || message.fields.envelopeFrom,
      subject: message.fields.subject,
      bccId: bccAccountId
    })
    return `https://${appConfig.LINKS.FORENSICS_HOSTNAME}/new-incident?${searchParams.toString()}`
  }, [bccAccountId, forensicsFlag, message])
  const encrypted = useMemo(() => !!message?.attributes.includes('encrypted'), [message])
  const blocked = useMemo(() => !!message?.attributes.includes('blocked'), [message])
  const quarantined = useMemo(() => !!message?.attributes.includes('quarantined'), [message])
  const atdMessage = useMemo(() => !!message?.attributes.includes('atd_message'), [message])
  const atdPending = useMemo(() => !!message?.attributes.includes('atd_pending'), [message])
  const atdQuarantine = useMemo(() => !!message?.attributes.includes('atd_quarantine'), [message])
  const avError = useMemo(() => !!message?.attributes.includes('service_error_av'), [message])
  const hasVirus = useMemo(() => message && Array.isArray(message.virus) && !!message.virus.length, [message])
  const userBlocked = blocked && !canDeliver
  const userQuarantineBlocked = quarantined && !canDeliverQuarantine
  const firstRecipient = useMemo(() => message?.fields?.recipientInfo?.[0], [message])
  const showMsgBody = useMemo(() => !!message?.allowMsgBody, [message])
  const headersOnly = useMemo(() => !!message?.headersOnly, [message])
  const isAtpAffected = useMemo(
    () =>
      message?.fields?.recipientInfo?.some(recipient =>
        ['atd', 'atd_subscribed', 'atd_full', 'atd_quarantine']?.some(reason => reason === recipient.reason)
      ) || false,
    [message]
  )
  const isLargeMessage = useMemo(() => message && isLargeMessageFn(message.fields.size), [message])
  const hasContent = useMemo(() => !!message?.body && !!Number(message.fields.size), [message])
  const hasImages = useMemo(() => message?.hasImages, [message])
  const isBarracudaResponsible = useMemo(() => !!message?.barracudaResponsible, [message])

  const showMarkSpam = useMemo(() => !!message?.showMarkSpam, [message])

  const showMarkHam = useMemo(() => !!message?.showMarkHam, [message])

  return useMemo(
    () => ({
      canExempt,
      canBlock,
      canDeliver,
      canDeliverQuarantine,
      encrypted,
      blocked,
      quarantined,
      atdMessage,
      atdPending,
      atdQuarantine,
      avError,
      hasVirus,
      userBlocked,
      userQuarantineBlocked,
      firstRecipient,
      headersString,
      forensicsLink,
      showMsgBody,
      headersOnly,
      emergencyInbox,
      isAtpAffected,
      isLargeMessage,
      hasContent,
      hasImages,
      hasInferenceAccess,
      isBarracudaResponsible,
      showMarkSpam,
      showMarkHam
    }),
    [
      canExempt,
      canBlock,
      canDeliver,
      canDeliverQuarantine,
      encrypted,
      blocked,
      quarantined,
      atdMessage,
      atdPending,
      atdQuarantine,
      avError,
      hasVirus,
      userBlocked,
      userQuarantineBlocked,
      firstRecipient,
      headersString,
      forensicsLink,
      showMsgBody,
      headersOnly,
      emergencyInbox,
      isAtpAffected,
      isLargeMessage,
      hasContent,
      hasImages,
      hasInferenceAccess,
      isBarracudaResponsible,
      showMarkSpam,
      showMarkHam
    ]
  )
}
