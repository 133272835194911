import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { InboundScheduleType, InboundScheduleUI } from 'types/Settings'
import {
  getAccountPermissions,
  getQuarantineNotification,
  putQuarantineNotification,
  updateSchedule
} from 'redux/features/settings/settingsSlice'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import { getErrorMessage, isFailed, isSuccess } from 'redux/toolkit/api'
import { ScheduleMatrixProps } from 'components/libs/scheduler/schedulerMatrixTypes'

export interface UseQuarantineNotificationLogic {
  isDisabledAdd: boolean
  isConfirmDialogOpened: boolean
  selectedScheduleType: InboundScheduleType
  onScheduleTypeChange: (e: ChangeEvent<HTMLInputElement>) => void
  onResetSchedule: () => void
  OnSaveQuarantineNotification: () => void
  onResetYesNoDialog: () => void
  onCloseConfirmDialog: () => void
  isGetQuarantineNotificationCompleted: boolean
  getQuarantineNotificationError: string | undefined
  scheduleMatrixConfig: ScheduleMatrixProps
}

export const useQuarantineNotificationLogic = (): UseQuarantineNotificationLogic => {
  const dispatch = useAppDispatch()
  const {
    quarantineNotification,
    accountPermissions,
    customInboundSchedule,
    isGetQuarantineNotificationSuccess,
    isGetQuarantineNotificationFailed,
    getQuarantineNotificationError
  } = useAppSelector(_store => ({
    quarantineNotification: _store.settings.quarantineNotification,
    accountPermissions: _store.settings.accountPermissions,
    customInboundSchedule: _store.settings.updatedSchedule,
    isGetQuarantineNotificationSuccess: isSuccess(_store.settings.getQuarantineNotificationApiStatus),
    isGetQuarantineNotificationFailed: isFailed(_store.settings.getQuarantineNotificationApiStatus),
    getQuarantineNotificationError: getErrorMessage(_store.settings.getQuarantineNotificationApiStatus)
  }))
  const isGetQuarantineNotificationCompleted = useMemo(
    () => isGetQuarantineNotificationSuccess || isGetQuarantineNotificationFailed,
    [isGetQuarantineNotificationSuccess, isGetQuarantineNotificationFailed]
  )
  const [isDisabledAdd, setDisableAdd] = useState<boolean>(false)
  const [selectedScheduleType, setSelectedScheduleType] = useState<InboundScheduleType>(InboundScheduleType.never)
  const [isConfirmDialogOpened, setIsConfirmDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getQuarantineNotification())
    dispatch(getAccountPermissions())
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SETTINGS_QUARANTINE_NOTIFICATION_PAGE_VIEW)
  }, [dispatch])

  useEffect(() => {
    if (quarantineNotification && accountPermissions) {
      setSelectedScheduleType(quarantineNotification.inboundSchedule)
      if (accountPermissions.allowQuarantineNotificationIntervalChange === 0) {
        setDisableAdd(true)
      }
    }
  }, [accountPermissions, quarantineNotification])

  const onScheduleTypeChange: UseQuarantineNotificationLogic['onScheduleTypeChange'] = useCallback(e => {
    const { value } = e.target

    setSelectedScheduleType(value as InboundScheduleType)
  }, [])

  const onResetSchedule = useCallback(() => {
    setIsConfirmDialogOpen(true)
  }, [])

  const OnSaveQuarantineNotification = useCallback(() => {
    if (customInboundSchedule) {
      dispatch(
        putQuarantineNotification({
          inboundSchedule: selectedScheduleType,
          customInboundSchedule
        })
      )
    }
  }, [dispatch, customInboundSchedule, selectedScheduleType])

  const onResetYesNoDialog = useCallback(() => {
    dispatch(getQuarantineNotification())
  }, [dispatch])

  const onScheduleMatrixChange = useCallback(
    (schedule: InboundScheduleUI) => {
      dispatch(updateSchedule(schedule))
    },
    [dispatch]
  )

  const onCloseConfirmDialog = useCallback(() => setIsConfirmDialogOpen(false), [])

  return useMemo(
    () => ({
      isDisabledAdd,
      isConfirmDialogOpened,
      selectedScheduleType,
      isGetQuarantineNotificationCompleted,
      getQuarantineNotificationError,
      onScheduleTypeChange,
      onResetSchedule,
      OnSaveQuarantineNotification,
      onResetYesNoDialog,
      onCloseConfirmDialog,
      scheduleMatrixConfig: {
        isEnabled: accountPermissions?.allowQuarantineNotificationIntervalChange === 1,
        customInboundSchedule: quarantineNotification?.customInboundSchedule,
        doUpdate: onScheduleMatrixChange
      }
    }),
    [
      isDisabledAdd,
      isConfirmDialogOpened,
      selectedScheduleType,
      isGetQuarantineNotificationCompleted,
      getQuarantineNotificationError,
      onScheduleTypeChange,
      onResetSchedule,
      OnSaveQuarantineNotification,
      onResetYesNoDialog,
      onCloseConfirmDialog,
      onScheduleMatrixChange,
      accountPermissions,
      quarantineNotification
    ]
  )
}
