import { useParams, useSearchParams } from 'react-router-dom'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import routesConfig from 'lib/routesConfig'
import { DomainStatus, VerificationMethod } from 'types/domains'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { getDomainForVerification, verifyDomain } from 'redux/features/domains/domainsSlice'
import { resetVerifyDomain } from 'redux/features/domains/domainsActions'

export enum UiState {
  FORM,
  VERIFICATION_IN_PROGRESS,
  ALREADY_VERIFIED
}

export interface State {
  domainId: string | undefined
  domainName: string
  help: {
    isHelpOpen: boolean
  }
  inProgress: boolean
  error: string
  selectedVerificationMethod: VerificationMethod
  expectedMxRecords: string[]
  expectedCnameRecord: string
  expectedPostmasterEmail: string
  expectedTechEmail: string
  isLoading: boolean
  uiState: UiState
  inProgressLabel: string
  alreadyVerifiedLabel: string
}

export interface EventHandlers {
  onChangeVerificationMethod: (event: ChangeEvent<HTMLInputElement>, value: string) => void
  help: {
    onHelpOpen: () => void
    onHelpClose: () => void
  }
  onVerify: () => void
  onGoBack: () => void
}

export type VerifyDomainLogic = [State, EventHandlers]

export const useVerifyDomainLogic = (): VerifyDomainLogic => {
  const dispatch = useAppDispatch()
  const { domainId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const token = searchParams.get('token')
  const {
    isGetDomainPending,
    getDomainError,
    domain,
    domainName,
    isVerifyDomainPending,
    isVerifyDomainSuccess,
    isVerifyDomainFailed,
    isCplAccount
  } = useAppSelector(_store => ({
    isGetDomainPending: isPending(_store.domains.api.getDomainForVerificationApiStatus),
    getDomainError: getErrorMessage(_store.domains.api.getDomainForVerificationApiStatus) || '',
    domain: _store.domains.domain,
    domainName: _store.domains.domain?.domainName || '',
    isVerifyDomainPending: isPending(_store.domains.api.verifyDomainApiStatus),
    isVerifyDomainSuccess: isSuccess(_store.domains.api.verifyDomainApiStatus),
    isVerifyDomainFailed: isFailed(_store.domains.api.verifyDomainApiStatus),
    isCplAccount: !!_store.auth.accessTokenObject?.isCplAccount
  }))
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const defaultVerificationMethod = useMemo(() => {
    switch (true) {
      case isCplAccount:
        return VerificationMethod.CPL_PROBE
      case !!token:
        return VerificationMethod.TOKEN_CHECK
      default:
        return VerificationMethod.MX_RECORDS
    }
  }, [isCplAccount, token])
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState(defaultVerificationMethod)

  const uiState = useMemo(() => {
    switch (true) {
      case domain?.status === DomainStatus.VERIFIED || !!domain?.verifiedByOtherAccount:
        return UiState.ALREADY_VERIFIED
      case selectedVerificationMethod === VerificationMethod.CPL_PROBE ||
        selectedVerificationMethod === VerificationMethod.TOKEN_CHECK:
        return UiState.VERIFICATION_IN_PROGRESS
      default:
        return UiState.FORM
    }
  }, [domain, selectedVerificationMethod])

  const onHelpOpen = useCallback(() => setIsHelpOpen(true), [])

  const onHelpClose = useCallback(() => setIsHelpOpen(false), [])

  const onChangeVerificationMethod = useCallback((event: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedVerificationMethod(value as VerificationMethod)
  }, [])

  const onVerify = useCallback(() => {
    if (domainId) {
      dispatch(verifyDomain({ domainId, method: selectedVerificationMethod }))
    }
  }, [dispatch, domainId, selectedVerificationMethod])

  const onGoBack = useCallback(() => {
    routesConfig.DOMAINS.goto()
  }, [])

  const expectedMxRecords = useMemo(
    () => (domain ? [domain.primaryMxRecord?.domain || '', domain.backupMxRecord?.domain || ''] : []),
    [domain]
  )

  const expectedCnameRecord = useMemo(
    () => (domain ? `barracuda${domain.cnameToken}.${domain.domainName}` : ''),
    [domain]
  )

  const expectedPostmasterEmail = useMemo(() => (domain ? `postmaster@${domain.domainName}` : ''), [domain])

  const expectedTechEmail = useMemo(() => domain?.techEmail || '', [domain])

  const inProgressLabel = useMemo(() => {
    switch (true) {
      case selectedVerificationMethod === VerificationMethod.TOKEN_CHECK:
        return 'verify_domain.token_verification'
      case selectedVerificationMethod === VerificationMethod.CPL_PROBE:
        return 'verify_domain.cpl_probe'
      default:
        return ''
    }
  }, [selectedVerificationMethod])

  const alreadyVerifiedLabel = useMemo(() => {
    switch (true) {
      case domain?.status === DomainStatus.VERIFIED:
        return 'verify_domain.already_verified'
      case !!domain?.verifiedByOtherAccount:
        return 'verify_domain.already_verified_by_other_account'
      default:
        return ''
    }
  }, [domain])

  useEffect(() => {
    if (!domainId) {
      routesConfig.DOMAINS.goto()
      return undefined
    }
    if (token) {
      dispatch(verifyDomain({ domainId, method: VerificationMethod.TOKEN_CHECK, token }))
    }
    if (isCplAccount) {
      dispatch(verifyDomain({ domainId, method: VerificationMethod.CPL_PROBE }))
    }
    dispatch(getDomainForVerification({ domainId }))
    return () => {
      dispatch(resetVerifyDomain())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isVerifyDomainSuccess) {
      routesConfig.DOMAINS.goto()
    }
  }, [isVerifyDomainSuccess])

  useEffect(() => {
    if (isVerifyDomainFailed && selectedVerificationMethod === VerificationMethod.TOKEN_CHECK && token) {
      setSelectedVerificationMethod(VerificationMethod.MX_RECORDS)
      setSearchParams(new URLSearchParams())
    }
  }, [token, selectedVerificationMethod, isVerifyDomainFailed, setSearchParams])

  return useMemo(
    () => [
      {
        domainId,
        domainName,
        help: { isHelpOpen },
        inProgress: isVerifyDomainPending,
        error: getDomainError,
        selectedVerificationMethod,
        expectedMxRecords,
        expectedCnameRecord,
        expectedPostmasterEmail,
        expectedTechEmail,
        isLoading: isGetDomainPending,
        uiState,
        inProgressLabel,
        alreadyVerifiedLabel
      },
      { onChangeVerificationMethod, help: { onHelpOpen, onHelpClose }, onVerify, onGoBack }
    ],
    [
      domainId,
      domainName,
      isHelpOpen,
      isVerifyDomainPending,
      getDomainError,
      selectedVerificationMethod,
      expectedMxRecords,
      expectedCnameRecord,
      expectedPostmasterEmail,
      expectedTechEmail,
      isGetDomainPending,
      uiState,
      inProgressLabel,
      alreadyVerifiedLabel,
      onChangeVerificationMethod,
      onHelpOpen,
      onHelpClose,
      onVerify,
      onGoBack
    ]
  )
}
