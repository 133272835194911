import appConfig, { AppNames } from 'config/appConfig'

const { IS_DEV, IS_GT3, IS_PRODUCTION, IS_TEST, IS_STAGING } = appConfig.ENVIRONMENT

export type FeatureConfig = [AppNames[], boolean[]]

export enum AppFeature {
  // Wizard
  EnabledWizardBccLogin = 'EnabledWizardBccLogin',
  DisabledWizardAdminCheck = 'DisabledWizardAdminCheck',
  DisabledWizardRegionSelectedCheck = 'DisabledWizardRegionSelectedCheck',
  EnabledRegionChange = 'EnabledRegionChange',
  UseReactAppWhenFinishedWizard = 'UseReactAppWhenFinishedWizard',

  // Admin
  EnabledLicenseCompliance = 'EnabledLicenseCompliance',

  // Enduser
  DisabledLanguageSelector = 'DisabledLanguageSelector',
  EnabledTemporaryPasscode = 'EnabledTemporaryPasscode',
  EnabledForAllUserRoles = 'EnabledForAllUserRoles',
  Enable_message_auth_via_mstore_search = 'Enable_message_auth_via_mstore_search'
}

export const APP_FEATURES: { [key in AppFeature]: FeatureConfig } = {
  // Wizard
  [AppFeature.UseReactAppWhenFinishedWizard]: [[AppNames.wizard], [IS_STAGING, IS_DEV]],
  [AppFeature.EnabledWizardBccLogin]: [[AppNames.wizard], [IS_STAGING, IS_PRODUCTION]],
  [AppFeature.DisabledWizardAdminCheck]: [[AppNames.wizard], [IS_DEV, IS_GT3]],
  [AppFeature.DisabledWizardRegionSelectedCheck]: [[AppNames.wizard], []],
  [AppFeature.EnabledRegionChange]: [
    [AppNames.wizard, AppNames.enduser],
    [IS_PRODUCTION, IS_TEST]
  ],

  // Admin
  [AppFeature.EnabledLicenseCompliance]: [[AppNames.admin], [IS_DEV, IS_STAGING, IS_GT3]],

  // Enduser
  [AppFeature.DisabledLanguageSelector]: [[AppNames.enduser], [IS_PRODUCTION]],
  [AppFeature.EnabledTemporaryPasscode]: [[AppNames.enduser], [IS_DEV, IS_STAGING, IS_GT3]],
  [AppFeature.EnabledForAllUserRoles]: [[AppNames.enduser], []],
  // TODO: will turn ON when the app slowness issue is solved
  [AppFeature.Enable_message_auth_via_mstore_search]: [[AppNames.enduser], []]
}

export function isFeatureEnabled(featureName: AppFeature): boolean {
  const [appName, enabledStates] = APP_FEATURES[featureName]
  const isValidApp = appName.includes(appConfig.APP.NAME) || IS_TEST
  const isEnabled = enabledStates.some(state => state)

  return isValidApp && isEnabled
}

export default (Object.keys(AppFeature) as Array<AppFeature>).reduce(
  (all, appFeature) => ({ ...all, [appFeature]: isFeatureEnabled(appFeature) }),
  {}
) as { [key in AppFeature]: boolean }
