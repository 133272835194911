import React, { useMemo } from 'react'
import { Grid, Typography } from '@barracuda-internal/bds-core'

import { GridSize } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import styles from 'components/libs/settings/settingsInputStyles'

interface SettingsRowHelperProps {
  variant?: 'danger' | 'info'
  text: string
  className?: string
  labelColumnSize?: number
  textVariant?: Variant
}

const SettingsRowHelper = ({ variant, text, className, labelColumnSize, textVariant }: SettingsRowHelperProps) => {
  const classes = styles()

  const labelSize = labelColumnSize || 3
  const contentSize = 12 - labelSize

  return useMemo(
    () => (
      <Grid container direction="row" className={`${classes.helperRow} ${className}`}>
        <Grid item xs={labelSize as GridSize}>
          <Typography variant="body2" data-testid="label:blank" />
        </Grid>
        <Grid item xs={contentSize as GridSize}>
          <Typography
            variant={textVariant || 'body2'}
            className={variant ? classes[variant] : ''}
            data-testid={`helper:${text}`}
          >
            {text}
          </Typography>
        </Grid>
      </Grid>
    ),
    [classes, variant, text, className, contentSize, labelSize, textVariant]
  )
}

export default SettingsRowHelper
