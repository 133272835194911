import { useEffect, useMemo } from 'react'

import config from 'config/appConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed } from 'redux/toolkit/api'
import { getDomainInfo } from 'redux/features/auth/authSlice'
import { getCountryFromCode2 } from 'lib/isoCountries'

export interface State {
  region?: string
  isDomainInfoError: boolean
}

export type LoginLogic = [State]

export const qNKey = `${config.LEGACY_UI_URL}/user/settings/quarantine_notification`

export const useLoginLogic = (): LoginLogic => {
  const dispatch = useAppDispatch()
  const { isDomainInfoError, domainInfo } = useAppSelector(_store => ({
    isDomainInfoError: isFailed(_store.auth.domainInfoApiStatus),
    domainInfo: _store.auth.domainInfo
  }))

  // init
  useEffect(() => {
    dispatch(getDomainInfo())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const region = useMemo(() => {
    if (!domainInfo) {
      return undefined
    }

    return getCountryFromCode2(domainInfo.region)?.name
  }, [domainInfo])

  return useMemo(
    () => [
      {
        region,
        isDomainInfoError
      }
    ],
    [region, isDomainInfoError]
  )
}
