import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import Loading from 'components/libs/loading/Loading'
import { getSSOSetings } from 'redux/features/auth/authSlice'
import { isFailed } from 'redux/toolkit/api'

import routesConfig from 'lib/routesConfig'
import { useAuth0Login } from 'lib/hooks/useAuth0Login'

function LoginSSO() {
  const dispatch = useAppDispatch()
  const { auth0Login } = useAuth0Login()

  const { ssoSettings, isGetSSOSettingsFailed } = useAppSelector(_store => ({
    ssoSettings: _store.auth.ssoSettings,
    isGetSSOSettingsFailed: isFailed(_store.auth.api.getLoginSettingsApiStatus)
  }))

  // init
  useEffect(() => {
    dispatch(getSSOSetings())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // failed to get sso settings
  useEffect(() => {
    if (isGetSSOSettingsFailed) {
      routesConfig.LOGIN.goto()
    }
  }, [isGetSSOSettingsFailed])

  // starting auth0 login
  useEffect(() => {
    if (ssoSettings?.auth0Params) {
      auth0Login(ssoSettings.auth0Params)
    }
  }, [auth0Login, ssoSettings])

  return <Loading />
}

export default LoginSSO
