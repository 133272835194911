import { useMemo } from 'react'

import { SettingValue } from 'types/Settings'
import { AtpScanMode } from 'types/atpSettings'
import { Form, useAtpSettingsForm } from 'components/pages/atpSettings/useAtpSettingsForm'

export interface Page {
  isNotifyAdminDisabled: boolean
  isAdminEmailDisabled: boolean
  isExemptionsDisabled: boolean
}

export interface AtpSettingsLogic {
  page: Page
  form: Form
}

export const useAtpSettingsLogic = (): AtpSettingsLogic => {
  const form = useAtpSettingsForm()

  const isNotifyAdminDisabled = useMemo(() => form.settings.atd !== AtpScanMode.DELIVER_SCAN, [form.settings.atd])

  const isAdminEmailDisabled = useMemo(
    () => isNotifyAdminDisabled || form.settings.atd_notify !== SettingValue.ENABLED,
    [form.settings.atd_notify, isNotifyAdminDisabled]
  )

  const isExemptionsDisabled = useMemo(
    () => !(form.settings.atd === AtpScanMode.DELIVER_SCAN || form.settings.atd === AtpScanMode.SCAN_DELIVER),
    [form.settings.atd]
  )

  const page: Page = useMemo(
    () => ({
      isNotifyAdminDisabled,
      isAdminEmailDisabled,
      isExemptionsDisabled
    }),
    [isAdminEmailDisabled, isExemptionsDisabled, isNotifyAdminDisabled]
  )

  return useMemo(() => ({ page, form }), [page, form])
}
