import Cookies from 'universal-cookie'
import config from 'config/appConfig'

const { COOKIES } = config
const cookies = new Cookies()

const getCookieDomain = () => {
  const cookieDomain = config.DOMAIN
  return cookieDomain
}
const removeSessionCookie = () => {
  const cookieDomain = getCookieDomain()
  if (cookieDomain === 'localhost') {
    return
  }
  cookies.remove(COOKIES.SESSION, {
    domain: cookieDomain,
    path: '/'
  })
  cookies.remove(COOKIES.SESSION, {
    domain: `.${cookieDomain}`,
    path: '/'
  })
}

const setSessionCookie = (value: string) => {
  removeSessionCookie()
  const cookieDomain = config.DOMAIN
  if (cookieDomain === 'localhost') {
    return
  }
  cookies.set(COOKIES.SESSION, value, {
    expires: new Date(Date.now() + 8 * 60 * 60 * 1000),
    domain: getCookieDomain(),
    path: '/'
  })
}
const getSessionCookie = (): string => {
  const cookieDomain = config.DOMAIN
  if (cookieDomain === 'localhost') {
    return 'localhost'
  }
  return cookies.get(COOKIES.SESSION)
}

const getSidebarBannerCookie = () =>
  typeof cookies.get(COOKIES.SIDEBAR_BANNER_HIDDEN, { doNotParse: true }) !== 'undefined'
const setSidebarBannerCookie = (value: boolean) => {
  cookies.set(COOKIES.SIDEBAR_BANNER_HIDDEN, value, {
    path: '/'
  })
}

const getLanguageCookie = (): string | undefined => cookies.get(COOKIES.LANGUAGE_PREFERENCE)
const setLanguageCookie = (value: string) => {
  cookies.set(COOKIES.LANGUAGE_PREFERENCE, value, {
    path: '/'
  })
}

export {
  setSessionCookie,
  removeSessionCookie,
  getSessionCookie,
  getSidebarBannerCookie,
  setSidebarBannerCookie,
  getLanguageCookie,
  setLanguageCookie
}
