import React, { useImperativeHandle, useMemo, useState } from 'react'
import { Button } from '@barracuda-internal/bds-core'
import styles from 'components/pages/settings/linkedAccounts/linkedAccountsStyles'
import { useFormatMessage } from 'lib/localization'

interface AddButtonProps {
  disabled: boolean
  handle: React.RefObject<{ setIsDisabled: (value: boolean) => void }>
  onLink: () => void
}

const BASE_I18N_KEY = 'ess.settings.linked_accounts'

const AddButton: React.FC<AddButtonProps> = ({ handle, onLink, disabled }) => {
  const [isDisabled, setIsDisabled] = useState(disabled)
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  useImperativeHandle(
    handle,
    () => ({
      setIsDisabled
    }),
    []
  )

  return useMemo(
    () => (
      <Button
        disabled={isDisabled}
        className={classes.actionButton}
        color="primary"
        onClick={onLink}
        variant="contained"
        data-testid="link-button"
      >
        {' '}
        {formatMessage('add')}{' '}
      </Button>
    ),
    [classes.actionButton, formatMessage, isDisabled, onLink]
  )
}

export default AddButton
