import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { MessageSource } from 'types/Messages'
import { getMessageSource } from 'redux/features/mstore/mstoreSlice'
import { useMessageVariables } from 'components/libs/message/useMessageVariables'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'

export interface State {
  isGetMessageSourcePending: boolean
  isGetMessageSourceSuccess: boolean
  isGetMessageSourceFailed: boolean
  getMessageSourceError: string | undefined
  isShowSourceDisabled: boolean | undefined
  showSourceDisabledReason: string | undefined
  messageSource: MessageSource | undefined
}

export type MessageDetailsContentTabMessageSourceLogic = [State]

export const useMessageDetailsContentTabMessageSourceLogic = (): MessageDetailsContentTabMessageSourceLogic => {
  const { hasContent, isLargeMessage, isAtpAffected, encrypted } = useMessageVariables()
  const { hasRightToSkipSendUserAction, canViewBlockedMessages, canViewQuarantinedMessages, isMessageSourceAvailable } =
    useMessageLogRights()
  const dispatch = useAppDispatch()
  const {
    message,
    isGetMessageSourceSuccess,
    isGetMessageSourceFailed,
    getMessageSourceError,
    isGetMessageSourcePending,
    messageSource,
    showImages,
    userId
  } = useAppSelector(_store => ({
    message: _store.mstore.message,
    isGetMessageSourcePending: isPending(_store.mstore.getMessageSourceApiStatus),
    isGetMessageSourceSuccess: isSuccess(_store.mstore.getMessageSourceApiStatus),
    isGetMessageSourceFailed: isFailed(_store.mstore.getMessageSourceApiStatus),
    getMessageSourceError: getErrorMessage(_store.mstore.getMessageSourceApiStatus),
    messageSource: _store.mstore.messageSource,
    showImages: _store.mstore.showImages,
    userId: _store.auth.accessTokenObject?.userId
  }))

  const showSourceDisabledReason = useMemo(() => {
    if (!message) {
      return undefined
    }
    switch (true) {
      case encrypted: {
        return 'encrypted'
      }
      case isAtpAffected: {
        return 'atd_detected'
      }
      case !canViewBlockedMessages: {
        return 'cant_view_blocked'
      }
      case !canViewQuarantinedMessages: {
        return 'cant_view_quarantined'
      }
      case !hasContent: {
        return 'no_content'
      }
      default:
        return ''
    }
  }, [canViewQuarantinedMessages, canViewBlockedMessages, isAtpAffected, message, hasContent, encrypted])

  useEffect(() => {
    if (!message) {
      return
    }
    if (message && messageSource && message.mid === messageSource.mid) {
      return
    }
    dispatch(
      getMessageSource({
        messageId: message.mid,
        parse: 0,
        headersOnly: isLargeMessage ? 1 : 0,
        showImages: showImages ? 1 : 0,
        domainId: message.did,
        userId: hasRightToSkipSendUserAction ? undefined : userId
      })
    )
  }, [dispatch, isLargeMessage, message, messageSource, showImages, hasRightToSkipSendUserAction, userId])

  return useMemo(
    () => [
      {
        isGetMessageSourcePending,
        isGetMessageSourceSuccess,
        isGetMessageSourceFailed,
        getMessageSourceError,
        isShowSourceDisabled: !isMessageSourceAvailable,
        showSourceDisabledReason,
        messageSource
      }
    ],
    [
      getMessageSourceError,
      isGetMessageSourceFailed,
      isGetMessageSourcePending,
      isGetMessageSourceSuccess,
      isMessageSourceAvailable,
      messageSource,
      showSourceDisabledReason
    ]
  )
}
