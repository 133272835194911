import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function ReportsDomain(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Select the report type from the <b>Report Type</b> drop-down menu. Each of the <b>Inbound Traffic</b> and{' '}
        <b>Outbound Traffic</b> reports summarize all messages processed through {props.productName} for this domain.
      </p>
      <p>
        Generate reports for up to a thirty day period. You can set the <b>Start Date</b> for up to 30 days in the past
        from the current date based on the default time zone of the account. Click in the <b>Start Date</b> field, and
        select the start date from the calendar. Click <b>Show Report</b> to display the report summary, or click{' '}
        <b>Export</b> to download the report as a .<b>csv</b> file to your local system.
      </p>
      Report columns include:
      <ul>
        <li>
          <b>Date</b>
          <br />
          Date messages were received. The date format is YYYY-MM-DD (Example: 2011-11-20).
        </li>
        <li>
          <b>Deferred</b>
          <br />
          Total number of deferred messages.
        </li>
        <li>
          <b>Rate Controlled</b>
          <br />
          Number of messages deferred due to Rate Control.
        </li>
        <li>
          <b>Blocked:Policy</b>
          <br />
          Number of messages blocked due to policy.
        </li>
        <li>
          <b>Blocked:Spam</b>
          <br />
          Number of messages blocked due to spam.
        </li>
        <li>
          <b>Blocked:Virus</b>
          <br />
          Number of messages blocked due to viruses.
        </li>
        <li>
          <b>Blocked:ATP</b>
          <br />
          Number of messages blocked due to Advanced Threat Protection (ATP) (<i>inbound</i> traffic only).
        </li>
        <li>
          <b>Blocked:Other</b>
          <br />
          Number of messages blocked due to Possible Mail Loop, Malformed email, or Invalid Recipient.
        </li>
        <li>
          <b>Blocked:Total</b>
          <br />
          Total number of blocked messages.
        </li>
        <li>
          <b>Quarantined</b>
          <br />
          Number of messages quarantined.
        </li>
        <li>
          <b>Allowed</b>
          <br />
          Number of messages allowed.
        </li>
        <li>
          <b>Encrypted</b>
          <br />
          Number of messages encrypted (<i>outbound</i> traffic only).
        </li>
        <li>
          <b>Total Received</b>
          <br />
          Total number of messages processed from the Internet through {props.productName} to this domain.
        </li>
      </ul>
      <p>
        Other available reports are listed below. Use the <b>Top</b> field to specify the number of results, from the
        highest number down, to show in the report.
      </p>
      <ul>
        <li>
          <b>Top Email Senders</b>
          <br />
          Senders of the most messages.
        </li>
        <li>
          <b>Top Email Recipients</b>
          <br />
          Recipients of the most messages.
        </li>
        <li>
          <b>Top Spam Senders</b>
          <br />
          Senders of the most messages identified as Spam.
        </li>
        <li>
          <b>Top Spam Recipients</b>
          <br />
          Recipients of the most messages identified as Spam.
        </li>
        <li>
          <b>Top Virus Senders</b>
          <br />
          Senders of the most messages containing viruses.
        </li>
        <li>
          <b>Top Virus Recipients</b>
          <br />
          Recipients of the most messages containing viruses.
        </li>
        <li>
          <b>Top Blocked Senders</b>
          <br />
          Senders of the greatest number of blocked messages.
        </li>
        <li>
          <b>Top Blocked Recipients</b>
          <br />
          Recipients of the greatest number of blocked messages.
        </li>
      </ul>
    </div>
  )
}

export default ReportsDomain
