import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  alert: {
    margin: 0,
    backgroundColor: colors.yellow050,
    borderBottom: `1px solid ${colors.scaleGray300}`,
    '& div': {
      zIndex: 1299
    }
  },
  interfaceBannerLink: {
    margin: theme.spacing(1),
    color: colors.blue500,
    cursor: 'pointer'
  }
}))
