import { useCallback, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getInboundTopBlockedStats, updateFilters } from 'redux/features/stats/statsSlice'

import { DashboardRange, StatType, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'

export interface UseInboundTopBlockedLogicProps {
  domainId?: string
  range: DashboardRange
}

interface State {
  inboundTopBlockedStats: TopRecipientDomains | undefined
  inboundTopBlockedStatsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseInboundTopBlockedLogic = [State, EventHandlers]

export const useInboundTopBlockedLogic = (props: UseInboundTopBlockedLogicProps): UseInboundTopBlockedLogic => {
  const dispatch = useAppDispatch()

  const { inboundTopBlockedStats, inboundTopBlockedStatsInProgress } = useAppSelector(_store => ({
    inboundTopBlockedStats: _store.stats.inboundTopBlockedStats,
    inboundTopBlockedStatsInProgress: isPending(_store.stats.api.getInboundTopBlockedStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.inbound)?.dropdownItems[0].value || ''
  )

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(
    value => {
      setSelectedFilter(value as StatType)
      dispatch(
        updateFilters({
          inboundTopBlocked: { statType: value === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS }
        })
      )
      dispatch(
        getInboundTopBlockedStats({
          domainId: props.domainId,
          range: props.range,
          statType: value === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS
        })
      )
    },
    [dispatch, props.range, props.domainId]
  )

  return useMemo(
    () => [
      {
        inboundTopBlockedStats,
        inboundTopBlockedStatsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [inboundTopBlockedStats, inboundTopBlockedStatsInProgress, selectedFilter, onSelectFilter]
  )
}
