import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundAtp(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        In addition to the virus scanner included with {props.productName}, you can also subscribe to the Advanced
        Threat Protection (ATP) service. ATP is a cloud-based virus scanning service that applies to <i>inbound</i>{' '}
        messages, analyzing email attachments with most MIME types in a separate, secured cloud environment to detect
        new threats and determine whether to block such messages. If you subscribe to ATP, you must enable the service
        on this page. Scanning statistics for files blocked by ATP are displayed on the <b>Dashboard</b> page. For more
        information on ATP, see
        <a href="https://campus.barracuda.com/doc/96023051/" target="_blank" rel="noreferrer">
          {' '}
          Barracuda Campus
        </a>
        .
        <br />
        <br />
        Enable and configure ATP as follows:
      </p>
      <ul>
        <li>
          <b>Enable Advanced Threat Protection</b>
          <br />
          These settings apply to email attachments:
        </li>
        <ul>
          <li>
            <b>Deliver First, Then Scan</b>
            <br />
            When selected, the ATP service attempts to scan the mail in real time:
          </li>
          <ul>
            <li>
              If the ATP scan completes in real time and a virus is detected, the message is blocked and is not
              delivered.
            </li>
            <li>
              If the scan does not complete in real time, the message is delivered. If the ATP service determines the
              attachment is suspicious or virus-infected upon completion, the recipient is notified. If{' '}
              <b>Notify Admin</b> is set to <b>Yes</b>, an email alert is sent to the specified admin address.
            </li>
          </ul>
          <b>Note:</b> This option does not delay email processing; however, the email recipient can potentially open an
          infected attachment.
          <li>
            <b>Scan First, Then Deliver</b>
            <br />
            When selected, the ATP service scans new messages with attachments before delivery. If a virus is detected
            in an attachment, or the attachment is a known threat, the message is blocked, otherwise, the message is
            delivered to the recipient.
            <br />
            These messages appear in the Message log and <b>Pending Scan</b> displays in the <b>Reason</b> column. The
            mail server retries until the scan is complete and no virus is detected in the attachment, at which point
            the message is delivered.
            <br />
            <b>Note</b>: This option provides more security and prevents the email recipient from opening infected
            attachments. Note that messages with attachments may be temporarily deferred while queued for scanning; if
            the message is still deferred after four hours, the message is quarantined.
            <br />
          </li>
          <li>
            <b>No</b>
            <br />
            Do not enable ATP.
          </li>
        </ul>
        <li>
          <b>Notify Admin</b>
        </li>
        <ul>
          <li>
            <b>Yes</b>
            <br />
            If <b>Deliver First, Then Scan</b> is selected, and if a virus is detected in a scanned attachment, an email
            notification is sent to the Admin indicating the sender, recipient, attachment type and virus detected.
          </li>
          <li>
            <b>No</b>
            <br />
            No email notification is sent related to ATP. An email notification is still sent to the recipient if ATP
            detects a virus or suspicious attachment.
          </li>
        </ul>
        <li>
          <b>ATP Notification Email</b>
          <br />
          If <b>Notify Admin</b> is set to <b>Yes</b>, enter the email address to notify the Admin if a virus is
          detected by ATP in a scanned attachment.
        </li>
      </ul>
      <p>
        <h3>ATP Exemptions</h3>
        When ATP is set to either <b>Deliver First, then Scan</b> or <b>Scan First, then Deliver</b>, use this section
        to exempt sender email addresses, sender domains, recipient email addresses, recipient domains, or sender IP
        addresses from ATP scanning. Attachments from exempted entries are not sent to the ATP cloud. Note that these
        exemptions apply to <i>ATP scanning only</i> and do not apply to {props.productName} virus scanning.
        <br />
        <br />
        <b>Exemptions by Envelope From Email Address / Envelope From Domains</b>
        <p>To add an exemption by envelope from email address or envelope from domain:</p>
      </p>
      <ol>
        <li>
          Enter the email address or domain in the <b>Exemptions</b> field.
        </li>
        <li>
          Select <b>Sender</b> or <b>Recipient</b>.
        </li>
        <li>
          Optionally, enter a <b>Comment</b> for the exemption.
        </li>
        <li>
          Click <b>Add</b>; the exemption displays in the list.
        </li>
      </ol>
      <p>
        To remove an exemption, click <b>Delete</b>.
        <br />
        <br />
        <b>Exemptions by Sender IP Address</b>
      </p>
      <p>To add an exemption by sender IP address:</p>
      <ol>
        <li>
          Enter the sender&apos;s IP address in the <b>Exemptions</b> field, and enter the associated <b>Netmask</b>.
        </li>
        <li>
          Optionally, enter a <b>Comment</b> for the exemption.
        </li>
        <li>
          Click <b>Add</b>; the exemption displays in the list.
        </li>
      </ol>
      <p>
        To remove an exemption, click <b>Delete</b>.
      </p>
    </div>
  )
}

export default InboundAtp
