import React, { useMemo } from 'react'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { DialogActions } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.domains'

export const DisableSpoolingWarningDialog = ({ onConfirm, onClose }: DialogActions) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <WarningDialog
        title={formatMessage('edit_domain.warnings.disable_spooling.title')}
        description={formatMessage('edit_domain.warnings.disable_spooling.description')}
        onCancel={onClose}
        onConfirm={onConfirm}
      />
    ),
    [formatMessage, onClose, onConfirm]
  )
}
