import { makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  descriptionSpacing: {
    marginTop: theme.spacing(2)
  },
  rowSpacing: {
    marginTop: theme.spacing(2)
  }
}))

export default styles
