import React, { useMemo } from 'react'

import { Select, SelectMenuItem, SelectWrapper } from '@barracuda-internal/bds-core'
import { AvailablePoliciesSettings, ExemptSenders, ManagedPolicy } from 'types/users'
import { useFormatMessage } from 'lib/localization'
import { useDefaultPolicyLogic } from 'components/pages/users/defaultPolicy/useDefaultPolicyLogic'
import {
  FormRow,
  FormSpacer,
  FormText,
  HelperText,
  InlineRadioGroup,
  PageContainer,
  PageTitle
} from 'components/libs/layout/pages/pageLayout'

import routesConfig from 'lib/routesConfig'
import timezones, { Timezone } from 'config/timezones'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import UsersDefaultPolicySupport from 'components/pages/support/help/usersDefaultPolicy/UsersDefaultPolicy'
import { RadioButtonStates } from '../usersTypes'

const BASE_I18N_KEY = 'ess.users.default_policy'

const DefaultPolicy: React.FC = () => {
  const [state, eventHandlers] = useDefaultPolicyLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: UsersDefaultPolicySupport,
    title: TOPICS.defaultPolicy,
    groupTitle: GROUP_TITLE.userTopics
  })

  return useMemo(
    () => (
      <PageContainer
        isLoading={state.isLoading}
        isFailedToLoad={state.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.isDirty}
      >
        <HelpModal />
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={onOpenSupport}
          onCancel={() => routesConfig.USERS_LIST.goto()}
          onSubmit={eventHandlers.onSaveChanges}
          submitInProgress={state.inProgress}
          isDisabledSubmit={!state.isDirty}
        />
        <FormText>{formatMessage('information_text')}</FormText>
        <FormSpacer />
        <FormRow contentIndent={4} rowTitle={formatMessage('default_policy_managed_users')}>
          <SelectWrapper variant="outlined">
            <Select
              name={AvailablePoliciesSettings.DEFAULT_MANAGED_SCAN_POLICY}
              onChange={e =>
                eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_MANAGED_SCAN_POLICY)(e.target.value)
              }
              value={state.form.defaultManagedScanPolicy}
              disabled={state.inProgress}
            >
              {[ManagedPolicy.scan, ManagedPolicy.allow, ManagedPolicy.block].map((policy: ManagedPolicy) => (
                <SelectMenuItem key={policy} value={policy}>
                  {formatMessage(`managed_policy_select.${policy}`)}
                </SelectMenuItem>
              ))}
            </Select>
          </SelectWrapper>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('default_policy_unmanaged_users')}>
          <SelectWrapper variant="outlined">
            <Select
              name={AvailablePoliciesSettings.DEFAULT_UNMANAGED_SCAN_POLICY}
              onChange={e =>
                eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_UNMANAGED_SCAN_POLICY)(e.target.value)
              }
              value={state.form.defaultUnmanagedScanPolicy}
              disabled={state.inProgress}
            >
              {[ManagedPolicy.scan, ManagedPolicy.allow, ManagedPolicy.block].map((policy: ManagedPolicy) => (
                <SelectMenuItem key={policy} value={policy}>
                  {formatMessage(`managed_policy_select.${policy}`)}
                </SelectMenuItem>
              ))}
            </Select>
          </SelectWrapper>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('exempt_senders')}>
          <SelectWrapper variant="outlined">
            <Select
              name={AvailablePoliciesSettings.DEFAULT_USER_EXEMPT_ENABLED}
              onChange={e =>
                eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_USER_EXEMPT_ENABLED)(e.target.value)
              }
              value={state.form.defaultUserExemptEnabled}
              disabled={state.inProgress}
            >
              {[ExemptSenders.allow, ExemptSenders.partiallyAllow, ExemptSenders.block].map((policy: ExemptSenders) => (
                <SelectMenuItem key={policy} value={policy}>
                  {formatMessage(`exempt_senders_select.${policy}`)}
                </SelectMenuItem>
              ))}
            </Select>
          </SelectWrapper>
        </FormRow>
        <FormRow contentIndent={4} rowTitle="">
          <FormSpacer />
          <HelperText>{formatMessage('exempt_senders_information_text')}</HelperText>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('allow_users_to_block')}>
          <InlineRadioGroup
            name={AvailablePoliciesSettings.DEFAULT_USER_BLOCK_ENABLED}
            onChange={eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_USER_BLOCK_ENABLED)}
            value={state.form.defaultUserBlockEnabled ? RadioButtonStates.on : RadioButtonStates.off}
            disabled={state.inProgress}
          />
        </FormRow>
        <FormRow contentIndent={4} rowTitle="">
          <HelperText>{formatMessage('allow_users_to_block_information_text')}</HelperText>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('allow_deliver_blocked_messages')}>
          <InlineRadioGroup
            name={AvailablePoliciesSettings.DEFAULT_USER_DELIVER_ENABLED}
            onChange={eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_USER_DELIVER_ENABLED)}
            value={state.form.defaultUserDeliverEnabled ? RadioButtonStates.on : RadioButtonStates.off}
            disabled={state.inProgress}
          />
        </FormRow>
        <FormRow contentIndent={4} rowTitle="">
          <HelperText>{formatMessage('allow_deliver_blocked_messages_information_text')}</HelperText>
          <FormSpacer half />
          <HelperText>{formatMessage('allow_deliver_blocked_messages_information_text_2')}</HelperText>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('allow_deliver_quarantined_messages')}>
          <InlineRadioGroup
            name={AvailablePoliciesSettings.DEFAULT_USER_QUAR_DELIVER_ENABLED}
            onChange={eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_USER_QUAR_DELIVER_ENABLED)}
            value={state.form.defaultUserQuarDeliverEnabled ? RadioButtonStates.on : RadioButtonStates.off}
            disabled={state.inProgress}
          />
        </FormRow>
        <FormRow contentIndent={4} rowTitle="">
          <HelperText>{formatMessage('allow_deliver_quarantined_messages_information_text')}</HelperText>
        </FormRow>
        <FormSpacer />

        <FormRow contentIndent={4} rowTitle={formatMessage('default_timezone')}>
          <SelectWrapper variant="outlined">
            <Select
              name={AvailablePoliciesSettings.DEFAULT_TIMEZONE}
              onChange={e => eventHandlers.onFormChange(AvailablePoliciesSettings.DEFAULT_TIMEZONE)(e.target.value)}
              value={state.form.defaultTimezone}
              disabled={state.inProgress}
            >
              {timezones.map((timezone: Timezone) => {
                const [tzValue, , utc, description] = timezone

                return (
                  <SelectMenuItem key={utc} value={tzValue}>
                    {`(UTC${utc}) ${description}`}
                  </SelectMenuItem>
                )
              })}
            </Select>
          </SelectWrapper>
        </FormRow>

        <FormSpacer />
        <FormSpacer />
      </PageContainer>
    ),
    [eventHandlers, formatMessage, state, onOpenSupport, HelpModal]
  )
}

export default DefaultPolicy
