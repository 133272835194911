import { UiRoutes } from 'lib/routesConfig'

export enum PreAuthCommandType {
  NO_ACTION = 'NO_ACTION',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export interface PreAuthNoActionCommand {
  type: PreAuthCommandType.NO_ACTION
}

export interface PreAuthChangePasswordCommand {
  type: PreAuthCommandType.CHANGE_PASSWORD
  user: string
  expiration: string
  sig: string
}

export type PreAuthCommand = PreAuthChangePasswordCommand | PreAuthNoActionCommand

export const makePreAuthCommandFactory = () => {
  const isChangePassword = (searchParams: URLSearchParams) => {
    const url = searchParams.get('url')
    if (!url) {
      return false
    }
    return url === 'user/settings/change_password'
  }

  const create = (queryString: string): PreAuthCommand => {
    const searchParams = new URLSearchParams(queryString)

    if (isChangePassword(searchParams)) {
      const user = searchParams.get('user') || ''
      const expiration = searchParams.get('expiration') || ''
      const sig = searchParams.get('sig') || ''
      return {
        type: PreAuthCommandType.CHANGE_PASSWORD,
        user,
        expiration,
        sig
      }
    }

    return { type: PreAuthCommandType.NO_ACTION }
  }

  return Object.freeze({
    create
  })
}

export const makePreAuthCommandHandler = (uiRoutes: UiRoutes) => {
  const handle = (command: PreAuthCommand) => {
    const { type } = command
    switch (type) {
      case PreAuthCommandType.CHANGE_PASSWORD: {
        const { user, expiration, sig } = command
        const searchParams = new URLSearchParams({
          user,
          expiration,
          sig
        })
        uiRoutes.RESET_PASSWORD.goto({}, false, `?${searchParams.toString()}`)
        break
      }
      case PreAuthCommandType.NO_ACTION: // fallthrough
      default: {
        break
      }
    }
  }

  return Object.freeze({
    handle
  })
}
