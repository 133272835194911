import util from 'util'
import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { useFormatMessage } from 'lib/localization'
import routesConfig, { pushToHistory } from 'lib/routesConfig'
import { postAllowSender, postBlockSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import {
  getLoginSettings,
  login,
  resetLogin,
  resetLoginSettings,
  temporaryLogin,
  temporaryPasscode
} from 'redux/features/auth/authSlice'
import { LoginPayload, requestLoginInfo, TemporaryPasscodeRateLimitError } from 'redux/features/auth/authApiThunks'
import { isEmailValid } from 'lib/validation'
import appConfig from 'config/appConfig'
import usePreviousValue from 'lib/usePreviousValue'
import { useAuth0Login } from 'lib/hooks/useAuth0Login'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import appFeatures from 'config/appFeatures'
import { SessionCheckStatus, useSessionCheck } from 'components/pages/login/useSessionCheck'
import { ConnectionCheckStatus, useAvailableConnections } from 'components/pages/login/useAvailableConnections'
import { LoginViews } from 'components/pages/login/loginPageTypes'
import { useErrorParam } from 'components/pages/login/useErrorParam'
import { getPublicAppSettings } from 'redux/features/app/appSlice'
import { usePostAuthCommands } from 'lib/postAuthCommands/usePostAuthCommands'
import { usePreAuthCommands } from 'lib/preAuthCommands/usePreAuthCommands'

export enum UiState {
  INITIALIZING,
  READY
}

export interface State {
  isLoading: boolean
  cardType: LoginViews
  formValues: LoginPayload
  emailError: boolean
  isTemporaryPasscodeEnabled: boolean
  message: string
  topErrorMessage: string
  topErrorDescription: string
  adminLoginUrl: string
  validatedLoginError: boolean
  nextButton: MutableRefObject<HTMLButtonElement | undefined>
  loginButton: MutableRefObject<HTMLButtonElement | undefined>
  uiState: UiState
}

export interface EventHandlers {
  setCardType: Dispatch<SetStateAction<LoginViews>>
  setFormValues: Dispatch<SetStateAction<LoginPayload>>
  onInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onEmailEnter: () => void
  onLogin: () => void
  onTemporaryLogin: () => void
  onTemporaryPasscode: () => void
  resetLoginState: (hardReset?: boolean) => void
  onForgotPassword: () => void
  onResendLink: () => void
  onClickLogo: () => void
  onCloseSnackBar: (event?: SyntheticEvent, reason?: string) => void
  auth0Login: () => void
  goBackToSignIn: () => void
  setAutofocusRef: (el: HTMLInputElement) => void
}

export type UseLoginLogic = [State, EventHandlers]

const BASE_I18N_KEY = 'ess.login'
const ADMIN_LOGIN_URL = `https://login.barracudanetworks.com/auth/login?service=${encodeURI(
  `${window.location.origin}/admin`
)}`
const LOGO_LINK = 'https://www.barracuda.com/products/essentials'

export const useLoginLogic = (): UseLoginLogic => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()

  const {
    loginSettings,
    loginError,
    isLoggingIn,
    isRequestingLoginSettings,
    isTemporaryPasscodeSuccess,
    requestLoginSettingsApiStatusSuccess,
    isTemporaryPasscodeFailed,
    isTemporaryPasscodePending,
    temporaryPasscodeApiStatus,
    userDomainId,
    publicAppSettings,
    isEnhancedAuthenticationEnabled,
    isRequestLoginInfoPending
  } = useAppSelector(_store => ({
    loginSettings: _store.auth.loginSettings,
    loginError: getErrorMessage(_store.auth.loginApiStatus),
    isLoggingIn: isPending(_store.auth.loginApiStatus),
    isRequestingLoginSettings: isPending(_store.auth.requestLoginSettingsApiStatus),
    isTemporaryPasscodeSuccess: isSuccess(_store.auth.temporaryPasscodeApiStatus),
    requestLoginSettingsApiStatusSuccess: isSuccess(_store.auth.requestLoginSettingsApiStatus),
    isTemporaryPasscodeFailed: isFailed(_store.auth.temporaryPasscodeApiStatus),
    isTemporaryPasscodePending: isPending(_store.auth.temporaryPasscodeApiStatus),
    isTemporaryLoginFailed: isFailed(_store.auth.temporaryPasscodeApiStatus),
    temporaryPasscodeApiStatus: _store.auth.temporaryPasscodeApiStatus,
    userDomainId: _store.auth.accessTokenObject?.domainId,
    publicAppSettings: _store.app.publicAppSettings,
    isEnhancedAuthenticationEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled,
    isRequestLoginInfoPending: isPending(_store.auth.requestLoginInfoApiStatus)
  }))
  const { auth0Login: doAuth0Login } = useAuth0Login()
  const { appTypeEntryPath } = useAppTypeEntryPaths()

  const actionType = params.get('actionType') || undefined
  const messageId = params.get('messageId') || undefined
  const resetPassword = params.get('reset-password') || undefined
  const forgotPassword = params.get('forgot-password') || undefined
  const autoFillEmail = params.get('email') || undefined
  const loginHint = params.get('login-hint') || undefined
  const defaultEmail = autoFillEmail || loginHint || ''

  const [cardType, setCardType] = useState<LoginViews>(LoginViews.Login)
  const [formValues, setFormValues] = useState<LoginPayload>({ user_id: defaultEmail, password: '' })
  const [emailError, setEmailError] = useState(false)
  const [isTemporaryPasscodeEnabled, setIsTemporaryPasscodeEnabled] = useState(false)
  const [message, setMessage] = useState('')
  const [topErrorMessage, setTopErrorMessage] = useState('')
  const [topErrorDescription, setTopErrorDescription] = useState('')

  const previousCardType: LoginViews | undefined = usePreviousValue(cardType)
  const prevRequestLoginSettingsApiStatusSuccess: boolean = usePreviousValue(requestLoginSettingsApiStatusSuccess)
  const nextButton = useRef<HTMLButtonElement>()
  const loginButton = useRef<HTMLButtonElement>()

  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [{ status: sessionCheckStatus }, { checkSession, forceCheckSessionStateDone }] = useSessionCheck()
  const [
    { status: availableConnectionCheckStatus },
    { createAuthProcess, resetCreateAuthProcess, startOAuth2AuthorizationCodeFlow }
  ] = useAvailableConnections({
    setCardType,
    setTopErrorMessage,
    setIsTemporaryPasscodeEnabled
  })
  useErrorParam({ setTopErrorMessage, setTopErrorDescription })
  const postAuthCommands = usePostAuthCommands()
  const preAuthCommands = usePreAuthCommands()

  const isPublicSettingsReady = useMemo(() => typeof publicAppSettings !== 'undefined', [publicAppSettings])

  // init
  useEffect(() => {
    if (isEnhancedAuthenticationEnabled) {
      checkSession()
    }

    if (loginHint) {
      onEmailEnter()
    }

    setMessage('')

    if (resetPassword === '1') {
      setMessage(formatMessage('reset_password_message'))
      routesConfig.LOGIN.goto()
    } else if (forgotPassword && forgotPassword.length !== 0) {
      const msg = formatMessage('message.sent_message')
      setMessage(util.format(msg, forgotPassword))
      routesConfig.LOGIN.goto()
    }

    const listener = (e: any) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        e.preventDefault()
        e.stopPropagation()
        if (loginButton.current) {
          loginButton.current.click()
        } else if (nextButton.current) {
          nextButton.current.click()
        }
      }
    }
    document.addEventListener('keydown', listener)

    // unmount
    return () => {
      document.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line
  }, [])

  const gotoEntryPath = useCallback(() => {
    const returnUrl = params.get('return_to')
    if (returnUrl) {
      pushToHistory(returnUrl)
    } else {
      appTypeEntryPath.goto()
    }
  }, [params, appTypeEntryPath])

  useEffect(() => {
    if (!isEnhancedAuthenticationEnabled) {
      return
    }
    if (userDomainId) {
      const command = postAuthCommands.commandFactory.create(window.location.search || '')
      postAuthCommands.commandHandler.handle(command)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDomainId])

  useEffect(() => {
    if (isEnhancedAuthenticationEnabled) {
      return
    }
    if (userDomainId) {
      // TODO: using mixpanel without user
      // trackMixpanelEvent(TRACKING_EVENTS.WEBUI.LOGIN_PAGE_VIEW)
      if (actionType === 'quarantineNotification') {
        routesConfig.SETTINGS_QUARANTINE_NOTIFICATION.goto()
      } else if (actionType && messageId) {
        // eslint-disable-next-line default-case
        switch (true) {
          case actionType === 'deliver':
            dispatch(
              postRedeliverMessage({
                items: [{ messageId, domainId: userDomainId }],
                atpBypass: false
              })
            )
            break
          case actionType === 'block':
            dispatch(postBlockSender([{ messageId, domainId: userDomainId }]))
            break
          case actionType === 'whitelist':
            dispatch(
              postAllowSender({
                items: [{ messageId, domainId: userDomainId }],
                atpBypass: false
              })
            )
            break
        }
        gotoEntryPath()
      } else {
        gotoEntryPath()
      }
    }
    // eslint-disable-next-line
  }, [userDomainId])

  // reset login settings when login screen is set back to default
  useEffect(() => {
    if (!!previousCardType && previousCardType in LoginViews && cardType === LoginViews.Login) {
      dispatch(resetLoginSettings())
      resetCreateAuthProcess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, cardType, previousCardType])

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setEmailError(false)
      const { name, value } = e.target
      const nextValue = name === 'user_id' ? value.trim() : value
      setFormValues({ ...formValues, [name]: nextValue })
    },
    [formValues]
  )

  const onEmailEnter = useCallback(() => {
    setFormValues({ ...formValues, password: '' })
    const valid = isEmailValid(formValues.user_id)
    setEmailError(!valid)
    if (!valid) {
      return
    }
    if (availableConnectionCheckStatus === ConnectionCheckStatus.DISABLED) {
      dispatch(getLoginSettings(formValues))
      return
    }
    createAuthProcess(formValues.user_id)
  }, [availableConnectionCheckStatus, createAuthProcess, dispatch, formValues])

  const auth0Login = useCallback(async () => {
    if (availableConnectionCheckStatus !== ConnectionCheckStatus.DISABLED) {
      startOAuth2AuthorizationCodeFlow()
      return
    }
    if (loginSettings?.auth0Params && loginSettings?.isRolledOutUserRole) {
      doAuth0Login(loginSettings?.auth0Params)
    }
  }, [doAuth0Login, loginSettings, startOAuth2AuthorizationCodeFlow, availableConnectionCheckStatus])

  useEffect(() => {
    if (requestLoginSettingsApiStatusSuccess && !prevRequestLoginSettingsApiStatusSuccess) {
      // forward the user to the correct region
      if (appFeatures.EnabledRegionChange && loginSettings?.url && loginSettings.url !== window.location.host) {
        const urlParams = new URLSearchParams(window.location.search)
        urlParams.set('login-hint', formValues.user_id)
        window.location.href = `${window.location.protocol}//${loginSettings.url}${
          window.location.pathname
        }?${urlParams.toString()}`
      }

      setIsTemporaryPasscodeEnabled(!!loginSettings?.temporaryPasscodeEnabled)

      if (loginSettings?.auth0Params?.connection && appConfig.AUTH0.isEnabled && loginSettings.ssoEnabled) {
        // if not rolled out user
        if (!loginSettings?.isRolledOutOktaUser) {
          // GlobalDialog is handle the non-rolled out Okta user scenario
          // azure/temp_passcode flow
        } else if (loginSettings?.temporaryPasscodeEnabled) {
          setCardType(LoginViews.AzureTemporaryPasscode)
          // azure flow
        } else {
          auth0Login()
        }
      } else {
        // LDAP, manual flows
        setCardType(LoginViews.Password)
      }
    }
  }, [
    loginSettings,
    requestLoginSettingsApiStatusSuccess,
    auth0Login,
    prevRequestLoginSettingsApiStatusSuccess,
    formValues
  ])

  useEffect(() => {
    if (isTemporaryPasscodeSuccess) {
      setCardType(LoginViews.TemporaryPasscode)
    }
  }, [formatMessage, isTemporaryPasscodeSuccess])

  useEffect(() => {
    if (isTemporaryPasscodeFailed) {
      const error = temporaryPasscodeApiStatus[2]

      let parsedError
      try {
        parsedError = error && (JSON.parse(error) as TemporaryPasscodeRateLimitError)
      } catch (e) {
        parsedError = undefined
      }

      if (parsedError) {
        if (parsedError.statusCode === 409) {
          setTopErrorMessage(
            formatMessage('temporary_passcode_limit', {
              rateLimit: parsedError.rate_limit,
              expirationTime: parsedError.expiration_time
            })
          )
        }
        if (parsedError.statusCode === 424) {
          setTopErrorMessage(
            formatMessage('temporary_feature_not_enabled', { domainName: formValues.user_id.split('@')[1] })
          )
        }
      } else {
        setTopErrorMessage(formatMessage('internal_server_error'))
      }
    }
  }, [formatMessage, formValues.user_id, isTemporaryPasscodeFailed, temporaryPasscodeApiStatus])

  const resetLoginState = useCallback(
    (hardReset = false) => {
      dispatch(resetLogin())
      setTopErrorMessage('')
      resetCreateAuthProcess()
      if (hardReset) {
        setFormValues({ user_id: '', password: '' })
        setCardType(LoginViews.Login)
        routesConfig.LOGIN.softGoto()
      } else {
        setFormValues({ ...formValues, password: '' })
      }
    },
    [dispatch, formValues, resetCreateAuthProcess]
  )

  const goBackToSignIn = useCallback(() => {
    resetLoginState()
    setCardType(LoginViews.Login)
    routesConfig.LOGIN.softGoto()
  }, [resetLoginState])

  const onLogin = useCallback(() => {
    dispatch(login(formValues))
  }, [dispatch, formValues])

  const onTemporaryLogin = useCallback(() => {
    dispatch(temporaryLogin(formValues))
  }, [dispatch, formValues])

  const onTemporaryPasscode = useCallback(() => {
    resetLoginState()
    dispatch(temporaryPasscode(formValues))
  }, [dispatch, resetLoginState, formValues])

  const onForgotPassword = useCallback(() => {
    dispatch(requestLoginInfo({ userId: formValues.user_id, origin: 'CheckEmail' }))
    setCardType(LoginViews.Message)
  }, [dispatch, formValues])

  const onResendLink = useCallback(() => {
    onForgotPassword()
  }, [onForgotPassword])

  const onClickLogo = useCallback(() => {
    window.location.href = LOGO_LINK
  }, [])

  const onCloseSnackBar = useCallback((event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setMessage('')
  }, [])

  const validatedLoginError = useMemo(() => {
    let parsedError
    try {
      parsedError = loginError && JSON.parse(loginError)
    } catch (e) {
      parsedError = undefined
    }

    if (parsedError && parsedError.temporaryFeatureDisabled) {
      setTopErrorMessage(
        formatMessage('temporary_feature_not_enabled', { domainName: formValues.user_id.split('@')[1] })
      )
      return false
    }
    return [LoginViews.Password, LoginViews.TemporaryPasscode].includes(cardType) && !!loginError
  }, [formatMessage, formValues.user_id, cardType, loginError])

  const isLoading = useMemo(
    () =>
      isRequestingLoginSettings ||
      availableConnectionCheckStatus === ConnectionCheckStatus.PENDING ||
      isLoggingIn ||
      isTemporaryPasscodePending ||
      (requestLoginSettingsApiStatusSuccess && cardType === LoginViews.Login) ||
      isRequestLoginInfoPending,
    [
      isRequestingLoginSettings,
      availableConnectionCheckStatus,
      isLoggingIn,
      isTemporaryPasscodePending,
      requestLoginSettingsApiStatusSuccess,
      cardType,
      isRequestLoginInfoPending
    ]
  )

  const uiState = useMemo(() => {
    switch (true) {
      case isPublicSettingsReady && sessionCheckStatus === SessionCheckStatus.DONE:
        return UiState.READY
      default:
        return UiState.INITIALIZING
    }
  }, [isPublicSettingsReady, sessionCheckStatus])

  useEffect(() => {
    if (!isPublicSettingsReady) {
      dispatch(getPublicAppSettings())
      forceCheckSessionStateDone()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublicSettingsReady])

  const setAutofocusRef = useCallback((el: HTMLInputElement) => {
    if (el) {
      el.focus()
    }
  }, [])

  useEffect(() => {
    if (!isEnhancedAuthenticationEnabled) {
      return
    }
    const command = preAuthCommands.commandFactory.create(window.location.search || '')
    preAuthCommands.commandHandler.handle(command)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => [
      {
        isLoading,
        cardType,
        formValues,
        emailError,
        isTemporaryPasscodeEnabled,
        message,
        topErrorMessage,
        topErrorDescription,
        adminLoginUrl: ADMIN_LOGIN_URL,
        validatedLoginError,
        nextButton,
        loginButton,
        uiState
      },
      {
        setCardType,
        setFormValues,
        onInputChange,
        onEmailEnter,
        onLogin,
        onTemporaryLogin,
        onTemporaryPasscode,
        resetLoginState,
        onForgotPassword,
        onResendLink,
        onClickLogo,
        onCloseSnackBar,
        auth0Login,
        goBackToSignIn,
        setAutofocusRef
      }
    ],
    [
      isLoading,
      cardType,
      formValues,
      emailError,
      isTemporaryPasscodeEnabled,
      message,
      topErrorMessage,
      topErrorDescription,
      validatedLoginError,
      nextButton,
      loginButton,
      setCardType,
      setFormValues,
      onInputChange,
      onEmailEnter,
      onLogin,
      onTemporaryLogin,
      onTemporaryPasscode,
      resetLoginState,
      onForgotPassword,
      onResendLink,
      onClickLogo,
      onCloseSnackBar,
      auth0Login,
      uiState,
      goBackToSignIn,
      setAutofocusRef
    ]
  )
}
