import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { verifyLinkedAccount } from 'redux/features/settings/settingsSlice'
import routesConfig from 'lib/routesConfig'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import useLogout from 'lib/useLogout'

export interface State {
  isVerifyLinkedAccountApiPending: boolean
  isVerifyLinkedAccountApiFailed: boolean
  isVerifyLinkedAccountApiSuccess: boolean
  userId: string
  email: string
  errorMessageId: string
  allParamsValid: boolean
}

export interface EventHandlers {
  onCloseErrorDialog: () => void
  onCloseInfoDialog: () => void
}

export type VerifyLinkAccountLogic = [State, EventHandlers]

export const useVerifyLinkAccountLogic = (): VerifyLinkAccountLogic => {
  const dispatch = useAppDispatch()
  const [logout] = useLogout()

  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const accountId = params.get('aid') || ''
  const userId = params.get('user') || ''
  const email = params.get('email') || ''
  const hash = params.get('hash') || ''
  const allParamsValid = !!(accountId && userId && email && hash)
  const [errorMessageId, setErrorMessageId] = useState('')

  const { isVerifyLinkedAccountApiPending, isVerifyLinkedAccountApiFailed, isVerifyLinkedAccountApiSuccess } =
    useAppSelector(_store => ({
      isVerifyLinkedAccountApiPending: isPending(_store.settings.verifyLinkedAccountApiStatus),
      isVerifyLinkedAccountApiFailed: isFailed(_store.settings.verifyLinkedAccountApiStatus),
      isVerifyLinkedAccountApiSuccess: isSuccess(_store.settings.verifyLinkedAccountApiStatus)
    }))

  useEffect(() => {
    // TODO: using mixpanel without user
    // trackMixpanelEvent(TRACKING_EVENTS.WEBUI.VERIFY_LINK_ACCOUNT_PAGE_VIEW)
  }, [dispatch])

  useEffect(() => {
    if (!allParamsValid) {
      return
    }

    dispatch(
      verifyLinkedAccount({
        accountId,
        userId,
        hash,
        email
      })
    )
  }, [accountId, allParamsValid, dispatch, email, hash, search, userId])

  useEffect(() => {
    if (!isVerifyLinkedAccountApiFailed) {
      return
    }
    setErrorMessageId('unable_to_link')
  }, [isVerifyLinkedAccountApiFailed])

  const onCloseErrorDialog = useCallback(() => {
    logout(true)
  }, [logout])

  const onCloseInfoDialog = () => routesConfig.LOGIN.goto()

  return useMemo(
    () => [
      {
        isVerifyLinkedAccountApiPending,
        isVerifyLinkedAccountApiFailed,
        isVerifyLinkedAccountApiSuccess,
        userId,
        email,
        errorMessageId,
        allParamsValid
      },
      {
        onCloseErrorDialog,
        onCloseInfoDialog
      }
    ],
    [
      allParamsValid,
      onCloseErrorDialog,
      email,
      errorMessageId,
      isVerifyLinkedAccountApiFailed,
      isVerifyLinkedAccountApiPending,
      isVerifyLinkedAccountApiSuccess,
      userId
    ]
  )
}
