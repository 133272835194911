import { useMemo, useState, useEffect, useCallback } from 'react'

import { useLocation, useParams } from 'react-router-dom'
import { useFormatMessage } from 'lib/localization'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isSuccess } from 'redux/toolkit/api'
import {
  getAccountSettings,
  getDomainSettings,
  setHasPageChanges,
  updateAccountSettings,
  updateDomainSettings,
  reset
} from 'redux/features/settings/settingsSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { AvailableSettings, SettingValue, SettingsObject } from 'types/Settings'
import { BULK_EDIT_CONFIG } from './config'

export interface State {
  title: string
  rawData: string
}

export interface EventHandlers {
  onCancelConfirm: () => void
  onSave: () => void
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void
}

export type UseAdminBulkEditLogic = [State, EventHandlers]

const BASE_I18N_KEY = 'ess.settings.bulk_edit'

export const useAdminBulkEditLogic = (): UseAdminBulkEditLogic => {
  const { domainId } = useParams()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings,
    hasPageChanges
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    hasPageChanges: _stores.settings.hasPageChanges
  }))

  const [rawData, setRawData] = useState('')
  const [initialRawData, setInitialRawData] = useState('')

  const currentDomainId = useMemo(() => domainId || accessTokenObject?.pdDomainId, [accessTokenObject, domainId])
  const config = useMemo(() => {
    const path = domainId ? pathname.replace(domainId, ':domainId') : pathname
    return BULK_EDIT_CONFIG()[path]
  }, [pathname, domainId])

  // init
  useEffect(() => {
    if (currentDomainId) {
      dispatch(getDomainSettings({ domainId: currentDomainId, settings: config.settings }))
    } else {
      dispatch(getAccountSettings(config.settings))
    }
    // eslint-disable-next-line
  }, [])

  // unmount
  useEffect(
    () => () => {
      dispatch(reset())
    },
    [dispatch]
  )

  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      config.backUrl.goto()
    }
  }, [isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess, config])

  useEffect(() => {
    const settings = currentDomainId ? domainSettings : accountSettings
    let settingName = config.settings[0]
    if (config.dependingSetting) {
      settingName = config.dependingOutcome(settings[config.dependingSetting] as SettingValue)
    }

    // Forbid accessing the bulk edit page which is not used according to the INBOUND_TLS_ALL setting value
    if (config.settings.includes(AvailableSettings.INBOUND_TLS_ALL) && settings.inbound_tls_all !== null) {
      if (
        settings.inbound_tls_all === SettingValue.ENABLED &&
        config.settings.includes(AvailableSettings.INBOUND_TLS_DOMAINS)
      ) {
        config.backUrl.goto()
        return
      }
      if (
        settings.inbound_tls_all !== SettingValue.ENABLED &&
        config.settings.includes(AvailableSettings.INBOUND_TLS_DOMAIN_EXEMPTIONS)
      ) {
        config.backUrl.goto()
        return
      }
    }

    const data = settings[settingName]
    try {
      setRawData(config.dataToCsv(data as string, formatMessage, accessTokenObject?.isCplAccount))
      setInitialRawData(config.dataToCsv(data as string, formatMessage, accessTokenObject?.isCplAccount))
    } catch (error) {
      dispatch(
        setErrorSnackBar({
          message: 'error_parsing_settings'
        })
      )
    }
  }, [dispatch, formatMessage, setRawData, config, accessTokenObject, domainSettings, accountSettings, currentDomainId])

  const updateSettings = useCallback(
    (settings: SettingsObject) => {
      if (currentDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: currentDomainId,
            settings
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings }))
      }
    },
    [dispatch, currentDomainId]
  )

  const onCancelConfirm = useCallback(() => {
    if (hasPageChanges) {
      setRawData(initialRawData)
      dispatch(setHasPageChanges(false))
    } else {
      config.backUrl.goto()
    }
  }, [dispatch, config, hasPageChanges, initialRawData])

  const onSave = useCallback(() => {
    try {
      const settings = currentDomainId ? domainSettings : accountSettings
      const settingName = config.dependingSetting
        ? config.dependingOutcome(settings[config.dependingSetting] as SettingValue)
        : config.settings[0]
      const data = config.rawDataToArray(rawData, formatMessage, accessTokenObject?.isCplAccount)
      updateSettings({ [settingName]: data })
      dispatch(setHasPageChanges(false))
    } catch (err) {
      dispatch(
        setErrorSnackBar({
          message: err.message
        })
      )
    }
  }, [
    formatMessage,
    dispatch,
    updateSettings,
    accessTokenObject,
    config,
    rawData,
    domainSettings,
    accountSettings,
    currentDomainId
  ])

  const onChange = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      setRawData(e.currentTarget.value)
      dispatch(setHasPageChanges(true))
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        title: config.title,
        rawData
      },
      {
        onCancelConfirm,
        onSave,
        onChange
      }
    ],
    [config, rawData, onCancelConfirm, onSave, onChange]
  )
}
