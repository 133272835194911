import { useCallback, useEffect, useState } from 'react'

import { BlockTransition, setBlockTransition } from 'lib/routes'

export type UseBlockTransitionProps = {
  dirtyCheck: boolean
  isDirtyForm: boolean
}

export type TransitionCb = (() => void) | undefined
export type ResetTransitionCb = () => void

export const useBlockTransition = ({
  dirtyCheck,
  isDirtyForm
}: UseBlockTransitionProps): [TransitionCb, ResetTransitionCb] => {
  const [transitionCb, setTransitioCb] = useState<TransitionCb>(undefined)

  // init
  useEffect(() => {
    let unsetBlockTransition: () => void | undefined
    if (dirtyCheck) {
      unsetBlockTransition = setBlockTransition(blockTransition)
    }

    return () => {
      unsetBlockTransition?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirtyForm])

  const blockTransition: BlockTransition = useCallback(
    doTransition => {
      if (!isDirtyForm) {
        doTransition()
      } else {
        setTransitioCb(() => doTransition)
      }
    },
    [isDirtyForm]
  )

  const resetTransitionCb = useCallback(() => {
    setTransitioCb(undefined)
  }, [])

  return [transitionCb, resetTransitionCb]
}
