export enum WizardSteps {
  regionSelection = 'regionSelection',
  serverAndMxSetup = 'serverAndMxSetup'
}

export enum ServerAndMxSetupSteps {
  specifyEmailServer = 'specifyEmailServer',
  addNewMxRecords = 'addNewMxRecords',
  removeOldMxRecords = 'removeOldMxRecords'
}

export type SectionsIsVerifiedStates = {
  [key in ServerAndMxSetupSteps]: boolean
}

export type SectionsProps = {
  isRequestedToCompleteSetup?: boolean
  setSpecifyEmailServerSectionErrorState?: (results: boolean) => void
  setIsAddMxRecordsSectionHasError?: (results: boolean) => void
  setIsRemoveOldMxRecordsSectionHasError?: (results: boolean) => void
  sectionsVerifiedStates: { [key in ServerAndMxSetupSteps]: boolean }
  setSectionToActive: () => void
}
