import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  messageDetailViewContainer: {
    height: `calc(100vh - ${theme.layout.navbarHeight + theme.layout.footerHeight}px)`
  },
  messageDetailViewContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    margin: theme.spacing(2, 0, 2, 3),
    fontSize: '18px'
  }
}))
