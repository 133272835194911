import { useCallback, useMemo } from 'react'
import { GeoPolicy } from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface GeoPoliciesFormState {
  country: string
  policy: string
  comment: string
}

export interface ValidatorDependencies {
  presentPolicies: GeoPolicy[]
}

export interface GeoPoliciesFormConfig {
  initialState: GeoPoliciesFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type GeoPoliciesForm = SettingsForm<GeoPoliciesFormState>

export const useGeoPoliciesForm = ({ initialState, delegateIsDirtyForm }: GeoPoliciesFormConfig): GeoPoliciesForm => {
  const countryField = useStateField(initialState.country)
  const policyField = useStateField(initialState.policy)
  const commentField = useRefField(initialState.comment)

  const formConfig = useMemo(
    () => ({
      fields: {
        country: countryField,
        policy: policyField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [commentField, countryField, delegateIsDirtyForm, policyField]
  )
  const form = useSettingsForm<GeoPoliciesFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: GeoPolicy, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.presentPolicies.find(entry => entry.country === newPolicy.country)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }
      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
