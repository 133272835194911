import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'

import config from 'config/appConfig'
import { getStartDate } from 'lib/mstore'
import { AdvancedSearchForm } from 'types/Messages'
import { createAdvancedForm, createBasicSearchTerm } from 'lib/searchForm'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getSearch,
  MlogViewConfig,
  setSearchEndDate,
  setSearchStartDate,
  setSearchString,
  setDateRange
} from 'redux/features/mstore/mstoreSlice'
import { setWarningSnackBar } from 'redux/features/app/appSlice'
import { reset as resetMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'

export interface UseAdvancedSearchLogic {
  formData: AdvancedSearchForm
  handleFormChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleClose: () => void
  setRevertOnCancel: Dispatch<SetStateAction<boolean>>
  handleSearch: () => void
  datePickerConfig: {
    min: Date
    max: Date
    format: string
    onChange: (event: DateTimePickerChangeEvent) => void
  }
  start: Date
  end: Date
  isSaveSearchOpen: boolean
  setIsSaveSearchOpen: Dispatch<SetStateAction<boolean>>
  onClickAway: (event: React.MouseEvent<EventTarget>) => void
  isOpenSearchOpen: boolean
  setIsOpenSearchOpen: Dispatch<SetStateAction<boolean>>
  viewConfig: MlogViewConfig
}

const initAdvancedSearchForm: AdvancedSearchForm = {
  subject: '',
  header_from: '',
  envelope_from: '',
  header_to: '',
  envelope_to: '',
  attachment: '',
  basic_search_term: ''
}

export interface AdvancedSearchProps {
  onClose: () => void
}

export const useAdvancedSearchLogic = (props: AdvancedSearchProps): UseAdvancedSearchLogic => {
  const dispatch = useAppDispatch()

  const { searchTerm, viewConfig } = useAppSelector(_store => ({
    searchTerm: _store.mstore.searchTerm,
    viewConfig: _store.mstore.viewConfig
  }))

  const [formData, setFormData] = useState(initAdvancedSearchForm)
  const [originalFormData, setOriginalFormData] = useState(initAdvancedSearchForm)
  const [isSaveSearchOpen, setIsSaveSearchOpen] = useState(false)
  const [isOpenSearchOpen, setIsOpenSearchOpen] = useState(false)
  /*
              This field is used if we need to revert to the original search string when going out of the
              advanced search form. So if we do not save the form and just click cancel or exit the form
              we should end up with the search string that we had before opening the advanced search form  
          */
  const [revertOnCancel, setRevertOnCancel] = useState(true)

  const updateForm = useCallback(() => {
    const advancedForm = createAdvancedForm(initAdvancedSearchForm, searchTerm.search_str)
    setFormData(advancedForm)
    setOriginalFormData(advancedForm)
  }, [searchTerm])

  // INIT create advanced form fields from the search string and update form on close dialog for opening search terms
  useEffect(() => {
    updateForm()
    // eslint-disable-next-line
  }, [isOpenSearchOpen])

  /*
              Update the search string on all changes in the advanced form fields
              this way we have the search string always ready with all the changes needed
              so we can just dispatch the search action when doing the search
          */
  useEffect(() => {
    const advancedFormSearchString = createBasicSearchTerm(formData)
    if (advancedFormSearchString) {
      dispatch(setSearchString(`${advancedFormSearchString} ${formData.basic_search_term}`))
    } else {
      dispatch(setSearchString(formData.basic_search_term))
    }
  }, [dispatch, formData])

  const handleFormChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target
      setFormData({ ...formData, [name]: value })
    },
    [formData]
  )

  const changeDate = useCallback(
    (event: DateTimePickerChangeEvent) => {
      const { name, value } = event.target

      const startLimit = getStartDate(30) * 1000
      const endLimit = Date.now()
      // Note: DateTimePicker min={} and max={} are not being checked
      // when datetime is changed using the keyboard, so we need to check it here
      if (!value || value.getTime() < startLimit || value.getTime() > endLimit) {
        return
      }
      const roundedTimestamp = Math.round(value.getTime() / 1000)
      if (name === 'start') {
        dispatch(setSearchStartDate(roundedTimestamp))
      } else {
        dispatch(setSearchEndDate(roundedTimestamp))
      }
      dispatch(setDateRange({ days: undefined }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (searchTerm.start >= searchTerm.end) {
      dispatch(
        setWarningSnackBar({
          message: 'bad_date_range'
        })
      )
    }
  }, [dispatch, searchTerm])

  const handleClose = useCallback(() => {
    if (revertOnCancel) {
      setFormData(originalFormData)
      const advancedFormSearchString = createBasicSearchTerm(formData)
      dispatch(setSearchString(advancedFormSearchString))
    }
    props.onClose()
  }, [dispatch, formData, originalFormData, props, revertOnCancel])

  const onClickAway = useCallback(
    (event: React.MouseEvent<EventTarget>) => {
      const target = event.target as HTMLElement
      // target.closest is used to select html elements outside of advanced search that we want to be clickable
      // w/o closing the advanced search dropdown. We control the .keep-open class but not the .k-animation-container class.
      // It's automatically set by kendo, we'll need to keep an eye on it if kendo ever changes the name.
      const keepAdvancedSearchOpen = target
        ? target.closest('.keep-open') || target.closest('.k-animation-container')
        : null
      if (!event || (event.target && !keepAdvancedSearchOpen)) {
        props.onClose()
        return
      }

      if (!keepAdvancedSearchOpen) {
        props.onClose()
      }
    },
    [props]
  )

  const handleSearch = useCallback(() => {
    dispatch(getSearch())
    props.onClose()
  }, [dispatch, props])

  return useMemo(
    () => ({
      formData,
      handleFormChange,
      handleClose,
      setRevertOnCancel,
      handleSearch,
      datePickerConfig: {
        min: new Date(getStartDate(30) * 1000),
        max: new Date(Date.now()),
        format: config.DATETIME.DATE_TIME_PICKER_FORMAT,
        onChange: changeDate
      },
      start: new Date(searchTerm.start * 1000),
      end: new Date(searchTerm.end * 1000),
      isSaveSearchOpen,
      setIsSaveSearchOpen,
      onClickAway,
      isOpenSearchOpen,
      setIsOpenSearchOpen,
      viewConfig
    }),
    [
      formData,
      handleFormChange,
      handleClose,
      setRevertOnCancel,
      handleSearch,
      changeDate,
      searchTerm,
      isSaveSearchOpen,
      setIsSaveSearchOpen,
      onClickAway,
      isOpenSearchOpen,
      setIsOpenSearchOpen,
      viewConfig
    ]
  )
}
