import { UiRoute } from 'lib/routesConfig'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { UserRole } from 'config/userRole'
import { HelpComponentProps } from 'types/Help'

export interface SupportTopicPermission {
  minimumRole?: UserRole
  cpl?: boolean
}
export interface SupportTopic extends SupportTopicPermission {
  label: TOPICS
  href: UiRoute
  content: (props: HelpComponentProps) => JSX.Element
  title: TOPICS
  groupTitle: GROUP_TITLE
}

export interface SupportTopicConfig extends SupportTopicPermission {
  title: GROUP_TITLE | null
  topics: SupportTopic[]
}

export interface TopicContent {
  content: (props: HelpComponentProps) => JSX.Element
  title: TOPICS
  groupTitle: GROUP_TITLE
}

export enum QueryType {
  GETBACKUP = 'getBackup',
  DORESTORE = 'doRestore'
}
