import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import apiRoutes from 'lib/api/apiRoutes'

import { SyslogParsed } from 'types/AccountManagement'

export interface UpdateAccountSyslogPayload {
  hostname: string
  port: string
}

export const getAccountSyslog = createAsyncThunk<SyslogParsed, undefined, ApiRejectResponse>(
  'SYSLOG/getAccountSyslog',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_SYSLOG)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
export const updateAccountSyslog = createAsyncThunk<undefined, UpdateAccountSyslogPayload, ApiRejectResponse>(
  'SYSLOG/updateAccountSyslog',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.UPDATE_SYSLOG, {
        data: {
          host: payload.hostname,
          port: payload.port
        }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'post_syslog_success'
        })
      )
      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'post_syslog_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const testAccountSyslog = createAsyncThunk<SyslogParsed, undefined, ApiRejectResponse>(
  'SYSLOG/testAccountSyslog',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.TEST_SYSLOG)
      dispatch(
        setSuccessSnackBar({
          message: 'test_syslog_success'
        })
      )
      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'test_syslog_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteAccountSyslog = createAsyncThunk<undefined, undefined, ApiRejectResponse>(
  'SYSLOG/deleteAccountSyslog',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_SYSLOG)
      dispatch(
        setSuccessSnackBar({
          message: 'delete_syslog_success'
        })
      )
      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'delete_syslog_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)
