import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(0.7),
    marginTop: theme.spacing(0.7)
  },
  label: {
    width: 300,
    height: 21,
    lineHeight: '21px'
  },
  cloudscanScoringWrapper: {
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 0 2px 0 rgba(0,0,0,.5) inset',
    padding: theme.spacing(1),
    backgroundColor: colors.scaleGray050,
    maxWidth: 400
  },
  cloudscanScoringTable: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  sliderCell: {
    width: 150
  },
  inputCell: {
    width: 30,
    padding: 0,
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none'
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none'
    },
    textAlign: 'center'
  },
  table: {
    flex: 1
  },
  nameCell: {
    width: '25%'
  },
  actionCell: {
    width: '20%'
  },
  descriptionCell: {
    width: '60%'
  },
  dropDownList: {
    height: 23,
    minWidth: 120,
    fontSize: 13
  },
  smallRadioButton: {
    padding: theme.spacing(0.5),
    '& svg': {
      fontSize: 13
    }
  },
  smallRadioLabel: {
    marginLeft: -theme.spacing(0.5)
  }
}))
