/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Grid, AppBar, Snackbar, Alert } from '@barracuda-internal/bds-core'

import Footer from 'components/libs/layout/footer/Footer'
import { useResetPasswordLogic, CardType } from 'components/pages/actions/resetPassword/useResetPasswordLogic'
import ResetPasswordCardUI from 'components/pages/actions/resetPassword/views/ResetPasswordCard'
import CheckEmailCardUI from 'components/pages/actions/resetPassword/views/CheckEmailCard'
import SuccessfulResetCardUI from 'components/pages/actions/resetPassword/views/SuccessfulResetCard'
import ExpiredLinkCardUI from 'components/pages/actions/resetPassword/views/ExpiredLinkCard'
import styles from './resetPasswordStyles'

const ResetPassword: React.FC = () => {
  const intl = useIntl()
  const classes = styles()
  const resetLogic = useResetPasswordLogic()
  const [state, eventHandlers] = resetLogic

  const text = useCallback(
    (id: string) => {
      if (!id) {
        return ''
      }
      return intl.formatMessage({ id: `ess.reset_password.${id}` })
    },
    [intl]
  )

  const ResetPasswordCard = useMemo(() => <ResetPasswordCardUI logic={resetLogic} />, [resetLogic])

  const CheckEmailCard = useMemo(() => <CheckEmailCardUI logic={resetLogic} />, [resetLogic])

  const SuccessfulResetCard = useMemo(() => <SuccessfulResetCardUI logic={resetLogic} />, [resetLogic])

  const LinkExpiredCard = useMemo(() => <ExpiredLinkCardUI logic={resetLogic} />, [resetLogic])

  const CardMap: Record<CardType, ReactNode> = useMemo(
    () => ({
      ResetPassword: ResetPasswordCard,
      LinkExpired: LinkExpiredCard,
      CheckEmail: CheckEmailCard,
      SuccessfulReset: SuccessfulResetCard
    }),
    [ResetPasswordCard, LinkExpiredCard, CheckEmailCard, SuccessfulResetCard]
  )

  return useMemo(
    () => (
      <AppBar position="fixed" elevation={1} className={classes.login}>
        <Snackbar
          className={classes.snackBar}
          open={state.snackbarMessageId !== ''}
          transitionDuration={0}
          autoHideDuration={6000}
          onClose={eventHandlers.onCloseSnackBar}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          TransitionProps={{
            appear: false
          }}
          data-testid="reset-password-snackbar"
        >
          <Alert severity={state.snackbarSeverity} onClose={eventHandlers.onCloseSnackBar}>
            {text(state.snackbarMessageId)}
          </Alert>
        </Snackbar>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className={classes.container}>{CardMap[state.cardType]}</div>
          </Grid>
        </Grid>
        <Footer />
      </AppBar>
    ),
    [
      CardMap,
      classes.container,
      classes.login,
      classes.snackBar,
      eventHandlers.onCloseSnackBar,
      state.cardType,
      state.snackbarMessageId,
      state.snackbarSeverity,
      text
    ]
  )
}

export default ResetPassword
