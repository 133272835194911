import { createSlice, isAnyOf, isFulfilled, PayloadAction } from '@reduxjs/toolkit'

import { Params } from 'react-router-dom'
import { getPublicAppSettings, postMixpanelEvent } from 'redux/features/app/appApiThunks'
import { SplitterOrientation } from 'components/libs/splitter/splitterTypes'
import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import { getLanguageCookie, setLanguageCookie } from 'lib/cookies'
import { Language } from 'types/Language'
import { validateSessionId } from 'redux/features/auth/authApiThunks'
import { AppTypes } from 'types/AppTypes'
import appConfig from 'config/appConfig'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import { PublicAppSettings } from 'types/Settings'
import { getSenderPolicies } from '../settings/settingsApiThunks'
import { getMessage, getSearch } from '../mstore/mstoreSlice'
import { getAtpSearch } from '../atp/atpApiThunks'

export interface ActivePath {
  id?: string
  url: string
  legacyPath: string
  isNavbarVisible: boolean
  params: Readonly<Params<string>>
  urlParams: string
  isPublicRoute: boolean
  metadata: Record<string, unknown>
}

export enum SnackbarSeveriy {
  none = '',
  error = 'error',
  warning = 'warning',
  success = 'success'
}

export interface Snackbar {
  severity: SnackbarSeveriy
  open: boolean
  autoHideDuration: number
  message: string
  params: any[]
}

export interface Splitter {
  orientation: SplitterOrientation
}

export interface AppState {
  api: {
    getPublicAppSettingsApiStatus: ApiStatus
  }
  appType: AppTypes
  activePath: ActivePath
  snackBar: Snackbar
  splitter: Splitter
  timezone: string
  language: string
  isBlocked: boolean
  publicAppSettings: PublicAppSettings | undefined
}

const INITIAL_APP_TYPES = {
  [appConfig.APP_NAMES.ADMIN]: AppTypes.admin,
  [appConfig.APP_NAMES.ENDUSER]: AppTypes.enduser,
  [appConfig.APP_NAMES.WIZARD]: AppTypes.wizard,
  [appConfig.APP_NAMES.CUDASPT]: AppTypes.cudaspt
} as { [key: string]: AppTypes }

// initialState
export const INITIAL_STATE: AppState = {
  api: {
    getPublicAppSettingsApiStatus: inIdle
  },
  appType: INITIAL_APP_TYPES[appConfig.APP.NAME],
  activePath: {
    // using explicit type check so the jest node environment can be used
    // without a window mock for testing the slice
    url: typeof window === 'undefined' ? '' : window.location.pathname,
    legacyPath: '',
    isNavbarVisible: false,
    params: {},
    urlParams: '',
    metadata: {},
    isPublicRoute: true
  },
  snackBar: {
    severity: SnackbarSeveriy.none,
    open: false,
    autoHideDuration: 6000,
    message: '',
    params: []
  },
  splitter: {
    orientation:
      (localStorage.getItem(LocalStorageKeys.splitterOrientation) as SplitterOrientation) ||
      SplitterOrientation.vertical
  },
  timezone: '',
  language: getLanguageCookie() || Language.english,
  isBlocked: false,
  publicAppSettings: undefined
}

export type SetActivePathPayload = ActivePath

export interface SetSnackbarPayload {
  message: Snackbar['message']
  severity?: Snackbar['severity']
  open?: Snackbar['open']
  autoHideDuration?: Snackbar['autoHideDuration']
  params?: Snackbar['params']
}

/* eslint-disable no-param-reassign */
export const appSlice = createSlice({
  name: 'APP',
  initialState: INITIAL_STATE,
  reducers: {
    setAppType: (state: AppState, action: PayloadAction<AppTypes>) => {
      state.appType = action.payload
    },
    setActivePath: (state: AppState, action: PayloadAction<SetActivePathPayload>) => {
      state.activePath = {
        ...INITIAL_STATE.activePath,
        ...action.payload
      }
    },
    setSuccessSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.success,
        open: true,
        ...action.payload
      }
    },
    setWarningSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.warning,
        open: true,
        ...action.payload
      }
    },
    setErrorSnackBar: (state: AppState, action: PayloadAction<SetSnackbarPayload>) => {
      state.snackBar = {
        ...INITIAL_STATE.snackBar,
        severity: SnackbarSeveriy.error,
        open: true,
        ...action.payload
      }
    },
    closeSnackBar: state => {
      state.snackBar = { ...INITIAL_STATE.snackBar }
    },
    setSplitterOrientation: (state: AppState, action: PayloadAction<SplitterOrientation>) => {
      state.splitter = {
        ...INITIAL_STATE.splitter,
        orientation: action.payload
      }
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
      setLanguageCookie(action.payload)
    },
    setAppBlockedState: (state, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // getPublicAppSettings
      .addCase(getPublicAppSettings.pending, state => {
        state.api.getPublicAppSettingsApiStatus = inProgress
      })
      .addCase(getPublicAppSettings.fulfilled, (state, action) => {
        state.api.getPublicAppSettingsApiStatus = successResponse
        state.publicAppSettings = action.payload
      })
      .addCase(getPublicAppSettings.rejected, (state, action) => {
        state.api.getPublicAppSettingsApiStatus = failedResponse(action.payload)
      })

      .addMatcher(
        isAnyOf(getSenderPolicies.fulfilled, getMessage.fulfilled, getSearch.fulfilled, getAtpSearch.fulfilled),
        (state, action) => {
          state.timezone = action.payload.metadata.userTimezone
        }
      )
      .addMatcher(isFulfilled(validateSessionId), (state, action) => {
        state.timezone =
          action.payload?.accessTokenObject?.userInfo?.timezone ||
          action.payload?.accessTokenObject?.timezone ||
          state.timezone
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  setAppType,
  setActivePath,
  setSuccessSnackBar,
  setWarningSnackBar,
  setErrorSnackBar,
  closeSnackBar,
  setSplitterOrientation,
  setLanguage,
  setAppBlockedState,
  reset
} = appSlice.actions

export { postMixpanelEvent, getPublicAppSettings }

export default appSlice.reducer
