import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { createAuthenticationProcess, resetCreateAuthenticationProcess } from 'redux/features/oauth2/oauth2Slice'
import { OAuth2Connection } from 'types/oauth2'
import { LoginViews } from 'components/pages/login/loginPageTypes'
import { useFormatMessage } from 'lib/localization'
import appConfig from 'config/appConfig'
import routesConfig from 'lib/routesConfig'

export enum ConnectionCheckStatus {
  DISABLED,
  IDLE,
  PENDING,
  DONE
}

export interface State {
  status: ConnectionCheckStatus
}

export interface EventHandlers {
  createAuthProcess: (preferredUserId: string) => void
  startOAuth2AuthorizationCodeFlow: () => void
  resetCreateAuthProcess: () => void
}

export type AvailableConnections = [State, EventHandlers]

export interface AvailableConnectionsConfig {
  setCardType: (view: LoginViews) => void
  setTopErrorMessage: (error: string) => void
  setIsTemporaryPasscodeEnabled: (isEnabled: boolean) => void
}

const BASE_I18N_KEY = 'ess.oauth2'

export const useAvailableConnections = (config: AvailableConnectionsConfig): AvailableConnections => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()
  const [isResponseHandled, setIsResponseHandled] = useState(false)
  const {
    isEnhancedAuthEnabled,
    isCreateAuthenticationProcessSuccess,
    isCreateAuthenticationProcessFailed,
    isCreateAuthenticationProcessPending,
    createAuthenticationProcessResponse
  } = useAppSelector(_store => ({
    isEnhancedAuthEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled,
    isCreateAuthenticationProcessPending: isPending(_store.oauth2.api.createAuthenticationProcessApiStatus),
    isCreateAuthenticationProcessSuccess: isSuccess(_store.oauth2.api.createAuthenticationProcessApiStatus),
    isCreateAuthenticationProcessFailed: isFailed(_store.oauth2.api.createAuthenticationProcessApiStatus),
    createAuthenticationProcessResponse: _store.oauth2.createAuthenticationProcessResponse
  }))

  const status = useMemo(() => {
    switch (true) {
      case !isEnhancedAuthEnabled:
        return ConnectionCheckStatus.DISABLED
      case isResponseHandled:
      case isCreateAuthenticationProcessFailed:
        return ConnectionCheckStatus.DONE
      case isCreateAuthenticationProcessPending:
        return ConnectionCheckStatus.PENDING
      default:
        return ConnectionCheckStatus.IDLE
    }
  }, [
    isCreateAuthenticationProcessFailed,
    isCreateAuthenticationProcessPending,
    isEnhancedAuthEnabled,
    isResponseHandled
  ])

  const startOAuth2AuthorizationCodeFlow = useCallback(() => {
    if (!createAuthenticationProcessResponse) {
      config.setTopErrorMessage(formatMessage('auth_code_flow.start_failed'))
      setIsResponseHandled(true)
      return
    }
    const { id } = createAuthenticationProcessResponse
    window.location.href = `${appConfig.GATEWAY_URL}/internal/oauth2/authorize?auth_process_id=${id}&redirect_uri=${window.location.origin}/webui/oauth2/callback&connection=${OAuth2Connection.MS_ENTRA}`
  }, [config, createAuthenticationProcessResponse, formatMessage])

  const handleCreateAuthenticationProcessResponse = useCallback(() => {
    if (!createAuthenticationProcessResponse) {
      config.setTopErrorMessage(formatMessage('process.start_failed'))
      setIsResponseHandled(true)
      return
    }
    const { availableConnections, id } = createAuthenticationProcessResponse
    const isTdfLogin = availableConnections.includes(OAuth2Connection.TDF)
    const isTempPasscodeLoginAllowed = availableConnections.includes(OAuth2Connection.TEMP_PASSCODE)
    config.setIsTemporaryPasscodeEnabled(isTempPasscodeLoginAllowed)
    const isAzureLogin = availableConnections.includes(OAuth2Connection.MS_ENTRA)
    switch (true) {
      case isTdfLogin:
        if (appConfig.ENVIRONMENT.IS_GT3) {
          window.location.href = `https://ess.gt3.barracudanetworks.com/user/auth/login?auth_process_id=${id}`
        } else {
          window.location.href = `${appConfig.LEGACY_UI_URL}/user/auth/login?auth_process_id=${id}`
        }
        break
      case isTempPasscodeLoginAllowed && isAzureLogin:
        config.setCardType(LoginViews.AzureTemporaryPasscode)
        setIsResponseHandled(true)
        break
      case isAzureLogin:
        startOAuth2AuthorizationCodeFlow()
        break
      default:
        config.setCardType(LoginViews.Password)
        setIsResponseHandled(true)
    }
  }, [config, createAuthenticationProcessResponse, formatMessage, startOAuth2AuthorizationCodeFlow])

  const resetCreateAuthProcess = useCallback(() => {
    setIsResponseHandled(false)
    dispatch(resetCreateAuthenticationProcess())
  }, [dispatch])

  const createAuthProcess = useCallback(
    (preferredUserId: string) => {
      resetCreateAuthProcess()
      config.setTopErrorMessage('')
      const authProcessId = makeUuid()
      dispatch(
        createAuthenticationProcess({
          preferredUserId,
          authProcessId,
          queryString: window.location.search
        })
      )
    },
    [config, dispatch, resetCreateAuthProcess]
  )

  useEffect(
    () => () => {
      dispatch(resetCreateAuthenticationProcess())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!isCreateAuthenticationProcessSuccess) {
      return
    }
    handleCreateAuthenticationProcessResponse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAuthenticationProcessSuccess])

  useEffect(() => {
    if (!isCreateAuthenticationProcessFailed) {
      return
    }
    routesConfig.LOGIN.goto(undefined, false, '?error=oauth2.process.create_session_failed')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAuthenticationProcessSuccess])

  return useMemo(
    () => [{ status }, { createAuthProcess, resetCreateAuthProcess, startOAuth2AuthorizationCodeFlow }],
    [createAuthProcess, resetCreateAuthProcess, status, startOAuth2AuthorizationCodeFlow]
  )
}
