import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  OVERVIEW: {
    path: 'overview',
    legacyPath: 'status',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_DASHBOARD: {
    path: 'overview/dashboard',
    legacyPath: 'status',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_MESSAGE_LOG: {
    path: 'overview/message-log',
    legacyPath: 'log',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_ATP_LOG: {
    path: 'overview/atp-log',
    legacyPath: 'atdlog',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_OUTBOUND_QUARANTINE: {
    path: 'overview/outbound-quarantine',
    legacyPath: 'quarantine',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_AUDIT_LOG: {
    path: 'overview/audit-log',
    legacyPath: 'auditlog',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  },
  OVERVIEW_LICENSE_COMPLIANCE: {
    path: 'overview/license',
    // There is no path for TDF license
    legacyPath: '',
    appAccess: [AppNames.admin],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OVERVIEW_DASHBOARD'
    }
  }
}
