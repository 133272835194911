import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import {
  EventHandlers as SearchLogicEventHandlers,
  State as SearchLogicState,
  useSearchLogic
} from 'components/pages/support/useSearchLogic'
import { SupportTopicConfig, QueryType } from 'types/Support'
import { AvailableSettings, SettingValue } from 'types/Settings'
import { getAccountSettings, updateAccountSettings } from 'redux/features/settings/settingsSlice'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { GraphQLRequestPayload, getBackupGraphql } from 'redux/features/graphql/graphqlApiThunks'
import { useSupportRights } from 'components/libs/userRights/pages/useSupportRights'

export interface State {
  searchResults: SearchLogicState['searchResults']
  searchQuery: string
  topics: SupportTopicConfig[]
  canAccessSupport: boolean
  canAccessPartner: boolean
  canAccessConfiguration: boolean
  supportAccessStatus: string
  messageAccessStatus: string
  showSupportAccessDialog: boolean
  showMessageAccessDialog: boolean
  showAccountConfigurationDialog: boolean
  updateInProgress: boolean
  saveInProgress: boolean
  tokenValue: string
  applyFlag: boolean
  selectedSettings: string
  saveConfig: boolean
  isAccountConfigDialogDirty: boolean
}

export interface EventHandlers {
  onLinkClick: SearchLogicEventHandlers['onLinkClick']
  onSubmitSearch: () => void
  onReset: SearchLogicEventHandlers['onReset']
  onSearchQueryChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSupportAccessStatusChange: () => void
  onMessageAccessStatusChange: () => void
  onSaveClick: () => void
  applyToken: () => void
  onClose: () => void
  setIsAccountConfigDialogDirty: Dispatch<SetStateAction<boolean>>
}

export type SupportLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.SUPPORT_ACCESS_ENABLED, AvailableSettings.MESSAGE_BODY_ACCESS_ENABLED]

export interface UseSupportLogicProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export const useSupportLogic = (): SupportLogic => {
  const dispatch = useAppDispatch()

  const {
    isUpdateAccountSettingsSuccess,
    isUpdateAccountSettingsProgress,
    accountSettings,
    getBackupSuccess,
    getBackupProgress,
    graphQLResponse
  } = useAppSelector(_stores => ({
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateAccountSettingsProgress: isPending(_stores.settings.updateAccountSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    getBackupSuccess: isSuccess(_stores.graphql.api.getBackupGraphqlApiStatus),
    getBackupProgress: isPending(_stores.graphql.api.getBackupGraphqlApiStatus),
    graphQLResponse: _stores.graphql.graphQLResponse
  }))

  const { canAccessSupport, canAccessPartner, canAccessConfiguration, accessibleTopics } = useSupportRights()

  const [searchQuery, setSearchQuery] = useState('')
  const [{ searchResults }, { onSearch, onReset, onLinkClick }] = useSearchLogic()
  const [showSupportAccessDialog, setShowSupportAccessDialog] = useState(false)
  const [showMessageAccessDialog, setShowMessageAccessDialog] = useState(false)
  const [showAccountConfigurationDialog, setShowAccountConfigurationDialog] = useState(false)
  const [applyFlag, setApplyFlag] = useState(false)
  const [tokenValue, setTokenValue] = useState('')
  const [selectedSettings, setSelectedSettings] = useState('')
  const [saveConfig, setSaveConfig] = useState(false)
  const [isAccountConfigDialogDirty, setIsAccountConfigDialogDirty] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getAccountSettings(SETTINGS_LIST))
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SUPPORT_PAGE_VIEW)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isUpdateAccountSettingsSuccess) {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
  }, [dispatch, isUpdateAccountSettingsSuccess])

  useEffect(() => {
    if (getBackupSuccess && saveConfig) {
      setSaveConfig(false)
      const newTokenValue = graphQLResponse?.token || ''
      setTokenValue(newTokenValue)
      setShowAccountConfigurationDialog(true)
    }
  }, [getBackupSuccess, graphQLResponse, setTokenValue, saveConfig])

  const onSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const updateSettings = useCallback(
    (accessStatus: string, settingName: string) => {
      dispatch(
        updateAccountSettings({
          settings: { [settingName]: accessStatus }
        })
      )
    },
    [dispatch]
  )

  const onAccessStatusChange = useCallback(
    (accessStatus: string, setShowDialog: (value: boolean) => void, updateSettingType: string) => {
      setShowDialog(accessStatus === SettingValue.DISABLED)
      if (accessStatus === SettingValue.ENABLED) {
        updateSettings(SettingValue.DISABLED, updateSettingType)
      }
    },
    [updateSettings]
  )

  const supportAccessStatus: string = useMemo(
    () => String(accountSettings.support_access_enabled || SettingValue.ENABLED),
    [accountSettings]
  )

  // Usage for support access
  const onSupportAccessStatusChange = useCallback(() => {
    setSelectedSettings('support_access')
    onAccessStatusChange(
      supportAccessStatus,
      value => setShowSupportAccessDialog(value),
      AvailableSettings.SUPPORT_ACCESS_ENABLED
    )
  }, [onAccessStatusChange, supportAccessStatus, setShowSupportAccessDialog])

  const messageAccessStatus: string = useMemo(
    () => String(accountSettings.message_body_access_enabled || SettingValue.ENABLED),
    [accountSettings]
  )

  // Usage for message body access
  const onMessageAccessStatusChange = useCallback(() => {
    setSelectedSettings('partner_access')
    onAccessStatusChange(
      messageAccessStatus,
      value => setShowMessageAccessDialog(value),
      AvailableSettings.MESSAGE_BODY_ACCESS_ENABLED
    )
  }, [onAccessStatusChange, messageAccessStatus, setShowMessageAccessDialog])

  const onSaveClick = useCallback(() => {
    setSaveConfig(true)
    setApplyFlag(false)
    const getTokenPayload: GraphQLRequestPayload = {
      queryType: QueryType.GETBACKUP
    }
    dispatch(getBackupGraphql(getTokenPayload))
  }, [dispatch])

  const applyToken = useCallback(() => {
    setSaveConfig(false)
    setApplyFlag(true)
    setShowAccountConfigurationDialog(true)
  }, [])

  const onSubmitSearch = useCallback(() => onSearch(searchQuery), [onSearch, searchQuery])

  const onClose = useCallback(() => {
    setShowSupportAccessDialog(false)
    setShowMessageAccessDialog(false)
    setShowAccountConfigurationDialog(false)
  }, [])

  return useMemo(
    () => [
      {
        searchResults,
        searchQuery,
        topics: accessibleTopics,
        canAccessSupport,
        canAccessPartner,
        canAccessConfiguration,
        showSupportAccessDialog,
        showMessageAccessDialog,
        showAccountConfigurationDialog,
        supportAccessStatus,
        messageAccessStatus,
        updateInProgress: isUpdateAccountSettingsProgress,
        saveInProgress: getBackupProgress,
        tokenValue,
        applyFlag,
        selectedSettings,
        saveConfig,
        isAccountConfigDialogDirty
      },
      {
        onLinkClick,
        onSearchQueryChange,
        onReset,
        onSubmitSearch,
        onSupportAccessStatusChange,
        onMessageAccessStatusChange,
        onSaveClick,
        applyToken,
        onClose,
        setIsAccountConfigDialogDirty
      }
    ],
    [
      onLinkClick,
      onReset,
      onSubmitSearch,
      onSupportAccessStatusChange,
      onMessageAccessStatusChange,
      onSaveClick,
      applyToken,
      onClose,
      setIsAccountConfigDialogDirty,
      searchQuery,
      searchResults,
      showSupportAccessDialog,
      showMessageAccessDialog,
      showAccountConfigurationDialog,
      isUpdateAccountSettingsProgress,
      getBackupProgress,
      supportAccessStatus,
      messageAccessStatus,
      tokenValue,
      applyFlag,
      selectedSettings,
      saveConfig,
      isAccountConfigDialogDirty,
      canAccessSupport,
      canAccessPartner,
      canAccessConfiguration,
      accessibleTopics
    ]
  )
}
