import { SearchSettings, SearchSettingsRepository } from 'lib/services/searchSettings/searchSettingsRepository'

export const SearchSettingsService = (repository: SearchSettingsRepository) => {
  const getAll = async (): Promise<SearchSettings[]> => repository.getAll()
  const add = async (item: SearchSettings): Promise<void> => repository.add(item)
  const deleteFn = async (searchId: string): Promise<void> => repository.delete(searchId)

  return Object.freeze({
    getAll,
    add,
    delete: deleteFn
  })
}
