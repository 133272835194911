import * as React from 'react'
import type { Blocker, Transition } from 'history'
import { history } from '../browserHistory'

export function useBlocker(blocker: Blocker, when = true): void {
  React.useEffect(() => {
    if (!when) return undefined

    const unblock = history.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        }
      }
      blocker(autoUnblockingTx)
    })

    return unblock
  }, [blocker, when])
}
