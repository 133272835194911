import { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { useAppSelector } from 'redux/toolkit/hooks'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'

export enum OverviewTabs {
  dashboard = 'dashboard',
  messageLog = 'message_log',
  atpLog = 'atp_log',
  outboundQuarantine = 'outbound_quarantine',
  auditLog = 'audit_log'
}

const TABS: [OverviewTabs, UiRoute][] = [
  [OverviewTabs.dashboard, routesConfig.OVERVIEW_DASHBOARD],
  [OverviewTabs.messageLog, routesConfig.OVERVIEW_MESSAGE_LOG],
  [OverviewTabs.atpLog, routesConfig.OVERVIEW_ATP_LOG],
  [OverviewTabs.outboundQuarantine, routesConfig.OVERVIEW_OUTBOUND_QUARANTINE],
  [OverviewTabs.auditLog, routesConfig.OVERVIEW_AUDIT_LOG]
]

const BASE_I18N_KEY = 'ess.overview.tabs'

export const useOverviewSubmenuItemsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const { appType } = useAppSelector(_stores => ({
    appType: _stores.app.appType
  }))

  const overviewListItems: SubMenuItem[] = useMemo(() => {
    function filterTabs(tab: [OverviewTabs, UiRoute]) {
      const [, route] = tab

      return !!route?.hasAppTypeAccess(appType)
    }

    return TABS.filter(filterTabs).map(tab => {
      const [tabId, routeConfig] = tab
      return {
        name: formatMessage(tabId),
        path: routeConfig.path
      }
    })
  }, [formatMessage, appType])

  return useMemo(() => [overviewListItems], [overviewListItems])
}
