import { useCallback, useMemo, useRef } from 'react'

export type DirtyFormValues = {
  isDirty: boolean
  changedSettings: Record<string, any>
}

export type UseDirtyFormObjectCheckProps = Record<string, any>

export type ResetInitialForm = (nextFormValues: UseDirtyFormObjectCheckProps) => void

export type DirtyFormCheck = [
  DirtyFormValues,
  ResetInitialForm,
  (values: UseDirtyFormObjectCheckProps) => DirtyFormValues
]

export const useDirtyFormObjectCheck = (formValues: UseDirtyFormObjectCheckProps): DirtyFormCheck => {
  const initialFormData = useRef(formValues)

  const resetInitialForm = useCallback((nextFormValues: UseDirtyFormObjectCheckProps) => {
    initialFormData.current = nextFormValues
  }, [])

  const getChangedSettings = useCallback(
    (values?: UseDirtyFormObjectCheckProps): Record<string, any> => {
      const changedSettings: Record<string, any> = {}
      const currentForm = values || formValues

      Object.keys(currentForm).forEach(key => {
        const currentValue = currentForm[key]
        const initialValue = initialFormData.current[key]

        if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
          changedSettings[key] = currentValue
        }
      })

      return changedSettings
    },
    [formValues]
  )

  const isDirtyValues = useMemo(() => {
    const changedSettings = getChangedSettings()
    return {
      isDirty: Object.keys(changedSettings).length > 0,
      changedSettings
    }
  }, [getChangedSettings])

  const getIsDirtyValues = useCallback(
    (values: UseDirtyFormObjectCheckProps): DirtyFormValues => {
      const changedSettings = getChangedSettings(values)
      return {
        isDirty: Object.keys(changedSettings).length > 0,
        changedSettings
      }
    },
    [getChangedSettings]
  )

  return [isDirtyValues, resetInitialForm, getIsDirtyValues]
}
