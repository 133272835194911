import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import PageLoader from 'components/libs/pageLoader/PageLoader'
import { useSettingsPageLogic } from 'components/libs/settingsPage/useSettingsPageLogic'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import BlockNavigationDialog from 'components/libs/blockNavigationDialog/BlockNavigationDialog'
import DomainConflictDialog from 'components/libs/domainConflictDialog/DomainConflictDialog'
import SettingsHeader from 'components/libs/settingsPage/SettingsHeader'
import { SettingsPageProps } from 'types/SettingsPage'

function SettingsPage({ title, help, actions, warningDialog, children }: SettingsPageProps) {
  const [state] = useSettingsPageLogic()

  return useMemo(
    () => (
      <Grid container direction="column" data-testid="settings-page">
        <SettingsHeader title={title} help={help} actions={actions} />
        <PageLoader isLoading={state.isLoading} isFailed={state.isFailed} isSuccess={state.isSuccess}>
          {children}
        </PageLoader>
        {warningDialog?.open && (
          <WarningDialog
            title={warningDialog.title}
            description={warningDialog.description}
            onCancel={warningDialog.onCancel}
            onConfirm={warningDialog.onConfirm}
          />
        )}
        <BlockNavigationDialog hasChanges={state.hasPageChanges} />
        <DomainConflictDialog />
      </Grid>
    ),
    [state, title, help, actions, warningDialog, children]
  )
}

export default SettingsPage
