import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'

import { DataResult, orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query'
import { GridDataStateChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useFormatMessage } from 'lib/localization'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isEmailValid } from 'lib/validation'
import { getLinkedAccounts, postLinkedAccount, deleteLinkedAccount } from 'redux/features/settings/settingsSlice'
import { trackMixpanelEvent, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { isPending } from 'redux/toolkit/api'
import { LinkedAccount } from 'types/Settings'

interface GridState {
  dataState: State
  dataResult: DataResult
}

interface LinkedAccountsForm {
  email: string
}

export interface ModifiedLinkedAccount extends LinkedAccount {
  inEdit: boolean
}

export interface UseLinkedAccountsLogic {
  linkedAccountsAreLoading: boolean
  sort: SortDescriptor[]
  handleSortChange: (e: GridSortChangeEvent) => void
  sortedData: ModifiedLinkedAccount[]
  dataStateChange: (e: GridDataStateChangeEvent) => void
  gridState: GridState
  isConfirmDialogOpen: boolean
  closeConfirmDialog: () => void
  onDoUnlink: () => void
  accountLinkedMessage: string
  successfulDialog: boolean
  closeSuccessDialog: () => void
  defaultFormValues: React.MutableRefObject<LinkedAccountsForm>
  emailInput: React.MutableRefObject<HTMLInputElement | null>
  onLink: () => void
  onUnlink: (dataItem: LinkedAccount) => void
  linkedAccountEmail: string
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  actionButton: React.MutableRefObject<{ setIsDisabled: (value: boolean) => void } | null>
}

const BASE_I18N_KEY = 'ess.settings.linked_accounts'

const initialSort = [{ field: 'email', dir: 'asc' } as SortDescriptor]

const dataState: State = {
  skip: 0,
  take: 10
}

export const useLinkedAccountsLogic = (): UseLinkedAccountsLogic => {
  const defaultFormValues = useRef<LinkedAccountsForm>({
    email: ''
  })
  const emailInput = useRef<HTMLInputElement | null>(null)
  const actionButton = useRef<{ setIsDisabled: (value: boolean) => void } | null>(null)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()
  const {
    linkedAccounts,
    linkedAccountsAreLoading,
    linkedAccountIsPosting,
    linkedAccountAdded,
    linkedAccountIsDeleting
  } = useAppSelector(_store => ({
    linkedAccounts: _store.settings.linkedAccounts,
    linkedAccountsAreLoading: isPending(_store.settings.getLinkedAccountsApiStatus),
    linkedAccountIsPosting: isPending(_store.settings.postLinkedAccountApiStatus),
    linkedAccountAdded: _store.settings.linkedAccountAdded,
    linkedAccountIsDeleting: isPending(_store.settings.deleteLinkedAccountApiStatus)
  }))
  const [sort, setSort] = useState(initialSort)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [successfulDialog, setSuccessfulDialog] = useState(false)
  const [dataItem, setDataItem] = useState<LinkedAccount>(Object())
  const [accountLinkedMessage, setAccountLinkedMessage] = useState('')

  const getFormValues = useCallback(
    () => ({
      email: emailInput.current?.value
    }),
    []
  )

  const resetForm = useCallback(() => {
    if (emailInput.current) {
      emailInput.current.value = ''
      defaultFormValues.current.email = ''
    }
  }, [])

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SETTINGS_LINKED_ACCOUNTS_PAGE_VIEW)
    dispatch(getLinkedAccounts())
    setAccountLinkedMessage('')
    // eslint-disable-next-line
  }, [])

  const gridData = useMemo(() => linkedAccounts, [linkedAccounts])

  const [gridState, setGridState] = useState({
    dataState,
    dataResult: process(gridData || [], dataState)
  })

  const dataStateChange = useCallback(
    (e: GridDataStateChangeEvent) => {
      setGridState({
        dataState: e.dataState,
        dataResult: process(gridData || [], e.dataState)
      })
    },
    [gridData]
  )

  const sortedData = useMemo(() => {
    const data = orderBy([...gridState.dataResult.data], sort) as ModifiedLinkedAccount[]

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift({ ...defaultFormValues.current, inEdit: true })
    return data
  }, [gridState.dataResult.data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (actionButton.current) {
      const { value } = e.target
      defaultFormValues.current.email = value
      actionButton.current.setIsDisabled(!isEmailValid(value))
    }
  }, [])

  const onLink = useCallback(() => {
    const { email } = getFormValues()
    if (email) dispatch(postLinkedAccount({ email }))
  }, [dispatch, getFormValues])

  useEffect(() => {
    if (!linkedAccountIsPosting) {
      setGridState({
        ...gridState,
        dataResult: process(linkedAccounts || [], gridState.dataState)
      })
      resetForm()
    }
    // eslint-disable-next-line
  }, [linkedAccountIsPosting, linkedAccounts])

  useEffect(() => {
    if (!linkedAccountIsPosting && linkedAccountAdded?.email) {
      const message = formatMessage('verification_message', { email: linkedAccountAdded.email })
      setAccountLinkedMessage(message)
    }
  }, [linkedAccountIsPosting, linkedAccountAdded, formatMessage])

  useEffect(() => {
    if (!linkedAccountIsDeleting) {
      setGridState({
        ...gridState,
        dataResult: process(linkedAccounts || [], gridState.dataState)
      })
    }
    // eslint-disable-next-line
  }, [linkedAccountIsDeleting, linkedAccounts])

  const onUnlink = useCallback((linkedAccount: LinkedAccount) => {
    setDataItem(linkedAccount)
    setIsConfirmDialogOpen(true)
  }, [])

  const closeConfirmDialog = useCallback(() => setIsConfirmDialogOpen(false), [])

  const onDoUnlink = useCallback(() => {
    dispatch(deleteLinkedAccount(dataItem))
  }, [dispatch, dataItem])

  const closeSuccessDialog = useCallback(() => {
    setSuccessfulDialog(false)
    setAccountLinkedMessage('')
  }, [])

  useEffect(() => {
    if (accountLinkedMessage.length) {
      setSuccessfulDialog(true)
    }
  }, [accountLinkedMessage.length])

  return useMemo(
    () => ({
      linkedAccountsAreLoading,
      sort,
      handleSortChange,
      sortedData,
      dataStateChange,
      gridState,
      isConfirmDialogOpen,
      closeConfirmDialog,
      onDoUnlink,
      accountLinkedMessage,
      successfulDialog,
      closeSuccessDialog,
      defaultFormValues,
      emailInput,
      onLink,
      onUnlink,
      linkedAccountEmail: dataItem.email,
      onEmailChange,
      actionButton
    }),
    [
      linkedAccountsAreLoading,
      sort,
      handleSortChange,
      sortedData,
      dataStateChange,
      gridState,
      isConfirmDialogOpen,
      closeConfirmDialog,
      onDoUnlink,
      accountLinkedMessage,
      successfulDialog,
      closeSuccessDialog,
      emailInput,
      onLink,
      onUnlink,
      dataItem,
      onEmailChange,
      actionButton
    ]
  )
}
