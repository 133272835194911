import { useMemo } from 'react'

import { ErrorDialogProps } from 'components/libs/dialog/errorDialog/ErrorDialog'

export interface ErrorDialogLogicConfig {
  onClose: ErrorDialogProps['onClose']
}

export type ErrorDialogLogic = [
  {
    isDialogOpened: boolean
    onHandleClose: () => void
  }
]

export const useErrorDialogLogic = ({ onClose }: ErrorDialogLogicConfig): ErrorDialogLogic =>
  useMemo(
    () => [
      {
        isDialogOpened: true,
        onHandleClose: onClose
      }
    ],
    [onClose]
  )
