import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DataTableState, SortDirection, UpdateDataTable } from 'types/redux/dataTables/dataTables'
import reducerHandlers from 'redux/features/dataTables/reduceHandlers'

export const GRID_COLUMNS = {
  DOMAIN_NAME: 'domain_name',
  STATUS: 'status',
  ALIASES: 'aliases',
  RECIPIENTS: 'recipients',
  MAIL_SERVERS: 'mail_servers',
  SETTINGS: 'settings',
  DOMAIN_OPTIONS: 'domain_options'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DOMAIN_NAME]: {
    sortable: true,
    orderIndex: 0,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.STATUS]: {
    sortable: false,
    orderIndex: 1,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.ALIASES]: {
    sortable: false,
    orderIndex: 2,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.RECIPIENTS]: {
    sortable: false,
    orderIndex: 3,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.MAIL_SERVERS]: {
    sortable: false,
    orderIndex: 4,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.SETTINGS]: {
    sortable: false,
    orderIndex: 5,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.DOMAIN_OPTIONS]: {
    sortable: false,
    orderIndex: 6,
    show: true,
    resizable: true
  }
}

export const ITEMS_PER_PAGE = 10

// initialState
export const INITIAL_STATE: DataTableState = {
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0,
  take: ITEMS_PER_PAGE,
  search: '',
  filter: null,
  sort: [{ field: GRID_COLUMNS.DOMAIN_NAME, dir: SortDirection.asc }]
}

/* eslint-disable no-param-reassign */
export const domainsSlice = createSlice({
  name: 'DATATABLE/DOMAINS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) =>
        reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload }),
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  }
})
/* eslint-enable no-param-reassign */

export const { update, reset } = domainsSlice.actions

export default domainsSlice.reducer
