import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { UserRole } from 'config/userRole'
import useUserRights from 'components/libs/userRights/useUserRights'

export interface UserRightHelpers {
  pdDomainName?: string | null
}

export interface ValidatedRights {
  helpers: UserRightHelpers
  canAddUpdateUsers: boolean
  canUpdateEmailContinuitySettings: boolean
  canEditAccountUser: boolean
  canResetAccountUserPassword: boolean
  canLoginAsAnAccountUser: boolean
  canDeleteAccountUser: boolean
}

export type UseUserRights = ValidatedRights

export enum UserRights {
  CAN_ADD_UPDATE_USERS = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER,
  CAN_UPDATE_EMAIL_CONTINUITY_SETTINGS = UserRole.ACCOUNT_USER,
  CAN_EDIT_ACCOUNT_USER = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER,
  CAN_RESET_ACCOUNT_USER_PASSWORD = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER + UserRole.HELPDESK_ROLE,
  CAN_LOG_IN_AS_AN_ACCOUNT_USER = UserRole.ACCOUNT_USER,
  CAN_DELETE_ACCOUNT_USER = UserRole.ACCOUNT_USER + UserRole.DOMAIN_USER
}

export const useUsersRights = (): UseUserRights => {
  const { pdDomainName } = useAppSelector(_stores => ({
    pdDomainName: _stores.auth.accessTokenObject?.pdDomainName
  }))
  const { validatedUserRights, isPdDomainIdSet } = useUserRights([
    UserRights.CAN_ADD_UPDATE_USERS,
    UserRights.CAN_UPDATE_EMAIL_CONTINUITY_SETTINGS,
    UserRights.CAN_EDIT_ACCOUNT_USER,
    UserRights.CAN_RESET_ACCOUNT_USER_PASSWORD,
    UserRights.CAN_LOG_IN_AS_AN_ACCOUNT_USER,
    UserRights.CAN_DELETE_ACCOUNT_USER
  ])
  const [
    canAddUpdateUsersType,
    canUpdateEmailContinuitySettingsType,
    canEditAccountUserType,
    canResetAccountUserPasswordType,
    canLoginAsAnAccountUserType,
    canDeleteAccountUserType
  ] = validatedUserRights

  const canUpdateEmailContinuitySettings = useMemo(
    () => canUpdateEmailContinuitySettingsType && !isPdDomainIdSet,
    [isPdDomainIdSet, canUpdateEmailContinuitySettingsType]
  )

  return useMemo(
    () => ({
      helpers: {
        pdDomainName
      },
      canAddUpdateUsers: canAddUpdateUsersType,
      canUpdateEmailContinuitySettings,
      canEditAccountUser: canEditAccountUserType,
      canResetAccountUserPassword: canResetAccountUserPasswordType,
      canLoginAsAnAccountUser: canLoginAsAnAccountUserType,
      canDeleteAccountUser: canDeleteAccountUserType
    }),
    [
      pdDomainName,
      canAddUpdateUsersType,
      canUpdateEmailContinuitySettings,
      canEditAccountUserType,
      canResetAccountUserPasswordType,
      canLoginAsAnAccountUserType,
      canDeleteAccountUserType
    ]
  )
}
