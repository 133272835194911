import { createAction } from '@reduxjs/toolkit'

export enum MlogViewType {
  MESSAGE_LOG = 'message-log',
  OUTBOUND_QUARANTINE = 'outbound-quarantine'
}

export const setDownloadAttachmentProgress = createAction<{ total: number; loaded: number }>(
  'MSTORE/setDownloadAttachmentProgress'
)

export const setDownloadMessageProgress = createAction<{ total: number; loaded: number }>(
  'MSTORE/setDownloadMessageProgress'
)

export const removeListItems = createAction<string[]>('MSTORE/removeListItems')

export const resetSelectedMessage = createAction<void>('MSTORE/resetSelectedMessage')
