import React, { PropsWithChildren, useMemo } from 'react'

import { FormHelperText } from '@barracuda-internal/bds-core'

import styles from 'components/libs/layout/pages/pagesLayoutStyles'

export interface HelperTextProps extends PropsWithChildren {
  error?: boolean
}

export const HelperText = ({ children, error }: HelperTextProps) => {
  const classes = styles()

  return useMemo(
    () => (
      <FormHelperText error={error} className={classes.helperText}>
        {children}
      </FormHelperText>
    ),
    [error, classes, children]
  )
}
