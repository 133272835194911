import React from 'react'

import styles from './cellStyles'

interface Props {
  children: any
  className?: string
  title?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onDoubleClick?: () => void
}

function Cell({ children, className, title, onClick, onDoubleClick, ...rest }: Props) {
  const classes = styles()

  return (
    <td
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      role="presentation"
      title={title}
      className={`${classes.cell} ${className}`}
      {...rest}
    >
      {children}
    </td>
  )
}

export default Cell
