import { useMemo } from 'react'
import { makePostAuthCommandFactory, makePostAuthCommandHandler } from 'lib/postAuthCommands/postAuthCommands'
import routesConfig from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

export const usePostAuthCommands = () => {
  const dispatch = useAppDispatch()
  const { userDomainId, currentUserId } = useAppSelector(_store => ({
    currentUserId: _store.auth.accessTokenObject?.userId || '',
    userDomainId: _store.auth.accessTokenObject?.domainId || 0
  }))
  const commandFactory = useMemo(() => makePostAuthCommandFactory(currentUserId), [currentUserId])
  const commandHandler = useMemo(
    () => makePostAuthCommandHandler(routesConfig, dispatch, userDomainId),
    [dispatch, userDomainId]
  )

  return useMemo(
    () => ({
      commandFactory,
      commandHandler
    }),
    [commandFactory, commandHandler]
  )
}
