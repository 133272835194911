import { makeStyles } from '@barracuda-internal/bds-core'

const confirmDialogStyles = makeStyles(theme => ({
  scheduledMatrixContainer: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  helperTextContainer: {
    marginTop: theme.spacing(2)
  }
}))

export default confirmDialogStyles
