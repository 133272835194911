import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { v4 as makeUuid } from 'uuid'
import { process, SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { SenderIpEntry, SenderIpEntryExtended } from 'types/SenderIp'
import { addOutboundIp, removeOutboundIp } from 'redux/features/outboundIp/outboundIpApiThunks'
import { sortIpAddress, sortText } from 'lib/sort'
import routesConfig from 'lib/routesConfig'
import {
  SenderIpForm,
  SenderIpFormState,
  useSenderIpForm
} from 'components/pages/outboundSettings/senderIp/useSenderIpForm'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { FormErrors } from 'components/pages/outboundSettings/senderIp/useSenderIpValidation'

const initialSort = [{ field: 'domainName', dir: 'asc' } as SortDescriptor]

export const enum INPUT_NAMES {
  DOMAIN_NAME = 'domainName',
  IP = 'ip',
  COMMENT = 'comment'
}

export const DEFAULT_NETMASK = '255.255.255.255'
const INITIAL_FORM_STATE = { domainName: '', ip: '', netmask: DEFAULT_NETMASK, comment: '' }
const BLANK_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export interface SenderIpTableLogicConfig {
  delegateIsDirtyForm: (isDirty: boolean) => void
}

export interface State {
  idToRemove: string
  sort: SortDescriptor[]
  form: SenderIpForm
  formState: SenderIpFormState
  errors: FormErrors
  tableData: {
    data: SenderIpEntryExtended[]
    total: number
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onConfirmRemove: () => void
  onConfirmCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSortChange: (e: GridSortChangeEvent) => void
  onBulkEdit: () => void
}

export type SenderIpTableLogic = [State, EventHandlers]

export const useSenderIpTableLogic = ({ delegateIsDirtyForm }: SenderIpTableLogicConfig): SenderIpTableLogic => {
  const dispatch = useAppDispatch()
  const { pdDomainId, outboundIps } = useAppSelector(_stores => ({
    pdDomainId: _stores.auth.accessTokenObject?.pdDomainId,
    outboundIps: _stores.outboundIp.outboundIps
  }))
  const form = useSenderIpForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm
  })
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort)
  const [idToRemove, setIdToRemove] = useState('')

  const onAddItem = useCallback(
    (item: SenderIpEntry) => {
      if (pdDomainId) {
        dispatch(addOutboundIp({ pdDomainId, item }))
      } else {
        dispatch(addOutboundIp({ item }))
      }
    },
    [pdDomainId, dispatch]
  )

  const onAdd = useCallback(() => {
    const newIp: SenderIpEntry = {
      ...form.getState(),
      id: makeUuid()
    }
    if (form.validate(newIp)) {
      onAddItem(newIp)
    }
  }, [form, onAddItem])

  const onRemoveItem = useCallback(
    (id: string) => {
      if (pdDomainId) {
        dispatch(removeOutboundIp({ pdDomainId, id }))
      } else {
        dispatch(removeOutboundIp({ id }))
      }
    },
    [dispatch, pdDomainId]
  )

  const onRemove = useCallback(
    (id: string) => {
      setIdToRemove(id)
    },
    [setIdToRemove]
  )

  const onConfirmRemove = useCallback(() => {
    onRemoveItem(idToRemove)
    setIdToRemove('')
  }, [idToRemove, onRemoveItem, setIdToRemove])

  const onConfirmCancel = useCallback(() => {
    setIdToRemove('')
  }, [setIdToRemove])

  const tableData = useMemo(() => {
    const entries = outboundIps || []
    const orderedData = [...entries].sort((a, b) => {
      switch (sort[0].field) {
        case INPUT_NAMES.DOMAIN_NAME:
          return sortText(a.domainName, b.domainName, sort[0].dir !== 'asc')
        case INPUT_NAMES.IP:
          return sortIpAddress(a.ip, b.ip, sort[0].dir !== 'asc')
        case INPUT_NAMES.COMMENT:
          return sortText(a.comment || '', b.comment || '', sort[0].dir !== 'asc')
        default:
          return 0
      }
    })

    const { data } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift(BLANK_ENTRY)
    return {
      data,
      total: data.length || 0
    }
  }, [outboundIps, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === INPUT_NAMES.DOMAIN_NAME:
          form.fields.domainName.setValue(value)
          break
        case name === INPUT_NAMES.IP:
          form.fields.ip.setValue(value)
          break
        case name === INPUT_NAMES.COMMENT:
          form.fields.comment.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.OUTBOUND_SETTINGS_SENDER_IP_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        idToRemove,
        sort,
        form,
        formState: form.getState(),
        tableData,
        errors: form.errors
      },
      {
        onAdd,
        onRemove,
        onConfirmRemove,
        onConfirmCancel,
        handleSortChange,
        handleOnInputChange,
        onBulkEdit
      }
    ],
    [
      form,
      handleOnInputChange,
      handleSortChange,
      idToRemove,
      onAdd,
      onBulkEdit,
      onConfirmCancel,
      onConfirmRemove,
      onRemove,
      sort,
      tableData
    ]
  )
}
