interface PresetDateItem {
  value: number
  label: string
}

export const presetDateItems: PresetDateItem[] = [
  {
    value: 1,
    label: 'one_day'
  },
  {
    value: 2,
    label: 'two_days'
  },
  {
    value: 7,
    label: 'one_week'
  },
  {
    value: 14,
    label: 'two_weeks'
  },
  {
    value: 30,
    label: 'one_month'
  },
  {
    value: 0,
    label: 'custom'
  }
]
