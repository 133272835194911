import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isPending } from 'redux/toolkit/api'
import { updateAccountSettings } from 'redux/features/settings/settingsSlice'
import { AvailableSettings, SettingValue } from 'types/Settings'

export type State = {
  updateInProgress: boolean
}

export type EventHandlers = {
  onConfirm: () => void
  onCancel: () => void
}

export type UseMessageAccessConfirmDialogLogicProps = {
  onCloseDialog: () => void
}
export type UseMessageAccessConfirmDialogLogic = [State, EventHandlers]

export const useMessageAccessConfirmDialog = ({
  onCloseDialog
}: UseMessageAccessConfirmDialogLogicProps): UseMessageAccessConfirmDialogLogic => {
  const { isUpdateSettingInProgress } = useAppSelector(_store => ({
    isUpdateSettingInProgress: isPending(_store.settings.updateAccountSettingsApiStatus)
  }))
  const dispatch = useAppDispatch()

  const updateSettings = useCallback(() => {
    dispatch(
      updateAccountSettings({
        settings: { [AvailableSettings.MESSAGE_BODY_ACCESS_ENABLED]: SettingValue.ENABLED }
      })
    )
    onCloseDialog()
  }, [dispatch, onCloseDialog])

  const onConfirm = useCallback(() => {
    updateSettings()
  }, [updateSettings])

  const onCancel = useCallback(() => {
    onCloseDialog()
  }, [onCloseDialog])

  return useMemo(
    () => [
      {
        updateInProgress: isUpdateSettingInProgress
      },
      {
        onConfirm,
        onCancel
      }
    ],
    [isUpdateSettingInProgress, onConfirm, onCancel]
  )
}
