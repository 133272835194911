import React, { useMemo } from 'react'

import { TextField, TextFieldProps } from '@barracuda-internal/bds-core'
import { useIpTextFieldLogic } from 'components/libs/IpTextField/useIpTextFieldLogic'

const IpTextField = (props: TextFieldProps) => {
  const { onInput } = useIpTextFieldLogic()
  return useMemo(() => <TextField {...props} onInput={onInput} />, [onInput, props])
}

export default IpTextField
