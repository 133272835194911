import React, { useState, useMemo, useEffect, useCallback, ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  updateAccountSyslog,
  deleteAccountSyslog,
  testAccountSyslog,
  getAccountSyslog,
  resetAccountSyslog
} from 'redux/features/syslog/syslogSlice'
import { AccountSyslogResults } from 'types/AccountManagement'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'

export interface State {
  inProgress: boolean
  isFailedToLoad: boolean
  hostName: string
  port: string
  hostError?: string
  portError?: string
  accountSyslogs?: AccountSyslogResults
  isDisabled: boolean
  isDirtyForm: boolean
}

export interface EventHandlers {
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onAdd: () => void
  onTest: () => void
  onRemove: () => void
}

export type UseAccountManagementLogic = [State, EventHandlers]

export const useAccountManagementLogic = (): UseAccountManagementLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const [hostName, setHostName] = React.useState('')
  const [port, setPort] = React.useState('')
  const [hostError, setHostError] = useState<string>()
  const [portError, setPortError] = useState<string>()
  const [isDisabled, setIsDisabled] = useState(false)
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([hostName, port])

  const {
    isGetAccountSyslogsListInProgress,
    isFailedToLoad,
    isUpdateAccountSyslogPending,
    isUpdateAccountSyslogSuccess,
    isTestAccountSyslogPending,
    isDeleteAccountSyslogSuccess,
    accountSyslogs
  } = useAppSelector(_stores => ({
    isGetAccountSyslogsListInProgress: isPending(_stores.syslogs.api.getAccountSyslogsApiStatus),
    isFailedToLoad: isFailed(_stores.syslogs.api.getAccountSyslogsApiStatus),
    isUpdateAccountSyslogPending: isPending(_stores.syslogs.api.updateAccountSyslogApiStatus),
    isUpdateAccountSyslogSuccess: isSuccess(_stores.syslogs.api.updateAccountSyslogApiStatus),
    isTestAccountSyslogPending: isPending(_stores.syslogs.api.testAccountSyslogApiStatus),
    isDeleteAccountSyslogSuccess: isSuccess(_stores.syslogs.api.deleteAccountSyslogApiStatus),
    accountSyslogs: _stores.syslogs.accountSyslogs
  }))

  // init
  useEffect(() => {
    // eslint-disable-next-line
    dispatch(getAccountSyslog())
  }, [dispatch])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // update state on add
  useEffect(() => {
    if (isUpdateAccountSyslogSuccess) {
      dispatch(getAccountSyslog())
    }
    setHostError('')
    setPortError('')
    setShouldUpdateInitialForm(true)
  }, [dispatch, isUpdateAccountSyslogSuccess])

  // update state on remove
  useEffect(() => {
    if (isDeleteAccountSyslogSuccess) {
      dispatch(getAccountSyslog())
    }
  }, [dispatch, isDeleteAccountSyslogSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountSyslog())
    },
    [dispatch]
  )

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  useEffect(() => {
    setHostName(accountSyslogs?.host || '')
    setPort(accountSyslogs?.port || '')
    setIsDisabled(!!accountSyslogs)
    setShouldUpdateInitialForm(true)
  }, [accountSyslogs])

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // eslint-disable-next-line default-case
    switch (true) {
      case name === 'hostname':
        setHostName(value)
        break
      case name === 'port':
        setPort(value)
        break
    }
    setHostError('')
    setPortError('')
  }, [])

  const onAdd = useCallback(() => {
    if (!hostName || !port) {
      if (!hostName) {
        setHostError('specify_hostname')
      }
      if (!port) {
        setPortError('specify_port')
      }

      return
    }

    const syslog = {
      hostname: hostName,
      port
    }

    dispatch(updateAccountSyslog(syslog))
  }, [dispatch, hostName, port])

  const onTest = useCallback(() => {
    dispatch(testAccountSyslog())
  }, [dispatch])

  const onRemove = useCallback(() => {
    dispatch(deleteAccountSyslog())
  }, [dispatch])

  return useMemo(
    () => [
      {
        inProgress: isGetAccountSyslogsListInProgress || isUpdateAccountSyslogPending || isTestAccountSyslogPending,
        isFailedToLoad,
        hostName,
        port,
        hostError,
        portError,
        isDisabled,
        isDirtyForm
      },
      {
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        onInputChange,
        onAdd,
        onTest,
        onRemove
      }
    ],
    [
      isGetAccountSyslogsListInProgress,
      isUpdateAccountSyslogPending,
      isTestAccountSyslogPending,
      isFailedToLoad,
      hostName,
      port,
      hostError,
      portError,
      isDisabled,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      onInputChange,
      onAdd,
      onTest,
      onRemove,
      isDirtyForm
    ]
  )
}
