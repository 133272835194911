import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function RecipientList(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The <b>Recipients List</b> displays the unique recipients seen in the last 30 days for the selected domain. Use
        the Search box to search for a specific recipient or set of recipients.
      </p>
      <br />
      <br />
      Select the total number of results to display per page from the
      <b>Results per page</b> drop-down menu, and click the controls to the right of the menu to move through the
      results. To return to the <b>Domains</b> page, click <b>Back to Domains</b>.
    </div>
  )
}

export default RecipientList
