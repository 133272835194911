import { useCallback, useMemo } from 'react'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { CustomRblsParsed } from 'types/CustomRbls'

export interface CustomRblsFormState {
  zone: string
  comment: string
}

export interface ValidatorDependencies {
  data: CustomRblsParsed[]
}

export interface CustomRblsFormConfig {
  initialState: CustomRblsFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type CustomRblsForm = SettingsForm<CustomRblsFormState>

export const useCustomRblsForm = ({ initialState, delegateIsDirtyForm }: CustomRblsFormConfig): CustomRblsForm => {
  const zoneField = useRefField(initialState.zone)
  const commentField = useRefField(initialState.comment)

  const formConfig = useMemo(
    () => ({
      fields: {
        zone: zoneField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, zoneField]
  )
  const form = useSettingsForm<CustomRblsFormState>(formConfig)

  const validate = useCallback(
    (newEntry: CustomRblsParsed, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.data.find(entry => entry.zone === newEntry.zone)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      if (!newEntry.zone) {
        form.setError('specify_domain')
        return false
      }

      const regex = /^[a-z0-9.-]{1,255}$/i
      if (!regex.test(newEntry.zone)) {
        form.setError('invalid_domain')
        return false
      }

      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
