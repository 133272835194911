import { createSlice } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import { getBannerSettings, updateHasMailFlownSetting } from 'redux/features/banners/bannersApiThunks'
import { BannerSettings } from 'types/banners'

interface BannersState {
  api: {
    getBannerSettingsApiStatus: ApiStatus
    updatedHasMailFlownSettingApiStatus: ApiStatus
  }
  bannerSettings: BannerSettings
}

// initialState
export const INITIAL_STATE: BannersState = {
  api: {
    getBannerSettingsApiStatus: inIdle,
    updatedHasMailFlownSettingApiStatus: inIdle
  },
  bannerSettings: {
    hasMailFlown: false,
    wizardComplete: false,
    hasVerifiedDomains: false
  }
}

/* eslint-disable no-param-reassign */
export const bannersSlice = createSlice({
  name: 'BANNERS',
  initialState: INITIAL_STATE,
  reducers: {
    resetBanner: state => {
      state.api.getBannerSettingsApiStatus = INITIAL_STATE.api.getBannerSettingsApiStatus
      state.api.updatedHasMailFlownSettingApiStatus = INITIAL_STATE.api.updatedHasMailFlownSettingApiStatus
      state.bannerSettings = INITIAL_STATE.bannerSettings
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // get bannerSettings
      .addCase(getBannerSettings.pending, state => {
        state.api.getBannerSettingsApiStatus = inProgress
      })
      .addCase(getBannerSettings.fulfilled, (state, action) => {
        state.api.getBannerSettingsApiStatus = successResponse
        state.bannerSettings = action.payload
      })
      .addCase(getBannerSettings.rejected, (state, action) => {
        state.api.getBannerSettingsApiStatus = failedResponse(action.payload)
      })
      // update hasMailFlown
      .addCase(updateHasMailFlownSetting.pending, state => {
        state.api.updatedHasMailFlownSettingApiStatus = inProgress
      })
      .addCase(updateHasMailFlownSetting.fulfilled, state => {
        state.api.updatedHasMailFlownSettingApiStatus = successResponse
      })
      .addCase(updateHasMailFlownSetting.rejected, (state, action) => {
        state.api.updatedHasMailFlownSettingApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetBanner, reset } = bannersSlice.actions

export { getBannerSettings, updateHasMailFlownSetting }

export default bannersSlice.reducer
