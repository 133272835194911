import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { createAuthenticationProcess } from 'redux/features/oauth2/oauth2Slice'
import routesConfig from 'lib/routesConfig'
import appConfig from 'config/appConfig'
import { OAuth2Connection } from 'types/oauth2'

export const useOAuth2AuthorizeLogic = () => {
  const dispatch = useAppDispatch()
  const [params] = useSearchParams()
  const redirect_uri = params.get('redirect_uri') || `${window.location.origin}/webui/oauth2/callback`
  const connection = params.get('connection') || OAuth2Connection.MS_ENTRA

  const { isCreateProcessSuccess, isCreateProcessFailed, authProcess } = useAppSelector(_store => ({
    isCreateProcessSuccess: isSuccess(_store.oauth2.api.createAuthenticationProcessApiStatus),
    isCreateProcessFailed: isFailed(_store.oauth2.api.createAuthenticationProcessApiStatus),
    authProcess: _store.oauth2.createAuthenticationProcessResponse
  }))

  useEffect(() => {
    const authProcessId = makeUuid()
    dispatch(createAuthenticationProcess({ authProcessId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isCreateProcessSuccess && authProcess) {
      window.location.href = `${appConfig.GATEWAY_URL}/internal/oauth2/authorize?auth_process_id=${authProcess.id}&redirect_uri=${redirect_uri}&connection=${connection}`
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateProcessSuccess])

  useEffect(() => {
    if (isCreateProcessFailed) {
      routesConfig.LOGIN.goto(undefined, false, '?error=OAuth2AuthorizationCodeRequestFailed')
    }
  }, [isCreateProcessFailed])
}
