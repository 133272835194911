/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess_cudaspt: {
    login: {
      header: '{region} region',
      admin_login: 'Admin Login',
      enter_creds: 'Enter your LDAP credentials.',
      username: 'Username',
      password: 'Password',
      login: 'Login',
      error_page: {
        title: 'Unable to load application',
        subtitle: 'please try again later.'
      },
      errors: {
        missed_creds: 'Enter both name and password.',
        invalid_creds: 'Invalid username or password',
        insufficient_rights: 'You do not have permission to access this page. Must be part of:'
      }
    }
  }
}
/* eslint-enable quotes */
