import inIframe from 'lib/inIframe'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  navbarHeight: inIframe() ? 0 : 60,
  navbarProductLogoHeight: 40,
  footerHeight: 24,
  sideMenuWidth: 320,
  outlineDivHeight: 48,
  messageLogToolbarHeight: 48,
  messageDetailToolbarHeight: 48,
  splitterHeight: 3,
  logTableTitleHeight: 30,
  spacing: 16
}
