import { useCallback, useMemo } from 'react'
import { RecipientPolicy, RecipientPolicyStatus } from 'types/Settings'
import { isEmailValid } from 'lib/validation'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface RecipientPoliciesFormState {
  policy: RecipientPolicyStatus
  name: string
  comment: string
}

export interface ValidatorDependencies {
  data: RecipientPolicy[]
}

export interface RecipientPoliciesFormConfig {
  initialState: RecipientPoliciesFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type RecipientPoliciesForm = SettingsForm<RecipientPoliciesFormState>

export const useRecipientPoliciesForm = ({
  initialState,
  delegateIsDirtyForm
}: RecipientPoliciesFormConfig): RecipientPoliciesForm => {
  const nameField = useRefField(initialState.name)
  const commentField = useRefField(initialState.comment)
  const policyField = useStateField(initialState.policy)

  const formConfig = useMemo(
    () => ({
      fields: {
        name: nameField,
        comment: commentField,
        policy: policyField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, nameField, policyField]
  )
  const form = useSettingsForm<RecipientPoliciesFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: RecipientPolicy, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.data.find(entry => entry.name === newPolicy.name)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      if (!newPolicy.name || !isEmailValid(newPolicy.name)) {
        form.setError('invalid_email')
        return false
      }
      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
