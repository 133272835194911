import { useCallback, useMemo, useState } from 'react'
import { isValidIpV4Address } from 'lib/validation'
import { useFormatMessage } from 'lib/localization'
import { SaveOutboundIpData, SenderIpEntryExtended } from 'types/SenderIp'

export interface FormErrors {
  newDomainError: string
  newIpError: string
}

export interface Methods {
  validateNewEntry: (newEntry: SaveOutboundIpData) => boolean
  resetErrors: () => void
}

export type UseSenderIpValidation = [FormErrors, Methods]

const BASE_I18N_KEY = 'ess.outbound_settings.sender_ip'

export const useSenderIpValidation = (): UseSenderIpValidation => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [newDomainError, setNewDomainError] = useState<string>('')
  const [newIpError, setNewIpError] = useState<string>('')

  const resetErrors = useCallback(() => {
    setNewDomainError('')
    setNewIpError('')
  }, [])

  const validateNewEntry = useCallback(
    (newEntry: SaveOutboundIpData) => {
      resetErrors()
      let hasError = false

      if (!newEntry.ip || !isValidIpV4Address(newEntry.ip)) {
        setNewIpError(formatMessage('invalid_ip_address'))
        hasError = true
      }

      if (!newEntry.domainName) {
        setNewDomainError(formatMessage('empty_domain'))
        hasError = true
      }

      return !hasError
    },
    [formatMessage, resetErrors]
  )

  return useMemo(
    () => [
      {
        newDomainError,
        newIpError
      },
      { validateNewEntry, resetErrors }
    ],
    [newDomainError, newIpError, resetErrors, validateNewEntry]
  )
}
