import { colors, makeStyles } from '@barracuda-internal/bds-core'

export interface StyleProps {
  isOptionsDisabled: boolean
}

const styles = makeStyles(theme => ({
  authorizationStatusLabel: {
    marginRight: theme.spacing(2)
  },
  autorizedLabel: {
    color: colors.statusSuccess
  },
  tenantStatusError: {
    color: colors.statusCritical
  },
  directoryOptions: {
    opacity: ({ isOptionsDisabled }: StyleProps) => (isOptionsDisabled ? 0.65 : 1),
    pointerEvents: ({ isOptionsDisabled }: StyleProps) => (isOptionsDisabled ? 'none' : 'auto')
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  directoryOptionsControls: {
    display: 'flex'
  },
  syncStats: {
    lineHeight: `${theme.spacing(5)}px`
  },
  syncNow: {
    marginRight: theme.spacing(1)
  },
  testAzureAdSettings: {
    opacity: ({ isOptionsDisabled }: StyleProps) => (isOptionsDisabled ? 0.65 : 1),
    pointerEvents: ({ isOptionsDisabled }: StyleProps) => (isOptionsDisabled ? 'none' : 'auto')
  },
  tetButton: {
    marginTop: theme.spacing(1)
  }
}))

export default styles
