import { colors, makeStyles } from '@barracuda-internal/bds-core'

export type StyleProps =
  | {
      disabled?: boolean
      disablePositioning?: boolean
      indent?: boolean
    }
  | undefined

export default makeStyles(theme => ({
  row: {
    alignItems: ({ disablePositioning }: StyleProps = {}) => (disablePositioning ? '' : 'center'),
    opacity: ({ disabled }: StyleProps = {}) => (disabled ? 0.65 : 1),
    marginBottom: theme.spacing(0.7),
    marginTop: ({ indent }: StyleProps = {}) => (indent ? 0 : theme.spacing(0.7)),
    pointerEvents: ({ disabled }: StyleProps = {}) => (disabled ? 'none' : 'auto')
  },
  label: {
    marginLeft: ({ indent }: StyleProps = {}) => (indent ? theme.spacing(1.5) : 0)
  },
  helperRow: {
    opacity: ({ disabled }: StyleProps = {}) => (disabled ? 0.65 : 1),
    marginBottom: theme.spacing(0.7),
    pointerEvents: ({ disabled }: StyleProps = {}) => (disabled ? 'none' : 'auto')
  },
  danger: {
    color: colors.red600
  },
  info: {
    color: colors.scaleGray500
  },
  textInputRoot: {
    marginTop: -theme.spacing(0.5),
    background: ({ disabled }: StyleProps = {}) => (disabled ? 'transparent' : colors.white),
    '&p': {
      backgroundColor: 'green'
    }
  },
  settingsBlock: {
    opacity: ({ disabled }: StyleProps = {}) => (disabled ? 0.65 : 1),
    pointerEvents: ({ disabled }: StyleProps = {}) => (disabled ? 'none' : 'auto')
  },
  textArea: {
    color: colors.barracudaBlack90,
    formFamily: 'Roboto',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: '1rem',
    fontStyle: 'normal',
    lineHeight: '125%',
    letterSpacing: 'normal',
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
    '&:focus-visible': {
      borderColor: '#067CC1',
      borderWidth: '2px',
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.42,
      color: colors.barracudaBlack90
    }
  }
}))
