import { useCallback, useMemo } from 'react'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { SenderIpEntry } from 'types/SenderIp'
import { useSenderIpValidation, FormErrors } from 'components/pages/outboundSettings/senderIp/useSenderIpValidation'

export interface SenderIpFormState {
  domainName: string
  ip: string
  comment: string
  netmask: string
}

export interface SenderIpFormConfig {
  initialState: SenderIpFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export interface SenderIpForm extends SettingsForm<SenderIpFormState> {
  errors: FormErrors
}

export const useSenderIpForm = ({ initialState, delegateIsDirtyForm }: SenderIpFormConfig): SenderIpForm => {
  const domainNameField = useRefField(initialState.domainName)
  const ipField = useRefField(initialState.ip)
  const netmaskField = useRefField(initialState.netmask)
  const commentField = useRefField(initialState.comment)
  const [errors, { validateNewEntry, resetErrors }] = useSenderIpValidation()

  const formConfig = useMemo(
    () => ({
      fields: {
        domainName: domainNameField,
        ip: ipField,
        netmask: netmaskField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, domainNameField, ipField, netmaskField]
  )
  const form = useSettingsForm<SenderIpFormState>(formConfig)

  const validate = useCallback(
    (newEntry: SenderIpEntry) => {
      resetErrors()
      return validateNewEntry(newEntry)
    },
    [resetErrors, validateNewEntry]
  )

  return useMemo(
    () => ({
      ...form,
      validate,
      errors
    }),
    [form, validate, errors]
  )
}
