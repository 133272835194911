import { colors, makeStyles } from '@barracuda-internal/bds-core'

const PROGRESS_WIDTH = 24
const PROGRESS_HEIGHT = 23

export default makeStyles(theme => ({
  checkboxLabel: {
    marginTop: theme.spacing(3)
  },
  noRecords: {
    marginTop: theme.spacing(1)
  },
  progressIcon: {
    width: PROGRESS_WIDTH,
    height: PROGRESS_HEIGHT
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  statusIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&.clean': {
      color: colors.green300
    },
    '&.infected': {
      color: colors.red500
    },
    '&.suspicious': {
      color: colors.orange300
    },
    '&.error': {
      color: colors.orange400
    }
  },
  statusText: {
    marginLeft: theme.spacing(2)
  },
  linkText: {
    color: colors.blue600
  },
  deliverProgress: {
    marginRight: theme.spacing(1)
  }
}))
