import React, { useMemo } from 'react'

import { Grid, TextField } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import routesConfig from 'lib/routesConfig'
import { useAddUpdateUserLogic } from 'components/pages/users/addUpdateUsers/useAddUpdateUsersLogic'
import { UserUpdateResults } from 'types/users'
import {
  FormRow,
  FormSpacer,
  FormText,
  InlineRadioGroup,
  PageContainer,
  PageTitle
} from 'components/libs/layout/pages/pageLayout'

import styles from 'components/pages/users/addUpdateUsers/addUpdatedUsersStyles'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import UsersEditSupport from 'components/pages/support/help/usersEdit/UsersEdit'

const BASE_I18N_KEY = 'ess.users.add_update_users'

const AddUpdateUsers: React.FC = () => {
  const [state, eventHandlers] = useAddUpdateUserLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: UsersEditSupport,
    title: TOPICS.addUpdateUsers,
    groupTitle: GROUP_TITLE.userTopics
  })

  return useMemo(
    () => (
      <PageContainer dirtyCheck isDirtyForm={state.isDirtyForm}>
        <HelpModal />
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={onOpenSupport}
          onCancel={() => routesConfig.USERS_LIST.goto()}
          onSubmit={eventHandlers.onSaveChanges}
          submitInProgress={state.isAddUpdateInProgress}
          isDisabledSubmit={!state.isDirtyForm}
        />
        <FormText>{formatMessage('information_text')}</FormText>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle={formatMessage('user_accounts')}>
          <TextField
            className={classes.usersField}
            disabled={state.isAddUpdateInProgress}
            multiline
            minRows={4}
            maxRows={4}
            value={state.userAccounts}
            onChange={(e: any) => eventHandlers.setUserAccounts(e.target.value)}
            error={!!state.inputError}
            helperText={state.inputError && formatMessage(state.inputError)}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('enable_user_quarantine')}>
          <InlineRadioGroup
            name="enable_user_quarantine"
            value={state.isEnabledUserQuarantine}
            onChange={eventHandlers.setIsEnabledUserQuarantine}
            disabled={state.isAddUpdateInProgress}
          />
        </FormRow>
        <FormRow rowTitle={formatMessage('notify_new_users')}>
          <InlineRadioGroup
            name="notify_new_users"
            value={state.isNotifyNewUser}
            onChange={eventHandlers.setIsNotifyNewUsers}
            disabled={state.isAddUpdateInProgress}
          />
        </FormRow>

        {state.addUpdateAccountUsersResults && (
          <Grid className={classes.resultsContainer} alignItems="center" container direction="column">
            <Grid container className={classes.resultsTopElem} />
            <Grid container direction="row">
              {/* INFORMATION HEADER */}
              <FormText>
                {formatMessage('results.base_text')}
                {!!(
                  state.addUpdateAccountUsersResults.addedCount + state.addUpdateAccountUsersResults.updatedCount
                ) && (
                  <>
                    &nbsp;
                    {formatMessage('results.quarantine_notification', {
                      enabled: state.addUpdateAccountUsersResults.isEnabledUserQuarantine ? 1 : 0
                    })}
                  </>
                )}
                {!!state.addUpdateAccountUsersResults.addedCount && (
                  <>
                    &nbsp;
                    {formatMessage('results.notified_users')}
                  </>
                )}
              </FormText>
              <FormSpacer half />

              {/* RESULTS */}
              <Grid container direction="column">
                {state.addUpdateAccountUsersResults.addedCount > 10 && (
                  <>
                    <FormText>
                      <data className={classes.result_green}>
                        &bull;&nbsp;
                        {formatMessage('results.bulk_successfully_added', {
                          count: state.addUpdateAccountUsersResults.addedCount
                        })}
                      </data>
                    </FormText>
                    <FormSpacer half />
                  </>
                )}
                {state.addUpdateAccountUsersResults.updatedCount > 10 && (
                  <>
                    <FormText>
                      <data className={classes.result_blue}>
                        &bull;&nbsp;
                        {formatMessage('results.bulk_successfully_updated', {
                          count: state.addUpdateAccountUsersResults.updatedCount
                        })}
                      </data>
                    </FormText>
                    <FormSpacer half />
                  </>
                )}
                {state.addUpdateAccountUsersResults.results.map(resultObject => {
                  const [userId, domain, result, level] = resultObject

                  if (
                    result === UserUpdateResults.successfullyAdded &&
                    (state.addUpdateAccountUsersResults?.addedCount || 0) > 10
                  ) {
                    return null
                  }

                  if (
                    result === UserUpdateResults.successfullyUpdated &&
                    (state.addUpdateAccountUsersResults?.updatedCount || 0) > 10
                  ) {
                    return null
                  }

                  return (
                    <data key={userId}>
                      <FormText>
                        <data className={classes[level]}>
                          &bull;&nbsp;
                          {formatMessage(`results.${result}`, {
                            user_id: userId,
                            domain
                          })}
                        </data>
                      </FormText>
                      <FormSpacer half />
                    </data>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageContainer>
    ),
    [eventHandlers, classes, formatMessage, state, onOpenSupport, HelpModal]
  )
}

export default AddUpdateUsers
