import { useCallback, useMemo, useRef } from 'react'
import { SettingsFormField } from 'lib/settingsForm/useSettingsForm'

export const useRefField = (initialValue: string): SettingsFormField => {
  const input = document.createElement('input')
  input.value = initialValue
  const ref = useRef<HTMLInputElement>(input)

  const setValue = useCallback((value: string) => {
    ref.current.value = value
  }, [])

  const reset = useCallback(() => {
    ref.current.value = initialValue
  }, [initialValue])

  return useMemo(
    () => ({
      getValue: () => ref.current.value,
      setValue,
      reset,
      ref
    }),
    [reset, setValue]
  )
}
