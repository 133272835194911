import { v4 as makeUuid } from 'uuid'
import { isValidIpV4Address } from 'lib/validation'
import { AVAILABLE_IP_EXEMPTION_POLICIES, DomainEntry, IpAddressEntry } from 'types/Settings'
import { parseDataItem } from 'lib/parseData'

export function listToCsvString(data: string, selectorFn: any, header: string): string {
  let parsedData = []
  if (data) {
    parsedData = JSON.parse(data)
  }
  return parsedData.reduce(selectorFn, header)
}

export function dropError(
  formatMessage: (path: string, data?: any) => string,
  localizationPath: string,
  props: { row: number; item?: any }
) {
  const message = formatMessage(localizationPath, props)
  throw Error(`@literal:${message}`)
}

export function combineDropError(
  formatMessage: (path: string, data?: any) => string,
  localizationPath: string[],
  props: { row: number }
) {
  const message = `${formatMessage('error_on_row', props)} (${localizationPath.map(path => `${formatMessage(path)}`)})`
  throw Error(`@literal:${message}`)
}

export function IpAddressDataToArray(
  rawData: string,
  formatMessage: (path: string, data?: any) => string
): IpAddressEntry[] {
  const dataArray: IpAddressEntry[] = []

  // Remove the header line
  const dataList = rawData.split('\n').slice(1)

  dataList.forEach((dataString: string, index: number) => {
    const item = parseDataItem(dataString, 3)
    if (!item) {
      return
    }

    const [address, netmask, policy, baseComment = ''] = item
    const comment = baseComment.substring(0, 100)

    const row = index + 1
    /* eslint-disable no-unused-expressions */
    !address && dropError(formatMessage, 'error_missing_address', { row })
    !isValidIpV4Address(address) && dropError(formatMessage, 'error_invalid_address', { row })

    !netmask && dropError(formatMessage, 'error_missing_netmask', { row })
    !isValidIpV4Address(netmask) && dropError(formatMessage, 'error_invalid_netmask', { row })

    !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
    !AVAILABLE_IP_EXEMPTION_POLICIES.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

    const findDuplicate = dataArray.find(entry => entry.address === address && entry.netmask === netmask)
    findDuplicate &&
      dropError(formatMessage, 'error_duplicate_value', {
        row,
        item: `${findDuplicate.address},${findDuplicate.netmask}`
      })

    dataArray.push({
      id: makeUuid(),
      address,
      netmask,
      comment,
      policy
    })
  })

  return dataArray
}

export function DomainDataToArray(rawData: string, formatMessage: (path: string, data?: any) => string): DomainEntry[] {
  const dataArray: DomainEntry[] = []

  // Remove the header line
  const dataList = rawData.split('\n').slice(1)

  dataList.forEach((dataString: string, index: number) => {
    const item = parseDataItem(dataString, 1)
    if (!item) {
      return
    }

    const [domain, baseComment = ''] = item
    const comment = baseComment.substring(0, 100)

    const row = index + 1
    /* eslint-disable no-unused-expressions */
    !domain && dropError(formatMessage, 'error_missing_domain', { row })

    const regex = /^[a-z0-9.-]{1,255}$/i
    !regex.test(domain) && dropError(formatMessage, 'error_invalid_domain', { row })

    const findDuplicate = dataArray.find(entry => entry.domain === domain)
    findDuplicate &&
      dropError(formatMessage, 'error_duplicate_value', {
        row,
        item: `${findDuplicate.domain}`
      })

    dataArray.push({
      id: makeUuid(),
      domain,
      comment
    })
  })

  return dataArray
}
