/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { ResetPasswordLogic } from 'components/pages/actions/resetPassword/useResetPasswordLogic'

const BASE_I18N_KEY = 'ess.reset_password'

export interface ResetPasswordCardProps {
  logic: ResetPasswordLogic
}

const ResetPasswordCard = ({ logic }: ResetPasswordCardProps) => {
  const [state, eventHandlers] = logic
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={0} className={classes.card} data-testid="reset-password-card">
        {state.isLoading && <LinearProgress data-testid="linear-progress" />}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('title')}</Typography>
            <Typography variant="body1">
              {formatMessage('reset_password_message', { email: <b>{state.userId}</b> })}
            </Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {formatMessage(state.topErrorMessage)}
              </Alert>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormEmailRow}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  name="password1"
                  value={state.newPassword}
                  onChange={eventHandlers.onNewPasswordChange}
                  disabled={state.isLoading}
                  label={formatMessage('password1')}
                  type="password"
                  data-testid="new-password"
                />
              </Grid>
              <Grid item xs={12} className={classes.cardFormEmailRow}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  name="password2"
                  error={!state.isPasswordMatch}
                  value={state.confirmPassword}
                  onChange={eventHandlers.onConfirmPasswordChange}
                  disabled={state.isLoading}
                  label={formatMessage('password2')}
                  type="password"
                  helperText={state.messageId ? formatMessage(state.messageId) : ''}
                  data-testid="confirm-password"
                />
              </Grid>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Button
                  className={classes.cardFormButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={state.isLoading || state.isResetDisabled}
                  onClick={eventHandlers.onReset}
                  data-testid="reset-button"
                >
                  {formatMessage('reset')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardWrapper,
      classes.cardHeader,
      classes.cardLogo,
      classes.topError,
      classes.cardFormEmailRow,
      classes.cardFormActionRow,
      classes.cardFormButton,
      state.isLoading,
      state.userId,
      state.topErrorMessage,
      state.newPassword,
      state.isPasswordMatch,
      state.confirmPassword,
      state.messageId,
      state.isResetDisabled,
      formatMessage,
      eventHandlers.onNewPasswordChange,
      eventHandlers.onConfirmPasswordChange,
      eventHandlers.onReset
    ]
  )
}

export default ResetPasswordCard
/* eslint-enable jsx-a11y/anchor-is-valid */
