import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(() => ({
  dropDownList: {
    minWidth: 120
  },
  policyWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  block: {
    color: colors.red500
  },
  check: {
    color: colors.green300
  }
}))
