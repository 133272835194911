import { ChartColorCodes } from 'components/pages/overview/dashboard/emailStatisticsCharts/useEmailStatisticsChartLogic'
import { Action } from 'types/Messages'

export default {
  data: [
    {
      id: Action.quarantined,
      data: [
        {
          x: '29-01',
          y: 15
        },
        {
          x: '30-01',
          y: 19
        },
        {
          x: '31-01',
          y: 40
        },
        {
          x: '01-02',
          y: 7
        },
        {
          x: '02-02',
          y: 46
        },
        {
          x: '03-02',
          y: 25
        },
        {
          x: '04-02',
          y: 55
        },
        {
          x: '05-02',
          y: 61
        },
        {
          x: '06-02',
          y: 66
        },
        {
          x: '07-02',
          y: 18
        },
        {
          x: '08-02',
          y: 14
        },
        {
          x: '09-02',
          y: 19
        },
        {
          x: '10-02',
          y: 43
        },
        {
          x: '11-02',
          y: 26
        },
        {
          x: '12-02',
          y: 63
        },
        {
          x: '13-02',
          y: 49
        },
        {
          x: '14-02',
          y: 69
        },
        {
          x: '15-02',
          y: 5
        },
        {
          x: '16-02',
          y: 23
        },
        {
          x: '17-02',
          y: 76
        },
        {
          x: '18-02',
          y: 13
        },
        {
          x: '19-02',
          y: 7
        },
        {
          x: '20-02',
          y: 12
        },
        {
          x: '21-02',
          y: 30
        },
        {
          x: '22-02',
          y: 25
        },
        {
          x: '23-02',
          y: 49
        },
        {
          x: '24-02',
          y: 43
        },
        {
          x: '25-02',
          y: 25
        },
        {
          x: '26-02',
          y: 85
        },
        {
          x: '27-02',
          y: 47
        }
      ]
    },
    {
      id: Action.blocked,
      data: [
        {
          x: '29-01',
          y: 12
        },
        {
          x: '30-01',
          y: 17
        },
        {
          x: '31-01',
          y: 25
        },
        {
          x: '01-02',
          y: 84
        },
        {
          x: '02-02',
          y: 54
        },
        {
          x: '03-02',
          y: 90
        },
        {
          x: '04-02',
          y: 12
        },
        {
          x: '05-02',
          y: 30
        },
        {
          x: '06-02',
          y: 45
        },
        {
          x: '07-02',
          y: 45
        },
        {
          x: '08-02',
          y: 86
        },
        {
          x: '09-02',
          y: 37
        },
        {
          x: '10-02',
          y: 55
        },
        {
          x: '11-02',
          y: 30
        },
        {
          x: '12-02',
          y: 51
        },
        {
          x: '13-02',
          y: 37
        },
        {
          x: '14-02',
          y: 32
        },
        {
          x: '15-02',
          y: 10
        },
        {
          x: '16-02',
          y: 58
        },
        {
          x: '17-02',
          y: 63
        },
        {
          x: '18-02',
          y: 94
        },
        {
          x: '19-02',
          y: 15
        },
        {
          x: '20-02',
          y: 82
        },
        {
          x: '21-02',
          y: 13
        },
        {
          x: '22-02',
          y: 24
        },
        {
          x: '23-02',
          y: 52
        },
        {
          x: '24-02',
          y: 55
        },
        {
          x: '25-02',
          y: 10
        },
        {
          x: '26-02',
          y: 99
        },
        {
          x: '27-02',
          y: 47
        }
      ]
    },
    {
      id: 'sent',
      data: [
        {
          x: '29-01',
          y: 48
        },
        {
          x: '30-01',
          y: 87
        },
        {
          x: '31-01',
          y: 55
        },
        {
          x: '01-02',
          y: 44
        },
        {
          x: '02-02',
          y: 97
        },
        {
          x: '03-02',
          y: 78
        },
        {
          x: '04-02',
          y: 25
        },
        {
          x: '05-02',
          y: 55
        },
        {
          x: '06-02',
          y: 68
        },
        {
          x: '07-02',
          y: 39
        },
        {
          x: '08-02',
          y: 40
        },
        {
          x: '09-02',
          y: 10
        },
        {
          x: '10-02',
          y: 74
        },
        {
          x: '11-02',
          y: 28
        },
        {
          x: '12-02',
          y: 84
        },
        {
          x: '13-02',
          y: 15
        },
        {
          x: '14-02',
          y: 15
        },
        {
          x: '15-02',
          y: 26
        },
        {
          x: '16-02',
          y: 4
        },
        {
          x: '17-02',
          y: 25
        },
        {
          x: '18-02',
          y: 59
        },
        {
          x: '19-02',
          y: 39
        },
        {
          x: '20-02',
          y: 68
        },
        {
          x: '21-02',
          y: 37
        },
        {
          x: '22-02',
          y: 71
        },
        {
          x: '23-02',
          y: 21
        },
        {
          x: '24-02',
          y: 6
        },
        {
          x: '25-02',
          y: 21
        },
        {
          x: '26-02',
          y: 21
        },
        {
          x: '27-02',
          y: 96
        }
      ]
    }
  ],
  colors: [ChartColorCodes.allowed, ChartColorCodes.blocked, ChartColorCodes.quarantined],
  axisBottomValuesToShow: [
    '29-01',
    '31-01',
    '02-02',
    '04-02',
    '06-02',
    '08-02',
    '10-02',
    '12-02',
    '14-02',
    '16-02',
    '18-02',
    '20-02',
    '22-02',
    '24-02',
    '26-02'
  ],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLegendClick: () => {},
  disabledLegends: [],
  totals: [
    ['sent', 25],
    ['blocked', 1],
    ['quarantined', 0]
  ] as [string, number][],
  maxValueInRange: 99
}
