import React, { useMemo } from 'react'
import { Grid, LinearProgress } from '@barracuda-internal/bds-core'
import { EmailLogProvider, EmailLog as BdsEmailLog } from '@barracuda-internal/converged-email-log'

import { UiState, useEmailLogLogic } from 'components/pages/emailLog/useEmailLogLogic'
import styles from 'components/pages/emailLog/emailLogStyles'

const EmailLog: React.FC = () => {
  const { uiState, bccAccountId, emailLogApiClient } = useEmailLogLogic()
  const classes = styles()

  return useMemo(
    () => (
      <EmailLogProvider bccAccountId={bccAccountId} emailLogApiClient={emailLogApiClient}>
        <Grid container className={classes.wrapper} data-testid="container">
          {uiState === UiState.INITIALIZING && <LinearProgress />}
          {uiState === UiState.READY && <BdsEmailLog />}
        </Grid>
      </EmailLogProvider>
    ),
    [classes.wrapper, bccAccountId, emailLogApiClient, uiState]
  )
}

export default EmailLog
