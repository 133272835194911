import { makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  domainRecipientsPage: {
    height: 'calc(100vh - 80px)',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  title: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7)
  },
  contentSpacing: {
    padding: theme.spacing(2)
  },
  headerRowSpacing: {
    marginLeft: theme.spacing(2)
  },
  defaultRow: {
    flexGrow: 0,
    width: '100%',
    flexBasis: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  rowMargin: {
    marginTop: theme.spacing(1)
  },
  tableRow: {
    flexGrow: 1,
    width: '100%',
    flexBasis: 0,
    maxWidth: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  kendoGrid: {
    height: '100%',
    width: '100%',

    '& > .k-widget': {
      height: '100%'
    }
  },
  searchTextField: {
    margin: theme.spacing(2, 2, 3, 0),
    backgroundColor: theme.palette.common.white,
    minWidth: 624
  },
  recipientsTable: {
    height: '100%',

    '& tbody tr': {
      cursor: 'pointer'
    }
  }
}))

export default styles
