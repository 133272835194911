import React, { useMemo } from 'react'
import { FormHelperText } from '@barracuda-internal/bds-core'
import { useEditDomainOptionsLogic } from 'components/pages/domains/editDomain/editDomainOptions/useEditDomainOptionsLogic'
import { useFormatMessage } from 'lib/localization'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { SettingValue } from 'types/Settings'
import { RcptToTarget, SpoolingValue } from 'types/domains'
import { FormRow, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface EditDomainOptionsProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains.edit_domain'

const EditDomainOptions = ({ form }: EditDomainOptionsProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditDomainOptionsLogic(form)

  return useMemo(
    () => (
      <FormBlock title={formatMessage('options.title')}>
        <FormRow rowTitle={formatMessage('options.spooling.label')}>
          <InlineRadioGroup
            name="spooling"
            value={state.spooling}
            onChange={eventHandlers.onChangeSpooling}
            disabled={state.inProgress}
            labels={[formatMessage('options.yes'), formatMessage('options.no')]}
            values={[`${state.spoolingYesValue}`, `${SpoolingValue.NO}`]}
          />
        </FormRow>
        {state.permissions.canManageUserAutoAdd && (
          <FormRow rowTitle={formatMessage('options.user_auto_add.label')}>
            <InlineRadioGroup
              name="user_auto_add"
              value={state.userAutoAdd}
              onChange={eventHandlers.onChangeUserAutoAdd}
              disabled={state.inProgress}
              labels={[formatMessage('options.yes'), formatMessage('options.no')]}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            />
            <FormHelperText error={!!state.errors.ldapSyncWithUserAutoAdd}>
              {formatMessage(state.errors.ldapSyncWithUserAutoAdd || 'options.user_auto_add.helper')}
            </FormHelperText>
          </FormRow>
        )}
        <FormRow rowTitle={formatMessage('options.enable_sender_proof_protection.label')}>
          <InlineRadioGroup
            name="sender_spoof"
            value={state.senderSpoof}
            onChange={eventHandlers.onChangeSenderSpoof}
            disabled={state.inProgress}
            labels={[formatMessage('options.yes'), formatMessage('options.no')]}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
          />
        </FormRow>
        {state.permissions.canManageRecipientRewrite && (
          <FormRow rowTitle={formatMessage('options.rewrite_recipients_for_aliased_domains.label')}>
            <InlineRadioGroup
              name="rcpt_to_target"
              value={state.rcptToTarget}
              onChange={eventHandlers.onChangeRcptToTarget}
              disabled={state.inProgress}
              labels={[formatMessage('options.yes'), formatMessage('options.no')]}
              values={[RcptToTarget.DOMAIN, RcptToTarget.NONE]}
            />
          </FormRow>
        )}
      </FormBlock>
    ),
    [eventHandlers, formatMessage, state]
  )
}

export default EditDomainOptions
