/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */
import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import IconOkay from 'assets/images/icons/icn-ok.png'
import IconWarning from 'assets/images/icons/icn-warning.png'
import IconCritical from 'assets/images/icons/icn-critical.png'
import IconFlag from 'assets/images/icons/icn-flag.svg'

import { HelpComponentProps } from 'types/Help'

function DomainManager(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The <b>Domains</b> page displays all domains added to {props.productName} for email processing. Use this page to
        manage existing domains and add new domains. For more information on the <b>Domains</b> page, see{' '}
      </p>
      <a href="https://campus.barracuda.com/doc/96022982/" target="_blank" rel="noreferrer">
        {' '}
        Barracuda Campus.
      </a>
      <br />
      <br />
      To sort by <b>Domain Name</b>, click on the column heading. Click on a domain name to toggle additional details
      and actions.
      <br />
      <br />
      The <b>Domain Name</b> column displays the Domain status as follows:
      <ul>
        <li>
          <img src={IconOkay} /> indicates the Domain is verified and all MX records for the domain are pointing to{' '}
          {props.productName}.
        </li>
        <li>
          <img src={IconWarning} /> indicates the Domain is verified but the MX records are not all pointing to{' '}
          {props.productName}, and therefore MX is not configured.
        </li>
        <li>
          <img src={IconCritical} /> indicates the Domain is new and not yet verified. Click <b>Verify Ownership</b> to
          complete the verification process.
        </li>
      </ul>
      See{' '}
      <a href="https://campus.barracuda.com/doc/96022984/" target="_blank" rel="noreferrer">
        {' '}
        Barracuda Campus
      </a>{' '}
      for information on resolving states other than verified <img src={IconOkay} />.
      <h3>
        <a>Domain Options</a>
      </h3>
      You can take the following actions on a domain:
      <ul>
        <li>
          <b>Aliases</b>
          <br />
          Click the link in the <b>Aliases</b> column to go to the <b>Domains &gt; Domain Settings</b> page where you
          can modify the domain alias.
        </li>
        <li>
          <b>Recipients</b>
          <br />
          To view the list of unique recipients seen in the last 30 days for a domain, click the recipient value in the{' '}
          <b>Recipients</b>
          column for that domain. The <b>Recipients List</b> page displays.
        </li>
        <li>
          <b>Verify Ownership</b>
          <br />
          Click <b>Verify Ownership</b> in the <b>Domain Name</b> column to go to the <b>Domain Verification</b> page to
          complete the verification process.
        </li>
        <li>
          <b>Edit Domain Settings</b>
          <br />
          If you have not completed the verification process, click <b>Edit</b> in the <b>Settings</b> column to go to
          the <b>Domains &gt; Domain Settings</b> page.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Manage</b>
            <br />
            Click <b>Manage</b> in the <b>Domain Options</b> column to view the dashboard and configure inbound and
            outbound policies for the domain.
          </li>
        )}
        <li>
          <b>Remove</b>
          <br />
          Click <b>Remove</b> in the <b>Domain Options</b> column to remove the selected domain; once removed,{' '}
          {props.productName} no longer processes mail for that domain.
        </li>
        <li>
          <b>Reset to account policies</b>
          <br />
          If you have set custom policies on a domain, the <b>Domain Specific Policies</b> (<img src={IconFlag} />) icon
          displays. Click the domain name to toggle details on. <b>Domain Specific Policies</b>
          displays in the <b>Domain Options</b> field. To revert the domain to the global settings, click{' '}
          <b>Reset to account policies</b>.
        </li>
      </ul>
      <h3>
        <a>Add a New Domain</a>
      </h3>
      <ol>
        <li>
          Click <b>Add Domain</b>.
        </li>
        <li>
          Enter the <b>Domain Name</b> and <b>Mail Server</b> hostname (FQDN) or IP address.
        </li>
        <li>
          Click <b>Add</b>. Once the domain is added, the <b>Domains &gt; Domain Settings</b> page displays.
        </li>
        <li>
          Each of the domains where you want to filter email must be verified by {props.productName} for proof of
          ownership; {props.productName} does not process email for a domain until the verification process is complete.
          Use the <b>Domains &gt; Domain Settings</b> page to complete the verification process, configure mail servers,
          SMTP over TLS, encryption, authentication, and other domain-specific settings.
        </li>
      </ol>
      {!props.hasCpl && (
        <>
          <h3>
            <a>Relay Outbound Mail</a>
          </h3>
          In your mail server or Smart host, specify the hostname value from the <b>Outbound Hostname</b> field for each
          domain from which you want to relay outbound mail.
        </>
      )}
    </div>
  )
}

export default DomainManager
/* eslint-enable jsx-a11y/anchor-is-valid, jsx-a11y/alt-text */
