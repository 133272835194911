import React, { useMemo } from 'react'

import {
  Alert,
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Select,
  SelectLabel,
  SelectMenuItem,
  SelectWrapper,
  Typography
} from '@barracuda-internal/bds-core'

import {
  Checkbox as CheckboxIcon,
  CheckboxSelected as CheckboxSelectedIcon
} from '@barracuda-internal/bds-core/dist/Icons/Controls'
import { useFormatMessage } from 'lib/localization'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { Cell } from 'components/libs/grid/cell'

import { useEditUserDialogLogic } from 'components/pages/users/usersList/editUserDialog/useEditUserDialogLogic'
import styles from 'components/pages/users/usersList/usersListStyles'
import { AccountUser, AccountUserRole } from 'types/users'

const BASE_I18N_KEY = 'ess.users.edit_user_dialog'

export interface EditUserDialogProps {
  onClose: () => void
  user: AccountUser
}

function EditUserDialog({ onClose, user, ...rest }: EditUserDialogProps) {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditUserDialogLogic({ onClose, user })

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open {...rest}>
        {state.dialogError && <Alert severity="error">{formatMessage(state.dialogError)}</Alert>}
        <DialogTitle disableTypography>
          <Typography variant="h6">
            {formatMessage('title', {
              user: user.userId,
              b: (txt: string) => <b>{txt}</b>
            })}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogSubtitle}>{formatMessage('content_title')}</DialogContentText>
          <Grid container direction="column">
            <Grid data-testid="role-selector" item>
              <SelectWrapper className={classes.userRoleSelector} variant="outlined">
                <SelectLabel>{formatMessage('user_role')}</SelectLabel>
                <Select
                  data-testid="role-selector-action"
                  disabled={state.updateInProgress}
                  label={formatMessage('user_role')}
                  onChange={e => eventHandlers.onSelectUserRole(e.target.value as AccountUserRole)}
                  value={state.selectedUserRole}
                >
                  {Object.values(AccountUserRole)
                    .filter(value => value !== AccountUserRole.all)
                    .map(userRole => (
                      <SelectMenuItem data-testid={userRole} key={userRole} value={userRole}>
                        {formatMessage(`user_roles.${userRole}`)}
                      </SelectMenuItem>
                    ))}
                </Select>
              </SelectWrapper>
            </Grid>
            <Grid
              item
              className={
                state.selectedUserRole === AccountUserRole.user || state.updateInProgress
                  ? classes.disabledDomainsTable
                  : classes.domainsTable
              }
            >
              <DataTable style={{ maxHeight: 400 }} data={state.domains} selectedField="selected">
                <DataTableColumn
                  width={42}
                  field="select"
                  title={' '}
                  headerCell={() => (
                    <Grid
                      className={classes.checkboxHeaderCell}
                      onClick={eventHandlers.onSelectAllDomains}
                      role="button"
                    >
                      {state.isAllDomainsSelected ? (
                        <CheckboxSelectedIcon className={classes.checkedRow} />
                      ) : (
                        <CheckboxIcon />
                      )}
                    </Grid>
                  )}
                  cell={({ dataItem }: { dataItem: DomainsInDB }) => (
                    <Cell
                      onClick={() => eventHandlers.onSelectDomain(dataItem.domainId)}
                      className={classes.selectColumn}
                      data-testid="user-id-cell"
                    >
                      {eventHandlers.isDomainSelected(dataItem.domainId) ? (
                        <CheckboxSelectedIcon className={classes.checkedRow} />
                      ) : (
                        <CheckboxIcon />
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field="domain"
                  title={formatMessage('domain')}
                  cell={({ dataItem }: { dataItem: DomainsInDB }) => (
                    <Cell onClick={() => eventHandlers.onSelectDomain(dataItem.domainId)} data-testid="domain-cell">
                      <Typography className={classes.domainColumn} variant="body2">
                        {dataItem.domainName}
                      </Typography>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={eventHandlers.onSave} color="primary" isLoading={state.updateInProgress}>
            {formatMessage('save')}
          </Button>
          <Button onClick={eventHandlers.onCancel} color="primary" disabled={state.updateInProgress}>
            {formatMessage('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, state, eventHandlers, rest, user]
  )
}

export default EditUserDialog
