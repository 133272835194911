import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { SortDescriptor, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { AVAILABLE_IP_EXEMPTION_POLICIES, IpAddressEntry } from 'types/Settings'
import { sortIpAddress, sortText } from 'lib/sort'
import { FormErrors } from 'components/libs/ipAddressTable/useIpAddressTableValidation'
import {
  IpAddressTableForm,
  IpAddressTableFormState,
  useIpAddressTableForm
} from 'components/libs/ipAddressTable/useIpAddressTableForm'

export interface UseIpAddressTableLogicProps {
  data: IpAddressEntry[]
  defaultNetmask?: boolean
  shouldShowPolicy?: boolean
  onAddItem: (ip: IpAddressEntry) => void
  onRemoveItem: (id: string) => void
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export interface State {
  idToRemove: string
  sort: SortDescriptor[]
  form: IpAddressTableForm
  formState: IpAddressTableFormState
  errors: FormErrors
  tableData: {
    data: IpAddressEntry[]
    total: number
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onConfirmRemove: () => void
  onConfirmCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSortChange: (e: GridSortChangeEvent) => void
  onPolicyChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
}

export type UseIpAddressTableLogic = [State, EventHandlers]

const initialSort = [{ field: 'address', dir: 'asc' } as SortDescriptor]

export const enum IP_ADDRESS_INPUT_NAMES {
  ADDRESS = 'address',
  NETMASK = 'netmask',
  POLICY = 'policy',
  COMMENT = 'comment'
}

const DEFAULT_NETMASK = '255.255.255.255'

const INITIAL_FORM_STATE = { address: '', netmask: '', comment: '', policy: AVAILABLE_IP_EXEMPTION_POLICIES[0] }

const BLANK_IP_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const useIpAddressTableLogic = ({
  data,
  defaultNetmask,
  shouldShowPolicy,
  onAddItem,
  onRemoveItem,
  delegateDirtyFormState
}: UseIpAddressTableLogicProps): UseIpAddressTableLogic => {
  const form = useIpAddressTableForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort)
  const [idToRemove, setIdToRemove] = useState('')

  const onAdd = useCallback(() => {
    const netmask = form.fields.netmask.getValue() || (defaultNetmask ? DEFAULT_NETMASK : '')
    const newIp: IpAddressEntry = {
      ...form.getState(),
      netmask,
      id: MD5(makeUuid()).toString()
    }
    if (!shouldShowPolicy) {
      newIp.policy = undefined
    }
    if (form.validate(newIp, { data })) {
      onAddItem(newIp)
    }
  }, [form, defaultNetmask, shouldShowPolicy, data, onAddItem])

  const onRemove = useCallback(
    (id: string) => {
      setIdToRemove(id)
    },
    [setIdToRemove]
  )

  const onConfirmRemove = useCallback(() => {
    onRemoveItem(idToRemove)
    setIdToRemove('')
  }, [idToRemove, onRemoveItem, setIdToRemove])

  const onConfirmCancel = useCallback(() => {
    setIdToRemove('')
  }, [setIdToRemove])

  const tableData = useMemo(() => {
    const orderedData = [...data].sort((a, b) => {
      switch (sort[0].field) {
        case IP_ADDRESS_INPUT_NAMES.ADDRESS:
          return sortIpAddress(a.address, b.address, sort[0].dir !== 'asc')
        case IP_ADDRESS_INPUT_NAMES.NETMASK:
          return sortIpAddress(a.netmask, b.netmask, sort[0].dir !== 'asc')
        case IP_ADDRESS_INPUT_NAMES.POLICY:
          return sortText(a.policy || '', b.policy || '', sort[0].dir !== 'asc')
        case IP_ADDRESS_INPUT_NAMES.COMMENT:
          return sortText(a.comment || '', b.comment || '', sort[0].dir !== 'asc')
        default:
          return 0
      }
    })

    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_IP_ENTRY)
    return {
      data: processedData as IpAddressEntry[],
      total: processedData.length || 0
    }
  }, [data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === IP_ADDRESS_INPUT_NAMES.ADDRESS:
          form.fields.address.setValue(value)
          break
        case name === IP_ADDRESS_INPUT_NAMES.NETMASK:
          form.fields.netmask.setValue(value)
          break
        case name === IP_ADDRESS_INPUT_NAMES.COMMENT:
          form.fields.comment.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  const onPolicyChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { value } = e.target
      if (typeof value !== 'string') {
        return
      }
      form.fields.policy.setValue(value)
      form.delegateIsDirty()
    },
    [form]
  )

  return useMemo(
    () => [
      {
        idToRemove,
        sort,
        form,
        formState: form.getState(),
        errors: form.errors,
        tableData
      },
      {
        onAdd,
        onRemove,
        onConfirmRemove,
        onConfirmCancel,
        handleOnInputChange,
        handleSortChange,
        onPolicyChange
      }
    ],
    [
      idToRemove,
      sort,
      form,
      tableData,
      onAdd,
      onRemove,
      onConfirmRemove,
      onConfirmCancel,
      handleOnInputChange,
      handleSortChange,
      onPolicyChange
    ]
  )
}
