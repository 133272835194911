import { useCallback, useMemo, useState } from 'react'
import { isPending } from 'redux/toolkit/api'
import { useAppSelector } from 'redux/toolkit/hooks'
import { SettingsPageActions } from 'types/SettingsPage'

export interface State {
  disableCancel: boolean
  disableSave: boolean
  isWarningDialogOpen: boolean
}

export interface EventHandlers {
  onCancel: () => void
  closeDialog: () => void
}

export type UseSettingsActionLogic = [State, EventHandlers]

export const useSettingsActionLogic = ({
  cancelDisabled,
  saveDisabled,
  onCancelConfirm
}: SettingsPageActions): UseSettingsActionLogic => {
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
  const { isUpdateAccountSettingsPending, isUpdateDomainSettingsPending, hasPageChanges } = useAppSelector(_stores => ({
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus),
    hasPageChanges: _stores.settings.hasPageChanges
  }))

  const onCancel = useCallback(() => {
    if (hasPageChanges) {
      setIsWarningDialogOpen(true)
    } else {
      onCancelConfirm()
    }
  }, [hasPageChanges, onCancelConfirm])

  const closeDialog = useCallback(() => {
    setIsWarningDialogOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        disableCancel:
          cancelDisabled !== undefined
            ? cancelDisabled
            : isUpdateAccountSettingsPending || isUpdateDomainSettingsPending || !hasPageChanges,
        disableSave:
          saveDisabled !== undefined
            ? saveDisabled
            : isUpdateAccountSettingsPending || isUpdateDomainSettingsPending || !hasPageChanges,
        isWarningDialogOpen
      },
      {
        onCancel,
        closeDialog
      }
    ],
    [
      cancelDisabled,
      saveDisabled,
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending,
      hasPageChanges,
      isWarningDialogOpen,
      onCancel,
      closeDialog
    ]
  )
}
