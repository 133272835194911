import { useCallback, useMemo } from 'react'

import { useAppDispatch } from 'redux/toolkit/hooks'
import { switchToOldUi } from 'redux/features/auth/authSlice'
import { AvailableSettings, SettingValue } from 'types/Settings'
import { useUiSwitcherRights } from 'components/libs/userRights/libs/useUiSwitcherRights'

export interface State {
  show: boolean
}

export interface EventHandlers {
  onSwitchToOldUi: () => void
}

export const useUiSwitcherLogic = (): [State, EventHandlers] => {
  const dispatch = useAppDispatch()

  const onSwitchToOldUi = useCallback(
    () => dispatch(switchToOldUi({ [AvailableSettings.REACT_OPT_IN]: SettingValue.DISABLED })),
    [dispatch]
  )

  const { canViewPhaseOneFeature } = useUiSwitcherRights()

  return useMemo(
    () => [
      {
        show: canViewPhaseOneFeature
      },
      {
        onSwitchToOldUi
      }
    ],
    [canViewPhaseOneFeature, onSwitchToOldUi]
  )
}
