import React, { useMemo } from 'react'

import FilterList from '@material-ui/icons/FilterList'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import { Button, Grid, Popover } from '@barracuda-internal/bds-core'
import styles from 'components/libs/fluidRow/fluidRowStyles'
import { FluidRowLogicProps, useFluidRowLogic } from 'components/libs/fluidRow/useFluidRowLogic'

const FluidRow = (props: FluidRowLogicProps) => {
  const classes = styles()
  const [state, eventHandlers] = useFluidRowLogic(props.children)

  return useMemo(
    () => (
      <Grid ref={state.containerRef} className={classes.parent}>
        {props.fixedContainer && (
          <Grid ref={state.fixedContainerRef} item className={classes.growFlex}>
            {props.fixedContainer}
          </Grid>
        )}
        <Grid item className={classes.childrenWrapper}>
          {React.Children.map(props.children, (child, index) => (
            <Grid
              ref={(el: HTMLDivElement) => {
                state.childrenRefs.current[index] = el
              }}
            >
              {child}
            </Grid>
          ))}
        </Grid>
        <Grid className={classes.wrapper}>
          {state.rowChildren.map(child => child)}
          {!!state.expandChildren.length && (
            <Grid>
              <Button
                className={classes.moreButton}
                color="primary"
                variant="text"
                innerRef={state.expandButtonRef}
                onClick={eventHandlers.handleClick}
              >
                <FilterList className={!state.hasHiddenActiveFilter ? classes.filterIcon : ''} />
                {state.hasHiddenActiveFilter && <FiberManualRecord fontSize="small" className={classes.indicator} />}
                {props.buttonText}
              </Button>
              <Popover
                open={state.isExpandOpen}
                anchorEl={state.expandButtonRef.current}
                onClose={eventHandlers.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <Grid className={classes.moreFiltersWrapper}>{state.expandChildren.map(child => child)}</Grid>
              </Popover>
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
    [props, classes, state, eventHandlers]
  )
}

export default FluidRow
