import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function UsersDefaultPolicy(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains verified in {props.productName} for processing email unless you
        change these settings for a specific domain. In that case, the domain-level settings override the global system
        settings.
      </p>
      <br />
      <br />
      <p>
        Configure the following default policies for email recipients; you can create additional policies that override
        the defaults you set below.
      </p>

      <ul>
        <li>
          <b>Default policy for managed users (users on Users list)</b>
          <br />
          Select the default policy for users entered manually or added by syncing {props.productName} with your LDAP
          server or Azure AD:
          <ul>
            <li>
              <b>Scan</b>
              <br />
              Scan all email sent to the user&apos;s email address per set policies.
            </li>

            <li>
              <b>Allow</b>
              <br />
              Bypass all scanning for set policies for email going to the user&apos;s email address. Virus scanning, if
              enabled, still applies.
            </li>

            <li>
              <b>Block</b>
              <br />
              Block all email addressed to the user&apos;s email address.
            </li>
          </ul>
        </li>

        <li>
          <b>Default policy for unmanaged users (users NOT on Users list)</b>
          <br />
          Set different scan/block/allow policies for <i>Unmanaged Users</i> including all senders and recipients of
          email for the configured domains, but who are not in your users list. If you do not edit this setting, all
          email is scanned by default as opposed to blocked or allowed.
          <ul>
            <li>
              <b>Scan</b>
              <br />
              Scan all email sent to user&apos;s email address per set policies.
            </li>

            <li>
              <b>Allow</b>
              <br />
              Bypass all scanning for set policies for email going to the user&apos;s email address. Virus scanning, if
              enabled, still applies.
            </li>

            <li>
              <b>Block</b>
              <br />
              Block all email addressed to the user&apos;s email address.
            </li>
          </ul>
        </li>

        <li>
          <b>Exempt Senders</b>
          <br />
          Specify whether users can exempt messages from a specific email address or domain on the{' '}
          <b>Settings &gt; Sender Policy</b> page.
          <ul>
            <li>
              <b>Allow users to exempt senders</b>
              <br />
              Users can exempt senders, and user exemptions override account and domain admin block lists.
            </li>

            <li>
              <b>Allow users to exempt senders but do not override admin block lists</b>
              <br />
              Users can exempt senders, but account and domain admin block lists take precedence over user exemptions.
            </li>

            <li>
              <b>Do not allow users to exempt senders</b>
              <br />
              Users cannot exempt senders.
            </li>
          </ul>
        </li>

        <li>
          <b>Allow users to block senders</b>
          <br />
          Specify whether users can block messages from a specific email address or domain on the{' '}
          <b>Settings &gt; Sender Policy</b> page.
          <ul>
            <li>
              <b>Yes</b>
              <br />
              When set to <b>Yes</b>, users can block messages from a specific email address or domain.
            </li>

            <li>
              <b>No</b>
              <br />
              When set to <b>No</b>, users cannot block messages from a specific email address or domain, and the{' '}
              <b>Block</b> option is not available in the <b>Message Log</b>.
            </li>
          </ul>
        </li>

        <li>
          <b>Allow end users to view and deliver blocked messages</b>
          <br />
          <ul>
            <li>
              <b>Yes</b>
              <br />
              When set to <b>Yes</b>, users can view and deliver blocked messages from their message log, except
              messages blocked for Antivirus or Advanced Threat Protection.
            </li>

            <li>
              <b>No</b> <i>(Recommended setting)</i>
              <br />
              When set to <b>No</b>, users cannot view, deliver, allow, or download blocked messages from their message
              log.
            </li>
          </ul>
        </li>

        <li>
          <b>Allow end users to view and deliver quarantined messages</b>
          <br />
          <ul>
            <li>
              <b>Yes</b>
              <br />
              When set to <b>Yes</b>, users can view and deliver quarantined messages from their message log.
            </li>

            <li>
              <b>No</b>
              <br />
              When set to <b>No</b>, users cannot view, deliver, allow, or download quarantined messages from their
              message log.
            </li>
          </ul>
        </li>

        <li>
          <b>Default Time Zone</b>
          <br />
          Select the user&apos;s default time zone from the drop-down menu.
        </li>
      </ul>
    </div>
  )
}

export default UsersDefaultPolicy
