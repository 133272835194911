import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function Compliance(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      When the total number of <b>Active</b> users on your account in a 30-day period exceeds the number of user
      licenses (<b>Recipients</b>) on your account, you are out of compliance.
      <h3 id="help-licenses">User Licenses</h3>
      Billing is based on the number of user mailboxes receiving and/or sending mail the mail server recognizes in{' '}
      {props.productName} in a 30-day period.
      <br />
    </div>
  )
}

export default Compliance
