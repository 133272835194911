import React, { useMemo } from 'react'
import { Tab, TabPanel, Tabs } from '@barracuda-internal/bds-core'
import MessageDetailsContentTabMessagePreview from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessagePreview/MessageDetailsContentTabMessagePreview'
import MessageDetailsContentTabMessageSource from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabMessageSource/MessageDetailsContentTabMessageSource'
import styles, {
  colors
} from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/messageDetailsContentTabsStyles'
import {
  TabName,
  useMessageDetailsContentTabsLogic
} from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/useMessageDetailsContentTabsLogic'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.message_detail'

const MessageDetailsContentTabs = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [{ selectedTabName, isPreviewEnabled, showTabs }, { onTabChange }] = useMessageDetailsContentTabsLogic()
  const classes = styles()
  return useMemo(
    () => (
      <>
        {showTabs && (
          <Tabs
            value={selectedTabName}
            onChange={onTabChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="standard"
            TabIndicatorProps={{
              style: {
                padding: '2px',
                boxShadow: `inset 0px -4px 0px ${colors.barracudaBlue}`
              }
            }}
            className={classes.messageDetailTab}
          >
            {isPreviewEnabled && <Tab label={formatMessage('preview')} value={TabName.preview} />}
            <Tab label={formatMessage('source')} value={TabName.source} />
          </Tabs>
        )}
        <TabPanel className={classes.tabPanel}>
          {selectedTabName === TabName.preview && isPreviewEnabled && <MessageDetailsContentTabMessagePreview />}
          {selectedTabName === TabName.source && <MessageDetailsContentTabMessageSource />}
        </TabPanel>
      </>
    ),
    [
      classes.messageDetailTab,
      classes.tabPanel,
      formatMessage,
      onTabChange,
      selectedTabName,
      isPreviewEnabled,
      showTabs
    ]
  )
}

export default MessageDetailsContentTabs
