import { colors, makeStyles } from '@barracuda-internal/bds-core'

const confirmDialogStyles = makeStyles(theme => ({
  warningIcon: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    color: colors.statusWarning
  }
}))

export default confirmDialogStyles
