import React from 'react'

import {
  CheckboxGroup,
  CheckboxLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton
} from '@barracuda-internal/bds-core'
import { List, Close } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Button, Checkbox } from '@material-ui/core'
import { EventTypes, useAuditLogFilterLogic } from 'components/pages/auditLog/auditLogFilter/useAuditLogFilterLogic'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/auditLog/auditLogFilter/auditlogFilterStyles'

const BASE_I18N_KEY = 'ess.audit_log.filter'
const BASE_I18N_ACTION_KEY = 'ess.audit_log.action'

export interface AuditLogFilterProps {
  eventTypes: EventTypes
  onUpdateEventTypes: (eventTypes: EventTypes, performSearch: boolean) => void
}

const AuditLogFilter: React.FC<AuditLogFilterProps> = ({ eventTypes, onUpdateEventTypes }) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatActionMessage = useFormatMessage(BASE_I18N_ACTION_KEY)
  const [state, eventHandlers] = useAuditLogFilterLogic(eventTypes, onUpdateEventTypes)

  return (
    <Grid>
      <Button variant="contained" startIcon={<List />} onClick={eventHandlers.onOpen} data-testid="filter-button">
        {formatMessage('filter')}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={state.openSelection}
        onClose={eventHandlers.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="dialog"
      >
        <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
          {formatMessage('title')}
          <IconButton onClick={eventHandlers.onClose} className={classes.closeButton} data-testid="close-button">
            <Close />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description" data-testid="dialog-description">
            {formatMessage('event_type')}
          </DialogContentText>
          <CheckboxGroup>
            {Object.entries(state.eventTypes).map(([key, value]) => (
              <CheckboxLabel
                control={<Checkbox name={key} checked={value} onChange={eventHandlers.onChange} />}
                label={formatActionMessage(key)}
                key={key}
                data-testid={`checkbox-${key}`}
              />
            ))}
          </CheckboxGroup>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={eventHandlers.onClear} color="secondary" data-testid="clear-button">
            {formatMessage('clear')}
          </Button>
          <Button onClick={eventHandlers.onApply} variant="contained" color="primary" data-testid="apply-button">
            {formatMessage('apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default AuditLogFilter
