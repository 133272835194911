import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function AuditLog(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The Audit Log provides a record of all changes made to {props.productName} by administrators, end users, and
        Barracuda Support.{' '}
      </p>
      <p>The Audit Log contains data for the last 14 days.</p>
      <p>The Audit Log displays the following information:</p>
      <ul>
        <li>
          <b>Date/Time</b> – When the action occurred.
        </li>
        <li>
          <b>User</b> – Email and IP address of the user who performed the action. Displayed value is{' '}
          <b>Barracuda Networks Support</b> if action was taken by a Barracuda Support Representative.{' '}
        </li>
        <li>
          <b>Scope</b> – Describes what is affected by the action. Actions include (creating, changing, or deleting).{' '}
        </li>
        <ul>
          <li>
            <b>Account</b> – Affects Account Settings, Domains, Marking Messages, Allow Lists.
          </li>
          <li>
            <b>Domain</b> – Affects Domain Settings, Users, Allow Lists.
          </li>
          <li>
            <b>User</b> – Affects User Settings.{' '}
          </li>
        </ul>
        <li>
          <b>Details</b> – Basic information about the action.{' '}
        </li>
      </ul>
    </div>
  )
}

export default AuditLog
