import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AVAILABLE_ATTACHMENT_FILTER_ACTIONS, AttachmentFilterAction, AvailableSettings } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'

export interface State {
  form: {
    password_archive_filtering: string
    password_msoffice_filtering: string
    password_pdf_filtering: string
  }
  actionOptions: string[]
  hasPageChanges: boolean
}

export interface EventHandlers {
  handleOnSelectChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onSave: (name: SELECT_NAMES) => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsContentPoliciesTableDirty: Dispatch<SetStateAction<boolean>>
  setIsMessageTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseContentPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.PASSWORD_ARCHIVE_FILTERING,
  AvailableSettings.PASSWORD_MSOFFICE_FILTERING,
  AvailableSettings.PASSWORD_PDF_FILTERING,
  AvailableSettings.ATTACHMENT_FILTER_POLICY,
  AvailableSettings.MESSAGE_CONTENT_FILTERS
]
export const enum SELECT_NAMES {
  ARCHIVE = 'password_archive_filtering',
  MSOFFICE = 'password_msoffice_filtering',
  PDF = 'password_pdf_filtering'
}

export const useContentPoliciesLogic = (): UseContentPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [form, setForm] = useState({
    [SELECT_NAMES.ARCHIVE]: AttachmentFilterAction.BLOCK,
    [SELECT_NAMES.MSOFFICE]: AttachmentFilterAction.IGNORE,
    [SELECT_NAMES.PDF]: AttachmentFilterAction.IGNORE
  })
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    accountSettings,
    domainSettings,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus)
  }))
  const { canQuarantineSettings } = useInboundSettingsRights()

  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([
    form.password_archive_filtering,
    form.password_msoffice_filtering,
    form.password_pdf_filtering
  ])
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isContentPoliciesTableDirty, setIsContentPoliciesTableDirty] = useState<boolean>(false)
  const [isMessageTableDirty, setIsMessageTableDirty] = useState<boolean>(false)
  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  useEffect(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    if (settings) {
      setForm({
        [SELECT_NAMES.ARCHIVE]: (settings.password_archive_filtering ||
          form.password_archive_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.MSOFFICE]: (settings.password_msoffice_filtering ||
          form.password_msoffice_filtering) as AttachmentFilterAction,
        [SELECT_NAMES.PDF]: (settings.password_pdf_filtering || form.password_pdf_filtering) as AttachmentFilterAction
      })
      setShouldUpdateInitialForm(true)
    }
    // eslint-disable-next-line
  }, [accessTokenObject, domainSettings, accountSettings])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const handleOnSelectChange = useCallback(
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target
      setForm({ ...form, [name as SELECT_NAMES]: value })
    },
    [form]
  )

  const onSave = useCallback(
    (name: SELECT_NAMES) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [name]: form[name] }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [name]: form[name] } }))
      }
    },
    [dispatch, accessTokenObject, form]
  )

  return useMemo(
    () => [
      {
        form,
        actionOptions: canQuarantineSettings
          ? AVAILABLE_ATTACHMENT_FILTER_ACTIONS
          : AVAILABLE_ATTACHMENT_FILTER_ACTIONS.slice(0, -1),
        hasPageChanges: isDirtyForm || isContentPoliciesTableDirty || isMessageTableDirty
      },
      {
        handleOnSelectChange,
        onSave,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsContentPoliciesTableDirty,
        setIsMessageTableDirty
      }
    ],
    [
      form,
      canQuarantineSettings,
      isDirtyForm,
      isContentPoliciesTableDirty,
      isMessageTableDirty,
      handleOnSelectChange,
      onSave,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
