import { colors } from '@barracuda-internal/bds-core'

export const defaultSplitterValues = {
  color: colors.scaleGray050,
  hover: colors.uiElement,
  drag: colors.scaleGray050,
  splitterSize: '3px',
  initialPrimarySize: '50%',
  minPrimarySize: '0px',
  minSecondarySize: '0px',
  resetOnDoubleClick: false
}
