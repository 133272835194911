import React, { useMemo } from 'react'
import { Alert, Button, Grid, LinearProgress, Typography } from '@barracuda-internal/bds-core'
import { useVerifyDomainTransferLogic } from 'components/pages/domains/verifyDomainTransfer/useVerifyDomainTransferLogic'
import { PageContainer } from 'components/libs/layout/pages/PageContainer'
import { PageTitle } from 'components/libs/layout/pages/PageTitle'
import { useFormatMessage } from 'lib/localization'
import { FormRow } from 'components/libs/layout/pages/FormRow'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'
import appConfig from 'config/appConfig'

const BASE_I18N_KEY = 'ess.domains.verify_domain_transfer'

const VerifyDomainTransfer = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useVerifyDomainTransferLogic()

  return useMemo(
    () => (
      <PageContainer>
        <PageTitle title={formatMessage('title')} />
        <Grid container direction="column">
          {state.isGetPending && <LinearProgress />}
          {!state.isGetSuccess && state.errorText && (
            <FormBlock>
              <Alert severity="error">
                <Typography variant="body2">{formatMessage(state.errorText.textId, state.errorText.data)}</Typography>
              </Alert>
              <FormSpacer />
              <FormSpacer />
              <Button onClick={eventHandlers.onCancel} color="secondary">
                {formatMessage('back_to_domains')}
              </Button>
            </FormBlock>
          )}
          {state.isGetSuccess && (
            <>
              <Typography variant="h2">{state.domainName || ' '}</Typography>
              <FormSpacer />
              <FormSpacer />
              {state.errorText && (
                <>
                  <Alert severity="error">
                    <Typography variant="body2">
                      {formatMessage(state.errorText.textId, state.errorText.data)}
                    </Typography>
                  </Alert>
                  <FormSpacer />
                  <FormSpacer />
                </>
              )}
              <Typography variant="body2">{formatMessage('info')}</Typography>
              <Typography variant="body2">{formatMessage('cname_record_info')}</Typography>
              <FormSpacer />
              <FormSpacer />
              <FormRow contentIndent={1} rowTitle={formatMessage('hostname', { b: (txt: string) => <b>{txt}</b> })}>
                {state.cnameRecord}
              </FormRow>
              <FormSpacer />
              <FormRow contentIndent={1} rowTitle={formatMessage('points_to')}>
                {appConfig.DOMAIN_MOVE.CNAME_TARGET}
              </FormRow>
              <FormSpacer />
              <FormSpacer />
              <Typography variant="body2">{formatMessage('long_running_info')}</Typography>
              <FormSpacer />
              <FormSpacer />
              <FormBlock>
                <Button onClick={eventHandlers.onCancel} color="secondary">
                  {formatMessage('cancel')}
                </Button>
                <Button
                  onClick={eventHandlers.onVerify}
                  color="primary"
                  variant="contained"
                  isLoading={state.isVerifyPending}
                >
                  {formatMessage('verify')}
                </Button>
              </FormBlock>
            </>
          )}
        </Grid>
      </PageContainer>
    ),
    [eventHandlers, formatMessage, state]
  )
}

export default VerifyDomainTransfer
