import { useState, useMemo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postRecategorize } from 'redux/features/mstore/mstoreSlice'

export interface UseRecategorizeOtherDialogLogic {
  customCategory: string
  errorMsg: string
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  submitForm: () => void
}

export interface RecategorizeOtherDialogProps {
  onClose: () => void
}

export const useRecategorizeOtherDialogLogic = (
  props: RecategorizeOtherDialogProps
): UseRecategorizeOtherDialogLogic => {
  const dispatch = useAppDispatch()

  const { checkedMessages } = useAppSelector(_store => ({
    checkedMessages: _store.mstore.checkedMessages
  }))

  const [customCategory, setCustomCategory] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const isCustomCategoryOk = useCallback((value: string) => {
    const format = /[^a-zA-Z0-9_\- ]+/ // Only special characters allowed are spaces, - and _
    return !format.test(value)
  }, [])

  const handleFormChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomCategory(e.target.value)
      if (!isCustomCategoryOk(e.target.value)) {
        setErrorMsg('limit_special_chars')
      } else if (e.target.value.length > 30) {
        setErrorMsg('character_limit')
      } else {
        setErrorMsg('')
      }
    },
    [isCustomCategoryOk]
  )

  const submitForm = useCallback(() => {
    dispatch(
      postRecategorize({
        messageIds: checkedMessages.map(message => message.mid),
        category: 'other',
        customCategory
      })
    )
    props.onClose()
  }, [dispatch, customCategory, props, checkedMessages])

  return useMemo(
    () => ({
      customCategory,
      errorMsg,
      handleFormChange,
      submitForm
    }),
    [customCategory, errorMsg, handleFormChange, submitForm]
  )
}
