import React, { PropsWithChildren, useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { useFormatMessage } from 'lib/localization'
import { useBlockTransition } from 'lib/useBlockTransition'

import styles from 'components/libs/layout/pages/pagesLayoutStyles'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'

const BASE_I18N_KEY = 'ess.layout'

export type PageContainerProps = {
  isLoading?: boolean
  isFailedToLoad?: boolean
  dirtyCheck?: boolean
  isDirtyForm?: boolean
  noPadding?: boolean
  fullPage?: boolean
}

export const PageContainer = ({
  children,
  isLoading = false,
  isFailedToLoad = false,
  dirtyCheck = false,
  isDirtyForm = false,
  noPadding = false,
  fullPage = false
}: PropsWithChildren<PageContainerProps>) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [transitionCb, resetTransitionCb] = useBlockTransition({ dirtyCheck, isDirtyForm })

  return useMemo(
    () => (
      <ContentLoader fullPage={fullPage} isLoading={isLoading} isFailed={isFailedToLoad}>
        <Grid className={noPadding ? '' : classes.pageContainer} alignItems="center" container direction="column">
          {children}
        </Grid>
        {!!transitionCb && (
          <WarningDialog
            title={formatMessage('unsaved_dialog.title')}
            description={formatMessage('unsaved_dialog.text')}
            onCancel={resetTransitionCb}
            onConfirm={transitionCb}
          />
        )}
      </ContentLoader>
    ),
    [classes, children, fullPage, isLoading, isFailedToLoad, formatMessage, transitionCb, resetTransitionCb, noPadding]
  )
}
