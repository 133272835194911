import { useCallback, useMemo } from 'react'
import { PredefinedFilterExceptions, OutboundPredefinedFilterExceptionType } from 'types/Settings'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'
import { useRefField } from 'lib/settingsForm/useRefField'
import { isUsNumber } from 'lib/validation'

export interface PredefinedFilterExceptionsFormState {
  pattern: string
  type: OutboundPredefinedFilterExceptionType
}

export interface ValidatorDependencies {
  data: PredefinedFilterExceptions[]
}

export interface PredefinedFilterExceptionsFormConfig {
  initialState: PredefinedFilterExceptionsFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type PredefinedFilterExceptionsForm = SettingsForm<PredefinedFilterExceptionsFormState>

export const usePredefinedFilterExceptionsForm = ({
  initialState,
  delegateIsDirtyForm
}: PredefinedFilterExceptionsFormConfig): PredefinedFilterExceptionsForm => {
  const patternField = useRefField(initialState.pattern)
  const typeField = useStateField(initialState.type)

  const formConfig = useMemo(
    () => ({
      fields: {
        pattern: patternField,
        type: typeField
      },
      delegateIsDirtyForm
    }),
    [delegateIsDirtyForm, patternField, typeField]
  )
  const form = useSettingsForm<PredefinedFilterExceptionsFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: PredefinedFilterExceptions, dependencies: ValidatorDependencies) => {
      form.setError('')

      const findDuplicate = dependencies.data.find(entry => entry.pattern === newPolicy.pattern)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      if (!newPolicy.pattern) {
        form.setError('missing_filtering_pattern')
        return false
      }

      if (newPolicy.type === OutboundPredefinedFilterExceptionType.PHONE_NUMBER && !isUsNumber(newPolicy.pattern)) {
        form.setError('error_invalid_phone_number')
        return false
      }

      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
