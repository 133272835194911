/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { EventHandlers, State, UiState } from 'components/pages/login/useLoginLogic'

const BASE_I18N_KEY = 'ess.login'

export type TemporaryPasswordCardProps = {
  state: State
  eventHandlers: EventHandlers
}

function TemporaryPasswordCard(props: TemporaryPasswordCardProps) {
  const { state, eventHandlers } = props
  const classes = styles(bdsTheme)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={3} className={classes.card} data-testid="temporary-password-card">
        {(state.isLoading || state.uiState === UiState.INITIALIZING) && (
          <LinearProgress data-testid="linear-progress" />
        )}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('temporary_passcode_title')}</Typography>
            <Typography variant="body1">
              {formatMessage('temporary_passcode_text', {
                email: <b>{state.formValues.user_id}</b>
              })}
            </Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
            {state.topErrorDescription && (
              <Grid item xs={12}>
                <Alert severity="error" className={classes.topError}>
                  {state.topErrorDescription}
                </Alert>
              </Grid>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormEmailRow}>
                <TextField
                  error={state.validatedLoginError}
                  fullWidth
                  name="password"
                  value={state.formValues.password}
                  onChange={eventHandlers.onInputChange}
                  label={formatMessage('temporary_passcode')}
                  type="password"
                  helperText={state.validatedLoginError ? formatMessage('invalid_temporary_login') : ''}
                  data-testid="password-input"
                  inputRef={ref => eventHandlers.setAutofocusRef(ref)}
                />
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRowLeft}>
                <Typography variant="body1" className={classes.cardInlineText}>
                  {formatMessage('didnt_receive_email')}
                </Typography>
                <Link
                  onClick={eventHandlers.onTemporaryPasscode}
                  data-testid="resend-code"
                  className={`${classes.cardInlineLink} ${state.isLoading ? 'disabled' : ''}`}
                >
                  <Typography variant="body1">{formatMessage('resend_code')}</Typography>
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Button
                  className={classes.cardFormButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={state.isLoading || state.uiState === UiState.INITIALIZING}
                  onClick={eventHandlers.onTemporaryLogin}
                  innerRef={state.loginButton}
                  data-testid="login-button"
                >
                  {formatMessage('signin')}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link onClick={() => eventHandlers.resetLoginState(true)} data-testid="different-user-link">
                  <Typography variant="body1">{formatMessage('different_account_message')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardFormActionRow,
      classes.cardFormButton,
      classes.cardFormEmailRow,
      classes.cardFormLinkRow,
      classes.cardFormLinkRowLeft,
      classes.cardHeader,
      classes.cardInlineLink,
      classes.cardInlineText,
      classes.cardLogo,
      classes.cardWrapper,
      classes.topError,
      eventHandlers,
      formatMessage,
      state.formValues.password,
      state.formValues.user_id,
      state.isLoading,
      state.loginButton,
      state.topErrorDescription,
      state.topErrorMessage,
      state.uiState,
      state.validatedLoginError
    ]
  )
}

export default TemporaryPasswordCard
/* eslint-enable jsx-a11y/anchor-is-valid */
