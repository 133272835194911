import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import {
  deleteAccountUsers,
  getAccountUsers,
  resetAccountUsers,
  resetDeleteAccountUsers
} from 'redux/features/users/usersSlice'
import { setSuccessSnackBar } from 'redux/features/app/appSlice'

export enum DeleteUserDialogErrors {
  userDeleteError = 'user_delete_error'
}

export type State = {
  dialogError?: DeleteUserDialogErrors
  deleteInProgress: boolean
}

export type EventHandlers = {
  onDelete: () => void
  onCancel: () => void
}

export type UseEditUserDialogLogicProps = {
  onClose: () => void
  userIds: string[]
}
export type UseEditUserDialogLogic = [State, EventHandlers]

export const useDeleteUserDialog = ({ onClose, userIds }: UseEditUserDialogLogicProps): UseEditUserDialogLogic => {
  const { isDeleteAccountUsersInProgress, isDeleteAccountUsersSuccess, isDeleteAccountUsersFailed } = useAppSelector(
    _store => ({
      isDeleteAccountUsersInProgress: isPending(_store.users.api.deleteAccountUsersApiStatus),
      isDeleteAccountUsersSuccess: isSuccess(_store.users.api.deleteAccountUsersApiStatus),
      isDeleteAccountUsersFailed: isFailed(_store.users.api.deleteAccountUsersApiStatus)
    })
  )
  const dispatch = useAppDispatch()
  const [dialogError, setDialogError] = useState<DeleteUserDialogErrors | undefined>()

  // init
  useEffect(
    () =>
      // eslint-disable-next-line
      () => {
        if (isDeleteAccountUsersSuccess) {
          dispatch(resetAccountUsers())
          dispatch(getAccountUsers())
        }
        dispatch(resetDeleteAccountUsers())
        setDialogError(undefined)
      },
    [dispatch, isDeleteAccountUsersSuccess]
  )

  // Delete is finished
  useEffect(() => {
    if (isDeleteAccountUsersSuccess) {
      if (userIds.length > 1) {
        dispatch(setSuccessSnackBar({ message: 'delete_account_users_success', params: [userIds.length] }))
      } else {
        dispatch(setSuccessSnackBar({ message: 'delete_account_user_success', params: [userIds.join(', ')] }))
      }
      onClose()
    } else if (isDeleteAccountUsersFailed) {
      setDialogError(DeleteUserDialogErrors.userDeleteError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteAccountUsersSuccess, isDeleteAccountUsersFailed, userIds, dispatch])

  const onDelete = useCallback(() => {
    setDialogError(undefined)
    dispatch(deleteAccountUsers(userIds))
  }, [dispatch, userIds])

  const onCancel = useCallback(() => {
    onClose()
  }, [onClose])

  return useMemo(
    () => [
      {
        dialogError,
        deleteInProgress: isDeleteAccountUsersInProgress
      },
      {
        onDelete,
        onCancel
      }
    ],
    [dialogError, isDeleteAccountUsersInProgress, onDelete, onCancel]
  )
}
