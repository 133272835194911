import React, { useMemo } from 'react'

import { Grid, Button } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { SettingsPageActions } from 'types/SettingsPage'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import { useSettingsActionLogic } from './useSettingsActionLogic'

const BUTTON_I18N_KEY = 'ess.button'
const DIALOG_I18N_KEY = 'ess.dialog'

function SettingsActions({
  cancelDisabled,
  saveDisabled,
  onCancelConfirm,
  onSave,
  hasPageChanges
}: SettingsPageActions) {
  const buttonFormatMessage = useFormatMessage(BUTTON_I18N_KEY)
  const formatDialogMessage = useFormatMessage(DIALOG_I18N_KEY)
  const [state, eventHandlers] = useSettingsActionLogic({ cancelDisabled, saveDisabled, onCancelConfirm, onSave })

  return useMemo(
    () => (
      <Grid item>
        <Button
          variant="text"
          color="primary"
          onClick={eventHandlers.onCancel}
          size="small"
          data-testid="cancel-button"
          disabled={state.disableCancel}
        >
          {buttonFormatMessage('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          size="small"
          data-testid="save-button"
          disabled={!hasPageChanges && state.disableSave}
        >
          {buttonFormatMessage('save')}
        </Button>
        <WarningDialog
          open={state.isWarningDialogOpen}
          title={formatDialogMessage('unsaved_changes')}
          onCancel={eventHandlers.closeDialog}
          onConfirm={() => {
            eventHandlers.closeDialog()
            onCancelConfirm()
          }}
        />
      </Grid>
    ),
    [buttonFormatMessage, formatDialogMessage, onCancelConfirm, onSave, state, eventHandlers, hasPageChanges]
  )
}

export default SettingsActions
