import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  dialogTitle: {
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  }
}))
