import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { getPoliciesSettings, resetPoliciesSettings, updatePoliciesSettings } from 'redux/features/users/usersSlice'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { AvailablePoliciesSettings, ExemptSenders, ManagedPolicy, PoliciesSettings } from 'types/users'
import { DEFAULT_TIMEZONE } from 'lib/datetime'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'
import { RadioButtonStates } from '../usersTypes'

export interface State {
  inProgress: boolean
  isLoading: boolean
  isFailedToLoad: boolean
  isDirty: boolean
  form: PoliciesSettings
}

const RADIO_BUTTON_NAMES = [
  AvailablePoliciesSettings.DEFAULT_USER_BLOCK_ENABLED,
  AvailablePoliciesSettings.DEFAULT_USER_DELIVER_ENABLED,
  AvailablePoliciesSettings.DEFAULT_USER_QUAR_DELIVER_ENABLED
]

export interface EventHandlers {
  onFormChange: (name: AvailablePoliciesSettings) => (NewValue: any) => void
  onSaveChanges: () => void
}

export type UseEmailContinuityLogic = [State, EventHandlers]
export const useDefaultPolicyLogic = (): UseEmailContinuityLogic => {
  const dispatch = useAppDispatch()
  const {
    policySettings,
    inProgress,
    isLoading,
    isFailedToLoad,
    isUpdatePoliciesSettingsIsSuccess,
    isUpdatePoliciesSettingsIsFailed,
    isGetPoliciesSettingsIsSuccess
  } = useAppSelector(_store => ({
    policySettings: _store.users.policiesSettings,
    inProgress: isPending(_store.users.api.updatePoliciesSettingsApiStatus),
    isLoading: isPending(_store.users.api.getPoliciesSettingsApiStatus),
    isFailedToLoad: isFailed(_store.users.api.getPoliciesSettingsApiStatus),
    isUpdatePoliciesSettingsIsSuccess: isSuccess(_store.users.api.updatePoliciesSettingsApiStatus),
    isUpdatePoliciesSettingsIsFailed: isFailed(_store.users.api.updatePoliciesSettingsApiStatus),
    isGetPoliciesSettingsIsSuccess: isSuccess(_store.users.api.getPoliciesSettingsApiStatus)
  }))

  const [form, setForm] = useState<PoliciesSettings>({
    defaultManagedScanPolicy: ManagedPolicy.scan,
    defaultUnmanagedScanPolicy: ManagedPolicy.scan,
    defaultUserExemptEnabled: ExemptSenders.allow,
    defaultUserBlockEnabled: true,
    defaultUserDeliverEnabled: true,
    defaultUserQuarDeliverEnabled: false,
    defaultTimezone: DEFAULT_TIMEZONE
  })
  const [{ isDirty, changedSettings }, resetInitialForm] = useDirtyFormObjectCheck(form)

  // init
  useEffect(
    () => {
      dispatch(getPoliciesSettings())
      trackEventInAllServices(TRACKING_EVENTS.ADMIN.DEFAULT_POLICY_PAGE_VIEW)

      return () => {
        dispatch(resetPoliciesSettings())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // get is success
  useEffect(() => {
    if (isGetPoliciesSettingsIsSuccess && policySettings) {
      const nextForm = {
        [AvailablePoliciesSettings.DEFAULT_MANAGED_SCAN_POLICY]: policySettings.defaultManagedScanPolicy,
        [AvailablePoliciesSettings.DEFAULT_UNMANAGED_SCAN_POLICY]: policySettings.defaultUnmanagedScanPolicy,
        [AvailablePoliciesSettings.DEFAULT_USER_EXEMPT_ENABLED]: policySettings.defaultUserExemptEnabled,
        [AvailablePoliciesSettings.DEFAULT_USER_BLOCK_ENABLED]:
          typeof policySettings.defaultUserBlockEnabled === 'boolean' ? policySettings.defaultUserBlockEnabled : true,
        [AvailablePoliciesSettings.DEFAULT_USER_DELIVER_ENABLED]:
          typeof policySettings.defaultUserDeliverEnabled === 'boolean'
            ? policySettings.defaultUserDeliverEnabled
            : true,
        [AvailablePoliciesSettings.DEFAULT_USER_QUAR_DELIVER_ENABLED]:
          typeof policySettings.defaultUserQuarDeliverEnabled === 'boolean'
            ? policySettings.defaultUserQuarDeliverEnabled
            : false,
        [AvailablePoliciesSettings.DEFAULT_TIMEZONE]: policySettings.defaultTimezone
      }
      resetInitialForm(nextForm)
      setForm(nextForm)
    }
  }, [dispatch, resetInitialForm, isGetPoliciesSettingsIsSuccess, policySettings])

  // update is success
  useEffect(() => {
    if (isUpdatePoliciesSettingsIsSuccess) {
      dispatch(setSuccessSnackBar({ message: 'update_policies_settings_success' }))
    }
  }, [dispatch, isUpdatePoliciesSettingsIsSuccess])

  // update is Failed
  useEffect(() => {
    if (isUpdatePoliciesSettingsIsFailed) {
      dispatch(setErrorSnackBar({ message: 'update_policies_settings_failed' }))
    }
  }, [dispatch, isUpdatePoliciesSettingsIsFailed])

  const onFormChange = useCallback(
    (name: AvailablePoliciesSettings) => (newValue: any) => {
      let setValue = newValue
      if (RADIO_BUTTON_NAMES.includes(name)) {
        setValue = newValue === RadioButtonStates.on
      }
      setForm({ ...form, [name]: setValue })
    },
    [form]
  )

  const onSaveChanges = useCallback(() => {
    dispatch(updatePoliciesSettings(changedSettings as Partial<PoliciesSettings>))
  }, [dispatch, changedSettings])

  return useMemo(
    () => [
      {
        inProgress,
        isLoading,
        isFailedToLoad,
        isDirty,
        form
      },
      {
        onFormChange,
        onSaveChanges
      }
    ],
    [inProgress, isLoading, isFailedToLoad, isDirty, form, onFormChange, onSaveChanges]
  )
}
