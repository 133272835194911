import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@barracuda-internal/bds-core'

import styles from 'components/libs/scheduler/scheduleMatrixStyles'
import useScheduleMatrixLogic from 'components/libs/scheduler/useScheduleMatrixLogic'
import { ScheduleMatrixProps } from 'components/libs/scheduler/schedulerMatrixTypes'

const BASE_I18N_KEY = 'ess.scheduler'

const ScheduleMatrix = (props: ScheduleMatrixProps) => {
  const intl = useIntl()
  const classes = styles()
  const sheduleMatrixLogic = useScheduleMatrixLogic(props)
  const { isEnabled } = props

  return useMemo(() => {
    const { hours, days, container, scheduleRef } = sheduleMatrixLogic

    return (
      <div>
        <table className={classes.table}>
          <thead>
            <tr>
              <td />
              {hours.map(val => (
                <th scope="col" key={val} className={classes.hoursRow}>
                  <Typography variant="subtitle2" className={classes.hoursCell}>{`${val}:00`}</Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {days.map(day => (
              <tr key={day}>
                <th scope="row">
                  <Typography variant="subtitle2">{intl.formatMessage({ id: `${BASE_I18N_KEY}.${day}` })}</Typography>
                </th>
                {hours.map(hour => (
                  <td
                    {...container}
                    data-time={`${day}-${hour}`}
                    key={`${day}-${hour}`}
                    className={`${scheduleRef?.current?.[day]?.[hour] ? 'selected-cell' : ''} ${
                      !isEnabled ? 'disableCss' : ''
                    }`}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }, [classes, intl, sheduleMatrixLogic, isEnabled])
}

export default ScheduleMatrix
