import { colors, makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  textInput: {
    '& input': {
      fontSize: 12,
      padding: theme.spacing(1)
    }
  },
  emailTestRecipientUser: {
    '& input': {
      textAlign: 'right'
    }
  },
  emailTestRecipientDomain: {
    color: colors.scaleGray500
  },
  smtpErrorDetails: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  warningIcon: {
    width: 40,
    height: 40,
    color: colors.statusWarning,
    marginRight: theme.spacing(2)
  },
  warningGrid: {
    flexWrap: 'nowrap'
  },
  warningGridIcon: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center'
  },
  warningGridBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',

    '& span': {
      display: 'inline-block',
      fontWeight: 600
    }
  }
}))

export default styles
