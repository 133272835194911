import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SelfService3(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      Set <b>Virus Protection</b> to <b>On</b> to have {props.productName} detect and block viruses on inbound email.
      <br />
      <br />
      Set <b>Spam Protection</b> to <b>On</b> to have {props.productName} evaluate inbound mail for spam based on a
      score assigned to each message processed.
      <br />
      <br />
      With <b>Spam Protection</b> set to <b>On</b>, Spam Scoring becomes enabled. Scoring ranges from 1 (unlikely spam)
      to 10 (definitely spam). Setting a score of 1 will likely block legitimate messages while setting a score of 10
      will allow more messages through the system. Based on this score, {props.productName} will block messages that
      appear to be spam and they will be logged in the user&apos;s Message Log with Score as the reason for the block.
      Note that you can enable this feature, but disable Spam Scoring. Please look below for additional options that are
      turned <b>On</b>.
      <br />
      <br />
      Setting <b>Spam Protection</b> to <b>Off</b> means that inbound mail will not be scanned for Spam.
      <br />
      <br />
      Note that the following features, configured on the <b>INBOUND SETTINGS &gt; Anti-Spam/Antivirus</b> page, will be
      enabled when Spam Protection is set to <b>On</b>, and disabled when Spam Protection is set to <b>Off</b>.
      <ul>
        <li>
          Barracuda Reputation Block List (BRBL). The Barracuda Reputation Block List (BRBL) is a database of IP
          addresses that have been manually verified to be a noted source of spam.
        </li>
        <li>
          Barracuda Real-Time System (BRTS) - The Barracuda Real-Time System is an advanced service to detect zero-hour
          spam and virus outbreaks even where traditional heuristics and signatures to detect such messages do not yet
          exist.
        </li>
        <li>Sender Policy Framework (SPF) - Block Fail is disabled.</li>
        <li>
          Barracuda Anti-Fraud Intelligence - This is Barracuda Networks anti-phishing detection, which uses a special
          Bayesian database for detecting Phishing scams.
        </li>
        <li>Blocking based on Intent Analysis.</li>
        <li>
          CloudScan Scoring- A cloud-based spam scanning engine, which assigns a score to each message processed ranging
          from 0 (definitely not spam) to 10 (definitely spam).
        </li>
      </ul>
      <p>
        Click <b>Skip</b> to bypass this step (you can configure these settings later) or <b>Next</b> to continue.
      </p>
    </div>
  )
}

export default SelfService3
