import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'components/pages/overview/dashboard/dashboardStyles'
import TableWithDropdown from 'components/libs/tableWithDropdown/TableWithDropdown'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'
import { useTopDomainsLogic, UseTopDomainsLogicProps } from './useTopDomainsLogic'

const TopDomains: React.FC<UseTopDomainsLogicProps> = (props: UseTopDomainsLogicProps) => {
  const classes = styles()
  const [state, eventHandlers] = useTopDomainsLogic(props)

  const config = getTableWithDropdownConfig(props.domainId ? DisplayType.senderDomains : DisplayType.recipientDomains)
  return useMemo(
    () => (
      <Grid className={classes.tableWrapper} item data-testid="container">
        {config && (
          <TableWithDropdown
            config={config}
            data={state.topDomains?.results}
            onSelect={eventHandlers.onSelectFilter}
            selectedValue={state.selectedFilter}
            inProgress={state.topDomainsInProgress}
            rankColumn
          />
        )}
      </Grid>
    ),
    [state, classes, config, eventHandlers]
  )
}

export default TopDomains
