import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { appTypeRelatedPaths } from 'config/uiPaths'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { isMyFeatureOn } from 'lib/splitio'

export default function validateRouteAccess<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidateRouteAccess: React.FC<any> = props => {
    const { activePath, appType } = useAppSelector(_store => ({
      activePath: _store.app.activePath,
      appType: _store.app.appType
    }))
    const [shouldRenderComponent, setShouldRenderComponent] = useState<boolean>(false)
    const { appTypeEntryPath, appTypeLoginEntryPath } = useAppTypeEntryPaths()

    // validate custom authentication values
    useEffect(() => {
      const appTypeAccess = appTypeRelatedPaths[appType].includes(activePath.id || '')
      const splitIoChecks = activePath.metadata?.splitIoFlags
        ? (activePath.metadata.splitIoFlags as string[]).every((flag: string) => isMyFeatureOn(flag))
        : true
      const entryPath = activePath.isPublicRoute ? appTypeLoginEntryPath : appTypeEntryPath

      if (!appTypeAccess) {
        entryPath.goto()
      } else if (!splitIoChecks) {
        entryPath.goto()
      } else {
        setShouldRenderComponent(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appTypeLoginEntryPath, appTypeEntryPath])

    return useMemo(() => {
      if (!shouldRenderComponent) {
        return null
      }

      return <WrappedComponent {...(props as any)} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRenderComponent])
  }

  return ValidateRouteAccess
}
