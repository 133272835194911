import React from 'react'
import { Autocomplete, IconButton, TextField } from '@barracuda-internal/bds-core'
import { InputAdornment } from '@mui/material'
import { Search } from '@material-ui/icons'

import { useStyles } from './searchFieldStyles'

export type SearchFieldProps = {
  /**
   * If true, the component is disabled.
   */
  disabled?: boolean
  /**
   * The ID passed to the Autocomplete component.
   */
  id: string
  /**
   *  The label content.
   */
  label?: string
  /**
   * Callback fired when the value is changed.
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  /**
   * Callback fired when the search icon is clicked.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * Optional array of options used to determine if search is autocomplete.
   */
  options?: string[]
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string
  /**
   * The size of the component.
   */
  size?: 'small' | 'medium'
  /**
   * The value of the component.
   */
  value?: string
}

export function SearchField({
  id,
  disabled = false,
  label,
  options,
  onChange,
  onClick,
  placeholder,
  size = 'small',
  value
}: SearchFieldProps) {
  const styles = useStyles()

  return options?.length ? (
    <Autocomplete
      data-testid={id}
      disableClearable
      value={value}
      freeSolo
      id={id}
      fullWidth
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          className={styles.textField}
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="search icon button" onClick={onClick} size={size}>
                  <Search fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
      size={size}
    />
  ) : (
    <TextField
      id={id}
      data-testid={id}
      className={styles.textField}
      disabled={disabled}
      label={label}
      fullWidth
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      size={size}
      InputProps={{
        type: 'search',
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={disabled ? styles.searchButtonDisabled : ''}
              aria-label="search icon button"
              onClick={onClick}
              edge="end"
              size={size}
            >
              <Search fontSize="inherit" />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
