import { createStyles, colors, makeStyles } from '@barracuda-internal/bds-core'

export const commonStyles = createStyles({
  '@global': {
    body: {
      backgroundColor: colors.white
    }
  },
  title: {
    color: colors.scaleGray900
  },
  subtitle: {
    color: colors.scaleGray600
  }
})

export const wizardStyles = makeStyles(theme => ({
  logo: {
    margin: theme.spacing(3)
  }
}))
