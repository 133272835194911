import React, { useMemo } from 'react'

import {
  Box,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  Select,
  SelectLabel,
  SelectWrapper,
  TextField
} from '@barracuda-internal/bds-core'
import { Close, Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { Tune } from '@material-ui/icons'

import { useFormatMessage } from 'lib/localization'
import { onKeyDown } from 'lib/keyEvents'
import { presetDateItems } from 'config/presetMenuItems'

import AdvancedSearch from 'components/pages/messageLog/messageLogSearchBar/advancedSearch/AdvancedSearch'
import styles from 'components/pages/messageLog/messageLogSearchBar/messageLogSearchBarStyles'
import { useMessageLogSearchBarLogic } from 'components/pages/messageLog/messageLogSearchBar/useMessageLogSearchBarLogic'
import { ActionTaken, MessageDirection } from 'types/Messages'
import FluidRow from 'components/libs/fluidRow/FluidRow'

const BASE_I18N_KEY = 'ess.message_log'

const MessageLogSearchbar = () => {
  const {
    searchTerm,
    searchField,
    advancedSearch,
    dateField,
    actionTakenField,
    statusField,
    reasonField,
    handleSearch,
    handleFilterChange,
    disableSearch,
    domainsField,
    permissions,
    messageDirectionField,
    viewConfig,
    changedSelects
  } = useMessageLogSearchBarLogic()

  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const domainMenu = useMemo(
    () => (
      <SelectWrapper key="domain" size="small" variant="outlined">
        <SelectLabel id="domain">{formatMessage('domain_menu.label')}</SelectLabel>
        <Select
          label={formatMessage('domain_menu.label')}
          labelId="domain"
          className={`${classes.selectMenus} ${changedSelects.domain_id ? 'active' : ''}`}
          disabled={domainsField.disabled}
          name="domain_id"
          data-id="domains-dropdown"
          value={domainsField.selectedItem}
          onChange={domainsField.handleDomainChange}
          data-testid="domain-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          {domainsField.searchAllEnabled && (
            <MenuItem key="all" className={`${classes.menuItem}`} value="all">
              All domains
            </MenuItem>
          )}
          {domainsField.menuItems.map(({ domainId, domainName }) => (
            <MenuItem key={domainId} className={`${classes.menuItem}`} value={domainId}>
              {domainName}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, domainsField, changedSelects]
  )

  const directionMenu = useMemo(
    () => (
      <SelectWrapper key="direction" size="small" variant="outlined">
        <SelectLabel id="direction">{formatMessage('message_direction_menu.label')}</SelectLabel>
        <Select
          label={formatMessage('message_direction_menu.label')}
          labelId="direction"
          className={`${classes.selectMenus} ${changedSelects.outbound ? 'active' : ''}`}
          name="outbound"
          data-id="message-direction-dropdown"
          value={messageDirectionField.selectedItem}
          onChange={messageDirectionField.handleMessageDirectionChange}
          data-testid="message-direction-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          <MenuItem className={`${classes.menuItem}`} value={MessageDirection.INBOUND}>
            {formatMessage('message_direction_menu.inbound')}
          </MenuItem>
          <MenuItem className={`${classes.menuItem}`} value={MessageDirection.OUTBOUND}>
            {formatMessage('message_direction_menu.outbound')}
          </MenuItem>
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, messageDirectionField, changedSelects]
  )

  const dateMenu = useMemo(
    () => (
      <SelectWrapper key="preset" size="small" variant="outlined">
        <SelectLabel id="preset">{formatMessage('preset_menu.label')}</SelectLabel>
        <Select
          label={formatMessage('preset_menu.label')}
          labelId="preset"
          className={`${classes.selectMenus} ${changedSelects.dateRange ? 'active' : ''}`}
          name="presets"
          data-id="date-present-dropdown"
          value={searchTerm.dateRange ? searchTerm.dateRange : 0}
          onChange={dateField.handleDateChange}
          data-testid="date-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          {presetDateItems.map(({ value, label }) => (
            <MenuItem key={label} className={`${classes.menuItem} ${value === 0 ? 'custom' : ''}`} value={value}>
              {formatMessage(`preset_menu.${label}`)}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, searchTerm, dateField, changedSelects]
  )

  const actionMenu = useMemo(
    () => (
      <SelectWrapper key="action" size="small" variant="outlined">
        <SelectLabel id="action">{formatMessage('action.label')}</SelectLabel>
        <Select
          label={formatMessage('action.label')}
          labelId="action"
          className={`${classes.selectMenus} ${changedSelects.action ? 'active' : ''}`}
          name="action"
          data-id="action-taken-dropdown"
          value={actionTakenField.selectedItem}
          onChange={handleFilterChange}
          data-testid="action-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          {actionTakenField.menuItems.map((label: string, idx: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={label} key={idx}>
              {formatMessage(`action.${label}`)}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, actionTakenField, changedSelects, handleFilterChange]
  )

  const statusMenu = useMemo(
    () => (
      <SelectWrapper key="delivery" size="small" variant="outlined">
        <SelectLabel id="delivery">{formatMessage('status.label')}</SelectLabel>
        <Select
          label={formatMessage('status.label')}
          labelId="delivery"
          disabled={actionTakenField.selectedItem === ActionTaken.emailContinuity}
          className={`${classes.selectMenus} ${changedSelects.delivery_status ? 'active' : ''}`}
          name="delivery_status"
          data-id="delivery-status-dropdown"
          value={statusField.selectedItem}
          onChange={handleFilterChange}
          data-testid="delivery-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          {statusField.menuItems.map((label: string, idx: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={label} key={idx}>
              {formatMessage(`status.${label}`)}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, actionTakenField, changedSelects, statusField, handleFilterChange]
  )

  const reasonMenu = useMemo(
    () => (
      <SelectWrapper key="reason" size="small" variant="outlined">
        <SelectLabel id="reason">{formatMessage('reason.label')}</SelectLabel>
        <Select
          label={formatMessage('reason.label')}
          labelId="reason"
          disabled={actionTakenField.selectedItem === ActionTaken.emailContinuity}
          className={`${classes.selectMenus} ${changedSelects.reason ? 'active' : ''}`}
          name="reason"
          data-id="reason-dropdown"
          value={reasonField.selectedItem}
          onChange={handleFilterChange}
          data-testid="reason-select"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: -10,
              horizontal: 'center'
            }
          }}
        >
          {reasonField.menuItems.map((label: string, idx: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={label} key={idx}>
              {formatMessage(`reason.${label}`)}
            </MenuItem>
          ))}
        </Select>
      </SelectWrapper>
    ),
    [formatMessage, classes, reasonField, actionTakenField, changedSelects, handleFilterChange]
  )

  const getMenus = useMemo(() => {
    const menus = []

    if (permissions.canFilterAboutDomains) {
      menus.push(domainMenu)
    }
    if (viewConfig.filters.showMessageDirection && permissions.canFilterAboutDirections) {
      menus.push(directionMenu)
    }
    if (viewConfig.filters.showDatePresets) {
      menus.push(dateMenu)
    }
    if (viewConfig.filters.showAction) {
      menus.push(actionMenu)
    }
    if (viewConfig.filters.showStatus) {
      menus.push(statusMenu)
    }
    if (viewConfig.filters.showReason) {
      menus.push(reasonMenu)
    }
    return menus
  }, [permissions, viewConfig, domainMenu, directionMenu, dateMenu, actionMenu, statusMenu, reasonMenu])

  return useMemo(
    () => (
      <Grid
        container
        className={classes.outerWrapper + (disableSearch ? ` ${classes.disableBtn}` : '')}
        data-testid="root-grid"
      >
        <FluidRow
          buttonText={formatMessage('more_filters')}
          fixedContainer={
            <Grid className={classes.searchTextFieldWrapper}>
              <TextField
                size="small"
                className={classes.searchTextField}
                label={formatMessage('search_bar.search')}
                placeholder={formatMessage('search_bar.find_hint')}
                variant="outlined"
                value={searchTerm.search_str}
                onChange={searchField.handleInputChange}
                innerRef={searchField.inputElement}
                onKeyDown={onKeyDown(['Enter'], () => handleSearch())}
                disabled={advancedSearch.openAdvancedSearch}
                InputProps={{
                  startAdornment: (
                    <IconButton
                      size="small"
                      edge="start"
                      data-id="search-button"
                      data-testid="search-button"
                      onClick={() => handleSearch()}
                    >
                      <Search />
                    </IconButton>
                  ),
                  endAdornment: (
                    <>
                      {searchTerm.search_str.length > 0 && (
                        <IconButton
                          size="small"
                          edge="end"
                          data-id="search-bar-clear-button"
                          data-testid="clear-button"
                          onClick={searchField.handleClearTerm}
                        >
                          <Close />
                        </IconButton>
                      )}
                      {!advancedSearch.openAdvancedSearch && (
                        <IconButton
                          size="small"
                          edge="end"
                          data-id="adv-search-start-button"
                          data-testid="advanced-button"
                          onClick={() => advancedSearch.setOpenAdvancedSearch(!advancedSearch.openAdvancedSearch)}
                        >
                          <Tune />
                        </IconButton>
                      )}
                    </>
                  )
                }}
                data-testid="search-input"
              />
              <Popper
                className={classes.advancedSearch}
                open={advancedSearch.openAdvancedSearch}
                anchorEl={searchField.inputElement.current}
                transition
                modifiers={{
                  offset: {
                    enabled: true,
                    offset: '0, 8'
                  }
                }}
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'left top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <AdvancedSearch onClose={() => advancedSearch.setOpenAdvancedSearch(false)} />
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          }
        >
          {getMenus}
        </FluidRow>
      </Grid>
    ),
    [classes, disableSearch, formatMessage, handleSearch, searchTerm, searchField, advancedSearch, getMenus]
  )
}

export default MessageLogSearchbar
