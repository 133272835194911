import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export type VerticalBarChartStackedIconProps = SvgIconProps

export function VerticalBarChartStackedIcon(props: VerticalBarChartStackedIconProps) {
  return (
    <SvgIcon id="icon-stacked-bar-chart" viewBox="0 0 16 16" {...props}>
      <path d="M6.875 11.4583H4.375L4.375 16.0417H6.875L6.875 11.4583Z" fill="currentColor" />
      <path d="M11.375 8.95834H9.04165L9.04165 16.0417H11.375L11.375 8.95834Z" fill="currentColor" />
      <path d="M16.0417 12.2917H13.7083L13.7083 16.0417H16.0416L16.0417 12.2917Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45833 6.04168H4.79167L4.79167 11.0417H6.45833L6.45833 6.04168ZM10.9262 4.37501L9.47002 4.37501V8.54168H10.9262V4.37501ZM15.625 6.04168L14.1508 6.04031L14.1508 11.8736L15.625 11.875L15.625 6.04168ZM4.375 5.62501L4.375 11.4583H6.875L6.875 5.62501H4.375ZM9.04167 3.95834L9.04165 8.95834H11.375L11.375 3.95834L9.04167 3.95834ZM13.7083 5.62501L13.7083 12.2917H16.0417V5.62501H13.7083Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
