import { v4 as makeUuid } from 'uuid'

export interface MixpanelProperties {
  $os: string
  $browser: string
  $referrer: string
  $referring_domain: string
  $current_url: string
  $browser_version: string
  $screen_height: number
  $screen_width: number
  mp_lib: string
  $insert_id: string
  time: number
  $device_id: string
  $initial_referrer: string
  $initial_referring_domain: string
}
export function getMixpanelProperties(): Partial<MixpanelProperties> {
  const properties: Partial<MixpanelProperties> = {}

  properties.$os = getOperatingSystem()
  properties.$browser = window.navigator.userAgent
  properties.$current_url = window.location.href
  const browserVersion = detectBrowserVersion()
  if (browserVersion) {
    properties.$browser_version = browserVersion
  } else {
    properties.$browser_version = 'Unknown' // Fallback if browser version detection fails
  }
  properties.$screen_height = window.screen.height
  properties.$screen_width = window.screen.width
  properties.$insert_id = makeUuid()
  properties.time = Date.now()
  properties.$device_id = getDeviceId()
  properties.$initial_referrer = document.referrer
  properties.$initial_referring_domain = window.location.hostname

  return properties as Partial<MixpanelProperties>
}

const getOperatingSystem = (): string => {
  const { userAgent } = window.navigator

  if (/Mac/i.test(userAgent)) {
    return 'Mac OS'
  }
  if (/Win/i.test(userAgent)) {
    return 'Windows'
  }
  if (/Linux/i.test(userAgent)) {
    return 'Linux'
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS'
  }
  if (/Android/i.test(userAgent)) {
    return 'Android'
  }
  return 'Unknown'
}
const detectBrowserVersion = (): string => {
  const { userAgent } = window.navigator
  const match = userAgent.match(/(Chrome|Safari|Firefox|Edge|IE|Opera(?=\/))\/?\s*(\d+)/i)
  if (match && match.length >= 3) {
    return `${match[1]} ${match[2]}`
  }
  return 'Unknown'
}

const getDeviceId = (): string => {
  let deviceId = localStorage.getItem('device_id')
  if (!deviceId) {
    deviceId = makeUuid()
    localStorage.setItem('device_id', deviceId)
  }
  return deviceId
}
