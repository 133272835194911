import React, { useMemo } from 'react'

import { DateTime, TSSettings } from 'luxon'
import { DateRange, DateRangeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro'

import { useFormatMessage } from 'lib/localization'
import { RelativeDateRanges, RETENTION_POLICY_DAY_RANGE } from 'types/reports'
import {
  DateRangePicker,
  DEFAULT_SHORTCUT_ITEMS,
  ShortcutItems
} from 'components/pages/reports/reportList/customizedBDSComponents/DateRangePicker'

const AVAILABLE_SHORTCUT_ITEMS = [ShortcutItems.lastDay, ShortcutItems.last7days, ShortcutItems.last30days]
const ASSOCIATED_RELATIVE_DATE_RANGES: { [keys in ShortcutItems]?: RelativeDateRanges } = {
  [ShortcutItems.lastDay]: RelativeDateRanges.lastDay,
  [ShortcutItems.last7days]: RelativeDateRanges.last7Days,
  [ShortcutItems.last30days]: RelativeDateRanges.lastMonth
}

const BASE_I18N_KEY = 'ess.reports'

export type DateRangeOnChangeType = (
  newValue: DateRange<TSSettings>,
  selectorConfig: PickerChangeHandlerContext<DateRangeValidationError>
) => void

export interface DateRangePickerProps {
  disabled?: boolean
  onOpen?: () => void
  onClose?: () => void
  onChange: DateRangeOnChangeType
  value: DateRange<TSSettings>
  errorMessage?: string
}

const DateRangeSelector = ({
  disabled = false,
  onOpen,
  onClose,
  onChange,
  value,
  errorMessage
}: DateRangePickerProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <DateRangePicker
        singleInput
        customProps={{
          closeOnSelect: false,
          disabled,
          onOpen,
          onClose
        }}
        shortcutItems={AVAILABLE_SHORTCUT_ITEMS.map((shortcutItem: ShortcutItems) => ({
          ...DEFAULT_SHORTCUT_ITEMS[shortcutItem],
          label: formatMessage(`shortcut_items.${shortcutItem}`),
          relativeDateRange: ASSOCIATED_RELATIVE_DATE_RANGES[shortcutItem]
        }))}
        error={!!errorMessage}
        errorMessage={errorMessage}
        onChange={onChange}
        value={value}
        defaultValue={value}
        maxDate={DateTime.now()}
        minDate={DateTime.now().minus({ day: RETENTION_POLICY_DAY_RANGE })}
      />
    ),
    [onOpen, onClose, formatMessage, disabled, onChange, value, errorMessage]
  )
}
export default DateRangeSelector
