import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import {
  getAccountEmailContinuitySettings,
  resetAccountEmailContinuitySettings,
  updateAccountEmailContinuitySettings
} from 'redux/features/users/usersSlice'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  isEnableEmailContinuity: RadioButtonStates
  inProgress: boolean
  isLoading: boolean
  isFailedToLoad: boolean
  isConfirmDialogOpened: boolean
  isDirtyForm: boolean
}

export interface EventHandlers {
  setIsEnableEmailContinuity: Dispatch<SetStateAction<RadioButtonStates>>
  onSaveChanges: () => void
  onConfirm: () => void
  onCloseConfirmDialog: () => void
}

export type UseEmailContinuityLogic = [State, EventHandlers]
export const useEmailContinuityLogic = (): UseEmailContinuityLogic => {
  const dispatch = useAppDispatch()
  const {
    emailContinuitySettings,
    inProgress,
    isLoading,
    isFailedToLoad,
    isUpdateEmailContinuityStateIsSuccess,
    isUpdateEmailContinuityStateIsFailed,
    isGetEmailContinuityStateIsSuccess,
    isGetEmailContinuityStateIsFailed
  } = useAppSelector(_store => ({
    emailContinuitySettings: _store.users.accountEmailContinuitySettings,
    inProgress: isPending(_store.users.api.updateAccountEmailContinuitySettingsApiStatus),
    isLoading: isPending(_store.users.api.getAccountEmailContinuitySettingsApiStatus),
    isFailedToLoad: isFailed(_store.users.api.getAccountEmailContinuitySettingsApiStatus),
    isUpdateEmailContinuityStateIsSuccess: isSuccess(_store.users.api.updateAccountEmailContinuitySettingsApiStatus),
    isUpdateEmailContinuityStateIsFailed: isFailed(_store.users.api.updateAccountEmailContinuitySettingsApiStatus),
    isGetEmailContinuityStateIsSuccess: isSuccess(_store.users.api.getAccountEmailContinuitySettingsApiStatus),
    isGetEmailContinuityStateIsFailed: isFailed(_store.users.api.getAccountEmailContinuitySettingsApiStatus)
  }))

  const [isEnableEmailContinuity, setIsEnableEmailContinuity] = useState<RadioButtonStates>(RadioButtonStates.off)
  const [isConfirmDialogOpened, setIsConfirmDialogOpened] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([isEnableEmailContinuity])
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)

  // init
  useEffect(
    () => {
      dispatch(getAccountEmailContinuitySettings())
      trackEventInAllServices(TRACKING_EVENTS.ADMIN.EMAIL_CONTINUITY_PAGE_VIEW)

      return () => {
        dispatch(resetAccountEmailContinuitySettings())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // get is success
  useEffect(() => {
    if (isGetEmailContinuityStateIsSuccess) {
      setIsEnableEmailContinuity(
        emailContinuitySettings?.isAutoEnabled || false ? RadioButtonStates.on : RadioButtonStates.off
      )
      setShouldUpdateInitialForm(true)
    }
  }, [dispatch, isGetEmailContinuityStateIsSuccess, emailContinuitySettings])

  // get is Failed
  useEffect(() => {
    if (isGetEmailContinuityStateIsFailed) {
      dispatch(setErrorSnackBar({ message: 'get_email_continuity_settings_failed' }))
    }
  }, [dispatch, isGetEmailContinuityStateIsFailed])

  // update is success
  useEffect(() => {
    if (isUpdateEmailContinuityStateIsSuccess) {
      dispatch(setSuccessSnackBar({ message: 'update_email_continuity_settings_success' }))
    }
  }, [dispatch, isUpdateEmailContinuityStateIsSuccess])

  // update is Failed
  useEffect(() => {
    if (isUpdateEmailContinuityStateIsFailed) {
      dispatch(setErrorSnackBar({ message: 'update_email_continuity_settings_failed' }))
    }
  }, [dispatch, isUpdateEmailContinuityStateIsFailed])

  const doUpdateSettings = useCallback(() => {
    setIsConfirmDialogOpened(false)
    dispatch(updateAccountEmailContinuitySettings(isEnableEmailContinuity === RadioButtonStates.on))
  }, [dispatch, isEnableEmailContinuity])

  const onSaveChanges = useCallback(() => {
    if (isEnableEmailContinuity === RadioButtonStates.on && emailContinuitySettings?.isSpooling) {
      setIsConfirmDialogOpened(true)
    } else {
      doUpdateSettings()
    }
  }, [emailContinuitySettings, doUpdateSettings, isEnableEmailContinuity])

  const onCloseConfirmDialog = useCallback(() => {
    setIsConfirmDialogOpened(false)
  }, [])

  return useMemo(
    () => [
      {
        isEnableEmailContinuity,
        inProgress,
        isLoading,
        isFailedToLoad,
        isConfirmDialogOpened,
        isDirtyForm
      },
      {
        setIsEnableEmailContinuity,
        onSaveChanges,
        onConfirm: doUpdateSettings,
        onCloseConfirmDialog
      }
    ],
    [
      isEnableEmailContinuity,
      inProgress,
      isLoading,
      isFailedToLoad,
      setIsEnableEmailContinuity,
      onSaveChanges,
      isConfirmDialogOpened,
      doUpdateSettings,
      onCloseConfirmDialog,
      isDirtyForm
    ]
  )
}
