import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles({
  textArea: {
    width: '100%',
    marginTop: '20px'
  },
  cardContent: {
    width: '80%'
  },
  progress: {
    height: 3,
    marginTop: 0
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  subTitle: {
    color: colors.barracudaBlack50
  },
  actions: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16
  }
})
