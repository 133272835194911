import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DataTableState, SortDirection, UpdateDataTable } from 'types/redux/dataTables/dataTables'
import { AccountUserRole } from 'types/users'
import reducerHandlers from 'redux/features/dataTables/reduceHandlers'

export const GRID_COLUMNS = {
  SELECT: 'select',
  USER_ID: 'user_id',
  LINKED_ACCOUNTS: 'linked_accounts',
  SOURCE: 'source',
  ROLE: 'role',
  QUARANTINE: 'quarantine',
  ACTIONS: 'actions'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.SELECT]: {
    sortable: false,
    orderIndex: 0,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.USER_ID]: {
    sortable: true,
    orderIndex: 1,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.LINKED_ACCOUNTS]: {
    sortable: false,
    orderIndex: 2,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.SOURCE]: {
    sortable: true,
    orderIndex: 3,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.ROLE]: {
    sortable: false,
    orderIndex: 4,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.QUARANTINE]: {
    sortable: false,
    orderIndex: 5,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.ACTIONS]: {
    sortable: false,
    orderIndex: 6,
    show: true,
    resizable: true
  }
}

export const ITEMS_PER_PAGE = 50

export interface UsersTableState extends DataTableState {
  userRoleFilter: AccountUserRole
  domainFilter: string
}

export interface UpdateUsersTable extends UpdateDataTable {
  userRoleFilter?: AccountUserRole
  domainFilter?: string
}

// initialState
export const INITIAL_STATE: UsersTableState = {
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0, // offset
  take: ITEMS_PER_PAGE, // limit
  search: '',
  filter: null,
  sort: [{ field: GRID_COLUMNS.USER_ID, dir: SortDirection.asc }],
  userRoleFilter: AccountUserRole.all,
  domainFilter: 'all'
}

/* eslint-disable no-param-reassign */
export const usersSlice = createSlice({
  name: 'DATATABLE/USERS',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateUsersTable }>) =>
        reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload }),
      prepare: (config: UpdateUsersTable) => ({
        payload: { config }
      })
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  }
})
/* eslint-enable no-param-reassign */

export const { update, reset } = usersSlice.actions

export default usersSlice.reducer
