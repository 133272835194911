import React, { useMemo } from 'react'

import {
  Checkbox,
  CheckboxLabel,
  DataTable,
  DataTableColumn,
  Grid,
  MenuItem,
  Select,
  Slider,
  Typography
} from '@barracuda-internal/bds-core'

import { Cell } from 'components/libs/grid/cell'
import styles from 'components/pages/inboundSettings/reputation/reputationStyles'
import { useFormatMessage } from 'lib/localization'
import { useReputationLogic, ScoringType } from 'components/pages/inboundSettings/reputation/useReputationLogic'
import {
  AvailableSettings,
  SettingValue,
  EmailCategories,
  emailCategoriesActions,
  SettingActionValueStringBulkEmail
} from 'types/Settings'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import InboundAntiVirus from 'components/pages/support/help/inboundAntiVirus/InboundAntiVirus'
import { PageTitle, FormSpacer, FormRow, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'

const BASE_I18N_KEY = 'ess.inbound_settings.reputation'
const BASE_DIALOG_I18N_KEY = 'ess.dialog'

const Reputation: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatDialogMessage = useFormatMessage(BASE_DIALOG_I18N_KEY)
  const [state, eventHandlers] = useReputationLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer key={state.key} dirtyCheck isDirtyForm={state.isDirtyForm}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={eventHandlers.onCancelConfirm}
          onSubmit={eventHandlers.onSave}
          submitInProgress={UseGetSettingsStateLogic.isLoading}
          isDisabledSubmit={!state.isDirtyForm}
          help={{
            content: InboundAntiVirus,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.antiSpamAntivirus,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        {state.isWarningDialogOpen && (
          <WarningDialog
            title={formatDialogMessage('antivirus_off')}
            onCancel={eventHandlers.onCancelWarningDialog}
            onConfirm={eventHandlers.onConfirmWarningDialog}
          />
        )}
        <FormSpacer />
        <FormRow rowTitle={<>{formatMessage('use_reputation_block_list')} </>}>
          <InlineRadioGroup
            name={AvailableSettings.BRBL}
            labels={
              state.canQuarantineSettings
                ? [formatMessage('block'), formatMessage('quarantine'), formatMessage('off')]
                : [formatMessage('block'), formatMessage('off')]
            }
            value={state.settings.brbl}
            values={state.canQuarantineSettings ? ['1', '2', '0'] : ['1', '0']}
            onChange={eventHandlers.onFormChange(AvailableSettings.BRBL)}
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('scan_email_or_viruses')}>
          <InlineRadioGroup
            name={AvailableSettings.VSCAN}
            value={state.settings.vscan}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.VSCAN)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('yes'), formatMessage('no')]}
          />
        </FormRow>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle={formatMessage('use_real_time')}>
          <InlineRadioGroup
            name={AvailableSettings.BRTS}
            value={state.settings.brts}
            values={state.canQuarantineSettings ? ['1', '2', '0'] : ['1', '0']}
            onChange={eventHandlers.onFormChange(AvailableSettings.BRTS)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={
              state.canQuarantineSettings
                ? [formatMessage('block'), formatMessage('quarantine'), formatMessage('off')]
                : [formatMessage('block'), formatMessage('off')]
            }
          />
          {state.canSendBrtsEvidence && (
            <CheckboxLabel
              control={
                <Checkbox
                  checked={state.settings.brts_send_evidence === '1'}
                  onChange={eventHandlers.onFormChange(AvailableSettings.BRTS_SEND_EVIDENCE)}
                  name={AvailableSettings.BRTS_SEND_EVIDENCE}
                  color="primary"
                  size="small"
                  data-testid="real-time-checkbox"
                  disabled={UseGetSettingsStateLogic.isLoading}
                />
              }
              label={<Typography variant="body2">{formatMessage('send_to_barracuda')}</Typography>}
            />
          )}
        </FormRow>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle={formatMessage('enable_cloudscan')}>
          <InlineRadioGroup
            name={AvailableSettings.FASTSPAM_ENABLED}
            value={state.settings.fastspam_enabled}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            onChange={eventHandlers.onFormChange(AvailableSettings.FASTSPAM_ENABLED)}
            disabled={UseGetSettingsStateLogic.isLoading}
            labels={[formatMessage('yes'), formatMessage('no')]}
          />
          {state.settings.fastspam_enabled === '1' && (
            <Grid item className={classes.cloudscanScoringWrapper}>
              <Typography variant="body2" data-testid="cloudscan-scoring-title">
                <b>{formatMessage('cloudscan_scoring')}</b>
              </Typography>
              <Typography variant="body2" data-testid="cloudscan-scoring-description">
                {formatMessage('cloudscan_scoring_description')}
              </Typography>
              <table className={classes.cloudscanScoringTable} data-testid="cloudscan-scoring-table">
                <tbody>
                  <tr>
                    <td>{formatMessage('action')}</td>
                    <td>{formatMessage('score')}</td>
                    <td className={classes.sliderCell} />
                    <td>{formatMessage('enabled')}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage('block')}</td>
                    <td>
                      <input
                        lang="en_EN"
                        type="number"
                        min="0"
                        max="10"
                        step=".01"
                        className={classes.inputCell}
                        value={state.fastSpamScore.block}
                        onChange={event => {
                          eventHandlers.onScoringChange(
                            ScoringType.BLOCK,
                            event.target.value ? parseFloat(event.target.value) : 0.0
                          )
                        }}
                      />
                    </td>
                    <td>
                      <Slider
                        value={state.fastSpamScore.block}
                        onChange={(_, value) => eventHandlers.onScoringChange(ScoringType.BLOCK, value as number)}
                        min={0}
                        max={10}
                        step={1}
                      />
                    </td>
                    <td>
                      <Checkbox
                        checked={state.fastSpamScore.block > 0}
                        onChange={(_, checked) => eventHandlers.onScoringChange(ScoringType.BLOCK, checked ? 5.0 : 0.0)}
                        size="small"
                      />
                    </td>
                  </tr>
                  {state.canQuarantineSettings && (
                    <tr>
                      <td>{formatMessage('quarantine')}</td>
                      <td>
                        <input
                          lang="en_EN"
                          type="number"
                          min="0"
                          max="10"
                          step=".01"
                          className={classes.inputCell}
                          value={state.fastSpamScore.quarantine}
                          onChange={event =>
                            eventHandlers.onScoringChange(
                              ScoringType.QUARANTINE,
                              event.target.value ? parseFloat(event.target.value) : 0
                            )
                          }
                        />
                      </td>
                      <td>
                        <Slider
                          value={state.fastSpamScore.quarantine}
                          onChange={(_, value) =>
                            eventHandlers.onScoringChange(ScoringType.QUARANTINE, value as number)
                          }
                          min={0}
                          max={10}
                          step={1}
                        />
                      </td>
                      <td>
                        <Checkbox
                          checked={state.fastSpamScore.quarantine > 0}
                          onChange={(_, checked) =>
                            eventHandlers.onScoringChange(ScoringType.QUARANTINE, checked ? 5.0 : 0.0)
                          }
                          size="small"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          )}
        </FormRow>
        <FormSpacer />
        {state.canEmailCategorization && (
          <FormRow alignTitleToTop rowTitle={formatMessage('email_categorization')}>
            <DataTable data={state.tableData.data}>
              <DataTableColumn
                headerClassName={classes.nameCell}
                field="name"
                title={formatMessage('category_name')}
                cell={({ dataItem }: { dataItem: EmailCategories }) => (
                  <Cell data-testid="name-cell" className={classes.nameCell}>
                    <Typography variant="body2">{formatMessage(dataItem.name)}</Typography>
                  </Cell>
                )}
              />
              <DataTableColumn
                headerClassName={classes.actionCell}
                field="action"
                title={formatMessage('action')}
                cell={({ dataItem }: { dataItem: EmailCategories }) => (
                  <Cell data-testid="action-cell" className={classes.actionCell}>
                    <Select
                      variant="outlined"
                      className={classes.dropDownList}
                      name={dataItem.variable}
                      defaultValue={state.settings[dataItem.variable] || dataItem.default}
                      onChange={eventHandlers.onActionChange}
                      SelectDisplayProps={{ style: { padding: 8 } }}
                    >
                      {emailCategoriesActions.map(action => (
                        <MenuItem key={action.value} value={action.value}>
                          {formatMessage(action.label)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Cell>
                )}
              />
              <DataTableColumn
                field="description"
                headerClassName={classes.descriptionCell}
                title={formatMessage('description')}
                cell={({ dataItem }: { dataItem: EmailCategories }) => (
                  <Cell data-testid="description-cell" className={classes.descriptionCell}>
                    <Typography variant="body2">{formatMessage(dataItem.description)}</Typography>
                    <Typography variant="body2">
                      <b>{formatMessage('recommended')}:</b>
                      {formatMessage(dataItem.recommended)}
                    </Typography>
                  </Cell>
                )}
              />
            </DataTable>
          </FormRow>
        )}
        <FormSpacer />
        <FormRow rowTitle={formatMessage('bulk_email_detection')}>
          <InlineRadioGroup
            name={AvailableSettings.BULK_EMAIL_DETECTION}
            onChange={eventHandlers.onFormChange(AvailableSettings.BULK_EMAIL_DETECTION)}
            labels={
              state.canQuarantineSettings
                ? [formatMessage('block'), formatMessage('quarantine'), formatMessage('off')]
                : [formatMessage('block'), formatMessage('off')]
            }
            value={state.settings.bulk_email_detection || 'off'}
            values={
              state.canQuarantineSettings
                ? [
                    SettingActionValueStringBulkEmail.BLOCK,
                    SettingActionValueStringBulkEmail.QUARANTINE,
                    SettingActionValueStringBulkEmail.OFF
                  ]
                : [SettingActionValueStringBulkEmail.BLOCK, SettingActionValueStringBulkEmail.OFF]
            }
            disabled={UseGetSettingsStateLogic.isLoading}
          />
        </FormRow>
        <FormSpacer />
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [state, formatMessage, eventHandlers, formatDialogMessage, classes, UseGetSettingsStateLogic]
  )
}

export default Reputation
