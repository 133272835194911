import React, { useMemo } from 'react'
import { LineProps, ResponsiveLine, Serie } from '@nivo/line'
import { Datum } from '@nivo/legends'
import { isNumber } from 'lodash'

import { Grid } from '@barracuda-internal/bds-core'
import styles from 'components/libs/nivo/nivoStyles'

export type LineChartProps = {
  config: Partial<LineProps>
  colors: string[]
  disabledLegends: string[]
  onLegendClick?: (legend: Datum) => void
  maxValueInRange?: number
  formats?: {
    axisBottom: (v: any) => any
    axisLeft: (v: any) => any
  }
  chartTooltip?: (points: any) => any
}

const LineChart = (props: LineChartProps) => {
  const classes = styles()

  const legends: any = useMemo(
    () =>
      props.config.data?.map((serieData: Serie, idx) => ({
        data: [
          {
            id: serieData.id || '',
            label: serieData.id || '',
            color: props.colors[idx]
          }
        ],
        anchor: 'top-right',
        direction: 'row',
        justify: false,
        translateX: 0 - idx * 90,
        translateY: -28,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 90,
        itemHeight: 20,
        itemOpacity: props.disabledLegends.includes(serieData.id as string) ? 0.3 : 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        onClick: props.onLegendClick,
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)'
            }
          }
        ]
      })),
    [props.config.data, props.colors, props.disabledLegends, props.onLegendClick]
  )

  return useMemo(
    () => (
      <ResponsiveLine
        data={[]}
        margin={{ top: 40, right: 40, bottom: 95, left: 40 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: isNumber(props.maxValueInRange) ? Math.max(props.maxValueInRange, 5) : 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          format: v => {
            if (props.formats?.axisBottom) {
              return props.formats.axisBottom(v)
            }
            return v
          },
          tickPadding: 5,
          tickRotation: -90,
          legendOffset: 36,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickSize: 5,
          format: v => {
            if (props.formats?.axisLeft) {
              return props.formats.axisLeft(v)
            }
            return v
          },
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle'
        }}
        colors={{ scheme: 'nivo' }}
        enableGridX={false}
        enableArea
        areaOpacity={0.3}
        lineWidth={3}
        pointSize={7}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        sliceTooltip={({ slice }) => {
          const customTooltip = props.chartTooltip?.(slice.points[0].data.x)

          if (customTooltip) {
            return (
              <Grid container className={classes.tooltipWithChart} direction="row">
                {customTooltip}
              </Grid>
            )
          }

          return (
            <Grid container className={classes.tooltip} direction="row">
              <Grid item>
                <Grid container direction="column">
                  <Grid item className={classes.tooltipTitle}>
                    {slice.points[0].data.xFormatted}
                  </Grid>
                  {slice.points.map(point => (
                    <Grid
                      key={point.id}
                      className={classes.serieTitle}
                      style={{
                        color: point.serieColor
                      }}
                    >
                      <strong>{`${point.serieId} - ${point.data.y}`}</strong>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )
        }}
        enableSlices="x"
        crosshairType="bottom-left"
        pointLabelYOffset={-12}
        motionConfig="default"
        useMesh
        legends={legends}
        {...props.config}
      />
    ),
    [props, classes, legends]
  )
}

export default LineChart
