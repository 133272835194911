import { useMemo, useCallback, useState, ChangeEvent, Dispatch, SetStateAction } from 'react'

import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'

import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  AvailableSettings,
  MessageContentFilter,
  OutboundMessageContentFilterAction,
  AVAILABLE_OUTBOUND_MESSAGE_FILTER_ACTIONS,
  SettingValue,
  MessageContentFilterMatches
} from 'types/Settings'
import routesConfig from 'lib/routesConfig'

import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'
import {
  MessageContentForm,
  useMessageContentForm
} from 'components/pages/outboundSettings/contentPolicies/useMessageContentForm'
import { SettingsFormField } from 'lib/settingsForm/useSettingsForm'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

export interface State {
  isConfirmDialogOpen: boolean
  sort: SortDescriptor[]
  errors: {
    pattern: string
    match: string
  }
  actionOptions: string[]
  form: MessageContentForm
  formState: Omit<MessageContentFilter, 'id'>
  tableData: {
    total: number
    data: MessageContentFilter[]
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onRemoveConfirm: () => void
  onRemoveCancel: () => void
  onBulkEdit: () => void
  handleSortChange: (e: GridSortChangeEvent) => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOnCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
}

export type UseMessageTableLogic = [State, EventHandlers]

const INITIAL_FORM_STATE = {
  pattern: '',
  action: OutboundMessageContentFilterAction.BLOCK,
  matchSubject: SettingValue.ENABLED,
  matchHeaders: SettingValue.ENABLED,
  matchBody: SettingValue.ENABLED,
  matchAttachments: SettingValue.ENABLED,
  matchSender: SettingValue.ENABLED,
  matchRecipient: SettingValue.ENABLED
}

const BLANK_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export interface UseMessageTableLogicProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export const enum SELECT_NAMES {
  ACTION = 'action',
  SUBJECT = 'subject',
  HEADERS = 'headers',
  BODY = 'body',
  ATTACHMENTS = 'attachments',
  SENDER = 'sender',
  RECIPIENT = 'recipient'
}

const initialSort = [{ field: 'pattern', dir: 'asc' } as SortDescriptor]

export const useMessageTableLogic = ({ delegateDirtyFormState }: UseMessageTableLogicProps): UseMessageTableLogic => {
  const form = useMessageContentForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const dispatch = useAppDispatch()
  const [sort, setSort] = useState(initialSort)
  const [removeId, setRemoveId] = useState<string>('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)

  const { accessTokenObject, accountSettings, domainSettings } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const tableData = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    let parsedData: MessageContentFilter[] = []
    if (settings.outbound_message_content_filters) {
      try {
        parsedData = JSON.parse(settings.outbound_message_content_filters as string) as MessageContentFilter[]
      } catch (error) {
        dispatch(
          setErrorSnackBar({
            message: 'error_parsing_settings'
          })
        )
      }
    }
    const orderedData = orderBy(parsedData || [], sort)
    const { data } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift(BLANK_ENTRY)
    return {
      data,
      total: data.length || 0
    }
  }, [accessTokenObject?.pdDomainId, domainSettings, accountSettings, sort, dispatch])

  const updateData = useCallback(
    (data: MessageContentFilter[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.OUTBOUND_MESSAGE_CONTENT_FILTERS]: data }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.OUTBOUND_MESSAGE_CONTENT_FILTERS]: data } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(() => {
    const data = tableData.data.filter(entry => entry.id !== '')
    const newEntry: MessageContentFilter = {
      ...form.getStateAsFilter(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newEntry, { data })) {
      updateData([...data, newEntry])
    }
  }, [tableData.data, form, updateData])

  const onRemove = useCallback(
    (id: string) => {
      setRemoveId(id)
      setIsConfirmDialogOpen(true)
    },
    [setRemoveId, setIsConfirmDialogOpen]
  )

  const onRemoveConfirm = useCallback(() => {
    const policies = tableData.data.filter(entry => entry.id !== removeId && entry.id !== '')
    updateData(policies)
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [tableData.data, updateData, removeId])

  const onRemoveCancel = useCallback(() => {
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [setRemoveId, setIsConfirmDialogOpen])

  const onBulkEdit = useCallback(() => {
    routesConfig.OUTBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT.goto()
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.fields.pattern.setValue(e.target.value)
      form.delegateIsDirty()
    },
    [form]
  )

  const handleOnCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target
      let field: SettingsFormField | undefined
      // eslint-disable-next-line default-case
      switch (name) {
        case MessageContentFilterMatches.SUBJECT:
          field = form.fields.matchSubject
          break
        case MessageContentFilterMatches.BODY:
          field = form.fields.matchBody
          break
        case MessageContentFilterMatches.HEADERS:
          field = form.fields.matchHeaders
          break
        case MessageContentFilterMatches.ATTACHMENTS:
          field = form.fields.matchAttachments
          break
        case MessageContentFilterMatches.SENDER:
          field = form.fields.matchSender
          break
        case MessageContentFilterMatches.RECIPIENT:
          field = form.fields.matchRecipient
          break
      }
      if (field) {
        field.setValue(field.getValue() === SettingValue.ENABLED ? SettingValue.DISABLED : SettingValue.ENABLED)
        form.delegateIsDirty()
      }
    },
    [form]
  )

  const handleOnSelectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (typeof e.target.value === 'string') {
        form.fields.action.setValue(e.target.value)
        form.delegateIsDirty()
      }
    },
    [form]
  )

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const actionOptions = useMemo(() => AVAILABLE_OUTBOUND_MESSAGE_FILTER_ACTIONS, [])

  return useMemo(
    () => [
      {
        isConfirmDialogOpen,
        sort,
        errors: form.errors,
        actionOptions,
        form,
        formState: form.getStateAsFilter(),
        tableData
      },
      {
        onAdd,
        onRemove,
        onRemoveConfirm,
        onRemoveCancel,
        onBulkEdit,
        handleSortChange,
        handleOnInputChange,
        handleOnSelectChange,
        handleOnCheckboxChange
      }
    ],
    [
      isConfirmDialogOpen,
      sort,
      form,
      actionOptions,
      tableData,
      onAdd,
      onRemove,
      onRemoveConfirm,
      onRemoveCancel,
      onBulkEdit,
      handleSortChange,
      handleOnInputChange,
      handleOnSelectChange,
      handleOnCheckboxChange
    ]
  )
}
