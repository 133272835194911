import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'

export type GetPamProductDataRequest = void
export interface PamProductDataResponse {
  data: {
    subscriptionGroups: any[]
    pendingActivations: any[]
    error: {
      code: string
    }
  }
}

export const getPamProductData = createAsyncThunk<PamProductDataResponse, GetPamProductDataRequest, ApiRejectResponse>(
  'PAM/getPamProductData',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_PRODUCT_DATA)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
