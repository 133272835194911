import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { InboundScheduleType, InboundScheduleUI } from 'types/Settings'
import { ScheduleMatrixProps } from 'components/libs/scheduler/schedulerMatrixTypes'
import {
  getQuarantineNotificationSettings,
  resetQuarantineNotificationSettings,
  updateQuarantineNotificationSettings
} from 'redux/features/users/usersSlice'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  isEnabledMessagesForIntentAnalysis: RadioButtonStates
  defaultInterval: RadioButtonStates
  isAllowSpecifyInterval: RadioButtonStates
  isTempPasscodeEnabled: RadioButtonStates
  inProgress: boolean
  isLoading: boolean
  isFailedToLoad: boolean
  scheduleMatrixConfig: ScheduleMatrixProps
  isDirtyForm: boolean
}

export interface EventHandlers {
  onSaveChanges: () => void
  setIsEnabledMessagesForIntentAnalysis: Dispatch<SetStateAction<RadioButtonStates>>
  setDefaultInterval: Dispatch<SetStateAction<RadioButtonStates>>
  setIsAllowSpecifyInterval: Dispatch<SetStateAction<RadioButtonStates>>
  setIsTempPasscodeEnabled: Dispatch<SetStateAction<RadioButtonStates>>
}

export type UseQuarantineNotificationLogic = [State, EventHandlers]
export const useQuarantineNotificationLogic = (): UseQuarantineNotificationLogic => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    isGetQuarantineNotificationSuccess,
    isFailedToLoad,
    isUpdating,
    isUpdateQuarantineNotificationSuccess,
    isUpdateQuarantineNotificationFailed,
    quarantinNotificationSettings
  } = useAppSelector(_store => ({
    isLoading: isPending(_store.users.api.getQuarantineNotificationSettingsApiStatus),
    isGetQuarantineNotificationSuccess: isSuccess(_store.users.api.getQuarantineNotificationSettingsApiStatus),
    isUpdating: isPending(_store.users.api.updateQuarantineNotificationSettingsApiStatus),
    isUpdateQuarantineNotificationSuccess: isSuccess(_store.users.api.updateQuarantineNotificationSettingsApiStatus),
    isUpdateQuarantineNotificationFailed: isFailed(_store.users.api.updateQuarantineNotificationSettingsApiStatus),
    isFailedToLoad: isFailed(_store.users.api.getQuarantineNotificationSettingsApiStatus),
    quarantinNotificationSettings: _store.users.quarantineNotificationSettings
  }))

  const [isEnabledMessagesForIntentAnalysis, setIsEnabledMessagesForIntentAnalysis] = useState<RadioButtonStates>(
    RadioButtonStates.on
  )
  const [defaultInterval, setDefaultInterval] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [isAllowSpecifyInterval, setIsAllowSpecifyInterval] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [isTempPasscodeEnabled, setIsTempPasscodeEnabled] = useState<RadioButtonStates>(RadioButtonStates.on)
  const [customQuarantineNotification, setCustomQuarantineNotification] = useState<InboundScheduleUI | undefined>()
  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([
    isEnabledMessagesForIntentAnalysis,
    defaultInterval,
    isAllowSpecifyInterval,
    isTempPasscodeEnabled,
    customQuarantineNotification
  ])

  // init
  useEffect(
    () => {
      dispatch(getQuarantineNotificationSettings())
      trackEventInAllServices(TRACKING_EVENTS.ADMIN.QUARANTINE_NOTIFICATION_PAGE_VIEW)

      return () => {
        dispatch(resetQuarantineNotificationSettings())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // QN settings are updated
  useEffect(() => {
    if (quarantinNotificationSettings) {
      setIsEnabledMessagesForIntentAnalysis(
        quarantinNotificationSettings.enableQuarantine ? RadioButtonStates.on : RadioButtonStates.off
      )
      setDefaultInterval(
        quarantinNotificationSettings.quarantineNotificationInterval === InboundScheduleType.never
          ? RadioButtonStates.on
          : RadioButtonStates.off
      )
      setIsAllowSpecifyInterval(
        quarantinNotificationSettings.allowQuarantineNotificationIntervalChange
          ? RadioButtonStates.on
          : RadioButtonStates.off
      )
      setIsTempPasscodeEnabled(
        quarantinNotificationSettings.allowTemporaryPasscode ? RadioButtonStates.on : RadioButtonStates.off
      )
      setCustomQuarantineNotification(quarantinNotificationSettings.customQuarantineNotificationInterval)
      setShouldUpdateInitialForm(true)
    }
  }, [isGetQuarantineNotificationSuccess, quarantinNotificationSettings])

  // update is success
  useEffect(() => {
    if (isUpdateQuarantineNotificationSuccess) {
      dispatch(setSuccessSnackBar({ message: 'update_quarantine_notifications_settings_success' }))
    }
  }, [dispatch, isUpdateQuarantineNotificationSuccess])

  // update is Failed
  useEffect(() => {
    if (isUpdateQuarantineNotificationFailed) {
      dispatch(setErrorSnackBar({ message: 'update_quarantine_notifications_settings_files' }))
    }
  }, [dispatch, isUpdateQuarantineNotificationFailed])

  const onSaveChanges = useCallback(() => {
    dispatch(
      updateQuarantineNotificationSettings({
        enableQuarantine: isEnabledMessagesForIntentAnalysis === RadioButtonStates.on,
        allowTemporaryPasscode: isTempPasscodeEnabled === RadioButtonStates.on,
        quarantineNotificationInterval:
          defaultInterval === RadioButtonStates.on ? InboundScheduleType.never : InboundScheduleType.custom,
        customQuarantineNotificationInterval: customQuarantineNotification,
        allowQuarantineNotificationIntervalChange: isAllowSpecifyInterval === RadioButtonStates.on
      })
    )
  }, [
    dispatch,
    isEnabledMessagesForIntentAnalysis,
    isTempPasscodeEnabled,
    defaultInterval,
    customQuarantineNotification,
    isAllowSpecifyInterval
  ])

  const onScheduleMatrixChange = useCallback((schedule: InboundScheduleUI) => {
    setCustomQuarantineNotification(schedule)
  }, [])

  return useMemo(
    () => [
      {
        isEnabledMessagesForIntentAnalysis,
        defaultInterval,
        isAllowSpecifyInterval,
        isTempPasscodeEnabled,
        inProgress: isUpdating,
        isLoading,
        isFailedToLoad,
        isDirtyForm,
        scheduleMatrixConfig: {
          isEnabled: defaultInterval === RadioButtonStates.off && !isUpdating,
          doUpdate: onScheduleMatrixChange,
          customInboundSchedule: quarantinNotificationSettings?.customQuarantineNotificationInterval
        }
      },
      {
        onSaveChanges,
        setIsEnabledMessagesForIntentAnalysis,
        setDefaultInterval,
        setIsAllowSpecifyInterval,
        setIsTempPasscodeEnabled
      }
    ],
    [
      isLoading,
      isFailedToLoad,
      onSaveChanges,
      isEnabledMessagesForIntentAnalysis,
      defaultInterval,
      isAllowSpecifyInterval,
      isTempPasscodeEnabled,
      isUpdating,
      setIsEnabledMessagesForIntentAnalysis,
      setDefaultInterval,
      setIsAllowSpecifyInterval,
      setIsTempPasscodeEnabled,
      onScheduleMatrixChange,
      quarantinNotificationSettings,
      isDirtyForm
    ]
  )
}
