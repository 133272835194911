import { useMemo } from 'react'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { useAzureAdPollUserStats } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdPollUserStats'
import {
  useAzureAdLookupUser,
  State as LookupUserState,
  EventHandlers as LookupUserEvents
} from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdLookupUser'
import {
  useAzureAdAuthorization,
  State as AuthorizationState,
  EventHandlers as AuthorizationEvents
} from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdAuthorization'
import {
  useAzureAdSyncNow,
  State as SyncNowState,
  EventHandlers as SyncNowEvents,
  Stats
} from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdSyncNow'
import {
  useAzureAdOptions,
  State as OptionsState,
  EventHandlers as OptionsEvents
} from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdOptions'
import { useAzureAdMultiDependencyState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdMultiDependencyState'

interface State {
  optionsState: OptionsState & {
    isOptionsDisabled: boolean
  }
  authorizationState: AuthorizationState
  syncNowState: SyncNowState & {
    isSyncNowLoading: boolean
    isSyncNowDisabled: boolean
    syncStats: Stats
  }
  lookupUserState: LookupUserState & {
    isLookupUserDisabled: boolean
  }
}

interface EventHandlers {
  optionsEvents: OptionsEvents
  authorizationEvents: AuthorizationEvents
  syncNowEvents: SyncNowEvents
  lookupUserEvents: LookupUserEvents
}

export type AzureAdSettingsLogic = [State, EventHandlers]

export const useAzureAdSettingsLogic = (form: EditDomainForm): AzureAdSettingsLogic => {
  const [pollUserStatsState] = useAzureAdPollUserStats(form)
  const [lookupUserState, lookupUserEvents] = useAzureAdLookupUser(form)
  const [authorizationState, authorizationEvents] = useAzureAdAuthorization(form)
  const [syncNowState, syncNowEvents] = useAzureAdSyncNow(form)
  const [optionsState, optionsEvents] = useAzureAdOptions(form)
  // Get those state variables that depend on more than a single azure AD flow hook
  const { isOptionsDisabled, syncStats, isSyncNowLoading, isSyncNowDisabled, isLookupUserDisabled } =
    useAzureAdMultiDependencyState(
      form,
      optionsEvents,
      authorizationState,
      syncNowEvents,
      pollUserStatsState,
      lookupUserEvents,
      lookupUserState
    )
  return useMemo(
    () => [
      {
        optionsState: {
          ...optionsState,
          isOptionsDisabled
        },
        authorizationState,
        syncNowState: {
          ...syncNowState,
          isSyncNowLoading,
          isSyncNowDisabled,
          syncStats
        },
        lookupUserState: {
          ...lookupUserState,
          isLookupUserDisabled
        }
      },
      {
        optionsEvents,
        authorizationEvents,
        syncNowEvents,
        lookupUserEvents
      }
    ],
    [
      optionsState,
      isOptionsDisabled,
      authorizationState,
      syncNowState,
      isSyncNowLoading,
      isSyncNowDisabled,
      syncStats,
      lookupUserState,
      isLookupUserDisabled,
      optionsEvents,
      authorizationEvents,
      syncNowEvents,
      lookupUserEvents
    ]
  )
}
