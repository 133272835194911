import { uniq } from 'lodash'
import { extractEmail } from 'lib/email'

export interface ComposerData {
  from: string
  to: string
  cc: string
  subject: string
  body: string
}

export function populateEmailData(emailType: string, composerData: ComposerData, userId: string) {
  switch (emailType) {
    case 'new':
      return { ...composerData }
    case 'forward':
      return {
        ...composerData,
        from: userId,
        to: ''
      }
    case 'reply':
      return {
        ...composerData,
        from: userId,
        to: extractEmail(composerData.from),
        cc: ''
      }
    case 'replyAll':
      return {
        ...composerData,
        from: userId,
        to: getReplyAllRecipients(composerData, userId).to
      }
    case 'newEmail':
      return {
        ...composerData
      }
    default:
      return composerData
  }
}

export function getReplyAllRecipients(composerData: any, userId: string) {
  const to = [...composerData.to.split(', ')].filter(
    (email: string) => !(email === userId || email.includes(`<${userId}>`))
  )

  const allRecipients: { [index: string]: string } = {
    to: uniq([extractEmail(composerData.from), ...to]).join(', ')
  }

  return allRecipients
}
