import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as makeUuid } from 'uuid'
import { AtpExemptEmail, AtpExemptIp, AtpScanMode, AtpSettings } from 'types/atpSettings'
import { AvailableSettings, SettingValue } from 'types/Settings'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getAccountSettings,
  getDomainSettings,
  resetGetSettingsApiStatus,
  resetUpdateSettingsApiStatus,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { toAtpSettings, toSettingsObject } from 'lib/atpSettings'
import { FormErrors, useAtpSettingsValidation } from 'components/pages/atpSettings/useAtpSettingsValidation'
import { AtpExemptEmailsTable, useAtpExemptEmailsTable } from 'components/pages/atpSettings/useAtpExemptEmailsTable'
import { AtpExemptIpsTable, useAtpExemptIpsTable } from 'components/pages/atpSettings/useAtpExemptIpsTable'
import { RadioButtonStates } from 'components/pages/users/usersTypes'
import { getBlockTransition, setBlockTransition } from 'lib/routes'

export enum SaveMode {
  HEAD,
  ATP_EMAILS,
  ATP_IPS
}

export interface Form {
  hasChanges: boolean
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  isAtpNotify: RadioButtonStates
  setisAtpNotify: Dispatch<SetStateAction<RadioButtonStates>>
  settings: AtpSettings
  emailsTable: AtpExemptEmailsTable
  ipsTable: AtpExemptIpsTable
  isAntiVirusEnabled: boolean
  isGetSettingsSuccess: boolean
  isGetSettingsPending: boolean
  isGetSettingsFailed: boolean
  isUpdateSuccess: boolean
  isUpdatePending: boolean
  isUpdateFailed: boolean
  onCancelConfirm: () => void
  onSave: () => void
  errors: FormErrors
  insertEmailInData: (email: AtpExemptEmail) => boolean
  insertIpInData: (ip: AtpExemptIp) => boolean
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  key: string
}

export const useAtpSettingsForm = (): Form => {
  const dispatch = useAppDispatch()
  const {
    pdDomainId,
    atd,
    atd_notify,
    atd_admin_emails,
    atd_exempt_email,
    atd_exempt_ip,
    isAntiVirusEnabled,
    isGetSettingsSuccess,
    isGetSettingsPending,
    isGetSettingsFailed,
    isUpdateSuccess,
    isUpdatePending,
    isUpdateFailed
  } = useAppSelector(_store => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const pdDomainId = _store.auth.accessTokenObject?.pdDomainId
    const settingsStore = (pdDomainId ? _store.settings.domainSettings : _store.settings.accountSettings) || {}
    const getApiStatus = pdDomainId
      ? _store.settings.getDomainSettingsApiStatus
      : _store.settings.getAccountSettingsApiStatus
    const updateApiStatus = pdDomainId
      ? _store.settings.updateDomainSettingsApiStatus
      : _store.settings.updateAccountSettingsApiStatus
    return {
      pdDomainId,
      isAntiVirusEnabled: settingsStore.vscan === SettingValue.ENABLED,
      isGetSettingsSuccess: isSuccess(getApiStatus),
      isGetSettingsPending: isPending(getApiStatus),
      isGetSettingsFailed: isFailed(getApiStatus),
      ...toAtpSettings(settingsStore),
      isUpdateSuccess: isSuccess(updateApiStatus),
      isUpdatePending: isPending(updateApiStatus),
      isUpdateFailed: isFailed(updateApiStatus)
    }
  })
  const [data, setData] = useState<AtpSettings>({
    [AvailableSettings.ATD]: '',
    [AvailableSettings.ATD_NOTIFY]: '',
    [AvailableSettings.ATD_ADMIN_EMAILS]: '',
    [AvailableSettings.ATD_EXEMPT_EMAIL]: [],
    [AvailableSettings.ATD_EXEMPT_IP]: []
  })
  const [isAtpNotify, setisAtpNotify] = useState<RadioButtonStates>(RadioButtonStates.off)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, { validateNewEmail, validateNewIp, validateAdminEmails, resetErrors }] = useAtpSettingsValidation(data)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const [key, setKey] = useState(makeUuid())

  const collectDataForUpdate = useCallback((formData: AtpSettings, saveMode: SaveMode): Partial<AtpSettings> => {
    const shouldCollectAtpNotify = formData[AvailableSettings.ATD] === AtpScanMode.DELIVER_SCAN
    const shouldCollectAdminEmails =
      shouldCollectAtpNotify && formData[AvailableSettings.ATD_NOTIFY] === SettingValue.ENABLED
    const head = {
      [AvailableSettings.ATD]: formData[AvailableSettings.ATD],
      ...(shouldCollectAtpNotify ? { [AvailableSettings.ATD_NOTIFY]: formData[AvailableSettings.ATD_NOTIFY] } : {}),
      ...(shouldCollectAdminEmails
        ? { [AvailableSettings.ATD_ADMIN_EMAILS]: formData[AvailableSettings.ATD_ADMIN_EMAILS] }
        : {})
    }
    switch (true) {
      case saveMode === SaveMode.HEAD: {
        return head
      }
      case saveMode === SaveMode.ATP_EMAILS: {
        return {
          ...head,
          [AvailableSettings.ATD_EXEMPT_EMAIL]: formData[AvailableSettings.ATD_EXEMPT_EMAIL]
        }
      }
      case saveMode === SaveMode.ATP_IPS: {
        return {
          ...head,
          [AvailableSettings.ATD_EXEMPT_IP]: formData[AvailableSettings.ATD_EXEMPT_IP]
        }
      }
      default: {
        return {}
      }
    }
  }, [])

  const loadSettings = useCallback(() => {
    const settings = [
      AvailableSettings.ATD,
      AvailableSettings.ATD_NOTIFY,
      AvailableSettings.ATD_ADMIN_EMAILS,
      AvailableSettings.ATD_EXEMPT_EMAIL,
      AvailableSettings.ATD_EXEMPT_IP,
      AvailableSettings.VSCAN
    ]
    if (pdDomainId) {
      dispatch(getDomainSettings({ settings, domainId: pdDomainId }))
    } else {
      dispatch(getAccountSettings(settings))
    }
  }, [dispatch, pdDomainId])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const saveSettings = useCallback(
    (formData: AtpSettings, saveMode: SaveMode) => {
      const settings = toSettingsObject(collectDataForUpdate(formData, saveMode))
      if (pdDomainId) {
        dispatch(updateDomainSettings({ domainId: pdDomainId, settings }))
      } else {
        dispatch(updateAccountSettings({ settings }))
      }
    },
    [collectDataForUpdate, dispatch, pdDomainId]
  )

  const insertEmailInData = useCallback(
    (email: AtpExemptEmail) => {
      if (validateNewEmail(email)) {
        const nextData = {
          ...data,
          [AvailableSettings.ATD_EXEMPT_EMAIL]: [email, ...data[AvailableSettings.ATD_EXEMPT_EMAIL]]
        }
        setData(nextData)
        setHasChanges(true)
        saveSettings(nextData, SaveMode.ATP_EMAILS)
        return true
      }

      return false
    },
    [data, saveSettings, validateNewEmail]
  )

  const removeEmailFromData = useCallback(
    (id: string) => {
      const nextEmails = data[AvailableSettings.ATD_EXEMPT_EMAIL].filter(item => item.id !== id)
      const nextData = {
        ...data,
        [AvailableSettings.ATD_EXEMPT_EMAIL]: nextEmails
      }
      setData(nextData)
      setHasChanges(true)
      saveSettings(nextData, SaveMode.ATP_EMAILS)
    },
    [data, saveSettings]
  )

  const insertIpInData = useCallback(
    (ip: AtpExemptIp) => {
      if (validateNewIp(ip)) {
        const nextData = {
          ...data,
          [AvailableSettings.ATD_EXEMPT_IP]: [ip, ...data[AvailableSettings.ATD_EXEMPT_IP]]
        }
        setData(nextData)
        setHasChanges(true)
        saveSettings(nextData, SaveMode.ATP_IPS)
        return true
      }
      return false
    },
    [data, saveSettings, validateNewIp]
  )

  const removeIpFromData = useCallback(
    (id: string) => {
      const nextIps = data[AvailableSettings.ATD_EXEMPT_IP].filter(item => item.id !== id)
      const nextData = {
        ...data,
        [AvailableSettings.ATD_EXEMPT_IP]: nextIps
      }
      setData(nextData)
      setHasChanges(true)
      saveSettings(nextData, SaveMode.ATP_IPS)
    },
    [data, saveSettings]
  )

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      if (name === 'atd_notify') {
        setisAtpNotify(newValue)
        setData({
          ...data,
          [AvailableSettings.ATD_NOTIFY]: newValue === 'on' ? SettingValue.ENABLED : SettingValue.DISABLED
        })
      } else {
        setData({ ...data, [name]: newValue })
      }
      setHasChanges(true)
    },
    [data]
  )

  const onSave = useCallback(() => {
    if (validateAdminEmails()) {
      saveSettings(data, SaveMode.HEAD)
    }
  }, [data, saveSettings, validateAdminEmails])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        loadSettings()
        setBlockTransition(undefined)
        setKey(makeUuid())
      })
    }
  }, [loadSettings])

  const emailsTable = useAtpExemptEmailsTable({ insertEmailInData, removeEmailFromData, data: atd_exempt_email })

  const ipsTable = useAtpExemptIpsTable({ insertIpInData, removeIpFromData, data: atd_exempt_ip })

  useEffect(() => {
    resetGetSettingsApiStatus()
    resetUpdateSettingsApiStatus()
    loadSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isGetSettingsSuccess) {
      setData({
        [AvailableSettings.ATD]: atd,
        [AvailableSettings.ATD_NOTIFY]: atd_notify,
        [AvailableSettings.ATD_ADMIN_EMAILS]: atd_admin_emails,
        [AvailableSettings.ATD_EXEMPT_EMAIL]: atd_exempt_email,
        [AvailableSettings.ATD_EXEMPT_IP]: atd_exempt_ip
      })
      setisAtpNotify(atd_notify === '1' ? RadioButtonStates.on : RadioButtonStates.off)
      setHasChanges(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetSettingsSuccess])

  useEffect(() => {
    if (isUpdateSuccess) {
      loadSettings()
      resetErrors()
    }
  }, [isUpdateSuccess, loadSettings, resetErrors])

  return useMemo(
    () => ({
      hasChanges,
      onFormChange,
      isAtpNotify,
      setisAtpNotify,
      settings: data,
      emailsTable,
      ipsTable,
      isAntiVirusEnabled,
      isGetSettingsSuccess,
      isGetSettingsPending,
      isGetSettingsFailed,
      isUpdateSuccess,
      isUpdatePending,
      isUpdateFailed,
      onCancelConfirm,
      onSave,
      errors,
      insertEmailInData,
      insertIpInData,
      helpConfig: {
        isOpen: isHelpDialogOpened,
        onHelpClick,
        onCloseHelp
      },
      key
    }),
    [
      hasChanges,
      onFormChange,
      isAtpNotify,
      setisAtpNotify,
      data,
      emailsTable,
      ipsTable,
      isAntiVirusEnabled,
      isGetSettingsSuccess,
      isGetSettingsPending,
      isGetSettingsFailed,
      isUpdateSuccess,
      isUpdatePending,
      isUpdateFailed,
      onCancelConfirm,
      onSave,
      errors,
      insertEmailInData,
      insertIpInData,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      key
    ]
  )
}
