import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles({
  circularProgress: {
    marginBottom: 10
  },
  card: {
    border: 100
  }
})
