import React from 'react'

import { TextField } from '@barracuda-internal/bds-core'

import styles from './outlinedDivStyles'

function InputComponent({ inputRef, ...other }: { inputRef: any }) {
  return <div {...other} />
}
function OutlinedDiv(props: { children: any; label: string }) {
  const classes = styles()
  return (
    <TextField
      className={classes.textfield}
      label={props.label}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent as any,
        style: { padding: 8 }
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ children: props.children }}
    />
  )
}
export default OutlinedDiv
