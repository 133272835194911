import { colors, makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentSpacing: {
    padding: theme.spacing(2)
  },
  fieldSet: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.scaleGray100,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    width: '100%'
  },
  radioLabel: {
    marginBottom: theme.spacing(1)
  },
  radioLabelText: {
    marginBottom: theme.spacing(0.5)
  },
  radioHelper: {
    color: colors.scaleGray500
  },
  inProgressLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.spacing(8)
  },
  alreadyVerifiedLabel: {
    marginBottom: theme.spacing(2)
  },
  nextButton: {
    marginRight: theme.spacing(1)
  }
}))

export default styles
