import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundDlp(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
        unless you change these settings for a specific domain. In that case, the domain-level settings override the
        global system settings.
      </p>
      <br />
      <br />
      Domain names added to this page have <b>Force TLS</b> enabled for outbound mail. Mail sent to these domains must
      be transmitted across a TLS connection. If a TLS connection cannot be established, then mail is not delivered.
      <br />
      <br />
      Click on one of the following table headings to sort the table:
      <ul>
        <li>Domain Name</li>
        <li>Comment</li>
      </ul>
    </div>
  )
}

export default OutboundDlp
