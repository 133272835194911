import { useMemo } from 'react'

import useUserRights, { UserRole } from 'components/libs/userRights/useUserRights'

export interface ValidatedRights {
  canEditSpamNotificationAddress: boolean
}

export type UseOutboundSettingsRights = ValidatedRights

export enum UserRights {
  CAN_EDIT_SPAM_NOTIFICATION_ADDRESS = UserRole.ACCOUNT_USER
}
export const useOutboundSettingsRights = (): UseOutboundSettingsRights => {
  const { validatedUserRights } = useUserRights([UserRights.CAN_EDIT_SPAM_NOTIFICATION_ADDRESS])

  const [canEditSpamNotificationAddress] = validatedUserRights

  return useMemo(
    () => ({
      canEditSpamNotificationAddress
    }),
    [canEditSpamNotificationAddress]
  )
}
