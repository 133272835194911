export interface AtpLog {
  id: string
  cuid: string
  accountId: string
  domainId: number
  mid: string
  origin: string
  md5: string
  filename: string
  status: string
  determination: string
  fileType: string
  risk: number
  future: string
  created: string
  modified: string
  deleted: number
  headerFrom: string
  headerTo: string
  headerSubject: string
}

export interface ModifiedAtpLog extends AtpLog {
  isFuture: boolean
  formattedDate: string
  statusText: string
  originText: string
  riskText: string
  statusIcon?: (props: any) => JSX.Element
}

export interface AtpSearch {
  searchStr: string
  status: string
  time: number
  filetype: ATP_FILE_TYPES
  messageId: string
}

export interface AtpOverview {
  infectedCount: number
  suspiciousCount: number
}

export interface FormattedAtpFileTypes {
  key: keyof typeof ATP_FILE_TYPES
  label: string
}

export enum ATP_FILE_TYPES {
  all = 'all',
  exe = 'EXE - Microsoft executable',
  scr = 'SCR - Microsoft screen saver',
  pif = 'PIF - Program information file',
  cpl = 'CPL - Microsoft control panel extension',
  inx = 'INX - Installshield installer',
  isu = 'ISU - Installshield uninstaller',
  com = 'COM - Microsoft command',
  cmd = 'CMD - Microsoft command',
  unk = 'UNK - Unknown',
  bin = 'BIN - Binary',
  dll = 'DLL - Microsoft dynamic link library',
  msi = 'MSI - Microsoft installer',
  msp = 'MSP - Microsoft installer patch',
  mst = 'MST - Microsoft installer setup transform',
  job = 'JOB - Microsoft task scheduler job',
  paf = 'PAF - Microsoft portable application installer',
  reg = 'REG - Microsoft registry entry',
  rgs = 'RGS - Microsoft registry script',
  u3p = 'U3P - Microsoft U3 smart application',
  chm = 'CHM - Microsoft compiled html help',
  elf = 'ELF - Executable and Linkable Format',
  so = 'SO - Executable and Linkable Format shared object',
  o = 'O - Executable and Linkable Format object',
  core = 'CORE - Executable and Linkable Format core dump',
  rpm = 'RPM - Red hat package',
  deb = 'DEB - Debian package',
  out = 'OUT - Linux executable',
  run = 'RUN - Linux executable',
  dmg = 'DMG - Mac installer',
  app = 'APP - MACH-0 application',
  osx = 'OSX - MACH-0 application',
  ipa = 'IPA - Mac appstore archive',
  class = 'CLASS - Java binary',
  jar = 'JAR - Java archive',
  vb = 'VB - Vbscript file',
  vbs = 'VBS - Vbscript file',
  vbscript = 'VBSCRIPT',
  vbe = 'VBE - Vbscript encoded',
  js = 'JS - Javascript',
  jse = 'JSE - Javascript encoded',
  ps1 = 'PS1 - Powershell script',
  sct = 'SCT - Windows scriplet',
  ws = 'WS - Windows script',
  wsf = 'WSF - Windows script',
  zip = 'ZIP - archive',
  zipx = 'ZIPX - archive',
  rar = 'RAR - archive',
  arj = 'ARJ - Microsoft dos archive',
  cab = 'CAB - Microsoft cabinet archive',
  lzh = 'LZH - Amiga lha archive',
  lha = 'LHA - Amiga lha archive',
  gz = 'GZ - Gunzip archive',
  '7z' = '7z - 7zip archive',
  iso = 'ISO - binary',
  udf = 'UDF - binary',
  rtf = 'RTF - Rich text format',
  pdf = 'PDF - Adobe acrobat document',
  ps = 'PS - Adobe postscript',
  eps = 'EPS - Adobe encapsulated postscript',
  doc = 'DOC - Microsoft word document',
  dot = 'DOT - Microsoft word template',
  docx = 'DOCX - Microsoft word document',
  dotx = 'DOTX - Microsoft word template',
  docm = 'DOCM - Microsoft word macro-enabled document',
  dotm = 'DOTM - Microsoft word macro-enabled template',
  xls = 'XLS - Microsoft excel spreadsheet',
  xlt = 'XLT - Microsoft excel template',
  xla = 'XLA - Microsoft excel add-in',
  xlsx = 'XLSX - Microsoft excel spreadsheet',
  xltx = 'XLTX - Microsoft excel template',
  xlsm = 'XLSM - Microsoft excel macro-enabled spreadsheet',
  xltm = 'XLTM - Microsoft excel macro-enabled template',
  xlam = 'XLAM - Microsoft excel macro-enabled add-in',
  xlsb = 'XLSB - Microsoft excel macro-enabled binary',
  xlc = 'XLC - Microsoft office',
  xlw = 'XLW - Microsoft office',
  xll = 'XLL - Microsoft office',
  xld = 'XLD - Microsoft office',
  ppt = 'PPT - Microsoft powerpoint presentation',
  pot = 'POT - Microsoft powerpoint template',
  pps = 'PPS - Microsoft powerpoint slideshow',
  ppa = 'PPA - Microsoft powerpoint add-in',
  pptx = 'PPTX - Microsoft powerpoint presentation',
  potx = 'POTX - Microsoft powerpoint template',
  ppsx = 'PPSX - Microsoft powerpoint slideshow',
  ppam = 'PPAM - Microsoft powerpoint macro-enabled add-in',
  pptm = 'PPTM - Microsoft powerpoint macro-enabled presentation',
  potm = 'POTM - Microsoft powerpoint macro-enabled template',
  ppsm = 'PPSM - Microsoft powerpoint macro-enabled slideshow',
  ppz = 'PPZ - Microsoft office',
  sldx = 'SLDX - Microsoft office',
  thmx = 'THMX - Microsoft office',
  mht = 'MHT - web archive',
  mhtml = 'MHTML - web archive',
  iqy = 'IQY - binary',
  apk = 'APK - Android application package expansion file',
  obb = 'OBB - Android/play opaque binary blob',
  dex = 'DEX - Dalvik executable',
  html = 'HTML - Hypertext markup language',
  htm = 'HTM - Hypertext markup language',
  xhtml = 'XHTML - Extensible hypertext markup language',
  ics = 'ICS - iCalendar',
  onetoc = 'ONETOC - Microsoft OneNote',
  onetoc2 = 'ONETOC2 - Microsoft OneNote',
  onetmp = 'ONETMP - Microsoft OneNote',
  onepkg = 'ONEPKG - Microsoft OneNote',
  one = 'ONE - Microsoft OneNote'
}
