import { useCallback, useEffect, useMemo } from 'react'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { EditDomainWarningDialogType } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import {
  getAzureAdTenantStatus,
  getAzureAdAdminConsent,
  postAzureAdDisconnect,
  resetAzureAdTenantStatus
} from 'redux/features/azureAd/azureAdSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { TenantStatus, TenantAuthorizationStatus } from 'types/azureAd'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { formatDate } from 'lib/datetime'
import appConfig from 'config/appConfig'
import { SettingValue } from 'types/Settings'

export interface State {
  tenantStatus: TenantAuthorizationStatus
  authorizationDate: string | undefined
  authorizedAccount: string | undefined
  isDisconnectPending: boolean
  isAuthorizeDisabled: boolean
  isRevokeAuthorizationDisabled: boolean
}

export interface EventHandlers {
  onAuthorize: () => void
  onRevokeAuthorization: () => void
}

export type AzureAdAuthorization = [State, EventHandlers]

export const useAzureAdAuthorization = (form: EditDomainForm): AzureAdAuthorization => {
  const [
    { domainId, tenantStatus: formTenantStatus, autoSync, enableSso, inProgress },
    { openWarningDialog, closeWarningDialog }
  ] = form
  const dispatch = useAppDispatch()
  const {
    isDisconnectPending,
    isDisconnectSuccess,
    isGetTenantStatusPending,
    isGetTenantStatusError,
    authorizedAt,
    authorizedAccount,
    currentTenantStatus
  } = useAppSelector(_store => ({
    isDisconnectPending: isPending(_store.azureAd.api.postAzureAdDisconnect),
    isDisconnectSuccess: isSuccess(_store.azureAd.api.postAzureAdDisconnect),
    isGetTenantStatusPending: isPending(_store.azureAd.api.getAzureAdTenantStatus),
    isGetTenantStatusError: isFailed(_store.azureAd.api.getAzureAdTenantStatus),
    authorizedAt: _store.azureAd.azureAdTenantStatus?.authorizedAt,
    authorizedAccount: _store.azureAd.azureAdTenantStatus?.tenantName,
    currentTenantStatus: _store.azureAd.azureAdTenantStatus?.status
  }))

  const tenantStatus = useMemo(() => {
    switch (true) {
      case isGetTenantStatusError:
        return TenantAuthorizationStatus.ERROR
      case currentTenantStatus === TenantStatus.ACTIVE:
        return TenantAuthorizationStatus.AUTHORIZED
      case currentTenantStatus === TenantStatus.INACTIVE:
        return TenantAuthorizationStatus.UNAUTHORIZED
      case !currentTenantStatus && isGetTenantStatusPending:
        return TenantAuthorizationStatus.UNKNOWN
      default:
        return TenantAuthorizationStatus.UNAUTHORIZED
    }
  }, [currentTenantStatus, isGetTenantStatusError, isGetTenantStatusPending])

  const authorizationDate = useMemo(() => {
    if (!authorizedAt || authorizedAt < 0) {
      return undefined
    }
    return formatDate(new Date(authorizedAt), appConfig.DATETIME.AZURE_AUTHORIZATION_DATE_TIME_FORMAT)
  }, [authorizedAt])

  const isAuthorizeDisabled = useMemo(() => inProgress, [inProgress])

  const isRevokeAuthorizationDisabled = useMemo(
    () => tenantStatus !== TenantAuthorizationStatus.AUTHORIZED || isDisconnectPending || inProgress,
    [inProgress, isDisconnectPending, tenantStatus]
  )

  const onConfirmAuthorization = useCallback(() => {
    dispatch(getAzureAdAdminConsent({ domainId, tenantState: appConfig.AZURE_AD.TENANT_STATE_STATIC }))
  }, [dispatch, domainId])

  const onAuthorize = useCallback(() => {
    openWarningDialog({
      type: EditDomainWarningDialogType.AUTHORIZE_AZURE_AD,
      dialogActions: {
        onConfirm: onConfirmAuthorization,
        onClose: closeWarningDialog
      }
    })
  }, [closeWarningDialog, onConfirmAuthorization, openWarningDialog])

  const onRevokeAuthorization = useCallback(() => {
    dispatch(postAzureAdDisconnect({ domainId }))
  }, [dispatch, domainId])

  useEffect(() => {
    dispatch(getAzureAdTenantStatus({ domainId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    formTenantStatus.setValue(tenantStatus)
  }, [tenantStatus, formTenantStatus])

  useEffect(() => {
    if (isDisconnectSuccess) {
      autoSync.reset(SettingValue.DISABLED)
      enableSso.reset(SettingValue.DISABLED)
      dispatch(resetAzureAdTenantStatus())
      dispatch(getAzureAdTenantStatus({ domainId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisconnectSuccess])

  return useMemo(
    () => [
      {
        tenantStatus,
        authorizationDate,
        authorizedAccount,
        isDisconnectPending,
        isAuthorizeDisabled,
        isRevokeAuthorizationDisabled
      },
      { onAuthorize, onRevokeAuthorization }
    ],
    [
      tenantStatus,
      authorizationDate,
      authorizedAccount,
      isDisconnectPending,
      isAuthorizeDisabled,
      isRevokeAuthorizationDisabled,
      onAuthorize,
      onRevokeAuthorization
    ]
  )
}
