import { createAction, createSlice, isAnyOf } from '@reduxjs/toolkit'

import * as messages from 'redux/features/dataTables/messages/messagesSlice'
import * as auditLog from 'redux/features/dataTables/auditLog/auditLogSlice'
import * as atp from 'redux/features/dataTables/atp/atpSlice'
import * as users from 'redux/features/dataTables/users/usersSlice'
import * as domains from 'redux/features/dataTables/domains/domainsSlice'
import { DataTableState } from 'types/redux/dataTables/dataTables'

export interface DataTablesState {
  messages: DataTableState
  auditLog: DataTableState
  atp: DataTableState
  users: users.UsersTableState
  domains: DataTableState
}

// initialState
export const INITIAL_STATE = {
  messages: messages.INITIAL_STATE,
  auditLog: auditLog.INITIAL_STATE,
  atp: atp.INITIAL_STATE,
  users: users.INITIAL_STATE,
  domains: domains.INITIAL_STATE
}

const reset = createAction('DATATABLES/reset/all')

/* eslint-disable no-param-reassign */
export const dataTablesSlice = createSlice({
  name: 'DATA_TABLES',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(reset, () => ({
        ...INITIAL_STATE
      }))
      .addMatcher(isAnyOf(messages.update, messages.reset), (state: DataTablesState, action) => {
        state.messages = { ...messages.default(state.messages, action) }
      })
      .addMatcher(isAnyOf(auditLog.update, auditLog.reset), (state: DataTablesState, action) => {
        state.auditLog = { ...auditLog.default(state.auditLog, action) }
      })
      .addMatcher(isAnyOf(atp.update, atp.reset), (state: DataTablesState, action) => {
        state.atp = { ...atp.default(state.atp, action) }
      })
      .addMatcher(isAnyOf(users.update, users.reset), (state: DataTablesState, action) => {
        state.users = { ...users.default(state.users, action) }
      })
      .addMatcher(isAnyOf(domains.update, domains.reset), (state: DataTablesState, action) => {
        state.domains = { ...domains.default(state.domains, action) }
      })
  }
})
/* eslint-enable no-param-reassign */

export default dataTablesSlice.reducer
