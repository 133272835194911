import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage } from 'redux/toolkit/api'
import { trackMixpanelEvent, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { azureSSOLogin } from 'redux/features/auth/authSlice'
import routesConfig from 'lib/routesConfig'

export interface State {
  loginError: string | undefined
}

export interface EventHandlers {
  onCloseErrorDialog: () => void
}

export type AzureSSOLogic = [State, EventHandlers]

export const useAzureSSOLogic = (): AzureSSOLogic => {
  const { search } = useLocation()
  const dispatch = useAppDispatch()
  const { loginError, accessToken } = useAppSelector(_store => ({
    loginError: getErrorMessage(_store.auth.loginApiStatus),
    accessToken: _store.auth.accessToken
  }))
  const params = new URLSearchParams(search)
  const state = params.get('state') || ''
  const code = params.get('code') || ''
  const sessionState = params.get('session_state') || ''

  const onCloseErrorDialog = () => routesConfig.LOGIN.goto()

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.AZURE_SSO_PAGE_VIEW)
  }, [dispatch])

  useEffect(() => {
    dispatch(
      azureSSOLogin({
        state,
        code,
        session_state: sessionState
      })
    )
  }, [search, state, code, sessionState, dispatch])

  useEffect(() => {
    if (accessToken) {
      routesConfig.MESSAGE_LOG.goto()
    }
  }, [accessToken])

  return useMemo(
    () => [
      {
        loginError
      },
      {
        onCloseErrorDialog
      }
    ],
    [loginError]
  )
}
