import React, { useMemo } from 'react'
import { useEditDomainDirectoryServicesLogic } from 'components/pages/domains/editDomain/editDomainDirectoryServices/useEditDomainDirectoryServicesLogic'
import { useFormatMessage } from 'lib/localization'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { DirectoryService } from 'types/domains'
import { AzureAdSettings } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/AzureAdSettings'
import { FormRow } from 'components/libs/layout/pages/FormRow'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'
import { InlineRadioGroup } from 'components/libs/layout/pages/formContent/InlineRadioGroup'
import { LdapSettings } from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/LdapSettings'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface EditDomainDirectoryServicesProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains'

const EditDomainDirectoryServices = ({ form }: EditDomainDirectoryServicesProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditDomainDirectoryServicesLogic(form)

  return useMemo(
    () => (
      <FormBlock
        title={formatMessage('edit_domain.directory_services.title')}
        description={formatMessage('edit_domain.directory_services.description')}
      >
        <FormRow rowTitle={formatMessage('edit_domain.directory_services.type.label')}>
          <InlineRadioGroup
            name="directory_services"
            value={state.directoryServices}
            onChange={eventHandlers.onChangeDirectoryServices}
            disabled={state.isDirectoryServicesDisabled}
            values={[DirectoryService.LDAP, DirectoryService.AZURE_AD, DirectoryService.NONE]}
            labels={[
              formatMessage('edit_domain.directory_services.types.ldap'),
              formatMessage('edit_domain.directory_services.types.azure_ad'),
              formatMessage('edit_domain.directory_services.types.none')
            ]}
          />
        </FormRow>
        <FormSpacer />
        {state.directoryServices === DirectoryService.AZURE_AD && <AzureAdSettings form={form} />}
        {state.directoryServices === DirectoryService.LDAP && <LdapSettings form={form} />}
      </FormBlock>
    ),
    [eventHandlers, form, formatMessage, state]
  )
}

export default EditDomainDirectoryServices
