import { MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { process } from '@progress/kendo-data-query'

import { useFormatMessage } from 'lib/localization'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'
import { ActionTaken, ReportMessage } from 'types/Messages'
import { BDSGridPagerConfig } from 'types/redux/dataTables/dataTables'
import { stopDeliverReasons } from 'config/filterMaps'

import { mapReasons, mapSize } from 'lib/mstore'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import {
  checkAllSearchMessages,
  deleteMessage,
  getRedeliveryQueueMessages,
  getSearch,
  MlogViewConfig,
  postRecategorize,
  postRedeliverMessage,
  postRejectMessages,
  resetSearch,
  setRedeliveryQueueMessages
} from 'redux/features/mstore/mstoreSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import {
  reset as resetMessagesTable,
  update as updateMessagesTable
} from 'redux/features/dataTables/messages/messagesSlice'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import appFeatures from 'config/appFeatures'
import { useUserDomainIdOverride } from 'lib/hooks/useUserDomainIdOverride'

export interface UseMessageLogListToolbarLogic {
  isGetSearchLoading: boolean
  disableRefresh: boolean
  badgeCount: number
  redeliveryOpen: boolean
  setRedeliveryOpen: (isOpen: boolean) => void
  recategorizeOtherOpen: boolean
  setRecategorizeOtherOpen: (isOpen: boolean) => void
  handleIfCanDeliver: () => void
  handleDelete: () => void
  anchorEl: HTMLElement | null
  setAnchorEl: (e: SetStateAction<HTMLElement | null>) => void
  handleRecategorizeClick: (category: string) => void
  handleSetOpenCategorizeOther: () => void
  setComposerOpen: (isOpen: boolean) => void
  handleOpenRedeliveryQueueModal: (status: boolean) => void
  pageConfig: BDSGridPagerConfig
  composerOpen: boolean
  redeliveryQueueOpen: boolean
  setRedeliveryQueueOpen: (isOpen: boolean) => void
  messages: ReportMessage[]
  onRefresh: () => void
  checkConfig: {
    isChecked: boolean
    isIndeterminate: boolean
    onCheckAll: () => void
  }
  exportConfig: {
    exportRef: MutableRefObject<(CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null>
    fileName: () => string
    headers: {
      label: string
      key: string
    }[]
    csvExport: () => void
  }
  checkedMessages: ReportMessage[]
  viewConfig: MlogViewConfig
  handleDirectDeliver: () => void
  handleReject: () => void
  permissions: {
    canRecategorizeMessage: boolean
    canDeleteMessage: boolean
    canCreateNewEmail: boolean
  }
}

const BASE_I18N_KEY = 'ess.message_log'

export const useMessageLogListToolbarLogic = (): UseMessageLogListToolbarLogic => {
  const { hasRightToSkipSendUserAction, canRecategorizeMessage, canDeleteMessage, canCreateNewEmail } =
    useMessageLogRights()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const {
    redeliveryQueue,
    isGetSearchLoading,
    isMessageHistoryOpen,
    mstoreSearch,
    checkedMessages,
    messageTable,
    searchFilter,
    searchTerm,
    viewConfig,
    isDeleteMessageSuccess,
    userId,
    userDomainId
  } = useAppSelector(_store => ({
    redeliveryQueue: _store.mstore.redeliveryQueue,
    isGetSearchLoading: isPending(_store.mstore.getSearchApiStatus),
    isMessageHistoryOpen: _store.mstore.isMessageHistoryOpen,
    mstoreSearch: _store.mstore.search,
    checkedMessages: _store.mstore.checkedMessages,
    messageTable: _store.dataTables.messages,
    searchFilter: _store.mstore.searchFilter,
    searchTerm: _store.mstore.searchTerm,
    viewConfig: _store.mstore.viewConfig,
    isDeleteMessageSuccess: isSuccess(_store.mstore.deleteMessageApiStatus),
    userId: _store.auth.accessTokenObject?.userId,
    userDomainId: _store.auth.accessTokenObject?.domainId
  }))
  const overrideDomainId = useUserDomainIdOverride()
  const dispatch = useAppDispatch()

  const exportRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  const [redeliveryOpen, setRedeliveryOpen] = useState(false)
  const [recategorizeOtherOpen, setRecategorizeOtherOpen] = useState(false)
  const [composerOpen, setComposerOpen] = useState(false)
  const [redeliveryQueueOpen, setRedeliveryQueueOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isIndeterminate, setIsIndeterminate] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const messagesRange = useMemo(
    () => ({
      from: 0,
      to: Math.min(mstoreSearch?.messages?.length || 0, messageTable.skip + messageTable.take)
    }),
    [messageTable, mstoreSearch]
  )

  useEffect(() => {
    if (!mstoreSearch?.messages?.length || !checkedMessages.length) {
      setIsChecked(false)
      setIsIndeterminate(false)
      return
    }

    if (messagesRange.to - messagesRange.from === checkedMessages.length) {
      setIsChecked(true)
      setIsIndeterminate(false)
    } else {
      setIsChecked(false)
      setIsIndeterminate(true)
    }
  }, [checkedMessages, mstoreSearch, messagesRange])

  const formattedMessages = useMemo(
    () =>
      process(
        (checkedMessages || []).map((report: ReportMessage) => ({
          ...(report && {
            ...report,
            exportDate: formatDate(new Date(report.date), config.DATETIME.EXPORT_DATE_FORMAT),
            formattedSize: mapSize(report.size),
            reason: mapReasons(report.reasons, formatMessage)
          })
        })),
        {}
      ).data,
    [checkedMessages, formatMessage]
  )

  const handleOpenRedeliveryQueueModal = useCallback(
    (status: boolean) => {
      dispatch(getRedeliveryQueueMessages())
      setRedeliveryQueueOpen(status)
    },
    [dispatch]
  )

  const handleRecategorizeClick = useCallback(
    (category: string) => {
      dispatch(
        postRecategorize({
          messageIds: checkedMessages.map(message => message.mid),
          category
        })
      )

      setAnchorEl(null)
      setRecategorizeOtherOpen(false)
    },
    [dispatch, checkedMessages]
  )

  const handleSetOpenCategorizeOther = useCallback(() => {
    setAnchorEl(null)
    setRecategorizeOtherOpen(true)
  }, [])

  const onRefresh = useCallback(() => {
    dispatch(resetSearch({ dateReset: true }))
    dispatch(resetMessagesTable())
    dispatch(getSearch({ keepPagination: true }))
  }, [dispatch])

  const handleDelete = useCallback(() => {
    dispatch(
      deleteMessage({
        items: checkedMessages.map(message => {
          const domainId = Number(overrideDomainId(message.did))

          return {
            messageId: message.mid,
            domainId:
              appFeatures.Enable_message_auth_via_mstore_search && userDomainId !== undefined
                ? userDomainId
                : Number(message.did)
          }
        }),
        userId: hasRightToSkipSendUserAction ? undefined : userId
      })
    )
  }, [dispatch, checkedMessages, hasRightToSkipSendUserAction, userId, overrideDomainId, userDomainId])

  const handleIfCanDeliver = useCallback(() => {
    const reasonFound = checkedMessages.find(
      aMessage => aMessage.reasons.length && stopDeliverReasons.indexOf(aMessage.reasons[0]) > -1
    )
    if (reasonFound) {
      dispatch(
        setErrorSnackBar({
          message: 'stop_delivery_reasons',
          params: [formatMessage(`reason.${reasonFound.reasons[0]}`)]
        })
      )
    } else {
      dispatch(setRedeliveryQueueMessages(checkedMessages.map(m => ({ ...m, did: Number(m.did) || 0 }))))
      setRedeliveryOpen(true)
    }
  }, [dispatch, formatMessage, checkedMessages])

  const handleDirectDeliver = useCallback(() => {
    const payload = {
      items: checkedMessages.map(msg => ({ messageId: msg.mid, domainId: Number(msg.did) || 0 })),
      atpBypass: false
    }
    dispatch(postRedeliverMessage(payload))
  }, [dispatch, checkedMessages])

  const handleReject = useCallback(() => {
    dispatch(postRejectMessages(checkedMessages.map(msg => ({ messageId: msg.mid, domainId: Number(msg.did) }))))
  }, [checkedMessages, dispatch])

  const onCheckAll = useCallback(() => {
    dispatch(checkAllSearchMessages({ ...messagesRange, isChecked: !isChecked }))
  }, [dispatch, isChecked, messagesRange])

  const pageConfig: BDSGridPagerConfig = useMemo(() => {
    const { skip, take }: { skip: number; take: number } = messageTable

    return {
      pageable: {
        buttonCount: 0
      },
      skip,
      take,
      total: mstoreSearch?.messageCount || 0,
      onPageChange: (e: any) => {
        dispatch(updateMessagesTable({ skip: e.skip }))
        dispatch(checkAllSearchMessages({ ...messagesRange, isChecked: false }))
        setIsChecked(false)
        setIsIndeterminate(false)
        dispatch(getSearch({ keepPagination: true }))
      }
    }
  }, [messageTable, mstoreSearch, dispatch, messagesRange])

  const exportHeaders = useMemo(
    () => [
      { label: formatMessage('export.message_id'), key: 'mid' },
      { label: formatMessage('export.from'), key: 'envelopeFrom' },
      { label: formatMessage('export.to'), key: 'to' },
      { label: formatMessage('export.subject'), key: 'subject' },
      { label: formatMessage('export.date'), key: 'exportDate' },
      { label: formatMessage('export.size'), key: 'formattedSize' },
      { label: formatMessage('export.action'), key: 'status' },
      { label: formatMessage('export.reason'), key: 'reason' }
    ],
    [formatMessage]
  )

  const exportFileName = useCallback(() => {
    const currentDate = formatDate(new Date(), config.DATETIME.DASH_SEPARATED_DATE_WITHOUT_TIME)
    return `message_log_${currentDate}.csv`
  }, [])

  const csvExport = () => {
    if (exportRef?.current) {
      exportRef.current.link?.click()
    }
  }

  const disableRefresh = useMemo(
    () => isMessageHistoryOpen || isGetSearchLoading,
    [isMessageHistoryOpen, isGetSearchLoading]
  )

  useEffect(() => {
    if (isDeleteMessageSuccess) {
      onRefresh()
    }
  }, [isDeleteMessageSuccess, onRefresh])

  const canDeleteSelectedMessages = useMemo(
    () => canDeleteMessage && searchFilter.action === ActionTaken.quarantined && !Number(searchTerm.outbound),
    [searchFilter, canDeleteMessage, searchTerm]
  )

  return useMemo(
    () => ({
      isGetSearchLoading,
      disableRefresh,
      badgeCount: redeliveryQueue?.results?.length || 0,
      redeliveryOpen,
      setRedeliveryOpen,
      recategorizeOtherOpen,
      setRecategorizeOtherOpen,
      handleIfCanDeliver,
      handleDelete,
      anchorEl,
      setAnchorEl,
      handleRecategorizeClick,
      handleSetOpenCategorizeOther,
      setComposerOpen,
      handleOpenRedeliveryQueueModal,
      pageConfig,
      composerOpen,
      redeliveryQueueOpen,
      setRedeliveryQueueOpen,
      messages: mstoreSearch.messages,
      onRefresh,
      checkConfig: {
        isChecked,
        isIndeterminate,
        onCheckAll
      },
      exportConfig: {
        exportRef,
        fileName: exportFileName,
        headers: exportHeaders,
        csvExport
      },
      checkedMessages: formattedMessages,
      viewConfig,
      handleDirectDeliver,
      handleReject,
      permissions: {
        canRecategorizeMessage,
        canDeleteMessage: canDeleteSelectedMessages,
        canCreateNewEmail
      }
    }),
    [
      disableRefresh,
      redeliveryQueue,
      isGetSearchLoading,
      redeliveryOpen,
      setRedeliveryOpen,
      recategorizeOtherOpen,
      setRecategorizeOtherOpen,
      handleIfCanDeliver,
      handleDelete,
      anchorEl,
      setAnchorEl,
      handleRecategorizeClick,
      handleSetOpenCategorizeOther,
      handleOpenRedeliveryQueueModal,
      composerOpen,
      redeliveryQueueOpen,
      setRedeliveryQueueOpen,
      isChecked,
      isIndeterminate,
      mstoreSearch,
      onCheckAll,
      onRefresh,
      pageConfig,
      exportFileName,
      exportHeaders,
      viewConfig,
      handleDirectDeliver,
      handleReject,
      formattedMessages,
      canRecategorizeMessage,
      canDeleteSelectedMessages,
      canCreateNewEmail
    ]
  )
}
