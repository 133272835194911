import { InboundScheduleUI, InboundSchedule, InboundScheduleUIValue, InboundScheduleValue } from 'types/Settings'

/**
 * Convert QN schedule days from lists to objects, e.g., [3,20] => {3: true, 20: true}
 * @param scheduleArr
 */
export function convertScheduleToObj(scheduleArr: InboundSchedule): InboundScheduleUI {
  return Object.entries(scheduleArr).reduce(
    (scheduleObj, [key, val]) => ({
      ...scheduleObj,
      [key]: hoursArrToObj(val)
    }),
    {} as InboundScheduleUI
  )
}

function hoursArrToObj(hoursArr: InboundScheduleValue): InboundScheduleUIValue {
  return hoursArr.reduce((accumulator, time) => {
    accumulator[time] = true
    return accumulator
  }, {} as InboundScheduleUIValue)
}

/**
 * Convert QN schedule days from objects to lists, e.g., {3: true, 20: true} => [3,20]
 * @param scheduleObj
 */
export function convertScheduleToArr(scheduleObj: InboundScheduleUI): InboundSchedule {
  return Object.entries(scheduleObj).reduce(
    (scheduleArr, [key, val]) => ({
      ...scheduleArr,
      [key]: Object.keys(val).map(Number)
    }),
    {} as InboundSchedule
  )
}
