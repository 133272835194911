import OVERVIEW_TAB_ES from 'config/i18n/tabs/overview_es'
import INBOUND_SETTINGS_TAB_ES from 'config/i18n/tabs/inbound_settings_es'
import OUTBOUND_SETTINGS_TAB_ES from 'config/i18n/tabs/outbound_settings_es'
import USERS_TAB_ES from 'config/i18n/tabs/users_es'
import SUPPORT_ES from 'config/i18n/support_es'
import DOMAINS_TAB_ES from 'config/i18n/tabs/domains_es'

const EMAIL = 'Correo electrónico'
const HEADERS = 'Cabeceras'
const MESSAGE_LOG = 'Registro de Mensajes'
const SETTINGS = 'Parámetros'
const SUPPORT = 'Apoyo'
const SENDER = 'Remitente'
const SUBJECT = 'Asunto'
const DOMAINS = 'Dominios'
const OVERVIEW = 'Visión general'
const INBOUND_SETTINGS = 'Ajustes de entrada'
const OUTBOUND_SETTINGS = 'Ajustes de salida'
const ATP_SETTINGS = 'Ajustes ATP'
const USERS = 'Usuarios'
const REPORTS = 'Informes'
const ACCOUNT_MANAGEMENT = 'Gestión de cuentas'
const SEARCH = 'Buscar en'
const CLOSE = 'Cierra'
const SAVE = 'Guardar'
const CANCEL = 'Cancelar'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess: {
    overview: OVERVIEW_TAB_ES,
    inbound_settings: INBOUND_SETTINGS_TAB_ES,
    outbound_settings: OUTBOUND_SETTINGS_TAB_ES,
    users: USERS_TAB_ES,
    support_page: SUPPORT_ES,
    domains: DOMAINS_TAB_ES,

    account_switcher: {
      switching_account: 'Cambiando a la cuenta "{account}" ...',
      cancel: CANCEL
    },
    ui_switcher: {
      new_interface: 'Nueva interfaz'
    },
    nivo: {
      choropleth_tooltip_attacks: '{count, plural, =0 {Attacks} one {Attack} other {Attacks}}'
    },
    layout: {
      yes: 'Sí',
      no: 'No',
      cancel: CANCEL,
      save_changes: 'Guardar cambios',
      search: SEARCH,
      unsaved_dialog: {
        title: 'Cambios no guardados',
        text: 'Hay cambios sin guardar en esta página. ¿Desea continuar?'
      },
      global_dialog: {
        not_supported_role_type: {
          title: 'Redirección a la interfaz clásica',
          text: 'Los usuarios con privilegios de administrador de dominio o de Help Desk sólo son compatibles actualmente con la interfaz clásica.',
          button: 'Redirigir'
        },
        invalid_access: {
          title: 'Acceso no válido',
          text: 'La función de seguridad mejorada (SSO) no está habilitada. Póngase en contacto con el soporte técnico de Barracuda Networks.',
          button: 'Ok'
        }
      }
    },
    app: {
      error: {
        default: 'Oops! Algo salió mal',
        failed_to_load_content: 'Fallo al cargar el contenido',
        add_domain_error_duplicate: 'Este nombre de dominio ya ha sido añadido.',
        add_domain_error_quarantine_notifier: 'Error al crear la configuración del Notificador de Cuarentena',
        add_domain_error_blacklisted_domain_name: 'Nombre de dominio en la lista negra',
        delete_domain_failure_not_found: 'No se ha encontrado el dominio que se desea eliminar',
        delete_domain_failure_quarantine_notifier:
          'Se ha eliminado el dominio, pero no se ha podido eliminar la configuración del notificador de cuarentena.',
        manage_domain_failure_forbidden: 'No tienes privilegios suficientes para gestionar el dominio seleccionado',
        edit_domain_failure_forbidden: 'No tienes privilegios suficientes para editar el dominio seleccionado',
        get_domain_failure: 'Se ha producido un error al obtener los datos del dominio'
      }
    },
    loading: 'Cargando...',
    expiration_banner: {
      free_trial_expires: 'Prueba gratuita caducada'
    },
    global_message_banner: {
      impersonated_user_text: 'Conectado como: <b>{user}</b>.',
      impersonated_user_button: 'Cerrar sesión',
      pd_domain_id_text: 'Ahora está gestionando la configuración de <b>{domain}</b>.',
      pd_domain_id_text_helpdesk: 'Desempeña una función de servicio de asistencia para <b>{domain}</b>',
      pd_domain_id_button: 'Volver a la gestión de dominios.',
      wizard_text: 'Parece que aún no ha completado la configuración de Email Gateway Defense.',
      wizard_link: 'Puesta en marcha',
      wizard_complete_text: 'Configuración completada. Esperando a que llegue el correo...'
    },
    banners: {
      impersonated_account_admin: 'Administrador conectado como: {accountId}. <link>Salir de esta cuenta</link>'
    },
    advanced_search: {
      keywords: 'Palabras clave',
      from: 'De',
      envelope_from: 'Envelope From',
      to: 'Para',
      envelope_to: 'Envelope To',
      subject: 'Asunto',
      attachment_name: 'Nombre de Adjunto',
      start_date: 'Fecha Inicio',
      end_date: 'Fecha Final'
    },
    save_search: {
      title: 'Salvar búsqueda',
      must_enter_name: 'Debe introducir un nombre',
      valid_name: ' ',
      name_your_search: 'Nombra tu búsqueda guardada...'
    },
    open_search: {
      title: 'Abrir búsqueda',
      subtitle: 'Nombre',
      find_your_search: 'Encuentra tu búsqueda guardada...'
    },
    login: {
      title: 'Iniciar sesión',
      signin_message: 'Inicie sesión para acceder a sus mensajes y a la configuración de Email Gateway Defense',
      signin_message_email: 'Por favor, Introduzca la contraseña de <b>{email}</b>',
      invalid_email: 'Dirección de correo electrónico y/o contraseña no válidas',
      password: 'Contraseña',
      email: 'Dirección de correo electrónico',
      reset_password_message: 'Contraseña restablecida correctamente',
      valid_email_message: 'Introduzca una dirección de correo electrónico válida',
      signin: 'Iniciar sesión',
      next: 'Próximo',
      different_account_message: 'Acceder a otra cuenta',
      forgot_password: 'Contraseña olvidada?',
      admin_signin: 'Inicio de sesión de administrador',
      signing_in: 'Espere, por favor...',
      expired_message: 'El enlace para iniciar sesión ha caducado',
      is_not_loading: 'Redirección a la interfaz clásica',
      invalid_link:
        'No se ha podido cargar la nueva interfaz de usuario en Email Gateway Defense debido a una dificultad técnica, le estamos redirigiendo a la interfaz de usuario clásica en Email Gateway Defense.',
      redirect: 'Redirigir',
      login_failed: 'El inicio de sesión no se ha realizado correctamente',
      new_link_sent: 'Se ha enviado un nuevo enlace a su correo electrónico',
      signin_shared_mailbox: 'Iniciar sesión en un buzón compartido o en una lista de distribución?',
      signin_temporary_passcode:
        'Podemos <a>enviarte por correo electrónico un código de acceso temporal</a> para que inicies sesión.',
      temporary_passcode_title: 'Introduzca la contraseña temporal',
      temporary_passcode_text:
        'Hemos enviado un código de acceso temporal a {email}. El código es válido durante 15 minutos.',
      havent_received_email: 'No ha recibido un correo electrónico?',
      resend_temporary_passcode:
        'Puede tardar unos minutos. Si ha pasado un tiempo podemos <a>reenviar un código de acceso temporal</a>.',
      already_have_passcode: 'Ya tienes un código de acceso temporal?',
      temporary_passcode: 'Código de acceso temporal',
      invalid_temporary_login:
        'El código de acceso no es válido o ha caducado. Comprueba tu correo electrónico e inténtalo de nuevo.',
      temporary_passcode_sent:
        'Se ha enviado un código de acceso temporal a su correo electrónico. Esto puede tardar unos minutos.',
      temporary_passcode_limit:
        'Ha alcanzado el límite de {rateLimit} contraseñas temporales por sesión de {expirationTime} minutos. Consulta tu correo electrónico para encontrar la contraseña temporal más reciente.',
      temporary_feature_not_enabled:
        'La función de código de acceso temporal no está activada para {domainName}. Compruébelo con su administrador.',
      internal_server_error: 'Error interno del servidor',
      message: {
        title: 'Compruebe su correo',
        sent_message: 'Se ha enviado un correo electrónico con un enlace para restablecer su contraseña a {email}.',
        expiry_message: 'El enlace caduca en 1 hora.',
        resend: 'Reenviar enlace'
      },
      azure_temporary_passcode_title: 'Opciones de inicio de sesión',
      continue: 'Continuar',
      welcome: 'Iniciar sesión',
      welcome_message: 'Inicie sesión en Email Gateway Defense para acceder a sus mensajes y ajustes',
      go_to_signin: 'Volver al inicio de sesión',
      option_divider_text: 'O',
      resend_link: 'Reenviar enlace',
      sign_in_with_microsoft: 'Continuar con Microsoft',
      email_a_temporary_passcode: 'Envíame por correo electrónico una contraseña temporal',
      password_for: 'Introduzca la contraseña para {email}',
      signin_option_for: 'Seleccione la opción de inicio de sesión para {email}',
      didnt_receive_email: '¿No ha recibido un correo electrónico?',
      resend_code: 'Reenviar código'
    },
    bcc_login: {
      error_page: {
        401: {
          title: 'Admin no puede ser autorizado',
          subtitle: 'Por favor, inténtelo de nuevo con credenciales válidas.'
        },
        default: {
          title: 'Error del servidor',
          subtitle: 'Póngase en contacto con el servicio de asistencia.'
        }
      }
    },
    reset_password: {
      title: 'Restaurar contraseña',
      reset_password_message: 'Introduzca una contraseña nueva para {email}',
      password1: 'Nueva contraseña',
      password2: 'Confirmar nueva contraseña',
      password_matches: 'La contraseña coincide',
      password_not_match: 'La contraseña no coincide',
      new_link_sent: 'Se ha enviado un nuevo enlace a su correo electrónico',
      reset: 'Reiniciar',
      expired_link: {
        title: 'Enlace caducado',
        message: 'El enlace para restablecer la contraseña de {email} ha expirado.',
        sign_in: 'Volver al inicio de sesión',
        resend_link: 'Reenviar enlace'
      },
      check_email: {
        title: 'Compruebe su correo electrónico',
        message: 'Se ha enviado un correo electrónico con un enlace para restablecer su contraseña a {email}.',
        link_will_expire: 'El enlace caduca en 1 hora.'
      },
      successful_reset: {
        title: 'Su contraseña se ha restablecido correctamente.',
        sign_in: 'Iniciar sesión'
      },
      error: {
        send_login_info_failed: 'Error al enviar la información de acceso',
        resend_link_failed: 'Error al reenviar el enlace',
        password_reset_failed: 'No se ha podido restablecer la contraseña'
      },
      didnt_receive_email: '¿No ha recibido un correo electrónico?',
      resend_link: 'Reenviar código'
    },
    button: {
      cancel: CANCEL,
      save: 'Guardar cambios'
    },
    dialog: {
      title: 'Diálogo',
      confirm_title: 'Confirmación',
      info_title: 'Información',
      error_title: 'Error',
      cancel: CANCEL,
      ok: 'Aceptar',
      yes: 'Sí',
      no: 'No',
      submit: 'Envíe',
      save: SAVE,
      open: 'Abrir',
      search: 'Buscar',
      okay: 'Aceptar',
      unsaved_changes: 'Hay cambios sin guardar en esta página. ¿Desea continuar?',
      antivirus_off: 'Al desactivar Anti-Virus se desactivará ATP',
      domain_settings_conflict: 'Este cambio entra en conflicto con {numberOfDomains} dominios.',
      domain_settings_conflict_description:
        'La configuración a nivel de dominio tiene prioridad sobre la configuración a nivel de cuenta. Guardar estos cambios no tendrá efecto para esos dominios.',
      confirm_removal: '¿Confirmar eliminación?'
    },
    reports: {
      title: 'Informa',
      search: SEARCH,
      domain: 'Dominio',
      email_flow: 'Flujo de electrónico',
      inbound: 'Entrada',
      outbound: 'Salida',
      display: 'Mostrar',
      all_domains: 'Todos los dominios',
      top: 'Top {count}',
      display_all: 'Todos',
      unable_to_load: 'No se puede cargar',
      no_data_found: 'No se han encontrado datos',
      invalid_date: 'Intervalo de fechas no válido',
      shortcut_items: {
        last_day: 'Último día',
        last_7_days: 'Últimos 7 días',
        last_30_days: 'Últimos 30 días',
        reset: 'Restablecer'
      },
      intervals: {
        hourly: 'Intervalos horarios',
        daily: 'Intervalos diarios',
        weekly: 'Intervalos semanales'
      },
      conditions_equals: 'Igual a',
      conditions_not_equals: 'No es igual a',
      report_types: {
        barracuda: 'Lista de informes',
        custom: 'Informes programados'
      },
      predefined_report_types: {
        inbound_traffic_summary: 'Resumen del tráfico entrante',
        outbound_traffic_summary: 'Resumen del tráfico saliente',
        inbound_blocked_emails_breakdown: 'Desglose de correos electrónicos entrantes bloqueados',
        top_inbound_email_senders: 'Principales remitentes de correo electrónico entrante',
        top_inbound_email_recipients: 'Principales destinatarios del correo electrónico entrante',
        top_inbound_blocked_senders_breakdown: 'Principales remitentes entrantes bloqueados',
        top_inbound_blocked_recipients_breakdown: 'Desglose de los principales destinatarios entrantes bloqueados',
        top_outbound_email_senders: 'Principales remitentes de correo electrónico saliente',
        top_outbound_blocked_senders: 'Principales remitentes salientes bloqueados'
      },
      tabs: {
        report_list: 'Report list',
        scheduled_reports: 'Scheduled reports'
      },
      title_bar: {
        download_button: 'Exportar como',
        save_as: 'Guardar como',
        download_csv: 'Descargar como CSV',
        download_pdf: 'Descargar como PDF',
        schedule_report: 'Calendario',
        close: CLOSE,
        save: SAVE,
        save_report_dialog: {
          title: 'Guardar informe',
          subtitle:
            'Introduzca un nombre para guardar este informe y sus parámetros seleccionados actuales.  Podrá acceder a este informe en el futuro en Mis informes.',
          placeholder_text: 'Nombre del informe',
          date_warning:
            'Los datos que estás guardando desde {startDate} hasta {endDate} se eliminarán permanentemente después de {deleteDate}. Esto se debe a nuestra política de retención de datos de {retentionPolicyDayRange} días. Para conservar el acceso a estos datos, descárguelos como CSV o PDF antes de {expirationDate}.',
          expired_date_range:
            'El intervalo de fechas seleccionado es inalcanzable. Seleccione un intervalo de tiempo válido para guardar este informe'
        }
      },
      labels: {
        report_date: 'Fecha',
        email: 'Correo electrónico',
        sender_email: 'Correo electrónico del remitente',
        recipient_email: 'Correo electrónico del destinatario',
        total_deferred: 'Total aplazado',
        total_blocked: 'Total bloqueado',
        total_quarantined: 'Total en cuarentena',
        total_encrypted: 'Total encriptado',
        total_allowed: 'Total permitido',
        blocked_by_rate_control: 'Bloqueado por el control de velocidad',
        blocked_by_policy: 'Bloqueado por política',
        blocked_by_spam: 'Bloqueado por spam',
        blocked_for_virus: 'Bloqueado por virus',
        blocked_by_atp: 'Bloqueado por ATP',
        blocked_other: 'Otros bloqueados'
      },
      schedule_report: {
        title: 'Calendario',
        report_name: 'Nombre del informe',
        time_range: 'Intervalo de tiempo',
        format: 'Formato',
        last_30_days: 'Últimos 30 días',
        last_7_days: 'Últimos 7 días',
        last_day: 'Último día',
        csv: 'Datos CSV',
        frequency: 'Frecuencia',
        daily: 'Diario',
        weekly: 'Semanal',
        monthly: 'Mensualmente',
        recipients: 'Destinatarios',
        cancel: CANCEL,
        schedule: 'Horario',
        on: 'En',
        every: 'Cada',
        first: 'Primero',
        second: 'Segundo',
        third: 'Tercera',
        fourth: 'Cuarto',
        last: 'Última',
        mo: 'Lu',
        tu: 'Ma',
        we: 'Mi',
        th: 'Ju',
        fr: 'Vi',
        sa: 'Sá',
        su: 'Do',
        invalid_recipient: 'El destinatario con dirección de correo electrónico {email} no es válido.',
        missing_recipients: 'Indique los destinatarios.'
      },
      scheduled_reports: {
        title: 'Informes programados',
        new_schedule: 'Nuevo horario',
        search: 'Buscar en',
        sent: 'Enviado',
        disabled: 'Discapacitados',
        processing: 'Tratamiento',
        csv: 'CSV',
        pdf: 'PDF',
        last_30_days: 'Últimos 30 días',
        last_7_days: 'Últimos 7 días',
        last_day: 'Último día',
        daily: 'Diario',
        weekly: 'Semanal',
        monthly: 'Mensualmente',
        scheduled_time: 'informe previsto a {time} para',
        next_schedule: 'Próxima entrega programada:',
        last_schedule: 'Enviado por última vez el:',
        today: 'Hoy',
        at: 'en',
        disable_schedule: 'Desactivar horario',
        enable_schedule: 'Activar horario',
        delete_schedule: 'Borrar horario',
        open_report: 'Informe abierto',
        more: 'más'
      }
    },
    settings: {
      title: 'Ajustes',
      tabs: {
        quarantine_notification: 'Notificación de cuarentena',
        sender_policies: 'Política de remitentes',
        linked_accounts: 'Cuentas vinculadas',
        change_password: 'Cambiar contraseña'
      },
      bulk_edit: {
        buttons: {
          save: 'Guardar cambios',
          cancel: CANCEL
        },
        rate_control_title: 'Edición masiva: Exenciones de control de tasas',
        custom_rbls_title: 'Edición masiva: RBL personalizados',
        ip_policies_title: 'Edición masiva: Políticas de direcciones IP',
        trusted_forwarders_title: 'Edición masiva: Remitentes de confianza',
        geoip_policies_title: 'Edición masiva: Políticas GeoIP',
        language_policies_title: 'Edición masiva: Políticas lingüísticas',
        recipient_policies_title: 'Edición masiva: Políticas de destinatarios',
        sender_policies_title: 'Edición masiva: Políticas de remitente',
        dmarc_exempt_title: 'Edición masiva: Exento de DMARC',
        dkim_exempt_title: 'Edición masiva: DKIM Exento',
        spf_exempt_title: 'Edición masiva: Exenciones del SPF por dirección IP',
        spf_domain_exempt_title: 'Edición masiva: Exenciones del SPF por dominio',
        spf_missing_exempt_title: 'Edición Masiva: Falta SPF Exento',
        ptr_exempt_title: 'Edición Masiva: PTR Exento',
        attachment_filter_title: 'Edición Masiva: Filtro de archivos adjuntos',
        message_filter_title: 'Edición Masiva: Filtro de contenido de mensajes',
        intent_domains_title: 'Edición Masiva: Políticas de Dominio de Intención',
        predefined_filters_title: 'Edición masiva: Asunto',
        predefined_filters_exceptions_title: 'Edición Masiva: Excepciones de filtro predefinidas',
        dlp_encryption_title: 'Edición Masiva: DLP/Encriptación',
        sender_ip_title: 'Edición masiva: Rangos de direcciones IP de remitente',
        inbound_tls_domains_title: 'Edición masiva: Dominios TLS forzados',
        inbound_tls_domain_exemptions_title: 'Edición masiva: Dominios TLS exentos',
        geoip_sender_exempt_title: 'Edición masiva: Exenciones GeoIP por dirección de correo electrónico/dominios',
        geoip_exempt_title: 'Edición masiva: Exenciones GeoIP por dirección IP del remitente',
        machine_learning_exempt_title:
          'Edición masiva: Exenciones de aprendizaje automático por dirección de correo electrónico/dominio',
        place_holder: 'Escriba o políticas anteriores aquí',
        title: 'Edición masiva: Política de remitentes',
        header:
          'Remitente (Dirección de correo electrónico, dominio o usuario),Política (bloquear, eximir, cuarentena),Comentario (opcional)',
        custom_rbls_header: 'Nombre del host,Comentario (opcional)',
        control_rate_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        ip_policy_header: 'Dirección IP,Máscara de red,Política (bloquear, eximir),Comentario (opcional)',
        trusted_forwarders_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        geoip_policies_header: 'País (ISO ALPHA-3),Política (bloqueo, cuarentena),Comentario (opcional)',
        language_policies_header: 'Idioma,Política (bloqueo, cuarentena),Comentario (opcional)',
        recipient_policies_header: 'Dirección de correo electrónico,Política (escaneada, exenta),Comentario (opcional)',
        sender_policies_header_cpl: 'Dirección de correo electrónico,Política (bloquear, eximir),Comentario (opcional)',
        sender_policies_header:
          'Dirección de correo electrónico,Política (bloquear, eximir, poner en cuarentena),Comentario (opcional)',
        geoip_exempt_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        geoip_sender_exempt_header: 'Correo electrónico / Dominio,Comentario (opcional)',
        dmarc_exempt_header: 'Dominio,Comentario (opcional)',
        dkim_exempt_header: 'Dominio,Comentario (opcional)',
        spf_exempt_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        spf_domain_exempt_header: 'Dominio,Comentario (opcional)',
        spf_missing_exempt_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        ptr_exempt_header: 'Dirección IP,Máscara de red,Comentario (opcional)',
        sender_ip_header: 'Registro y política Dominio,Dirección IP,Máscara de red,Comentario (Opcional)',
        machine_learning_exempt_header: 'Correo electrónico / Dominio,Comentario (opcional)',
        attachment_filter_header:
          'Tipo de filtro (nombre de archivo/mime),Patrón (expresión Wilcard o tipo mime),Comprobar archivos (1/0),Acción (bloquear/ignorar/cuarentena),Comentario (opcional)',
        attachment_filter_header_cpl:
          'Tipo de filtro (nombre de archivo/mime),Patrón (expresión Wilcard o tipo mime),Comprobar archivos (1/0),Acción (bloquear/ignorar),Comentario (opcional)',
        message_filter_header:
          'Patrón (expresión regular),Acción (bloquear/permitir/cuarentena),Filtro de coincidencias (lista separada por comas de: asunto, encabezados, cuerpo, adjuntos, remitente, destinatario)',
        outbound_message_filter_header:
          'Patrón (expresión regular),Acción (bloquear/permitir/cuarentena/cifrar/no_cifrar),Filtro de coincidencias (lista separada por comas de: asunto, encabezados, cuerpo, adjuntos, remitente, destinatario).',
        message_filter_header_cpl:
          'Patrón (expresión regular),Acción (bloquear/permitir),Filtro de coincidencias (lista separada por comas de: asunto, encabezados, cuerpo, adjuntos, remitente, destinatario)',
        intent_domains_header: 'Dominio,Política (ignorar/bloquear/cuarentena),Comentario (opcional)',
        intent_domains_header_cpl: 'Dominio,Política (ignorar/bloquear),Comentario (opcional)',
        predefined_filters_header_cpl:
          'Patrón (tarjeta de crédito/ssn/privacidad/hipaa),Acción (bloquear),Filtro de coincidencia (lista separada por comas de: asunto, encabezados, cuerpo, adjuntos)',
        predefined_filters_header:
          'Patrón (tarjeta de crédito/ssn/privacidad/hipaa),Acción (bloquear),Filtro de coincidencia (lista separada por comas de: asunto, encabezados, cuerpo, adjuntos)',
        predefined_filters_exception_header: 'Tipo (número de teléfono/dirección),Patrón',
        dlp_encryption_header: 'Nombre de dominio,Comentario (opcional)',
        inbound_tls_domains_header: 'Nombre de dominio,Comentario (opcional)',
        inbound_tls_domain_exemptions_header: 'Nombre de dominio,Comentario (opcional)',
        sub_title:
          'La edición masiva le permite realizar múltiples cambios (adiciones, sustracciones, modificaciones) en una lista de parámetros de configuración en un solo paso..',
        content_header: 'Edición de los datos CSV',
        content_sub_header:
          'Muchas columnas sólo pueden aceptar formas específicas de datos. Por ejemplo, las columnas asociadas a direcciones de correo electrónico deben contener datos que aparezcan en el formato de una dirección de correo electrónico. Algunas columnas están asociadas a datos que son opcionales o tienen valores por defecto asociados, y pueden dejarse vacías.',
        warning:
          'Nota: Asegúrese de que la primera línea no contiene ningún dato válido, ya que no se analiza. Utilice títulos de columna en la primera línea si lo desea.',
        error_missing_policy: 'Error en la fila %d (Falta la política)',
        error_invalid_name: 'Error en la fila %d (Nombre no válido)',
        error_invalid_policy: 'Error en la fila %d (Política no válida)',
        error_missing_zone: 'Error en la fila {row} (Falta zona)',
        error_invalid_zone: 'Error en la fila {row} (Zona no válida)',
        error_missing_address: 'Error en la fila {row} (Falta dirección)',
        error_invalid_address: 'Error en la fila {row} (Dirección no válida)',
        error_missing_netmask: 'Error en la fila {row} (Falta la máscara de red)',
        error_invalid_netmask: 'Error en la fila {row} (Máscara de red no válida)',
        error_missing_ip_policy: 'Error en la fila {row} (Política no especificada)',
        error_invalid_ip_policy: 'Error en la fila {row} (Política no válida)',
        error_missing_country: 'Error en la fila {row} (País que falta)',
        error_invalid_country: 'Error en la fila {row} (Código de país no válido)',
        error_missing_email: 'Error en la fila {row} (Falta la dirección de correo electrónico)',
        error_invalid_email: 'Error en la fila {row} (Dirección de correo electrónico no válida: {item})',
        error_missing_domain: 'Error on row %d (Falta dominio)',
        error_invalid_domain: 'Error on row %d (Dominio no válido)',
        error_on_row: 'Error on row {row}',
        error_missing_filter_type: 'Falta el tipo de filtro',
        error_invalid_filter_type: 'Tipo de filtro no válido',
        error_missing_filter_pattern: 'Falta el patrón del filtro',
        error_missing_predefined_filter_pattern: 'Falta el patrón predefinido',
        error_invalid_filter_pattern: 'Patrón de filtro no válido',
        error_missing_archive_check: 'Falta la comprobación del archivo',
        error_invalid_archive_check: 'Comprobación de archivo no válida',
        error_missing_filter_action: 'Falta la acción del filtro',
        error_invalid_filter_action: 'Acción de filtro no válida',
        error_missing_match_filter: 'Debe especificarse al menos un filtro de coincidencia',
        error_invalid_match_filter: 'Filtro de coincidencia no válido especificado',
        error_missing_type: 'Tipo que falta',
        error_invalid_type: 'Tipo no válido',
        error_invalid_phone_number: 'Número de teléfono no válido',
        error_duplicate_value: 'Error en la fila {row} (Valor duplicado: {item})',
        error_invalid_language: 'Código de idioma no válido',
        error_missing_language: 'Lengua que falta',
        error_missing_machine_learning_exempt: 'Falta dirección de correo electrónico/dominio',
        error_invalid_email_domain: 'Correo electrónico o dominio no válidos'
      },
      ip_address_table: {
        ip_address: 'Dirección IP',
        netmask: 'Máscara de red',
        policy: 'Política',
        comment: 'Comentario',
        actions: 'Acciones',
        add: 'Añadir',
        remove: 'Eliminar',
        confirm_removal: '¿Confirmar eliminación?',
        block: 'Bloque',
        exempt: 'Exento',
        error: {
          empty_ip_address: 'Por favor, introduzca una dirección IP válida.',
          invalid_ip_address: 'Dirección IP no válida.',
          empty_netmask: 'Introduzca una máscara de red válida.',
          invalid_netmask: 'Máscara de red no válida.',
          duplicate_value: 'Valor duplicado: {item}'
        }
      },
      domain_table: {
        domains: 'Dominios',
        email_domain: 'Email/Domain',
        comment: 'Comentario',
        actions: 'Acciones',
        add: 'Añadir',
        remove: 'Eliminar',
        confirm_removal: '¿Confirmar eliminación?',
        error: {
          empty_domain: 'Especifique un nombre de dominio.',
          invalid_domain: 'El nombre de dominio que ha introducido no es válido.',
          duplicate_value: 'Valor duplicado: {item}',
          invalid_email_domain: 'El dominio/correo electrónico que ha introducido no es válido.'
        }
      },
      change_password: {},
      quarantine_notification: {
        reset_dialog: {
          title: 'Restablecer ajustes',
          message: '¿Estás seguro de que desea restablecer la configuración?'
        },
        buttons: {
          save: SAVE,
          reset: 'Reiniciar',
          default_interval: {
            never: 'Nunca',
            scheduled: 'Programada'
          }
        },
        title: 'Notificación de Cuarentena',
        disabled_warning: 'El administrador del dominio ha desactivado los cambios en esta configuración.',
        default_interval: 'Intervalo predeterminado para las notificaciones de cuarentena de usuarios',
        schedule_notification: 'Programar intervalos de notificación',
        schedule_notification_sub_title:
          'Las notificaciones se enviarán en cualquier momento dentro del bloque de horas elegido. Haga clic y arrastre para seleccionar.  Mantenga pulsada la tecla Mayús mientras arrastra para anular la selección.',
        allow_override: 'El administrador del dominio ha desactivado los cambios en esta configuración.'
      },
      sender_policies: {
        invalid_domain: 'Introduzca un nombre de dominio válido',
        invalid_domain_title: 'Dominio no válido',
        confirm_removal: '¿Estás seguro de que desea eliminar la política?',
        confirm_removal_title: 'Confirmar eliminación',
        block: 'Bloque',
        exempt: 'Exento',
        quarantine: 'Cuarentena',
        warningHoverMessage: 'Su organización ha bloqueado este remitente',
        buttons: {
          bulk_edit: 'Edición masiva',
          add: 'Añadir',
          remove: 'Eliminar'
        },
        tableHeadings: {
          sender: 'Remitente',
          policy: 'Política',
          comment: 'Comentario',
          modified: 'Modificado',
          actions: 'Acciones'
        },
        title: 'Política de remitentes',
        sub_title:
          'Especifique si desea Bloquear o Eximir los mensajes procedentes de una dirección de correo electrónico o dominio específicos.',
        sender_policy_whitelist_notification_message:
          'Su organización ha limitado la capacidad de bloquear y permitir direcciones de correo electrónico y dominios. Las políticas de remitente con algún conflicto tendrán un símbolo de advertencia junto a ellas.'
      },
      linked_accounts: {
        title: 'Cuentas Vinculadas',
        sub_title:
          'Puede añadir direcciones de correo electrónico adicionales que tenga en el mismo dominio para reenviar el correo en cuarentena a esta cuenta.',
        verification_message:
          'Se ha enviado un correo electrónico a {email} con instrucciones sobre cómo verificar esta cuenta. Esta cuenta no se vinculará hasta que se verifique.',
        confirm_unlink: '¿Estás seguro de que quieres desvincular la cuenta {email}?',
        account: 'Cuenta (Dirección de correo electrónico)',
        add: 'Añadir',
        unlink: 'Desvincular',
        actions: 'Acciones',
        unlink_title: 'Confirmar desvinculación',
        invalid_params: 'Parámetros no válidos',
        email_sent: 'La cuenta de correo {userId} está ahora vinculada a su cuenta {email}.',
        verifying: 'Verificación de la cuenta vinculada...',
        unable_to_link: 'No se pueden vincular estas cuentas'
      }
    },
    message_log: {
      title: 'Registro de Mensajes',
      outbound_quarantine_title: 'Cuarentena saliente',
      more_filters: 'Más filtros',
      search_bar: {
        search: 'Búsqueda',
        find_hint: 'Buscar mensajes, destinatarios, remitentes y mucho más...'
      },
      columns: {
        status: 'ESTATUS',
        from: 'DE',
        to: 'A',
        subject: 'ASUNTO',
        date: 'FECHA',
        size: 'TAMAÑO',
        reason: 'RAZÓN',
        score: 'PUNTUACIÓN',
        details: 'DETALLES'
      },
      toolbar: {
        refresh: 'Refrescar',
        deliver: 'Entregar',
        export: 'Exportar la tabla a CSV',
        categorize: 'Clasifique',
        delete: 'Borrar Mensaje(s)',
        reject: 'Rechazar mensaje(s)',
        corp_email: 'Correo electrónico corporativo',
        trans_email: 'Correo electrónico transaccional',
        market_materials: 'Materiales de marketing y boletines',
        mailing_lists: 'Listas de Correo',
        social_media: 'Redes Sociales',
        other: 'Otros',
        message_queue: 'Cola de Mensajes',
        deliver_dialog: {
          title: 'Entregar Mensaje',
          text: 'Este mensaje se entregará al destinatario. El remitente o dominio puede añadirse a su lista de permitidos para entregar futuros mensajes. Opcionalmente, informe del correo electrónico para ayudar al equipo de Barracuda a identificar mejor las amenazas. ',
          learn_more_link: 'Más información',
          deliver_only: 'Entregar sólo el mensaje(s)',
          allow_list_sender: 'Entrega y añade el remitente(s) a tu lista de permitidos',
          allow_list_domain: 'Entregue y añada el/los dominio(s) a su lista de permitidos',
          deliver_report: 'Entregar e informar',
          deliver: 'Entregar'
        },
        categorize_other_box_title: 'Especifique la categoría',
        limit_special_chars: 'Los caracteres permitidos son Aa-Zz, 0-9, -_ y espacios.',
        character_limit: 'Sólo se admiten 30 caracteres',
        new_email: 'Nuevo mensaje'
      },
      from_column_menu: {
        show_name: 'Mostrar nombre',
        show_email: 'Mostrar dirección de correo electrónico',
        show_both: 'Mostrar ambos'
      },
      message_detail_toolbar: {
        downloading: 'Descargar',
        incorrectly_allowed: 'Informar como Entregado Incorrectamente',
        incorrectly_blocked: 'Denunciar como Bloqueado Incorrectamente',
        menu: {
          download: 'Descargar Mensaje',
          allow_deliver: 'Permitir Remitente y Entregar Mensaje',
          add_email: 'Añadir Correo Electrónico del Remitente a la Lista de Bloqueados',
          add_domain: 'Añadir Dominio del Remitente a la Lista de Bloqueados',
          delete: 'Borrar',
          delete_message: 'Borrar Mensaje',
          reject_message: 'Rechazar mensaje',
          show_history: 'Historia del Espectáculo',
          hide_history: 'Ocultar Historia',
          reply: 'Respuesta',
          reply_all: 'Responder a Todos',
          forward: 'Adelante',
          actions: 'Más Acciones',
          view_atp_reports: 'Ver los informes ATP'
        }
      },
      header_detail: {
        long_reason: 'El mensaje era %s debido a %s',
        short_reason: 'El mensaje era %s',
        remediated_by_sentinel: 'Permitido: Remediado por Impersonation Protection en %s',
        remediated_by_forensics: 'Permitido: Remediado por Respuesta a Incidentes en %s',
        from: 'De:',
        to: 'A:',
        subject: 'Asunto:',
        date: 'Fecha:',
        attachments: 'Adjuntos:',
        mid: 'Mensaje ID:',
        ip: 'IP:',
        env_from: 'Sobre De:',
        recipients: 'Destinatarios:'
      },
      recipients_info: {
        recipients: 'Destinatarios',
        action: 'Acción',
        reason: 'Razón',
        delivery_status: 'Estado de Entrega'
      },
      message_preview: {
        message_too_large: 'Mensaje demasiado grande. Por favor, descargue este mensaje para ver la fuente.',
        message_has_virus: 'Virus detectado. Este mensaje no puede mostrarse.',
        show_images_banner: 'Las imágenes de este mensaje no se muestran automáticamente.',
        show_images: 'Mostrar Imágenes',
        cant_view_blocked: 'Su administrador ha desactivado la visualización y entrega de mensajes bloqueados.',
        cant_view_quarantined: 'Su administrador ha desactivado la visualización y entrega de mensajes en cuarentena.',
        no_content: 'Este mensaje no tiene contenido, o el contenido aún no está disponible.',
        atd_detected: 'Este mensaje tiene uno o más archivos adjuntos con amenazas avanzadas detectadas.',
        admin_disabled:
          'Esta cuenta tiene desactivada la vista de mensajes. Ponte en contacto con el administrador de la cuenta para activarla.',
        encrypted: 'El contenido del mensaje está encriptado.'
      },
      redelivery_queue: {
        redelivering: 'Reenvío...',
        buttons: {
          retry: 'Reintentar',
          close: CLOSE
        },
        title: 'Cola de Mensajes',
        sub_title:
          'La cola de mensajes muestra los mensajes designados para su entrega de el registro de mensajes. Los mensajes se eliminan automáticamente de la cola en 12 horas.'
      },
      email_composer: {
        reply: 'Re',
        forward: 'Fw',
        from: 'De',
        to: 'Para',
        cc: 'Cc',
        attachments: 'Adjuntos',
        new_email_title: 'Nuevo Mensaje',
        subject: 'Asunto',
        buttons: {
          cancel: CANCEL,
          send: 'Enviar'
        }
      },
      reporting_action_modal: {
        report_message: 'Mensaje de Información',
        incorrectly_delivered: 'Informar de Entrega Incorrecta',
        incorrectly_blocked: 'Informar de Bloqueo Incorrecto',
        incorrectly_delivered_content:
          'Este correo electrónico ha sido reportado para su revisión por el equipo de Barracuda. Proporcione detalles adicionales para asegurar que mensajes como este sean bloqueados en el futuro.',
        incorrectly_blocked_content:
          'Este correo electrónico ha sido reportado para su revisión por el equipo de Barracuda. Proporcione detalles adicionales para asegurar que mensajes como este sean entregados en el futuro.',
        buttons: {
          close: CLOSE,
          why_should_be_blocked: 'DÍGANOS POR QUÉ DEBE BLOQUEARSE',
          why_should_be_allowed: 'DÍGANOS POR QUÉ DEBERÍA PERMITIRSE'
        }
      },
      preset_menu: {
        label: 'Fecha',
        one_day: 'Último 1 día',
        two_days: 'Últimos 2 días',
        one_week: 'Última 1 semana',
        two_weeks: 'Últimas 2 semanas',
        one_month: 'Último 1 mes',
        custom: 'A medida'
      },
      domain_menu: {
        label: DOMAINS
      },
      action: {
        label: 'Medidas adoptadas',
        any: 'Cualquier',
        allowed: 'Permitido',
        blocked: 'Bloqueado',
        quarantined: 'Cuarentena',
        encrypted: 'Encriptada',
        deferred: 'Aplazado',
        remediated: 'Remediado',
        email_continuity: 'Continuidad del correo electrónico'
      },
      action_for_some: {
        allowed: 'Permitido para algunos',
        blocked: 'Bloqueado para algunos',
        quarantined: 'En cuarentena para algunos',
        encrypted: 'Encriptado para algunos',
        deferred: 'Aplazado para algunos'
      },
      status: {
        label: 'Estado de la entrega',
        any: 'Cualquier',
        delivered: 'Entregado',
        delivered_partial: 'Entregado para algunos',
        spooled: 'En cola',
        not_delivered: 'No entregado',
        deferred: 'Aplazado',
        rejected: 'Rechazado'
      },
      encryption: {
        inbound_outbound_encrypted: 'Cifrado de Entrada + Cifrado de Salida',
        inbound_encrypted: 'Sólo Cifrado Entrante',
        outbound_encrypted: 'Sólo Cifrado Saliente',
        encrypted_for_some: 'Cifrado de Entrada + Cifrado de Salida Para Algunos Destinatarios'
      },
      reason: {
        label: 'Razón',
        any: 'Cualquier',
        spf: 'SPF',
        bafi: 'Antifraude',
        barracuda_email_blacklist: 'Dirección de Correo Electrónico del Remitente',
        av: 'Antivirus',
        bbl: 'Barracuda Reputación',
        brts: 'Barracuda Sistema en Tiempo Real',
        content_url: 'Contenido URL',
        content_protected: 'Contenido Protegido',
        intent: 'Análisis de Intenciones',
        image_analysis: 'Análisis de Imágenes',
        rbl: 'Lista de Bloqueos en Tiempo Real',
        content_subject: 'Contenido del Asunto',
        content_header: 'Contenido de la Cabecera',
        content_body: 'Contenido del Cuerpo',
        predefined_filter_exemption: 'Exenciones de Filtro Predefinidas',
        predefined_content_sender: 'Dirección del Remitente Predefinida',
        content_attachment: 'Adjunto - Contenido del adjunto',
        content_sender: 'Dirección en Campo De',
        content_recipient: 'Dirección Para/CC',
        invalid_recipient: 'Destinatario no Válido',
        dest_invalid_recipient: 'Destinatario no Válido',
        dkim: 'DKIM',
        dmarc: 'DMARC',
        encryption_exemption: 'Excepción de Cifrado',
        mark_spam: 'Enviado a Categorización de Spam',
        no_prt: 'Sin Registro PTR',
        no_spf: 'Sin Registro SPF',
        password_protected_pdf: 'Filtrado por PDF Protegido con Contraseña',
        patd_scan_pending: 'Escaneo Pendiente',
        phishline: 'Formación y Concienciación en Ciberseguridad',
        predefined_content_subject: 'Contenido de Asunto Predefinido',
        predefined_content_header: 'Contenido de cabecera predefinido',
        predefined_content_body: 'Contenido predefinido del cuerpo',
        predefined_content_attachment: 'Contenido Predefinido de Adjunto',
        predefined_content_recipient: 'Dirección To/CC predefinida',
        rate_control: 'Control de Límite de Cantidad de Correos',
        sender: 'Políticas del remitente',
        ip: 'Políticas de direcciones IP',
        tls_required: 'TLS Obligatorio',
        inbound_tls_required: 'TLS entrante Obligatorio',
        attachment_filter: 'Adjunto - Filtro de adjunto',
        message_loop: 'Posible Bucle de Correo',
        message_size: 'Mensaje demasiado grande',
        bulk_email: 'Correo masivo',
        office_macros: 'Macros de oficina',
        predefined_filter_exception: 'Excepciones de filtro predefinidas',
        emailcat: 'Categorización del correo electrónico',
        system_sender: 'Políticas del Sistema del Remitente',
        recipient: 'Destinatario',
        recipient_limit: 'Lista de destinatarios',
        remediated_by_sentinel: 'Remediado por Impersonation Protection',
        remediated_by_forensics: 'Remediado por Incident Response',
        atd_exemption: 'ATP Exento',
        atd_full: 'ATP - Protección avanzada contra amenazas',
        atd: 'ATP - Protección avanzada contra amenazas',
        atd_subscribed: 'Detección avanzada de amenazas',
        atd_quarantine: 'ATP en cuarentena',
        batd_scan_pending: 'Escaneo Pendiente',
        service_error_batd: 'ATP - Escaneo no concluyente',
        ui_delivered: 'Entregado por UI',
        sender_disconnect: 'Entrega de mensaje interrumpida',
        sender_quit: 'Mensaje incompleto',
        sender_rset: 'Mensaje incompleto',
        service_error_av: 'AV - Servicio no disponible',
        service_error_cs: 'Servicio Cloudscan no disponible',
        sender_spoof: 'Protección contra la suplantación de identidad del remitente',
        score: 'Puntuación',
        suspicious: 'Sospechoso',
        suspended: 'Cuenta suspendida',
        content_protected_msdoc: 'Filtrado por documento Office Protegido con Contraseña',
        taxonomy_blackmail: 'Extorsión',
        taxonomy_spam: 'Spam',
        taxonomy_becspoofing: 'Suplantación',
        taxonomy_conversationhijacking: 'Secuestro de la conversación',
        taxonomy_phishing: 'Phishing',
        malformed: 'Malformado',
        language: 'Políticas de Idiomas',
        geoip: 'Políticas de GeoIP',
        'system-sender': 'Políticas del Sistema del Remitente',
        no_ptr: 'No hay registro PTR',
        ndr: 'Política de NDR saliente'
      },
      export: {
        message_id: 'Mensaje ID',
        from: 'De',
        to: 'Para',
        subject: 'Asunto',
        date: 'Fecha',
        size: 'Tamaño',
        action: 'Acción',
        reason: 'Razón',
        score: 'Puntuación'
      },
      error: {
        no_email_domain: 'El registro de mensajes no está disponible porque no tiene dominios de correo electrónico'
      },
      no_records_available: 'No hay registros disponibles',
      message_direction_menu: {
        inbound: 'Entrada',
        outbound: 'Salida',
        label: 'Dirección'
      },
      find_similar_messages: 'Buscar mensajes similares',
      pager_count: {
        items: 'elementos',
        of: 'de'
      }
    },
    message_detail: {
      title: 'Detalle de Mensaje',
      preview: 'Vista Previa',
      source: 'Fuente'
    },
    splitter_orientation: {
      none: 'Sin División',
      horizontal: 'División Horizontal',
      vertical: 'División Vertical'
    },
    navbar: {
      add: 'Añadir',
      changes_saved: 'Cambios guardados',
      legacy_ui: 'Clásico UI'
    },
    logoutmenu: {
      serial_number: 'Número de Serie',
      expiration: 'Caducidad',
      sign_out: 'Cerrar sesión',
      language_selector: 'Idioma: ',
      language_title: 'Seleccione el idioma preferido',
      review_license: 'Licencias de revisión'
    },
    email_details: {
      email_from: 'Correo electrónico de {remitente} el {fecha}',
      recipients: 'Destinatarios',
      sender: SENDER,
      date: 'Fecha',
      subject: SUBJECT,
      headers: HEADERS,
      value: 'Valor',
      type: 'Tipo',
      copied: 'Copiado',
      copy_to_clipboard: 'Copiar al portapapeles',
      tabs: {
        email: EMAIL,
        headers: HEADERS,
        threat_details: 'Detalles de la amenaza'
      },
      threats: {
        dmarc: 'Cumplimiento de DMARC',
        spf: 'SPF Comprobación IP',
        dkim: 'Alineación DKIM'
      }
    },
    sidemenu: {
      logout: 'Cerrar sesión',
      profile: 'Perfil',
      language_selector: 'Idioma: {language}',
      manage_profile: 'Gestionar perfil',
      review_licenses: 'Revisar licencias',
      message_log: MESSAGE_LOG,
      settings: SETTINGS,
      support: SUPPORT,
      domains: DOMAINS,
      overview_dashboard: OVERVIEW,
      inbound_settings: INBOUND_SETTINGS,
      outbound_settings: OUTBOUND_SETTINGS,
      atp_settings: ATP_SETTINGS,
      users: USERS,
      report_list: REPORTS,
      account_management: ACCOUNT_MANAGEMENT,
      settings_sender_policy: SETTINGS,
      bcc_admin: 'Barracuda Cloud Control',
      switch_to_old_ui: 'Cambiar a la antigua interfaz de usuario',
      new_interface: 'Nueva interfaz de producto',
      your_apps: 'Tus aplicaciones',
      email_log: 'Registro de correo electrónico',
      cookie_preferences: 'Preferencias de cookies'
    },
    change_password_page: {
      change_password: 'Cambiar contraseña',
      old_password: 'Contraseña actual',
      new_password: 'Nueva contraseña',
      confirm_new_password: 'Confirmar nueva contraseña',
      change_password_title: 'Confirmar cambio de contraseña',
      change_password_confirmation: '¿Estás seguro de que quieres cambiar la contraseña?'
    },
    scheduler: {
      mon: 'Lunes',
      tue: 'Martes',
      wed: 'Miércoles',
      thu: 'Jueves',
      fri: 'Viernes',
      sat: 'Sábado',
      sun: 'Domingo',
      short_mon: 'Lun',
      short_tue: 'Mar',
      short_wed: 'Mié',
      short_thu: 'Jue',
      short_fri: 'Vie',
      short_sat: 'Sáb',
      short_sun: 'Dom'
    },
    snackbar: {
      new_report_save_success: '"%s" el informe se guarda correctamente',
      new_report_save_failed: 'Error al guardar el informe "%s"',
      load_report_failed: 'Error al cargar el informe "%s"',
      set_pinned_report_id_success: 'Informe fijado correctamente: "%s',
      set_pinned_report_id_failed: 'Fallo al anclar el informe: "%s"',
      download_attachment_failed: 'No se ha podido descargar el archivo adjunto',
      download_message_failed: 'Mensaje de error de descarga',
      bad_date_range: 'La fecha/hora de inicio debe ser anterior a la fecha/hora de finalización',
      post_policy_success: 'La política de remitente se ha añadido correctamente',
      post_policy_failure: 'Error al añadir política de remitente',
      post_policy_duplicate_failure: 'Existe una entrada duplicada',
      put_bulk_edit_success: 'La edición masiva se ha guardado correctamente',
      put_bulk_edit_failure: 'Error al guardar edición masiva: %s',
      put_quarantine_notification_success:
        'La configuración de la notificación de cuarentena se ha guardado correctamente',
      put_quarantine_notification_failure: 'No se ha podido guardar la configuración de notificación de cuarentena',
      delete_policy_success: 'La política de remitente se ha eliminado correctamente',
      delete_policy_failure: 'Error al eliminar la política de remitentes',
      password_success: 'La contraseña se ha establecido correctamente',
      password_failure: 'Error al establecer la contraseña',
      reset_password_success: 'La contraseña se ha restablecido correctamente',
      reset_password_failure: 'No se ha podido restablecer la contraseña',
      get_message_source_failed: 'Error al obtener el origen del mensaje',
      local_storage_quota_exceeded: 'El almacenamiento local del navegador se ha quedado sin espacio',
      get_redeliver_message_failure: 'No se pudo recibir el mensaje de la cola de entrega',
      post_block_sender_success: 'Añadido "%s" a la lista de bloqueos',
      post_block_sender_failed: 'Error al añadir remitente o dominio a la lista de bloqueados',
      post_allow_sender_success: '%d mensaje(s) permitido(s) listado(s) y enviado(s) a la cola de entrega',
      post_allow_sender_failed: 'Error al añadir remitente o dominio a la lista de permitidos',
      post_allow_sender_admin_forbidden:
        'Acción prohibida por la política de administración, póngase en contacto con su administrador',
      post_redeliver_message_success: '%d mensaje(s) enviado(s) a la cola de entrega',
      post_redeliver_message_failure: 'Se ha producido un error. 0 mensajes enviados a la cola de entrega',
      post_redeliver_message_admin_forbidden:
        'Acción prohibida por la política de administración, póngase en contacto con su administrador',
      post_new_email_success: 'El correo electrónico se ha enviado correctamente',
      post_new_email_failure: 'No se ha podido enviar el correo electrónico',
      post_recategorize_success: '%d mensaje(s) recategorizado(s)',
      post_recategorize_failure: 'Fallo al recategorizar mensaje(s)',
      post_add_linked_account_success: 'La cuenta vinculada se ha añadido correctamente',
      post_add_linked_account_failure: 'No se ha podido añadir la cuenta vinculada',
      post_add_linked_account_not_same_domain_failure:
        'La cuenta vinculada debe estar en el mismo dominio que %s, etc.',
      post_add_linked_account_existed_failure:
        'Este usuario ya existe. Elija otra dirección de correo electrónico para añadirla como alias.',
      post_add_linked_account_already_linked_failure: 'La dirección de correo electrónico %s ya está vinculada a %s',
      post_delete_linked_account_success: 'La dirección de correo electrónico se ha desvinculado correctamente',
      post_delete_linked_account_failure: 'Error al desvincular la dirección de correo electrónico',
      error_password_char_limit: 'La contraseña debe tener menos de 100 caracteres',
      error_new_password_not_same: 'La nueva contraseña no puede ser la misma que la antigua',
      error_confirm_password_is_same: 'Nueva contraseña y Confirmar nueva contraseña deben ser iguales',
      post_fp_report_success:
        'Mensaje marcado como no spam. Desactiva los bloqueadores de anuncios para rellenar nuestro formulario de comentarios sobre la eficacia del correo electrónico cuando notifiques un mensaje.',
      post_fp_report_failure: 'Error al marcar el mensaje como no spam',
      post_fn_report_success:
        'Mensaje marcado como spam. Por favor, desactive los bloqueadores de anuncios para completar nuestro formulario de comentarios de eficacia de correo electrónico cuando informe de un mensaje.',
      post_fn_report_failure: 'Error al marcar el mensaje como spam',
      delete_message_success: '%d mensaje(s) borrado(s) con éxito',
      delete_message_failure: 'No se han podido borrar los mensajes',
      delete_message_partial_success:
        '%d mensaje(s) borrado(s) con éxito y %d mensaje(s) no ha(n) podido ser borrado(s)',
      stop_delivery_reasons: '0 Mensajes entregados. No se puede entregar debido a "%s"',
      stop_delivery_admin: 'La entrega de %s mensajes ha sido desactivada por su administrador',
      message_detail_missing: 'Falta el detalle del mensaje. 0 mensajes entregados',
      detect_email_server_failure: 'No se ha detectado el servidor de correo electrónico',
      verify_email_server_failure: 'No se ha podido verificar el servidor de correo electrónico',
      wizard_save_error: 'Se ha producido un error al guardar, inténtalo de nuevo',
      download_message_success: 'Mensaje descargado correctamente',
      download_message_failure: 'No se ha podido descargar el mensaje',
      download_attachment_success: 'Archivo adjunto descargado correctamente',
      download_attachment_failure: 'No se ha podido descargar el archivo adjunto',
      post_reject_messages_success: '%d mensaje(s) rechazado(s)',
      post_reject_messages_partial_success: 'Rechazados %d mensajes; Error al rechazar %d mensaje(s)',
      post_reject_messages_failed: 'Error al rechazar mensaje(s)',
      success_changing_settings: 'Se han guardado los cambios de configuración.',
      error_changing_settings: 'Error al guardar los cambios.',
      add_domain_success: 'El dominio se agregó con éxito',
      add_domain_transfer_required: 'El dominio existe en otra cuenta, se requiere transferencia de dominio',
      add_domain_failure: 'Error al agregar el dominio',
      delete_domain_success: 'El dominio fue eliminado con éxito',
      delete_domain_failure: 'Error al eliminar el dominio',
      delete_domain_failure_not_found: 'No se ha encontrado el dominio que se desea eliminar',
      post_syslog_success: 'Syslog se ha añadido correctamente',
      post_syslog_failure: 'Error al añadir el syslog',
      test_syslog_success: 'Estado del servidor: Puerto accesible',
      test_syslog_failure: 'Estado del servidor: Puerto inalcanzable',
      delete_syslog_success: 'Syslog se ha eliminado correctamente',
      delete_syslog_failure: 'Error al borrar el syslog',
      update_account_user_role_success: 'Se han actualizado correctamente los roles del usuario: "%s"',
      add_update_account_users_success: 'Usuario(s) de cuenta %s actualizado(s) con éxito',
      add_update_account_users_failed: 'Error al actualizar usuario(s) de cuenta',
      get_email_continuity_settings_failed: 'Error al cargar la configuración de continuidad del correo electrónico',
      update_email_continuity_settings_success:
        'Configuración de continuidad del correo electrónico actualizada correctamente',
      update_email_continuity_settings_failed:
        'Error al actualizar la configuración de continuidad del correo electrónico',
      update_quarantine_notifications_settings_success:
        'Configuración de notificación de cuarentena actualizada correctamente',
      update_quarantine_notifications_settings_failed:
        'No se ha podido actualizar la configuración de las notificaciones de cuarentena.',
      update_policies_settings_success: 'Actualización correcta de la configuración de políticas',
      update_policies_settings_failed: 'Error al actualizar la configuración de políticas',
      delete_account_user_success: 'Usuario: "%s" se ha eliminado correctamente',
      delete_account_users_success: 'Usuarios de cuenta %s eliminados con éxito',
      login_as_user_failed: 'No se puede iniciar sesión como "%s"',
      account_switch_failed: 'No se puede cambiar a la cuenta "%s"',
      add_domain_error_duplicate: 'Este nombre de dominio ya ha sido añadido.',
      add_domain_error_quarantine_notifier: 'Error al crear la configuración del Notificador de Cuarentena',
      add_domain_error_blacklisted_domain_name: 'Este nombre de dominio no se puede añadir',
      delete_domain_failure_quarantine_notifier:
        'Se ha eliminado el dominio, pero no se ha podido eliminar la configuración del notificador de cuarentena.',
      manage_domain_failure: 'Error al cambiar al dominio seleccionado',
      manage_domain_failure_forbidden: 'No tienes privilegios suficientes para gestionar el dominio seleccionado',
      edit_domain_failure_forbidden: 'No tienes privilegios suficientes para editar el dominio seleccionado',
      get_domain_failure: 'Se ha producido un error al obtener los datos del dominio',
      wildcard_email_policy:
        'Correos electrónicos malformados detectados, no se admiten comodines. Por ejemplo, utilice cliente.com en lugar de *@cliente.com.',
      verify_domain_success: {
        verified_now: 'Gracias, el dominio %s ya está verificado.',
        email_sent: 'Se ha enviado un correo electrónico con instrucciones de verificación a %s.',
        cpl_verification_started: 'La verificación de %s ha comenzado. Puede tardar unos minutos.'
      },
      verify_domain_failure: {
        mx_error: 'No se pueden verificar los registros MX del dominio',
        no_tech_email:
          'No se ha encontrado ninguna dirección de correo electrónico en la información WHOIS de este dominio. Por favor, pruebe con otro método.',
        cname_error:
          'Actualmente no se encuentra el registro CNAME correcto para su dominio. Compruebe que la entrada se ha añadido a sus registros DNS. Tenga en cuenta que cualquier cambio puede tardar hasta 24 horas en propagarse, dependiendo de la configuración de su proveedor de Internet.',
        invalid_or_expired: 'Dominio no válido o código de verificación caducado',
        verified_by_other_account:
          'Este dominio ya ha sido añadido y verificado por otra cuenta. Si este es su dominio, póngase en contacto con el Servicio de Atención al Cliente para obtener más ayuda.',
        already_verified: 'El dominio %s ya está verificado.',
        email_send_failure: 'Error al enviar mensaje de correo electrónico a %s',
        tech_email_not_found:
          'No se ha podido encontrar la dirección de correo electrónico del contacto técnico del dominio %s',
        cpl_verification_failed: 'La verificación del dominio falló, por favor llame al Soporte de Barracuda Networks.',
        default: 'No se puede verificar el dominio'
      },
      add_mail_server_success: 'Se ha guardado el servidor de correo',
      add_mail_server_failure: {
        default: 'Error al guardar el servidor de correo',
        duplicate: 'Existe un servidor de correo duplicado.'
      },
      remove_mail_server_success: 'Se ha eliminado el servidor de correo',
      remove_mail_server_failure: {
        default: 'Error al eliminar el servidor de correo',
        not_found: 'El servidor de correo no existe.'
      },
      update_mail_server_success: 'Se ha actualizado el servidor de correo',
      update_mail_server_failure: {
        default: 'Error al actualizar el servidor de correo',
        not_found: 'El servidor de correo no existe.',
        duplicate: 'Existe un servidor de correo duplicado.'
      },
      test_mail_server_success: 'Mensaje entregado con éxito a %s@%s',
      test_mail_server_failure: {
        default: 'No se ha podido entregar el mensaje.',
        not_found: 'El servidor de correo no existe.'
      },
      save_domain_settings_success: 'La configuración del dominio se ha guardado correctamente',
      save_domain_settings_failure: 'No se ha podido guardar la configuración del dominio',
      error_subnet_too_large: 'El rango de subred especificado es demasiado grande. Divídalo en varias subredes /24.',
      error_new_entry_single_ip: 'Las nuevas entradas sólo pueden ser números IP únicos',
      error_is_not_verified_domain: 'El nombre de dominio debe ser de un dominio verificado.',
      error_already_specified: 'La subred especificada entra en conflicto con una subred de otra cuenta',
      error_potentially_shared_infrastructure:
        'La dirección/rango IP que ha seleccionado forma parte de un rango que ha sido marcado como infraestructura potencialmente compartida. No se puede asignar a ningún dominio específico.',
      error_no_reverse_dns: 'La dirección IP que proporcionó carece de DNS inverso',
      error_conflict_subnet: 'La subred especificada entra en conflicto con la subred: %s / %s',
      row_error_subnet_too_large:
        'Error en la fila %s (El rango de subred especificado es demasiado grande. Por favor, divídalo en múltiples subredes /24).',
      row_error_new_entry_single_ip: 'Error en la fila %s (Las nuevas entradas sólo pueden ser números IP únicos.)',
      row_error_is_not_verified_domain: 'Error en la fila %s (El nombre de dominio debe ser de un dominio verificado.)',
      row_error_already_specified:
        'Error en la fila %s (La subred especificada entra en conflicto con una subred de otra cuenta).',
      row_error_potentially_shared_infrastructure:
        'Error en la fila %s (La dirección IP/rango que ha seleccionado forma parte de un rango que ha sido marcado como infraestructura potencialmente compartida. No puede asignarlo a ningún dominio específico).',
      row_error_no_reverse_dns: 'Error en la fila %s (La dirección IP proporcionada carece de DNS inverso)',
      row_error_conflict_subnet:
        'Error en la fila %s (La subred especificada entra en conflicto con la subred: %s / %s)',
      save_domain_settings_unconfirmed_warning: 'Actualización rechazada debido a advertencias no confirmadas',
      lookup_azure_ad_user: {
        success: 'Dirección de correo electrónico presente en la cuenta Azure AD',
        failure: 'No se ha podido encontrar la dirección de correo electrónico en la cuenta Azure AD',
        signed_url_error: 'Error al consultar la cuenta Azure AD'
      },
      encryption_confirm_validation_success: 'El dominio se ha validado correctamente.',
      encryption_confirm_validation_failure: 'No se ha podido validar el dominio, inténtelo de nuevo.',
      encryption_logo_remove_success: 'El logotipo se ha eliminado correctamente.',
      encryption_validation_remove_success: 'Se han eliminado los ajustes de validación.',
      encryption_cname_validation_success:
        'Por favor, cree el subdominio: %s y apunte el registro a "encrypt.barracudanetworks.com". Después de crear este registro, haga clic en Confirmar validación.',
      encryption_post_validation_success:
        'Se ha enviado un correo electrónico a %s. Después de seguir el enlace de este correo electrónico, haga clic en Confirmar validación para completar el proceso de verificación del dominio con Barracuda Email Encryption Service.',
      encryption_validation_invalid: 'Por favor, empiece validando con CNAME o Postmaster.',
      encryption_failure: 'La solicitud no pudo realizarse en este momento.',
      ldap_get_sync_stats_failure: 'Error al obtener estadísticas de sincronización LDAP',
      ldap_test_success: 'Conectado con éxito al servidor LDAP',
      ldap_test_failure: 'Error al conectar con el servidor LDAP',
      ldap_serial_reset_success: 'La serie más alta vista se ha reiniciado a 0',
      ldap_serial_reset_failure: 'Error al restablecer LDAP más alto visto serie',
      ldap_sync_success:
        'Usuarios LDAP sincronizados. %s usuario(s) actualizado(s). %s usuario(s) añadido(s). %s usuario(s) eliminado(s).',
      ldap_sync_failure: 'Error de sincronización LDAP',
      edit_domain_form_has_errors: 'Hay errores en el formulario. Por favor, corríjalos y vuelva a guardar.',
      reset_domain_settings_success: 'Restablecida con éxito la configuración del dominio a las políticas globales',
      reset_domain_settings_failure: 'No se puede cambiar la configuración',
      save_and_apply_config: {
        backup_failure: 'Operación fallida. Vuelva a intentarlo más tarde.',
        restore_success: 'Los ajustes de configuración se han aplicado correctamente.',
        restore_failure: 'Token inválido o caducado. No se ha podido restaurar.'
      },
      verify_domain_transfer_completed: 'El dominio %s ha sido transferido correctamente.',
      verify_domain_transfer_success: 'Se ha iniciado la transferencia del dominio',
      verify_domain_transfer_failure: 'Failed to verify the domain',
      delete_domain_move_success: 'No se ha podido verificar el dominio',
      delete_domain_move_failure: 'Error al eliminar el dominio',
      delete_domain_move_failure_not_found: 'No se ha encontrado el dominio.',
      delete_domain_move_failure_unauthorized: 'No tiene privilegios suficientes para eliminar el dominio seleccionado',
      schedule_report_success: 'Programa creado',
      schedule_report_updated_success: 'Programa actualizado sus cambios se han guardado correctamente.',
      schedule_report_deleted_success: 'Programa suprimido',
      error_parsing_settings: 'Ajustes con formato JSON no válidos',
      error_download_report: 'No se ha podido descargar el informe'
    },
    data_tables: {
      message_log: {
        select: 'Seleccione',
        action: 'Acción',
        delivery: 'Entrega',
        from: 'De',
        to: 'Para',
        subject: 'Asunto',
        date: 'Fecha',
        size: 'Tamaño',
        encryption: 'Encriptación',
        attachment: 'Adjunto',
        reason: 'Razón',
        score: 'Puntuación'
      },
      audit_log: {
        timestamp: 'Fecha',
        action: 'Tipo de evento',
        description: 'Descripción',
        actor: 'Usuario',
        ip: 'Dirección IP'
      },
      domains: {
        domain_name: 'Nombre de dominio',
        status: 'Estado',
        aliases: 'Alias',
        recipients: 'Destinatarios (últimos 30 días)',
        mail_servers: 'Servidores de correo',
        settings: 'Ajustes',
        domain_options: 'Opciones de dominio'
      },
      atp_log: {
        status: 'Estado',
        time: 'Tiempo',
        from: 'De',
        to: 'Para',
        subject: 'Asunto',
        file_name: 'Nombre del archivo',
        file_type: 'Tipo de archivo',
        report: 'Informe',
        view_report: 'Ver informe',
        no_records_available: 'No hay registros disponibles'
      }
    },
    atp_log: {
      title: 'Registro ATP',
      domain: 'Dominio',
      infected_attachments: 'Archivos adjuntos infectados',
      suspicious_attachments: 'Archivos adjuntos sospechosos',
      last_30_days: 'En los últimos 30 días',
      status: 'Estado',
      time: 'Tiempo',
      file_type: 'Tipo de archivo',
      export_button: 'Descargar CSV',
      search_placeholder: 'Buscar remitente, destinatario, asunto o nombre de archivo',
      all_domains: 'Todos los dominios',
      all: 'Todos los tipos de archivos',
      filters: {
        all: 'Todos',
        clean: 'Limpiar',
        suspicious: 'Sospechoso',
        virus: 'Infectado',
        30: 'Últimos 30 días',
        7: 'Últimos 7 días',
        1: 'Últimas 24 horas'
      },
      logStatuses: {
        all: 'Todos',
        infected: 'Infectado',
        clean: 'Limpiar',
        error: 'Error',
        suspicious: 'Sospechoso',
        future: 'Escaneado...'
      },
      export: {
        status: 'Estado',
        origin: 'Origen',
        fileName: 'Nombre del archivo',
        fileType: 'Tipo de archivo',
        messageId: 'Mensaje ID',
        from: 'De',
        to: 'Para',
        time: 'Tiempo'
      },
      view_reports_modal: {
        title: 'Aviso de entrega de correo electrónico',
        content:
          'Este mensaje contiene los siguientes archivos adjuntos, en uno o más de los cuales se han detectado amenazas avanzadas. Revise el informe de cada adjunto infectado. Si decide entregar el mensaje, revise y acepte el descargo de responsabilidad y haga clic en Entregar mensaje.',
        cancel: CANCEL,
        deliver: 'Entregar',
        checkbox_label:
          'Acepto que Barracuda Networks no asume ningún riesgo asociado con las amenazas que mi organización pueda sufrir durante o en relación con la entrega de este mensaje y sus archivos adjuntos asociados.',
        ui_loading: 'Cargando datos adjuntos...',
        no_atp_attachment: 'Sin fijación ATP'
      }
    },
    audit_log: {
      title: 'Registro de auditoría',
      status: 'Estado',
      time: 'Tiempo',
      file_type: 'Tipo de archivo',
      exportButton: 'Exportar CSV',
      search_placeholder: SEARCH,
      start_date: 'Fecha Inicio',
      end_date: 'Fecha Final',
      filter: {
        title: 'Filtro',
        filter: 'Filtro',
        event_type: 'Tipo de acontecimiento',
        apply: 'Solicitar',
        clear: 'Claro'
      },
      export: {
        to: 'Para',
        time: 'TIEMPO',
        ipAddress: 'Dirección IP',
        userName: 'Usuario',
        description: 'Descripción',
        eventType: 'Tipo de acontecimiento',
        formattedDate: 'Fecha'
      },
      action: {
        select_all: 'Seleccionar todo',
        move: 'Mover',
        delete: 'Borrar',
        change: 'Clasificado',
        login: 'Iniciar sesión',
        logout: 'Cerrar sesión',
        log_action: 'Acción de registro',
        create: 'Cree',
        delete_all: 'Borrar todo',
        reset: 'Restablecer',
        enable: 'Activar',
        disable: 'Desactivar',
        suspend: 'Suspender',
        notify: 'Notificar a',
        migrate_cpl: 'Migrar CPL',
        restore: 'Restaurar',
        login_failure: 'Firmar en caso de fallo'
      },
      affected: {
        unavailable: 'No disponible',
        details_unavailable: 'Detalles no disponibles',
        require_password_for_quarantine_links: 'Contraseña necesaria para los enlaces de cuarentena',
        allow_quarantine_notification_interval_change: 'Permitir a los usuarios especificar el intervalo',
        enable_quarantine: 'Mensajes de cuarentena',
        sender_policies: 'Políticas del remitente',
        quarantine_notification_interval: 'Intervalo de notificación de cuarentena',
        created: 'Hora de creación del usuario',
        sub_expires: 'Expiración de la suscripción',
        sub_start_date: 'Fecha de inicio de la suscripción',
        visited: 'Visitado',
        password: 'Contraseña',
        smtp_servers: 'Servidores de correo',
        message_content_filters: 'Filtro de contenido de mensajes',
        ip_policies: 'Políticas de direcciones IP',
        geoip_policies: 'Políticas de GeoIP',
        spooling: 'En cola',
        spf_hardfail: 'Fallo grave del Marco de directivas de remitente (SPF)',
        spf_softfail: 'Fallo suave del Marco de directivas de remitente (SPF)',
        cname_token: 'CNAME token',
        directory_services: 'Servicios de directorio',
        dkim: 'Acción DomainKeys Identified Mail (DKIM)',
        azure_ad: 'Azure AD',
        sender_spoof: 'Protección contra la suplantación de identidad del remitente',
        user_auto_add: 'Añadir usuarios automáticamente',
        fastspam_scores: 'Puntuación de Cloudscan',
        encryption_passtoken: 'Cifrado Validación Passtoken',
        encryption_vtype: 'Tipo de validación de cifrado',
        ldap_auth: 'Configuración de la autenticación LDAP',
        ldap_sync: 'LDAP sync config',
        force_tls: 'SMTP sobre TLS',
        srs: 'Esquema de reescritura del remitente (SRS)',
        ptr: 'Bloqueo por ausencia de registros PTR',
        bafi: 'Inteligencia antifraude',
        redirector_content_action: 'Intención de contenido',
        atd: 'ATP - Protección avanzada contra amenazas',
        intent: 'Análisis de intenciones',
        brts: 'Sistema en tiempo real Barracuda (BRTS)',
        brbl: 'Lista de bloqueos de reputación de Barracuda',
        fastspam_enabled: 'Cloudscan',
        encryption_verification: 'Verificación del cifrado',
        vscan: 'Análisis de virus',
        ext_sender_warning: 'Aviso de remitente externo',
        linkprotect: 'Protección de enlaces',
        default_user_exempt_enabled: 'Permitir a los usuarios eximir a los remitentes',
        status: 'Estado de la suscripción',
        typosquat: 'Protección contra la typosquatting',
        outbound_message_content_filters: 'Filtro de contenido de mensajes salientes',
        linked_domain_policy: 'Políticas de dominio de intención',
        dmarc: 'Autenticación de mensajes basada en dominios (DMARC)',
        dkim_exempt: 'DKIM Exento',
        atd_notify: 'ATP - Notificar a Admin',
        user_policies: 'Políticas de los beneficiarios',
        ldap: 'Ajustes de sincronización LDAP',
        encryption_confirmed: 'Validación de cifrado confirmada',
        outbound_predefined_message_content_filters: 'Filtros predefinidos de salida',
        spf_exempt: 'Exenciones del SPF por dirección IP',
        spf_domain_exempt: 'Exenciones del SPF por dominio',
        language_policies: 'Políticas de Idiomas',
        atd_exempt_email: 'ATP - Exenciones por dirección de correo electrónico / dominios',
        default_timezone: 'Zona horaria por defecto',
        bulk_email_detection: 'Detección de correo masivo',
        attachment_filter_policy: 'Adjunto - Filtro de adjunto',
        default_unmanaged_scan_policy: 'Política por defecto para usuarios no gestionados',
        emailcat_marketing: 'Material de marketing y boletines',
        default_user_deliver_enabled: 'Permitir a los usuarios finales ver y entregar mensajes bloqueados',
        default_user_quar_deliver_enabled: 'Permitir a los usuarios finales ver y entregar mensajes en cuarentena',
        emailcat_listserver: 'Categorización de las listas de correo',
        emailcat_socialmedia: 'Categorización de las redes sociales',
        emailcat_corporate: 'Categorización del correo electrónico corporativo',
        emailcat_transactional: 'Categorización del correo electrónico transaccional',
        emergency_inbox_autoenabled: 'Continuidad del correo electrónico',
        outbound_tls_domains: 'Dominios TLS salientes',
        default_managed_scan_policy: 'Política por defecto para usuarios gestionados',
        trusted_forwarders: 'Transitarios de confianza',
        managed_domains: 'Dominios gestionados por usuario',
        default_user_block_enabled: 'Permitir a los usuarios bloquear remitentes',
        deleted: 'Dominio eliminado',
        rate_control_exemptions: 'Exenciones del control de tasas',
        saved_searches: 'Búsquedas guardadas',
        rbls: 'Listas negras de reputación (RBLs)',
        atd_exempt_ip: 'ATP - Exenciones por dirección IP del remitente',
        dmarc_exempt: 'Exento de DMARC',
        ptr_exempt: 'Exenciones PTR que faltan',
        outbound_spam_notification_address: 'Dirección de notificación de spam saliente',
        reject_notification_address: 'Dirección de notificación de rechazo',
        rate_control: 'Control de Límite de Cantidad de Correos',
        alias_of: 'Alias de dominio',
        helpdesk_domains: 'Dominios del servicio de asistencia al usuario',
        outbound_tagline: 'Texto saliente Tagline/Footer',
        outbound_tagline_enabled: 'Añadir eslogan',
        outbound_attachment_filter_policy: 'Filtro de archivos adjuntos salientes',
        atd_admin_emails: 'Correo electrónico de notificación ATP',
        disable_account_rate_control: 'Control interno de tarifas',
        sender_notification_address: 'Dirección de notificación de cuarentena',
        sender_notification_enable: 'Notificación de remitente en cuarentena',
        reject_notification_subject: 'Asunto de la notificación de rechazo',
        reject_notification_template: 'Plantilla de notificación de rechazo',
        password_msoffice_filtering: 'Documentos de Microsoft Office protegidos con contraseña',
        password_archive_filtering: 'Filtrado de archivos protegido por contraseña',
        outbound_password_archive_filtering: 'Filtrado de archivos salientes protegido por contraseña',
        password_pdf_filtering: 'PDF protegido con contraseña',
        outbound_password_pdf_filtering: 'PDF saliente protegido con contraseña',
        outbound_predefined_message_content_filter_exceptions: 'Excepciones del filtro predefinido de salida',
        outbound_password_msoffice_filtering: 'Documentos de Microsoft Office salientes protegidos con contraseña',
        sender_notification_subject: 'Asunto de la notificación de cuarentena',
        sender_notification_template: 'Plantilla de notificación de cuarentena',
        support_access_enabled: 'Apoyo al acceso',
        default_scan_policy: 'Política de destinatarios por defecto',
        message_body_access_enabled: 'Acceso de los socios a los mensajes',
        dismissed_notifications: 'Notificación Desestimada',
        sub_override: 'Suscripción anulada por Barracuda Networks',
        brts_send_evidence: 'Enviar contenido de mensajes sospechosos a Barracuda Central para su análisis',
        outbound_quarantine_start: 'Hora de inicio de la notificación',
        outbound_quarantine_email: 'Dirección de notificación',
        outbound_quarantine_interval: 'Intervalo de notificación de cuarentena saliente',
        custom_interval: 'Intervalo de notificación de cuarentena saliente',
        search_logs_all_domains:
          '(Configuración interna) Activar registros de mensajes de búsqueda en todos los dominios',
        azure_ad_ui: '(Configuración interna) Activar la configuración de Azure AD',
        domain_move: '(Configuración interna) Activar el traslado de dominio de autoservicio',
        trial_expiration_banner: '(Configuración interna) Activar banner de caducidad de prueba',
        greylist_exempt: '(Configuración interna) Desactivar los aplazamientos greylist (sospechosos) para la cuenta',
        use_primary_smarthost: '(Configuración interna) Utilizar Smarthost primario',
        auditlog: '(Configuración interna) Activar registro de auditoría',
        click_protection: '(Configuración interna) Activar la configuración de protección contra clics',
        config_copy: '(Configuración interna) Activar copia de seguridad de la configuración de la cuenta',
        dmarc_ui: '(Configuración interna) Activar la configuración DMARC',
        inbound_tagline: '(Configuración interna) Eslogan del correo electrónico entrante',
        noblock_sender_response: '(Configuración interna) Activar bloqueo silencioso de mensajes de correo electrónico',
        retention: '(Configuración interna) Periodo de conservación del correo electrónico',
        outbound_use_email_domain:
          '(Configuración interna) Utilizar el dominio de correo electrónico para los mensajes salientes',
        outbound_ndr_policy: '(Configuración interna) Política NDR de salida',
        spoofing_enabled: '(Configuración interna) Spoofing activado',
        max_message_size: '(Configuración interna) Tamaño máximo de mensaje',
        athena_stats: '(Configuración interna) Activar recuento de destinatarios de athena',
        disable_shared_ip_ranges: '(Configuración interna) Retransmisión saliente alojada - EG. O365, Google Apps,...',
        outbound_ip_partition_id: '(Configuración interna) Tipo de partición de salida',
        skip_subdomains: '(Configuración interna) Rechazar correo a dominios secundarios',
        outbound_rate_control_block: '(Configuración interna) Bloqueo de control de tarifa de usuario de salida',
        outbound_rate_control: '(Configuración interna) Control de velocidad de usuario no gestionado por dominio',
        outbound_per_domain_rate_limit: '(Configuración interna) Control de velocidad por usuario gestionado',
        admins_msg_body_access:
          '(Configuración interna) Permitir a los administradores ver el contenido del correo electrónico y los archivos adjuntos',
        disable_outbound_multilevel_intent_content_check:
          '(Configuración interna) Permitir a los administradores desactivar la comprobación saliente de intención múltiple del contenido de las páginas web.',
        outbound_per_user_rate_limit: '(Configuración interna) Exenciones de control de tarifas de usuarios salientes',
        type: '(Configuración interna) Tipo de dominio (ESS/Híbrido)',
        end_user_react_ui: '(Configuración interna) Usuario final React UI',
        react_end_users: '(Configuración interna) Usuario final React UI',
        inline_deployment: 'Despliegue en línea',

        // NOTE: These Quarantine Notifier Settings need to be in camel-case
        allowOverride: 'Permitir a los usuarios especificar el intervalo',
        inboundSchedule: 'Intervalo de notificación de cuarentena',
        customInboundSchedule: 'Programar intervalos de notificación',
        customOutboundSchedule: 'Programar intervalos de notificación',
        outboundEmailIds: 'Dirección de notificación',
        outboundSchedule: 'Intervalo de notificación de cuarentena saliente',
        allowWhitelist: 'Permitir a los usuarios eximir a los remitentes',

        // Strings with params
        fmt_domain_moved: 'Dominio %s movido a %s',
        fmt_domain_from: 'Dominio %s movido a esta cuenta de %s',
        fmt_changed: '%s cambiado',
        fmt_changed_for_domain: '%s cambiado para el dominio %s',
        fmt_user_deleted: 'Usuario %s eliminado',
        fmt_outbound_ip_deleted: 'IP de salida %s eliminada',
        fmt_deleted_for_domain: '%s borrado para el dominio %s',
        fmt_domain_deleted: 'Dominio %s eliminado',
        fmt_deleted: '%s borrado',
        fmt_user_logged_in: 'Usuario conectado correctamente',
        fmt_logged_in_domain: 'El usuario se ha conectado correctamente a la gestión del dominio %s',
        fmt_support_logged_in: 'Barracuda Support ha iniciado sesión correctamente',
        fmt_user_logged_out: 'Usuario desconectado correctamente',
        fmt_user_logged_out_domain: 'El usuario se ha desconectado correctamente de la gestión del dominio %s',
        fmt_support_logged_out: 'Barracuda Support ha cerrado la sesión correctamente',
        fmt_atd_exported: 'Líneas de registro ATD exportadas',
        fmt_message_log_exported: 'Líneas del registro de mensajes exportadas',
        fmt_message_deleted: 'Mensaje %s borrado',
        fmt_message_sent_to_emailreg: 'Mensaje %s enviado a EmailReg',
        fmt_message_blocked: 'Mensaje %s bloqueado',
        fmt_message_whitelisted: 'Mensaje %s en la lista blanca',
        fmt_message_recategorized: 'Mensaje %s recategorizado',
        fmt_message_delivered: 'Mensaje %s entregado',
        fmt_message_viewed: 'Mensaje %s visto',
        fmt_message_marked_spam: 'Mensaje %s marcado como spam',
        fmt_message_marked_not_spam: 'Mensaje %s marcado como no spam',
        fmt_message_rejected: 'Mensaje %s rechazado',
        fmt_setting_created: '%s creados',
        fmt_user_created: 'Usuario %s creado',
        fmt_outbound_ip_created: 'IP de salida %s creada',
        fmt_domain_created: 'Dominio %s creado',
        fmt_user_settings_deleted: 'Todos los ajustes del usuario %s borrados',
        fmt_domain_users_deleted: 'Todos los usuarios del dominio %s eliminados',
        fmt_account_outbound_ips_deleted: 'Todas las IPs salientes para el dominio %s en la cuenta %s eliminadas',
        fmt_list_outbound_ips_deleted: 'Todas las IPs salientes de la lista [%s] eliminadas',
        fmt_domain_settings_deleted: 'Todos los ajustes del dominio %s eliminados',
        fmt_account_settings_deleted: 'Todos los ajustes de la cuenta %s eliminados',
        fmt_domain_settings_reset_account_level:
          'Ajustes de Dominio para el Dominio %s Restablecidos a Equivalentes de Nivel de Cuenta',
        fmt_login_info_sent: 'La Información de Inicio de Sesión se ha Enviado a <strong>%s</strong>.',
        fmt_account_ended: 'Cuenta Cerrada',
        fmt_account_disabled: 'Cuenta Desactivada',
        fmt_account_suspended: 'Cuenta Suspendida',
        fmt_account_migrated_to_cpl: 'Cuenta Migrada a CPL',
        fmt_user_notified_via_email: 'Usuario(s) %s ha(n) Sido Notificado(s) por Correo Electrónico',
        fmt_domain_settings_restored: 'Configuración de Dominio [%s] Restaurada para el Dominio %s',
        fmt_login_failure: 'Fallo de Inicio de Sesión'
      }
    },
    atp_settings: {
      title: 'Protección avanzada contra amenazas',
      enable_advanced_threat_protection: 'Activar protección avanzada contra amenazas',
      deliver_scan: 'Primero entregar, luego escanear',
      scan_deliver: 'Escanear primero y entregar después',
      no: 'No',
      disabling_antivirus: 'Al desactivar el antivirus se desactivará la protección avanzada contra amenazas',
      notify_admin: 'Notificar a Admin',
      yes: 'Sí',
      admin_emails: 'Correo electrónico de notificación ATP',
      atp_exemptions: 'Exenciones ATP',
      atp_exemptions_note:
        'Nota: Las entradas que aparezcan aquí no tendrán archivos adjuntos escaneados con el servicio ATP.',
      exemptions_by_email_addresses_domains: 'Exenciones por dirección de correo electrónico / dominios',
      exemptions_by_email_addresses_domains_short: 'Exenciones (Direcciones de correo electrónico / Dominio)',
      exemptions_by_ip_addresses: 'Exenciones por dirección IP del remitente',
      exemptions_by_ip_addresses_short: 'Exenciones (Dirección IP del remitente)',
      netmask: 'Máscara de red',
      anti_virus_disabled:
        'Se ha desactivado la Protección avanzada contra amenazas porque se ha desactivado el análisis en busca de virus. <br></br> Establezca la opción Buscar virus en el correo electrónico en Sí en la página Configuración de entrada -> Antispam/Antivirus.',
      error: {
        empty_email: 'Introduzca una dirección de correo electrónico válida.',
        invalid_email: 'La dirección de correo electrónico {email} no es válida.',
        empty_ip_address: 'Por favor, introduzca una dirección IP válida.',
        invalid_ip_address: 'Dirección IP no válida',
        empty_netmask: 'Introduzca una máscara de red válida.',
        invalid_netmask: 'Máscara de red no válida.'
      }
    },
    account_management: {
      title: 'Gestión de cuentas',
      hostname: 'Dirección IP / Nombre de host',
      port: 'Puerto',
      actions: 'Acciones',
      add: 'Añadir',
      test: 'Prueba',
      delete: 'Borrar',
      specify_hostname: 'Especifique un nombre de host.',
      specify_port: 'Especifique un puerto.',
      hostname_info: 'Se requiere TCP+TLS para conectarse correctamente. No se admite Non-TLS',
      error_missing_hostname: 'Error en la fila {row} (Nombre de host no válida)',
      error_invalid_hostname: 'Error en la fila {row} (Nombre de host no válida)',
      error_missing_port: 'Error en la fila {row} (Puerto no válida)',
      error_invalid_port: 'Error en la fila {row} (Puerto no válida)'
    },
    oauth2: {
      process: {
        start_failed: 'No se ha podido iniciar el proceso de autenticación'
      },
      auth_code_flow: {
        start_failed: 'Error al iniciar el flujo de código de autorización.',
        token_exchange_failed: 'Error en el intercambio del código de autorización.',
        create_session_failed: 'Autenticación fallida.'
      },
      exception: {
        OAuth2AuthorizeParameterMissing:
          'No se ha proporcionado un parámetro obligatorio para la llamada de autorización.',
        OAuth2AuthorizationCodeRequestFailed: 'Solicitud de código de autorización fallida.',
        OAuth2IdentityProviderFlowFailed: 'Se ha producido un error al ejecutar el flujo de autenticación.',
        OAuth2TokenExchangePKCEVerificationFailed: 'La verificación del código de intercambio de tokens ha fallado.',
        IdTokenGenerationFailed: 'Se ha producido un error al generar el identificador.',
        EGDIdentityNotFound: 'Lo sentimos, esa dirección de correo electrónico no se reconoce como usuario válido.',
        OAuth2AuthorizeRedirectURINotAllowed: 'El URI de redirección especificado no está permitido.',
        azure_ad_api_token_failed: 'El intercambio de fichas ha fallado.',
        azure_ad_api_env_missing: 'Se ha producido un error al intentar procesar la solicitud.',
        ms_invalid_request: 'La solicitud de autorización no es válida.',
        ms_unauthorized_client: 'La aplicación cliente no puede solicitar un código de autorización.',
        ms_access_denied: 'El propietario del recurso denegó el consentimiento.',
        ms_unsupported_response_type: 'El servidor de autorización no admite el tipo de respuesta en la solicitud.',
        ms_server_error: 'El servidor ha encontrado un error inesperado.',
        ms_temporarily_unavailable: 'El servidor está temporalmente demasiado ocupado para atender la solicitud.',
        ms_invalid_resource:
          'El recurso de destino no es válido porque no existe, Microsoft Entra ID no puede encontrarlo o no está correctamente configurado.',
        ms_login_required: 'Se han encontrado demasiados usuarios o ninguno.',
        ms_interaction_required: 'La solicitud requiere la interacción del usuario.',
        ms_invalid_grant: 'El código de autorización o el verificador de código PKCE no son válidos o han caducado.',
        ms_invalid_client: 'Ha fallado la autenticación del cliente.',
        ms_unsupported_grant_type: 'El servidor de autorización no admite el tipo de concesión de autorización.',
        ms_consent_required: 'La solicitud requiere el consentimiento del usuario.',
        ms_invalid_scope: 'El ámbito solicitado por la aplicación no es válido.',
        tdf_invalid_domain: 'Lo sentimos, ese dominio no se reconoce como válido.',
        tdf_domain_not_verified: 'Lo sentimos, ese dominio no está verificado.',
        azure_ad_api_error_token_failed: 'El intento de autenticación ha sido rechazado.',
        OAuth2ProcessNotFound: 'La solicitud de autenticación ha caducado. Intente iniciar sesión de nuevo.'
      }
    }
  }
}
/* eslint-enable quotes */
