import React, { useMemo } from 'react'
import { Grid, Typography } from '@barracuda-internal/bds-core'

import styles from './chartTotalBoxStyles'

export interface ChartTotalBoxProps {
  color: string
  count: number
  text: string
}

export function ChartTotalBox({ color, count, text }: ChartTotalBoxProps) {
  const classes = styles()

  return useMemo(
    () => (
      <Grid className={classes.wrapper} container direction="row" alignItems="center">
        <Grid item className={classes.colorBox} style={{ backgroundColor: color }} />
        <Grid item xs container direction="column">
          <Typography className={classes.count}>{count.toLocaleString()}</Typography>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
      </Grid>
    ),
    [color, count, text, classes]
  )
}
