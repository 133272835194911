export enum CategoryTypes {
  allowed = 'allowed',
  blocked = 'blocked',
  quarantined = 'quarantined',
  encrypted = 'encrypted',
  blockedAtd = 'blocked:atd',
  blockedAtdSubscribed = 'blocked:atd_subscribed',
  blockedAtdFull = 'blocked:atd_full',
  blockedBbl = 'blocked:bbl',
  blockedSpam = 'blocked:spam',
  blockedBrts = 'blocked:brts',
  blockedAv = 'blocked:av'
}
