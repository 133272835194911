import React from 'react'

import MessageLogListToolbar from 'components/pages/messageLog/messageLogList/messageLogListToolbar/MessageLogListToolbar'
import MessageLogListTable from 'components/pages/messageLog/messageLogList/messageLogListTable/MessageLogListTable'

function MessageLogList() {
  return (
    <>
      <MessageLogListToolbar />
      <MessageLogListTable />
    </>
  )
}

export default MessageLogList
