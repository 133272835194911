import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  SETTINGS: {
    path: 'settings',
    legacyPath: '/users/settings',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  },
  SETTINGS_QUARANTINE_NOTIFICATION: {
    path: 'settings/quarantine_notification',
    legacyPath: '/users/settings/quarantine_notification',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  },
  SETTINGS_SENDER_POLICY: {
    path: 'settings/sender_policy',
    legacyPath: '/users/settings/sender_policy',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  },
  SETTINGS_LINKED_ACCOUNTS: {
    path: 'settings/linked_accounts',
    legacyPath: '/users/settings/linked_accounts',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  },
  SETTINGS_CHANGE_PASSWORD: {
    path: 'settings/change_password',
    legacyPath: '/users/settings/change_password',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  },
  SENDER_POLICIES_BULK_EDIT: {
    path: 'settings/sender-policies/bulk-edit',
    legacyPath: '',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    },
    metadata: {
      sideMenuId: 'SETTINGS'
    }
  }
}
