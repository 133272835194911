import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  smallInput: {
    width: 90,
    '& p': {
      margin: 0,
      backgroundColor: colors.backgroundLight
    }
  }
}))
