/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import ErrorIcon from 'assets/images/icons/exclamation.png'
import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function Regex(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        {props.productName} allows, but does not require, the use of regular expressions in many of its block/allow
        features. A regular expression is a combination of characters or character classes and operators which allow
        text to be described for matching purposes.
        <b>Matches are NOT case-sensitive.</b> All regular expressions are lower case before use as is the text against
        which expressions are being matched. For this reason, none of the capital letter character classes can be used.
      </p>

      <div className={classes.errorNotice}>
        <img src={ErrorIcon} className={classes.noticeIcon} />
        <strong>Caution:</strong> Be careful when using <a href="#specialcharacters">special characters</a> such as |,
        *, &apos;.&apos; as they are not taken literally unless escaped with a preceding &apos;\&apos; character.
      </div>

      <p>Topics in this page include:</p>
      <ul>
        <li>
          <a href="#help-regex-operators">Operators</a>
        </li>
        <li>
          <a href="#help-regex-classes">Character Classes</a>
        </li>
        <li>
          <a href="#help-regex-misc">Miscellaneous characters</a>
        </li>
        <li>
          <a href="#help-regex-specialchars">Special characters</a>
        </li>
        <li>
          <a href="#help-regex-examples">Examples</a>
        </li>
      </ul>

      <h3 id="help-regex-operators">Operators</h3>
      <p>Operators recognized by {props.productName} in regular expressions include:</p>
      <ul className={classes.noListStyle}>
        <li>
          <b>*</b> - zero or more times
        </li>
        <li>
          <b>+</b> - one or more times
        </li>
        <li>
          <b>?</b> - zero or one time
        </li>
        <li>
          <b>|</b> - or
        </li>
        <li>
          <b>( )</b> - grouping
        </li>
      </ul>

      <h3 id="help-regex-classes">Character Classes</h3>
      <p>
        A character class matches a character or range of characters that is listed between brackets &apos;[&apos; and
        &apos;]&apos;. A dash (&apos;-&apos;) is used to specify a range of characters. Use the &apos;^&apos; character
        inside the &apos;[&apos; and &apos;]&apos; to negate a class. Some examples are:
      </p>

      <ul className={classes.noListStyle}>
        <li>
          <b>.</b> - any character except newline
        </li>
        <li>
          <b>[ac]</b> - one of the following characters: a A c C
        </li>
        <li>
          <b>[^ac]</b> - anything but the characters &apos;a&apos;, &apos;A&apos;, &apos;c&apos;, &apos;C&apos;
        </li>
        <li>
          <b>[a-z]</b> - letters &apos;a&apos; through &apos;z&apos; regardless of case
        </li>
        <li>
          <b>[a-z.]</b> - letters &apos;a&apos; through &apos;z&apos; or &apos;A&apos; through &apos;Z&apos; or a dot{' '}
        </li>
        <li>
          <b>[a-z\-]</b> - any alphabetic character or a dash
        </li>
        <li>
          <b>\d</b> - digit, shortcut for <b>[0-9]</b>
        </li>
        <li>
          <b>[^\d]</b> - non-digit
        </li>
        <li>
          <b>\w</b> - part of word: shortcut for <b>[A-Za-z0-9_]</b>
        </li>
        <li>
          <b>[^\w]</b> - non-word character
        </li>
        <li>
          <b>\s</b> - space character: shortcut for <b>[ \n\r\t]</b>
        </li>
        <li>
          <b>[^\s]</b> - non-space character
        </li>
      </ul>

      <h3 id="help-regex-misc">Miscellaneous characters</h3>
      <ul className={classes.noListStyle}>
        <li>
          <b>^</b> - beginning of line
        </li>
        <li>
          <b>$</b> - end of line
        </li>
        <li>
          <b>\b</b> - word boundary
        </li>
        <li>
          <b>\t</b> - tab character
        </li>
      </ul>

      <h3 id="help-regex-specialchars">Special characters</h3>
      <p>
        The following characters have a special meaning in regular expressions in {props.productName} and are prepended
        by a <b>\</b> character when they are interpreted literally:
      </p>
      <div className={classes.quote}>. [ ] \ * ? $ ( ) | ^ @</div>

      <h3 id="help-regex-examples">Examples</h3>
      <ul className={classes.noListStyle}>
        <li>
          <b>\bcialis\b</b> - matches cialis, but not specialist
        </li>
        <li>
          <b>p[^\w]?h[^\w]?a[^\w]?r[^\w]?m[^\w]?a[^\w]?c[^\w]?y</b> - matches pha,rmacy or P.harm-acy
        </li>
        <li>
          <b>viagra</b> - matches viagra, VIAGRA or vIaGRa
        </li>
        <li>
          <b>\d+</b> - matches 1 or more digits: 0, 42, 007
        </li>
        <li>
          <b>(bad|good)</b> - matches the letters &apos;bad&apos; or matches the letters &apos;good&apos;
        </li>
        <li>
          <b>^free</b> - matches the letters &apos;free&apos; at the beginning of a line
        </li>
        <li>
          <b>v[i1]agra</b> - matches viagra or v1agra
        </li>
        <li>
          <b>v(ia|1a)gra</b> - matches viagRA or v1agra
        </li>
        <li>
          <b>v\|agra</b> - matches v|Agra, V|Agra, v|agRa, etc.
        </li>
        <li>
          <b>v(i|1|\|)?agra</b> - matches vagra, viaGra, v1agrA, V|AGRA, etc.
        </li>
        <li>
          <b>\*FREE\*</b> - matches *FREE*, *frEE*, etc.
        </li>
        <li>
          <b>\*FREE\* V.*GRA</b> - matches *FREE* VIAGRA, *Free* VEHiCLegrA, etc.
        </li>
      </ul>
    </div>
  )
}

export default Regex
/* eslint-enable jsx-a11y/alt-text */
