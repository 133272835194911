import React, { useMemo } from 'react'
import { Chip, Checkbox, DataTable, DataTableColumn, Tooltip, MenuItem, Typography } from '@barracuda-internal/bds-core'

import { GridNoRecords } from '@progress/kendo-react-grid'
import { useFormatMessage } from 'lib/localization'
import { ModifiedReportMessage } from 'types/Messages'

import IconEncryption from 'assets/images/icons/icon_encryption.svg'
import IconAttachment from 'assets/images/icons/icon_attachment.svg'

import { Cell } from 'components/libs/grid/cell'
import { TableText } from 'components/libs/layout/pages/TableText'
import { useMessageLogListTableLogic } from 'components/pages/messageLog/messageLogList/messageLogListTable/useMessageLogListTableLogic'
import styles from 'components/pages/messageLog/messageLogList/messageLogListTable/messageLogListTableStyles'

const BASE_I18N_KEY = 'ess.message_log'
const BASE_I18N_TABLE_KEY = 'ess.data_tables.message_log'

const MessageLogListTable = () => {
  const classes = styles({ tableHeight: '100%', tableWidth: '100%' })
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)

  const {
    tableData,
    columns,
    columnsConfig,
    fromColumnMenu,
    handleRowClick,
    handleCheck,
    handleColumnResize,
    rowRender,
    loadedAndNoRecord,
    viewConfig
  } = useMessageLogListTableLogic()

  return useMemo(
    () => (
      <DataTable
        className={`${classes.table} ${classes.dataTable}`}
        data={tableData}
        selectedField="selected"
        onColumnResize={handleColumnResize}
        resizable
        rowRender={rowRender}
      >
        <GridNoRecords>{loadedAndNoRecord ? formatMessage('no_records_available') : ' '}</GridNoRecords>
        <DataTableColumn
          field={columns.CHECKBOX}
          {...columnsConfig[columns.CHECKBOX]}
          title=" "
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="checkbox-cell">
              <Checkbox
                size="small"
                onChange={() => handleCheck(dataItem)}
                checked={dataItem.isChecked}
                id={dataItem.mid}
                className={classes.checkbox}
                disableRipple
              />
            </Cell>
          )}
        />
        <DataTableColumn
          field={columns.ACTION}
          {...columnsConfig[columns.ACTION]}
          title={tableFormatMessage(`${columns.ACTION}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="action-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip
                title={formatMessage(dataItem.action.tooltip, { datetime: dataItem.action.datetime })}
                placement="top"
              >
                <Chip
                  className={`${classes.actionChip} ${dataItem.actionColor}`}
                  label={`${formatMessage(dataItem.action.text)}${dataItem.action.suffix}`}
                  size="small"
                  data-testid="action-cell-chip"
                  data-testvalue={dataItem.action.tooltip}
                />
              </Tooltip>
            </Cell>
          )}
        />
        {/* BDS Grid does not accept boolean as child, so turning false to empty string */}
        {(viewConfig.table.showDeliveryColumn || '') && (
          <DataTableColumn
            field={columns.DELIVERY}
            {...columnsConfig[columns.DELIVERY]}
            title={tableFormatMessage(`${columns.DELIVERY}`)}
            cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
              <Cell data-testid="delivery-cell" onClick={event => handleRowClick(event, dataItem)}>
                <Tooltip disableFocusListener title={formatMessage(dataItem.statusMapped.text)} placement="top">
                  <img
                    alt={formatMessage(dataItem.statusMapped.text)}
                    src={dataItem.statusMapped.icon}
                    className={classes.icon}
                    data-testid="delivery-cell-img"
                    data-testvalue={dataItem.statusMapped.text}
                  />
                </Tooltip>
              </Cell>
            )}
          />
        )}
        <DataTableColumn
          field={columns.FROM}
          {...columnsConfig[columns.FROM]}
          title={tableFormatMessage(`${columns.FROM}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="from-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip disableFocusListener title={dataItem.from} placement="top">
                <TableText dataText={dataItem.from} truncated />
              </Tooltip>
            </Cell>
          )}
          columnMenu={({ onCloseMenu }: { onCloseMenu: () => void }) =>
            Object.entries(fromColumnMenu.menuItems).map(([key, { label }]) => (
              <MenuItem
                key={key}
                className={classes.menuItem}
                onClick={() => {
                  fromColumnMenu.handleFromColumnMenu(key)
                  onCloseMenu()
                }}
                selected={key === fromColumnMenu.display}
                value={key}
              >
                {label}
              </MenuItem>
            ))
          }
        />
        <DataTableColumn
          field={columns.TO}
          {...columnsConfig[columns.TO]}
          title={tableFormatMessage(`${columns.TO}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="to-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip disableFocusListener title={dataItem.to} placement="top">
                <TableText dataText={dataItem.to} truncated />
              </Tooltip>
            </Cell>
          )}
        />
        <DataTableColumn
          field={columns.SUBJECT}
          {...columnsConfig[columns.SUBJECT]}
          title={tableFormatMessage(`${columns.SUBJECT}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="subject-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip disableFocusListener title={dataItem.subject} placement="top">
                <TableText dataText={dataItem.subject} truncated />
              </Tooltip>
            </Cell>
          )}
        />
        <DataTableColumn
          field={columns.DATE}
          {...columnsConfig[columns.DATE]}
          title={tableFormatMessage(`${columns.DATE}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="date-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip disableFocusListener title={dataItem.formattedDate} placement="top">
                <TableText dataText={dataItem.formattedDate} truncated />
              </Tooltip>
            </Cell>
          )}
        />
        <DataTableColumn
          field={columns.SIZE}
          {...columnsConfig[columns.SIZE]}
          title={tableFormatMessage(`${columns.SIZE}`)}
          cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
            <Cell data-testid="size-cell" onClick={event => handleRowClick(event, dataItem)}>
              <Tooltip disableFocusListener title={dataItem.formattedSize} placement="top">
                <TableText dataText={dataItem.formattedSize} truncated />
              </Tooltip>
            </Cell>
          )}
        />
        {/* BDS Grid does not accept boolean as child, so turning false to empty string */}
        {(viewConfig.table.showEncryptionColumn || '') && (
          <DataTableColumn
            field={columns.ENCRYPTION}
            {...columnsConfig[columns.ENCRYPTION]}
            headerCell={() => <img alt="" src={IconEncryption} className={classes.cellIcon} />}
            cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
              <Cell data-testid="encryption-cell" onClick={event => handleRowClick(event, dataItem)}>
                {dataItem.encryption.icon ? (
                  <Tooltip disableFocusListener title={formatMessage(dataItem.encryption.text)} placement="top">
                    <img
                      alt={formatMessage(dataItem.encryption.text)}
                      src={dataItem.encryption.icon}
                      className={classes.icon}
                    />
                  </Tooltip>
                ) : null}
              </Cell>
            )}
          />
        )}
        {/* BDS Grid does not accept boolean as child, so turning false to empty string */}
        {(viewConfig.table.showAttachmentColumn || '') && (
          <DataTableColumn
            field={columns.ATTACHMENT}
            {...columnsConfig[columns.ATTACHMENT]}
            headerCell={() => <img alt="" src={IconAttachment} className={classes.cellIcon} />}
            cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
              <Cell data-testid="attachment-cell" onClick={event => handleRowClick(event, dataItem)}>
                {dataItem.attachments && dataItem.attachments.length > 0 && (
                  <Tooltip disableFocusListener title="Attachment" placement="top">
                    <img alt="Attachment" src={IconAttachment} className={classes.icon} />
                  </Tooltip>
                )}
              </Cell>
            )}
          />
        )}
        {/* BDS Grid does not accept boolean as child, so turning false to empty string */}
        {(viewConfig.table.showReasonColumn || '') && (
          <DataTableColumn
            field={columns.REASON}
            {...columnsConfig[columns.REASON]}
            title={tableFormatMessage(`${columns.REASON}`)}
            cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
              <Cell data-testid="reason-cell" onClick={event => handleRowClick(event, dataItem)}>
                <Tooltip disableFocusListener title={dataItem.reason} placement="top">
                  <TableText dataText={dataItem.reason} truncated dataTestId="reason-cell-text" />
                </Tooltip>
              </Cell>
            )}
          />
        )}
        {/* BDS Grid does not accept boolean as child, so turning false to empty string */}
        {(viewConfig.table.showReasonColumn || '') && (
          <DataTableColumn
            field={columns.SCORE}
            {...columnsConfig[columns.SCORE]}
            title={tableFormatMessage(`${columns.SCORE}`)}
            cell={({ dataItem }: { dataItem: ModifiedReportMessage }) => (
              <Cell data-testid="score-cell" onClick={event => handleRowClick(event, dataItem)}>
                <Tooltip disableFocusListener title={dataItem.score} placement="top">
                  <TableText dataText={dataItem.score} truncated />
                </Tooltip>
              </Cell>
            )}
          />
        )}
      </DataTable>
    ),
    [
      classes,
      formatMessage,
      tableFormatMessage,
      tableData,
      columns,
      columnsConfig,
      fromColumnMenu,
      handleRowClick,
      handleCheck,
      handleColumnResize,
      rowRender,
      loadedAndNoRecord,
      viewConfig
    ]
  )
}

export default MessageLogListTable
