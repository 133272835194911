import { UserRole } from 'config/userRole'

export { UserRole }

export function minimumUserRoleRequirement(role: number) {
  return (Object.values(UserRole) as Array<number>).reduce((allRoles: number, currentRole: number) => {
    if (currentRole <= role) {
      return allRoles + currentRole
    }
    return allRoles
  }, 0)
}

// features (can use multiple roles) like UserRole.SUPPORT_USER.value + UserRole.DOMAIN_USER.value
export enum UserRightsForUiPaths {
  DOMAINS = minimumUserRoleRequirement(UserRole.HELPDESK_ROLE),
  USERS = minimumUserRoleRequirement(UserRole.HELPDESK_ROLE)
}

export enum UserRights {
  VIEW_ADMIN_MESSAGES_DETAILS = UserRole.ACCOUNT_USER,
  GET_ADMIN_SUPPORT_TOPICS = UserRole.ACCOUNT_USER,
  ADMIN_APP_ACCESS = UserRole.ACCOUNT_USER,
  ACCOUNT_LEVEL_BLOCK_SENDER = UserRole.ACCOUNT_USER,
  ACCOUNT_LEVEL_FPFN = UserRole.ACCOUNT_USER,
  REDELIVER_ACCOUNT_LEVEL = UserRole.ACCOUNT_USER,
  ALLOW_LIST_ACCOUNT_LEVEL = UserRole.ACCOUNT_USER,
  ACCOUNT_LEVEL_REACATEGORIZE = UserRole.ACCOUNT_USER
}
