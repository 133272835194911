import React, { useMemo } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@barracuda-internal/bds-core'
import styles from 'components/pages/support/supportStyles'

import { useFormatMessage } from 'lib/localization'

import {
  useMessageAccessConfirmDialog,
  UseMessageAccessConfirmDialogLogicProps
} from 'components/pages/support/useMessageAccessConfirmDialog'

const BASE_I18N_KEY = 'ess.support_page'

export type MessageAccessConfirmProps = UseMessageAccessConfirmDialogLogicProps

function MessageAccessConfirmDialog({ onCloseDialog }: MessageAccessConfirmProps) {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [state, eventHandlers] = useMessageAccessConfirmDialog({ onCloseDialog })

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle className={classes.borderBottomStyle}>
          <Grid container direction="row" alignItems="center">
            <Typography variant="h3">{formatMessage('enable_message_access')}</Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" alignItems="center">
            <Typography variant="body1">{formatMessage('dialog.message_access.following_permission')}</Typography>
          </Grid>
          <br />
          <br />
          <Grid container direction="row" alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Typography variant="h4">{formatMessage('dialog.message_access.email_content')}</Typography>
              <Typography variant="body1">{formatMessage('dialog.message_access.email_content_text')}</Typography>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid container direction="row" alignItems="center">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Typography variant="h4">{formatMessage('dialog.message_access.email_attachment')}</Typography>
              <Typography variant="body1">{formatMessage('dialog.message_access.email_attachment_text')}</Typography>
            </Grid>
          </Grid>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={eventHandlers.onCancel} color="primary" disabled={state.updateInProgress}>
            {formatMessage('cancel')}
          </Button>
          <Button variant="contained" onClick={eventHandlers.onConfirm} color="primary">
            {formatMessage('enable_message_access')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [formatMessage, state, eventHandlers, classes]
  )
}

export default MessageAccessConfirmDialog
