import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function EndUserQuarantineNotification(props: HelpComponentProps) {
  const classes = styles()

  return (
    <div className={classes.content} data-testid="content">
      <p>
        The Barracuda Email Gateway Defense can send you notifications (a quarantine digest) at predefined intervals,
        indicating that you have quarantined messages. If enabled by your system administrator, you can set the
        notification interval from this page. Notifications only go out if new mail is quarantined since the last
        notice.
      </p>
      <p>
        The links in the quarantine notification email allow you to access your Barracuda Email Gateway Defense user
        account without entering your username and password. The link is valid for seven days, after which you must log
        in manually at https://ess.vt2.barracudanetworks.com.
      </p>
      <p />
      <ul>
        <li>
          <b>Quarantine messages</b>
          <br />
          When set to <b>Yes</b>, messages that would be blocked by intent are instead quarantined.
        </li>
        <li>
          <b>Default interval for user quarantine notifications</b>
          <br />
          Specify whether to <b>Never</b> send user quarantine notifications or create <b>Scheduled</b> user quarantine
          notifications.
          <ul>
            <li>
              <b>Never</b>
              <br />
              Mail is quarantined but no notification is sent.
            </li>
            <li>
              <b>Scheduled</b>
              <br />
              Select the day and time quarantine notifications are to be sent using the{' '}
              <b>Schedule notification intervals</b> section.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

export default EndUserQuarantineNotification
