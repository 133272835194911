import React, { ChangeEvent, FC, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { AtpExemptIp } from 'types/atpSettings'
import { useSettingsTableActions } from 'components/pages/atpSettings/useSettingsTableActions'

const initialIpSort = [{ field: 'address', dir: 'asc' } as SortDescriptor]

const BLANK_ATP_EXEMPT_IP = { address: '', netmask: '', comment: '', id: '' }

export interface AtpExemptIpsTableConfig {
  data: AtpExemptIp[]
  insertIpInData: (ip: AtpExemptIp) => boolean
  removeIpFromData: (id: string) => void
}

export interface AtpExemptIpsTable {
  newIp: {
    address: MutableRefObject<HTMLInputElement | null>
    netmask: MutableRefObject<HTMLInputElement | null>
    comment: MutableRefObject<HTMLInputElement | null>
    id: string
  }
  onNewIpChange: (e: ChangeEvent<HTMLInputElement>) => void
  onAddIp: () => void
  onDeleteIp: (id: string) => void
  ConfirmDeleteIpDialog: FC
  tableData: {
    data: AtpExemptIp[]
    total: number
  }
  handleSortChange: (e: GridSortChangeEvent) => void
  sort: SortDescriptor[]
  hasChanges: boolean
}

export const useAtpExemptIpsTable = ({
  data,
  insertIpInData,
  removeIpFromData
}: AtpExemptIpsTableConfig): AtpExemptIpsTable => {
  const [sort, setSort] = useState(initialIpSort)
  const newIpAddressRef = useRef<HTMLInputElement | null>(null)
  const newIpNetmaskRef = useRef<HTMLInputElement | null>(null)
  const newIpCommentRef = useRef<HTMLInputElement | null>(null)
  const [hasChanges, setHasChanges] = useState(false)

  const onAddAtpExemptIp = useCallback(() => {
    if (newIpAddressRef.current && newIpNetmaskRef.current && newIpCommentRef.current) {
      const inserted = insertIpInData({
        address: newIpAddressRef.current?.value || '',
        netmask: newIpNetmaskRef.current?.value || '',
        comment: newIpCommentRef.current?.value || '',
        id: MD5(makeUuid()).toString()
      })
      if (inserted) {
        newIpAddressRef.current.value = ''
        newIpNetmaskRef.current.value = ''
        newIpCommentRef.current.value = ''
      }
    }
  }, [insertIpInData])

  const onDeleteAtpExemptIp = useCallback(
    (id: string) => {
      removeIpFromData(id)
    },
    [removeIpFromData]
  )

  const onNewIpChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (newIpAddressRef.current && newIpNetmaskRef.current && newIpCommentRef.current) {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === 'address':
          newIpAddressRef.current.value = value
          break
        case name === 'netmask':
          newIpNetmaskRef.current.value = value
          break
        case name === 'comment':
          newIpCommentRef.current.value = value
          break
      }
      setHasChanges(true)
    }
  }, [])

  const tableData = useMemo(() => {
    const orderedData = orderBy(data, sort)
    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_ATP_EXEMPT_IP)
    return {
      data: processedData as AtpExemptIp[],
      total: processedData.length || 0
    }
  }, [data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const actions = useSettingsTableActions({
    addItem: onAddAtpExemptIp,
    deleteItem: onDeleteAtpExemptIp
  })

  return useMemo(
    () => ({
      newIp: {
        address: newIpAddressRef,
        netmask: newIpNetmaskRef,
        comment: newIpCommentRef,
        id: ''
      },
      onNewIpChange,
      onAddIp: actions.onAdd,
      onDeleteIp: actions.onDelete,
      ConfirmDeleteIpDialog: actions.ConfirmDialog,
      tableData,
      handleSortChange,
      sort,
      hasChanges
    }),
    [actions, onNewIpChange, tableData, handleSortChange, sort, hasChanges]
  )
}
