import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import appConfig from 'config/appConfig'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { getCurrentSession } from 'redux/features/oauth2/oauth2ApiThunks'
import { IdToken } from 'types/oauth2'
import { resetGetCurrentSession } from 'redux/features/oauth2/oauth2Slice'
import {
  isPostAuthRedirectCommand,
  isPostAuthViewQuarantinedMessagesCommand,
  makePostAuthCommandFactory
} from 'lib/postAuthCommands/postAuthCommands'

export enum SessionCheckStatus {
  IDLE,
  PENDING,
  DONE
}

export interface State {
  status: SessionCheckStatus
}

export interface EventHandlers {
  checkSession: () => void
  forceCheckSessionStateDone: () => void
}

export type SessionCheck = [State, EventHandlers]

export const useSessionCheck = (): SessionCheck => {
  const { appTypeEntryPath } = useAppTypeEntryPaths()
  const dispatch = useAppDispatch()
  const [isResponseHandled, setIsResponseHandled] = useState(false)
  const {
    isEnhancedAuthEnabled,
    isGetCurrentSessionSuccess,
    isGetCurrentSessionFailed,
    isGetCurrentSessionPending,
    currentSessionResponse
  } = useAppSelector(_store => ({
    isEnhancedAuthEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled,
    isGetCurrentSessionPending: isPending(_store.oauth2.api.getCurrentSessionApiStatus),
    isGetCurrentSessionSuccess: isSuccess(_store.oauth2.api.getCurrentSessionApiStatus),
    isGetCurrentSessionFailed: isFailed(_store.oauth2.api.getCurrentSessionApiStatus),
    currentSessionResponse: _store.oauth2.getCurrentSessionResponse
  }))

  const status = useMemo(() => {
    switch (true) {
      case !isEnhancedAuthEnabled:
      case isResponseHandled:
      case isGetCurrentSessionFailed:
        return SessionCheckStatus.DONE
      case isGetCurrentSessionPending:
        return SessionCheckStatus.PENDING
      default:
        return SessionCheckStatus.IDLE
    }
  }, [isEnhancedAuthEnabled, isGetCurrentSessionFailed, isGetCurrentSessionPending, isResponseHandled])

  const isOwnToken = useCallback((idToken: IdToken) => idToken.azp === appConfig.GATEWAY_URL, [])

  const handleCurrentSessionResponse = useCallback(() => {
    if (!currentSessionResponse) {
      setIsResponseHandled(true)
      return
    }
    if (isOwnToken(currentSessionResponse.idToken)) {
      const commandFactory = makePostAuthCommandFactory(currentSessionResponse.egdIdentity.primaryUserId || '')
      const command = commandFactory.create(window.location.search || '')
      // Do not add the command to redirect to message log, as that is the default behaviour anyway
      const ignoreCommand =
        isPostAuthViewQuarantinedMessagesCommand(command) ||
        (isPostAuthRedirectCommand(command) && command.redirectTo === '/webui/message-log')
      if (!ignoreCommand) {
        const cmdEncoded = btoa(JSON.stringify(commandFactory.create(window.location.search || '')))
        appTypeEntryPath.goto(undefined, undefined, `?command=${cmdEncoded}`)
        return
      }
      appTypeEntryPath.goto()
      return
    }
    setIsResponseHandled(true)
  }, [appTypeEntryPath, currentSessionResponse, isOwnToken])

  const checkSession = useCallback(() => {
    dispatch(getCurrentSession())
  }, [dispatch])

  const forceCheckSessionStateDone = useCallback(() => {
    setIsResponseHandled(true)
  }, [])

  useEffect(
    () => () => {
      dispatch(resetGetCurrentSession())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!isGetCurrentSessionSuccess) {
      return
    }
    handleCurrentSessionResponse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetCurrentSessionSuccess])

  return [{ status }, { checkSession, forceCheckSessionStateDone }]
}
