import { LdapExceptions } from 'types/ldap'

export const isLdapException = (value: unknown): value is LdapExceptions =>
  typeof value === 'string' &&
  Object.values(LdapExceptions)
    .map(e => String(e))
    .includes(value)

export const shouldRetryLdapAuth = (ldapException: LdapExceptions) =>
  [LdapExceptions.LDAPError, LdapExceptions.TIMEOUT, LdapExceptions.SERVER_DOWN].includes(ldapException)
