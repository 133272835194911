import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import logger from 'lib/logger'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'

export interface ValidationRequests {
  domainId: string
}

export interface ValidationResponse {
  domainName: string
}

export interface CnameValidationResponse {
  verificationToken: string
  domainName: string
}

export interface PostmanValidationResponse {
  domainName: string
}

export const cnameValidation = createAsyncThunk<CnameValidationResponse, ValidationRequests, ApiRejectResponse>(
  'ENCRYPTION/cnameValidation',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.CNAME_VALIDATION, { urlParams: { domainId: payload.domainId } })
      dispatch(
        setSuccessSnackBar({
          message: 'encryption_cname_validation_success',
          params: [`barracuda${resp.data.verificationToken}.${resp.data.domainName}`]
        })
      )
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to do cname validation: ${e?.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'encryption_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const postValidation = createAsyncThunk<PostmanValidationResponse, ValidationRequests, ApiRejectResponse>(
  'ENCRYPTION/postValidation',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.POST_VALIDATION, { urlParams: { domainId: payload.domainId } })
      dispatch(setSuccessSnackBar({ message: 'encryption_post_validation_success', params: [resp.data.domainName] }))
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to do post validation: ${e?.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'encryption_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const confirmValidation = createAsyncThunk<boolean, ValidationRequests, ApiRejectResponse>(
  'ENCRYPTION/confirmValidation',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.CONFIRM_VALIDATION, { urlParams: { domainId: payload.domainId } })
      dispatch(setSuccessSnackBar({ message: 'encryption_confirm_validation_success' }))
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to do confirm validation: ${e?.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'encryption_confirm_validation_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const removeValidation = createAsyncThunk<boolean, ValidationRequests, ApiRejectResponse>(
  'ENCRYPTION/removeValidation',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.REMOVE_VALIDATION, { urlParams: { domainId: payload.domainId } })
      dispatch(setSuccessSnackBar({ message: 'encryption_validation_remove_success' }))
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to do remove validation: ${e?.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'encryption_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const removeLogo = createAsyncThunk<boolean, ValidationRequests, ApiRejectResponse>(
  'ENCRYPTION/removeLogo',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.REMOVE_LOGO, { urlParams: { domainId: payload.domainId } })
      dispatch(setSuccessSnackBar({ message: 'encryption_logo_remove_success' }))
      return resp.data as any
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to do remove logo: ${e?.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'encryption_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)
