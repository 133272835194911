import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  getDashboardStats,
  getGeoDataStats,
  getInboundEmailStatisticsStats,
  getInboundTopBlockedStats,
  getLastBlockedAtpStats,
  getOutboundEmailStatisticsStats,
  getOutboundTopBlockedStats,
  getTopDomainsStats,
  getTotalThreatsAndVirusesStats,
  getUniqueDomainsRecipients
} from 'redux/features/stats/statsApiThunks'
import {
  DashboardFilters,
  EmailStatistics,
  GeoData,
  LastBlockedAtp,
  StatType,
  TopBlocked,
  TopDomainsActions,
  TopRecipientDomains,
  TotalThreatsAndViruses,
  UniqueDomainRecipientsResponse
} from 'types/stats'
import { Action } from 'types/Messages'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'

export interface StatsState {
  api: {
    getDashboardStats: ApiStatus
    getGeoDataStats: ApiStatus
    getTopDomainsStats: ApiStatus
    getInboundTopBlockedStats: ApiStatus
    getOutboundTopBlockedStats: ApiStatus
    getLastBlockedAtpStats: ApiStatus
    getInboundEmailStatisticsApiStatus: ApiStatus
    getOutboundEmailStatisticsApiStatus: ApiStatus
    getTotalThreatsAndVirusesApiStatus: ApiStatus
    getUniqueDomainsRecipientsApiStatus: ApiStatus
  }
  dashboardFilters: DashboardFilters
  geoData: GeoData | undefined
  topDomains: TopRecipientDomains | undefined
  inboundTopBlockedStats: TopBlocked | undefined
  outboundTopBlockedStats: TopBlocked | undefined
  lastBlockedAtpStats: LastBlockedAtp | undefined
  inboundEmailStatistics: EmailStatistics | undefined
  outboundEmailStatistics: EmailStatistics | undefined
  totalThreatsAndViruses: TotalThreatsAndViruses | undefined
  uniqueDomainRecipients: UniqueDomainRecipientsResponse | undefined
}

// initialState
export const INITIAL_STATE: StatsState = {
  api: {
    getDashboardStats: inIdle,
    getGeoDataStats: inIdle,
    getTopDomainsStats: inIdle,
    getInboundTopBlockedStats: inIdle,
    getOutboundTopBlockedStats: inIdle,
    getLastBlockedAtpStats: inIdle,
    getInboundEmailStatisticsApiStatus: inIdle,
    getOutboundEmailStatisticsApiStatus: inIdle,
    getTotalThreatsAndVirusesApiStatus: inIdle,
    getUniqueDomainsRecipientsApiStatus: inIdle
  },
  dashboardFilters: {
    topDomains: {
      action: (getTableWithDropdownConfig(DisplayType.recipientDomains)?.dropdownItems[0].value ||
        '') as TopDomainsActions
    },
    inboundEmailStatistics: {
      action: Action.none
    },
    inboundTopBlocked: {
      statType:
        (getTableWithDropdownConfig(DisplayType.inbound)?.dropdownItems[0].value || '') === StatType.SENDERS
          ? StatType.SENDERS
          : StatType.RECIPIENTS
    },
    outboundEmailStatistics: {
      action: Action.none
    },
    outboundTopBlocked: {
      statType:
        (getTableWithDropdownConfig(DisplayType.outbound)?.dropdownItems[0].value || '') === StatType.SENDERS
          ? StatType.SENDERS
          : StatType.RECIPIENTS
    }
  },
  geoData: undefined,
  topDomains: undefined,
  inboundTopBlockedStats: undefined,
  outboundTopBlockedStats: undefined,
  lastBlockedAtpStats: undefined,
  inboundEmailStatistics: undefined,
  outboundEmailStatistics: undefined,
  totalThreatsAndViruses: undefined,
  uniqueDomainRecipients: undefined
}

/* eslint-disable no-param-reassign */
export const statsSlice = createSlice({
  name: 'STATS',
  initialState: INITIAL_STATE,
  reducers: {
    updateFilters: (state, action: PayloadAction<Partial<DashboardFilters>>) => {
      state.dashboardFilters = { ...state.dashboardFilters, ...action.payload }
    },
    resetGeoDataStats: state => {
      state.geoData = INITIAL_STATE.geoData
      state.api.getGeoDataStats = INITIAL_STATE.api.getGeoDataStats
    },
    resetTopRecipientDomainsStats: state => {
      state.topDomains = INITIAL_STATE.topDomains
      state.api.getTopDomainsStats = INITIAL_STATE.api.getTopDomainsStats
    },
    resetInboundTopBlockedStats: state => {
      state.inboundTopBlockedStats = INITIAL_STATE.inboundTopBlockedStats
      state.api.getInboundTopBlockedStats = INITIAL_STATE.api.getInboundTopBlockedStats
    },
    resetOutboundTopBlockedStats: state => {
      state.outboundTopBlockedStats = INITIAL_STATE.outboundTopBlockedStats
      state.api.getOutboundTopBlockedStats = INITIAL_STATE.api.getOutboundTopBlockedStats
    },
    resetLastBlockedAtpStats: state => {
      state.lastBlockedAtpStats = INITIAL_STATE.lastBlockedAtpStats
      state.api.getLastBlockedAtpStats = INITIAL_STATE.api.getLastBlockedAtpStats
    },
    resetInboundEmailStatistics: state => {
      state.inboundEmailStatistics = INITIAL_STATE.inboundEmailStatistics
      state.api.getInboundEmailStatisticsApiStatus = INITIAL_STATE.api.getInboundEmailStatisticsApiStatus
    },
    resetOutboundEmailStatistics: state => {
      state.outboundEmailStatistics = INITIAL_STATE.outboundEmailStatistics
      state.api.getOutboundEmailStatisticsApiStatus = INITIAL_STATE.api.getOutboundEmailStatisticsApiStatus
    },
    resetTotalThreatsAndVirusesStats: state => {
      state.totalThreatsAndViruses = INITIAL_STATE.totalThreatsAndViruses
      state.api.getTotalThreatsAndVirusesApiStatus = INITIAL_STATE.api.getTotalThreatsAndVirusesApiStatus
    },
    resetUniqueDomainRecipients: state => {
      state.uniqueDomainRecipients = INITIAL_STATE.uniqueDomainRecipients
      state.api.getUniqueDomainsRecipientsApiStatus = INITIAL_STATE.api.getUniqueDomainsRecipientsApiStatus
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // getDashboardStats
      .addCase(getDashboardStats.pending, state => {
        state.api.getDashboardStats = inProgress
        state.api.getGeoDataStats = inProgress
        state.api.getTopDomainsStats = inProgress
        state.api.getInboundTopBlockedStats = inProgress
        state.api.getOutboundTopBlockedStats = inProgress
        state.api.getLastBlockedAtpStats = inProgress
        state.api.getInboundEmailStatisticsApiStatus = inProgress
        state.api.getOutboundEmailStatisticsApiStatus = inProgress
        state.api.getTotalThreatsAndVirusesApiStatus = inProgress
        state.geoData = INITIAL_STATE.geoData
        state.topDomains = INITIAL_STATE.topDomains
        state.inboundTopBlockedStats = INITIAL_STATE.inboundTopBlockedStats
        state.outboundTopBlockedStats = INITIAL_STATE.outboundTopBlockedStats
        state.lastBlockedAtpStats = INITIAL_STATE.lastBlockedAtpStats
      })
      .addCase(getDashboardStats.fulfilled, (state, action) => {
        state.api.getDashboardStats = successResponse
        state.api.getGeoDataStats = successResponse
        state.api.getTopDomainsStats = successResponse
        state.api.getInboundTopBlockedStats = successResponse
        state.api.getOutboundTopBlockedStats = successResponse
        state.api.getLastBlockedAtpStats = successResponse
        state.api.getInboundEmailStatisticsApiStatus = successResponse
        state.api.getOutboundEmailStatisticsApiStatus = successResponse
        state.api.getTotalThreatsAndVirusesApiStatus = successResponse
        state.geoData = action.payload.geoData || undefined
        state.topDomains = action.payload.topDomains
        state.inboundTopBlockedStats = action.payload.inboundTopBlocked
        state.outboundTopBlockedStats = action.payload.outboundTopBlocked
        state.lastBlockedAtpStats = action.payload.lastBlockedAtp
        state.inboundEmailStatistics = action.payload.inboundEmailStatistics
        state.outboundEmailStatistics = action.payload.outboundEmailStatistics
        state.totalThreatsAndViruses = action.payload.totalTheatsAndViruses
      })
      .addCase(getDashboardStats.rejected, (state, action) => {
        state.api.getDashboardStats = failedResponse(action.payload)
        state.api.getGeoDataStats = failedResponse(action.payload)
        state.api.getTopDomainsStats = failedResponse(action.payload)
        state.api.getInboundTopBlockedStats = failedResponse(action.payload)
        state.api.getOutboundTopBlockedStats = failedResponse(action.payload)
        state.api.getLastBlockedAtpStats = failedResponse(action.payload)
        state.api.getInboundEmailStatisticsApiStatus = failedResponse(action.payload)
        state.api.getOutboundEmailStatisticsApiStatus = failedResponse(action.payload)
        state.api.getTotalThreatsAndVirusesApiStatus = failedResponse(action.payload)
      })
      // getGeoDataStats
      .addCase(getGeoDataStats.pending, state => {
        state.api.getGeoDataStats = inProgress
        state.geoData = INITIAL_STATE.geoData
      })
      .addCase(getGeoDataStats.fulfilled, (state, action) => {
        state.api.getGeoDataStats = successResponse
        state.geoData = action.payload
      })
      .addCase(getGeoDataStats.rejected, (state, action) => {
        state.api.getGeoDataStats = failedResponse(action.payload)
      })

      // getTopDomainsStats
      .addCase(getTopDomainsStats.pending, state => {
        state.api.getTopDomainsStats = inProgress
        state.topDomains = INITIAL_STATE.topDomains
      })
      .addCase(getTopDomainsStats.fulfilled, (state, action) => {
        state.api.getTopDomainsStats = successResponse
        state.topDomains = action.payload
      })
      .addCase(getTopDomainsStats.rejected, (state, action) => {
        state.api.getTopDomainsStats = failedResponse(action.payload)
      })

      // getInboundTopBlockedStats
      .addCase(getInboundTopBlockedStats.pending, state => {
        state.api.getInboundTopBlockedStats = inProgress
        state.inboundTopBlockedStats = INITIAL_STATE.inboundTopBlockedStats
      })
      .addCase(getInboundTopBlockedStats.fulfilled, (state, action) => {
        state.api.getInboundTopBlockedStats = successResponse
        state.inboundTopBlockedStats = action.payload
      })
      .addCase(getInboundTopBlockedStats.rejected, (state, action) => {
        state.api.getInboundTopBlockedStats = failedResponse(action.payload)
      })

      // getOutboundTopBlockedStats
      .addCase(getOutboundTopBlockedStats.pending, state => {
        state.api.getOutboundTopBlockedStats = inProgress
        state.outboundTopBlockedStats = INITIAL_STATE.outboundTopBlockedStats
      })
      .addCase(getOutboundTopBlockedStats.fulfilled, (state, action) => {
        state.api.getOutboundTopBlockedStats = successResponse
        state.outboundTopBlockedStats = action.payload
      })
      .addCase(getOutboundTopBlockedStats.rejected, (state, action) => {
        state.api.getOutboundTopBlockedStats = failedResponse(action.payload)
      })

      // getLastBlockedAtpStats
      .addCase(getLastBlockedAtpStats.pending, state => {
        state.api.getLastBlockedAtpStats = inProgress
        state.lastBlockedAtpStats = INITIAL_STATE.lastBlockedAtpStats
      })
      .addCase(getLastBlockedAtpStats.fulfilled, (state, action) => {
        state.api.getLastBlockedAtpStats = successResponse
        state.lastBlockedAtpStats = action.payload
      })
      .addCase(getLastBlockedAtpStats.rejected, (state, action) => {
        state.api.getLastBlockedAtpStats = failedResponse(action.payload)
      })

      // getInboundEmailStatisticsStats
      .addCase(getInboundEmailStatisticsStats.pending, state => {
        state.api.getInboundEmailStatisticsApiStatus = inProgress
      })
      .addCase(getInboundEmailStatisticsStats.fulfilled, (state, action) => {
        state.api.getInboundEmailStatisticsApiStatus = successResponse
        state.inboundEmailStatistics = action.payload
      })
      .addCase(getInboundEmailStatisticsStats.rejected, (state, action) => {
        state.api.getInboundEmailStatisticsApiStatus = failedResponse(action.payload)
      })

      // getOutboundEmailStatisticsStats
      .addCase(getOutboundEmailStatisticsStats.pending, state => {
        state.api.getOutboundEmailStatisticsApiStatus = inProgress
      })
      .addCase(getOutboundEmailStatisticsStats.fulfilled, (state, action) => {
        state.api.getOutboundEmailStatisticsApiStatus = successResponse
        state.outboundEmailStatistics = action.payload
      })
      .addCase(getOutboundEmailStatisticsStats.rejected, (state, action) => {
        state.api.getOutboundEmailStatisticsApiStatus = failedResponse(action.payload)
      })

      // getTotalThreatsAndVirusesStats
      .addCase(getTotalThreatsAndVirusesStats.pending, state => {
        state.api.getTotalThreatsAndVirusesApiStatus = inProgress
      })
      .addCase(getTotalThreatsAndVirusesStats.fulfilled, (state, action) => {
        state.api.getTotalThreatsAndVirusesApiStatus = successResponse
        state.totalThreatsAndViruses = action.payload
      })
      .addCase(getTotalThreatsAndVirusesStats.rejected, (state, action) => {
        state.api.getTotalThreatsAndVirusesApiStatus = failedResponse(action.payload)
      })

      // getUniqueDomainsRecipients
      .addCase(getUniqueDomainsRecipients.pending, state => {
        state.api.getUniqueDomainsRecipientsApiStatus = inProgress
      })
      .addCase(getUniqueDomainsRecipients.fulfilled, (state, action) => {
        state.api.getUniqueDomainsRecipientsApiStatus = successResponse
        state.uniqueDomainRecipients = action.payload
      })
      .addCase(getUniqueDomainsRecipients.rejected, (state, action) => {
        state.api.getUniqueDomainsRecipientsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  updateFilters,
  resetGeoDataStats,
  resetTopRecipientDomainsStats,
  resetInboundTopBlockedStats,
  resetOutboundTopBlockedStats,
  resetLastBlockedAtpStats,
  resetInboundEmailStatistics,
  resetOutboundEmailStatistics,
  resetTotalThreatsAndVirusesStats,
  resetUniqueDomainRecipients,
  reset
} = statsSlice.actions

export {
  getDashboardStats,
  getGeoDataStats,
  getTopDomainsStats,
  getInboundTopBlockedStats,
  getOutboundTopBlockedStats,
  getLastBlockedAtpStats,
  getInboundEmailStatisticsStats,
  getOutboundEmailStatisticsStats,
  getTotalThreatsAndVirusesStats,
  getUniqueDomainsRecipients
}

export default statsSlice.reducer
