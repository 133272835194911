import React, { useMemo } from 'react'

import { useGlobalDialogLogic } from 'components/libs/dialog/globalDialog/useGlobalDialogLogic'
import InfoDialog from 'components/libs/dialog/infoDialog/InfoDialog'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.layout'

const GlobalDialog: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [{ dialogType, onConfirm }] = useGlobalDialogLogic()

  return useMemo(() => {
    if (!dialogType) {
      return null
    }

    return (
      <InfoDialog
        title={formatMessage(`global_dialog.${dialogType}.title`)}
        buttonText={formatMessage(`global_dialog.${dialogType}.button`)}
        text={formatMessage(`global_dialog.${dialogType}.text`)}
        onClose={onConfirm}
        data-testid={`${dialogType}-dialog`}
      />
    )
  }, [dialogType, onConfirm, formatMessage])
}

export default GlobalDialog
