import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import logger from 'lib/logger'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { LdapSettings, LdapSyncStats, LdapTestResult } from 'types/ldap'
import { SaveDomainSettingsResponse } from 'redux/features/domains/domainsApiThunks'

export interface LdapSyncStatsRequest {
  domainId: string
}

export type LdapSyncStatsResponse = any

export const getLdapSyncStats = createAsyncThunk<LdapSyncStatsResponse, LdapSyncStatsRequest, ApiRejectResponse>(
  'LDAP/getLdapSyncStats',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const res = await restClient(apiRoutes.GET_LDAP_SYNC_STATS, {
        urlParams: {
          domainId: payload.domainId
        }
      })
      return res.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to get LDAP sync stats: ${e.message}`)
      }
      dispatch(
        setErrorSnackBar({
          message: 'ldap_get_sync_stats_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface LdapTestRequest {
  domainId: string
  ldapSettings: LdapSettings
  testingEmail?: string
}

export const postLdapTest = createAsyncThunk<LdapTestResult, LdapTestRequest, ApiRejectResponse>(
  'LDAP/postLdapTest',
  async (payload, { rejectWithValue }) => {
    try {
      const { domainId, ...data } = payload
      const res = await restClient(apiRoutes.POST_LDAP_TEST, {
        urlParams: { domainId },
        data
      })
      return res.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to execute LDAP test: ${e.message}`)
      }
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface LdapSyncNowRequest {
  domainId: string
}

export type LdapSyncNowResponse = LdapSyncStats

export const postLdapSyncNow = createAsyncThunk<LdapSyncNowResponse, LdapSyncNowRequest, ApiRejectResponse>(
  'LDAP/postLdapSyncNow',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.POST_LDAP_SYNC_NOW, {
        urlParams: {
          domainId: payload.domainId
        }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'ldap_sync_success',
          params: [resp.data?.usersUpdated || 0, resp.data?.usersNew || 0, resp.data?.usersDeleted || 0]
        })
      )
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to execute LDAP sync: ${e.message}`)
      }
      dispatch(
        setErrorSnackBar({
          message: 'ldap_sync_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface LdapSerialResetRequest {
  domainId: string
}

export type LdapSerialResetResponse = SaveDomainSettingsResponse

export const postLdapSerialReset = createAsyncThunk<LdapSerialResetResponse, LdapSerialResetRequest, ApiRejectResponse>(
  'LDAP/postLdapSerialReset',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { domainId } = payload
      const resp = await restClient(apiRoutes.SAVE_DOMAIN_SETTINGS, {
        data: {
          settings: { ldapHighestSerial: { value: '0' } },
          encryption: {},
          ignoreWarnings: false
        },
        urlParams: { domainId }
      })
      dispatch(setSuccessSnackBar({ message: 'ldap_serial_reset_success' }))
      return resp.data
    } catch (e) {
      if (e?.message) {
        logger.error(`Failed to reset LDAP highest seen serial: ${e.message}`)
      }
      dispatch(setErrorSnackBar({ message: 'ldap_serial_reset_failure' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)
