import React, { FC, useMemo } from 'react'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { useRemoveDomainLogic } from 'components/pages/domains/removeDomain/useRemoveDomainLogic'
import { useFormatMessage } from 'lib/localization'

export interface RemoveDomainProps {
  domainId: string | undefined
  isDomainMove: boolean | undefined
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.domains'

export const RemoveDomain: FC<RemoveDomainProps> = ({ domainId, onClose, isDomainMove }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useRemoveDomainLogic({ domainId, onClose, isDomainMove: !!isDomainMove })

  return useMemo(
    () => (
      <WarningDialog
        open={!!domainId}
        title={formatMessage('delete_domain_confirm.title')}
        description={formatMessage(state.dialogDescription)}
        onCancel={eventHandlers.onClose}
        onClose={eventHandlers.onClose}
        onConfirm={state.isConfirmVisible ? eventHandlers.onConfirm : undefined}
        isPending={state.isPending}
      />
    ),
    [domainId, eventHandlers, formatMessage, state]
  )
}
