import { AvailableSettings, SettingsObject } from 'types/Settings'

export const filterSettings = (settings: SettingsObject, keyList: AvailableSettings[]) =>
  Object.keys(settings)
    .filter(key => keyList.includes(key as AvailableSettings))
    .reduce((obj: SettingsObject, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key as AvailableSettings] = settings[key as AvailableSettings]
      return obj
    }, {})
