import { Action, Direction } from 'types/Messages'
import { CategoryTypes } from 'types/categoryTypes'

export enum DashboardRange {
  'last24Hours' = '1',
  'last30Days' = '30'
}

export enum StatType {
  RECIPIENTS = 'recipients',
  SENDERS = 'senders'
}

export enum FileType {
  exe = 'exe',
  msi = 'msi',
  apk = 'apk',
  zip = 'zip',
  rar = 'rar',
  office = 'office',
  blockedAv = 'blocked:av'
}

export type StatsPayloadBase = {
  domainId?: string
  range: DashboardRange
}

export type TopDomainsActions = Action.none | Action.allowed | Action.blocked

export type GeoDataResult = { [key: string]: { count: number } }
export type TopRecipientsDomainResult = {
  domain: string
  count: number
  recipientsCount: number
}

export type EmailStatisticsObject = {
  total: number
  extra: number
  actionCategories: { [key in CategoryTypes]: number }
}

export type EmailStatisticsResult = EmailStatisticsObject & {
  date: string
}

export type TotalThreatsAndVirusesOverviewResult = {
  atdTotal: number
  avTotal: number
}

export type TotalThreatsAndVirusesObject = {
  total: number
  date: string
  fileTypes: { [key in FileType]: number }
}

export interface StatsBase<T> {
  accountId: string
  count: number
  domainId: string
  start: string
  end: string
  results: T
}

export type EmailStatistics = StatsBase<EmailStatisticsResult[]> & {
  direction: Direction
  action: Action
  counts: EmailStatisticsObject
  categoryResults: { [key: string]: number }
}

export type GeoData = StatsBase<GeoDataResult>
export type TopRecipientDomains = StatsBase<TopRecipientsDomainResult[]>
export type TopBlocked = TopRecipientDomains
export type LastBlockedAtp = TopRecipientDomains
export type TotalThreatsAndViruses = {
  accountId: string
  count: number
  domainId: string
  start: string
  end: string
  overviewResults: TotalThreatsAndVirusesOverviewResult
  fileTypeResults: TotalThreatsAndVirusesObject[]
  fileTypeCounts: {
    total: number
    fileTypes: { [key in FileType]: number }
  }
}

export type StatComponentProps = {
  domainId?: string
  range: DashboardRange
}

export interface UniqueDomainRecipientsRequest {
  domainId: string
  page: number
  size: number
  userIdFilter?: string
}

export type UniqueDomainRecipientsResponse = StatsBase<string[]>

export interface DashboardStatsResponse {
  geoData: GeoData | null
  topDomains: TopRecipientDomains
  inboundTopBlocked: TopBlocked
  outboundTopBlocked: TopBlocked
  inboundEmailStatistics: EmailStatistics
  outboundEmailStatistics: EmailStatistics
  lastBlockedAtp: LastBlockedAtp
  totalTheatsAndViruses: TotalThreatsAndViruses
}

interface TopDomainFilter {
  action: TopDomainsActions
}
interface ActionFilter {
  action: Action
}
interface StatsFilter {
  statType: StatType
}
export interface DashboardFilters {
  topDomains: TopDomainFilter
  inboundEmailStatistics: ActionFilter
  inboundTopBlocked: StatsFilter
  outboundEmailStatistics: ActionFilter
  outboundTopBlocked: StatsFilter
}
