import { useCallback, useMemo, useState } from 'react'
import {
  MessageContentFilter,
  OutboundMessageContentFilterAction,
  MessageContentFilterMatch,
  SettingValue
} from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface MessageContentFormState {
  pattern: string
  action: OutboundMessageContentFilterAction
  matchSubject: SettingValue
  matchHeaders: SettingValue
  matchBody: SettingValue
  matchAttachments: SettingValue
  matchSender: SettingValue
  matchRecipient: SettingValue
}

export interface ValidatorDependencies {
  data: MessageContentFilter[]
}

export interface MessageContentFormConfig {
  initialState: MessageContentFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export interface MessageContentForm extends SettingsForm<MessageContentFormState> {
  getStateAsFilter: () => Omit<MessageContentFilter, 'id'>
  errors: {
    pattern: string
    match: string
  }
}

export const useMessageContentForm = ({
  initialState,
  delegateIsDirtyForm
}: MessageContentFormConfig): MessageContentForm => {
  const patternField = useRefField(initialState.pattern)
  const actionField = useStateField(initialState.action)
  const matchSubjectField = useStateField(initialState.matchSubject)
  const matchHeadersField = useStateField(initialState.matchHeaders)
  const matchBodyField = useStateField(initialState.matchBody)
  const matchAttachmentsField = useStateField(initialState.matchAttachments)
  const matchSenderField = useStateField(initialState.matchSender)
  const matchRecipientField = useStateField(initialState.matchRecipient)
  const [patternError, setPatternError] = useState('')
  const [matchError, setMatchError] = useState('')

  const formConfig = useMemo(
    () => ({
      fields: {
        pattern: patternField,
        action: actionField,
        matchSubject: matchSubjectField,
        matchBody: matchBodyField,
        matchHeaders: matchHeadersField,
        matchAttachments: matchAttachmentsField,
        matchSender: matchSenderField,
        matchRecipient: matchRecipientField
      },
      delegateIsDirtyForm
    }),
    [
      actionField,
      delegateIsDirtyForm,
      matchAttachmentsField,
      matchBodyField,
      matchHeadersField,
      matchRecipientField,
      matchSenderField,
      matchSubjectField,
      patternField
    ]
  )
  const form = useSettingsForm<MessageContentFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: MessageContentFilter, dependencies: ValidatorDependencies) => {
      form.setError('')
      setPatternError('')
      setMatchError('')
      let nextPatternError = ''
      let nextMatchError = ''

      const findDuplicate = dependencies.data.find(entry => entry.pattern === newPolicy.pattern)
      if (findDuplicate) {
        nextPatternError = 'duplicate_value'
      }

      if (!newPolicy.pattern) {
        nextPatternError = 'missing_pattern'
      }

      const filteredMatch = Object.keys(newPolicy.match).reduce((perv, curr) => {
        const currentKey = curr as keyof MessageContentFilterMatch
        if (newPolicy.match[currentKey]) {
          // eslint-disable-next-line no-param-reassign
          perv[currentKey] = newPolicy.match[currentKey]
        }
        return perv
      }, {} as MessageContentFilterMatch)

      if (Object.keys(filteredMatch).length === 0) {
        nextMatchError = 'one_match_filter'
      }

      setMatchError(nextMatchError)
      setPatternError(nextPatternError)

      return !nextPatternError && !nextMatchError
    },
    [form]
  )

  const getMatch = useCallback(
    (): MessageContentFilter['match'] => ({
      ...(matchSubjectField.getValue() === SettingValue.ENABLED ? { subject: 1 } : {}),
      ...(matchBodyField.getValue() === SettingValue.ENABLED ? { body: 1 } : {}),
      ...(matchHeadersField.getValue() === SettingValue.ENABLED ? { headers: 1 } : {}),
      ...(matchAttachmentsField.getValue() === SettingValue.ENABLED ? { attachments: 1 } : {}),
      ...(matchSenderField.getValue() === SettingValue.ENABLED ? { sender: 1 } : {}),
      ...(matchRecipientField.getValue() === SettingValue.ENABLED ? { recipient: 1 } : {})
    }),
    [matchAttachmentsField, matchBodyField, matchHeadersField, matchRecipientField, matchSenderField, matchSubjectField]
  )

  const getStateAsFilter = useCallback(
    (): Omit<MessageContentFilter, 'id'> => ({
      pattern: patternField.getValue(),
      action: actionField.getValue(),
      match: getMatch()
    }),
    [actionField, getMatch, patternField]
  )

  return useMemo(
    () => ({
      ...form,
      validate,
      getStateAsFilter,
      errors: {
        pattern: patternError,
        match: matchError
      }
    }),
    [form, getStateAsFilter, matchError, patternError, validate]
  )
}
