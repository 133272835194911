import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  tooltip: {
    background: colors.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    border: `1px solid ${colors.scaleGray300}`
  },
  tooltipWithChart: {
    width: 400,
    background: colors.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    border: `1px solid ${colors.scaleGray300}`
  },
  tooltipTitle: {
    color: colors.scaleGray900
  },
  serieTitle: {
    padding: `${theme.spacing(0.5)}px 0`
  },
  flag: {
    height: theme.spacing(1.5),
    marginRight: theme.spacing(0.75),
    verticalAlign: -1
  }
}))
