import { FocusEvent, MouseEvent } from 'react'

const setCaretPosition = (elemId: string, childNode = 0, position = 0) => {
  const el = document.getElementById(elemId)
  if (el) {
    const range = document.createRange()
    const sel = window.getSelection()
    if (sel && el.childNodes[childNode]) {
      range.setStart(el.childNodes[childNode], position)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}

export const onFocusContentEditable = (e: FocusEvent) => setCaretPosition(e.currentTarget.id)

export const onMouseDownContentEditable = (e: MouseEvent) => {
  const el = document.getElementById(e.currentTarget.id)
  // Only set the caret to top if the email content doesn't have the focus
  // Allow user to position the caret with mouse click
  if (el !== document.activeElement) {
    e.preventDefault()
    setCaretPosition(e.currentTarget.id)
  }
}
