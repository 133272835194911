import { useMemo, useCallback, useState, ChangeEvent, Dispatch, SetStateAction } from 'react'

import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'

import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  AvailableSettings,
  PredefinedFilter,
  OutboundPredefinedFilterAction,
  AVAILABLE_PREDEFINED_FILTER_ACTIONS,
  OutboundPredefinedFilterPattern,
  SettingValue,
  PredefinedFilterMatches
} from 'types/Settings'
import routesConfig from 'lib/routesConfig'

import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'
import {
  PredefinedFilterForm,
  usePredefinedFilterForm
} from 'components/pages/outboundSettings/contentPolicies/usePredefinedFiltersForm'
import { SettingsFormField } from 'lib/settingsForm/useSettingsForm'

export interface State {
  isConfirmDialogOpen: boolean
  sort: SortDescriptor[]
  errors: {
    pattern: string
    match: string
  }
  actionOptions: string[]
  form: PredefinedFilterForm
  formState: Omit<PredefinedFilter, 'id'>
  tableData: {
    total: number
    data: PredefinedFilter[]
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onRemoveConfirm: () => void
  onRemoveCancel: () => void
  onBulkEdit: () => void
  handleSortChange: (e: GridSortChangeEvent) => void
  handleOnCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
}

export type UsePredefinedFiltersTableLogic = [State, EventHandlers]

const INITIAL_FORM_STATE = {
  pattern: OutboundPredefinedFilterPattern.CREDITCARD,
  action: OutboundPredefinedFilterAction.BLOCK,
  matchSubject: SettingValue.ENABLED,
  matchHeaders: SettingValue.ENABLED,
  matchBody: SettingValue.ENABLED,
  matchAttachments: SettingValue.ENABLED
}

const BLANK_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const enum SELECT_NAMES {
  PATTERN = 'pattern',
  ACTION = 'action',
  SUBJECT = 'subject',
  HEADERS = 'headers',
  BODY = 'body',
  ATTACHMENTS = 'attachments'
}

const initialSort = [{ field: 'pattern', dir: 'asc' } as SortDescriptor]

export interface UsePredefinedFiltersTableLogicProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export const usePredefinedFiltersTableLogic = ({
  delegateDirtyFormState
}: UsePredefinedFiltersTableLogicProps): UsePredefinedFiltersTableLogic => {
  const form = usePredefinedFilterForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const dispatch = useAppDispatch()
  const [sort, setSort] = useState(initialSort)
  const [removeId, setRemoveId] = useState<string>('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)

  const { accessTokenObject, accountSettings, domainSettings } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const tableData = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedData = settings.outbound_predefined_message_content_filters
      ? (JSON.parse(settings.outbound_predefined_message_content_filters as string) as PredefinedFilter[])
      : []
    const orderedData = orderBy(parsedData || [], sort)
    const { data } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift(BLANK_ENTRY)
    return {
      data,
      total: data.length || 0
    }
  }, [accessTokenObject, domainSettings, accountSettings, sort])

  const updateData = useCallback(
    (data: PredefinedFilter[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS]: data }
          })
        )
      } else {
        dispatch(
          updateAccountSettings({ settings: { [AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS]: data } })
        )
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(() => {
    const data = tableData.data.filter(entry => entry.id !== '')
    const newEntry: PredefinedFilter = {
      ...form.getStateAsFilter(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newEntry, { data })) {
      updateData([...data, newEntry])
    }
  }, [tableData.data, form, updateData])

  const onRemove = useCallback(
    (id: string) => {
      setRemoveId(id)
      setIsConfirmDialogOpen(true)
    },
    [setRemoveId, setIsConfirmDialogOpen]
  )

  const onRemoveConfirm = useCallback(() => {
    const policies = tableData.data.filter(entry => entry.id !== removeId && entry.id !== '')
    updateData(policies)
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [tableData.data, updateData, removeId])

  const onRemoveCancel = useCallback(() => {
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [setRemoveId, setIsConfirmDialogOpen])

  const onBulkEdit = useCallback(() => {
    routesConfig.OUTBOUND_SETTINGS_PREDEFINED_FILTERS_BULK_EDIT.goto()
  }, [])

  const handleOnCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target
      let field: SettingsFormField | undefined
      // eslint-disable-next-line default-case
      switch (name) {
        case PredefinedFilterMatches.SUBJECT:
          field = form.fields.matchSubject
          break
        case PredefinedFilterMatches.BODY:
          field = form.fields.matchBody
          break
        case PredefinedFilterMatches.HEADERS:
          field = form.fields.matchHeaders
          break
        case PredefinedFilterMatches.ATTACHMENTS:
          field = form.fields.matchAttachments
          break
      }
      if (field) {
        field.setValue(field.getValue() === SettingValue.ENABLED ? SettingValue.DISABLED : SettingValue.ENABLED)
        form.delegateIsDirty()
      }
    },
    [form]
  )

  const handleOnSelectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (typeof e.target.value !== 'string') {
        return
      }
      // eslint-disable-next-line default-case
      switch (e.target.name) {
        case SELECT_NAMES.PATTERN:
          form.fields.pattern.setValue(e.target.value)
          break
        case SELECT_NAMES.ACTION:
          form.fields.action.setValue(e.target.value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  return useMemo(
    () => [
      {
        isConfirmDialogOpen,
        sort,
        errors: form.errors,
        actionOptions: AVAILABLE_PREDEFINED_FILTER_ACTIONS,
        form,
        formState: form.getStateAsFilter(),
        tableData
      },
      {
        onAdd,
        onRemove,
        onRemoveConfirm,
        onRemoveCancel,
        onBulkEdit,
        handleSortChange,
        handleOnSelectChange,
        handleOnCheckboxChange
      }
    ],
    [
      isConfirmDialogOpen,
      sort,
      form,
      tableData,
      onAdd,
      onRemove,
      onRemoveConfirm,
      onRemoveCancel,
      onBulkEdit,
      handleSortChange,
      handleOnSelectChange,
      handleOnCheckboxChange
    ]
  )
}
