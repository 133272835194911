import React, { useMemo } from 'react'
import { ResponsivePie } from '@nivo/pie'

export type LineChartProps = {
  config: any
}

const LineChart = (props: LineChartProps) =>
  useMemo(
    () => (
      <ResponsivePie
        data={[]}
        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        colors={{ scheme: 'nivo' }}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]]
        }}
        {...props.config}
      />
    ),
    [props]
  )

export default LineChart
