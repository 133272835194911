import { ChangeEvent, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { process } from '@progress/kendo-data-query'

import { CSVLink } from 'react-csv'
import { GridPageChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'

import { ColumnsConfig } from 'types/redux/dataTables/dataTables'
import {
  getAtpFiletypes,
  getAtpOverview,
  getAtpReport,
  getAtpSearch,
  setSearchTerm,
  reset
} from 'redux/features/atp/atpSlice'
import { getAvailableDomains, resetGetAvailableDomains } from 'redux/features/user/userSlice'
import { update as updateAtpLogTable, reset as resetAtpLogTable } from 'redux/features/dataTables/atp/atpSlice'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'

import { ATP_FILE_TYPES, AtpLog, ModifiedAtpLog, FormattedAtpFileTypes } from 'types/Atp'
import { AtpLogSearchParams, AtpLogSearchPayload } from 'redux/features/atp/atpApiThunks'
import { atpIsFuture, atpRisk, atpStatus, atpStatusIcon } from 'lib/atp'
import routesConfig from 'lib/routesConfig'
import { useFormatMessage } from 'lib/localization'

interface SelectItem {
  value: string
  label: string
}
export interface UseAtpLogLogic {
  inProgress: boolean
  isSuccess: boolean
  isFailed: boolean
  isLogInProgress: boolean
  isOverviewInProgress: boolean
  loadedAndNoRecord: boolean
  domainConfig: {
    items: SelectItem[]
    selectedItem: string | undefined
    onSelect: (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => void
    disabled: boolean
  }
  infectedCount: number
  suspiciousCount: number
  exportConfig: {
    exportRef: MutableRefObject<(CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null>
    fileName: () => string
    headers: {
      label: string
      key: string
    }[]
    csvExport: () => void
  }
  searchConfig: {
    search: string
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    fetchData: (payload?: AtpLogSearchParams) => void
    handlePageChange: (e: GridPageChangeEvent) => void
  }
  filterConfig: {
    filetypes: FormattedAtpFileTypes[]
    selectedFiletype: ATP_FILE_TYPES
    timeFilters: number[]
    selectedTimeFilter: number
    statusFilters: string[]
    selectedStatusFilter: string
    handleFilterChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  }
  tableConfig: {
    tableData: {
      skip: number
      take: number
      total: number
      data: ModifiedAtpLog[]
    }
    columns: { [key: string]: string }
    columnsConfig: ColumnsConfig
  }
  handleRowClick: (item: ModifiedAtpLog) => void
  reportConfig: {
    onViewReport: (item: ModifiedAtpLog) => void
    isLoading: boolean
  }
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

const STATUS_FILTERS = ['all', 'clean', 'suspicious', 'virus']
const TIME_FILTERS = [30, 7, 1]
const BASE_I18N_KEY = 'ess.atp_log'

export const useAtpLogLogic = (): UseAtpLogLogic => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()

  const {
    accessTokenObject,
    atpTable,
    atpLog,
    atpOverview,
    atpFiletypes,
    searchTerm,
    atpLogSuccess,
    atpLogPending,
    atpLogFailed,
    atpOverviewPending,
    atpOverviewFailed,
    atpFiletypesPending,
    atpFiletypesSuccess,
    atpFiletypesFailed,
    availableDomains,
    availableDomainsPending,
    availableDomainsSuccess,
    availableDomainsFailed,
    atpReportPending
  } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject,
    atpTable: _store.dataTables.atp,
    atpLog: _store.atp.search,
    atpOverview: _store.atp.overview,
    atpFiletypes: _store.atp.filetypes,
    searchTerm: _store.atp.searchTerm,
    availableDomains: _store.user.availableDomains,
    availableDomainsPending: isPending(_store.user.api.getAvailableDomainsApiStatus),
    availableDomainsSuccess: isSuccess(_store.user.api.getAvailableDomainsApiStatus),
    availableDomainsFailed: isFailed(_store.user.api.getAvailableDomainsApiStatus),
    atpLogSuccess: isSuccess(_store.atp.getAtpLogStatus),
    atpLogPending: isPending(_store.atp.getAtpLogStatus),
    atpLogFailed: isFailed(_store.atp.getAtpLogStatus),
    atpOverviewPending: isPending(_store.atp.getAtpOverviewStatus),
    atpOverviewFailed: isFailed(_store.atp.getAtpOverviewStatus),
    atpFiletypesPending: isPending(_store.atp.getAtpFiletypesStatus),
    atpFiletypesSuccess: isSuccess(_store.atp.getAtpFiletypesStatus),
    atpFiletypesFailed: isFailed(_store.atp.getAtpFiletypesStatus),
    atpReportPending: isPending(_store.atp.getAtpReportStatus)
  }))

  const exportRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null)
  const [selectedDomain, setSelectedDomain] = useState<string | undefined>()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const filetypes = useMemo(() => {
    const allFiles: FormattedAtpFileTypes = {
      key: ATP_FILE_TYPES.all,
      label: formatMessage('all')
    }
    const fileTypeFormatted: FormattedAtpFileTypes[] = atpFiletypes.map(filetype => ({
      key: filetype,
      label: ATP_FILE_TYPES[filetype]
    }))
    return [allFiles, ...fileTypeFormatted]
  }, [atpFiletypes, formatMessage])

  const fetchData = useCallback(
    (payload: AtpLogSearchPayload) => {
      dispatch(getAtpSearch(payload))
    },
    [dispatch]
  )

  // init
  useEffect(() => {
    dispatch(getAvailableDomains())
    dispatch(getAtpFiletypes())
    // eslint-disable-next-line
  }, [])

  // unmount
  useEffect(
    () => () => {
      dispatch(reset())
      dispatch(resetGetAvailableDomains())
      dispatch(resetAtpLogTable())
    },
    [dispatch]
  )

  // initial domains list is arrived and pdDomain is set
  useEffect(() => {
    if (availableDomainsSuccess) {
      if (!!availableDomains?.length && !selectedDomain) {
        let initialDomain
        // pd-domain-id is set
        if (accessTokenObject?.pdDomainId) {
          initialDomain = availableDomains.find(domain => domain.domainId === accessTokenObject.pdDomainId)
          // account has only one domain
        } else if (availableDomains.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          initialDomain = availableDomains[0]
        }

        if (initialDomain) {
          setSelectedDomain(initialDomain.domainId)
        } else if (availableDomains.length > 1) {
          setSelectedDomain('all')
        }
      }
    }
  }, [dispatch, fetchData, atpTable, availableDomainsSuccess, availableDomains, selectedDomain, accessTokenObject])

  useEffect(() => {
    if (selectedDomain) {
      dispatch(getAtpOverview({ domainId: selectedDomain }))
    }
  }, [dispatch, selectedDomain])

  useEffect(() => {
    if (selectedDomain) {
      dispatch(getAtpSearch({ domainId: selectedDomain }))
    }
    // eslint-disable-next-line
  }, [dispatch, selectedDomain, atpTable.skip, atpTable.take, searchTerm])

  const handlePageChange = useCallback(
    (e: GridPageChangeEvent) => {
      const { take, skip } = e.page
      dispatch(updateAtpLogTable({ skip, take }))
    },
    [dispatch]
  )

  const onSelectDomain = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const domainId = event.target.value as string
      setSelectedDomain(domainId)
    },
    []
  )

  const handleFilterChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = event.target
      // The button's value is always string we need to convert it to number for the time filter
      dispatch(setSearchTerm({ [name as string]: name === 'time' ? Number(value) : value }))
    },
    [dispatch]
  )

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setSearchTerm({ searchStr: e.target.value }))
    },
    [dispatch]
  )

  const domains = useMemo(() => {
    const listDomains = (availableDomains || []).map(domain => ({ value: domain.domainId, label: domain.domainName }))
    if (listDomains.length > 1) {
      listDomains.unshift({ value: 'all', label: 'all_domains' })
    }
    return listDomains
  }, [availableDomains])

  const tableData = useMemo(() => {
    const { take, skip } = atpTable
    const { data } = process(
      (atpLog.result || []).map((report: AtpLog) => ({
        ...(report && {
          ...report,
          isFuture: atpIsFuture(atpStatus(report.status), report?.determination),
          formattedDate: formatDate(new Date(report.created), config.DATETIME.ATP_LOG_DATE_WITH_TIME_FORMAT),
          statusText: atpStatus(report.status),
          originText: report.origin === 'MX' ? 'Automated' : 'Manual',
          riskText: atpRisk(report.risk),
          statusIcon: atpStatusIcon(atpStatus(report.status))
        })
      })),
      { skip: 0, take }
    )

    return {
      data,
      total: atpLog.count || 0,
      // this does NOT come from "DataTableState"
      skip: skip || 0,
      take: take || 0
    }
  }, [atpLog, atpTable])

  const exportHeaders = useMemo(
    () => [
      { label: 'status', key: 'statusText' },
      { label: 'origin', key: 'originText' },
      { label: 'fileName', key: 'filename' },
      { label: 'fileType', key: 'fileType' },
      { label: 'messageId', key: 'mid' },
      { label: 'from', key: 'headerFrom' },
      { label: 'to', key: 'headerTo' },
      { label: 'time', key: 'formattedDate' }
    ],
    []
  )

  const exportFileName = useCallback(() => {
    const currentDate = formatDate(new Date(), config.DATETIME.DASH_SEPARATED_DATE_WITHOUT_TIME)
    return `atd_log_${currentDate}.csv`
  }, [])

  const csvExport = () => {
    if (exportRef?.current) {
      exportRef.current.link?.click()
    }
  }

  const handleRowClick = useCallback((message: ModifiedAtpLog) => {
    const path = `${routesConfig.MESSAGE_DETAIL.url({ messageId: message.mid, domainId: message.domainId.toString() })}`
    window.open(`${window.location.origin}${path}`, '_blank')
  }, [])

  const onViewReport = useCallback(
    (item: ModifiedAtpLog) => {
      dispatch(getAtpReport({ cuid: item.cuid }))
    },
    [dispatch]
  )

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  return useMemo(
    () => ({
      inProgress: atpFiletypesPending || availableDomainsPending,
      isSuccess: atpFiletypesSuccess && availableDomainsSuccess && !!selectedDomain,
      isFailed:
        atpLogFailed ||
        atpOverviewFailed ||
        atpFiletypesFailed ||
        availableDomainsFailed ||
        (availableDomainsSuccess && !selectedDomain),
      isLogInProgress: atpLogPending,
      isOverviewInProgress: atpOverviewPending,
      loadedAndNoRecord: atpLogSuccess && !tableData.data.length,
      domainConfig: {
        items: domains,
        selectedItem: selectedDomain,
        onSelect: onSelectDomain,
        disabled: !!accessTokenObject?.pdDomainId
      },
      infectedCount: atpOverview.infectedCount,
      suspiciousCount: atpOverview.suspiciousCount,
      exportConfig: {
        exportRef,
        fileName: exportFileName,
        headers: exportHeaders,
        csvExport
      },
      searchConfig: {
        search: searchTerm.searchStr,
        handleInputChange,
        handlePageChange,
        fetchData
      },
      filterConfig: {
        filetypes,
        selectedFiletype: searchTerm.filetype,
        timeFilters: TIME_FILTERS,
        selectedTimeFilter: searchTerm.time,
        statusFilters: STATUS_FILTERS,
        selectedStatusFilter: searchTerm.status,
        handleFilterChange
      },
      tableConfig: {
        tableData,
        columns: atpTable.GRID_COLUMNS,
        columnsConfig: atpTable.columnsConfig
      },
      handleRowClick,
      reportConfig: {
        onViewReport,
        isLoading: atpReportPending
      },
      helpConfig: {
        isOpen: isHelpDialogOpened,
        onHelpClick,
        onCloseHelp
      }
    }),
    [
      accessTokenObject,
      selectedDomain,
      onSelectDomain,
      handleInputChange,
      fetchData,
      handlePageChange,
      tableData,
      atpTable,
      atpOverview,
      filetypes,
      onViewReport,
      atpReportPending,
      searchTerm,
      exportFileName,
      exportHeaders,
      handleFilterChange,
      domains,
      handleRowClick,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      atpLogSuccess,
      atpLogPending,
      atpLogFailed,
      atpOverviewPending,
      atpOverviewFailed,
      atpFiletypesPending,
      atpFiletypesSuccess,
      atpFiletypesFailed,
      availableDomainsPending,
      availableDomainsSuccess,
      availableDomainsFailed
    ]
  )
}
