import { useCallback, useMemo } from 'react'
import { IntentDomainPolicies, LinkedDomainPolicy } from 'types/AntiPhishing'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface LinkedDomainPoliciesFormState {
  domain: string
  policy: IntentDomainPolicies
  comment: string
}

export interface ValidatorDependencies {
  data: LinkedDomainPolicy[]
}

export interface LinkedDomainPoliciesFormConfig {
  initialState: LinkedDomainPoliciesFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type LinkedDomainPoliciesForm = SettingsForm<LinkedDomainPoliciesFormState>

export const useLinkedDomainPoliciesForm = ({
  initialState,
  delegateIsDirtyForm
}: LinkedDomainPoliciesFormConfig): LinkedDomainPoliciesForm => {
  const domainField = useRefField(initialState.domain)
  const commentField = useRefField(initialState.comment)
  const policyField = useStateField(initialState.policy)

  const formConfig = useMemo(
    () => ({
      fields: {
        domain: domainField,
        comment: commentField,
        policy: policyField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, domainField, policyField]
  )
  const form = useSettingsForm<LinkedDomainPoliciesFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: LinkedDomainPolicy, dependencies: ValidatorDependencies) => {
      form.setError('')

      const findDuplicate = dependencies.data.find(entry => entry.domain === newPolicy.domain)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      if (!newPolicy.domain) {
        form.setError('specify_domain')
        return false
      }

      const regex = /^[a-z0-9.-]{1,255}$/i
      if (!regex.test(newPolicy.domain)) {
        form.setError('invalid_domain')
        return false
      }

      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
