import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundCustomRbls(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      External IP block lists, also known as DNSBLs or RBLs, are lists of Internet addresses that have been identified
      as potential originators of spam. External IP block lists are efficient and an important part of the spam blocking
      process. In conjunction with the{' '}
      <a href="https://www.barracudacentral.org/rbl" target="_new">
        Barracuda Reputation Block List (BRBL)
      </a>
      , {props.productName} uses these lists to verify the authenticity of the messages you receive. IP Reputation
      checks are only performed on inbound email traffic. All allow lists override external RBLs.
      <p>
        Several organizations maintain external block lists; if you are using a paid or free external block list you can
        leverage the block list if you are within the terms of service. Adding a free RBL service will often not work
        when added to {props.productName}. A paid subscription to a RBL service is required to use their service. This
        subscription will provide you with your own custom RBL hostname that you can add to {props.productName}.
      </p>
      <p>
        Add any additional free or subscription block lists. The table in this page can be sorted by clicking on one of
        the following column titles:
      </p>
      <ul>
        <li>RBL Zone</li>
        <li>Comment</li>
      </ul>
      <br />
      <br />
      <b>Warning:</b>
      Use of any block list may occasionally generate &quot;false positives&quot; (legitimate messages that are
      blocked). Messages blocked due to external block lists or the BRBL are the only blocked messages that are not sent
      to the user&apos;s Message Log.
    </div>
  )
}

export default InboundCustomRbls
