import React, { useMemo } from 'react'

import { Typography, TextareaAutosize, Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/outboundSettings/senderIp/SenderIpBulkEdit/senderIpBulkEditStyles'
import { useSenderIpBulkEditLogic } from 'components/pages/outboundSettings/senderIp/SenderIpBulkEdit/useSenderIpBulkEditLogic'
import SettingsHeader from 'components/libs/settingsPage/SettingsHeader'
import DomainConflictDialog from 'components/libs/domainConflictDialog/DomainConflictDialog'
import BlockNavigationDialog from 'components/libs/blockNavigationDialog/BlockNavigationDialog'
import PageLoader from 'components/libs/pageLoader/PageLoader'

const BASE_I18N_KEY = 'ess.settings.bulk_edit'

const SenderIpBulkEdit: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useSenderIpBulkEditLogic()

  return useMemo(
    () => (
      <Grid container direction="column" data-testid="settings-page">
        <SettingsHeader
          title={formatMessage('sender_ip_title')}
          actions={{
            cancelDisabled: false,
            onSave: eventHandlers.onSave,
            onCancelConfirm: eventHandlers.onCancelConfirm,
            hasPageChanges: state.hasPageChanges
          }}
        />
        <PageLoader isLoading={state.isLoading} isFailed={state.isFailed} isSuccess={state.isSuccess}>
          <Grid data-testid="admin-bulk-edit-content">
            <Typography variant="body2" gutterBottom data-testid="subtitle">
              {formatMessage('sub_title')}
            </Typography>
            <Typography className={classes.contentHeader} variant="h6" gutterBottom data-testid="content-header">
              {formatMessage('content_header')}
            </Typography>
            <Typography variant="body2" gutterBottom data-testid="content-subheader">
              {formatMessage('content_sub_header')}
            </Typography>
            <Typography className={classes.warning} variant="body2" gutterBottom data-testid="content-subtitle">
              {formatMessage('warning')}
            </Typography>
            <TextareaAutosize
              minRows={16}
              maxRows={16}
              className={classes.textArea}
              placeholder={formatMessage('place_holder')}
              value={state.rawData}
              onChange={eventHandlers.onChange}
              data-testid="textarea"
            />
          </Grid>
        </PageLoader>
        <BlockNavigationDialog hasChanges={state.hasPageChanges} />
        <DomainConflictDialog />
      </Grid>
    ),
    [classes, formatMessage, state, eventHandlers]
  )
}

export default SenderIpBulkEdit
