import React, { useMemo } from 'react'

import { Button, Grid, MenuItem, Select, Typography } from '@barracuda-internal/bds-core'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { FormSpacer, PageTitle } from 'components/libs/layout/pages/pageLayout'
import InboundRecipientPolicies from 'components/pages/support/help/inboundRecipientPolicies/InboundRecipientPolicies'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import {
  RECIPIENT_SELECT_NAMES,
  useRecipientPoliciesLogic
} from 'components/pages/inboundSettings/recipientPolicies/useRecipientPoliciesLogic'
import { RecipientPolicyStatus } from 'types/Settings'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import RecipientPoliciesTable from './RecipientPoliciesTable'

const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const BASE_I18N_KEY = 'ess.inbound_settings.recipient_policies'

const RecipientPolicies: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)
  const [state, eventHandlers] = useRecipientPoliciesLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.isDisabledNavigation}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundRecipientPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.recipientPolicies,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <Grid container>
          <Grid container alignItems="center">
            <Typography variant="body1">{formatMessage('default_policy_description')}</Typography>
            <Select
              variant="outlined"
              className={inboundClasses.headerDropdown}
              name={RECIPIENT_SELECT_NAMES.DEFAULT_POLICY}
              value={state.defaultPolicy}
              onChange={eventHandlers.handleOnSelectChange}
              SelectDisplayProps={{ style: { padding: 8 } }}
              data-testid="default-policy-field"
            >
              {Object.keys(RecipientPolicyStatus).map(policyValue => (
                <MenuItem key={policyValue} value={policyValue}>
                  {formatMessage(policyValue)}
                </MenuItem>
              ))}
            </Select>
            <Button
              color="primary"
              onClick={() => {
                eventHandlers.onSave()
              }}
              variant="contained"
              data-testid="save-button"
              disabled={state.isDisabledSubmit}
            >
              {formatMessage('save')}
            </Button>
          </Grid>
          <Grid container>
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEdit}
              variant="contained"
              size="small"
              data-testid="bulk-button"
            >
              {formatInboundMessage('bulk_edit')}
            </Button>
          </Grid>
          <RecipientPoliciesTable
            data={state.data}
            onAddItem={eventHandlers.onAdd}
            onRemoveItem={eventHandlers.onRemove}
            delegateDirtyFormState={eventHandlers.setIsPoliciesTableDirty}
          />
        </Grid>
      </SettingsPageContainer>
    ),
    [state, formatMessage, eventHandlers, inboundClasses, formatInboundMessage]
  )
}

export default RecipientPolicies
