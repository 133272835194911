import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import {
  deleteScheduledReport,
  getScheduledReportList,
  resetScheduledReportsList,
  resetScheduledReportUpdate,
  updateScheduledReport
} from 'redux/features/reports/reportsSlice'
import { ParsedScheduleReport, ScheduledReportStatus } from 'types/reports'
import { formatDate } from 'lib/datetime'
import config from 'config/appConfig'
import { useFormatMessage } from 'lib/localization'

export interface State {
  isLoading: boolean
  isGetScheduledReportsListFailed: boolean
  scheduledReports: ParsedScheduleReport[]
  search: string
  isScheduleReportOpen: boolean
  editItem?: ParsedScheduleReport
  anchorEl: HTMLButtonElement | null
  menuItems: {
    label: string
    onClick: () => void
  }[]
}

export interface EventHandlers {
  onSearch: Dispatch<SetStateAction<string>>
  onSchedule: (item?: ParsedScheduleReport) => void
  onCloseSchedule: () => void
  onOpenDotsMenu: (event: React.MouseEvent<HTMLButtonElement>, selectedReport: ParsedScheduleReport) => void
  onCloseDotsMenu: () => void
}

export enum MENU_ITEMS {
  enableSchedule = 'enable_schedule',
  disableSchedule = 'disable_schedule',
  deleteSchedule = 'delete_schedule',
  openReport = 'open_report'
}

const BASE_I18N_KEY = 'ess.reports.scheduled_reports'

export const useScheduledReportsLogic = (): [State, EventHandlers] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()
  const {
    isGetScheduledReportsListInProgress,
    isGetScheduledReportsListIsFailed,
    isScheduleReportSuccess,
    isUpdateScheduledReportSuccess,
    isUpdateScheduledReportPending,
    isDeleteScheduledReportSuccess,
    isDeleteScheduledReportPending,
    scheduledReports
  } = useAppSelector(_store => ({
    isGetScheduledReportsListInProgress: isPending(_store.reports.api.getScheduledReportsListApiStatus),
    isGetScheduledReportsListIsFailed: isFailed(_store.reports.api.getScheduledReportsListApiStatus),
    isScheduleReportSuccess: isSuccess(_store.reports.api.scheduleReportApiStatus),
    isUpdateScheduledReportSuccess: isSuccess(_store.reports.api.updateScheduledReportApiStatus),
    isUpdateScheduledReportPending: isPending(_store.reports.api.updateScheduledReportApiStatus),
    isDeleteScheduledReportSuccess: isSuccess(_store.reports.api.deleteScheduledReportApiStatus),
    isDeleteScheduledReportPending: isPending(_store.reports.api.deleteScheduledReportApiStatus),
    scheduledReports: _store.reports.scheduledList
  }))
  const [search, setSearch] = useState<string>('')
  const [isScheduleReportOpen, setIsScheduleReportOpen] = useState(false)
  const [editItem, setEditItem] = useState<ParsedScheduleReport | undefined>(undefined)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuItems, setMenuItems] = useState<any>([])

  // init
  useEffect(() => {
    dispatch(getScheduledReportList())

    return () => {
      dispatch(resetScheduledReportsList())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scheduledReportsFiltered: ParsedScheduleReport[] = useMemo(
    () =>
      scheduledReports.reduce((perv, current) => {
        if (!current.name.toLowerCase().includes(search.toLowerCase())) {
          return perv
        }

        const formattedDate = formatDate(new Date(current.nextSchedule), config.DATETIME.DEFAULT_DATE_FORMAT)
        const formattedHours = formatDate(new Date(current.nextSchedule), config.DATETIME.REPORT_TIME_FORMAT)
        const formattedLastDate = current.lastSchedule
          ? formatDate(new Date(current.lastSchedule), config.DATETIME.DEFAULT_DATE_FORMAT)
          : null
        const formattedLastHours = current.lastSchedule
          ? formatDate(new Date(current.lastSchedule), config.DATETIME.REPORT_TIME_FORMAT)
          : null

        let formattedRecipients = current.recipients.split(',')
        if (formattedRecipients.length > 11) {
          const hiddenNumber = formattedRecipients.length - 10
          formattedRecipients = formattedRecipients.splice(0, 10)
          formattedRecipients.push(`+ ${hiddenNumber} ${formatMessage('more')}`)
        }

        perv.push({
          ...current,
          formattedDate,
          formattedHours,
          formattedLastDate,
          formattedLastHours,
          formattedRecipients
        })

        return perv
      }, [] as ParsedScheduleReport[]),
    [search, scheduledReports, formatMessage]
  )

  const onSchedule = useCallback((item?: ParsedScheduleReport) => {
    setIsScheduleReportOpen(true)
    if (item) {
      setEditItem(item)
    } else {
      setEditItem(undefined)
    }
  }, [])

  const onCloseSchedule = useCallback(() => {
    setIsScheduleReportOpen(false)
  }, [])

  const onOpenDotsMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, selectedReport: ParsedScheduleReport) => {
      setAnchorEl(event.currentTarget)
      setMenuItems([
        {
          label:
            selectedReport.status === ScheduledReportStatus.disabled
              ? MENU_ITEMS.enableSchedule
              : MENU_ITEMS.disableSchedule,
          onClick: () => {
            dispatch(
              updateScheduledReport({
                ...selectedReport,
                status:
                  selectedReport.status === ScheduledReportStatus.disabled
                    ? ScheduledReportStatus.enabled
                    : ScheduledReportStatus.disabled
              })
            )
            setAnchorEl(null)
          }
        },
        {
          label: MENU_ITEMS.deleteSchedule,
          onClick: () => {
            dispatch(deleteScheduledReport({ id: selectedReport.id }))
            setAnchorEl(null)
          }
        },
        {
          label: MENU_ITEMS.openReport,
          onClick: () => {
            // eslint-disable-next-line no-console
            console.log('Open report')
          }
        }
      ])
    },
    [dispatch]
  )

  const onCloseDotsMenu = useCallback(() => setAnchorEl(null), [])

  useEffect(() => {
    if (isUpdateScheduledReportSuccess || isDeleteScheduledReportSuccess || isScheduleReportSuccess) {
      dispatch(resetScheduledReportUpdate())
      dispatch(getScheduledReportList())
    }
  }, [dispatch, isUpdateScheduledReportSuccess, isDeleteScheduledReportSuccess, isScheduleReportSuccess])

  return useMemo(
    () => [
      {
        isLoading:
          isGetScheduledReportsListInProgress || isUpdateScheduledReportPending || isDeleteScheduledReportPending,
        isGetScheduledReportsListFailed: isGetScheduledReportsListIsFailed,
        scheduledReports: scheduledReportsFiltered,
        search,
        isScheduleReportOpen,
        editItem,
        anchorEl,
        menuItems
      },
      {
        onSearch: setSearch,
        onSchedule,
        onCloseSchedule,
        onOpenDotsMenu,
        onCloseDotsMenu
      }
    ],
    [
      isGetScheduledReportsListInProgress,
      isGetScheduledReportsListIsFailed,
      isUpdateScheduledReportPending,
      isDeleteScheduledReportPending,
      scheduledReportsFiltered,
      search,
      isScheduleReportOpen,
      editItem,
      anchorEl,
      menuItems,
      setSearch,
      onSchedule,
      onCloseSchedule,
      onOpenDotsMenu,
      onCloseDotsMenu
    ]
  )
}
