import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles({
  textArea: {
    width: '100%',
    marginTop: '20px'
  },
  warning: {
    color: colors.red500
  },
  contentHeader: {}
})
