import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SelfService2(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      Enter your mail server IP address or hostname (FQDN) and click <b>Add</b>.
      <p>
        If {props.productName} Setup wizard has already identified your mail server IP based on the MX record, it will
        prepopulate the <b>Mail Servers</b>.
      </p>
      Before proceeding to the next step, ensure that all the mail servers you&apos;re using can verify the test email
      address. After entering a valid email address for your domain, click <b>Test All Mail Servers</b>.
      <p>
        To skip this step and enter your mail server information later on the <b>DOMAINS &gt; Domains Manager</b> page,
        click the <b>Skip</b> button.
      </p>
    </div>
  )
}

export default SelfService2
