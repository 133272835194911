import { createStyles, makeStyles, colors } from '@barracuda-internal/bds-core'
import { commonStyles } from 'components/pages/onboardWizard/onboardWizardStyles'

const NAV_WIDTH = 320
const CONTENT_WIDTH = 800
const STEP_INDICATOR_WIDTH = 60
const SECTION_HEADING_WIDTH = 570

export default makeStyles(theme => {
  const DEFAULT_STYLES = createStyles({
    '@global': commonStyles['@global'],
    navItem: {
      height: theme.spacing(6)
    },
    navItemText: {
      fontSize: 15,
      fontWeight: 600
    },
    stepIndicatorIcon: {
      color: colors.white,
      fontSize: 16,
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
      boxShadow: 'none',
      pointerEvents: 'none'
    }
  })

  return createStyles({
    title: commonStyles.title,
    subtitle: commonStyles.subtitle,
    container: {
      marginBottom: theme.spacing(10),
      '@media screen and (min-width:1181px)': {
        marginTop: theme.spacing(3)
      }
    },
    navWrapper: {
      padding: 0,
      '@media screen and (min-width:1181px)': {
        width: NAV_WIDTH,
        zIndex: 1
      },
      '@media screen and (max-width:1180px)': {
        height: 48,
        zIndex: 1
      }
    },
    navInnerWrapper: {
      position: 'fixed',
      '@media screen and (min-width:1181px)': {
        width: NAV_WIDTH
      }
    },
    successNavIcon: {
      color: colors.forest600
    },
    erroredNavIcon: {
      color: colors.red600
    },
    navList: {
      padding: 0,
      color: colors.scaleGray600,
      '@media screen and (max-width:1180px)': {
        display: 'flex',
        flex: 1,
        backgroundColor: colors.backgroundLight,
        width: '100%'
      }
    },
    navItemText: {
      ...DEFAULT_STYLES.navItemText,
      '@media screen and (max-width:1180px)': {
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    },
    navItemIcon: {
      paddingLeft: theme.spacing(2.5)
    },
    navItem: {
      ...DEFAULT_STYLES.navItem,
      '@media screen and (max-width:1180px)': {
        '& div': {
          padding: 0
        },
        textAlign: 'center',
        backgroundColor: colors.backgroundLight,
        overflow: 'hidden',
        flex: 1
      }
    },
    activeNavItem: {
      ...DEFAULT_STYLES.navItem,
      backgroundColor: 'inherit !important',
      '@media screen and (min-width:1181px)': {
        borderRight: `2px solid ${colors.blue600}`
      },
      '@media screen and (max-width:1180px)': {
        borderBottom: `2px solid ${colors.blue600}`,
        textAlign: 'center',
        overflow: 'hidden',
        flex: 1,
        '& div': {
          padding: 0
        }
      }
    },
    activeNavItemText: {
      ...DEFAULT_STYLES.navItemText,
      color: colors.blue600,
      '@media screen and (max-width:1180px)': {
        wordBreak: 'break-all',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    },
    contentWrapper: {
      width: CONTENT_WIDTH,
      '@media screen and (min-width:1181px)': {
        marginLeft: theme.spacing(6)
      },
      '@media screen and (max-width:1180px)': {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    },
    sectionWrapper: {
      marginTop: theme.spacing(6),
      '@media screen and (max-width:680px)': {
        flexWrap: 'nowrap'
      }
    },
    sectionHeading: {
      width: SECTION_HEADING_WIDTH,
      '@media screen and (max-width:680px)': {
        width: 'auto'
      }
    },
    sectionTitle: {
      ...commonStyles.title,
      marginBottom: theme.spacing(0.5)
    },
    sectionSubtitle: commonStyles.subtitle,
    stepIndicatorWrapper: {
      width: STEP_INDICATOR_WIDTH
    },
    stepIndicatorIcon: {
      ...DEFAULT_STYLES.stepIndicatorIcon,
      backgroundColor: colors.scaleGray600
    },
    stepIndicatorIconSuccess: {
      ...DEFAULT_STYLES.stepIndicatorIcon,
      backgroundColor: colors.forest600
    },
    sectionContentWrapper: {
      marginTop: theme.spacing(3)
    },
    buttonsContainer: {
      marginTop: theme.spacing(6)
    },
    saveAndExitButton: {
      marginRight: theme.spacing(3)
    }
  })
})
