import { createSlice } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import {
  postAzureAdSyncNow,
  getAzureAdSyncStats,
  getAzureAdTenantStatus,
  TenantStatusResponse,
  getAzureAdAdminConsent,
  postAzureAdDisconnect,
  getAzureAdLookupUser,
  getAzureAdLookupUserSignedUrl
} from 'redux/features/azureAd/azureAdApiThunks'
import { AzureUserStatus, DisconnectResult, SyncNowStatus, SyncStats } from 'types/azureAd'

export interface AzureAdState {
  api: {
    getAzureAdTenantStatus: ApiStatus
    getAzureAdAdminConsent: ApiStatus
    postAzureAdDisconnect: ApiStatus
    getAzureAdSyncStats: ApiStatus
    postAzureAdSyncNow: ApiStatus
    getAzureAdLookupUserSignedUrl: ApiStatus
    getAzureAdLookupUser: ApiStatus
  }
  azureAdTenantStatus: TenantStatusResponse | undefined
  azureAdDisconnect: DisconnectResult | undefined
  azureAdSyncStats: SyncStats | undefined
  azureAdSyncNow: SyncNowStatus | undefined
  azureAdLookupUserSignedUrl: string | undefined
  azureAdLookupUser: AzureUserStatus | undefined
}

// initialState
export const INITIAL_STATE: AzureAdState = {
  api: {
    getAzureAdTenantStatus: inIdle,
    getAzureAdAdminConsent: inIdle,
    postAzureAdDisconnect: inIdle,
    getAzureAdSyncStats: inIdle,
    postAzureAdSyncNow: inIdle,
    getAzureAdLookupUserSignedUrl: inIdle,
    getAzureAdLookupUser: inIdle
  },
  azureAdTenantStatus: undefined,
  azureAdDisconnect: undefined,
  azureAdSyncStats: undefined,
  azureAdSyncNow: undefined,
  azureAdLookupUserSignedUrl: undefined,
  azureAdLookupUser: undefined
}

/* eslint-disable no-param-reassign */
export const azureAdSlice = createSlice({
  name: 'AZURE_AD',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetSyncStats: state => {
      state.api.getAzureAdSyncStats = INITIAL_STATE.api.getAzureAdSyncStats
      state.azureAdSyncStats = INITIAL_STATE.azureAdSyncStats
    },
    resetSyncNow: state => {
      state.api.postAzureAdSyncNow = INITIAL_STATE.api.postAzureAdSyncNow
      state.azureAdSyncNow = INITIAL_STATE.azureAdSyncNow
    },
    resetLookupUserSignedUrl: state => {
      state.api.getAzureAdLookupUserSignedUrl = INITIAL_STATE.api.getAzureAdLookupUserSignedUrl
      state.azureAdLookupUserSignedUrl = INITIAL_STATE.azureAdLookupUserSignedUrl
    },
    resetAzureAdTenantStatus: state => {
      state.api.getAzureAdTenantStatus = INITIAL_STATE.api.getAzureAdTenantStatus
      state.azureAdTenantStatus = INITIAL_STATE.azureAdTenantStatus
    }
  },
  extraReducers: builder => {
    builder
      // getAzureAdTenantStatus
      .addCase(getAzureAdTenantStatus.pending, state => {
        state.api.getAzureAdTenantStatus = inProgress
      })
      .addCase(getAzureAdTenantStatus.fulfilled, (state, action) => {
        state.api.getAzureAdTenantStatus = successResponse
        state.azureAdTenantStatus = action.payload
      })
      .addCase(getAzureAdTenantStatus.rejected, (state, action) => {
        state.api.getAzureAdTenantStatus = failedResponse(action.payload)
      })

      // postAzureAdAdminConsent
      .addCase(getAzureAdAdminConsent.pending, state => {
        state.api.getAzureAdAdminConsent = inProgress
      })
      .addCase(getAzureAdAdminConsent.fulfilled, state => {
        state.api.getAzureAdAdminConsent = successResponse
      })
      .addCase(getAzureAdAdminConsent.rejected, (state, action) => {
        state.api.getAzureAdAdminConsent = failedResponse(action.payload)
      })

      // postAzureAdDisconnect
      .addCase(postAzureAdDisconnect.pending, state => {
        state.api.postAzureAdDisconnect = inProgress
      })
      .addCase(postAzureAdDisconnect.fulfilled, (state, action) => {
        state.api.postAzureAdDisconnect = successResponse
        state.azureAdDisconnect = action.payload
      })
      .addCase(postAzureAdDisconnect.rejected, (state, action) => {
        state.api.postAzureAdDisconnect = failedResponse(action.payload)
      })

      // getAzureAdSyncStatus
      .addCase(getAzureAdSyncStats.pending, state => {
        state.api.getAzureAdSyncStats = inProgress
      })
      .addCase(getAzureAdSyncStats.fulfilled, (state, action) => {
        state.api.getAzureAdSyncStats = successResponse
        state.azureAdSyncStats = action.payload
      })
      .addCase(getAzureAdSyncStats.rejected, (state, action) => {
        state.api.getAzureAdSyncStats = failedResponse(action.payload)
      })

      // getAzureAdSyncNow
      .addCase(postAzureAdSyncNow.pending, state => {
        state.api.postAzureAdSyncNow = inProgress
      })
      .addCase(postAzureAdSyncNow.fulfilled, (state, action) => {
        state.api.postAzureAdSyncNow = successResponse
        state.azureAdSyncNow = action.payload
      })
      .addCase(postAzureAdSyncNow.rejected, (state, action) => {
        state.api.postAzureAdSyncNow = failedResponse(action.payload)
      })

      // getAzureAdLookupUserSignedUrl
      .addCase(getAzureAdLookupUserSignedUrl.pending, state => {
        state.api.getAzureAdLookupUserSignedUrl = inProgress
      })
      .addCase(getAzureAdLookupUserSignedUrl.fulfilled, (state, action) => {
        state.api.getAzureAdLookupUserSignedUrl = successResponse
        state.azureAdLookupUserSignedUrl = action.payload
      })
      .addCase(getAzureAdLookupUserSignedUrl.rejected, (state, action) => {
        state.api.getAzureAdLookupUserSignedUrl = failedResponse(action.payload)
      })

      // getAzureAdLookupUser
      .addCase(getAzureAdLookupUser.pending, state => {
        state.api.getAzureAdLookupUser = inProgress
      })
      .addCase(getAzureAdLookupUser.fulfilled, (state, action) => {
        state.api.getAzureAdLookupUser = successResponse
        state.azureAdLookupUser = action.payload
      })
      .addCase(getAzureAdLookupUser.rejected, (state, action) => {
        state.api.getAzureAdLookupUser = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetSyncStats, resetSyncNow, resetLookupUserSignedUrl, resetAzureAdTenantStatus } =
  azureAdSlice.actions

export {
  getAzureAdTenantStatus,
  getAzureAdAdminConsent,
  postAzureAdDisconnect,
  getAzureAdSyncStats,
  postAzureAdSyncNow,
  getAzureAdLookupUser,
  getAzureAdLookupUserSignedUrl
}

export default azureAdSlice.reducer
