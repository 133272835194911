import React, { useMemo } from 'react'

import { StatComponentProps } from 'types/stats'

import EmailStatisticsChart from 'components/pages/overview/dashboard/emailStatisticsCharts/EmailStatisticsChart'
import { useOutboundEmailStatisticsLogic } from 'components/pages/overview/dashboard/emailStatisticsCharts/useOutboundEmailStatisticsLogic'

const OutboundEmailStatistics: React.FC<StatComponentProps> = props => {
  const [state, eventHandlers] = useOutboundEmailStatisticsLogic(props)

  return useMemo(
    () => <EmailStatisticsChart title="outbound_email_statistics" logic={{ state, eventHandlers }} />,
    [state, eventHandlers]
  )
}

export default OutboundEmailStatistics
