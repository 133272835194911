import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'

import { changePassword } from 'redux/features/settings/settingsSlice'
import useLogout from 'lib/useLogout'

interface PasswordFormValues {
  newPassword: string
  oldPassword: string
  confirmNewPassword: string
}

export interface UseChangePasswordLogic {
  changePasswordFormValues: PasswordFormValues
  handleInputChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  changingPassword: boolean
  submitIsDisabled: boolean
  onSubmit: () => void
  isOpenConfirmDialog: boolean
  closeConfirmDialog: () => void
  onChangePassword: () => void
}

const initPasswordFormValues = {
  newPassword: '',
  oldPassword: '',
  confirmNewPassword: ''
}

export const useChangePasswordLogic = (): UseChangePasswordLogic => {
  const dispatch = useAppDispatch()
  const [logout] = useLogout()

  const { changingPassword, changePasswordFailed, changePasswordSuccess, isUsedOnetimePasscode } = useAppSelector(
    _store => ({
      changingPassword: isPending(_store.settings.changePasswordApiStatus),
      changePasswordFailed: isFailed(_store.settings.changePasswordApiStatus),
      changePasswordSuccess: isSuccess(_store.settings.changePasswordApiStatus),
      isUsedOnetimePasscode: !!_store.auth.accessTokenObject?.isUsedOnetimePasscode
    })
  )
  const [changePasswordFormValues, setChangePasswordFormValues] = React.useState(initPasswordFormValues)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true)

  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.SETTINGS_CHANGE_PASSWORD_PAGE_VIEW)
    // eslint-disable-next-line
  }, [])

  const handleInputChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.currentTarget
      setChangePasswordFormValues({ ...changePasswordFormValues, [name]: value })
    },
    [changePasswordFormValues]
  )

  // Force to logout the user after changed the pwd with a onetime used pwd
  useEffect(() => {
    if (changePasswordSuccess && isUsedOnetimePasscode) {
      logout(true)
    }
  }, [logout, changePasswordSuccess, isUsedOnetimePasscode])

  useEffect(() => {
    let message = ''
    const formIsFilled = !!(
      changePasswordFormValues.newPassword.length &&
      changePasswordFormValues.oldPassword.length &&
      changePasswordFormValues.confirmNewPassword.length
    )

    if (changePasswordFormValues.newPassword.length > 100) {
      message = 'error_password_char_limit'
    } else if (changePasswordFormValues.newPassword === changePasswordFormValues.oldPassword) {
      message = 'error_new_password_not_same'
    } else if (changePasswordFormValues.newPassword !== changePasswordFormValues.confirmNewPassword) {
      message = 'error_confirm_password_is_same'
    }

    if (formIsFilled && message.length === 0) {
      setSubmitIsDisabled(false)
    } else {
      setSubmitIsDisabled(true)
    }
  }, [changePasswordFormValues])

  useEffect(() => {
    if (!changingPassword && !changePasswordFailed) {
      setChangePasswordFormValues(initPasswordFormValues)
    }
  }, [changingPassword, changePasswordFailed])

  const onSubmit = useCallback(() => {
    setIsOpenConfirmDialog(true)
  }, [])

  const onChangePassword = useCallback(() => {
    if (!submitIsDisabled) {
      dispatch(changePassword(changePasswordFormValues))
    }
  }, [dispatch, changePasswordFormValues, submitIsDisabled])

  const closeConfirmDialog = useCallback(() => {
    setIsOpenConfirmDialog(false)
  }, [])

  return useMemo(
    () => ({
      changePasswordFormValues,
      handleInputChange,
      changingPassword,
      submitIsDisabled,
      onSubmit,
      isOpenConfirmDialog,
      closeConfirmDialog,
      onChangePassword
    }),
    [
      changePasswordFormValues,
      handleInputChange,
      changingPassword,
      submitIsDisabled,
      onSubmit,
      isOpenConfirmDialog,
      closeConfirmDialog,
      onChangePassword
    ]
  )
}
