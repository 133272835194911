import React, { useCallback, useMemo, MouseEvent } from 'react'
import { Box, Button, Grid, Link, Typography } from '@barracuda-internal/bds-core'
import styles from 'components/libs/layout/footer/loginFooter/loginFooterStyles'
import config from 'config/appConfig'

const LoginFooter = () => {
  const classes = styles()
  const copyrightYears = new Date().getFullYear()

  const onClickCookiePreferences = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const el = document.getElementById('ot-sdk-btn')
    if (el) {
      el.click()
    }
  }, [])

  return useMemo(
    () => (
      <Grid container className={classes.container}>
        <Box>
          <Typography variant="body2" className={classes.footerText}>
            © {copyrightYears} Barracuda Networks, Inc.
          </Typography>
        </Box>
        <Box>
          <Link
            href="https://www.barracuda.com/company/legal/privacy"
            className={classes.footerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            href="https://www.barracuda.com/legal/terms"
            className={`${classes.footerLink} ${config.ONETRUST_ID ? '' : 'nomargin'}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>
          {config.ONETRUST_ID && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link className={`${classes.footerLink} nomargin`} onClick={onClickCookiePreferences}>
              Cookie preferences
            </Link>
          )}
        </Box>
      </Grid>
    ),
    [classes.container, classes.footerLink, classes.footerText, copyrightYears, onClickCookiePreferences]
  )
}

export default LoginFooter
