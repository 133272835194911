import { v4 as makeUuid } from 'uuid'
import routesConfig from 'lib/routesConfig'
import { CustomRblsParsed } from 'types/CustomRbls'
import {
  AVAILABLE_ATTACHMENT_FILTER_ACTIONS,
  AVAILABLE_CPL_OUTBOUND_MESSAGE_FILTER_ACTIONS,
  AVAILABLE_CPL_PREDEFINED_FILTER_ACTIONS,
  AVAILABLE_GEO_POLICIES,
  AVAILABLE_INTENT_DOMAIN_ACTIONS,
  AVAILABLE_MESSAGE_CONTENT_FILTER_MATCHES,
  AVAILABLE_MESSAGE_FILTER_ACTIONS,
  AVAILABLE_OUTBOUND_MESSAGE_FILTER_ACTIONS,
  AVAILABLE_PREDEFINED_FILTER_ACTIONS,
  AVAILABLE_PREDEFINED_FILTER_EXCEPTION_TYPE,
  AVAILABLE_PREDEFINED_FILTER_MATCHES,
  AVAILABLE_PREDEFINED_FILTER_PATTERN,
  AVAILABLE_RECIPIENT_POLICIES,
  AVAILABLE_SENDER_POLICIES,
  AttachmentFilter,
  AvailableSettings,
  DmarcDkimExempt,
  DomainEntry,
  GeoIPAddressExemptPolicy,
  GeoPolicy,
  GeoSenderNameExemptPolicy,
  IntentDomainPolicy,
  IpAddressEntry,
  LanguagePolicy,
  MessageContentFilter,
  MessageContentFilterMatch,
  PredefinedFilter,
  PredefinedFilterExceptionType,
  PredefinedFilterExceptions,
  PredefinedFilterMatch,
  RecipientPolicy,
  SenderPolicy,
  SettingValue,
  MachineLearningExemptEntry
} from 'types/Settings'
import { isEmailOrDomainValid, isEmailValid, isUsNumber, isValidIpV4Address } from 'lib/validation'
import { getCountryListCode3 } from 'lib/isoCountries'
import { languagePolicies } from 'lib/languages'
import { parseDataItem } from 'lib/parseData'
import {
  dropError,
  IpAddressDataToArray,
  combineDropError,
  listToCsvString,
  DomainDataToArray
} from 'components/libs/bulkEdit/bulkEdit'

export const BULK_EDIT_CONFIG = () => ({
  [routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data: any) => string) => {
      const dataArray: CustomRblsParsed[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 1)
        if (!item) {
          return
        }

        const [zone, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !zone && dropError(formatMessage, 'error_missing_zone', { row })

        const regex = /^[a-z0-9.-]{1,255}$/i
        !regex.test(zone) && dropError(formatMessage, 'error_invalid_zone', { row })

        const findDuplicate = dataArray.find(entry => entry.zone === zone)
        findDuplicate && dropError(formatMessage, 'error_duplicate_value', { row, item: `${findDuplicate.zone}` })
        /* eslint-enable no-unused-expressions */

        dataArray.push({
          id: makeUuid(),
          zone,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: CustomRblsParsed) =>
        `${accumulator}\n${currentData.zone},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('custom_rbls_header'))
    },
    title: 'custom_rbls_title',
    backUrl: routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS,
    settings: [AvailableSettings.RBLS]
  },
  [routesConfig.INBOUND_SETTINGS_RATE_CONTROL_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: IpAddressEntry[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [address, netmask, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !address && dropError(formatMessage, 'error_missing_address', { row })
        !isValidIpV4Address(address) && dropError(formatMessage, 'error_invalid_address', { row })
        !netmask && dropError(formatMessage, 'error_missing_netmask', { row })
        !isValidIpV4Address(netmask) && dropError(formatMessage, 'error_invalid_netmask', { row })

        const findDuplicate = dataArray.find(entry => entry.address === address && entry.netmask === netmask)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.address},${findDuplicate.netmask}`
          })
        /* eslint-enable no-unused-expressions */

        dataArray.push({
          id: makeUuid(),
          address,
          netmask,
          comment,
          policy: 'exempt'
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('control_rate_header'))
    },
    title: 'rate_control_title',
    backUrl: routesConfig.INBOUND_SETTINGS_RATE_CONTROL,
    settings: [AvailableSettings.RATE_CONTROL_EXEMPTIONS]
  },
  [routesConfig.INBOUND_SETTINGS_IP_POLICIES_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      IpAddressDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.policy},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('ip_policy_header'))
    },
    title: 'ip_policies_title',
    backUrl: routesConfig.INBOUND_SETTINGS_IP_POLICIES,
    settings: [AvailableSettings.IP_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_TRUSTED_FORWARDERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: IpAddressEntry[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [address, netmask, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !address && dropError(formatMessage, 'error_missing_address', { row })
        !isValidIpV4Address(address) && dropError(formatMessage, 'error_invalid_address', { row })
        !netmask && dropError(formatMessage, 'error_missing_netmask', { row })
        !isValidIpV4Address(netmask) && dropError(formatMessage, 'error_invalid_netmask', { row })

        const findDuplicate = dataArray.find(entry => entry.address === address && entry.netmask === netmask)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.address},${findDuplicate.netmask}`
          })

        dataArray.push({
          id: makeUuid(),
          address,
          netmask,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('trusted_forwarders_header'))
    },
    title: 'trusted_forwarders_title',
    backUrl: routesConfig.INBOUND_SETTINGS_IP_POLICIES,
    settings: [AvailableSettings.TRUSTED_FORWARDERS]
  },
  [routesConfig.INBOUND_SETTINGS_GEOIP_POLICIES_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: GeoPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [country, policy, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        const countryCodes = getCountryListCode3()
        /* eslint-disable no-unused-expressions */
        !country && dropError(formatMessage, 'error_missing_country', { row })
        !countryCodes.find(details => details.code === country) &&
          dropError(formatMessage, 'error_invalid_country', { row })
        !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
        !AVAILABLE_GEO_POLICIES.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

        const findDuplicate = dataArray.find(entry => entry.country === country)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.country}`
          })

        dataArray.push({
          id: makeUuid(),
          country,
          policy,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: GeoPolicy) =>
        `${accumulator}\n${currentData.country},${currentData.policy},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('geoip_policies_header'))
    },
    title: 'geoip_policies_title',
    backUrl: routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES,
    settings: [AvailableSettings.GEOIP_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_GEOIP_IPADDRESS_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: GeoIPAddressExemptPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [address, netmask, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !address && dropError(formatMessage, 'error_missing_address', { row })
        !isValidIpV4Address(address) && dropError(formatMessage, 'error_invalid_address', { row })
        !netmask && dropError(formatMessage, 'error_missing_netmask', { row })
        !isValidIpV4Address(netmask) && dropError(formatMessage, 'error_invalid_netmask', { row })

        const findDuplicate = dataArray.find(entry => entry.address === address)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.address}`
          })
        /* eslint-enable no-unused-expressions */

        dataArray.push({
          id: makeUuid(),
          address,
          netmask,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: GeoIPAddressExemptPolicy) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('geoip_exempt_header'))
    },
    title: 'geoip_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES,
    settings: [AvailableSettings.GEOIP_IP_ADDRESS_EXEMPT_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_GEOIP_EMAIL_DOMAIN_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: GeoSenderNameExemptPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 1)
        if (!item) {
          return
        }

        const [name, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !name && dropError(formatMessage, 'error_missing_email', { row })
        !isEmailOrDomainValid(name) && dropError(formatMessage, 'error_invalid_email', { row })

        const findDuplicate = dataArray.find(entry => entry.name === name)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.name}`
          })

        dataArray.push({
          id: makeUuid(),
          name,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: GeoSenderNameExemptPolicy) =>
        `${accumulator}\n${currentData.name},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('geoip_sender_exempt_header'))
    },
    title: 'geoip_sender_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES,
    settings: [AvailableSettings.GEOIP_SENDER_NAME_EXEMPT_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_LANGUAGE_POLICIES_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: LanguagePolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [language, policy, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !language && dropError(formatMessage, 'error_missing_language', { row })
        !languagePolicies.includes(language) && dropError(formatMessage, 'error_invalid_language', { row })
        !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
        !AVAILABLE_GEO_POLICIES.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

        const findDuplicate = dataArray.find(entry => entry.language === language)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.language}`
          })

        dataArray.push({
          id: makeUuid(),
          language,
          policy,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: LanguagePolicy) =>
        `${accumulator}\n${currentData.language},${currentData.policy},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('language_policies_header'))
    },
    title: 'language_policies_title',
    backUrl: routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES,
    settings: [AvailableSettings.LANGUAGE_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: RecipientPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [name, policy, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !name && dropError(formatMessage, 'error_missing_email', { row })
        !isEmailValid(name) && dropError(formatMessage, 'error_invalid_email', { row })
        !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
        !AVAILABLE_RECIPIENT_POLICIES.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

        const findDuplicate = dataArray.find(entry => entry.name === name)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.name}`
          })

        dataArray.push({
          id: makeUuid(),
          name,
          policy,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: RecipientPolicy) =>
        `${accumulator}\n${currentData.name},${currentData.policy},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('recipient_policies_header'))
    },
    title: 'recipient_policies_title',
    backUrl: routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES,
    settings: [AvailableSettings.USER_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_SENDER_POLICIES_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availablePolicies = isCplAccount ? AVAILABLE_SENDER_POLICIES.slice(0, -1) : AVAILABLE_SENDER_POLICIES
      const dataArray: SenderPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [name, policy, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !name && dropError(formatMessage, 'error_missing_email', { row })
        !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
        !availablePolicies.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

        const findDuplicate = dataArray.find(entry => entry.name === name)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.name}`
          })

        dataArray.push({
          id: makeUuid(),
          name,
          policy,
          comment,
          modified: Date.now() / 1000
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: SenderPolicy) =>
        `${accumulator}\n${currentData.name},${currentData.policy},${currentData.comment}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'sender_policies_header_cpl' : 'sender_policies_header'}`)
      )
    },
    title: 'sender_policies_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_POLICIES,
    settings: [AvailableSettings.SENDER_POLICIES]
  },
  [routesConfig.INBOUND_SETTINGS_DMARC_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DmarcDkimExempt) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('dmarc_exempt_header'))
    },
    title: 'dmarc_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.DMARC_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_DKIM_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DmarcDkimExempt) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('dkim_exempt_header'))
    },
    title: 'dkim_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.DKIM_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_SPF_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      IpAddressDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('spf_exempt_header'))
    },
    title: 'spf_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.SPF_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_SPF_DOMAIN_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DomainEntry) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('spf_domain_exempt_header'))
    },
    title: 'spf_domain_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.SPF_DOMAIN_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_SPF_MISSING_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      IpAddressDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('spf_missing_exempt_header'))
    },
    title: 'spf_missing_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.SPF_MISSING_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_PTR_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      IpAddressDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: IpAddressEntry) =>
        `${accumulator}\n${currentData.address},${currentData.netmask},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('ptr_exempt_header'))
    },
    title: 'ptr_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_SENDER_AUTH,
    settings: [AvailableSettings.PTR_EXEMPT]
  },
  [routesConfig.INBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availableActions = isCplAccount
        ? AVAILABLE_ATTACHMENT_FILTER_ACTIONS.slice(0, -1)
        : AVAILABLE_ATTACHMENT_FILTER_ACTIONS
      const dataArray: AttachmentFilter[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 4)
        if (!item) {
          return
        }

        const [type, pattern, scanArchive, action, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        const errorMessages: string[] = []
        /* eslint-disable no-unused-expressions */
        if (!type) {
          errorMessages.push('error_missing_filter_type')
        } else if (type !== 'filename' && type !== 'mime') {
          errorMessages.push('error_invalid_filter_type')
        }

        !pattern && errorMessages.push('error_missing_filter_pattern')

        if (!scanArchive) {
          errorMessages.push('error_missing_archive_check')
        } else if (Number(scanArchive) !== 0 && Number(scanArchive) !== 1) {
          errorMessages.push('error_missing_archive_check')
        }

        if (!action) {
          errorMessages.push('error_missing_filter_action')
        } else if (!availableActions.includes(action)) {
          errorMessages.push('error_invalid_filter_action')
        }

        const findDuplicate = dataArray.find(entry => entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.pattern}`
          })

        if (errorMessages.length) {
          combineDropError(formatMessage, errorMessages, { row })
        }

        dataArray.push({
          id: makeUuid(),
          type,
          pattern,
          scanArchive: Number(scanArchive),
          action,
          comment,
          minSize: 0
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: AttachmentFilter) =>
        `${accumulator}\n${currentData.type},${currentData.pattern},${currentData.scanArchive},${currentData.action},${currentData.comment}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'attachment_filter_header_cpl' : 'attachment_filter_header'}`)
      )
    },
    title: 'attachment_filter_title',
    backUrl: routesConfig.INBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.ATTACHMENT_FILTER_POLICY]
  },
  [routesConfig.INBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availableActions = isCplAccount
        ? AVAILABLE_MESSAGE_FILTER_ACTIONS.slice(0, -1)
        : AVAILABLE_MESSAGE_FILTER_ACTIONS
      const dataArray: MessageContentFilter[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [pattern, action, match] = item

        const matchArray = match.split(',')

        const row = index + 1
        const errorMessages: string[] = []
        /* eslint-disable no-unused-expressions */

        !pattern && errorMessages.push('error_missing_filter_pattern')

        if (!action) {
          errorMessages.push('error_missing_filter_action')
        } else if (!availableActions.includes(action)) {
          errorMessages.push('error_invalid_filter_action')
        }

        if (!matchArray.length) {
          errorMessages.push('error_missing_match_filter')
        } else if (!matchArray.every(val => AVAILABLE_MESSAGE_CONTENT_FILTER_MATCHES.includes(val))) {
          errorMessages.push('error_invalid_match_filter')
        }

        const findDuplicate = dataArray.find(entry => entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.pattern}`
          })

        if (errorMessages.length) {
          combineDropError(formatMessage, errorMessages, { row })
        }

        const matchParsed = matchArray.reduce((perv, current) => {
          // eslint-disable-next-line no-param-reassign
          perv[current as keyof MessageContentFilterMatch] = 1
          return perv
        }, {} as MessageContentFilterMatch)

        dataArray.push({
          id: makeUuid(),
          pattern,
          match: matchParsed,
          action
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: MessageContentFilter) =>
        `${accumulator}\n${currentData.pattern},${currentData.action},${Object.entries(currentData.match).map(
          ([key, value]) => (value === 1 ? key : '')
        )}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'message_filter_header_cpl' : 'message_filter_header'}`)
      )
    },
    title: 'message_filter_title',
    backUrl: routesConfig.INBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.MESSAGE_CONTENT_FILTERS]
  },
  [routesConfig.INBOUND_SETTINGS_INTENT_DOMAINS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availablePolicies = isCplAccount
        ? AVAILABLE_INTENT_DOMAIN_ACTIONS.slice(0, -1)
        : AVAILABLE_INTENT_DOMAIN_ACTIONS
      const dataArray: IntentDomainPolicy[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [domain, policy, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !domain && dropError(formatMessage, 'error_missing_domain', { row })

        const regex = /^[a-z0-9.-]{1,255}$/i
        !regex.test(domain) && dropError(formatMessage, 'error_invalid_domain', { row })
        !policy && dropError(formatMessage, 'error_missing_ip_policy', { row })
        !availablePolicies.includes(policy) && dropError(formatMessage, 'error_invalid_ip_policy', { row })

        const findDuplicate = dataArray.find(entry => entry.domain === domain)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.domain}`
          })

        dataArray.push({
          id: makeUuid(),
          domain,
          policy,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: IntentDomainPolicy) =>
        `${accumulator}\n${currentData.domain},${currentData.policy},${currentData.comment}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'intent_domains_header_cpl' : 'intent_domains_header'}`)
      )
    },
    title: 'intent_domains_title',
    backUrl: routesConfig.INBOUND_SETTINGS_ANTI_PHISHING,
    settings: [AvailableSettings.LINKED_DOMAIN_POLICY]
  },
  [routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING_EXEMPT_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data: any) => string) => {
      const dataArray: MachineLearningExemptEntry[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 1)
        if (!item) {
          return
        }

        const [name, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        /* eslint-disable no-unused-expressions */
        !name && dropError(formatMessage, 'error_missing_machine_learning_exempt', { row })
        !isEmailOrDomainValid(name) && dropError(formatMessage, 'error_invalid_email_domain', { row })

        const findDuplicate = dataArray.find(entry => entry.name === name)
        findDuplicate && dropError(formatMessage, 'error_duplicate_value', { row, item: `${findDuplicate.name}` })
        /* eslint-enable no-unused-expressions */

        dataArray.push({
          id: makeUuid(),
          name,
          comment
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: MachineLearningExemptEntry) =>
        `${accumulator}\n${currentData.name},${currentData.comment}`
      return listToCsvString(data, selectorFnToCsv, formatMessage('machine_learning_exempt_header'))
    },
    title: 'machine_learning_exempt_title',
    backUrl: routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING,
    settings: [AvailableSettings.MACHINE_LEARNING_EXEMPT]
  },
  [routesConfig.OUTBOUND_SETTINGS_ENCRYPTION_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DmarcDkimExempt) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('dlp_encryption_header'))
    },
    title: 'dlp_encryption_title',
    backUrl: routesConfig.OUTBOUND_SETTINGS_ENCRYPTION,
    settings: [
      AvailableSettings.OUTBOUND_TLS_ALL,
      AvailableSettings.OUTBOUND_TLS_DOMAINS,
      AvailableSettings.OUTBOUND_TLS_DOMAIN_EXEMPTIONS
    ],
    dependingSetting: AvailableSettings.OUTBOUND_TLS_ALL,
    dependingOutcome: (outboundTlsAll: SettingValue) =>
      outboundTlsAll === SettingValue.ENABLED
        ? AvailableSettings.OUTBOUND_TLS_DOMAIN_EXEMPTIONS
        : AvailableSettings.OUTBOUND_TLS_DOMAINS
  },
  [routesConfig.OUTBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availableActions = isCplAccount
        ? AVAILABLE_ATTACHMENT_FILTER_ACTIONS.slice(0, -1)
        : AVAILABLE_ATTACHMENT_FILTER_ACTIONS
      const dataArray: AttachmentFilter[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 4)
        if (!item) {
          return
        }

        const [type, pattern, scan_archive, action, baseComment = ''] = item
        const comment = baseComment.substring(0, 100)

        const row = index + 1
        const errorMessages: string[] = []
        /* eslint-disable no-unused-expressions */
        if (!type) {
          errorMessages.push('error_missing_filter_type')
        } else if (type !== 'filename' && type !== 'mime') {
          errorMessages.push('error_invalid_filter_type')
        }

        !pattern && errorMessages.push('error_missing_filter_pattern')

        if (!scan_archive) {
          errorMessages.push('error_missing_archive_check')
        } else if (Number(scan_archive) !== 0 && Number(scan_archive) !== 1) {
          errorMessages.push('error_missing_archive_check')
        }

        if (!action) {
          errorMessages.push('error_missing_filter_action')
        } else if (!availableActions.includes(action)) {
          errorMessages.push('error_invalid_filter_action')
        }

        const findDuplicate = dataArray.find(entry => entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.pattern}`
          })

        if (errorMessages.length) {
          combineDropError(formatMessage, errorMessages, { row })
        }

        dataArray.push({
          id: makeUuid(),
          type,
          pattern,
          scanArchive: Number(scan_archive),
          action,
          comment,
          minSize: 0
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: AttachmentFilter) =>
        `${accumulator}\n${currentData.type},${currentData.pattern},${currentData.scanArchive},${currentData.action},${currentData.comment}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'attachment_filter_header_cpl' : 'attachment_filter_header'}`)
      )
    },
    title: 'attachment_filter_title',
    backUrl: routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.OUTBOUND_ATTACHMENT_FILTER_POLICY]
  },
  [routesConfig.OUTBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availableActions = isCplAccount
        ? AVAILABLE_CPL_OUTBOUND_MESSAGE_FILTER_ACTIONS
        : AVAILABLE_OUTBOUND_MESSAGE_FILTER_ACTIONS
      const dataArray: MessageContentFilter[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [pattern, action, match] = item

        const matchArray = match.split(',')

        const row = index + 1
        const errorMessages: string[] = []
        /* eslint-disable no-unused-expressions */

        !pattern && errorMessages.push('error_missing_filter_pattern')

        if (!action) {
          errorMessages.push('error_missing_filter_action')
        } else if (!availableActions.includes(action)) {
          errorMessages.push('error_invalid_filter_action')
        }

        if (!matchArray.length) {
          errorMessages.push('error_missing_match_filter')
        } else if (!matchArray.every(val => AVAILABLE_MESSAGE_CONTENT_FILTER_MATCHES.includes(val))) {
          errorMessages.push('error_invalid_match_filter')
        }

        const findDuplicate = dataArray.find(entry => entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.pattern}`
          })

        if (errorMessages.length) {
          combineDropError(formatMessage, errorMessages, { row })
        }

        const matchParsed = matchArray.reduce((perv, current) => {
          // eslint-disable-next-line no-param-reassign
          perv[current as keyof MessageContentFilterMatch] = 1
          return perv
        }, {} as MessageContentFilterMatch)

        dataArray.push({
          id: makeUuid(),
          pattern,
          match: matchParsed,
          action
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: MessageContentFilter) =>
        `${accumulator}\n${currentData.pattern},${currentData.action},${Object.entries(currentData.match).map(
          ([key, value]) => (value === 1 ? key : '')
        )}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'message_filter_header_cpl' : 'outbound_message_filter_header'}`)
      )
    },
    title: 'message_filter_title',
    backUrl: routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.OUTBOUND_MESSAGE_CONTENT_FILTERS]
  },
  [routesConfig.OUTBOUND_SETTINGS_PREDEFINED_FILTERS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const availableActions = isCplAccount
        ? AVAILABLE_CPL_PREDEFINED_FILTER_ACTIONS
        : AVAILABLE_PREDEFINED_FILTER_ACTIONS
      const dataArray: PredefinedFilter[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [pattern, action, match] = item

        const matchArray = match.split(',')

        const row = index + 1
        const errorMessages: string[] = []
        /* eslint-disable no-unused-expressions */

        if (!pattern) {
          errorMessages.push('error_missing_predefined_filter_pattern')
        } else if (!AVAILABLE_PREDEFINED_FILTER_PATTERN.includes(pattern)) {
          errorMessages.push('error_invalid_filter_pattern')
        }

        if (!action) {
          errorMessages.push('error_missing_filter_action')
        } else if (!availableActions.includes(action)) {
          errorMessages.push('error_invalid_filter_action')
        }

        if (!matchArray.length) {
          errorMessages.push('error_missing_match_filter')
        } else if (!matchArray.every(val => AVAILABLE_PREDEFINED_FILTER_MATCHES.includes(val))) {
          errorMessages.push('error_invalid_match_filter')
        }

        const findDuplicate = dataArray.find(entry => entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.pattern}`
          })

        if (errorMessages.length) {
          combineDropError(formatMessage, errorMessages, { row })
        }

        const matchParsed = matchArray.reduce((perv, current) => {
          // eslint-disable-next-line no-param-reassign
          perv[current as keyof PredefinedFilterMatch] = 1
          return perv
        }, {} as PredefinedFilterMatch)

        dataArray.push({
          id: makeUuid(),
          pattern,
          match: matchParsed,
          action
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string, isCplAccount?: boolean) => {
      const selectorFnToCsv = (accumulator: string, currentData: PredefinedFilter) =>
        `${accumulator}\n${currentData.pattern},${currentData.action},${Object.entries(currentData.match).map(
          ([key, value]) => (value === 1 ? key : '')
        )}`

      return listToCsvString(
        data,
        selectorFnToCsv,
        formatMessage(`${isCplAccount ? 'predefined_filters_header_cpl' : 'predefined_filters_header'}`)
      )
    },
    title: 'predefined_filters_title',
    backUrl: routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS]
  },
  [routesConfig.OUTBOUND_SETTINGS_PREDEFINED_FILTERS_EXCEPTIONS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) => {
      const dataArray: PredefinedFilterExceptions[] = []

      // Remove the header line
      const dataList = rawData.split('\n').slice(1)

      dataList.forEach((dataString: string, index: number) => {
        const item = parseDataItem(dataString, 2)
        if (!item) {
          return
        }

        const [type, pattern] = item

        const row = index + 1
        /* eslint-disable no-unused-expressions */

        if (!type) {
          dropError(formatMessage, 'error_missing_type', { row })
        } else if (!AVAILABLE_PREDEFINED_FILTER_EXCEPTION_TYPE.includes(type)) {
          dropError(formatMessage, 'error_invalid_type', { row })
        }

        if (!pattern) {
          dropError(formatMessage, 'error_missing_predefined_filter_pattern', { row })
        } else if (type === PredefinedFilterExceptionType.PHONE && !isUsNumber(pattern)) {
          dropError(formatMessage, 'error_invalid_phone_number', { row })
        }

        const findDuplicate = dataArray.find(entry => entry.type === type && entry.pattern === pattern)
        findDuplicate &&
          dropError(formatMessage, 'error_duplicate_value', {
            row,
            item: `${findDuplicate.type}:${findDuplicate.pattern}`
          })

        dataArray.push({
          id: makeUuid(),
          pattern,
          type
        })
      })

      return dataArray
    },
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: PredefinedFilterExceptions) =>
        `${accumulator}\n${currentData.type},${currentData.pattern}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('predefined_filters_exception_header'))
    },
    title: 'predefined_filters_exceptions_title',
    backUrl: routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES,
    settings: [AvailableSettings.OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS_EXCEPTIONS]
  },
  [routesConfig.INBOUND_TLS_DOMAINS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DomainEntry) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('inbound_tls_domains_header'))
    },
    title: 'inbound_tls_domains_title',
    backUrl: routesConfig.DOMAINS,
    settings: [AvailableSettings.INBOUND_TLS_DOMAINS, AvailableSettings.INBOUND_TLS_ALL]
  },
  [routesConfig.INBOUND_TLS_DOMAIN_EXEMPTIONS_BULK_EDIT.path]: {
    rawDataToArray: (rawData: string, formatMessage: (path: string, data?: any) => string) =>
      DomainDataToArray(rawData, formatMessage),
    dataToCsv: (data: string, formatMessage: (path: string, data?: any) => string) => {
      const selectorFnToCsv = (accumulator: string, currentData: DomainEntry) =>
        `${accumulator}\n${currentData.domain},${currentData.comment}`

      return listToCsvString(data, selectorFnToCsv, formatMessage('inbound_tls_domain_exemptions_header'))
    },
    title: 'inbound_tls_domain_exemptions_title',
    backUrl: routesConfig.DOMAINS,
    settings: [AvailableSettings.INBOUND_TLS_DOMAIN_EXEMPTIONS, AvailableSettings.INBOUND_TLS_ALL]
  }
})
