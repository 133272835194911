import { useCallback, useMemo, useState } from 'react'
import toggleInArray from 'lib/toggleInArray'

export type SelectedCheckboxes = string[]
export type OnSelectCheckbox = (checkboxId: string) => void
export type OnSelectAllCheckboxes = () => void
export type IsCheckboxIdIsSelected = (checkboxId: string) => boolean
export type IsAllCheckboxSelected = boolean
export type GetSelectedIds = (allId: string[]) => string[]
export type ResetCheckboxes = (newSelection?: string[]) => void

export type UseDatatableCheckboxColumnLogic = [
  SelectedCheckboxes,
  OnSelectCheckbox,
  OnSelectAllCheckboxes,
  IsCheckboxIdIsSelected,
  IsAllCheckboxSelected,
  GetSelectedIds,
  ResetCheckboxes
]
export const ALL_CHECKBOX = 'ALL_CHECKBOX'

export type UseDatatableCheckboxColumnLogicProps = {
  initialSelectedCheckboxes?: string[]
}

export const useDatatableCheckboxColumnLogic = (
  { initialSelectedCheckboxes = [] }: UseDatatableCheckboxColumnLogicProps = { initialSelectedCheckboxes: [] }
): UseDatatableCheckboxColumnLogic => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(initialSelectedCheckboxes)

  const onSelectCheckbox = useCallback(
    (checkboxId: string) => {
      setSelectedCheckboxes(toggleInArray(selectedCheckboxes, checkboxId))
    },
    [selectedCheckboxes]
  )

  const onSelectAllCheckboxes = useCallback(() => {
    setSelectedCheckboxes(selectedCheckboxes.includes(ALL_CHECKBOX) ? [] : [ALL_CHECKBOX])
  }, [selectedCheckboxes])

  const isCheckboxIdIsSelected = useCallback(
    (checkboxId: string) => {
      if (selectedCheckboxes.includes(ALL_CHECKBOX)) {
        return !selectedCheckboxes.includes(checkboxId)
      }
      return selectedCheckboxes.includes(checkboxId)
    },
    [selectedCheckboxes]
  )

  const isAllCheckboxSelected = useMemo(() => selectedCheckboxes.includes(ALL_CHECKBOX), [selectedCheckboxes])

  const getSelectedIds = useCallback(
    (allIds: string[]) =>
      allIds.reduce(
        (collectedIds: string[], id: string) => (isCheckboxIdIsSelected(id) ? [...collectedIds, id] : collectedIds),
        []
      ),
    [isCheckboxIdIsSelected]
  )

  const resetCheckboxes = useCallback((newSelection: string[] = []) => {
    setSelectedCheckboxes(newSelection)
  }, [])

  return useMemo(
    () => [
      selectedCheckboxes,
      onSelectCheckbox,
      onSelectAllCheckboxes,
      isCheckboxIdIsSelected,
      isAllCheckboxSelected,
      getSelectedIds,
      resetCheckboxes
    ],
    [
      selectedCheckboxes,
      onSelectCheckbox,
      onSelectAllCheckboxes,
      isCheckboxIdIsSelected,
      isAllCheckboxSelected,
      getSelectedIds,
      resetCheckboxes
    ]
  )
}
