import { makeStyles, colors } from '@barracuda-internal/bds-core'
import { CSSProperties } from '@material-ui/styles'

interface StyleProps {
  tableHeight: CSSProperties['height']
  tableWidth: CSSProperties['width']
}

export default makeStyles(theme => ({
  cellIcon: {
    width: 16,
    height: 16
  },
  dataTable: {
    height: ({ tableHeight }: StyleProps) => tableHeight,
    width: ({ tableWidth }: StyleProps) => tableWidth
  },
  actionChip: {
    backgroundColor: colors.green050,
    '&.purple100': {
      backgroundColor: colors.purple100
    },
    '&.redOrange200': {
      backgroundColor: colors.redOrange200
    },
    '&.green050': {
      backgroundColor: colors.green050
    },
    '&.red200': {
      backgroundColor: colors.red200
    },
    '&.orange100': {
      backgroundColor: colors.orange100
    },
    '&.indigo100': {
      backgroundColor: colors.indigo100
    },
    '&.scaleGray050': {
      backgroundColor: colors.scaleGray050
    }
  },
  table: {
    overflowY: 'scroll',
    '& .k-grid-toolbar': {
      height: `${theme.layout.messageLogToolbarHeight}`
    },
    '& .k-i-sort-desc-sm:before': {
      content: '"\\25BE" !important',
      paddingBottom: 6
    },
    '& th': {
      textTransform: 'uppercase',
      color: colors.statusInformational,
      lineHeight: '22px',
      padding: '2px 10px',
      fontSize: '12px',
      borderColor: colors.uiElement,
      borderLeft: '1 solid transparent',
      borderRight: '1 solid transparent',
      '& .k-link': {
        padding: '0 !important',
        margin: '0 !important'
      }
    },
    '& th:nth-child(8), th:nth-child(12)': {
      textAlign: 'right',
      paddingRight: 10
    },
    '& th:nth-child(n+9):nth-child(-n+10)': {
      textAlign: 'center'
    },
    '& td': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: 0,
      lineHeight: '27px'
    },
    '& td:nth-child(1)': {
      textAlign: 'center'
    },
    '& td:nth-child(2)': {
      paddingLeft: 4
    },
    '& td:nth-child(3)': {
      textAlign: 'center',
      alignItems: 'center'
    },
    '& td:nth-child(n+4):nth-child(-n+7), td:nth-child(11)': {
      paddingLeft: 10
    },
    '& td:nth-child(n+9):nth-child(-n+10)': {
      textAlign: 'center'
    },
    '& td:nth-child(8), td:nth-child(12)': {
      textAlign: 'right',
      paddingRight: 10
    },
    '& tr': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      padding: '0',
      '&.history-row': {
        boxShadow: `inset 0 0 0 2px ${colors.barracudaBlueDark}`
      },
      '&.k-state-selected': {
        backgroundColor: colors.hoverBlue
      }
    }
  },
  checkbox: {
    width: '12px',
    height: '12px',
    padding: theme.spacing(0.75)
  },
  icon: {
    width: '100%',
    height: '20px',
    display: 'block'
  },
  menuItem: {
    fontSize: '14px'
  }
}))
