import React, { useMemo } from 'react'
import { Grid, Typography } from '@barracuda-internal/bds-core'

import MessageDetails from 'components/libs/message/messageDetails/MessageDetails'
import { useMessageDetailLogic } from 'components/pages/messageDetail/useMessageDetailLogic'
import styles from 'components/pages/messageDetail/messageDetailViewStyles'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.message_detail'

const MessageDetailPage = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [{ message, accountPermissions }] = useMessageDetailLogic()
  const classes = styles()

  return useMemo(() => {
    if (!message || !accountPermissions) {
      return null
    }

    return (
      <Grid container className={classes.messageDetailViewContainer}>
        <Grid item xs={12} className={classes.messageDetailViewContent}>
          <Typography className={classes.title}>{formatMessage('title')}</Typography>
          <MessageDetails />
        </Grid>
      </Grid>
    )
  }, [
    accountPermissions,
    classes.messageDetailViewContainer,
    classes.messageDetailViewContent,
    classes.title,
    formatMessage,
    message
  ])
}

export default MessageDetailPage
