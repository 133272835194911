/* eslint-disable no-bitwise */
import { useCallback, useMemo } from 'react'

import reduxStore from 'lib/reduxStore'

import { useAppSelector } from 'redux/toolkit/hooks'
import { UserRights, UserRightsForUiPaths, UserRole } from 'components/libs/userRights/userRights'
import { UserFeatures } from 'types/auth'

export { UserRights, UserRightsForUiPaths, UserRole }
export type UseUserRights = {
  userHasRight: (rightName: number, withPdDomainIdCheck?: boolean) => boolean
  isUserType: (role: UserRole) => boolean
  isImpersonatedUser: boolean
  isDefaultPortalId: boolean
  isPdDomainIdSet: boolean
  isCplAccount: boolean
  validatedUserRights: boolean[]
  userFeatures?: UserFeatures
}

export const hasRight = (rightName: number, userRoleType: number | undefined = undefined): boolean => {
  const validatedUserRoleType: number | undefined =
    userRoleType || reduxStore.getState()?.auth?.accessTokenObject?.roleType

  if (!validatedUserRoleType) {
    return false
  }

  return !!(validatedUserRoleType & rightName)
}

const useUserRights = (userRightsToCheck: number[] = [], withPdDomainIdToCheck = false): UseUserRights => {
  const { userRoleType, userFeatures, pdDomainId, isImpersonatedUser, isCplAccount, portalId, defaultPortalId } =
    useAppSelector(_store => ({
      userRoleType: _store.auth.accessTokenObject?.roleType,
      userFeatures: _store.auth.accessTokenObject?.features,
      pdDomainId: _store.auth.accessTokenObject?.pdDomainId,
      isImpersonatedUser: !!_store.auth.accessTokenObject?.isImpersonated,
      isCplAccount: !!_store.auth.accessTokenObject?.isCplAccount,
      defaultPortalId: _store.auth.accessTokenObject?.userInfo?.defaultPortalId,
      portalId: _store.auth.accessTokenObject?.userInfo?.portalId
    }))

  const userHasRight = useCallback(
    (rightName: number, withPdDomainIdCheck = false): boolean =>
      hasRight(rightName, userRoleType) || (withPdDomainIdCheck && !!pdDomainId),
    [userRoleType, pdDomainId]
  )

  const validatedUserRights = useMemo(
    () => (userRightsToCheck || []).map(userRight => userHasRight(userRight, withPdDomainIdToCheck)),
    [userRightsToCheck, withPdDomainIdToCheck, userHasRight]
  )

  const isUserType = useCallback((role: UserRole) => !!(userRoleType && userRoleType & role), [userRoleType])

  const isDefaultPortalId = useMemo(() => !!portalId && portalId === defaultPortalId, [portalId, defaultPortalId])

  return useMemo(
    () => ({
      userHasRight,
      isUserType,
      validatedUserRights,
      isImpersonatedUser,
      isDefaultPortalId,
      isCplAccount,
      isPdDomainIdSet: !!pdDomainId,
      userFeatures
    }),
    [
      userHasRight,
      isUserType,
      validatedUserRights,
      isImpersonatedUser,
      isDefaultPortalId,
      pdDomainId,
      isCplAccount,
      userFeatures
    ]
  )
}

export default useUserRights
