import React, { useMemo } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  CheckboxLabel,
  CircularProgress,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from '@barracuda-internal/bds-core'

import { GridNoRecords } from '@progress/kendo-react-grid'
import {
  ConfirmRedeliverDialogLogic,
  State,
  UiState
} from 'components/libs/dialog/confirmRedeliver/useConfirmRedeliverDialogLogic'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import { Cell } from 'components/libs/grid/cell'
import styles from 'components/libs/dialog/confirmRedeliver/confirmRedeliverDialogStyles'

export interface ConfirmRedeliverDialogProps {
  logic: ConfirmRedeliverDialogLogic
}

const BASE_I18N_KEY = 'ess.atp_log'
const BASE_I18N_TABLE_KEY = 'ess.data_tables.atp_log'

const ConfirmRedeliverDialog = ({ logic }: ConfirmRedeliverDialogProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const tableFormatMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [
    { atpItems, error, isOpen, uiState, isAtpReportPending, isDeliverEnabled, isRedeliverPending },
    { handleClose, handleConfirm, handleClickViewReport, handleToggleEnableDeliver }
  ] = logic
  const classes = styles()

  return useMemo(
    () => (
      <Dialog maxWidth="lg" open={isOpen} onClose={handleClose} data-testid="confirm-redeliver-dialog">
        <DialogTitle disableTypography id="form-dialog-title">
          <Typography variant="h6">{formatMessage('view_reports_modal.title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{formatMessage('view_reports_modal.content')}</DialogContentText>
          <DataTable data={atpItems}>
            {/* No records */}
            <GridNoRecords>
              {uiState === UiState.LOADING && (
                <>
                  <LinearProgress />
                  <Typography className={classes.noRecords} variant="body2" data-testid="uistate-loading">
                    {formatMessage('view_reports_modal.ui_loading')}
                  </Typography>
                </>
              )}
              {uiState === UiState.ERROR && (
                <Alert severity="error" data-testid="uistate-error">
                  {errorFormatMessage(error || 'default')}
                </Alert>
              )}
              {uiState === UiState.READY && !atpItems.length && (
                <Typography className={classes.noRecords} variant="body2" data-testid="no-atp-attachment">
                  {formatMessage('view_reports_modal.no_atp_attachment')}
                </Typography>
              )}
              {uiState === UiState.READY && !!atpItems.length && ' '}
            </GridNoRecords>

            {/* Status */}
            <DataTableColumn
              title={tableFormatMessage('status')}
              cell={({ dataItem }: { dataItem: State['atpItems'][number] }) => (
                <Cell data-testid="status-cell">
                  {dataItem.isFuture && (
                    <Grid className={classes.statusWrapper} data-testid="is-future-item">
                      <CircularProgress className={classes.progressIcon} color="secondary" />
                      <Typography className={classes.statusText} variant="body2">
                        {dataItem.status}
                      </Typography>
                    </Grid>
                  )}
                  {!dataItem.isFuture && (
                    <Grid className={classes.statusWrapper}>
                      {dataItem.icon && <dataItem.icon className={`${classes.statusIcon} ${dataItem.status}`} />}
                      {!dataItem.icon && <Grid className={classes.statusIcon} data-testid="default-icon" />}
                      <Typography className={classes.statusText} variant="body2">
                        {dataItem.status}
                      </Typography>
                    </Grid>
                  )}
                </Cell>
              )}
            />

            {/* FileName */}
            <DataTableColumn
              title={tableFormatMessage('file_name')}
              cell={({ dataItem }: { dataItem: State['atpItems'][number] }) => (
                <Cell data-testid="file-name-cell">{dataItem.fileName}</Cell>
              )}
            />

            {/* FileType */}
            <DataTableColumn
              title={tableFormatMessage('file_type')}
              cell={({ dataItem }: { dataItem: State['atpItems'][number] }) => (
                <Cell data-testid="file-type-cell">{dataItem.fileType}</Cell>
              )}
            />

            {/* Report */}
            <DataTableColumn
              title={tableFormatMessage('report')}
              cell={({ dataItem }: { dataItem: State['atpItems'][number] }) => (
                <Cell data-testid="report-cell">
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    disabled={isAtpReportPending}
                    onClick={() => handleClickViewReport(dataItem.cuid)}
                    data-testid="report-button"
                  >
                    {uiState === UiState.READY && (
                      <Typography variant="body2">{tableFormatMessage('view_report')}</Typography>
                    )}
                  </Button>
                </Cell>
              )}
            />
          </DataTable>
          <DialogContentText className={classes.checkboxLabel}>
            <CheckboxLabel
              control={<Checkbox checked={isDeliverEnabled} onChange={handleToggleEnableDeliver} name="checkedA" />}
              label={formatMessage('view_reports_modal.checkbox_label')}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {formatMessage('view_reports_modal.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            disabled={!isDeliverEnabled || isRedeliverPending}
            data-testid="deliver-button"
          >
            {isRedeliverPending && (
              <CircularProgress className={classes.deliverProgress} size={12} data-testid="deliver-button-progress" />
            )}
            {formatMessage('view_reports_modal.deliver')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      isOpen,
      handleClose,
      formatMessage,
      uiState,
      atpItems,
      classes,
      errorFormatMessage,
      error,
      tableFormatMessage,
      isDeliverEnabled,
      handleToggleEnableDeliver,
      handleConfirm,
      isRedeliverPending,
      isAtpReportPending,
      handleClickViewReport
    ]
  )
}

export default ConfirmRedeliverDialog
