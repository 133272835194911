import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles({
  dropdown: {
    height: 36,
    width: 48,

    '& > div:focus': {
      backgroundColor: 'inherit'
    },
    '&:before': {
      // normal
      borderBottom: 'none !important'
    },
    '&:after': {
      // focused
      borderBottom: 'none !important'
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: 'none !important'
    }
  },
  menuItem: {
    fontSize: '14px',
    minHeight: 26,
    minWidth: 26
  },
  active: {
    fontWeight: 700
  }
})
