import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  AccountEamilContinuitySettings,
  AccountUser,
  AccountUsersResults,
  AddUpdateAccountUserResult,
  PoliciesSettings,
  QuarantineNotificationSettings,
  QuarantineStatus,
  UserQNSettings
} from 'types/users'
import { InboundScheduleType } from 'types/Settings'
import {
  addUpdateAccountUsers,
  deleteAccountUsers,
  getAccountEmailContinuitySettings,
  getAccountUsers,
  getPoliciesSettings,
  getQuarantineNotificationSettings,
  getUserQNSettings,
  getUsersQNSettings,
  loginAsUser,
  requestResetPassword,
  updateAccountEmailContinuitySettings,
  updateAccountUserRole,
  updatePoliciesSettings,
  updateQuarantineNotificationSettings
} from 'redux/features/users/usersApiThunks'
import insertToArray from 'lib/insertToArray'

export interface UsersState {
  api: {
    getAccountUsersApiStatus: ApiStatus
    updateAccountUserRoleApiStatus: ApiStatus
    requestPasswordResetApiStatus: ApiStatus
    addUpdateAccountUsersApiStatus: ApiStatus
    deleteAccountUsersApiStatus: ApiStatus
    loginAsUserApiStatus: ApiStatus
    getAccountEmailContinuitySettingsApiStatus: ApiStatus
    updateAccountEmailContinuitySettingsApiStatus: ApiStatus
    getQuarantineNotificationSettingsApiStatus: ApiStatus
    updateQuarantineNotificationSettingsApiStatus: ApiStatus
    getPoliciesSettingsApiStatus: ApiStatus
    updatePoliciesSettingsApiStatus: ApiStatus
  }
  accountUsers?: AccountUsersResults
  requestedResetPasswordUserId?: string
  addUpdateAccountUsersResults?: AddUpdateAccountUserResult[]
  accountEmailContinuitySettings?: AccountEamilContinuitySettings
  quarantineNotificationSettings?: QuarantineNotificationSettings
  policiesSettings?: PoliciesSettings
}

// initialState
export const INITIAL_STATE: UsersState = {
  api: {
    getAccountUsersApiStatus: inIdle,
    updateAccountUserRoleApiStatus: inIdle,
    requestPasswordResetApiStatus: inIdle,
    addUpdateAccountUsersApiStatus: inIdle,
    deleteAccountUsersApiStatus: inIdle,
    loginAsUserApiStatus: inIdle,
    getAccountEmailContinuitySettingsApiStatus: inIdle,
    updateAccountEmailContinuitySettingsApiStatus: inIdle,
    getQuarantineNotificationSettingsApiStatus: inIdle,
    updateQuarantineNotificationSettingsApiStatus: inIdle,
    getPoliciesSettingsApiStatus: inIdle,
    updatePoliciesSettingsApiStatus: inIdle
  },
  accountUsers: undefined,
  requestedResetPasswordUserId: undefined,
  addUpdateAccountUsersResults: undefined,
  accountEmailContinuitySettings: undefined,
  quarantineNotificationSettings: undefined,
  policiesSettings: undefined
}

/* eslint-disable no-param-reassign */
export function updateQuarantineStatus(state: UsersState, userId: string, inboundSchedule: InboundScheduleType) {
  const newQuarantineStatus =
    inboundSchedule === InboundScheduleType.custom ? QuarantineStatus.enabled : QuarantineStatus.disabled

  const userIndex = findIndex(state.accountUsers?.users, (user: AccountUser) => user && user.userId === userId)
  if (userIndex !== -1 && state.accountUsers) {
    state.accountUsers.users[userIndex].quarantine = newQuarantineStatus
  }
}

export const usersSlice = createSlice({
  name: 'USERS',
  initialState: INITIAL_STATE,
  reducers: {
    resetAccountUsers: state => {
      state.api.getAccountUsersApiStatus = INITIAL_STATE.api.getAccountUsersApiStatus
      state.accountUsers = INITIAL_STATE.accountUsers
    },
    resetUpdateAccountUserRole: state => {
      state.api.updateAccountUserRoleApiStatus = INITIAL_STATE.api.updateAccountUserRoleApiStatus
    },
    resetRequestResetPassword: state => {
      state.api.requestPasswordResetApiStatus = INITIAL_STATE.api.requestPasswordResetApiStatus
      state.requestedResetPasswordUserId = INITIAL_STATE.requestedResetPasswordUserId
    },
    resetAddUpdateAccountUser: state => {
      state.api.addUpdateAccountUsersApiStatus = INITIAL_STATE.api.addUpdateAccountUsersApiStatus
      state.addUpdateAccountUsersResults = INITIAL_STATE.addUpdateAccountUsersResults
    },
    resetAccountEmailContinuitySettings: state => {
      state.api.getAccountEmailContinuitySettingsApiStatus =
        INITIAL_STATE.api.getAccountEmailContinuitySettingsApiStatus
      state.api.updateAccountEmailContinuitySettingsApiStatus =
        INITIAL_STATE.api.updateAccountEmailContinuitySettingsApiStatus
      state.accountEmailContinuitySettings = INITIAL_STATE.accountEmailContinuitySettings
    },
    resetDeleteAccountUsers: state => {
      state.api.deleteAccountUsersApiStatus = INITIAL_STATE.api.deleteAccountUsersApiStatus
    },
    resetQuarantineNotificationSettings: state => {
      state.api.getQuarantineNotificationSettingsApiStatus =
        INITIAL_STATE.api.getQuarantineNotificationSettingsApiStatus
      state.api.updateQuarantineNotificationSettingsApiStatus =
        INITIAL_STATE.api.updateQuarantineNotificationSettingsApiStatus
      state.quarantineNotificationSettings = INITIAL_STATE.quarantineNotificationSettings
    },
    resetPoliciesSettings: state => {
      state.api.getPoliciesSettingsApiStatus = INITIAL_STATE.api.getPoliciesSettingsApiStatus
      state.api.updatePoliciesSettingsApiStatus = INITIAL_STATE.api.updatePoliciesSettingsApiStatus
      state.policiesSettings = INITIAL_STATE.policiesSettings
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // getAccountUsers
      .addCase(getAccountUsers.pending, state => {
        state.api.getAccountUsersApiStatus = inProgress
      })
      .addCase(getAccountUsers.fulfilled, (state, action) => {
        state.api.getAccountUsersApiStatus = successResponse
        state.accountUsers = {
          ...action.payload.results,
          users: insertToArray(
            state.accountUsers?.users || [],
            action.payload.results.users,
            action.payload.metadata.offset || 0
          )
        }
      })
      .addCase(getAccountUsers.rejected, (state, action) => {
        state.api.getAccountUsersApiStatus = failedResponse(action.payload)
      })

      // updateAccountUserRole
      .addCase(updateAccountUserRole.pending, state => {
        state.api.updateAccountUserRoleApiStatus = inProgress
      })
      .addCase(updateAccountUserRole.fulfilled, state => {
        state.api.updateAccountUserRoleApiStatus = successResponse
      })
      .addCase(updateAccountUserRole.rejected, (state, action) => {
        state.api.updateAccountUserRoleApiStatus = failedResponse(action.payload)
      })

      // addUpdateAccountUsers
      .addCase(addUpdateAccountUsers.pending, state => {
        state.api.addUpdateAccountUsersApiStatus = inProgress
      })
      .addCase(addUpdateAccountUsers.fulfilled, (state, action) => {
        state.api.addUpdateAccountUsersApiStatus = successResponse
        state.addUpdateAccountUsersResults = action.payload.results
      })
      .addCase(addUpdateAccountUsers.rejected, (state, action) => {
        state.api.addUpdateAccountUsersApiStatus = failedResponse(action.payload)
      })

      // deleteAccountUsers
      .addCase(deleteAccountUsers.pending, state => {
        state.api.deleteAccountUsersApiStatus = inProgress
      })
      .addCase(deleteAccountUsers.fulfilled, state => {
        state.api.deleteAccountUsersApiStatus = successResponse
      })
      .addCase(deleteAccountUsers.rejected, (state, action) => {
        state.api.deleteAccountUsersApiStatus = failedResponse(action.payload)
      })

      // requestResetPassword
      .addCase(requestResetPassword.pending, (state, action) => {
        state.api.requestPasswordResetApiStatus = inProgress
        state.requestedResetPasswordUserId = action.meta.arg
      })
      .addCase(requestResetPassword.fulfilled, state => {
        state.api.requestPasswordResetApiStatus = successResponse
      })
      .addCase(requestResetPassword.rejected, (state, action) => {
        state.api.requestPasswordResetApiStatus = failedResponse(action.payload)
      })

      // getUserQNSettings
      .addCase(getUserQNSettings.fulfilled, (state, action) => {
        updateQuarantineStatus(state, action.meta.arg, action.payload.result.inboundSchedule)
      })
      .addCase(getUserQNSettings.rejected, (state, action) => {
        updateQuarantineStatus(state, action.meta.arg, InboundScheduleType.never)
      })

      // getUsersQNSettings
      .addCase(getUsersQNSettings.fulfilled, (state, action) => {
        action.payload.result.forEach((qnSettings: UserQNSettings) => {
          updateQuarantineStatus(state, qnSettings.userId, qnSettings.value?.inboundSchedule)
        })
      })
      .addCase(getUsersQNSettings.rejected, (state, action) => {
        action.meta.arg.forEach((user_id: string) => {
          updateQuarantineStatus(state, user_id, InboundScheduleType.never)
        })
      })

      // login as user
      .addCase(loginAsUser.pending, state => {
        state.api.loginAsUserApiStatus = inProgress
      })
      .addCase(loginAsUser.rejected, (state, action) => {
        state.api.loginAsUserApiStatus = failedResponse(action.payload)
      })

      // getAccountEmailContinuitySettings
      .addCase(getAccountEmailContinuitySettings.pending, state => {
        state.api.getAccountEmailContinuitySettingsApiStatus = inProgress
      })
      .addCase(getAccountEmailContinuitySettings.fulfilled, (state, action) => {
        state.api.getAccountEmailContinuitySettingsApiStatus = successResponse
        state.accountEmailContinuitySettings = action.payload
      })
      .addCase(getAccountEmailContinuitySettings.rejected, (state, action) => {
        state.api.getAccountEmailContinuitySettingsApiStatus = failedResponse(action.payload)
      })

      // updateAccountEmailContinuitySettings
      .addCase(updateAccountEmailContinuitySettings.pending, state => {
        state.api.updateAccountEmailContinuitySettingsApiStatus = inProgress
      })
      .addCase(updateAccountEmailContinuitySettings.fulfilled, (state, action) => {
        state.api.updateAccountEmailContinuitySettingsApiStatus = successResponse
        state.accountEmailContinuitySettings = action.payload
      })
      .addCase(updateAccountEmailContinuitySettings.rejected, (state, action) => {
        state.api.updateAccountEmailContinuitySettingsApiStatus = failedResponse(action.payload)
      })

      // getQuarantineNotificationSettings
      .addCase(getQuarantineNotificationSettings.pending, state => {
        state.api.getQuarantineNotificationSettingsApiStatus = inProgress
      })
      .addCase(getQuarantineNotificationSettings.fulfilled, (state, action) => {
        state.api.getQuarantineNotificationSettingsApiStatus = successResponse
        state.quarantineNotificationSettings = action.payload.results
      })
      .addCase(getQuarantineNotificationSettings.rejected, (state, action) => {
        state.api.getAccountEmailContinuitySettingsApiStatus = failedResponse(action.payload)
      })

      // updateQuarantineNotificationSettings
      .addCase(updateQuarantineNotificationSettings.pending, state => {
        state.api.updateQuarantineNotificationSettingsApiStatus = inProgress
      })
      .addCase(updateQuarantineNotificationSettings.fulfilled, (state, action) => {
        state.api.updateQuarantineNotificationSettingsApiStatus = successResponse
        state.quarantineNotificationSettings = action.payload.results
      })
      .addCase(updateQuarantineNotificationSettings.rejected, (state, action) => {
        state.api.updateQuarantineNotificationSettingsApiStatus = failedResponse(action.payload)
      })

      // getPoliciesSettings
      .addCase(getPoliciesSettings.pending, state => {
        state.api.getPoliciesSettingsApiStatus = inProgress
      })
      .addCase(getPoliciesSettings.fulfilled, (state, action) => {
        state.api.getPoliciesSettingsApiStatus = successResponse
        state.policiesSettings = action.payload.results
      })
      .addCase(getPoliciesSettings.rejected, (state, action) => {
        state.api.getPoliciesSettingsApiStatus = failedResponse(action.payload)
      })

      // updatePoliciesSettings
      .addCase(updatePoliciesSettings.pending, state => {
        state.api.updatePoliciesSettingsApiStatus = inProgress
      })
      .addCase(updatePoliciesSettings.fulfilled, (state, action) => {
        state.api.updatePoliciesSettingsApiStatus = successResponse
        state.policiesSettings = action.payload.results
      })
      .addCase(updatePoliciesSettings.rejected, (state, action) => {
        state.api.updatePoliciesSettingsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetAccountUsers,
  resetUpdateAccountUserRole,
  resetRequestResetPassword,
  resetAddUpdateAccountUser,
  resetDeleteAccountUsers,
  resetAccountEmailContinuitySettings,
  resetQuarantineNotificationSettings,
  resetPoliciesSettings,
  reset
} = usersSlice.actions

export {
  getAccountUsers,
  updateAccountUserRole,
  addUpdateAccountUsers,
  deleteAccountUsers,
  requestResetPassword,
  getAccountEmailContinuitySettings,
  updateAccountEmailContinuitySettings,
  getQuarantineNotificationSettings,
  updateQuarantineNotificationSettings,
  getPoliciesSettings,
  updatePoliciesSettings
}

export default usersSlice.reducer
