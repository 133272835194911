import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { QueryType } from 'types/Support'

export interface GraphQLRequestPayload {
  queryType: QueryType
  token?: string
}

export interface GraphQLResponse {
  successCode: number
  token: string
}

export const getBackupGraphql = createAsyncThunk<GraphQLResponse, GraphQLRequestPayload, ApiRejectResponse>(
  'GRAPHQL/getBackup',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.GET_GRAPHQL_QUERY_RESPONSE, {
        data: payload
      })

      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'save_and_apply_config.backup_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const doRestoreGraphql = createAsyncThunk<GraphQLResponse, GraphQLRequestPayload, ApiRejectResponse>(
  'GRAPHQL/doRestore',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.GET_GRAPHQL_QUERY_RESPONSE, {
        data: payload
      })
      dispatch(
        setSuccessSnackBar({
          message: 'save_and_apply_config.restore_success'
        })
      )
      return resp.data
    } catch (e) {
      dispatch(
        setErrorSnackBar({
          message: 'save_and_apply_config.restore_failure'
        })
      )
      return rejectWithValue(validateApiError(e))
    }
  }
)
