import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  dropDownList: {
    border: '0px none',
    verticalAlign: 'middle',
    width: '100%'
  },
  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    margin: '0',
    padding: theme.spacing(0.25, 0)
  },
  label: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '70px'
  },
  headerTextArea: {
    border: '0px none',
    verticalAlign: 'middle',
    width: '100%'
  },
  bodyTextArea: {
    border: 'none',
    maxHeight: '300px',
    overflow: 'scroll',
    padding: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    width: '100%'
  },
  card: {
    width: '50%',
    '& .k-animation-container': {
      zIndex: 10003
    }
  },
  attachmentsSection: {
    display: 'inline-flex',
    paddingTop: theme.spacing(1)
  },
  attachmentsButton: {
    display: 'inline-block',
    padding: 0,
    marginRight: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden',

    '& input': {
      cursor: 'pointer',
      border: '1px solid red',
      display: 'block',
      position: 'absolute',
      opacity: 0,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
  },
  attachmentsGroup: {
    alignItems: 'center',
    display: 'inline-block'
  },
  attachmentsItems: {
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  sendingProgress: {
    display: 'inline-block'
  },
  chip: {
    margin: 1
  }
}))
