import React, { useMemo } from 'react'
import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@barracuda-internal/bds-core'
import { InputAdornment } from '@material-ui/core'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { useAzureAdSettingsLogic } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdSettingsLogic'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/azureAdSettingsStyles'
import { TenantAuthorizationStatus } from 'types/azureAd'
import { FormRow } from 'components/libs/layout/pages/FormRow'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'
import { InlineRadioGroup } from 'components/libs/layout/pages/formContent/InlineRadioGroup'
import { SettingValue } from 'types/Settings'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface AzureAdSettingsProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains.edit_domain.directory_services.azure_ad'

export const AzureAdSettings = ({ form }: AzureAdSettingsProps) => {
  const [
    { optionsState, authorizationState, syncNowState, lookupUserState },
    { optionsEvents, authorizationEvents, lookupUserEvents, syncNowEvents }
  ] = useAzureAdSettingsLogic(form)
  const classes = styles({
    isOptionsDisabled: optionsState.isOptionsDisabled
  })
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <>
        {/* Tenant authorization */}
        <FormBlock>
          <FormRow rowTitle={formatMessage('status')}>
            {authorizationState.tenantStatus === TenantAuthorizationStatus.UNKNOWN && (
              <Grid container direction="row" alignItems="center">
                <Grid item className={classes.authorizationStatusLabel}>
                  <CircularProgress size={16} />
                </Grid>
              </Grid>
            )}
            {authorizationState.tenantStatus === TenantAuthorizationStatus.ERROR && (
              <Grid container direction="row" alignItems="center">
                <Grid item className={classes.authorizationStatusLabel}>
                  <Typography variant="body1" className={classes.tenantStatusError}>
                    {formatMessage('tenant_status_error')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {authorizationState.tenantStatus === TenantAuthorizationStatus.UNAUTHORIZED && (
              <Grid container direction="row" alignItems="center">
                <Grid item className={classes.authorizationStatusLabel}>
                  <Typography variant="body1">{formatMessage('not_authorized')}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={authorizationEvents.onAuthorize}
                    disabled={authorizationState.isAuthorizeDisabled}
                  >
                    {formatMessage('authorize')}
                  </Button>
                </Grid>
              </Grid>
            )}
            {authorizationState.tenantStatus === TenantAuthorizationStatus.AUTHORIZED && (
              <Grid container direction="column">
                <Grid item>
                  <Grid container direction="row" alignItems="center">
                    <Grid item className={classes.authorizationStatusLabel}>
                      <Typography variant="body1" className={classes.autorizedLabel}>
                        {formatMessage('authorized')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!authorizationState.isDisconnectPending && (
                        <Button
                          variant="text"
                          color="primary"
                          onClick={authorizationEvents.onRevokeAuthorization}
                          disabled={authorizationState.isRevokeAuthorizationDisabled || optionsState.isOptionsDisabled}
                        >
                          {formatMessage('revoke_authorization')}
                        </Button>
                      )}
                      {authorizationState.isDisconnectPending && <CircularProgress size={16} color="secondary" />}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <b>{formatMessage('authorized_account')}:</b> {authorizationState.authorizedAccount}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    <b>{formatMessage('authorization_date')}:</b> {authorizationState.authorizationDate}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </FormRow>
        </FormBlock>

        {/* Directory Options */}
        <FormBlock title={formatMessage('directory_options')} titleVariant="h5">
          <FormRow rowTitle={formatMessage('synchronization_options.label')}>
            <Grid container direction="row">
              <Box>
                <InlineRadioGroup
                  name="auto_sync"
                  value={optionsState.autoSync}
                  onChange={optionsEvents.onChangeAutoSync}
                  disabled={optionsState.isOptionsDisabled}
                  values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                  labels={[
                    formatMessage('synchronization_options.automatic'),
                    formatMessage('synchronization_options.manual')
                  ]}
                />
              </Box>
              <Box className={classes.syncNow}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={syncNowEvents.onSyncNow}
                  disabled={syncNowState.isSyncNowDisabled}
                  isLoading={syncNowState.isSyncNowLoading}
                >
                  {formatMessage('sync_now')}
                </Button>
              </Box>
              <Box className={classes.syncStats}>
                {formatMessage(syncNowState.syncStats.textId, {
                  sync_stats: formatMessage('sync_stats', {
                    sync_users_added: syncNowState.syncStats.usersAdded,
                    sync_users_updated: syncNowState.syncStats.usersUpdated,
                    sync_users_deleted: syncNowState.syncStats.usersDeleted
                  }),
                  sync_finished_at: syncNowState.syncStats.finishedAt
                })}
              </Box>
            </Grid>
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('sso.label')}>
            <InlineRadioGroup
              name="enable_sso"
              value={optionsState.enableSso}
              onChange={optionsEvents.onChangeEnableSso}
              disabled={optionsState.isOptionsDisabled}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
              labels={[formatMessage('sso.yes'), formatMessage('sso.no')]}
            />
          </FormRow>
        </FormBlock>

        {/* Test configuration settings */}
        <FormBlock title={formatMessage('test_azure_ad_settings.title')} titleVariant="h5">
          <FormRow alignTitleToTop rowTitle={formatMessage('test_azure_ad_settings.label')}>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  value={lookupUserState.testUser}
                  onChange={lookupUserEvents.onChangeTestUser}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{lookupUserState.domainName}</InputAdornment>
                  }}
                  error={!!lookupUserState.error}
                  helperText={formatMessage(lookupUserState.error || 'test_azure_ad_settings.helper.note')}
                  disabled={optionsState.isOptionsDisabled}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.tetButton}
                  onClick={lookupUserEvents.onLookupUser}
                  disabled={lookupUserState.isLookupUserDisabled}
                  isLoading={lookupUserState.isLookupUserPending}
                >
                  {formatMessage('test_azure_ad_settings.button')}
                </Button>
              </Grid>
            </Grid>
          </FormRow>
        </FormBlock>
      </>
    ),
    [
      formatMessage,
      authorizationState,
      classes,
      authorizationEvents,
      optionsState,
      optionsEvents,
      syncNowEvents,
      syncNowState,
      lookupUserState,
      lookupUserEvents
    ]
  )
}
