import React, { useMemo } from 'react'
import { Grid } from '@barracuda-internal/bds-core'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { DialogActions } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/domains/editDomain/editDomainWarningDialogs/editDomainWarningDialogStyles'

const BASE_I18N_KEY = 'ess.domains'

export const DisableAzureAdWarningDialog = ({ onConfirm, onClose }: DialogActions) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <WarningDialog
        title={formatMessage('edit_domain.warnings.disable_azure.title')}
        description={
          <Grid container className={classes.descriptionSpacing}>
            <Grid item>{formatMessage('edit_domain.warnings.disable_azure.description.info')}</Grid>
            <Grid item>
              <ol>
                <li>{formatMessage('edit_domain.warnings.disable_azure.description.consequences.sso')}</li>
                <li>{formatMessage('edit_domain.warnings.disable_azure.description.consequences.sync')}</li>
              </ol>
            </Grid>
            <Grid item>{formatMessage('edit_domain.warnings.disable_azure.description.note')}</Grid>
          </Grid>
        }
        onCancel={onClose}
        onConfirm={onConfirm}
      />
    ),
    [classes, formatMessage, onClose, onConfirm]
  )
}
