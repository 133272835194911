import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: `calc(100vw - ${theme.spacing(12)}px)`,
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    zIndex: 1200,
    justifyContent: 'space-between',
    alignItems: 'baseline',

    '& #ot-sdk-btn.ot-sdk-show-settings': {
      background: 'transparent',
      padding: 0,
      border: 'none',
      fontSize: 12,
      color: '#646464',

      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline'
      }
    }
  },
  footerText: {
    marginRight: theme.spacing(9),
    fontSize: 12,
    color: '#646464'
  },
  footerLink: {
    marginRight: theme.spacing(4),
    fontSize: 12,
    color: '#646464',
    cursor: 'pointer',

    '&.nomargin': {
      marginRight: 0
    }
  }
}))
