import React, { useMemo } from 'react'

import { Checkbox, CheckboxLabel, Grid, TextareaAutosize, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings } from 'types/Settings'

import styles from 'components/pages/outboundSettings/outboundSettingsStyles'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { MAX_CHAR, useTaglineLogic } from 'components/pages/outboundSettings/tagline/useTaglineLogic'
import OutboundTagline from 'components/pages/support/help/outboundTagline/OutboundTagline'
import { PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.outbound_settings.tagline'

const Tagline: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useTaglineLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer
        key={state.key}
        isLoading={UseGetSettingsStateLogic.isLoading}
        isFailedToLoad={UseGetSettingsStateLogic.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.isDirtyForm}
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={eventHandlers.onCancelConfirm}
          onSubmit={eventHandlers.onSave}
          submitInProgress={state.isUpdateInProgress}
          isDisabledSubmit={!state.isDirtyForm}
          help={{
            content: OutboundTagline,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.taglineFooter,
            group: GROUP_TITLE.outboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <Grid container alignItems="center" direction="row">
          <Grid item xs={3}>
            <Typography variant="body2">{formatMessage('append_label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <CheckboxLabel
              control={
                <Checkbox
                  checked={state.form.outbound_tagline_enabled === '1'}
                  onChange={eventHandlers.onCheckboxChange}
                  name={AvailableSettings.OUTBOUND_TAGLINE_ENABLED}
                  color="primary"
                  size="small"
                  data-testid="checkbox"
                />
              }
              label={<Typography variant="body2">{formatMessage('append_description')}</Typography>}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" className={classes.selectRowWrapper}>
          <Grid item xs={3}>
            <Typography variant="body2">{formatMessage('tagline_label')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextareaAutosize
              minRows={16}
              maxRows={16}
              className={classes.textArea}
              value={state.form.outbound_tagline}
              onChange={eventHandlers.onTextChange}
              data-testid="textarea"
              maxLength={MAX_CHAR}
            />
            <Typography variant="body2">
              {formatMessage('characters_left', { charactersLeft: state.charactersLeft })}
            </Typography>
          </Grid>
        </Grid>
      </SettingsPageContainer>
    ),
    [UseGetSettingsStateLogic, state, formatMessage, eventHandlers, classes]
  )
}

export default Tagline
