import React, { useCallback, useMemo, useState } from 'react'
import Help from 'components/pages/support/help/Help'
import { TopicContent } from 'types/Support'

export { GROUP_TITLE, TOPICS } from 'components/pages/support/config'

export type IsOpened = boolean
export type OnOpen = () => void
export type OnClose = () => void
export type HelpModal = () => JSX.Element | null

export type UseSupportModalLogic = [OnOpen, HelpModal, IsOpened, OnClose]

export const useSupportModalLogic = (modalConfig: TopicContent): UseSupportModalLogic => {
  const [isSupportOpened, setIsSupportOpened] = useState<boolean>(false)

  const onOpenSupport = useCallback(() => {
    setIsSupportOpened(true)
  }, [])

  const onCloseSupport = useCallback(() => {
    setIsSupportOpened(false)
  }, [])

  const HelpModal = useCallback(() => {
    if (!isSupportOpened) {
      return null
    }

    return <Help modalComponentInfo={{ ...modalConfig, onClose: onCloseSupport }} />
  }, [modalConfig, isSupportOpened, onCloseSupport])

  return useMemo(
    () => [onOpenSupport, HelpModal, isSupportOpened, onCloseSupport],
    [isSupportOpened, onOpenSupport, onCloseSupport, HelpModal]
  )
}
