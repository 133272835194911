import React, { useMemo } from 'react'
import {
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Typography
} from '@barracuda-internal/bds-core'
import SuccessIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Success'
import ErrorIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Critical'
import { useLdapTestDialogLogic } from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/useLdapTestDialogLogic'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/ldapTestDialogStyles'

export interface LdapTestDialogProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.domains.edit_domain.directory_services.ldap.test'

export const LdapTestDialog = ({ onClose }: LdapTestDialogProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useLdapTestDialogLogic({ onClose })
  const classes = styles()

  return useMemo(
    () => (
      <Dialog open maxWidth="md" fullWidth>
        <DialogTitle>{formatMessage('title')}</DialogTitle>
        <DialogContent>
          {state.isLdapTestPending && <LinearProgress />}

          {/* Test result */}
          <Grid container alignItems="center" justifyContent="center" direction="row">
            {state.testSummary.success === true && (
              <Grid className={classes.success} container alignItems="center" direction="row">
                <SuccessIcon className={classes.icon} />
                {!!state.testSummary.text && (
                  <Typography variant="body1">{formatMessage(state.testSummary.text)}</Typography>
                )}
              </Grid>
            )}
            {state.testSummary.success === false && (
              <Grid className={classes.error} container alignItems="center" direction="row">
                <ErrorIcon className={classes.icon} />
                {!!state.testSummary.text && (
                  <Typography variant="body1">{formatMessage(state.testSummary.text)}</Typography>
                )}
              </Grid>
            )}
            {typeof state.testSummary.success === 'undefined' && !!state.testSummary.text && (
              <Typography variant="body1">{formatMessage(state.testSummary.text)}</Typography>
            )}
          </Grid>

          {/* User lookup result */}
          <Grid container className={classes.userLookupTitle}>
            {state.testSummary.testedEmail && state.testSummary.attributes && (
              <Typography variant="body1">
                {formatMessage('user_query_title', { test_email: state.testSummary.testedEmail })}:
              </Typography>
            )}
            {!state.testSummary.testedEmail && state.testSummary.attributes && (
              <Typography variant="body1">{formatMessage('user_filter_title')}:</Typography>
            )}
          </Grid>
          {state.testSummary.attributes && (
            <DataTable data={state.testSummary.attributes}>
              <DataTableColumn field="attribute" title="Attribute" width={200} />
              <DataTableColumn field="value" title="Value" />
            </DataTable>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={eventHandlers.onClose} disabled={state.isLdapTestPending}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, eventHandlers, formatMessage, state]
  )
}
