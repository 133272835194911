import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import InboundRegionalPolicies from 'components/pages/support/help/inboundRegionalPolicies/InboundRegionalPolicies'
import { useRegionalPoliciesLogic } from 'components/pages/inboundSettings/regionalPolicies/useRegionalPoliciesLogic'
import GeoPoliciesTable from 'components/pages/inboundSettings/regionalPolicies/GeoPoliciesTable'
import LanguagePoliciesTable from 'components/pages/inboundSettings/regionalPolicies/LanguagePoliciesTable'
import { FormSpacer, PageTitle } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import GeoPoliciesSenderNameExempt from 'components/pages/inboundSettings/regionalPolicies/GeoPoliciesSenderNameExempt'
import GeoPoliciesIPAddressExempt from 'components/pages/inboundSettings/regionalPolicies/GeoPoliciesIPAddressExempt'

const BASE_I18N_KEY = 'ess.inbound_settings.regional_policies'

const RegionalPolicies: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useRegionalPoliciesLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundRegionalPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.regionalPolicies,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <GeoPoliciesTable delegateDirtyFormState={eventHandlers.setIsGeoipTableDirty} />
        <GeoPoliciesSenderNameExempt delegateDirtyFormState={eventHandlers.setIsSenderExemptTableDirty} />
        <GeoPoliciesIPAddressExempt delegateDirtyFormState={eventHandlers.setIsIpAExemptTableDirty} />
        {state.canChangeLanguagePolicies && (
          <>
            <FormSpacer />
            <FormSpacer />
            <LanguagePoliciesTable delegateDirtyFormState={eventHandlers.setIsLanguageTableDirty} />
          </>
        )}
        <FormSpacer />
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [eventHandlers, formatMessage, state]
  )
}

export default RegionalPolicies
