import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'

import WarningDialog from '../dialog/warningDialog/WarningDialog'
import { useDomainConflictDialogLogic } from './useDomainConflictDialogLogic'

const BASE_I18N_KEY = 'ess.dialog'

function DomainConflictDialog() {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useDomainConflictDialogLogic()

  return useMemo(
    () => (
      <WarningDialog
        open={state.isOpen}
        title={formatMessage('domain_settings_conflict', { numberOfDomains: state.numberOfDomainsInConflict })}
        description={formatMessage('domain_settings_conflict_description')}
        onCancel={eventHandlers.onCancel}
        onConfirm={eventHandlers.onConfirm}
      />
    ),
    [formatMessage, state, eventHandlers]
  )
}

export default DomainConflictDialog
