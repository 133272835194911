import React, { useMemo } from 'react'

import ContentEditable from 'react-contenteditable'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'

import { addEllipsis } from 'lib/string'
import { useEmailComposerLogic } from 'components/libs/dialog/emailComposer/useEmailComposerLogic'
import styles from 'components/libs/dialog/emailComposer/emailComposerStyles'
import { useFormatMessage } from 'lib/localization'

export interface EmailComposerProps {
  handleClose: any
  emailType: string
}

const BASE_18N_KEY = 'ess.message_log.email_composer'

const EmailComposer: React.FC<EmailComposerProps> = React.forwardRef(
  ({ emailType, handleClose }: EmailComposerProps, _) => {
    const classes = styles()
    const formatMessage = useFormatMessage(BASE_18N_KEY)
    const [state, eventHandlers] = useEmailComposerLogic({ emailType, onClose: handleClose })
    return useMemo(
      () => (
        <>
          {state.isGetMessagePending && <LinearProgress data-testid="linear-progress" />}
          {(state.isGetMessageSuccess || state.isNewEmail) && (
            <Card className={classes.card}>
              <CardHeader title={state.subject.value} />
              <Divider />
              <CardContent data-testid="card-content">
                <div className={classes.headerWrapper}>
                  <label htmlFor="form" className={classes.label}>
                    {formatMessage('from')}
                  </label>
                  <Select
                    id="form"
                    fullWidth
                    variant="outlined"
                    className={classes.dropDownList}
                    name="from"
                    value={state.from.value}
                    onChange={eventHandlers.onChangeField('from')}
                  >
                    {/* Update useForm::onChangeField if anything else but a string is used here as value */}
                    {state.availableFromEmails.map(email => (
                      <MenuItem key={email} value={email}>
                        {email}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className={classes.headerWrapper}>
                  <label htmlFor="to" className={classes.label}>
                    {formatMessage('to')}
                  </label>
                  <TextField
                    id="to"
                    name="to"
                    className={classes.headerTextArea}
                    value={state.to.value}
                    onChange={eventHandlers.onChangeField('to')}
                    error={!state.to.isValid}
                  />
                </div>
                <div className={classes.headerWrapper}>
                  <label htmlFor="cc" className={classes.label}>
                    {formatMessage('cc')}
                  </label>
                  <TextField
                    id="cc"
                    name="cc"
                    defaultValue={state.cc.value}
                    className={classes.headerTextArea}
                    onChange={eventHandlers.onChangeField('cc')}
                    error={!state.cc.isValid}
                  />
                </div>
                {state.isNewEmail && (
                  <div className={classes.headerWrapper}>
                    <label htmlFor="subject" className={classes.label}>
                      {formatMessage('subject')}
                    </label>
                    <TextField
                      id="subject"
                      name="subject"
                      defaultValue={state.subject.value}
                      className={classes.headerTextArea}
                      onChange={eventHandlers.onChangeField('subject')}
                    />
                  </div>
                )}
                <div className={classes.attachmentsSection}>
                  <Button variant="text" color="primary" size="small" className={classes.attachmentsButton}>
                    {formatMessage('attachments')}
                    :
                    <input type="file" onChange={eventHandlers.onAddAttachment} />
                  </Button>
                  <div className={classes.attachmentsGroup}>
                    {Object.values(state.attachments.value).map(attachment => (
                      <div className={classes.attachmentsItems} key={attachment.id}>
                        <Chip
                          key={attachment.id}
                          label={addEllipsis(attachment.fileName, 30)}
                          className={classes.chip}
                          size="small"
                          color="primary"
                          onDelete={() => eventHandlers.onDeleteAttachment(attachment.id)}
                        />
                      </div>
                    ))}
                  </div>
                  <Divider />
                </div>
                <ContentEditable
                  className={classes.bodyTextArea}
                  id={state.isNewEmail ? 'new_email' : 'email_content'}
                  html={state.body.value}
                  disabled={false}
                  onChange={eventHandlers.onChangeField('body')}
                  onFocus={eventHandlers.onFocusContentEditable}
                  onMouseDown={eventHandlers.onMouseDownContentEditable}
                />
                <Divider />
              </CardContent>
              <CardActions>
                <Button
                  color="secondary"
                  size="medium"
                  variant="contained"
                  disabled={state.isPostNewEmailPending}
                  onClick={eventHandlers.onCancel}
                >
                  {formatMessage('buttons.cancel')}
                </Button>
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  onClick={eventHandlers.onSend}
                  disabled={state.isPostNewEmailPending || !state.isFormValid}
                >
                  {formatMessage('buttons.send')}
                </Button>
                {state.isPostNewEmailPending && (
                  <div className={classes.sendingProgress}>
                    <Typography variant="subtitle2">Sending email message</Typography>
                    <LinearProgress />
                  </div>
                )}
              </CardActions>
            </Card>
          )}
        </>
      ),
      [
        state.isGetMessagePending,
        state.isGetMessageSuccess,
        state.subject.value,
        state.from.value,
        state.availableFromEmails,
        state.to.value,
        state.to.isValid,
        state.cc.value,
        state.cc.isValid,
        state.isNewEmail,
        state.attachments.value,
        state.body.value,
        state.isPostNewEmailPending,
        state.isFormValid,
        classes.card,
        classes.headerWrapper,
        classes.label,
        classes.dropDownList,
        classes.headerTextArea,
        classes.attachmentsSection,
        classes.attachmentsButton,
        classes.attachmentsGroup,
        classes.bodyTextArea,
        classes.sendingProgress,
        classes.attachmentsItems,
        classes.chip,
        formatMessage,
        eventHandlers
      ]
    )
  }
)

export default EmailComposer
