import { useCallback, useMemo } from 'react'
import { LanguagePolicy } from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface LanguagePoliciesFormState {
  language: string
  policy: string
  comment: string
}

export interface ValidatorDependencies {
  presentPolicies: LanguagePolicy[]
}

export interface LanguagePoliciesFormConfig {
  initialState: LanguagePoliciesFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type LanguagePoliciesForm = SettingsForm<LanguagePoliciesFormState>

export const useLanguagePoliciesForm = ({
  initialState,
  delegateIsDirtyForm
}: LanguagePoliciesFormConfig): LanguagePoliciesForm => {
  const languageField = useStateField(initialState.language)
  const policyField = useStateField(initialState.policy)
  const commentField = useRefField(initialState.comment)

  const formConfig = useMemo(
    () => ({
      fields: {
        language: languageField,
        policy: policyField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, languageField, policyField]
  )
  const form = useSettingsForm<LanguagePoliciesFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: LanguagePolicy, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.presentPolicies.find(entry => entry.language === newPolicy.language)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }
      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
