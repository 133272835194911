import { createAsyncThunk } from '@reduxjs/toolkit'

import { v4 as makeUuid } from 'uuid'
import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { RootState } from 'redux/toolkit/store'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import {
  ALL_DOMAINS,
  BarracudaReportTypes,
  CustomReport,
  paginatedReports,
  Report,
  ReportsList,
  ReportsResponse,
  ScheduledReport,
  ScheduledReportStatus,
  ScheduleReportFormat,
  ScheduleReportFrequency
} from 'types/reports'
import { ShortcutItems } from 'components/pages/reports/reportList/customizedBDSComponents/DateRangePicker'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'

export interface GetReportPayload {
  id: string
  type: BarracudaReportTypes
  reportIsChanged: boolean
  paginated?: boolean
}

export interface DownloadReportPayload extends GetReportPayload {
  format: GetReportFormat
  label: string
}
export interface DownloadReportResponse {
  content: string
  contentType: string
  name: string
}

export enum GetReportFormat {
  CSV = 'CSV',
  JSON = 'JSON'
}

export type SetCustomReportsListPayload = CustomReport[]
export type SetCustomReportsListResponse = { items: CustomReport[] }
export type SetPinnedReportIdPayload = string
export type SetPinnedReportIdResponse = { id: string }

export function createReportParams(reportId: BarracudaReportTypes, rootState: RootState): Report {
  const { reports, auth, user } = rootState
  const columnFilters = Object.values(reports.filters.columnFilters).filter(columnFilter => !!columnFilter)

  const result: Report = {
    reportId,
    startDate: null,
    endDate: null,
    ...(!reports.filters.relativeDateRange && {
      startDate: reports.filters.startDate,
      endDate: reports.filters.endDate
    }),
    interval: reports.filters.interval,
    relativeDateRange: reports.filters.relativeDateRange,
    accountId: auth.accessTokenObject?.accountId || '',
    domainIds: reports.filters.domainIds.includes(ALL_DOMAINS)
      ? (user.availableDomains || []).map((domain: DomainsInDB) => domain.domainId)
      : reports.filters.domainIds,
    filters: columnFilters.length ? columnFilters : null,
    direction: reports.filters.direction,
    listTop: reports.filters.listTop
  }

  if (paginatedReports.includes(reportId)) {
    result.page = reports.filters.page - 1
    result.pageSize = reports.filters.pageSize
  }

  return result
}

export interface ScheduleReportPayload {
  name: string
  timeRange: string
  format: string
  frequency: string
  every?: string
  on?: string
  recipients: string
}

export interface UpdateScheduledReportPayload extends ScheduleReportPayload {
  id: string
  status?: ScheduledReportStatus
}

export interface DeleteScheduledReportPayload {
  id: string
}

export const getReportsList = createAsyncThunk<ReportsList, undefined, ApiRejectResponse>(
  'REPORTS/getList',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_REPORTS_LIST)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getReport = createAsyncThunk<ReportsResponse, GetReportPayload, ApiRejectResponse>(
  'REPORTS/getReport',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const apiParams = createReportParams(payload.type, getState() as RootState)
      const route = payload.paginated ? apiRoutes.GET_PAGINATED_REPORT : apiRoutes.GET_REPORT
      const resp = await restClient(route, {
        data: { filters: apiParams },
        headers: {
          isToolkitCompatible: true
        }
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadReport = createAsyncThunk<DownloadReportResponse, DownloadReportPayload, ApiRejectResponse>(
  'REPORTS/downloadReport',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const apiParams = createReportParams(payload.type, getState() as RootState)
      const route = payload.paginated ? apiRoutes.GET_PAGINATED_REPORT : apiRoutes.GET_REPORT
      const resp = await restClient(route, {
        data: { filters: { ...apiParams, format: payload.format || GetReportFormat.CSV } },
        headers: {
          isToolkitCompatible: true
        }
      })

      return { ...resp.data, name: payload.label }
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'error_download_report' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const setCustomReportsList = createAsyncThunk<
  SetCustomReportsListResponse,
  SetCustomReportsListPayload,
  ApiRejectResponse
>('REPORTS/setCustomReportsList', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.SET_CUSTOM_REPORTS, {
      data: { items: payload },
      headers: {
        isToolkitCompatible: true
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setPinnedReportId = createAsyncThunk<
  SetPinnedReportIdResponse,
  SetPinnedReportIdPayload,
  ApiRejectResponse
>('REPORTS/setPinnedReportId', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.SET_PINNED_REPORT_ID, {
      data: { id: payload },
      headers: {
        isToolkitCompatible: true
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
export const getScheduledReportList = createAsyncThunk<ScheduledReport[], undefined, ApiRejectResponse>(
  'REPORTS/scheduledReportList',
  async (payload, { rejectWithValue }) => {
    try {
      // Dummy loading state
      await wait(2000)

      const resp = await restClient(apiRoutes.GET_SCHEDULED_REPORTS_LIST)

      // send back the dummy data since GT3 transition HTTP 404 to HTTP 200
      return scheduledDummyData
      return resp.data
    } catch (e) {
      // TODO: Remove dummmy-data when the API endpoint is implemented
      return scheduledDummyData
      // return rejectWithValue(validateApiError(e))
    }
  }
)

export const scheduleReport = createAsyncThunk<ReportsResponse, ScheduleReportPayload, ApiRejectResponse>(
  'REPORTS/scheduleReport',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // Dummy loading state
      await wait(2000)

      // TODO: Remove dummmy-data when the API endpoint is implemented
      scheduledDummyData = [
        ...scheduledDummyData,
        {
          ...payload,
          id: makeUuid(),
          status: ScheduledReportStatus.enabled,
          nextSchedule: dummyNextDay.getTime()
        }
      ]
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_success'
        })
      )

      const resp = await restClient(apiRoutes.SCHEDULE_REPORT, { params: { ...payload } })

      // send back the dummy data since GT3 transition HTTP 404 to HTTP 200
      return true
      return resp.data
    } catch (e) {
      return true
      // return rejectWithValue(validateApiError(e))
    }
  }
)

export const updateScheduledReport = createAsyncThunk<boolean, UpdateScheduledReportPayload, ApiRejectResponse>(
  'REPORTS/updateScheduledReport',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // Dummy loading state
      await wait(2000)

      // TODO: Remove dummmy-data when the API endpoint is implemented
      scheduledDummyData = scheduledDummyData.map(data => {
        if (data.id === payload.id) {
          return {
            ...payload,
            nextSchedule: dummyNextDay.getTime(),
            status: payload.status || ScheduledReportStatus.enabled
          }
        }
        return data
      })
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_updated_success'
        })
      )

      const resp = await restClient(apiRoutes.UPDATE_SCHEDULED_REPORT, { params: { ...payload } })

      // send back the dummy data since GT3 transition HTTP 404 to HTTP 200
      return true
      return resp.data
    } catch (e) {
      return true
      // return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteScheduledReport = createAsyncThunk<boolean, DeleteScheduledReportPayload, ApiRejectResponse>(
  'REPORTS/deleteScheduledReport',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // Dummy loading state
      await wait(2000)

      // TODO: Remove dummmy-data when the API endpoint is implemented

      scheduledDummyData = scheduledDummyData.filter(data => data.id !== payload.id)
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_deleted_success'
        })
      )
      const resp = await restClient(apiRoutes.DELETE_SCHEDULED_REPORT, { params: { ...payload } })

      // send back the dummy data since GT3 transition HTTP 404 to HTTP 200
      return true
      return resp.data
    } catch (e) {
      return true
      // return rejectWithValue(validateApiError(e))
    }
  }
)

// eslint-disable-next-line no-promise-executor-return
const wait = (n: number) => new Promise(resolve => setTimeout(resolve, n))

const dummyNextDay = new Date()
dummyNextDay.setDate(dummyNextDay.getDate() + 1)
dummyNextDay.setHours(12)

const dummyPreviousDay = new Date()
dummyPreviousDay.setDate(dummyNextDay.getDate() - 1)
dummyPreviousDay.setHours(12)

let scheduledDummyData: ScheduledReport[] = [
  {
    id: '1',
    name: BarracudaReportTypes.inboundTraffic,
    timeRange: ShortcutItems.last30days,
    format: ScheduleReportFormat.csv,
    frequency: ScheduleReportFrequency.daily,
    at: '12:00 AM',
    recipients:
      'User1@abcopmany.com,User12345@abcopmany.com,User1098@abcopmany.com,User1097@abcopmany.com,User1096@abcopmany.com,User1095@abcopmany.com,User1094@abcopmany.com,User1093@abcopmany.com,User1092@abcopmany.com,User1091@abcopmany.com,User1090@abcopmany.com,User1089@abcopmany.com,User1088@abcopmany.com,User1078@abcopmany.com,User1068@abcopmany.com',
    nextSchedule: dummyNextDay.getTime(),
    lastSchedule: dummyPreviousDay.getTime(),
    status: ScheduledReportStatus.enabled
  },
  {
    id: '2',
    name: BarracudaReportTypes.topInboundEmailSenders,
    timeRange: ShortcutItems.last30days,
    format: ScheduleReportFormat.csv,
    frequency: ScheduleReportFrequency.daily,
    at: '12:00 AM',
    recipients: 'User1@abcopmany.com',
    nextSchedule: new Date().getHours() > 12 ? dummyNextDay.getTime() : Date.now(),
    lastSchedule: dummyPreviousDay.getTime(),
    status: ScheduledReportStatus.disabled
  }
]
