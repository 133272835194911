import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  determinationBox: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2),
    width: '100%',
    '&.purple100': {
      backgroundColor: colors.purple100
    },
    '&.green050': {
      backgroundColor: colors.green050
    },
    '&.red200': {
      backgroundColor: colors.red200
    },
    '&.orange100': {
      backgroundColor: colors.orange100
    },
    '&.redOrange200': {
      backgroundColor: colors.redOrange200
    },
    '&.indigo100': {
      backgroundColor: colors.indigo100
    },
    '&.scaleGray050': {
      backgroundColor: colors.scaleGray050
    }
  },
  determinationIcon: {
    height: 20,
    margin: theme.spacing(0.5, 1, 0, 0),
    width: 20
  },
  determinationText: {
    lineHeight: 1.4,
    padding: theme.spacing(0.5, 0)
  },
  headerSubject: {
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  headerDate: {
    fontSize: '0.75rem',
    paddingRight: theme.spacing(2),
    textAlign: 'right'
  },
  headerSummaryDetail: {
    boxShadow: 'none',
    paddingTop: 0,
    position: 'inherit',
    '&.Mui-expanded': {
      margin: 0
    }
  },
  headerAccordionSummary: {
    minHeight: 40,
    width: 'max-content',
    '&.Mui-expanded': {
      margin: 0,
      minHeight: 40
    },
    '& > div:first-child': {
      margin: 0,
      minHeight: 40
    },
    '& > div:last-child': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1)
    }
  },
  headerFromTo: {
    '& table': {
      borderSpacing: 0,
      fontFamily: 'Roboto',
      width: '100%'
    },
    // From: and To: labels
    '& td:first-child': {
      color: colors.barracudaBlack70,
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      height: 20,
      textAlign: 'right',
      whiteSpace: 'nowrap',
      width: 88
    },
    // From: address
    '& tr:nth-child(1) td:nth-child(2)': {
      fontFamily: 'Roboto',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 500,
      paddingLeft: 5
    },
    // To: address
    '& tr:nth-child(2) td:nth-child(2)': {
      fontFamily: 'Roboto',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      paddingLeft: theme.spacing(0.5)
    }
  },
  headerDetails: {
    padding: theme.spacing(0, 2, 2),
    '& table': {
      borderSpacing: 0,
      fontFamily: 'Roboto',
      width: '100%'
    },
    // Labels
    '& #details > tbody > tr > td:first-child': {
      color: colors.barracudaBlack70,
      fontSize: '0.75rem',
      height: 20,
      textAlign: 'right',
      whiteSpace: 'nowrap',
      width: 88
    },
    // Value
    '& #details > tbody > tr > td:nth-child(2)': {
      fontSize: '0.75rem',
      paddingLeft: theme.spacing(0.5)
    },
    '& #details > tbody > tr:last-child > td:nth-child(1)': {
      fontSize: '0.75rem',
      paddingTop: theme.spacing(0.75),
      verticalAlign: 'top'
    }
  },
  recipients: {
    marginTop: theme.spacing(0.5),
    width: 'fit-content',
    '& th': {
      color: theme.palette.text.secondary,
      fontSize: '0.75rem',
      fontWeight: 500,
      paddingBottom: theme.spacing(0.5),
      textAlign: 'left',
      textTransform: 'uppercase'
    },
    '& td': {
      fontSize: '0.75rem',
      padding: theme.spacing(0, 2, 0, 0),
      verticalAlign: 'top',
      whiteSpace: 'normal'
    },
    '& td:nth-child(4)': {
      maxWidth: 'none',
      wordBreak: 'normal'
    }
  },
  attachmentLink: {
    cursor: 'pointer',
    pointerEvents: 'auto',

    '&.disabled': {
      cursor: 'auto',
      color: theme.palette.text.secondary,
      pointerEvents: 'none'
    }
  },
  findSimilar: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),

    '& a': {
      alignItems: 'center',
      display: 'flex',

      '& svg': {
        fontSize: '1.2rem',
        marginLeft: theme.spacing(1)
      }
    }
  }
}))
