import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  OUTBOUND_SETTINGS: {
    path: 'outbound-settings',
    legacyPath: 'outbound',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_SENDER_IP: {
    path: 'outbound-settings/sender-ip',
    legacyPath: 'outbound/sender_ip',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_SENDER_IP_BULK_EDIT: {
    path: 'outbound-settings/sender-ip/bulk-edit',
    legacyPath: 'settings/bulk_edit/sender_ips',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_TAGLINE: {
    path: 'outbound-settings/tagline',
    legacyPath: 'outbound/tagline',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_ENCRYPTION: {
    path: 'outbound-settings/encryption',
    legacyPath: 'outbound/encryption',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_ENCRYPTION_BULK_EDIT: {
    path: 'outbound-settings/encryption/bulk-edit',
    legacyPath: 'settings/bulk_edit/encryption_domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_CONTENT_POLICIES: {
    path: 'outbound-settings/content-policies',
    legacyPath: 'outbound/content_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT: {
    path: 'outbound-settings/attachment-filters/bulk-edit',
    legacyPath: 'settings/bulk_edit/outbound_attachment_filters',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT: {
    path: 'outbound-settings/message-content/bulk-edit',
    legacyPath: 'settings/bulk_edit/outbound_message_content_filters',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_PREDEFINED_FILTERS_BULK_EDIT: {
    path: 'outbound-settings/predefined-filters/bulk-edit',
    legacyPath: 'settings/bulk_edit/outbound_predefined_message_content_filters',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_PREDEFINED_FILTERS_EXCEPTIONS_BULK_EDIT: {
    path: 'outbound-settings/predefined-filters-exceptions/bulk-edit',
    legacyPath: 'settings/bulk_edit/outbound_predefined_message_content_filter_exceptions',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  },
  OUTBOUND_SETTINGS_NOTIFICATIONS: {
    path: 'outbound-settings/notifications',
    legacyPath: 'outbound/sender_notification',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'OUTBOUND_SETTINGS'
    }
  }
}
