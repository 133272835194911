import { useState, useMemo, useCallback } from 'react'

export interface State {
  isHelpOpen: boolean
}

export interface EventHandlers {
  onHelpOpen: () => void
  onHelpClose: () => void
}

export type UseSettingsHeaderLogic = [State, EventHandlers]

export const useSettingsHeaderLogic = (): UseSettingsHeaderLogic => {
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  const onHelpOpen = useCallback(() => {
    setIsHelpOpen(true)
  }, [])

  const onHelpClose = useCallback(() => {
    setIsHelpOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        isHelpOpen
      },
      {
        onHelpOpen,
        onHelpClose
      }
    ],
    [isHelpOpen, onHelpOpen, onHelpClose]
  )
}
