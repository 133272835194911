import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function LinkedAccounts(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      You can add additional email addresses you have in the same domain to forward quarantined email to this account.
      Enter the email address in the <b>Account (Email Address)</b> field, select whether to{' '}
      <b>Link account without verification</b>, and click <b>Add</b>.
      <br />
      <br />
      <b>Note:</b> You can only link email addresses in domains registered to your Barracuda Email Gateway Defense
      account. Check with your account administrator for a list of these domains.
    </div>
  )
}

export default LinkedAccounts
