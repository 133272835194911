import { useState, useMemo, useCallback } from 'react'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { saveSearchSettings } from 'redux/features/mstore/mstoreSlice'
import { Direction, MessageDirection } from 'types/Messages'

export interface UseSaveSearchDialogLogic {
  saveName: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  saveSearch: () => void
}

export const useSaveSearchDialogLogic = (onClose: () => void): UseSaveSearchDialogLogic => {
  const dispatch = useAppDispatch()

  const { searchTerm, searchFilter } = useAppSelector(_store => ({
    searchTerm: _store.mstore.searchTerm,
    searchFilter: _store.mstore.searchFilter
  }))

  const [saveName, setSaveName] = useState('')

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const regex = /^([\sa-z0-9-_.]){1,32}$/i
    if (e.target.value === '' || regex.test(e.target.value)) {
      setSaveName(value)
    }
  }, [])

  const saveSearch = useCallback(() => {
    const newSearchSettings = {
      name: saveName,
      id: MD5(makeUuid()).toString(),
      messageSearchFilters: {
        selectedDomainId: searchTerm.domain_id,
        direction: searchTerm.outbound === MessageDirection.OUTBOUND ? Direction.OUTBOUND : Direction.INBOUND,
        dateRange: searchTerm.dateRange,
        start: searchTerm.start,
        end: searchTerm.end,
        action: searchFilter.action,
        deliveryStatus: searchFilter.delivery_status,
        reason: searchFilter.reason,
        search_str: searchTerm.search_str
      }
    }
    dispatch(saveSearchSettings(newSearchSettings))

    onClose()
  }, [saveName, searchTerm, searchFilter, dispatch, onClose])

  return useMemo(
    () => ({
      saveName,
      handleInputChange,
      saveSearch
    }),
    [saveName, handleInputChange, saveSearch]
  )
}
