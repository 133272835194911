import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, azureAdApi, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import {
  AzureUserStatus,
  DisconnectResult,
  Resource,
  SyncMode,
  SyncNowStatus,
  SyncStats,
  SyncStatus,
  TenantStatus
} from 'types/azureAd'
import { RootState } from 'redux/toolkit/store'

export interface AzureAdApiSignedUrlRequest {
  domainId: string
  resource: Resource
  tenantState?: string
  accountId?: string
  syncedAt?: number
  syncMode?: SyncMode
  testUser?: string
}

const getSignedUrl = async (request: AzureAdApiSignedUrlRequest): Promise<string> => {
  const { domainId, ...data } = request
  const res = await restClient(apiRoutes.GET_SIGNED_AZURE_AD_API_URL, {
    data,
    urlParams: { domainId }
  })
  return res.data
}

export interface TenantStatusRequest {
  domainId: string
}

export interface TenantStatusResponse {
  tenantName: string
  status: TenantStatus
  message: string
  authorizedAt: number
}

export const getAzureAdTenantStatus = createAsyncThunk<TenantStatusResponse, TenantStatusRequest, ApiRejectResponse>(
  'AZURE_AD/getAzureAdTenantStatus',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await azureAdApi.get(
        await getSignedUrl({
          domainId: payload.domainId,
          resource: Resource.TENANT_STATUS
        })
      )
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface AdminConsentRequest {
  domainId: string
  tenantState: string
}

export const getAzureAdAdminConsent = createAsyncThunk<void, AdminConsentRequest, ApiRejectResponse>(
  'AZURE_AD/getAzureAdAdminConsent',
  async (payload, { rejectWithValue }) => {
    try {
      window.location.href = await getSignedUrl({
        domainId: payload.domainId,
        resource: Resource.ADMIN_CONSENT,
        tenantState: payload.tenantState
      })
      return undefined
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface DisconnectRequest {
  domainId: string
}

export const postAzureAdDisconnect = createAsyncThunk<DisconnectResult, DisconnectRequest, ApiRejectResponse>(
  'AZURE_AD/postAzureAdDisconnect',
  async (payload, { rejectWithValue }) => {
    try {
      await restClient(apiRoutes.POST_DISABLE_AZURE_AD_OPTIONS, {
        urlParams: {
          domainId: payload.domainId
        }
      })
      await azureAdApi.get(
        await getSignedUrl({
          domainId: payload.domainId,
          resource: Resource.DISCONNECT
        })
      )
      return DisconnectResult.SUCCESS
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface SyncStatsRequest {
  domainId: string
}

export const getAzureAdSyncStats = createAsyncThunk<SyncStats, SyncStatsRequest, ApiRejectResponse>(
  'AZURE_AD/getAzureAdSyncStats',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await restClient(apiRoutes.GET_AZURE_AD_API_SYNC_STATS, {
        urlParams: { domainId: payload.domainId }
      })
      return res.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface SyncNowRequest {
  domainId: string
}

export const postAzureAdSyncNow = createAsyncThunk<SyncNowStatus, SyncNowRequest, ApiRejectResponse>(
  'AZURE_AD/postAzureAdSyncNow',
  async (payload, { rejectWithValue, getState }) => {
    try {
      // Get current sync stats before kicking off the sync flow
      const { data: currentStats }: { data: SyncStats } = await restClient(apiRoutes.GET_AZURE_AD_API_SYNC_STATS, {
        urlParams: { domainId: payload.domainId }
      })

      // Bail out if current sync status is InProgress
      if (currentStats.syncStatus === SyncStatus.IN_PROGRESS) {
        return SyncNowStatus.SYNC_ALREADY_IN_PROGRESS
      }

      // Reset the sync stats in the ESS DB
      await restClient(apiRoutes.POST_RESET_AZURE_AD_SYNC_STATS, {
        urlParams: { domainId: payload.domainId }
      })

      // Call the Azure microservice, request starting the user sync
      const accountId = (getState() as RootState).auth.accessTokenObject?.accountId
      await azureAdApi.get(
        await getSignedUrl({
          domainId: payload.domainId,
          resource: Resource.SYNC_NOW,
          accountId,
          syncedAt: 0,
          syncMode: SyncMode.MANUAL
        })
      )

      // Indicate the sync flow has been started, polling fresh stats can be started
      return SyncNowStatus.SYNC_STARTED
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface LookupUserRequest {
  signedUrl: string
}

export const getAzureAdLookupUser = createAsyncThunk<AzureUserStatus, LookupUserRequest, ApiRejectResponse>(
  'AZURE_AD/getAzureAdLookupUser',
  async (payload, { rejectWithValue }) => {
    try {
      await azureAdApi.get(payload.signedUrl)
      return AzureUserStatus.PRESENT
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export interface LookupUserSignedUrlRequest {
  domainId: string
  testUser: string
}

export const getAzureAdLookupUserSignedUrl = createAsyncThunk<string, LookupUserSignedUrlRequest, ApiRejectResponse>(
  'AZURE_AD/getAzureAdLookupUserSignedUrl',
  async (payload, { rejectWithValue }) => {
    try {
      return await getSignedUrl({
        domainId: payload.domainId,
        resource: Resource.LOOKUP_USER,
        testUser: payload.testUser
      })
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
