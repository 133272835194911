import React, { useMemo } from 'react'
import IncompleteWizardBanner from 'components/libs/banner/IncompleteWizardBanner'
import GlobalMessageBanner from 'components/libs/banner/globalMessageBanner/GlobalMessageBanner'
import { useBannersLogic } from 'components/libs/banner/useBannersLogic'
import PreMailflowBanner from 'components/libs/banner/PreMailflowBanner'

const Banners = () => {
  const [{ wizardUrl, incompleteWizardBanner, preMailflowBanner }] = useBannersLogic()
  return useMemo(
    () => (
      <>
        {incompleteWizardBanner && <IncompleteWizardBanner wizardUrl={wizardUrl} />}
        {preMailflowBanner && <PreMailflowBanner />}
        <GlobalMessageBanner />
      </>
    ),
    [incompleteWizardBanner, preMailflowBanner, wizardUrl]
  )
}

export default Banners
