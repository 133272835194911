const ADMIN_EVENTS = {
  // users
  USERS_LIST_PAGE_VIEW: 'Users - Users list Pageview',
  DEFAULT_POLICY_PAGE_VIEW: 'Users - Default policy Pageview',
  ADD_UPDATE_USER_PAGE_VIEW: 'Users - Add/Update Users Pageview',
  QUARANTINE_NOTIFICATION_PAGE_VIEW: 'Users - Quarantine notification PageView',
  EMAIL_CONTINUITY_PAGE_VIEW: 'Users - Email Continuity pageview'
} as const

export default ADMIN_EVENTS
