import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { isEqual } from 'lodash'
import { DateTime } from 'luxon'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import usePreviousValue from 'lib/usePreviousValue'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import {
  getReport,
  getReportsList,
  resetReport,
  resetReportsList,
  resetSetCustomReportsList,
  resetSetPinnedReportId,
  setPinnedReportId,
  setSavedReportId,
  updateFilters
} from 'redux/features/reports/reportsSlice'
import {
  BarracudaReport,
  BarracudaReportTypes,
  CustomReport,
  EmailFlows,
  paginatedReports,
  RelativeDateRanges,
  ReportIntervals,
  ReportsListItem,
  ReportTypes
} from 'types/reports'
import { useFormatMessage } from 'lib/localization'
import { TitleBarLogic, useTitleBarLogic } from 'components/pages/reports/reportList/useTitleBarLogic'
import { ChartData, useChartsLogic } from 'components/pages/reports/reportList/useChartsLogic'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { getAvailableDomains } from 'redux/features/user/userSlice'

export interface ReportsListObject {
  label: string
  count: number
  items: ReportsListItem[]
}

export type UIReportsList = ReportsListObject[]
export type ChartIndex = 0 | 1
export type IsDisabledDropdown = boolean

export interface State {
  isInitialLoading: boolean
  isFailedInitialLoad: boolean
  isGetReportsListFailed: boolean
  isFetchingReport: boolean
  isFailedToFetchingReport: boolean
  isSavingReport: boolean
  isScheduleReportOpen: boolean
  reportsList?: UIReportsList
  selectedListItem?: ReportsListItem
  pinnedListItem?: ReportsListItem
  selectedChartIndex: ChartIndex
  selectedInterval: ReportIntervals
  intervalAnchorEl: HTMLElement | null
  intervalDropdownConfig: [ReportIntervals[], IsDisabledDropdown]
}

export interface EventHandlers {
  onSearch: Dispatch<SetStateAction<string>>
  onSelectListItem: Dispatch<SetStateAction<ReportsListItem | undefined>>
  onPinnedListItem: (newPinnedListItem: ReportsListItem) => void
  onSelectChartIndex: Dispatch<SetStateAction<ChartIndex>>
  onSelectInterval: (interval: ReportIntervals) => void
  onSetIntervalAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
  onCloseScheduleReport: () => void
}

export const OUTBOUND_EMAIL_TYPE_REPORTS = [
  BarracudaReportTypes.outboundTraffic,
  BarracudaReportTypes.topOutboundBlockedSenders,
  BarracudaReportTypes.topOutboundEmailSenders
]

export const ALL_DOMAINS = 'all_domains'
const BASE_I18N_KEY = 'ess.reports'

export const useReportsListLogic = (): [State, EventHandlers, TitleBarLogic, ChartData] => {
  const dispatch = useAppDispatch()
  const {
    isGetAvailableDomainsInProgress,
    isGetAvailableDomainsFailed,
    isGetReportsListInProgress,
    isGetReportsListSuccess,
    isGetReportsListFailed,
    isGetReportInProgress,
    isGetReportFailed,
    isSavingPinnedReportId,
    isSetPinnedReportIdInProgress,
    isSetPinnedReportIdSuccess,
    isSetPinnedReportIdFailed,
    isSavingReport,
    reportsList,
    filterValues,
    savedReportId
  } = useAppSelector(_store => ({
    isGetAvailableDomainsInProgress: isPending(_store.user.api.getAvailableDomainsApiStatus),
    isGetAvailableDomainsFailed: isFailed(_store.user.api.getAvailableDomainsApiStatus),
    isGetReportsListInProgress: isPending(_store.reports.api.getReportsListApiStatus),
    isGetReportsListSuccess: isSuccess(_store.reports.api.getReportsListApiStatus),
    isGetReportsListFailed: isFailed(_store.reports.api.getReportsListApiStatus),
    isGetReportInProgress: isPending(_store.reports.api.getReportApiStatus),
    isGetReportFailed: isFailed(_store.reports.api.getReportApiStatus),
    isSavingReport: isPending(_store.reports.api.setCustomReportsListApiStatus),
    isSavingPinnedReportId: isPending(_store.reports.api.setPinnedReportIdApiStatus),
    isSetPinnedReportIdInProgress: isPending(_store.reports.api.setPinnedReportIdApiStatus),
    isSetPinnedReportIdSuccess: isSuccess(_store.reports.api.setPinnedReportIdApiStatus),
    isSetPinnedReportIdFailed: isFailed(_store.reports.api.setPinnedReportIdApiStatus),
    savedReportId: _store.reports.savedReportId,
    reportsList: _store.reports.list,
    filterValues: _store.reports.filters
  }))
  const [isScheduleReportOpen, setIsScheduleReportOpen] = useState(false)

  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const buildPredefinedListItem: (type: BarracudaReportTypes) => ReportsListItem = useCallback(
    (type: BarracudaReportTypes) => ({
      id: String(type),
      type,
      label: formatMessage(`predefined_report_types.${type}`)
    }),
    [formatMessage]
  )

  const onReportCallback = useCallback(() => {
    setIsScheduleReportOpen(true)
  }, [])
  const onCloseScheduleReport = useCallback(() => {
    setIsScheduleReportOpen(false)
  }, [])

  const [search, setSearch] = useState<string>('')
  const [selectedListItem, setSelectedListItem] = useState<ReportsListItem | undefined>()
  const [pinnedListItem, setPinnedListItem] = useState<ReportsListItem | undefined>()
  const [selectedChartIndex, setSelectedChartIndex] = useState<ChartIndex>(0)
  const [intervalAnchorEl, setIntervalAnchorEl] = useState<HTMLElement | null>(null)
  const [titleBarLogic] = useTitleBarLogic(onReportCallback, selectedListItem)
  const [chartData] = useChartsLogic(selectedListItem, selectedChartIndex)
  const prevSelectedListItem = usePreviousValue(selectedListItem)
  const prevReportsList = usePreviousValue(reportsList)
  const prevIsSetPinnedReportIdFailed = usePreviousValue(isSetPinnedReportIdFailed)
  const prevIsSetPinnedReportIdSuccess = usePreviousValue(isSetPinnedReportIdSuccess)
  const prevIsSetPinnedReportIdInProgress = usePreviousValue(isSetPinnedReportIdInProgress)
  const prevIsSavingReport = usePreviousValue(isSavingReport)

  // init
  useEffect(() => {
    dispatch(getAvailableDomains())
    dispatch(getReportsList())

    return () => {
      dispatch(getAvailableDomains())
      dispatch(resetReportsList())
      dispatch(resetReport())
      dispatch(resetSetCustomReportsList())
      dispatch(resetSetPinnedReportId())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // scroll to the selected item on init
  useEffect(() => {
    if (isGetReportsListSuccess) {
      let interval: any
      const scrollToPinnedItem = () => {
        const pinnedListElement = document.getElementById('selected-report-id')
        if (pinnedListElement) {
          pinnedListElement.scrollIntoView()
          clearInterval(interval)
        }
      }

      if (!interval) {
        interval = setInterval(scrollToPinnedItem, 100)
      }
    }
  }, [isGetReportsListSuccess])

  const intervalDropdownConfig: [ReportIntervals[], boolean] = useMemo(() => {
    const isInInterval = (dateRanges: RelativeDateRanges[], dayRange: number) =>
      (filterValues.relativeDateRange && dateRanges.includes(filterValues.relativeDateRange)) ||
      ((filterValues.startDate &&
        filterValues.endDate &&
        DateTime.fromISO(filterValues.endDate).diff(DateTime.fromISO(filterValues.startDate), ['days']).toObject()
          ?.days) ||
        0) +
        1 <=
        dayRange

    // default
    if (!filterValues) {
      return [[], false]
    }

    // less than 2 days
    if (isInInterval([RelativeDateRanges.lastDay], 2)) {
      return [[ReportIntervals.weekly, ReportIntervals.daily], true]
    }

    // less than 3 days
    if (isInInterval([], 3)) {
      return [[ReportIntervals.weekly], false]
    }

    // less than 8 days
    if (isInInterval([RelativeDateRanges.last7Days], 7)) {
      return [[ReportIntervals.hourly, ReportIntervals.weekly], true]
    }

    return [[ReportIntervals.hourly], false]
  }, [filterValues])

  // disabled intervals are changed
  useEffect(() => {
    const [disabledIntervals] = intervalDropdownConfig
    if (disabledIntervals.includes(filterValues.interval)) {
      const newInterval = Object.values(ReportIntervals).find(interval => !disabledIntervals.includes(interval))
      if (newInterval) {
        dispatch(updateFilters({ interval: newInterval }))
      }
    }
  }, [dispatch, intervalDropdownConfig, filterValues])

  // set new pinned item is success
  useEffect(() => {
    if (isSetPinnedReportIdSuccess && !prevIsSetPinnedReportIdSuccess) {
      dispatch(
        setSuccessSnackBar({ message: 'set_pinned_report_id_success', params: [prevSelectedListItem?.label || ''] })
      )
    }
  }, [isSetPinnedReportIdSuccess, prevIsSetPinnedReportIdSuccess, prevSelectedListItem, dispatch])

  // set new pinned item is failed
  useEffect(() => {
    if (isSetPinnedReportIdFailed && !prevIsSetPinnedReportIdFailed) {
      dispatch(
        setErrorSnackBar({ message: 'set_pinned_report_id_failed', params: [prevSelectedListItem?.label || ''] })
      )
      setSelectedListItem(prevSelectedListItem)
    }
  }, [isSetPinnedReportIdFailed, prevIsSetPinnedReportIdFailed, prevSelectedListItem, dispatch])

  // selected list item or filters are updated
  useEffect(() => {
    if (!selectedListItem || isGetAvailableDomainsFailed) {
      return
    }

    // new selected list item
    if (prevSelectedListItem !== selectedListItem) {
      setSelectedChartIndex(0)
      const selectedCustomReport = reportsList?.customReports.find(
        (customReport: CustomReport) => customReport.id === selectedListItem.id
      )

      // update filters first if new custom report is selected and the new filter settings are different
      if (selectedCustomReport && !isEqual(selectedCustomReport.filters, filterValues)) {
        dispatch(updateFilters(selectedCustomReport.filters))
        // set direction to outbound first for the outbound type Barracuda reports
      } else if (
        !selectedCustomReport &&
        OUTBOUND_EMAIL_TYPE_REPORTS.includes(selectedListItem.type) &&
        !filterValues.isDirty
      ) {
        dispatch(updateFilters({ direction: EmailFlows.outbound, isDirty: true }))
      } else {
        dispatch(
          getReport({
            id: selectedListItem.id,
            type: selectedListItem.type,
            reportIsChanged: true,
            paginated: paginatedReports.includes(selectedListItem.type)
          })
        )
      }
      // filters are changed and filters are valid
    } else if (filterValues.isDirty && filterValues.isValid) {
      dispatch(
        getReport({
          id: selectedListItem.id,
          type: selectedListItem.type,
          reportIsChanged: false,
          paginated: paginatedReports.includes(selectedListItem.type)
        })
      )
    }
  }, [
    isGetAvailableDomainsFailed,
    selectedListItem,
    dispatch,
    prevSelectedListItem,
    filterValues,
    reportsList,
    isSetPinnedReportIdInProgress,
    prevIsSetPinnedReportIdInProgress
  ])

  // set pinned item when reports list is loaded
  useEffect(() => {
    if (!prevReportsList && reportsList) {
      let selectedItem: ReportsListItem | undefined

      const isPinnedBarracudaReport = reportsList.barracudaReports.some(
        (listItem: BarracudaReport) => listItem.id === reportsList.pinnedReportId
      )
      if (isPinnedBarracudaReport) {
        selectedItem = buildPredefinedListItem(reportsList.pinnedReportId as BarracudaReportTypes)
      } else {
        selectedItem = reportsList.customReports.find(
          (listItem: ReportsListItem) => listItem.id === reportsList?.pinnedReportId
        )
      }

      if (selectedItem) {
        setPinnedListItem(selectedItem)
        setSelectedListItem(selectedItem)
      } else {
        setSelectedListItem(buildPredefinedListItem(BarracudaReportTypes.inboundTraffic))
      }
    }
  }, [prevReportsList, reportsList, pinnedListItem, buildPredefinedListItem, dispatch])

  const uiReportsList: UIReportsList = useMemo(() => {
    const predefinedBarracudaReports = (reportsList?.barracudaReports || []).filter((report: BarracudaReport) =>
      Object.values(BarracudaReportTypes).includes(report.id)
    )

    const baseList = [
      {
        label: formatMessage(`report_types.${ReportTypes.custom}`),
        count: reportsList?.customReports.length || 0,
        items: reportsList?.customReports || []
      },
      {
        label: formatMessage(`report_types.${ReportTypes.barracuda}`),
        count: predefinedBarracudaReports.length,
        items: predefinedBarracudaReports.map((report: BarracudaReport) => buildPredefinedListItem(report.id))
      }
    ]

    return baseList.reduce((filteredList: UIReportsList, reportListObject: ReportsListObject) => {
      const items = reportListObject.items.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      if (!items.length && !!search) {
        return filteredList
      }

      return [
        ...filteredList,
        {
          ...reportListObject,
          count: items.length,
          items
        }
      ]
    }, [])
  }, [search, reportsList, formatMessage, buildPredefinedListItem])

  // select the newly saved report after the api call is successes
  useEffect(() => {
    if (!isSavingReport && prevIsSavingReport && savedReportId && reportsList) {
      const justSavedListItem = uiReportsList[0].items.find(reportListItem => reportListItem.id === savedReportId)

      dispatch(setSavedReportId(undefined))
      setSelectedListItem(justSavedListItem)
      // scroll if new report is saved
      if (justSavedListItem && justSavedListItem?.id !== selectedListItem?.id) {
        window.setTimeout(() => document.getElementById('selected-report-id')?.scrollIntoView(), 100)
      }
    }
  }, [dispatch, isSavingReport, prevIsSavingReport, savedReportId, uiReportsList, selectedListItem, reportsList])

  const onPinnedListItem = useCallback(
    (newPinnedListItem: ReportsListItem) => {
      const validatedListItem: ReportsListItem | undefined =
        pinnedListItem?.id === newPinnedListItem.id ? undefined : newPinnedListItem
      if (validatedListItem) {
        setPinnedListItem(validatedListItem)
        dispatch(setPinnedReportId(newPinnedListItem.id))
      }
    },
    [dispatch, pinnedListItem]
  )

  const onSelectInterval = useCallback(
    (interval: ReportIntervals) => {
      if (filterValues.interval !== interval) {
        dispatch(updateFilters({ interval }))
      }
      setIntervalAnchorEl(null)
    },
    [dispatch, filterValues]
  )

  return useMemo(
    () => [
      {
        isInitialLoading: isGetAvailableDomainsInProgress || (isGetReportsListInProgress && !reportsList),
        isFailedInitialLoad: isGetAvailableDomainsFailed,
        isGetReportsListFailed,
        isFetchingReport: isGetReportInProgress,
        isFailedToFetchingReport: isGetReportFailed,
        isSavingReport: isSavingReport || isSavingPinnedReportId,
        isScheduleReportOpen,
        reportsList: uiReportsList,
        selectedListItem,
        pinnedListItem,
        selectedChartIndex,
        selectedInterval: filterValues.interval,
        intervalAnchorEl,
        intervalDropdownConfig
      },
      {
        onSearch: setSearch,
        onSelectListItem: setSelectedListItem,
        onPinnedListItem,
        onSelectChartIndex: setSelectedChartIndex,
        onSelectInterval,
        onSetIntervalAnchorEl: setIntervalAnchorEl,
        onCloseScheduleReport
      },
      titleBarLogic,
      chartData
    ],
    [
      isGetAvailableDomainsInProgress,
      isGetReportsListInProgress,
      isGetReportsListFailed,
      isGetReportFailed,
      isSavingReport,
      isScheduleReportOpen,
      uiReportsList,
      reportsList,
      onPinnedListItem,
      selectedListItem,
      pinnedListItem,
      titleBarLogic,
      chartData,
      selectedChartIndex,
      setSelectedChartIndex,
      filterValues,
      onSelectInterval,
      intervalAnchorEl,
      setIntervalAnchorEl,
      isSavingPinnedReportId,
      isGetReportInProgress,
      isGetAvailableDomainsFailed,
      onCloseScheduleReport,
      intervalDropdownConfig
    ]
  )
}
