import React, { ChangeEvent, useMemo } from 'react'
import {
  Alert,
  Button,
  DataTable,
  DataTableColumn,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import { GridNoRecords } from '@progress/kendo-react-grid'
import { InputAdornment } from '@material-ui/core'
import styles from 'components/pages/domains/editDomain/editDomainMailServers/editDomainMailServersStyles'
import {
  EmailServerUiState,
  ModifiedEmailServer,
  useEditDomainMailServersLogic
} from 'components/pages/domains/editDomain/editDomainMailServers/useEditDomainMailServersLogic'
import { useFormatMessage } from 'lib/localization'
import { Cell } from 'components/libs/grid/cell'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'
import { FormRow } from 'components/libs/layout/pages/FormRow'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'

export interface EditDomainMailServersProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains'

const EditDomainMailServers = ({ form }: EditDomainMailServersProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditDomainMailServersLogic(form)
  const classes = styles()

  return useMemo(
    () => (
      <FormBlock
        title={formatMessage('edit_domain.mail_servers.title')}
        description={formatMessage('edit_domain.mail_servers.description')}
      >
        <Dialog open={state.isConfirmDialogOpen} maxWidth="md" fullWidth>
          <DialogTitle>{formatMessage('edit_domain.mail_servers.confirm_removal')}</DialogTitle>
          <DialogContent>
            <Grid container className={classes.warningGrid}>
              <Grid item className={classes.warningGridIcon}>
                <Warning className={classes.warningIcon} />
              </Grid>
              <Grid item className={classes.warningGridBody}>
                <Typography variant="body1">
                  {formatMessage('edit_domain.mail_servers.confirm_removal_body', {
                    span: (txt: string) => <span>{txt}</span>,
                    serverName: state.inProgressServerName
                  })}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={eventHandlers.onConfirmRemoval}
              disabled={state.isRemoveMailServerPending}
              isLoading={state.isRemoveMailServerPending}
            >
              {formatMessage('edit_domain.mail_servers.remove')}
            </Button>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={eventHandlers.onCloseConfirmDialog}
              disabled={state.isTestMailServerPending}
            >
              {formatMessage('edit_domain.mail_servers.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={state.isTestMailServerDialogOpen} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Typography variant="body1">
              {formatMessage('edit_domain.mail_servers.test_configuration')}:
              <br />
              <b>{state.inProgressServerName}</b>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row">
              <Grid item>
                {state.smtpErrorDetails && (
                  <Alert className={classes.smtpErrorDetails} severity="error">
                    {state.smtpErrorDetails}
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <TextField
                  className={classes.emailTestRecipientUser}
                  size="small"
                  label="Recipient"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className={classes.emailTestRecipientDomain} position="end">
                        @{state.domainName}
                      </InputAdornment>
                    )
                  }}
                  onChange={eventHandlers.onChangeRecipient}
                  value={state.recipient}
                  error={!!state.errors.recipientError}
                  helperText={
                    state.errors.recipientError
                      ? formatMessage(`edit_domain.mail_servers.${state.errors.recipientError}`)
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={eventHandlers.onTestMailServer}
              disabled={state.isTestMailServerPending}
              isLoading={state.isTestMailServerPending}
            >
              {formatMessage('edit_domain.mail_servers.send')}
            </Button>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={eventHandlers.onCloseTestMailServerDialog}
              disabled={state.isTestMailServerPending}
            >
              {formatMessage('edit_domain.mail_servers.cancel')}
            </Button>
          </DialogActions>
        </Dialog>

        <DataTable data={state.tableData}>
          <GridNoRecords> </GridNoRecords>
          <DataTableColumn
            title={formatMessage('edit_domain.mail_servers.address')}
            cell={({ dataItem }: { dataItem: ModifiedEmailServer }) => (
              <Cell>
                {dataItem.uiState === EmailServerUiState.EDIT && (
                  <TextField
                    fullWidth
                    className={classes.textInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      eventHandlers.onChangeHost(dataItem.id, e.target.value)
                    }
                    size="small"
                    name="address"
                    error={!!state.errors.hostError}
                    helperText={
                      state.errors.hostError ? formatMessage(`edit_domain.mail_servers.${state.errors.hostError}`) : ''
                    }
                    inputProps={{
                      ref: (ref: HTMLInputElement | null) => eventHandlers.collectAddressRef(dataItem.id, ref)
                    }}
                    defaultValue={state.refs.hostRefs.get(dataItem.id)?.value || dataItem.host}
                  />
                )}
                {dataItem.uiState === EmailServerUiState.DISPLAY && (
                  <Typography variant="body2">{dataItem.host}</Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            title={formatMessage('edit_domain.mail_servers.priority')}
            width={200}
            cell={({ dataItem }: { dataItem: ModifiedEmailServer }) => (
              <Cell>
                {dataItem.uiState === EmailServerUiState.EDIT && (
                  <TextField
                    className={classes.textInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      eventHandlers.onChangePreference(dataItem.id, e.target.value)
                    }
                    size="small"
                    name="priority"
                    error={!!state.errors.preferenceError}
                    helperText={
                      state.errors.preferenceError
                        ? formatMessage(`edit_domain.mail_servers.${state.errors.preferenceError}`)
                        : ''
                    }
                    inputProps={{
                      ref: (ref: HTMLInputElement | null) => eventHandlers.collectPriorityRef(dataItem.id, ref)
                    }}
                    defaultValue={state.refs.preferenceRefs.get(dataItem.id)?.value || dataItem.preference}
                  />
                )}
                {dataItem.uiState === EmailServerUiState.DISPLAY && (
                  <Typography variant="body2">{dataItem.preference}</Typography>
                )}
              </Cell>
            )}
          />
          <DataTableColumn
            width={300}
            cell={({ dataItem }: { dataItem: ModifiedEmailServer }) => (
              <Cell>
                {dataItem.uiState === EmailServerUiState.EDIT && dataItem.id === '' && (
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    onClick={() => eventHandlers.onAddMailServer(dataItem.id)}
                    disabled={state.inProgress}
                    isLoading={state.isAddMailServerPending && state.inProgressServerId === dataItem.id}
                  >
                    {formatMessage('edit_domain.mail_servers.add')}
                  </Button>
                )}
                {dataItem.uiState === EmailServerUiState.EDIT && dataItem.id !== '' && (
                  <>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => eventHandlers.toggleEmailServerUiState(dataItem.id)}
                      disabled={state.inProgress}
                    >
                      {formatMessage('edit_domain.mail_servers.cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => eventHandlers.onUpdateMailServer(dataItem.id)}
                      disabled={state.inProgress}
                      isLoading={state.isUpdateMailServerPending && state.inProgressServerId === dataItem.id}
                    >
                      {formatMessage('edit_domain.mail_servers.update')}
                    </Button>
                  </>
                )}
                {dataItem.uiState === EmailServerUiState.DISPLAY && (
                  <>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => eventHandlers.onOpenTestMailServerDialog(dataItem.id)}
                      disabled={state.inProgress}
                    >
                      {formatMessage('edit_domain.mail_servers.test')}
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => eventHandlers.toggleEmailServerUiState(dataItem.id)}
                      disabled={state.inProgress}
                    >
                      {formatMessage('edit_domain.mail_servers.edit')}
                    </Button>
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      onClick={() => eventHandlers.onOpenConfirmDialog(dataItem.id)}
                      disabled={state.inProgress}
                    >
                      {formatMessage('edit_domain.mail_servers.remove')}
                    </Button>
                  </>
                )}
              </Cell>
            )}
          />
        </DataTable>
        <FormSpacer />
        <FormBlock title={formatMessage('edit_domain.mail_servers.mx_server_configuration')} titleVariant="h5">
          <FormRow rowTitle={formatMessage('edit_domain.mail_servers.primary')}>
            <b>{state.mxServers.primary}</b>
          </FormRow>
          <FormRow rowTitle={formatMessage('edit_domain.mail_servers.backup')}>{state.mxServers.backup}</FormRow>
        </FormBlock>
        {state.permissions.canSeeOutboundSmarthost && (
          <FormBlock
            title={formatMessage('edit_domain.mail_servers.outbound_smarthost_configuration')}
            titleVariant="h5"
            bottomSpacerCount={0}
          >
            <FormRow rowTitle={formatMessage('edit_domain.mail_servers.hostname')}>{state.outboundSmarthost}</FormRow>
          </FormBlock>
        )}
      </FormBlock>
    ),
    [classes, eventHandlers, formatMessage, state]
  )
}

export default EditDomainMailServers
