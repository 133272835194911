import { createSlice } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import {
  getAccountSyslog,
  updateAccountSyslog,
  testAccountSyslog,
  deleteAccountSyslog
} from 'redux/features/syslog/syslogApiThunks'
import { SyslogParsed } from 'types/AccountManagement'

export interface SyslogState {
  api: {
    getAccountSyslogsApiStatus: ApiStatus
    updateAccountSyslogApiStatus: ApiStatus
    testAccountSyslogApiStatus: ApiStatus
    deleteAccountSyslogApiStatus: ApiStatus
  }
  accountSyslogs?: SyslogParsed
}

// initialState
export const INITIAL_STATE: SyslogState = {
  api: {
    getAccountSyslogsApiStatus: inIdle,
    updateAccountSyslogApiStatus: inIdle,
    testAccountSyslogApiStatus: inIdle,
    deleteAccountSyslogApiStatus: inIdle
  },
  accountSyslogs: undefined
}

/* eslint-disable no-param-reassign */
export const syslogSlice = createSlice({
  name: 'SYSLOG',
  initialState: INITIAL_STATE,
  reducers: {
    resetAccountSyslog: state => {
      state.api.getAccountSyslogsApiStatus = INITIAL_STATE.api.getAccountSyslogsApiStatus
      state.accountSyslogs = INITIAL_STATE.accountSyslogs
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // get syslog
      .addCase(getAccountSyslog.pending, state => {
        state.api.getAccountSyslogsApiStatus = inProgress
      })
      .addCase(getAccountSyslog.fulfilled, (state, action) => {
        state.api.getAccountSyslogsApiStatus = successResponse
        if (!action.payload.host) {
          state.accountSyslogs = INITIAL_STATE.accountSyslogs
        } else {
          state.accountSyslogs = action.payload
        }
      })
      .addCase(getAccountSyslog.rejected, (state, action) => {
        state.api.getAccountSyslogsApiStatus = failedResponse(action.payload)
      })
      // update syslog
      .addCase(updateAccountSyslog.pending, state => {
        state.api.updateAccountSyslogApiStatus = inProgress
      })
      .addCase(updateAccountSyslog.fulfilled, (state, action) => {
        state.api.updateAccountSyslogApiStatus = successResponse
        state.accountSyslogs = action.payload
      })
      .addCase(updateAccountSyslog.rejected, (state, action) => {
        state.api.updateAccountSyslogApiStatus = failedResponse(action.payload)
      })
      // test syslog
      .addCase(testAccountSyslog.pending, state => {
        state.api.testAccountSyslogApiStatus = inProgress
      })
      .addCase(testAccountSyslog.fulfilled, state => {
        state.api.testAccountSyslogApiStatus = successResponse
      })
      .addCase(testAccountSyslog.rejected, (state, action) => {
        state.api.testAccountSyslogApiStatus = failedResponse(action.payload)
      })
      // delete syslog
      .addCase(deleteAccountSyslog.pending, state => {
        state.api.deleteAccountSyslogApiStatus = inProgress
      })
      .addCase(deleteAccountSyslog.fulfilled, state => {
        state.api.deleteAccountSyslogApiStatus = successResponse
        state.accountSyslogs = INITIAL_STATE.accountSyslogs
      })
      .addCase(deleteAccountSyslog.rejected, (state, action) => {
        state.api.deleteAccountSyslogApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetAccountSyslog, reset } = syslogSlice.actions

export { getAccountSyslog, updateAccountSyslog, testAccountSyslog, deleteAccountSyslog }

export default syslogSlice.reducer
