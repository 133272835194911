import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CircularProgress, Grid } from '@barracuda-internal/bds-core'

import styles from 'components/libs/contentLoader/contentLoaderStyles'
import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'

export interface MaskLoaderProps {
  open: boolean
  message?: string
  fullPage?: boolean
}

function MaskLoader({ open, message, fullPage = false }: MaskLoaderProps) {
  const classes = styles()
  const [contentHeight, setContentHeight] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current && !fullPage) {
      setContentHeight(contentRef.current?.clientHeight)
    }
  }, [fullPage])

  return useMemo(() => {
    if (!open) {
      return null
    }

    return (
      <div className={classes.wrapper}>
        <div
          data-testid="content-statuses-section"
          className={fullPage ? classes.fullSizeStaticContainer : classes.fullSizeContainer}
          style={{ height: fullPage ? 'auto' : contentHeight, zIndex: 99999 }}
        >
          <Grid className={fullPage ? classes.fullSizeOpacityStaticContainer : classes.fullSizeOpacityContainer} />
          <Grid container justifyContent="center" alignContent="center" direction="column">
            <CircularProgress data-testid="loading-state" className={classes.loadingIconWithMessage} />
            <ContentLoaderMessage withIcon={false} message={message} />
          </Grid>
        </div>
      </div>
    )
  }, [classes, open, message, contentHeight, fullPage])
}

export default MaskLoader
