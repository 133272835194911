import { useParams } from 'react-router-dom'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { GridPageChangeEvent } from '@progress/kendo-react-grid'
import routesConfig from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getDomain } from 'redux/features/domains/domainsSlice'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { getUniqueDomainsRecipients, resetUniqueDomainRecipients } from 'redux/features/stats/statsSlice'

export interface State {
  domainId: string | undefined
  domainName: string
  help: {
    isHelpOpen: boolean
  }
  error: string
  isLoading: boolean
  userIdFilter: string
  page: number
  size: number
  recipients: string[]
  total: number
  noRecords: {
    textId: string
    data?: Record<string, any>
  }
}

export interface EventHandlers {
  help: {
    onHelpOpen: () => void
    onHelpClose: () => void
  }
  onGoBack: () => void
  onChangeSearchRecipientFilter: (e: ChangeEvent<HTMLInputElement>) => void
  onSearchRecipient: () => void
  onPageChange: (e: GridPageChangeEvent) => void
}

export type DomainRecipientsLogic = [State, EventHandlers]

export const useUniqueDomainRecipientsLogic = (): DomainRecipientsLogic => {
  const dispatch = useAppDispatch()
  const { domainId } = useParams()
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [userIdFilter, setUserIdFilter] = useState('')
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(500)
  const [noRecords, setNoRecords] = useState<{ textId: string; data: Record<string, any> | undefined }>({
    textId: '',
    data: undefined
  })

  const { isGetDomainPending, domain, error, isGetRecipientsPending, uniqueDomainRecipients, isGetRecipientsSuccess } =
    useAppSelector(_store => ({
      isGetDomainPending: isPending(_store.domains.api.getDomainApiStatus),
      domain: _store.domains.domainList.find(item => item.domainId === domainId) || _store.domains.domain,
      error:
        getErrorMessage(_store.domains.api.getDomainApiStatus) ||
        getErrorMessage(_store.stats.api.getUniqueDomainsRecipientsApiStatus) ||
        '',
      isGetRecipientsPending: isPending(_store.stats.api.getUniqueDomainsRecipientsApiStatus),
      uniqueDomainRecipients: _store.stats.uniqueDomainRecipients,
      isGetRecipientsSuccess: isSuccess(_store.stats.api.getUniqueDomainsRecipientsApiStatus)
    }))

  const domainName = useMemo(() => domain?.domainName || '', [domain])

  const fetchData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    () => {
      if (domainId) {
        dispatch(getUniqueDomainsRecipients({ domainId, page, size, userIdFilter }))
      }
    },
    [dispatch, domainId, page, size, userIdFilter]
  )

  const onHelpOpen = useCallback(() => setIsHelpOpen(true), [])

  const onHelpClose = useCallback(() => setIsHelpOpen(false), [])

  const onGoBack = useCallback(() => {
    routesConfig.DOMAINS.goto()
  }, [])

  const onChangeSearchRecipientFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUserIdFilter(e.target.value)
  }, [])

  const onSearchRecipient = useCallback(() => {
    fetchData()
  }, [fetchData])

  const onPageChange = useCallback(
    (e: GridPageChangeEvent) => {
      const { take, skip } = e.page
      if (take !== size) {
        setPage(1)
      } else {
        setPage(take ? skip / take + 1 : 1)
      }
      setSize(take)
    },
    [size]
  )

  useEffect(() => {
    if (!domainId) {
      routesConfig.DOMAINS.goto()
      return undefined
    }
    return () => {
      dispatch(resetUniqueDomainRecipients())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!domain && domainId) {
      dispatch(getDomain({ domainId }))
    }
  }, [dispatch, domain, domainId])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, domainId])

  useEffect(() => {
    switch (true) {
      case isGetRecipientsSuccess && !uniqueDomainRecipients?.results.length:
        setNoRecords({
          textId: userIdFilter ? 'recipients.no_recipients_found' : 'recipients.no_records_available',
          data: userIdFilter ? { userIdFilter } : undefined
        })
        return
      default:
        setNoRecords({
          textId: '',
          data: undefined
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetRecipientsSuccess])

  return useMemo(
    () => [
      {
        domainId,
        domainName,
        help: { isHelpOpen },
        error,
        isLoading: isGetDomainPending || isGetRecipientsPending,
        userIdFilter,
        page,
        size,
        recipients: uniqueDomainRecipients?.results || [],
        total: uniqueDomainRecipients?.count || 0,
        noRecords
      },
      { help: { onHelpOpen, onHelpClose }, onGoBack, onChangeSearchRecipientFilter, onSearchRecipient, onPageChange }
    ],
    [
      domainId,
      domainName,
      isHelpOpen,
      error,
      isGetDomainPending,
      isGetRecipientsPending,
      userIdFilter,
      page,
      size,
      uniqueDomainRecipients,
      noRecords,
      onHelpOpen,
      onHelpClose,
      onGoBack,
      onChangeSearchRecipientFilter,
      onSearchRecipient,
      onPageChange
    ]
  )
}
