import { makeStyles } from '@barracuda-internal/bds-core'

const commonStyles = {
  display: 'inline-block',
  cursor: 'default'
}

export default makeStyles(theme => ({
  errorDialog: {
    ...commonStyles,
    minWidth: 500
  },
  errorDialogText: {
    ...commonStyles,
    cursor: 'pointer',
    color: theme.palette.error.main
  }
}))
