import layout from './layout'

type CustomTheme = {
  layout: {
    [Key in keyof typeof layout]: (typeof layout)[Key]
  }
}

declare module '@material-ui/core/styles/createTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends CustomTheme {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  layout
}
