import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { isSuccess, isPending, isFailed } from 'redux/toolkit/api'

export type UseSettingsStateLogic = {
  isLoading: boolean
  isSuccess: boolean
  isFailedToLoad: boolean
}

export const useSettingsStateLogic = (): [UseSettingsStateLogic] => {
  const {
    isGetAccountSettingsPending,
    isGetDomainSettingsPending,
    isGetAccountSettingsFailed,
    isGetDomainSettingsFailed,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess,
    isUpdateAccountSettingsPending,
    isUpdateDomainSettingsPending
  } = useAppSelector(_stores => ({
    isGetAccountSettingsPending: isPending(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsPending: isPending(_stores.settings.getDomainSettingsApiStatus),
    isGetAccountSettingsFailed: isFailed(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsFailed: isFailed(_stores.settings.getDomainSettingsApiStatus),
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus),
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus)
  }))

  return useMemo(
    () => [
      {
        isLoading:
          isGetAccountSettingsPending ||
          isGetDomainSettingsPending ||
          isUpdateAccountSettingsPending ||
          isUpdateDomainSettingsPending,
        isSuccess: isGetAccountSettingsSuccess || isGetDomainSettingsSuccess,
        isFailedToLoad: isGetAccountSettingsFailed || isGetDomainSettingsFailed
      }
    ],
    [
      isGetAccountSettingsSuccess,
      isGetDomainSettingsSuccess,
      isGetAccountSettingsPending,
      isGetDomainSettingsPending,
      isGetAccountSettingsFailed,
      isGetDomainSettingsFailed,
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending
    ]
  )
}
