import { AtpExemptEmail, AtpExemptIp, AtpScanMode, AtpSettings } from 'types/atpSettings'
import { AvailableSettings, SettingsObject, SettingValue } from 'types/Settings'

export const asAtpScanMode = (originalValue: any, defaultValue: AtpScanMode | '' = ''): AtpScanMode | '' => {
  if (originalValue === '') {
    return ''
  }
  if (Object.values(AtpScanMode).includes(originalValue)) {
    return originalValue as AtpScanMode
  }
  return defaultValue
}

export const asSettingValue = (originalValue: any, defaultValue: SettingValue | '' = ''): SettingValue | '' => {
  if (originalValue === '') {
    return ''
  }
  if (Object.values(SettingValue).includes(originalValue)) {
    return originalValue as SettingValue
  }
  return defaultValue
}

export const asString = (originalValue: any): string => {
  if (!originalValue) {
    return ''
  }
  return `${originalValue}`
}

export const asAtpExemptEmailArray = (originalValue: any, defaultValue: AtpExemptEmail[] = []): AtpExemptEmail[] => {
  if (originalValue === null) {
    return []
  }
  try {
    const parsed = JSON.parse(originalValue)
    if (Array.isArray(parsed)) {
      return parsed
    }
  } catch {
    return defaultValue
  }
  return defaultValue
}

export const asAtpExemptIpArray = (originalValue: any, defaultValue: AtpExemptIp[] = []): AtpExemptIp[] => {
  if (originalValue === null) {
    return []
  }
  try {
    const parsed = JSON.parse(originalValue)
    if (Array.isArray(parsed)) {
      return parsed
    }
  } catch {
    return defaultValue
  }
  return defaultValue
}

export const toAtpSettings = (settings: SettingsObject): AtpSettings => ({
  atd: asAtpScanMode(settings.atd, AtpScanMode.DISABLE),
  atd_notify: asSettingValue(settings.atd_notify, SettingValue.DISABLED),
  atd_admin_emails: asString(settings.atd_admin_emails),
  atd_exempt_email: asAtpExemptEmailArray(settings.atd_exempt_email, []),
  atd_exempt_ip: asAtpExemptIpArray(settings.atd_exempt_ip, [])
})

export const toSettingsObject = (atpSettings: Partial<AtpSettings>): SettingsObject => {
  const keys = Object.keys(atpSettings)
  return {
    ...(keys.includes(AvailableSettings.ATD) ? { [AvailableSettings.ATD]: String(atpSettings.atd).trim() } : {}),
    ...(keys.includes(AvailableSettings.ATD_NOTIFY)
      ? { [AvailableSettings.ATD_NOTIFY]: String(atpSettings.atd_notify).trim() }
      : {}),
    ...(keys.includes(AvailableSettings.ATD_ADMIN_EMAILS)
      ? {
          [AvailableSettings.ATD_ADMIN_EMAILS]: String(atpSettings.atd_admin_emails)
            .split(',')
            .map(item => item.trim())
            .filter(item => !!item)
            .join(',')
        }
      : {}),
    ...(keys.includes(AvailableSettings.ATD_EXEMPT_EMAIL)
      ? { [AvailableSettings.ATD_EXEMPT_EMAIL]: JSON.stringify(atpSettings.atd_exempt_email) }
      : {}),
    ...(keys.includes(AvailableSettings.ATD_EXEMPT_IP)
      ? { [AvailableSettings.ATD_EXEMPT_IP]: JSON.stringify(atpSettings.atd_exempt_ip) }
      : {})
  }
}
