import { useState, useMemo, useEffect, useCallback } from 'react'
import { v4 as makeUuid } from 'uuid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  AvailableSettings,
  DEFAULT_SETTINGS_OBJECT,
  EmailCategories,
  SettingsObject,
  emailCategories
} from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { filterSettings } from 'components/libs/settings/settings'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'
import { getBlockTransition, setBlockTransition } from 'lib/routes'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

interface FastspamScores {
  block: number
  quarantine: number
}

export interface State {
  canEmailCategorization: boolean
  canQuarantineSettings: boolean
  canSendBrtsEvidence: boolean
  settings: SettingsObject
  key: string
  tableData: {
    total: number
    data: EmailCategories[]
  }
  isWarningDialogOpen: boolean
  isDirtyForm: boolean
  fastSpamScore: FastspamScores
}

export interface EventHandlers {
  onFormChange: (name: AvailableSettings) => (NewValue: any) => void
  onScoringChange: (name: ScoringType, value: number) => void
  onActionChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  onSave: () => void
  onCancelConfirm: () => void
  onCancelWarningDialog: () => void
  onConfirmWarningDialog: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
}

export type UseReputationLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.BRBL,
  AvailableSettings.VSCAN,
  AvailableSettings.BRTS,
  AvailableSettings.FASTSPAM_ENABLED,
  AvailableSettings.FASTSPAM_SCORES,
  AvailableSettings.BULK_EMAIL_DETECTION
]

export enum ScoringType {
  QUARANTINE = 'quarantine',
  BLOCK = 'block'
}

export const useReputationLogic = (): UseReputationLogic => {
  const dispatch = useAppDispatch()
  const [formObject, setFormObject] = useState<SettingsObject>(DEFAULT_SETTINGS_OBJECT)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const { canQuarantineSettings, canEmailCategorization, canSendBrtsEvidence } = useInboundSettingsRights()

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const [{ isDirty, changedSettings }, resetInitialForm] = useDirtyFormObjectCheck(formObject)

  // key is used to force a re-render of <SettingsPage>
  // The Cancel button is supposed to reset the initial form values only after a confirmation.
  // The need for a confirmation (block) is set up via useDirtyFormCheck
  const [key, setKey] = useState(makeUuid())

  // init
  useEffect(() => {
    if (canSendBrtsEvidence) {
      SETTINGS_LIST.push(AvailableSettings.BRTS_SEND_EVIDENCE)
    }
    if (canEmailCategorization) {
      SETTINGS_LIST.push(
        AvailableSettings.EMAILCAT_CORPORATE,
        AvailableSettings.EMAILCAT_LISTSERVER,
        AvailableSettings.EMAILCAT_MARKETING,
        AvailableSettings.EMAILCAT_SOCIALMEDIA,
        AvailableSettings.EMAILCAT_TRANSACTIONAL
      )
    }
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [canEmailCategorization])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const fastSpamScore = useMemo(() => {
    let score = { block: 0, quarantine: 0 }
    const fastSpamScores = formObject.fastspam_scores
    if (typeof fastSpamScores === 'string') {
      try {
        score = JSON.parse(fastSpamScores)
      } catch (error) {
        dispatch(
          setErrorSnackBar({
            message: 'error_parsing_settings'
          })
        )
      }
    }
    return score
  }, [formObject.fastspam_scores, dispatch])

  const resetForm = useCallback(
    (settings: SettingsObject) => {
      const filteredSettings = filterSettings(settings, SETTINGS_LIST)
      const settingsWithDefaults = setValueWithDefaults(filteredSettings)

      setFormObject(settingsWithDefaults)
      resetInitialForm(settingsWithDefaults)
    },
    [resetInitialForm]
  )

  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      resetForm(domainSettings)
    } else {
      resetForm(accountSettings)
    }
  }, [resetForm, accountSettings, domainSettings, accessTokenObject])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  const setValueWithDefaults = (settings: SettingsObject) => {
    const settingsWithDefaults = settings

    if (!settingsWithDefaults.fastspam_enabled) {
      settingsWithDefaults[AvailableSettings.FASTSPAM_ENABLED] = '1'
    }

    if (!settingsWithDefaults.fastspam_scores) {
      settingsWithDefaults[AvailableSettings.FASTSPAM_SCORES] = '{"block": 5, "quarantine": 0}'
    }

    return settingsWithDefaults
  }

  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  const onFormChange = useCallback(
    (name: AvailableSettings) => (newValue: any) => {
      if (name === AvailableSettings.BRTS_SEND_EVIDENCE) {
        const dbValue = formObject.brts_send_evidence === '1' ? '0' : '1'
        setFormObject({ ...formObject, [name]: dbValue } as SettingsObject)
      } else if (name === AvailableSettings.VSCAN && newValue === '0') {
        setIsWarningDialogOpen(true)
      } else {
        setFormObject({ ...formObject, [name]: newValue } as SettingsObject)
      }
    },
    [formObject]
  )

  const onScoringChange = useCallback(
    (name: ScoringType, value: number) => {
      try {
        const fastspamScores = JSON.parse(formObject.fastspam_scores as string)
        if (name === ScoringType.QUARANTINE && value >= fastspamScores.block) {
          fastspamScores.quarantine = fastspamScores.block - 1 < 0 ? 0 : fastspamScores.block - 1
        } else if (name === ScoringType.BLOCK && value <= fastspamScores.quarantine) {
          fastspamScores.block = value
          fastspamScores.quarantine = Math.max(value - 1, 0)
        } else {
          fastspamScores[name] = value
        }

        setFormObject({
          ...formObject,
          [AvailableSettings.FASTSPAM_SCORES]: JSON.stringify(fastspamScores)
        })
      } catch (error) {
        dispatch(
          setErrorSnackBar({
            message: 'error_parsing_settings'
          })
        )
      }
    },
    [formObject, dispatch]
  )

  const onActionChange = useCallback(
    (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target
      setFormObject({ ...formObject, [name as string]: value })
    },
    [formObject]
  )

  const onSave = useCallback(() => {
    const updatedSettings = { ...changedSettings }
    if (updatedSettings[AvailableSettings.FASTSPAM_SCORES]) {
      try {
        const currentFastspamScores = JSON.parse(updatedSettings[AvailableSettings.FASTSPAM_SCORES] as string)

        const transformedFastspamScores = {
          block: currentFastspamScores.block,
          quarantine: currentFastspamScores.quarantine
        }
        updatedSettings[AvailableSettings.FASTSPAM_SCORES] = transformedFastspamScores
      } catch (error) {
        dispatch(
          setErrorSnackBar({
            message: 'error_parsing_settings'
          })
        )
      }
    }
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: updatedSettings
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: updatedSettings }))
    }
  }, [dispatch, changedSettings, accessTokenObject])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        if (accessTokenObject?.pdDomainId) {
          resetForm(domainSettings)
        } else {
          resetForm(accountSettings)
        }
        setBlockTransition(undefined)
        setKey(makeUuid())
      })
    }
  }, [resetForm, accessTokenObject, domainSettings, accountSettings])

  const onConfirmWarningDialog = useCallback(() => {
    setFormObject({ ...formObject, [AvailableSettings.VSCAN]: '0' })
    setIsWarningDialogOpen(false)
  }, [formObject])

  const onCancelWarningDialog = useCallback(() => {
    setIsWarningDialogOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        canQuarantineSettings,
        canEmailCategorization,
        canSendBrtsEvidence,
        settings: formObject,
        tableData: {
          total: emailCategories.length,
          data: emailCategories
        },
        isWarningDialogOpen,
        isDirtyForm: isDirty,
        fastSpamScore,
        key
      },
      {
        onFormChange,
        onScoringChange,
        onActionChange,
        onSave,
        onCancelConfirm,
        onCancelWarningDialog,
        onConfirmWarningDialog,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        }
      }
    ],
    [
      canQuarantineSettings,
      canEmailCategorization,
      canSendBrtsEvidence,
      formObject,
      isWarningDialogOpen,
      isDirty,
      key,
      onFormChange,
      onScoringChange,
      onActionChange,
      onSave,
      onCancelConfirm,
      onCancelWarningDialog,
      onConfirmWarningDialog,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      fastSpamScore
    ]
  )
}
