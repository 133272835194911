import React, { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { DirectoryService } from 'types/domains'
import { EditDomainWarningDialogType } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import { isPending, isSuccess } from 'redux/toolkit/api'

export interface State {
  directoryServices: string
  isDirectoryServicesDisabled: boolean
}

export interface EventHandlers {
  onChangeDirectoryServices: (value: string) => void
}

export type EditDomainDirectoryServicesLogic = [State, EventHandlers]

export const useEditDomainDirectoryServicesLogic = (form: EditDomainForm): EditDomainDirectoryServicesLogic => {
  const { domain, isGetDomainSuccess, isAzureAdSyncNowPending, isAzureAdAuthorizePending, isAzureAdDisconnectPending } =
    useAppSelector(_store => ({
      domain: _store.domains.domain,
      domainId: _store.domains.domain?.domainId,
      isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
      isAzureAdSyncNowPending: isPending(_store.azureAd.api.postAzureAdSyncNow),
      isAzureAdAuthorizePending: isPending(_store.azureAd.api.getAzureAdAdminConsent),
      isAzureAdDisconnectPending: isPending(_store.azureAd.api.postAzureAdDisconnect)
    }))
  const [
    { inProgress, directoryServices, isAzureAdPollStatsInProgress, isAzureAdLookupUserPending },
    { openWarningDialog, closeWarningDialog }
  ] = form

  const directoryServicesValue = useMemo(() => {
    if (typeof directoryServices.value === 'undefined') {
      return ''
    }
    return directoryServices.value || DirectoryService.NONE
  }, [directoryServices.value])

  const isDirectoryServicesDisabled = useMemo(
    () =>
      inProgress ||
      isAzureAdSyncNowPending ||
      isAzureAdPollStatsInProgress.value ||
      isAzureAdAuthorizePending ||
      isAzureAdDisconnectPending ||
      isAzureAdLookupUserPending.value,
    [
      inProgress,
      isAzureAdPollStatsInProgress.value,
      isAzureAdSyncNowPending,
      isAzureAdAuthorizePending,
      isAzureAdDisconnectPending,
      isAzureAdLookupUserPending
    ]
  )

  const onChangeDirectoryServices = useCallback(
    (value: string) => {
      if (value !== DirectoryService.AZURE_AD && domain?.directoryServices === DirectoryService.AZURE_AD) {
        openWarningDialog({
          type: EditDomainWarningDialogType.DISABLE_AZURE_AD,
          dialogActions: {
            onConfirm: () => {
              directoryServices.setValue(value as DirectoryService)
              closeWarningDialog()
            },
            onClose: closeWarningDialog
          }
        })
        return
      }
      directoryServices.setValue(value as DirectoryService)
    },
    [closeWarningDialog, directoryServices, domain?.directoryServices, openWarningDialog]
  )

  useEffect(() => {
    if (isGetDomainSuccess) {
      directoryServices.reset(domain?.directoryServices || DirectoryService.NONE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  return useMemo(
    () => [
      {
        directoryServices: directoryServicesValue,
        isDirectoryServicesDisabled
      },
      {
        onChangeDirectoryServices
      }
    ],
    [directoryServicesValue, isDirectoryServicesDisabled, onChangeDirectoryServices]
  )
}
