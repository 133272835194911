import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function StatusSeenRecipients(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      All unique recipients seen in the past day (24 hours) or the last 30 days by {props.productName}
      are listed in this page, one email address per line. Maximum listed per page is 50. Click the{' '}
      <span className={classes.blueText}>Back to Dashboard</span> link on the page to return to the <b>Dashboard</b>{' '}
      page.
    </div>
  )
}

export default StatusSeenRecipients
