import React, { useMemo } from 'react'

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  LinearProgress,
  Typography
} from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { FpFnType } from 'types/Messages'

import styles from './reportingActionModalStyles'
import { ReportingActionModalProps, useReportingActionModalLogic } from './useReportingActionModalLogic'

const BASE_I18N_KEY = 'ess.message_log.reporting_action_modal'

const ReportingActionModal: React.FC<ReportingActionModalProps> = props => {
  const { postFpfnReportInProgress, messageData, handleReport } = useReportingActionModalLogic(props)

  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <>
        {messageData.messageIsLoading && <LinearProgress data-testid="linear-progress" />}
        {postFpfnReportInProgress && (
          <Card className={classes.loadingCard} data-testid="loader-card">
            <div className={classes.loadingDiv}>
              <CircularProgress data-testid="circular-progress" />
              <div className={classes.reportingMessage}>{formatMessage('report_message')}</div>
            </div>
          </Card>
        )}
        {!messageData.messageIsLoading && !messageData.messageLoadingError && !postFpfnReportInProgress && (
          <Card className={classes.card} data-testid="data-card">
            <CardHeader
              title={
                <Typography className={classes.title} data-testid="title">
                  {props.fpFnType === FpFnType.FN
                    ? formatMessage('incorrectly_delivered')
                    : formatMessage('incorrectly_blocked')}
                </Typography>
              }
            />
            <Divider />
            <CardContent>
              <Typography variant="body1" className={classes.actionContent} data-testid="content">
                {props.fpFnType === FpFnType.FN
                  ? formatMessage('incorrectly_delivered_content')
                  : formatMessage('incorrectly_blocked_content')}
              </Typography>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActions}>
              <Button
                color="secondary"
                size="medium"
                variant="contained"
                onClick={props.handleClose}
                data-testid="close-button"
              >
                {formatMessage('buttons.close')}
              </Button>
              <Button
                color="primary"
                size="medium"
                variant="contained"
                onClick={handleReport}
                data-testid="handle-button"
              >
                {props.fpFnType === FpFnType.FN
                  ? formatMessage('buttons.why_should_be_blocked')
                  : formatMessage('buttons.why_should_be_allowed')}
              </Button>
            </CardActions>
          </Card>
        )}
      </>
    ),
    [classes, formatMessage, postFpfnReportInProgress, messageData, handleReport, props]
  )
}

export default ReportingActionModal
