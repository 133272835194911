import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending } from 'redux/toolkit/api'
import { autoLogin } from 'redux/features/auth/authSlice'
import routesConfig, { gotoLegacyMessageLog, gotoLegacyUrl } from 'lib/routesConfig'
import useLogout from 'lib/useLogout'
import logger from 'lib/logger'
import useHandleQNDigestLinks from 'lib/useHandleQNDigestLinks'

export interface AutoLoginInfoDialogLogic {
  isVisible: boolean
  message: 'expired_message' | 'invalid_link' | ''
  onClose: () => void
}

interface AutoLoginProgressLogic {
  isVisible: boolean
}

export type AutoLoginLogic = [AutoLoginInfoDialogLogic, AutoLoginProgressLogic]

export const useAutoLoginLogic = (): AutoLoginLogic => {
  const dispatch = useAppDispatch()
  const [logout] = useLogout()

  const loginHasBeenDispatched = useRef(false)
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get('return_url') || undefined
  const url = searchParams.get('url') || undefined
  const token = searchParams.get('token') || undefined
  const { loginError, accessToken, isLoggingIn, enforceManualLogin, userDomainId, isEnhancedAuthenticationEnabled } =
    useAppSelector(_store => ({
      loginError: getErrorMessage(_store.auth.loginApiStatus),
      isLoggingIn: isPending(_store.auth.loginApiStatus),
      accessToken: _store.auth.accessToken,
      enforceManualLogin: _store.auth.enforceManualLogin,
      userDomainId: _store.auth.accessTokenObject?.domainId,
      isEnhancedAuthenticationEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled
    }))
  const shouldDispatchLogin = !accessToken && token
  const infoDialogMessage = useMemo(() => {
    if (loginError && !enforceManualLogin) {
      return 'invalid_link'
    }
    return ''
  }, [enforceManualLogin, loginError])
  const shouldDisplayInfoDialog = !!infoDialogMessage
  const shouldDisplayProgress = isLoggingIn
  const [handleQnDigestLink, , generateQNDigestReturnUrlParams] = useHandleQNDigestLinks()

  const onCloseInfoDialog = useCallback(() => {
    const returnUrlParam = searchParams.get('return_url')
    const urlParam = searchParams.get('url')

    if (returnUrlParam) {
      window.location.href = returnUrlParam
    } else if (urlParam) {
      gotoLegacyUrl(urlParam)
    } else {
      gotoLegacyMessageLog()
    }
  }, [searchParams])

  // init
  useEffect(() => {
    if (isEnhancedAuthenticationEnabled) {
      routesConfig.LOGIN.goto(undefined, true)
      return
    }
    logger.info(`AUTO_LOGIN_DEBUG - AutoLogin token - ${token}`)

    if (!token) {
      logout(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout])

  // dispatch login
  useEffect(() => {
    if (!shouldDispatchLogin || loginHasBeenDispatched.current) {
      return
    }
    loginHasBeenDispatched.current = true
    dispatch(autoLogin({ token }))
  }, [dispatch, shouldDispatchLogin, token])

  // handle login success
  useEffect(() => {
    if (!userDomainId) {
      return
    }
    handleQnDigestLink({
      url,
      redirectUrl
    })
  }, [userDomainId, handleQnDigestLink, url, redirectUrl])

  // handle login failure
  useEffect(() => {
    if (!loginError || !enforceManualLogin) {
      return
    }

    routesConfig.LOGIN.goto(undefined, false, generateQNDigestReturnUrlParams())
  }, [enforceManualLogin, loginError, generateQNDigestReturnUrlParams])

  return useMemo(
    () => [
      {
        isVisible: shouldDisplayInfoDialog,
        message: infoDialogMessage,
        onClose: onCloseInfoDialog
      },
      { isVisible: shouldDisplayProgress }
    ],
    [infoDialogMessage, onCloseInfoDialog, shouldDisplayInfoDialog, shouldDisplayProgress]
  )
}
