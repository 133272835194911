import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundAntiVirus(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <h3>Scan Email for Viruses</h3>
      With virus scanning enabled, all outbound messages processed by {props.productName} are checked for viruses. Note:
      Detected viruses will not be quarantined or delivered to the intended recipient - even if the message has been
      allowed by a Block/Exempt filter. It is strongly recommended to set{' '}
      <b>Enable Virus Scanning of Outgoing Emails</b> to <i>Yes</i>.
    </div>
  )
}

export default OutboundAntiVirus
