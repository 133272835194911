import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import MessageDetailsContentHeader from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentHeader/MessageDetailsContentHeader'
import MessageDetailsContentTabs from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentTabs/MessageDetailsContentTabs'
import styles from 'components/libs/message/messageDetails/messageDetailsContent/messageDetailsContentStyles'

const MessageDetailsContent = () => {
  const classes = styles()
  return useMemo(
    () => (
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.row}>
          <MessageDetailsContentHeader />
        </Grid>
        <Grid item xs={12} className={`${classes.row} ${classes.bottomRow}`}>
          <MessageDetailsContentTabs />
        </Grid>
      </Grid>
    ),
    [classes]
  )
}

export default MessageDetailsContent
