import React, { useMemo } from 'react'
import { Alert } from '@barracuda-internal/bds-core'

import styles from 'components/libs/banner/BannerStyles'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.global_message_banner'

const PreMailflowBanner = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Alert className={classes.alert} severity="info">
        {formatMessage('wizard_complete_text')}
      </Alert>
    ),
    [classes.alert, formatMessage]
  )
}

export default PreMailflowBanner
