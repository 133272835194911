import { useMemo } from 'react'

import { YesNoDialogProps } from 'components/libs/dialog/yesNoDialog/YesNoDialog'

export interface YesNoDialogLogicConfig {
  open: boolean
  onCancel: YesNoDialogProps['onCancel']
  onClose: YesNoDialogProps['onClose']
  onConfirm: YesNoDialogProps['onConfirm']
}

export type YesNoDialogLogic = [
  {
    isDialogOpened: boolean
    onHandleClose: () => void
    onClickYes: () => void
    onClickNo: () => void
  }
]

export const useYesNoDialogLogic = ({ onCancel, onClose, onConfirm, open }: YesNoDialogLogicConfig): YesNoDialogLogic =>
  useMemo(() => {
    const onClickYes = () => {
      onConfirm()
      onClose()
    }

    const onClickNo = () => {
      onCancel?.()
      onClose()
    }

    return [
      {
        isDialogOpened: open,
        onHandleClose: onClose,
        onClickYes,
        onClickNo
      }
    ]
  }, [onCancel, onClose, onConfirm, open])
