import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import ErrorPage from 'components/libs/errorPage/ErrorPage'
import styles from 'components/libs/contentLoader/contentLoaderStyles'

const BASE_I18N_KEY = 'ess.app.error'

export interface ContentLoaderErrorPageProps {
  message?: string
  withIcon?: boolean
}

function ContentLoaderMessage({ message, withIcon = true }: ContentLoaderErrorPageProps) {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Grid className={classes.errorPage} container justifyContent="center" alignContent="center" direction="column">
        <ErrorPage withIcon={withIcon} title={message || formatMessage('failed_to_load_content')} />
      </Grid>
    ),
    [classes, formatMessage, message, withIcon]
  )
}

export default ContentLoaderMessage
