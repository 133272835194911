import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function UsersEmailContinuity(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Email Continuity allows end-users to send, receive, compose, and forward emails when designated mail servers are
        unavailable. Note that Email Continuity is automatically disabled after 96 hours. Messages in Email Continuity
        are viewable in the Message list for 30 days, after which they expire. Enable spooling on the{' '}
        <b>Domains &gt; Domain Settings</b>
        page for each domain where you want to enable Email Continuity. For more information, see
      </p>
      <a href="https://campus.barracuda.com/doc/96023041/" target="_blank" rel="noreferrer">
        {' '}
        Barracuda Campus.
      </a>
      <br />
      <br />
      <b>Important</b>: If your organization is using LDAP Authentication and your LDAP server goes offline,{' '}
      {props.productName} cannot connect to your LDAP server, and therefore users are unable to log into Email
      Continuity.
      <br />
      <br />
      <ul>
        <li>
          <b>Email Continuity</b>
          <br />
          Specify whether to allow end-users to send, receive, compose, and forward emails when designated mail servers
          are unavailable.
          <br />
        </li>
        <ul>
          <li>
            <b>Off</b>
            <br />
            Email Continuity is disabled. If {props.productName} cannot connect to your mail server, the mail is
            deferred and the sending mail server is notified. Depending on its configuration, the sending mail server
            can retry the message or notify the sender that the mail was deferred or failed.
          </li>
          <li>
            <b>Auto-Enable</b>
            <br />
            When selected, Email Continuity automatically starts once it detects that {props.productName} has been
            spooling messages for a 10 minute period. Email Continuity automatically stops when it detects that{' '}
            {props.productName} has stopped spooling or when 96 hours elapses, whichever comes first.
          </li>
        </ul>
      </ul>
      <h3>User Actions</h3>
      Once Email Continuity starts, users can take the following actions:
      <ul>
        <li>
          Compose a <b>New Message</b>
        </li>
        <li>
          <b>Reply</b> to the sender
        </li>
        <li>
          <b>Forward</b> a message to one or more email addresses; separate multiple addresses with a comma delimiter
        </li>
        <li>
          <b>Download</b> messages from Email Continuity as .eml files
        </li>
      </ul>
    </div>
  )
}

export default UsersEmailContinuity
