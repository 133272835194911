import { createSlice } from '@reduxjs/toolkit'

import {
  createAuthenticationProcess,
  CreateAuthenticationProcessResponse,
  getCurrentSession,
  GetCurrentSessionResponse,
  tokenExchange,
  OAuth2TokenExchangeResponse
} from 'redux/features/oauth2/oauth2ApiThunks'
import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'

export interface OAuth2State {
  api: {
    getCurrentSessionApiStatus: ApiStatus
    createAuthenticationProcessApiStatus: ApiStatus
    tokenExchangeApiStatus: ApiStatus
  }
  getCurrentSessionResponse: GetCurrentSessionResponse | undefined
  createAuthenticationProcessResponse: CreateAuthenticationProcessResponse | undefined
  tokenExchangeResponse: OAuth2TokenExchangeResponse | undefined
}

// initialState
export const INITIAL_STATE: OAuth2State = {
  api: {
    getCurrentSessionApiStatus: inIdle,
    createAuthenticationProcessApiStatus: inIdle,
    tokenExchangeApiStatus: inIdle
  },
  getCurrentSessionResponse: undefined,
  createAuthenticationProcessResponse: undefined,
  tokenExchangeResponse: undefined
}

/* eslint-disable no-param-reassign */
export const oauth2Slice = createSlice({
  name: 'OAUTH2',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetGetCurrentSession: state => {
      state.api.getCurrentSessionApiStatus = INITIAL_STATE.api.getCurrentSessionApiStatus
      state.getCurrentSessionResponse = INITIAL_STATE.getCurrentSessionResponse
    },
    resetCreateAuthenticationProcess: state => {
      state.api.createAuthenticationProcessApiStatus = INITIAL_STATE.api.createAuthenticationProcessApiStatus
      state.createAuthenticationProcessResponse = INITIAL_STATE.createAuthenticationProcessResponse
    },
    resetTokenExchange: state => {
      state.api.tokenExchangeApiStatus = INITIAL_STATE.api.tokenExchangeApiStatus
      state.tokenExchangeResponse = INITIAL_STATE.tokenExchangeResponse
    }
  },
  extraReducers: builder => {
    builder
      // getCurrentSession
      .addCase(getCurrentSession.pending, state => {
        state.api.getCurrentSessionApiStatus = inProgress
      })
      .addCase(getCurrentSession.fulfilled, (state, action) => {
        state.api.getCurrentSessionApiStatus = successResponse
        state.getCurrentSessionResponse = action.payload
      })
      .addCase(getCurrentSession.rejected, (state, action) => {
        state.api.getCurrentSessionApiStatus = failedResponse(action.payload)
      })

      // createAuthenticationProcess
      .addCase(createAuthenticationProcess.pending, state => {
        state.api.createAuthenticationProcessApiStatus = inProgress
      })
      .addCase(createAuthenticationProcess.fulfilled, (state, action) => {
        state.api.createAuthenticationProcessApiStatus = successResponse
        state.createAuthenticationProcessResponse = action.payload
      })
      .addCase(createAuthenticationProcess.rejected, (state, action) => {
        state.api.createAuthenticationProcessApiStatus = failedResponse(action.payload)
      })

      // tokenExchange
      .addCase(tokenExchange.pending, state => {
        state.api.tokenExchangeApiStatus = inProgress
      })
      .addCase(tokenExchange.fulfilled, (state, action) => {
        state.api.tokenExchangeApiStatus = successResponse
        state.tokenExchangeResponse = action.payload
      })
      .addCase(tokenExchange.rejected, (state, action) => {
        state.api.tokenExchangeApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetCreateAuthenticationProcess, resetTokenExchange, resetGetCurrentSession } =
  oauth2Slice.actions

export { createAuthenticationProcess, getCurrentSession, tokenExchange }

export default oauth2Slice.reducer
