import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import {
  AVAILABLE_CPL_INTENT_DOMAIN_POLICIES,
  AVAILABLE_INTENT_DOMAIN_POLICIES,
  IntentDomainPolicies,
  LinkedDomainPolicy
} from 'types/AntiPhishing'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'
import {
  LinkedDomainPoliciesForm,
  LinkedDomainPoliciesFormState,
  useLinkedDomainPoliciesForm
} from 'components/pages/inboundSettings/antiPhishing/useLinkedDomainPoliciesForm'
import { AvailableSettings } from 'types/Settings'
import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

export interface UseAntiPhishingTableLogicProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export interface State {
  idToRemove: string
  sort: SortDescriptor[]
  intentActions: string[]
  form: LinkedDomainPoliciesForm
  formState: LinkedDomainPoliciesFormState
  error: string
  tableData: {
    data: LinkedDomainPolicy[]
    total: number
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onConfirmRemove: () => void
  onConfirmCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  handleSortChange: (e: GridSortChangeEvent) => void
}

export type UseAntiPhishingTableLogic = [State, EventHandlers]

const initialSort = [{ field: 'domain', dir: 'asc' } as SortDescriptor]

export const enum INPUT_NAMES {
  DOMAIN = 'domain',
  COMMENT = 'comment'
}
export const enum SELECT_NAMES {
  POLICY = 'policy'
}

const INITIAL_FORM_STATE = { domain: '', policy: IntentDomainPolicies.IGNORE, comment: '' }
const BLANK_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const useAntiPhishingTableLogic = ({
  delegateDirtyFormState
}: UseAntiPhishingTableLogicProps): UseAntiPhishingTableLogic => {
  const form = useLinkedDomainPoliciesForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const dispatch = useAppDispatch()
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort)
  const [idToRemove, setIdToRemove] = useState('')
  const { canQuarantineSettings } = useInboundSettingsRights()
  const { pdDomainId, settings } = useAppSelector(_stores => {
    const localPdDomainId = _stores.auth.accessTokenObject?.pdDomainId
    const localSettings = localPdDomainId ? _stores.settings.domainSettings : _stores.settings.accountSettings
    return {
      pdDomainId: localPdDomainId,
      settings: localSettings
    }
  })
  const parsedLinkedDomainPolicies = useMemo(
    () =>
      settings.linked_domain_policy
        ? (JSON.parse(settings.linked_domain_policy as string) as LinkedDomainPolicy[])
        : [],
    [settings.linked_domain_policy]
  )

  const updateSettings = useCallback(
    (data: LinkedDomainPolicy[]) => {
      if (pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: pdDomainId,
            settings: { [AvailableSettings.LINKED_DOMAIN_POLICY]: data }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.LINKED_DOMAIN_POLICY]: data } }))
      }
    },
    [pdDomainId, dispatch]
  )

  const onAdd = useCallback(() => {
    const newEntry: LinkedDomainPolicy = {
      ...form.getState(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newEntry, { data: parsedLinkedDomainPolicies })) {
      const entries = [...parsedLinkedDomainPolicies, newEntry]
      updateSettings(entries)
    }
  }, [form, parsedLinkedDomainPolicies, updateSettings])

  const onRemove = useCallback(
    (id: string) => {
      setIdToRemove(id)
    },
    [setIdToRemove]
  )

  const onConfirmRemove = useCallback(() => {
    if (idToRemove) {
      const entries = parsedLinkedDomainPolicies.filter(entry => entry.id !== idToRemove)
      updateSettings(entries)
      setIdToRemove('')
    }
  }, [idToRemove, parsedLinkedDomainPolicies, updateSettings])

  const onConfirmCancel = useCallback(() => {
    setIdToRemove('')
  }, [setIdToRemove])

  const tableData = useMemo(() => {
    const orderedData = orderBy(parsedLinkedDomainPolicies, sort)
    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_ENTRY)
    return {
      data: processedData as LinkedDomainPolicy[],
      total: processedData.length || 0
    }
  }, [parsedLinkedDomainPolicies, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === INPUT_NAMES.DOMAIN:
          form.fields.domain.setValue(value)
          break
        case name === INPUT_NAMES.COMMENT:
          form.fields.comment.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  const handleOnSelectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      if (typeof e.target.value === 'string') {
        form.fields.policy.setValue(e.target.value)
        form.delegateIsDirty()
      }
    },
    [form]
  )

  const intentActions = useMemo(
    () => (canQuarantineSettings ? AVAILABLE_INTENT_DOMAIN_POLICIES : AVAILABLE_CPL_INTENT_DOMAIN_POLICIES),
    [canQuarantineSettings]
  )

  return useMemo(
    () => [
      {
        idToRemove,
        sort,
        intentActions,
        form,
        formState: form.getState(),
        error: form.error,
        tableData
      },
      {
        onAdd,
        onRemove,
        onConfirmRemove,
        onConfirmCancel,
        handleOnInputChange,
        handleSortChange,
        handleOnSelectChange
      }
    ],
    [
      idToRemove,
      sort,
      intentActions,
      form,
      tableData,
      onAdd,
      onRemove,
      onConfirmRemove,
      onConfirmCancel,
      handleOnInputChange,
      handleSortChange,
      handleOnSelectChange
    ]
  )
}
