import { useCallback, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getOutboundTopBlockedStats, updateFilters } from 'redux/features/stats/statsSlice'

import { DashboardRange, StatType, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'

export interface UseOutboundTopBlockedLogicProps {
  domainId?: string
  range: DashboardRange
}

interface State {
  outboundTopBlockedStats: TopRecipientDomains | undefined
  outboundTopBlockedStatsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseOutboundTopBlockedLogic = [State, EventHandlers]

export const useOutboundTopBlockedLogic = (props: UseOutboundTopBlockedLogicProps): UseOutboundTopBlockedLogic => {
  const dispatch = useAppDispatch()

  const { outboundTopBlockedStats, outboundTopBlockedStatsInProgress } = useAppSelector(_store => ({
    outboundTopBlockedStats: _store.stats.outboundTopBlockedStats,
    outboundTopBlockedStatsInProgress: isPending(_store.stats.api.getOutboundTopBlockedStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.outbound)?.dropdownItems[0].value || ''
  )

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(
    value => {
      setSelectedFilter(value as StatType)
      dispatch(
        updateFilters({
          outboundTopBlocked: { statType: value === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS }
        })
      )
      dispatch(
        getOutboundTopBlockedStats({
          domainId: props.domainId,
          range: props.range,
          statType: value === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS
        })
      )
    },
    [dispatch, props.range, props.domainId]
  )

  return useMemo(
    () => [
      {
        outboundTopBlockedStats,
        outboundTopBlockedStatsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [outboundTopBlockedStats, outboundTopBlockedStatsInProgress, selectedFilter, onSelectFilter]
  )
}
