import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export type StackedLineChartIconProps = SvgIconProps

export function StackedLineChartIcon(props: StackedLineChartIconProps) {
  return (
    <SvgIcon id="icon-stacked-bar-chart" viewBox="0 0 16 16" {...props}>
      <path
        d="M1.66667 16.6583L7.91667 10.4L11.25 13.7333L17.1583 7.09167L18.3333 8.26667L11.25 16.2333L7.91667 12.9L2.91667 17.9083L1.66667 16.6583ZM2.91667 12.9083L7.91667 7.90001L11.25 11.2333L18.3333 3.26667L17.1583 2.09167L11.25 8.73334L7.91667 5.40001L1.66667 11.6583L2.91667 12.9083Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}
