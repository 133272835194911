import React, { useMemo } from 'react'

import { MenuItem, Select } from '@barracuda-internal/bds-core'
import * as EmailIcons from '@barracuda-internal/bds-core/dist/Icons/Email'

import { useFormatMessage } from 'lib/localization'
import styles from 'components/libs/splitter/SplitterMenu/splitterMenuStyles'
import { SplitterMenuItems, useSplitterMenuLogic } from 'components/libs/splitter/SplitterMenu/useSplitterMenuLogic'
import { SplitterOrientation } from '../splitterTypes'

const BASE_I18N_KEY = 'ess.splitter_orientation'

const SplitterMenu = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { splitterOrientation, handleChange } = useSplitterMenuLogic()

  const menuItems: SplitterMenuItems[] = useMemo(
    () => [
      {
        key: 'none',
        label: formatMessage('none'),
        icon: <EmailIcons.NoSplit fontSize="inherit" className={classes.menuItem} />
      },
      {
        key: 'horizontal',
        label: formatMessage('horizontal'),
        icon: <EmailIcons.HorizontalSplit fontSize="inherit" className={classes.menuItem} />
      },
      {
        key: 'vertical',
        label: formatMessage('vertical'),
        icon: <EmailIcons.VerticalSplit fontSize="inherit" className={classes.menuItem} />
      }
    ],
    [classes.menuItem, formatMessage]
  )

  return useMemo(
    () => (
      <Select
        className={classes.dropdown}
        multiple={false}
        onChange={e => handleChange(e.target.value as SplitterOrientation)}
        value={splitterOrientation}
        renderValue={(selected: any): React.ReactElement => {
          const selectedMenuItem = menuItems.filter(x => x.key === selected)
          return <div>{selectedMenuItem[0].icon}</div>
        }}
        data-testid="select-menu"
      >
        {menuItems.map(({ key, label, icon }) => (
          <MenuItem className={classes.menuItem} key={key} value={key}>
            {icon}
            {splitterOrientation === key && <div className={classes.active}>{label}</div>}
            {splitterOrientation !== key && label}
          </MenuItem>
        ))}
      </Select>
    ),
    [classes, splitterOrientation, handleChange, menuItems]
  )
}

export default SplitterMenu
