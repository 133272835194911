import { useMemo } from 'react'
import { makePreAuthCommandFactory, makePreAuthCommandHandler } from 'lib/preAuthCommands/preAuthCommands'
import routesConfig from 'lib/routesConfig'

export const usePreAuthCommands = () => {
  const commandFactory = useMemo(() => makePreAuthCommandFactory(), [])
  const commandHandler = useMemo(() => makePreAuthCommandHandler(routesConfig), [])

  return useMemo(
    () => ({
      commandFactory,
      commandHandler
    }),
    [commandFactory, commandHandler]
  )
}
