/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react'
import { Alert, Link } from '@barracuda-internal/bds-core'
import { useGlobalMessagesBannerLogic } from 'components/libs/banner/globalMessageBanner/useGlobalMessageBannerLogic'

import styles from 'components/libs/banner/BannerStyles'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.global_message_banner'

const GlobalMessagesBanner = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [{ isBannerVisible, userEmail, pdDomainName, onRemovePdDomainId, onLogout, isHelpdeskPdDomainId }] =
    useGlobalMessagesBannerLogic()

  const ImpersonatedUserBanner = useCallback(
    () => (
      <Alert className={classes.alert} severity="info" data-testid="impersonated_user_banner">
        {formatMessage('impersonated_user_text', { user: userEmail })}
        <Link className={classes.interfaceBannerLink} onClick={onLogout}>
          <b>{formatMessage('impersonated_user_button')}</b>
        </Link>
      </Alert>
    ),
    [classes, formatMessage, userEmail, onLogout]
  )

  const PdDomainIdBanner = useCallback(
    () => (
      <Alert className={classes.alert} severity="info" data-testid="pd_domain_id_banner">
        {formatMessage(`pd_domain_id_text${isHelpdeskPdDomainId ? '_helpdesk' : ''}`, { domain: pdDomainName })}
        <Link className={classes.interfaceBannerLink} onClick={onRemovePdDomainId}>
          <b>{formatMessage('pd_domain_id_button')}</b>
        </Link>
      </Alert>
    ),
    [classes, formatMessage, pdDomainName, onRemovePdDomainId, isHelpdeskPdDomainId]
  )

  return useMemo(() => {
    if (!isBannerVisible) {
      return null
    }

    return (
      <>
        {userEmail && <ImpersonatedUserBanner />}
        {pdDomainName && <PdDomainIdBanner />}
      </>
    )
  }, [isBannerVisible, ImpersonatedUserBanner, PdDomainIdBanner, pdDomainName, userEmail])
}

export default GlobalMessagesBanner
