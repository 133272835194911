import { useMemo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, DomainEntry, GeoSenderNameExemptPolicy } from 'types/Settings'
import routesConfig from 'lib/routesConfig'
import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'

export interface State {
  tableData: any[]
}

export interface EventHandlers {
  onAddItem: (item: DomainEntry) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
}

export type UseGeoPoliciesSenderNameExemptLogic = [State, EventHandlers]

export const useGeoPoliciesSenderNameExemptLogic = (): UseGeoPoliciesSenderNameExemptLogic => {
  const dispatch = useAppDispatch()
  const { accessTokenObject, accountSettings, domainSettings } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const transformToDomainEntries = (entries: GeoSenderNameExemptPolicy[]): DomainEntry[] =>
    entries.map(entry => ({
      domain: entry.name,
      comment: entry.comment,
      id: entry.id
    }))

  const transformToExemptEntries = (entries: DomainEntry[]): GeoSenderNameExemptPolicy[] =>
    entries.map(entry => ({
      name: entry.domain,
      comment: entry.comment,
      id: entry.id
    }))

  const tableData = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedExemptions = settings.geoip_sender_exempt
      ? (JSON.parse(settings.geoip_sender_exempt as string) as GeoSenderNameExemptPolicy[])
      : []
    return transformToDomainEntries(parsedExemptions)
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateSettings = useCallback(
    (entries: GeoSenderNameExemptPolicy[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.GEOIP_SENDER_NAME_EXEMPT_POLICIES]: entries }
          })
        )
      } else {
        dispatch(
          updateAccountSettings({ settings: { [AvailableSettings.GEOIP_SENDER_NAME_EXEMPT_POLICIES]: entries } })
        )
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAddItem = useCallback(
    (item: DomainEntry) => {
      const entries = [...tableData, item]
      const mappedExemptEntries = transformToExemptEntries(entries)
      updateSettings(mappedExemptEntries)
    },
    [tableData, updateSettings]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const exemptions = tableData.filter(entry => entry.id !== id)
      const mappedExemptEntries = transformToExemptEntries(exemptions)
      updateSettings(mappedExemptEntries)
    },
    [tableData, updateSettings]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_GEOIP_EMAIL_DOMAIN_EXEMPT_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        tableData
      },
      {
        onAddItem,
        onRemoveItem,
        onBulkEdit
      }
    ],
    [tableData, onAddItem, onRemoveItem, onBulkEdit]
  )
}
