import { createAsyncThunk } from '@reduxjs/toolkit'
import { objectToCamel } from 'ts-case-convert'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { DomainsInDB } from 'types/redux/user/UserTypes'

export interface GetAvailableDomainsResponse {
  results: DomainsInDB[]
  resultsCount: number
  itemsTotal: number
  metadata: number
  pageNum: number
  pageSize: number
  pagesTotal: number
}

export interface GetAvailableDomainsRequest {
  page?: number
  size?: number
}
export const getAvailableDomains = createAsyncThunk<
  GetAvailableDomainsResponse,
  GetAvailableDomainsRequest | undefined,
  ApiRejectResponse
>('USER/getAvailableDomains', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.GET_AVAILABLE_DOMAINS, {
      params: {
        page: payload?.page,
        size: payload?.size
      }
    })
    return objectToCamel(resp.data) as any
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
