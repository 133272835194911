import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  contentWrapper: {
    position: 'relative',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  loadingOverlay: {
    position: 'absolute',
    zIndex: 1000,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.8,
    backgroundColor: colors.white
  }
}))
