import React, { PropsWithChildren, useMemo } from 'react'

import { Typography } from '@barracuda-internal/bds-core'
import styles from 'components/libs/layout/pages/tableTextStyle'

export interface TableTextProps extends PropsWithChildren {
  dataText?: string | number
  truncated?: boolean
  dataTestId?: string
}

export const TableText: React.FC<TableTextProps> = React.forwardRef(
  ({ dataText, truncated = false, dataTestId, children }: TableTextProps, _) => {
    const classes = styles()

    return useMemo(
      () => (
        <Typography
          className={truncated ? classes.ellipsisText : classes.tableText}
          variant="body2"
          data-testid={dataTestId}
        >
          {dataText}
          {children}
        </Typography>
      ),
      [classes.ellipsisText, classes.tableText, dataTestId, dataText, truncated, children]
    )
  }
)
