import API_ENDPOINTS from '../../config/apiEndpoints'
import initApiRoutes from './initApiRoutes'

export interface ApiRoutes {
  [key: string]: ApiRoute
}

export interface ApiRoute {
  method: any
  path(params: Record<string, unknown>): string
  url: string
}

const apiRoutes: ApiRoutes = initApiRoutes({ API_ENDPOINTS })

export default apiRoutes
