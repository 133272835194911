import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Typography, LinearProgress, Grid } from '@barracuda-internal/bds-core'

import styles from 'components/pages/azureSSO/azureSSOStyles'
import { useAzureSSOLogic } from 'components/pages/azureSSO/useAzureSSOLogic'
import ErrorDialog from 'components/libs/dialog/errorDialog/ErrorDialog'

const AzureSSO: React.FC = () => {
  const classes = styles()
  const intl = useIntl()
  const [state, eventHandlers] = useAzureSSOLogic()
  const { loginError } = state
  const { onCloseErrorDialog } = eventHandlers

  const text = useCallback((id: string) => intl.formatMessage({ id: `ess.login.${id}` }), [intl])

  return useMemo(
    () => (
      <Grid container className={classes.root}>
        {loginError ? (
          <Grid item xs={12}>
            <ErrorDialog text={text('login_failed')} onClose={onCloseErrorDialog} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography variant="overline">{text('signing_in')}</Typography>
            <Grid container className={classes.progress}>
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    [classes.progress, classes.root, loginError, onCloseErrorDialog, text]
  )
}
export default AzureSSO
