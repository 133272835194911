import React, { useMemo } from 'react'
import { Button, Grid, TextField, Typography } from '@barracuda-internal/bds-core'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { useLdapSettingsLogic } from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/useLdapSettingsLogic'
import { FormRow } from 'components/libs/layout/pages/FormRow'
import { useFormatMessage } from 'lib/localization'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'
import { InlineRadioGroup } from 'components/libs/layout/pages/formContent/InlineRadioGroup'
import { SettingValue } from 'types/Settings'
import { HelperText } from 'components/libs/layout/pages/HelperText'
import styles from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/ldapSettingsStyles'
import { LdapTestDialog } from 'components/pages/domains/editDomain/editDomainDirectoryServices/ldapSettings/LdapTestDialog'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface LdapSettingsProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains.edit_domain.directory_services.ldap'
const EDIT_DOMAIN_I18N_KEY = 'ess.domains.edit_domain'

export const LdapSettings = ({ form }: LdapSettingsProps) => {
  const [state, eventHandlers] = useLdapSettingsLogic(form)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatEditDomainMessage = useFormatMessage(EDIT_DOMAIN_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <>
        {/* BASIC CONFIGURATION */}
        <FormBlock title={formatMessage('ldap_configuration.title')} titleVariant="h5">
          {state.isLdapTestDialogOpen && <LdapTestDialog onClose={eventHandlers.onCloseLdapTestDialog} />}
          <FormRow rowTitle={formatMessage('ldap_configuration.host')}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={9}>
                <TextField
                  value={state.ldapHost}
                  onChange={eventHandlers.onChangeLdapHost}
                  fullWidth
                  className={`${classes.rightMargin} ${classes.fullWidthWithMargin}`}
                />
              </Grid>
              <Grid item xs={3} className={classes.verticalAlign}>
                <Typography variant="body1" className={classes.rightMargin}>
                  {formatMessage('ldap_configuration.port')}:
                </Typography>
                <TextField value={state.ldapPort} type="number" onChange={eventHandlers.onChangeLdapPort} fullWidth />
              </Grid>
            </Grid>
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('ldap_configuration.use_ssl')}>
            <InlineRadioGroup
              name="use_ssl"
              value={state.ldapSsl}
              onChange={eventHandlers.onChangeLdapSsl}
              disabled={false}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
              labels={[formatMessage('ldap_configuration.use_ssl_yes'), formatMessage('ldap_configuration.use_ssl_no')]}
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('ldap_configuration.ldap_username')}>
            <TextField value={state.ldapUsername} onChange={eventHandlers.onChangeLdapUsername} fullWidth />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('ldap_configuration.ldap_password')}>
            <TextField
              value={state.ldapNewPassword}
              placeholder="*****************"
              type="password"
              onChange={eventHandlers.onChangeLdapNewPassword}
              fullWidth
              helperText={state.errors.ldapNewPassword ? formatEditDomainMessage(state.errors.ldapNewPassword) : ''}
              error={!!state.errors.ldapNewPassword}
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('ldap_configuration.ldap_base_dn')}>
            <TextField
              value={state.ldapBaseDn}
              onChange={eventHandlers.onChangeLdapBaseDn}
              helperText={formatMessage('ldap_configuration.ldap_base_dn_helper')}
              fullWidth
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('ldap_configuration.ldap_mail_attributes')}>
            <TextField
              value={state.ldapAttributes}
              onChange={eventHandlers.onChangeLdapAttributes}
              helperText={formatMessage('ldap_configuration.ldap_mail_attributes_helper')}
              fullWidth
            />
          </FormRow>
          <FormSpacer />
        </FormBlock>

        {/* TESTING */}
        <FormBlock title={formatMessage('ldap_configuration.test_config')} titleVariant="h5">
          <FormRow rowTitle={formatMessage('ldap_configuration.testing_email_address')}>
            <TextField
              value={state.ldapTestingEmailAddress}
              onChange={eventHandlers.onChangeLdapTestingEmailAddress}
              helperText={formatMessage('ldap_configuration.testing_email_address_helper')}
              fullWidth
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle="">
            <Button variant="contained" color="secondary" onClick={eventHandlers.onTestSettings}>
              {formatMessage('ldap_configuration.test_settings')}
            </Button>
          </FormRow>
        </FormBlock>

        {/* ADVANCED CONFIGURATION */}
        <FormBlock
          title={formatMessage('advanced_configuration.title')}
          titleVariant="h5"
          collapsible
          collapsed={state.isAdvancedOptionsCollapsed}
          onClickTitle={eventHandlers.onToggleAdvancedOptions}
        >
          <FormRow rowTitle={formatMessage('advanced_configuration.domain_limited')}>
            <InlineRadioGroup
              name="domain_limited"
              value={state.ldapDomainLimited}
              onChange={eventHandlers.onChangeLdapDomainLimited}
              disabled={false}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
              labels={[
                formatMessage('advanced_configuration.domain_limited_yes'),
                formatMessage('advanced_configuration.domain_limited_no')
              ]}
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('advanced_configuration.user_filter')} alignTitleToTop>
            <TextField value={state.ldapUserFilter} onChange={eventHandlers.onChangeLdapUserFilter} fullWidth />
            <FormSpacer half />
            <HelperText>
              {formatMessage('advanced_configuration.user_filter_helper.info')}
              <br />
              {formatMessage('advanced_configuration.user_filter_helper.example')}
            </HelperText>
          </FormRow>
          <FormSpacer />
        </FormBlock>

        {/* DIRECTORY OPTIONS */}
        <FormBlock title={formatMessage('directory_options.title')} titleVariant="h5">
          <FormRow rowTitle={formatMessage('directory_options.ldap_sync')}>
            <Grid container direction="row">
              <InlineRadioGroup
                name="ldap_sync"
                value={state.ldapSync}
                onChange={eventHandlers.onChangeLdapSync}
                disabled={false}
                values={[SettingValue.ENABLED, SettingValue.DISABLED]}
                labels={[
                  formatMessage('directory_options.ldap_sync_yes'),
                  formatMessage('directory_options.ldap_sync_no')
                ]}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={eventHandlers.onSyncNow}
                disabled={state.isLdapSyncPending || state.inProgress}
                isLoading={state.isLdapSyncPending}
              >
                {formatMessage('directory_options.sync_now')}
              </Button>
            </Grid>
            <FormSpacer half />
            {state.errors.ldapSyncWithUserAutoAdd && (
              <>
                <HelperText error>{formatEditDomainMessage(state.errors.ldapSyncWithUserAutoAdd)}</HelperText>
                <FormSpacer half />
              </>
            )}
            {state.ldapLastSync && (
              <HelperText>
                {formatMessage('directory_options.ldap_sync_helper', { last_ldap_sync: state.ldapLastSync })}
              </HelperText>
            )}
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('directory_options.ldap_auth')}>
            <InlineRadioGroup
              name="ldap_auth"
              value={state.ldapAuth}
              onChange={eventHandlers.onChangeLdapAuth}
              disabled={false}
              values={[SettingValue.ENABLED, SettingValue.DISABLED]}
              labels={[
                formatMessage('directory_options.ldap_auth_yes'),
                formatMessage('directory_options.ldap_auth_no')
              ]}
            />
          </FormRow>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('directory_options.auth_filter')} alignTitleToTop>
            <TextField value={state.ldapAuthFilter} onChange={eventHandlers.onChangeLdapAuthFilter} fullWidth />
            <FormSpacer half />
            <HelperText>
              {formatMessage('directory_options.auth_filter_helper.info')}
              <br />
              {formatMessage('directory_options.auth_filter_helper.user_name')}
              <br />
              {formatMessage('directory_options.auth_filter_helper.email_address')}
              <br />
              {formatMessage('directory_options.auth_filter_helper.domain_name')}
              <br />
              {formatMessage('directory_options.auth_filter_helper.example')}
              <br />
            </HelperText>
          </FormRow>
        </FormBlock>
      </>
    ),
    [classes, eventHandlers, formatEditDomainMessage, formatMessage, state]
  )
}
