import React, { useMemo } from 'react'

import { Button, Fab, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@barracuda-internal/bds-core'
import { Checkmark, CheckmarkCircle } from '@barracuda-internal/bds-core/dist/Icons/Core'
// BDS v0.10.1 doesn't have this icon
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import { useFormatMessage } from 'lib/localization'

import { ServerAndMxSetupSteps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { useServerAndMxSetupLogic } from 'components/pages/onboardWizard/serverAndMxSetup/useServerAndMxSetupLogic'
import styles from 'components/pages/onboardWizard/serverAndMxSetup/serverAndMxSetupStyles'

import SpecifyEmailServer from 'components/pages/onboardWizard/serverAndMxSetup/sections/SpecifyEmailServer'
import AddNewMxRecords from 'components/pages/onboardWizard/serverAndMxSetup/sections/AddNewMxRecords'
import RemoveOldMxRecords from 'components/pages/onboardWizard/serverAndMxSetup/sections/RemoveOldMxRecords'

const BASE_I18N_KEY = 'ess_wizard.server_and_mx_setup'

const ServerAndMxSetup = () => {
  const classes = styles()
  const formatNavMessage = useFormatMessage(`${BASE_I18N_KEY}.nav`)
  const formatContentMessage = useFormatMessage(`${BASE_I18N_KEY}.content`)

  const [state, eventHandlers] = useServerAndMxSetupLogic()

  return useMemo(
    () => (
      <Grid className={classes.container} container justifyContent="center" alignItems="flex-start">
        {/* NAV */}
        <Grid data-testid="nav-wrapper" className={classes.navWrapper} container>
          <Grid className={classes.navInnerWrapper} container direction="column">
            <List className={classes.navList} aria-label="server and mx setup">
              {Object.entries(state.navItemStatuses).map((navItemConfig, idx) => {
                const [navItemName, [isActive, isVerified, isErrored]] = navItemConfig

                return (
                  <ListItem
                    button
                    data-testid={`${navItemName}-nav-item`}
                    className={isActive ? classes.activeNavItem : classes.navItem}
                    key={navItemName}
                    selected={isActive}
                    onClick={() => {
                      eventHandlers.onSelectSection(navItemName as ServerAndMxSetupSteps)
                    }}
                  >
                    {isVerified && !isErrored && (
                      <ListItemIcon data-testid="verified-nav-item" className={classes.navItemIcon}>
                        <CheckmarkCircle className={classes.successNavIcon} />
                      </ListItemIcon>
                    )}
                    {isErrored && (
                      <ListItemIcon data-testid="errored-nav-item" className={classes.navItemIcon}>
                        <ErrorOutlineIcon className={classes.erroredNavIcon} />
                      </ListItemIcon>
                    )}
                    <ListItemText
                      data-testid="nav-item"
                      data-active-nav-item={isActive}
                      inset={!isVerified && !isErrored}
                    >
                      <Typography className={isActive ? classes.activeNavItemText : classes.navItemText}>
                        {`${idx + 1}. ${formatNavMessage(`item_${idx + 1}`)}`}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
        </Grid>

        {/* CONTENT */}
        <Grid data-testid="content-wrapper" className={classes.contentWrapper} container direction="column">
          <Typography className={classes.title} variant="h2">
            {formatContentMessage('title')}
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            {formatContentMessage('subtitle')}
          </Typography>
          {Object.entries(state.sectionsIsVerifiedStates).map((sectionState, idx) => {
            const [sectionName, isVerified] = sectionState

            return (
              <Grid
                key={sectionName}
                data-testid={sectionName}
                className={classes.sectionWrapper}
                data-wizard-section={sectionName}
                container
                direction="row"
              >
                <Grid className={classes.stepIndicatorWrapper} container alignItems="flex-start">
                  <Fab className={isVerified ? classes.stepIndicatorIconSuccess : classes.stepIndicatorIcon}>
                    {isVerified ? <Checkmark data-testid="verified-section" /> : idx + 1}
                  </Fab>
                </Grid>
                <Grid item xs>
                  <Grid className={classes.sectionHeading} container direction="column">
                    <Typography className={classes.sectionTitle} variant="h3">
                      {formatContentMessage(`section_${idx + 1}_title`)}
                    </Typography>
                    <Typography className={classes.sectionSubtitle} variant="body1">
                      {formatContentMessage(`section_${idx + 1}_subtitle`)}
                    </Typography>
                  </Grid>
                  <Grid className={classes.sectionContentWrapper} container>
                    {sectionName === ServerAndMxSetupSteps.specifyEmailServer && (
                      <SpecifyEmailServer
                        sectionsVerifiedStates={state.sectionsIsVerifiedStates}
                        isRequestedToCompleteSetup={state.isReqestedToCompleteSetup}
                        setSpecifyEmailServerSectionErrorState={eventHandlers.setSectionVerifiedState(
                          ServerAndMxSetupSteps.specifyEmailServer,
                          eventHandlers.setIsSpecifyEmailServerSectionHasError
                        )}
                        setSectionToActive={() => {
                          eventHandlers.onSelectSection(ServerAndMxSetupSteps.specifyEmailServer, true)
                        }}
                      />
                    )}
                    {sectionName === ServerAndMxSetupSteps.addNewMxRecords && (
                      <AddNewMxRecords
                        sectionsVerifiedStates={state.sectionsIsVerifiedStates}
                        isRequestedToCompleteSetup={state.isReqestedToCompleteSetup}
                        setIsAddMxRecordsSectionHasError={eventHandlers.setSectionVerifiedState(
                          ServerAndMxSetupSteps.addNewMxRecords,
                          eventHandlers.setIsAddMxRecordsSectionHasError
                        )}
                        setSectionToActive={() => {
                          eventHandlers.onSelectSection(ServerAndMxSetupSteps.addNewMxRecords, true)
                        }}
                      />
                    )}
                    {sectionName === ServerAndMxSetupSteps.removeOldMxRecords && (
                      <RemoveOldMxRecords
                        sectionsVerifiedStates={state.sectionsIsVerifiedStates}
                        isRequestedToCompleteSetup={state.isReqestedToCompleteSetup}
                        setIsRemoveOldMxRecordsSectionHasError={eventHandlers.setSectionVerifiedState(
                          ServerAndMxSetupSteps.removeOldMxRecords,
                          eventHandlers.setIsRemoveOldMxRecordsSectionHasError
                        )}
                        setSectionToActive={() => {
                          eventHandlers.onSelectSection(ServerAndMxSetupSteps.removeOldMxRecords, true)
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          <Grid className={classes.buttonsContainer} container direction="row" justifyContent="flex-end">
            <Button
              className={classes.saveAndExitButton}
              color="primary"
              disabled={state.saveAndExitButtonStatuses.disabled}
              isLoading={state.saveAndExitButtonStatuses.inProgress}
              onClick={eventHandlers.onSaveAndExit}
            >
              {formatContentMessage('save_and_exit')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={state.saveAndExitButtonStatuses.inProgress}
              isLoading={state.saveAndExitButtonStatuses.disabled}
              onClick={eventHandlers.onCompleteSetup}
            >
              {formatContentMessage('complete_setup')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes, formatNavMessage, formatContentMessage, state, eventHandlers]
  )
}

export default ServerAndMxSetup
