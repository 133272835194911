import React, { useMemo } from 'react'
import { Alert, CircularProgress } from '@barracuda-internal/bds-core'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import DomainSettings from 'components/pages/support/help/domainSettings/DomainSettings'
import { useEditDomainLogic } from 'components/pages/domains/editDomain/useEditDomainLogic'
import EditDomainMailServers from 'components/pages/domains/editDomain/editDomainMailServers/EditDomainMailServers'
import EditDomainAlias from 'components/pages/domains/editDomain/editDomainAlias/EditDomainAlias'
import EditDomainOptions from 'components/pages/domains/editDomain/editDomainOptions/EditDomainOptions'
import EditDomainDirectoryServices from 'components/pages/domains/editDomain/editDomainDirectoryServices/EditDomainDirectoryServices'
import { EditDomainWarningDialog } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import EditDomainSmtpEncryption from 'components/pages/domains/editDomain/editDomainSmtpEncryption/EditDomainSmtpEncryption'
import EditDomainEncryption from 'components/pages/domains/editDomain/editDomainEncryption/EditDomainEncryption'
import EditDomainAdvanced from 'components/pages/domains/editDomain/editDomainAdvanced/EditDomainAdvanced'
import { PageContainer, PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'

const BASE_I18N_KEY = 'ess.domains'

const EditDomain = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [state, eventHandlers, form] = useEditDomainLogic()
  const [
    {
      hasChanges,
      shouldConfirmNavigation,
      domain,
      isSaveDisabled,
      isSavePending,
      error,
      isGetDomainPending,
      warningDialogProps
    },
    { onSave, onCancel, setIsSmtpEncryptionTableDirty }
  ] = form

  return useMemo(
    () => (
      <PageContainer isLoading={isGetDomainPending} dirtyCheck isDirtyForm={hasChanges || shouldConfirmNavigation}>
        <EditDomainWarningDialog {...warningDialogProps} />
        <PageTitle
          title={
            <>
              {formatMessage('edit_domain.header.title')} (
              {domain?.domainName ? `${domain?.domainName}` : <CircularProgress color="primary" size={16} />})
            </>
          }
          onOpenSupport={eventHandlers.help.onHelpOpen}
          onCancel={onCancel}
          onSubmit={() => onSave(false)}
          submitInProgress={isSavePending}
          isDisabledSubmit={!hasChanges || isSaveDisabled}
          help={{
            content: DomainSettings,
            onClose: eventHandlers.help.onHelpClose,
            title: TOPICS.domainSettings,
            group: GROUP_TITLE.domains,
            isHelpOpen: state.help.isHelpOpen
          }}
        />
        <FormSpacer />
        {error && (
          <>
            <Alert severity="error">{errorFormatMessage(error)}</Alert>
            <FormSpacer />
          </>
        )}
        <EditDomainMailServers form={form} />
        {state.permissions.canManageAlias && <EditDomainAlias form={form} />}
        <EditDomainOptions form={form} />
        <EditDomainSmtpEncryption form={form} delegateDirtyFormState={setIsSmtpEncryptionTableDirty} />
        {state.permissions.canManageEncryption && <EditDomainEncryption form={form} />}
        {state.permissions.canManageDirectoryServices && <EditDomainDirectoryServices form={form} />}
        {state.permissions.canManageAdvancedConfiguration && <EditDomainAdvanced form={form} />}
        <FormSpacer />
        <FormSpacer />
      </PageContainer>
    ),
    [
      isGetDomainPending,
      hasChanges,
      shouldConfirmNavigation,
      warningDialogProps,
      formatMessage,
      domain?.domainName,
      eventHandlers.help.onHelpOpen,
      eventHandlers.help.onHelpClose,
      onCancel,
      isSavePending,
      isSaveDisabled,
      state.help.isHelpOpen,
      state.permissions.canManageAlias,
      state.permissions.canManageEncryption,
      state.permissions.canManageDirectoryServices,
      state.permissions.canManageAdvancedConfiguration,
      error,
      errorFormatMessage,
      form,
      setIsSmtpEncryptionTableDirty,
      onSave
    ]
  )
}

export default EditDomain
