import React from 'react'

import { Alert, Typography } from '@barracuda-internal/bds-core'
import styles from 'components/pages/support/help/helpStyles'
import Preview from 'assets/images/barracuda/email_banner_preview.png'
import { HelpComponentProps } from 'types/Help'

function InboundEmailBanners(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Email warning banners are designed to alert end users about the types of potential threats that may exist within
        a given email. If enabled, a banner will display at the top of the suspicious emails.
        <a href="https://campus.barracuda.com/doc/167977270/" target="_blank" rel="noreferrer">
          &nbsp;Learn more
        </a>
      </p>
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email.
      You are unable to change these settings for a specific domain.
      <br />
      <br />
      <Alert severity="info">
        <b>Beta version</b> This feature is a beta release. There is an option to test the feature for a select set of
        users.
      </Alert>
      <br />
      <img src={Preview} alt="Preview email banners" />
      <br />
      <br />
      <b>Email warning banner types:</b>
      <br />
      <br />
      <div className={`${classes.bannerRectangle} ${classes.infoBanner}`}>
        <Typography variant="h5">External sender</Typography>
        <span>Confirm you trust this sender before taking any actions.</span>
      </div>
      <br />
      <div className={`${classes.bannerRectangle} ${classes.warningBanner}`}>
        <Typography variant="h5">Warning: Unusual sender &lt;sender@email.com&gt;</Typography>
        <span>
          You don&apos;t usually receive emails from this address. Confirm you trust this sender before taking any
          actions.
        </span>
      </div>
      <br />
      <div className={`${classes.bannerRectangle} ${classes.warningBanner}`}>
        <Typography variant="h5">Warning: Newly registered sender domain &lt;newdomain.com&gt;</Typography>
        <span>
          This message comes from a newly registered domain, active for only a short time. Scammers commonly create new
          domains for attacks.
        </span>
      </div>
      <br />
      <div className={`${classes.bannerRectangle} ${classes.warningBanner}`}>
        <Typography variant="h5">Warning: Unusual sender IP</Typography>
        <span>
          This message originated from a source not commonly seen for this domain, which could be an indication of a
          scam.
        </span>
      </div>
      <br />
      <div className={`${classes.bannerRectangle} ${classes.warningBanner}`}>
        <Typography variant="h5">Warning: Unusual link</Typography>
        <span>
          This message contains an unusual link, which may lead to a malicious site. Confirm the message is safe before
          clicking any links.
        </span>
      </div>
    </div>
  )
}

export default InboundEmailBanners
