import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundContentPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      <p>
        Content filtering controls the delivery of a message based on characteristics of the message Subject, Header,
        Body, Sender, Recipient, and Attachment(s). You can specify simple words or phrases when you create filters, and
        choose where to apply these filters for inbound messages.
        <br />
        <b>Note:</b> Content filtering is <i>not</i> case sensitive.
      </p>
      <div>
        This page includes the following sections:
        <br />
        <a href="#inbound_attachment_filter">Attachment Filter</a>
        <br />
        <a href="#inbound_message_content_filter">Message Content Filter</a>
        <br />
        <br />
        <h3 id="inbound_attachment_filter">Attachment Filter</h3>
        <p>For inbound mail, you can choose File Name filtering or MIME Type filtering for attachments.</p>
      </div>
      <h4>File Name Filtering</h4>
      Add the attachment filename patterns you want to block{!props.hasCpl && <>, quarantine,</>} or ignore. All email
      messages, except those from allowed senders, go through attachment filtering. Enter filename patterns using{' '}
      <b>*</b> as a wildcard, then indicate <b>Block</b>
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,
        </>
      )}{' '}
      or <b>Ignore</b> for attachments that match your filename patterns, for example:
      <ul>
        <li>To take an action for all .zip files, specify *.zip</li>
        <li>
          To take an action with all files that include the word <b>jobs</b>, enter *jobs*{' '}
        </li>
      </ul>
      <p>
        Actions you specify for inbound file name patterns apply to files found in .zip and other archive files when you
        select <b>Scan</b> from the <b>Check Archives</b> drop-down menu.
      </p>
      <br />
      <h4>MIME Type Filtering</h4>
      You can select to <b>Block</b>
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,
        </>
      )}{' '}
      or <b>Ignore</b> inbound messages if they contain attachments with certain MIME types.Use the formats of the MIME
      type examples in the table below; <i>do not use regular expressions</i>.
      <br />
      <br />
      <b>Example MIME Types include:</b>
      <br />
      <br />
      application / msword <br />
      application / octet - stream <br />
      application / x - gzip <br />
      application / java - archive <br />
      application / x - javascript <br />
      application / pdf <br />
      application / vnd.ms - powerpoint <br />
      image / gif <br />
      image / jpeg <br />
      image / png <br />
      video / quicktime <br />
      audio / mpeg
      <br /> <br />
      Click on one of the following column headings to sort the table:
      <ul>
        <li>Filter Type</li>
        <li>Pattern</li>
        <li>Action</li>
        <li>Comment</li>
      </ul>
      <h4>Password Protected Archive Filtering</h4>
      Using the drop-down menu, select the action to take with attached archive files that require a password to unpack:{' '}
      <b>Block</b>
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,
        </>
      )}{' '}
      or <b>Ignore</b>. Choosing <b> Ignore</b> means that the service does not look for, or act on, emails with
      attachments that require a password to unpack.
      <br />
      <br />
      <h4>Password Protected Microsoft Documents</h4>
      Using the drop-down menu, select the action to take with messages containing password protected Microsoft
      attachments: <b>Block</b>
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,
        </>
      )}{' '}
      or <b>Ignore</b>. Choosing <b> Ignore</b> means that the service does not look for, or act on, emails containing
      password protected Microsoft documents.
      <br />
      <br />
      <h4>Password Protected PDF Documents</h4>
      Using the drop-down menu, select the action to take with messages containing password protected PDF attachments:{' '}
      <b>Block</b>
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,
        </>
      )}{' '}
      or <b>Ignore</b>. Choosing <b> Ignore</b> means that the service does not look for, or act on, emails containing
      password protected PDF documents.
      <br /> <br />
      <h3 id="inbound_message_content_filter">Message Content Filter</h3>
      You can use{' '}
      <a
        href="https://campus.barracuda.com/product/campus/doc/5472273/regular-expressions"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        Regular Expressions
      </a>
      , and the following <b> special characters</b>
      by escaping(preceding the character) with a backslash(&quot;\&quot;):
      <div>. [ & nbsp; ] & nbsp; \ & nbsp; * & nbsp; ? & nbsp; $ & nbsp; ( & nbsp; ) & nbsp; | & nbsp; ^ & nbsp; @</div>
      <br />
      <br />
      Enter filter patterns, one per line, selecting <b> Allow</b>{' '}
      {!props.hasCpl && (
        <>
          , <b>Quarantine</b>,{' '}
        </>
      )}{' '}
      or <b> Block</b> for <b>Subject</b>, <b>Headers</b>, <b>Body</b>,<b>Attachment</b>, <b>Sender</b> address, or{' '}
      <b> Recipient</b> address.Note that Header filters are applied to both the header name and content of any header,
      while Subject filters only scan the contents of the Subject header.
      <br />
      Selecting the <b> Sender</b> and / or the <b> Recipient</b> address checkboxes checks only the <b> Header</b>{' '}
      address(and not the <b> Envelope</b> address). Note the following:
      <ul>
        <li>
          To check the <b>Envelope From</b> address, add an entry in <b>Sender Policies</b>, which also checks the{' '}
          <b>Header From</b> field.
        </li>
        <li>
          To check the <b>Envelope To</b> address, add an entry in <b>Recipient Policies</b>, which also checks the{' '}
          <b>Header To</b> field.
        </li>
      </ul>
      <p>
        Note that Attachment Content Filters are limited to text type files such as most MS Office files, HTML, pdf
        files and other document files. Archive files such as zip, tar, etc. are searched for document files.
      </p>
      <br />
      Click on one of the following column headings to sort the table:
      <ul>
        <li>Pattern</li>
        <li>Action</li>
      </ul>
    </div>
  )
}

export default InboundContentPolicies
