import React, { useMemo } from 'react'

import { Button, Grid } from '@barracuda-internal/bds-core'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useSenderPoliciesLogic } from 'components/pages/inboundSettings/senderPolicies/useSenderPoliciesLogic'
import InboundSenderPolicies from 'components/pages/support/help/inboundSenderPolicies/InboundSenderPolicies'
import SenderPoliciesTable from 'components/pages/inboundSettings/senderPolicies/SenderPoliciesTable'
import { PageTitle } from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_INBOUND_I18N_KEY = 'ess.inbound_settings'
const BASE_I18N_KEY = 'ess.inbound_settings.sender_policies'

const SenderPolicies: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatInboundMessage = useFormatMessage(BASE_INBOUND_I18N_KEY)
  const [state, eventHandlers] = useSenderPoliciesLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.isDirtyForm}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundSenderPolicies,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.senderPolicies,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <Grid container>
          <Grid container>
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEdit}
              variant="contained"
              size="small"
              data-testid="bulk-button"
            >
              {formatInboundMessage('bulk_edit')}
            </Button>
          </Grid>
          <SenderPoliciesTable
            data={state.data}
            availablePolicies={state.availablePolicies}
            onAddItem={eventHandlers.onAdd}
            onRemoveItem={eventHandlers.onRemove}
            delegateDirtyFormState={eventHandlers.setDirtyForm}
          />
        </Grid>
      </SettingsPageContainer>
    ),
    [formatMessage, eventHandlers, inboundClasses.bulkEdit, formatInboundMessage, state]
  )
}

export default SenderPolicies
