/* eslint-disable quotes */
const USERS_LIST = 'Lista de usuarios'
const DEFAULT_POLICY = 'Política por defecto'
const ADD_UPDATE_USERS = 'adir/actualizar usuarios'
const QUARANTINE_NOTIFICATION = 'Notificación de cuarentena'
const EMAIL_CONTINUITY = 'Continuidad del correo electrónico'
const ALL = 'Todos'
const SEARCH = 'Buscar en'
const ENABLED = 'Activado'
const DISABLED = 'Discapacitados'
const LOADING = 'Cargando...'
const EDIT = 'Editar'
const DOMAINS = 'Dominios'
const USER_ROLE = 'Función del usuario'
const SAVE = 'Guardar'
const DELETE = 'Borrar'
const CANCEL = 'Cancelar'
const YES = 'Sí'
const NO = 'No'
const OFF = 'Fuera de'
const NEVER = 'Nunca'
const SCHEDULED = 'Programado'
const USER_ROLES = {
  all: ALL,
  domain_admin: 'Administrador del dominio',
  help_desk: 'Servicio de asistencia',
  user: 'Usuario'
}

const users = {
  tabs: {
    users_list: USERS_LIST,
    default_policy: DEFAULT_POLICY,
    add_update_users: ADD_UPDATE_USERS,
    quarantine_notification: QUARANTINE_NOTIFICATION,
    email_continuity: EMAIL_CONTINUITY
  },
  cancel: CANCEL,
  save_changes: 'Guardar cambios',
  users_list: {
    title: USERS_LIST,
    user_role: USER_ROLE,
    domains: DOMAINS,
    all: ALL,
    loading: LOADING,
    no_records_available: 'No hay registros disponibles',
    add_update_users: 'Añadir/actualizar usuarios',
    delete_users: 'Eliminar usuarios',
    user_roles: USER_ROLES,
    logging_you_as: 'Registrándote como "{user}"',
    table: {
      user_id: 'Cuenta de usuario (Email)',
      linked_accounts: 'cuentas vinculadas',
      source: 'Fuente',
      role: 'Papel',
      quarantine: 'Cuarentena',
      actions: 'Acciones'
    },
    sources: {
      azure_ad: 'Azure AD',
      ldap: 'Ldap',
      manual: 'Manual',
      auto: 'Auto'
    },
    quarantine: {
      enabled: ENABLED,
      disabled: DISABLED
    },
    actions: {
      edit: EDIT,
      reset_password: 'Restablecer contraseña',
      log_in_as: 'Iniciar sesión como este usuario',
      delete: 'Borrar'
    },
    search_placeholder: 'Buscar cuenta de usuario, cuentas vinculadas',
    alerts: {
      success_reset_password: 'La información de inicio de sesión se ha enviado a <b>{user}</b>',
      failed_reset_password: 'No se puede enviar la información de restablecimiento de contraseña a <b>{user}</b>'
    }
  },
  edit_user_dialog: {
    title: 'Editar usuario: <b>{user}</b>',
    content_title: 'Funciones y permisos',
    user_role: USER_ROLE,
    domains: DOMAINS,
    save: SAVE,
    cancel: CANCEL,
    user_roles: USER_ROLES,
    domain: 'Dominio',
    missed_domain_selection: 'Por favor, seleccione dominio(s) para el rol de usuario seleccionado',
    user_update_error: 'No se puede actualizar el rol del usuario'
  },
  delete_user_dialog: {
    title: 'Borrar usuario: <b>{user}</b>?',
    titles: 'Eliminar los usuarios <b>{count}</b> seleccionados?',
    content_title: 'Confirmar eliminación',
    confirm: DELETE,
    cancel: CANCEL,
    user_delete_error: 'No se pueden eliminar los usuarios seleccionados'
  },
  default_policy: {
    title: DEFAULT_POLICY,
    information_text:
      'Especifique las políticas de análisis de correo electrónico predeterminadas para usuarios administrados y no administrados.',
    default_policy_managed_users: 'Política por defecto para usuarios gestionados (usuarios de la lista Usuarios)',
    default_policy_unmanaged_users:
      'Política por defecto para usuarios no gestionados (usuarios NO incluidos en la lista de usuarios)',
    exempt_senders: 'Remitentes exentos',
    exempt_senders_information_text:
      'Nota: El cambio de este valor afectará a la configuración actual de la política del usuario.',
    allow_users_to_block: 'Permitir a los usuarios bloquear remitentes',
    allow_users_to_block_information_text:
      'Nota: El cambio de este valor afectará a la configuración actual de la política del usuario.',
    allow_deliver_blocked_messages: 'Permitir a los usuarios finales ver y entregar mensajes bloqueados',
    allow_deliver_blocked_messages_information_text:
      'Nota: Esto permitirá a los usuarios finales enviar mensajes potencialmente dañinos o hacer clic en enlaces peligrosos.',
    allow_deliver_blocked_messages_information_text_2: 'No se recomienda.',
    allow_deliver_quarantined_messages: 'Permitir a los usuarios finales ver y entregar mensajes en cuarentena',
    allow_deliver_quarantined_messages_information_text:
      'Nota: Esto permitirá a los usuarios finales enviar mensajes potencialmente dañinos o hacer clic en enlaces peligrosos.',
    default_timezone: 'Zona horaria por defecto',
    managed_policy_select: {
      allow: 'permitir',
      scan: 'escanear',
      block: 'bloque'
    },
    exempt_senders_select: {
      0: 'No permitir que los usuarios eximan a los remitentes',
      1: 'Permitir a los usuarios eximir a los remitentes',
      2: 'Permitir a los usuarios eximir a los remitentes, pero no anular las listas de bloqueo del administrador.'
    }
  },
  add_update_users: {
    title: ADD_UPDATE_USERS,
    user_accounts: 'Cuentas de usuario',
    user_email_error: 'Introduzca al menos una dirección de correo electrónico válida.',
    information_text:
      'Cree o actualice manualmente las cuentas de usuario con la configuración que se indica a continuación.. Si la opción <b>Notificar a nuevos</b> usuarios está configurada como <i>Sí</i>, el usuario recibirá un correo electrónico de bienvenida en cuanto se cree la cuenta. El enlace del correo electrónico de bienvenida para el inicio de sesión en la cuenta de usuario caduca en 7 días.',
    enable_user_quarantine: 'Activar la cuarentena de usuarios',
    notify_new_users: 'Notificar a nuevos usuarios',
    yes: YES,
    no: NO,
    results: {
      base_text: 'Se han realizado las siguientes acciones en la cuenta de usuario.',
      quarantine_notification:
        'La notificación de cuarentena ha sido {enabled, plural, =0 {deshabilitada} otra {habilitada}}.',
      notified_users: 'Los usuarios han sido notificados por correo electrónico.',
      successfully_added: 'Usuario <b>{user_id}</b> añadido.',
      bulk_successfully_added: '<b>{count}</b> usuarios añadidos..',
      successfully_updated: 'Usuario <b>{user_id}</b> actualizado.',
      bulk_successfully_updated: '<b>{count}</b> usuarios actualizados.',
      failed_to_add_user: 'Error al añadir el usuario <b>{user_id}</b>.',
      failed_to_update_user: 'Error al actualizar el usuario <b>{user_id}</b>.',
      invalid_email: 'La dirección de correo electrónico <b>{user_id}</b> no es válida.',
      invalid_domain:
        'El dominio de correo electrónico <b>{domain}</b> no está permitido. Solo puede añadir usuarios a dominios verificados.',
      missed_domain_right: 'No tienes permiso para gestionar usuarios en <b>{domain}</b>.',
      already_exists_as_linked_account:
        '<b>{user_id}</b> ya existe como cuenta vinculada.  Elige otra dirección de correo electrónico para añadirla.'
    }
  },
  quarantine_notification: {
    title: QUARANTINE_NOTIFICATION,
    quarantine_messages_for_intent_analysis: 'Cuarentena de mensajes para el análisis de intenciones',
    quarantine_messages_for_intent_analysis_help:
      'Cuando se establece en Sí, los mensajes que serían bloqueados por el Análisis de Intenciones serán puestos en cuarentena en su lugar.',
    default_interval: 'Intervalo predeterminado para las notificaciones de cuarentena de usuarios',
    scheduled_interval: 'Programar intervalos de notificación',
    scheduled_interval_help:
      'Las notificaciones se enviarán en cualquier momento dentro del bloque de horas elegido. Haga clic y arrastre para seleccionar. Mantenga pulsada la tecla Ctrl mientras arrastra para anular la selección.',
    allow_specify_interval: 'Permitir a los usuarios especificar el intervalo',
    allow_temp_passcode: 'Permitir a los usuarios iniciar sesión con una contraseña temporal',
    allow_temp_passcode_help:
      'Los usuarios tendrán la opción de recibir una contraseña temporal válida durante 15 minutos para iniciar sesión. Esto resulta útil para los usuarios que inician sesión en un buzón compartido o en una lista de distribución.',
    yes: YES,
    no: NO,
    never: NEVER,
    scheduled: SCHEDULED
  },
  email_continuity: {
    title: EMAIL_CONTINUITY,
    information_text:
      'Permite a los usuarios finales recibir y enviar correos electrónicos cuando los servidores de correo designados no están disponibles. El servicio Email Continuity caducará automáticamente transcurridas 96 horas.',
    email_continuity: EMAIL_CONTINUITY,
    off: OFF,
    auto_enable: 'Activación automática',
    auto_enable_alert:
      'Al utilizar la función Auto-Enable para Email Continuity se habilitará el spooling para todos los dominios asociados a esta cuenta.',
    confirm_dialog: {
      title: 'Activar la cola de impresión',
      text: 'Al utilizar la función Auto-Enable para Email Continuity se habilitará el spooling para todos los dominios asociados a esta cuenta.',
      confirm: 'Activar',
      cancel: CANCEL
    }
  }
}
/* eslint-enable quotes */

export default users
