import { makeStyles, colors } from '@barracuda-internal/bds-core'

export { colors }

export default makeStyles(theme => ({
  tabPanel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      padding: 16,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
    }
  },
  messageDetailTab: {
    margin: '0px 15px 0px 15px',
    borderBottom: `1px solid ${colors.uiElement}`,
    '& button': {
      margin: 0,
      minWidth: '70px !important',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'uppercase'
    },
    '& button.Mui-selected': {
      margin: 0,
      minWidth: '70px !important',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '16px',
      textTransform: 'uppercase'
    }
  }
}))
