import { useCallback, useEffect, useMemo, useState } from 'react'
import { Serie } from '@nivo/line'
import { Datum } from '@nivo/legends'
import { colors } from '@barracuda-internal/bds-core'
import { CategoryTypes } from 'types/categoryTypes'

import { EventHandlers, State } from 'components/pages/overview/dashboard/emailStatisticsCharts/EmailStatisticsChart'
import { useFormatMessage } from 'lib/localization'
import { Action, Direction } from 'types/Messages'
import { DashboardRange, EmailStatistics, EmailStatisticsResult } from 'types/stats'
import toggleInArray from 'lib/toggleInArray'
import {
  getInboundEmailStatisticsStats,
  getOutboundEmailStatisticsStats,
  updateFilters
} from 'redux/features/stats/statsSlice'
import { useAppDispatch } from 'redux/toolkit/hooks'

export enum ChartTotalValueKeys {
  allowed = 'allowed',
  total_blocked = 'total_blocked',
  blocked = 'blocked',
  virus = 'virus',
  atp = 'atp',
  encrypted = 'encrypted',
  quarantined = 'quarantined',
  total = 'total',
  brbl = 'brbl',
  spam = 'spam',
  brts = 'brts',
  sent = 'sent',
  total_sent = 'total_sent'
}

export const ChartColorCodes = {
  allowed: colors.blue500,
  blocked: colors.orange600,
  quarantined: colors.yellow900,
  bbl: colors.redOrange300,
  spam: colors.orange700,
  brts: colors.orange800,
  virus: colors.orange200,
  encrypted: colors.blue800
} as const

export type UseEmailStatisticsChartLogic = [
  State['chartConfig'],
  State['selectedFilter'],
  EventHandlers['onSelectFilter']
]
export type ChartAxisBottomValues = { [key in Action]?: CategoryTypes[] }
export type ChartAxisAlternativeCategoryNames = { [key in CategoryTypes]?: ChartTotalValueKeys }
export type ChartTotalValues = { [key in Action]?: [ChartTotalValueKeys, CategoryTypes[]][] }
export type ChartColors = { [Key in Action]?: string[] }

export type useEmailStatisticsChartLogicProps = {
  direction: Direction
  emailStatisticsData: EmailStatistics | undefined
  chartAxisBottomValues: ChartAxisBottomValues
  chartAxisAlternativeCategoryNames: ChartAxisAlternativeCategoryNames
  chartColors: ChartColors
  chartTotalValues: ChartTotalValues
  demoData: State['chartConfig']
  domainId?: string
  range: DashboardRange
}

const BASE_I18N_KEY = 'ess.overview.dashboard.charts'

export const useEmailStatisticsChartLogic = (
  props: useEmailStatisticsChartLogicProps
): UseEmailStatisticsChartLogic => {
  const dispatch = useAppDispatch()
  const formatActionMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    emailStatisticsData,
    chartAxisBottomValues,
    chartAxisAlternativeCategoryNames,
    chartTotalValues,
    chartColors,
    demoData
  } = props
  const [selectedFilter, setSelectedFilter] = useState<Action>(Action.none)
  const [disabledLegends, setDisabledLegends] = useState<string[]>([])

  useEffect(() => {
    setDisabledLegends([])
  }, [selectedFilter])

  const onLegendClick = useCallback(
    (legend: Datum) => {
      setDisabledLegends(toggleInArray(disabledLegends, legend.id as string))
    },
    [disabledLegends]
  )

  const chartConfig: State['chartConfig'] = useMemo(() => {
    if (!emailStatisticsData) {
      return demoData
    }

    let maxValueInRange = 0
    const dataFilter = emailStatisticsData.action as Action
    const axisBottomValuesToShow: string[] = []
    const serieObject = emailStatisticsData.results.reduce(
      (all: { [key: string]: Serie }, emailStat: EmailStatisticsResult, idx) => {
        if ((idx + 1) % 2 !== 0) {
          axisBottomValuesToShow.push(emailStat.date)
        }
        const updatedAll = { ...all }

        chartAxisBottomValues[dataFilter]?.forEach(actionCategory => {
          const dataId = formatActionMessage(
            chartAxisAlternativeCategoryNames[actionCategory] || actionCategory.replace(':', '_')
          )
          const newSerieData = {
            x: emailStat.date,
            y: disabledLegends.includes(dataId) ? null : emailStat.actionCategories[actionCategory as CategoryTypes]
          }
          maxValueInRange = Math.max(maxValueInRange, newSerieData.y || 0)

          if (!all[actionCategory]) {
            updatedAll[actionCategory] = {
              id: dataId,
              data: [newSerieData]
            }
          } else {
            // TODO: check typing (readonly)
            const data = updatedAll[actionCategory].data as any[]
            data.push(newSerieData)
          }
        })

        return updatedAll
      },
      {}
    )

    const totals: State['chartConfig']['totals'] =
      chartTotalValues?.[dataFilter]?.reduce((all: State['chartConfig']['totals'], chartAction) => {
        const [chartTotalValue, categoryTypes] = chartAction
        const categoryTotal: number = categoryTypes.reduce(
          (categoryAll, category) => categoryAll + (emailStatisticsData.counts.actionCategories[category] || 0),
          0
        )

        all.push([chartTotalValue, categoryTotal])

        return all
      }, []) || []

    return {
      data: Object.values(serieObject).slice().reverse(),
      colors: chartColors[dataFilter]?.slice().reverse() || [],
      axisBottomValuesToShow,
      onLegendClick,
      totals,
      maxValueInRange,
      disabledLegends
    }
    /* eslint-disable prettier/prettier */
  }, [
    emailStatisticsData,
    chartAxisBottomValues,
    chartTotalValues,
    disabledLegends,
    chartAxisAlternativeCategoryNames,
    chartColors,
    demoData,
    formatActionMessage,
    onLegendClick
  ])
  /* eslint-enable prettier/prettier */

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(
    value => {
      setSelectedFilter(value as Action)
      // eslint-disable-next-line default-case
      switch (props.direction) {
        case Direction.INBOUND:
          dispatch(
            updateFilters({
              inboundEmailStatistics: { action: value as Action }
            })
          )
          dispatch(
            getInboundEmailStatisticsStats({
              domainId: props.domainId,
              range: props.range,
              action: value as Action
            })
          )
          break
        case Direction.OUTBOUND:
          dispatch(
            updateFilters({
              outboundEmailStatistics: { action: value as Action }
            })
          )
          dispatch(
            getOutboundEmailStatisticsStats({
              domainId: props.domainId,
              range: props.range,
              action: value as Action
            })
          )
          break
      }
    },
    [dispatch, props.direction, props.domainId, props.range]
  )

  return useMemo(() => [chartConfig, selectedFilter, onSelectFilter], [chartConfig, selectedFilter, onSelectFilter])
}
