import React, { useMemo } from 'react'

import { Button, Grid, TextField, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import { useLoginLogic } from 'components/pages/cudaspt/login/useLoginLogic'
import Loading from 'components/libs/loading/Loading'
import ErrorPage from 'components/libs/errorPage/ErrorPage'

import styles from 'components/pages/cudaspt/login/loginStyles'

const BASE_I18N_KEY = 'ess_cudaspt.login'

const Login: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [state] = useLoginLogic()

  return useMemo(() => {
    if (!state.region && !state.isDomainInfoError) {
      return <Loading />
    }

    if (state.isDomainInfoError) {
      return <ErrorPage title={formatMessage('error_page.title')} subtitle={formatMessage('error_page.subtitle')} />
    }

    return (
      <Grid container className={classes.root} direction="column">
        <Grid container spacing={0}>
          <Grid item className={classes.header} xs>
            <Typography className={classes.headerTitle} variant="h2">
              {formatMessage('header', { region: state.region })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.contentWrapper} alignItems="center" justify="center">
          <Grid container className={classes.content} direction="column">
            <Typography variant="h3" className={classes.contentTitle}>
              {formatMessage('admin_login')}
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              {formatMessage('enter_creds')}
            </Typography>
            <Grid className={classes.formRow} container direction="row">
              <Grid item xs={2}>
                <Typography className={classes.formTitle} variant="body1">
                  {formatMessage('username')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField variant="outlined" size="small" fullWidth />
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={2}>
                <Typography className={classes.formTitle} variant="body1">
                  {formatMessage('password')}
                </Typography>
              </Grid>
              <Grid className={classes.formRow} item xs={8}>
                <TextField variant="outlined" type="password" size="small" fullWidth />
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Button color="primary" variant="contained" size="large">
                  {formatMessage('login')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }, [state, classes, formatMessage])
}

export default Login
