import { useCallback, useMemo, useState } from 'react'
import { AtpExemptEmail, AtpExemptIp, AtpScanMode, AtpSettings } from 'types/atpSettings'
import { isEmailOrDomainValid, isEmailValid, isValidIpV4Address } from 'lib/validation'
import { SettingValue } from 'types/Settings'

export interface FormErrors {
  newEmailOrDomainError: string
  newIpAddressError: string
  newIpNetmaskError: string
  adminEmailsError: string
  firstInvalidAdminEmail: string
}

export interface Methods {
  validateAdminEmails: () => boolean
  validateNewEmail: (newEmail: AtpExemptEmail) => boolean
  validateNewIp: (newIp: AtpExemptIp) => boolean
  resetErrors: () => void
}

export const useAtpSettingsValidation = (data: AtpSettings): [FormErrors, Methods] => {
  const [newEmailOrDomainError, setNewEmailOrDomainError] = useState<string>('')
  const [newIpAddressError, setNewIpAddressError] = useState<string>('')
  const [newIpNetmaskError, setNewIpNetmaskError] = useState<string>('')
  const [adminEmailsError, setAdminEmailsError] = useState<string>('')
  const [firstInvalidAdminEmail, setFirstInvalidAdminEmail] = useState<string>('')

  const validateAdminEmails = useCallback(() => {
    setAdminEmailsError('')
    setFirstInvalidAdminEmail('')
    if (data.atd === AtpScanMode.DELIVER_SCAN && data.atd_notify === SettingValue.ENABLED) {
      const adminEmails = data.atd_admin_emails
        .split(',')
        .map(email => email.trim())
        .filter(email => !!email)

      if (adminEmails.length === 0) {
        setAdminEmailsError('error.empty_email')
        return false
      }

      const firstInvalidEmail = data.atd_admin_emails
        .split(',')
        .map(email => email.trim())
        .filter(email => !!email)
        .find(email => !isEmailValid(email))
      if (firstInvalidEmail) {
        setAdminEmailsError('error.invalid_email')
        setFirstInvalidAdminEmail(firstInvalidEmail)
        return false
      }
    }
    return true
  }, [data])

  const validateNewEmail = useCallback((newEmail: AtpExemptEmail) => {
    setNewEmailOrDomainError('')
    if (!newEmail.email) {
      setNewEmailOrDomainError('error.empty_email')
      return false
    }
    if (!isEmailOrDomainValid(newEmail.email)) {
      setNewEmailOrDomainError('error.invalid_email')
      return false
    }
    return true
  }, [])

  const validateNewIp = useCallback((newIp: AtpExemptIp) => {
    setNewIpAddressError('')
    setNewIpNetmaskError('')
    let hasError = false

    if (!newIp.address) {
      setNewIpAddressError('error.empty_ip_address')
      hasError = true
    } else if (!isValidIpV4Address(newIp.address)) {
      setNewIpAddressError('error.invalid_ip_address')
      hasError = true
    }

    if (!newIp.netmask) {
      setNewIpNetmaskError('error.empty_netmask')
      hasError = true
    } else if (!isValidIpV4Address(newIp.netmask)) {
      setNewIpNetmaskError('error.invalid_netmask')
      hasError = true
    }

    return !hasError
  }, [])

  const resetErrors = useCallback(() => {
    setNewEmailOrDomainError('')
    setNewIpAddressError('')
    setNewIpNetmaskError('')
    setAdminEmailsError('')
    setFirstInvalidAdminEmail('')
  }, [])

  return useMemo(
    () => [
      {
        newEmailOrDomainError,
        newIpAddressError,
        newIpNetmaskError,
        adminEmailsError,
        firstInvalidAdminEmail
      },
      { validateAdminEmails, validateNewEmail, validateNewIp, resetErrors }
    ],
    [
      adminEmailsError,
      firstInvalidAdminEmail,
      newEmailOrDomainError,
      newIpAddressError,
      newIpNetmaskError,
      resetErrors,
      validateAdminEmails,
      validateNewEmail,
      validateNewIp
    ]
  )
}
