import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { UiRoute } from 'lib/routes'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setActivePath } from 'redux/features/app/appSlice'
import validateRouteAccess from 'lib/auth/validateRouteAccess'

export interface Props {
  route: UiRoute
  Component: any
}

function PublicRoute({ route, Component }: Props) {
  const dispatch = useAppDispatch()
  const { appId, isBlockedApp } = useAppSelector(_stores => ({
    appId: _stores.app.activePath?.id,
    isBlockedApp: _stores.app.isBlocked
  }))

  const params = useParams()
  const location = useLocation()
  const [shouldRenderComponent, setShouldRenderComponent] = useState<boolean>(false)

  useEffect(() => {
    dispatch(
      // TODO: align type with redux state depends on (BNESS-22692)
      setActivePath({
        id: route.id,
        url: location.pathname,
        legacyPath: route.legacyPath,
        urlParams: location.search,
        metadata: route.metadata || {},
        params,
        isNavbarVisible: false,
        isPublicRoute: true
      })
    )
  }, [dispatch, location, route, params])

  useEffect(() => {
    if (appId === route?.id) {
      setShouldRenderComponent(true)
    }
  }, [appId, route])

  const RenderComponent = useCallback(
    (childProps: any) => {
      // use the session validation only for the selected routes
      const WrappedComponent = validateRouteAccess(Component)
      return <WrappedComponent {...childProps} />
    },
    [Component]
  )

  return useMemo(() => {
    if (!shouldRenderComponent || isBlockedApp) {
      return null
    }
    return <RenderComponent route={route} />
  }, [shouldRenderComponent, RenderComponent, route, isBlockedApp])
}

export default PublicRoute
