import { useMemo, useState, useEffect, useCallback } from 'react'

import { useFormatMessage } from 'lib/localization'
import { isValidIpV4Address } from 'lib/validation'
import { validNetmask } from 'lib/ipAddress'
import routesConfig from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { bulkEditOutboundIps, getOutboundIps, reset } from 'redux/features/outboundIp/outboundIpSlice'
import { SaveOutboundIpData, SenderIpEntryExtended } from 'types/SenderIp'
import { dropError } from 'components/libs/bulkEdit/bulkEdit'
import { parseDataItem } from 'lib/parseData'

export interface State {
  isLoading: boolean
  isSuccess: boolean
  isFailed: boolean
  hasPageChanges: boolean
  rawData: string
}

export interface EventHandlers {
  onCancelConfirm: () => void
  onSave: () => void
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void
}

export type UseSenderIpBulkEditLogic = [State, EventHandlers]

const BASE_I18N_KEY = 'ess.settings.bulk_edit'
const BACK_URL = routesConfig.OUTBOUND_SETTINGS_SENDER_IP

const rawDataToArray = (rawData: string, formatMessage: (path: string, data?: any) => string) => {
  const dataArray: SaveOutboundIpData[] = []

  // Remove the header line
  const dataList = rawData.split('\n').slice(1)

  dataList.forEach((dataString: string, index: number) => {
    const item = parseDataItem(dataString, 3)
    if (!item) {
      return
    }

    const [domain, ip, netmask, baseComment = ''] = item
    const comment = baseComment.substring(0, 100)

    const row = index + 1
    /* eslint-disable no-unused-expressions */
    if (!domain) {
      dropError(formatMessage, 'error_missing_domain_name', { row })
    }
    if (!ip || !isValidIpV4Address(ip)) {
      dropError(formatMessage, 'error_invalid_ip_address', { row })
    }
    if (!netmask || !isValidIpV4Address(netmask) || !validNetmask(netmask)) {
      dropError(formatMessage, 'error_invalid_netmask', { row })
    }
    /* eslint-enable no-unused-expressions */

    dataArray.push({
      domainName: domain,
      ip,
      netmask,
      comment
    })
  })

  return dataArray
}

const selectorFnToCsv = (accumulator: string, currentData: SenderIpEntryExtended) =>
  `${accumulator}\n${currentData.domainName},${currentData.ip},${currentData.netmask},${currentData.comment}`

export const useSenderIpBulkEditLogic = (): UseSenderIpBulkEditLogic => {
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const {
    accessTokenObject,
    isGetOutboundIpsSuccess,
    isGetOutboundIpsFailed,
    isGetOutboundIpsPending,
    isBulkEditOutboundIpApiStatusSuccess,
    isBulkEditOutboundIpApiStatusPending,
    outboundIps
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isGetOutboundIpsSuccess: isSuccess(_stores.outboundIp.getOutboundIpApiStatus),
    isGetOutboundIpsFailed: isFailed(_stores.outboundIp.getOutboundIpApiStatus),
    isGetOutboundIpsPending: isPending(_stores.outboundIp.getOutboundIpApiStatus),
    isBulkEditOutboundIpApiStatusSuccess: isSuccess(_stores.outboundIp.bulkEditOutboundIpApiStatus),
    isBulkEditOutboundIpApiStatusPending: isPending(_stores.outboundIp.bulkEditOutboundIpApiStatus),
    outboundIps: _stores.outboundIp.outboundIps
  }))

  const [rawData, setRawData] = useState('')
  const [initialRawData, setInitialRawData] = useState('')
  const [hasPageChanges, setHasPageChanges] = useState(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getOutboundIps({ pdDomainId: accessTokenObject.pdDomainId }))
    } else {
      dispatch(getOutboundIps({}))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isBulkEditOutboundIpApiStatusSuccess) {
      dispatch(reset())
      BACK_URL.goto()
    }
  }, [dispatch, isBulkEditOutboundIpApiStatusSuccess])

  useEffect(() => {
    const data = (outboundIps || []).reduce(selectorFnToCsv, formatMessage('sender_ip_header'))
    setRawData(data)
    setInitialRawData(data)
  }, [formatMessage, setRawData, accessTokenObject, outboundIps])

  const onCancelConfirm = useCallback(() => {
    if (hasPageChanges) {
      setRawData(initialRawData)
      setHasPageChanges(false)
    } else {
      BACK_URL.goto()
    }
  }, [hasPageChanges, initialRawData])

  const onSave = useCallback(() => {
    // try {
    const data = rawDataToArray(rawData, formatMessage)
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        bulkEditOutboundIps({
          pdDomainId: accessTokenObject.pdDomainId,
          items: data
        })
      )
    } else {
      dispatch(bulkEditOutboundIps({ items: data }))
    }
    setHasPageChanges(false)
  }, [formatMessage, dispatch, rawData, accessTokenObject])

  const onChange = useCallback((e: React.FormEvent<HTMLTextAreaElement>) => {
    setRawData(e.currentTarget.value)
    setHasPageChanges(true)
  }, [])

  return useMemo(
    () => [
      {
        isLoading: isGetOutboundIpsPending || isBulkEditOutboundIpApiStatusPending,
        isSuccess: isGetOutboundIpsSuccess,
        isFailed: isGetOutboundIpsFailed,

        hasPageChanges,
        rawData
      },
      {
        onCancelConfirm,
        onSave,
        onChange
      }
    ],
    [
      isGetOutboundIpsPending,
      isGetOutboundIpsSuccess,
      isGetOutboundIpsFailed,
      isBulkEditOutboundIpApiStatusPending,
      hasPageChanges,
      rawData,
      onCancelConfirm,
      onSave,
      onChange
    ]
  )
}
