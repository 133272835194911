import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { requestResetPassword, resetRequestResetPassword } from 'redux/features/users/usersSlice'
import { AccountUser } from 'types/users'
import { FormAlert } from 'components/pages/users/usersList/usersListTypes'

export interface RequestResetPasswordProps {
  setFormAlert: Dispatch<SetStateAction<FormAlert | undefined>>
}

export type IsRequestResetPasswordInProgress = boolean
export type OnRequestResetPassword = (user: AccountUser) => void

export type UseRequestResetPasswordLogic = [IsRequestResetPasswordInProgress, OnRequestResetPassword]
export const useRequestResetPasswordLogic = ({
  setFormAlert
}: RequestResetPasswordProps): UseRequestResetPasswordLogic => {
  const {
    isRequestResetPasswordInProgress,
    isRequestResetPasswordSuccess,
    isRequestResetPasswordFailed,
    requestedResetPasswordUserId
  } = useAppSelector(_store => ({
    isRequestResetPasswordInProgress: isPending(_store.users.api.requestPasswordResetApiStatus),
    isRequestResetPasswordSuccess: isSuccess(_store.users.api.requestPasswordResetApiStatus),
    isRequestResetPasswordFailed: isFailed(_store.users.api.requestPasswordResetApiStatus),
    requestedResetPasswordUserId: _store.users.requestedResetPasswordUserId
  }))
  const dispatch = useAppDispatch()

  // init
  useEffect(
    () => () => {
      dispatch(resetRequestResetPassword())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // reset password request is finished
  useEffect(() => {
    if ((isRequestResetPasswordSuccess || isRequestResetPasswordFailed) && requestedResetPasswordUserId) {
      setFormAlert({
        severity: isRequestResetPasswordSuccess ? 'success' : 'error',
        message: isRequestResetPasswordSuccess ? 'success_reset_password' : 'failed_reset_password',
        params: { user: requestedResetPasswordUserId }
      })
    }
  }, [requestedResetPasswordUserId, isRequestResetPasswordSuccess, isRequestResetPasswordFailed, setFormAlert])

  const onRequestResetPassword = useCallback(
    (user: AccountUser) => {
      dispatch(requestResetPassword(user.userId))
    },
    [dispatch]
  )

  return useMemo(
    () => [isRequestResetPasswordInProgress, onRequestResetPassword],
    [isRequestResetPasswordInProgress, onRequestResetPassword]
  )
}
