import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export type AlarmOffIconProps = SvgIconProps

export function AlarmOffIcon(props: AlarmOffIconProps) {
  return (
    <SvgIcon id="icon-alarm-off" viewBox="0 0 20 20" {...props}>
      <path
        id="Vector"
        d="M10 5.00005C13.225 5.00005 15.8333 7.60838 15.8333 10.8334C15.8333 11.5334 15.7 12.2084 15.475 12.8334L16.7417 14.1C17.225 13.1084 17.5 12.0084 17.5 10.8334C17.5 6.69172 14.1417 3.33338 10 3.33338C8.825 3.33338 7.725 3.60838 6.73333 4.09172L8 5.35838C8.625 5.13338 9.3 5.00005 10 5.00005ZM18.3333 4.76672L14.5 1.55005L13.425 2.82505L17.2583 6.04172L18.3333 4.76672ZM2.43333 1.90838L1.375 2.97505L2.48333 4.08338L1.55833 4.85838L2.74167 6.04172L3.66667 5.25838L4.33333 5.92505C3.19167 7.24172 2.5 8.95838 2.5 10.8334C2.5 14.975 5.85 18.3334 10 18.3334C11.875 18.3334 13.5917 17.6417 14.9083 16.5L16.7417 18.3334L17.8 17.275L3.24167 2.72505L2.43333 1.90838ZM13.725 15.325C12.7167 16.1584 11.4167 16.6667 10 16.6667C6.775 16.6667 4.16667 14.0584 4.16667 10.8334C4.16667 9.41672 4.675 8.11671 5.50833 7.10838L13.725 15.325ZM6.68333 2.73338L5.5 1.55005L4.78333 2.14172L5.96667 3.32505L6.68333 2.73338Z"
        fill="#646464"
      />
    </SvgIcon>
  )
}
