import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  login: {
    backgroundColor: theme.palette.info.main,
    height: '100%'
  },
  logoBanner: {
    backgroundColor: theme.palette.text.primary,
    height: 60,
    width: '100%'
  },
  logo: {
    height: 40,
    margin: 10,
    cursor: 'pointer'
  },
  card: {
    width: 400,
    marginTop: theme.spacing(-10),
    boxShadow: '0px 12px 40px 0px rgba(0, 0, 0, 0.12)'
  },
  cardWrapper: {
    textAlign: 'center',
    padding: theme.spacing(5)
  },
  cardHeader: {
    marginBottom: theme.spacing(3),

    '& h2': {
      fontWeight: 700,
      marginBottom: theme.spacing(2)
    }
  },
  cardLogo: {
    marginBottom: theme.spacing(3)
  },
  cardFormEmailRow: {
    marginBottom: theme.spacing(3)
  },
  cardFormPasswordRow: {
    marginBottom: theme.spacing(3)
  },
  cardFormActionRow: {
    marginBottom: theme.spacing(3)
  },
  cardFormLinkRow: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',

    '& a': {
      cursor: 'pointer'
    }
  },
  cardFormLinkRowLeft: {
    marginBottom: theme.spacing(3),
    textAlign: 'left'
  },
  cardFormButton: {
    height: 46,
    fontSize: 16,
    fontWeight: 400
  },
  cardMsLoginButton: {
    height: 46,
    fontWeight: 400,
    fontSize: 16,
    backgroundColor: colors.white
  },
  optionDivider: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  optionDividerLine: {
    borderBottom: '1px solid grey',
    flexGrow: 1,
    marginBottom: 8
  },
  optionDividerText: {
    width: 60,
    height: 16,
    flexGrow: 0,
    textAlign: 'center',
    fontSize: 14
  },
  cardTextBottomMargin: {
    marginBottom: theme.spacing(3)
  },
  cardInlineText: {
    display: 'inline-block'
  },
  cardInlineLink: {
    display: 'inline-block',
    background: 'transparent',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',

    '&:hover': {
      background: 'transparent',

      '& b': {
        textDecoration: 'underline'
      }
    },

    '&.disabled': {
      color: '#afafae',
      pointerEvents: 'none'
    }
  },
  inlineEmailAddress: {
    display: 'inline-block',
    margin: theme.spacing(1)
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: colors.white,
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& a': {
      cursor: 'pointer'
    }
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0'
  },
  snackBar: {
    marginBottom: 32,
    borderRadius: 32
  },
  progress: {
    height: 5
  },
  loginCardTitle: {
    marginBottom: 10
  },
  loginCardForm: {
    height: 80
  },
  loginCardActionGridContainer: {
    marginTop: 30
  },
  passwordCardContentTitle: {
    marginBottom: 10
  },
  passwordCardInput: {
    height: 60
  },
  passwordCardActionGridContainer: {
    marginTop: 0
  },
  forgotPasswordLink: {
    marginTop: theme.spacing(2)
  },
  passwordCardActionLinks: {
    marginTop: theme.spacing(2)
  },
  flexLink: {
    display: 'flex',

    '&.disabled': {
      color: '#afafae',
      pointerEvents: 'none'
    }
  },
  passwordFlexLink: {
    marginBottom: theme.spacing(2),
    display: 'flex'
  },
  messageCardContent: {
    marginBottom: 10
  },
  messageCardSubtitle: {
    marginBottom: 5
  },
  resendLinkBox: {
    marginTop: 13
  },
  differentUserLoginBox: {
    marginTop: 63
  },
  cardContent: {
    paddingBottom: '16px !important'
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  launchIcon: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  temporaryPasscodeCardContentTitle: {
    marginBottom: theme.spacing(3)
  },
  learnMoreLink: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  temporaryLinkWrapper: {
    marginBottom: theme.spacing(2)
  },
  temporaryLink: {
    marginLeft: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none'
    }
  },
  topError: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  bottomAlert: {
    background: colors.black,
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(1.5)
  },
  closeIcon: {
    color: colors.white
  },
  azureLoginBox: {
    backgroundColor: colors.deepOcean025,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  azureLoginRow: {
    height: '100%'
  },
  msLogo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2)
  }
}))
