import React, { useMemo } from 'react'
import {
  Alert,
  Button,
  CircularProgress,
  DataTable,
  DataTableColumn,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { HelpOutline, Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { GridNoRecords } from '@progress/kendo-react-grid'
import Help from 'components/pages/support/help/Help'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import pageStyles from 'components/pages/pagesStyles'
import styles from 'components/pages/domains/uniqueDomainRecipients/uniqueDomainRecipientsStyles'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import { useUniqueDomainRecipientsLogic } from 'components/pages/domains/uniqueDomainRecipients/useUniqueDomainRecipientsLogic'
import RecipientList from 'components/pages/support/help/recipientList/RecipientList'
import { onKeyDown } from 'lib/keyEvents'
import { Cell } from 'components/libs/grid/cell'

const BASE_I18N_KEY = 'ess.domains'

const UniqueDomainRecipients = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [state, eventHandlers] = useUniqueDomainRecipientsLogic()
  const pageClasses = pageStyles()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container className={classes.domainRecipientsPage}>
        <Grid item xs={12} className={classes.defaultRow}>
          <Grid className={classes.title} alignItems="center" container direction="row">
            <Grid xs item>
              <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                  <Grid container direction="row">
                    <Typography variant="h2" data-testid="title">
                      {formatMessage('recipients.header.title')}{' '}
                      {state.domainName || <CircularProgress color="primary" size={16} />}
                    </Typography>
                    <HelpOutline
                      data-testid="on-open-support"
                      onClick={eventHandlers.help.onHelpOpen}
                      className={pageClasses.helpIcon}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {state.help.isHelpOpen && (
              <Help
                modalComponentInfo={{
                  content: RecipientList,
                  onClose: eventHandlers.help.onHelpClose,
                  title: TOPICS.recipientsList,
                  groupTitle: GROUP_TITLE.domains
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${classes.defaultRow} ${classes.rowMargin}`}>
          <Button size="small" variant="text" color="primary" onClick={eventHandlers.onGoBack}>
            {formatMessage('recipients.go_back')}
          </Button>
        </Grid>
        <Grid item xs={12} className={`${classes.defaultRow} ${classes.rowMargin}`}>
          <Typography variant="body1">{formatMessage('recipients.content')}</Typography>
        </Grid>
        <Grid item className={classes.defaultRow}>
          <TextField
            className={classes.searchTextField}
            label={formatMessage('recipients.search_label')}
            placeholder={formatMessage('recipients.search_hint')}
            variant="outlined"
            value={state.userIdFilter}
            onChange={eventHandlers.onChangeSearchRecipientFilter}
            onKeyDown={onKeyDown(['Enter'], eventHandlers.onSearchRecipient)}
            InputProps={{
              startAdornment: (
                <IconButton
                  size="small"
                  edge="start"
                  data-id="search-button"
                  data-testid="search-button"
                  onClick={eventHandlers.onSearchRecipient}
                >
                  <Search />
                </IconButton>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ style: { paddingTop: 15, paddingBottom: 15, paddingRight: 4, paddingLeft: 4 } }}
          />
        </Grid>
        {state.error && (
          <Grid item className={`${classes.defaultRow} ${classes.rowMargin}`}>
            <Alert severity="error">{errorFormatMessage(state.error)}</Alert>
          </Grid>
        )}
        <Grid item className={classes.defaultRow}>
          {state.isLoading && <LinearProgress />}
        </Grid>
        <Grid item className={classes.tableRow}>
          <Grid container className={classes.recipientsTable}>
            <Grid item className={classes.kendoGrid}>
              <DataTable
                data={state.recipients}
                pageConfig={{
                  pageable: { pageSizes: [2, 100, 250, 500, 1000] },
                  skip: (state.page - 1) * state.size,
                  take: state.size,
                  total: state.total || 0
                }}
                onPageChange={eventHandlers.onPageChange}
              >
                <GridNoRecords>
                  {state.noRecords.textId
                    ? formatMessage(state.noRecords.textId, { b: (txt: any) => <b>{txt}</b>, ...state.noRecords.data })
                    : ' '}
                </GridNoRecords>
                <DataTableColumn
                  field="user"
                  title={formatMessage('recipients.recipient')}
                  cell={({ dataItem }: { dataItem: string }) => (
                    <Cell>
                      <Typography variant="body2">{dataItem}</Typography>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [classes, formatMessage, eventHandlers, pageClasses, state, errorFormatMessage]
  )
}

export default UniqueDomainRecipients
