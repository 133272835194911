import { useCallback, useEffect, useMemo, useState } from 'react'

import { WizardSteps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getEmailServerSettings, resetEmailServerSettings } from 'redux/features/emailServer/emailServerSlice'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { trackEventInAllServices, TRACKING_EVENTS, trackPageLeaveMixpanelEvent } from 'lib/monitoring/monitoringService'
import routesConfig, { gotoAdminEntryPathFromWizard, gotoEnduserEntryPathFromWizard } from 'lib/routesConfig'
import appFeatures from 'config/appFeatures'
import appConfig from 'config/appConfig'
import { UserRights, useUserRights } from 'components/pages/onboardWizard/onboardWizardLogicUserRights'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'

export interface State {
  currentStep: WizardSteps | undefined
  isGetEmailServerSettingsInProgress: boolean
  isGetEmailServerSettingsIsSuccess: boolean
  isGetEmailServerSettingsFailed: boolean
}

export interface EventHandlers {
  gotoNextStep: () => void
}

export type OnboardWizardLogic = [State, EventHandlers]

export const useOnboardWizardLogic = (): OnboardWizardLogic => {
  const dispatch = useAppDispatch()
  const {
    isGetEmailServerSettingsInProgress,
    isGetEmailServerSettingsIsSuccess,
    isGetEmailServerSettingsFailed,
    emailServerSettings
  } = useAppSelector(_store => ({
    isGetEmailServerSettingsInProgress: isPending(_store.emailServer.getEmailServerSettingsApiStatus),
    isGetEmailServerSettingsIsSuccess: isSuccess(_store.emailServer.getEmailServerSettingsApiStatus),
    isGetEmailServerSettingsFailed: isFailed(_store.emailServer.getEmailServerSettingsApiStatus),
    emailServerSettings: _store.emailServer.emailServerSettings
  }))
  const { userHasRight } = useUserRights()
  const [currentStep, setCurrentStep] = useState<WizardSteps | undefined>()
  const isEGDReactOverviewEnabled = isMyFeatureOn(FEATURES.EGD_React_Overview_Rollout)

  useEffect(() => {
    dispatch(getEmailServerSettings())
    trackPageLeaveMixpanelEvent(TRACKING_EVENTS.WIZARD.CLOSE)

    return () => {
      dispatch(resetEmailServerSettings())
    }
    // eslint-disable-next-line
  }, [])

  const migrateToken = useMemo(() => {
    const migrateTokenSecret = emailServerSettings?.migrateToken
    if (!migrateTokenSecret) {
      return ''
    }

    return `?${appConfig.QUERY_PARAMS.MIGRATE_TOKEN}=${encodeURIComponent(migrateTokenSecret)}`
  }, [emailServerSettings])

  // account settings are loaded
  useEffect(() => {
    // 1 - goto endUser app if user is endUser
    if (!appFeatures.DisabledWizardAdminCheck && userHasRight(UserRights.GOTO_ENDUSER_APP)) {
      gotoEnduserEntryPathFromWizard()
      // 2 - goto admin app if the wizard is completed
    } else if (emailServerSettings?.wizardCompleted) {
      gotoAdminEntryPathFromWizard('', isEGDReactOverviewEnabled)
      // 3 - Forward the user to the selected region (if not matched with the current host)
    } else if (
      appFeatures.EnabledRegionChange &&
      emailServerSettings?.url &&
      emailServerSettings.url !== window.location.hostname
    ) {
      window.location.href = `${window.location.origin?.replace(
        window.location.host,
        emailServerSettings?.url || ''
      )}${routesConfig.BCC_LOGIN?.url()}${migrateToken}`
      // default - welcome the user in the wizard
    } else if (emailServerSettings && !currentStep) {
      if (!emailServerSettings.region) {
        trackEventInAllServices(TRACKING_EVENTS.WIZARD.START)
      }
      setCurrentStep(
        !appFeatures.DisabledWizardRegionSelectedCheck && emailServerSettings.region
          ? WizardSteps.serverAndMxSetup
          : WizardSteps.regionSelection
      )
    }
  }, [emailServerSettings, currentStep, migrateToken, dispatch, userHasRight, isEGDReactOverviewEnabled])

  const gotoNextStep = useCallback(() => {
    setCurrentStep(WizardSteps.serverAndMxSetup)
  }, [])

  return useMemo(
    () => [
      {
        currentStep,
        isGetEmailServerSettingsInProgress,
        isGetEmailServerSettingsIsSuccess,
        isGetEmailServerSettingsFailed
      },
      {
        gotoNextStep
      }
    ],
    [
      currentStep,
      isGetEmailServerSettingsInProgress,
      isGetEmailServerSettingsIsSuccess,
      isGetEmailServerSettingsFailed,
      gotoNextStep
    ]
  )
}
