import { useMemo, useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { getReportsList, scheduleReport, updateScheduledReport } from 'redux/features/reports/reportsSlice'
import {
  BarracudaReport,
  BarracudaReportTypes,
  ParsedScheduleReport,
  ScheduleReportEveryOption,
  ScheduleReportFormat,
  ScheduleReportFrequency
} from 'types/reports'
import { ShortcutItems } from 'components/pages/reports/reportList/customizedBDSComponents/DateRangePicker'
import { SHORT_DAYS } from 'types/Settings'
import { ScheduleReportPayload } from 'redux/features/reports/reportsApiThunks'
import { isEmailValid } from 'lib/validation'

export interface State {
  isLoaded: boolean
  scheduleInProgress: boolean
  form: ScheduleReportPayload
  timeRanges: string[]
  formats: string[]
  everyOptions: string[]
  frequency: string[]
  days: string[]
  predefinedBarracudaReports: BarracudaReport[]
  recipientsError: string
  firstInvalidRecipient: string
}

export interface EventHandlers {
  handleOnInputChange: (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => void
  handleFrequencyClick: (value: string) => void
  handleDayClick: (value: string) => void
  onSchedule: () => void
}

export interface UseScheduleReportLogicProps {
  name?: string
  report?: ParsedScheduleReport
  onClose: () => void
}

const AVAILABLE_SHORTCUT_ITEMS = [ShortcutItems.last30days, ShortcutItems.last7days, ShortcutItems.lastDay]
const DEFAULT_FORMAT = ScheduleReportFormat.csv
const DEFAULT_FREQUENCY = ScheduleReportFrequency.daily

export const enum INPUT_NAMES {
  NAME = 'name',
  TIME_RANGE = 'timeRange',
  FORMAT = 'format',
  FREQUENCY = 'frequency',
  EVERY = 'every',
  ON = 'on',
  RECIPIENTS = 'recipients'
}

export const useScheduleReportLogic = (props: UseScheduleReportLogicProps): [State, EventHandlers] => {
  const dispatch = useAppDispatch()
  const {
    reportsList,
    isGetReportsListSuccess,
    isScheduleReportSuccess,
    isScheduleReportPending,
    isUpdateScheduledReportPending,
    isUpdateScheduledReportSuccess
  } = useAppSelector(_store => ({
    isGetReportsListSuccess: isSuccess(_store.reports.api.getReportsListApiStatus),
    isScheduleReportSuccess: isSuccess(_store.reports.api.scheduleReportApiStatus),
    isScheduleReportPending: isPending(_store.reports.api.scheduleReportApiStatus),
    isUpdateScheduledReportSuccess: isSuccess(_store.reports.api.updateScheduledReportApiStatus),
    isUpdateScheduledReportPending: isPending(_store.reports.api.updateScheduledReportApiStatus),
    reportsList: _store.reports.list
  }))
  const [formObject, setFormObject] = useState<ScheduleReportPayload>({
    name: props.name || props.report?.name || '',
    timeRange: props.report?.timeRange || AVAILABLE_SHORTCUT_ITEMS[0],
    format: props.report?.format || DEFAULT_FORMAT,
    frequency: props.report?.frequency || DEFAULT_FREQUENCY,
    every: props.report?.every || undefined,
    on: props.report?.on || undefined,
    recipients: props.report?.recipients || ''
  })
  const [recipientsError, setRecipientsError] = useState<string>('')
  const [firstInvalidRecipient, setFirstInvalidRecipient] = useState<string>('')

  // init
  useEffect(() => {
    dispatch(getReportsList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isGetReportsListSuccess && !props.name && !props.report) {
      setFormObject({ ...formObject, [INPUT_NAMES.NAME]: reportsList?.barracudaReports[0].id || '' })
    }
  }, [isGetReportsListSuccess, formObject, reportsList, props.name, props.report])

  const predefinedBarracudaReports = useMemo(
    () =>
      (reportsList?.barracudaReports || []).filter((report: BarracudaReport) =>
        Object.values(BarracudaReportTypes).includes(report.id)
      ),
    [reportsList]
  )

  const handleOnInputChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined
        value: unknown
      }>
    ) => {
      const { name, value } = event.target
      if (name) {
        setFormObject({ ...formObject, [name]: value })
      }
    },
    [formObject]
  )

  const handleFrequencyClick = useCallback(
    (value: string) => {
      setFormObject({
        ...formObject,
        [INPUT_NAMES.FREQUENCY]: value,
        [INPUT_NAMES.EVERY]: value === ScheduleReportFrequency.monthly ? ScheduleReportEveryOption.first : undefined,
        [INPUT_NAMES.ON]: value !== ScheduleReportFrequency.daily ? formObject.on : undefined
      })
    },
    [formObject]
  )

  const handleDayClick = useCallback(
    (value: string) => {
      setFormObject({ ...formObject, [INPUT_NAMES.ON]: value })
    },
    [formObject]
  )

  const onSchedule = useCallback(() => {
    if (!formObject.recipients) {
      setRecipientsError('missing_recipients')
      return
    }
    const firstInvalidEmail = formObject.recipients
      .split(',')
      .map(email => email.trim())
      .filter(email => !!email)
      .find(email => !isEmailValid(email))

    if (firstInvalidEmail) {
      setRecipientsError('invalid_recipient')
      setFirstInvalidRecipient(firstInvalidEmail)
      return
    }

    if (props.report) {
      dispatch(updateScheduledReport({ ...formObject, id: props.report.id, status: props.report.status }))
    } else {
      dispatch(scheduleReport(formObject))
    }
  }, [dispatch, formObject, props.report])

  useEffect(() => {
    if (isScheduleReportSuccess || isUpdateScheduledReportSuccess) {
      props.onClose()
    }
  }, [isScheduleReportSuccess, isUpdateScheduledReportSuccess, props])

  return useMemo(
    () => [
      {
        isLoaded: isGetReportsListSuccess,
        scheduleInProgress: isScheduleReportPending || isUpdateScheduledReportPending,
        form: formObject,
        timeRanges: AVAILABLE_SHORTCUT_ITEMS,
        formats: Object.values(ScheduleReportFormat),
        everyOptions: Object.values(ScheduleReportEveryOption),
        frequency: Object.values(ScheduleReportFrequency),
        days: SHORT_DAYS,
        predefinedBarracudaReports,
        recipientsError,
        firstInvalidRecipient
      },
      { handleOnInputChange, handleFrequencyClick, handleDayClick, onSchedule }
    ],
    [
      formObject,
      isGetReportsListSuccess,
      isUpdateScheduledReportPending,
      isScheduleReportPending,
      predefinedBarracudaReports,
      recipientsError,
      firstInvalidRecipient,
      handleOnInputChange,
      handleFrequencyClick,
      handleDayClick,
      onSchedule
    ]
  )
}
