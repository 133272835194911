/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Link,
  Typography
} from '@barracuda-internal/bds-core'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { ResetPasswordLogic } from 'components/pages/actions/resetPassword/useResetPasswordLogic'

const BASE_I18N_KEY = 'ess.reset_password'

export interface ExpiredLinkCardProps {
  logic: ResetPasswordLogic
}

const ExpiredLinkCard = ({ logic }: ExpiredLinkCardProps) => {
  const [state, eventHandlers] = logic
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={0} className={classes.card} data-testid="expired-link-card">
        {state.isLoading && <LinearProgress data-testid="linear-progress" />}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('expired_link.title')}</Typography>
            <Typography variant="body1">
              {formatMessage('expired_link.message', { email: <b>{state.userId}</b> })}
            </Typography>
            <Typography variant="body1">{formatMessage('check_email.link_will_expire')}</Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Button
                  className={classes.cardFormButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={state.isLoading}
                  onClick={eventHandlers.onSendLoginInfo}
                  data-testid="resend-link-button"
                >
                  {formatMessage('expired_link.resend_link')}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link onClick={eventHandlers.onLogin} data-testid="go-to-signin">
                  <Typography variant="body1">{formatMessage('expired_link.sign_in')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardWrapper,
      classes.cardHeader,
      classes.cardLogo,
      classes.topError,
      classes.cardFormActionRow,
      classes.cardFormButton,
      classes.cardFormLinkRow,
      state.isLoading,
      state.userId,
      state.topErrorMessage,
      formatMessage,
      eventHandlers.onSendLoginInfo,
      eventHandlers.onLogin
    ]
  )
}

export default ExpiredLinkCard
/* eslint-enable jsx-a11y/anchor-is-valid */
