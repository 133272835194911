export enum SplitterOrientation {
  none = 'none',
  horizontal = 'horizontal',
  vertical = 'vertical'
}

/**
 * Props for the renderSplitter callback in Split
 */
export type RenderSplitterProps = {
  /**
   * The measured size of the splitter in pixels.
   */
  pixelSize: number
  /**
   * True if the splitter is horizontal (i.e. top/bottom); false otherwise.
   */
  horizontal: boolean
  /**
   * True if the user is currently dragging the splitter; false otherwise.
   */
  dragging: boolean
}

export type SplitMeasuredSizes = {
  /**
   * The measured size of the primary pane in pixels.
   */
  primary: number
  /**
   * The measured size of the splitter in pixels.
   */
  splitter: number
  /**
   * The measured size of the secondary pane in pixels.
   */
  secondary: number
}

export interface SplitProps {
  /**
   * Add this attribute or set to true to create a top/bottom split.
   * Set to false to create a left|right split.
   */
  horizontal?: boolean
  /**
   * The initial width/height of the left/top pane.
   * Width is specified as a CSS unit (e.g. %, fr, px).
   * The default is 50%.
   */
  initialPrimarySize?: string
  /**
   * The preferred minimum width/height of the left/top pane.
   * Specified as a CSS unit (e.g. %, fr, px).
   * The default is 0.
   */
  minPrimarySize?: string
  /**
   * The preferred minimum width/height of the right/bottom pane.
   * Specified as a CSS unit (e.g. %, fr, px).
   * The default is 0.
   */
  minSecondarySize?: string
  /**
   * The width of the splitter between the panes.
   * Specified as a CSS unit (e.g. %, fr, px).
   * The default is 3px.
   */
  splitterSize?: string
  /**
   * Render props for the splitter.
   * @param pixelSize The measured size of the splitter in pixels.
   * @param horizontal True if the splitter is horizontal (i.e. top/bottom); false otherwise.
   */
  renderSplitter?: (props: RenderSplitterProps) => React.ReactNode | undefined
  /**
   * When true, if the user double clicks the splitter it will reset to its initial position.
   * The default is false.
   */
  resetOnDoubleClick?: boolean
  /**
   * The colors to use for the default splitter.
   * Only used when renderSplitter is undefined;
   * The defaults are silver, gray, and black
   */
  colors?: {
    color: string
    hover: string
    drag: string
  }

  children: any
  showSplitter: boolean
}

export type MeasuredDimensions = {
  height: number
  width: number
}
