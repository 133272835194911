import React, { useMemo } from 'react'

import { Alert, Grid } from '@barracuda-internal/bds-core'

import { LinearProgress } from '@material-ui/core'
import MessageDetailsToolbar from 'components/libs/message/messageDetails/messageDetailsToolbar/MessageDetailsToolbar'
import MessageDetailsContent from 'components/libs/message/messageDetails/messageDetailsContent/MessageDetailsContent'

import styles from 'components/libs/message/messageDetails/messageDetailsStyles'
import { useMessageDetailsLogic } from 'components/libs/message/messageDetails/useMessageDetailsLogic'
import { useErrorFormatMessage } from 'lib/localization'

const MessageDetails = () => {
  const [{ isGetMessagePending, isGetMessageSuccess, getMessageError }] = useMessageDetailsLogic()
  const classes = styles()
  const formatError = useErrorFormatMessage()

  return useMemo(
    () => (
      <Grid container className={classes.container}>
        {isGetMessagePending && (
          <Grid item xs={12} className={classes.row}>
            <LinearProgress data-testid="linear-progress" />
          </Grid>
        )}
        {getMessageError && (
          <Grid item xs={12} className={classes.row}>
            <Alert severity="error">{formatError(getMessageError)}</Alert>
          </Grid>
        )}
        {isGetMessageSuccess && (
          <>
            <Grid item xs={12} className={classes.row}>
              <MessageDetailsToolbar />
            </Grid>
            <Grid item xs={12} className={`${classes.row} ${classes.bottomRow}`}>
              <MessageDetailsContent />
            </Grid>
          </>
        )}
      </Grid>
    ),
    [
      classes.bottomRow,
      classes.container,
      classes.row,
      formatError,
      getMessageError,
      isGetMessagePending,
      isGetMessageSuccess
    ]
  )
}

export default MessageDetails
