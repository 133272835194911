import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import { useSenderIpLogic } from 'components/pages/outboundSettings/senderIp/useSenderIpLogic'
import OutboundSenderIp from 'components/pages/support/help/outboundSenderIp/OutboundSenderIp'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { PageContainer, PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import SenderIpTable from 'components/pages/outboundSettings/senderIp/SenderIpTable'

const BASE_I18N_KEY = 'ess.outbound_settings.sender_ip'

const SenderIp: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useSenderIpLogic()

  return useMemo(
    () => (
      <PageContainer
        isLoading={state.isLoading}
        isFailedToLoad={state.isFailed}
        dirtyCheck
        isDirtyForm={state.isDirtyForm}
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: OutboundSenderIp,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.senderIpAddressRanges,
            group: GROUP_TITLE.outboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <SenderIpTable delegateIsDirtyForm={eventHandlers.setIsTableDirty} />
      </PageContainer>
    ),
    [formatMessage, state, eventHandlers]
  )
}

export default SenderIp
