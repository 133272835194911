import React, { useMemo } from 'react'

import { DeleteOutline } from '@material-ui/icons'
import { Close } from '@barracuda-internal/bds-core/dist/Icons/Core'
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  ListItemIcon,
  ListItemSecondaryAction,
  FormControlLabel,
  Divider,
  Modal
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'

import styles from 'components/libs/dialog/openSearch/openSearchDialogStyles'
import { useOpenSearchDialogLogic } from 'components/libs/dialog/openSearch/useOpenSearchDialogLogic'

interface OpenSearchFormProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.open_search'
const BASE_I18N_DIALOG_KEY = 'ess.dialog'

const OpenSearchDialog: React.FC<OpenSearchFormProps> = (props: OpenSearchFormProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dialogFormatMessage = useFormatMessage(BASE_I18N_DIALOG_KEY)

  const { onClose } = props
  const {
    savedSearchQuery,
    savedSearches,
    handleInputChange,
    handleDeleteSearch,
    selectedId,
    handleSelectSearch,
    onClickOpen
  } = useOpenSearchDialogLogic(props.onClose)

  return useMemo(
    () => (
      <Modal open onClose={onClose} className={classes.modal} data-testid="root-dialog">
        <Card elevation={1} className={`keep-open ${classes.container}`}>
          <CardHeader
            title={formatMessage('title')}
            action={
              <IconButton onClick={onClose} data-testid="close-icon">
                <Close />
              </IconButton>
            }
            data-testid="title"
          />
          <CardContent>
            <TextField
              variant="outlined"
              fullWidth
              name="subject"
              placeholder={formatMessage('find_your_search')}
              margin="none"
              value={savedSearchQuery}
              onChange={handleInputChange}
              data-testid="search-input"
            />
            {!!savedSearches.length && (
              <RadioGroup name="savedSearch" value={selectedId} onChange={handleSelectSearch} data-testid="radio-group">
                <List
                  className={classes.listHeader}
                  subheader={
                    <Typography variant="h5" color="inherit" gutterBottom data-testid="subtitle">
                      {formatMessage('subtitle')}
                    </Typography>
                  }
                >
                  <Box mb={2} display="flex" flexDirection="column" height="210px" className={classes.scrollBox}>
                    {savedSearches.map(s => (
                      <Box key={s.id}>
                        <ListItem>
                          <ListItemIcon>
                            <FormControlLabel
                              value={s.id}
                              control={<Radio color="primary" />}
                              label={s.name}
                              data-testid="radio-button"
                            />
                          </ListItemIcon>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => handleDeleteSearch(s.id)} data-testid="delete-button">
                              <DeleteOutline />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                      </Box>
                    ))}
                  </Box>
                </List>
              </RadioGroup>
            )}
          </CardContent>
          <CardActions className={classes.buttonsWrapper}>
            <Button variant="text" color="secondary" onClick={onClose} data-testid="cancel-button">
              {dialogFormatMessage('cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickOpen}
              disabled={selectedId === ''}
              data-testid="open-button"
            >
              {dialogFormatMessage('open')}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    ),
    [
      classes,
      formatMessage,
      dialogFormatMessage,
      savedSearchQuery,
      savedSearches,
      handleInputChange,
      handleDeleteSearch,
      selectedId,
      handleSelectSearch,
      onClose,
      onClickOpen
    ]
  )
}

export default OpenSearchDialog
