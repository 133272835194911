import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { v4 as makeUuid } from 'uuid'
import { trim, uniq } from 'lodash'
import { Grid, Typography } from '@barracuda-internal/bds-core'

import { saveAs } from '@progress/kendo-file-saver'
import { useFormatMessage } from 'lib/localization'
import {
  BarracudaReport,
  CustomReport,
  getReportType,
  paginatedReports,
  ReportsListItem,
  ReportTypes,
  RETENTION_POLICY_DAY_RANGE,
  TableFilters
} from 'types/reports'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import usePreviousValue from 'lib/usePreviousValue'
import {
  downloadReport,
  resetDownloadReport,
  setCustomReportsList,
  setSavedReportId
} from 'redux/features/reports/reportsSlice'
import { formatDate, luxonDate } from 'lib/datetime'
import styles from 'components/pages/reports/reportsStyles'
import DateRangeSelector from 'components/pages/reports/reportList/DateRangeSelector'
import {
  SaveReportDialogConfig,
  UnifiedReportingTitleBarProps
} from 'components/pages/reports/reportList/customizedBDSComponents/UnifiedReportingTitleBar'
import { GetReportFormat } from 'redux/features/reports/reportsApiThunks'
import { FEATURES } from 'lib/splitioFeatures'
import { isMyFeatureOn } from 'lib/splitio'

export enum DownloadTypes {
  CSV = 'download_csv',
  PDF = 'download_pdf'
}

export enum ReportSelectorTypes {
  schedule_report = 'schedule_report'
}

export type TitleBarLogic = Omit<UnifiedReportingTitleBarProps, 'reportName'>

const BASE_I18N_KEY = 'ess.reports.title_bar'

export const useTitleBarLogic = (
  onReportCallback: () => void,
  selectedListItem: ReportsListItem | undefined
): [TitleBarLogic] => {
  const dispatch = useAppDispatch()
  const classes = styles()
  const {
    filterValues,
    reportsList,
    isSetCustomReportsSuccess,
    isSetCustomReportsFailed,
    isDisabledButtons,
    isDownloadPending,
    isDownloadSuccess,
    downloadReportData
  } = useAppSelector(_store => ({
    filterValues: _store.reports.filters,
    reportsList: _store.reports.list,
    downloadReportData: _store.reports.downloadReportData,
    isDownloadPending: isPending(_store.reports.api.downloadReportApiStatus),
    isDownloadSuccess: isSuccess(_store.reports.api.downloadReportApiStatus),
    isSetCustomReportsSuccess: isSuccess(_store.reports.api.setCustomReportsListApiStatus),
    isSetCustomReportsFailed: isFailed(_store.reports.api.setCustomReportsListApiStatus),
    isDisabledButtons:
      isPending(_store.reports.api.getReportApiStatus) || isFailed(_store.reports.api.getReportApiStatus)
  }))

  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [reportName, setReportName] = useState<string>(selectedListItem?.label || '')
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false)
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [reportOptionsAnchorEl, setReportOptionsAnchorEl] = useState<HTMLButtonElement | null>(null)
  const prevIsSetCustomReportsSuccess = usePreviousValue(isSetCustomReportsSuccess)
  const prevIsSetCustomReportsFailed = usePreviousValue(isSetCustomReportsFailed)
  const [cachedFilterValues, setCachedFilterValues] = useState<TableFilters | undefined>()
  const isScheduledReportsOn = isMyFeatureOn(FEATURES.EGD_React_Scheduled_Reports)

  // update report name when the selectedListItem is changed
  useEffect(() => {
    if (selectedListItem) {
      setReportName(selectedListItem.label)
    }
  }, [selectedListItem])

  // set initial cachedFilterValues when new report is loaded
  useEffect(() => {
    setCachedFilterValues(filterValues)
  }, [filterValues])

  // show save report statuses about api callbacks
  useEffect(() => {
    if (isSetCustomReportsSuccess && !prevIsSetCustomReportsSuccess) {
      dispatch(setSuccessSnackBar({ message: 'new_report_save_success', params: [reportName] }))
    }
    if (isSetCustomReportsFailed && !prevIsSetCustomReportsFailed) {
      dispatch(setErrorSnackBar({ message: 'new_report_save_failed', params: [reportName] }))
    }
  }, [
    dispatch,
    isSetCustomReportsSuccess,
    isSetCustomReportsFailed,
    prevIsSetCustomReportsSuccess,
    prevIsSetCustomReportsFailed,
    reportName
  ])

  const activeReportType = useMemo(() => {
    if (!selectedListItem || !reportsList) {
      return undefined
    }

    const isBarracudaReport = reportsList.barracudaReports.some(
      (listItem: BarracudaReport) => listItem.id === selectedListItem.id
    )

    return isBarracudaReport ? ReportTypes.barracuda : ReportTypes.custom
  }, [reportsList, selectedListItem])

  const saveButtonText = useMemo(() => {
    if (
      activeReportType === ReportTypes.barracuda ||
      (activeReportType === ReportTypes.custom && reportName !== selectedListItem?.label)
    ) {
      return 'save_as'
    }

    return 'save'
  }, [activeReportType, selectedListItem, reportName])

  const onExport = useCallback(
    (e: any) => {
      const targetType = e.target.getAttribute('value')
      switch (true) {
        case targetType === formatMessage(DownloadTypes.CSV):
          if (selectedListItem) {
            dispatch(
              downloadReport({
                id: selectedListItem.id,
                type: selectedListItem.type,
                reportIsChanged: false,
                paginated: paginatedReports.includes(selectedListItem.type),
                format: GetReportFormat.CSV,
                label: selectedListItem.label
              })
            )
          }
          // eslint-disable-next-line no-console
          console.log('starting to download CSV format')
          break
        default:
      }
      setDownloadAnchorEl(null)
    },
    [dispatch, formatMessage, selectedListItem]
  )

  useEffect(() => {
    if (!isDownloadSuccess || !downloadReportData) {
      return
    }

    const url = window.URL.createObjectURL(
      new Blob([Buffer.from(downloadReportData.content)], {
        type: downloadReportData.contentType
      })
    )

    saveAs(url, downloadReportData.name)
    dispatch(resetDownloadReport())
  }, [dispatch, downloadReportData, isDownloadSuccess])

  const onReport = useCallback(
    (e: any) => {
      const targetType = e.target.getAttribute('value')
      switch (true) {
        case targetType === formatMessage(ReportSelectorTypes.schedule_report):
          onReportCallback()
          break
        default:
      }
      setReportOptionsAnchorEl(null)
    },
    [formatMessage, onReportCallback]
  )

  const downloadConfig = useMemo(
    () => ({
      isDownloadPending,
      downloadAnchorEl,
      downloadButtonText: formatMessage('download_button'),
      downloadMenuItems: [formatMessage(DownloadTypes.CSV)],
      onDownloadButtonClick: (e: MouseEvent<HTMLButtonElement>) => {
        if (!isDisabledButtons) {
          setDownloadAnchorEl(e.currentTarget)
        }
      },
      onDownloadMenuItemClick: onExport,
      onDownloadMenuClose: () => setDownloadAnchorEl(null)
    }),
    [isDisabledButtons, formatMessage, downloadAnchorEl, onExport, isDownloadPending]
  )

  const reportOptionsConfig = useMemo(() => {
    const reportOptions = []
    if (isScheduledReportsOn) {
      reportOptions.push(formatMessage(ReportSelectorTypes.schedule_report))
    }
    return {
      onReportOptionsButtonClick: (e: MouseEvent<HTMLButtonElement>) => {
        if (!isDisabledButtons) {
          setReportOptionsAnchorEl(e.currentTarget)
        }
      },
      onReportOptionsMenuClose: () => setReportOptionsAnchorEl(null),
      onReportOptionsMenuItemClick: onReport,
      reportOptions,
      reportOptionsAnchorEl
    }
  }, [isScheduledReportsOn, isDisabledButtons, reportOptionsAnchorEl, formatMessage, onReport])

  const isExpiredDateRange = useCallback(
    (filterValuesForCheck: TableFilters = filterValues) => {
      if (!filterValuesForCheck) {
        return true
      }

      return (
        !filterValuesForCheck.relativeDateRange &&
        luxonDate(filterValuesForCheck.startDate || undefined).startOf('day') <
          luxonDate().minus({ day: RETENTION_POLICY_DAY_RANGE }).startOf('day')
      )
    },
    [filterValues]
  )

  const onSaveNewCustomReport = useCallback(() => {
    if (cachedFilterValues && !isExpiredDateRange(cachedFilterValues) && selectedListItem && reportsList) {
      // check the name is already reserved
      let reportWithSameName: any
      let updatedReportName = reportName
      // collect the max version number of the names and check report with the same name
      const sameNameMaxVersionNumber = [...reportsList.barracudaReports, ...reportsList.customReports]
        .reduce(
          (maxNumbers: number[], report: any) => {
            const savedReportName = report.name || report.label
            if (savedReportName === reportName) {
              reportWithSameName = report
            }

            if (savedReportName.includes(reportName)) {
              const versionNumber = savedReportName
                ?.replace(reportName, '')
                .match(/\((\d*)\)+$/)
                ?.pop()

              if (versionNumber) {
                return uniq([...maxNumbers, Number(versionNumber)])
              }
            }

            return maxNumbers
          },
          [0]
        )
        .sort()
        .reduce(
          (maxNumber: number, currentMaxNumber: number) =>
            currentMaxNumber > maxNumber ? currentMaxNumber : maxNumber,
          0
        )

      // versioning report name if there are saved report with same name and the selected report is a Barracuda type
      if (
        reportWithSameName &&
        (activeReportType === ReportTypes.barracuda || reportWithSameName.id !== selectedListItem.id)
      ) {
        updatedReportName = `${reportName} (${Number(sameNameMaxVersionNumber) + 1})`
      }

      let newCustomReportsList
      let savedReportId = selectedListItem.id
      // new custom report
      if (
        (reportWithSameName && reportWithSameName.id !== selectedListItem.id) ||
        !reportWithSameName ||
        activeReportType === ReportTypes.barracuda
      ) {
        const newReportId = makeUuid()
        savedReportId = newReportId
        const newCustomReport: CustomReport = {
          id: newReportId,
          label: updatedReportName,
          type: selectedListItem.type,
          filters: cachedFilterValues
        }

        newCustomReportsList = [...(reportsList?.customReports || []), newCustomReport].sort((a, b) =>
          a.label > b.label ? 1 : -1
        )
        // update custom report
      } else {
        newCustomReportsList = (reportsList?.customReports || []).map(report => {
          const updatedReport = { ...report }

          if (report.label === updatedReportName) {
            updatedReport.filters = cachedFilterValues
          }

          return updatedReport
        })
      }

      dispatch(setCustomReportsList(newCustomReportsList))
      dispatch(setSavedReportId(savedReportId))
      setIsSaveDialogOpen(false)
    }
  }, [activeReportType, reportName, selectedListItem, reportsList, dispatch, isExpiredDateRange, cachedFilterValues])

  const onChangeDateRange = useCallback(
    (newValue: any, selectorConfig: any) => {
      setCachedFilterValues({
        ...cachedFilterValues,
        ...(newValue[0] && { startDate: newValue[0].toISODate() }),
        ...(newValue[1] && { endDate: newValue[1].toISODate() }),
        relativeDateRange: selectorConfig.shortcut?.relativeDateRange || null
      })
    },
    [cachedFilterValues]
  )

  const customSaveDialogContent = useMemo(() => {
    if (!filterValues || !selectedListItem) {
      return null
    }

    // warning text before save about the fixed dates and expires
    if (getReportType(selectedListItem) === ReportTypes.barracuda && !filterValues.relativeDateRange) {
      return () => (
        <Typography className={classes.fixedDateRangeWarningText}>
          {formatMessage('save_report_dialog.date_warning', {
            startDate: formatDate(filterValues.startDate || ''),
            endDate: formatDate(filterValues.endDate || ''),
            retentionPolicyDayRange: RETENTION_POLICY_DAY_RANGE,
            deleteDate: formatDate(luxonDate().minus({ day: RETENTION_POLICY_DAY_RANGE }).toISODate() || ''),
            expirationDate: formatDate(luxonDate().minus({ day: RETENTION_POLICY_DAY_RANGE }).toISODate() || '')
          })}
        </Typography>
      )
    }

    // trying update custom report but the selected date range is expired
    if (getReportType(selectedListItem) === ReportTypes.custom && isExpiredDateRange()) {
      return () => (
        <Grid>
          <Typography className={classes.expiredDateRangeText}>
            {formatMessage('save_report_dialog.expired_date_range')}
          </Typography>
          <Grid item className={classes.tableControllerItem}>
            <DateRangeSelector
              onChange={onChangeDateRange}
              value={[
                luxonDate(cachedFilterValues?.startDate || filterValues.startDate || ''),
                luxonDate(cachedFilterValues?.endDate || filterValues.endDate || '')
              ]}
            />
          </Grid>
        </Grid>
      )
    }

    return null
  }, [
    classes,
    selectedListItem,
    formatMessage,
    filterValues,
    cachedFilterValues,
    isExpiredDateRange,
    onChangeDateRange
  ])

  const saveReportDialogConfig: SaveReportDialogConfig = useMemo(
    () => ({
      onReportNameChange: (e: any) => setReportName(trim(e.target.value)),
      onClose: () => {
        setIsSaveDialogOpen(false)
      },
      onOpen: () => {
        if (!isDisabledButtons) {
          setReportName(selectedListItem?.label || '')
          setIsSaveDialogOpen(true)
        }
      },
      onSave: onSaveNewCustomReport,
      open: isSaveDialogOpen,
      reportNameValue: reportName,
      saveButtonText: formatMessage(saveButtonText),
      saveReportDialogCloseButtonText: formatMessage('close'),
      saveReportDialogSaveButtonText: formatMessage(saveButtonText),
      saveReportDialogTitle: formatMessage('save_report_dialog.title'),
      saveReportDialogSubtitle: formatMessage('save_report_dialog.subtitle'),
      saveReportDialogTextfieldLabel: formatMessage('save_report_dialog.placeholder_text'),
      selectedReport: selectedListItem?.label || '',
      CustomContent: customSaveDialogContent
    }),
    [
      isDisabledButtons,
      isSaveDialogOpen,
      reportName,
      selectedListItem,
      formatMessage,
      onSaveNewCustomReport,
      saveButtonText,
      customSaveDialogContent
    ]
  )

  return useMemo(
    () => [
      {
        downloadConfig,
        reportOptionsConfig,
        saveReportDialogConfig
      }
    ],
    [downloadConfig, reportOptionsConfig, saveReportDialogConfig]
  )
}
