import { colors, makeStyles } from '@barracuda-internal/bds-core'

const commonStyles = {
  display: 'inline-block',
  cursor: 'default'
}

export default makeStyles(theme => ({
  dialogContent: {
    ...commonStyles,
    minWidth: 500
  },
  content: {
    flexWrap: 'nowrap'
  },
  warningIcon: {
    width: 40,
    height: 40,
    color: colors.statusWarning,
    marginRight: theme.spacing(2)
  },
  contentText: {
    marginBottom: 0
  }
}))
