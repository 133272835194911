import { useMemo } from 'react'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { useAppSelector } from 'redux/toolkit/hooks'

export interface State {
  isLoading: boolean
  isSuccess: boolean
  isFailed: boolean
  hasPageChanges: boolean
}

export type UseSettingsPageLogic = [State]

export const useSettingsPageLogic = (): UseSettingsPageLogic => {
  const {
    isUpdateAccountSettingsPending,
    isUpdateDomainSettingsPending,
    isGetAccountSettingsPending,
    isGetDomainSettingsPending,
    isGetAccountSettingsSuccess,
    isGetDomainSettingsSuccess,
    isGetAccountSettingsFailed,
    isGetDomainSettingsFailed,
    hasPageChanges
  } = useAppSelector(_stores => ({
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus),
    isGetAccountSettingsPending: isPending(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsPending: isPending(_stores.settings.getDomainSettingsApiStatus),
    isGetAccountSettingsSuccess: isSuccess(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsSuccess: isSuccess(_stores.settings.getDomainSettingsApiStatus),
    isGetAccountSettingsFailed: isFailed(_stores.settings.getAccountSettingsApiStatus),
    isGetDomainSettingsFailed: isFailed(_stores.settings.getDomainSettingsApiStatus),
    hasPageChanges: _stores.settings.hasPageChanges
  }))

  return useMemo(
    () => [
      {
        isLoading:
          isUpdateAccountSettingsPending ||
          isUpdateDomainSettingsPending ||
          isGetAccountSettingsPending ||
          isGetDomainSettingsPending,
        isSuccess: isGetAccountSettingsSuccess || isGetDomainSettingsSuccess,
        isFailed: isGetAccountSettingsFailed || isGetDomainSettingsFailed,
        hasPageChanges
      }
    ],
    [
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending,
      isGetAccountSettingsPending,
      isGetDomainSettingsPending,
      isGetAccountSettingsSuccess,
      isGetDomainSettingsSuccess,
      isGetAccountSettingsFailed,
      isGetDomainSettingsFailed,
      hasPageChanges
    ]
  )
}
