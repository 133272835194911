import { Action } from 'types/Messages'
import { StatType } from 'types/stats'

export enum DisplayType {
  recipientDomains = 'recipientDomains',
  senderDomains = 'senderDomains',
  inbound = 'inbound',
  outbound = 'outbound',
  atp = 'atp'
}

export const RANK_COLUMN = 'RANK'

export interface ColumnConfig {
  mapToValue: string
  width?: number
  alignRight?: boolean
  formatDate?: boolean
}

export interface SelectItem {
  value: string
  label: string
}

export interface TableWithDropdownConfig {
  title: string
  dropdownItems: SelectItem[]
  columns: { [key: string]: ColumnConfig }
}

const recipientDomainsConfig = () => ({
  title: 'top_recipient_domains',
  dropdownItems: [
    {
      value: Action.none,
      label: 'volume'
    },
    {
      value: Action.blocked,
      label: 'blocked'
    },
    {
      value: Action.allowed,
      label: 'allowed'
    }
  ],
  columns: {
    DOMAIN: {
      mapToValue: 'domain'
    },
    VOLUME: {
      mapToValue: 'count',
      width: 70,
      alignRight: true
    },
    RECIPIENTS: {
      mapToValue: 'recipientsCount',
      width: 80,
      alignRight: true
    }
  }
})

const senderDomainsConfig = () => ({
  title: 'top_sender_domains',
  dropdownItems: [
    {
      value: Action.none,
      label: 'volume'
    },
    {
      value: Action.blocked,
      label: 'blocked'
    },
    {
      value: Action.allowed,
      label: 'allowed'
    }
  ],
  columns: {
    DOMAIN: {
      mapToValue: 'domain'
    },
    VOLUME: {
      mapToValue: 'count',
      width: 70,
      alignRight: true
    }
  }
})

const inboundConfig = (statType?: StatType) => ({
  title: 'inbound',
  dropdownItems: [
    {
      value: StatType.RECIPIENTS,
      label: 'top_recipients_blocked'
    },
    {
      value: StatType.SENDERS,
      label: 'top_senders_blocked'
    }
  ],
  columns: {
    [statType === StatType.SENDERS ? 'SENDERS' : 'RECIPIENTS']: {
      mapToValue: 'domain'
    },
    BLOCKED: {
      mapToValue: 'count',
      width: 70,
      alignRight: true
    }
  }
})

const outboundConfig = (statType?: StatType) => ({
  title: 'outbound',
  dropdownItems: [
    {
      value: StatType.RECIPIENTS,
      label: 'top_recipients_blocked'
    },
    {
      value: StatType.SENDERS,
      label: 'top_senders_blocked'
    }
  ],
  columns: {
    [statType === StatType.SENDERS ? 'SENDERS' : 'RECIPIENTS']: {
      mapToValue: 'domain'
    },
    BLOCKED: {
      mapToValue: 'count',
      width: 70,
      alignRight: true
    }
  }
})

const atpConfig = () => ({
  title: 'last_blocked',
  dropdownItems: [
    {
      value: 'atp',
      label: 'atp'
    }
  ],
  columns: {
    FILE_NAME: {
      mapToValue: 'filename'
    },
    FILE_TYPE: {
      mapToValue: 'filetype'
    },
    LAST_SEEN: {
      mapToValue: 'modified',
      alignRight: true,
      formatDate: true
    }
  }
})

export const getTableWithDropdownConfig = (key: DisplayType, statType?: StatType) => {
  switch (key) {
    case DisplayType.recipientDomains:
      return recipientDomainsConfig()
    case DisplayType.senderDomains:
      return senderDomainsConfig()
    case DisplayType.inbound:
      return inboundConfig(statType)
    case DisplayType.outbound:
      return outboundConfig(statType)
    case DisplayType.atp:
      return atpConfig()

    default:
      return undefined
  }
}
