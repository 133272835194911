import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'components/pages/overview/dashboard/dashboardStyles'
import TableWithDropdown from 'components/libs/tableWithDropdown/TableWithDropdown'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'
import { useLastBlockedLogic, UseLastBlockedLogicProps } from './useLastBlockedLogic'

const LastBlocked: React.FC<UseLastBlockedLogicProps> = (props: UseLastBlockedLogicProps) => {
  const classes = styles()
  const [state, eventHandlers] = useLastBlockedLogic(props)

  const config = getTableWithDropdownConfig(DisplayType.atp)
  return useMemo(
    () => (
      <Grid className={classes.tableWrapper} item data-testid="container">
        {config && (
          <TableWithDropdown
            config={config}
            data={state.lastBlockedAtpStats?.results}
            onSelect={eventHandlers.onSelectFilter}
            selectedValue={state.selectedFilter}
            inProgress={state.lastBlockedAtpStatsInProgress}
          />
        )}
      </Grid>
    ),
    [state, classes, config, eventHandlers]
  )
}

export default LastBlocked
