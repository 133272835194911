import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles({
  table: {
    borderSpacing: '1px',
    '& td:nth-child(n+2)': {
      border: `solid 1px  ${colors.scaleGray200}`,
      cursor: 'move',
      minWidth: '44px',
      padding: 0
    },
    '& .selected-cell': {
      backgroundColor: colors.barracudaBlue
    },
    '& .disableCss': {
      opacity: 0.5,
      cursor: 'not-allowed !important'
    },
    '& tbody th': {
      textAlign: 'left'
    }
  },
  hoursRow: {
    height: 26,
    overflow: 'visible',
    position: 'relative',

    '&:last-of-type': {
      '&:after': {
        content: '"24:00"',
        display: 'block',
        fontSize: 14,
        fontWeight: 'normal',
        height: '100%',
        letterSpacing: '0.00714em',
        position: 'absolute',
        right: '-50%',
        textAlign: 'center',
        top: 0,
        width: '100%',
        lineHeight: 1.57
      }
    }
  },
  hoursCell: {
    display: 'block',
    height: '100%',
    left: '-50%',
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    width: '100%'
  }
})
