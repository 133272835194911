import React, { useMemo } from 'react'

import {
  Alert,
  Button,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@barracuda-internal/bds-core'
import { CriticalOutline as CriticalOutlineIcon } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

import { useFormatMessage } from 'lib/localization'

import {
  useDeleteUserDialog,
  UseEditUserDialogLogicProps
} from 'components/pages/users/usersList/deleteUserDialog/useDeleteUserDialogLogic'
import styles from 'components/pages/users/usersList/usersListStyles'

const BASE_I18N_KEY = 'ess.users.delete_user_dialog'

export type EditUserDialogProps = UseEditUserDialogLogicProps

function DeleteUserDialog({ onClose, userIds, ...rest }: EditUserDialogProps) {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useDeleteUserDialog({ onClose, userIds })

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open {...rest}>
        {state.dialogError && <Alert severity="error">{formatMessage(state.dialogError)}</Alert>}
        <DialogTitle disableTypography>
          <Typography variant="h6">
            {formatMessage(userIds.length > 1 ? 'titles' : 'title', {
              user: userIds.join(', '),
              count: userIds.length,
              b: (txt: string) => <b>{txt}</b>
            })}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" alignItems="center">
            <CriticalOutlineIcon className={classes.criticalIcon} />
            <Typography variant="h3">{formatMessage('content_title')}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{ backgroundColor: colors.statusCritical }}
            onClick={eventHandlers.onDelete}
            color="primary"
            isLoading={state.deleteInProgress}
          >
            {formatMessage('confirm')}
          </Button>
          <Button onClick={eventHandlers.onCancel} color="primary" disabled={state.deleteInProgress}>
            {formatMessage('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [classes, formatMessage, state, eventHandlers, rest, userIds]
  )
}

export default DeleteUserDialog
