import { useCallback, useMemo } from 'react'
import { AttachmentFilter, AttachmentFilterAction, AttachmentFilterType } from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useStateField } from 'lib/settingsForm/useStateField'

export interface AttachmentFilterPolicyFormState {
  type: AttachmentFilterType
  pattern: string
  scanArchive: string
  action: AttachmentFilterAction
  comment: string
}

export interface ValidatorDependencies {
  data: AttachmentFilter[]
}

export interface AttachmentFilterPolicyFormConfig {
  initialState: AttachmentFilterPolicyFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type AttachmentFilterPolicyForm = SettingsForm<AttachmentFilterPolicyFormState>

export const useAttachmentFilterPolicyForm = ({
  initialState,
  delegateIsDirtyForm
}: AttachmentFilterPolicyFormConfig): AttachmentFilterPolicyForm => {
  const typeField = useStateField(initialState.type)
  const patternField = useRefField(initialState.pattern)
  const scanArchiveField = useStateField(initialState.scanArchive)
  const actionField = useStateField(initialState.action)
  const commentField = useRefField(initialState.comment)

  const formConfig = useMemo(
    () => ({
      fields: {
        type: typeField,
        pattern: patternField,
        scanArchive: scanArchiveField,
        action: actionField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [actionField, commentField, delegateIsDirtyForm, patternField, scanArchiveField, typeField]
  )
  const form = useSettingsForm<AttachmentFilterPolicyFormState>(formConfig)

  const validate = useCallback(
    (newPolicy: AttachmentFilter, dependencies: ValidatorDependencies) => {
      form.setError('')
      const findDuplicate = dependencies.data.find(entry => entry.pattern === newPolicy.pattern)
      if (findDuplicate) {
        form.setError('duplicate_value')
        return false
      }

      return true
    },
    [form]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
