import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings } from 'types/Settings'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'
import { useInboundSettingsRights } from 'components/libs/userRights/pages/useInboundSettingsRights'

export interface State {
  canChangeLanguagePolicies: boolean
  hasPageChanges: boolean
}

export interface EventHandlers {
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsGeoipTableDirty: Dispatch<SetStateAction<boolean>>
  setIsLanguageTableDirty: Dispatch<SetStateAction<boolean>>
  setIsIpAExemptTableDirty: Dispatch<SetStateAction<boolean>>
  setIsSenderExemptTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseRegionalPoliciesLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.GEOIP_POLICIES,
  AvailableSettings.GEOIP_SENDER_NAME_EXEMPT_POLICIES,
  AvailableSettings.GEOIP_IP_ADDRESS_EXEMPT_POLICIES,
  AvailableSettings.LANGUAGE_POLICIES
]

export const useRegionalPoliciesLogic = (): UseRegionalPoliciesLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const { accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess } = useAppSelector(
    _stores => ({
      accessTokenObject: _stores.auth.accessTokenObject,
      isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
      isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus)
    })
  )
  const { canChangeLanguagePolicies } = useInboundSettingsRights()
  const [isGeoipTableDirty, setIsGeoipTableDirty] = useState<boolean>(false)
  const [isLanguageTableDirty, setIsLanguageTableDirty] = useState<boolean>(false)
  const [isIpExemptTableDirty, setIsIpAExemptTableDirty] = useState<boolean>(false)
  const [isSenderExemptTableDirty, setIsSenderExemptTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      // dispatch(setHasPageChanges(false))
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  return useMemo(
    () => [
      {
        canChangeLanguagePolicies,
        hasPageChanges: isGeoipTableDirty || isLanguageTableDirty || isIpExemptTableDirty || isSenderExemptTableDirty
      },
      {
        setIsGeoipTableDirty,
        setIsLanguageTableDirty,
        setIsIpAExemptTableDirty,
        setIsSenderExemptTableDirty,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        }
      }
    ],
    [
      canChangeLanguagePolicies,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      isGeoipTableDirty,
      isLanguageTableDirty,
      isIpExemptTableDirty,
      isSenderExemptTableDirty,
      setIsGeoipTableDirty,
      setIsLanguageTableDirty,
      setIsIpAExemptTableDirty,
      setIsSenderExemptTableDirty
    ]
  )
}
