import { useEffect, useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { trackMixpanelEvent, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface UseLicenseContentLogic {
  enabled: boolean
  serial: string
}

export const useLicenseContentLogic = (): UseLicenseContentLogic => {
  const { serial } = useAppSelector(_stores => ({
    serial: _stores.auth.accessTokenObject?.serialNumber
  }))

  // init
  useEffect(() => {
    trackMixpanelEvent(TRACKING_EVENTS.WEBUI.LC_PAGEVIEW)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => ({
      enabled: !!serial,
      serial: serial || ''
    }),
    [serial]
  )
}
