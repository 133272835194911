import React from 'react'
import { Collapse, Grid, List, ListItem, Typography } from '@barracuda-internal/bds-core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { EmptyResultsIcon } from '@cuda/bds.icons.empty-results-icon'
import { EmptyState } from '@cuda/bds.ui.components.empty-state'
import { SearchField } from '@cuda/bds.ui.components.search-field'

import useStyles from './sideMenuStyles'

export type DropdownListItem = {
  id: string
  label: string
}

export type Dropdown = {
  label: string
  count?: number
  items: DropdownListItem[]
}

export type SideMenuProps = {
  /**
   * Dropdowns to be rendered in the side menu
   */
  dropdowns: Dropdown[]
  /**
   * Text to be rendered when the dropdown is empty
   */
  emptyResultsText?: string
  /**
   * Callback function to be called when the search field changes
   */
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  /**
   * Placeholder text for the search field
   */
  placeholderText?: string
  /**
   * Render prop for the dropdown's items
   */
  renderDropdownItems: (items: DropdownListItem[], index: number) => React.ReactNode | React.ReactNode[]
  /**
   * Render prop for the dropdown's items when the dropdown is empty
   */
  renderDropdownItemsEmptyState: (dropdown: Dropdown, index: number) => React.ReactNode | React.ReactNode[]
  /**
   * Subtitle to be rendered below the title
   */
  subtitle?: string
  /**
   * Title of the side menu
   */
  title: string
  /**
   * Make item's container scrollable
   */
  scrollableItemContainer?: boolean
}

export function SideMenu({
  dropdowns,
  emptyResultsText = 'No results found',
  onChange,
  placeholderText = 'Search',
  renderDropdownItems,
  renderDropdownItemsEmptyState,
  subtitle,
  title,
  scrollableItemContainer = false
}: SideMenuProps) {
  const classes = useStyles()

  const [closedDropdowns, setClosedDropdowns] = React.useState<any>({})

  const handleDropdownClick = (idx: number) => {
    setClosedDropdowns((prevState: any) => ({
      ...prevState,
      [idx]: !prevState[idx]
    }))
  }

  return (
    <Grid className={classes.sidemenuContainer} container direction="column" spacing={2}>
      <Grid item>
        {subtitle ? (
          <>
            <Typography variant="h6">{title}</Typography>
            <Typography color="secondary" variant="subtitle2">
              {subtitle}
            </Typography>
          </>
        ) : (
          <Typography variant="h2">{title}</Typography>
        )}
      </Grid>
      <Grid item>
        <SearchField id="side-menu-search" onChange={onChange} placeholder={placeholderText} />
      </Grid>
      <Grid item className={scrollableItemContainer ? classes.itemsContainer : ''}>
        {dropdowns?.length ? (
          dropdowns.map((dropdown, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <List className={classes.list} disablePadding key={index}>
              <ListItem onClick={() => handleDropdownClick(index)} disableGutters>
                <Grid container direction="row" className={classes.listLabelContainer}>
                  <Typography className={classes.listLabel} variant="body1">
                    {dropdown.label}
                  </Typography>
                  {!!dropdown.count && (
                    <Typography color="secondary" variant="body2">
                      &nbsp;({dropdown.count})
                    </Typography>
                  )}
                </Grid>
                {!closedDropdowns[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={!closedDropdowns[index]} timeout="auto" unmountOnExit>
                {dropdown.items?.length
                  ? renderDropdownItems(dropdown.items, index)
                  : renderDropdownItemsEmptyState(dropdown, index)}
              </Collapse>
            </List>
          ))
        ) : (
          <Grid container className={classes.emptyStateContainer}>
            <EmptyState icon={<EmptyResultsIcon className={classes.emptyStateIcon} />} text={emptyResultsText} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
