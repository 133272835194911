import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  deleteDomain,
  deleteDomainMove,
  getDomainList,
  resetDeleteDomainMove
} from 'redux/features/domains/domainsSlice'
import { resetDeleteDomain } from 'redux/features/domains/domainsActions'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { DeleteDomainConfirmationType, DomainExceptions } from 'types/domains'

export interface RemoveDomainLogicConfig {
  domainId: string | undefined
  isDomainMove: boolean
  onClose: () => void
}

export interface State {
  isPending: boolean
  dialogDescription: string
  isConfirmVisible: boolean
}

export interface EventHandlers {
  onClose: () => void
  onConfirm: () => void
}

export type RemoveDomainLogic = [State, EventHandlers]

const DEFAULT_DIALOG_DESCRIPTION = 'delete_domain_confirm.message'

export const useRemoveDomainLogic = ({
  domainId,
  isDomainMove,
  onClose
}: RemoveDomainLogicConfig): RemoveDomainLogic => {
  const [dialogDescription, setDialogDescription] = useState(DEFAULT_DIALOG_DESCRIPTION)
  const [isConfirmVisible, setIsConfirmVisible] = useState(true)
  const [confirmationType, setConfirmationType] = useState(DeleteDomainConfirmationType.CONFIRM_DELETION)
  const dispatch = useAppDispatch()
  const {
    isDeleteDomainPending,
    isDeleteDomainSuccess,
    isDeleteDomainMovePending,
    isDeleteDomainMoveSuccess,
    deleteDomainError
  } = useAppSelector(_store => ({
    isDeleteDomainPending: isPending(_store.domains.api.deleteDomainApiStatus),
    isDeleteDomainSuccess: isSuccess(_store.domains.api.deleteDomainApiStatus),
    deleteDomainError: getErrorMessage(_store.domains.api.deleteDomainApiStatus) || '',
    isDeleteDomainMovePending: isPending(_store.domains.api.deleteDomainMoveApiStatus),
    isDeleteDomainMoveSuccess: isSuccess(_store.domains.api.deleteDomainMoveApiStatus)
  }))

  const resetDialog = useCallback(() => {
    setDialogDescription(DEFAULT_DIALOG_DESCRIPTION)
    setIsConfirmVisible(true)
    setConfirmationType(DeleteDomainConfirmationType.CONFIRM_DELETION)
  }, [])

  const handleClose = useCallback(() => {
    onClose()
    resetDialog()
  }, [onClose, resetDialog])

  const onConfirm = useCallback(() => {
    if (domainId && !isDomainMove) {
      dispatch(deleteDomain({ domainId, confirmationType }))
    }
    if (domainId && isDomainMove) {
      dispatch(deleteDomainMove({ domainId }))
    }
  }, [confirmationType, dispatch, domainId, isDomainMove])

  useEffect(() => {
    dispatch(resetDeleteDomain())
    dispatch(resetDeleteDomainMove())
  }, [dispatch])

  useEffect(() => {
    if (isDeleteDomainSuccess || isDeleteDomainMoveSuccess) {
      dispatch(getDomainList())
      handleClose()
    }
  }, [dispatch, handleClose, isDeleteDomainSuccess, isDeleteDomainMoveSuccess])

  useEffect(() => {
    if (deleteDomainError) {
      switch (deleteDomainError) {
        case DomainExceptions.DeleteDomainOutboundIpConflict:
          setDialogDescription('delete_domain_confirm.outbound_ip_conflict')
          setIsConfirmVisible(false)
          break
        case DomainExceptions.DeleteDomainOnlyVerifiedDomainConflict:
          setDialogDescription('delete_domain_confirm.only_verified_domain')
          setIsConfirmVisible(true)
          setConfirmationType(DeleteDomainConfirmationType.CONFIRM_DELETION_WHEN_ONLY_VERIFIED)
          break
        default:
          resetDialog()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDomainError])

  return useMemo(
    () => [
      {
        isPending: isDeleteDomainPending || isDeleteDomainMovePending,
        dialogDescription,
        isConfirmVisible
      },
      { onClose: handleClose, onConfirm }
    ],
    [isDeleteDomainPending, isDeleteDomainMovePending, dialogDescription, isConfirmVisible, handleClose, onConfirm]
  )
}
