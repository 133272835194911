import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import config from 'config/appConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setActivePath } from 'redux/features/app/appSlice'
import { UiRoute } from 'lib/routes'
import validateSplitioKeys from 'components/libs/validateSplitioKeys'
import validateEnduserRoutes from 'lib/auth/validateEnduserRoutes'
import validateSession from 'lib/auth/validateSession'
import validateRouteAccess from 'lib/auth/validateRouteAccess'
import validateAppType from 'lib/auth/validateAppType'

export interface Props {
  route?: UiRoute
  Component: any
}

function PrivateRoute({ route, Component }: Props) {
  const dispatch = useAppDispatch()
  const { appId, isBlockedApp } = useAppSelector(_stores => ({
    appId: _stores.app.activePath.id,
    isBlockedApp: _stores.app.isBlocked
  }))

  const [shouldRenderComponent, setShouldRenderComponent] = useState<boolean>(false)

  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    if (route) {
      dispatch(
        // TODO: align type with redux state depends on (BNESS-22692)
        setActivePath({
          id: route.id || '',
          url: location.pathname,
          legacyPath: route.legacyPath,
          urlParams: location.search,
          params,
          metadata: route.metadata || {},
          isNavbarVisible: true,
          isPublicRoute: false
        })
      )
    }
  }, [dispatch, location, params, route])

  useEffect(() => {
    if (appId === route?.id) {
      setShouldRenderComponent(true)
    }
  }, [appId, route])

  const RenderComponent = useCallback(
    (childProps: any) => {
      const baseAuth = (additionalAuth: any) => validateSession(validateAppType(validateRouteAccess(additionalAuth)))

      let WrappedComponent
      switch (true) {
        case config.APP.IS_ADMIN || config.APP.IS_WIZARD:
          WrappedComponent = baseAuth(validateSplitioKeys(Component))
          break
        case config.APP.IS_ENDUSER:
          WrappedComponent = baseAuth(validateSplitioKeys(validateEnduserRoutes(Component)))
          break
        default:
          WrappedComponent = baseAuth(Component)
      }

      return <WrappedComponent {...childProps} />
    },
    [Component]
  )

  return useMemo(() => {
    if (!shouldRenderComponent || isBlockedApp) {
      return null
    }

    return <RenderComponent route={route} />
  }, [shouldRenderComponent, RenderComponent, route, isBlockedApp])
}

export default PrivateRoute
