import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import { useCallbackPrompt } from 'lib/hooks/useCallbackPrompt'

import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'

const BASE_I18N_KEY = 'ess.dialog'

const BlockNavigationDialog: React.FC<{ hasChanges: boolean }> = ({ hasChanges }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(hasChanges)

  return useMemo(
    () => (
      <WarningDialog
        open={showPrompt}
        title={formatMessage('unsaved_changes')}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    ),
    [formatMessage, showPrompt, confirmNavigation, cancelNavigation]
  )
}

export default BlockNavigationDialog
