import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  root: {
    // margin: theme.spacing(2)
  },
  header: {
    backgroundColor: colors.barracudaBlue,
    borderBottom: `1px solid ${colors.scaleGray300}`
  },
  headerTitle: {
    margin: theme.spacing(2, 0, 2, 2),
    color: colors.white
  },
  contentWrapper: {
    marginTop: theme.spacing(4)
  },
  content: {
    border: `1px solid ${colors.scaleGray300}`,
    padding: theme.spacing(2),
    width: 600
  },
  contentTitle: {
    color: colors.barracudaBlue,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  formRow: {
    marginBottom: theme.spacing(1)
  },
  formTitle: {
    lineHeight: `${theme.spacing(5)}px`
  }
}))
