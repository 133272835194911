import { AvailableSettings, SettingValue } from 'types/Settings'

export enum AtpScanMode {
  DELIVER_SCAN = 'deliver_scan',
  SCAN_DELIVER = 'scan_deliver',
  DISABLE = 'disable'
}

export enum AtpExemptionType {
  SENDER = 'sender',
  RECIPIENT = 'recipient'
}

export interface AtpExemptEmail {
  email: string
  type: AtpExemptionType
  comment: string
  id: string
}

export interface AtpExemptIp {
  address: string
  netmask: string
  comment: string
  id: string
}

export interface AtpSettings {
  [AvailableSettings.ATD]: AtpScanMode | ''
  [AvailableSettings.ATD_NOTIFY]: SettingValue | ''
  [AvailableSettings.ATD_ADMIN_EMAILS]: string
  [AvailableSettings.ATD_EXEMPT_EMAIL]: AtpExemptEmail[]
  [AvailableSettings.ATD_EXEMPT_IP]: AtpExemptIp[]
}
