import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  LOGIN: {
    path: 'login',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.enduser, AppNames.wizard],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.wizard,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  LOGIN_SSO: {
    path: 'login/sso',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    }
  },
  AUTH0_LOGIN_CALLBACK: {
    path: 'auth0-login-callback',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.enduser, AppTypes.domain, AppTypes.helpdesk]
    }
  },
  BCC_LOGIN: {
    path: 'bcc-login',
    legacyPath: '',
    appAccess: [AppNames.wizard, AppNames.admin, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.wizard,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  RESET_PASSWORD_LOGIN: {
    path: 'login?reset-password=1',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  FORGOT_PASSWORD_LOGIN: {
    path: 'login?forgot-password=:email',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  AUTO_LOGIN: {
    path: 'actions/auto-login',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  AZURE_SSO: {
    path: 'actions/sso/azure',
    legacyPath: 'user/auth/login',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  OAUTH2_CALLBACK: {
    path: 'oauth2/callback',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  },
  OAUTH2_AUTHORIZE: {
    path: 'oauth2/authorize',
    appAccess: [AppNames.admin, AppNames.enduser, AppTypes.cplAccount],
    authorization: {
      appTypeAccess: [
        AppTypes.enduser,
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.domain,
        AppTypes.helpdesk,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    }
  }
}
