import React, { useMemo } from 'react'
import { Close } from '@barracuda-internal/bds-core/dist/Icons/Core'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Modal,
  RadioGroup,
  List,
  Box,
  ListItem,
  ListItemIcon,
  FormControlLabel,
  Radio
} from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'

import styles from 'components/libs/dialog/languageMenu/languageMenusDialogStyles'
import { useLanguageMenuDialogLogic } from 'components/libs/dialog/languageMenu/useLanguageMenuDialogLogic'
import { Language } from 'types/Language'

export interface LanguageMenuFormProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.logoutmenu'
const BASE_I18N_DIALOG_KEY = 'ess.dialog'
const BASE_I18N_COMMON = 'ess_language.language'

const LanguageMenuDialog: React.FC<LanguageMenuFormProps> = ({ onClose }: LanguageMenuFormProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dialogFormatMessage = useFormatMessage(BASE_I18N_DIALOG_KEY)
  const commonFormatMessage = useFormatMessage(BASE_I18N_COMMON)
  const { selectedLanguage, handleSelectLanguage, onSaveLanguage } = useLanguageMenuDialogLogic({ onClose })

  return useMemo(
    () => (
      <Modal open onClose={onClose} className={classes.modal} data-testid="root-dialog">
        <Card className={`keep-open ${classes.container}`}>
          <CardHeader
            className={classes.menuTitle}
            title={formatMessage('language_title')}
            action={
              <IconButton onClick={onClose} data-testid="close-icon">
                <Close />
              </IconButton>
            }
            data-testid="title"
          />
          <CardContent>
            <RadioGroup
              name="languageMenu"
              value={selectedLanguage}
              onChange={handleSelectLanguage}
              data-testid="radio-group"
            >
              <List className={classes.listHeader}>
                <Box display="flex" flexDirection="column" height="210px">
                  <Box key={Language.english}>
                    <ListItem>
                      <ListItemIcon>
                        <FormControlLabel
                          checked={selectedLanguage === Language.english}
                          value={Language.english}
                          control={<Radio color="primary" />}
                          label={commonFormatMessage(Language.english)}
                          data-testid="radio-button-en"
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Box>
                  <Box key={Language.spanish}>
                    <ListItem>
                      <ListItemIcon>
                        <FormControlLabel
                          checked={selectedLanguage === Language.spanish}
                          value={Language.spanish}
                          control={<Radio color="primary" />}
                          label={commonFormatMessage(Language.spanish)}
                          data-testid="radio-button-es"
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Box>
                  <Box key={Language.japanese}>
                    <ListItem>
                      <ListItemIcon>
                        <FormControlLabel
                          checked={selectedLanguage === Language.japanese}
                          value={Language.japanese}
                          control={<Radio color="primary" />}
                          label={commonFormatMessage(Language.japanese)}
                          data-testid="radio-button-jp"
                        />
                      </ListItemIcon>
                    </ListItem>
                  </Box>
                </Box>
              </List>
            </RadioGroup>
          </CardContent>
          <CardActions className={classes.buttonsWrapper}>
            <Button variant="text" color="secondary" onClick={onClose} data-testid="cancel-button">
              {dialogFormatMessage('cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onSaveLanguage} data-testid="okay-button">
              {dialogFormatMessage('okay')}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    ),
    // eslint-disable-next-line prettier/prettier
    [
      classes,
      formatMessage,
      dialogFormatMessage,
      onClose,
      selectedLanguage,
      handleSelectLanguage,
      onSaveLanguage,
      commonFormatMessage
    ]
  )
}

export default LanguageMenuDialog
