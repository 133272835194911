import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundQuarantineInbox(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        This page is an interface for managing outbound email quarantined by {props.productName}. You can set quarantine
        policies on the <b>Outbound Settings &gt; Content Policies</b> page by selecting the
        <b> Quarantine</b> action for Attachment Filters, Message Content Filters, and Predefined Filters.
      </p>
      <br />
      <br />
      The page consists of two panes:
      <ul>
        <li>
          The Outbound Message List
          <br />
          Contains various details of quarantined messages.
        </li>
        <li>
          The Reading Pane
          <br />
          Displays message content. Select whether to display the Reading Pane to the <b>Right</b> or at the{' '}
          <b>Bottom</b> of a message, or select <b>Off</b> to hide the Reading Pane.
        </li>
      </ul>
      You can filter the message list in multiple ways including by <b>From</b>, <b>To</b>, and <b>Subject</b>. To view
      all filters, click <b>Advanced Search</b> to toggle the Advanced Search fields.
      <br />
      <br />
      <h3>Message Actions</h3>
      The admin can choose to <b> Export</b>, <b>Deliver</b>, <b>Reject</b>, or <b> Delete</b> messages.By default, the
      outbound quarantine message log displays messages for the last 2 days. If you want to view up to 30 days of
      outbound quarantine messages, click <b> Advanced Search</b> and enter the date range using the <b> Start Date</b>{' '}
      and <b> End Date</b> fields.
      <ol>
        <li>Select the message(s) using the checkboxes to the left of each message.</li>
        <li>
          Click the desired action: <b>Export</b>, <b>Deliver</b>, <b>Reject</b>, or <b>Delete</b>.
        </li>
      </ol>
      If you <b>Reject</b> a message:
      <ul>
        <li>The message is not delivered</li>
        <li>The message is deleted</li>
        <li>
          A Non-Delivery Report (NDR) message is returned to the sender, notifying them that their message was rejected
          by {props.productName} due to policy. A standard notification template is used for this NDR; however, the
          admin can create a custom NDR message by editing the <b>Reject Notification Template</b> on the{' '}
          <b>Outbound Settings &gt; Notifications</b> page in the <b>Notification to Sender for Rejected Message</b>{' '}
          section.
        </li>
      </ul>
      <h3>Simple Filters</h3>
      To create and apply simple filters to messages:
      <ol>
        <li>
          Select <b>All domains</b> or select a specific domain to search.
        </li>
        <li>
          Enter a whole (not partial) word, search pattern, or phrase in the <b>Search</b> field. Using the field name
          is not necessary, but narrows the search. For example, if you search on just <i>myaddress@yahoo.com</i>, the
          From, To, Subject, and Body fields of the message are all searched.
        </li>
        <br />
        Examples:
        <ul>
          <li>
            <b>IP_address</b>
            <br />
            ip:10.1.1.1
          </li>
          <li>
            <b>Message_id</b>
            <br />
            Verify the Reading Pane appears either on the right or bottom section of the page. Click on the message, and
            in the Reading Pane, click <span className={classes.blueText}>Show Details</span> to find the message_id. In
            the <b>Search</b> box, use the format message_id:&lt:your-message-id&gt;
            <br />
            message_id:1374102064-320627-22657-10347-7
          </li>
          <li>
            <b>Envelope_from</b>
            <br />
            envelope_from:myaddress@yahoo.com
            <br />
            myaddress@yahoo.com
          </li>
          <li>
            <b>Subject</b>
            <br />
            subject:Tomorrow
            <br />
            Tomorrow
          </li>
        </ul>
        <li>
          Click <b>Search</b>.
        </li>
      </ol>
      <h3>Advanced Filters</h3>
      Use advanced filters to refine your search. Click <b>Advanced Search</b> to display the Advanced Search fields. To
      hide these options, click <b>Advanced Search</b> again. To remove a search value, click <b>Clear</b>. Select or
      enter a word or phrase to search on in one or more of the following fields, then click <b>Search</b>.
      <ul>
        <li>
          <b>From</b>
          <br />
          Sender email address (this may not match the address in the headers that mail clients display to an end-user).
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es).
        </li>
        <li>
          <b>Envelope From</b>
          Sender email address to which bounce messages are delivered. This field is also sometimes known as{' '}
          <i>envelope from</i>, <i>envelope sender</i>, <i>MAIL FROM</i>, <i>return address</i>, and other names. All of
          these names refer to the email address found by the SMTP MAIL FROM command. The Envelope From field contents
          are generally not seen by the email user.
        </li>
        <li>
          <b>Envelope To</b>
          <br />
          This is the real destination email address.
        </li>
        <li>
          <b>Subject</b>
          <br />
          Messages where any portion of the &quot;Subject:&quot; field contains the specified text.
        </li>
        <li>
          <b>Start Date, End Date</b>
          Use these fields to limit date/time range of message search results. For example:
        </li>
        <ul>
          <li>
            View 10 minutes of results
            <br />
            <b>Start Date:</b> 2013-10-01 12:00am &nbsp;&nbsp; <b>End Date:</b> 2013-10-01-12:10am
            <br />
            Returns messages from 12:00 AM through 12:09 AM, not including 12:10 AM.
          </li>
          <li>
            View a 7 day range
            <br />
            <b>Start Date:</b> 2013-09-24 12:00am &nbsp;&nbsp; <b>End Date:</b> 2013-10-01 12:00am
          </li>
          <li>
            View yesterday&apos;s messages through today
            <br />
            <b>Start Date:</b> Yesterday &nbsp;&nbsp; <b>End Date:</b> blank
            <br />
            (leave the <b>End Date</b> field blank)
            <b>Results</b>
            <br />
            Select the number of results to display per page.
          </li>
        </ul>
      </ul>
      <h3>Saved Searches</h3>
      A Saved Search allows you to create a search filter for reuse at a later time.
      <br /> <br />
      To create a saved search:
      <ol>
        <li>
          Click <b>Saved Searches</b>.
        </li>
        <li>
          Enter the search criteria in the <b>Search Saved Entries</b> field.
        </li>
        <li>
          Enter a name to represent the saved search in the <b>Save current search</b> field.
        </li>
        <li>
          Click <b>Save</b>. The saved search is added to the <b>Saved Searches</b> box.
        </li>
      </ol>
      To run a saved search:
      <ol>
        <li>
          Click <b>Saved Searches</b>.
        </li>
        <li>Click on the named of the saved search you want to run.</li>
        <li>The search results displays.</li>
      </ol>
    </div>
  )
}

export default OutboundQuarantineInbox
