import React, { useMemo } from 'react'
import { Grid } from '@barracuda-internal/bds-core'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { DialogActions } from 'components/pages/domains/editDomain/editDomainWarningDialogs/EditDomainWarningDialog'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/domains/editDomain/editDomainWarningDialogs/editDomainWarningDialogStyles'
import { useAzureAdAuthorizationLogic } from 'components/pages/domains/editDomain/editDomainWarningDialogs/useAzureAdAuthorizationLogic'

const BASE_I18N_KEY = 'ess.domains'

export const AzureAdAuthorizationWarningDialog = ({ onConfirm, onClose }: DialogActions) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { isAuthorizePending } = useAzureAdAuthorizationLogic()

  return useMemo(
    () => (
      <WarningDialog
        isPending={isAuthorizePending}
        title={formatMessage('edit_domain.warnings.azure_authorization.title')}
        description={
          <Grid container className={classes.descriptionSpacing} direction="column">
            <Grid item>{formatMessage('edit_domain.warnings.azure_authorization.description.info')}</Grid>
            <Grid item className={classes.rowSpacing}>
              {formatMessage('edit_domain.warnings.azure_authorization.description.permissions.label')}:
            </Grid>
            <Grid item>
              <ol>
                <li>
                  {formatMessage('edit_domain.warnings.azure_authorization.description.permissions.user_profile')}
                </li>
                <li>
                  {formatMessage('edit_domain.warnings.azure_authorization.description.permissions.all_groups_read')}
                </li>
                <li>
                  {formatMessage('edit_domain.warnings.azure_authorization.description.permissions.all_groups_write')}
                </li>
                <li>
                  {formatMessage(
                    'edit_domain.warnings.azure_authorization.description.permissions.directory_data_read'
                  )}
                </li>
                <li>
                  {formatMessage(
                    'edit_domain.warnings.azure_authorization.description.permissions.directory_data_write'
                  )}
                </li>
                <li>
                  {formatMessage(
                    'edit_domain.warnings.azure_authorization.description.permissions.all_users_profile_read'
                  )}
                </li>
                <li>
                  {formatMessage(
                    'edit_domain.warnings.azure_authorization.description.permissions.all_users_profile_write'
                  )}
                </li>
              </ol>
            </Grid>
            <Grid item>{formatMessage('edit_domain.warnings.azure_authorization.description.continue')}</Grid>
          </Grid>
        }
        onCancel={onClose}
        onConfirm={onConfirm}
      />
    ),
    [classes, formatMessage, onClose, onConfirm, isAuthorizePending]
  )
}
