import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'

import {
  ProviderWithMxLinks,
  providerRelatedMxLinks
} from 'components/pages/onboardWizard/serverAndMxSetup/sections/providers/providerTypes'

export type DetectProviderLinksLogic = [ProviderWithMxLinks | undefined]

export const useDetectProviderLinksLogic = (): DetectProviderLinksLogic => {
  const { provider } = useAppSelector(_store => ({
    provider: _store.emailServer.provider
  }))

  const providerWithMxLinks = useMemo(() => {
    if (provider) {
      const providerLinks = providerRelatedMxLinks.find(
        providerRelatedMxLink => providerRelatedMxLink.criteria === provider[0]?.criteria
      )

      return providerLinks ? { ...provider[0], ...providerLinks } : undefined
    }

    return undefined
  }, [provider])

  return useMemo(() => [providerWithMxLinks], [providerWithMxLinks])
}
