import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tableText: {
    marginRight: theme.spacing(2)
  }
}))
