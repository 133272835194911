import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'

import {
  Box,
  Button,
  Chip,
  DataTable,
  DataTableColumn,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@barracuda-internal/bds-core'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import OutlinedDiv from 'components/libs/outlinedDiv/OutlinedDiv'

import { useFormatMessage } from 'lib/localization'
import { onKeyDown } from 'lib/keyEvents'

import { Cell } from 'components/libs/grid/cell'
import { TableText } from 'components/libs/layout/pages/TableText'

import styles from 'components/pages/auditLog/auditLogStyles'
import { useAuditLogLogic } from 'components/pages/auditLog/useAuditLogLogic'
import { ModifiedAuditLog } from 'types/AuditLog'
import AuditLogFilter from 'components/pages/auditLog/auditLogFilter/AuditLogFilter'
import { PageTitle } from 'components/libs/layout/pages/pageLayout'

const BASE_I18N_KEY = 'ess.audit_log'
const BASE_I18N_EXPORT_KEY = 'ess.audit_log.export'
const BASE_I18N_TABLE_KEY = 'ess.data_tables.audit_log'
const BASE_I18N_FILTER_KEY = 'ess.audit_log.action'

const AuditLog = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatExportMessage = useFormatMessage(BASE_I18N_EXPORT_KEY)
  const formatTableMessage = useFormatMessage(BASE_I18N_TABLE_KEY)
  const formatFilterMessage = useFormatMessage(BASE_I18N_FILTER_KEY)
  const { inProgress, exportConfig, searchConfig, tableConfig, datePickerConfig } = useAuditLogLogic()

  return useMemo(
    () => (
      <>
        <CSVLink
          ref={exportConfig.exportRef}
          filename={exportConfig.fileName()}
          headers={exportConfig.headers.map(header => ({ ...header, label: formatExportMessage(header.label) }))}
          data={tableConfig.tableData.data}
          data-testid="export-link"
        />

        <Grid>
          <Grid item xs={12} className={classes.container}>
            <Grid className={classes.cardsRow}>
              <PageTitle title="Audit Log" />
              <Grid className={classes.exportButton}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.exportButton}
                  onClick={exportConfig.csvExport}
                  data-testid="export-button"
                >
                  {formatMessage('exportButton')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.container}>
            <Grid item xs={12} className={classes.filterRow}>
              <Grid item className={classes.searchText}>
                <TextField
                  className={classes.searchTextField}
                  placeholder={formatMessage('search_placeholder')}
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={searchConfig.search}
                  inputProps={{ maxLength: 128 }}
                  onChange={searchConfig.handleInputChange}
                  onKeyDown={onKeyDown(['Enter'], () => searchConfig.handleSearch())}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    startAdornment: (
                      <IconButton size="small" edge="start" data-id="search-button" data-testid="search-button">
                        <Search />
                      </IconButton>
                    )
                  }}
                  data-testid="search-input"
                />
              </Grid>
              <Grid className={classes.dateRange}>
                <div className={classes.datePickerWrapper}>
                  <OutlinedDiv label={formatMessage('start_date')}>
                    <DatePicker
                      className={classes.datePicker}
                      name="start"
                      value={searchConfig.startDate}
                      {...datePickerConfig}
                      data-testid="start-datepicker"
                    />
                  </OutlinedDiv>
                  <OutlinedDiv label={formatMessage('end_date')}>
                    <DatePicker
                      className={classes.datePicker}
                      name="end"
                      value={searchConfig.endDate}
                      {...datePickerConfig}
                      data-testid="end-datepicker"
                    />
                  </OutlinedDiv>
                </div>
              </Grid>
              <Grid className={classes.filter}>
                <AuditLogFilter
                  eventTypes={searchConfig.eventTypes}
                  onUpdateEventTypes={searchConfig.handleUpdateEventTypes}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.filterChips}>
              {Object.entries(searchConfig.eventTypes).map(
                ([key, value]) =>
                  value && (
                    <div className={classes.filterChips} key={key}>
                      <Chip
                        key={key}
                        label={formatFilterMessage(key)}
                        className={classes.chip}
                        onDelete={(e: any) => searchConfig.handleRemoveEventType(key)}
                        size="small"
                        color="primary"
                        data-testid={`chip-${key}`}
                      />
                    </div>
                  )
              )}
            </Grid>
            <Grid>
              <Box className={classes.logTableProgress}>
                {inProgress && <LinearProgress data-testid="linear-progress" />}
              </Box>
              <DataTable
                pageConfig={{
                  pageable: { pageSizes: [10, 25, 50] },
                  skip: tableConfig.tableData.skip,
                  take: tableConfig.tableData.take,
                  total: tableConfig.tableData.total
                }}
                onPageChange={(e: any) => {
                  const { skip, take } = e.page
                  searchConfig.handlePageChange({ skip, take })
                }}
                onSortChange={searchConfig.handleSortChange}
                data={tableConfig.tableData}
                selectedField="selected"
                resizable
                sortable={{ allowUnsort: false, mode: 'single' }}
                sort={searchConfig.sort}
              >
                <DataTableColumn
                  field={tableConfig.columns.DATE}
                  {...tableConfig.columnsConfig[tableConfig.columns.DATE]}
                  title={formatTableMessage(`${tableConfig.columns.DATE}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAuditLog }) => (
                    <Cell data-testid="date-cell">
                      <Typography variant="body2">{dataItem.formattedDate}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.EVENT_TYPE}
                  {...tableConfig.columnsConfig[tableConfig.columns.EVENT_TYPE]}
                  title={formatTableMessage(`${tableConfig.columns.EVENT_TYPE}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAuditLog }) => (
                    <Cell data-testid="event-type-cell">
                      <Typography variant="body2">{dataItem.eventType}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.DESCRIPTION}
                  {...tableConfig.columnsConfig[tableConfig.columns.DESCRIPTION]}
                  title={formatTableMessage(`${tableConfig.columns.DESCRIPTION}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAuditLog }) => (
                    <Cell data-testid="description-cell">
                      <TableText dataText={dataItem.description} truncated />
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.USER}
                  {...tableConfig.columnsConfig[tableConfig.columns.USER]}
                  title={formatTableMessage(`${tableConfig.columns.USER}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAuditLog }) => (
                    <Cell data-testid="user-cell">
                      <Typography variant="body2">{dataItem.userName}</Typography>
                    </Cell>
                  )}
                />
                <DataTableColumn
                  field={tableConfig.columns.IP_ADDRESS}
                  {...tableConfig.columnsConfig[tableConfig.columns.IP_ADDRESS]}
                  title={formatTableMessage(`${tableConfig.columns.IP_ADDRESS}`)}
                  cell={({ dataItem }: { dataItem: ModifiedAuditLog }) => (
                    <Cell data-testid="ip-address-cell">
                      <Typography variant="body2">{dataItem.ipAddress}</Typography>
                    </Cell>
                  )}
                />
              </DataTable>
            </Grid>
          </Grid>
        </Grid>
      </>
    ),
    [
      exportConfig,
      tableConfig,
      classes,
      formatMessage,
      searchConfig,
      datePickerConfig,
      inProgress,
      formatTableMessage,
      formatExportMessage,
      formatFilterMessage
    ]
  )
}

export default AuditLog
