import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundRateControl(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email
      {!props.hasCpl && (
        <>
          , unless you change these settings for a specific domain. In that case, the domain-level settings override the
          global system settings
        </>
      )}
      .
      <br /> <br />
      The {props.productName} Rate Control feature protects your mail server from spammers or spam-programs (also known
      as &quot;spam-bots&quot;) that send large amounts of email to the server in a small amount of time. Note that any
      IP address entered in the <b>Rate Control Exemptions</b> section on this page is exempt from inbound Rate Control.
      <h3>Rate Control Exemptions</h3>
      Use this section to exclude known and trusted IP addresses or IP ranges from IP-based Rate Control. Note that
      email messages from these IP addresses are still scanned for spam and virus content. For each IP address you want
      to exclude:
      <ul>
        <li>
          Enter the <b>IP Address</b> and <b>Netmask</b>.
        </li>
        <li>
          Optionally, enter a <b>Comment</b> to identify the IP address.
        </li>
        <li>
          Click <b>Add</b> in the <b>Actions</b> column to add the IP address to the table.
        </li>
      </ul>
      To delete an IP address from the table, click <b>Remove</b> in the <b>Actions</b> column.
      <br />
      <br />
      Click on a column heading to sort the <b>Rate Control Exemptions</b> table:
      <ul>
        <li>IP Address</li>
        <li>Netmask</li>
        <li>Comment</li>
      </ul>
      {!props.hasCpl && (
        <>
          <h3>Internal Rate Control</h3>
          Internal Rate Control is the threshold for the number of messages that a domain within your organization is
          willing to receive from a sender (a single IP address).
          <ul>
            <li>
              <b>Exempt Rate Control Between Domains In This Account</b>
              <br />
              Select <b>Yes</b> to exempt email sent between domains in the same account from Rate Control.
            </li>
          </ul>
        </>
      )}
      <h3>Rate Control</h3>
      The Rate Control mechanism counts the number of messages for a domain from a sender (a single IP address) over a
      30 minute period and compares that number to the entered{' '}
      <b>Maximum Messages per Sender IP Address per 30 minutes</b> threshold. If the number of inbound messages for a
      domain from a sender (a single IP address) exceeds this threshold within a 30 minute period, {props.productName}{' '}
      defers any further connection attempts from that particular IP address until the next 30 minute period and logs
      each attempt as <b>Deferred</b> in the <b>Message Log</b> with a <b>Reason</b> of <b>Rate Control</b>.
    </div>
  )
}

export default InboundRateControl
