import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getErrorMessage, isSuccess } from 'redux/toolkit/api'
import { useAppSelector } from 'redux/toolkit/hooks'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'
import { useFormatMessage } from 'lib/localization'

const DIALOG_I18N_KEY = 'ess.dialog'

export interface SettingsTableActionsConfig {
  addItem: () => void
  deleteItem: (id: string) => void
  handleSuccess?: () => void
  handleError?: (error: string) => void
}

export const useSettingsTableActions = ({
  addItem,
  deleteItem,
  handleSuccess,
  handleError
}: SettingsTableActionsConfig) => {
  const formatMessage = useFormatMessage(DIALOG_I18N_KEY)
  const selectedItemId = useRef<string>('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { isUpdateSuccess, updateError } = useAppSelector(_store => {
    const pdDomainId = _store.auth.accessTokenObject?.pdDomainId
    const updateApiStatus = pdDomainId
      ? _store.settings.updateDomainSettingsApiStatus
      : _store.settings.updateAccountSettingsApiStatus
    return {
      isUpdateSuccess: isSuccess(updateApiStatus),
      updateError: getErrorMessage(updateApiStatus)
    }
  })

  const onRequestDeleteConfirmation = useCallback((id: string) => {
    selectedItemId.current = id
    setIsConfirmDialogOpen(true)
  }, [])

  const onDelete = useCallback(() => {
    deleteItem(selectedItemId.current)
    selectedItemId.current = ''
    setIsConfirmDialogOpen(false)
  }, [deleteItem])

  const onAdd = useCallback(() => {
    addItem()
    setIsConfirmDialogOpen(false)
  }, [addItem])

  const ConfirmDialog = useMemo(
    () => () =>
      (
        <WarningDialog
          title=""
          description={formatMessage('confirm_removal')}
          onConfirm={onDelete}
          onClose={() => setIsConfirmDialogOpen(false)}
          open={isConfirmDialogOpen}
          onCancel={() => setIsConfirmDialogOpen(false)}
        />
      ),
    [formatMessage, isConfirmDialogOpen, onDelete]
  )

  useEffect(() => {
    if (handleSuccess && isUpdateSuccess) {
      handleSuccess()
    }
  }, [handleSuccess, isUpdateSuccess])

  useEffect(() => {
    if (handleError && updateError) {
      handleError(updateError)
    }
  }, [handleError, handleSuccess, isUpdateSuccess, updateError])

  return useMemo(
    () => ({
      ConfirmDialog,
      onDelete: onRequestDeleteConfirmation,
      onAdd
    }),
    [ConfirmDialog, onAdd, onRequestDeleteConfirmation]
  )
}
