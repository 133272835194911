import { useMemo, useCallback, useState, ChangeEvent, Dispatch, SetStateAction } from 'react'

import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'

import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, GeoPolicyStatus, LanguagePolicy } from 'types/Settings'
import routesConfig from 'lib/routesConfig'

import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'
import { languagePolicies } from 'lib/languages'
import {
  LanguagePoliciesForm,
  LanguagePoliciesFormState,
  useLanguagePoliciesForm
} from 'components/pages/inboundSettings/regionalPolicies/useLanguagePoliciesForm'

export interface State {
  isConfirmDialogOpen: boolean
  languages: string[]
  sort: SortDescriptor[]
  error: string
  form: LanguagePoliciesForm
  formState: LanguagePoliciesFormState
  tableData: {
    total: number
    data: LanguagePolicy[]
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onRemoveConfirm: () => void
  onRemoveCancel: () => void
  onBulkEdit: () => void
  handleSortChange: (e: GridSortChangeEvent) => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleOnSelectChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
}

export type UseLanguagePoliciesTableLogic = [State, EventHandlers]

export interface UseLanguagePoliciesTableLogicProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export const enum LANGUAGE_SELECT_NAMES {
  LANGUAGE = 'language',
  POLICY = 'policy'
}

const initialSort = [{ field: LANGUAGE_SELECT_NAMES.LANGUAGE, dir: 'asc' } as SortDescriptor]

const INITIAL_FORM_STATE = {
  language: languagePolicies[0],
  policy: GeoPolicyStatus.block,
  comment: ''
}

const BLANK_LANGUAGE_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const useLanguagePoliciesTableLogic = ({
  delegateDirtyFormState
}: UseLanguagePoliciesTableLogicProps): UseLanguagePoliciesTableLogic => {
  const form = useLanguagePoliciesForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const dispatch = useAppDispatch()
  const [sort, setSort] = useState(initialSort)
  const [removeId, setRemoveId] = useState<string>('')
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [presentPolicies, setPresentPolicies] = useState<LanguagePolicy[]>([])

  const { accessTokenObject, accountSettings, domainSettings } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const tableData = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedData = settings.language_policies
      ? (JSON.parse(settings.language_policies as string) as LanguagePolicy[])
      : []
    setPresentPolicies(parsedData || [])
    const orderedData = orderBy(parsedData || [], sort)
    const { data } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    data.unshift(BLANK_LANGUAGE_ENTRY)
    return {
      data,
      total: data.length || 0
    }
  }, [accessTokenObject, domainSettings, accountSettings, sort])

  const updateData = useCallback(
    (policies: LanguagePolicy[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.LANGUAGE_POLICIES]: policies }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.LANGUAGE_POLICIES]: policies } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(() => {
    const newPolicy: LanguagePolicy = {
      ...form.getState(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newPolicy, { presentPolicies })) {
      const policies = [...presentPolicies, newPolicy]
      updateData(policies)
    }
  }, [form, presentPolicies, updateData])

  const onRemove = useCallback(
    (id: string) => {
      setRemoveId(id)
      setIsConfirmDialogOpen(true)
    },
    [setRemoveId, setIsConfirmDialogOpen]
  )

  const onRemoveConfirm = useCallback(() => {
    const policies = presentPolicies.filter(entry => entry.id !== removeId)
    updateData(policies)
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [presentPolicies, removeId, updateData, setIsConfirmDialogOpen, setRemoveId])

  const onRemoveCancel = useCallback(() => {
    setRemoveId('')
    setIsConfirmDialogOpen(false)
  }, [setRemoveId, setIsConfirmDialogOpen])

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_LANGUAGE_POLICIES_BULK_EDIT.goto()
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      form.fields.comment.setValue(e.target.value)
      form.delegateIsDirty()
    },
    [form]
  )

  const handleOnSelectChange = useCallback(
    (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = e.target
      if (typeof value !== 'string') {
        return
      }
      // eslint-disable-next-line default-case
      switch (true) {
        case name === LANGUAGE_SELECT_NAMES.LANGUAGE:
          form.setError('')
          form.fields.language.setValue(value)
          break
        case name === LANGUAGE_SELECT_NAMES.POLICY:
          form.fields.policy.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  return useMemo(
    () => [
      {
        isConfirmDialogOpen,
        languages: languagePolicies,
        sort,
        error: form.error,
        form,
        formState: form.getState(),
        tableData
      },
      {
        onAdd,
        onRemove,
        onRemoveConfirm,
        onRemoveCancel,
        onBulkEdit,
        handleSortChange,
        handleOnInputChange,
        handleOnSelectChange
      }
    ],
    [
      isConfirmDialogOpen,
      sort,
      form,
      tableData,
      onAdd,
      onRemove,
      onRemoveConfirm,
      onRemoveCancel,
      onBulkEdit,
      handleSortChange,
      handleOnInputChange,
      handleOnSelectChange
    ]
  )
}
