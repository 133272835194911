import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { BannerSettings } from 'types/banners'
import { AvailableSettings } from 'types/Settings'

export const getBannerSettings = createAsyncThunk<BannerSettings, void, ApiRejectResponse>(
  'BANNERS/getBannerSettings',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_BANNER_SETTINGS)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const updateHasMailFlownSetting = createAsyncThunk<undefined, void, ApiRejectResponse>(
  'BANNERS/updateHasMailFlownSetting',
  async (payload, { rejectWithValue }) => {
    try {
      await restClient(apiRoutes.UPDATE_ACCOUNT_SETTINGS, {
        data: { newSettings: { [AvailableSettings.HAS_MAIL_FLOWN]: '1' } }
      })
      return undefined
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
