import React, { useEffect, useMemo, useState } from 'react'

import { UserRole } from 'config/userRole'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setAppType } from 'redux/features/app/appSlice'
import { AppTypes } from 'types/AppTypes'
import appConfig from 'config/appConfig'

export default function validateAppType<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidateAppType: React.FC<any> = props => {
    const dispatch = useAppDispatch()
    const { userType, pdDomainId, isCplAccount, currentAppType } = useAppSelector(_store => ({
      userType: _store.auth.accessTokenObject?.roleType,
      pdDomainId: _store.auth.accessTokenObject?.pdDomainId,
      isCplAccount: _store.auth.accessTokenObject?.isCplAccount,
      currentAppType: _store.app.appType
    }))
    const [shouldRenderComponent, setShouldRenderComponent] = useState<boolean>(false)

    const calculatedAppType: AppTypes | undefined = useMemo(() => {
      if (userType) {
        const isEnduserType = userType === UserRole.USER
        const isHelpdeskType = userType === UserRole.HELPDESK_ROLE
        const isDomainUserType = userType === UserRole.DOMAIN_USER
        const isAccountAdminType = userType === UserRole.ACCOUNT_USER

        switch (true) {
          case appConfig.APP.IS_WIZARD:
            return AppTypes.wizard
          case isCplAccount:
            return AppTypes.cplAccount
          case isEnduserType:
            return AppTypes.enduser
          case pdDomainId && !isHelpdeskType && !isDomainUserType:
            return AppTypes.pdDomainId
          case isAccountAdminType:
            return AppTypes.admin
          case isHelpdeskType && !pdDomainId:
            return AppTypes.helpdesk
          case isDomainUserType && !pdDomainId:
            return AppTypes.domain
          case isHelpdeskType && !!pdDomainId:
            return AppTypes.helpdeskWithPdDomainId
          case isDomainUserType && !!pdDomainId:
            return AppTypes.domainWithPdDomainId

          default:
            return undefined
        }
      }

      return undefined
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // validate appType
    useEffect(() => {
      if (calculatedAppType && calculatedAppType !== currentAppType) {
        dispatch(setAppType(calculatedAppType))
      } else {
        setShouldRenderComponent(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // continue the render after the new appType is set
    useEffect(() => {
      setShouldRenderComponent(true)
    }, [currentAppType])

    return useMemo(() => {
      if (!shouldRenderComponent) {
        return null
      }

      return <WrappedComponent {...(props as any)} />
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRenderComponent])
  }

  return ValidateAppType
}
