import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function UsersQuarantine(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        You can select to send user quarantine notifications at scheduled intervals. Note that quarantine notifications
        are sent only if new mail is quarantined since the last notice.{' '}
      </p>

      <ul>
        <li>
          <b>Quarantine messages for Intent Analysis</b>
          <br />
          When set to <b>Yes</b>, messages that would be blocked by Intent Analysis will be quarantined instead.
        </li>
        <li>
          <b>Default interval for user quarantine notifications</b>
          <br />
          Specify whether to <b>Never</b> send user quarantine notifications or create <b>Scheduled</b> user quarantine
          notifications.
        </li>
        <ul>
          <li>
            <b>Never</b>
            <br />
            Mail is quarantined but no notification is sent.
          </li>
          <li>
            <b>Scheduled</b>
            <br />
            Select the day and time quarantine notifications are to be sent using the{' '}
            <b>Schedule notification intervals</b> section.
          </li>
        </ul>
        <li>
          <b>Allow users to specify interval</b>
          <br />
          When set to <b>Yes</b>, users can override these settings and configure their own notification interval.
        </li>
      </ul>
      <p>
        The links in the quarantine notification email allow the user to access their {props.productName} user account
        without entering their username and password. The link is valid for seven days, after which the user must log in
        manually at https://{window.location.hostname}.
      </p>
      <p>
        You can require users to enter their login credentials before they can access quarantined messages. By default,
        this is set to <b>No</b>. Note that this option is available to account admins only and does not display when
        logged in as a domain admin.
      </p>

      <ul>
        <li>
          <b>Require login credentials to access quarantined messages</b>
          <br />
          When set to <b>Yes</b>, users will be prompted to enter their account credentials before they can view their
          quarantined messages.
        </li>
      </ul>
    </div>
  )
}

export default UsersQuarantine
