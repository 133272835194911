import { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { v4 as makeUuid } from 'uuid'

import { isEmailValid } from 'lib/validation'
import { ComposerAttachment } from 'redux/features/mstore/mstoreApiThunks'
import { useFileReader } from 'lib/useFileReader'

export interface FormConfig {
  from: string
  to: string
  cc: string
  subject: string
  body: string
  attachments: ComposerAttachment[]
}

export type FieldName = 'from' | 'to' | 'cc' | 'subject' | 'body'

export interface Field<T> {
  value: T
  isValid: boolean
}

export interface State {
  from: Field<string>
  to: Field<string>
  cc: Field<string>
  subject: Field<string>
  body: Field<string>
  attachments: Field<ComposerAttachment[]>
}

interface Values {
  from: State['from']['value']
  to: State['to']['value']
  cc: State['cc']['value']
  subject: State['subject']['value']
  body: State['body']['value']
  attachments: State['attachments']['value']
}

export interface Methods {
  getValues: () => Values
  isFormValid: () => boolean
  onAddAttachment: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeField: (fieldName: FieldName) => (e: ChangeEvent<{ value: unknown }>) => void
  onDeleteAttachment: (id: string) => void
  onSetAttachments: (value: ComposerAttachment[]) => void
  onSetField: (fieldName: FieldName) => (value: string) => void
}

export type Form = [State, Methods]

export const useForm = (initialState: FormConfig): Form => {
  const makeFileReader = useFileReader()
  const validateFrom = (value: string) => isEmailValid(value)
  const validateTo = (value: string) => isEmailValid(value)
  const validateCc = (value: string) => !value || isEmailValid(value)
  const validateSubject = (value: string) => !!value
  const validateBody = (value: string) => !!value

  const [from, setFrom] = useState({
    value: initialState.from,
    isValid: validateFrom(initialState.from)
  })
  const [to, setTo] = useState({
    value: initialState.to,
    isValid: validateTo(initialState.to)
  })
  const [cc, setCc] = useState({
    value: initialState.cc,
    isValid: validateCc(initialState.cc)
  })
  const [subject, setSubject] = useState({
    value: initialState.subject,
    isValid: validateSubject(initialState.subject)
  })
  const [body, setBody] = useState({
    value: initialState.body,
    isValid: validateBody(initialState.body)
  })
  const [attachments, setAttachments] = useState({
    value: initialState.attachments,
    isValid: true
  })

  const handleChange = (
    value: string,
    setter: Dispatch<SetStateAction<{ value: string; isValid: boolean }>>,
    validator: (value: string) => boolean
  ) => setter({ value, isValid: validator(value) })

  const onSetField = useCallback(
    (fieldName: FieldName) => (value: string) => {
      switch (true) {
        case fieldName === 'from':
          return handleChange(value, setFrom, validateFrom)
        case fieldName === 'to':
          return handleChange(value, setTo, validateTo)
        case fieldName === 'cc':
          return handleChange(value, setCc, validateCc)
        case fieldName === 'subject':
          return handleChange(value, setSubject, validateSubject)
        case fieldName === 'body':
          return handleChange(value, setBody, validateBody)
        default:
          return undefined
      }
    },
    []
  )

  const onChangeField = useCallback(
    (fieldName: FieldName) => (e: ChangeEvent<{ value: unknown }>) => {
      // Select component in <EmailComposer> exposes ChangeEvent<{ value: unknown }>
      // ignoring non string values because by the <Select> setup in <EmailComposer> the values must be strings
      // <TextFields> expose ChangeEvent<{ value: string }> it is safe to ignore non string values for those
      // see the value porps passed to <MenuItems>
      // see the value porps passed to <TextFields>
      if (typeof e.target.value !== 'string') {
        return
      }
      onSetField(fieldName)(e.target.value)
    },
    [onSetField]
  )

  const isFormValid = useCallback(
    () => ![from, to, cc, subject, body, attachments].some(formValue => !formValue.isValid),
    [from, to, cc, subject, body, attachments]
  )

  const onAddAttachment = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return
      }
      const file = e.target.files[0]
      const reader = makeFileReader()
      reader.onload = evt => {
        const result = evt.target?.result ?? null
        if (!(result instanceof ArrayBuffer)) {
          return
        }
        const nextAttachment: ComposerAttachment = {
          id: makeUuid(),
          fileName: file.name,
          binaryContent: result
        }
        setAttachments({
          value: [nextAttachment, ...attachments.value],
          isValid: true
        })
      }
      reader.readAsArrayBuffer(file)
    },
    [attachments.value, makeFileReader]
  )

  const onDeleteAttachment = useCallback(
    (id: string) => {
      setAttachments({ value: attachments.value.filter(attachment => attachment.id !== id), isValid: true })
    },
    [attachments.value]
  )

  const onSetAttachments = useCallback((value: ComposerAttachment[]) => setAttachments({ value, isValid: true }), [])

  const getValues = useCallback(
    () => ({
      from: from.value,
      to: to.value,
      cc: cc.value,
      subject: subject.value,
      body: body.value,
      attachments: attachments.value
    }),
    [attachments.value, body.value, cc.value, from.value, subject.value, to.value]
  )

  return useMemo(
    () => [
      {
        from,
        to,
        cc,
        subject,
        body,
        attachments
      },
      {
        getValues,
        isFormValid,
        onChangeField,
        onSetField,
        onSetAttachments,
        onDeleteAttachment,
        onAddAttachment
      }
    ],
    [
      attachments,
      body,
      cc,
      getValues,
      from,
      isFormValid,
      onAddAttachment,
      onChangeField,
      onDeleteAttachment,
      onSetAttachments,
      onSetField,
      subject,
      to
    ]
  )
}
