import { makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  verticalAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  fullWidthWithMargin: {
    width: `calc(100% - ${theme.spacing(1)}px)`
  },
  minWidth: {
    minWidth: 100
  }
}))

export default styles
