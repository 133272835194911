import util from 'util'
import { useMemo } from 'react'

import { Message } from 'types/Messages'
import { formatDate } from 'lib/datetime'
import appConfig from 'config/appConfig'
import { extractEmail } from 'lib/email'
import { useFormatMessage } from 'lib/localization'
import { useAppSelector } from 'redux/toolkit/hooks'

export interface EmailContentConfig {
  emailType: string
  message: Message | undefined
}

export interface EmailContent {
  from: string
  to: string
  cc: string
  body: string
  subject: string
  isReply: boolean
  isReplyAll: boolean
  isNewEmail: boolean
}

const BASE_18N_KEY = 'ess.message_log.email_composer'

export const useEmailContent = ({ emailType, message }: EmailContentConfig): EmailContent => {
  const formatMessage = useFormatMessage(BASE_18N_KEY)
  const isReply = emailType.search(/reply/i) !== -1
  const isReplyAll = emailType.search(/all/i) !== -1
  const isNewEmail = emailType.search(/new/i) !== -1
  const { userId } = useAppSelector(_store => ({
    userId: _store.auth.accessTokenObject?.userId || ''
  }))

  const from = useMemo(() => {
    if (isNewEmail) {
      return userId
    }
    if (!message) {
      return ''
    }
    return extractEmail(message.headers.from || message.headers.From || '')
  }, [isNewEmail, message, userId])

  const to = useMemo(() => {
    if (!message) {
      return ''
    }
    return extractEmail((!isNewEmail && (message.headers.to || message.headers.To)) || '')
  }, [isNewEmail, message])

  const cc = useMemo(() => {
    if (!message) {
      return ''
    }
    return extractEmail((!isNewEmail && (message.headers.cc || message.headers.Cc)) || '')
  }, [isNewEmail, message])

  const header = useMemo(() => {
    if (!message) {
      return []
    }
    const headerDate = message.headers.date || message.headers.Date
    const date =
      headerDate ||
      formatDate(new Date(message.fields.timestamp || 0), appConfig.DATETIME.DEFAULT_DATE_WITH_TIME_FORMAT)
    return [
      isReply ? '' : '---------- Forwarded message ----------',
      `From: ${message.headers.from || message.headers.From || ''}`,
      `Date: ${date}`,
      `To: ${message.headers.to || message.headers.To || ''}`,
      `Cc: ${message.headers.cc || message.headers.Cc || ''}`,
      `Subject: ${message.headers.subject || message.headers.Subject || ''}`
    ].map(line => {
      const escapedLine = line.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      return `<div>${escapedLine}</div>`
    })
  }, [isReply, message])

  const body = useMemo(() => {
    if (isNewEmail) {
      return '<div><br></div> <div><br></div> <div><br></div>'
    }

    if (!message) {
      return ''
    }

    return `<div><br></div> <div><br></div> <div><br></div><div>${header.join('')}<br>${
      message.body
    }</div><div><br><br><br></div>`
  }, [message, isNewEmail, header])

  const subject = useMemo(() => {
    if (isNewEmail) {
      return formatMessage('new_email_title')
    }

    const prefix = isReply ? 'reply' : 'forward'
    const subjectText = message ? message.fields.subject || '' : ''
    return util.format('%s: %s', formatMessage(prefix), subjectText)
  }, [formatMessage, isNewEmail, isReply, message])

  return useMemo(
    () => ({
      from,
      to,
      cc,
      body,
      subject,
      isReply,
      isReplyAll,
      isNewEmail
    }),
    [from, to, cc, body, subject, isReply, isReplyAll, isNewEmail]
  )
}
