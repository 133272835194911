import React, { PropsWithChildren, useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'

export type PageSubtitleProps = {
  description?: string
}

export const PageSubtitle = ({ children, description }: PropsWithChildren<PageSubtitleProps>) =>
  useMemo(
    () => (
      <Grid container direction="column" justifyContent="flex-start">
        <Typography variant="h2">{children}</Typography>
        {!!description && (
          <Grid>
            <Typography variant="body1" data-testid={`description:${description}`}>
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    ),
    [children, description]
  )
