/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import IconOkay from 'assets/images/icons/icn-ok.png'
import IconWarning from 'assets/images/icons/icn-warning.png'
import IconCritical from 'assets/images/icons/icn-critical.png'

import { HelpComponentProps } from 'types/Help'

function DomainSettings(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <div>
        This page includes the following sections:
        <br />
        <a href="#mail_servers">Mail Servers</a>
        <br />
        <a href="#using_hosted_email_services">Using Hosted Email Services</a>
        <br />
        <a href="#mx_records_config">MX Records Configuration</a>
        <br />
        {!props.hasCpl && (
          <>
            <a href="#domain_alias">Domain Alias</a>
            <br />
          </>
        )}
        <a href="#options">Options</a>
        <br />
        <a href="#smtp_encryption">SMTP Encryption</a>
        <br />
        {!props.hasCpl && (
          <>
            <a href="#encryption">Encryption</a>
            <br />
            <a href="#dir_services">Directory Services</a>
            <br />
            <a href="#adv_config">Advanced Configuration</a>
          </>
        )}
        <br /> <br />
        <h3 id="mail_servers">Mail Servers</h3>
        Add each mail server you want {props.productName} to secure:
      </div>
      <ol>
        <li>
          Click <b>Add Mail Server</b>. Enter the Smart host or mail server hostname (FQDN) or IP address.
        </li>
        <li>
          Enter a <b>Priority</b> (optional) if there are multiple mail server hostnames.
        </li>
        <li>
          If you need to specify a destination port, append it to the hostname or IP address of the mail server, for
          example, my.mail.server:587.
        </li>
        <li>
          Click <b>Add</b>.
        </li>
      </ol>
      Once {props.productName} verifies the mail server, the <b>Domain Name</b> column in the <b>Domains</b> page
      displays the Domain status as follows:
      <ul>
        <li>
          <img src={IconOkay} /> indicates the Domain is verified and all MX records for the domain are pointing to{' '}
          {props.productName}.
        </li>
        <li>
          <img src={IconWarning} /> indicates the Domain is verified but the MX records are not all pointing to{' '}
          {props.productName}, and therefore MX is not configured.
        </li>
        <li>
          <img src={IconCritical} /> indicates the Domain is new and not yet verified. Click <b>Verify Ownership</b> to
          complete the verification process.
        </li>
      </ul>
      To test the mail server entry, click <b>Test</b>. To modify the mail server entry, click <b>Edit</b>. To delete
      the mail server entry, click <b>Remove</b>.
      <br />
      <br />
      <b>Important</b>: Block all port 25 traffic except for that originating from the {props.productName} IP address
      ranges. See{' '}
      <a href="https://campus.barracuda.com/doc/96023036/" target="_new">
        Barracuda Campus
      </a>
      for a list of IP ranges based on the region you selected for your instance.
      <br />
      <br />
      <h3 id="using_hosted_email_services">Using Hosted Email Services</h3>
      If you are using a hosted email service, see
      <a href="https://campus.barracuda.com/doc/96023081/" target="_new">
        Deployment Options
      </a>
      in Barracuda Campus.
      <br />
      <br />
      <h3 id="mx_records_config">MX Records Configuration</h3>
      Displays the MX (Mail Exchange) records for this domain.
      <br />
      <br />
      {!props.hasCpl && (
        <>
          <h3 id="domain_alias">Domain Alias</h3>
          If you alias this domain to another domain already added and configured, then this domain inherits the
          settings created for the other domain. When you use domain aliasing, <b>all</b> users <b>must</b> be a member
          of the parent domain.
          <br />
          <br />
        </>
      )}
      <h3 id="options">Options</h3>
      <ul>
        <li>
          <b>Spooling</b>
          <br />
          {props.productName} can retain all of your email for a limited time if your mail server goes down.
        </li>
        <ul>
          <li>
            If Spooling is set to <b>Yes</b> and {props.productName} cannot connect to your mail server, the mail is
            allowed and the <b>Delivery Status</b> displays as <b>Spooled</b>. The sending mail server sees this as a
            delivered message. When the service is able to deliver the mail, the <b>Delivery Status</b> changes to{' '}
            <b>Delivered</b>. If the mail is undeliverable for 96 hours, the
            <b>Delivery Status</b> changes to <b>Expired</b>.
            <br />
          </li>
          <li>
            If Spooling is set to <b>No</b> and {props.productName} cannot connect to your mail server, the mail is
            deferred and the sending mail server is notified. Depending on its configuration, the sending mail server
            can retry the message or notify the sender that the mail was deferred or failed.
          </li>
        </ul>

        {!props.hasCpl && (
          <li>
            <b>Automatically Add Users</b>
            <br />
            If set to <b>Yes</b>, and if {props.productName} receives mail for at least a two day period in the last
            seven days for a user that has not been added to the <b>User List</b>, then the service automatically
            creates an account for that user.
            <br />
            <br />
            <b>Note:</b> To avoid invalid user creation, enable recipient verification on your mail server. If you do
            not enable recipient verification on your mail server and set <b>Automatically Add Users</b> to <b>Yes</b>,
            the system may create more user accounts than are needed.
            <br />
            <br />
          </li>
        )}
        <li>
          <b>Sender Spoof Protection</b>
          <br />
          Enable Sender Spoof Protection when your domain does NOT have any DNS sender authentication settings, such as
          SPF or DMARC.
          <br />
          <br />
          Select <b>Yes</b> to use Sender Spoof Protection to block emails from senders using your domain name. This
          means that Sender Spoof Protection will block emails if the domain used in either the &quot;Header From&quot;
          or “Envelope From” fields matches your domain in the &quot;Envelope To&quot; field. This feature does not
          protect against cross-domain spoofing within an account.
          <br />
          <br />
          Note that Sender Spoof Protection is for inbound mail only, and does not stop your domain from being spoofed
          at other mail servers.
          <br />
          <br />
          Barracuda Networks strongly recommends setting up proper SPF, DKIM, and DMARC records to authenticate your
          emails. Once configured, disable Sender Spoof Protection to allow the service to leverage the newly created
          records to authenticate emails that use your domain and originate from outside the organization.
          <br />
          <br />
          To bypass Sender Spoof Protection, create a sender policy and select <b>Exempt</b> as the sender policy on the{' '}
          <b>Inbound Settings &gt; Sender Policies</b> page.
          <br />
          <br />
        </li>
        <li>
          <b>Rewrite Recipients for Aliased Domains</b>
          <br />
          If set to <b>Yes</b>, the mail is sent to the recipient where the alias domain is replaced by the primary
          domain instead of sending the message to the original envelope recipient.
          <br />
          <br />
          This feature is applicable to domains that are an alias of another domain. Selecting <b>Yes</b>, for domains
          that do not have an alias, will have no effect.
          <br />
          <br />
          <b>Note:</b> You must verify that user email addresses are unique in your organization to avoid email
          collision and/or incorrect delivery.
        </li>
      </ul>
      <h3 id="smtp_encryption">SMTP Encryption</h3>
      <ul>
        <li>
          <b>Use TLS from Barracuda Networks to your mail server(s)</b>
          <br />
          Use this feature if you want messages for this domain to be passed on to your mail servers via a secure TLS
          connection.
        </li>
        <ul>
          <li>
            <b>Never</b>
            <br />
            Messages for this domain are never sent over a TLS connection.
          </li>
          <li>
            <b>Optional</b>
            <br />
            {props.productName} attempts a TLS connection to your mail server for delivery of your email. If TLS is not
            available, the mail is delivered normally (not over a TLS connection).
          </li>
          <li>
            <b>Required</b>
            <br />
            Messages for this domain are only sent over a TLS connection. Connections to your mail server must be a
            secure TLS connection. If your mail server does not support a TLS connection, the mail is rejected (it is
            bounced).
          </li>
        </ul>

        {!props.hasCpl && (
          <li>
            <b>Require TLS to Barracuda Networks from these domains</b>
            <br />
            Use this feature to require a TLS connection from certain domains to Barracuda Networks. You can manually
            add specific domains one at a time or use the <b>Bulk Edit</b> button.
          </li>
        )}
      </ul>
      {!props.hasCpl && (
        <>
          <h3 id="encryption">Encryption</h3>
          To encrypt email sent from this domain, go to <b> Outbound Settings &gt; Content Policies</b>, and select{' '}
          <b> Encrypt</b> when defining content filters.
          <br /> <br />
          To enable the following email encryption features, you must validate your domain by adding a CNAME record or
          by sending an email to your Postmaster address.Click <b> Validate CNAME</b> or <b> Validate Postmaster</b> to
          validate your domain.
          <ul>
            <li>Add your company logo to the notification message to recipients.</li>
            <li>Customize the text in the body of the notification message.</li>
            <li>Customize the subject of the notification message.</li>
            <li>Allow Replies - Allows recipient to reply to messages.</li>
            <li>
              Enable Read Receipts - Sends a read receipt email to the sender notifying that the recipient has read the
              message.
            </li>
          </ul>
          <br />
          <b>Encryption Notification Placeholders</b>
          <br />
          <br />
          Use the following placeholders:
          <ul>
            <li>
              <b>%sender%</b>
              <br />
              The sender
            </li>
            <li>
              <b>%senderdomain%</b>
              <br />
              The sender&apos;s domain
            </li>
            <li>
              <b>%recipient%</b>
              <br />
              The encrypted mail recipient
            </li>
            <li>
              <b>%link%</b>
              <br />
              The Barracuda Message Center link. For example: &#60;a href=%link%&#62;Click here&#60;/a&#62;
            </li>
            <li>
              <b>%logo%</b>
              <br />
              Your logo image. For example: &#60;img src=%logo%&#62;
            </li>
            <li>
              <b>%displayname%</b>
              <br />
              The Domain Display Name set for this domain.
            </li>
          </ul>
          <br />
          <b>Email Notification</b>
          <br />
          <br />
          When {props.productName} sends an encrypted outbound message, an email notification is sent to the
          recipient.This email includes a link the recipient can click to retrieve their message at the Barracuda
          Message Center.For more information, see{' '}
          <a href="https://campus.barracuda.com/doc/96023027/" target="_new">
            {' '}
            Barracuda Message Center User Guide
          </a>
          .
          <br />
          <h3 id="dir_services">Directory Services</h3>
          Use this section to set up directory services for this domain.
          <ul>
            <li>
              <b>LDAP</b>
              <br />
              Configure LDAP authentication via your organization&apos;s LDAP servers for this domain.
            </li>
            <li>
              <b>Azure AD</b>
              <br />
              Configure user authentication via your organization&apos;s Azure AD service for this domain.
            </li>
            <li>
              <b>None</b>
              <br />
              All directory services are disabled.
            </li>
          </ul>
          <b>Note:</b> If you replaced a previous LDAP setup with Azure AD, your LDAP settings are preserved and can be
          restored.Conversely, if you replaced a previous Azure AD setup with LDAP, you can restore your settings.See{' '}
          <a href="https://campus.barracuda.com/doc/96022973/" target="_new">
            {' '}
            How to Restore LDAP or Azure AD Directory Services
          </a>{' '}
          in Barracuda Campus for details.
          <br />
          <br />
          <b>IMPORTANT:</b> If you are moving from LDAP to Azure AD, you must delete all {props.productName} users you
          created with LDAP before synchronizing to Azure AD.
          <br />
          <br />
          <b>Type: LDAP</b>
          <ul>
            <li>
              <b>LDAP Configuration</b>{' '}
            </li>
            <ul>
              <li>
                <b>LDAP Host</b>
                <br />
                The server used for LDAP lookups. If this setting is a hostname, and is contained in multiple A records,
                then fail-over capabilities are available if {props.productName} is unable to connect to one of the
                machines listed. Only one hostname or IP address entry is allowed in this field.
              </li>
              <li>
                <b>Port</b>
                <br />
                Port used to connect to the LDAP service on the specified LDAP Server. Typically port 389 is used for
                regular LDAP and LDAP using the STARTTLS mode for privacy. Port 636 is assigned to the LDAPS service
                (LDAP over SSL/TLS).
              </li>
              <li>
                <b>Use SSL (LDAPS)</b>
                <br />
                By default, LDAP traffic is transmitted unsecured. When set to <b>Yes</b>, LDAP traffic is confidential
                and secure via Secure Sockets Layer (SSL)/Transport Layer Security (TLS) technology.
              </li>
              <li>
                <b>Bind DN/Username</b>
                <br />
                Username used to connect to the LDAP service on the specified LDAP Server. If in the form{' '}
                <i>accountname@domain.com</i>, the username is transformed into a proper LDAP bind DN like{' '}
                <i>CN=accountname,CN=users,DC=domain,DC=com</i> when accessing the LDAP server.
              </li>
              <li>
                <b>Password</b>
                <br />
                Password used to connect to the LDAP service on the specified LDAP Server.
              </li>
              <li>
                <b>Base DN</b>
                <br />
                Base DN for your directory. This is the starting search point in the LDAP tree. The default value looks
                up the &apos;defaultNamingContext&apos; top-level attribute and uses it as the search base. For example,
                if your domain is test.com, your Base DN might be dc=test,dc=com.
              </li>
              <li>
                <b>Mail Attributes</b>
                <br />
                Attribute in your LDAP directory that contains the user&apos;s email address. Note that the attributes
                listed in this field determine which user address is primary versus aliases for a user account. By
                default, the mail attribute is listed first. Take caution changing the order of the attribute as you may
                encounter unexpected results. See{' '}
                <a href="https://campus.barracuda.com/doc/96022971/" target="_new">
                  Barracuda Campus
                </a>{' '}
                for details.
              </li>
            </ul>
            <li>
              <b>Test LDAP Configuration Settings</b>{' '}
            </li>
            <ul>
              <li>
                <b>Testing Email Address</b>
                <br />
                Enter a valid primary email address (do not use an alias address). If blank, testing is limited to
                connection. Click <b>Test Settings</b> to test LDAP configuration settings.
              </li>
            </ul>
            <li>
              <b>Advanced LDAP Configuration: Directory Options</b>{' '}
            </li>
            <ul>
              <li>
                <b>Synchronize Automatically</b>
                <br />
                Set to <b>Yes</b> if you are using LDAP and want {props.productName} to automatically synchronize your
                LDAP users to its database on a regular basis for recipient verification. With Microsoft Exchange
                Server, the synchronization is incremental. Select <b>No</b> if you want to synchronize manually in case
                your LDAP server is not always available. To synchronize manually, click <b>Synchronize Now</b>.
              </li>
              <li>
                <b>Use LDAP for Authentication</b>
                <br />
                Set to <b>Yes</b> to enable LDAP for user login authentication. You can disable if your LDAP server will
                be unavailable for a period of time. If you do not enable LDAP Authentication, the first time an
                incoming email for a valid user is quarantined, the user is sent an email with a login and password so
                that they can access their quarantine inbox.
              </li>
              <ul>
                <li>
                  <b>Authentication Filter</b>
                  <br />
                  Specify the LDAP filtering string for email user authentication. The filter consists of a series of
                  attributes that might contain the email address. If the email address is found in any of those
                  attributes, then the account holder is allowed to log into {props.productName} if the provided LDAP
                  password is correct.
                  <br />
                  See LDAP query syntax and examples here:{' '}
                  <a href="https://technet.microsoft.com/en-us/library/aa996205(v=exchg.65).aspx" target="_new">
                    LDAP Query Basics
                  </a>
                  .
                </li>
              </ul>
            </ul>
          </ul>
          <br />
          <b>Type: Azure AD</b>
          <ul>
            <li>
              <b>Authorize</b>
              <br />
              Click <b>Authorize</b> to begin the setup process. To complete authorization, you must have Office 365
              admin credentials. See{' '}
              <a href="https://campus.barracuda.com/doc/96022972/" target="_new">
                Barracuda Campus
              </a>{' '}
              for step-by-step instructions.
            </li>
            <li>
              <b>Synchronize Options</b>
            </li>
            <ul>
              <li>
                <b>Synchronize Automatically</b>
                <br />
                {props.productName} automatically synchronizes with your Azure AD directory every 15 minutes and
                adds/updates your users. If you encounter sync issues, such as new users not being properly synced
                between your Azure AD directory and the {props.productName} user list, click <b>Sync Now</b> to manually
                synchronize {props.productName} with your Azure AD directory.
              </li>
              <li>
                <b>Manual</b>
                <br />
                {props.productName} does not synchronize with your Azure AD directory until you click <b>Sync Now</b>.{' '}
                <b>Sync now</b> will do a full synchronization with your Azure AD directory.
              </li>
            </ul>
            <li>
              <b>Enable Single Sign-On</b>
              configure Single Sign-On (SSO) for the domain so that authenticated users can access all or a subset of
              the restricted resources by authenticating just once.
            </li>
            <ul>
              <li>
                <b>Yes</b>
                <br />
                When selected, SSO is enabled.
              </li>
              <li>
                <b>No</b>
                <br />
                When selected, SSO is disabled.
              </li>
            </ul>
            <li>
              <b>Test Azure AD Configuration Settings</b>{' '}
            </li>
            <ul>
              <li>
                <b>Testing Email Address</b>
                <br />
                Enter a valid primary email address (do not use an alias address). Click <b>Test Settings</b> to test
                Azure AD configuration settings.
              </li>
            </ul>
          </ul>
          <br />
          <h3 id="adv_config">Advanced Configurations</h3>
          <ul>
            <li>
              <b>Sender Rewriting Scheme (SRS)</b>
              <br />
              When set to <b>Yes</b>, {props.productName} rewrites the Envelope FROM address of inbound messages so that
              they appear to come from Barracuda Networks instead of from the original sender. This is useful if you are
              using a hosted email service that cannot or will not turn off Sender Policy Framework (SPF) checking. For
              more information on SPF, visit{' '}
              <a href="http://www.openspf.org/Introduction" target="_new">
                openspf.org
              </a>
              .
            </li>
          </ul>
        </>
      )}
    </div>
  )
}

export default DomainSettings
/* eslint-enable jsx-a11y/alt-text */
