import { useMemo } from 'react'

import useUserRights, { UserRole } from 'components/libs/userRights/useUserRights'
import appConfig from 'config/appConfig'

export interface ValidatedRights {
  canViewPhaseOneFeature: boolean
  canViewPhaseTwoFeature: boolean
}

export type UseUiSwitcherRights = ValidatedRights

export enum UserRights {
  IS_ACCOUNT_ADMIN = UserRole.ACCOUNT_USER
}
export const useUiSwitcherRights = (): UseUiSwitcherRights => {
  const { validatedUserRights, isImpersonatedUser, userFeatures, isPdDomainIdSet } = useUserRights([
    UserRights.IS_ACCOUNT_ADMIN
  ])

  const [isAccountAdmin] = validatedUserRights

  const canViewPhaseOneFeature = useMemo(
    () =>
      appConfig.APP.IS_ADMIN &&
      isAccountAdmin &&
      !isPdDomainIdSet &&
      !isImpersonatedUser &&
      !!userFeatures?.optInEnabled &&
      !userFeatures.optOutEnabled,
    [isAccountAdmin, isImpersonatedUser, userFeatures, isPdDomainIdSet]
  )

  const canViewPhaseTwoFeature = useMemo(
    () =>
      appConfig.APP.IS_ADMIN &&
      isAccountAdmin &&
      !isPdDomainIdSet &&
      !isImpersonatedUser &&
      !!userFeatures?.optOutEnabled,
    [isAccountAdmin, isImpersonatedUser, userFeatures, isPdDomainIdSet]
  )

  return useMemo(
    () => ({
      canViewPhaseOneFeature,
      canViewPhaseTwoFeature
    }),
    [canViewPhaseOneFeature, canViewPhaseTwoFeature]
  )
}
