import React, { ChangeEvent, FC, MutableRefObject, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { AtpExemptEmail, AtpExemptionType } from 'types/atpSettings'
import { useSettingTableCellRadios } from 'components/pages/atpSettings/useSettingsTableCellRadio'
import { useSettingsTableActions } from 'components/pages/atpSettings/useSettingsTableActions'

const initialSort = [{ field: 'email', dir: 'asc' } as SortDescriptor]

const BLANK_ATP_EXEMPT_EMAIL = { email: '', type: AtpExemptionType.SENDER, comment: '', id: '' }

export interface AtpExemptEmailsTableConfig {
  data: AtpExemptEmail[]
  insertEmailInData: (email: AtpExemptEmail) => boolean
  removeEmailFromData: (id: string) => void
}

export interface AtpExemptEmailsTable {
  newEmail: {
    email: MutableRefObject<HTMLInputElement | null>
    comment: MutableRefObject<HTMLInputElement | null>
    type: AtpExemptionType
    id: string
  }
  onNewEmailChange: (e: ChangeEvent<HTMLInputElement>) => void
  newEmailType: AtpExemptionType
  newEmailTypeRadios: Map<AtpExemptionType, ReactNode>
  onAddEmail: () => void
  onDeleteEmail: (id: string) => void
  ConfirmDeleteEmailDialog: FC
  tableData: {
    data: AtpExemptEmail[]
    total: number
  }
  handleSortChange: (e: GridSortChangeEvent) => void
  sort: SortDescriptor[]
  hasChanges: boolean
}

export const useAtpExemptEmailsTable = ({
  data,
  insertEmailInData,
  removeEmailFromData
}: AtpExemptEmailsTableConfig): AtpExemptEmailsTable => {
  const newEmailEmailRef = useRef<HTMLInputElement | null>(null)
  const newEmailTypeRef = useRef<AtpExemptionType>(AtpExemptionType.SENDER)
  const newEmailCommentRef = useRef<HTMLInputElement | null>(null)
  const [sort, setSort] = useState(initialSort)
  const [hasChanges, setHasChanges] = useState(false)

  const onAddAtpExemptEmail = useCallback(() => {
    if (newEmailEmailRef.current && newEmailCommentRef.current && newEmailTypeRef.current) {
      const inserted = insertEmailInData({
        email: newEmailEmailRef.current?.value || '',
        comment: newEmailCommentRef.current?.value || '',
        type: newEmailTypeRef.current,
        id: MD5(makeUuid()).toString()
      })

      if (inserted) {
        newEmailEmailRef.current.value = ''
        newEmailCommentRef.current.value = ''
        newEmailTypeRef.current = AtpExemptionType.SENDER
      }
    }
  }, [insertEmailInData])

  const onDeleteAtpExemptEmail = useCallback(
    (id: string) => {
      removeEmailFromData(id)
    },
    [removeEmailFromData]
  )

  const onNewEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (newEmailEmailRef.current && newEmailCommentRef.current) {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === 'email':
          newEmailEmailRef.current.value = value
          break
        case name === 'comment':
          newEmailCommentRef.current.value = value
          break
      }
      setHasChanges(true)
    }
  }, [])

  const onClickNewEmailType = useCallback((type: AtpExemptionType) => {
    newEmailTypeRef.current = type
    setHasChanges(true)
  }, [])

  const newEmailTypeRadios = useSettingTableCellRadios<AtpExemptionType>(
    [AtpExemptionType.RECIPIENT, AtpExemptionType.SENDER],
    onClickNewEmailType,
    newEmailTypeRef.current
  )

  const tableData = useMemo(() => {
    const orderedData = orderBy(data, sort)
    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_ATP_EXEMPT_EMAIL)
    return {
      data: processedData as AtpExemptEmail[],
      total: processedData.length || 0
    }
  }, [data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const actions = useSettingsTableActions({
    addItem: onAddAtpExemptEmail,
    deleteItem: onDeleteAtpExemptEmail
  })

  return useMemo(
    () => ({
      newEmail: {
        email: newEmailEmailRef,
        comment: newEmailCommentRef,
        type: newEmailTypeRef.current,
        id: ''
      },
      onNewEmailChange,
      newEmailType: newEmailTypeRef.current,
      newEmailTypeRadios,
      onAddEmail: actions.onAdd,
      onDeleteEmail: actions.onDelete,
      ConfirmDeleteEmailDialog: actions.ConfirmDialog,
      tableData,
      handleSortChange,
      sort,
      hasChanges
    }),
    [actions, newEmailTypeRadios, onNewEmailChange, tableData, handleSortChange, sort, hasChanges]
  )
}
