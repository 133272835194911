import { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getUnaliasedDomainNames } from 'redux/features/domains/domainsSlice'
import { isSuccess, isPending, isFailed } from 'redux/toolkit/api'
import { DomainNameById } from 'types/domains'
import { EditDomainForm, EMPTY_DOMAIN_ALIAS_OF_VALUE } from 'components/pages/domains/editDomain/useEditDomainForm'

export enum SelectStatus {
  LOADING,
  READY,
  ERROR
}

export interface State {
  aliasOf: string
  selectState: {
    status: SelectStatus
    label: string
    helperText: string
  }
  availableDomainNames: DomainNameById[]
  isGetUnaliasedDomainNamesLoading: boolean
  isGetUnaliasedDomainNamesSuccess: boolean
  inProgress: boolean
}

export interface EventHandlers {
  onChangeAliasOf: (w: ChangeEvent<HTMLInputElement>) => void
}

export type EditDomainAliasLogic = [State, EventHandlers]

export const useEditDomainAliasLogic = (form: EditDomainForm): EditDomainAliasLogic => {
  const dispatch = useAppDispatch()
  const {
    domainAliasOf,
    unaliasedDomainNames,
    isGetUnaliasedDomainNamesLoading,
    isGetUnaliasedDomainNamesSuccess,
    isGetUnaliasedDomainNamesFailed,
    isGetDomainPending,
    isSaveDomainSettingsPending
  } = useAppSelector(_store => ({
    domainAliasOf: _store.domains.domain?.aliasOf,
    unaliasedDomainNames: _store.domains.unaliasedDomainNames || [],
    isGetUnaliasedDomainNamesLoading: isPending(_store.domains.api.getUnaliasedDomainNamesApiStatus),
    isGetUnaliasedDomainNamesSuccess: isSuccess(_store.domains.api.getUnaliasedDomainNamesApiStatus),
    isGetUnaliasedDomainNamesFailed: isFailed(_store.domains.api.getUnaliasedDomainNamesApiStatus),
    isGetDomainPending: isPending(_store.domains.api.getDomainApiStatus),
    isSaveDomainSettingsPending: isPending(_store.domains.api.saveDomainSettingsApiStatus)
  }))
  const [{ domainId, aliasOf }] = form

  const onChangeAliasOf = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      aliasOf.setValue(e.target.value)
    },
    [aliasOf]
  )

  const selectState = useMemo((): State['selectState'] => {
    switch (true) {
      case isGetUnaliasedDomainNamesLoading:
      case isGetUnaliasedDomainNamesSuccess && typeof domainAliasOf === 'undefined':
        return { status: SelectStatus.LOADING, label: '', helperText: 'domain_select_loading' }
      case isGetUnaliasedDomainNamesSuccess && typeof domainAliasOf !== 'undefined':
        return { status: SelectStatus.READY, label: 'domain_select_empty_value', helperText: 'domain_select_helper' }
      case isGetUnaliasedDomainNamesFailed:
      default:
        return {
          status: SelectStatus.ERROR,
          label: '',
          helperText: 'domain_select_error'
        }
    }
  }, [
    domainAliasOf,
    isGetUnaliasedDomainNamesFailed,
    isGetUnaliasedDomainNamesLoading,
    isGetUnaliasedDomainNamesSuccess
  ])

  const aliasOfValue = useMemo(() => {
    switch (true) {
      case selectState.status === SelectStatus.ERROR:
        return ''
      case selectState.status === SelectStatus.READY:
        return aliasOf.value || EMPTY_DOMAIN_ALIAS_OF_VALUE
      default:
        return EMPTY_DOMAIN_ALIAS_OF_VALUE
    }
  }, [aliasOf.value, selectState.status])

  const inProgress = useMemo(
    () => isGetDomainPending || isSaveDomainSettingsPending || isGetUnaliasedDomainNamesLoading,
    [isGetDomainPending, isGetUnaliasedDomainNamesLoading, isSaveDomainSettingsPending]
  )

  const availableDomainNames = useMemo(
    () => unaliasedDomainNames.filter(d => d.domainId.toString() !== domainId),
    [domainId, unaliasedDomainNames]
  )

  useEffect(() => {
    dispatch(getUnaliasedDomainNames())
  }, [dispatch])

  useEffect(() => {
    if (typeof domainAliasOf !== 'undefined') {
      aliasOf.reset(domainAliasOf || EMPTY_DOMAIN_ALIAS_OF_VALUE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainAliasOf, aliasOf.reset])

  return useMemo(
    () => [
      {
        aliasOf: aliasOfValue,
        selectState,
        availableDomainNames,
        isGetUnaliasedDomainNamesLoading,
        isGetUnaliasedDomainNamesSuccess,
        inProgress
      },
      {
        onChangeAliasOf
      }
    ],
    [
      aliasOfValue,
      selectState,
      availableDomainNames,
      isGetUnaliasedDomainNamesLoading,
      isGetUnaliasedDomainNamesSuccess,
      inProgress,
      onChangeAliasOf
    ]
  )
}
