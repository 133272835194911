import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SelfService4(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      If you are using Microsoft Office 365 and want to route your outbound mail through {props.productName}, select
      that option. If you click the <b>Click here</b> link for instructions, Barracuda Campus will guide you through
      Office 365 configuration to work with {props.productName}.
      <p>
        If you only want to route your inbound mail through {props.productName} and NOT your outbound mail, select the
        2nd option on the page. You can change this routing later.
      </p>
    </div>
  )
}

export default SelfService4
