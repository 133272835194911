import { useEffect, useMemo, useState } from 'react'

import { useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isSuccess } from 'redux/toolkit/api'
import { bccLogin } from 'redux/features/auth/authSlice'
import { gotoAdminEntryPathFromWizard, gotoBccLogin } from 'lib/routesConfig'
import appConfig from 'config/appConfig'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'

export type bccLoginFailedMessage = number | string

export const useBccLoginLogic = (): [boolean, bccLoginFailedMessage?] => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { isBccLoginSuccess, bccLoginFailedMessage } = useAppSelector(_store => ({
    isBccLoginSuccess: isSuccess(_store.auth.loginApiStatus),
    bccLoginFailedMessage: getErrorMessage(_store.auth.loginApiStatus)
  }))
  const { appTypeEntryPath } = useAppTypeEntryPaths()
  const [error, setError] = useState(false)

  // init
  useEffect(() => {
    const migrateToken = searchParams.get(appConfig.QUERY_PARAMS.MIGRATE_TOKEN)
    const errorQueryParam = searchParams.get('error')

    if (!errorQueryParam) {
      dispatch(bccLogin(migrateToken || undefined))
    } else {
      setError(true)
    }
    // eslint-disable-next-line
  }, [])

  // successFully logged in
  useEffect(() => {
    if (isBccLoginSuccess) appTypeEntryPath.goto()
  }, [isBccLoginSuccess, appTypeEntryPath])

  // failed to login
  useEffect(() => {
    if (!!bccLoginFailedMessage && appConfig.APP.IS_WIZARD) gotoAdminEntryPathFromWizard()
    if (Number(bccLoginFailedMessage) === 401) gotoBccLogin()
  }, [bccLoginFailedMessage])

  return useMemo(() => [error, bccLoginFailedMessage], [error, bccLoginFailedMessage])
}
