import React, { Dispatch, SetStateAction, useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'

import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { useGeoPoliciesSenderNameExemptLogic } from 'components/pages/inboundSettings/regionalPolicies/useGeoPoliciesSenderNameExemptLogic'
import DomainTable from 'components/libs/domainTable/DomainTable'

const BASE_I18N_KEY = 'ess.inbound_settings.regional_policies'

export interface GeoPoliciesSenderNameExemptProps {
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

const GeoPoliciesSenderNameExempt = ({ delegateDirtyFormState }: GeoPoliciesSenderNameExemptProps) => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useGeoPoliciesSenderNameExemptLogic()
  return useMemo(
    () => (
      <Grid container>
        <Grid container className={inboundClasses.spaceGrid}>
          <Typography variant="h4">{formatMessage('geo_policies_sender_exmpt_name')}</Typography>
          <Button
            className={inboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button-geoip-sender-exempt"
          >
            Bulk Edit
          </Button>
        </Grid>
        <DomainTable
          data={state.tableData}
          onAddItem={entry => eventHandlers.onAddItem(entry)}
          onRemoveItem={id => eventHandlers.onRemoveItem(id)}
          shouldHandleEmail
          data-testid="geoip-sender-exempt-table"
          delegateDirtyFormState={delegateDirtyFormState}
        />
      </Grid>
    ),
    [inboundClasses, formatMessage, eventHandlers, state, delegateDirtyFormState]
  )
}
export default GeoPoliciesSenderNameExempt
