import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setAppBlockedState } from 'redux/features/app/appSlice'
import appConfig from 'config/appConfig'
import appFeatures from 'config/appFeatures'
import { gotoLegacyLogin, gotoLegacyMessageLog } from 'lib/routesConfig'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { resetLoginSettings } from 'redux/features/auth/authSlice'

export type UseGlobalMessagesBannerLogic = {
  dialogType?: GlobalDialogTypes
  onConfirm: () => void
}

export enum GlobalDialogTypes {
  not_supported_role_type = 'not_supported_role_type',
  invalid_access = 'invalid_access'
}

export const useGlobalDialogLogic = (): [UseGlobalMessagesBannerLogic] => {
  const dispatch = useAppDispatch()
  const { loginSettings, accessTokenObject, userStates, isEnhancedAuthenticationEnabled } = useAppSelector(_store => ({
    loginSettings: _store.auth.loginSettings,
    accessTokenObject: _store.auth.accessTokenObject,
    userStates: _store.auth.userStates,
    isEnhancedAuthenticationEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled
  }))
  const [forceEnabledUserRoleValidation, setForceEnabledUserRoleValidation] = useState<boolean>(false)
  const { appTypeLoginEntryPath } = useAppTypeEntryPaths()

  useEffect(() => {
    if (appFeatures.EnabledForAllUserRoles) {
      // eslint-disable-next-line
      window.__egd__.forceEnabledUserRoleValidation = () => setForceEnabledUserRoleValidation(true)
    }
  }, [])

  const showNonRolledOutUserRoleDialog = useMemo(() => {
    if (isEnhancedAuthenticationEnabled) {
      return false
    }
    return (
      appConfig.APP.IS_ENDUSER &&
      (!appFeatures.EnabledForAllUserRoles || forceEnabledUserRoleValidation) &&
      ((!!loginSettings && !loginSettings.isRolledOutUserRole) ||
        (accessTokenObject && !accessTokenObject.userStates?.isRolledOutUserRole))
    )
  }, [isEnhancedAuthenticationEnabled, loginSettings, accessTokenObject, forceEnabledUserRoleValidation])

  const showInvalidAccessDialog = useMemo(() => {
    if (isEnhancedAuthenticationEnabled) {
      return false
    }
    return (
      appConfig.APP.IS_ENDUSER &&
      ((!!loginSettings && !loginSettings.isRolledOutOktaUser && !!loginSettings.auth0Params?.connection) ||
        (userStates && !userStates.isRolledOutOktaUser))
    )
  }, [isEnhancedAuthenticationEnabled, userStates, loginSettings])

  const dialogType = useMemo(() => {
    switch (true) {
      case showInvalidAccessDialog:
        return GlobalDialogTypes.invalid_access
      case showNonRolledOutUserRoleDialog:
        return GlobalDialogTypes.not_supported_role_type
      default:
        return undefined
    }
  }, [showNonRolledOutUserRoleDialog, showInvalidAccessDialog])

  const onUnderstandNonRolledOutUserRoleDialog = useCallback(() => {
    if (dialogType === GlobalDialogTypes.invalid_access) {
      dispatch(resetLoginSettings())
      appTypeLoginEntryPath.goto()
    } else if (!accessTokenObject && loginSettings) {
      gotoLegacyLogin(loginSettings.userId)
    } else {
      gotoLegacyMessageLog()
    }
  }, [loginSettings, accessTokenObject, appTypeLoginEntryPath, dialogType, dispatch])

  const onConfirm = useCallback(() => {
    switch (dialogType) {
      case GlobalDialogTypes.not_supported_role_type:
      case GlobalDialogTypes.invalid_access:
        onUnderstandNonRolledOutUserRoleDialog()
        break
      default:
    }
  }, [onUnderstandNonRolledOutUserRoleDialog, dialogType])

  useEffect(() => {
    dispatch(setAppBlockedState(!!dialogType))
  }, [dispatch, dialogType])

  return useMemo(
    () => [
      {
        dialogType,
        onConfirm
      }
    ],
    [dialogType, onConfirm]
  )
}
