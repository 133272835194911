const DASHBOARD = 'Salpicadero'
const ALL = 'Todos'

/* eslint-disable quotes */
const overview = {
  tabs: {
    dashboard: DASHBOARD,
    message_log: 'Mensaje Registro',
    atp_log: 'ATP registro',
    outbound_quarantine: 'Cuarentena de Salida',
    audit_log: 'Registro de Auditoría',
    review_license: 'Licencias de Revisión',
    domains: 'Dominios'
  },
  dashboard: {
    title: DASHBOARD,
    select_domain: 'Seleccionar dominio',
    all: ALL,
    time: 'Tiempo',
    last_24_hours: 'Últimas 24 horas',
    last_30_days: 'Últimos 30 días',
    threat_origins: 'Origen de las amenazas',
    inbound_email_statistics: 'Estadísticas del correo electrónico entrante',
    outbound_email_statistics: 'Estadísticas de correo electrónico saliente',
    total_threat_viruses: 'Total Amenazas / Virus',
    threats_viruses_detected: 'Amenazas / virus detectados',
    dropdown_table: {
      top_recipient_domains: 'Dominios más visitados',
      top_sender_domains: 'Principales dominios remitentes',
      no_data_found: 'No se han encontrado datos',
      inbound: 'Entrada',
      outbound: 'Salida',
      atp: 'ATP',
      none: 'Volumen',
      volume: 'Volumen',
      blocked: 'Bloqueado',
      allowed: 'Permitido',
      top_recipients_blocked: 'Principales destinatarios bloqueados',
      top_senders_blocked: 'Principales remitentes bloqueados',
      last_blocked: 'Último bloqueado',
      selected_value: {
        none: 'Volumen',
        blocked: 'Bloqueado',
        allowed: 'Permitido',
        recipients: 'Principales destinatarios bloqueados',
        senders: 'Principales remitentes bloqueados',
        atp: 'ATP'
      },
      table: {
        RANK: 'Rango',
        DOMAIN: 'Dominio',
        VOLUME: 'Volumen',
        BLOCKED: 'Bloqueado',
        RECIPIENTS: 'Destinatarios',
        SENDERS: 'Remitentes',
        FILE_NAME: 'Archivo',
        FILE_TYPE: 'Tipo de archivo',
        LAST_SEEN: 'Visto por última vez'
      }
    },
    charts: {
      overview: 'Visión general',
      allowed: 'Permitido',
      file_type: 'Tipo de archivo',
      total_blocked: 'Total bloqueado',
      advanced_threats: 'Amenazas avanzadas',
      sent: 'Enviado',
      encrypted: 'Cifrado',
      total_sent: 'Total enviado',
      total: 'Total',
      virus: 'Virus',
      viruses: 'Viruses',
      atp: 'ATP',
      brbl: 'BRBL',
      spam: 'Spam',
      brts: 'BRTS',
      quarantined: 'En cuarentena',
      blocked: 'Bloqueado',
      blocked_bbl: 'BRBL',
      blocked_spam: 'Spam',
      blocked_brts: 'BRTS',
      blocked_av: 'Virus',
      office: 'Ms Office',
      category: 'Categoría',
      percentage: 'Porcentaje'
    },
    footer: {
      account_id: 'ID de cuenta',
      serial_number: 'Número de serie',
      version: 'Versión'
    }
  }
}
/* eslint-enable quotes */

export default overview
