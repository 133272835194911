import React, { ChangeEvent, useMemo } from 'react'
import { Grid, Radio, RadioGroup, RadioLabel, Typography } from '@barracuda-internal/bds-core'

import { GridSize } from '@material-ui/core'
import styles from 'components/libs/settings/settingsInputStyles'

export interface RadioOptions {
  label: string
  value: string
}

interface SettingsRowRadioProps {
  disabled?: boolean
  label?: string
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: RadioOptions[]
  value: string | Record<string, any> | null | undefined
  title?: string
  disablePositioning?: boolean
  indent?: boolean
  labelColumnSize?: number
  radioDirection?: 'row' | 'column'
}

const SettingsRowRadio: React.FC<SettingsRowRadioProps> = ({
  disabled,
  label,
  name,
  onChange,
  options,
  value,
  title,
  disablePositioning,
  indent,
  labelColumnSize,
  radioDirection
}: SettingsRowRadioProps) => {
  const classes = styles({ disabled, disablePositioning, indent })

  const labelSize = labelColumnSize || 3
  const contentSize = 12 - labelSize

  return useMemo(
    () => (
      <Grid container direction="row" className={classes.row}>
        <Grid item xs={labelSize as GridSize}>
          <Typography variant="body2" className={classes.label} data-testid={`label:${label}`}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={contentSize as GridSize}>
          <Typography variant="body2" data-testid={`title:${title}`}>
            {title}
          </Typography>
          <RadioGroup name={name} row={radioDirection !== 'column'} value={value} onChange={onChange}>
            {options.map(option => (
              <RadioLabel
                label={<Typography variant="body2">{option.label}</Typography>}
                value={option.value}
                control={<Radio color="primary" size="small" />}
                data-testid={`radio_option:${name}:${option.value}`}
                key={option.value}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    ),
    [classes, label, title, name, onChange, options, value, contentSize, labelSize, radioDirection]
  )
}

export default SettingsRowRadio
