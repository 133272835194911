import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function DomainVerification(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        Each of the domains where you want to filter email must be verified by {props.productName} for proof of
        ownership; {props.productName} does not process email for a domain until the verification process is complete.
        <br />
        <br />
      </p>
      {props.hasCpl && (
        <>
          To verify ownership, you must first connect your Barracuda Email Security Gateway to Barracuda Cloud Control
          from the <b>ADVANCED &gt; Cloud Control</b> page in the Barracuda Email Security Gateway web interface. Point
          the MX record for the domain to this same Barracuda Email Security Gateway by modifying your DNS server. For
          more information, see{' '}
          <a href="https://campus.barracuda.com/doc/3866715/" target="_new">
            How to Set Up Your Cloud Protection Layer
          </a>{' '}
          in Barracuda Campus.
        </>
      )}
      {!props.hasCpl && (
        <>
          Click the <b>Verify Ownership</b> link in the <b>Domains Name</b> column to go to the{' '}
          <b>Domains &gt; Domain Settings</b> page to complete the verification process using one of the following
          methods:
          <ul>
            <li>
              <b>MX Records</b>
              <br />
              Add one or both of the Barracuda Networks Mail Exchange (MX) records to your domain&apos;s DNS server.
              Assign these records a higher priority than your domain&apos;s existing MX records to ensure your mail
              continues to flow until your {props.productName} domain is validated. Note that it can take up to 24 hours
              for the {props.productName} DNS server to recognize this change in your MX records. Once your domain is
              validated, you can remove the old MX records, leaving just the Barracuda Networks MX records. Your mail is
              now sent to {props.productName} for scanning.
              <p>
                To view the MX record configuration or mail statistics for a verified domain, go to the <b>Domains</b>{' '}
                page, and click the domain name in the <b>Domain Name</b> column to toggle the MX record configuration
                details.
              </p>
            </li>
            <li>
              <b>CNAME Records</b>
              <br />
              Note: You must have access to your DNS server to use this verification method.
            </li>
            <ol>
              <li>
                Log into your DNS Server and, under this domain, create a subdomain whose name is created by
                concatenating &apos;barracuda&apos; and the CNAME token. For example:{' '}
                <code>barracuda30929916985.mydomain.com</code>
              </li>
              <li>
                Point the CNAME record of that subdomain to: <code>ess.barracuda.com</code>
                <br />
                Note: Allow the DNS propagation to take effect before proceeding.
              </li>
              <li>
                Click <b>Next</b> to complete the domain verification process.
                <br />
                Note: To view the validation status, click <b>Edit</b> in the <b>Settings</b> column on the{' '}
                <b>Domains</b> page for the selected domain. In the <b>Domain &gt; Domain Settings</b> page, scroll to
                the
                <b>Encryption</b> section to view the <b>Validation Status</b>.
              </li>
            </ol>
            <li>
              <b>Email to the domain&apos;s technical contact</b>
              <br />
              <b>Note:</b> This verification option is available only when {props.productName} can find your technical
              contact in your domain&apos;s WHOIS entry.
              <br />
              This method sends a verification email to the technical contact email address listed on your domain&apos;s
              WHOIS entry. The confirmation email includes a link that the recipient must click to verify the domain.
            </li>
            <li>
              <b>Email to the Postmaster</b>
              <br />
              <b>Note:</b> This verification option is available only when {props.productName} can find your postmaster
              in your domain&apos;s WHOIS records.
              <br />
              This method sends a verification email to the postmaster email address for your domain. The confirmation
              email includes a link that the recipient must click to verify the domain.
            </li>
          </ul>
        </>
      )}
    </div>
  )
}

export default DomainVerification
