import { useCallback, useEffect, useMemo } from 'react'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { isSettingValue, SettingValue } from 'types/Settings'
import { useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { State as AuthorizationState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdAuthorization'
import { State as LookupUserState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdLookupUser'
import { TenantAuthorizationStatus } from 'types/azureAd'

export interface State {
  autoSync: string
  enableSso: string
}

export interface EventHandlers {
  onChangeAutoSync: (value: string) => void
  onChangeEnableSso: (value: string) => void
  getIsOptionsDisabled: (authorizationState: AuthorizationState, lookupUserState: LookupUserState) => boolean
}

export type AzureAdOptions = [State, EventHandlers]

export const useAzureAdOptions = (form: EditDomainForm): AzureAdOptions => {
  const [{ autoSync, enableSso, inProgress, isAzureAdPollStatsInProgress }] = form
  const { isGetDomainSuccess, domain, isSyncNowPending } = useAppSelector(_store => ({
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
    domain: _store.domains.domain,
    isSyncNowPending: isPending(_store.azureAd.api.postAzureAdSyncNow)
  }))

  const getIsOptionsDisabled = useCallback(
    (authorizationState: AuthorizationState, lookupUserState: LookupUserState) =>
      authorizationState.tenantStatus !== TenantAuthorizationStatus.AUTHORIZED ||
      authorizationState.isDisconnectPending ||
      isAzureAdPollStatsInProgress.value ||
      isSyncNowPending ||
      lookupUserState.isLookupUserPending ||
      inProgress,
    [inProgress, isAzureAdPollStatsInProgress, isSyncNowPending]
  )

  const onChangeAutoSync = useCallback(
    (value: string) => {
      if (isSettingValue(value)) {
        autoSync.setValue(value as SettingValue)
      }
    },
    [autoSync]
  )

  const onChangeEnableSso = useCallback(
    (value: string) => {
      if (isSettingValue(value)) {
        enableSso.setValue(value as SettingValue)
      }
    },
    [enableSso]
  )

  const autoSyncValue = useMemo(() => {
    if (typeof autoSync.value === 'undefined') {
      return ''
    }
    return autoSync.value || SettingValue.DISABLED
  }, [autoSync.value])

  const enableSsoValue = useMemo(() => {
    if (typeof enableSso.value === 'undefined') {
      return ''
    }
    return enableSso.value || SettingValue.DISABLED
  }, [enableSso.value])

  useEffect(() => {
    if (isGetDomainSuccess) {
      autoSync.reset(domain?.azureAd ? domain.azureAd.autoSync : SettingValue.DISABLED)
      enableSso.reset(domain?.azureAd ? domain.azureAd.enableSso : SettingValue.DISABLED)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  return useMemo(
    () => [
      { autoSync: autoSyncValue, enableSso: enableSsoValue },
      { onChangeAutoSync, onChangeEnableSso, getIsOptionsDisabled }
    ],
    [autoSyncValue, enableSsoValue, onChangeAutoSync, onChangeEnableSso, getIsOptionsDisabled]
  )
}
