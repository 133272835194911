import React from 'react'

import { CircularProgress, Grid } from '@barracuda-internal/bds-core'
import styles from 'components/libs/overlayLoader/overlayLoaderStyles'

function OverlayLoader() {
  const classes = styles()

  return (
    <Grid className={classes.overlay} data-testid="content-overlay">
      <CircularProgress />
    </Grid>
  )
}

export default OverlayLoader
