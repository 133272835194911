import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function EndUserSenderPolicy(props: HelpComponentProps) {
  const classes = styles()

  return (
    <div className={classes.content} data-testid="content">
      <b>Note:</b> Allow list (<b>Exempt</b>) and block list (<b>Block</b>) policies are only available for selection
      from the <b>Policy</b> drop-down menu when enabled by your administrator.
      <br />
      <br />
      Sender Policy allows you to specify whether to always block, exempt, or quarantine email from a specific email
      address or domain.
      <br />
      <br />
      To create a new policy, enter the email address or domain in the <b>Sender</b> field, and use the drop-down menu
      to select the
      <b>Policy</b>. Optionally, you can enter a<b>Comment</b> to indicate why you created the policy. Click
      <b>Add</b> to create the policy.
      <br />
      <br />
      To delete a policy, click <b>Remove</b> in the <b>Actions</b> column.
    </div>
  )
}

export default EndUserSenderPolicy
