import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { setErrorSnackBar } from 'redux/features/app/appSlice'

import {
  BulkEditOutboundIpsRequest,
  SenderIpValidationError,
  GetOutboundIpsRequest,
  GetOutboundIpsResponse,
  RemoveOutboundIpsRequest,
  AddOutboundIpsRequest
} from 'types/SenderIp'

export const getOutboundIps = createAsyncThunk<GetOutboundIpsResponse, GetOutboundIpsRequest, ApiRejectResponse>(
  'OUTBOUND_IP/getOutboundIps',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_OUTBOUND_IPS, { params: { ...payload } })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const removeOutboundIp = createAsyncThunk<GetOutboundIpsResponse, RemoveOutboundIpsRequest, ApiRejectResponse>(
  'OUTBOUND_IP/removeOutboundIps',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.REMOVE_OUTBOUND_IPS, { data: { ...payload } })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const addOutboundIp = createAsyncThunk<
  GetOutboundIpsResponse,
  AddOutboundIpsRequest,
  ApiRejectResponse<SenderIpValidationError | string>
>('OUTBOUND_IP/addOutboundIps', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const resp = await restClient(apiRoutes.ADD_OUTBOUND_IPS, { data: { ...payload } })

    return resp.data
  } catch (e) {
    if (e.status === 409) {
      const { message, item } = JSON.parse(e.data.detail) as SenderIpValidationError
      const params = item && item.ip && item.netmask ? [item.ip, item.netmask] : undefined
      dispatch(setErrorSnackBar({ message, params }))
      return rejectWithValue(e.data.detail as SenderIpValidationError)
    }
    return rejectWithValue(validateApiError(e))
  }
})

export const bulkEditOutboundIps = createAsyncThunk<
  GetOutboundIpsResponse,
  BulkEditOutboundIpsRequest,
  ApiRejectResponse<SenderIpValidationError | string>
>('OUTBOUND_IP/bulkEditOutboundIps', async (payload, { rejectWithValue, dispatch }) => {
  try {
    const resp = await restClient(apiRoutes.BULK_EDIT_OUTBOUND_IPS, { data: { ...payload } })

    return resp.data
  } catch (e) {
    if (e.status === 409) {
      const { message, row, item } = JSON.parse(e.data.detail) as SenderIpValidationError
      const itemParams = item && item.ip && item.netmask ? [item.ip, item.netmask] : []
      dispatch(setErrorSnackBar({ message: `row_${message}`, params: [row, ...itemParams] }))
      return rejectWithValue(e.data.detail as SenderIpValidationError)
    }
    return rejectWithValue(validateApiError(e))
  }
})
