import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import { useEmailContinuityLogic } from 'components/pages/users/emailContinuity/useEmailContinuityLogic'
import {
  FormRow,
  FormSpacer,
  FormText,
  InlineRadioGroup,
  PageContainer,
  PageTitle
} from 'components/libs/layout/pages/pageLayout'

import routesConfig from 'lib/routesConfig'
import ConfirmDialog from 'components/pages/users/emailContinuity/confirmDialog/ConfirmDialog'
import { GROUP_TITLE, TOPICS, useSupportModalLogic } from 'components/pages/support/useSupportModalLogic'
import UsersEmailContinuitySupport from 'components/pages/support/help/usersEmailContinuity/UsersEmailContinuity'

const BASE_I18N_KEY = 'ess.users.email_continuity'

const EmailContinuity: React.FC = () => {
  const [state, eventHandlers] = useEmailContinuityLogic()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [onOpenSupport, HelpModal] = useSupportModalLogic({
    content: UsersEmailContinuitySupport,
    title: TOPICS.emailContinuity,
    groupTitle: GROUP_TITLE.userTopics
  })

  return useMemo(
    () => (
      <>
        <PageContainer
          isLoading={state.isLoading}
          isFailedToLoad={state.isFailedToLoad}
          dirtyCheck
          isDirtyForm={state.isDirtyForm}
        >
          <HelpModal />
          <PageTitle
            title={formatMessage('title')}
            onOpenSupport={onOpenSupport}
            onCancel={() => routesConfig.USERS_LIST.goto()}
            onSubmit={eventHandlers.onSaveChanges}
            submitInProgress={state.inProgress}
            isDisabledSubmit={!state.isDirtyForm}
          />
          <FormText>{formatMessage('information_text')}</FormText>
          <FormSpacer />
          <FormRow rowTitle={formatMessage('email_continuity')}>
            <InlineRadioGroup
              name="email_continuity"
              value={state.isEnableEmailContinuity}
              onChange={eventHandlers.setIsEnableEmailContinuity}
              disabled={state.inProgress}
              labels={[formatMessage('auto_enable'), formatMessage('off')]}
            />
          </FormRow>
          <FormSpacer />
        </PageContainer>
        {state.isConfirmDialogOpened && (
          <ConfirmDialog onConfirm={eventHandlers.onConfirm} onClose={eventHandlers.onCloseConfirmDialog} />
        )}
      </>
    ),
    [eventHandlers, formatMessage, state, onOpenSupport, HelpModal]
  )
}

export default EmailContinuity
