// Reason types that end user's are not allowed to deliver
export const stopDeliverReasons = [
  'atd_subscribed',
  'atd_full',
  'atd', // reason occurred when atp found a virus. "virus" attribute was later removed but end user shouldn't deliver atp msg anyway
  'atd_quarantine',
  'sender_disconnect',
  'sender_quit',
  'sender_rset',
  'batd_scan_pending'
]
