import { createSlice } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import {
  getLdapSyncStats,
  LdapSerialResetResponse,
  LdapSyncNowResponse,
  LdapSyncStatsResponse,
  postLdapSerialReset,
  postLdapSyncNow,
  postLdapTest
} from 'redux/features/ldap/ldapApiThunks'
import { LdapTestResult } from 'types/ldap'

export interface LdapState {
  api: {
    getLdapSyncStats: ApiStatus
    postLdapTest: ApiStatus
    postLdapSyncNow: ApiStatus
    postLdapSerialReset: ApiStatus
  }
  ldapSyncStats: LdapSyncStatsResponse | undefined
  ldapTest: LdapTestResult | undefined
  ldapSyncNow: LdapSyncNowResponse | undefined
  ldapSerialReset: LdapSerialResetResponse | undefined
}

// initialState
export const INITIAL_STATE: LdapState = {
  api: {
    getLdapSyncStats: inIdle,
    postLdapTest: inIdle,
    postLdapSyncNow: inIdle,
    postLdapSerialReset: inIdle
  },
  ldapSyncStats: undefined,
  ldapTest: undefined,
  ldapSyncNow: undefined,
  ldapSerialReset: undefined
}

/* eslint-disable no-param-reassign */
export const ldapSlice = createSlice({
  name: 'LDAP',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    }),
    resetSyncStats: state => {
      state.api.getLdapSyncStats = INITIAL_STATE.api.getLdapSyncStats
      state.ldapSyncStats = INITIAL_STATE.ldapSyncStats
    },
    resetTest: state => {
      state.api.postLdapTest = INITIAL_STATE.api.postLdapTest
      state.ldapTest = INITIAL_STATE.ldapTest
    },
    resetSyncNow: state => {
      state.api.postLdapSyncNow = INITIAL_STATE.api.postLdapSyncNow
      state.ldapSyncNow = INITIAL_STATE.ldapSyncNow
    },
    resetSerialReset: state => {
      state.api.postLdapSerialReset = INITIAL_STATE.api.postLdapSerialReset
      state.ldapSerialReset = INITIAL_STATE.ldapSerialReset
    }
  },
  extraReducers: builder => {
    builder
      // getLdapSyncStats
      .addCase(getLdapSyncStats.pending, state => {
        state.api.getLdapSyncStats = inProgress
      })
      .addCase(getLdapSyncStats.fulfilled, (state, action) => {
        state.api.getLdapSyncStats = successResponse
        state.ldapSyncStats = action.payload
      })
      .addCase(getLdapSyncStats.rejected, (state, action) => {
        state.api.getLdapSyncStats = failedResponse(action.payload)
      })

      // postLdapTest
      .addCase(postLdapTest.pending, state => {
        state.api.postLdapTest = inProgress
      })
      .addCase(postLdapTest.fulfilled, (state, action) => {
        state.api.postLdapTest = successResponse
        state.ldapTest = action.payload
      })
      .addCase(postLdapTest.rejected, (state, action) => {
        state.api.postLdapTest = failedResponse(action.payload)
      })

      // postLdapSyncNow
      .addCase(postLdapSyncNow.pending, state => {
        state.api.postLdapSyncNow = inProgress
      })
      .addCase(postLdapSyncNow.fulfilled, (state, action) => {
        state.api.postLdapSyncNow = successResponse
        state.ldapSyncNow = action.payload
      })
      .addCase(postLdapSyncNow.rejected, (state, action) => {
        state.api.postLdapSyncNow = failedResponse(action.payload)
      })

      // postLdapSerialReset
      .addCase(postLdapSerialReset.pending, state => {
        state.api.postLdapSerialReset = inProgress
      })
      .addCase(postLdapSerialReset.fulfilled, (state, action) => {
        state.api.postLdapSerialReset = successResponse
        state.ldapSerialReset = action.payload
      })
      .addCase(postLdapSerialReset.rejected, (state, action) => {
        state.api.postLdapSerialReset = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset, resetSyncStats, resetTest, resetSyncNow, resetSerialReset } = ldapSlice.actions

export { getLdapSyncStats, postLdapTest, postLdapSyncNow, postLdapSerialReset }

export default ldapSlice.reducer
