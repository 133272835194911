import { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import {
  EditDomainForm,
  EditDomainFormErrors,
  EMPTY_DOMAIN_ALIAS_OF_VALUE
} from 'components/pages/domains/editDomain/useEditDomainForm'
import { RcptToTarget, SpoolingValue } from 'types/domains'
import { isSettingValue, SettingValue } from 'types/Settings'
import { isSuccess } from 'redux/toolkit/api'
import { DomainsTabRights, useDomainsTabRights } from 'components/pages/domains/useDomainsTabRights'

export interface State {
  isOptionsVisible: boolean
  spooling: string
  userAutoAdd: string
  senderSpoof: string
  rcptToTarget: string
  spoolingYesValue: string
  inProgress: boolean
  errors: EditDomainFormErrors
  permissions: DomainsTabRights
}

export interface EventHandlers {
  onChangeSpooling: (value: string) => void
  onChangeUserAutoAdd: (value: string) => void
  onChangeSenderSpoof: (value: string) => void
  onChangeRcptToTarget: (value: string) => void
}

export type EditDomainAliasLogic = [State, EventHandlers]

export const useEditDomainOptionsLogic = (form: EditDomainForm): EditDomainAliasLogic => {
  const permissions = useDomainsTabRights()
  const { domain, isGetDomainSuccess } = useAppSelector(_store => ({
    domain: _store.domains.domain,
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus)
  }))
  const [{ aliasOf, spooling, userAutoAdd, senderSpoof, rcptToTarget, inProgress, errors }, { clearError }] = form
  const isOptionsVisible = useMemo(() => aliasOf.value === EMPTY_DOMAIN_ALIAS_OF_VALUE, [aliasOf.value])

  const onChangeSpooling = useCallback(
    (value: string) => {
      spooling.setValue(value)
    },
    [spooling]
  )

  const onChangeUserAutoAdd = useCallback(
    (value: string) => {
      if (isSettingValue(value)) {
        userAutoAdd.setValue(value)
        clearError('ldapSyncWithUserAutoAdd')
      }
    },
    [clearError, userAutoAdd]
  )

  const onChangeSenderSpoof = useCallback(
    (value: string) => {
      if (isSettingValue(value)) {
        senderSpoof.setValue(value)
      }
    },
    [senderSpoof]
  )

  const onChangeRcptToTarget = useCallback(
    (value: string) => {
      rcptToTarget.setValue(value as RcptToTarget)
    },
    [rcptToTarget]
  )

  const spoolingValue = useMemo(() => {
    if (typeof spooling.value === 'undefined') {
      return ''
    }
    return spooling.value ? `${spooling.value}` : SpoolingValue.NO
  }, [spooling.value])

  const userAutoAddValue = useMemo(() => {
    if (typeof userAutoAdd.value === 'undefined') {
      return ''
    }
    return userAutoAdd.value || SettingValue.DISABLED
  }, [userAutoAdd.value])

  const senderSpoofValue = useMemo(() => {
    if (typeof senderSpoof.value === 'undefined') {
      return ''
    }
    return senderSpoof.value || SettingValue.DISABLED
  }, [senderSpoof.value])

  const rcptToTargetValue = useMemo(() => {
    if (typeof rcptToTarget.value === 'undefined') {
      return ''
    }
    return rcptToTarget.value || RcptToTarget.NONE
  }, [rcptToTarget.value])

  useEffect(() => {
    if (isGetDomainSuccess) {
      spooling.reset(domain?.spooling ? domain?.spooling : SpoolingValue.NO)
      userAutoAdd.reset(domain?.userAutoAdd ? domain?.userAutoAdd : SettingValue.DISABLED)
      senderSpoof.reset(domain?.senderSpoof ? domain?.senderSpoof : SettingValue.DISABLED)
      rcptToTarget.reset(domain?.rcptToTarget ? domain?.rcptToTarget : RcptToTarget.NONE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  return useMemo(
    () => [
      {
        isOptionsVisible,
        spooling: spoolingValue,
        spoolingYesValue:
          domain?.spooling === SpoolingValue.NO ? SpoolingValue.YES : domain?.spooling || SpoolingValue.YES,
        userAutoAdd: userAutoAddValue,
        senderSpoof: senderSpoofValue,
        rcptToTarget: rcptToTargetValue,
        inProgress,
        errors,
        permissions
      },
      {
        onChangeSpooling,
        onChangeUserAutoAdd,
        onChangeSenderSpoof,
        onChangeRcptToTarget
      }
    ],
    [
      isOptionsVisible,
      spoolingValue,
      domain,
      userAutoAddValue,
      senderSpoofValue,
      rcptToTargetValue,
      inProgress,
      errors,
      permissions,
      onChangeSpooling,
      onChangeUserAutoAdd,
      onChangeSenderSpoof,
      onChangeRcptToTarget
    ]
  )
}
