import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  INBOUND_SETTINGS: {
    path: 'inbound-settings',
    legacyPath: 'settings',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_REPUTATION: {
    path: 'inbound-settings/reputation',
    legacyPath: 'settings/reputation',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_CUSTOM_RBLS: {
    path: 'inbound-settings/custom-rbls',
    legacyPath: 'settings/custom_rbls',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_CUSTOM_RBLS_BULK_EDIT: {
    path: 'inbound-settings/custom-rbls/bulk-edit',
    legacyPath: 'settings/bulk_edit/custom_rbls',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_RATE_CONTROL: {
    path: 'inbound-settings/rate-control',
    legacyPath: 'settings/rate_control',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_RATE_CONTROL_BULK_EDIT: {
    path: 'inbound-settings/rate-control/bulk-edit',
    legacyPath: 'settings/bulk_edit/rate_exemption',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_IP_POLICIES: {
    path: 'inbound-settings/ip-policies',
    legacyPath: 'settings/ip_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_IP_POLICIES_BULK_EDIT: {
    path: 'inbound-settings/ip-policies/bulk-edit',
    legacyPath: 'settings/bulk_edit/ip_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_TRUSTED_FORWARDERS_BULK_EDIT: {
    path: 'inbound-settings/trusted-forwarders/bulk-edit',
    legacyPath: 'settings/bulk_edit/trusted_forwarders',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_REGIONAL_POLICIES: {
    path: 'inbound-settings/regional-policies',
    legacyPath: 'settings/regional_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_GEOIP_POLICIES_BULK_EDIT: {
    path: 'inbound-settings/geoip-policies/bulk-edit',
    legacyPath: 'settings/bulk_edit/geoip_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_LANGUAGE_POLICIES_BULK_EDIT: {
    path: 'inbound-settings/language-policies/bulk-edit',
    legacyPath: 'settings/bulk_edit/language_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_RECIPIENT_POLICIES: {
    path: 'inbound-settings/recipient-policies',
    legacyPath: 'settings/recipient_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_RECIPIENT_POLICIES_BULK_EDIT: {
    path: 'inbound-settings/recipient-policies/bulk-edit',
    legacyPath: 'settings/bulk_edit/user_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SENDER_POLICIES: {
    path: 'inbound-settings/sender-policies',
    legacyPath: 'settings/sender_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SENDER_POLICIES_BULK_EDIT: {
    path: 'inbound-settings/sender-policies/bulk-edit',
    legacyPath: 'settings/bulk_edit/sender_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SENDER_AUTH: {
    path: 'inbound-settings/sender-auth',
    legacyPath: 'settings/sender_auth',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_DMARC_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/dmarc-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/dmarc_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_DKIM_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/dkim-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/dkim_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SPF_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/spf-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/spf_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SPF_DOMAIN_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/spf-domain-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/spf_domain_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_SPF_MISSING_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/spf-missing-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/spf_missing_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_PTR_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/ptr-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/ptr_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_CONTENT_POLICIES: {
    path: 'inbound-settings/content-policies',
    legacyPath: 'settings/content_policies',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT: {
    path: 'inbound-settings/attachment-filters/bulk-edit',
    legacyPath: 'settings/bulk_edit/attachment_filters',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT: {
    path: 'inbound-settings/message-content-filters/bulk-edit',
    legacyPath: 'settings/bulk_edit/message_content_filters',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_ANTI_PHISHING: {
    path: 'inbound-settings/anti-phishing',
    legacyPath: 'settings/anti_phishing',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_MACHINE_LEARNING: {
    path: 'inbound-settings/machine-learning',
    legacyPath: 'settings/machine_learning',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_INTENT_DOMAINS_BULK_EDIT: {
    path: 'inbound-settings/intent-domains/bulk-edit',
    legacyPath: 'settings/bulk_edit/intent_domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_GEOIP_IPADDRESS_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/geoip_exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/geoip_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_GEOIP_EMAIL_DOMAIN_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/geoip_sender_exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/geoip_sender_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  },
  INBOUND_SETTINGS_MACHINE_LEARNING_EXEMPT_BULK_EDIT: {
    path: 'inbound-settings/machine-learning-exempt/bulk-edit',
    legacyPath: 'settings/bulk_edit/inference_exempt',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId,
        AppTypes.cplAccount
      ]
    },
    metadata: {
      sideMenuId: 'INBOUND_SETTINGS'
    }
  }
}
