import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { useFormatMessage } from 'lib/localization'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'

export enum ReportsTabs {
  reportList = 'report_list',
  scheduledReports = 'scheduled_reports'
}

const TABS: [ReportsTabs, UiRoute][] = [
  [ReportsTabs.reportList, routesConfig.REPORT_LIST],
  [ReportsTabs.scheduledReports, routesConfig.SCHEDULED_REPORTS]
]

const BASE_I18N_KEY = 'ess.reports.tabs'

export const useReportsSubmenuItemsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { appType } = useAppSelector(_stores => ({
    appType: _stores.app.appType
  }))

  const isScheduledReportsOn = isMyFeatureOn(FEATURES.EGD_React_Scheduled_Reports)

  const reportsListItems: SubMenuItem[] = useMemo(() => {
    function filterTabs(tab: [ReportsTabs, UiRoute]) {
      const [tabName, route] = tab

      return !!route?.hasAppTypeAccess(appType) && (tabName !== ReportsTabs.scheduledReports || isScheduledReportsOn)
    }

    return TABS.filter(filterTabs).map(tab => {
      const [tabId, routeConfig] = tab
      return {
        name: formatMessage(tabId),
        path: routeConfig.path
      }
    })
  }, [formatMessage, appType, isScheduledReportsOn])

  return useMemo(() => [reportsListItems], [reportsListItems])
}
