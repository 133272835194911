import { makeStyles } from '@barracuda-internal/bds-core'
import pagesStyles from 'components/pages/pagesStyles'

const usersStyles = makeStyles(theme => {
  const CONTENT_PADDING = theme.spacing(3)

  return {
    selectorsWrapper: {
      zIndex: 1,
      marginTop: theme.spacing(2),
      paddingLeft: CONTENT_PADDING,
      paddingRight: CONTENT_PADDING
    }
  }
})

export default () => ({
  ...pagesStyles(),
  ...usersStyles()
})
