import { useMemo } from 'react'

import useUserRights from 'components/libs/userRights/useUserRights'

export interface ValidatedRights {
  canQuarantineSettings: boolean
  canEmailCategorization: boolean
  canSendBrtsEvidence: boolean
  canDisableAccountRateControl: boolean
  canChangeLanguagePolicies: boolean
  canChangeIntentRealtime: boolean
}

export type UseInboundSettingsRights = ValidatedRights

export const useInboundSettingsRights = (): UseInboundSettingsRights => {
  const { isCplAccount } = useUserRights([])

  const {
    canQuarantineSettings,
    canEmailCategorization,
    canDisableAccountRateControl,
    canChangeLanguagePolicies,
    canChangeIntentRealtime
  } = useMemo(
    () => ({
      canQuarantineSettings: !isCplAccount,
      canEmailCategorization: !isCplAccount,
      canDisableAccountRateControl: !isCplAccount,
      canChangeLanguagePolicies: !isCplAccount,
      canChangeIntentRealtime: isCplAccount
    }),
    [isCplAccount]
  )

  const canSendBrtsEvidence = useMemo(() => !window.location.hostname.startsWith('us'), [])

  return useMemo(
    () => ({
      canQuarantineSettings,
      canEmailCategorization,
      canSendBrtsEvidence,
      canDisableAccountRateControl,
      canChangeLanguagePolicies,
      canChangeIntentRealtime
    }),
    [
      canQuarantineSettings,
      canEmailCategorization,
      canSendBrtsEvidence,
      canDisableAccountRateControl,
      canChangeLanguagePolicies,
      canChangeIntentRealtime
    ]
  )
}
