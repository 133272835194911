import React, { useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'
import { HelpOutline } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { SettingsPageHeader } from 'types/SettingsPage'

import styles from 'components/pages/pagesStyles'
import SettingsActions from 'components/libs/settingsPage/SettingsActions'
import Help from 'components/pages/support/help/Help'
import { useSettingsHeaderLogic } from './useSettingsHeaderLogic'

function SettingsHeader({ title, help, actions }: SettingsPageHeader) {
  const classes = styles()
  const [state, eventHandlers] = useSettingsHeaderLogic()

  return useMemo(
    () => (
      <Grid className={classes.titleContainer} alignItems="center" container direction="row">
        <Grid xs item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Grid container direction="row">
                <Typography variant="h2" data-testid="title">
                  {title}
                </Typography>
                {help && (
                  <HelpOutline
                    data-testid="on-open-support"
                    onClick={eventHandlers.onHelpOpen}
                    className={classes.helpIcon}
                  />
                )}
              </Grid>
            </Grid>
            {actions && <SettingsActions {...actions} />}
          </Grid>
        </Grid>
        {help && state.isHelpOpen && (
          <Help
            modalComponentInfo={{
              content: help.content,
              onClose: eventHandlers.onHelpClose,
              title: help.title,
              groupTitle: help.group
            }}
          />
        )}
      </Grid>
    ),
    [classes, state, eventHandlers, title, help, actions]
  )
}

export default SettingsHeader
