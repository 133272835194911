import { makeStyles } from '@barracuda-internal/bds-core'

export interface StyleProps {
  nested: boolean
}

export default makeStyles(theme => ({
  label: {
    paddingLeft: ({ nested }: StyleProps) => (nested ? theme.spacing(2) : 0)
  }
}))
