import { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import { useAppSelector } from 'redux/toolkit/hooks'

export enum OutboundSettingsTabs {
  senderIp = 'sender_ip',
  tagline = 'tagline',
  encryption = 'encryption',
  contentPolicies = 'content_policies',
  notifications = 'notifications'
}

const TABS: [OutboundSettingsTabs, UiRoute][] = [
  [OutboundSettingsTabs.senderIp, routesConfig.OUTBOUND_SETTINGS_SENDER_IP],
  [OutboundSettingsTabs.tagline, routesConfig.OUTBOUND_SETTINGS_TAGLINE],
  [OutboundSettingsTabs.encryption, routesConfig.OUTBOUND_SETTINGS_ENCRYPTION],
  [OutboundSettingsTabs.contentPolicies, routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES],
  [OutboundSettingsTabs.notifications, routesConfig.OUTBOUND_SETTINGS_NOTIFICATIONS]
]

const BASE_I18N_KEY = 'ess.outbound_settings.tabs'

export const useOutboundSettingsSubmenuItemsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { appType } = useAppSelector(_stores => ({
    appType: _stores.app.appType
  }))

  const outboundSettingsListItems: SubMenuItem[] = useMemo(() => {
    // eslint-disable-next-line consistent-return
    function filterTabs(tab: [OutboundSettingsTabs, UiRoute]) {
      const [, route] = tab

      return !!route?.hasAppTypeAccess(appType)
    }

    return TABS.filter(filterTabs).map(tab => {
      const [tabId, routeConfig] = tab
      return {
        name: formatMessage(tabId),
        path: routeConfig.path
      }
    })
  }, [formatMessage, appType])

  return useMemo(() => [outboundSettingsListItems], [outboundSettingsListItems])
}
