import React, { useMemo } from 'react'
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  RadioLabel,
  Typography
} from '@barracuda-internal/bds-core'
import { HelpOutline } from '@barracuda-internal/bds-core/dist/Icons/Core'
import Help from 'components/pages/support/help/Help'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import pageStyles from 'components/pages/pagesStyles'
import styles from 'components/pages/domains/verifyDomain/verifyDomainStyles'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'
import { UiState, useVerifyDomainLogic } from 'components/pages/domains/verifyDomain/useVerifyDomainLogic'
import DomainVerification from 'components/pages/support/help/domainVerification/DomainVerification'
import { VerificationMethod } from 'types/domains'

const BASE_I18N_KEY = 'ess.domains'

const VerifyDomain = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const [state, eventHandlers] = useVerifyDomainLogic()
  const pageClasses = pageStyles()
  const classes = styles()

  return useMemo(
    () => (
      <Grid container direction="row">
        <Grid className={classes.title} alignItems="center" container direction="row">
          <Grid xs item>
            <Grid container direction="column" justifyContent="space-between">
              <Grid item>
                <Grid container direction="row">
                  <Typography variant="h2" data-testid="title">
                    {formatMessage('verify_domain.header.title')}
                  </Typography>
                  <HelpOutline
                    data-testid="on-open-support"
                    onClick={eventHandlers.help.onHelpOpen}
                    className={pageClasses.helpIcon}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h3">
                  {state.isLoading && <CircularProgress size={12} />}
                  {!state.isLoading && state.domainName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {state.help.isHelpOpen && (
            <Help
              modalComponentInfo={{
                content: DomainVerification,
                onClose: eventHandlers.help.onHelpClose,
                title: TOPICS.domainVerification,
                groupTitle: GROUP_TITLE.domains
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.contentSpacing}>
          <Typography variant="body1">{formatMessage('verify_domain.content')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {(state.isLoading || state.uiState === UiState.VERIFICATION_IN_PROGRESS) && <LinearProgress />}
          {state.error && <Alert severity="error">{errorFormatMessage(state.error)}</Alert>}
        </Grid>
        {state.uiState === UiState.VERIFICATION_IN_PROGRESS && (
          <Grid item xs={12} className={classes.inProgressLabel}>
            <Typography variant="body2" color="primary">
              {formatMessage(state.inProgressLabel)}
            </Typography>
          </Grid>
        )}
        {state.uiState === UiState.ALREADY_VERIFIED && (
          <Grid item xs={12} className={classes.contentSpacing}>
            <Grid container>
              <Grid item xs={12} className={classes.alreadyVerifiedLabel}>
                <Alert severity="info">
                  {formatMessage(state.alreadyVerifiedLabel, { domainName: state.domainName })}
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Button size="small" color="primary" variant="contained" onClick={eventHandlers.onGoBack}>
                  {formatMessage('verify_domain.ok')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {state.uiState === UiState.FORM && (
          <>
            <Grid item xs={12}>
              <Grid container className={classes.contentSpacing}>
                <FormControl component="fieldset" className={classes.fieldSet}>
                  <FormLabel component="legend" color="secondary">
                    Select a verification method
                  </FormLabel>
                  <RadioGroup
                    aria-label="verification_method"
                    name="verification_method"
                    value={state.selectedVerificationMethod}
                    onChange={eventHandlers.onChangeVerificationMethod}
                  >
                    <RadioLabel
                      disabled={state.isLoading}
                      className={classes.radioLabel}
                      value={VerificationMethod.MX_RECORDS}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Grid container>
                          <Grid item xs={12} className={classes.radioLabelText}>
                            {formatMessage('verify_domain.options.mx_records.label', { b: (txt: any) => <b>{txt}</b> })}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" component="div" className={classes.radioHelper}>
                              {state.isLoading && <CircularProgress size={12} />}
                              {!state.isLoading && state.expectedMxRecords.length && (
                                <>
                                  {formatMessage('verify_domain.options.mx_records.helper')}:{' '}
                                  <b>{state.expectedMxRecords.join(', ')}</b>
                                </>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                    <RadioLabel
                      disabled={state.isLoading}
                      className={classes.radioLabel}
                      value={VerificationMethod.CNAME_RECORD}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Grid container>
                          <Grid item xs={12} className={classes.radioLabelText}>
                            {formatMessage('verify_domain.options.cname_record.label', {
                              b: (txt: any) => <b>{txt}</b>
                            })}
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" component="div" className={classes.radioHelper}>
                              {state.isLoading && <CircularProgress size={12} />}
                              {!state.isLoading && state.expectedCnameRecord && (
                                <>
                                  {formatMessage('verify_domain.options.cname_record.helper')}:{' '}
                                  <b>{state.expectedCnameRecord}</b>
                                </>
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                    {state.expectedTechEmail && (
                      <RadioLabel
                        className={classes.radioLabel}
                        value={VerificationMethod.TECH_EMAIL}
                        control={<Radio color="primary" size="small" />}
                        label={
                          <Grid container>
                            <Grid item xs={12} className={classes.radioLabelText}>
                              {formatMessage('verify_domain.options.tech_email.label', {
                                b: (txt: any) => <b>{txt}</b>
                              })}
                              : <b style={{ display: 'inline-flex' }}>{state.expectedTechEmail}</b>
                            </Grid>
                          </Grid>
                        }
                      />
                    )}
                    <RadioLabel
                      disabled={state.isLoading}
                      className={classes.radioLabel}
                      value={VerificationMethod.POSTMASTER_EMAIL}
                      control={<Radio color="primary" size="small" />}
                      label={
                        <Grid container>
                          <Grid item xs={12} className={classes.radioLabelText}>
                            {formatMessage('verify_domain.options.postmaster_email.label', {
                              b: (txt: any) => <b>{txt}</b>
                            })}
                            : {state.isLoading && <CircularProgress size={12} />}
                            {!state.isLoading && state.expectedPostmasterEmail && (
                              <b style={{ display: 'inline-flex' }}>{state.expectedPostmasterEmail}</b>
                            )}
                          </Grid>
                        </Grid>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.contentSpacing}>
              <Button
                className={classes.nextButton}
                size="small"
                color="primary"
                variant="contained"
                onClick={eventHandlers.onVerify}
                disabled={state.isLoading || state.inProgress}
                isLoading={state.inProgress}
              >
                {formatMessage('verify_domain.next')}
              </Button>
              <Button
                size="small"
                variant="text"
                color="primary"
                onClick={eventHandlers.onGoBack}
                disabled={state.inProgress}
              >
                {formatMessage('verify_domain.cancel')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    ),
    [classes, formatMessage, eventHandlers, pageClasses, state, errorFormatMessage]
  )
}

export default VerifyDomain
