import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'
import styles from 'components/libs/layout/pages/pagesLayoutStyles'

export interface FormSpacerProps {
  half?: boolean
}

export const FormSpacer = ({ half = false }: FormSpacerProps) => {
  const classes = styles()

  return useMemo(() => <Grid container className={classes[half ? 'formSpacerHalf' : 'formSpacer']} />, [classes, half])
}
