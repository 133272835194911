import { colors, makeStyles } from '@barracuda-internal/bds-core'

const addUpdatedUsersStyles = makeStyles(theme => ({
  usersField: {
    width: theme.spacing(45)
  },
  formLine: {
    marginBottom: theme.spacing(2)
  },
  resultsContainer: {
    marginTop: theme.spacing(2)
  },
  resultsTopElem: {
    borderTop: `1px solid ${colors.scaleGray200}`,
    paddingTop: theme.spacing(2)
  },
  result_blue: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: colors.blue600
  },
  result_green: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: colors.green600
  },
  result_red: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    color: colors.red600
  }
}))

export default addUpdatedUsersStyles
