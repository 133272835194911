import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  container: {
    height: `calc(100vh - ${theme.layout.navbarHeight + theme.layout.footerHeight}px)`,
    overflow: 'auto',
    '& a': {
      cursor: 'pointer'
    }
  },
  linksContainer: {
    position: 'relative',
    top: theme.layout.navbarHeight + theme.spacing(2)
  },
  titleWrapper: {
    position: 'fixed',
    zIndex: 100,
    width: '100%'
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    fontWeight: 400
  },
  card: {
    marginBottom: 20
  },
  searchPanel: {
    marginLeft: 20
  },
  panelWrapper: {
    paddingBottom: theme.spacing(1)
  },
  helpLinksPanel: {
    marginTop: theme.spacing(2),
    marginLeft: 20,
    paddingBottom: theme.spacing(1)
  },
  helpLinksContent: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  helpLinkGroup: {
    width: '25%'
  },
  helpLinkList: {
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  documentationPanel: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20
  },
  supportPanel: {
    marginLeft: 20,
    marginRight: 20
  },
  supportPanelTop: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20
  },
  searchResultsPanel: {
    marginLeft: 20,
    marginRight: 20,
    height: '100%'
  },
  cardContent: {
    marginBottom: -12
  },
  textField: {
    '&&&': {
      width: '80%',
      marginRight: 10
    }
  },
  helperText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66
  },
  searchResultsContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  searchResultsTitleWrapper: {
    flexGrow: 0,
    flexBasis: 0
  },
  searchResultsWrapper: {
    flexGrow: 1,
    flexBasis: 0
  },
  supportAccessStatusTitle: {
    marginTop: theme.spacing(1.5)
  },
  supportAccessEnabled: {
    fontWeight: 'bold',
    color: colors.green500,
    marginTop: theme.spacing(1.5)
  },
  boldText: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1.5)
  },
  borderBottomStyle: {
    borderBottom: '1px solid #ccc'
  },
  copiedMessage: {
    '& > *': {
      minWidth: 'auto'
    }
  }
}))
