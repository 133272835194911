import React, { useMemo } from 'react'

import {
  Button,
  RadioGroup,
  Radio,
  RadioLabel,
  Typography,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton
} from '@barracuda-internal/bds-core'
import { Close } from '@barracuda-internal/bds-core/dist/Icons/Core'

import { useFormatMessage } from 'lib/localization'

import styles from './redeliveryDialogStyles'
import { useRedeliveryDialogLogic } from './useRedeliveryDialogLogic'

export interface RedeliveryDialogProps {
  onClose: () => void
  showReportButton?: boolean
}

const BASE_I18N_KEY = 'ess.message_log.toolbar.deliver_dialog'

const RedeliveryDialog: React.FC<RedeliveryDialogProps> = props => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { onClose, showReportButton } = props
  const { deliverDialogForm, setDeliverDialogForm, userAllowlistEnabled, handleDeliverDialogClick } =
    useRedeliveryDialogLogic(props)

  return useMemo(
    () => (
      <Dialog open onClose={onClose} data-testid="root-dialog">
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant="h6" data-testid="title">
            {formatMessage('title')}
          </Typography>
          <IconButton className={classes.closeButton} onClick={onClose} data-testid="close-icon">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="subtitle1" data-testid="subtitle">
            {/* TODO: get URL for "Learn more" */}
            {formatMessage('text')}
          </DialogContentText>
          <RadioGroup name="deliverDialog" value={deliverDialogForm} onChange={setDeliverDialogForm} row>
            <RadioLabel
              label={formatMessage('deliver_only')}
              value="deliverOnly"
              control={<Radio color="primary" />}
              data-testid="radio-delivery"
            />
            {userAllowlistEnabled && (
              <RadioLabel
                label={formatMessage('allow_list_sender')}
                value="deliverAllowSender"
                control={<Radio />}
                data-testid="radio-allow"
              />
            )}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          {/* TODO: integrate FPFN feedback form with this button */}
          {showReportButton && (
            <Button onClick={handleDeliverDialogClick} color="primary" variant="contained" data-testid="report-button">
              {formatMessage('deliver_report')}
            </Button>
          )}
          <Button onClick={handleDeliverDialogClick} color="primary" variant="contained" data-testid="handle-button">
            {formatMessage('deliver')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [
      classes,
      formatMessage,
      deliverDialogForm,
      setDeliverDialogForm,
      userAllowlistEnabled,
      handleDeliverDialogClick,
      onClose,
      showReportButton
    ]
  )
}

export default RedeliveryDialog
