import React, { useMemo } from 'react'

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  RadioLabel,
  Typography
} from '@barracuda-internal/bds-core'
import { LinearProgress } from '@material-ui/core'
import IconAlertInfo from 'assets/images/icons/icon_alert_info.svg'
import YesNoDialog from 'components/libs/dialog/yesNoDialog/YesNoDialog'
import ScheduleMatrix from 'components/libs/scheduler/ScheduleMatrix'
import { InboundScheduleType } from 'types/Settings'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'

import { useQuarantineNotificationLogic } from 'components/pages/settings/quarantineNotification/useQuarantineNotificationLogic'
import styles from 'components/pages/settings/quarantineNotification/QuarantineNotificationStyles'

const BASE_I18N_KEY = 'ess.settings.quarantine_notification'

const QuarantineNotification: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()

  const quarantineNotificationLogic = useQuarantineNotificationLogic()

  return useMemo(() => {
    const {
      isDisabledAdd,
      isConfirmDialogOpened,
      selectedScheduleType,
      isGetQuarantineNotificationCompleted,
      getQuarantineNotificationError,
      onScheduleTypeChange,
      onResetSchedule,
      OnSaveQuarantineNotification,
      onResetYesNoDialog,
      onCloseConfirmDialog,
      scheduleMatrixConfig
    } = quarantineNotificationLogic

    return (
      <>
        <YesNoDialog
          title={formatMessage('reset_dialog.title')}
          text={formatMessage('reset_dialog.message')}
          open={isConfirmDialogOpened}
          onClose={onCloseConfirmDialog}
          onConfirm={onResetYesNoDialog}
        />
        <Card elevation={1} data-testid="root-card">
          <CardHeader
            title={
              <Typography className={classes.title} data-testid="title">
                {formatMessage('title')}
              </Typography>
            }
          />
          {!isGetQuarantineNotificationCompleted && <LinearProgress data-testid="progress" />}
          {isGetQuarantineNotificationCompleted && getQuarantineNotificationError && (
            <Alert className={classes.alert} severity="error" data-testid="alert">
              {errorFormatMessage(getQuarantineNotificationError)}
            </Alert>
          )}
          {isGetQuarantineNotificationCompleted && !getQuarantineNotificationError && (
            <>
              {isDisabledAdd && (
                <Box className={classes.determinationBox} data-testid="banner">
                  <Grid container direction="row" wrap="nowrap" alignItems="flex-start">
                    <Grid item>
                      <img alt="" src={IconAlertInfo} className={classes.determinationIcon} />
                    </Grid>
                    <Grid item>
                      <Typography noWrap variant="subtitle2">
                        {formatMessage('allow_override')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <CardActions className={classes.actions}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={onResetSchedule}
                  disabled={isDisabledAdd}
                  data-testid="reset-button"
                >
                  {formatMessage('buttons.reset')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={OnSaveQuarantineNotification}
                  disabled={isDisabledAdd}
                  data-testid="save-button"
                >
                  {formatMessage('buttons.save')}
                </Button>
              </CardActions>
              <CardContent data-testid="content">
                <div className={classes.radioButtonWrapper}>
                  <Typography variant="subtitle1" data-testid="subtitle">
                    {formatMessage('default_interval')}
                  </Typography>
                  <RadioGroup
                    name="inboundSchedule"
                    value={selectedScheduleType}
                    onChange={onScheduleTypeChange}
                    row
                    data-testid="radio-group"
                  >
                    <RadioLabel
                      label={formatMessage('buttons.default_interval.never')}
                      value={InboundScheduleType.never}
                      disabled={isDisabledAdd}
                      control={<Radio color="primary" data-testid="radio-never" />}
                    />
                    <RadioLabel
                      label={formatMessage('buttons.default_interval.scheduled')}
                      value={InboundScheduleType.custom}
                      disabled={isDisabledAdd}
                      control={<Radio color="primary" data-testid="radio-custom" />}
                    />
                  </RadioGroup>
                </div>
                {selectedScheduleType !== InboundScheduleType.never && (
                  <div className={classes.schedulerWrapper}>
                    <Typography variant="subtitle1" data-testid="schedule-subtitle">
                      {formatMessage('schedule_notification')}
                    </Typography>
                    <RadioGroup row>
                      <ScheduleMatrix {...scheduleMatrixConfig} />
                    </RadioGroup>
                    <FormHelperText data-testid="helper-text" className={classes.scheduleNotificationSubTitle}>
                      {formatMessage('schedule_notification_sub_title')}
                    </FormHelperText>
                  </div>
                )}
              </CardContent>
            </>
          )}
        </Card>
      </>
    )
  }, [classes, errorFormatMessage, formatMessage, quarantineNotificationLogic])
}

export default QuarantineNotification
