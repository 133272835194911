import { countries } from 'countries-list'
import isoCountries from 'i18n-iso-countries'

import appConfig from 'config/appConfig'

export interface CountryData {
  code: string
  name: string
}

export function getCountryList(): CountryData[] {
  const countryList = Object.entries(countries).map(([code, details]) => ({
    code,
    name: details.name
  }))

  return countryList.concat().sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
}

export function getFilteredCountryList(selectedCountries: CountryData['code'][]): CountryData[] {
  return getCountryList().filter(countryData => selectedCountries.includes(countryData.code))
}

export function getCountryListCode3(): CountryData[] {
  const countryList = Object.entries(countries).map(([code, details]) => ({
    code: isoCountries.alpha2ToAlpha3(code),
    name: details.name
  }))

  return countryList.concat().sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
}

// works for ISO 3166-1 Alpha-2, Alpha-3 or Numeric code
export function getCountryNameFromCode2(code2: string) {
  return isoCountries.getName(code2, appConfig.LANGUAGE)?.replace(' of America', '')
}

export function getCountryFromCode2(code: CountryData['code']): CountryData | undefined {
  return getCountryList().find(countryData => countryData.code === code)
}

export function getCountry2FromCode3(code3: string): string | undefined {
  return isoCountries.alpha3ToAlpha2(code3)
}

export function getCode2CountryName(code2: string): string | undefined {
  return getCountryList().find(countryObject => countryObject.code === code2)?.name
}

export function getCode3CountryName(code: string): string | undefined {
  return getCountryListCode3().find(countryObject => countryObject.code === code)?.name
}

export { isoCountries }
