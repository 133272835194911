import CryptoJS from 'crypto-js'
import deepmerge from 'deepmerge'

import appConfig from 'config/appConfig'
import { activateIntercomClient } from 'lib/monitoring/intercom/intercomClient'
import { LoginResponse, User } from 'types/auth'

export { TRACKING_EVENTS } from 'config/trackingEvents/trackingEvents'

declare global {
  export interface Window {
    Intercom: any
  }
}

export interface Flags {
  [key: string]: string
}

const intercomConfig = appConfig.INTERCOM
const isIntercomEnabled = intercomConfig.IS_ENABLED && intercomConfig.APP_ID && intercomConfig.SECRET

function getUserHash(userId: string): string {
  return CryptoJS.HmacSHA256(userId, intercomConfig.SECRET).toString(CryptoJS.enc.Hex)
}

export function setIntercomUser(
  user: User,
  accessToken: LoginResponse['accessToken'],
  metadata: Record<string, unknown> = {}
) {
  if (isIntercomEnabled && typeof window.Intercom === 'function' && user) {
    const intercomData = deepmerge(
      {
        app_id: intercomConfig.APP_ID,
        user_hash: getUserHash(user.userId),
        user_id: user.userId,
        email: user.userId,
        name: 'ESS-user',
        state: '',
        zip_code: '',

        bos_serial: accessToken,
        bcc_account_id: user.bccAccountId,
        bcc_user_id: user.userInfo?.userId || '',
        productVersion: 'v1',

        // TODO: get accounts for user
        companies: [],
        company: {
          company_id: user.companyId,
          company_name: user.companyName
        }
      },
      metadata
    )

    window.Intercom?.('update', intercomData)
  }
}

export function initIntercom(user: User, accessToken: LoginResponse['accessToken']) {
  if (isIntercomEnabled) {
    activateIntercomClient(intercomConfig.APP_ID)

    if (isIntercomEnabled && typeof window.Intercom === 'function') {
      const intercomData: { [key: string]: string | boolean } = {
        app_id: intercomConfig.APP_ID,
        hide_default_launcher: true
      }

      window.Intercom?.('boot', intercomData)
      setIntercomUser(user, accessToken)
    }
  }
}

export function trackIntercomEvent(eventName: string, metadata: Record<string, unknown> = {}) {
  if (isIntercomEnabled && typeof window.Intercom === 'function') {
    window.Intercom?.('trackEvent', eventName, metadata)
  } else if (appConfig.ENVIRONMENT.IS_DEV) {
    // eslint-disable-next-line
    console.log('Intercom is not defined, blocked event:', eventName, metadata)
  }
}

export function trackIntercomEventDelayed(eventName: string, metadata: Record<string, unknown> = {}) {
  window.setTimeout(() => {
    trackIntercomEvent(eventName, metadata)
  })
}

export function openNewItercomMessage() {
  if (isIntercomEnabled && typeof window.Intercom === 'function') {
    window.Intercom?.('showNewMessage')
  }
}
