import React, { useCallback, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import Loading from 'components/libs/loading/Loading'
import appConfig from 'config/appConfig'
import { pushToHistory } from 'lib/routesConfig'
import { auth0Login } from 'redux/features/auth/authSlice'
import { isFailed } from 'redux/toolkit/api'
import useLogout from 'lib/useLogout'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'

let returnUrl: string | undefined

function Auth0LoginCallback() {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const dispatch = useAppDispatch()
  const [logout] = useLogout()
  const { appTypeEntryPath, appTypeLoginEntryPath } = useAppTypeEntryPaths()

  const { accessTokenObject, isAuth0LoginFailed } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject,
    isAuth0LoginFailed: isFailed(_store.auth.loginApiStatus)
  }))

  const validateAccessToken = useCallback(async () => {
    const auth0Audience = appConfig.AUTH0.audience

    if (!appConfig.AUTH0.isEnabled) {
      appTypeEntryPath.goto()
    }

    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: auth0Audience
        }
      })
      if (!accessToken) {
        appTypeLoginEntryPath.goto()
      }

      dispatch(auth0Login(accessToken))
    } catch (e) {
      appTypeEntryPath.goto()
    }
  }, [dispatch, getAccessTokenSilently, appTypeLoginEntryPath, appTypeEntryPath])

  // Handle auth0 operation result
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    returnUrl = urlParams.get('return_to') || returnUrl

    // Wait until auth0 operation completes
    if (!isLoading) {
      if (isAuthenticated) {
        // Exchange auth0 access token for API access token and complete the signin flow
        validateAccessToken()
      } else {
        // Bail out if auth0 operation is complete, but user is not authenticated
        appTypeLoginEntryPath.goto()
      }
    }
  }, [dispatch, validateAccessToken, isLoading, isAuthenticated, appTypeLoginEntryPath])

  // login success
  useEffect(() => {
    if (accessTokenObject) {
      if (returnUrl) {
        pushToHistory(returnUrl)
        returnUrl = undefined
      } else {
        appTypeEntryPath.goto()
      }
    }
  }, [accessTokenObject, appTypeEntryPath])

  // login failed
  useEffect(() => {
    if (isAuth0LoginFailed) {
      logout(true)
    }
  }, [isAuth0LoginFailed, logout])

  return <Loading />
}

export default Auth0LoginCallback
