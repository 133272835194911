import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getOldMxRecords, resetOldMxRecords } from 'redux/features/emailServer/emailServerSlice'
import { OldMxRecords } from 'types/emailServer'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { SectionsProps } from 'components/pages/onboardWizard/onboardWizardTypes'
import { useDetectProviderLinksLogic } from 'components/pages/onboardWizard/serverAndMxSetup/sections/providers/useDetectProviderLinksLogic'
import { ProviderWithMxLinks } from 'components/pages/onboardWizard/serverAndMxSetup/sections/providers/providerTypes'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'

export interface State {
  oldMxRecords: OldMxRecords | undefined
  getOldMxRecordsInProgress: boolean
  verifyUpdateButtonStatuses: { disabled: boolean; inProgress: boolean }
  isShowMissedToVerifyMxRecordsError: boolean
  providerWithMxLinks: ProviderWithMxLinks | undefined
  isMissedToVerify: boolean[]
  isMxRecordsAreVerified: boolean
}

export interface EventHandlers {
  onVerifyUpdate: () => void
}

export interface TestHelpers {
  [key: string]: Dispatch<SetStateAction<any>>
}

export type RemoveOldRecordsLogic = [State, EventHandlers, TestHelpers]

export const useRemoveOldMxRecordsLogic = ({
  setSectionToActive,
  isRequestedToCompleteSetup,
  setIsRemoveOldMxRecordsSectionHasError,
  sectionsVerifiedStates
}: SectionsProps): RemoveOldRecordsLogic => {
  const dispatch = useAppDispatch()
  const {
    detectedDomain,
    isDetectEmailServerInProgress,
    isDetectEmailServerSuccess,
    getOldMxRecordsInProgress,
    getOldMxRecordsSuccess,
    getOldMxRecordsFailed,
    oldMxRecords
  } = useAppSelector(_store => ({
    detectedDomain: _store.emailServer.emailServer?.domainName,
    isDetectEmailServerInProgress: isPending(_store.emailServer.detectEmailServerApiStatus),
    isDetectEmailServerSuccess: isSuccess(_store.emailServer.detectEmailServerApiStatus),
    getOldMxRecordsInProgress: isPending(_store.emailServer.getOldMxRecordsApiStatus),
    getOldMxRecordsSuccess: isSuccess(_store.emailServer.getOldMxRecordsApiStatus),
    getOldMxRecordsFailed: isFailed(_store.emailServer.getOldMxRecordsApiStatus),
    oldMxRecords: _store.emailServer.oldMxRecords
  }))
  const [isShowMissedToVerifyMxRecordsError, setIsShowMissedToVerifyMxRecordsError] = useState<boolean>(false)
  const [isMxRecordsAreVerified, setIsMxRecordsAreVerified] = useState<boolean>(false)
  const [providerWithMxLinks] = useDetectProviderLinksLogic()

  // init
  useEffect(
    () => () => {
      dispatch(resetOldMxRecords())
    },
    // eslint-disable-next-line
    []
  )

  // previous section is completed
  useEffect(() => {
    if (sectionsVerifiedStates.addNewMxRecords) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_3_START)
    }
  }, [sectionsVerifiedStates.addNewMxRecords])

  // section verified state is changed
  useEffect(() => {
    if (sectionsVerifiedStates.removeOldMxRecords) {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_3_COMPLETE)
    }
  }, [sectionsVerifiedStates.removeOldMxRecords])

  // domain is changed
  useEffect(
    () => {
      if (detectedDomain && isDetectEmailServerSuccess) {
        setIsMxRecordsAreVerified(false)
        dispatch(getOldMxRecords(detectedDomain))
        setIsShowMissedToVerifyMxRecordsError(false)
      }
    },
    // eslint-disable-next-line
    [detectedDomain, isDetectEmailServerSuccess]
  )

  // new server detection is started
  useEffect(() => {
    if (isDetectEmailServerInProgress) {
      setIsMxRecordsAreVerified(false)
      setIsShowMissedToVerifyMxRecordsError(false)
      dispatch(resetOldMxRecords())
    }
  }, [dispatch, isDetectEmailServerInProgress])

  // delegate the section's error state
  useEffect(() => {
    setIsRemoveOldMxRecordsSectionHasError?.(isShowMissedToVerifyMxRecordsError)
  }, [isShowMissedToVerifyMxRecordsError, setIsRemoveOldMxRecordsSectionHasError])

  // user is requested to complete the setup
  useEffect(() => {
    if (isRequestedToCompleteSetup) {
      const isMissedToVerifyMxRecords = !oldMxRecords || oldMxRecords.some(record => !record.verified)
      setIsShowMissedToVerifyMxRecordsError(isMissedToVerifyMxRecords)
    }
  }, [isRequestedToCompleteSetup, oldMxRecords])

  const isMissedToVerify = useMemo(() => {
    const isAlreadyHitVerifyButton: boolean =
      isMxRecordsAreVerified && (getOldMxRecordsSuccess || getOldMxRecordsFailed)

    return (oldMxRecords || []).map(record => !record.verified && isAlreadyHitVerifyButton)
  }, [isMxRecordsAreVerified, getOldMxRecordsSuccess, getOldMxRecordsFailed, oldMxRecords])

  const onVerifyUpdate = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_3_VERIFIY_UPDATE, { oldMxRecords })

    if (detectedDomain) {
      setIsMxRecordsAreVerified(true)
      setSectionToActive()
      setIsShowMissedToVerifyMxRecordsError(false)
      dispatch(getOldMxRecords(detectedDomain))
    }
  }, [dispatch, detectedDomain, setSectionToActive, oldMxRecords])

  const verifyUpdateButtonStatuses = useMemo(
    () => ({
      disabled: !oldMxRecords,
      inProgress: getOldMxRecordsInProgress
    }),
    [oldMxRecords, getOldMxRecordsInProgress]
  )

  return useMemo(
    () => [
      {
        oldMxRecords,
        getOldMxRecordsInProgress,
        verifyUpdateButtonStatuses,
        isShowMissedToVerifyMxRecordsError,
        providerWithMxLinks,
        isMissedToVerify,
        isMxRecordsAreVerified
      },
      { onVerifyUpdate },
      { setIsShowMissedToVerifyMxRecordsError, setIsMxRecordsAreVerified }
    ],
    [
      oldMxRecords,
      getOldMxRecordsInProgress,
      verifyUpdateButtonStatuses,
      providerWithMxLinks,
      isShowMissedToVerifyMxRecordsError,
      isMissedToVerify,
      isMxRecordsAreVerified,
      onVerifyUpdate,
      setIsShowMissedToVerifyMxRecordsError,
      setIsMxRecordsAreVerified
    ]
  )
}
