import React, { useMemo } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@barracuda-internal/bds-core'
import WarningIcon from '@barracuda-internal/bds-core/dist/Icons/Feedback/Warning'
import { useFormatMessage } from 'lib/localization'

import styles from 'components/pages/users/emailContinuity/confirmDialog/confirmdialogStyles'

const BASE_I18N_KEY = 'ess.users.email_continuity.confirm_dialog'

export type ConfirmDialogProps = {
  onConfirm: () => void
  onClose: () => void
}

function ConfirmDialog({ onConfirm, onClose }: ConfirmDialogProps) {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle disableTypography>
          <Typography variant="h6">{formatMessage('title')}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={1}>
              <WarningIcon className={classes.warningIcon} />
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1">{formatMessage('text')}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onConfirm} color="primary">
            {formatMessage('confirm')}
          </Button>
          <Button onClick={onClose} color="primary">
            {formatMessage('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    [formatMessage, onConfirm, onClose, classes]
  )
}

export default ConfirmDialog
