import React, { useMemo } from 'react'
import { CircularProgress, MenuItem, TextField } from '@barracuda-internal/bds-core'
import {
  SelectStatus,
  useEditDomainAliasLogic
} from 'components/pages/domains/editDomain/editDomainAlias/useEditDomainAliasLogic'
import { useFormatMessage } from 'lib/localization'
import { EditDomainForm, EMPTY_DOMAIN_ALIAS_OF_VALUE } from 'components/pages/domains/editDomain/useEditDomainForm'
import { FormRow } from 'components/libs/layout/pages/pageLayout'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface EditDomainAliasProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains'

const EditDomainAlias = ({ form }: EditDomainAliasProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditDomainAliasLogic(form)

  return useMemo(
    () => (
      <FormBlock title={formatMessage('edit_domain.alias.title')}>
        <FormRow rowTitle={formatMessage('edit_domain.domain_select_description')}>
          <TextField
            fullWidth
            size="small"
            select
            label={formatMessage('edit_domain.domain_select_label')}
            value={state.aliasOf}
            onChange={eventHandlers.onChangeAliasOf}
            disabled={state.inProgress}
            error={state.selectState.status === SelectStatus.ERROR}
            helperText={
              state.selectState.helperText ? formatMessage(`edit_domain.${state.selectState.helperText}`) : ''
            }
          >
            <MenuItem key={EMPTY_DOMAIN_ALIAS_OF_VALUE} value={EMPTY_DOMAIN_ALIAS_OF_VALUE}>
              {state.selectState.status !== SelectStatus.LOADING &&
                state.selectState.label &&
                formatMessage(`edit_domain.${state.selectState.label}`)}
              {state.selectState.status !== SelectStatus.LOADING && !state.selectState.label && ''}
              {state.selectState.status === SelectStatus.LOADING && <CircularProgress size={16} color="secondary" />}
            </MenuItem>
            {state.isGetUnaliasedDomainNamesSuccess &&
              state.availableDomainNames.map(item => (
                <MenuItem key={item.domainId} value={`${item.domainId}`}>
                  {item.domainName}
                </MenuItem>
              ))}
          </TextField>
        </FormRow>
      </FormBlock>
    ),
    [eventHandlers, formatMessage, state]
  )
}

export default EditDomainAlias
