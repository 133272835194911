/* eslint-disable quotes */
const USERS_LIST = 'Users list'
const DEFAULT_POLICY = 'Default policy'
const ADD_UPDATE_USERS = 'Add/Update users'
const QUARANTINE_NOTIFICATION = 'Quarantine notification'
const EMAIL_CONTINUITY = 'Email continuity'
const ALL = 'All'
const SEARCH = 'Search'
const ENABLED = 'Enabled'
const DISABLED = 'Disabled'
const LOADING = 'Loading...'
const EDIT = 'Edit'
const USER_ROLE = 'User role'
const DOMAINS = 'Domains'
const SAVE = 'Save'
const DELETE = 'Delete'
const CANCEL = 'Cancel'
const YES = 'Yes'
const NO = 'No'
const OFF = 'Off'
const NEVER = 'Never'
const SCHEDULED = 'Scheduled'
const USER_ROLES = {
  all: ALL,
  domain_admin: 'Domain admin',
  help_desk: 'Help desk',
  user: 'User'
}

const users = {
  tabs: {
    users_list: USERS_LIST,
    default_policy: DEFAULT_POLICY,
    add_update_users: ADD_UPDATE_USERS,
    quarantine_notification: QUARANTINE_NOTIFICATION,
    email_continuity: EMAIL_CONTINUITY
  },
  cancel: CANCEL,
  save_changes: 'Save changes',
  users_list: {
    title: USERS_LIST,
    user_role: USER_ROLE,
    domains: DOMAINS,
    all: ALL,
    loading: LOADING,
    no_records_available: 'No records available',
    add_update_users: 'Add/Update users',
    delete_users: 'Delete users',
    user_roles: USER_ROLES,
    logging_you_as: 'Logging you as "{user}"',
    table: {
      user_id: 'User account (Email)',
      linked_accounts: 'linked accounts',
      source: 'Source',
      role: 'Role',
      quarantine: 'Quarantine',
      actions: 'Actions'
    },
    sources: {
      azure_ad: 'Azure AD',
      ldap: 'Ldap',
      manual: 'Manual',
      auto: 'Auto'
    },
    quarantine: {
      enabled: ENABLED,
      disabled: DISABLED
    },
    actions: {
      edit: EDIT,
      reset_password: 'Reset password',
      log_in_as: 'Log in as this user',
      delete: 'Delete'
    },
    search_placeholder: 'Search User Account, Linked Accounts',
    alerts: {
      success_reset_password: 'Login information has been sent to <b>{user}</b>',
      failed_reset_password: 'Unable to send reset password information to <b>{user}</b>'
    }
  },
  edit_user_dialog: {
    title: 'Edit user: <b>{user}</b>',
    content_title: 'Roles & Permissions',
    user_role: USER_ROLE,
    domains: DOMAINS,
    save: SAVE,
    cancel: CANCEL,
    user_roles: USER_ROLES,
    domain: 'Domain',
    missed_domain_selection: 'Please select domain(s) for the selected user role',
    user_update_error: 'Unable to update user'
  },
  delete_user_dialog: {
    title: 'Delete user: <b>{user}</b>?',
    titles: 'Delete the selected <b>{count}</b> users?',
    content_title: 'Confirm removal',
    confirm: DELETE,
    cancel: CANCEL,
    user_delete_error: 'Unable to delete the selected user(s)'
  },
  default_policy: {
    title: DEFAULT_POLICY,
    information_text: 'Specify the default email scanning policies for managed and unmanaged users.',
    default_policy_managed_users: 'Default policy for managed users (users on Users list)',
    default_policy_unmanaged_users: 'Default policy for unmanaged users (users NOT on Users list)',
    exempt_senders: 'Exempt Senders',
    exempt_senders_information_text: 'Note: Changing this value will affect the users current policy configuration',
    allow_users_to_block: 'Allow users to block senders',
    allow_users_to_block_information_text:
      'Note: Changing this value will affect the users current policy configuration',
    allow_deliver_blocked_messages: 'Allow end users to view and deliver blocked messages',
    allow_deliver_blocked_messages_information_text:
      'Note: This will allow end-users to deliver potentially harmful messages or click on dangerous links.',
    allow_deliver_blocked_messages_information_text_2: 'Not recommended.',
    allow_deliver_quarantined_messages: 'Allow end users to view and deliver quarantined messages',
    allow_deliver_quarantined_messages_information_text:
      'Note: This will allow end-users to deliver potentially harmful messages or click on dangerous links.',
    default_timezone: 'Default Time Zone',
    managed_policy_select: {
      allow: 'allow',
      scan: 'scan',
      block: 'block'
    },
    exempt_senders_select: {
      0: 'Do not allow users to exempt senders',
      1: 'Allow users to exempt senders',
      2: 'Allow users to exempt senders but do not override admin block lists'
    }
  },
  add_update_users: {
    title: ADD_UPDATE_USERS,
    user_accounts: 'User accounts',
    user_email_error: 'Please enter at least one valid email address.',
    information_text:
      'Manually create or update user accounts with the settings below. If <b>Notify New Users</b> is set to <i>Yes</i>, the user will be sent a welcome email as soon as the account is created. The link in the welcome email for the user account login expires within 7 days.',
    enable_user_quarantine: 'Enable user quarantine',
    notify_new_users: 'Notify new users',
    yes: YES,
    no: NO,
    results: {
      base_text: 'The following user account actions were performed.',
      quarantine_notification: 'Quarantine notification has been {enabled, plural, =0 {disabled} other {enabled}}.',
      notified_users: 'User(s) have been notified via email.',
      successfully_added: 'User <b>{user_id}</b> added.',
      bulk_successfully_added: '<b>{count}</b> users added.',
      successfully_updated: 'User <b>{user_id}</b> updated.',
      bulk_successfully_updated: '<b>{count}</b> users updated.',
      failed_to_add_user: 'Failed to add user <b>{user_id}</b>.',
      failed_to_update_user: 'Failed to update user <b>{user_id}</b>.',
      invalid_email: 'The email address <b>{user_id}</b> is not valid.',
      invalid_domain: 'The email domain <b>{domain}</b> is not allowed. You can only add users to verified domains.',
      missed_domain_right: 'You do not have permission to manage users on <b>{domain}</b>.',
      already_exists_as_linked_account:
        '<b>{user_id}</b> already exists as a linked account.  Please choose another email address to add.'
    }
  },
  quarantine_notification: {
    title: QUARANTINE_NOTIFICATION,
    quarantine_messages_for_intent_analysis: 'Quarantine messages for Intent Analysis',
    quarantine_messages_for_intent_analysis_help:
      'When set to Yes, messages that would be blocked by Intent Analysis will be quarantined instead.',
    default_interval: 'Default interval for user quarantine notifications',
    scheduled_interval: 'Schedule notification intervals',
    scheduled_interval_help:
      'Notifications will be sent at any time within the hour block chosen. Click and drag to select. Hold Ctrl while dragging to unselect.',
    allow_specify_interval: 'Allow users to specify interval',
    allow_temp_passcode: 'Allow users to log in with temporary passcode',
    allow_temp_passcode_help:
      'Users will have the option to receive a temporary passcode that is valid for 15 minutes to log in. This is useful for users signing into a shared mailbox or distribution list.',
    yes: YES,
    no: NO,
    never: NEVER,
    scheduled: SCHEDULED
  },
  email_continuity: {
    title: EMAIL_CONTINUITY,
    information_text:
      'Allows end users the ability to receive and send emails when designated mail servers are unavailable. Email Continuity service will auto-expire after 96 hours.',
    email_continuity: EMAIL_CONTINUITY,
    off: OFF,
    auto_enable: 'Auto-enable',
    auto_enable_alert:
      'Using the Auto-Enable feature for Email Continuity will enable spooling for all of the domains associated with this account.',
    confirm_dialog: {
      title: 'Enable spooling',
      text: 'Using the Auto-Enable feature for Email Continuity will enable spooling for all of the domains associated with this account.',
      confirm: 'Enable',
      cancel: CANCEL
    }
  }
}
/* eslint-enable quotes */

export default users
