import { SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query'
import { PageChangeEvent } from '@progress/kendo-react-data-tools'
import { GridPagerSettings, GridSortChangeEvent, GridSortSettings } from '@progress/kendo-react-grid'

export interface BDSGridPagerConfig {
  pageable: GridPagerSettings | boolean
  skip: number
  take: number
  total: number
  onPageChange?: (e: PageChangeEvent) => void
}

export interface BDSGridSortConfig {
  sortable: GridSortSettings
  sort: SortDescriptor[]
  onSortChange?: (e: GridSortChangeEvent) => void
}

export interface UpdateDataTable {
  columnsConfig?: {
    [key: string]: object
  }
  skip?: number
  take?: number
  filter?: null | string[] | FilterDescriptor[]
  search?: string
  sort?: SortDescriptor[]
}

export interface SortFields {
  [key: string]: string
}

export interface GridColumns {
  [key: string]: string
}
export interface ColumnConfig {
  sortable: boolean
  orderIndex: number
  show: boolean
}
export interface ColumnsConfig {
  [key: string]: ColumnConfig
}

export interface DataTableState {
  SORT_FIELDS?: SortFields
  GRID_COLUMNS: GridColumns
  ITEMS_PER_PAGE: number
  ITEMS_PER_REQUEST?: number
  columnsConfig: ColumnsConfig
  skip: number
  take: number
  filter: null | FilterDescriptor[] | string[]
  search: string
  sort?: SortDescriptor[]
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc'
}
