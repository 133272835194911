import { useCallback, useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { UserRole } from 'config/userRole'
import useUserRights from 'components/libs/userRights/useUserRights'
import { DomainType } from 'types/domains'

export const useDomainsTabRights = () => {
  const { userHasRight } = useUserRights()
  const { isCplAccount, isHelpdeskUser } = useAppSelector(_store => ({
    isCplAccount: !!_store.auth.accessTokenObject?.isCplAccount,
    isHelpdeskUser: _store.auth.accessTokenObject?.roleType === UserRole.HELPDESK_ROLE,
    pdDomainId: _store.auth.accessTokenObject?.pdDomainId
  }))

  const canAccessDomainsTab = useMemo(
    () => userHasRight(UserRole.DOMAIN_USER) || userHasRight(UserRole.HELPDESK_ROLE),
    [userHasRight]
  )

  const canAddDomain = useMemo(() => userHasRight(UserRole.ACCOUNT_USER), [userHasRight])

  const canDeleteDomain = useMemo(() => userHasRight(UserRole.ACCOUNT_USER), [userHasRight])

  const canSeeOutboundSmarthost = useMemo(() => !isCplAccount, [isCplAccount])

  const canSeeEmailContinuityStatus = useMemo(() => !isCplAccount, [isCplAccount])

  const canResetDomainToAccountSettings = useMemo(() => !isCplAccount, [isCplAccount])

  const shouldSeeHybridLabel = useCallback(
    (domainType: string) => domainType === DomainType.HYBRID && !isCplAccount,
    [isCplAccount]
  )

  const canManageDomain = useCallback(
    (domainType: string) => domainType !== DomainType.HYBRID && !isCplAccount,
    [isCplAccount]
  )

  const canEditDomain = useMemo(() => !isHelpdeskUser, [isHelpdeskUser])

  const canManageAlias = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageAdvancedSmtpEncryptionSettings = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageEncryption = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageUserAutoAdd = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageRecipientRewrite = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageDirectoryServices = useMemo(() => !isCplAccount, [isCplAccount])

  const canManageAdvancedConfiguration = useMemo(() => !isCplAccount, [isCplAccount])

  return useMemo(
    () => ({
      canAccessDomainsTab,
      canAddDomain,
      canDeleteDomain,
      canSeeOutboundSmarthost,
      canSeeEmailContinuityStatus,
      canResetDomainToAccountSettings,
      shouldSeeHybridLabel,
      canManageDomain,
      canEditDomain,
      canManageAlias,
      canManageAdvancedSmtpEncryptionSettings,
      canManageEncryption,
      canManageUserAutoAdd,
      canManageRecipientRewrite,
      canManageDirectoryServices,
      canManageAdvancedConfiguration
    }),
    [
      canAccessDomainsTab,
      canAddDomain,
      canDeleteDomain,
      canSeeOutboundSmarthost,
      canSeeEmailContinuityStatus,
      canResetDomainToAccountSettings,
      shouldSeeHybridLabel,
      canManageDomain,
      canEditDomain,
      canManageAlias,
      canManageAdvancedSmtpEncryptionSettings,
      canManageEncryption,
      canManageUserAutoAdd,
      canManageRecipientRewrite,
      canManageDirectoryServices,
      canManageAdvancedConfiguration
    ]
  )
}

export type DomainsTabRights = ReturnType<typeof useDomainsTabRights>
