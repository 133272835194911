import wizardEvents from 'config/trackingEvents/wizardEvents'
import webuiEvents from 'config/trackingEvents/webuiEvents'
import adminEvents from 'config/trackingEvents/adminEvents'

const TRACKING_EVENTS = {
  WIZARD: wizardEvents,
  WEBUI: webuiEvents,
  ADMIN: adminEvents
} as const

export { TRACKING_EVENTS }
