import React, { useMemo } from 'react'
import { ChoroplethProps, ResponsiveChoropleth } from '@nivo/geo'
import { colors } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'

import styles from 'components/libs/nivo/nivoStyles'
import features from 'components/libs/nivo/choropleth/world_map.json'
import { getCode2CountryName } from 'lib/isoCountries'

const BASE_I18N_KEY = 'ess.nivo'

const Choropleth = (props: Partial<ChoroplethProps>) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <ResponsiveChoropleth
        data={[]}
        features={features.features}
        margin={{ top: 15, right: 10, bottom: 10, left: 10 }}
        colors={[colors.blue500]}
        domain={[0, 100]}
        unknownColor="#f2f2f2"
        label="properties.name"
        valueFormat={value => Number(value).toFixed(0)}
        projectionTranslation={[0.48, 0.56]}
        projectionRotation={[0, 0, 0]}
        projectionScale={92}
        isInteractive
        graticuleLineColor="#dddddd"
        borderWidth={0.5}
        borderColor="#152538"
        legends={[]}
        tooltip={tooltip => {
          if (!tooltip.feature.value) {
            return null
          }

          const country2Code = tooltip.feature.data.code2 || ''
          return (
            <div className={classes.tooltip}>
              <div className={classes.tooltipTitle}>
                {country2Code && (
                  <img className={classes.flag} src={`/images/flags/${country2Code.toLowerCase()}.svg`} alt="" />
                )}
                {`${getCode2CountryName(country2Code) || 'N/A'}: ${tooltip.feature.value} ${formatMessage(
                  'choropleth_tooltip_attacks',
                  {
                    count: tooltip.feature.value
                  }
                )}`}
              </div>
            </div>
          )
        }}
        {...props}
      />
    ),
    [props, classes, formatMessage]
  )
}

export default Choropleth
