import React, { useMemo } from 'react'

import { Box, Grid, MenuItem, Select, Typography } from '@barracuda-internal/bds-core'
import { Serie } from '@nivo/line'
import { Datum } from '@nivo/legends'
import { Action } from 'types/Messages'

import { useFormatMessage } from 'lib/localization'
import ContentLoader from 'components/libs/contentLoader/ContentLoader'
import LineChart from 'components/libs/nivo/lineChart/LineChart'
import styles from 'components/pages/overview/dashboard/dashboardStyles'
import dropdownStyles from 'components/libs/tableWithDropdown/tableWithDropdownStyles'

export interface State {
  chartConfig: {
    data: Serie[]
    colors: string[]
    onLegendClick: (legend: Datum) => void
    axisBottomValuesToShow: string[]
    totals: [string, number][]
    maxValueInRange: number
    disabledLegends: string[]
  }
  inProgress: boolean
  isFailed: boolean
  selectedFilter: Action
  chartTooltip?: (xAxis: string) => any
}

export interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type EmailStatisticsChartProps = {
  title: string

  logic: {
    state: State
    eventHandlers: EventHandlers
  }
}

const BASE_I18N_KEY = 'ess.overview.dashboard'

const DROPDOWN_ITEMS = [Action.none, Action.blocked, Action.allowed, Action.quarantined]

const EmailStatisticsChart: React.FC<EmailStatisticsChartProps> = props => {
  const classes = styles()
  const dropdownClasses = dropdownStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const {
    logic: { eventHandlers, state }
  } = props

  return useMemo(
    () => (
      <Grid container direction="column" data-testid="container">
        <Typography className={classes.subtitle} variant="subtitle1" data-testid="subtitle">
          {formatMessage(props.title)}&nbsp;
          <Select
            className={dropdownClasses.dropdown}
            onChange={e => eventHandlers.onSelectFilter(e.target.value)}
            value={state.selectedFilter}
            renderValue={(selected: any): React.ReactElement => (
              <span>{formatMessage(`charts.${selected === Action.none ? 'overview' : selected}`)}</span>
            )}
            data-testid="select-menu"
          >
            {DROPDOWN_ITEMS.map(item => (
              <MenuItem key={item} value={item}>
                {formatMessage(`charts.${item === Action.none ? 'overview' : item}`)}
              </MenuItem>
            ))}
          </Select>
        </Typography>
        <ContentLoader isLoading={state.inProgress} isFailed={state.isFailed}>
          <Grid item className={classes.chart} data-testid="chart-wrapper">
            <LineChart
              config={{ data: state.chartConfig.data, colors: state.chartConfig.colors }}
              colors={state.chartConfig.colors}
              onLegendClick={state.chartConfig.onLegendClick}
              disabledLegends={state.chartConfig.disabledLegends}
              chartTooltip={state.chartTooltip}
              formats={{
                axisBottom: v => (state.chartConfig.axisBottomValuesToShow.includes(v) ? v : ''),
                axisLeft: v => (Number.isInteger(v) ? v : '')
              }}
              maxValueInRange={state.chartConfig.maxValueInRange}
              data-testid="line-chart"
            />
            <Grid className={classes.chartTotalWrapper} container direction="row" data-testid="totals">
              {state.chartConfig.totals.map(totalObject => {
                const [totalName, totalValue] = totalObject

                return (
                  <Grid key={totalName} item xs>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <Grid className={classes.chartTotalCount} item>
                        {totalValue.toLocaleString()}
                      </Grid>
                      <Grid item>{formatMessage(`charts.${totalName}`)}</Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </ContentLoader>
        <Grid className={dropdownClasses.circleWrapper} item>
          {DROPDOWN_ITEMS.map(item => (
            <Box
              key={item}
              className={`${dropdownClasses.circle} ${item === state.selectedFilter ? 'selected' : ''}`}
              onClick={() => eventHandlers.onSelectFilter(item)}
              data-testid={`dropdown-item-${item}`}
            />
          ))}
        </Grid>
      </Grid>
    ),
    [state, eventHandlers, classes, dropdownClasses, formatMessage, props.title]
  )
}

export default EmailStatisticsChart
