import { useCallback, useMemo, useState } from 'react'
import { isValidIpV4Address } from 'lib/validation'
import { IpAddressEntry } from 'types/Settings'
import { useFormatMessage } from 'lib/localization'

export interface FormErrors {
  newIpAddressError: string
  newIpNetmaskError: string
  newIpDuplicateError: string
}

export interface Methods {
  validateNewIp: (newIp: IpAddressEntry, data: IpAddressEntry[]) => boolean
  resetErrors: () => void
}

export type UseIpAddressTableValidation = [FormErrors, Methods]

const BASE_I18N_KEY = 'ess.settings.ip_address_table.error'

export const useIpAddressTableValidation = (): UseIpAddressTableValidation => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [newIpAddressError, setNewIpAddressError] = useState<string>('')
  const [newIpNetmaskError, setNewIpNetmaskError] = useState<string>('')
  const [newIpDuplicateError, setNewIpDuplicateError] = useState<string>('')

  const validateNewIp = useCallback(
    (newIp: IpAddressEntry, data: IpAddressEntry[]) => {
      setNewIpAddressError('')
      setNewIpNetmaskError('')
      let hasError = false

      if (!newIp.address) {
        setNewIpAddressError(formatMessage('empty_ip_address'))
        hasError = true
      } else if (!isValidIpV4Address(newIp.address)) {
        setNewIpAddressError(formatMessage('invalid_ip_address'))
        hasError = true
      }

      if (!newIp.netmask) {
        setNewIpNetmaskError(formatMessage('empty_netmask'))
        hasError = true
      } else if (!isValidIpV4Address(newIp.netmask)) {
        setNewIpNetmaskError(formatMessage('invalid_netmask'))
        hasError = true
      }

      const findDuplicate = data.find(entry => entry.address === newIp.address && entry.netmask === newIp.netmask)
      if (findDuplicate) {
        setNewIpDuplicateError(formatMessage('duplicate_value', { item: `${findDuplicate.address}` }))
        hasError = true
      }

      return !hasError
    },
    [formatMessage]
  )

  const resetErrors = useCallback(() => {
    setNewIpAddressError('')
    setNewIpNetmaskError('')
    setNewIpDuplicateError('')
  }, [])

  return useMemo(
    () => [
      {
        newIpAddressError,
        newIpNetmaskError,
        newIpDuplicateError
      },
      { validateNewIp, resetErrors }
    ],
    [newIpAddressError, newIpNetmaskError, newIpDuplicateError, resetErrors, validateNewIp]
  )
}
