import { colors, makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  domainsPage: {
    height: 'calc(100vh - 80px)',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  defaultRow: {
    flexGrow: 0,
    width: '100%',
    flexBasis: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  tableRow: {
    flexGrow: 1,
    width: '100%',
    flexBasis: 0,
    maxWidth: '100%',
    overflow: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center'
  },
  statusIconSuccess: {
    color: colors.statusSuccess,
    marginRight: theme.spacing(1)
  },
  statusIconCritical: {
    color: colors.statusCritical,
    marginRight: theme.spacing(1)
  },
  statusIconWarning: {
    color: colors.statusWarning,
    marginRight: theme.spacing(1)
  },
  flagIcon: {
    color: colors.statusWarning,
    marginRight: theme.spacing(1)
  },
  mailServerText: {
    marginBottom: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  domainsTable: {
    height: '100%',

    '& tbody tr': {
      cursor: 'pointer'
    }
  },
  domainOptionsCell: {
    display: 'flex'
  },
  mxBanner: {
    marginBottom: theme.spacing(1)
  },
  mxPopover: {
    padding: theme.spacing(2)
  },
  details: {
    '& p': {
      padding: `${theme.spacing(0.5)}px 0`
    }
  },
  detailTitle: {
    fontWeight: 900
  },
  detailTopMargin: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  titleRow: {
    width: '100%'
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  kendoGrid: {
    height: '100%',
    width: '100%',

    '& > .k-widget': {
      height: '100%'
    }
  },
  searchTextField: {
    margin: theme.spacing(2, 2, 3, 0),
    backgroundColor: theme.palette.common.white,
    minWidth: 624
  },
  settingLabel: {
    marginLeft: theme.spacing(2)
  }
}))

export default styles
