import { useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { getDomainTransferStates } from 'redux/features/domains/domainsSlice'
import { DomainTransferStateRecord } from 'types/domains'
import appConfig from 'config/appConfig'

export interface State {
  domainTransferStates: Record<number, DomainTransferStateRecord | null>
  isActive: boolean
  isTimedOut: boolean
}

export interface EventHandlers {
  start: (domainIds: string[]) => void
  stop: (domainIds: string[]) => void
  stopAll: () => void
  add: (domainIds: string[]) => void
}

export type PollDomainTransfer = [State, EventHandlers]

export const usePollDomainTransferState = (timeout?: number): PollDomainTransfer => {
  const [isTimedOut, setIsTimedOut] = useState(false)
  const domainIds = useRef<string[]>([])
  const dispatch = useAppDispatch()
  const timer = useRef<number>()
  const startedAt = useRef(0)
  const { isPollSuccess, domainTransferStates, isPollFailed } = useAppSelector(_store => ({
    isPollSuccess: isSuccess(_store.domains.api.getDomainTransferStatesApiStatus),
    isPollFailed: isFailed(_store.domains.api.getDomainTransferStatesApiStatus),
    domainTransferStates: _store.domains.domainTransferStates
  }))

  const isTotalPollTimeout = useCallback(
    (lastPolledAt: number) => (timeout ? startedAt.current + timeout < lastPolledAt : false),
    [timeout]
  )

  const poll = useCallback(() => {
    if (startedAt.current) {
      dispatch(getDomainTransferStates({ domainIds: domainIds.current }))
    }
  }, [dispatch, domainIds])

  const start = useCallback(
    (pollDomainIds: string[]) => {
      setIsTimedOut(false)
      domainIds.current = pollDomainIds
      startedAt.current = Date.now()
      poll()
    },
    [poll]
  )

  const stopAll = useCallback(() => {
    startedAt.current = 0
    window.clearTimeout(timer.current)
  }, [])

  const stop = useCallback(
    (ids: string[]) => {
      domainIds.current = domainIds.current.filter(id => !ids.includes(id))
      if (domainIds.current.length === 0) {
        stopAll()
      }
    },
    [stopAll]
  )

  const add = useCallback(
    (ids: string[]) => {
      if (!startedAt.current) {
        start(ids)
        return
      }
      domainIds.current = Array.from(new Set([...domainIds.current, ...ids]))
    },
    [start]
  )

  const startNewPoll = useCallback(() => {
    if (isTotalPollTimeout(Date.now())) {
      stopAll()
      setIsTimedOut(true)
    } else {
      timer.current = window.setTimeout(poll, appConfig.DOMAIN_MOVE.POLL_TRANSFER_STATE_INTERVAL)
    }
  }, [isTotalPollTimeout, poll, stopAll])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stopAll, [])

  useEffect(() => {
    if (isPollSuccess) {
      startNewPoll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollSuccess])

  useEffect(() => {
    if (isPollFailed) {
      startNewPoll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPollFailed])

  return useMemo(
    () => [
      {
        domainTransferStates,
        isActive: !!startedAt.current,
        isTimedOut
      },
      {
        start,
        stop,
        stopAll,
        add
      }
    ],
    [domainTransferStates, isTimedOut, start, stop, stopAll, add]
  )
}
