/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import ErrorIcon from 'assets/images/icons/error.png'
import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundTagline(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
        unless you change these settings for a specific domain. In that case, the domain-level settings override the
        global system settings.
      </p>
      <br />
      <br />

      <p>
        If you select <b>Append Tagline</b>, the text you enter in the text box is appended to each outgoing message
        sent via {props.productName}. The text is appended exactly as entered. Note that if you use HTML coding in your
        footer, then all your outbound mail must be sent using HTML encoding. If it is not sent as HTML, or if you are
        not sure, then your outbound footer should be in text only. The outbound mail footer maximum number of
        characters is 3000.
      </p>
      <div className={classes.warnNotice}>
        <img src={ErrorIcon} className={classes.noticeIcon} />
        <b>Note:</b> If you use a character set in the footer, make sure to use the same character set in outbound
        messages. If you are using multiple character sets, the footer text may not display properly.
      </div>
    </div>
  )
}

export default OutboundTagline
/* eslint-enable jsx-a11y/alt-text */
