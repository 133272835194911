import { useCallback, useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { appAuth0LoginCallbackPath } from 'lib/routesConfig'
import { Auth0Params } from 'types/login'

export interface UseAuth0Login {
  auth0Login: (auth0Params: Auth0Params) => void
}

export const useAuth0Login = (): UseAuth0Login => {
  const { loginWithRedirect } = useAuth0()

  const auth0Login = useCallback(
    async (authParams: Auth0Params) => {
      const urlParams = new URLSearchParams(window.location.search)
      const redirectUrl = urlParams.get('return_to') || undefined
      const queryParams = new URLSearchParams({
        return_to: redirectUrl || ''
      })
      const queryString = redirectUrl ? `?${queryParams.toString()}` : ''

      await loginWithRedirect({
        authorizationParams: {
          ...authParams,
          redirect_uri: `${window.location.origin}${appAuth0LoginCallbackPath?.path}${queryString}`
        }
      })
    },
    [loginWithRedirect]
  )

  return useMemo(
    () => ({
      auth0Login
    }),
    [auth0Login]
  )
}
