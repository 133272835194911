import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  DOMAINS: {
    path: 'domains',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.helpdesk, AppTypes.domain, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  VERIFY_DOMAIN: {
    path: 'domains/verify/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.helpdesk, AppTypes.domain, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  UNIQUE_DOMAIN_RECIPIENTS: {
    path: 'domains/unique-recipients/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.helpdesk, AppTypes.domain, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  EDIT_DOMAIN: {
    path: 'domains/edit/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.domain, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  INBOUND_TLS_DOMAINS_BULK_EDIT: {
    path: 'domains/inbound-tls-domains/bulk-edit/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  INBOUND_TLS_DOMAIN_EXEMPTIONS_BULK_EDIT: {
    path: 'domains/inbound-tls-domain-exemptions/bulk-edit/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  },
  VERIFY_DOMAIN_TRANSFER: {
    path: 'domains/verify-transfer/:domainId',
    legacyPath: 'domains',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.helpdesk, AppTypes.domain, AppTypes.cplAccount]
    },
    metadata: {
      sideMenuId: 'DOMAINS'
    }
  }
}
