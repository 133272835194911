import React, { useMemo } from 'react'
import { Grid, Typography, Button } from '@barracuda-internal/bds-core'
import styles from 'components/pages/domains/editDomain/editDomainAdvanced/editDomainAdvancedStyles'
import { useEditDomainAdvancedLogic } from 'components/pages/domains/editDomain/editDomainAdvanced/useEditDomainAdvancedLogic'
import { useFormatMessage } from 'lib/localization'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { SettingValue } from 'types/Settings'
import { FormSpacer, FormRow, InlineRadioGroup } from 'components/libs/layout/pages/pageLayout'
import { FormBlock } from 'components/libs/layout/pages/FormBlock'

export interface EditDomainAdvancedProps {
  form: EditDomainForm
}

const BASE_I18N_KEY = 'ess.domains.edit_domain'

const EditDomainAdvanced = ({ form }: EditDomainAdvancedProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useEditDomainAdvancedLogic(form)
  const classes = styles()

  return useMemo(
    () => (
      <FormBlock
        title={formatMessage('advanced.title')}
        collapsible
        collapsed={!state.isVisible}
        onClickTitle={eventHandlers.onToggleVisible}
      >
        <FormSpacer />
        <FormRow rowTitle={formatMessage('advanced.srs.title')}>
          <InlineRadioGroup
            name="srs"
            value={state.srs}
            onChange={eventHandlers.onChangeSrs}
            disabled={false}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            labels={[formatMessage('advanced.srs.yes'), formatMessage('advanced.srs.no')]}
          />
        </FormRow>
        <FormSpacer />
        {state.ldapHighestSerial && (
          <>
            <FormRow rowTitle={formatMessage('advanced.ldap_highest_serial.title')}>
              <Grid container direction="row" alignItems="center">
                <Typography variant="body1">
                  {formatMessage('advanced.ldap_highest_serial.value', { value: state.ldapHighestSerial })}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={eventHandlers.onResetLdapHighestSerial}
                  className={classes.inlineButton}
                  isLoading={state.serialResetInProgress}
                  disabled={state.inProgress}
                >
                  {formatMessage('advanced.ldap_highest_serial.reset')}
                </Button>
              </Grid>
            </FormRow>
            <FormSpacer />
          </>
        )}
        <FormSpacer />
      </FormBlock>
    ),
    [classes, eventHandlers, formatMessage, state]
  )
}

export default EditDomainAdvanced
