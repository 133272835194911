/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import ErrorIcon from 'assets/images/icons/error.png'
import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function OutboundSenderIp(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      <p>
        Each IP address from which you want to allow outgoing mail through {props.productName} must be listed on this
        page, and must have a PTR record. The <b>Logging Domain</b> is the domain name that appears in the{' '}
        <b>Message Log</b> as the sending domain for the associated IP address.
      </p>
      <div className={classes.warnNotice}>
        <img src={ErrorIcon} className={classes.noticeIcon} />
        <b>Important</b>: To assure Barracuda Networks is the authorized sending mail service for outbound mail
        recipients, add the following to the SPF record INCLUDE line for each domain sending outbound mail based on your
        instance. For example: <code>{`include:spf.${window.location.hostname}`} -all</code>
      </div>
      <br />
      <br />
      See
      <a href="https://campus.barracuda.com/doc/96023038/" target="_new">
        Sender Policy Framework for Outbound Mail
      </a>
      in Barracuda Campus.
      <br />
      <br />
      Click on one of the following columns to sort the <b>Sender IP Address Range</b> table:
      <ul>
        <li>
          <b>Logging & Policy Domain</b>
        </li>
        <li>
          <b>IP Address</b>
        </li>
        <li>
          <b>Netmask</b>
        </li>
        <li>
          <b>Comment</b>
        </li>
      </ul>
    </div>
  )
}

export default OutboundSenderIp
/* eslint-enable jsx-a11y/alt-text */
