import React, { PropsWithChildren, useMemo } from 'react'
import { Grid } from '@barracuda-internal/bds-core'

import styles from 'components/libs/settings/settingsInputStyles'

export interface SettingsBlockProps extends PropsWithChildren {
  disabled?: boolean
}

const SettingsBlock = ({ children, disabled }: SettingsBlockProps) => {
  const classes = styles({ disabled })

  return useMemo(
    () => (
      <Grid container className={classes.settingsBlock}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    ),
    [children, classes]
  )
}

export default SettingsBlock
