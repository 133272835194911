import { useMemo, useEffect, useCallback, useState, Dispatch, SetStateAction } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings } from 'types/Settings'
import { CustomRblsParsed } from 'types/CustomRbls'
import routesConfig from 'lib/routesConfig'

import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  setHasPageChanges,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'

export interface State {
  data: CustomRblsParsed[]
  hasPageChanges: boolean
}

export interface EventHandlers {
  onAdd: (item: CustomRblsParsed) => void
  onRemove: (id: string) => void
  onBlukEdit: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsCustomRblsTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseCustomRblsLogic = [State, EventHandlers]

const SETTINGS_LIST = [AvailableSettings.RBLS]

export const useCustomRblsLogic = (): UseCustomRblsLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    accessTokenObject,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const [isCustomRblsTableDirty, setIsCustomRblsTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
      dispatch(setHasPageChanges(false))
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const data = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedData = settings.rbls ? (JSON.parse(settings.rbls as string) as CustomRblsParsed[]) : []
    return parsedData
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateSettings = useCallback(
    (rbls: CustomRblsParsed[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { rbls }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { rbls } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAdd = useCallback(
    (item: CustomRblsParsed) => {
      const rbls = [...data, item]
      updateSettings(rbls)
    },
    [data, updateSettings]
  )

  const onRemove = useCallback(
    (id: string) => {
      const rbls = data.filter(rbl => rbl.id !== id)
      updateSettings(rbls)
    },
    [data, updateSettings]
  )

  const onBlukEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        data,
        hasPageChanges: isCustomRblsTableDirty
      },
      {
        onAdd,
        onRemove,
        onBlukEdit,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsCustomRblsTableDirty
      }
    ],
    [data, isCustomRblsTableDirty, onAdd, onRemove, onBlukEdit, isHelpDialogOpened, onHelpClick, onCloseHelp]
  )
}
