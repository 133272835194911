import React, { Children, cloneElement, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import appConfig from 'config/appConfig'
import { isPending, isSuccess } from 'redux/toolkit/api'

import Footer from 'components/libs/layout/footer/Footer'
import styles from 'components/libs/layout/layoutStyles'
import Banners from 'components/libs/banner/Banners'
import GlobalDialog from 'components/libs/dialog/globalDialog/GlobalDialog'
import SideBar from 'components/libs/layout/navbar/SideBar'
import AccountSwitcher from 'components/libs/accountSwitcher/AccountSwitcher'
import { useAppSelector } from 'redux/toolkit/hooks'
import UiSwitcher from 'components/libs/uiSwitcher/UiSwitcher'
import OverlayLoader from 'components/libs/overlayLoader/OverlayLoader'

export default function Layout({ children }: { children: React.ReactNode }) {
  const { activePath, switchUiPending, switchUiSuccess, accessTokenObject } = useAppSelector(_store => ({
    activePath: _store.app.activePath,
    accessTokenObject: _store.auth.accessTokenObject,
    switchUiPending: isPending(_store.auth.switchToOldUiApiStatus),
    switchUiSuccess: isSuccess(_store.auth.switchToOldUiApiStatus)
  }))
  const classes = styles()
  const [searchParams] = useSearchParams()
  const printableView = searchParams.get('printable_view')
  const routeHasPrintableView = activePath.metadata.printable
  const shouldShowPrintableView = printableView && !!routeHasPrintableView

  return useMemo(() => {
    const isNavbarVisible = !appConfig.APP.IS_CUDASPT

    return (
      <div className={`path-id-${activePath.id} ${classes.pathWrapper}`}>
        {isNavbarVisible && !shouldShowPrintableView && !!accessTokenObject && (
          <div className={classes.sidebar}>
            <SideBar />
          </div>
        )}
        <div className={classes.app}>
          <div className={`${classes.wrapper} ${shouldShowPrintableView ? classes.printable : ''}`}>
            <Banners />
            <AccountSwitcher />
            <UiSwitcher />
            <GlobalDialog />
            {Children.map(children, child => cloneElement(child as React.ReactElement<any>, {}))}
          </div>
        </div>
        {!shouldShowPrintableView && <Footer />}
        {(switchUiPending || switchUiSuccess) && <OverlayLoader />}
        <div className={classes.hidden}>
          <button id="ot-sdk-btn" className="ot-sdk-show-settings" />
        </div>
      </div>
    )
  }, [
    activePath.id,
    children,
    classes.app,
    classes.pathWrapper,
    classes.printable,
    classes.sidebar,
    classes.wrapper,
    classes.hidden,
    shouldShowPrintableView,
    switchUiPending,
    switchUiSuccess,
    accessTokenObject
  ])
}
