import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundMachineLearning(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      The use of machine learning algorithms can help identify and block malicious emails before they reach your inbox,
      enhancing your email security and further protecting your organization from evolving email threats.{' '}
      {props.productName} uses machine learning algorithms by default.
      <br />
      Emails blocked by machine learning will include the threat type in the{' '}
      <a href="https://campus.barracuda.com/doc/96023047/" target="_blank" rel="noreferrer">
        {' '}
        Message Log details
      </a>
      .
      <p>
        Exempt specific email addresses or domains from machine learning threat detection:
        <ul>
          <li>
            <b>Email Address/Domain</b>
          </li>
          Add an email address or domain you want to exempt from machine learning threat detection.
          <li>
            <b>Comment</b>
          </li>
          Add an optional comment.
          <li>
            <b>Actions</b>
          </li>
          Click <b>Add</b> to add the selected email address/domain, or click <b>Remove</b> to remove an entry from the
          list.
        </ul>
      </p>
    </div>
  )
}

export default InboundMachineLearning
