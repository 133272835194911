import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SenderNotification(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      When {props.productName} quarantines an outbound message, the message is listed on the <b>Outbound Quarantine</b>{' '}
      page. Quarantined emails are not sent to the recipients unless the administrator clicks
      <b>Deliver</b>. Use this page to configure administrator and sender quarantine notifications.
      <p>This page includes the following sections:</p>
      <br />
      <br />
      <a href="#admin_notification">Admin Quarantine Notification</a>
      <br />
      <a href="#sender_notification">Sender Quarantine Notification</a>
      <br />
      <a href="#rejected_message_notification">Notification to Sender of Rejected Message</a>
      <br />
      <a href="#admin_spam_notification">Admin Spam Notification</a>
      <br />
      <br />
      <h3 id="admin_notification">Admin Quarantine Notification</h3>
      Use this section to configure the frequency of notification reports sent to the administrator summarizing outbound
      quarantine information.
      <ul>
        <li>
          <b>Notification Interval</b>
          <br />
          The frequency at which the administrator receives an email report listing quarantined outbound messages.
          Select from the following options:
        </li>
        <ul>
          <li>
            <b>Never</b>
          </li>
          <li>
            <b>Immediately</b>
          </li>
          <li>
            <b>Scheduled</b>
            <br />
            Select the day and time you want notification reports sent to the administrator in the{' '}
            <b>Schedule notification intervals</b> section.
          </li>
        </ul>
        <li>
          <b>Notification Address</b>
          <br />
          Specify one or more email addresses (separated by commas) to which the reports are to be sent.
        </li>
      </ul>
      <h3 id="sender_notification">Sender Quarantine Notification</h3>
      When enabled, configure Non-Delivery Reports (NDRs) to be sent from {props.productName} to the sender of an
      outbound message quarantined for any reason.
      <ul>
        <li>
          <b>Quarantine Sender Notification</b>
          <br />
          Select <b>Yes</b> to send a notification to the sender of an outbound message that {props.productName}{' '}
          quarantines. This notification indicates to the sender that the message is quarantined and awaits evaluation
          by the administrator before delivery, rejection, or deletion. Select <b>No</b> to disable sender notification,
          for example, if you do not anticipate quarantined outgoing messages or you want to avoid additional email
          traffic to your users.
        </li>
        <li>
          <b>Quarantine Notification Address</b>
          <br />
          The &apos;from&apos; address of the NDR that the sender receives. For example, &nbsp;&nbsp;
          <code>admin@yourcompany.com</code>
        </li>
        <li>
          <b>Quarantine Notification Subject</b>
          <br />
          Enter the NDR subject that the sender receives. For example,&nbsp;&nbsp;{' '}
          <code>Message you sent violated company policy</code>
        </li>
        <li>
          <b>Quarantine Notification Template</b>
          <br />
          Displays the text to be used in the body of the NDR email. You can edit and customize this text using the
          following variables:
          <br />
          <br />
          <b>%R</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMTP recipient of the message. This is the real email address to
          which delivery was attempted.
          <br />
          <br />
          <b>%j</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject
          <br />
          <br />
          <b>%m</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Message ID
        </li>
      </ul>
      <h3 id="rejected_message_notification">Notification to Sender of Rejected Message</h3>
      Whenever an administrator rejects a message in the outbound quarantine, the sender receives an NDR indicating that
      the message was rejected and will not be delivered to the Internet.
      <ul>
        <li>
          <b>Reject Notification Address</b>
          <br />
          The &apos;from&apos; address of the NDR that the sender receives. For example: &nbsp;&nbsp;
          <code>admin@yourcompany.com</code>
        </li>
        <li>
          <b>Reject Notification Subject</b>
          <br />
          The subject of the NDR the sender receives. For example:&nbsp;&nbsp;{' '}
          <code>Message you sent will not be delivered</code>
        </li>
        <li>
          <b>Reject Notification Template</b>
          <br />
          Displays the text to be used in the body of the NDR email. You can edit and customize this text and use the
          following variables:
          <br />
          <br />
          <b>%R</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SMTP recipient of the message. This is the real email address to
          which delivery was attempted.
          <br />
          <br />
          <b>%j</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subject
          <br />
          <br />
          <b>%m</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Message ID
        </li>
      </ul>
      <h3 id="admin_spam_notification">Admin Spam Notification</h3>
      In the <b>Spam Notification Address</b> field, specify one or more email addresses (separated by commas) to which
      a notification is sent when a user is suspected of sending outbound spam. Note that this option is available to
      account admins only and does not display when logged in as a domain admin.
    </div>
  )
}

export default SenderNotification
