import { AdvancedSearchForm, ActionTaken, SearchFilter } from 'types/Messages'

const REGEX_PATTERNS = {
  PARENTHESIS: /\(|\)/g,
  FIELD_WITH_VALUES: /([^\s}]*:[^\s]*)/g,
  FIELD_WITH_VALUES_BETWEEN_PARENTHESIS: /([^\s}]*:\([^)]*\))/g,
  FIRST_COLON: /:(.+)/,
  MULTIPLE_SPACES: /\s\s+/g
}

export const createAdvancedForm = (
  advancedSearchForm: AdvancedSearchForm,
  searchString: string
): AdvancedSearchForm => {
  const tempAdvSearch = { ...advancedSearchForm }
  let tempSearch = searchString

  const updateField = (fieldName: string, value: string) => {
    tempAdvSearch[fieldName as keyof AdvancedSearchForm] = `${
      tempAdvSearch[fieldName as keyof AdvancedSearchForm] || ''
    } ${value.replace(REGEX_PATTERNS.MULTIPLE_SPACES, ' ')}`.trim()
  }

  const validateFieldsAndValues = ([fieldName, value]: string[]) => {
    const isValidFormKey = Object.prototype.hasOwnProperty.call(tempAdvSearch, fieldName)
    const isValidFieldNameWithColon = Object.keys(tempAdvSearch).some((field: string) => `${field}:` === fieldName)

    switch (true) {
      case isValidFormKey && !!value:
        updateField(fieldName, value)
        break
      case !isValidFormKey && !!value:
        updateField('basic_search_term', `${fieldName}:${value}`)
        break
      case !!fieldName && !isValidFieldNameWithColon && !value:
        updateField('basic_search_term', fieldName)
        break
      default:
    }
  }

  const collectFields = (withParenthesis = false) => {
    const pattern = withParenthesis
      ? REGEX_PATTERNS.FIELD_WITH_VALUES_BETWEEN_PARENTHESIS
      : REGEX_PATTERNS.FIELD_WITH_VALUES
    const matchedFields = tempSearch.match(pattern)

    // eslint-disable-next-line no-unused-expressions
    matchedFields?.forEach((part: string) => {
      const [fieldName, value] = part.split(REGEX_PATTERNS.FIRST_COLON)
      validateFieldsAndValues([fieldName, value?.replace(REGEX_PATTERNS.PARENTHESIS, '')])
    })

    tempSearch = tempSearch.replace(pattern, '')
  }

  // 1 - collect fields with pattern [fieldName]:(values)
  collectFields(true)
  // 2 - collect fields with pattern [fieldName]:values
  collectFields()
  // 3 - add the rest keywords to basicSearchTerm field
  updateField('basic_search_term', tempSearch)

  return tempAdvSearch
}

export const createBasicSearchTerm = (advancedSearchForm: AdvancedSearchForm) => {
  const updatedSearchString = Object.keys(advancedSearchForm).reduce((all: string, fieldName: string) => {
    const fieldValue = advancedSearchForm[fieldName as keyof AdvancedSearchForm].trim()
    if (fieldName !== 'basic_search_term' && !!fieldValue.length) {
      const isFieldHasSpace = fieldValue.match(/\s+/)

      return `${all} ${fieldName}:${isFieldHasSpace ? `(${fieldValue})` : fieldValue}`.trim()
    }

    return all
  }, '')

  return updatedSearchString
}

export const generateFilterString = (searchFilter: SearchFilter) => {
  const filterString = Object.entries(searchFilter).reduce((query: string, [key, val]) => {
    if (val !== ActionTaken.any) {
      switch (key) {
        default:
          return `${query} ${key}:${val}`.trimStart()
      }
    }

    return query
  }, '')

  return filterString
}
