import React, { useMemo, useRef, RefObject, useEffect, useState, useCallback, useLayoutEffect } from 'react'

interface State {
  isExpandOpen: boolean
  hasHiddenActiveFilter: boolean
  containerRef: RefObject<HTMLDivElement>
  fixedContainerRef: RefObject<HTMLDivElement>
  childrenRefs: React.MutableRefObject<HTMLDivElement[]>
  expandButtonRef: RefObject<HTMLDivElement>
  rowChildren: React.ReactNode[]
  expandChildren: React.ReactNode[]
}

interface EventHandlers {
  handleClick: () => void
  handleClose: () => void
}

type UseFluidRowLogic = [State, EventHandlers]

export interface FluidRowLogicProps {
  children: React.ReactNode[]
  fixedContainer?: React.ReactNode
  buttonText: string
}

const calculateWidth = (element: HTMLDivElement): number =>
  element.getBoundingClientRect().width +
  parseInt(window.getComputedStyle(element).marginLeft, 10) +
  parseInt(window.getComputedStyle(element).marginRight, 10)

const calculateMinWidth = (element: Element): number =>
  parseInt(window.getComputedStyle(element).minWidth, 10) +
  parseInt(window.getComputedStyle(element).marginLeft, 10) +
  parseInt(window.getComputedStyle(element).marginRight, 10)

export const useFluidRowLogic = (children: React.ReactNode[]): UseFluidRowLogic => {
  const containerRef = useRef<HTMLDivElement>(null)
  const fixedContainerRef = useRef<HTMLDivElement>(null)
  const childrenRefs = useRef<HTMLDivElement[]>([])
  const expandButtonRef = useRef<HTMLDivElement>(null)
  const [isExpandOpen, setIsExpandOpen] = useState<boolean>(false)
  const [hasHiddenActiveFilter, setHasHiddenActiveFilter] = useState<boolean>(false)
  const [splittedChildren, setSplittedChildren] = useState<React.ReactNode[][]>([[], []])
  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [childContainerWidth, setChildContainerWidth] = useState<number>(0)
  const [childWidths, setChildWidths] = useState<number[]>([])

  const handleClick = useCallback(() => {
    setIsExpandOpen(!isExpandOpen)
  }, [isExpandOpen])

  const handleClose = useCallback(() => {
    setIsExpandOpen(false)
  }, [])

  const calculateSize = useCallback(() => {
    if (containerRef.current) {
      let width = containerRef.current.offsetWidth
      if (fixedContainerRef.current) {
        width -= calculateMinWidth(fixedContainerRef.current.children[0])
      }
      setContainerWidth(containerRef.current.offsetWidth)
      setChildContainerWidth(width)
      setChildWidths(childrenRefs.current.map(child => calculateWidth(child)))
    }
  }, [])

  useLayoutEffect(() => {
    if (children.length === 1) {
      setSplittedChildren([[children[0]], []])
    }
    if (childWidths.length > 1 && childContainerWidth > 0) {
      let totalWidth = 0
      if (expandButtonRef.current) {
        totalWidth += calculateWidth(expandButtonRef.current)
      }

      const firstGroup: React.ReactNode[] = []
      const secondGroup: React.ReactNode[] = []
      for (let i = 0; i < childWidths.length; i++) {
        if (totalWidth + childWidths[i] <= childContainerWidth) {
          firstGroup.push(children[i])
          totalWidth += childWidths[i]
        } else {
          secondGroup.push(children[i])
        }
      }

      if (secondGroup.length) {
        const nextElementToShow = childWidths[firstGroup.length]
        let accumulatedWidth = 0
        if (fixedContainerRef.current && nextElementToShow) {
          const fixedMinWidth = calculateMinWidth(fixedContainerRef.current.children[0])
          accumulatedWidth = fixedMinWidth + totalWidth + nextElementToShow
          if (secondGroup.length === 1 && expandButtonRef.current) {
            accumulatedWidth -= calculateWidth(expandButtonRef.current)
          }
        } else {
          accumulatedWidth = totalWidth + nextElementToShow
          if (secondGroup.length === 1 && expandButtonRef.current) {
            accumulatedWidth -= calculateWidth(expandButtonRef.current)
          }
        }

        if (containerWidth >= accumulatedWidth) {
          firstGroup.push(secondGroup[0])
          secondGroup.pop()
        }
      }

      setSplittedChildren([firstGroup, secondGroup])
    }
  }, [children, containerWidth, childContainerWidth, childWidths])

  useEffect(() => {
    setHasHiddenActiveFilter(
      childrenRefs.current
        .slice(splittedChildren[0].length)
        .some(child => child?.getElementsByClassName('active').length)
    )
  }, [splittedChildren])

  useEffect(() => {
    calculateSize()
    window.addEventListener('resize', calculateSize)
    return () => {
      window.removeEventListener('resize', calculateSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => [
      {
        isExpandOpen,
        hasHiddenActiveFilter,
        containerRef,
        fixedContainerRef,
        childrenRefs,
        expandButtonRef,
        rowChildren: splittedChildren[0],
        expandChildren: splittedChildren[1]
      },
      {
        handleClick,
        handleClose
      }
    ],
    [
      isExpandOpen,
      hasHiddenActiveFilter,
      containerRef,
      fixedContainerRef,
      childrenRefs,
      splittedChildren,
      handleClick,
      handleClose
    ]
  )
}
