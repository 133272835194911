import { useMemo } from 'react'

import { InfoDialogProps } from 'components/libs/dialog/infoDialog/InfoDialog'

export interface InfoDialogLogicConfig {
  open: boolean
  onClose: InfoDialogProps['onClose']
}

export type InfoDialogLogic = [
  {
    isDialogOpened: boolean
    onHandleClose: () => void
  }
]

export const useInfoDialogLogic = ({ onClose, open }: InfoDialogLogicConfig): InfoDialogLogic =>
  useMemo(
    () => [
      {
        isDialogOpened: open,
        onHandleClose: onClose
      }
    ],
    [onClose, open]
  )
