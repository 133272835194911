import { colors, createStyles, makeStyles } from '@barracuda-internal/bds-core'

export const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      '& [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration': {
        '-webkit-appearance': 'none',
        appearance: 'none'
      },
      '& :disabled': {
        cursor: 'default',
        backgroundColor: colors.scaleGray025
      }
    },
    searchButtonDisabled: {
      cursor: 'default',
      backgroundColor: colors.scaleGray025
    }
  })
)
