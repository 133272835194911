/* eslint-disable quotes */
const outbound_settings = {
  tabs: {
    sender_ip: 'Rangos de direcciones IP del remitente',
    tagline: 'Lema/Pie',
    encryption: 'DLP/Encriptación',
    content_policies: 'Políticas de Contenidos',
    notifications: 'Notificaciones'
  },
  save: 'Guardar cambios',
  cancel: 'Cancelar',
  bulk_edit: 'Edición masiva',
  sender_ip: {
    title: 'Rangos de direcciones IP del remitente',
    subtitle:
      'Especifique los rangos de direcciones IP que pueden enviar correos electrónicos salientes desde sus dominios.',
    domain_column: 'Registro y dominio de políticas',
    ip_column: 'Dirección IP',
    netmask: 'Máscara de red',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    bulk_edit: 'Edición masiva',
    confirm_removal: '¿Confirmar eliminación?',
    empty_domain: 'Especifique un nombre de dominio',
    invalid_ip_address: 'Introduzca una dirección IP válida'
  },
  tagline: {
    title: 'Lema/Pie',
    append_label: 'Añadir eslogan',
    append_description: 'Añada el siguiente texto a los mensajes de correo electrónico salientes',
    tagline_label: 'Texto del eslogan/pie de página',
    characters_left: 'Caracteres a la izquierda: {charactersLeft}'
  },
  encryption: {
    title: 'DLP/Encriptación',
    all_messages: 'Requerir conexión TLS para todos los mensajes salientes',
    selected_domains: 'Requerir conexión TLS para determinados dominios de destinatarios',
    table_description_all:
      'Para forzar a Email Gateway Defense a exigir una conexión TLS segura a todos los dominios <b>excepto</b> un determinado dominio de destinatario, introduzca el nombre del dominio de destinatario exento y haga clic en Agregar.',
    table_description_select:
      'Para forzar a Email Gateway Defense a exigir una conexión TLS segura a un dominio de destinatario, introduzca el nombre del dominio de destinatario y haga clic en Agregar.',
    table_sub_description:
      'Si no se puede establecer una conexión TLS, el correo no se envía al dominio del destinatario.',
    save_changes_helper: 'Guardar los cambios para habilitar la edición masiva'
  },
  content_policies: {
    title: 'Políticas de Contenidos',
    attachment_filter: 'Filtro de fijación',
    filter_type: 'Tipo de filtro',
    pattern: 'Patrón',
    check_archives: 'Comprobar archivos',
    action: 'Acción',
    comment: 'Comentario',
    actions: 'Acciones',
    add: 'Añadir',
    remove: 'Eliminar',
    no: 'No',
    yes: 'Sí',
    scan: 'Escanear',
    do_not_scan: 'No escanear',
    block: 'Bloque',
    ignore: 'Ignore',
    allow: 'Permitir',
    quarantine: 'Cuarentena',
    encrypt: 'Encriptar',
    not_encrypt: 'No encriptar',
    filename: 'Nombre del archivo',
    mime: 'Tipo MIME',
    message_content_filter: 'Filtro de contenido de mensajes',
    subject: 'Asunto',
    headers: 'Cabeceras',
    body: 'Cuerpo',
    attachments: 'Archivos adjuntos',
    sender: 'Remitente',
    recipient: 'Destinatario',
    match: 'Partido',
    one_match_filter: 'Debe seleccionarse al menos un filtro de coincidencia.',
    duplicate_value: 'Existe una entrada duplicada.',
    archive_filtering: 'Filtrado de archivos protegido por contraseña:',
    msoffice_filtering: 'Documentos de Microsoft Office protegidos con contraseña:',
    pdf_filtering: 'Filtrado de PDF protegidos por contraseña:',
    predefined_filters: 'Filtros predefinidos',
    predefined_filters_description:
      'Filtre los mensajes salientes en busca de patrones predefinidos de fuga de datos (específicos de EE.UU.) en el asunto, el cuerpo y los archivos adjuntos. Acción recomendada: Cifrar',
    creditcard: 'Tarjetas de crédito',
    ssn: 'Números de la Seguridad Social',
    privacy: 'Privacidad',
    hipaa: 'HIPAA',
    predefined_filter_exceptions: 'Excepciones de filtro predefinidas',
    predefined_filter_exceptions_description:
      'Añada exenciones a los filtros de contenido predefinidos de HIPAA o Privacidad.',
    type: 'Tipo',
    phone_number: 'Número de teléfono',
    address: 'Dirección',
    missing_filtering_pattern: 'Falta el patrón de filtrado.',
    error_invalid_phone_number: 'Número de teléfono no válido',
    save: 'Guardar'
  },
  notifications: {
    title: 'Notificaciones',
    admin_quarantine_notification: 'Notificación de cuarentena administrativa',
    admin_quarantine_notification_description:
      'Configure la frecuencia de los informes de notificación que recibe el administrador en los que se resume la información de la cuarentena saliente.',
    notification_interval: 'Intervalo de notificación',
    never: 'Nunca',
    immediately: 'Inmediatamente',
    scheduled: 'Programado',
    schedule_notification_intervals: 'Programar intervalos de notificación',
    matrix_description:
      'Las notificaciones se enviarán en cualquier momento dentro del bloque de horas elegido. <br></br> Haga clic y arrastre para seleccionar. Mantenga pulsada la tecla Ctrl mientras arrastra para anular la selección.',
    notification_address: 'Dirección de notificación',
    sender_quarantine_notification: 'Notificación de cuarentena del remitente',
    sender_quarantine_notification_description:
      'Configure el Informe de no entrega (NDR) que, si está activado, se envía al remitente de un mensaje saliente que ha sido puesto en cuarentena por cualquier motivo. Este NDR indica que el mensaje está pendiente de evaluación y acción por parte del administrador.',
    quarantine_sender_notification: 'Notificación de remitente en cuarentena',
    yes: 'Sí',
    no: 'No',
    quarantine_notification_address: 'Dirección de notificación de cuarentena',
    quarantine_notification_subject: 'Asunto de la notificación de cuarentena',
    quarantine_notification_subject_placeholder: '**Mensaje enviado violando la política**',
    quarantine_notification_template: 'Plantilla de notificación de cuarentena',
    quarantine_notification_template_placeholder:
      'Su mensaje a: %R fue puesto en cuarentena por Email Gateway Defense de Barracuda Networks. El correo electrónico que envió con el siguiente asunto NO HA SIDO ENTREGADO y está a la espera de ser evaluado por el administrador de la cuenta o del dominio. \n\nAsunto: %jj',
    notification_to_sender: 'Notificación al remitente de mensaje rechazado',
    notification_to_sender_description:
      'Configure el Informe de no entrega (NDR) que se envía por correo electrónico al remitente de un mensaje saliente en cuarentena si dicho mensaje es rechazado por el administrador.',
    reject_notification_address: 'Dirección de notificación de rechazo',
    reject_notification_subject: 'Asunto de la notificación de rechazo',
    reject_notification_subject_placeholder: '**Mensaje enviado violando la política**',
    reject_notification_template: 'Plantilla de notificación de rechazo',
    reject_notification_template_placeholder:
      'Su mensaje a: %R ha sido rechazado por el administrador del dominio y NO SERÁ ENTREGADO. Por favor, póngase en contacto con su administrador de correo electrónico. \n\nAsunto: %j',
    admin_spam_notification: 'Notificación de spam',
    admin_spam_notification_description:
      'Configure la dirección de correo electrónico que recibe notificaciones cuando se sospecha que los usuarios envían spam saliente.',
    spam_notification_address: 'Dirección de notificación de spam',
    invalid_email:
      '{email} no es una dirección de correo electrónico válida. Asegúrate de que no haya espacios después de la coma.',
    email_belong_to_manage_domain:
      'La dirección de correo electrónico debe pertenecer a un dominio que usted gestione.',
    blank_email: 'Introduzca su dirección de correo electrónico'
  }
}
/* eslint-enable quotes */

export default outbound_settings
