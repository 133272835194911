interface ApiRoutes {
  [index: string]: ApiRoute
}

interface ApiRoute {
  method: string
  path: string
  metadata?: string
}

interface UrlParams {
  [key: string]: string
}

export default function initApiRoutes({ API_ENDPOINTS }: { API_ENDPOINTS: any }) {
  function flattenRoutes(routes: any, collectedAll = {}): ApiRoutes {
    return Object.entries(routes).reduce((all: ApiRoutes, [key, route]) => {
      if (!(route as ApiRoute).path) {
        return flattenRoutes(route, all)
      }

      return {
        ...all,
        [key]: route
      }
    }, collectedAll)
  }

  const flattenedRoutes: ApiRoutes = flattenRoutes(API_ENDPOINTS)

  return Object.keys(flattenedRoutes).reduce(
    (all, apiRoute) => ({
      ...all,
      [apiRoute]: {
        ...flattenedRoutes[apiRoute],
        path: (urlParams: UrlParams = {}) => {
          const path = Object.keys(urlParams).reduce(
            (updatedPath, urlParam) => updatedPath.replace(`:${urlParam}`, urlParams[urlParam]),
            flattenedRoutes[apiRoute].path
          )

          return `${path}`
        },
        url: flattenedRoutes[apiRoute].path
      }
    }),
    {}
  )
}
