import { InboundScheduleUI } from 'types/Settings'

export interface ScheduleMatrixProps {
  isEnabled: boolean
  customInboundSchedule?: InboundScheduleUI
  doUpdate: (schedule: InboundScheduleUI) => void
}

export const EMPTY_SCHEDULE_MATRIX: InboundScheduleUI = {
  mon: {},
  tue: {},
  wed: {},
  thu: {},
  fri: {},
  sat: {},
  sun: {}
}
