import React, { PropsWithChildren, useMemo } from 'react'

import { Grid, Typography, TypographyProps } from '@barracuda-internal/bds-core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import styles from 'components/libs/layout/pages/pagesLayoutStyles'
import { FormSpacer } from 'components/libs/layout/pages/FormSpacer'

export type FormBlockProps = {
  title?: string
  titleVariant?: TypographyProps['variant']
  collapsible?: boolean
  collapsed?: boolean
  onClickTitle?: () => void
  description?: string
  bottomSpacerCount?: number
}

export const FormBlock = ({
  title = '',
  titleVariant = 'h4',
  collapsible = false,
  collapsed = false,
  children,
  onClickTitle,
  description,
  bottomSpacerCount = 2
}: PropsWithChildren<FormBlockProps>) => {
  const classes = styles()

  const titleWithIcon = useMemo(() => {
    if (!collapsible) {
      return title
    }

    const icon = collapsed ? <ExpandMore /> : <ExpandLess />
    return (
      <>
        {title} {icon}
      </>
    )
  }, [collapsed, collapsible, title])

  return useMemo(
    () => (
      <Grid container direction="column">
        <Grid container direction="row" alignItems="center">
          <Typography
            onClick={onClickTitle}
            className={`${classes.blockTitle} ${collapsible ? 'collapsible' : ''}`}
            variant={titleVariant}
          >
            {titleWithIcon}
          </Typography>
        </Grid>
        {description && (
          <>
            <FormSpacer />
            <Typography variant="body1">{description}</Typography>
            <FormSpacer />
          </>
        )}
        {(!collapsible || (collapsible && !collapsed)) && <Grid container>{children}</Grid>}
        {Array(bottomSpacerCount)
          .fill(null)
          .map((c, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <FormSpacer key={idx} />
          ))}
      </Grid>
    ),
    [
      onClickTitle,
      classes.blockTitle,
      collapsible,
      titleVariant,
      titleWithIcon,
      description,
      collapsed,
      children,
      bottomSpacerCount
    ]
  )
}
