import React from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import productLogo from 'assets/images/barracuda/barracuda-teeth.png'
import styles from 'components/libs/loading/loadingStlyles'

const BASE_I18N_KEY = 'ess'

function Loading() {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return (
    <Grid className={classes.container} container justifyContent="center" alignContent="center" direction="column">
      <Grid item>
        <img className={classes.logo} alt="" src={productLogo} />
      </Grid>
      <Grid item>
        <Typography variant="h4" className={classes.text}>
          {formatMessage('loading')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Loading
