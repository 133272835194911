import { LocalStorageKeys } from 'lib/types/localStorageTypes'
import { removeSessionCookie } from 'lib/cookies'

// Always bump the version when you made changes in any of the stored stores
const LOCAL_STORAGE_VERSION = '1.0'

const version = localStorage.getItem(LocalStorageKeys.version)

// version check on Init
if (version !== LOCAL_STORAGE_VERSION) {
  // reset the session info and localStorage
  localStorage.clear()
  localStorage.setItem(LocalStorageKeys.version, LOCAL_STORAGE_VERSION)
  removeSessionCookie()
}
