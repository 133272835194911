import { colors, makeStyles } from '@barracuda-internal/bds-core'
import pagesStyles from 'components/pages/pagesStyles'

const styles = makeStyles(theme => ({
  adminEmailRow: {
    marginTop: theme.spacing(1.5)
  },
  sectionNote: {
    marginBottom: theme.spacing(3)
  },
  italic: {
    fontStyle: 'italic'
  },
  tableTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tableSpacing: {
    marginTop: theme.spacing(5)
  },
  atpScanHelper: {
    fontWeight: 400,
    fontSize: 14
  }
}))

export default () => ({
  ...pagesStyles(),
  ...styles()
})
