import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  getDomainList,
  addDomain,
  deleteDomain,
  manageDomain,
  getDomainForVerification,
  verifyDomain,
  getDomain,
  getVerifiedDomainList,
  getPdDomain,
  getUnaliasedDomainNames,
  saveDomainSettings,
  SaveDomainSettingsResponse,
  resetDomainSettings,
  ResetDomainSettingsResponse,
  resetEditDomain,
  AddDomainResponse,
  getMoveDomain,
  GetMoveDomainResponse,
  postVerifyDomainTransfer,
  deleteDomainMove,
  getDomainTransferStates,
  GetDomainTransferStatesResponse
} from 'redux/features/domains/domainsApiThunks'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'
import {
  clearDomainUniqueSettings,
  insertDomainMailServer,
  removeDomainMailServer,
  updateDomainMailServer,
  resetAddDomain,
  resetDeleteDomain,
  resetManageDomain,
  resetVerifyDomain,
  resetGetUnaliasedDomainNames,
  resetSaveDomainSettings,
  resetGetDomain,
  resetGetDomainForVerification
} from 'redux/features/domains/domainsActions'
import { Domain, EmailServer, DomainNameById, DomainMoveState } from 'types/domains'

export interface DomainsState {
  api: {
    getDomainListApiStatus: ApiStatus
    addDomainApiStatus: ApiStatus
    deleteDomainApiStatus: ApiStatus
    manageDomainApiStatus: ApiStatus
    getDomainForVerificationApiStatus: ApiStatus
    verifyDomainApiStatus: ApiStatus
    getDomainApiStatus: ApiStatus
    getVerifiedDomainListApiStatus: ApiStatus
    getPdDomainApiStatus: ApiStatus
    getUnaliasedDomainNamesApiStatus: ApiStatus
    saveDomainSettingsApiStatus: ApiStatus
    resetDomainSettingsApiStatus: ApiStatus
    getMoveDomainApiStatus: ApiStatus
    postVerifyDomainTransferApiStatus: ApiStatus
    deleteDomainMoveApiStatus: ApiStatus
    getDomainTransferStatesApiStatus: ApiStatus
  }
  domainList: Domain[]
  verifiedDomainList: Domain[]
  totalDomainCount: number
  domain: Domain | undefined
  domainNameFilter: string | undefined
  pdDomain: Domain | undefined
  unaliasedDomainNames: DomainNameById[] | undefined
  saveDomainSettingsResult: SaveDomainSettingsResponse | undefined
  resetDomainSettingsResult: ResetDomainSettingsResponse | undefined
  addDomainResponse: AddDomainResponse | undefined
  moveDomain: GetMoveDomainResponse | undefined
  domainTransferStates: GetDomainTransferStatesResponse
}

// initialState
export const INITIAL_STATE: DomainsState = {
  api: {
    getDomainListApiStatus: inIdle,
    addDomainApiStatus: inIdle,
    deleteDomainApiStatus: inIdle,
    manageDomainApiStatus: inIdle,
    getDomainForVerificationApiStatus: inIdle,
    verifyDomainApiStatus: inIdle,
    getDomainApiStatus: inIdle,
    getVerifiedDomainListApiStatus: inIdle,
    getPdDomainApiStatus: inIdle,
    getUnaliasedDomainNamesApiStatus: inIdle,
    saveDomainSettingsApiStatus: inIdle,
    resetDomainSettingsApiStatus: inIdle,
    getMoveDomainApiStatus: inIdle,
    postVerifyDomainTransferApiStatus: inIdle,
    deleteDomainMoveApiStatus: inIdle,
    getDomainTransferStatesApiStatus: inIdle
  },
  domainList: [],
  verifiedDomainList: [],
  totalDomainCount: 0,
  domain: undefined,
  domainNameFilter: undefined,
  pdDomain: undefined,
  unaliasedDomainNames: undefined,
  saveDomainSettingsResult: undefined,
  resetDomainSettingsResult: undefined,
  addDomainResponse: undefined,
  moveDomain: undefined,
  domainTransferStates: {}
}

/* eslint-disable no-param-reassign */
export const domainsSlice = createSlice({
  name: 'DOMAINS',
  initialState: INITIAL_STATE,
  reducers: {
    resetDomainList: state => {
      state.api.getDomainListApiStatus = INITIAL_STATE.api.getDomainListApiStatus
      state.domainList = INITIAL_STATE.domainList
      state.totalDomainCount = INITIAL_STATE.totalDomainCount
    },
    reset: () => ({
      ...INITIAL_STATE
    }),
    removeDomainFromList: (state, action: PayloadAction<string>) => {
      state.domainList = state.domainList.filter(item => item.domainId !== action.payload)
    },
    setDomainNameFilter: (state, action: PayloadAction<string | undefined>) => {
      state.domainNameFilter = action.payload
    },
    resetMoveDomain: state => {
      state.api.getMoveDomainApiStatus = INITIAL_STATE.api.getMoveDomainApiStatus
      state.moveDomain = INITIAL_STATE.moveDomain
    },
    resetVerifyDomainTransfer: state => {
      state.api.postVerifyDomainTransferApiStatus = INITIAL_STATE.api.postVerifyDomainTransferApiStatus
    },
    resetDeleteDomainMove: state => {
      state.api.deleteDomainMoveApiStatus = INITIAL_STATE.api.deleteDomainMoveApiStatus
    },
    resetGetDomainTransferStates: state => {
      state.api.getDomainTransferStatesApiStatus = INITIAL_STATE.api.getDomainTransferStatesApiStatus
      state.domainTransferStates = INITIAL_STATE.domainTransferStates
    },
    setDomainTransferComplete: (state, action: PayloadAction<string>) => {
      const idx = state.domainList.findIndex(d => d.domainId === action.payload)
      if (idx === -1) {
        return
      }
      const nextDomainList = [...state.domainList]
      nextDomainList[idx].domainMoveState = DomainMoveState.TRANSFER_COMPLETE
      state.domainList = nextDomainList
    }
  },
  extraReducers: builder => {
    builder
      .addCase(insertDomainMailServer, (state, action: PayloadAction<EmailServer>) => {
        if (state.domain) {
          state.domain.mailServers.push(action.payload)
        }
      })
      .addCase(removeDomainMailServer, (state, action: PayloadAction<string>) => {
        if (state.domain) {
          const idx = state.domain.mailServers.findIndex(item => item.id === action.payload)
          if (idx > -1) {
            state.domain.mailServers.splice(idx, 1)
          }
        }
      })
      .addCase(updateDomainMailServer, (state, action: PayloadAction<EmailServer>) => {
        if (state.domain) {
          const idx = state.domain.mailServers.findIndex(item => item.id === action.payload.id)
          if (idx > -1) {
            state.domain.mailServers.splice(idx, 1, action.payload)
          }
        }
      })
      .addCase(clearDomainUniqueSettings, (state, action: PayloadAction<string>) => {
        if (state.domain && state.domain.domainId === action.payload && state.domain.uniqueSettings) {
          state.domain.uniqueSettings = undefined
        }
        if (state.domainList) {
          const idx = state.domainList.findIndex(item => item.domainId === action.payload)
          if (idx > -1) {
            state.domainList[idx].uniqueSettings = undefined
          }
        }
      })
      .addCase(resetAddDomain, state => {
        state.api.addDomainApiStatus = INITIAL_STATE.api.addDomainApiStatus
        state.addDomainResponse = INITIAL_STATE.addDomainResponse
      })
      .addCase(resetDeleteDomain, state => {
        state.api.deleteDomainApiStatus = INITIAL_STATE.api.deleteDomainApiStatus
      })
      .addCase(resetManageDomain, state => {
        state.api.manageDomainApiStatus = INITIAL_STATE.api.manageDomainApiStatus
      })
      .addCase(resetVerifyDomain, state => {
        state.api.verifyDomainApiStatus = INITIAL_STATE.api.verifyDomainApiStatus
        state.domain = INITIAL_STATE.domain
      })
      .addCase(resetGetUnaliasedDomainNames, state => {
        state.api.getUnaliasedDomainNamesApiStatus = INITIAL_STATE.api.getUnaliasedDomainNamesApiStatus
        state.unaliasedDomainNames = INITIAL_STATE.unaliasedDomainNames
      })
      .addCase(resetSaveDomainSettings, state => {
        state.api.saveDomainSettingsApiStatus = INITIAL_STATE.api.saveDomainSettingsApiStatus
        state.saveDomainSettingsResult = INITIAL_STATE.saveDomainSettingsResult
      })
      .addCase(resetGetDomain, state => {
        state.api.getDomainApiStatus = INITIAL_STATE.api.getDomainApiStatus
        state.domain = INITIAL_STATE.domain
      })
      .addCase(resetGetDomainForVerification, state => {
        state.api.getDomainApiStatus = INITIAL_STATE.api.getDomainForVerificationApiStatus
        state.domain = INITIAL_STATE.domain
      })

      // getDomainList
      .addCase(getDomainList.pending, state => {
        state.api.getDomainListApiStatus = inProgress
      })
      .addCase(getDomainList.fulfilled, (state, action) => {
        state.api.getDomainListApiStatus = successResponse
        state.domainList = action.payload.items
        state.totalDomainCount = action.payload.totalCount
      })
      .addCase(getDomainList.rejected, (state, action) => {
        state.api.getDomainListApiStatus = failedResponse(action.payload)
      })

      // addDomain
      .addCase(addDomain.pending, state => {
        state.api.addDomainApiStatus = inProgress
      })
      .addCase(addDomain.fulfilled, (state, action) => {
        state.api.addDomainApiStatus = successResponse
        state.addDomainResponse = action.payload
      })
      .addCase(addDomain.rejected, (state, action) => {
        state.api.addDomainApiStatus = failedResponse(action.payload)
      })

      // deleteDomain
      .addCase(deleteDomain.pending, state => {
        state.api.deleteDomainApiStatus = inProgress
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        state.api.deleteDomainApiStatus = successResponse
        state.domainList = state.domainList.filter(item => item.domainId !== action.payload.domainId)
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        state.api.deleteDomainApiStatus = failedResponse(action.payload)
      })

      // manageDomain
      .addCase(manageDomain.pending, state => {
        state.api.manageDomainApiStatus = inProgress
      })
      .addCase(manageDomain.fulfilled, state => {
        state.api.manageDomainApiStatus = successResponse
      })
      .addCase(manageDomain.rejected, (state, action) => {
        state.api.manageDomainApiStatus = failedResponse(action.payload)
      })

      // getDomainForVerification
      .addCase(getDomainForVerification.pending, state => {
        state.api.getDomainForVerificationApiStatus = inProgress
      })
      .addCase(getDomainForVerification.fulfilled, (state, action) => {
        state.api.getDomainForVerificationApiStatus = successResponse
        state.domain = action.payload
      })
      .addCase(getDomainForVerification.rejected, (state, action) => {
        state.api.getDomainForVerificationApiStatus = failedResponse(action.payload)
      })

      // verifyDomain
      .addCase(verifyDomain.pending, state => {
        state.api.verifyDomainApiStatus = inProgress
      })
      .addCase(verifyDomain.fulfilled, state => {
        state.api.verifyDomainApiStatus = successResponse
      })
      .addCase(verifyDomain.rejected, (state, action) => {
        state.api.verifyDomainApiStatus = failedResponse(action.payload)
      })

      // getDomain
      .addCase(getDomain.pending, state => {
        state.api.getDomainApiStatus = inProgress
      })
      .addCase(getDomain.fulfilled, (state, action) => {
        state.api.getDomainApiStatus = successResponse
        state.domain = action.payload
      })
      .addCase(getDomain.rejected, (state, action) => {
        state.api.getDomainApiStatus = failedResponse(action.payload)
      })

      // getVerifiedDomainList
      .addCase(getVerifiedDomainList.pending, state => {
        state.api.getVerifiedDomainListApiStatus = inProgress
      })
      .addCase(getVerifiedDomainList.fulfilled, (state, action) => {
        state.api.getVerifiedDomainListApiStatus = successResponse
        state.verifiedDomainList = action.payload
      })
      .addCase(getVerifiedDomainList.rejected, (state, action) => {
        state.api.getVerifiedDomainListApiStatus = failedResponse(action.payload)
      })

      // getDomainByDomainId
      .addCase(getPdDomain.pending, state => {
        state.api.getPdDomainApiStatus = inProgress
      })
      .addCase(getPdDomain.fulfilled, (state, action) => {
        state.api.getPdDomainApiStatus = successResponse
        state.pdDomain = action.payload
      })
      .addCase(getPdDomain.rejected, (state, action) => {
        state.api.getPdDomainApiStatus = failedResponse(action.payload)
      })

      // getUnaliasedDomainNames
      .addCase(getUnaliasedDomainNames.pending, state => {
        state.api.getUnaliasedDomainNamesApiStatus = inProgress
      })
      .addCase(getUnaliasedDomainNames.fulfilled, (state, action) => {
        state.api.getUnaliasedDomainNamesApiStatus = successResponse
        state.unaliasedDomainNames = action.payload
      })
      .addCase(getUnaliasedDomainNames.rejected, (state, action) => {
        state.api.getUnaliasedDomainNamesApiStatus = failedResponse(action.payload)
      })

      // updateDomainSettings
      .addCase(saveDomainSettings.pending, state => {
        state.api.saveDomainSettingsApiStatus = inProgress
      })
      .addCase(saveDomainSettings.fulfilled, (state, action) => {
        state.api.saveDomainSettingsApiStatus = successResponse
        state.saveDomainSettingsResult = action.payload
      })
      .addCase(saveDomainSettings.rejected, (state, action) => {
        state.api.saveDomainSettingsApiStatus = failedResponse(action.payload)
      })

      // resetDomainSettings
      .addCase(resetDomainSettings.pending, state => {
        state.api.resetDomainSettingsApiStatus = inProgress
      })
      .addCase(resetDomainSettings.fulfilled, (state, action) => {
        state.api.resetDomainSettingsApiStatus = successResponse
        state.resetDomainSettingsResult = action.payload
      })
      .addCase(resetDomainSettings.rejected, (state, action) => {
        state.api.resetDomainSettingsApiStatus = failedResponse(action.payload)
      })

      // getMoveDomain
      .addCase(getMoveDomain.pending, state => {
        state.api.getMoveDomainApiStatus = inProgress
      })
      .addCase(getMoveDomain.fulfilled, (state, action) => {
        state.api.getMoveDomainApiStatus = successResponse
        state.moveDomain = action.payload
      })
      .addCase(getMoveDomain.rejected, (state, action) => {
        state.api.getMoveDomainApiStatus = failedResponse(action.payload)
      })

      // postVerifyDomainTransfer
      .addCase(postVerifyDomainTransfer.pending, state => {
        state.api.postVerifyDomainTransferApiStatus = inProgress
      })
      .addCase(postVerifyDomainTransfer.fulfilled, state => {
        state.api.postVerifyDomainTransferApiStatus = successResponse
      })
      .addCase(postVerifyDomainTransfer.rejected, (state, action) => {
        state.api.postVerifyDomainTransferApiStatus = failedResponse(action.payload)
      })

      // deleteDomainMove
      .addCase(deleteDomainMove.pending, state => {
        state.api.deleteDomainMoveApiStatus = inProgress
      })
      .addCase(deleteDomainMove.fulfilled, state => {
        state.api.deleteDomainMoveApiStatus = successResponse
      })
      .addCase(deleteDomainMove.rejected, (state, action) => {
        state.api.deleteDomainMoveApiStatus = failedResponse(action.payload)
      })

      // getDomainTransferStates
      .addCase(getDomainTransferStates.pending, state => {
        state.api.getDomainTransferStatesApiStatus = inProgress
      })
      .addCase(getDomainTransferStates.fulfilled, (state, action) => {
        state.api.getDomainTransferStatesApiStatus = successResponse
        state.domainTransferStates = action.payload
      })
      .addCase(getDomainTransferStates.rejected, (state, action) => {
        state.api.getDomainTransferStatesApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const {
  resetDomainList,
  reset,
  setDomainNameFilter,
  resetMoveDomain,
  resetVerifyDomainTransfer,
  resetDeleteDomainMove,
  resetGetDomainTransferStates,
  setDomainTransferComplete
} = domainsSlice.actions

export {
  getDomainList,
  addDomain,
  deleteDomain,
  manageDomain,
  getDomainForVerification,
  verifyDomain,
  getDomain,
  getVerifiedDomainList,
  getPdDomain,
  getUnaliasedDomainNames,
  saveDomainSettings,
  resetDomainSettings,
  resetEditDomain,
  resetAddDomain,
  getMoveDomain,
  postVerifyDomainTransfer,
  deleteDomainMove,
  getDomainTransferStates
}

export default domainsSlice.reducer
