import React from 'react'

import { Select } from '@barracuda-internal/bds-core'
import { SelectProps } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'

// Force to use white bg for Select component
const customStyles = makeStyles(() =>
  createStyles({
    select: {
      backgroundColor: 'white',
      '&:focus': {
        backgroundColor: 'white'
      }
    }
  })
)

export interface CustomBDSSelectProps extends SelectProps {
  children: any
}

function CustomBDSSelect({ children, ...rest }: CustomBDSSelectProps) {
  const classes = customStyles()

  return (
    <Select
      {...rest}
      classes={{
        select: classes.select
      }}
      // Fix the dropdown position
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 10,
          horizontal: 'left'
        }
      }}
    >
      {children}
    </Select>
  )
}

export default CustomBDSSelect
