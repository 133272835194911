import { SettingValue } from 'types/Settings'

export interface LdapSettings {
  // LDAP Configuration fields
  host: string
  port: number
  ssl: SettingValue
  bindDn: string
  baseDn: string
  attrs: string

  // Advanced LDAP configuration fields
  domainLimited?: SettingValue
  userFilter?: string

  // LDAP directory options (ldap_auth and ldap_sync has their dedicated setting names)
  authFilter: string

  bindPwE?: string
}

export interface LdapSyncStats {
  usersNew: number
  usersUpdated: number
  domainId: number
  usersDeleted: number
  accountId: string
}

export interface LdapUserEntry {
  bindDn: string
  attrs: Record<string, string[] | null>
  mailAttributeValue: string | null
}

export enum LdapConnectionTestResult {
  NO_HOST_SPECIFIED = 'no_host_specified',
  NO_BIND_DN_SPECIFIED = 'no_bind_dn_specified',
  NO_PASSWORD_SPECIFIED = 'no_password_specified',
  NO_CONNECTION = 'no_connection',
  BIND_FAILED = 'bind_failed',
  UNKNOWN_ERROR = 'unknown_error',
  CONNECTION_SUCCESSFUL = 'connection_successful'
}

export enum LdapUserLookupTestResult {
  USER_LOOKUP_NOT_EXECUTED = 'user_lookup_not_executed',
  USER_LOOKUP_EMPTY = 'user_lookup_empty',
  USER_LOOKUP_SUCCESSFUL = 'user_lookup_successful'
}

export interface LdapTestResult {
  connectionResult: string
  testedEmailAddress: string | null
  userLookupResult: string
  userCount: number | null
  firstUser: LdapUserEntry | null
  errorMessage: string | null
}

export const LDAP_DISABLE_RESET_HIGHEST_SERIAL = '0'

export enum LdapExceptions {
  LDAPBaseDNNotFoundException = 'ldap.LDAPBaseDNNotFoundException',
  INVALID_CREDENTIALS = 'ldap.INVALID_CREDENTIALS',
  SERVER_DOWN = 'ldap.SERVER_DOWN',
  LDAPError = 'ldap.LDAPError',
  TIMEOUT = 'ldap.TIMEOUT'
}
