import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundSenderPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      <p>Sender policies allow you to exempt{!props.hasCpl && <>, quarantine,</>} or block messages as follows:</p>
      <ul>
        <li>
          <b>User</b>
          <br />
          Only the <i>username</i> part of the sender&apos;s email address is checked. For example, adding a policy for
          a username of <i>info</i> means that the policy you select is applied to all inbound messages from senders
          with an email address of <code>info@sendingdomain.com</code>, where <code>sendingdomain.com</code> represents
          any sending domain.
        </li>
        <li>
          <b>Domain</b>
          <br />
          Only the domain part of the email address is checked.
        </li>
        <li>
          <b>Email address</b>
          <br />
          The entire email address is checked.
        </li>
      </ul>
      <p>
        {props.productName} applies header scanning to both the Header and the Envelope From fields. In the Header
        field, only the email address portion is checked. When entering a username, domain, or email address, wildcards
        (such as *) or the @ sign are not supported. For example, enter
      </p>
      <div className={classes.quote}>
        <code>customer.com</code> instead of <code>*@customer.com</code>
      </div>
      <br />
      Click one of the following column headings to sort the table:
      <ul>
        <li>Sender</li>
        <li>Policy</li>
        <li>Comment</li>
        <li>Modified</li>
      </ul>
      <br />
      <h3>Allow Lists</h3>
      <p>
        Adding a domain, subdomain, or email sender and selecting <b>Exempt</b> always accepts, or allows messages,
        meaning that:
      </p>
      <ul>
        <li>
          Messages from allowed senders bypass spam scoring, Intent Analysis, content filters, and Sender Policy
          Framework (SPF).
        </li>
        <li>Virus scanning and rate control are still applied.</li>
      </ul>
      <p>
        <b>Warning:</b> Spammers can use an exempt email address to bypass filtering; as such, allowing trusted IP
        addresses is a more reliable way to identify trusted domains. To add trusted IP addresses to your allow list,
        see the
        <b>Inbound Settings &gt; IP Address Policies</b> page. If the email sender and recipient addresses are the same,{' '}
        {props.productName} disregards any exempt email addresses and processes the mail normally. This is done because
        spammers know that users tend to allow their own email address.
      </p>
      <h3>Block Lists</h3>
      <p>
        Blocking a domain automatically blocks all subdomains.
        <br />
        <br />
        <b>Warning:</b> Blocking email addresses is not recommended because spammers rarely, if ever, use the same
        sender email address more than once.
      </p>
    </div>
  )
}

export default InboundSenderPolicies
