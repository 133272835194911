// based on the api-gateway app/models/user_roles.py
export enum UserRole {
  NONE = 0, // no user specified
  ADMIN_USER = 1, // super user, internal
  AUDIT_USER = 2, // auditor, internal
  SUPPORT_USER = 8, // support, internal
  CS_USER = 16, // customer service, internal

  // Service users
  ACCOUNT_USER = 1024, // account user (portal account)
  DOMAIN_USER = 2048, // domain manager
  HELPDESK_ROLE = 4096, // help desk role (use as a role)
  USER = 8192 // an authenticated user (domain email user)
}

export enum UserRoleDescription {
  ADMIN_USER = 'Super user, internal',
  AUDIT_USER = 'Auditor, internal',
  SUPPORT_USER = 'Support, internal',
  CS_USER = 'Customer service, internal',
  ACCOUNT_USER = 'Account user (portal account)',
  DOMAIN_USER = 'Domain manager',
  HELPDESK_ROLE = 'Help Desk user',
  USER = 'Authenticated user (domain email user)'
}

// list of roles from high to low level
export const USER_ROLE_LIST = [
  UserRole.ADMIN_USER,
  UserRole.AUDIT_USER,
  UserRole.SUPPORT_USER,
  UserRole.CS_USER,
  UserRole.ACCOUNT_USER,
  UserRole.DOMAIN_USER,
  UserRole.HELPDESK_ROLE,
  UserRole.USER
]
