import { useCallback, useMemo, useRef } from 'react'

export type UseDirtyFormCheckProps = any[]

export type ResetInitialForm = () => void
export type IsDirtyForm = boolean

export type DirtyFormCheck = [IsDirtyForm, ResetInitialForm, (values: UseDirtyFormCheckProps) => boolean]

export const useDirtyFormCheck = (formValues: UseDirtyFormCheckProps): DirtyFormCheck => {
  const generateStringForMatch = useCallback(
    (values: UseDirtyFormCheckProps) =>
      values.reduce((all: string, formValue: any) => all + JSON.stringify(formValue), ''),
    []
  )

  const getStringForMatch = useCallback(() => generateStringForMatch(formValues), [formValues, generateStringForMatch])

  const initialFormData = useRef(getStringForMatch())

  const resetInitialForm = useCallback(() => {
    initialFormData.current = getStringForMatch()
  }, [getStringForMatch])

  const isDirtyForm = useMemo(
    () => initialFormData.current !== getStringForMatch(),
    [initialFormData, getStringForMatch]
  )

  const isDirtyValues = useCallback(
    (values: UseDirtyFormCheckProps) => initialFormData.current !== generateStringForMatch(values),
    [generateStringForMatch]
  )

  return [isDirtyForm, resetInitialForm, isDirtyValues]
}
