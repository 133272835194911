import React from 'react'
import { DateTime, TSSettings } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import {
  DateRangePicker as MUIDateRangePicker,
  DateRangePickerProps as MUIDateRangePickerProps
} from '@mui/x-date-pickers-pro/DateRangePicker'
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { DateRange, DateRangeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro'
import { Typography } from '@barracuda-internal/bds-core'
import { Calendar } from '@barracuda-internal/bds-core/dist/Icons/Core'

export enum ShortcutItems {
  lastDay = 'last_day',
  last7days = 'last_7_days',
  last30days = 'last_30_days',
  last60days = 'last_60_days',
  last6Months = 'last_months',
  reset = 'reset'
}

export type CustomDateRangePickerProps = MUIDateRangePickerProps<any>

export const DEFAULT_SHORTCUT_ITEMS: { [keys in ShortcutItems]: PickersShortcutsItem<DateRange<TSSettings>> } = {
  [ShortcutItems.lastDay]: {
    label: 'Last Day',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 1 }), today]
    }
  },
  [ShortcutItems.last7days]: {
    label: 'Last 7 Days',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 7 }), today]
    }
  },
  [ShortcutItems.last30days]: {
    label: 'Last 30 Days',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 30 }), today]
    }
  },
  [ShortcutItems.last60days]: {
    label: 'Last 60 Days',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 60 }), today]
    }
  },
  [ShortcutItems.last6Months]: {
    label: 'Last 6 Months',
    getValue: () => {
      const today = DateTime.now()
      return [today.minus({ days: 180 }), today]
    }
  },
  [ShortcutItems.reset]: {
    label: 'Reset',
    getValue: () => [null, null]
  }
}

/**
 * Props for the DateRangePicker component.
 */
export interface DateRangePickerProps {
  /**
   * The default value of the date range picker.
   */
  defaultValue?: DateRange<TSSettings>
  /**
   * The label of the date range picker.
   */
  label?: string
  /**
   * The maxDate prop prevents the selection of all values after props.maxDate.
   */
  maxDate?: DateTime | undefined
  /**
   * The minDate prop prevents the selection of all values before props.minDate.
   */
  minDate?: DateTime | undefined
  /**
   * Callback fired when the value changes.
   */
  onChange?:
    | ((value: DateRange<TSSettings>, context: PickerChangeHandlerContext<DateRangeValidationError>) => void)
    | undefined
  /**
   * If `true`, the date range picker will be displayed as a single input.
   */
  singleInput?: boolean
  /**
   * If `true`, the selected shortcut item label will be shown inside the input.
   */
  selectedShortcutLabel?: string
  /**
   * The value of the date range picker.
   */
  value?: DateRange<TSSettings>

  /**
   * Specifies if an error occurred in the date range picker.
   */
  error?: boolean
  /**
   * The error message to display when an error occurs.
   */
  errorMessage?: string | undefined
  /**
   * Custom MUI Datepicker props.
   */
  customProps?: CustomDateRangePickerProps
  /**
   * Available shortcut items.
   */
  shortcutItems?: PickersShortcutsItem<DateRange<TSSettings>>[]
}

export function DateRangePicker({
  defaultValue,
  label = 'Date Range',
  minDate,
  maxDate,
  onChange,
  selectedShortcutLabel,
  singleInput = false,
  value,
  error = false,
  errorMessage,
  customProps = {},
  shortcutItems
}: DateRangePickerProps) {
  const slots = singleInput ? { field: SingleInputDateRangeField } : {}

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <MUIDateRangePicker
        {...customProps}
        sx={{
          minWidth: '300px',
          '.MuiPickersPopper-root': {
            zIndex: 9999
          }
        }}
        slots={slots}
        slotProps={{
          textField: {
            error: !!error,
            helperText: errorMessage,
            size: 'small',
            sx: {
              minWidth: '380px',
              textAlign: 'center'
            },
            InputProps: {
              endAdornment: singleInput ? <Calendar color="secondary" /> : null,
              startAdornment: selectedShortcutLabel && (
                <Typography style={{ minWidth: '110px', marginRight: '5px' }}>{selectedShortcutLabel}</Typography>
              )
            }
          },
          popper: {
            sx: { zIndex: 9999 }
          },
          shortcuts: {
            items: shortcutItems || Object.values(DEFAULT_SHORTCUT_ITEMS),
            changeImportance: 'set',
            sx: {
              '.MuiChip-root': {
                borderRadius: '0px',
                backgroundColor: 'white',
                height: '48px'
              }
            }
          },
          actionBar: { actions: [] }
        }}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        value={value}
        onChange={onChange}
        calendars={2}
        defaultValue={defaultValue}
      />
    </LocalizationProvider>
  )
}
