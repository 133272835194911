import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

export type UseContentFitToBottomProps = {
  bottomMargin?: number
  startingHeight?: number
}

export type ContentRef = MutableRefObject<HTMLInputElement | null>
export type ContentHeight = number
let interval: number

const useContentFitToBottom = ({
  bottomMargin = 0,
  startingHeight = 0
}: UseContentFitToBottomProps): [ContentRef, ContentHeight] => {
  const contentRef = useRef<HTMLInputElement | null>(null)
  const [contentHeight, setContentHeight] = useState<number>(startingHeight)

  const handleResize = useCallback(() => {
    if (contentRef.current) {
      const newHeight = window.innerHeight - contentRef.current.getBoundingClientRect().top - bottomMargin
      if (newHeight !== contentHeight) {
        clearInterval(interval)
        setContentHeight(newHeight)
      }
    }
  }, [bottomMargin, contentHeight])

  // init
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    interval = window.setInterval(handleResize, 100)

    return () => {
      clearInterval(interval)
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => [contentRef, contentHeight], [contentRef, contentHeight])
}

export default useContentFitToBottom
