import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function Log(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <p>
        The Message Log displays inbound{!props.hasCpl && <> and outbound</>} email traffic that passes through{' '}
        {props.productName}. You can filter on <b>All</b>, <b>Allowed</b>, <b>UI Delivered</b>,<b>Not Allowed</b>,{' '}
        <b>Blocked</b>
        {!props.hasCpl && (
          <>
            , <b>Quarantined</b>,
          </>
        )}{' '}
        or <b>Deferred</b> messages for{!props.hasCpl && <> either</>} either <i>Inbound</i>{' '}
        {!props.hasCpl && (
          <>
            {' '}
            or <i>Outbound</i>
          </>
        )}{' '}
        email.
      </p>
      <p>The page is divided into two panes:</p>
      <ul>
        <li>The Message List, which contains various details of the messages.</li>
        <li>
          The Reading Pane, or Message View (off by default), displays the contents of a message on the right or bottom
          section of the page.
        </li>
      </ul>
      <p>
        To view the contents of a message in the Reading Pane, select either <b>Right</b> or <b>Bottom</b> to position
        the pane on the page. Click on the message once to view it in the pane. Click <b>Open</b> in the pane to open
        the message in a new tab and view message details. You can also double-click on a message in the Message List to
        view the message in a new tab.
        <br />
        <br />
        The following sections describe the different aspects of the <b>Overview &gt; Message Log</b> page:
      </p>
      <ul>
        <li>
          <a href="#help-search">Search for Messages</a>
        </li>
        <li>
          <a href="#help-scroll">Scroll through and View Messages</a>
        </li>
        <li>
          <a href="#help-display">Customize Display Appearance</a>
        </li>
        <li>
          <a href="#help-actions">Take Actions with Messages</a>
        </li>
        <li>
          <a href="#help-reasons">Reasons for Actions Taken</a>
        </li>
        <li>
          <a href="#help-column">Message Log Columns</a>
        </li>
      </ul>
      {!props.hasCpl && (
        <>
          <p>
            For email delivery fails, it is necessary to ensure that {props.productName} does not retry the mail
            continuously. This can result in our service being blocked by the destination domain. Instead,{' '}
            {props.productName} will return a cached response to the sender for a period of time, determined by the type
            of delivery failure (or success).
          </p>
          See{' '}
          <a href="https://campus.barracuda.com/doc/96023054/" target="_new">
            Email Cache Policies
          </a>{' '}
          in Barracuda Campus for additional information.
        </>
      )}
      <h3 id="help-search">Search for Messages</h3>
      <p>
        <b>Simple Search</b>
        <br />
        Use simple search to run a fast search based on a word, search pattern, or phrase.
      </p>
      <ol>
        {!props.hasCpl && (
          <li>
            Next to <b>Message Filter</b>, select either <i>Inbound</i> or <i>Outbound</i>.
          </li>
        )}
        <li>
          {' '}
          From the drop-down menu, select <b>All</b>, <b>Allowed</b>, <b>UI Delivered</b>, <b>Not Allowed</b>,{' '}
          <b>Blocked</b>,
          {!props.hasCpl && (
            <>
              {' '}
              <b>Email Continuity</b>, <b>Quarantined</b>,
            </>
          )}{' '}
          or <b>Deferred</b> messages.{' '}
        </li>
        <li>
          {' '}
          Enter a whole (not partial) word, search pattern, or phrase in the <b>Search</b> box in the form described
          below. Using the field name is not necessary, but narrows the search. So, for example, if you search on{' '}
          <i>myaddress@example.com</i>, the From, To, and Subject fields of the message are all searched.
        </li>
        <ul>
          <li>
            <b>IP_address</b>
            <br />
            Example: <i>ip:10.1.1.1</i>
          </li>
          <li>
            <b>Message_id</b>
            <br />
            Click on the message, and click <b>Show Details</b> to find the message_id. Use this format in the{' '}
            <b>Search</b> box: <i>message_id:&lt;your-message-id&gt;</i>
            <br />
            Example: <i>message_id:1374102064-320627-22657-10347-7</i>
          </li>
          <li>
            <b>Attachment filename</b>
            <br />
            Use this format in the <b>Search</b> box: <i>attachment:</i>
            <br />
            If the attachment filename contains spaces, you can represent the space with <b>%20</b> or enclose the
            filename in single or double quotation marks.
            <br />
            Example: To search for an attachment titled <i>Blue Skies.txt</i>, type any of the following:
          </li>
          <ul>
            <li>
              <i>attachment:Blue%20Skies.txt</i> or
            </li>
            <li>
              <i>attachment:&quot;Blue Skies.txt&quot;</i> or
            </li>
            <li>
              <i>attachment:&apos;Blue Skies.txt&apos;</i>
            </li>
          </ul>
          <li>
            <b>Spam</b>
            <br />
            This filter operates on messages determined by {props.productName} to be Spam. To display messages
            identified as Spam, enter this in the <b>Search</b> box: <i>filter:spam</i>
            <br />
          </li>
          <li>
            <b>Envelope_from</b>
            <br />
            Example: <i>envelope_from:myaddress@example.com</i> or <i>myaddress@example.com</i>
          </li>
          <li>
            <b>Header_to</b>
            <br />
            Example: <i>header_to:myaddress@example.com</i> or <i>myaddress@example.com</i>
            <br />
          </li>
          <li>
            <b>Subject</b>
            <br />
            Example: <i>subject:Tomorrow</i> or <i>Tomorrow</i>
            <br />
          </li>
          <li>
            <b>Score_lt(e)</b>
            <br />
            Example: <i>score_lt:5.2</i> lists all messages where the score is <i>less than</i> 5.2.
            <br />
            Example: <i>score_lte:3.0</i> lists all messages where the score is <i>less than or equal to</i> 3.0.
            <br />
          </li>
          <li>
            <b>Score_gt(e)</b>
            <br />
            Example: <i>score_gt:6.8</i> lists all messages where the score is <i>greater than</i> 6.8.
            <br />
            Example: <i>score_gte:2.5</i> lists all messages where the score is <i>greater than or equal to</i> 2.5.
            <br />
          </li>
          <li>
            <b>Size_lt</b>
            <br />
            Example: <i>size_lt:500</i> lists all messages where the size, including attachments, is <i>less than</i>{' '}
            500 bytes.
            <br />
          </li>
          <li>
            <b>Size_gt</b>
            <br />
            Example: <i>size_gt:1000</i> lists all messages where the size, including attachments, is{' '}
            <i>greater than</i> 1000 bytes.
            <br />
          </li>
          <li>
            <b>Delivery status</b>
            <br />
            <i>Delivered</i>, <i> Deferred</i>, <i>Not_Delivered</i>, <i>Spooled</i>. Use this format in the{' '}
            <b>Search</b> box: <i>delivery_status:&lt;delivery status&gt;</i>
            <br />
            Example: <i>delivery_status:deferred</i>
          </li>
        </ul>
        <li>
          Select the <b>Domain</b> and <b>Time Range</b>, and click <b>Search</b>. All fields are searched based on your
          criteria, so you may get a larger set of messages returned than you wanted.
        </li>
      </ol>
      <br />
      <b>Advanced Search</b>
      <br />
      To further filter or search for specific messages, click <b>Advanced Search</b> to display the Advanced Search
      options. To hide these options, click <b>Advanced Search</b> again. To remove a search value, click <b>Clear</b>.
      Select or enter a word or phrase to search on in one or more of the following fields, then click <b>Search</b>.
      <ul>
        <li>
          <b>From</b>
          <br />
          Sender email address (this may not match the address in the headers that mail clients display to an end-user).
        </li>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es).
        </li>
        <li>
          <b>Envelope From</b>
          <br />
          This is the (sender) email address to which bounce messages are delivered. This field is also sometimes known
          as <i>envelope from</i>, <i>envelope sender</i>,<i>MAIL FROM</i>, <i>return address</i>, and other names. All
          of these names refer to the email address found by the SMTP MAIL FROM command. The Envelope From field
          contents are generally not seen by the email user.
        </li>
        <li>
          <b>Envelope To</b>
          <br />
          The real destination email address.
        </li>
        <li>
          <b>Subject</b>
          <br />
          Messages where any portion of the &quot;Subject:&quot; field contains the specified text.
        </li>
        <li>
          <b>Action</b>
          <br />
          Limit to <b>Any</b>, <b>Allowed</b>, <b>Blocked</b>
          {!props.hasCpl && (
            <>
              ,<b>Quarantined</b>, <b>Encrypted</b>
            </>
          )}{' '}
          or <b>Deferred</b> messages.
        </li>
        <li>
          <b>Reason</b>
          <br />
          Messages with Actions taken for the selected <b>Reason</b>. For a description of reasons, see{' '}
          <a href="#help-reasons">Reasons for Actions Taken</a> below.
        </li>
        <li>
          <b>Delivery Status</b>
          <br />
          Limit to <b>Any</b>, <b>Delivered</b>, <b>Not Delivered</b>, <b>Deferred</b>, <b>Rejected</b>, or{' '}
          <b>Spooled</b>.
        </li>
        <li>
          <b>Start Date, End Date</b>
          <br />
          Use to limit date/time range of message search results. Examples:
        </li>
        <ul>
          <li>
            To view 10 minutes of results
            <br />
            <b>Start Date:</b> <i>2016-10-01 12:00am</i> &nbsp;&nbsp; <b>End Date:</b> <i>2016-10-01-12:10am</i>{' '}
            &nbsp;&nbsp; Returns messages from 12:00 AM through 12:09 AM, not including 12:10 AM.
          </li>
          <li>
            To view a 7 day range
            <br />
            <b>Start Date:</b> <i>2016-09-24 12:00am</i> &nbsp;&nbsp; <b>End Date:</b> <i>2016-10-01 12:00am</i>
          </li>
          <li>
            To view yesterday&apos;s messages through today
            <br />
            <b>Start Date:</b> <i>Yesterday</i>&nbsp;&nbsp; <b>End Date:</b> <i>blank</i>&nbsp;&nbsp; (leave the{' '}
            <b>End Date</b> field blank)
          </li>
        </ul>
        <li>
          <b>Attachment</b>
          <br />
          Attachment file name.
        </li>
        <li>
          <b>Results</b>
          <br />
          Limit result set to a maximum of 25, 50, 100, 150, or 200 messages.
        </li>
      </ul>
      <b>Saved Searches</b>
      <br />
      To save a search filter for use at a later time:
      <ol>
        <li>
          Enter your search criteria, and click <b>Search</b> to display matching results.
        </li>
        <li>
          Click <b>Saved Searches</b> to toggle the <b>Saved Search</b> box on.
        </li>
        <li>
          Enter a name to represent the saved search in the <b>Save current search</b> field.
        </li>
        <li>
          Click <b>Save</b>. The saved search is added to the <b>Saved Searches</b> box.
        </li>
      </ol>
      To run a saved search:
      <ol>
        <li>
          Click <b>Saved Searches</b> to toggle the <b>Saved Search</b> box on.
        </li>
        <li>
          In the <b>Name</b> list, click on the name of the saved search you want to run.
        </li>
      </ol>
      <h3 id="help-scroll">Scroll Through and View Messages</h3>
      Use the vertical scroll bar to move up and down the page. If there are more messages than are displayed on the
      page, click <b>Next 50</b> on the right of the <b>Search</b> bar.
      <br />
      <br />
      When you select a message in the log, the action taken (Block, {!props.hasCpl && <>Quarantine, </>}etc.) and
      associated reason appear in the <b>Message View</b> pane.Click <b> Show Details</b> to view data about the
      message, including Recipients, sending IP address, and other details For messages sent to multiple recipients or
      distribution lists, individual addresses are listed with the details, indicated by the{' '}
      <b> Includes Other Recipients</b> link.
      <br />
      <br />
      <b>If you have one or more Office 365 domains</b>, you can click <b> Search for similar messages</b> to locate
      messages similar to, but not necessarily related to exactly the same as, the current email, using{' '}
      <a href="https://campus.barracuda.com/doc/85494108/" target="_new">
        {' '}
        Incident Response
      </a>
      .Note that this link appears for messages from all email accounts, regardless of whether they are on an Office 365
      domain.
      <br /> <br />
      The following actions are available in the <b> Message View</b> pane:
      <ul>
        <li>
          <b>Source</b>
          <br />
          View the message source and headers.
        </li>
        <li>
          <b>Allow List</b>
          <br />
          Add this email address to your allow list, which means that messages from this email address are always
          allowed.
        </li>
        <li>
          <b>Block</b>
          <br />
          Select <b>Domain</b> or <b>Email</b> to always block mail from this domain or email address.
        </li>
        <li>
          <b>Download</b>
          <br />
          Download the message.
        </li>
        <li>
          <b>Open</b>
          <br />
          Open the message in a new tab.
        </li>
        <li>
          <b>Deliver</b>
          <br />
          Deliver the message to the recipient. This option is unavailable if the selected message has a <b>
            Reason
          </b>{' '}
          of <b>Advanced Threat Protection</b>.
        </li>
        <li>
          <b>Load Remote Content</b>
          <br />
          Display images embedded in the message.
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <b>ATP Reports</b>
              <br />
              Messages are scanned by Advanced Threat Protection (ATP) to determine whether they are clean or infected.
              If a message is blocked due to ATP, the <b>Deliver</b> field displays as <b>Not Delivered</b>.
              <br />
              See{' '}
              <a href="https://campus.barracuda.com/doc/96023049/" target="_new">
                Advanced Threat Protection Reports
              </a>{' '}
              in Barracuda Campus for additional information.
              <br />
              To deliver the message, first review the ATP Reports for that message:
            </li>
            <ol>
              <li>Click on the message blocked by ATP.</li>
              <li>
                In the reading pane, click <b>ATP Reports</b>.
              </li>
              <li>
                The <b>Email Delivery Warning</b> dialog box displays a list of attachments, one or more of which is
                suspected of being <b>Infected</b>.
              </li>
              <li>
                Review the report for each <b>Infected</b> attachment.
              </li>
              <li>
                If you determine you want to deliver the message, review and accept the disclaimer, and then click{' '}
                <b>Deliver Message</b> in the <b>Email Delivery Warning</b> dialog box.
              </li>
            </ol>
          </>
        )}
        <li>
          {' '}
          <b>Show Message History</b> - View all message log entries for the selected message. Helpful to view multiple
          delivery attempts for a single message.
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <b>Report as Incorrectly Blocked</b>
              <br />
              Click this button if you think the selected message was incorrectly blocked (this includes messages you
              consider to be legitimate), to have it sent to Barracuda Central for analysis.
              <br />
              This option is available only if the message was blocked or quarantined by Barracuda Networks policies,
              not by your own configuration.
            </li>
            <li>
              <b>Report as Incorrectly Delivered</b>
              <br />
              Click this button if you think the selected message was incorrectly delivered (this includes messages you
              consider to be spam), to have it sent to Barracuda Central for analysis.
              <br />
              This option is available only if the message was blocked or quarantined by Barracuda Networks policies,
              not by your own configuration.
            </li>
          </>
        )}
      </ul>
      <h3 id="help-display">Customize Display Appearance</h3>
      <ul>
        <li>
          <b>Resize column width</b>
          <br />
          Click the edge of the column header you want to move and drag to the desired width. Your cursor changes to a
          double arrow, pointing right and left, when it is over a movable border.
        </li>
        <li>
          <b>Reading Pane</b>
          <br />
          Select whether to display the message Reading Pane on the right or bottom of the <b>Message Log</b> page, or
          turn it off. On the Message Filter bar, next to <b>Reading Pane</b>, click <b>Right</b>, <b>Bottom</b>, or{' '}
          <b>Off</b> to configure.
        </li>
      </ul>
      <h3 id="help-actions">Take Actions with Messages</h3>
      <p>
        Select one or more messages using the check boxes on the left side of the Message List, and then click an action
        on the tool bar. Note that only users can delete messages from their message log.
      </p>
      <ul>
        <li>
          <b>Export</b>
          <br />
          Export either selected or all messages to a CSV file. To export all messages, click the check box at the top
          of the Message List. You are prompted for a file name to save to your local desktop or network.
        </li>
        <li>
          <b>Deliver</b>
          <br />
          Attempt to deliver the selected message(s).
        </li>
        <li>
          <b>Allow List</b>
          <br />
          Add the sender email address to your allow list and deliver message(s) (always allowed, but still scanned for
          viruses).
        </li>
        {!props.hasCpl && (
          <li>
            <b>Recategorize</b>
            <br />
            Select one or more categorized emails and click the Recategorize button to change the category. For example,
            if the message has been categorized as <b>Corporate</b> but you believe it should be categorized as{' '}
            <b>Marketing Materials</b>, you can change the category via the <b>Recategorize</b>
            drop-down menu. This action submits this email message for recategorization to your selected category. If
            you select <b>Other</b> and enter a custom category, this simply updates the category for that particular
            email message. For more information about Email Categorization, see{' '}
            <a href="#help-reasons">Reasons for Actions Taken</a> below.
          </li>
        )}
      </ul>
      <h3 id="help-reasons">Reasons for Actions Taken</h3>
      <p>Reason for specific action may be one of the following:</p>
      <ul>
        <li>
          <b>ATP Exempt</b>
          <br />
          Message was exempt from ATP scanning.
        </li>
        <li>
          <b>ATP Scan Inconclusive</b>
          <br />
          {props.hasCpl && <>Message was blocked because the ATP scan timed out or resulted in an unknown response.</>}
          {!props.hasCpl && (
            <> Message was quarantined because the ATP scan timed out or resulted in an unknown response.</>
          )}
        </li>
        <li>
          <b>AV Service Unavailable</b>
          <br />
          The <b>Scan Email for Viruses</b> setting on the <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page is set
          to <b>Yes</b>, but the virus scanning service was temporarily unavailable when the message came through. The
          message is deferred and retried when the virus scanning service is available.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Account Suspended</b>
            <br />
            If your {props.productName} subscription expired more than 60 days ago, your account is marked as{' '}
            <b>Suspended</b>, and email are no longer scanned for spam. Email is still scanned for viruses.
          </li>
        )}
        <li>
          <b>Advanced Threat Protection</b>
          <br />
          Message was blocked by the ATP cloud-based virus scanning service. ATP is an advanced virus scanning service
          which, when enabled on the <b>ATP Settings</b> page, provides additional scanning for the attachment file
          types you specify.
        </li>
        <li>
          <b>Anti-Fraud</b>
          <br />
          Action was taken because Barracuda Anti-Fraud Intelligence detected a potential phishing scheme, which could
          be used to gather confidential information about an organization or its individual users.
        </li>
        <li>
          <b>Antivirus</b>
          <br />
          Action was taken because the message had a virus attached.
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <b>Attachment Content</b>
              <br />
              Action was taken because content in an attachment to the message matched a <b>
                Message Content Filter
              </b>{' '}
              rule specified on the <b>Inbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Attachment Filter</b>
              <br />
              Action was taken due to <b>Attachment Filter</b> settings on either the <b>Inbound Settings</b> or{' '}
              <b>Outbound Settings &gt; Content Policies</b> pages.
            </li>
          </>
        )}
        <li>
          <b>Barracuda Real-Time System (BRTS)</b>
          <br />
          Action was taken because BRTS detected a zero-hour spam or virus. This advanced service detects spam or virus
          outbreaks even where traditional heuristics and signatures to detect such messages do not yet exist.
        </li>
        <li>
          <b>Barracuda Reputation</b>
          <br />A list maintained by Barracuda Central that includes IP addresses of known spammers.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Body Content</b>
            <br />
            Action was taken because content in the message body matched a <b>Message Content Filter</b> rule specified
            on the <b>Inbound Settings &gt; Content Policies</b> page.
          </li>
        )}
        <li>
          <b>Bulk Email</b>
          <br />
          Action was taken because the <b>Bulk Email Detection</b> setting on the{' '}
          <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page is set to <b>Yes</b>, and the message qualifies as Bulk.
        </li>
        <li>
          <b>Cloudscan Service Unavailable</b>
          <br />
          The <b>Enable Cloudscan</b> setting on the <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page is set to{' '}
          <b>Yes</b>, but the Cloudscan spam scoring service was temporarily unavailable when the message came through.
          The message is deferred and is retried when the Cloudscan service is available.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Content Protected</b>
            <br />
            Action was taken because the message has a password protected archive attachment. See settings for{' '}
            <b>Attachment Filter</b> on the <b>Inbound Settings</b> or <b>Outbound Settings &gt; Content Policies</b>{' '}
            pages.
          </li>
        )}
        <li>
          <b>Content URL</b>
          <br />
          Action was taken because the message contained one or more URLs listed in the <b>
            Intent Domain Policies
          </b>{' '}
          section of the <b>Inbound Settings &gt; Anti-Phishing</b> page.
        </li>
        <li>
          <b>DKIM</b>
          <br />
          Action was taken because the <b>DomainKeys Identified Mail (DKIM)</b> setting on the{' '}
          <b>Inbound Settings &gt; Sender Authorization</b> page is set to{' '}
          {!props.hasCpl && (
            <>
              <b>Quarantine</b> or{' '}
            </>
          )}
          <b>Block</b> and the message is from a domain that fails DKIM verification.
        </li>
        <li>
          <b>DMARC</b>
          <br />
          Action was taken because the <b>Domain Based Message Authentication (DMARC)</b> setting on the{' '}
          <b>Inbound Settings &gt; Sender Authorization</b> page is set to <b>Yes</b> and the message is from a domain
          that fails DMARC verification.
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <b>Email Categorization</b>
              <br />
              Per settings on the <b>Inbound Settings &gt; Anti-spam/Antivirus</b> page, email from this sender is
              categorized as not necessarily spam, but rather something that the user may have subscribed to at one time
              and may no longer wish to receive. For example, newsletters and memberships, or marketing information.
              Email Categorization assigns some of these emails to specific categories, which the admin can decide to
              allow, block, or quarantine (see the <b>Inbound Settings &gt; Anti-spam/Antivirus</b> page). Categories
              supported appear in the Message Log <b>Reason</b> as:
            </li>
            <ul>
              <li>
                <b>Email Categorization (corporate)</b>
                <br />
                Emails sent by a user at an authenticated organization from an MS Exchange Server that involves general
                corporate communications. Does not include marketing newsletters.
              </li>
              <li>
                <b>Email Categorization (transactional)</b>
                <br />
                Emails related to order confirmations, bills, invoices, bank statements, delivery/shipping notices, and
                service-related surveys.
              </li>
              <li>
                <b>Email Categorization (marketing)</b>
                <br />
                Promotional emails from companies such as Constant Contact.
              </li>
              <li>
                <b>Email Categorization (mailing lists)</b>
                <br />
                Emails from mailing lists, newsgroups, and other subscription-based services such as Google and Yahoo!
                Groups.
              </li>
              <li>
                <b>Email Categorization (social media)</b>
                <br />
                Notifications and other emails from social media sites such as Facebook and LinkedIn.
              </li>
            </ul>
          </>
        )}
        <li>
          <b>From Address</b>
          <br />
          Action was taken because of a sender or content rule for From Address.
        </li>
        <li>
          <b>GeoIP Policies</b>
          <br />
          Message blocked {!props.hasCpl && <>or quarantined</>} based on a country of origin selected on the{' '}
          <b>Inbound Settings &gt; Regional Policies</b> page.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Header Content</b>
            <br />
            Action was taken because content in the message header matched a <b>Message Content Filter</b> rule
            specified on the <b>Inbound Settings &gt; Content Policies</b> page.
          </li>
        )}
        <li>
          <b>IP Address Policies</b>
          <br />
          Action was taken because the sending IP address is listed (as <b>Blocked</b> or <b>Exempt</b>) on the{' '}
          <b>Inbound Settings &gt; IP Address Policies</b>
          page.
        </li>
        <li>
          <b>Image Analysis</b>
          <br />
          Action was taken because Image Analysis identified this message as a bulk/spam message.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Inbound TLS Required</b>
            <br />
            On the <b>Domains &gt; Domain Settings</b> page, the setting{' '}
            <b>Require TLS to Barracuda Networks from these domains</b> lists domains that must use a TLS connection
            when connecting to {props.productName}. If a TLS connection was not used, then the inbound message is
            blocked with a reason of <b>Inbound TLS required</b>.
          </li>
        )}
        <li>
          <b>Intent Analysis</b>
          <br />
          Action was taken because Intention Analysis identified this message as a bulk/spam message.
        </li>
        <li>
          <b>Invalid Recipient</b>
          <br />
          &quot;To&quot; address does not exist on the mail server.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Language Policies</b>
            <br />
            Message blocked/quarantined based on a character set selected on the{' '}
            <b>Inbound Settings &gt; Regional Policies</b> page.
          </li>
        )}
        <li>
          <b>Malformed</b>
          <br />
          The message did not conform to the SMTP protocol; for example, the Sender, From, Date, or other required
          fields may be empty.
        </li>
        <li>
          <b>Message Delivery Interrupted</b>
          <br />
          This error occurs when a sender&apos;s connection drops during email transmission, or if a sender closes or
          quits their email editor before email transmission is complete. The message is deferred until the connection
          resumes and the email is successfully sent.
        </li>
        <li>
          <b>Message Too Large</b>
          <br />
          The message exceeded the maximum message size allowed by the destination mail server, which rejected the
          message. (Note that {props.productName} allows messages of up to 300 MB).
        </li>
        <li>
          <b>No PTR Record</b>
          <br />
          Action was taken because:
        </li>
        <ol>
          <li>
            The <b>Block on No PTR Records</b> setting on the <b>Inbound Settings &gt; Sender Authentication</b> page
            was set to <b>Yes</b>, and
          </li>
          <li>
            Because of (1), {props.productName} queried DNS for the SPF record of the sending domain, and there was no
            PTR record.
          </li>
        </ol>
        {!props.hasCpl && (
          <>
            <li>
              <b>Office Protected</b>
              <br />
              The message has a password-protected Microsoft Office attachment. See settings for{' '}
              <b>Attachment Filter</b> on the <b>Inbound Settings &gt; Content Policies</b> and{' '}
              <b>Outbound Settings &gt; Content Policies</b> pages.
            </li>
            <li>
              <b>Password Protected PDF Filtering</b>
              <br />
              The message has a password-protected PDF attachment. See settings for <b>Attachment Filter</b> on the{' '}
              <b>Inbound Settings &gt; Content Policies</b> and <b>Outbound Settings &gt; Content Policies</b> pages.
            </li>
            <li>
              <b>Pending Scan</b>
              <br />
              If ATP is enabled with the <b>Scan First, Then Deliver</b> option, this reason indicates that the message
              is deferred because attachment scanning is pending. The mail server retries later to check if the scan is
              complete and, if it is, delivers the message.
            </li>
          </>
        )}
        <li>
          <b>Security Awareness Training</b>
          <br />
          Message was sent as part of a Security Awareness Training campaign.
        </li>
        <li>
          <b>Possible Mail Loop</b>
          <br />
          IP address for the destination mail server is not correctly configured in {props.productName}, and may instead
          contain the IP address for
          {props.productName}. This causes a mail loop.
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <b>Predefined Attachment Content</b>
              <br />
              Action was taken because an attachment to the message contained content that matched a Predefined filter
              based on data leakage patterns (specific to U.S.). See the <b>
                Outbound Settings &gt; Content Policies
              </b>{' '}
              page.
            </li>
            <li>
              <b>Predefined Body Content</b>
              <br />
              Action was taken because the message body contained content that matched a predefined filter based on data
              leakage patterns (specific to U.S.). See the <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Predefined Filter Exceptions</b>
              <br />
              The message body contained content that matched a predefined filter exception to HIPAA or Privacy content
              filters. See the <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Predefined From Address</b>
              <br />
              Action was taken because the message From address contained content that matched a predefined filter based
              on data leakage patterns (specific to U.S.). See the <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Predefined Header Content</b>
              <br />
              Action was taken because the message header contained content that matched a predefined filter based on
              data leakage patterns (specific to U.S.). See the <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Predefined Subject Content</b>
              <br />
              Action was taken because the message subject contained content that matched a predefined filter based on
              data leakage patterns (specific to U.S.). See the <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
            <li>
              <b>Predefined To/CC Address</b>
              <br />
              Action was taken because the message &quot;To/CC&quot; address contained content that matched a predefined
              filter based on data leakage patterns (specific to U.S.). See the{' '}
              <b>Outbound Settings &gt; Content Policies</b> page.
            </li>
          </>
        )}
        <li>
          <b>Rate Control</b>
          <br />
          Sender IP address exceeded maximum number of allowed connections in a half-hour period. The action taken is to
          defer the message unless the client continues to make connections.
        </li>
        <li>
          <b>Realtime Block List</b>
          <br />
          Action was taken because of IP Reputation Analysis, which determined that the sending IP address is listed on
          a Real-Time Block List (RBL) or DNS Block List (DNSBL).
        </li>
        <li>
          <b>Recipient</b>
          <br />
          Action was taken because of a rule for the &quot;To&quot; address.
        </li>
        <li>
          <b>Remediated by Incident Response</b>
          <br />
          Incident Response remediated this email by deleting it from recipient&apos;s inbox.
        </li>
        <li>
          <b>Remediated by Impersonation Protection</b>
          <br />
          Impersonation Protection remediated this email either by deleting it from the recipient&apos;s inbox or by
          moving it to the recipient&apos;s Junk mailbox.
        </li>
        <li>
          <b>Score</b>
          <br />
          Action was taken because the score for the message exceeded that set for <b>Cloudscan Scoring</b> on the{' '}
          <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page.
        </li>
        <li>
          <b>Sender Email Address</b>
          <br />
          The message was blocked for sender email address. This sender is listed in the Barracuda Networks email block
          list.
        </li>
        <li>
          <b>Sender Policies</b>
          <br />
          Action was taken because settings configured on the <b>Inbound Settings &gt; Sender Policies</b> page.
        </li>
        <li>
          <b>Sender Policy Framework</b>
          <br />
          Action was taken because the Sender IP address was not listed as an allowed sender for the specified domain
          using the SPF protocol.
        </li>
        <li>
          <b>Sender Spoof Protection</b>
          <br />
          Action was taken because of a rule to block the &quot;From&quot; address that uses your domain.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Subject Content</b>
            <br />
            Action was taken because content in the subject line matched a <b>Message Content Filter</b> rule specified
            on the <b>Inbound Settings &gt; Content Policies</b> page.
            <br />
            <b>Note</b>: A subject line of <i>Message Has No Content</i> indicates an incomplete SMTP transaction due to
            a failed connection. The log entry shows the from/to data, but has no header or body content. This mail
            includes messages that are malformed or are addressed to invalid recipients.
          </li>
        )}
        <li>
          <b>Suspicious</b>
          <br />
          Message deferred or blocked due to multi-level intent checks or <b>Barracuda Anti-Fraud Intelligence</b>{' '}
          checks, as configured on the
          <b>Inbound Settings &gt; Anti-Phishing</b> page.
        </li>
        <li>
          <b>System Sender Policies</b>
          <br />
          {!props.hasCpl && <>Applies to outbound mail. </>}Action was taken because the sender has been blocked per
          policy set by Barracuda Networks; this action prevents the
          {props.productName} IP address from being blocked. Contact your email administrator if you have questions.
        </li>
        {!props.hasCpl && (
          <li>
            <b>TLS Required</b>
            <br />
            On the <b>Outbound Settings &gt; DLP/Encryption</b> page, a TLS connection must be used when connecting to
            the recipient domains listed. If a TLS connection cannot be established, then the outbound message is not
            delivered and is blocked with a reason of <b>TLS Required</b>.
          </li>
        )}
        <li>
          <b>To/CC Address</b>
          <br />
          Action was taken because of a recipient or content rule for &quot;To/CC&quot; Address.
        </li>
        <li>
          <b>UI Delivered</b>
          <br />
          For emails blocked {!props.hasCpl && <>or quarantined </>}in the Message Log, the admin has the option to
          manually deliver those messages. Once the message is delivered, the reason code for that message displays as{' '}
          <b>Allowed</b> with a reason of <b>UI Delivered</b>.
        </li>
      </ul>
      <h3 id="help-column">Message Log Columns</h3>
      <ul>
        <li>
          <b>Action</b>
          <br />
          This column is color coded as follows:
        </li>
        <ul>
          <li>
            Red: <i>Blocked</i>
          </li>
          <li>
            Green: <i>Allowed</i>
          </li>
          <li>
            Orange: <i>Blocked for Some</i>
            <br />
            This action indicates that an email was received for multiple recipients, a mix of <b>Blocked</b> and{' '}
            <b>Allowed</b> to multiple recipients.
          </li>
          {!props.hasCpl && (
            <>
              <li>
                Yellow: <i>Quarantined</i>
                <br />
                For inbound or outbound mail. To take action with <i>Outbound</i> quarantined messages, see the{' '}
                <b>Outbound Quarantine</b> page.
              </li>
              <li>
                Brown: <i>Encrypted (DLP/Encryption)</i>
                <br />
                {props.productName} sends the message recipient(s) an email notification directing them to visit the
                Barracuda Message Center to retrieve the encrypted message.
              </li>
            </>
          )}
          <li>
            {' '}
            Blue: <i>Spooled</i>
          </li>
          <li>
            White: <i>Deferred</i>
          </li>
          <br />
          Messages may be deferred due to:
          <ul>
            <li>
              <b>Possible Outbreak</b> (see <a href="#help-reasons">Reasons for Actions Taken</a>)
            </li>
            <li>
              <b>Rate Control</b> (see <a href="#help-reasons">Reasons for Actions Taken</a>)
            </li>
            <li>
              <b>AV Service Unavailable</b> (see <a href="#help-reasons">Reasons for Actions Taken</a>)
            </li>
            {!props.hasCpl && (
              <li>
                Quarantine, but the message has been deferred because the quarantine notification interval for all users
                or for the particular user (recipient) is set to <b>Never</b>. In this case, quarantined messages are
                deferred until the quarantine notification interval is changed to another setting so that the messages
                appear in the user&apos;s Message Log. See the <b>Users &gt; Quarantine Notification</b> page to
                configure.
              </li>
            )}
          </ul>
        </ul>
        {!props.hasCpl && (
          <li>
            <b>Attachment</b>
            <br />
            This column shows a paper clip icon and indicates if there is an attachment with the message.
          </li>
        )}
        <li>
          <b>SMTP Over TLS</b>
          <br />
          This column displays to the right of the color coded <b>Action</b> column, and indicates whether the message
          was sent over a secure TLS channel. A padlock indicates the message was sent over a secure TLS channel
          {!props.hasCpl && (
            <>
              {' '}
              <b>either</b>
            </>
          )}{' '}
          <i>inbound</i>{' '}
          {!props.hasCpl && (
            <>
              {' '}
              or <i>outbound</i>
            </>
          )}{' '}
          via {props.productName}.
        </li>
        <li>
          <b>From</b>
          <br />
          Sender email address (this may not match the address in the headers that mail clients display to an end-user).
          Click the down-arrow next to the From column name to select:
        </li>
        <ul>
          <li>
            <b>Show name</b>
            <br />
            Show the Header From Pretty Name.
          </li>
          <li>
            <b>Show email address</b>
            <br />
            Shows the Header From Email Address.
          </li>
          <li>
            <b>Show both</b>
            <br />
            Shows the Header From Pretty Name plus the Header From Email Address.
          </li>
        </ul>
        <li>
          <b>To</b>
          <br />
          Recipient email address(es). CC and BCC recipients show up in separate log entries in the Message Log in the
          &quot;To&quot; field.
        </li>
        <li>
          <b>Subject</b>
          <br />
          Message subject line.
        </li>
        <li>
          <b>Date</b>
          <br />
          Date and time the message was processed.
        </li>
        <li>
          <b>Size</b>
          <br />
          Message size in bytes. All messages going through {props.productName} are subject to a 300MB size limit. This
          is 300,000,000 bytes for the entire message and includes all headers, body and attachments, with whichever
          encoding the MIME parts may have used.
          <br />
          <b>Note</b>: The size of the attachment file as indicated in the <b>Size</b> column and in the Message Preview
          Pane is the encoded size, which is generally larger than the actual file size.
        </li>
        <li>
          <b>Delivery</b>
          <br />
          Any, Delivered, Not Delivered, Deferred, Rejected, or Spooled.
        </li>
        <li>
          <b>Reason</b>
          <br />A brief description of why the Action (Blocked, Deferred, etc.) was taken for that message. See{' '}
          <a href="#help-reasons">Reasons for Actions Taken</a> for descriptions of all Reasons.
        </li>
        <li>
          <b>Score</b>
          <br />
          Action was taken because of message score for Spam. Spam scoring is only applied if <b>Enable Cloudscan</b> is
          set to <b>Yes</b> on the <b>Inbound Settings &gt; Anti-Spam/Antivirus</b> page.
        </li>
      </ul>
    </div>
  )
}

export default Log
