import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { getOutboundIps } from 'redux/features/outboundIp/outboundIpSlice'

export interface State {
  isLoading: boolean
  isFailed: boolean
  isSuccess: boolean
  isDirtyForm: boolean
}

export interface EventHandlers {
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsTableDirty: (isDirty: boolean) => void
}

export type UseSenderIpLogic = [State, EventHandlers]

export const useSenderIpLogic = (): UseSenderIpLogic => {
  const dispatch = useAppDispatch()
  const [isTableDirty, setIsTableDirty] = useState(false)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)

  const {
    pdDomainId,
    isGetOutboundIpsPending,
    isGetOutboundIpsSuccess,
    isGetOutboundIpsFailed,
    isRemoveOutboundIpsPending,
    isRemoveOutboundIpsSuccess,
    isAddOutboundIpsPending,
    isAddOutboundIpsSuccess
  } = useAppSelector(_stores => ({
    pdDomainId: _stores.auth.accessTokenObject?.pdDomainId,
    outboundIps: _stores.outboundIp.outboundIps,
    isGetOutboundIpsPending: isPending(_stores.outboundIp.getOutboundIpApiStatus),
    isGetOutboundIpsSuccess: isSuccess(_stores.outboundIp.getOutboundIpApiStatus),
    isGetOutboundIpsFailed: isFailed(_stores.outboundIp.getOutboundIpApiStatus),
    isRemoveOutboundIpsPending: isPending(_stores.outboundIp.removeOutboundIpApiStatus),
    isRemoveOutboundIpsSuccess: isSuccess(_stores.outboundIp.removeOutboundIpApiStatus),
    isAddOutboundIpsPending: isPending(_stores.outboundIp.addOutboundIpApiStatus),
    isAddOutboundIpsSuccess: isSuccess(_stores.outboundIp.addOutboundIpApiStatus)
  }))

  // init
  useEffect(() => {
    if (pdDomainId) {
      dispatch(getOutboundIps({ pdDomainId }))
    } else {
      dispatch(getOutboundIps({}))
    }
    // eslint-disable-next-line
  }, [pdDomainId])

  useEffect(() => {
    if (isRemoveOutboundIpsSuccess || isAddOutboundIpsSuccess) {
      if (pdDomainId) {
        dispatch(getOutboundIps({ pdDomainId }))
      } else {
        dispatch(getOutboundIps({}))
      }
    }
    // eslint-disable-next-line
  }, [isRemoveOutboundIpsSuccess, isAddOutboundIpsSuccess])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  return useMemo(
    () => [
      {
        isLoading: isGetOutboundIpsPending || isRemoveOutboundIpsPending || isAddOutboundIpsPending,
        isFailed: isGetOutboundIpsFailed,
        isSuccess: isGetOutboundIpsSuccess,
        isDirtyForm: isTableDirty
      },
      {
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsTableDirty
      }
    ],
    [
      isGetOutboundIpsPending,
      isRemoveOutboundIpsPending,
      isAddOutboundIpsPending,
      isGetOutboundIpsFailed,
      isGetOutboundIpsSuccess,
      isTableDirty,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
