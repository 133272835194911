import React, { useMemo } from 'react'
import Measure from 'react-measure'
import PropTypes from 'prop-types'

import 'components/libs/splitter/Split/split.css'
import { useSplitLogic } from 'components/libs/splitter/Split/useSplitLogic'
import { DefaultSplitter } from 'components/libs/splitter/defaultSplitter/DefaultSplitter'
import { SplitProps } from 'components/libs/splitter/splitterTypes'
import { defaultSplitterValues } from 'components/libs/splitter/config'

export function Split(props: React.PropsWithChildren<SplitProps>) {
  const { horizontal, splitterSize = defaultSplitterValues.splitterSize, renderSplitter, colors, showSplitter } = props

  const {
    renderSizes,
    renderSplitterProps,
    onMeasureContent,
    onMeasurePrimary,
    onMeasureSplitter,
    onSplitPointerUp,
    onSplitPointerDown,
    onSplitPointerMove,
    onSplitDoubleClick
  } = useSplitLogic(props)

  return useMemo(() => {
    const children = React.Children.toArray(props.children)
    const primaryChild = children.length > 0 ? children[0] : <div />
    const secondaryChild = children.length > 1 ? children[1] : <div />

    const rootClassName = horizontal ? 'split-container horizontal' : 'split-container vertical'

    const rootStyle = {
      '--react-split-min-primary': renderSizes.minPrimary,
      '--react-split-min-secondary': renderSizes.minSecondary,
      '--react-split-primary': renderSizes.primary,
      '--react-split-splitter': splitterSize
    } as React.CSSProperties

    return (
      <Measure bounds onResize={onMeasureContent} data-testid="meassure">
        {({ measureRef: contentRef }: { measureRef: React.LegacyRef<HTMLDivElement> | undefined }) => (
          <div data-testid="react-split" className="react-split" ref={contentRef}>
            <div className={rootClassName} style={rootStyle}>
              <div className="primary">
                <Measure bounds onResize={onMeasurePrimary}>
                  {({ measureRef: primaryRef }: { measureRef: React.LegacyRef<HTMLDivElement> | undefined }) => (
                    <div className="full-content" ref={primaryRef}>
                      {primaryChild}
                    </div>
                  )}
                </Measure>
              </div>
              {showSplitter && (
                <div
                  className="splitter"
                  tabIndex={-1}
                  onPointerDown={onSplitPointerDown}
                  onPointerUp={onSplitPointerUp}
                  onPointerMove={onSplitPointerMove}
                  onDoubleClick={onSplitDoubleClick}
                  data-testid="splitter"
                >
                  <Measure bounds onResize={onMeasureSplitter}>
                    {({ measureRef: splitterRef }: { measureRef: React.LegacyRef<HTMLDivElement> | undefined }) => (
                      <div className="full-content" ref={splitterRef}>
                        {renderSplitter && renderSplitter(renderSplitterProps)}
                        {!renderSplitter && (
                          <DefaultSplitter
                            {...renderSplitterProps}
                            color={renderSplitterProps.dragging ? colors?.drag : colors?.color}
                            hoverColor={renderSplitterProps.dragging ? colors?.drag : colors?.hover}
                          />
                        )}
                      </div>
                    )}
                  </Measure>
                </div>
              )}
              <div className="secondary">
                <div className="full-content">{secondaryChild}</div>
              </div>
            </div>
          </div>
        )}
      </Measure>
    )
  }, [
    colors,
    renderSplitter,
    renderSizes,
    renderSplitterProps,
    onMeasureContent,
    onMeasurePrimary,
    onMeasureSplitter,
    onSplitPointerUp,
    onSplitPointerDown,
    onSplitPointerMove,
    onSplitDoubleClick,
    horizontal,
    splitterSize,
    showSplitter,
    props.children
  ])
}

Split.propTypes = {
  horizontal: PropTypes.bool,
  splitterSize: PropTypes.string,
  renderSplitter: PropTypes.func,
  colors: PropTypes.object
}
