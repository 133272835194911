import { createSlice } from '@reduxjs/toolkit'

import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import { getAvailableDomains } from 'redux/features/user/userApiThunks'
import { DomainsInDB } from 'types/redux/user/UserTypes'

export interface UserState {
  api: {
    getAvailableDomainsApiStatus: ApiStatus
  }
  availableDomains: DomainsInDB[] | undefined
  totalDomainsCount: number | undefined
}

// initialState
export const INITIAL_STATE: UserState = {
  api: {
    getAvailableDomainsApiStatus: inIdle
  },
  availableDomains: undefined,
  totalDomainsCount: undefined
}

/* eslint-disable no-param-reassign */
export const userSlice = createSlice({
  name: 'USER',
  initialState: INITIAL_STATE,
  reducers: {
    resetGetAvailableDomains: state => {
      state.api.getAvailableDomainsApiStatus = INITIAL_STATE.api.getAvailableDomainsApiStatus
      state.availableDomains = INITIAL_STATE.availableDomains
      state.totalDomainsCount = INITIAL_STATE.totalDomainsCount
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // getAvailableDomains
      .addCase(getAvailableDomains.pending, state => {
        state.api.getAvailableDomainsApiStatus = inProgress
      })
      .addCase(getAvailableDomains.fulfilled, (state, action) => {
        state.api.getAvailableDomainsApiStatus = successResponse
        state.availableDomains = action.payload.results
        state.totalDomainsCount = action.payload.itemsTotal
      })
      .addCase(getAvailableDomains.rejected, (state, action) => {
        state.api.getAvailableDomainsApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { resetGetAvailableDomains, reset } = userSlice.actions

export { getAvailableDomains }

export default userSlice.reducer
