import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  container: {
    padding: theme.spacing(1, 2)
  },
  cardsRow: {
    maxHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(8, 1, 4, 0)
  },
  inProgress: {
    height: '3px',
    paddingBottom: theme.spacing(2)
  },
  hostInput: {
    paddingRight: theme.spacing(1)
  },
  portInput: {
    paddingRight: theme.spacing(1)
  },
  helper: {
    fontStyle: 'italic',
    opacity: 0.5,
    paddingBottom: '24px'
  },
  button: {
    marginRight: theme.spacing(1)
  }
}))
