import React, { useMemo } from 'react'

import { Grid, Typography } from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'
import { AvailableSettings, OutboundQuarantineInterval, SettingValue } from 'types/Settings'

import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import SenderNotification from 'components/pages/support/help/senderNotification/SenderNotification'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import { useNotificationsLogic } from 'components/pages/outboundSettings/notifications/useNotificationsLogic'
import ScheduleMatrix from 'components/libs/scheduler/ScheduleMatrix'
import { useSettingsStateLogic } from 'components/libs/useSettingsState'
import {
  PageTitle,
  FormSpacer,
  PageSubtitle,
  FormRow,
  InlineRadioGroup,
  InlineInputField
} from 'components/libs/layout/pages/pageLayout'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'

const BASE_I18N_KEY = 'ess.outbound_settings.notifications'

const Notifications: React.FC = () => {
  const classes = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers, scheduleMatrixConfig] = useNotificationsLogic()
  const [UseGetSettingsStateLogic] = useSettingsStateLogic()

  return useMemo(
    () => (
      <SettingsPageContainer
        key={state.key}
        isLoading={UseGetSettingsStateLogic.isLoading}
        isFailedToLoad={UseGetSettingsStateLogic.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.isDirtyForm}
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          onCancel={eventHandlers.onCancelConfirm}
          onSubmit={eventHandlers.onSave}
          submitInProgress={state.isUpdatePending}
          isDisabledSubmit={!state.isDirtyForm}
          help={{
            content: SenderNotification,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.notifications,
            group: GROUP_TITLE.outboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <PageSubtitle>
          {formatMessage('admin_quarantine_notification')}
          <Typography variant="body1" className={classes.italic} data-testid="quarantine-description">
            {formatMessage('admin_quarantine_notification_description')}
          </Typography>
        </PageSubtitle>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('notification_interval')}>
          <InlineRadioGroup
            name={AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL}
            value={state.form.outbound_quarantine_interval}
            onChange={eventHandlers.onFormChange(AvailableSettings.OUTBOUND_QUARANTINE_INTERVAL)}
            disabled={state.isUpdatePending}
            values={[
              OutboundQuarantineInterval.NEVER,
              OutboundQuarantineInterval.IMMEDIATELY,
              OutboundQuarantineInterval.CUSTOM
            ]}
            labels={[formatMessage('never'), formatMessage('immediately'), formatMessage('scheduled')]}
          />
        </FormRow>
        <FormSpacer />
        {state.form.outbound_quarantine_interval === OutboundQuarantineInterval.CUSTOM && (
          <FormRow rowTitle={formatMessage('schedule_notification_intervals')}>
            <Grid item xs={5}>
              <ScheduleMatrix {...scheduleMatrixConfig} />
              <Typography variant="body2" className={classes.matrixDescription}>
                {formatMessage('matrix_description', {
                  br: () => <br />
                })}
              </Typography>
            </Grid>
          </FormRow>
        )}
        <FormSpacer />
        <FormRow rowTitle={formatMessage('notification_address')}>
          <InlineInputField
            inputClassName={classes.mediumInput}
            name={AvailableSettings.OUTBOUND_QUARANTINE_EMAIL}
            onChange={eventHandlers.onFormChange(AvailableSettings.OUTBOUND_QUARANTINE_EMAIL)}
            value={state.form.outbound_quarantine_email}
            disabled={state.form.outbound_quarantine_interval === OutboundQuarantineInterval.NEVER}
            error={!!state.formErrors.outbound_quarantine_email}
            helperText={state.formErrors.outbound_quarantine_email}
          />
        </FormRow>
        <FormSpacer />
        <FormSpacer />
        <PageSubtitle>
          {formatMessage('sender_quarantine_notification')}
          <Typography variant="body1" className={classes.italic} data-testid="sender-quarantine-description">
            {formatMessage('sender_quarantine_notification_description')}
          </Typography>
        </PageSubtitle>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('quarantine_sender_notification')}>
          <InlineRadioGroup
            name={AvailableSettings.SENDER_NOTIFICATION_ENABLE}
            value={state.form.sender_notification_enable}
            onChange={eventHandlers.onFormChange(AvailableSettings.SENDER_NOTIFICATION_ENABLE)}
            disabled={state.isUpdatePending}
            values={[SettingValue.ENABLED, SettingValue.DISABLED]}
            labels={[formatMessage('yes'), formatMessage('no')]}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('quarantine_notification_address')}>
          <InlineInputField
            inputClassName={classes.mediumInput}
            name={AvailableSettings.SENDER_NOTIFICATION_ADDRESS}
            onChange={eventHandlers.onFormChange(AvailableSettings.SENDER_NOTIFICATION_ADDRESS)}
            value={state.form.sender_notification_address}
            disabled={state.form.sender_notification_enable === SettingValue.DISABLED}
            error={!!state.formErrors.sender_notification_address}
            helperText={state.formErrors.sender_notification_address}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('quarantine_notification_subject')}>
          <InlineInputField
            inputClassName={classes.mediumInput}
            name={AvailableSettings.SENDER_NOTIFICATION_SUBJECT}
            onChange={eventHandlers.onFormChange(AvailableSettings.SENDER_NOTIFICATION_SUBJECT)}
            value={state.form.sender_notification_subject}
            placeholder={formatMessage('quarantine_notification_subject_placeholder')}
            disabled={state.form.sender_notification_enable === SettingValue.DISABLED}
          />
        </FormRow>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle={formatMessage('quarantine_notification_template')}>
          <InlineInputField
            inputClassName={classes.mediumArea}
            name={AvailableSettings.SENDER_NOTIFICATION_TEMPLATE}
            onChange={eventHandlers.onFormChange(AvailableSettings.SENDER_NOTIFICATION_TEMPLATE)}
            value={state.form.sender_notification_template}
            placeholder={formatMessage('quarantine_notification_template_placeholder')}
            disabled={state.form.sender_notification_enable === SettingValue.DISABLED}
            rows={9}
          />
        </FormRow>
        <FormSpacer />
        <FormSpacer />
        <PageSubtitle>
          {formatMessage('notification_to_sender')}
          <Typography variant="body1" className={classes.italic} data-testid="notification-sender-description">
            {formatMessage('notification_to_sender_description')}
          </Typography>
        </PageSubtitle>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('reject_notification_address')}>
          <InlineInputField
            inputClassName={classes.mediumInput}
            name={AvailableSettings.REJECT_NOTIFICATION_ADDRESS}
            onChange={eventHandlers.onFormChange(AvailableSettings.REJECT_NOTIFICATION_ADDRESS)}
            value={state.form.reject_notification_address}
            error={!!state.formErrors.reject_notification_address}
            helperText={state.formErrors.reject_notification_address}
          />
        </FormRow>
        <FormSpacer />
        <FormRow rowTitle={formatMessage('reject_notification_subject')}>
          <InlineInputField
            inputClassName={classes.mediumInput}
            name={AvailableSettings.REJECT_NOTIFICATION_SUBJECT}
            onChange={eventHandlers.onFormChange(AvailableSettings.REJECT_NOTIFICATION_SUBJECT)}
            value={state.form.reject_notification_subject}
            placeholder={formatMessage('reject_notification_subject_placeholder')}
          />
        </FormRow>
        <FormSpacer />
        <FormRow alignTitleToTop rowTitle={formatMessage('reject_notification_template')}>
          <InlineInputField
            inputClassName={classes.mediumArea}
            name={AvailableSettings.REJECT_NOTIFICATION_TEMPLATE}
            onChange={eventHandlers.onFormChange(AvailableSettings.REJECT_NOTIFICATION_TEMPLATE)}
            value={state.form.reject_notification_template}
            placeholder={formatMessage('reject_notification_template_placeholder')}
            rows={6}
          />
        </FormRow>
        {state.canEditSpamNotificationAddress && (
          <>
            <FormSpacer />
            <FormSpacer />
            <PageSubtitle>
              {formatMessage('admin_spam_notification')}
              <Typography variant="body1" className={classes.italic} data-testid="admin-spam-description">
                {formatMessage('admin_spam_notification_description')}
              </Typography>
            </PageSubtitle>
            <FormSpacer />
            <FormRow rowTitle={formatMessage('spam_notification_address')}>
              <InlineInputField
                inputClassName={classes.mediumInput}
                name={AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS}
                onChange={eventHandlers.onFormChange(AvailableSettings.OUTBOUND_SPAM_NOTIFICATION_ADDRESS)}
                value={state.form.outbound_spam_notification_address}
                error={!!state.formErrors.outbound_spam_notification_address}
                helperText={state.formErrors.outbound_spam_notification_address}
              />
            </FormRow>
          </>
        )}
        <FormSpacer />
        <FormSpacer />
        <FormSpacer />
      </SettingsPageContainer>
    ),
    [UseGetSettingsStateLogic, state, formatMessage, eventHandlers, classes, scheduleMatrixConfig]
  )
}

export default Notifications
