import React, { useMemo } from 'react'

import { Button, Grid, Typography } from '@barracuda-internal/bds-core'

import InboundMachineLearning from 'components/pages/support/help/InboundMachineLearning/InboundMachineLearning'
import inboundSettingsStyles from 'components/pages/inboundSettings/inboundSettingsStyles'
import { useFormatMessage } from 'lib/localization'
import { PageTitle, FormSpacer } from 'components/libs/layout/pages/pageLayout'
import { useMachineLearningLogic } from 'components/pages/inboundSettings/machineLearning/useMachineLearningLogic'
import { SettingsPageContainer } from 'components/libs/layout/pages/SettingsPageContainer'
import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import DomainTable from 'components/libs/domainTable/DomainTable'

const BASE_I18N_KEY = 'ess.inbound_settings.machine_learning'

const MachineLearning: React.FC = () => {
  const inboundClasses = inboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const [state, eventHandlers] = useMachineLearningLogic()

  return useMemo(
    () => (
      <SettingsPageContainer dirtyCheck isDirtyForm={state.hasPageChanges}>
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: InboundMachineLearning,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.machineLearning,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <Grid container>
          <Grid container className={inboundClasses.spaceGrid}>
            <Typography variant="h4">{formatMessage('machine_learning_exempt')}</Typography>
            <Button
              className={inboundClasses.bulkEdit}
              onClick={eventHandlers.onBulkEdit}
              variant="contained"
              size="small"
              data-testid="bulk-button"
            >
              Bulk Edit
            </Button>
          </Grid>
          <DomainTable
            data={state.tableData}
            onAddItem={eventHandlers.onAddItem}
            onRemoveItem={eventHandlers.onRemoveItem}
            shouldHandleEmail
            data-testid="machine-learning-exempt-table"
            delegateDirtyFormState={eventHandlers.setIsMachineLearningTableDirty}
          />
        </Grid>
      </SettingsPageContainer>
    ),
    [inboundClasses, formatMessage, state, eventHandlers]
  )
}

export default MachineLearning
