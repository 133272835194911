import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles((theme: any) => ({
  container: {
    marginTop: theme.spacing(20)
  },
  logo: {
    width: theme.spacing(10)
  },
  text: {
    color: colors.scaleGray600
  }
}))
