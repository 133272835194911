import React, { useMemo } from 'react'

import { Alert, Box, Card, CardContent, Grid, LinearProgress } from '@barracuda-internal/bds-core'
import pageStyles from 'components/libs/layout/pages/pagesLayoutStyles'
import { useErrorFormatMessage, useFormatMessage } from 'lib/localization'

import MessageLogList from 'components/pages/messageLog/messageLogList/MessageLogList'
import MessageLogDetails from 'components/pages/messageLog/messageLogDetails/MessageLogDetails'
import { Split } from 'components/libs/splitter/Split/Split'

import styles from 'components/pages/messageLog/messageLogStyles'
import { useMessageLogLogic, UseMessageLogLogicProps } from 'components/pages/messageLog/useMessageLogLogic'
import MessageLogSearchbar from 'components/pages/messageLog/messageLogSearchBar/MessageLogSearchBar'
import { UiState } from 'components/pages/messageLog/useMessageLogPreloadLogic'
import { PageTitle } from 'components/libs/layout/pages/pageLayout'
import Help from 'components/pages/support/help/Help'
import useUserRights from 'components/libs/userRights/useUserRights'

const BASE_I18N_KEY = 'ess.message_log'

export type MessageLogProps = UseMessageLogLogicProps

const MessageLog = ({ outboundQuarantineView = false }: MessageLogProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const errorFormatMessage = useErrorFormatMessage()
  const { inProgress, showSplitter, isHorizontal, uiState, preloadErrors, helpConfig, isAdmin, helpTitle } =
    useMessageLogLogic({ outboundQuarantineView })
  const { userFeatures } = useUserRights()
  const classes = styles({ isAdmin, optIn: !!userFeatures?.optInEnabled })
  const pageClasses = pageStyles()

  return useMemo(
    () => (
      <Grid className={classes.logTableContainer}>
        <Card className={classes.logTableCard} elevation={1}>
          <CardContent className={classes.logTableCardContent}>
            <Grid className={pageClasses.pageContainer} alignItems="center" container direction="column">
              <PageTitle
                title={formatMessage(`${outboundQuarantineView ? 'outbound_quarantine_' : ''}title`)}
                onOpenSupport={helpConfig.onHelpClick}
              />
            </Grid>
            {helpConfig.isOpen && <Help modalComponentInfo={helpTitle} />}
            {uiState === UiState.Loading && <LinearProgress data-testid="preload-progress" />}
            {uiState === UiState.Error && preloadErrors.getAccountPermissionsError && (
              <Alert className={classes.alert} severity="error" data-testid="alert-account-permissions">
                {errorFormatMessage(preloadErrors.getAccountPermissionsError)}
              </Alert>
            )}
            {uiState === UiState.Error && preloadErrors.getAvailableDomainsError && (
              <Alert className={classes.alert} severity="error" data-testid="alert-domains">
                {errorFormatMessage(preloadErrors.getAvailableDomainsError)}
              </Alert>
            )}
            {uiState === UiState.Error &&
              !preloadErrors.getAvailableDomainsError &&
              preloadErrors.noEmailDomainError && (
                <Alert className={classes.alert} severity="error" data-testid="alert-no-email-domain">
                  {formatMessage('error.no_email_domain')}
                </Alert>
              )}
            {uiState === UiState.Ready && (
              <>
                <MessageLogSearchbar />
                <Box className={classes.logTableProgress}>
                  {inProgress && <LinearProgress data-testid="table-progress" />}
                </Box>
                <Grid className={classes.logTableContent} data-testid="message-log-content">
                  <Split initialPrimarySize="100%" horizontal={isHorizontal} showSplitter={showSplitter}>
                    <Box className={classes.tableBox}>
                      <MessageLogList />
                    </Box>
                    <Box className={classes.drawerBox} flexDirection="column">
                      <MessageLogDetails />
                    </Box>
                  </Split>
                </Grid>
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
    ),
    [
      classes,
      pageClasses.pageContainer,
      formatMessage,
      helpConfig,
      helpTitle,
      uiState,
      preloadErrors,
      errorFormatMessage,
      inProgress,
      isHorizontal,
      showSplitter,
      outboundQuarantineView
    ]
  )
}

export default MessageLog
