import { createSlice } from '@reduxjs/toolkit'
import { ApiStatus, failedResponse, inIdle, inProgress, successResponse } from 'redux/toolkit/api'

import { GraphQLResponse, getBackupGraphql, doRestoreGraphql } from './graphqlApiThunks'

export interface GraphQLState {
  api: {
    getBackupGraphqlApiStatus: ApiStatus
    doRestoreGraphqlApiStatus: ApiStatus
  }
  graphQLResponse?: GraphQLResponse
}

// initialState
export const INITIAL_STATE: GraphQLState = {
  api: {
    getBackupGraphqlApiStatus: inIdle,
    doRestoreGraphqlApiStatus: inIdle
  },
  graphQLResponse: undefined
}

/* eslint-disable no-param-reassign */
export const graphqlSlice = createSlice({
  name: 'GRAPHQL',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // get backup graphql response
      .addCase(getBackupGraphql.pending, state => {
        state.api.getBackupGraphqlApiStatus = inProgress
      })
      .addCase(getBackupGraphql.fulfilled, (state, action) => {
        state.api.getBackupGraphqlApiStatus = successResponse
        state.graphQLResponse = action.payload
      })
      .addCase(getBackupGraphql.rejected, (state, action) => {
        state.api.getBackupGraphqlApiStatus = failedResponse(action.payload)
      })
      // do restore graphql response
      .addCase(doRestoreGraphql.pending, state => {
        state.api.doRestoreGraphqlApiStatus = inProgress
      })
      .addCase(doRestoreGraphql.fulfilled, (state, action) => {
        state.api.doRestoreGraphqlApiStatus = successResponse
        state.graphQLResponse = action.payload
      })
      .addCase(doRestoreGraphql.rejected, (state, action) => {
        state.api.doRestoreGraphqlApiStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = graphqlSlice.actions

export { getBackupGraphql, doRestoreGraphql }

export default graphqlSlice.reducer
