import React, { useMemo } from 'react'
import { DisableSpoolingWarningDialog } from 'components/pages/domains/editDomain/editDomainWarningDialogs/DisableSpooling'
import { DisableAzureAdWarningDialog } from 'components/pages/domains/editDomain/editDomainWarningDialogs/DisableAzureAd'
import { AzureAdAuthorizationWarningDialog } from 'components/pages/domains/editDomain/editDomainWarningDialogs/AzureAdAuthorization'

export enum EditDomainWarningDialogType {
  NONE,
  DISABLE_SPOOLING,
  DISABLE_AZURE_AD,
  AUTHORIZE_AZURE_AD
}

export interface DialogActions {
  onConfirm: () => void
  onClose: () => void
}

export interface EditDomainWarningDialogProps {
  type: EditDomainWarningDialogType
  dialogActions: DialogActions
}

export const EditDomainWarningDialog = ({ type, dialogActions }: EditDomainWarningDialogProps) => {
  let element: JSX.Element | null = null
  switch (true) {
    case type === EditDomainWarningDialogType.DISABLE_SPOOLING:
      element = <DisableSpoolingWarningDialog onClose={dialogActions.onClose} onConfirm={dialogActions.onConfirm} />
      break
    case type === EditDomainWarningDialogType.DISABLE_AZURE_AD:
      element = <DisableAzureAdWarningDialog onClose={dialogActions.onClose} onConfirm={dialogActions.onConfirm} />
      break
    case type === EditDomainWarningDialogType.AUTHORIZE_AZURE_AD:
      element = (
        <AzureAdAuthorizationWarningDialog onClose={dialogActions.onClose} onConfirm={dialogActions.onConfirm} />
      )
      break
    case type === EditDomainWarningDialogType.NONE:
    default:
      element = null
  }

  return useMemo(() => element, [element])
}
