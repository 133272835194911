import { useEffect, useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import { getAccountPermissions } from 'redux/features/settings/settingsSlice'

export enum InboundSettingsTabs {
  reputation = 'reputation',
  customRbls = 'custom_rbls',
  rateControl = 'rate_control',
  ipPolicies = 'ip_policies',
  regionalPolicies = 'regional_policies',
  recipientPolicies = 'recipient_policies',
  senderPolicies = 'sender_policies',
  senderAuth = 'sender_auth',
  contentPolicies = 'content_policies',
  antiPhishing = 'anti_phishing',
  machineLearning = 'machine_learning'
}

const TABS: [InboundSettingsTabs, UiRoute][] = [
  [InboundSettingsTabs.reputation, routesConfig.INBOUND_SETTINGS_REPUTATION],
  [InboundSettingsTabs.customRbls, routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS],
  [InboundSettingsTabs.rateControl, routesConfig.INBOUND_SETTINGS_RATE_CONTROL],
  [InboundSettingsTabs.ipPolicies, routesConfig.INBOUND_SETTINGS_IP_POLICIES],
  [InboundSettingsTabs.regionalPolicies, routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES],
  [InboundSettingsTabs.recipientPolicies, routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES],
  [InboundSettingsTabs.senderPolicies, routesConfig.INBOUND_SETTINGS_SENDER_POLICIES],
  [InboundSettingsTabs.senderAuth, routesConfig.INBOUND_SETTINGS_SENDER_AUTH],
  [InboundSettingsTabs.contentPolicies, routesConfig.INBOUND_SETTINGS_CONTENT_POLICIES],
  [InboundSettingsTabs.antiPhishing, routesConfig.INBOUND_SETTINGS_ANTI_PHISHING],
  [InboundSettingsTabs.machineLearning, routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING]
]

const BASE_I18N_KEY = 'ess.inbound_settings.tabs'

export const useInboundSettingsSubmenuItemsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const dispatch = useAppDispatch()
  const { appType, hasInferenceAccess, accessTokenObject } = useAppSelector(_stores => ({
    appType: _stores.app.appType,
    hasInferenceAccess: !!_stores.settings.accountPermissions?.hasInferenceAccess,
    accessTokenObject: _stores.auth.accessTokenObject
  }))

  useEffect(() => {
    if (accessTokenObject) {
      dispatch(getAccountPermissions())
    }
  }, [accessTokenObject, dispatch])

  const inboundSettingsListItems: SubMenuItem[] = useMemo(() => {
    function filterTabs(tab: [InboundSettingsTabs, UiRoute]) {
      const [tabName, route] = tab

      return (
        !!route?.hasAppTypeAccess(appType) && (tabName !== InboundSettingsTabs.machineLearning || hasInferenceAccess)
      )
    }

    return TABS.filter(filterTabs).map(tab => {
      const [tabId, routeConfig] = tab
      return {
        name: formatMessage(tabId),
        path: routeConfig.path
      }
    })
  }, [formatMessage, appType, hasInferenceAccess])

  return useMemo(() => [inboundSettingsListItems], [inboundSettingsListItems])
}
