/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import IconOkay from 'assets/images/icons/icn-ok.png'
import IconWarning from 'assets/images/icons/icn-warning.png'
import IconCritical from 'assets/images/icons/icn-critical.png'
import IconInfo from 'assets/images/icons/icn-info.png'

import { HelpComponentProps } from 'types/Help'

const Status = (props: HelpComponentProps) => {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      The <b>Dashboard</b> page displays summarized email statistics for {props.productName}.{' '}
      {!props.hasCpl && (
        <>Inbound statistics display first, followed by outbound statistics at the bottom of the page.</>
      )}
      <ul>
        <li>
          To filter data by domains verified with {props.productName}, from the <b>Select domain</b> drop-down menu,
          select either <b>All</b> or a specific domain. Data displays in the tables and graphs based on your selection.
        </li>
        <li>
          Use the <b>Time</b> drop-down menu to switch between a view of activity for the <b>Last 24 Hours</b> and the{' '}
          <b>Last 30 Days</b>.
        </li>
      </ul>
      <p>The page is organized into the following sections:</p>
      <ul>
        <li>
          <a href="#help-Origins">Threat Origins</a>
        </li>
        <li>
          <a href="#help-RecipientDomains">Top Recipient Domains/Top Sender Domains</a>
        </li>
        <li>
          <a href="#help-TrafficStatus">Traffic Status</a>
        </li>
        <li>
          <a href="#help-Subscription">Subscriptions</a>
        </li>
        <li>
          <a href="#help-InboundStats">Inbound Email Statistics</a>
        </li>
        <li>
          <a href="#help-InboundTopRecBlocked">Inbound: Top Recipients/Senders Blocked</a>
        </li>
        {!props.hasCpl && (
          <>
            <li>
              <a href="#help-OutboundStats">Outbound Email Statistics: Overview</a>
            </li>
            <li>
              <a href="#help-OutboundTopSendBlocked">Outbound: Top Recipients/Senders Blocked</a>
            </li>
          </>
        )}
        <li>
          <a href="#help-TotalThreats">Total Threats/Viruses: Overview</a>
        </li>
        <li>
          <a href="#help-LastBlocked">Last Blocked: ATP</a>
        </li>
      </ul>
      <br />
      <h3 id="help-Origins">Threat Origins</h3>
      View geographic origins of threats detected in email processed by {props.productName} for the domains in your
      account.Hover the mouse over any blue circle to see an abbreviation of the country name and number of threats
      detected for the selected time frame.
      <br />
      <h3 id="help-RecipientDomains">Top Recipient Domains/Top Sender Domains</h3>
      Click either the drop - down menu or the circle icons below this section to filter by total number of messages
      processed(<b>Volume</b>, number of <b> Blocked</b>
      messages, or number of <b> Allowed</b> messages.
      <br /> <br />
      For <b> Select domain</b> set to <b> All</b>:
      <ul>
        <li>
          <b>Top Recipient Domains, Volume</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Domains ranked for highest number of mail received for the selected time frame, ranked as <b>1</b>, followed
            by the next highest recipients.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain protected by{props.productName}.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of messages received, by domain, for the selected time frame.
          </li>
          <li>
            <b>Recipients</b>
            <br />
            The average number of recipients for that domain for the selected time frame.
          </li>
        </ul>
        <li>
          <b>Top Recipient Domains, Blocked</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Domains ranked for highest number of received messages blocked in the selected time frame, ranked as{' '}
            <b>1</b>, followed by the next highest recipient domains.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain protected by {props.productName}.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of messages blocked, by domain, for the selected time frame.
          </li>
        </ul>
        <li>
          <b>Top Recipient Domains, Allowed</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Domains ranked for highest number of allowed messages in the selected time frame, ranked as <b>1</b>,
            followed by the next highest recipient domains.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain protected by {props.productName}.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of messages allowed, by domain, for the selected time frame.
          </li>
        </ul>
      </ul>
      For <b> Select domain</b> set to a specific domain:
      <ul>
        <li>
          <b>Top Sender Domains, Volume</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Outside domains with the highest volume of mail sent to the selected domain, ranked as <b>1</b>, followed by
            the next highest senders.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain sending email through{props.productName} to the selected domain.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of messages sent to the selected domain, by sending domain, through{props.productName}.
          </li>
        </ul>
        <li>
          <b>Top Sender Domains, Blocked</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Outside domains ranked for highest number of blocked messages sent to the selected domain, ranked as{' '}
            <b>1</b>, followed by the next highest sending domains.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain sending email through{props.productName} to the selected domain.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of blocked messages sent to the selected domain, by sending domain, through
            {props.productName}.
          </li>
        </ul>
        <li>
          <b>Top Sender Domains, Allowed</b>
        </li>
        <ul>
          <li>
            <b>Rank</b>
            <br />
            Outside domains ranked for highest number of allowed messages sent to the selected domain, ranked as{' '}
            <b>1</b>, followed by the next highest sending domains.
          </li>
          <li>
            <b>Domain</b>
            <br />
            Domain sending email through{props.productName} to the selected domain.
          </li>
          <li>
            <b>Volume</b>
            <br />
            The total number of allowed messages sent to the selected domain, by sending domain, through
            {props.productName}.
          </li>
        </ul>
      </ul>
      <h3 id="help-TrafficStatus">Traffic Status</h3>
      This section shows the date and time of the most recently received{!props.hasCpl && <> and sent</>} messages.
      <br /> <br />
      <h3 id="help-Subscription">Subscriptions</h3>
      If you have purchased {props.hasCpl && <>a</>}
      {!props.hasCpl && <>an</>} {props.productName} account, this section displays the following:
      <ul>
        {!props.hasCpl && (
          <>
            <li>
              <b>Email Protection</b>
              <br />
              This subscription is for the{props.productName} account.Subscription status displays as follows:
            </li>
            <ul>
              <li>
                <img src={IconOkay} /> indicates the subscription or trial is Active.
              </li>
              <li>
                <img src={IconWarning} /> indicates the subscription is within 30 days of expiration.
              </li>
              <li>
                <img src={IconCritical} /> indicates the subscription or trial has expired.
              </li>
              <li>
                <b>Purchased</b>
              </li>
              <br />
              Displays your account status including the number of licenses purchased.
              <li>
                <b>Active</b>
                <br />
                Displays the total number of currently active users on the account.
                <br />
                <b>Important:</b> If the <b>Active</b> user value is higher than the <b>Purchased</b> value, a warning
                displays in the web interface and both fields display in red indicating that you are out of compliance.
                Click <b>Learn more</b> in the warning to view options to bring your account back into compliance, or
                click
                <b>Remind me later</b> to close the warning.
              </li>
              <li>
                <b>Expires</b>
                <br />
                Displays the account expiration date.
              </li>
            </ul>
          </>
        )}
        <li>
          <b>ATP</b>
          <br />
          This <i>optional</i> subscription is for the Advanced Threat Protection (ATP) service, which is a
          subscription-based cloud virus scanning service that applies to <i>inbound</i> messages. ATP analyzes email
          attachments in a separate, secured cloud environment to detect new threats and determine whether to block such
          messages.
          <br />
          Subscription status display as follows:
        </li>
        <ul>
          <li>
            <img src={IconOkay} /> indicates the subscription or trial is Active.
          </li>
          <li>
            <img src={IconWarning} /> indicates the subscription is within 30 days of expiration.
          </li>
          <li>
            <img src={IconCritical} /> indicates the subscription or trial has expired.
          </li>
          <li>
            <img src={IconInfo} /> indicates that the ATP subscription has not been purchased for this
            {props.productName} account.
          </li>
        </ul>
        Your ATP subscription expiration date displays.For details and to configure ATP, see the <b> ATP Settings</b>{' '}
        page.
      </ul>
      <h3 id="help-InboundStats">Inbound Email Statistics</h3>A graph of the total inbound messages processed, in the
      time frame, by number allowed{!props.hasCpl && <>, quarantined,</>} or blocked.
      <br />
      <br />
      <b>Note:</b> The data that displays in this graph is stacked. For example, you have 30 <b>Allowed</b> messages
      showing on top
      {!props.hasCpl && (
        <>
          , 140 <b>Quarantined</b> messages,
        </>
      )}{' '}
      and 48 <b>Blocked</b> messages{!props.hasCpl && <> for a total of 218 messages</>}.
      <br /> <br />
      The graph displays data either for <b>All</b> domains or by the domain selected in the <b> Select domain</b> drop
      - down menu.Click either the drop - down menu at the top of this section or the circle icons below this section to
      select one of the following filters for inbound mail.
      <ul>
        <li>
          <b>Overview</b>
          <br />A graph of messages processed by number allowed, blocked, {!props.hasCpl && <>quarantined, </>}blocked
          for virus, or blocked for ATP.
        </li>
        <li>
          <b>Blocked</b>
          <br />A graph of the number of messages blocked by the service, organized by:
        </li>
        <ul>
          <li>
            <b>BRBL</b>
            <br />
            Messages that are blocked because they are sent from a particular IP address that is on the Barracuda
            Reputation Block List (BRBL). This is a free DNSBL, or &apos;block list&apos;, of IP addresses known to send
            spam. Barracuda Networks created the BRBL to help stop the spread of spam.
          </li>
          <li>
            <b>Spam</b>
            <br />
            Messages that are determined to be Spam.
          </li>
          <li>
            <b>BRTS</b>
            <br />
            {!props.hasCpl && <>Messages quarantined by the Barracuda Real-Time System (BRTS). </>}This is an advanced
            service that detects zero-hour spam and virus outbreaks even where traditional heuristics and signatures to
            detect such messages do not yet exist.
            {!props.hasCpl && (
              <>
                {' '}
                Each quarantined message has a reason of <b>BRTS</b> in the Message Log. If user has not set up
                quarantine for email, messages are blocked.
              </>
            )}
          </li>
          <li>
            <b>Virus</b>
            <br />
            Messages blocked due to a virus.
          </li>
          <li>
            <b>ATP</b>
            <br />
            Messages blocked due to ATP.
          </li>
        </ul>
        For details and to configure these mechanisms, see the <b>
          {' '}
          Inbound Settings & gt; Anti - Spam / Antivirus
        </b>{' '}
        and <b> ATP Settings</b> pages.
        <li>
          <b>Allowed</b>
          <br />A graph of the number of messages allowed by the service.
        </li>
        {!props.hasCpl && (
          <li>
            <b>Quarantined</b>
            <br />A graph of the number of messages quarantined by the service.
          </li>
        )}
      </ul>
      <h3 id="help-InboundTopRecBlocked">Inbound: Top Recipients / Senders Blocked</h3>
      Click either the drop - down menu at the top of this section or the circle icons below this section to filter by
      either recipients of inbound blocked messages or senders of inbound blocked messages.
      <br /> <br />
      For <b> Select domain</b> set to <b> All</b> domains:
      <ul>
        <li>
          <b>Inbound: Top Recipients Blocked</b>
          <br />
          Email addresses of recipients, ranked by number of messages blocked, that had the most blocked inbound
          messages.
        </li>
        <li>
          <b>Inbound: Top Senders Blocked</b>
          <br />
          Email addresses of senders, ranked by number of messages blocked, that sent the most blocked messages across
          all domains.
        </li>
      </ul>
      For <b> Select domain</b> set to a specific domain:
      <ul>
        <li>
          <b>Inbound: Top Recipients Blocked</b>
          <br />
          Email addresses of recipients, ranked by number of messages blocked, for the selected domain that had the most
          blocked inbound messages.
        </li>
        <li>
          <b>Inbound: Top Senders Blocked</b>
          <br />
          Email addresses of senders, ranked by number of messages blocked, that sent the most blocked messages to the
          selected domain.
        </li>
      </ul>
      {!props.hasCpl && (
        <>
          <h3 id="help-OutboundStats">Outbound Email Statistics</h3>
          A graph of the total outbound messages processed, in the time frame, by number sent(allowed), blocked, and
          quarantined.
          <br />
          <br />
          <b>Note:</b> The data that displays in this graph is stacked.For example, you have 30 <b> Sent</b> messages
          showing on top, 48 <b> Blocked</b> messages, and 140 <b> Quarantined</b> messages for a total of 218 messages
          processed that day.
          <br /> <br />
          The graph displays data either for <b>All</b> domains or by the domain selected in the <b> Select domain</b>{' '}
          drop - down menu.Click either the drop - down menu at the top of this section or the circle icons below this
          section to select one of the following filters for outbound mail:
          <ul>
            <li>
              <b>Overview</b>
              <br />A graph of messages processed by number sent (allowed), blocked, and quarantined. From the drop-down
              menu, click <b>Overview</b> for this view, or click the first circle icon below this section.
            </li>
            <li>
              <b>Blocked</b>
              <br />A graph of the number of messages blocked by the service, organized by total blocked, blocked by
              spam, and blocked by virus.
            </li>
            <li>
              <b>Allowed</b>
              <br />A graph of the number of messages sent (allowed) by the service, organized by total sent, number
              allowed, and number encrypted. Hover the mouse over a point in the timeline to see number of sent
              (unencrypted) versus encrypted.
            </li>
            <li>
              <b>Quarantined</b>
              <br />A graph of the number of messages quarantined by the service.
            </li>
          </ul>
          <h3 id="help-OutboundTopSendBlocked">Outbound: Top Recipients/Senders Blocked</h3>
          Click either the drop - down menu at the top of this section or the circle icons below this section to select
          one of the following action filters for outbound mail.
          <br /> <br />
          For <b> Select domain</b> set to <b> All</b> domains:
          <ul>
            <li>
              <b>Outbound: Top Senders Blocked</b>
              <br />
              Email addresses of top blocked senders, ranked by number of messages blocked.
            </li>
            <li>
              <b>Outbound: Top Recipients Blocked</b>
              <br />
              Email addresses of top blocked recipients, ranked by number of messages blocked.
            </li>
          </ul>
          For <b> Select domain</b> set to a specific domain:
          <ul>
            <li>
              <b>Outbound: Top Recipients Blocked</b>
              <br />
              Email addresses of recipients of messages from the selected domain, ranked by the highest number of
              messages that were blocked by the service.
            </li>
            <li>
              <b>Outbound: Top Senders Blocked</b>
              <br />
              Email addresses of senders, ranked by number of messages blocked, that sent the most blocked messages from
              the selected domain.
            </li>
          </ul>
        </>
      )}
      <h3 id="help-TotalThreats">Total Threats/Viruses Detected</h3>
      If you have purchased an ATP subscription, these graphs display total threats and viruses detected by ATP.
      <br /> <br />
      Click either the drop - down menu at the top of this section or the circle icons below this section to select one
      of the following action filters.
      <br /> <br />
      For <b> Select domain</b> set to <b> All</b> domains:
      <ul>
        <li>
          <b>Overview</b>
          <br />
          Summary and table listing viruses detected by the{props.productName} virus scanner, as well as advanced
          threats detected by <b>ATP</b>.
        </li>
        <li>
          <b>File Type</b>
          <br />
          Chart showing number of each infected file type blocked by ATP as well as by the{props.productName} virus
          scanner for the selected <b>Time</b>. File types blocked by the{props.productName} virus scanner are indicated
          as <b>Virus</b>.
        </li>
      </ul>
      For <b> Select domain</b> set to a specific domain:
      <ul>
        <li>
          <b>Overview</b>
          <br />
          Summary and table listing viruses and advanced threats detected for inbound mail to this domain. Advanced
          threats are detected by <b>ATP</b>.
        </li>
        <li>
          <b>File Type</b>
          <br />
          Chart showing number of each infected file type blocked by ATP as well as by the{props.productName} virus
          scanner for this domain for the selected <b>Time</b>. File types blocked by the{props.productName} virus
          scanner are indicated as <b>Virus</b>.
        </li>
      </ul>
      <h3 id="help-LastBlocked">Last Blocked: ATP</h3>
      If you have purchased an ATP subscription, this table displays the latest files blocked by ATP.
      <br /> <br />
      For <b> Select domain</b> set to <b> All</b> domains:
      <ul>
        <li>
          <b>Filename</b>
          <br />
          Name of an attachment to an inbound email to{props.productName} that was determined by <b>ATP</b> to be
          infected.
        </li>
        <li>
          <b>File Type</b>
          <br />
          File type of the attachment determined by ATP to be infected.
        </li>
        <li>
          <b>Last Seen</b>
          <br />
          Date and time that the infected attachment was scanned by ATP.
        </li>
      </ul>
      For <b> Select domain</b> set to a specific domain:
      <ul>
        <li>
          <b>Filename</b>
          <br />
          Name of an attachment to an inbound email to this domain determined by <b>ATP</b> to be infected.
        </li>
        <li>
          <b>File Type</b>
          <br />
          File type of the attachment determined by ATP to be infected.
        </li>
        <li>
          <b>Last Seen</b>
          <br />
          Date and time the infected attachment was scanned by ATP.
        </li>
      </ul>
    </div>
  )
}

export default Status
/* eslint-enable jsx-a11y/alt-text */
