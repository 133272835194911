import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector } from 'redux/toolkit/hooks'

export const useRemediated = (): { remediated: boolean; remediatedBy: string | null; remediatedAt: number | null } => {
  const [searchParams] = useSearchParams()
  const [remediated, setRemediated] = useState<boolean>(false)
  const [remediatedBy, setRemediatedBy] = useState<string | null>(null)
  const [remediatedAt, setRemediatedAt] = useState<number | null>(null)
  const { message, search } = useAppSelector(_store => ({
    message: _store.mstore.message,
    search: _store.mstore.search
  }))

  // Get remediated flag from redux if available, from URL params otherwise
  // Leave remediated flag undefined is not available from any source
  useEffect(() => {
    if (!message) {
      return
    }
    const messageInSearch = search.messages?.find(item => item.mid === message.mid)
    if (messageInSearch) {
      setRemediated(messageInSearch.remediated)
      setRemediatedBy(messageInSearch.remediatedBy)
      setRemediatedAt(messageInSearch.timestamp)
      return
    }
    const remediatedParam = searchParams.get('remediated')
    const remediatedByParam = searchParams.get('remediated_by')
    const remediatedAtParam = searchParams.get('remediated_at')
    if ([remediatedParam, remediatedByParam, remediatedAtParam].some(val => val === null)) {
      return
    }
    setRemediated(remediatedParam === 'true')
    setRemediatedBy(remediatedByParam)
    setRemediatedAt(Number(remediatedAtParam))
  }, [message, search, searchParams])

  return useMemo(() => ({ remediated, remediatedBy, remediatedAt }), [remediated, remediatedBy, remediatedAt])
}
