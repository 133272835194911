import { ChangeEvent, useCallback, useMemo, useState, Dispatch, SetStateAction } from 'react'
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query'
import MD5 from 'crypto-js/md5'
import { v4 as makeUuid } from 'uuid'
import { GridSortChangeEvent } from '@progress/kendo-react-grid'
import { CustomRblsParsed } from 'types/CustomRbls'
import {
  CustomRblsForm,
  CustomRblsFormState,
  useCustomRblsForm
} from 'components/pages/inboundSettings/customRbls/useCustomRblsForm'

export interface UseCustomRblsTableLogicProps {
  data: CustomRblsParsed[]
  onAddItem: (ip: CustomRblsParsed) => void
  onRemoveItem: (id: string) => void
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export interface State {
  idToRemove: string
  sort: SortDescriptor[]
  form: CustomRblsForm
  formState: CustomRblsFormState
  error: string
  tableData: {
    data: CustomRblsParsed[]
    total: number
  }
}

export interface EventHandlers {
  onAdd: () => void
  onRemove: (id: string) => void
  onConfirmRemove: () => void
  onConfirmCancel: () => void
  handleOnInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleSortChange: (e: GridSortChangeEvent) => void
}

export type UseCustomRblsTableLogic = [State, EventHandlers]

const initialSort = [{ field: 'zone', dir: 'asc' } as SortDescriptor]

export const enum RBL_INPUT_NAMES {
  ZONE = 'zone',
  COMMENT = 'comment'
}

const INITIAL_FORM_STATE = { zone: '', comment: '' }

const BLANK_ENTRY = { ...INITIAL_FORM_STATE, id: '' }

export const useCustomRblsTableLogic = ({
  data,
  onAddItem,
  onRemoveItem,
  delegateDirtyFormState
}: UseCustomRblsTableLogicProps): UseCustomRblsTableLogic => {
  const form = useCustomRblsForm({
    initialState: INITIAL_FORM_STATE,
    delegateIsDirtyForm: delegateDirtyFormState
  })
  const [sort, setSort] = useState<SortDescriptor[]>(initialSort)
  const [idToRemove, setIdToRemove] = useState('')

  const onAdd = useCallback(() => {
    const newRbls: CustomRblsParsed = {
      zone: form.fields.zone.getValue(),
      comment: form.fields.comment.getValue(),
      id: MD5(makeUuid()).toString()
    }
    if (form.validate(newRbls, { data })) {
      onAddItem(newRbls)
    }
  }, [form, data, onAddItem])

  const onRemove = useCallback(
    (id: string) => {
      setIdToRemove(id)
    },
    [setIdToRemove]
  )

  const onConfirmRemove = useCallback(() => {
    onRemoveItem(idToRemove)
    setIdToRemove('')
  }, [idToRemove, onRemoveItem, setIdToRemove])

  const onConfirmCancel = useCallback(() => {
    setIdToRemove('')
  }, [setIdToRemove])

  const tableData = useMemo(() => {
    const orderedData = orderBy(data, sort)
    const { data: processedData } = process(orderedData, {})

    // Have the first item as an edit item.  This method works with sorting.
    processedData.unshift(BLANK_ENTRY)
    return {
      data: processedData as CustomRblsParsed[],
      total: processedData.length || 0
    }
  }, [data, sort])

  const handleSortChange = useCallback((e: GridSortChangeEvent) => {
    setSort(e.sort)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      // eslint-disable-next-line default-case
      switch (true) {
        case name === RBL_INPUT_NAMES.ZONE:
          form.fields.zone.setValue(value)
          break
        case name === RBL_INPUT_NAMES.COMMENT:
          form.fields.comment.setValue(value)
          break
      }
      form.delegateIsDirty()
    },
    [form]
  )

  return useMemo(
    () => [
      {
        idToRemove,
        sort,
        form,
        formState: form.getState(),
        error: form.error,
        tableData
      },
      {
        onAdd,
        onRemove,
        onConfirmRemove,
        onConfirmCancel,
        handleOnInputChange,
        handleSortChange
      }
    ],
    [
      idToRemove,
      sort,
      form,
      tableData,
      onAdd,
      onRemove,
      onConfirmRemove,
      onConfirmCancel,
      handleOnInputChange,
      handleSortChange
    ]
  )
}
