import { useCallback, useEffect, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import useLogout from 'lib/useLogout'
import appConfig from 'config/appConfig'
import { manageDomain } from 'redux/features/domains/domainsSlice'
import { resetManageDomain } from 'redux/features/domains/domainsActions'
import routesConfig from 'lib/routesConfig'
import { isSuccess } from 'redux/toolkit/api'
import { AppTypes } from 'types/AppTypes'
import { getBlockTransition, setBlockTransition } from 'lib/routes'

export type UseGlobalMessagesBannerLogic = {
  isBannerVisible: boolean
  onLogout: () => void
  onRemovePdDomainId: () => void
  userEmail: string | undefined
  pdDomainName: string | undefined | null
  isHelpdeskPdDomainId: boolean
}

export const useGlobalMessagesBannerLogic = (): [UseGlobalMessagesBannerLogic] => {
  const dispatch = useAppDispatch()
  const {
    userEmail,
    isImpersonatedUser,
    pdDomainName,
    isManageDomainApiStatusSuccess,
    isValidatedSession,
    appType,
    isMspRoleValidated
  } = useAppSelector(_store => ({
    userEmail: _store.auth.accessTokenObject?.userId,
    isImpersonatedUser: !!_store.auth.accessTokenObject?.isImpersonated,
    pdDomainName: _store.auth.accessTokenObject?.pdDomainName,
    isManageDomainApiStatusSuccess: isSuccess(_store.domains.api.manageDomainApiStatus),
    isValidatedSession: _store.auth.isValidatedSession,
    appType: _store.app.appType,
    isMspRoleValidated: !!_store.auth.accessTokenObject?.isMspRoleValidated
  }))
  const [, logoutImpersonatedEnduser] = useLogout()

  useEffect(() => {
    if (isManageDomainApiStatusSuccess && !pdDomainName) {
      dispatch(resetManageDomain())
      routesConfig.DOMAINS.goto()
    }
  }, [dispatch, pdDomainName, isManageDomainApiStatusSuccess])

  const onLogout = useCallback(() => {
    logoutImpersonatedEnduser()
  }, [logoutImpersonatedEnduser])

  const onRemovePdDomainId = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        setBlockTransition(undefined)
        dispatch(manageDomain({ pdDomainId: undefined }))
      })
    } else {
      dispatch(manageDomain({ pdDomainId: undefined }))
    }
  }, [dispatch])

  const isHelpdeskPdDomainId = useMemo(
    () => (!!pdDomainName && appType === AppTypes.helpdeskWithPdDomainId) || (!pdDomainName && isMspRoleValidated),
    [pdDomainName, appType, isMspRoleValidated]
  )

  const isBannerVisible = useMemo(() => {
    const isImpersonatedEnduserUser = appConfig.APP.IS_ENDUSER && !!userEmail && isImpersonatedUser
    const isPdDomainId = !!pdDomainName
    return isValidatedSession && (isImpersonatedEnduserUser || isPdDomainId || isHelpdeskPdDomainId)
  }, [isValidatedSession, userEmail, isImpersonatedUser, pdDomainName, isHelpdeskPdDomainId])

  return useMemo(
    () => [
      {
        isBannerVisible,
        onLogout,
        onRemovePdDomainId,
        userEmail: isImpersonatedUser ? userEmail : undefined,
        pdDomainName,
        isHelpdeskPdDomainId
      }
    ],
    [userEmail, onLogout, isBannerVisible, pdDomainName, onRemovePdDomainId, isImpersonatedUser, isHelpdeskPdDomainId]
  )
}
