import { useState, useMemo, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { updateAccountSettings } from 'redux/features/settings/settingsSlice'

export interface State {
  isOpen: boolean
  numberOfDomainsInConflict: number | undefined
}

export interface EventHandlers {
  onConfirm: () => void
  onCancel: () => void
}

export type UseDomainConflictDialogLogic = [State, EventHandlers]

export const useDomainConflictDialogLogic = (): UseDomainConflictDialogLogic => {
  const dispatch = useAppDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const { numberOfDomainsInConflict, conflictSettings } = useAppSelector(_stores => ({
    numberOfDomainsInConflict: _stores.settings.numberOfDomainsInConflict,
    conflictSettings: _stores.settings.conflictSettings
  }))

  useEffect(() => {
    if (numberOfDomainsInConflict) {
      setIsOpen(true)
    }
  }, [numberOfDomainsInConflict])

  const onConfirm = useCallback(() => {
    dispatch(
      updateAccountSettings({
        settings: conflictSettings,
        force: true
      })
    )
    setIsOpen(false)
  }, [dispatch, conflictSettings])

  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [])

  return useMemo(
    () => [
      {
        isOpen,
        numberOfDomainsInConflict
      },
      {
        onConfirm,
        onCancel
      }
    ],
    [isOpen, numberOfDomainsInConflict, onConfirm, onCancel]
  )
}
