import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import routesConfig from 'lib/routesConfig'
import { useEditDomainForm, EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { DomainsTabRights, useDomainsTabRights } from 'components/pages/domains/useDomainsTabRights'
import { resetEditDomain } from 'redux/features/domains/domainsApiThunks'
import { useAppDispatch } from 'redux/toolkit/hooks'

export interface State {
  help: {
    isHelpOpen: boolean
  }
  permissions: DomainsTabRights
}

export interface EventHandlers {
  help: {
    onHelpOpen: () => void
    onHelpClose: () => void
  }
}

export type EditDomainLogic = [State, EventHandlers, EditDomainForm]

export const useEditDomainLogic = (): EditDomainLogic => {
  const dispatch = useAppDispatch()
  const permissions = useDomainsTabRights()
  const { domainId } = useParams()
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const form = useEditDomainForm(domainId || '')

  const onHelpOpen = useCallback(() => setIsHelpOpen(true), [])

  const onHelpClose = useCallback(() => setIsHelpOpen(false), [])

  useEffect(() => {
    if (!domainId) {
      routesConfig.DOMAINS.goto()
    }
    dispatch(resetEditDomain())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(
    () => [
      {
        help: { isHelpOpen },
        permissions
      },
      { help: { onHelpOpen, onHelpClose } },
      form
    ],
    [isHelpOpen, onHelpOpen, onHelpClose, permissions, form]
  )
}
