import React from 'react'

import Loading from 'components/libs/loading/Loading'
import { useOAuth2CallbackLogic } from 'components/pages/oauth2/useOAuth2CallbackLogic'

function OAuth2Callback() {
  useOAuth2CallbackLogic()

  return <Loading />
}

export default OAuth2Callback
