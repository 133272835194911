import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundRegionalPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      Use the {props.productName} Regional Policies feature to <b>Block</b>{' '}
      {!props.hasCpl && (
        <>
          or <b>Quarantine</b>
        </>
      )}{' '}
      inbound messages based on the country of origin or character set.
      <br />
      <br />
      <h3>Sender Policies</h3>
      Use the <b>GeoIP Policies</b> section to <b>Block</b>{' '}
      {!props.hasCpl && (
        <>
          or <b>Quarantine</b>
        </>
      )}{' '}
      by country of origin:
      <ul>
        <li>
          <b>Country</b>
          <br />
          Select the country you want to <b>Block</b>{' '}
          {!props.hasCpl && (
            <>
              or <b>Quarantine</b>
            </>
          )}{' '}
          from the drop-down menu.
        </li>
        <li>
          <b>Policy</b>
          <br />
          Select <b>Block</b>{' '}
          {!props.hasCpl && (
            <>
              or <b>Quarantine</b>
            </>
          )}{' '}
          from the drop-down menu for the selected country.
        </li>
        <li>
          <b>Comment</b>
          <br />
          You can add an optional comment about the selected country.
        </li>
        <li>
          <b>Actions</b>
          <br />
          Click <b>Add</b> to add the selected country, or click <b>Remove</b> to remove an entry from the list.
        </li>
      </ul>
      {!props.hasCpl && (
        <>
          <h3>Content Policies</h3>
          Use the <b> Language Policies</b> section to <b> Block</b> or <b> Quarantine</b> by character class.
          {props.productName}
          uses a natural language detection library to determine the language in the mail.
          <ul>
            <li>
              <b>Language</b>
              <br />
              Select the language you want to <b>Block</b> or <b>Quarantine</b> from the drop-down menu.
            </li>
            <li>
              <b>Policy</b>
              <br />
              Select <b>Block</b> or <b>Quarantine</b> from the drop-down menu for the selected language.
            </li>
            <li>
              <b>Comment</b>
              <br />
              You can add an optional comment about the selected language.
            </li>
            <li>
              <b>Actions</b>
              <br />
              Click <b>Add</b> to add the selected language, or click <b>Remove</b> to remove an entry from the list.
            </li>
          </ul>
          <b>Note: </b>To block or quarantine mail based on a specific language character set, go to the{' '}
          <b> Content Policies</b> section. See{' '}
          <a href="http://a4esl.org/c/charset.html" target="_blank" rel="noreferrer">
            {' '}
            http://a4esl.org/c/charset.html
          </a>{' '}
          for a list of widely used language character sets.
        </>
      )}
    </div>
  )
}

export default InboundRegionalPolicies
