export const parseDataItem = (dataString: string, end: number): string[] | undefined => {
  if (!dataString.trim().length) {
    return undefined
  }

  const arr = dataString.trim().split(',')
  const item = arr.slice(0, end)
  item.push(arr.slice(end).join(','))
  item.map(value => value.trim())
  return item
}
