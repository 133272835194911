/* eslint-disable jsx-a11y/anchor-is-valid */
import reactHtmlParser from 'react-html-parser'
import { renderToString } from 'react-dom/server'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { Grid, Link, Typography } from '@barracuda-internal/bds-core'
import { useIntl } from 'react-intl'
import LinkedAccounts from 'components/pages/support/help/linkedAccounts/LinkedAccounts'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import EndUserSenderPolicy from 'components/pages/support/help/endUserSenderPolicy/EndUserSenderPolicy'
import EndUserQuarantineNotification from 'components/pages/support/help/endUserQuarantineNotification/EndUserQuarantineNotification'
import EndUserLog from 'components/pages/support/help/endUserLog/EndUserLog'

export interface State {
  searchResults: ReactNode | null
}

export interface EventHandlers {
  onLinkClick: (uiRoute: UiRoute) => () => void
  onSearch: (query: string) => void
  onReset: () => void
}

export type SearchLogic = [State, EventHandlers]

const BASE_I18N_KEY = 'ess.support_page'

export const useSearchLogic = (): SearchLogic => {
  const [searchResults, setSearchResults] = useState<ReactNode | null>(null)
  const intl = useIntl()
  const parser = useMemo(() => new DOMParser(), [])

  const jsxToString = useCallback(
    (jsx: React.ReactElement) => {
      const htmlString = renderToString(jsx)
      return parser.parseFromString(htmlString, 'text/html').documentElement.textContent
    },
    [parser]
  )

  // Searches with individual keywords or strings
  const handleSearchString = (lowerCaseHelpContent: string | undefined, lowerCaseSearchQuery: string) => {
    /* eslint-disable quotes */
    const singleQ = "'"
    const doubleQ = '"'
    /* eslint-enable quotes */

    // Ignore quotation marks surrounding strings to be searched
    if (
      (lowerCaseSearchQuery.startsWith(singleQ) || lowerCaseSearchQuery.startsWith(doubleQ)) &&
      (lowerCaseSearchQuery.endsWith(singleQ) || lowerCaseSearchQuery.endsWith(doubleQ))
    ) {
      // eslint-disable-next-line no-param-reassign
      lowerCaseSearchQuery = lowerCaseSearchQuery.substring(1, lowerCaseSearchQuery.length - 1)
      return lowerCaseHelpContent?.includes(lowerCaseSearchQuery)
      // Search with individual keywords
    }
    if (lowerCaseSearchQuery.includes(' ')) {
      let searchHit = 0
      const searchArray = lowerCaseSearchQuery.split(' ')
      searchArray.map(searchWord => {
        if (lowerCaseHelpContent?.includes(searchWord)) {
          searchHit += 1
        }
        return searchWord
      })
      // return true if any of the keywords are found in searched content
      return searchHit
      // Single word search
    }
    return lowerCaseHelpContent?.includes(lowerCaseSearchQuery)
  }

  const onReset = () => setSearchResults(null)

  const onLinkClick = (route: UiRoute) => () => route.goto()

  // This array contains the info to be searched (name) and search result content links (value)
  const helpContents = useMemo(
    () => [
      {
        id: 'linkedAccountsHelp',
        name: jsxToString(<LinkedAccounts />),
        value: (
          <Link onClick={onLinkClick(routesConfig.SUPPORT_HELP_LINKED_ACCOUNTS)} data-name="linkedAccounts">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.linked_accounts` })}
          </Link>
        ),
        title: 'Linked Accounts'
      }, // Linked Accounts
      {
        id: 'senderPolicyHelp',
        name: jsxToString(<EndUserSenderPolicy />),
        value: (
          <Link onClick={onLinkClick(routesConfig.SUPPORT_HELP_ENDUSER_SENDER_POLICY)} data-name="senderPolicy">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.sender_policy` })}
          </Link>
        )
      }, // Sender Policy
      {
        id: 'quarantineNotificationHelp',
        name: jsxToString(<EndUserQuarantineNotification />),
        value: (
          <Link
            onClick={onLinkClick(routesConfig.SUPPORT_HELP_ENDUSER_QUARANTINE_NOTIFICATION)}
            data-name="quarantineNotification"
          >
            {intl.formatMessage({
              id: `${BASE_I18N_KEY}.quarantine_notification`
            })}
          </Link>
        )
      }, // Quarantine Notification
      {
        id: 'messageLogHelp',
        name: jsxToString(<EndUserLog />),
        value: (
          <Link onClick={onLinkClick(routesConfig.SUPPORT_HELP_ENDUSER_LOG)} data-name="messageLog">
            {intl.formatMessage({ id: `${BASE_I18N_KEY}.message_log` })}
          </Link>
        )
      } // Message Log
    ],
    [intl, jsxToString]
  )

  // Returns an array of help content files that have search matches
  const filterHelpContents = useCallback((helpContentList: typeof helpContents, theSearchQuery: string) => {
    if (!theSearchQuery) {
      return []
    }

    return helpContentList.filter(helpContent => {
      const helpContentLowerCase = helpContent.name?.toLowerCase() || ''
      const helpContentTitleLowerCase = helpContent.title?.toLowerCase() || ''
      const theSearchQueryLowerCase = theSearchQuery.toLowerCase()
      return handleSearchString(helpContentLowerCase + helpContentTitleLowerCase, theSearchQueryLowerCase)
    })
  }, [])

  const search = useCallback(
    (query: string) => {
      const myFilteredHelpContents = filterHelpContents(helpContents, query)
      if (myFilteredHelpContents.length === 0) {
        return (
          <ul>
            <li>
              <Typography variant="body1">
                {intl.formatMessage({ id: `${BASE_I18N_KEY}.no_search_results` })}{' '}
              </Typography>
              {/* Your search query returned no results. */}
            </li>
          </ul>
        )
      }

      return (
        <ul>
          {myFilteredHelpContents.map(myHelpContent => (
            <li id={myHelpContent.id} key={myHelpContent.id}>
              <Typography variant="body1">
                {myHelpContent.value}
                <br />
                <Grid component="span" container>
                  <Grid component="span" item xs={12}>
                    {reactHtmlParser(myHelpContent.name?.substring(0, 207))}
                  </Grid>
                </Grid>
                <br />
              </Typography>
            </li>
          ))}
        </ul>
      )
    },
    [filterHelpContents, helpContents, intl]
  )

  const onSearch = useCallback(
    (query: string) => {
      setSearchResults(search(query))
    },
    [search]
  )

  return useMemo(
    () => [
      {
        searchResults
      },
      {
        onLinkClick,
        onSearch,
        onReset
      }
    ],
    [onSearch, searchResults]
  )
}
