import { useMemo, useEffect, useCallback, useState, ChangeEvent, Dispatch, SetStateAction } from 'react'
import { v4 as makeUuid } from 'uuid'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, DomainEntry, SettingValue } from 'types/Settings'
import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess, isPending } from 'redux/toolkit/api'
import routesConfig from 'lib/routesConfig'
import { useDirtyFormCheck } from 'lib/useDirtyFormCheck'
import { getBlockTransition, setBlockTransition } from 'lib/routes'

export interface State {
  tlsAll: SettingValue
  tlsChanged: boolean
  data: any[]
  isUpdateInProgress: boolean
  hasPageChanges: boolean
  key: string
}

export interface EventHandlers {
  onAddItem: (item: DomainEntry) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
  onFormChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSave: () => void
  onCancelConfirm: () => void
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  setIsEncryptionTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseEncryptionLogic = [State, EventHandlers]

const SETTINGS_LIST = [
  AvailableSettings.OUTBOUND_TLS_ALL,
  AvailableSettings.OUTBOUND_TLS_DOMAINS,
  AvailableSettings.OUTBOUND_TLS_DOMAIN_EXEMPTIONS
]

export const useEncryptionLogic = (): UseEncryptionLogic => {
  const dispatch = useAppDispatch()
  const [tlsAll, setTlsAll] = useState<SettingValue>(SettingValue.DISABLED)
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  // key is used to force a re-render of <SettingsPage>
  // The Cancel button is supposed to reset the initial form values only after a confirmation.
  // The need for a confirmation (block) is set up via useDirtyFormCheck
  const [key, setKey] = useState(makeUuid())

  const {
    accessTokenObject,
    isUpdateAccountSettingsPending,
    isUpdateDomainSettingsPending,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    accountSettings,
    domainSettings
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    isUpdateAccountSettingsPending: isPending(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsPending: isPending(_stores.settings.updateDomainSettingsApiStatus),
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus),
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const [shouldUpdateInitialForm, setShouldUpdateInitialForm] = useState<boolean>(false)
  const [isDirtyForm, resetInitialForm] = useDirtyFormCheck([tlsAll])
  const [isEncryptionTableDirty, setIsEncryptionTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
    } else {
      dispatch(getAccountSettings(SETTINGS_LIST))
    }
    // eslint-disable-next-line
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  // set initialForm values
  useEffect(() => {
    if (shouldUpdateInitialForm) {
      setShouldUpdateInitialForm(false)
      resetInitialForm()
    }
  }, [shouldUpdateInitialForm, resetInitialForm])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(getDomainSettings({ domainId: accessTokenObject?.pdDomainId, settings: SETTINGS_LIST }))
      } else {
        dispatch(getAccountSettings(SETTINGS_LIST))
      }
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const onFormChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTlsAll(e.target.value as SettingValue)
  }, [])

  useEffect(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    setTlsAll((settings.outbound_tls_all as SettingValue) || SettingValue.DISABLED)
    setShouldUpdateInitialForm(true)
  }, [accessTokenObject, domainSettings, accountSettings])

  const tlsChanged = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return tlsAll !== settings.outbound_tls_all && settings.outbound_tls_all !== null
  }, [tlsAll, accessTokenObject, domainSettings, accountSettings])

  const data = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedExemptions = settings.outbound_tls_domain_exemptions
      ? (JSON.parse(settings.outbound_tls_domain_exemptions as string) as DomainEntry[])
      : []
    const parsedDomains = settings.outbound_tls_domains
      ? (JSON.parse(settings.outbound_tls_domains as string) as DomainEntry[])
      : []

    return tlsAll === SettingValue.ENABLED ? parsedExemptions : parsedDomains
  }, [tlsAll, accessTokenObject, domainSettings, accountSettings])

  const updateSettings = useCallback(
    (entries: DomainEntry[]) => {
      const settingName =
        tlsAll === SettingValue.ENABLED
          ? AvailableSettings.OUTBOUND_TLS_DOMAIN_EXEMPTIONS
          : AvailableSettings.OUTBOUND_TLS_DOMAINS
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { outbound_tls_all: tlsAll, [settingName]: entries }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { outbound_tls_all: tlsAll, [settingName]: entries } }))
      }
    },
    [tlsAll, dispatch, accessTokenObject]
  )

  const onAddItem = useCallback(
    (item: DomainEntry) => {
      const entries = [...data, item]
      updateSettings(entries)
    },
    [data, updateSettings]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const exemptions = data.filter(entry => entry.id !== id)
      updateSettings(exemptions)
    },
    [data, updateSettings]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.OUTBOUND_SETTINGS_ENCRYPTION_BULK_EDIT.goto()
  }, [])

  const onSave = useCallback(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        updateDomainSettings({
          domainId: accessTokenObject.pdDomainId,
          settings: { outbound_tls_all: tlsAll }
        })
      )
    } else {
      dispatch(updateAccountSettings({ settings: { outbound_tls_all: tlsAll } }))
    }
  }, [dispatch, tlsAll, accessTokenObject])

  const onCancelConfirm = useCallback(() => {
    const block = getBlockTransition()
    if (block) {
      block(() => {
        setBlockTransition(undefined)
        const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
        setTlsAll((settings.outbound_tls_all as SettingValue) || SettingValue.DISABLED)
        setKey(makeUuid())
      })
    }
  }, [accountSettings, domainSettings, accessTokenObject])

  return useMemo(
    () => [
      {
        tlsAll,
        tlsChanged,
        data,
        isUpdateInProgress: isUpdateAccountSettingsPending || isUpdateDomainSettingsPending,
        hasPageChanges: isDirtyForm || isEncryptionTableDirty,
        key
      },
      {
        onAddItem,
        onRemoveItem,
        onBulkEdit,
        onFormChange,
        onSave,
        onCancelConfirm,
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        setIsEncryptionTableDirty
      }
    ],
    [
      tlsAll,
      tlsChanged,
      data,
      isUpdateAccountSettingsPending,
      isUpdateDomainSettingsPending,
      isDirtyForm,
      isEncryptionTableDirty,
      key,
      onAddItem,
      onRemoveItem,
      onBulkEdit,
      onFormChange,
      onSave,
      onCancelConfirm,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp
    ]
  )
}
