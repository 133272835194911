import { Critical, Success, Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'

// ATP Functions
export enum AtpStatus {
  timeout = 'timeout',
  future = 'future',
  clean = 'clean',
  infected = 'infected',
  suspicious = 'suspicious',
  error = 'error'
}
export enum AtpRisk {
  none = 'none',
  low = 'low',
  medium = 'medium',
  high = 'high',
  error = 'error'
}

export function atpStatus(status: string) {
  switch (true) {
    case status === '0':
      return AtpStatus.timeout
    case status[0] === '1' || status === '800':
      return AtpStatus.future
    case status[0] === '2' || ['601', '602', '850'].includes(status):
      return AtpStatus.clean
    case status[0] === '3':
      return AtpStatus.infected
    case status[0] === '4':
      return AtpStatus.suspicious
    default:
      return AtpStatus.error
  }
}

export function atpRisk(risk: number) {
  switch (true) {
    case !risk:
      return AtpRisk.none
    case risk <= 33:
      return AtpRisk.low
    case risk <= 66:
      return AtpRisk.medium
    case risk <= 100:
      return AtpRisk.high
    default:
      return AtpRisk.error
  }
}

export function atpStatusIcon(status: AtpStatus) {
  switch (true) {
    case status === AtpStatus.infected:
      return Critical
    case status === AtpStatus.suspicious:
      return Warning
    case status === AtpStatus.clean:
      return Success
    case status === AtpStatus.error:
      return Warning
    default:
      return undefined
  }
}

export const atpIsFuture = (status: AtpStatus, determination: string | undefined) =>
  status === AtpStatus.future && (!determination || determination === AtpStatus.error)
