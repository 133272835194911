import { useCallback, useEffect, useMemo } from 'react'

import { ApiResponseError, getErrorMessage, isPending } from 'redux/toolkit/api'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postFpfnReport, resetFpfnRedirectionUrl } from 'redux/features/fpfn/fpfnSlice'
import { FpFnType, Message } from 'types/Messages'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { useUserDomainIdOverride } from 'lib/hooks/useUserDomainIdOverride'

export interface ReportingActionModalProps {
  handleClose: () => void
  fpFnType: FpFnType
}

export interface UseReportingActionModalLogic {
  postFpfnReportInProgress: boolean
  messageData: {
    messageIsLoading: boolean
    message: Message | undefined
    messageLoadingError: ApiResponseError
  }
  handleReport: () => void
}

export const useReportingActionModalLogic = (props: ReportingActionModalProps): UseReportingActionModalLogic => {
  const {
    getMessageInProgress,
    mstoreMessage,
    messageLoadingError,
    redirectionUrl,
    postFpfnReportInProgress,
    postFpfnReportErrorMessage,
    userDomainId
  } = useAppSelector(_store => ({
    getMessageInProgress: isPending(_store.mstore.getMessageApiStatus),
    messageLoadingError: getErrorMessage(_store.mstore.getMessageApiStatus),
    mstoreMessage: _store.mstore.message,
    redirectionUrl: _store.fpfn.redirectionURL,
    postFpfnReportInProgress: isPending(_store.fpfn.redirectionUrlApiStatus),
    postFpfnReportErrorMessage: getErrorMessage(_store.fpfn.redirectionUrlApiStatus),
    userDomainId: _store.auth.accessTokenObject?.domainId
  }))
  const overrideDomainId = useUserDomainIdOverride()
  const dispatch = useAppDispatch()

  const messageData = useMemo(
    () => ({
      messageIsLoading: getMessageInProgress,
      message: mstoreMessage,
      messageLoadingError
    }),
    [getMessageInProgress, mstoreMessage, messageLoadingError]
  )

  useEffect(() => {
    const handleReportType = props.fpFnType

    if (messageData.message) {
      const domainId = overrideDomainId(messageData.message?.did)
      dispatch(
        postFpfnReport({
          type: handleReportType,
          messageId: messageData.message.mid,
          domainId
        })
      )
    }
    return () => {
      dispatch(resetFpfnRedirectionUrl())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (postFpfnReportErrorMessage) {
      props.handleClose()
    }
  }, [postFpfnReportErrorMessage, props])

  const handleReport = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WEBUI.PROVIDE_ADDITIONAL_DETAILS)
    if (redirectionUrl && !postFpfnReportErrorMessage) {
      window.open(redirectionUrl, '_blank')
    }
    props.handleClose()
  }, [redirectionUrl, postFpfnReportErrorMessage, props])

  return useMemo(
    () => ({
      postFpfnReportInProgress,
      messageData,
      handleReport
    }),
    [postFpfnReportInProgress, messageData, handleReport]
  )
}
