import React, { useMemo } from 'react'

import { IconButton, TextField } from '@barracuda-internal/bds-core'

import { Close, Search } from '@barracuda-internal/bds-core/dist/Icons/Core'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/libs/layout/pages/pagesLayoutStyles'
import { onKeyDown } from 'lib/keyEvents'

const BASE_I18N_KEY = 'ess.layout'

export type SearchFieldProps = {
  value: string
  onChange: (e: any) => void
  onSearch: () => void
  onReset: () => void
  placeholder?: string
}

export const SearchField = ({ value, onChange, onSearch, onReset, placeholder = '' }: SearchFieldProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()

  return useMemo(
    () => (
      <TextField
        className={classes.searchField}
        label={formatMessage('search')}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown(['Enter'], onSearch)}
        InputProps={{
          startAdornment: (
            <IconButton
              size="small"
              edge="start"
              data-id="search-button"
              data-testid="search-button"
              onClick={onSearch}
            >
              <Search />
            </IconButton>
          ),
          endAdornment: !!value.length && (
            <IconButton
              size="small"
              edge="end"
              data-id="search-bar-clear-button"
              data-testid="clear-button"
              onClick={onReset}
            >
              <Close />
            </IconButton>
          )
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        data-testid="search-input"
      />
    ),
    [formatMessage, value, onSearch, onChange, onReset, classes, placeholder]
  )
}
