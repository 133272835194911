import { useCallback, useMemo } from 'react'
import { DomainEntry } from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { useFormatMessage } from 'lib/localization'
import { isEmailOrDomainValid } from 'lib/validation'

export interface DomainTableFormState {
  domain: string
  comment: string
}

export interface ValidatorDependencies {
  data: DomainEntry[]
  shouldHandleEmail: boolean
}

export interface DomainTableFormConfig {
  initialState: DomainTableFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export type DomainTableForm = SettingsForm<DomainTableFormState>

const BASE_I18N_KEY = 'ess.settings.domain_table.error'

export const useDomainTableForm = ({ initialState, delegateIsDirtyForm }: DomainTableFormConfig): DomainTableForm => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const domainField = useRefField(initialState.domain)
  const commentField = useRefField(initialState.comment)

  const formConfig = useMemo(
    () => ({
      fields: {
        domain: domainField,
        comment: commentField
      },
      delegateIsDirtyForm
    }),
    [commentField, delegateIsDirtyForm, domainField]
  )
  const form = useSettingsForm<DomainTableFormState>(formConfig)

  const validate = useCallback(
    (newEntry: DomainEntry, dependencies: ValidatorDependencies) => {
      form.setError('')

      const findDuplicate = dependencies.data.find(entry => entry.domain === newEntry.domain)
      if (findDuplicate) {
        form.setError(formatMessage('duplicate_value', { item: findDuplicate.domain }))
        return false
      }

      if (!newEntry.domain) {
        form.setError(formatMessage('empty_domain'))
        return false
      }

      if (dependencies.shouldHandleEmail) {
        if (!isEmailOrDomainValid(newEntry.domain)) {
          form.setError(formatMessage('invalid_email_domain'))
          return false
        }
      } else {
        const regex = /^[a-z0-9.-]{1,255}$/i
        if (!regex.test(newEntry.domain)) {
          form.setError(formatMessage('invalid_domain'))
          return false
        }
      }

      return true
    },
    [form, formatMessage]
  )

  return useMemo(
    () => ({
      ...form,
      validate
    }),
    [form, validate]
  )
}
