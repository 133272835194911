import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function InboundRecipientPolicies(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      <b>Note</b>: These settings apply to all domains you have verified in {props.productName} for processing email,
      unless you change these settings for a specific domain. In that case, the domain-level settings override the
      global system settings.
      <br />
      <br />
      Add any recipient email addresses you want to specifically always scan or always exempt (allow). To apply a
      default behavior to all recipients, select either <b>Scan</b> or <b>Exempt</b> from the{' '}
      <b>Default policy for all users</b> drop-down menu.
      <br />
      <br />
      Exempt (allowed) recipients bypass spam scoring (see <b>Enable Cloudscan</b> on the{' '}
      <b>Inbound Settings &gt; Anti-Spam/Anti-Virus</b> page) as well as all other block lists. Virus scanning still
      applies.
      <br />
      <br />
      Click on one of the column headdings to sort the table:
      <ul>
        <li>Repicient</li>
        <li>Policy</li>
        <li>Comment</li>
      </ul>
    </div>
  )
}

export default InboundRecipientPolicies
