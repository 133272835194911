import {
  SearchSettings,
  SearchSettingsRepository,
  SearchSettingsViewConfig
} from 'lib/services/searchSettings/searchSettingsRepository'
import logger from 'lib/logger'

export const LocalStorageSearchSettingsRepository = (
  viewConfig: SearchSettingsViewConfig
): SearchSettingsRepository => {
  const data = new Map<string, SearchSettings>()
  const getKey = () => `search_settings_${viewConfig.type}_${viewConfig.accountId}_${viewConfig.domainId || ''}`

  const read = () => {
    const key = getKey()
    const res = localStorage.getItem(key)
    if (!res) {
      return
    }

    try {
      const currentData = JSON.parse(res)
      data.clear()
      currentData.forEach((item: SearchSettings) => {
        data.set(item.id, item)
      })
    } catch (e) {
      logger.error(`Failed to parse localStorage key ${key}: ${res}`)
    }
  }

  const write = () => {
    const key = getKey()
    const entries = Array.from(data.values())
    try {
      localStorage.setItem(key, JSON.stringify(entries))
    } catch (e) {
      logger.error(`Failed to write to localStorage key ${key}: ${entries}`)
    }
  }

  const getAll = async () => {
    read()
    return Array.from(data.values())
  }

  const add = async (item: SearchSettings): Promise<void> => {
    read()
    data.set(item.id, item)
    await write()
  }

  const deleteFn = async (id: string): Promise<void> => {
    read()
    data.delete(id)
    write()
  }

  return Object.freeze({
    getAll,
    add,
    delete: deleteFn
  })
}
