import React, { useMemo } from 'react'

import { Grid } from '@barracuda-internal/bds-core'

import styles from 'components/pages/overview/dashboard/dashboardStyles'
import TableWithDropdown from 'components/libs/tableWithDropdown/TableWithDropdown'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'
import { StatType } from 'types/stats'
import { useOutboundTopBlockedLogic, UseOutboundTopBlockedLogicProps } from './useOutboundTopBlockedLogic'

const OutboundTopBlocked: React.FC<UseOutboundTopBlockedLogicProps> = (props: UseOutboundTopBlockedLogicProps) => {
  const classes = styles()
  const [state, eventHandlers] = useOutboundTopBlockedLogic(props)

  return useMemo(() => {
    const config = getTableWithDropdownConfig(
      DisplayType.outbound,
      state.selectedFilter === StatType.SENDERS ? StatType.SENDERS : StatType.RECIPIENTS
    )
    return (
      <Grid className={classes.tableWrapper} item data-testid="container">
        {config && (
          <TableWithDropdown
            config={config}
            data={state.outboundTopBlockedStats?.results}
            onSelect={eventHandlers.onSelectFilter}
            selectedValue={state.selectedFilter}
            inProgress={state.outboundTopBlockedStatsInProgress}
            rankColumn
          />
        )}
      </Grid>
    )
  }, [state, classes, eventHandlers])
}

export default OutboundTopBlocked
