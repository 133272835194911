import React, { useMemo } from 'react'

import { Grid, LinearProgress } from '@barracuda-internal/bds-core'

import ContentLoaderMessage from 'components/libs/contentLoader/ContentLoaderMessage'
import styles from 'components/libs/pageLoader/pageLoaderStyles'

export interface PageLoaderProps {
  isLoading: boolean
  isFailed: boolean
  isSuccess: boolean
  children: any
}

function PageLoader({ isLoading, isFailed, isSuccess, children }: PageLoaderProps) {
  const classes = styles()

  return useMemo(
    () => (
      <Grid>
        {isLoading && <LinearProgress data-testid="linear-loader" />}
        {isFailed && <ContentLoaderMessage data-testid="error-page" />}
        {isSuccess && (
          <Grid container className={classes.contentWrapper} data-testid="content-wrapper" direction="column">
            {isLoading && <Grid className={classes.loadingOverlay} data-testid="content-overlay" />}
            {children}
          </Grid>
        )}
      </Grid>
    ),
    [classes, isLoading, isFailed, isSuccess, children]
  )
}

export default PageLoader
