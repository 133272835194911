import { useCallback, useMemo } from 'react'

import { camelCase } from 'lodash'
import { BarChartData, LineChartData } from '@cuda/bds.ui.types'

import { useAppSelector } from 'redux/toolkit/hooks'
import {
  BarracudaReportTypes,
  ChartTypes,
  EmailFlows,
  FieldNames,
  ReportIntervals,
  ReportsListItem,
  ReportsResponse,
  TopRecipientsReport,
  TrafficSummaryReport
} from 'types/reports'
import { useFormatMessage } from 'lib/localization'
import { luxonDate } from 'lib/datetime'
import { isPending } from 'redux/toolkit/api'

export type Labels = string[] | undefined
export type IsFilled = boolean
export type ChartConfig = [ChartTypes, Labels, IsFilled]
type ChartReportConfig = {
  [key in BarracudaReportTypes]: ChartConfig[]
}
export type ChartsConfig = {
  [key in EmailFlows]: ChartReportConfig
}
const CHART_COLORS: { [key in FieldNames]?: string } = {
  [FieldNames.totalDeferred]: '#265973',
  [FieldNames.totalBlocked]: '#0DCCF2',
  [FieldNames.totalQuarantined]: '#A816AB',
  [FieldNames.totalAllowed]: '#C2AFF8',
  [FieldNames.blockedByRateControl]: '#265973',
  [FieldNames.blockedByPolicy]: '#0DCCF2',
  [FieldNames.blockedBySpam]: '#29A37A',
  [FieldNames.blockedForVirus]: '#A816AB',
  [FieldNames.blockedByATP]: '#C2AFF8',
  [FieldNames.blockedOther]: '#E65D19'
}

const tooltipLabels1: FieldNames[] = [
  FieldNames.totalDeferred,
  FieldNames.totalBlocked,
  FieldNames.totalQuarantined,
  FieldNames.totalAllowed
]
const tooltipLabels2: FieldNames[] = [
  FieldNames.blockedByRateControl,
  FieldNames.blockedByPolicy,
  FieldNames.blockedBySpam,
  FieldNames.blockedForVirus,
  FieldNames.blockedByATP,
  FieldNames.blockedOther
]
const tooltipLabels3: FieldNames[] = [
  FieldNames.totalDeferred,
  FieldNames.totalBlocked,
  FieldNames.totalQuarantined,
  FieldNames.totalAllowed,
  FieldNames.totalEncrypted
]

const REPORT_CHART_CONFIG: ChartsConfig = {
  [EmailFlows.inbound]: {
    [BarracudaReportTypes.inboundTraffic]: [
      [ChartTypes.linear, tooltipLabels1, false],
      [ChartTypes.verticalBarStacked, tooltipLabels1, false]
    ],
    [BarracudaReportTypes.outboundTraffic]: [
      [ChartTypes.linear, tooltipLabels1, false],
      [ChartTypes.verticalBarStacked, tooltipLabels1, false]
    ],
    [BarracudaReportTypes.inboundBlockedEmails]: [
      [ChartTypes.linear, tooltipLabels2, false],
      [ChartTypes.verticalBarStacked, tooltipLabels2, false]
    ],
    [BarracudaReportTypes.topInboundEmailSenders]: [[ChartTypes.barStacked, tooltipLabels1, false]],
    [BarracudaReportTypes.topInboundEmailRecipients]: [[ChartTypes.barStacked, tooltipLabels1, false]],
    [BarracudaReportTypes.topInboundBlockedSenders]: [[ChartTypes.barStacked, tooltipLabels2, false]],
    [BarracudaReportTypes.topInboundBlockedRecipients]: [[ChartTypes.barStacked, tooltipLabels2, false]],
    [BarracudaReportTypes.topOutboundEmailSenders]: [[ChartTypes.barStacked, tooltipLabels1, false]],
    [BarracudaReportTypes.topOutboundBlockedSenders]: [[ChartTypes.barStacked, tooltipLabels2, false]]
  },
  [EmailFlows.outbound]: {
    [BarracudaReportTypes.inboundTraffic]: [
      [ChartTypes.linear, tooltipLabels3, false],
      [ChartTypes.verticalBarStacked, tooltipLabels3, false]
    ],
    [BarracudaReportTypes.outboundTraffic]: [
      [ChartTypes.linear, tooltipLabels3, false],
      [ChartTypes.verticalBarStacked, tooltipLabels3, false]
    ],
    [BarracudaReportTypes.inboundBlockedEmails]: [
      [ChartTypes.linear, tooltipLabels2, false],
      [ChartTypes.verticalBarStacked, tooltipLabels2, false]
    ],
    [BarracudaReportTypes.topInboundEmailSenders]: [[ChartTypes.barStacked, tooltipLabels3, false]],
    [BarracudaReportTypes.topInboundEmailRecipients]: [[ChartTypes.barStacked, tooltipLabels3, false]],
    [BarracudaReportTypes.topInboundBlockedSenders]: [[ChartTypes.barStacked, tooltipLabels2, false]],
    [BarracudaReportTypes.topInboundBlockedRecipients]: [[ChartTypes.barStacked, tooltipLabels2, false]],
    [BarracudaReportTypes.topOutboundEmailSenders]: [[ChartTypes.barStacked, tooltipLabels3, false]],
    [BarracudaReportTypes.topOutboundBlockedSenders]: [[ChartTypes.barStacked, tooltipLabels2, false]]
  }
}
const TOP_TYPES_CHARTS: BarracudaReportTypes[] = [
  BarracudaReportTypes.topInboundEmailSenders,
  BarracudaReportTypes.topInboundEmailRecipients,
  BarracudaReportTypes.topOutboundBlockedSenders,
  BarracudaReportTypes.topInboundBlockedRecipients,
  BarracudaReportTypes.topOutboundEmailSenders,
  BarracudaReportTypes.topOutboundBlockedSenders
]
const MAX_TOP_CHARTS_ITEMS = 5

export interface ChartData {
  type: ChartTypes
  data: LineChartData | BarChartData
  icons: ChartTypes[]
  totals: { [key: string]: number }
}

const BASE_I18N_KEY = 'ess.reports'

export const useChartsLogic = (
  selectedListItem: ReportsListItem | undefined,
  selectedChartIndex: 0 | 1
): [ChartData] => {
  const { report, isReportLoading, filters } = useAppSelector(_store => ({
    report: _store.reports.report,
    isReportLoading: isPending(_store.reports.api.getReportApiStatus),
    filters: _store.reports.filters
  }))
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  // calculate the date axis label based on the sleected interval
  const getDateLabel = useCallback(
    (date: string) => {
      switch (filters.interval) {
        case ReportIntervals.hourly:
          return luxonDate(date).toFormat('MM/dd HH')
        default:
          return luxonDate(date).toFormat('MM/dd')
      }
    },
    [filters]
  )

  const chartConfig: ChartConfig | undefined = useMemo(() => {
    if (!selectedListItem || !report) {
      return undefined
    }
    return REPORT_CHART_CONFIG[filters.direction][selectedListItem.type]?.[selectedChartIndex]
  }, [selectedListItem, filters.direction, report, selectedChartIndex])

  const chartData = useMemo(() => {
    const emptyChartConfig = {
      type: ChartTypes.linear,
      data: { datasets: [], labels: [] },
      icons: []
    }

    if (!report || !selectedListItem || !chartConfig || isReportLoading) {
      return emptyChartConfig
    }
    let updatedResult: ReportsResponse['results'] = [...report.results] as ReportsResponse['results']
    if (TOP_TYPES_CHARTS.includes(selectedListItem.type)) {
      updatedResult = updatedResult.sort((a, b) => (a.total > b.total ? -1 : 1)).slice(0, MAX_TOP_CHARTS_ITEMS)
    }
    if (
      [
        BarracudaReportTypes.inboundTraffic,
        BarracudaReportTypes.outboundTraffic,
        BarracudaReportTypes.inboundBlockedEmails
      ].includes(selectedListItem.type)
    ) {
      updatedResult.reverse()
    }

    const [chartType, labels, isFilled] = chartConfig
    const chartLabels: string[] =
      labels ||
      (updatedResult as TopRecipientsReport[]).map(reportData => reportData.senderEmail || reportData.recipientEmail)
    const dataSet = chartLabels.map(tooltipLabel => ({
      id: camelCase(tooltipLabel),
      label: formatMessage(`labels.${tooltipLabel.toLowerCase()}`),
      fill: isFilled,
      data: updatedResult.map((item: any) => item[camelCase(tooltipLabel)]),
      ...(chartType === ChartTypes.linear && {
        borderColor: CHART_COLORS[tooltipLabel as FieldNames],
        borderWidth: 2
      }),
      ...(chartType !== ChartTypes.linear && {
        backgroundColor: CHART_COLORS[tooltipLabel as FieldNames],
        borderWidth: 1
      })
    }))
    return {
      type: chartType,
      data: {
        labels: updatedResult.map(reportData => {
          switch (selectedListItem.type) {
            case BarracudaReportTypes.topInboundEmailSenders:
            case BarracudaReportTypes.topInboundBlockedSenders:
            case BarracudaReportTypes.topOutboundEmailSenders:
              return (reportData as TopRecipientsReport).senderEmail
            case BarracudaReportTypes.topInboundEmailRecipients:
            case BarracudaReportTypes.topInboundBlockedRecipients:
            case BarracudaReportTypes.topOutboundBlockedSenders:
              return (reportData as TopRecipientsReport).recipientEmail
            default:
              return getDateLabel((reportData as TrafficSummaryReport).reportDate)
          }
        }),
        datasets: dataSet
      },
      icons: REPORT_CHART_CONFIG[filters.direction][selectedListItem.type]?.map(
        (charConfigObject: ChartConfig) => charConfigObject[0]
      )
    }
  }, [selectedListItem, filters.direction, report, formatMessage, isReportLoading, chartConfig, getDateLabel])

  const totals: ChartData['totals'] = useMemo(() => {
    if (!chartConfig || !report) {
      return {}
    }
    return report.extra.totals
  }, [report, chartConfig])

  return useMemo(() => [{ ...chartData, totals }], [chartData, totals])
}
