import { useCallback, useMemo } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending, isSuccess } from 'redux/toolkit/api'
import { LdapConnectionTestResult, LdapUserEntry, LdapUserLookupTestResult } from 'types/ldap'

export interface LdapTestDialogLogicConfig {
  onClose: () => void
}

export interface TestSummary {
  success: boolean | undefined
  text: string
  attributes: Array<{ attribute: string; value: string }> | undefined
  testedEmail: string | null
}

export interface State {
  isLdapTestPending: boolean
  testSummary: TestSummary
}

export interface EventHandlers {
  onClose: () => void
}

export type LdapTestDialogLogic = [State, EventHandlers]

export const useLdapTestDialogLogic = ({ onClose }: LdapTestDialogLogicConfig): LdapTestDialogLogic => {
  const { isLdapTestPending, isLdapTestSuccess, isLdapTestFailed, ldapTest } = useAppSelector(_store => ({
    isLdapTestPending: isPending(_store.ldap.api.postLdapTest),
    isLdapTestSuccess: isSuccess(_store.ldap.api.postLdapTest),
    isLdapTestFailed: isFailed(_store.ldap.api.postLdapTest),
    ldapTest: _store.ldap.ldapTest
  }))

  const ldapUserToTableData = useCallback((ldapUser: LdapUserEntry | null) => {
    if (!ldapUser) {
      return []
    }

    const attributes = [
      { attribute: 'Bind DN', value: ldapUser.bindDn },
      { attribute: 'Mail attribute value', value: ldapUser.mailAttributeValue || '' }
    ]
    return [
      ...attributes,
      ...Object.entries(ldapUser?.attrs || {}).map(([key, value]) => ({
        attribute: key,
        value: (value || []).join(', ').trim()
      }))
    ]
  }, [])

  const parsedTestResult: TestSummary = useMemo(() => {
    const DEFAULT_RES = { success: false, text: 'unknown_error', attributes: undefined, testedEmail: null }
    if (!ldapTest) {
      return DEFAULT_RES
    }

    switch (true) {
      // Test successfully queried the test email address
      case ldapTest.userLookupResult === LdapUserLookupTestResult.USER_LOOKUP_SUCCESSFUL &&
        !!ldapTest.testedEmailAddress:
        return {
          success: true,
          text: LdapUserLookupTestResult.USER_LOOKUP_SUCCESSFUL,
          attributes: ldapUserToTableData(ldapTest.firstUser),
          testedEmail: ldapTest.testedEmailAddress
        }

      // No email address was provided for test, but user filter returned results (showing first result only)
      case ldapTest.userLookupResult === LdapUserLookupTestResult.USER_LOOKUP_SUCCESSFUL &&
        !ldapTest.testedEmailAddress:
        return {
          success: true,
          text: LdapConnectionTestResult.CONNECTION_SUCCESSFUL,
          attributes: ldapUserToTableData(ldapTest.firstUser),
          testedEmail: null
        }

      // The provided email address could not be found in the LDAP dictionary (or the user filter returns empty set)
      case ldapTest.userLookupResult === LdapUserLookupTestResult.USER_LOOKUP_EMPTY:
        return {
          success: false,
          text: ldapTest.testedEmailAddress ? 'user_email_lookup_empty' : 'user_filter_lookup_empty',
          attributes: undefined,
          testedEmail: ldapTest.testedEmailAddress
        }

      // No test email address was provided and there is no user filter either (connection test only)
      case ldapTest.connectionResult === LdapConnectionTestResult.CONNECTION_SUCCESSFUL:
        return {
          success: true,
          text: LdapConnectionTestResult.CONNECTION_SUCCESSFUL,
          attributes: undefined,
          testedEmail: null
        }

      // Failed to successfully connect to the LDAP server with the reason given
      case ldapTest.connectionResult !== LdapConnectionTestResult.CONNECTION_SUCCESSFUL:
        return {
          success: false,
          text: ldapTest.connectionResult,
          attributes: undefined,
          testedEmail: null
        }
      default:
        return DEFAULT_RES
    }
  }, [ldapTest, ldapUserToTableData])

  const testSummary: TestSummary = useMemo(() => {
    switch (true) {
      case isLdapTestFailed:
        return { success: false, text: 'unknown_error', attributes: undefined, testedEmail: null }
      case isLdapTestSuccess:
        return parsedTestResult
      case isLdapTestPending:
      default:
        return { success: undefined, text: 'in_progress', attributes: undefined, testedEmail: null }
    }
  }, [isLdapTestFailed, isLdapTestPending, isLdapTestSuccess, parsedTestResult])

  return useMemo(
    () => [
      {
        isLdapTestPending,
        testSummary
      },
      {
        onClose
      }
    ],
    [isLdapTestPending, testSummary, onClose]
  )
}
