import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) =>
  createStyles({
    filterTitleContainer: {
      display: 'flex'
    },
    filterTitleIcon: {
      alignSelf: 'center',
      color: theme.palette.text.secondary
    },
    filterTitleText: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(0.5)
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(3)
    },
    formControl: { width: 300 },
    labelContainer: {
      display: 'flex',
      whiteSpace: 'pre-wrap',
      alignItems: 'center',
      marginBottom: theme.spacing(0.25),
      paddingRight: theme.spacing(1),
      justifyContent: 'flex-end'
    },
    label: {
      color: theme.palette.text.secondary
    },
    input: {
      '& [type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button': { opacity: 0 }
    },
    iconAdornment: {
      flexDirection: 'column',
      justifyContent: 'center'
    },
    iconButton: {
      padding: `${0}!important`,
      '&:first-child': {
        marginBottom: `-${theme.spacing(0.5)}px !important`
      },
      '&:last-child': {
        marginTop: `-${theme.spacing(0.5)}px !important`
      }
    }
  })
)
