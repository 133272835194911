import { useCallback, useMemo } from 'react'
import { IpAddressEntry } from 'types/Settings'
import { useRefField } from 'lib/settingsForm/useRefField'
import { SettingsForm, useSettingsForm } from 'lib/settingsForm/useSettingsForm'
import { FormErrors, useIpAddressTableValidation } from 'components/libs/ipAddressTable/useIpAddressTableValidation'

export interface IpAddressTableFormState {
  address: string
  netmask: string
  comment: string
  policy: string
}

export interface ValidatorDependencies {
  data: IpAddressEntry[]
  shouldDisplayPolicy: boolean
}

export interface IpAddressTableFormConfig {
  initialState: IpAddressTableFormState
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export interface IpAddressTableForm extends SettingsForm<IpAddressTableFormState> {
  errors: FormErrors
}

export const useIpAddressTableForm = ({
  initialState,
  delegateIsDirtyForm
}: IpAddressTableFormConfig): IpAddressTableForm => {
  const addressField = useRefField(initialState.address)
  const netmaskField = useRefField(initialState.netmask)
  const commentField = useRefField(initialState.comment)
  const policyField = useRefField(initialState.policy)
  const [errors, { validateNewIp, resetErrors }] = useIpAddressTableValidation()

  const formConfig = useMemo(
    () => ({
      fields: {
        address: addressField,
        netmask: netmaskField,
        comment: commentField,
        policy: policyField
      },
      delegateIsDirtyForm
    }),
    [addressField, commentField, delegateIsDirtyForm, netmaskField, policyField]
  )
  const form = useSettingsForm<IpAddressTableFormState>(formConfig)

  const validate = useCallback(
    (newEntry: IpAddressEntry, dependencies: ValidatorDependencies) => {
      resetErrors()
      form.setError('')
      const hasError = validateNewIp(newEntry, dependencies.data)
      form.setError(hasError ? 'has_error' : '')
      return hasError
    },
    [form, resetErrors, validateNewIp]
  )

  return useMemo(
    () => ({
      ...form,
      validate,
      errors,
      resetErrors
    }),
    [errors, form, resetErrors, validate]
  )
}
