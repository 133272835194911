import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  growFlex: {
    flex: '1 0 auto'
  },
  parent: {
    minWidth: 0,
    display: 'flex',
    flex: '1 1 auto'
  },
  childrenWrapper: {
    position: 'absolute',
    display: 'flex',
    visibility: 'hidden',
    overflow: 'hidden',
    height: 0,
    '& > *': {
      margin: theme.spacing(0, 1.5, 0, 0)
    }
  },
  wrapper: {
    display: 'flex',
    flex: '0 1 auto',
    minWidth: 0,
    '& > *:not(:last-child)': {
      margin: theme.spacing(0, 1.5, 0, 0),
      minWidth: 'unset'
    }
  },
  moreFiltersWrapper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      margin: theme.spacing(0, 0, 1.5, 0)
    }
  },
  moreButton: {
    minWidth: 'max-content'
  },
  indicator: {
    fontSize: '8px',
    position: 'relative',
    left: -theme.spacing(0.8),
    bottom: -theme.spacing(0.8)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  }
}))
