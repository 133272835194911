import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { isSettingValue, SettingValue } from 'types/Settings'
import { isSuccess, isPending } from 'redux/toolkit/api'
import { postLdapSerialReset } from 'redux/features/ldap/ldapSlice'
import { LDAP_DISABLE_RESET_HIGHEST_SERIAL } from 'types/ldap'

export interface State {
  isVisible: boolean
  inProgress: boolean
  srs: string
  ldapHighestSerial: string
  serialResetInProgress: boolean
}

export interface EventHandlers {
  onChangeSrs: (value: string) => void
  onResetLdapHighestSerial: () => void
  onToggleVisible: () => void
}

export type EditDomainAdvancedLogic = [State, EventHandlers]

export const useEditDomainAdvancedLogic = (form: EditDomainForm): EditDomainAdvancedLogic => {
  const dispatch = useAppDispatch()
  const { domain, isGetDomainSuccess, isResetSerialPending, isResetSerialSuccess } = useAppSelector(_store => ({
    domain: _store.domains.domain,
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
    isResetSerialPending: isPending(_store.ldap.api.postLdapSerialReset),
    isResetSerialSuccess: isSuccess(_store.ldap.api.postLdapSerialReset)
  }))

  const [{ domainId, srs, ldapHighestSerial, inProgress }] = form
  const [isVisible, setIsVisible] = useState(false)

  const onChangeSrs = useCallback(
    (value: string) => {
      if (isSettingValue(value)) {
        srs.setValue(value)
      }
    },
    [srs]
  )

  const onResetLdapHighestSerial = useCallback(() => {
    dispatch(postLdapSerialReset({ domainId }))
  }, [dispatch, domainId])

  const onToggleVisible = useCallback(() => {
    setIsVisible(!isVisible)
  }, [isVisible])

  const srsValue = useMemo(() => {
    if (typeof srs.value === 'undefined') {
      return ''
    }
    return srs.value ? `${srs.value}` : SettingValue.DISABLED
  }, [srs.value])

  const ldapHighestSerialValue = useMemo(() => {
    if (!ldapHighestSerial.value || ldapHighestSerial.value === LDAP_DISABLE_RESET_HIGHEST_SERIAL) {
      return ''
    }
    return ldapHighestSerial.value
  }, [ldapHighestSerial.value])

  useEffect(() => {
    if (isGetDomainSuccess) {
      srs.reset(domain?.srs ? domain.srs : SettingValue.DISABLED)
      ldapHighestSerial.reset(domain?.ldapHighestSerial)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  useEffect(() => {
    if (isResetSerialSuccess) {
      ldapHighestSerial.reset(LDAP_DISABLE_RESET_HIGHEST_SERIAL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetSerialSuccess])

  return useMemo(
    () => [
      {
        isVisible,
        inProgress: inProgress || isResetSerialPending,
        srs: srsValue,
        ldapHighestSerial: ldapHighestSerialValue,
        serialResetInProgress: isResetSerialPending
      },
      {
        onChangeSrs,
        onResetLdapHighestSerial,
        onToggleVisible
      }
    ],
    [
      isVisible,
      inProgress,
      isResetSerialPending,
      srsValue,
      ldapHighestSerialValue,
      onChangeSrs,
      onResetLdapHighestSerial,
      onToggleVisible
    ]
  )
}
