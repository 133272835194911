import { useMemo, useCallback, Dispatch, SetStateAction, useState, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, DomainEntry, MachineLearningExemptEntry } from 'types/Settings'
import routesConfig from 'lib/routesConfig'
import {
  getAccountSettings,
  getDomainSettings,
  resetAccountAndDomainSettings,
  setHasPageChanges,
  updateAccountSettings,
  updateDomainSettings
} from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'

export interface State {
  tableData: any[]
  hasPageChanges: boolean
}

export interface EventHandlers {
  helpConfig: {
    isOpen: boolean
    onHelpClick: () => void
    onCloseHelp: () => void
  }
  onAddItem: (item: DomainEntry) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
  setIsMachineLearningTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseMachineLearningLogic = [State, EventHandlers]

export const useMachineLearningLogic = (): UseMachineLearningLogic => {
  const dispatch = useAppDispatch()
  const [isHelpDialogOpened, setIsHelpDialogOpened] = useState<boolean>(false)
  const {
    accessTokenObject,
    accountSettings,
    domainSettings,
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess
  } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings,
    isUpdateAccountSettingsSuccess: isSuccess(_stores.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_stores.settings.updateDomainSettingsApiStatus)
  }))

  const [isMachineLearningTableDirty, setIsMachineLearningTableDirty] = useState<boolean>(false)

  // init
  useEffect(() => {
    if (accessTokenObject?.pdDomainId) {
      dispatch(
        getDomainSettings({
          domainId: accessTokenObject?.pdDomainId,
          settings: [AvailableSettings.MACHINE_LEARNING_EXEMPT]
        })
      )
    } else {
      dispatch(getAccountSettings([AvailableSettings.MACHINE_LEARNING_EXEMPT]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update state on add/remove
  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          getDomainSettings({
            domainId: accessTokenObject?.pdDomainId,
            settings: [AvailableSettings.MACHINE_LEARNING_EXEMPT]
          })
        )
      } else {
        dispatch(getAccountSettings([AvailableSettings.MACHINE_LEARNING_EXEMPT]))
      }
      dispatch(setHasPageChanges(false))
    }
  }, [dispatch, accessTokenObject, isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  // unmount
  useEffect(
    () => () => {
      dispatch(resetAccountAndDomainSettings())
    },
    [dispatch]
  )

  const transformToDomainEntries = (entries: MachineLearningExemptEntry[]): DomainEntry[] =>
    entries.map(entry => ({
      domain: entry.name,
      comment: entry.comment,
      id: entry.id
    }))

  const transformToExemptEntries = (entries: DomainEntry[]): MachineLearningExemptEntry[] =>
    entries.map(entry => ({
      name: entry.domain,
      comment: entry.comment,
      id: entry.id
    }))

  const tableData = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    const parsedExemptions = settings.inference_exempt
      ? (JSON.parse(settings.inference_exempt as string) as MachineLearningExemptEntry[])
      : []
    return transformToDomainEntries(parsedExemptions)
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateSettings = useCallback(
    (entries: MachineLearningExemptEntry[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.MACHINE_LEARNING_EXEMPT]: entries }
          })
        )
      } else {
        dispatch(updateAccountSettings({ settings: { [AvailableSettings.MACHINE_LEARNING_EXEMPT]: entries } }))
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAddItem = useCallback(
    (item: DomainEntry) => {
      const entries = [...tableData, item]
      const mappedExemptEntries = transformToExemptEntries(entries)
      updateSettings(mappedExemptEntries)
    },
    [tableData, updateSettings]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const exemptions = tableData.filter(entry => entry.id !== id)
      const mappedExemptEntries = transformToExemptEntries(exemptions)
      updateSettings(mappedExemptEntries)
    },
    [tableData, updateSettings]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING_EXEMPT_BULK_EDIT.goto()
  }, [])

  const onHelpClick = useCallback(() => {
    setIsHelpDialogOpened(true)
  }, [])

  const onCloseHelp = useCallback(() => {
    setIsHelpDialogOpened(false)
  }, [])

  return useMemo(
    () => [
      {
        tableData,
        hasPageChanges: isMachineLearningTableDirty
      },
      {
        helpConfig: {
          isOpen: isHelpDialogOpened,
          onHelpClick,
          onCloseHelp
        },
        onAddItem,
        onRemoveItem,
        onBulkEdit,
        setIsMachineLearningTableDirty
      }
    ],
    [
      tableData,
      isMachineLearningTableDirty,
      isHelpDialogOpened,
      onHelpClick,
      onCloseHelp,
      onAddItem,
      onRemoveItem,
      onBulkEdit,
      setIsMachineLearningTableDirty
    ]
  )
}
