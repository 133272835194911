import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import { isFailed, isPending } from 'redux/toolkit/api'

export const useAzureAdAuthorizationLogic = () => {
  const [isAuthorizePending, setIsAuthorizePending] = useState(false)

  const { isGetAzureAdAdminConsentPending, isGetAzureAdAdminConsentFailed } = useAppSelector(_store => ({
    isGetAzureAdAdminConsentPending: isPending(_store.azureAd.api.getAzureAdAdminConsent),
    isGetAzureAdAdminConsentFailed: isFailed(_store.azureAd.api.getAzureAdAdminConsent)
  }))

  useEffect(() => {
    // The authorize flow redirects the browser after the admin consent signed URL is retrieved
    // To keep the warning dialog pending indicator on, the success flag is copied to state
    if (isGetAzureAdAdminConsentPending) {
      setIsAuthorizePending(true)
    }
  }, [isGetAzureAdAdminConsentPending])

  useEffect(() => {
    // The authorize flow redirects the browser after the admin consent signed URL is retrieved
    // Turning off the pending flag in state on error
    if (isGetAzureAdAdminConsentFailed) {
      setIsAuthorizePending(false)
    }
  }, [isGetAzureAdAdminConsentFailed])

  return useMemo(() => ({ isAuthorizePending }), [isAuthorizePending])
}
