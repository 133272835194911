import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  container: {
    border: 'none',
    boxShadow: 'none',
    marginLeft: '1px',
    padding: theme.spacing(1)
  },
  dialogActions: {
    paddingRight: '24px'
  },
  headerButton: {
    margin: theme.spacing(1)
  },
  saveReportButton: {
    borderRadius: '5px'
  },
  saveReportDialogTitle: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0.5px'
  },
  saveReportDialogSubtitle: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '16px',
    marginTop: '12px',
    marginBottom: theme.spacing(2)
  },
  saveReportDialogTitleContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`
  },
  titleLabel: {
    fontSize: 23,
    fontWeight: 400
  },
  titleLabelTooltip: {
    fontSize: 14,
    fontWeight: 500
  }
}))
