import { useCallback, useEffect, useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import appConfig from 'config/appConfig'
import {
  allRoutesConfig,
  appAuth0LogoutCallbackPath,
  gotoAdminEntryPathFromEnduser,
  gotoBccLogout,
  gotoLegacyLogin
} from 'lib/routesConfig'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { logout as appLogout, resetLogout } from 'redux/features/auth/authSlice'
import { isFailed, isSuccess } from 'redux/toolkit/api'
import { removeSessionCookie } from 'lib/cookies'
import reduxStore from 'lib/reduxStore'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import useHandleQNDigestLinks from 'lib/useHandleQNDigestLinks'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'
import { UserRole } from 'config/userRole'
import { RootState } from 'redux/toolkit/store'

export type Logout = (useTDFTransition: boolean) => void
export type LogoutImpersonatedEnduser = () => void
// use this global variable to store the logout params because the some components are unmounted before the
//   useEffect part is able to catch the end of the app logout process.
export type IsTDFTransition = boolean
export type IsAzureUser = boolean
export type IsRolledOutOktaUser = boolean
export type ReturnToUrl = string | undefined
export type IsAccountAdmin = boolean
export type IsEnhancedAuth = boolean | undefined

export enum LogoutTypes {
  'logout',
  'impersonatedEnduserLogout'
}

export type LogoutParameters =
  | [LogoutTypes, IsTDFTransition, IsAzureUser, IsRolledOutOktaUser, ReturnToUrl, IsAccountAdmin, IsEnhancedAuth]
  | undefined
let logoutParameters: LogoutParameters

export function forceLogout(
  logoutParams: LogoutParameters = [LogoutTypes.logout, true, true, true, undefined, false, undefined]
) {
  const rootState = reduxStore.getState() as RootState
  const roleType = rootState.auth.accessTokenObject?.roleType
  if (typeof logoutParams[6] === 'undefined') {
    // eslint-disable-next-line no-param-reassign
    logoutParams[6] = !!rootState.app.publicAppSettings?.isEnhancedAuthenticationEnabled
  }
  // If there is no role type we have already logged out the user and cleared the redux
  if (!roleType) {
    return
  }
  if (roleType === UserRole.ACCOUNT_USER) {
    // eslint-disable-next-line no-param-reassign
    logoutParams[5] = true
  }
  logoutParameters = logoutParams
  reduxStore.dispatch(appLogout())
}

const useLogout = (): [Logout, LogoutImpersonatedEnduser] => {
  const dispatch = useAppDispatch()
  const { logout: authLogout } = useAuth0()
  const [, , generateQNDigestReturnUrlParams] = useHandleQNDigestLinks()
  const { appLogoutIsFinished, isAzureAdAccountStored, isPdDomainIdIsSet, roleType, isEnhancedAuthenticationEnabled } =
    useAppSelector(_store => ({
      appLogoutIsFinished: isSuccess(_store.auth.logoutApiStatus) || isFailed(_store.auth.logoutApiStatus),
      isAzureAdAccountStored: !!_store.auth.accessTokenObject?.isAzureAdAccount,
      isPdDomainIdIsSet: !!_store.auth.accessTokenObject?.pdDomainId,
      roleType: _store.auth.accessTokenObject?.roleType,
      isEnhancedAuthenticationEnabled: !!_store.app.publicAppSettings?.isEnhancedAuthenticationEnabled
    }))
  const { appTypeLoginEntryPath } = useAppTypeEntryPaths()

  // app is logged out - session is invalidated on api-gateway
  useEffect(() => {
    if (logoutParameters && appLogoutIsFinished) {
      // collect and reset the logout params
      const [
        logoutType,
        isTDFTransition,
        isAzureUser,
        isRolledOutOktaUser,
        returnToUrl,
        isAccountAdmin,
        isEnhancedAuth
      ] = logoutParameters
      logoutParameters = undefined

      // eslint-disable-next-line default-case
      switch (logoutType) {
        case LogoutTypes.logout:
          // remove values from localstorage and reset the logout redux states
          dispatch(resetLogout())
          removeSessionCookie()

          if (isAccountAdmin) {
            gotoBccLogout()
            return
          }

          if (isEnhancedAuth) {
            appTypeLoginEntryPath.goto()
            return
          }

          // invalidate the session in Okta when Azure logout and Auth0 is enabled
          if (appConfig.AUTH0.isEnabled && isAzureUser) {
            // keep the rolled out Okta users on react side
            if (isRolledOutOktaUser) {
              authLogout({
                logoutParams: { returnTo: `${window.location.origin}${appAuth0LogoutCallbackPath?.path}` }
              })
              // otherwise move the Azure user to TDF
            } else {
              gotoLegacyLogin()
            }
          } else if (isTDFTransition) {
            gotoLegacyLogin()
          } else {
            appTypeLoginEntryPath.goto(undefined, false, returnToUrl)
          }
          break
        case LogoutTypes.impersonatedEnduserLogout:
          gotoAdminEntryPathFromEnduser(allRoutesConfig.USERS_LIST.uri())
      }
    }
  }, [
    appLogoutIsFinished,
    authLogout,
    dispatch,
    generateQNDigestReturnUrlParams,
    appTypeLoginEntryPath,
    isPdDomainIdIsSet
  ])

  const logout = useCallback(
    (useTDFTransition: boolean) => {
      logoutParameters = [
        LogoutTypes.logout,
        useTDFTransition,
        isAzureAdAccountStored,
        isMyFeatureOn(FEATURES.EGD_React_Okta_Rollout),
        generateQNDigestReturnUrlParams(),
        !!(roleType && roleType === UserRole.ACCOUNT_USER),
        isEnhancedAuthenticationEnabled
      ]
      dispatch(appLogout())
    },
    [dispatch, isAzureAdAccountStored, generateQNDigestReturnUrlParams, roleType, isEnhancedAuthenticationEnabled]
  )

  const logoutImpersonatedEnduser = useCallback(() => {
    logoutParameters = [LogoutTypes.impersonatedEnduserLogout, false, false, false, undefined, false, undefined]
    dispatch(appLogout())
  }, [dispatch])

  return useMemo(() => [logout, logoutImpersonatedEnduser], [logout, logoutImpersonatedEnduser])
}

export default useLogout
