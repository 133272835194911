import React, { useMemo } from 'react'

import { Button, Grid, TextField } from '@barracuda-internal/bds-core'

import { GROUP_TITLE, TOPICS } from 'components/pages/support/config'
import styles from 'components/pages/accountManagement/accountManagementStyles'
import SyslogIntegration from 'components/pages/support/help/syslogIntegration/SyslogIntegration'
import { useAccountManagementLogic } from 'components/pages/accountManagement/useAccountManagementLogic'
import { FormSpacer, PageContainer, PageTitle, PageSubtitle } from 'components/libs/layout/pages/pageLayout'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.account_management'

const AccountManagement: React.FC = () => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useAccountManagementLogic()

  return useMemo(
    () => (
      <PageContainer
        isLoading={state.inProgress}
        isFailedToLoad={state.isFailedToLoad}
        dirtyCheck
        isDirtyForm={state.isDirtyForm}
      >
        <PageTitle
          title={formatMessage('title')}
          onOpenSupport={eventHandlers.helpConfig.onHelpClick}
          help={{
            content: SyslogIntegration,
            onClose: eventHandlers.helpConfig.onCloseHelp,
            title: TOPICS.syslogIntegration,
            group: GROUP_TITLE.inboundSettings,
            isHelpOpen: eventHandlers.helpConfig.isOpen
          }}
        />
        <FormSpacer />
        <PageSubtitle>{formatMessage('header')}</PageSubtitle>
        <FormSpacer />
        {!state.isFailedToLoad && (
          <Grid container>
            <Grid item xs={12} className={classes.container}>
              <Grid className={classes.cardsRow}>
                <Grid item xs={4}>
                  <TextField
                    className={classes.hostInput}
                    fullWidth
                    name="hostname"
                    error={!!state.hostError}
                    helperText={state.hostError && formatMessage(state.hostError)}
                    value={state.hostName}
                    onChange={eventHandlers.onInputChange}
                    data-testid="hostname-input"
                    disabled={state.isDisabled || state.inProgress}
                    label={formatMessage('hostname')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    className={classes.portInput}
                    fullWidth
                    name="port"
                    error={!!state.portError}
                    helperText={state.portError && formatMessage(state.portError)}
                    value={state.port}
                    onChange={eventHandlers.onInputChange}
                    data-testid="port-input"
                    disabled={state.isDisabled || state.inProgress}
                    label={formatMessage('port')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.helper}>{formatMessage('helper_text')}</p>
              </Grid>
              <Grid item xs={12}>
                {!state.isDisabled && (
                  <Button
                    color="primary"
                    onClick={eventHandlers.onAdd}
                    variant="contained"
                    data-testid="add-button"
                    disabled={state.inProgress}
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {state.isDisabled && (
                  <>
                    <Button
                      className={classes.button}
                      color="primary"
                      onClick={eventHandlers.onTest}
                      variant="contained"
                      data-testid="test-button"
                      disabled={state.inProgress}
                    >
                      {formatMessage('test')}
                    </Button>
                    <Button
                      onClick={eventHandlers.onRemove}
                      variant="outlined"
                      color="primary"
                      data-testid="delete-button"
                      disabled={state.inProgress}
                    >
                      {formatMessage('delete')}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageContainer>
    ),
    [state, classes, eventHandlers, formatMessage]
  )
}

export default AccountManagement
