import React, { useMemo } from 'react'
import MessageLog from 'components/pages/messageLog/MessageLog'
import { MESSAGE_LOG_VIEW_CONFIG } from 'redux/features/mstore/mstoreSlice'
import { useViewConfig, UiState } from 'components/libs/messageLog/useViewConfig'

const MessageLogPage = () => {
  const { uiState } = useViewConfig(MESSAGE_LOG_VIEW_CONFIG)

  return useMemo(
    () => (
      <>
        {uiState === UiState.READY && <MessageLog />}
        {uiState === UiState.INITIALIZING && null}
      </>
    ),
    [uiState]
  )
}

export default MessageLogPage
