import React, { FC, useMemo } from 'react'
import { Button, DataTable, DataTableColumn, Grid, TextField, Typography } from '@barracuda-internal/bds-core'
import { SenderIpEntryExtended } from 'types/SenderIp'
import { Cell } from 'components/libs/grid/cell'
import { TableText } from 'components/libs/layout/pages/TableText'
import IpTextField from 'components/libs/IpTextField/IpTextField'
import { useFormatMessage } from 'lib/localization'
import outboundSettingsStyles from 'components/pages/outboundSettings/outboundSettingsStyles'
import {
  useSenderIpTableLogic,
  DEFAULT_NETMASK,
  INPUT_NAMES
} from 'components/pages/outboundSettings/senderIp/useSenderIpTableLogic'
import WarningDialog from 'components/libs/dialog/warningDialog/WarningDialog'

const BASE_I18N_KEY = 'ess.outbound_settings.sender_ip'

export interface SenderIpTableProps {
  delegateIsDirtyForm: (isDirty: boolean) => void
}

const SenderIpTable: FC<SenderIpTableProps> = ({ delegateIsDirtyForm }) => {
  const outboundClasses = outboundSettingsStyles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [state, eventHandlers] = useSenderIpTableLogic({ delegateIsDirtyForm })

  return useMemo(
    () => (
      <>
        <Grid container>
          <Typography className={outboundClasses.helper} variant="body2">
            {formatMessage('subtitle')}
          </Typography>
          <Button
            className={outboundClasses.bulkEdit}
            onClick={eventHandlers.onBulkEdit}
            variant="contained"
            size="small"
            data-testid="bulk-button"
          >
            {formatMessage('bulk_edit')}
          </Button>
        </Grid>
        <DataTable
          className={outboundClasses.topSpacing}
          data={state.tableData}
          sortable={{
            allowUnsort: false,
            mode: 'single'
          }}
          sort={state.sort}
          onSortChange={eventHandlers.handleSortChange}
        >
          <DataTableColumn
            sortable
            field={INPUT_NAMES.DOMAIN_NAME}
            title={formatMessage('domain_column')}
            cell={({ dataItem }: { dataItem: SenderIpEntryExtended }) => (
              <Cell>
                {!dataItem.id && (
                  <TextField
                    fullWidth
                    defaultValue={state.formState.domainName}
                    inputRef={state.form.fields.domainName.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    name={INPUT_NAMES.DOMAIN_NAME}
                    error={!!(state.errors.newDomainError || state.errors.newDomainError)}
                    helperText={state.errors.newDomainError || state.errors.newDomainError || ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-domain-field"
                  />
                )}
                {dataItem.id && <TableText dataText={dataItem.domainName} truncated dataTestId="domain-field" />}
              </Cell>
            )}
          />

          <DataTableColumn
            sortable
            field={INPUT_NAMES.IP}
            title={formatMessage('ip_column')}
            cell={({ dataItem }: { dataItem: SenderIpEntryExtended }) => (
              <Cell>
                {dataItem.id === '' && (
                  <IpTextField
                    defaultValue={state.formState.ip}
                    inputRef={state.form.fields.ip.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    size="small"
                    name={INPUT_NAMES.IP}
                    error={!!(state.errors.newIpError || state.errors.newIpError)}
                    helperText={state.errors.newIpError || state.errors.newIpError || ''}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-ip-field"
                  />
                )}
                {dataItem.id !== '' && <TableText dataText={dataItem.ip} truncated data-testid="ip-field" />}
              </Cell>
            )}
          />

          <DataTableColumn
            sortable
            field="netmask"
            title={formatMessage('netmask')}
            cell={({ dataItem }: { dataItem: SenderIpEntryExtended }) => (
              <Cell>
                {dataItem.id !== '' && dataItem.netmask !== DEFAULT_NETMASK && (
                  <TableText dataText={dataItem.netmask} truncated data-testid="netmask-field" />
                )}
              </Cell>
            )}
          />

          <DataTableColumn
            sortable
            field={INPUT_NAMES.COMMENT}
            title={formatMessage('comment')}
            cell={({ dataItem }: { dataItem: SenderIpEntryExtended }) => (
              <Cell>
                {dataItem.id === '' && (
                  <TextField
                    defaultValue={state.formState.comment}
                    inputRef={state.form.fields.comment.ref}
                    onChange={eventHandlers.handleOnInputChange}
                    size="small"
                    name={INPUT_NAMES.COMMENT}
                    inputProps={{ style: { padding: 8 } }}
                    data-testid="new-comment-field"
                  />
                )}
                {dataItem.id !== '' && <TableText dataText={dataItem.comment} truncated data-testid="comment-field" />}
              </Cell>
            )}
          />

          <DataTableColumn
            sortable={false}
            field="actions"
            title={formatMessage('actions')}
            cell={({ dataItem }: { dataItem: SenderIpEntryExtended }) => (
              <Cell>
                {dataItem.id === '' && (
                  <Button
                    onClick={eventHandlers.onAdd}
                    variant="text"
                    color="primary"
                    size="small"
                    data-testid="add-button"
                  >
                    {formatMessage('add')}
                  </Button>
                )}
                {dataItem.id !== '' && (
                  <Button
                    onClick={() => eventHandlers.onRemove(dataItem.id)}
                    variant="text"
                    color="primary"
                    size="small"
                    data-testid="remove-button"
                  >
                    {formatMessage('remove')}
                  </Button>
                )}
              </Cell>
            )}
          />
        </DataTable>
        {!!state.idToRemove && (
          <WarningDialog
            title={formatMessage('confirm_removal')}
            onConfirm={eventHandlers.onConfirmRemove}
            onCancel={eventHandlers.onConfirmCancel}
          />
        )}
      </>
    ),
    [eventHandlers, formatMessage, outboundClasses, state]
  )
}

export default SenderIpTable
