export const RETENTION_POLICY_DAY_RANGE = 30

export interface Report {
  reportId: BarracudaReportTypes
  accountId: string
  startDate: string | null
  endDate: string | null
  relativeDateRange: RelativeDateRanges | null
  domainIds: string[]
  direction: EmailFlows
  interval: ReportIntervals
  listTop: ListTop
  filters: ReportFilter[] | null
  page?: number
  pageSize?: number
}

export interface PageState {
  pageIndex: number
  pageSize: number
}

export enum ReportTypes {
  barracuda = 'barracuda',
  custom = 'custom'
}

export enum ChartTypes {
  linear,
  bar,
  barStacked,
  verticalBar,
  verticalBarStacked
}

export interface BarracudaReport {
  id: BarracudaReportTypes
  name: string
  description: string
}

export interface ReportsListItem {
  id: string
  type: BarracudaReportTypes
  label: string
}

export interface CustomReport extends ReportsListItem {
  filters: TableFilters
}

export interface ReportsList {
  barracudaReports: BarracudaReport[]
  customReports: CustomReport[]
  pinnedReportId?: string
}

export enum BarracudaReportTypes {
  inboundTraffic = 'inbound_traffic_summary',
  outboundTraffic = 'outbound_traffic_summary',
  inboundBlockedEmails = 'inbound_blocked_emails_breakdown',
  topInboundEmailSenders = 'top_inbound_email_senders',
  topInboundEmailRecipients = 'top_inbound_email_recipients',
  topInboundBlockedSenders = 'top_inbound_blocked_senders_breakdown',
  topInboundBlockedRecipients = 'top_inbound_blocked_recipients_breakdown',
  topOutboundEmailSenders = 'top_outbound_email_senders',
  topOutboundBlockedSenders = 'top_outbound_blocked_senders'
}

export const breakdownReports = [
  BarracudaReportTypes.inboundBlockedEmails,
  BarracudaReportTypes.topInboundBlockedSenders,
  BarracudaReportTypes.topInboundBlockedRecipients
]

export const paginatedReports = [
  BarracudaReportTypes.topInboundEmailSenders,
  BarracudaReportTypes.topInboundEmailRecipients,
  BarracudaReportTypes.topInboundBlockedSenders,
  BarracudaReportTypes.topInboundBlockedRecipients,
  BarracudaReportTypes.topOutboundEmailSenders,
  BarracudaReportTypes.topOutboundBlockedSenders
]

export interface TotalTypeReport {
  totalDeferred: number
  totalBlocked: number
  totalQuarantined: number
  totalAllowed: number
  total: number
}

export interface BlockedTypeReport {
  blockedByRateControl: number
  blockedByPolicy: number
  blockedBySpam: number
  blockedForVirus: number
  blockedByAtp: number
  blockedOther: number
  total: number
}

export enum ReportIntervals {
  hourly = 'HOURLY',
  daily = 'DAILY',
  weekly = 'WEEKLY'
}

export enum ScheduleReportFrequency {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly'
}

export enum ScheduleReportEveryOption {
  first = 'first',
  second = 'second',
  third = 'third',
  fourth = 'fourth',
  last = 'last'
}

export enum ScheduleReportFormat {
  csv = 'csv'
}

export enum EmailFlows {
  inbound = 'INBOUND',
  outbound = 'OUTBOUND'
}

export enum ListTop {
  all = 'all',
  top3 = 'TOP 3',
  top5 = 'TOP 5',
  top10 = 'TOP 10',
  top15 = 'TOP 15',
  top25 = 'TOP 25',
  top50 = 'TOP 50'
}

export enum TotalFieldNames {
  total = 'total',
  totalAllowed = 'total_allowed',
  totalBlocked = 'total_blocked',
  totalDeferred = 'total_deferred',
  totalQuarantined = 'total_quarantined',
  totalEncrypted = 'total_encrypted',
  blockedByRateControl = 'blocked_by_rate_control',
  blockedByPolicy = 'blocked_by_policy',
  blockedBySpam = 'blocked_by_spam',
  blockedForVirus = 'blocked_for_virus',
  blockedByATP = 'blocked_by_ATP',
  blockedOther = 'blocked_other'
}

export enum FieldNames {
  date = 'report_date',
  senderEmail = 'sender_email',
  recipientEmail = 'recipient_email',
  totalAllowed = 'total_allowed',
  totalBlocked = 'total_blocked',
  totalDeferred = 'total_deferred',
  totalQuarantined = 'total_quarantined',
  totalEncrypted = 'total_encrypted',
  blockedByRateControl = 'blocked_by_rate_control',
  blockedByPolicy = 'blocked_by_policy',
  blockedBySpam = 'blocked_by_spam',
  blockedForVirus = 'blocked_for_virus',
  blockedByATP = 'blocked_by_ATP',
  blockedOther = 'blocked_other'
}

export const ALL_DOMAINS = 'all_domains'

export type ColumnFilters = { [key in FieldNames]?: ReportFilter }

export interface TableFilters {
  search: string
  startDate: string | null
  endDate: string | null
  relativeDateRange: RelativeDateRanges | null
  domainIds: string[]
  interval: ReportIntervals
  direction: EmailFlows
  listTop: ListTop
  page: number
  pageSize: number
  columnFilters: ColumnFilters
  isDirty: boolean
  isValid: boolean
  errors: {
    dateRange?: string
  }
}

export interface PaginationState {
  page: number
  pageSize: number
  isDirty?: boolean
}

export enum FilterConditions {
  equals = 'EQUALS',
  notEqual = 'NOT EQUALS'
}

export enum RelativeDateRanges {
  lastDay = 'LAST DAY',
  last7Days = 'LAST WEEK',
  lastMonth = 'LAST MONTH'
}

export interface ReportFilter {
  fieldName: string
  min?: number
  max?: number
  condition?: FilterConditions
  values?: string[]
}

interface ReportPagination {
  totalRecords: number
  currentPage: number
  totalPages: number
  nextPage: number | null
  perviousPage: number | null
}

export interface ReportExtra {
  pagination: ReportPagination
  totals: {
    [key in TotalFieldNames]: number
  }
}

export interface BaseReport<T> {
  results: T[]
  extra: ReportExtra
}

export interface ReportTable<T> {
  data: T[]
}

export interface TrafficSummaryReport extends TotalTypeReport {
  reportDate: string
}

export interface BlockedEmailsReport extends BlockedTypeReport {
  reportDate: string
}

export interface TopRecipientsReport extends TotalTypeReport {
  senderEmail: string
  recipientEmail: string
}

export interface TopBlockedReport extends BlockedTypeReport {
  senderEmail: string
  recipientEmail: string
}

export type InboundTrafficReport = BaseReport<TrafficSummaryReport>
export type OutboundTrafficReport = BaseReport<TrafficSummaryReport>
export type InboundBlockedEmailsBreakdownsReport = BaseReport<BlockedEmailsReport>
export type TopInboundEmailSendersReport = BaseReport<TopRecipientsReport>
export type TopInboundEmailRecipientsReport = BaseReport<TopRecipientsReport>
export type TopInboundBlockedSendersBreakdownReport = BaseReport<TopBlockedReport>

export type ReportsResponse =
  | InboundTrafficReport
  | OutboundTrafficReport
  | InboundBlockedEmailsBreakdownsReport
  | TopInboundEmailSendersReport
  | TopInboundEmailRecipientsReport
  | TopInboundBlockedSendersBreakdownReport

export type ReportsResponseTable =
  | ReportTable<TrafficSummaryReport>
  | ReportTable<BlockedEmailsReport>
  | ReportTable<TopRecipientsReport>
  | ReportTable<TopBlockedReport>

export type DateTypeRecord = TrafficSummaryReport & BlockedEmailsReport

export function isDateTypeRecord(
  record: TrafficSummaryReport | BlockedEmailsReport | TopRecipientsReport | TopBlockedReport
): record is DateTypeRecord {
  return Object.prototype.hasOwnProperty.call(record, 'reportDate')
}

export type EmailTypeRecord = TopRecipientsReport & TopBlockedReport

export function isEmailTypeRecord(
  record: TrafficSummaryReport | BlockedEmailsReport | TopRecipientsReport | TopBlockedReport
): record is EmailTypeRecord {
  return (
    Object.prototype.hasOwnProperty.call(record, 'senderEmail') ||
    Object.prototype.hasOwnProperty.call(record, 'recipientEmail')
  )
}

export type DateTypeReport = InboundTrafficReport & OutboundTrafficReport & InboundBlockedEmailsBreakdownsReport

export function isDateTypeReport(report: ReportsResponse): report is DateTypeReport {
  const firstItem = report.results?.[0]
  if (!firstItem) {
    return false
  }

  return Object.prototype.hasOwnProperty.call(firstItem, 'reportDate')
}

export type EmailTypeReport = TopInboundEmailSendersReport &
  TopInboundEmailRecipientsReport &
  TopInboundBlockedSendersBreakdownReport

export function isEmailTypeReport(report: ReportsResponse): report is EmailTypeReport {
  const firstItem = report.results?.[0]
  if (!firstItem) {
    return false
  }

  return (
    Object.prototype.hasOwnProperty.call(firstItem, 'senderEmail') ||
    Object.prototype.hasOwnProperty.call(firstItem, 'recipientEmail')
  )
}

export type DateTypeTableReport = ReportTable<TrafficSummaryReport> & ReportTable<BlockedEmailsReport>

export function isDateTypeTableReport(report: ReportsResponseTable): report is DateTypeTableReport {
  const firstItem = report.data?.[0]
  if (!firstItem) {
    return false
  }

  return Object.prototype.hasOwnProperty.call(firstItem, 'reportDate')
}

export type EmailTypeTableReport = ReportTable<TopRecipientsReport> & ReportTable<TopBlockedReport>

export function isEmailTypeTableReport(report: ReportsResponseTable): report is EmailTypeTableReport {
  const firstItem = report.data?.[0]
  if (!firstItem) {
    return false
  }

  return (
    Object.prototype.hasOwnProperty.call(firstItem, 'senderEmail') ||
    Object.prototype.hasOwnProperty.call(firstItem, 'recipientEmail')
  )
}

export function getReportType(report: ReportsListItem): ReportTypes {
  return (Object.values(BarracudaReportTypes) as string[]).includes(report.id)
    ? ReportTypes.barracuda
    : ReportTypes.custom
}

export enum ScheduledReportStatus {
  enabled = 'enabled',
  disabled = 'disabled'
}

export interface ScheduledReport {
  id: string
  name: string
  timeRange: string
  format: string
  frequency: string
  every?: string
  on?: string
  at?: string
  recipients: string
  nextSchedule: number
  lastSchedule?: number
  status: ScheduledReportStatus
}

export interface ParsedScheduleReport extends ScheduledReport {
  formattedDate: string
  formattedHours: string
  formattedLastDate: string | null
  formattedLastHours: string | null
  formattedRecipients: string[]
}
