import {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  MouseEventHandler,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import { cloneDeep } from 'lodash'

import { Day, DAYS, Hour, HOURS, InboundScheduleUI } from 'types/Settings'
import { EMPTY_SCHEDULE_MATRIX, ScheduleMatrixProps } from 'components/libs/scheduler/schedulerMatrixTypes'

interface ScheduleMatrixContainer {
  draggable: boolean
  onDragStart: DragEventHandler<HTMLTableCellElement>
  onClick: MouseEventHandler<HTMLTableCellElement>
  onDragLeave: DragEventHandler<HTMLTableCellElement>
  onDragEnd: DragEventHandler<HTMLTableCellElement>
  onDrag: DragEventHandler<HTMLTableCellElement>
  onDragOver: DragEventHandler<HTMLTableCellElement>
}

export interface ScheduleMatrixLogic {
  container: ScheduleMatrixContainer
  days: typeof DAYS
  hours: typeof HOURS
  scheduleRef: MutableRefObject<InboundScheduleUI | undefined>
}

const useScheduleMatrixLogic = ({
  isEnabled,
  customInboundSchedule,
  doUpdate
}: ScheduleMatrixProps): ScheduleMatrixLogic => {
  const scheduleRef = useRef(customInboundSchedule || EMPTY_SCHEDULE_MATRIX)

  useEffect(() => {
    if (customInboundSchedule) {
      scheduleRef.current = customInboundSchedule
    }
  }, [customInboundSchedule])

  const selectTime = useCallback(
    (e: MouseEvent | DragEvent, toggle = false) => {
      if (!isEnabled) return

      const { classList, dataset } = e.target as HTMLElement
      if (!dataset.time) {
        return
      }
      const [day, hour] = dataset.time.split('-') as [Day, Hour]

      // update UI
      if (toggle) {
        classList.toggle('selected-cell')
      } else {
        // eslint-disable-next-line no-unused-expressions
        e.shiftKey ? classList.remove('selected-cell') : classList.add('selected-cell')
      }

      // update dataset
      const updatedCurrent = cloneDeep(scheduleRef.current)

      if (updatedCurrent) {
        if (classList.contains('selected-cell')) {
          updatedCurrent[day][hour] = true
        } else {
          delete updatedCurrent[day][hour]
        }
        doUpdate(updatedCurrent)
      }
      scheduleRef.current = updatedCurrent
    },
    [doUpdate, isEnabled]
  )

  const handleDragStart = useCallback(
    (e: DragEvent) => {
      if (!isEnabled) return

      selectTime(e)
      e.stopPropagation()
    },
    [selectTime, isEnabled]
  )

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      if (!isEnabled) return

      e.preventDefault()
    },
    [isEnabled]
  )

  const handleDragOver = useCallback(
    (e: DragEvent) => {
      if (!isEnabled) return

      selectTime(e)
      e.preventDefault()
    },
    [selectTime, isEnabled]
  )

  const handleDragEnd = useCallback(
    (e: DragEvent) => {
      if (!isEnabled) return

      e.preventDefault()
    },
    [isEnabled]
  )

  const handleDrag = useCallback(
    (e: DragEvent) => {
      if (!isEnabled) return

      e.preventDefault()
    },
    [isEnabled]
  )

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (isEnabled) {
        selectTime(e, true)
        e.preventDefault()
      }
    },
    [isEnabled, selectTime]
  )

  const container: ScheduleMatrixContainer = useMemo(
    () => ({
      draggable: true,
      onDragStart: handleDragStart,
      onClick: handleClick,
      onDragLeave: handleDragLeave,
      onDragEnd: handleDragEnd,
      onDrag: handleDrag,
      onDragOver: handleDragOver
    }),
    [handleClick, handleDragOver, handleDragStart, handleDrag, handleDragEnd, handleDragLeave]
  )

  return useMemo(
    () => ({
      container,
      days: DAYS,
      hours: HOURS,
      scheduleRef
    }),
    [container]
  )
}

export default useScheduleMatrixLogic
