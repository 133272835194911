/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react'

import { Alert, Box, Card, CardContent, Grid, LinearProgress, Link, Typography } from '@barracuda-internal/bds-core'
import * as bdsTheme from '@barracuda-internal/bds-core/dist/styles/themes'
import { LogoPrimary } from '@barracuda-internal/bds-core/dist/Logos/Barracuda'
import { useFormatMessage } from 'lib/localization'
import styles from 'components/pages/login/loginStyles'
import { EventHandlers, State, UiState } from 'components/pages/login/useLoginLogic'

const BASE_I18N_KEY = 'ess.login'

export type MessageCardProps = {
  state: State
  eventHandlers: EventHandlers
}

function MessageCard(props: MessageCardProps) {
  const { state, eventHandlers } = props
  const classes = styles(bdsTheme)
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Card elevation={3} className={classes.card} data-testid="message-card">
        {(state.isLoading || state.uiState === UiState.INITIALIZING) && (
          <LinearProgress data-testid="linear-progress" />
        )}
        <CardContent className={classes.cardWrapper}>
          <Box className={classes.cardHeader}>
            <LogoPrimary className={classes.cardLogo} height={40} />
            <Typography variant="h2">{formatMessage('message.title')}</Typography>
            <Typography variant="body1">
              {formatMessage('message.sent_message', {
                email: <b>{state.formValues.user_id}</b>
              })}
            </Typography>
            <Typography variant="body1">{formatMessage('message.expiry_message')}</Typography>
          </Box>
          <Box>
            {state.topErrorMessage && (
              <Alert severity="error" className={classes.topError}>
                {state.topErrorMessage}
              </Alert>
            )}
            {state.topErrorDescription && (
              <Grid item xs={12}>
                <Alert severity="error" className={classes.topError}>
                  {state.topErrorDescription}
                </Alert>
              </Grid>
            )}
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} className={classes.cardFormActionRow}>
                <Typography variant="body1" className={classes.cardInlineText}>
                  {formatMessage('didnt_receive_email')}
                </Typography>
                <Link
                  onClick={eventHandlers.onResendLink}
                  data-testid="on-resend-link"
                  className={`${classes.cardInlineLink} ${state.isLoading ? 'disabled' : ''}`}
                >
                  <Typography variant="body1">{formatMessage('resend_link')}</Typography>
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.cardFormLinkRow}>
                <Link onClick={eventHandlers.goBackToSignIn} data-testid="go-to-signin">
                  <Typography variant="body1">{formatMessage('go_to_signin')}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    ),
    [
      classes.card,
      classes.cardFormActionRow,
      classes.cardFormLinkRow,
      classes.cardHeader,
      classes.cardInlineLink,
      classes.cardInlineText,
      classes.cardLogo,
      classes.cardWrapper,
      classes.topError,
      eventHandlers.goBackToSignIn,
      eventHandlers.onResendLink,
      formatMessage,
      state.formValues.user_id,
      state.isLoading,
      state.topErrorDescription,
      state.topErrorMessage,
      state.uiState
    ]
  )
}

export default MessageCard
/* eslint-enable jsx-a11y/anchor-is-valid */
