import { makeStyles } from '@material-ui/core/styles'
import { colors } from '@cuda/bds.ui.styles'

export default makeStyles(theme => ({
  pushPin: {
    color: theme.palette.secondary.main,
    display: 'none',
    '&:hover': {
      display: 'block'
    }
  },
  pushPinActive: {
    color: theme.palette.primary.main
  },
  listItem: {
    cursor: 'pointer',
    borderRadius: 4,
    justifyContent: 'space-between'
  },
  listItemText: {
    cursor: 'pointer',
    padding: theme.spacing(1, 0)
  },
  listItemSecondary: {
    marginTop: 8,
    marginBottom: 8,
    '&:hover': {
      background: colors.backgroundPrimary,
      '& $pushPin': {
        display: 'block'
      }
    },
    '&.Mui-selected': {
      background: colors.backgroundPrimary,
      color: theme.palette.primary.dark,
      '&:hover': {
        background: colors.backgroundPrimary
      }
    }
  },
  dropdownItemsEmptyStateContainer: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 1)
  }
}))
