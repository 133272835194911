import React, { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/toolkit/hooks'
import routesConfig from 'lib/routesConfig'

export default function validateEnduserRoutes<P>(WrappedComponent: React.ComponentType<P>) {
  const ValidateEnduserRoutes: React.FC<any> = props => {
    const { isUsedOnetimePasscode } = useAppSelector(_store => ({
      isUsedOnetimePasscode: !!_store.auth.accessTokenObject?.isUsedOnetimePasscode
    }))
    const [shouldRenderComponent, setShouldRenderComponent] = useState<boolean>(false)

    // Force the user to change the pwd if onetime pwd is used to login
    useEffect(() => {
      // TODO: register dedicated routes for each Settings view then use the route.id compare here
      const isChangePasswordPath = window.location.href.includes(routesConfig.SETTINGS_CHANGE_PASSWORD.path)
      if (isUsedOnetimePasscode && !isChangePasswordPath) {
        routesConfig.SETTINGS_CHANGE_PASSWORD.goto()
      } else {
        setShouldRenderComponent(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return useMemo(
      () => {
        if (!shouldRenderComponent) {
          return null
        }

        return <WrappedComponent {...(props as any)} />
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [shouldRenderComponent]
    )
  }

  return ValidateEnduserRoutes
}
