import React from 'react'

import styles from 'components/pages/support/help/helpStyles'
import { HelpComponentProps } from 'types/Help'

function SelfService1(props: HelpComponentProps) {
  const classes = styles()
  return (
    <div className={classes.content} data-testid="content">
      Enter the primary domain for which you want {props.productName} to scan email. You will add your email server(s)
      in the next step, and you can add more domains later. Click <b>Next</b>.
    </div>
  )
}

export default SelfService1
