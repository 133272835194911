import { useCallback, useMemo } from 'react'

import routesConfig, { UiRoute } from 'lib/routesConfig'
import { postAllowSender, postBlockSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

export const qNKey = '/user/settings/quarantine_notification'

export interface QNDigestLinkTarget extends MouseEvent {
  target: HTMLAnchorElement
}

export type QNDigestLinkQueryParams = {
  url?: string
  redirectUrl?: string
}

export type HandleQNDigestLink = (queryParams: QNDigestLinkQueryParams) => boolean
export type HandleQNDigestClickEvent = (e: any) => boolean
export type GenerateQNDigestReturnUrlParams = () => string | undefined
export default function useHandleQNDigestLinks(): [
  HandleQNDigestLink,
  HandleQNDigestClickEvent,
  GenerateQNDigestReturnUrlParams
] {
  const { userDomainId, currentUrlId } = useAppSelector(_store => ({
    userDomainId: _store.auth.accessTokenObject?.domainId,
    currentUrlId: _store.app.activePath.id
  }))
  const dispatch = useAppDispatch()

  const gotoPage = useCallback(
    (targetRoute: UiRoute) => {
      // prevent the route transition if the user is already on the selected route
      // TODO: we should add this logic to UiRoute.goto() method directly
      if (currentUrlId !== targetRoute.id) {
        targetRoute.goto()
      }
    },
    [currentUrlId]
  )

  const getQueryParams = useCallback((urlString: string): QNDigestLinkQueryParams => {
    const urlParams = new URLSearchParams(urlString)
    const redirectUrl = urlParams.get('return_url') || undefined
    const url = urlParams.get('url') || undefined

    return {
      url,
      redirectUrl
    }
  }, [])

  const generateQNDigestReturnUrlParams = useCallback(() => {
    let queryParams
    // keep the query params for the QN digest links
    const urlParams = window.location.search
    if (urlParams.includes('quar_sum_report')) {
      const originalQueryParams = new URLSearchParams(urlParams)
      const loginHint = originalQueryParams.get('login-hint') || originalQueryParams.get('user')
      const returnTo = `${routesConfig.QN_DIGEST_LINKS_HANDLER.url()}${urlParams}`
      queryParams = `?${new URLSearchParams({
        return_to: returnTo,
        ...(loginHint && { 'login-hint': loginHint })
      }).toString()}`
    }

    return queryParams
  }, [])

  const handleQnDigestLink = useCallback(
    (queryParams: QNDigestLinkQueryParams) => {
      const { redirectUrl, url } = queryParams

      if (!userDomainId) {
        return false
      }

      if (redirectUrl && redirectUrl.includes('quarantine_notification')) {
        const redirectPage = redirectUrl?.includes(qNKey) ? 'SETTINGS_QUARANTINE_NOTIFICATION' : 'MESSAGE_LOG'
        gotoPage(routesConfig[redirectPage])
        return true
      }
      if (url && url.includes('log')) {
        const [, , action, , messageId] = url.split('/')

        switch (true) {
          case action === 'deliver':
            dispatch(
              postRedeliverMessage({
                items: [{ messageId, domainId: userDomainId }],
                atpBypass: false
              })
            )
            break
          case action === 'block':
            dispatch(postBlockSender([{ messageId, domainId: userDomainId }]))
            break
          case action === 'whitelist':
            dispatch(
              postAllowSender({
                items: [{ messageId, domainId: userDomainId }],
                atpBypass: false
              })
            )
            break
          default:
        }
        gotoPage(routesConfig.MESSAGE_LOG)
        return true
      }
      gotoPage(routesConfig.MESSAGE_LOG)
      return true
    },
    [dispatch, userDomainId, gotoPage]
  )

  const handleQNDigestClickEvent = useCallback(
    (e: any) => {
      const targetHref = (e as QNDigestLinkTarget).target?.href
      const queryParams = getQueryParams(targetHref)

      // allow non QN digest link events
      if (!queryParams.url && !queryParams.redirectUrl) {
        return true
      }

      // prevent the bubble for QN digest links and handle the selected action
      e.preventDefault()
      e.stopPropagation()
      handleQnDigestLink(queryParams)
      return false
    },
    [handleQnDigestLink, getQueryParams]
  )

  return useMemo(
    () => [handleQnDigestLink, handleQNDigestClickEvent, generateQNDigestReturnUrlParams],
    [handleQnDigestLink, handleQNDigestClickEvent, generateQNDigestReturnUrlParams]
  )
}
