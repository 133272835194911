import React, { useMemo } from 'react'

import { useFormatMessage } from 'lib/localization'
import Loading from 'components/libs/loading/Loading'
import { useBccLoginLogic } from 'components/pages/bccLogin/useBccLoginLogic'
import ErrorPage from 'components/libs/errorPage/ErrorPage'

const BASE_I18N_KEY = 'ess.bcc_login.error_page'

function BccLogin() {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [error, bccLoginFailedMessage] = useBccLoginLogic()

  return useMemo(() => {
    // Keep the loading state on 401 status because we redirect
    if (!error && (!bccLoginFailedMessage || bccLoginFailedMessage === 401)) {
      return <Loading />
    }
    return <ErrorPage title={formatMessage('default.title')} subtitle={formatMessage('default.subtitle')} />
  }, [error, bccLoginFailedMessage, formatMessage])
}

export default BccLogin
