import { useCallback, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending } from 'redux/toolkit/api'

import { getTopDomainsStats, updateFilters } from 'redux/features/stats/statsSlice'

import { DashboardRange, StatType, TopDomainsActions, TopRecipientDomains } from 'types/stats'
import { DisplayType, getTableWithDropdownConfig } from 'components/libs/tableWithDropdown/config'
import { Direction } from 'types/Messages'

export interface UseTopDomainsLogicProps {
  domainId?: string
  range: DashboardRange
}

interface State {
  topDomains: TopRecipientDomains | undefined
  topDomainsInProgress: boolean
  selectedFilter: string
}

interface EventHandlers {
  onSelectFilter: (value: unknown) => void
}

export type UseTopDomainsLogic = [State, EventHandlers]

export const useTopDomainsLogic = (props: UseTopDomainsLogicProps): UseTopDomainsLogic => {
  const dispatch = useAppDispatch()

  const { topDomains, topDomainsInProgress } = useAppSelector(_store => ({
    topDomains: _store.stats.topDomains,
    topDomainsInProgress: isPending(_store.stats.api.getTopDomainsStats)
  }))
  const [selectedFilter, setSelectedFilter] = useState<string>(
    getTableWithDropdownConfig(DisplayType.recipientDomains)?.dropdownItems[0].value || ''
  )

  const onSelectFilter: EventHandlers['onSelectFilter'] = useCallback(
    value => {
      setSelectedFilter(value as TopDomainsActions)
      dispatch(updateFilters({ topDomains: { action: value as TopDomainsActions } }))
      dispatch(
        getTopDomainsStats({
          domainId: props.domainId,
          range: props.range,
          action: value as TopDomainsActions,
          direction: Direction.INBOUND,
          statType: props.domainId ? StatType.SENDERS : StatType.RECIPIENTS
        })
      )
    },
    [dispatch, props.domainId, props.range]
  )

  return useMemo(
    () => [
      {
        topDomains,
        topDomainsInProgress,
        selectedFilter
      },
      {
        onSelectFilter
      }
    ],
    [topDomains, topDomainsInProgress, selectedFilter, onSelectFilter]
  )
}
