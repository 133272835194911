import { RootState } from 'redux/toolkit/store'
import { SearchSettingsRepository } from 'lib/services/searchSettings/searchSettingsRepository'
import { SearchSettingsService } from 'lib/services/searchSettings/searchSettingsService'

export interface SearchSettingsRepositoryFactory {
  fromReduxState: (state: RootState) => SearchSettingsRepository
}

export const SearchSettingsServiceFactory = (repositoryFactory: SearchSettingsRepositoryFactory) => {
  const fromReduxState = (state: RootState) => {
    const repository = repositoryFactory.fromReduxState(state)
    return SearchSettingsService(repository)
  }

  return Object.freeze({
    fromReduxState
  })
}
