import { ChartColorCodes } from 'components/pages/overview/dashboard/emailStatisticsCharts/useEmailStatisticsChartLogic'
import { Action } from 'types/Messages'

export default {
  data: [
    {
      id: Action.quarantined,
      data: [
        {
          x: '29-01',
          y: 20
        },
        {
          x: '30-01',
          y: 3
        },
        {
          x: '31-01',
          y: 93
        },
        {
          x: '01-02',
          y: 8
        },
        {
          x: '02-02',
          y: 23
        },
        {
          x: '03-02',
          y: 77
        },
        {
          x: '04-02',
          y: 80
        },
        {
          x: '05-02',
          y: 78
        },
        {
          x: '06-02',
          y: 73
        },
        {
          x: '07-02',
          y: 35
        },
        {
          x: '08-02',
          y: 46
        },
        {
          x: '09-02',
          y: 29
        },
        {
          x: '10-02',
          y: 59
        },
        {
          x: '11-02',
          y: 36
        },
        {
          x: '12-02',
          y: 62
        },
        {
          x: '13-02',
          y: 9
        },
        {
          x: '14-02',
          y: 73
        },
        {
          x: '15-02',
          y: 6
        },
        {
          x: '16-02',
          y: 49
        },
        {
          x: '17-02',
          y: 44
        },
        {
          x: '18-02',
          y: 96
        },
        {
          x: '19-02',
          y: 36
        },
        {
          x: '20-02',
          y: 29
        },
        {
          x: '21-02',
          y: 94
        },
        {
          x: '22-02',
          y: 88
        },
        {
          x: '23-02',
          y: 65
        },
        {
          x: '24-02',
          y: 18
        },
        {
          x: '25-02',
          y: 79
        },
        {
          x: '26-02',
          y: 81
        },
        {
          x: '27-02',
          y: 32
        }
      ]
    },
    {
      id: Action.blocked,
      data: [
        {
          x: '29-01',
          y: 70
        },
        {
          x: '30-01',
          y: 41
        },
        {
          x: '31-01',
          y: 55
        },
        {
          x: '01-02',
          y: 23
        },
        {
          x: '02-02',
          y: 63
        },
        {
          x: '03-02',
          y: 21
        },
        {
          x: '04-02',
          y: 60
        },
        {
          x: '05-02',
          y: 84
        },
        {
          x: '06-02',
          y: 5
        },
        {
          x: '07-02',
          y: 84
        },
        {
          x: '08-02',
          y: 14
        },
        {
          x: '09-02',
          y: 61
        },
        {
          x: '10-02',
          y: 3
        },
        {
          x: '11-02',
          y: 30
        },
        {
          x: '12-02',
          y: 5
        },
        {
          x: '13-02',
          y: 14
        },
        {
          x: '14-02',
          y: 60
        },
        {
          x: '15-02',
          y: 70
        },
        {
          x: '16-02',
          y: 9
        },
        {
          x: '17-02',
          y: 31
        },
        {
          x: '18-02',
          y: 67
        },
        {
          x: '19-02',
          y: 0
        },
        {
          x: '20-02',
          y: 65
        },
        {
          x: '21-02',
          y: 97
        },
        {
          x: '22-02',
          y: 60
        },
        {
          x: '23-02',
          y: 14
        },
        {
          x: '24-02',
          y: 94
        },
        {
          x: '25-02',
          y: 12
        },
        {
          x: '26-02',
          y: 17
        },
        {
          x: '27-02',
          y: 29
        }
      ]
    },
    {
      id: Action.allowed,
      data: [
        {
          x: '29-01',
          y: 60
        },
        {
          x: '30-01',
          y: 87
        },
        {
          x: '31-01',
          y: 9
        },
        {
          x: '01-02',
          y: 53
        },
        {
          x: '02-02',
          y: 47
        },
        {
          x: '03-02',
          y: 43
        },
        {
          x: '04-02',
          y: 8
        },
        {
          x: '05-02',
          y: 70
        },
        {
          x: '06-02',
          y: 96
        },
        {
          x: '07-02',
          y: 12
        },
        {
          x: '08-02',
          y: 74
        },
        {
          x: '09-02',
          y: 63
        },
        {
          x: '10-02',
          y: 60
        },
        {
          x: '11-02',
          y: 35
        },
        {
          x: '12-02',
          y: 46
        },
        {
          x: '13-02',
          y: 67
        },
        {
          x: '14-02',
          y: 0
        },
        {
          x: '15-02',
          y: 5
        },
        {
          x: '16-02',
          y: 17
        },
        {
          x: '17-02',
          y: 49
        },
        {
          x: '18-02',
          y: 62
        },
        {
          x: '19-02',
          y: 65
        },
        {
          x: '20-02',
          y: 35
        },
        {
          x: '21-02',
          y: 78
        },
        {
          x: '22-02',
          y: 39
        },
        {
          x: '23-02',
          y: 34
        },
        {
          x: '24-02',
          y: 8
        },
        {
          x: '25-02',
          y: 80
        },
        {
          x: '26-02',
          y: 99
        },
        {
          x: '27-02',
          y: 38
        }
      ]
    }
  ],
  colors: [ChartColorCodes.allowed, ChartColorCodes.blocked, ChartColorCodes.quarantined],
  axisBottomValuesToShow: [
    '29-01',
    '31-01',
    '02-02',
    '04-02',
    '06-02',
    '08-02',
    '10-02',
    '12-02',
    '14-02',
    '16-02',
    '18-02',
    '20-02',
    '22-02',
    '24-02',
    '26-02'
  ],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLegendClick: () => {},
  disabledLegends: [],
  totals: [
    ['allowed', 13456],
    ['total_blocked', 345],
    ['virus', 34],
    ['atp', 70],
    ['quarantined', 1212]
  ] as [string, number][],
  maxValueInRange: 99
}
