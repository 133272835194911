import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { setHasPageChanges } from 'redux/features/settings/settingsSlice'
import { isSuccess } from 'redux/toolkit/api'

export interface SettingsFormField {
  getValue: () => string
  setValue: (value: string) => void
  reset: () => void
  ref: MutableRefObject<HTMLInputElement> | undefined
}

export interface SettingsFormConfig<TState> {
  fields: Record<keyof TState, SettingsFormField>
  delegateIsDirtyForm?: (isDirty: boolean) => void
}

export interface SettingsForm<TState> {
  fields: Record<keyof TState, SettingsFormField>
  getState: () => TState
  error: string
  setError: (value: string) => void
  delegateIsDirty: () => void
  validate: (newValue: any, dependencies?: any) => boolean
  reset: () => void
}

export const useSettingsForm = <TState extends object>({ fields, delegateIsDirtyForm }: SettingsFormConfig<TState>) => {
  const dispatch = useAppDispatch()
  const {
    isUpdateAccountSettingsSuccess,
    isUpdateDomainSettingsSuccess,
    isAddOutboundIpsSuccess,
    isRemoveOutboundIpsSuccess
  } = useAppSelector(_store => ({
    isUpdateAccountSettingsSuccess: isSuccess(_store.settings.updateAccountSettingsApiStatus),
    isUpdateDomainSettingsSuccess: isSuccess(_store.settings.updateDomainSettingsApiStatus),
    isAddOutboundIpsSuccess: isSuccess(_store.outboundIp.addOutboundIpApiStatus),
    isRemoveOutboundIpsSuccess: isSuccess(_store.outboundIp.removeOutboundIpApiStatus)
  }))
  const [error, setError] = useState('')

  const getState = useCallback((): TState => {
    const state: any = {}
    Object.entries(fields).forEach(([key, field]) => {
      state[key] = (field as SettingsFormField).getValue()
    })
    return state
  }, [fields])

  const formValues = useMemo(() => getState(), [getState])

  const [{ isDirty }, resetInitialForm, getIsDirtyValues] = useDirtyFormObjectCheck(formValues)

  const delegateIsDirty = useCallback(() => {
    const dirtyValuesResult = getIsDirtyValues(getState())
    delegateIsDirtyForm?.(dirtyValuesResult.isDirty)
    // TODO: remove redux usage of settings.hasPageChanges from the app
    dispatch(setHasPageChanges(dirtyValuesResult.isDirty))
  }, [delegateIsDirtyForm, dispatch, getIsDirtyValues, getState])

  const reset = useCallback(() => {
    Object.values(fields).forEach(field => (field as SettingsFormField).reset())
    setError('')
    delegateIsDirty()
    resetInitialForm(formValues)
  }, [delegateIsDirty, fields, resetInitialForm, formValues])

  useEffect(() => {
    delegateIsDirtyForm?.(isDirty)
  }, [isDirty, delegateIsDirtyForm])

  useEffect(() => {
    if (isUpdateAccountSettingsSuccess || isUpdateDomainSettingsSuccess) {
      reset()
      delegateIsDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateAccountSettingsSuccess, isUpdateDomainSettingsSuccess])

  useEffect(() => {
    if (isAddOutboundIpsSuccess) {
      reset()
      delegateIsDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddOutboundIpsSuccess])

  useEffect(() => {
    if (isRemoveOutboundIpsSuccess) {
      reset()
      delegateIsDirty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoveOutboundIpsSuccess])

  return useMemo(
    () => ({
      fields,
      getState,
      error,
      setError,
      delegateIsDirty,
      validate: () => true,
      reset
    }),
    [fields, getState, error, delegateIsDirty, reset]
  )
}
