import { useMemo } from 'react'

import { useAppSelector } from 'redux/toolkit/hooks'
import { useFormatMessage } from 'lib/localization'
import { UserType } from 'types/auth'
import { SubMenuItem } from 'components/libs/layout/navbar/sidebarTypes'
import routesConfig, { UiRoute } from 'lib/routesConfig'
import appConfig from 'config/appConfig'

export interface UseSettingsLogic {
  settingsListItems: SubMenuItem[]
}

export enum SettingsTabs {
  QUARANTINE_NOTIFICATION = 'quarantine_notification',
  SENDER_POLICIES = 'sender_policies',
  LINKED_ACCOUNTS = 'linked_accounts',
  CHANGE_PASSWORD = 'change_password'
}

export const TABS: [SettingsTabs, UiRoute][] = [
  [SettingsTabs.QUARANTINE_NOTIFICATION, routesConfig.SETTINGS_QUARANTINE_NOTIFICATION],
  [SettingsTabs.SENDER_POLICIES, routesConfig.SETTINGS_SENDER_POLICY],
  [SettingsTabs.LINKED_ACCOUNTS, routesConfig.SETTINGS_LINKED_ACCOUNTS],
  [SettingsTabs.CHANGE_PASSWORD, routesConfig.SETTINGS_CHANGE_PASSWORD]
]

const BASE_I18N_KEY = 'ess.settings.tabs'

export const useSettingsLogic = (): [SubMenuItem[]] => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const { accessTokenObject, isUsedOnetimePasscode } = useAppSelector(_store => ({
    accessTokenObject: _store.auth.accessTokenObject,
    isUsedOnetimePasscode: !!_store.auth.accessTokenObject?.isUsedOnetimePasscode
  }))

  const settingsListItems: SubMenuItem[] = useMemo(() => {
    function filterTabs(tab: [SettingsTabs, UiRoute]) {
      const [tabName] = tab
      if (
        !isUsedOnetimePasscode &&
        accessTokenObject?.type?.toLowerCase() !== UserType.manual &&
        tabName === SettingsTabs.CHANGE_PASSWORD
      ) {
        return false
      }
      // show only the change password if onetime pwd is used to Login
      if (isUsedOnetimePasscode && tabName !== SettingsTabs.CHANGE_PASSWORD) {
        return false
      }
      return true
    }

    return TABS.filter(filterTabs).map(tab => {
      const [tabId, routeConfig] = tab
      return {
        name: formatMessage(tabId),
        path: appConfig.APP.IS_ENDUSER && routeConfig.path
      }
    }) as SubMenuItem[]
  }, [formatMessage, accessTokenObject, isUsedOnetimePasscode])

  return useMemo(() => [settingsListItems], [settingsListItems])
}
