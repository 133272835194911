import React, { useMemo } from 'react'

import { StatComponentProps } from 'types/stats'

import EmailStatisticsChart from 'components/pages/overview/dashboard/emailStatisticsCharts/EmailStatisticsChart'
import { useInboundEmailStatisticsLogic } from 'components/pages/overview/dashboard/emailStatisticsCharts/useInboundEmailStatisticsLogic'

const InboundEmailStatistics: React.FC<StatComponentProps> = props => {
  const [state, eventHandlers] = useInboundEmailStatisticsLogic(props)

  return useMemo(
    () => <EmailStatisticsChart title="inbound_email_statistics" logic={{ state, eventHandlers }} />,
    [state, eventHandlers]
  )
}

export default InboundEmailStatistics
