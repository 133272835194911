import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataTableState, UpdateDataTable } from 'types/redux/dataTables/dataTables'
import reducerHandlers from '../reduceHandlers'

export const GRID_COLUMNS = {
  // Note: Keep these the same as the audit log DB column names
  DATE: 'timestamp',
  EVENT_TYPE: 'action',
  DESCRIPTION: 'description',
  USER: 'actor',
  IP_ADDRESS: 'ip'
}

export const COLUMNS_CONFIG = {
  [GRID_COLUMNS.DATE]: {
    sortable: true,
    orderIndex: 0,
    show: true,
    resizable: false,
    width: 200
  },
  [GRID_COLUMNS.EVENT_TYPE]: {
    sortable: true,
    orderIndex: 1,
    show: true,
    resizable: false,
    width: 200
  },
  [GRID_COLUMNS.DESCRIPTION]: {
    sortable: true,
    orderIndex: 2,
    show: true,
    resizable: true
  },
  [GRID_COLUMNS.USER]: {
    sortable: true,
    orderIndex: 3,
    show: true,
    resizable: false,
    width: 248
  },
  [GRID_COLUMNS.IP_ADDRESS]: {
    sortable: true,
    orderIndex: 4,
    show: true,
    resizable: false,
    width: 228
  }
}

export const ITEMS_PER_PAGE = 25

export const INITIAL_STATE: DataTableState = {
  GRID_COLUMNS,
  ITEMS_PER_PAGE,
  columnsConfig: COLUMNS_CONFIG,
  skip: 0,
  take: ITEMS_PER_PAGE,
  filter: null,
  search: '',
  sort: [{ field: GRID_COLUMNS.DATE, dir: 'desc' }]
}

/* eslint-disable no-param-reassign */
export const auditLogSlice = createSlice({
  name: 'DATATABLE/AUDIT_LOG',
  initialState: INITIAL_STATE,
  reducers: {
    update: {
      reducer: (state: DataTableState, action: PayloadAction<{ config: UpdateDataTable }>) =>
        reducerHandlers({ COLUMNS_CONFIG }).update({ state, payload: action.payload }),
      prepare: (config: UpdateDataTable) => ({
        payload: { config }
      })
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  }
})
/* eslint-enable no-param-reassign */

export const { update, reset } = auditLogSlice.actions

export default auditLogSlice.reducer
