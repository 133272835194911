export const extractEmail = (email: string) => {
  const emailRegEx = /([a-zA-Z0-9!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
  if (emailRegEx.test(email)) {
    const result = email.match(emailRegEx)
    return result ? result[0] : ''
  }
  return ''
}

export const isLargeMessage = (size: any) => {
  if (typeof size === 'string') {
    return Number(size) > 20 * 1024 * 1024
  }
  return size > 20 * 1024 * 1024
}
