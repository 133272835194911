import { useMemo, useCallback, useRef, useEffect, useState, ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getAzureAdLookupUser,
  getAzureAdLookupUserSignedUrl,
  resetLookupUserSignedUrl
} from 'redux/features/azureAd/azureAdSlice'
import {
  failedResponse,
  inIdle,
  inProgress,
  isFailed as isApiFailed,
  isPending,
  isSuccess as isApiSuccess,
  successResponse
} from 'redux/toolkit/api'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import appConfig from 'config/appConfig'

export interface State {
  result: any
  isLookupUserPending: boolean
  error: string
  testUser: string
  domainName: string
}

export interface EventHandlers {
  onLookupUser: () => void
  onChangeTestUser: (e: ChangeEvent<HTMLInputElement>) => void
  getIsLookupUserDisabled: (isOptionsDisabled: boolean) => boolean
}

export type LookupAzureAdUser = [State, EventHandlers]

export const useAzureAdLookupUser = (form: EditDomainForm): LookupAzureAdUser => {
  const [{ domainId, testUser, isAzureAdLookupUserPending }] = form
  const dispatch = useAppDispatch()
  const tryCount = useRef(0)
  const signedUrl = useRef<string>()
  const [status, setStatus] = useState(inIdle)
  const [result, setResult] = useState<any>()
  const [error, setError] = useState('')

  const {
    azureAdLookupUser,
    isGetAzureAdLookupUserSignedUrlSuccess,
    isGetAzureAdLookupUserSignedUrlFailed,
    isGetAzureAdLookupUserSuccess,
    isGetAzureAdLookupUserFailed,
    azureAdLookupUserSignedUrl,
    domainName
  } = useAppSelector(_store => ({
    azureAdLookupUser: _store.azureAd.azureAdLookupUser,
    isGetAzureAdLookupUserSuccess: isApiSuccess(_store.azureAd.api.getAzureAdLookupUser),
    isGetAzureAdLookupUserFailed: isApiFailed(_store.azureAd.api.getAzureAdLookupUser),
    isGetAzureAdLookupUserSignedUrlSuccess: isApiSuccess(_store.azureAd.api.getAzureAdLookupUserSignedUrl),
    isGetAzureAdLookupUserSignedUrlFailed: isApiFailed(_store.azureAd.api.getAzureAdLookupUserSignedUrl),
    azureAdLookupUserSignedUrl: _store.azureAd.azureAdLookupUserSignedUrl,
    domainName: _store.domains.domain?.domainName ? `@${_store.domains.domain?.domainName}` : ''
  }))

  const isLookupUserPending = useMemo(() => isPending(status), [status])

  const lookupAzureAdUser = useCallback(() => {
    resetLookupUserSignedUrl()
    signedUrl.current = undefined
    setStatus(inProgress)
    dispatch(getAzureAdLookupUserSignedUrl({ domainId, testUser: testUser.value }))
  }, [dispatch, domainId, testUser.value])

  const onChangeTestUser = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      testUser.setValue(e.target.value.trim())
    },
    [testUser]
  )

  const isValidTestEmailUser = useCallback(() => {
    if (!testUser.value) {
      setError('test_azure_ad_settings.empty_username')
      return false
    }
    if (testUser.value.includes('@')) {
      setError('test_azure_ad_settings.invalid_username')
      return false
    }
    return true
  }, [testUser.value])

  const onLookupUser = useCallback(() => {
    if (isValidTestEmailUser()) {
      lookupAzureAdUser()
    }
  }, [isValidTestEmailUser, lookupAzureAdUser])

  const getIsLookupUserDisabled = useCallback((isOptionsDisabled: boolean) => isOptionsDisabled, [])

  useEffect(() => {
    if (isGetAzureAdLookupUserSignedUrlSuccess && azureAdLookupUserSignedUrl) {
      signedUrl.current = azureAdLookupUserSignedUrl
      tryCount.current = 1
      dispatch(getAzureAdLookupUser({ signedUrl: signedUrl.current }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAzureAdLookupUserSignedUrlSuccess])

  useEffect(() => {
    if (isGetAzureAdLookupUserSignedUrlFailed) {
      setStatus(failedResponse('failed_to_get_signed_url_for_azure_ad_user_lookup'))
      dispatch(setErrorSnackBar({ message: 'lookup_azure_ad_user.signed_url_error' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAzureAdLookupUserSignedUrlSuccess])

  useEffect(() => {
    if (isGetAzureAdLookupUserSuccess) {
      setStatus(successResponse)
      setResult(azureAdLookupUser)
      dispatch(setSuccessSnackBar({ message: 'lookup_azure_ad_user.success' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAzureAdLookupUserSuccess])

  useEffect(() => {
    if (!signedUrl.current) {
      return
    }
    if (isGetAzureAdLookupUserFailed && tryCount.current >= appConfig.AZURE_AD.LOOKUP_USER_MAX_TRY_COUNT) {
      setStatus(failedResponse('maximum_retry_count_reached'))
      dispatch(setErrorSnackBar({ message: 'lookup_azure_ad_user.failure' }))
    }
    if (isGetAzureAdLookupUserFailed && tryCount.current < appConfig.AZURE_AD.LOOKUP_USER_MAX_TRY_COUNT) {
      tryCount.current += 1
      dispatch(getAzureAdLookupUser({ signedUrl: signedUrl.current }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAzureAdLookupUserFailed])

  useEffect(() => {
    isAzureAdLookupUserPending.setValue(isLookupUserPending)
  }, [isAzureAdLookupUserPending, isLookupUserPending])

  return useMemo(
    () => [
      { result, isLookupUserPending, error, domainName, testUser: testUser.value },
      { onChangeTestUser, onLookupUser, getIsLookupUserDisabled }
    ],
    [result, isLookupUserPending, error, domainName, testUser, onChangeTestUser, onLookupUser, getIsLookupUserDisabled]
  )
}
