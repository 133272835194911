import React, { ReactNode, useMemo } from 'react'
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons'
import { makeStyles } from '@barracuda-internal/bds-core'

export interface SettingsTableCellRadioProps<T = string> {
  value: T
  groupValue: T
  onClick: (value: T) => void
  onRef: (ref: SVGSVGElement | null) => void
  offRef: (ref: SVGSVGElement | null) => void
}

const styles = makeStyles(theme => ({
  radioButton: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginLeft: theme.spacing(1),
    '&.hide': {
      display: 'none'
    }
  }
}))

function SettingsTableCellRadio<T>({ value, groupValue, onClick, onRef, offRef }: SettingsTableCellRadioProps<T>) {
  const classes = styles()
  return (
    <>
      <RadioButtonChecked
        classes={{
          root: `${classes.radioButton} ${value === groupValue ? '' : 'hide'}`
        }}
        color="primary"
        ref={onRef}
      />
      <RadioButtonUnchecked
        classes={{
          root: `${classes.radioButton} ${value === groupValue ? 'hide' : ''}`
        }}
        ref={offRef}
        onClick={() => onClick(value)}
      />
    </>
  )
}

export function useSettingTableCellRadios<T>(options: T[], onClick: (value: T) => void, groupValue: T) {
  const radios: Map<T, ReactNode> = useMemo(() => new Map(), [])
  const onRefs: Map<T, SVGSVGElement | null> = new Map()
  const offRefs: Map<T, SVGSVGElement | null> = new Map()

  const handleClick = (value: T) => {
    onClick(value)
    Array.from(onRefs).forEach(([ownValue, onRef]) => {
      if (value === ownValue) {
        onRef?.classList.remove('hide')
      } else {
        onRef?.classList.add('hide')
      }
    })
    Array.from(offRefs).forEach(([ownValue, offRef]) => {
      if (value === ownValue) {
        offRef?.classList.add('hide')
      } else {
        offRef?.classList.remove('hide')
      }
    })
  }

  const setOnRef = (value: T, ref: SVGSVGElement | null) => {
    onRefs.set(value, ref)
  }

  const setOffRef = (value: T, ref: SVGSVGElement | null) => {
    offRefs.set(value, ref)
  }

  options.forEach(option => {
    radios.set(
      option,
      <SettingsTableCellRadio
        onClick={handleClick}
        onRef={(ref: SVGSVGElement | null) => setOnRef(option, ref)}
        offRef={(ref: SVGSVGElement | null) => setOffRef(option, ref)}
        value={option}
        groupValue={groupValue}
      />
    )
  })

  return useMemo(() => radios, [radios])
}
