import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  sidemenuContainer: {
    padding: theme.spacing(2)
  },
  list: {
    cursor: 'pointer'
  },
  listLabelContainer: {
    alignItems: 'flex-end'
  },
  listLabel: {
    cursor: 'pointer',
    fontWeight: 600
  },
  emptyStateContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  emptyStateIcon: {
    width: 94,
    height: 61
  },
  itemsContainer: {
    overflowY: 'scroll',
    position: 'absolute',
    top: 120,
    bottom: 0
  }
}))
