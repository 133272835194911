import React, { useMemo } from 'react'
import {
  LinearProgress,
  TextField,
  Button,
  DataTable,
  DataTableColumn,
  Card,
  CardHeader,
  CardContent,
  Typography
} from '@barracuda-internal/bds-core'
import { useFormatMessage } from 'lib/localization'

import YesNoDialog from 'components/libs/dialog/yesNoDialog/YesNoDialog'
import InfoDialog from 'components/libs/dialog/infoDialog/InfoDialog'
import { Cell } from 'components/libs/grid/cell'
import AddButton from 'components/pages/settings/linkedAccounts/AddButton'
import { isEmailValid } from 'lib/validation'
import styles from './linkedAccountsStyles'
import { ModifiedLinkedAccount, useLinkedAccountsLogic } from './useLinkedAccountsLogic'

const BASE_I18N_KEY = 'ess.settings.linked_accounts'

const LinkedAccounts: React.FC = () => {
  const {
    linkedAccountsAreLoading,
    sort,
    handleSortChange,
    sortedData,
    dataStateChange,
    gridState,
    isConfirmDialogOpen,
    closeConfirmDialog,
    onDoUnlink,
    accountLinkedMessage,
    successfulDialog,
    closeSuccessDialog,
    defaultFormValues,
    emailInput,
    onLink,
    onUnlink,
    linkedAccountEmail,
    onEmailChange,
    actionButton
  } = useLinkedAccountsLogic()

  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <>
        <Card elevation={1} data-testid="root-card">
          <CardHeader
            title={
              <Typography className={classes.title} data-testid="title">
                {formatMessage('title')}
              </Typography>
            }
            subheader={
              <Typography className={classes.subTitle} variant="body2" data-testid="subtitle">
                {formatMessage('sub_title')}
              </Typography>
            }
          />
          <CardContent>
            <div className={classes.progress}>
              {linkedAccountsAreLoading && <LinearProgress data-testid="loader" />}
            </div>
            {!linkedAccountsAreLoading && (
              <DataTable
                className={classes.table}
                sortable={{
                  allowUnsort: false,
                  mode: 'single'
                }}
                sort={sort}
                onSortChange={handleSortChange}
                data={sortedData}
                groupConfig={{
                  onDataStateChange: dataStateChange
                }}
                pageConfig={{
                  pageable: { pageSizes: [10, 25, 50] },
                  skip: gridState.dataState.skip,
                  take: gridState.dataState.take,
                  total: gridState.dataResult.total
                }}
                {...(gridState.dataState as any)}
                editField="inEdit"
              >
                <DataTableColumn
                  className={classes.editCell}
                  field="email"
                  title={formatMessage('account')}
                  cell={({ dataItem }: { dataItem: ModifiedLinkedAccount }) => (
                    <Cell data-testid="email-cell">
                      {dataItem.inEdit ? (
                        <TextField
                          fullWidth
                          name="email"
                          defaultValue={defaultFormValues.current?.email || ''}
                          inputRef={emailInput}
                          inputProps={{ style: { padding: 8 }, onChange: onEmailChange }}
                          data-testid="email-input"
                        />
                      ) : (
                        dataItem.email
                      )}
                    </Cell>
                  )}
                />
                <DataTableColumn
                  title={formatMessage('actions')}
                  sortable={false}
                  editable={false}
                  cell={({ dataItem }: { dataItem: ModifiedLinkedAccount }) => (
                    <Cell data-testid="action-cell">
                      {dataItem.inEdit ? (
                        <AddButton
                          disabled={!isEmailValid(defaultFormValues.current?.email || '')}
                          handle={actionButton}
                          onLink={onLink}
                        />
                      ) : (
                        <Button
                          className={classes.actionButton}
                          color="secondary"
                          onClick={() => onUnlink(dataItem)}
                          variant="contained"
                          data-testid="unlink-button"
                        >
                          {formatMessage('unlink')}
                        </Button>
                      )}
                    </Cell>
                  )}
                />
              </DataTable>
            )}
          </CardContent>
        </Card>
        <YesNoDialog
          title={formatMessage('unlink_title')}
          text={formatMessage('confirm_unlink', { email: linkedAccountEmail })}
          open={isConfirmDialogOpen}
          onClose={closeConfirmDialog}
          onConfirm={onDoUnlink}
        />
        {accountLinkedMessage.length > 0 && (
          <InfoDialog text={accountLinkedMessage} open={successfulDialog} onClose={closeSuccessDialog} />
        )}
      </>
    ),
    [
      classes,
      formatMessage,
      linkedAccountsAreLoading,
      sort,
      handleSortChange,
      sortedData,
      dataStateChange,
      gridState,
      isConfirmDialogOpen,
      closeConfirmDialog,
      onDoUnlink,
      accountLinkedMessage,
      successfulDialog,
      closeSuccessDialog,
      linkedAccountEmail,
      actionButton,
      emailInput,
      defaultFormValues,
      onLink,
      onUnlink,
      onEmailChange
    ]
  )
}

export default LinkedAccounts
