import React, { useCallback, useMemo, useState } from 'react'

import { useFormatMessage } from 'lib/localization'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { postAllowSender, postRedeliverMessage } from 'redux/features/mstore/mstoreSlice'
import { setErrorSnackBar } from 'redux/features/app/appSlice'
import { Action, MessageActionItem } from 'types/Messages'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { useUserDomainIdOverride } from 'lib/hooks/useUserDomainIdOverride'

export interface UseRedeliveryDialogLogic {
  deliverDialogForm: string
  setDeliverDialogForm: (e: React.ChangeEvent<HTMLInputElement>) => void
  userAllowlistEnabled: boolean
  handleDeliverDialogClick: () => void
}

export interface UseRedeliveryDialogLogicProps {
  onClose: () => void
}

const BASE_I18N_KEY = 'ess.message_log.action'

export const useRedeliveryDialogLogic = (props: UseRedeliveryDialogLogicProps): UseRedeliveryDialogLogic => {
  const overrideDomainId = useUserDomainIdOverride()
  const dispatch = useAppDispatch()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const [deliverDialogForm, setDeliverDialogFormChange] = useState('deliverOnly')
  const { accountPermissions, redeliveryQueueMessages, userDomainId } = useAppSelector(_store => ({
    accountPermissions: _store.settings.accountPermissions,
    redeliveryQueueMessages: _store.mstore.redeliveryQueueMessages,
    userDomainId: _store.auth.accessTokenObject?.domainId
  }))
  const { canDeliverAndAddSenderToAllowList, canDeliverBlockedQnMessages } = useMessageLogRights()

  const { onClose } = props

  const setDeliverDialogForm = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDeliverDialogFormChange(e.target.value)
  }, [])

  const handleDeliverDialogClick = useCallback(() => {
    // collect the redelivery data of each of the checked messages
    const composedMessages: MessageActionItem[] = []
    let blockedMessage = null
    redeliveryQueueMessages.forEach(message => {
      const isQuarantineDisabled =
        (message.status?.startsWith(Action.quarantined) || message.status?.startsWith(Action.allowedPartial)) &&
        accountPermissions?.defaultUserQuarDeliverEnabled !== 1
      const isBlockedDisabled =
        message.status?.startsWith(Action.blocked) && accountPermissions?.defaultUserDeliverEnabled !== 1

      const blockedState = isBlockedDisabled ? Action.blocked : null
      blockedMessage = isQuarantineDisabled ? Action.quarantined : blockedState

      if (blockedMessage && !canDeliverBlockedQnMessages) {
        return
      }

      const domainId = Number(overrideDomainId(message?.did))

      composedMessages.push({ messageId: message.mid, domainId })
    })

    if (blockedMessage && !canDeliverBlockedQnMessages) {
      dispatch(
        setErrorSnackBar({
          message: 'stop_delivery_admin',
          params: [formatMessage(blockedMessage)]
        })
      )
      onClose()
      return
    }
    const payload = {
      items: composedMessages,
      atpBypass: false
    }
    if (deliverDialogForm === 'deliverOnly') {
      dispatch(postRedeliverMessage(payload))
    } else if (deliverDialogForm === 'deliverAllowSender') {
      dispatch(postAllowSender(payload))
    }
    onClose()
  }, [
    redeliveryQueueMessages,
    canDeliverBlockedQnMessages,
    deliverDialogForm,
    onClose,
    accountPermissions?.defaultUserQuarDeliverEnabled,
    accountPermissions?.defaultUserDeliverEnabled,
    overrideDomainId,
    dispatch,
    formatMessage
  ])

  return useMemo(
    () => ({
      deliverDialogForm,
      setDeliverDialogForm,
      userAllowlistEnabled: canDeliverAndAddSenderToAllowList,
      handleDeliverDialogClick
    }),
    [deliverDialogForm, setDeliverDialogForm, canDeliverAndAddSenderToAllowList, handleDeliverDialogClick]
  )
}
