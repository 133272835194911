import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  drawer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '450px',
    padding: '24px'
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  row: {
    marginTop: theme.spacing(3)
  },
  recipientRow: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  actions: {
    marginTop: 'auto',
    alignSelf: 'flex-end'
  },
  circle: {
    borderRadius: 20,
    minWidth: 40,
    minHeight: 40
  },
  dayRow: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  roundedSelect: {
    borderRadius: 20
  },
  selected: {
    backgroundColor: colors.blue050,
    color: colors.blue600
  }
}))
