import { AnyAction, combineReducers } from '@reduxjs/toolkit'
import { splitReducer } from '@splitsoftware/splitio-redux'

import { RootState } from 'redux/toolkit/store'

// slices
import app, { INITIAL_STATE as appInitialState } from 'redux/features/app/appSlice'
import atp, { INITIAL_STATE as atpInitialState } from 'redux/features/atp/atpSlice'
import auth, { INITIAL_STATE as authInitialState, logout, resetAll } from 'redux/features/auth/authSlice'
import dataTables, { INITIAL_STATE as dataTablesInitialState } from 'redux/features/dataTables/dataTablesSlice'
import auditLog, { INITIAL_STATE as auditLogInitialState } from 'redux/features/auditLog/auditLogSlice'
import emailServer, { INITIAL_STATE as emailServerInitialState } from 'redux/features/emailServer/emailServerSlice'
import fpfn, { INITIAL_STATE as fpfnInitialState } from 'redux/features/fpfn/fpfnSlice'
import mstore, { INITIAL_STATE as mstoreInitialState } from 'redux/features/mstore/mstoreSlice'
import outboundIp, { INITIAL_STATE as outboundIpInitialState } from 'redux/features/outboundIp/outboundIpSlice'
import settings, { INITIAL_STATE as settingsInitialState } from 'redux/features/settings/settingsSlice'
import stats, { INITIAL_STATE as statsInitialState } from 'redux/features/stats/statsSlice'
import user, { INITIAL_STATE as userInitialState } from 'redux/features/user/userSlice'
import users, { INITIAL_STATE as usersInitialState } from 'redux/features/users/usersSlice'
import domains, { INITIAL_STATE as domainsInitialState } from 'redux/features/domains/domainsSlice'
import syslogs, { INITIAL_STATE as syslogInitialState } from 'redux/features/syslog/syslogSlice'
import azureAd, { INITIAL_STATE as azureAdInitialState } from 'redux/features/azureAd/azureAdSlice'
import encryption, { INITIAL_STATE as encryptionInitialState } from 'redux/features/encryption/encryptionSlice'
import reports, { INITIAL_STATE as reportsInitialState } from 'redux/features/reports/reportsSlice'
import ldap, { INITIAL_STATE as ldapInitialState } from 'redux/features/ldap/ldapSlice'
import graphql, { INITIAL_STATE as graphQlInitialState } from 'redux/features/graphql/graphqlSlice'
import oauth2, { INITIAL_STATE as oauth2InitialState } from 'redux/features/oauth2/oauth2Slice'
import banners, { INITIAL_STATE as bannersInitialState } from 'redux/features/banners/bannersSlice'
import pam, { INITIAL_STATE as pamInitialState } from 'redux/features/pam/pamSlice'

export const reducers = {
  app,
  auditLog,
  atp,
  auth,
  azureAd,
  dataTables,
  domains,
  emailServer,
  encryption,
  fpfn,
  graphql,
  ldap,
  mstore,
  outboundIp,
  pam,
  reports,
  settings,
  stats,
  syslogs,
  user,
  users,
  oauth2,
  splitio: splitReducer,
  banners
}

const combinedReducers = combineReducers({ ...reducers })

export default function reducersGenerator(state: RootState, action: AnyAction) {
  let appState = { ...state }

  // We should reset the store again after the logout is finished
  if (resetAll.match(action) || logout.fulfilled.match(action)) {
    appState = {
      app: {
        ...appInitialState,
        activePath: appState.app.activePath
      },
      auditLog: auditLogInitialState,
      atp: atpInitialState,
      auth: {
        ...authInitialState,
        logoutApiStatus: appState.auth.logoutApiStatus,
        switchToOldUiApiStatus: appState.auth.switchToOldUiApiStatus
      },
      dataTables: dataTablesInitialState,
      domains: domainsInitialState,
      emailServer: emailServerInitialState,
      fpfn: fpfnInitialState,
      mstore: mstoreInitialState,
      outboundIp: outboundIpInitialState,
      pam: pamInitialState,
      settings: settingsInitialState,
      stats: statsInitialState,
      user: userInitialState,
      users: usersInitialState,
      syslogs: syslogInitialState,
      azureAd: azureAdInitialState,
      encryption: encryptionInitialState,
      reports: reportsInitialState,
      ldap: ldapInitialState,
      graphql: graphQlInitialState,
      oauth2: oauth2InitialState,
      banners: bannersInitialState,

      // external reducers
      splitio: {
        ...appState.splitio,
        treatments: {}
      }
    }
  }

  return combinedReducers(appState, action)
}
