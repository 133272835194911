import { AppNames } from 'config/appConfig'
import { AppTypes } from 'types/AppTypes'

export default {
  USERS: {
    path: 'users',
    legacyPath: 'users',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  },
  USERS_LIST: {
    path: 'users/manage-users',
    legacyPath: 'users/manage_users',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  },
  USERS_DEFAULT_POLICY: {
    path: 'users/default-policy',
    legacyPath: 'users/default_policy',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  },
  USERS_ADD_UPDATE_USERS: {
    path: 'users/add-update-users',
    legacyPath: 'users/add_users',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.pdDomainId, AppTypes.domainWithPdDomainId]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  },
  USERS_QUARANTINE_NOTIFICATION: {
    path: 'users/quarantine',
    legacyPath: 'users/quarantine',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [
        AppTypes.admin,
        AppTypes.pdDomainId,
        AppTypes.helpdeskWithPdDomainId,
        AppTypes.domainWithPdDomainId
      ]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  },
  USERS_EMAIL_CONTINUITY: {
    path: 'users/email-continuity',
    legacyPath: 'users/email_continuity',
    appAccess: [AppNames.admin, AppNames.enduser],
    authorization: {
      appTypeAccess: [AppTypes.admin, AppTypes.pdDomainId, AppTypes.domainWithPdDomainId]
    },
    metadata: {
      sideMenuId: 'USERS'
    }
  }
}
