import { colors, makeStyles } from '@barracuda-internal/bds-core'

const styles = makeStyles(theme => ({
  logo: {
    width: 160,
    height: 65
  },
  floatRightContent: {
    marginLeft: 'auto'
  },
  validationButton: {
    marginRight: theme.spacing(0.5)
  },
  error: {
    color: colors.red500
  },
  success: {
    color: colors.green300
  },
  uploadButton: {
    marginRight: theme.spacing(0.5),
    '& input': {
      cursor: 'pointer',
      border: '1px solid red',
      display: 'block',
      position: 'absolute',
      opacity: 0,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
  },
  removeLogoButton: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(0.5)
  },
  textArea: {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    backgroundColor: 'transparent',
    '&:focus-visible': {
      borderColor: '#067CC1',
      borderWidth: '2px',
      outline: 'none'
    }
  }
}))

export default styles
