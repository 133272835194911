import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { ActionStatus, ActionTaken, MessageDirection, Reason, Search } from 'types/Messages'
import { getEndDate, getStartDate } from 'lib/mstore'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getSearch,
  MlogViewConfig,
  resetSearch,
  setSearchDate,
  setDateRange,
  setSearchDomainId,
  setSearchFilter,
  setSearchMessageDirection,
  setSearchTerm
} from 'redux/features/mstore/mstoreSlice'
import { reset as resetMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { useDirtyFormObjectCheck } from 'lib/useDirtyFormObjectCheck'

export interface UseMessageLogSearchBarLogic {
  searchTerm: Search
  handleFilterChange: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  handleSearch: () => void
  searchField: {
    handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClearTerm: () => void
    inputElement: MutableRefObject<null>
  }
  advancedSearch: {
    openAdvancedSearch: boolean
    setOpenAdvancedSearch: Dispatch<SetStateAction<boolean>>
  }
  dateField: {
    handleDateChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  }
  actionTakenField: {
    menuItems: string[]
    selectedItem: string
  }
  statusField: {
    menuItems: string[]
    selectedItem: string
  }
  reasonField: {
    menuItems: string[]
    selectedItem: string
  }
  disableSearch: boolean
  domainsField: {
    disabled: boolean
    menuItems: DomainsInDB[]
    selectedItem: string
    handleDomainChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
    searchAllEnabled: boolean
  }
  permissions: {
    canFilterAboutDomains: boolean
    canFilterAboutDirections: boolean
  }
  messageDirectionField: {
    selectedItem: MessageDirection
    handleMessageDirectionChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
  }
  viewConfig: MlogViewConfig
  changedSelects: Record<string, any>
}

export const useMessageLogSearchBarLogic = (): UseMessageLogSearchBarLogic => {
  const dispatch = useAppDispatch()
  const { searchFilter, searchTerm, isHistoryOpen, availableDomains, searchLogsAllDomainsLimit, viewConfig } =
    useAppSelector(_store => ({
      searchFilter: _store.mstore.searchFilter,
      searchTerm: _store.mstore.searchTerm,
      isHistoryOpen: _store.mstore.isMessageHistoryOpen,
      availableDomains: _store.user.availableDomains || [],
      searchLogsAllDomainsLimit: _store.settings.accountPermissions?.searchLogsAllDomainsLimit || 0,
      viewConfig: _store.mstore.viewConfig
    }))
  const {
    canSearchInAllDomains,
    canFilterAboutDomains,
    canFilterAboutDirections,
    canFilterAboutEmailContinuity,
    canFilterAboutQuarantinedAndEncryptedMessages,
    reasons,
    helpers: { isPdDomainIdSet }
  } = useMessageLogRights()

  const [{ changedSettings: changedSearch }, resetInitialSearch] = useDirtyFormObjectCheck(searchTerm)
  const [{ changedSettings: changedFilters }, resetInitialFilters] = useDirtyFormObjectCheck(searchFilter)

  useEffect(() => {
    resetInitialSearch(searchTerm)
    resetInitialFilters(searchFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changedSelects = useMemo(() => ({ ...changedSearch, ...changedFilters }), [changedSearch, changedFilters])

  const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false)
  const actionTakenMenuItems = useMemo(
    () =>
      Object.values(ActionTaken).reduce((all: string[], actionTaken: string) => {
        if (
          ((ActionTaken.quarantined === actionTaken || ActionTaken.quarantined) &&
            !canFilterAboutQuarantinedAndEncryptedMessages) ||
          (ActionTaken.emailContinuity === actionTaken && !canFilterAboutEmailContinuity)
        ) {
          return all
        }

        return [...all, actionTaken]
      }, []),
    [canFilterAboutEmailContinuity, canFilterAboutQuarantinedAndEncryptedMessages]
  )

  const inputElement = useRef(null)

  const handleClearBasicSearchTerm = useCallback(() => {
    dispatch(setSearchTerm({ ...searchTerm, search_str: '' }))
    dispatch(resetSearch({ hardReset: true }))
    dispatch(resetMessagesTable())

    dispatch(getSearch())
  }, [dispatch, searchTerm])

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch(setSearchTerm({ ...searchTerm, search_str: e.target.value }))
    },
    [dispatch, searchTerm]
  )

  const handleSearch = useCallback(() => {
    dispatch(getSearch())
  }, [dispatch])

  const handleDateChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const days = event.target.value as number

      dispatch(setDateRange({ days }))
      dispatch(setSearchDate({ start: getStartDate(days), end: getEndDate() }))

      handleSearch()
    },
    [dispatch, handleSearch]
  )

  const setFilterToEmailContinuity = useCallback(() => {
    dispatch(
      setSearchFilter({
        action: ActionTaken.emailContinuity,
        delivery_status: ActionStatus.spooled,
        reason: Reason.any
      })
    )
  }, [dispatch])

  const handleFilterChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      const { name, value } = event.target
      if (name === 'action' && value === ActionTaken.emailContinuity) {
        setFilterToEmailContinuity()
      } else if (name) {
        dispatch(setSearchFilter({ [name as string]: value }))
      }

      handleSearch()
    },
    [dispatch, handleSearch, setFilterToEmailContinuity]
  )

  const handleDomainChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      dispatch(setSearchDomainId(event.target.value === 'all' ? undefined : (event.target.value as string)))
      handleSearch()
    },
    [dispatch, handleSearch]
  )

  const handleMessageDirectionChange = useCallback(
    (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
      dispatch(setSearchMessageDirection(event.target.value as MessageDirection))
      handleSearch()
    },
    [dispatch, handleSearch]
  )

  return useMemo(
    () => ({
      searchTerm,
      handleSearch,
      handleFilterChange,
      searchField: {
        handleInputChange,
        handleClearTerm: handleClearBasicSearchTerm,
        inputElement
      },
      advancedSearch: {
        openAdvancedSearch,
        setOpenAdvancedSearch
      },
      dateField: {
        handleDateChange
      },
      actionTakenField: {
        menuItems: actionTakenMenuItems,
        selectedItem: searchFilter.action
      },
      statusField: {
        menuItems: Object.values(ActionStatus),
        selectedItem: searchFilter.delivery_status
      },
      reasonField: {
        menuItems: reasons,
        selectedItem: searchFilter.reason
      },
      disableSearch: isHistoryOpen,
      domainsField: {
        disabled: isPdDomainIdSet,
        menuItems: availableDomains,
        selectedItem: searchTerm.domain_id || 'all',
        handleDomainChange,
        searchAllEnabled:
          canSearchInAllDomains && availableDomains.length > 1 && availableDomains.length <= searchLogsAllDomainsLimit
      },
      messageDirectionField: {
        selectedItem: searchTerm.outbound,
        handleMessageDirectionChange
      },
      permissions: {
        canFilterAboutDomains,
        canFilterAboutDirections
      },
      viewConfig,
      changedSelects
    }),
    [
      isPdDomainIdSet,
      searchTerm,
      handleSearch,
      handleFilterChange,
      handleInputChange,
      handleClearBasicSearchTerm,
      openAdvancedSearch,
      handleDateChange,
      actionTakenMenuItems,
      searchFilter,
      isHistoryOpen,
      availableDomains,
      handleDomainChange,
      canSearchInAllDomains,
      canFilterAboutDomains,
      searchLogsAllDomainsLimit,
      handleMessageDirectionChange,
      canFilterAboutDirections,
      viewConfig,
      reasons,
      changedSelects
    ]
  )
}
