import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { TRACKING_EVENTS, trackMixpanelEvent } from 'lib/monitoring/monitoringService'
import appConfig from 'config/appConfig'
import { resetSelectedMessage } from 'redux/features/mstore/mstoreActions'

export interface State {
  isGetMessagePending: boolean
  isGetMessageSuccess: boolean
  getMessageError: string | undefined
}

export type MessageDetailsLogic = [State]

export const useMessageDetailsLogic = (): MessageDetailsLogic => {
  const dispatch = useAppDispatch()
  const { isGetMessagePending, isGetMessageSuccess, getMessageError } = useAppSelector(_store => ({
    isGetMessagePending: isPending(_store.mstore.getMessageApiStatus),
    isGetMessageSuccess: isSuccess(_store.mstore.getMessageApiStatus),
    getMessageError: getErrorMessage(_store.mstore.getMessageApiStatus)
  }))

  useEffect(() => {
    // disabled this event for endusers since caused significant spikes in our Mixpanel MTU
    if (!appConfig.APP.IS_ENDUSER) {
      trackMixpanelEvent(TRACKING_EVENTS.WEBUI.MESSAGE_DETAIL_PAGE_VIEW)
    }

    return () => {
      dispatch(resetSelectedMessage())
    }
  }, [dispatch])

  return useMemo(
    () => [{ isGetMessagePending, isGetMessageSuccess, getMessageError }],
    [isGetMessagePending, isGetMessageSuccess, getMessageError]
  )
}
