import React, { useCallback } from 'react'

import { isObject } from 'lodash'
import { useIntl } from 'react-intl'

import appConfig from 'config/appConfig'
import { useAppSelector } from 'redux/toolkit/hooks'
import MESSAGES_EN from 'config/i18n/en'
import MESSAGES_ES from 'config/i18n/es'
import MESSAGES_JP from 'config/i18n/jp'

import WIZARD_MESSAGES_EN from 'config/i18n/wizard_en'
import CUDASPT_MESSAGES_EN from 'config/i18n/cudaspt_en'
import LANGUAGE_NAMES from 'config/i18n/common'

export type MissedLocalization = {
  key: string
  value: string
}

interface Messages {
  [key: string]: Record<string, any> | string
}

interface MessagesCollection {
  [key: string]: Record<string, any>
}

export interface Localization {
  locale: string
  messages: Record<string, any>
}

const LOCALIZATIONS: { [key: string]: Messages } = {
  en_US: MESSAGES_EN,
  es_ES: MESSAGES_ES,
  jp_JP: MESSAGES_JP
}
// added the EN localization files to avoid missed translation errors
const DEFAULT_MESSAGES = { ...MESSAGES_EN, ...WIZARD_MESSAGES_EN, ...CUDASPT_MESSAGES_EN, ...LANGUAGE_NAMES }

const APP_NAMESPACE = 'ess'

function collectAppMessages(messages: Messages) {
  function stringifyKeys(messagesBlock: MessagesCollection, prefix = ''): MessagesCollection {
    const updatedPrefix = prefix === `${appConfig.NAME}.` ? `${prefix}${APP_NAMESPACE}.` : prefix

    return Object.keys(messagesBlock).reduce((all, messageId) => {
      if (isObject(messagesBlock[messageId])) {
        return {
          ...all,
          ...stringifyKeys(messagesBlock[messageId], `${updatedPrefix}${messageId}.`)
        }
      }

      return {
        ...all,
        [`${updatedPrefix}${messageId}`]: messagesBlock[messageId]
      }
    }, {})
  }

  const collectedMessages = Object.keys(messages).reduce((all, messageId) => {
    if (messageId.includes(appConfig.NAME.toLocaleLowerCase())) {
      return {
        ...all,
        [messageId.replace(`${appConfig.NAME}.`, '')]: messages[messageId]
      }
    }

    return all
  }, {} as Record<string, any>)

  return stringifyKeys(collectedMessages)
}

export const useFormatMessage = (baseKey: string) => {
  const { language } = useAppSelector(_store => ({
    language: _store.app.language
  }))
  const intl = useIntl()

  const formatMessage = useCallback(
    (path: string, data = {}) =>
      intl.formatMessage(
        {
          id: `${baseKey}.${path}`
        },
        {
          b: (txt: any): any => <b>{txt}</b>,
          i: (txt: any): any => <i>{txt}</i>,
          ...data
        }
      ),
    // language is needed in the dependency array to force creating a new formatMessage function when language is changing in redux
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseKey, intl, language]
  )
  return formatMessage
}

export const useErrorFormatMessage = () => useFormatMessage('ess.app.error')

export const collectMissedLocalizationKeys = () => {
  const enMessages = collectAppMessages({ ...MESSAGES_EN, ...WIZARD_MESSAGES_EN, ...CUDASPT_MESSAGES_EN })
  const tableDivider = { key: '-'.repeat(50), value: '-'.repeat(50) }

  Object.keys(LOCALIZATIONS).forEach((lang: string) => {
    if (lang !== 'en_US') {
      const langInstance = { key: (LANGUAGE_NAMES.ess_language.language as any)[lang], value: lang }
      const missedTranslates: MissedLocalization[] = []
      const localizationMessages = collectAppMessages(LOCALIZATIONS[lang])

      Object.keys(collectAppMessages(enMessages)).forEach((enKey: string) => {
        // skip the cudaspt entries
        if (!Object.prototype.hasOwnProperty.call(localizationMessages, enKey) && !enKey.includes('cudaspt')) {
          missedTranslates.push({ key: enKey, value: enMessages[enKey] as any })
        }
      })

      if (missedTranslates.length && window) {
        // eslint-disable-next-line
        window.__egd__ = {
          // eslint-disable-next-line
          getMissedTranslates: () => console.table([langInstance, tableDivider, ...missedTranslates])
        }
      }
    }
  })
}

export default function initLocalization(localization: string): Localization {
  if (appConfig.ENVIRONMENT.IS_DEV || appConfig.ENVIRONMENT.IS_GT3) {
    collectMissedLocalizationKeys()
  }

  return {
    messages: { ...collectAppMessages(DEFAULT_MESSAGES), ...collectAppMessages(LOCALIZATIONS[localization]) },
    locale: localization.replace('_', '-')
  }
}
