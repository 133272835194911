import { useMemo, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, GeoIPAddressExemptPolicy } from 'types/Settings'
import routesConfig from 'lib/routesConfig'
import { updateAccountSettings, updateDomainSettings } from 'redux/features/settings/settingsSlice'

export interface State {
  geoipAddressExempt: GeoIPAddressExemptPolicy[]
}

export interface EventHandlers {
  onAddItem: (item: GeoIPAddressExemptPolicy) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
}

export type UseGeoPoliciesIPAddressExemptLogic = [State, EventHandlers]

export const useGeoPoliciesIPAddressExemptLogic = (): UseGeoPoliciesIPAddressExemptLogic => {
  const dispatch = useAppDispatch()

  const { accessTokenObject, accountSettings, domainSettings } = useAppSelector(_stores => ({
    accessTokenObject: _stores.auth.accessTokenObject,
    accountSettings: _stores.settings.accountSettings,
    domainSettings: _stores.settings.domainSettings
  }))

  const geoipAddressExempt = useMemo(() => {
    const settings = accessTokenObject?.pdDomainId ? domainSettings : accountSettings
    return settings.geoip_exempt ? (JSON.parse(settings.geoip_exempt as string) as GeoIPAddressExemptPolicy[]) : []
  }, [accessTokenObject, domainSettings, accountSettings])

  const updateData = useCallback(
    (exemptions: GeoIPAddressExemptPolicy[]) => {
      if (accessTokenObject?.pdDomainId) {
        dispatch(
          updateDomainSettings({
            domainId: accessTokenObject.pdDomainId,
            settings: { [AvailableSettings.GEOIP_IP_ADDRESS_EXEMPT_POLICIES]: exemptions }
          })
        )
      } else {
        dispatch(
          updateAccountSettings({ settings: { [AvailableSettings.GEOIP_IP_ADDRESS_EXEMPT_POLICIES]: exemptions } })
        )
      }
    },
    [dispatch, accessTokenObject]
  )

  const onAddItem = useCallback(
    (item: GeoIPAddressExemptPolicy) => {
      const exemptions = [...geoipAddressExempt, item]
      updateData(exemptions)
    },
    [geoipAddressExempt, updateData]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const exemptions = geoipAddressExempt.filter(entry => entry.id !== id)
      updateData(exemptions)
    },
    [geoipAddressExempt, updateData]
  )

  const onBulkEdit = useCallback(() => {
    routesConfig.INBOUND_SETTINGS_GEOIP_IPADDRESS_EXEMPT_BULK_EDIT.goto()
  }, [])

  return useMemo(
    () => [
      {
        geoipAddressExempt
      },
      {
        onAddItem,
        onRemoveItem,
        onBulkEdit
      }
    ],
    [geoipAddressExempt, onAddItem, onRemoveItem, onBulkEdit]
  )
}
