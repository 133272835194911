import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(_ => ({
  overlay: {
    position: 'absolute',
    zIndex: 1000000,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: 0.8,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
