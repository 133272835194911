import { createSlice } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'
import { ErrorItem, SenderIpEntryExtended } from 'types/SenderIp'

import {
  addOutboundIp,
  bulkEditOutboundIps,
  getOutboundIps,
  removeOutboundIp
} from 'redux/features/outboundIp/outboundIpApiThunks'

export interface OutboundIpState {
  getOutboundIpApiStatus: ApiStatus
  removeOutboundIpApiStatus: ApiStatus
  addOutboundIpApiStatus: ApiStatus
  bulkEditOutboundIpApiStatus: ApiStatus
  outboundIps: SenderIpEntryExtended[]
}

// initialState
export const INITIAL_STATE: OutboundIpState = {
  getOutboundIpApiStatus: inIdle,
  removeOutboundIpApiStatus: inIdle,
  addOutboundIpApiStatus: inIdle,
  bulkEditOutboundIpApiStatus: inIdle,
  outboundIps: []
}

/* eslint-disable no-param-reassign */
export const outboundIpSlice = createSlice({
  name: 'OUTBOUND_IP',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // getOutboundIps
      .addCase(getOutboundIps.pending, state => {
        state.getOutboundIpApiStatus = inProgress
      })
      .addCase(getOutboundIps.fulfilled, (state, action) => {
        state.getOutboundIpApiStatus = successResponse
        state.outboundIps = action.payload.results
      })
      .addCase(getOutboundIps.rejected, (state, action) => {
        state.getOutboundIpApiStatus = failedResponse(action.payload)
      })

      // removeOutboundIps
      .addCase(removeOutboundIp.pending, state => {
        state.removeOutboundIpApiStatus = inProgress
      })
      .addCase(removeOutboundIp.fulfilled, state => {
        state.removeOutboundIpApiStatus = successResponse
      })
      .addCase(removeOutboundIp.rejected, (state, action) => {
        state.removeOutboundIpApiStatus = failedResponse(action.payload)
      })

      // addOutboundIps
      .addCase(addOutboundIp.pending, state => {
        state.addOutboundIpApiStatus = inProgress
      })
      .addCase(addOutboundIp.fulfilled, state => {
        state.addOutboundIpApiStatus = successResponse
      })
      .addCase(addOutboundIp.rejected, (state, action) => {
        if (typeof action.payload === 'string') {
          state.addOutboundIpApiStatus = failedResponse(action.payload)
        } else {
          state.addOutboundIpApiStatus = failedResponse(action.payload?.message)
        }
      })

      // bulkEditOutboundIps
      .addCase(bulkEditOutboundIps.pending, state => {
        state.bulkEditOutboundIpApiStatus = inProgress
      })
      .addCase(bulkEditOutboundIps.fulfilled, state => {
        state.bulkEditOutboundIpApiStatus = successResponse
      })
      .addCase(bulkEditOutboundIps.rejected, (state, action) => {
        if (typeof action.payload === 'string') {
          state.bulkEditOutboundIpApiStatus = failedResponse(action.payload)
        } else {
          state.bulkEditOutboundIpApiStatus = failedResponse(action.payload?.message)
        }
      })
  }
})
/* eslint-enable no-param-reassign */

export const { reset } = outboundIpSlice.actions

export { getOutboundIps, removeOutboundIp, addOutboundIp, bulkEditOutboundIps }

export default outboundIpSlice.reducer
