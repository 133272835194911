import { colors, makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  titleContainer: {
    marginTop: theme.spacing(1),
    height: theme.spacing(7),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  title: {
    color: colors.scaleGray900
  },
  subtitle: {
    color: colors.scaleGray600
  },
  helpIcon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    width: theme.spacing(2.5),
    marginTop: theme.spacing(0.25),
    color: colors.blue600
  }
}))
