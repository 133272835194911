import React, { Dispatch, SetStateAction, useMemo } from 'react'

import { Radio, RadioGroup, RadioLabel } from '@barracuda-internal/bds-core'

import { useFormatMessage } from 'lib/localization'
import { HelperText } from 'components/libs/layout/pages/HelperText'
import { RadioButtonStates } from 'components/pages/users/usersTypes'

const BASE_I18N_KEY = 'ess.layout'

export type InlinRadioGroupProps = {
  name: string
  helperText?: string
  value: any
  onChange: ((newValue: string) => void) | Dispatch<SetStateAction<RadioButtonStates>>
  disabled: boolean
  values?: any[]
  labels?: (string | undefined)[]
}

export type AdditionalButtonConfig = [string, any]

export const InlineRadioGroup = ({
  name,
  helperText,
  value,
  onChange,
  disabled,
  values,
  labels
}: InlinRadioGroupProps) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  const additionalRadioButtons: AdditionalButtonConfig[] = useMemo(() => {
    if (!!labels && labels.length > 2 && !!values && values.length > 2) {
      const additionalLabels = labels.slice(2)
      const additionalValues = values.slice(2)
      return additionalLabels.reduce(
        (prev: AdditionalButtonConfig[], currentLabel: string | undefined, currentIndex) => {
          const currentValue = additionalValues[currentIndex]
          if (currentLabel && currentValue) {
            return [...prev, [currentLabel, currentValue]]
          }

          return prev
        },
        []
      )
    }

    return []
  }, [labels, values])

  return useMemo(
    () => (
      <>
        <RadioGroup row name={name} value={value} data-testid={name} onChange={(e: any) => onChange(e.target.value)}>
          <RadioLabel
            disabled={disabled}
            value={values?.[0] || RadioButtonStates.on}
            control={<Radio color="primary" />}
            label={labels?.[0] || formatMessage('yes')}
          />
          <RadioLabel
            disabled={disabled}
            value={values?.[1] || RadioButtonStates.off}
            control={<Radio color="primary" />}
            label={labels?.[1] || formatMessage('no')}
          />
          {additionalRadioButtons.map((config: AdditionalButtonConfig) => (
            <RadioLabel
              key={config[0]}
              disabled={disabled}
              label={config[0]}
              value={config[1]}
              control={<Radio color="primary" />}
            />
          ))}
        </RadioGroup>
        {!!helperText && <HelperText>{helperText}</HelperText>}
      </>
    ),
    [formatMessage, name, helperText, value, onChange, disabled, values, labels, additionalRadioButtons]
  )
}
