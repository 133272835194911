import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  paginationContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  paginationActions: {
    display: 'flex',
    alignItems: 'center'
  },
  paginationChip: {
    margin: `${theme.spacing(0.5)}px !important`,
    backgroundColor: theme.palette.grey[100]
  },
  dropDownArrowIcon: {
    color: theme.palette.text.secondary
  },
  paginationText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize
  }
}))
