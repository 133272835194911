import { useMemo } from 'react'

import useUserRights, { UserRole } from 'components/libs/userRights/useUserRights'
import { ALL_TOPICS, GROUP_TITLE } from 'components/pages/support/config'
import { SupportTopic, SupportTopicConfig, SupportTopicPermission } from 'types/Support'
import { minimumUserRoleRequirement } from '../userRights'

export interface ValidatedRights {
  canAccessSupport: boolean
  canAccessConfiguration: boolean
  canAccessPartner: boolean
  accessibleTopics: SupportTopicConfig[]
}

export type UseSupportRights = ValidatedRights

export enum UserRights {
  CAN_ACCESS_SUPPORT = UserRole.ADMIN_USER + UserRole.ACCOUNT_USER,
  CAN_ACCESS_CONFIGURATION = UserRole.ADMIN_USER + UserRole.ACCOUNT_USER
}

export const useSupportRights = (): UseSupportRights => {
  const { validatedUserRights, isDefaultPortalId, isCplAccount, userHasRight, isUserType } = useUserRights([
    UserRights.CAN_ACCESS_SUPPORT,
    UserRights.CAN_ACCESS_CONFIGURATION,
    UserRole.HELPDESK_ROLE
  ])
  const [canAccessSupport, canAccessConfiguration, isHelpDeskUser] = validatedUserRights

  const canAccessPartner = useMemo(() => isDefaultPortalId && !isHelpDeskUser, [isDefaultPortalId, isHelpDeskUser])

  const accessibleTopics = useMemo(() => {
    const hasTopicAccess = (topic: SupportTopicPermission) =>
      (topic.cpl === undefined || topic.cpl === isCplAccount) &&
      (topic.minimumRole === undefined || userHasRight(minimumUserRoleRequirement(topic.minimumRole)))

    const filtered = ALL_TOPICS.reduce((prev, current) => {
      // If user has no right to the parent topic do not return it
      if (!hasTopicAccess(current)) {
        return prev
      }

      // If user has no right to child remove it from the list
      const filteredChildren = current.topics.filter(topic => hasTopicAccess(topic))

      if (current.title === GROUP_TITLE.endUser && isUserType(UserRole.USER)) {
        // eslint-disable-next-line no-param-reassign
        current.title = null
      }

      prev.push({
        ...current,
        topics: filteredChildren
      })

      return prev
    }, [] as SupportTopicConfig[])

    return filtered
  }, [userHasRight, isCplAccount, isUserType])

  return useMemo(
    () => ({
      canAccessSupport,
      canAccessPartner,
      canAccessConfiguration,
      accessibleTopics
    }),
    [canAccessSupport, canAccessPartner, canAccessConfiguration, accessibleTopics]
  )
}
