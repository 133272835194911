export interface ReducerParams {
  state: any
  payload: any
}

export type HardResetReport = boolean | undefined

function reducerHandlers({ COLUMNS_CONFIG = {} }: { COLUMNS_CONFIG: any }) {
  function validateSortConfig(config: any, state: any) {
    if (config.sort) {
      const [newSortConfig] = config.sort
      const [stateSortConfig] = state.sort
      const columnConfig = COLUMNS_CONFIG[newSortConfig.field]

      if (columnConfig?.isDescOrderFirst && newSortConfig?.field !== stateSortConfig?.field) {
        return {
          ...config,
          sort: [
            {
              ...newSortConfig,
              dir: 'desc'
            }
          ]
        }
      }
    }

    return config
  }

  return {
    update: ({ state, payload }: ReducerParams) => ({
      ...state,
      ...validateSortConfig(payload.config, state)
    })
  }
}

export function resetReport(report: any, doHardReset: HardResetReport): any | undefined {
  if (report) {
    if (doHardReset) {
      return undefined
    }
    return {
      ...report,
      report: {
        ...report.report,
        data: []
      }
    }
  }

  return undefined
}

export default reducerHandlers
