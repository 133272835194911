const ipAddressToInt = (a: string) => Number(a.split('.').reduce((acc, byte) => acc + byte.padStart(3, '0'), ''))

export const sortIpAddress = (a: string, b: string, reverse: boolean) =>
  reverse ? ipAddressToInt(b) - ipAddressToInt(a) : ipAddressToInt(a) - ipAddressToInt(b)

export const sortText = (a: string, b: string, reverse: boolean) => {
  if (a < b) {
    return reverse ? 1 : -1
  }
  if (a > b) {
    return reverse ? -1 : 1
  }

  return 0
}
