import { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { flow } from 'lodash'
import { EditDomainForm, EditDomainFormErrors } from 'components/pages/domains/editDomain/useEditDomainForm'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isSettingValue, SettingValue } from 'types/Settings'
import appConfig from 'config/appConfig'
import { FormField } from 'lib/useFormField'
import { postLdapSyncNow, postLdapTest } from 'redux/features/ldap/ldapSlice'
import { isPending } from 'redux/toolkit/api'
import { formatDate } from 'lib/datetime'
import { LdapSettings } from 'types/ldap'

type InputChangeHandler = ChangeEventHandler<HTMLInputElement>
type RadioChangeHandler = (value: string) => void

export interface State {
  ldapAuth: string
  ldapSync: string
  ldapAttributes: string
  ldapAuthFilter: string
  ldapBaseDn: string
  ldapDomainLimited: string
  ldapHost: string
  ldapNewPassword: string
  ldapPort: string
  ldapUserFilter: string
  ldapSsl: string
  ldapUsername: string
  ldapTestingEmailAddress: string
  errors: EditDomainFormErrors
  isLdapSyncPending: boolean
  inProgress: boolean
  ldapLastSync: string
  isLdapTestDialogOpen: boolean
  isAdvancedOptionsCollapsed: boolean
}

export interface EventHandlers {
  onChangeLdapAuth: RadioChangeHandler
  onChangeLdapSync: RadioChangeHandler
  onChangeLdapAttributes: InputChangeHandler
  onChangeLdapAuthFilter: InputChangeHandler
  onChangeLdapBaseDn: InputChangeHandler
  onChangeLdapDomainLimited: RadioChangeHandler
  onChangeLdapHost: InputChangeHandler
  onChangeLdapNewPassword: InputChangeHandler
  onChangeLdapPort: InputChangeHandler
  onChangeLdapUserFilter: InputChangeHandler
  onChangeLdapSsl: RadioChangeHandler
  onChangeLdapUsername: InputChangeHandler
  onChangeLdapTestingEmailAddress: InputChangeHandler
  onTestSettings: () => void
  onSyncNow: () => void
  onCloseLdapTestDialog: () => void
  onToggleAdvancedOptions: () => void
}

export type LdapSettingsLogic = [State, EventHandlers]

export const useLdapSettingsLogic = (form: EditDomainForm): LdapSettingsLogic => {
  const dispatch = useAppDispatch()
  const [isLdapTestDialogOpen, setIsLdapTestDialogOpen] = useState(false)
  const { domain, isLdapSyncPending } = useAppSelector(_store => ({
    domain: _store.domains.domain,
    isLdapSyncPending: isPending(_store.ldap.api.postLdapSyncNow)
  }))
  const [isAdvancedOptionsCollapsed, setIsAdvancedOptionsCollapsed] = useState(true)
  const [
    {
      domainId,
      ldapAuth,
      ldapSync,
      ldapAttributes,
      ldapAuthFilter,
      ldapBaseDn,
      ldapDomainLimited,
      ldapHost,
      ldapNewPassword,
      ldapPort,
      ldapUserFilter,
      ldapSsl,
      ldapUsername,
      ldapTestingEmailAddress,
      errors,
      inProgress
    },
    { clearError }
  ] = form

  const ldapLastSync = useMemo(() => {
    if (!domain?.ldapLastSync) {
      return ''
    }
    return formatDate(new Date((domain?.ldapLastSync || 0) * 1000), appConfig.DATETIME.LAST_LDAP_SYNC_FORMAT)
  }, [domain?.ldapLastSync])

  const onChangeSettingValueField = useCallback(
    (field: FormField<SettingValue | undefined>) => (value: string) => {
      if (isSettingValue(value)) {
        field.setValue(value)
      }
    },
    []
  )

  const onChangeLdapSync: RadioChangeHandler = useMemo(
    () => flow([onChangeSettingValueField(ldapSync), () => clearError('ldapSyncWithUserAutoAdd')]),
    [clearError, ldapSync, onChangeSettingValueField]
  )

  const onChangeStringField = useCallback(
    (field: FormField<string | undefined>) => (e: ChangeEvent<HTMLInputElement>) => {
      field.setValue(e.target.value)
    },
    []
  )

  const onChangeNumberField = useCallback(
    (field: FormField<number | undefined>) => (e: ChangeEvent<HTMLInputElement>) => {
      field.setValue(parseInt(e.target.value, 10))
    },
    []
  )

  const onTestSettings = useCallback(() => {
    const ldapSettings: LdapSettings = {
      host: ldapHost.value || '',
      port: ldapPort.value || 389,
      ssl: ldapSsl.value || SettingValue.DISABLED,
      bindDn: ldapUsername.value || '',
      baseDn: ldapBaseDn.value || '',
      attrs: ldapAttributes.value || '',
      domainLimited: ldapDomainLimited.value,
      userFilter: ldapUserFilter.value,
      authFilter: ldapAuthFilter.value || '',
      bindPwE: ldapNewPassword.value || undefined
    }
    const testingEmail = ldapTestingEmailAddress.value
    dispatch(postLdapTest({ domainId, ldapSettings, testingEmail }))
    setIsLdapTestDialogOpen(true)
  }, [
    dispatch,
    domainId,
    ldapAttributes.value,
    ldapAuthFilter.value,
    ldapBaseDn.value,
    ldapDomainLimited.value,
    ldapHost.value,
    ldapNewPassword.value,
    ldapPort.value,
    ldapSsl.value,
    ldapTestingEmailAddress.value,
    ldapUserFilter.value,
    ldapUsername.value
  ])

  const onSyncNow = useCallback(() => {
    dispatch(postLdapSyncNow({ domainId }))
  }, [dispatch, domainId])

  const inputValue = useCallback((value: any): string => {
    switch (true) {
      case typeof value === 'undefined' || value === null:
        return ''
      default:
        return `${value}`
    }
  }, [])

  const onCloseLdapTestDialog = useCallback(() => setIsLdapTestDialogOpen(false), [])

  const onToggleAdvancedOptions = useCallback(() => {
    setIsAdvancedOptionsCollapsed(!isAdvancedOptionsCollapsed)
  }, [isAdvancedOptionsCollapsed])

  useEffect(() => {
    if (domain) {
      ldapAuth.reset(domain?.ldapAuth || SettingValue.DISABLED)
      ldapSync.reset(domain?.ldapSync || SettingValue.DISABLED)
      ldapAttributes.reset(domain?.ldap?.attrs || '')
      ldapAuthFilter.reset(domain?.ldap?.authFilter || appConfig.LDAP.DEFAULT_AUTH_FILTER)
      ldapBaseDn.reset(domain?.ldap?.baseDn || '')
      ldapDomainLimited.reset(domain?.ldap?.domainLimited || SettingValue.DISABLED)
      ldapHost.reset(domain?.ldap?.host || '')
      ldapNewPassword.reset('')
      ldapPort.reset(domain?.ldap?.port)
      ldapUserFilter.reset(domain?.ldap?.userFilter || '')
      ldapSsl.reset(domain?.ldap?.ssl || SettingValue.DISABLED)
      ldapUsername.reset(domain?.ldap?.bindDn || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain])

  return useMemo(
    () => [
      {
        ldapAuth: inputValue(ldapAuth.value),
        ldapSync: inputValue(ldapSync.value),
        ldapAttributes: inputValue(ldapAttributes.value),
        ldapAuthFilter: inputValue(ldapAuthFilter.value),
        ldapBaseDn: inputValue(ldapBaseDn.value),
        ldapDomainLimited: inputValue(ldapDomainLimited.value),
        ldapHost: inputValue(ldapHost.value),
        ldapNewPassword: inputValue(ldapNewPassword.value),
        ldapPort: inputValue(ldapPort.value),
        ldapUserFilter: inputValue(ldapUserFilter.value),
        ldapSsl: inputValue(ldapSsl.value),
        ldapUsername: inputValue(ldapUsername.value),
        ldapTestingEmailAddress: inputValue(ldapTestingEmailAddress.value),
        errors,
        isLdapSyncPending,
        inProgress,
        ldapLastSync,
        isLdapTestDialogOpen,
        isAdvancedOptionsCollapsed
      },
      {
        onChangeLdapAuth: onChangeSettingValueField(ldapAuth),
        onChangeLdapSync,
        onChangeLdapAttributes: onChangeStringField(ldapAttributes),
        onChangeLdapAuthFilter: onChangeStringField(ldapAuthFilter),
        onChangeLdapBaseDn: onChangeStringField(ldapBaseDn),
        onChangeLdapDomainLimited: onChangeSettingValueField(ldapDomainLimited),
        onChangeLdapHost: onChangeStringField(ldapHost),
        onChangeLdapNewPassword: onChangeStringField(ldapNewPassword),
        onChangeLdapPort: onChangeNumberField(ldapPort),
        onChangeLdapUserFilter: onChangeStringField(ldapUserFilter),
        onChangeLdapSsl: onChangeSettingValueField(ldapSsl),
        onChangeLdapUsername: onChangeStringField(ldapUsername),
        onChangeLdapTestingEmailAddress: onChangeStringField(ldapTestingEmailAddress),
        onTestSettings,
        onSyncNow,
        onCloseLdapTestDialog,
        onToggleAdvancedOptions
      }
    ],
    [
      inputValue,
      ldapAuth,
      ldapSync.value,
      ldapAttributes,
      ldapAuthFilter,
      ldapBaseDn,
      ldapDomainLimited,
      ldapHost,
      ldapNewPassword,
      ldapPort,
      ldapUserFilter,
      ldapSsl,
      ldapUsername,
      ldapTestingEmailAddress,
      errors,
      isLdapSyncPending,
      inProgress,
      ldapLastSync,
      isLdapTestDialogOpen,
      isAdvancedOptionsCollapsed,
      onChangeSettingValueField,
      onChangeLdapSync,
      onChangeStringField,
      onChangeNumberField,
      onTestSettings,
      onSyncNow,
      onCloseLdapTestDialog,
      onToggleAdvancedOptions
    ]
  )
}
