import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { validateApiError, ApiRejectResponse } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { PublicAppSettings } from 'types/Settings'

export interface PostMixpanelPayload {
  eventName: string
  metadata: Record<string, unknown>
}

export const postMixpanelEvent = createAsyncThunk<undefined, PostMixpanelPayload, ApiRejectResponse>(
  'APP/postMixpanelPayload',
  async (payload, { rejectWithValue }) =>
    // return undefined until mixpanel api is implemented
    undefined

  // try {
  //   const resp = await restClient(apiRoutes.MIXPANEL, {
  //     data: { payload }
  //   })

  //   return resp.data
  // } catch (e) {
  //   return rejectWithValue(validateApiError(e))
  // }
)

export const getPublicAppSettings = createAsyncThunk<PublicAppSettings, void, ApiRejectResponse>(
  'APP/getPublicAppSettings',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_PUBLIC_APP_SETTINGS)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
