import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { inIdle, inProgress, successResponse, failedResponse, ApiStatus } from 'redux/toolkit/api'

import { exportAuditLog, getAuditLogSearch, AuditLogResult } from 'redux/features/auditLog/auditLogApiThunks'
import { AuditLogSearch } from 'types/AuditLog'
import { HardResetReport, resetReport } from 'redux/features/dataTables/reduceHandlers'
import { getStartDate, getEndDate } from 'lib/mstore'
import { InitEventTypes } from 'components/pages/auditLog/auditLogFilter/useAuditLogFilterLogic'
import { GRID_COLUMNS } from '../dataTables/auditLog/auditLogSlice'

export interface AuditLogState {
  getAuditLogStatus: ApiStatus
  exportAuditLogStatus: ApiStatus

  loadedSearchOffsets: number[]
  searchTerm: AuditLogSearch
  search: AuditLogResult | Record<string, never>
}

// Set up initial state
export const INITIAL_STATE: AuditLogState = {
  getAuditLogStatus: inIdle,
  exportAuditLogStatus: inIdle,

  loadedSearchOffsets: [],
  searchTerm: {
    searchStr: '',
    startDate: getStartDate(30),
    endDate: getEndDate(),
    sort: [{ field: GRID_COLUMNS.DATE, dir: 'desc' }],
    eventTypes: InitEventTypes
  },
  search: {}
}

/* eslint-disable no-param-reassign */
export const auditLogSlice = createSlice({
  name: 'AUDIT_LOG',
  initialState: INITIAL_STATE,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<Partial<AuditLogSearch>>) => {
      state.searchTerm = { ...state.searchTerm, ...action.payload }
    },
    setSearchStartDate: (state, action: PayloadAction<number>) => {
      state.searchTerm.startDate = action.payload
    },
    setSearchEndDate: (state, action: PayloadAction<number>) => {
      state.searchTerm.endDate = action.payload
    },
    resetSearch: (state, action: PayloadAction<HardResetReport>) => {
      state.search = resetReport(state.searchTerm, action.payload) || INITIAL_STATE.search
      state.loadedSearchOffsets = INITIAL_STATE.loadedSearchOffsets
      state.getAuditLogStatus = inIdle
    },
    reset: () => ({
      ...INITIAL_STATE
    })
  },
  extraReducers: builder => {
    builder
      // auditLogSearch
      .addCase(getAuditLogSearch.pending, state => {
        state.getAuditLogStatus = inProgress
      })
      .addCase(getAuditLogSearch.fulfilled, (state, action) => {
        state.getAuditLogStatus = successResponse
        state.search = action.payload
      })
      .addCase(getAuditLogSearch.rejected, (state, action) => {
        state.getAuditLogStatus = failedResponse(action.payload)
      })

      // exportAuditLog
      .addCase(exportAuditLog.pending, state => {
        state.exportAuditLogStatus = inProgress
      })
      .addCase(exportAuditLog.fulfilled, state => {
        state.exportAuditLogStatus = successResponse
      })
      .addCase(exportAuditLog.rejected, (state, action) => {
        state.exportAuditLogStatus = failedResponse(action.payload)
      })
  }
})
/* eslint-enable no-param-reassign */

export const { setSearchTerm, setSearchStartDate, setSearchEndDate, resetSearch, reset } = auditLogSlice.actions

export { getAuditLogSearch, exportAuditLog }

export default auditLogSlice.reducer
