import { isArray, camelCase } from 'lodash'

export default function camelizeKeys(obj: any): any {
  if (isArray(obj)) {
    return obj.map(v => camelizeKeys(v))
  }
  if (obj && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key])
      }),
      {}
    )
  }

  return obj
}
