import { useEffect } from 'react'

export const useFileReader = () => {
  const instances: FileReader[] = []

  useEffect(() => () => {
    let instance = instances.pop()
    while (instance) {
      instance.abort()
      instance.onload = () => {
        // Remove any previously assigned onload handler
      }
      instance = instances.pop()
    }
  })

  return () => {
    const fileReader = new FileReader()
    instances.push(fileReader)
    return fileReader
  }
}
