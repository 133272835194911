import { createAction } from '@reduxjs/toolkit'
import { EmailServer } from 'types/domains'

export const insertDomainMailServer = createAction<EmailServer>('DOMAINS/insertDomainMailServer')
export const removeDomainMailServer = createAction<string>('DOMAINS/removeDomainMailServer')
export const updateDomainMailServer = createAction<EmailServer>('DOMAINS/updateDomainMailServer')
export const clearDomainUniqueSettings = createAction<string>('DOMAINS/clearDomainUniqueSettings')
export const resetAddDomain = createAction<void>('DOMAINS/resetAddDomain')
export const resetDeleteDomain = createAction<void>('DOMAINS/resetDeleteDomain')
export const resetManageDomain = createAction<void>('DOMAINS/resetManageDomain')
export const resetVerifyDomain = createAction<void>('DOMAINS/resetVerifyDomain')
export const resetGetUnaliasedDomainNames = createAction<void>('DOMAINS/resetGetUnaliasedDomainNames')
export const resetSaveDomainSettings = createAction<void>('DOMAINS/resetSaveDomainSettings')
export const resetGetDomain = createAction<void>('DOMAINS/resetGetDomain')
export const resetGetDomainForVerification = createAction<void>('DOMAINS/resetGetDomainForVerification')
