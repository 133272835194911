import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { EGDIdentity, IdToken, OAuth2Exceptions, OAuth2ProcessDataPublic } from 'types/oauth2'

export type GetCurrentSessionRequest = void
export interface GetCurrentSessionResponse {
  idToken: IdToken
  idTokenJwt: string
  egdIdentity: EGDIdentity
}

export const getCurrentSession = createAsyncThunk<
  GetCurrentSessionResponse,
  GetCurrentSessionRequest,
  ApiRejectResponse
>('OAUTH2/getCurrentSession', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.OAUTH2_GET_CURRENT_SESSION, {
      headers: {
        isToolkitCompatible: true
      }
    })
    if (typeof resp.data === 'object' && resp.data !== null && Object.keys(resp.data).length === 0) {
      // This was a null response from API that got converted to empty object
      return null
    }
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export interface CreateAuthenticationProcessRequest {
  authProcessId: string
  queryString?: string
  preferredUserId?: string
}
export type CreateAuthenticationProcessResponse = OAuth2ProcessDataPublic

export const createAuthenticationProcess = createAsyncThunk<
  CreateAuthenticationProcessResponse,
  CreateAuthenticationProcessRequest,
  ApiRejectResponse
>('OAUTH2/createAuthenticationProcess', async (payload, { rejectWithValue }) => {
  try {
    const { authProcessId, ...data } = payload
    const resp = await restClient(apiRoutes.OAUTH2_CREATE_AUTHENTICATION_PROCESS, {
      urlParams: {
        authProcessId
      },
      data
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export interface OAuth2TokenExchangeRequest {
  code: string
}

export interface OAuth2TokenExchangeResponse {
  idToken: IdToken
  idTokenJwt: string
  identity: EGDIdentity
}

export const tokenExchange = createAsyncThunk<
  OAuth2TokenExchangeResponse,
  OAuth2TokenExchangeRequest,
  ApiRejectResponse
>('OAUTH2/tokenExchange', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.OAUTH2_TOKEN_EXCHANGE, {
      headers: {
        isToolkitCompatible: true
      },
      data: payload
    })
    return resp.data
  } catch (e) {
    const errorCode =
      e?.data?.detail === OAuth2Exceptions.OAuth2ProcessNotFound
        ? 'OAuth2ProcessNotFound'
        : 'oauth2.auth_code_flow.token_exchange_failed'
    return rejectWithValue(errorCode)
  }
})
