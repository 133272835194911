import { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { resetRegion, setRegion } from 'redux/features/emailServer/emailServerSlice'
import { isPending, isSuccess } from 'redux/toolkit/api'
import { CountryData, getCountryFromCode2, getFilteredCountryList } from 'lib/isoCountries'
import appConfig from 'config/appConfig'
import { isMyFeatureOn } from 'lib/splitio'
import { FEATURES } from 'lib/splitioFeatures'
import {
  setIntercomUser,
  trackEventInAllServices,
  TRACKING_EVENTS,
  trackPageLeaveMixpanelEvent
} from 'lib/monitoring/monitoringService'

export interface State {
  countries: CountryData[]
  selectedRegion: CountryData | undefined
  isSetSelectedRegionInProgress: boolean
  isMissedRegionError: boolean
}

export interface EventHandlers {
  onSelectRegion: (e: React.ChangeEvent<{ value: unknown }>) => void
  onConfirm: () => void
}

export type RegionSelectionLogic = [State, EventHandlers]

export interface RegionSelectionLogicProps {
  gotoNextStep: () => void
}

export const useRegionSelectionLogic = ({ gotoNextStep }: RegionSelectionLogicProps): RegionSelectionLogic => {
  const dispatch = useAppDispatch()
  const { user, accessToken, isSetSelectedRegionInProgress, isSetSelectedRegionSuccess, selectedRegionUrl } =
    useAppSelector(_store => ({
      user: _store.auth.accessTokenObject,
      accessToken: _store.auth.accessToken,
      isSetSelectedRegionInProgress: isPending(_store.emailServer.setRegionApiStatus),
      isSetSelectedRegionSuccess: isSuccess(_store.emailServer.setRegionApiStatus),
      selectedRegionUrl: _store.emailServer.emailServerSettings?.url
    }))
  const VALID_COUNTRIES = isMyFeatureOn(FEATURES.EGD_React_India_Deployment)
    ? ['US', 'GB', 'DE', 'CA', 'AU', 'IN']
    : ['US', 'GB', 'DE', 'CA', 'AU']
  const countries = getFilteredCountryList(VALID_COUNTRIES)
  const [selectedRegion, setSelectedRegion] = useState<CountryData | undefined>()
  const [isMissedRegionError, setIsMissedRegionError] = useState<boolean>(false)

  // init
  useEffect(
    () => {
      trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_0_START)
      trackPageLeaveMixpanelEvent(TRACKING_EVENTS.WIZARD.STEP_0_CLOSE_WIZARD)
      if (user && accessToken) {
        setIntercomUser(user, accessToken, { company: { finished_wizard: false } })
      }

      return () => {
        dispatch(resetRegion())
        trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_0_COMPLETE)
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(() => {
    const willTransitionToAnotherRegion =
      appConfig.ENVIRONMENT.IS_PRODUCTION && selectedRegionUrl && selectedRegionUrl !== window.location.host

    if (isSetSelectedRegionSuccess && !willTransitionToAnotherRegion) {
      gotoNextStep()
    }
  }, [isSetSelectedRegionSuccess, selectedRegionUrl, gotoNextStep])

  const onSelectRegion: EventHandlers['onSelectRegion'] = useCallback(e => {
    const newRegion = getCountryFromCode2((e.target.value || '') as string)
    setIsMissedRegionError(false)
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_0_REGION_CHANGE, { selectedRegion: newRegion })

    if (newRegion) {
      setSelectedRegion(newRegion)
    }
  }, [])

  const onConfirm: EventHandlers['onConfirm'] = useCallback(() => {
    trackEventInAllServices(TRACKING_EVENTS.WIZARD.STEP_0_CONFIRM_REGION, { selectedRegion })

    if (selectedRegion) {
      // fix the GB code for server side
      dispatch(setRegion(selectedRegion.code.replace('GB', 'UK')))
    } else {
      setIsMissedRegionError(true)
    }
  }, [dispatch, selectedRegion])

  return useMemo(
    () => [
      {
        countries,
        selectedRegion,
        isSetSelectedRegionInProgress,
        isMissedRegionError
      },
      {
        onSelectRegion,
        onConfirm
      }
    ],
    [selectedRegion, onSelectRegion, onConfirm, countries, isSetSelectedRegionInProgress, isMissedRegionError]
  )
}
