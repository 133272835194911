import React, { useMemo } from 'react'
import { Grid, IconButton, TextField, Typography } from '@barracuda-internal/bds-core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

import { FilterAltIcon } from '@cuda/bds.icons.filter-alt-icon'

import useStyles from './minMaxFilterStyles'

export type MinMaxFilterProps = {
  /**
   * The absolute max value
   */
  absoluteMax?: number
  /**
   * The absolute min value
   */
  absoluteMin?: number
  /**
   * The current max value
   */
  max?: number
  /**
   * The current min value
   */
  min?: number
  /**
   * The callback to be called when the max value changes
   */
  onChangeMax?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  /**
   * The callback to be called when the min value changes
   */
  onChangeMin?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
  /**
   * The callback to be called when the max value is decremented
   */
  onDecrementMax?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * The callback to be called when the min value is decremented
   */
  onDecrementMin?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * The callback to be called when the max value is incremented
   */
  onIncrementMax?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * The callback to be called when the min value is incremented
   */
  onIncrementMin?: React.MouseEventHandler<HTMLButtonElement> | undefined
  /**
   * The title of the filter
   */
  title?: string
}

export function MinMaxFilter({
  absoluteMax,
  absoluteMin,
  onChangeMax,
  onChangeMin,
  onDecrementMax,
  onDecrementMin,
  onIncrementMax,
  onIncrementMin,
  max,
  min,
  title = 'Filter'
}: MinMaxFilterProps) {
  const classes = useStyles()

  return useMemo(
    () => (
      <Grid container direction="column" spacing={3}>
        <Grid item className={classes.filterTitleContainer}>
          <FilterAltIcon fontSize="small" className={classes.filterTitleIcon} />
          <Typography variant="subtitle1" className={classes.filterTitleText}>
            {title}
          </Typography>
        </Grid>

        <Grid item>
          <Grid container className={classes.formContainer} direction="column" spacing={3}>
            <Grid item>
              <Grid
                container
                spacing={1}
                className={classes.formControl}
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={4} className={classes.labelContainer}>
                  <Typography align="right" data-field="condition-filter-max" variant="body1">
                    Max&nbsp;
                  </Typography>
                  {absoluteMax != null && (
                    <Typography
                      align="right"
                      data-field="condition-filter-max"
                      variant="body1"
                      className={classes.label}
                    >
                      {`(${absoluteMax})`}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="number"
                    id="condition-filter-max"
                    value={Number(max).toString()}
                    onChange={onChangeMax}
                    className={classes.input}
                    InputProps={{
                      inputProps: { min: absoluteMin, max: absoluteMax },
                      endAdornment: (
                        <InputAdornment position="end" className={classes.iconAdornment}>
                          <IconButton
                            aria-label="search icon button"
                            onClick={onIncrementMax}
                            edge="end"
                            size="small"
                            className={classes.iconButton}
                          >
                            <KeyboardArrowUp fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="search icon button"
                            onClick={onDecrementMax}
                            edge="end"
                            size="small"
                            className={classes.iconButton}
                          >
                            <KeyboardArrowDown fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                className={classes.formControl}
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={4} className={classes.labelContainer}>
                  <Typography align="right" data-field="condition-filter-min" variant="body1">
                    Min&nbsp;
                  </Typography>
                  {absoluteMin != null && (
                    <Typography
                      align="right"
                      data-field="condition-filter-min"
                      variant="body1"
                      className={classes.label}
                    >
                      {`(${absoluteMin})`}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    className={classes.input}
                    type="number"
                    variant="standard"
                    id="condition-filter-min"
                    onChange={onChangeMin}
                    value={Number(min).toString()}
                    InputProps={{
                      inputProps: { min: absoluteMin, max: absoluteMax },
                      endAdornment: (
                        <InputAdornment position="end" className={classes.iconAdornment}>
                          <IconButton
                            aria-label="search icon button"
                            onClick={onIncrementMin}
                            edge="end"
                            size="small"
                            className={classes.iconButton}
                          >
                            <KeyboardArrowUp fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="search icon button"
                            onClick={onDecrementMin}
                            edge="end"
                            size="small"
                            className={classes.iconButton}
                          >
                            <KeyboardArrowDown fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      min,
      max,
      title,
      absoluteMax,
      absoluteMin,
      onChangeMax,
      onChangeMin,
      onDecrementMax,
      onDecrementMin,
      onIncrementMax,
      onIncrementMin,
      classes
    ]
  )
}
