import util from 'util'
import { AuditLog } from 'types/AuditLog'

const supportedActions = [
  'MOVE',
  'DELETE',
  'CHANGE',
  'LOGIN',
  'LOGOUT',
  'LOG_EXPORT',
  'LOG_ACTION',
  'CREATE',
  'DELETE_ALL',
  'RESET',
  'ENABLE',
  'DISABLE',
  'SUSPEND',
  'NOTIFY',
  'MIGRATE_CPL',
  'RESTORE',
  'LOGIN_FAILURE'
]

export function formatEventType(audit: AuditLog, formatter: any) {
  if (supportedActions.includes(audit.action)) {
    return formatter(audit.action.toLowerCase())
  }
  return '-'
}

function formatAffected(affectedStr: string | null, formatter: any) {
  if (!affectedStr) {
    return '-'
  }
  return formatter(affectedStr)
}

function formatMove(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'domain':
      return util.format(formatter('fmt_domain_moved'), domainName, affected)
    case 'domain_from':
      return util.format(formatter('fmt_domain_from'), domainName, affected)
    default:
      return formatter('details_unavailable')
  }
}

function formatChange(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'user_settings':
      return util.format(formatter('fmt_changed'), affected)
    case 'domain_settings':
      return util.format(formatter('fmt_changed_for_domain'), affected, domainName)
    case 'account_settings':
      return util.format(formatter('fmt_changed'), affected)
    case 'domain':
      return util.format(formatter('fmt_changed_for_domain'), affected, domainName)
    default:
      return formatter('details_unavailable')
  }
}

function formatDelete(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'user_settings':
      return util.format(formatter('fmt_deleted'), affected)
    case 'user':
      return util.format(formatter('fmt_user_deleted'), affected)
    case 'outbound_ip':
      return util.format(formatter('fmt_outbound_ip_deleted'), affected)
    case 'domain_settings':
      return util.format(formatter('fmt_deleted_for_domain'), affected, domainName)
    case 'domain':
      return util.format(formatter('fmt_domain_deleted'), affected)
    case 'account_settings':
      return util.format(formatter('fmt_deleted'), affected)
    default:
      return formatter('details_unavailable')
  }
}

function formatLogin(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'user':
      return formatter('fmt_user_logged_in')
    case 'domain_manage':
      return util.format(formatter('fmt_logged_in_domain'), affected)
    case 'super_user':
      return formatter('fmt_support_logged_in')
    default:
      return formatter('details_unavailable')
  }
}

function formatLogout(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'user':
      return formatter('fmt_user_logged_out')
    case 'domain_manage':
      return util.format(formatter('fmt_user_logged_out_domain'), domainName)
    case 'super_user':
      return formatter('fmt_support_logged_out')
    default:
      return formatter('details_unavailable')
  }
}

function formatLogExport(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'atd':
      return formatter('fmt_atd_exported')
    case 'message':
      return formatter('fmt_message_log_exported')
    default:
      return formatter('details_unavailable')
  }
}

function formatLogAction(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'delete':
      return util.format(formatter('fmt_message_deleted'), affected)
    case 'emailreg':
      return util.format(formatter('fmt_message_sent_to_emailreg'), affected)
    case 'block':
      return util.format(formatter('fmt_message_blocked'), affected)
    case 'whitelist':
      return util.format(formatter('fmt_message_whitelisted'), affected)
    case 'recategorize':
      return util.format(formatter('fmt_message_recategorized'), affected)
    case 'deliver':
      return util.format(formatter('fmt_message_delivered'), affected)
    case 'view':
      return util.format(formatter('fmt_message_viewed'), affected)
    case 'spam':
      return util.format(formatter('fmt_message_marked_spam'), affected)
    case 'not_spam':
      return util.format(formatter('fmt_message_marked_not_spam'), affected)
    case 'mark_spam':
      return util.format(formatter('fmt_message_marked_spam'), affected)
    case 'mark_ham':
      return util.format(formatter('fmt_message_marked_not_spam'), affected)
    case 'redeliver':
      return util.format(formatter('fmt_message_delivered'), affected)
    case 'reject':
      return util.format(formatter('fmt_message_rejected'), affected)
    case 'outbound-reject':
      return util.format(formatter('fmt_message_rejected'), affected)
    case 'outbound-delete':
      return util.format(formatter('fmt_message_deleted'), affected)
    case 'outbound-deliver':
      return util.format(formatter('fmt_message_delivered'), affected)
    default:
      return formatter('details_unavailable')
  }
}

function formatCreate(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'user_settings':
      return util.format(formatter('fmt_setting_created'), affected)
    case 'user':
      return util.format(formatter('fmt_user_created'), affected)
    case 'outbound_ip':
      return util.format(formatter('fmt_outbound_ip_created'), affected)
    case 'domain':
      return util.format(formatter('fmt_domain_created'), domainName)
    default:
      return formatter('details_unavailable')
  }
}

function formatDeleteAll(actionType: string, domainName: string, affected: string, accountId: string, formatter: any) {
  switch (actionType) {
    case 'user_settings':
      return util.format(formatter('fmt_user_settings_deleted'), affected)
    case 'user':
      return util.format(formatter('fmt_domain_users_deleted'), affected)
    case 'outbound_ip':
      return util.format(formatter('fmt_account_outbound_ips_deleted'), domainName, accountId)
    case 'outbound_ip_list':
      return util.format(formatter('fmt_list_outbound_ips_deleted'), affected)
    case 'domain_settings':
      return util.format(formatter('fmt_domain_settings_deleted'), affected)
    case 'account_settings':
      return util.format(formatter('fmt_account_settings_deleted'), affected)
    default:
      return formatter('details_unavailable')
  }
}

function formatReset(actionType: string, domainName: string, affected: string, formatter: any) {
  switch (actionType) {
    case 'domain':
      return util.format(formatter('fmt_domain_settings_reset_account_level'), domainName)
    case 'user_password':
      return util.format(formatter('fmt_login_info_sent'), affected)
    default:
      return formatter('details_unavailable')
  }
}

function formatEnable(actionType: string, formatter: any) {
  if (actionType === 'account_settings') {
    return formatter('fmt_account_ended')
  }
  return formatter('details_unavailable')
}

function formatDisable(actionType: string, formatter: any) {
  if (actionType === 'account_settings') {
    return formatter('fmt_account_disabled')
  }
  return formatter('details_unavailable')
}

function formatSuspend(actionType: string, formatter: any) {
  if (actionType === 'account_settings') {
    return formatter('fmt_account_suspended')
  }
  return formatter('details_unavailable')
}

function formatNotify(actionType: string, affected: string, formatter: any) {
  if (actionType === 'user') {
    return util.format(formatter('fmt_user_notified_via_email'), affected)
  }
  return formatter('details_unavailable')
}

function formatMigrateCpl(actionType: string, formatter: any) {
  if (actionType === 'account_settings') {
    return formatter('fmt_account_migrated_to_cpl')
  }
  return formatter('details_unavailable')
}

function formatRestore(actionType: string, domainName: string, affected: string, formatter: any) {
  if (actionType === 'domain_settings') {
    return util.format(formatter('fmt_domain_settings_restored'), affected, domainName)
  }
  return formatter('details_unavailable')
}

function formatLoginFailure(actionType: string, formatter: any) {
  if (actionType === 'user') {
    return formatter('fmt_login_failure')
  }
  return formatter('details_unavailable')
}

export function formatDescription(audit: AuditLog, formatter: any) {
  const { action, actionType } = audit
  let { affected } = audit

  if (['user_settings', 'domain_settings', 'account_settings', 'domain'].includes(audit.actionType)) {
    // As new settings are added, they may not be added to the localization file yet,
    // Only use the localized version, else use the variable setting
    const localized = formatAffected(affected, formatter)
    if (!localized.startsWith('ess.audit_log')) {
      affected = localized
    }
  }

  // We need to watch for these showing up as NONE and address if that happens
  const accountId = audit.accountId || formatter('unavailable')
  const domainName = audit.domain || formatter('unavailable')

  switch (action) {
    case 'MOVE':
      return formatMove(actionType, domainName, affected, formatter)
    case 'CHANGE':
      return formatChange(actionType, domainName, affected, formatter)
    case 'DELETE':
      return formatDelete(actionType, domainName, affected, formatter)
    case 'LOGIN':
      return formatLogin(actionType, domainName, affected, formatter)
    case 'LOGOUT':
      return formatLogout(actionType, domainName, affected, formatter)
    case 'LOG_EXPORT':
      return formatLogExport(actionType, domainName, affected, formatter)
    case 'LOG_ACTION':
      return formatLogAction(actionType, domainName, affected, formatter)
    case 'CREATE':
      return formatCreate(actionType, domainName, affected, formatter)
    case 'DELETE_ALL':
      return formatDeleteAll(actionType, domainName, affected, accountId, formatter)
    case 'RESET':
      return formatReset(actionType, domainName, affected, formatter)
    case 'ENABLE':
      return formatEnable(actionType, formatter)
    case 'DISABLE':
      return formatDisable(actionType, formatter)
    case 'SUSPEND':
      return formatSuspend(actionType, formatter)
    case 'NOTIFY':
      return formatNotify(actionType, affected, formatter)
    case 'MIGRATE_CPL':
      return formatMigrateCpl(actionType, formatter)
    case 'RESTORE':
      return formatRestore(actionType, domainName, affected, formatter)
    case 'LOGIN_FAILURE':
      return formatLoginFailure(actionType, formatter)
    default:
      return formatter('details_unavailable')
  }
}
