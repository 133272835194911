import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import {
  getMoveDomain,
  postVerifyDomainTransfer,
  resetGetDomainTransferStates,
  resetMoveDomain,
  resetVerifyDomainTransfer
} from 'redux/features/domains/domainsSlice'
import routesConfig from 'lib/routesConfig'
import { getErrorMessage, isPending, isSuccess } from 'redux/toolkit/api'
import { DomainExceptions } from 'types/domains'
import { GraphqlExceptions } from 'types/graphql'
import { MstoreException } from 'types/mstore'
import { usePollDomainTransferState } from 'components/pages/domains/verifyDomainTransfer/usePollDomainTransferState'
import appConfig from 'config/appConfig'
import { setSuccessSnackBar } from 'redux/features/app/appSlice'

export interface MessageDescriptor {
  textId: string
  data?: Record<string, string>
}

export interface State {
  isGetPending: boolean
  isGetSuccess: boolean
  domainName: string
  cnameRecord: string
  errorText: MessageDescriptor | undefined
  isVerifyPending: boolean
}

export interface EventHandlers {
  onVerify: () => void
  onCancel: () => void
}

export type VerifyDomainTransferLogic = [State, EventHandlers]

export const useVerifyDomainTransferLogic = (): VerifyDomainTransferLogic => {
  const [
    { domainTransferStates, isActive: isPollActive, isTimedOut: isPollTimedOut },
    { start: startPoll, stopAll: stopPoll }
  ] = usePollDomainTransferState(appConfig.DOMAIN_MOVE.VERIFY_DOMAIN_TIMEOUT)
  const dispatch = useAppDispatch()
  const { domainId } = useParams()

  const {
    isGetPending,
    isGetSuccess,
    moveDomain,
    getMoveDomainError,
    isVerifyPending,
    isVerifySuccess,
    verifyDomainTransferError,
    isGetDomainTransferStatesPending
  } = useAppSelector(_store => ({
    isGetPending: isPending(_store.domains.api.getMoveDomainApiStatus),
    isGetSuccess: isSuccess(_store.domains.api.getMoveDomainApiStatus),
    getMoveDomainError: getErrorMessage(_store.domains.api.getMoveDomainApiStatus) as unknown,
    moveDomain: _store.domains.moveDomain,
    isVerifyPending: isPending(_store.domains.api.postVerifyDomainTransferApiStatus),
    isVerifySuccess: isSuccess(_store.domains.api.postVerifyDomainTransferApiStatus),
    verifyDomainTransferError: getErrorMessage(_store.domains.api.postVerifyDomainTransferApiStatus) as unknown,
    isGetDomainTransferStatesPending: isPending(_store.domains.api.getDomainTransferStatesApiStatus)
  }))

  const isException = (apiError: any): apiError is { exception: string; message: string } =>
    typeof apiError === 'object' &&
    apiError !== null &&
    typeof apiError.exception === 'string' &&
    typeof apiError.message === 'string'

  const exceptionAsTextId = useCallback((apiError: unknown, defaultId: string): MessageDescriptor | undefined => {
    if (typeof apiError === 'undefined') {
      return undefined
    }

    if (!isException(apiError)) {
      return { textId: defaultId }
    }

    const { exception, message } = apiError

    switch (exception) {
      case DomainExceptions.DomainTransferNotFoundException:
        return { textId: 'error.domain_transfer_not_found' }
      case DomainExceptions.DomainNotFoundException:
        return { textId: 'error.domain_not_found' }
      case DomainExceptions.UnverifiedDomainTransferAttemptException:
        return { textId: 'error.domain_not_verified' }
      case DomainExceptions.DomainTransferAlreadyCompleteException:
        return { textId: 'error.domain_transfer_already_complete' }
      case DomainExceptions.RequiredCnameRecordNotFoundException:
        return { textId: 'error.cname_record_not_found' }
      case DomainExceptions.DuplicateTransferAttemptException:
        return undefined
      case GraphqlExceptions.GraphqlQueryError:
      case GraphqlExceptions.GraphqlQueryFailed:
        return { textId: 'error.graphql_error' }
      case MstoreException.DomainMoveTimeout:
        return { textId: 'error.mstore_timeout' }
      case MstoreException.DomainMoveFailed:
        return { textId: 'error.mstore_error', data: { message } }
      default:
        return { textId: defaultId }
    }
  }, [])

  const getDomainErrorTextId = useMemo(
    () => exceptionAsTextId(getMoveDomainError, 'error.get_move_domain_failed'),
    [exceptionAsTextId, getMoveDomainError]
  )

  const verifyDomainErrorTextId = useMemo(() => {
    if (isPollTimedOut) {
      return { textId: 'error.mstore_timeout' }
    }
    return exceptionAsTextId(verifyDomainTransferError, 'error.verify_domain_failed')
  }, [exceptionAsTextId, verifyDomainTransferError, isPollTimedOut])

  const onVerify = useCallback(() => {
    if (domainId) {
      dispatch(postVerifyDomainTransfer({ domainId }))
    }
  }, [dispatch, domainId])

  const onCancel = useCallback(() => {
    stopPoll()
    routesConfig.DOMAINS.goto()
  }, [stopPoll])

  const isVerified = useMemo(
    () =>
      domainId &&
      Object.keys(domainTransferStates).includes(domainId || '') &&
      domainTransferStates[parseInt(domainId, 10)] === null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [domainTransferStates]
  )

  useEffect(() => {
    if (domainId) {
      dispatch(getMoveDomain({ domainId }))
    }
    return () => {
      dispatch(resetMoveDomain())
      dispatch(resetVerifyDomainTransfer())
      dispatch(resetGetDomainTransferStates())
    }
  }, [domainId, dispatch])

  useEffect(() => {
    if (domainId && isVerifySuccess) {
      startPoll([domainId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerifySuccess])

  useEffect(() => {
    if (isVerified) {
      dispatch(setSuccessSnackBar({ message: 'verify_domain_transfer_completed', params: [moveDomain?.domainName] }))
      routesConfig.DOMAINS.goto()
    }
    // eslint-disable-next-line
  }, [isVerified])

  useEffect(() => {
    if (
      domainId &&
      verifyDomainTransferError &&
      isException(verifyDomainTransferError) &&
      verifyDomainTransferError.exception === DomainExceptions.DuplicateTransferAttemptException
    ) {
      startPoll([domainId])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyDomainTransferError])

  return useMemo(
    () => [
      {
        isGetPending,
        isGetSuccess,
        domainName: moveDomain?.domainName || '',
        cnameRecord: moveDomain?.cnameRecord || '',
        errorText: getDomainErrorTextId || verifyDomainErrorTextId,
        isVerifyPending: isVerifyPending || isPollActive || isGetDomainTransferStatesPending,
        verifyDomainErrorTextId
      },
      {
        onVerify,
        onCancel
      }
    ],
    [
      isGetPending,
      isGetSuccess,
      moveDomain?.domainName,
      moveDomain?.cnameRecord,
      getDomainErrorTextId,
      isVerifyPending,
      isPollActive,
      verifyDomainErrorTextId,
      isGetDomainTransferStatesPending,
      onVerify,
      onCancel
    ]
  )
}
