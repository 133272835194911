import { makeStyles } from '@barracuda-internal/bds-core'

export default makeStyles({
  root: {
    margin: 20
  },
  progress: {
    marginTop: 0
  }
})
