import { createAsyncThunk } from '@reduxjs/toolkit'

import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import {
  AccountEamilContinuitySettings,
  AccountUser,
  AccountUserRole,
  AccountUsersResults,
  AddUpdateAccountUserResult,
  PoliciesSettings,
  QuarantineNotificationSettings,
  QuarantineStatus,
  UserQNSettings
} from 'types/users'
import { InboundScheduleReport } from 'types/Settings'
import { RootState } from 'redux/toolkit/store'
import { User } from 'types/auth'
import { gotoEnduserEntryPathFromAdmin } from 'lib/routesConfig'
import { convertScheduleToArr, convertScheduleToObj } from 'lib/convertSchedule'
import { EMPTY_SCHEDULE_MATRIX } from 'components/libs/scheduler/schedulerMatrixTypes'

export interface GetAccountUsersParams {
  size: number
  page: number
  search: string
  sort: string
  direction: string
  roles: string
  domain: string
}

export interface GetAccountUsersResponse {
  results: AccountUsersResults
  metadata: any
}

export interface UpdateAccountUserRolePayload {
  userId: string
  role: AccountUserRole
  domains: string[]
}

export interface AddUpdateAccountUsersPayload {
  userIds: string[]
  enableQuarantine: boolean
  notifyUsers: boolean
}

export interface AddUpdateAccountUsersResponse {
  results: AddUpdateAccountUserResult[]
  metadata: any
}

export type DeleteAccountUserPayload = string[]

export type RequestResetPasswordPayload = string

export type GetUserQNSettingsPayload = string

export interface GetUserQNSettingsResponse {
  result: InboundScheduleReport
  metadata: any
}

export type GetUsersQNSettingsPayload = string[]

export interface GetUsersQNSettingsResponse {
  result: UserQNSettings[]
  metadata: any
}

export type GetAccountEmailContinuitySettingsResponse = AccountEamilContinuitySettings
export type UpdateAccountEmailContinuitySettingsPayload = boolean
export type UpdateAccountEmailContinuitySettingsResponse = AccountEamilContinuitySettings

export interface GetQuarantineNotificationSettingsResponse {
  results: QuarantineNotificationSettings
  metadata: any
}

export type UpdateQuarantineNotificationSettingsPayload = QuarantineNotificationSettings
export type UpdateQuarantineNotificationSettingsResponse = GetQuarantineNotificationSettingsResponse

export interface GetPoliciesSettingsResponse {
  results: PoliciesSettings
  metadata: any
}

export type UpdatePoliciesSettingsPayload = Partial<PoliciesSettings>
export type UpdatePoliciesSettingsResponse = GetPoliciesSettingsResponse

export type LoginAsUserPayload = string
export type LoginAsUserResponse = User

export const getAccountUsers = createAsyncThunk<GetAccountUsersResponse, undefined, ApiRejectResponse>(
  'USERS/getAccountUsers',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { take, skip, search, sort, userRoleFilter, domainFilter } = (getState() as RootState).dataTables.users
      const params: GetAccountUsersParams = {
        size: take,
        page: skip,
        search,
        sort: sort?.[0].field || '',
        direction: sort?.[0].dir || '',
        roles: userRoleFilter,
        domain: domainFilter
      }

      const resp = await restClient(apiRoutes.GET_ACCOUNT_USERS, { params: { ...params } })

      // Set the quarantine status flag to loading for each users
      let user_ids: string[] = []
      resp.data.results.users = resp.data.results.users.map((user: AccountUser) => {
        user_ids = [...user_ids, user.userId]
        return {
          ...user,
          quarantine: QuarantineStatus.loading
        }
      })
      dispatch(getUsersQNSettings(user_ids))

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const updateAccountUserRole = createAsyncThunk<undefined, UpdateAccountUserRolePayload, ApiRejectResponse>(
  'USERS/updateAccountUserRole',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.UPDATE_ACCOUNT_USER_ROLE, {
        urlParams: { userId: payload.userId },
        data: {
          role: payload.role,
          domains: payload.domains
        }
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const addUpdateAccountUsers = createAsyncThunk<
  AddUpdateAccountUsersResponse,
  AddUpdateAccountUsersPayload,
  ApiRejectResponse
>('USERS/addUpdateAccountUsers', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.UPDATE_ACCOUNT_USERS, {
      data: {
        userIds: payload.userIds,
        enableQuarantine: payload.enableQuarantine,
        notifyUsers: payload.notifyUsers
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const deleteAccountUsers = createAsyncThunk<undefined, DeleteAccountUserPayload, ApiRejectResponse>(
  'USERS/deleteAccountUsers',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_ACCOUNT_USERS, {
        data: { userIds: payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const requestResetPassword = createAsyncThunk<undefined, RequestResetPasswordPayload, ApiRejectResponse>(
  'USERS/requestResetPassword',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.REQUEST_RESET_PASSWORD, {
        urlParams: { userId: payload }
      })

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getUserQNSettings = createAsyncThunk<
  GetUserQNSettingsResponse,
  GetUserQNSettingsPayload,
  ApiRejectResponse
>('USERS/getUserQNSettings', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.QUARANTINE_NOTIFICATION_USER_SETTINGS, { params: { user_id: payload } })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getUsersQNSettings = createAsyncThunk<
  GetUsersQNSettingsResponse,
  GetUsersQNSettingsPayload,
  ApiRejectResponse
>('USERS/getUsersQNSettings', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.QUARANTINE_NOTIFICATION_USERS_SETTINGS, { data: { userIds: payload } })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getAccountEmailContinuitySettings = createAsyncThunk<
  GetAccountEmailContinuitySettingsResponse,
  undefined,
  ApiRejectResponse
>('USERS/getAccountEmailContinuitySettings', async (_, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.GET_EMAIL_CONTINUITY_ACCOUNT_SETTINGS)
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const updateAccountEmailContinuitySettings = createAsyncThunk<
  UpdateAccountEmailContinuitySettingsResponse,
  UpdateAccountEmailContinuitySettingsPayload,
  ApiRejectResponse
>('USERS/updateAccountEmailContinuitySettings', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.UPDATE_EMAIL_CONTINUITY_ACCOUNT_SETTINGS, {
      data: { isAutoEnabled: payload }
    })
    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getQuarantineNotificationSettings = createAsyncThunk<
  GetQuarantineNotificationSettingsResponse,
  undefined,
  ApiRejectResponse
>('USERS/getQuarantineNotificationSettings', async (_, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.GET_QUARANTINE_NOTIFICATION_SETTINGS)

    const customInterval = resp.data.results.customQuarantineNotificationInterval
    resp.data.results.customQuarantineNotificationInterval = customInterval
      ? convertScheduleToObj(resp.data.results.customQuarantineNotificationInterval)
      : EMPTY_SCHEDULE_MATRIX

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const updateQuarantineNotificationSettings = createAsyncThunk<
  UpdateQuarantineNotificationSettingsResponse,
  UpdateQuarantineNotificationSettingsPayload,
  ApiRejectResponse
>('USERS/updateQuarantineNotificationSettings', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.UPDATE_QUARANTINE_NOTIFICATION_SETTINGS, {
      data: {
        ...payload,
        ...(payload.customQuarantineNotificationInterval && {
          customQuarantineNotificationInterval: convertScheduleToArr(payload.customQuarantineNotificationInterval)
        })
      }
    })

    const customInterval = resp.data.results.customQuarantineNotificationInterval
    resp.data.results.customQuarantineNotificationInterval = customInterval
      ? convertScheduleToObj(resp.data.results.customQuarantineNotificationInterval)
      : EMPTY_SCHEDULE_MATRIX

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const getPoliciesSettings = createAsyncThunk<GetPoliciesSettingsResponse, undefined, ApiRejectResponse>(
  'USERS/getPoliciesSettings',
  async (_, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_POLICIES_SETTINGS)

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const updatePoliciesSettings = createAsyncThunk<
  UpdatePoliciesSettingsResponse,
  UpdatePoliciesSettingsPayload,
  ApiRejectResponse
>('USERS/updatePoliciesSettings', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.UPDATE_POLICIES_SETTINGS, { data: { ...payload } })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const loginAsUser = createAsyncThunk<LoginAsUserResponse, LoginAsUserPayload, ApiRejectResponse>(
  'USERS/loginAsUser',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.LOGIN_AS_USER, { urlParams: { userId: payload } })

      gotoEnduserEntryPathFromAdmin()

      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)
