import { useEffect } from 'react'
import useAppTypeEntryPaths from 'components/libs/routes/useAppTypeEntryPaths'

function UnregisteredPage() {
  const { appTypeEntryPath } = useAppTypeEntryPaths()

  useEffect(() => {
    appTypeEntryPath.goto()
  }, [appTypeEntryPath])

  return null
}

export default UnregisteredPage
