import { useState, useMemo, useCallback, ChangeEvent } from 'react'
import { setLanguage } from 'redux/features/app/appSlice'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'

export interface UseLanguageMenuDialogLogicConfig {
  onClose: () => void
}

export interface UseLanguageMenuDialogLogic {
  selectedLanguage: string
  handleSelectLanguage: (e: ChangeEvent<HTMLInputElement>) => void
  onSaveLanguage: () => void
}

export const useLanguageMenuDialogLogic = ({
  onClose
}: UseLanguageMenuDialogLogicConfig): UseLanguageMenuDialogLogic => {
  const { preferredLanguage } = useAppSelector(_store => ({
    preferredLanguage: _store.app.language
  }))
  const dispatch = useAppDispatch()
  const [selectedLanguage, setSelectedLanguage] = useState(preferredLanguage)

  const handleSelectLanguage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage(e.target.value)
  }, [])

  const saveSelectLanguage = useCallback(() => {
    dispatch(setLanguage(selectedLanguage))
  }, [dispatch, selectedLanguage])

  const onSaveLanguage = useCallback(() => {
    onClose()
    saveSelectLanguage()
  }, [onClose, saveSelectLanguage])

  return useMemo(
    () => ({
      selectedLanguage,
      handleSelectLanguage,
      onSaveLanguage
    }),
    [selectedLanguage, handleSelectLanguage, onSaveLanguage]
  )
}
