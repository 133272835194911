import React, { useMemo } from 'react'
import { Alert, Link } from '@barracuda-internal/bds-core'

import styles from 'components/libs/banner/BannerStyles'
import { useFormatMessage } from 'lib/localization'

const BASE_I18N_KEY = 'ess.global_message_banner'

export type IncompleteWizardBannerProps = {
  wizardUrl?: string
}

const IncompleteWizardBanner = ({ wizardUrl }: IncompleteWizardBannerProps) => {
  const classes = styles()
  const formatMessage = useFormatMessage(BASE_I18N_KEY)

  return useMemo(
    () => (
      <Alert className={classes.alert} severity="info">
        {formatMessage('wizard_text')}
        <Link className={classes.interfaceBannerLink} href={wizardUrl}>
          <b>{formatMessage('wizard_link')}</b>
        </Link>
      </Alert>
    ),
    [classes.alert, classes.interfaceBannerLink, formatMessage, wizardUrl]
  )
}

export default IncompleteWizardBanner
