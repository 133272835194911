import { ActionStatus, ActionTaken, Direction, Reason } from 'types/Messages'

export interface MessageSearchFilters {
  selectedDomainId?: string
  direction: Direction
  dateRange?: number
  action: ActionTaken
  deliveryStatus: ActionStatus
  reason: Reason
  search_str: string
  start: number
  end: number
}

export enum SearchSettingsViewType {
  MESSAGE_LOG = 'message_log',
  QUARANTINE = 'quarantine'
}

export interface SearchSettingsViewConfig {
  type: SearchSettingsViewType
  accountId: string
  domainId: string | undefined
}
export interface SearchSettings {
  name: string
  id: string
  messageSearchFilters: MessageSearchFilters
}

export interface SearchSettingsRepository {
  getAll: () => Promise<SearchSettings[]>
  add: (item: SearchSettings) => Promise<void>
  delete: (id: string) => Promise<void>
}
