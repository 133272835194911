import React, { useMemo, useState } from 'react'
import { BrowserHistory, Action, Location } from 'history'
import { Router } from 'react-router-dom'

interface CustomRouterProps {
  basename?: string
  children?: React.ReactNode
  history: BrowserHistory
}

interface CustomRouterState {
  action: Action
  location: Location
}

export const CustomBrowserRouter: React.FC<CustomRouterProps> = ({
  history,
  basename,
  children
}: CustomRouterProps) => {
  const [historyState, setHistoryState] = useState<CustomRouterState>({
    action: history.action,
    location: history.location
  })

  React.useLayoutEffect(() => history.listen(setHistoryState), [history])

  return useMemo(
    () => (
      <Router
        basename={basename}
        location={historyState.location}
        navigationType={historyState.action}
        navigator={history}
      >
        {children}
      </Router>
    ),
    [basename, historyState, history, children]
  )
}
