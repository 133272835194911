import { useMemo } from 'react'
import {
  EventHandlers as LookupUserEvents,
  State as LookupUserState
} from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdLookupUser'
import { State as AuthorizationState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdAuthorization'
import { EventHandlers as SyncNowEvents } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdSyncNow'
import { EventHandlers as OptionsEvents } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdOptions'
import { State as PollUserStatsState } from 'components/pages/domains/editDomain/editDomainDirectoryServices/azureAdSettings/useAzureAdPollUserStats'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'

// This hook is used to calculate the state of those Azure AD settings
// UI elements that depend on multiple flow's individual state.
// The hook calls the state calculation function of other individual
// hooks with passing in the dependency (the other state)
export const useAzureAdMultiDependencyState = (
  form: EditDomainForm,
  optionsEvents: OptionsEvents,
  authorizationState: AuthorizationState,
  syncNowEvents: SyncNowEvents,
  pollUserStatsState: PollUserStatsState,
  lookupUserEvents: LookupUserEvents,
  lookupUserState: LookupUserState
) => {
  const isOptionsDisabled = useMemo(
    () => optionsEvents.getIsOptionsDisabled(authorizationState, lookupUserState),
    [authorizationState, optionsEvents, lookupUserState]
  )

  const syncStats = useMemo(() => syncNowEvents.getSyncStats(pollUserStatsState), [syncNowEvents, pollUserStatsState])

  const [isSyncNowLoading, isSyncNowDisabled] = useMemo(
    () => syncNowEvents.getSyncNowState(pollUserStatsState, isOptionsDisabled),
    [syncNowEvents, isOptionsDisabled, pollUserStatsState]
  )

  const isLookupUserDisabled = useMemo(
    () => lookupUserEvents.getIsLookupUserDisabled(isOptionsDisabled),
    [lookupUserEvents, isOptionsDisabled]
  )

  return useMemo(
    () => ({
      isOptionsDisabled,
      syncStats,
      isSyncNowLoading,
      isSyncNowDisabled,
      isLookupUserDisabled
    }),
    [isLookupUserDisabled, syncStats, isOptionsDisabled, isSyncNowDisabled, isSyncNowLoading]
  )
}
