import { useMemo, useEffect, useCallback, Dispatch, SetStateAction, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { AvailableSettings, DomainEntry, ForceTlsValue, SettingValue } from 'types/Settings'
import { updateDomainSettings } from 'redux/features/settings/settingsSlice'
import { isSuccess, isPending } from 'redux/toolkit/api'
import routesConfig from 'lib/routesConfig'
import { EditDomainForm } from 'components/pages/domains/editDomain/useEditDomainForm'
import { DomainsTabRights, useDomainsTabRights } from 'components/pages/domains/useDomainsTabRights'

export interface State {
  inProgress: boolean
  isLoading: boolean
  forceTlsValue: string
  inboundTlsAllValue: string
  tlsChanged: boolean
  data: any[]
  permissions: DomainsTabRights
}

export interface EventHandlers {
  onAddItem: (item: DomainEntry) => void
  onRemoveItem: (id: string) => void
  onBulkEdit: () => void
  onInputChange: (name: string) => (value: string) => void
  setIsSmtpEncryptionTableDirty: Dispatch<SetStateAction<boolean>>
}

export type UseEditDomainSmtpEncryptionLogic = [State, EventHandlers]

export interface UseEditDomainSmtpEncryptionLogicProps {
  form: EditDomainForm
  delegateDirtyFormState: Dispatch<SetStateAction<boolean>>
}

export const useEditDomainSmtpEncryptionLogic = ({
  form,
  delegateDirtyFormState
}: UseEditDomainSmtpEncryptionLogicProps): UseEditDomainSmtpEncryptionLogic => {
  const permissions = useDomainsTabRights()
  const dispatch = useAppDispatch()
  const [{ inProgress, domainId, forceTls, inboundTlsAll }] = form
  const [isSmtpEncryptionTableDirty, setIsSmtpEncryptionTableDirty] = useState<boolean>(false)

  useEffect(() => {
    delegateDirtyFormState(isSmtpEncryptionTableDirty)
  }, [isSmtpEncryptionTableDirty, delegateDirtyFormState])

  const { domain, isGetDomainSuccess, isUpdateDomainSettingsInProgress } = useAppSelector(_store => ({
    domain: _store.domains.domain,
    isGetDomainSuccess: isSuccess(_store.domains.api.getDomainApiStatus),
    isUpdateDomainSettingsInProgress: isPending(_store.settings.updateDomainSettingsApiStatus)
  }))

  const onInputChange = useCallback(
    (name: string) => (value: string) => {
      // eslint-disable-next-line default-case
      switch (true) {
        case name === AvailableSettings.FORCE_TLS:
          forceTls.setValue(value as ForceTlsValue)
          break
        case name === AvailableSettings.INBOUND_TLS_ALL:
          inboundTlsAll.setValue(value as SettingValue)
          break
      }
    },
    [forceTls, inboundTlsAll]
  )

  const data = useMemo(() => {
    const exemptions = domain?.inboundTlsDomainExemptions || []
    const domains = domain?.inboundTlsDomains || []
    return inboundTlsAll.value === SettingValue.ENABLED ? exemptions : domains
  }, [inboundTlsAll, domain])

  const updateSettings = useCallback(
    (entries: DomainEntry[]) => {
      if (!permissions.canManageAdvancedSmtpEncryptionSettings) {
        return
      }
      const settingName =
        inboundTlsAll.value === SettingValue.ENABLED
          ? AvailableSettings.INBOUND_TLS_DOMAIN_EXEMPTIONS
          : AvailableSettings.INBOUND_TLS_DOMAINS
      if (domainId) {
        dispatch(
          updateDomainSettings({
            domainId,
            settings: { inbound_tls_all: inboundTlsAll.value, [settingName]: entries }
          })
        )
      }
    },
    [permissions.canManageAdvancedSmtpEncryptionSettings, inboundTlsAll.value, domainId, dispatch]
  )

  const onAddItem = useCallback(
    (item: DomainEntry) => {
      const entries = [...data, item]
      updateSettings(entries)
    },
    [data, updateSettings]
  )

  const onRemoveItem = useCallback(
    (id: string) => {
      const entries = data.filter(entry => entry.id !== id)
      updateSettings(entries)
    },
    [data, updateSettings]
  )

  const onBulkEdit = useCallback(() => {
    if (inboundTlsAll.value === SettingValue.ENABLED) {
      routesConfig.INBOUND_TLS_DOMAIN_EXEMPTIONS_BULK_EDIT.goto()
    }
    if (inboundTlsAll.value === SettingValue.DISABLED) {
      routesConfig.INBOUND_TLS_DOMAINS_BULK_EDIT.goto()
    }
  }, [inboundTlsAll.value])

  const forceTlsValue = useMemo(() => {
    if (typeof forceTls.value === 'undefined') {
      return ''
    }
    return forceTls.value || ForceTlsValue.OPTIONAL
  }, [forceTls.value])

  const inboundTlsAllValue = useMemo(() => {
    if (typeof inboundTlsAll.value === 'undefined') {
      return ''
    }
    return inboundTlsAll.value || SettingValue.DISABLED
  }, [inboundTlsAll.value])

  useEffect(() => {
    if (isGetDomainSuccess) {
      forceTls.reset(domain?.forceTls ? domain?.forceTls : ForceTlsValue.OPTIONAL)
      inboundTlsAll.reset(domain?.inboundTlsAll ? domain?.inboundTlsAll : SettingValue.DISABLED)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetDomainSuccess])

  return useMemo(
    () => [
      {
        inProgress,
        isLoading: isUpdateDomainSettingsInProgress || inProgress,
        forceTlsValue,
        inboundTlsAllValue,
        tlsChanged: inboundTlsAll.isChanged,
        data,
        permissions
      },
      {
        onAddItem,
        onRemoveItem,
        onBulkEdit,
        onInputChange,
        setIsSmtpEncryptionTableDirty
      }
    ],
    [
      inProgress,
      isUpdateDomainSettingsInProgress,
      forceTlsValue,
      inboundTlsAllValue,
      inboundTlsAll.isChanged,
      data,
      permissions,
      onAddItem,
      onRemoveItem,
      onBulkEdit,
      onInputChange
    ]
  )
}
