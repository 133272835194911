import React, { ReactNode, useMemo } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@barracuda-internal/bds-core'
import { Warning } from '@barracuda-internal/bds-core/dist/Icons/Feedback'
import styles from 'components/libs/dialog/warningDialog/warningDialogStyles'
import { useFormatMessage } from 'lib/localization'
import { useWarningDialogLogic } from 'components/libs/dialog/warningDialog/useWarningDialogLogic'

const BASE_I18N_KEY = 'ess.dialog'

export interface WarningDialogProps {
  title: string
  description?: ReactNode
  onCancel: () => void
  onConfirm?: () => void
  open?: boolean
  onClose?: () => void
  isPending?: boolean
}

const WarningDialog: React.FC<WarningDialogProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
  open,
  onClose,
  isPending
}) => {
  const isOpen = typeof open === 'undefined' ? true : open
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const logic = useWarningDialogLogic({ isPending, onCancel, onConfirm, onClose })

  return useMemo(
    () => (
      <Dialog maxWidth="sm" open={isOpen} data-testid="root-dialog" onClose={logic.onClose}>
        <DialogTitle data-testid="title">{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid className={classes.content} container direction="row" alignItems="center">
            <Warning className={classes.warningIcon} data-testid="warning-icon" />
            <Grid>
              {typeof description === 'string' && !!description && (
                <Typography data-testid="description">{description}</Typography>
              )}
              {typeof description !== 'string' && !!description && description}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={logic.onCancel} data-testid="cancel-button" disabled={isPending}>
            {formatMessage('cancel')}
          </Button>
          {logic.onConfirm && (
            <Button
              variant="contained"
              color="primary"
              onClick={logic.onConfirm}
              data-testid="confirm-button"
              disabled={isPending}
              isLoading={isPending}
            >
              {formatMessage('ok')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    ),
    [isOpen, logic, classes, title, description, formatMessage, isPending]
  )
}

export default WarningDialog
